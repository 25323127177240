<!--Top 10 productos más vendidos-->
<div class="row">
  <div class="col-7 text-left d-flex align-items-center">
    <p class="main-title pl-2">Top 10 productos más vendidos este mes</p>
  </div>
  <div class="col-5 text-right">
    <button class="btn-morado" routerLink="/portafolio">
      Ver
      <span class="texto-btn-temp">todo el</span>
      catálogo
    </button>
  </div>
</div>
<div class="container-fluid container_productos" *ngIf="top_productos.length > 0; else estado_vacio">
  <app-top-producto-card *ngFor="let product of top_productos" [producto]="product"></app-top-producto-card>
</div>
<ng-template #estado_vacio>
  <div class="col-12 text-center">
    <img src="../../../assets/img/icon-warning-amarillo.png" alt="Advertencia" id="icon-warning"
      style="max-width: 200px" class="w-100 p-4" />
    <h5>
      Nada de nada
    </h5>
    <p>
      No tienes productos que ver para esta sección
    </p>
    <button (click)="validarAcceso()" class="btn-morado">Crear Catálogo</button>
  </div>
</ng-template>