import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import Chart from 'chart.js/auto';
import { Router } from '@angular/router';
import { RestService } from '../../services/rest/rest.service';
import { Store } from '@ngxs/store';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyPipe } from '@angular/common';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { LocalDataSource } from 'ng2-smart-table';

@Component({
  selector: 'app-graficas',
  templateUrl: './graficas.component.html',
  styleUrls: ['./graficas.component.css'],
})
export class GraficasComponent implements OnInit {
  /** ID horeca */
  public id_horeca = this.authService.user_horeca!._id;
  /** Pie - Tipo de trabajadores */
  @ViewChild('pie_trabajadores_tipo', { static: true })
  public pie_trabajadores_tipo!: ElementRef;
  public data_pie_trabajadores_tipo: any;
  public pie_chart_trabajadores_tipo: any;
  public filtro_trabajadores_tipo: any;
  public total_trabajadores_tipo: any;
  /** Pie - Estado de trabajadores */
  @ViewChild('pie_trabajadores_estado', { static: true })
  public pie_trabajadores_estado!: ElementRef;
  public data_pie_trabajadores_estado: any;
  public pie_chart_trabajadores_estado: any;
  public filtro_trabajadores_estado: any;
  public total_trabajadores_estado: any;
  /** Pie - Participacion ventas por punto */
  @ViewChild('pie_puntos_participacion', { static: true })
  public pie_puntos_participacion!: ElementRef;
  public data_pie_puntos_participacion: any;
  public pie_chart_puntos_participacion: any;
  public filtro_puntos_participacion: any;
  public total_puntos_participacion: any;
  /** Tabla pedidos por trabajador - planeador */
  public settings_pedidos_planeador: any = {};
  public data_pedidos_planeador: any = [];
  public source_pedidos_planeador?: LocalDataSource;
  public total_pedidos_planeador = 0;
  /** Tabla pedidos por trabajador - administrador */
  public settings_pedidos_administrador: any = {};
  public data_pedidos_administrador: any = [];
  public source_pedidos_administrador?: LocalDataSource;
  public total_pedidos_administrador = 0;
  /** Barra - pedidos por puntos */
  @ViewChild('bar_puntos_pedidos', { static: true })
  private bar_puntos_pedidos!: ElementRef;
  private data_bar_puntos_pedidos: any;
  private bar_chart_puntos_pedidos: any;
  public total_puntos_pedidos: any;
  /** Barra - distribuidores por puntos */
  @ViewChild('bar_punto_distribuidores', { static: true })
  private bar_punto_distribuidores!: ElementRef;
  private data_bar_punto_distribuidores: any;
  private bar_chart_punto_distribuidores: any;
  public total_punto_distribuidores: any;
  /** Barra - trabajadores por puntos */
  @ViewChild('bar_puntos_trabajadores', { static: true })
  private bar_puntos_trabajadores!: ElementRef;
  private data_bar_puntos_trabajadores: any;
  private bar_chart_puntos_trabajadores: any;
  public total_puntos_trabajadores: any;
  /** Paleta colores */
  public colors = [
    'rgba(199, 195, 239, 1)',
    'rgba(247, 234, 194, 1)',
    'rgba(250, 211, 157, 1)',
    'rgba(179, 179, 210, 1)',
    'rgba(142, 111, 247, 1)',
  ];
  /** Modal de carga para darle feedback al usuario */
  public modalCarga?: NgbModalRef;
  /** Evita que al hacer click por fuera se cierre el modal */
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };

  constructor(
    private restService: RestService,
    private authService: AuthService,
    private modalService: NgbModal,
    private currency: CurrencyPipe,
    private router: Router,
    private ngxsStore: Store
  ) {
    this.data_pie_trabajadores_tipo = {
      labels: [],
      datasets: [],
    };
    this.data_pie_trabajadores_estado = {
      labels: [],
      datasets: [],
    };
    this.data_pie_puntos_participacion = {
      labels: [],
      datasets: [],
    };
    this.data_bar_puntos_pedidos = {
      labels: [],
      datasets: [],
    };
    this.data_bar_punto_distribuidores = {
      labels: [],
      datasets: [],
    };
    this.data_bar_puntos_trabajadores = {
      labels: [],
      datasets: [],
    };
  }

  async ngOnInit() {
    this.id_horeca = await this.ngxsStore.snapshot().auth.user_horeca._id;
    /** Modal generíco cargando... */
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    /** Carga datos a graficas pie y barra */
    await this.getChartTrabajadoresTipo();
    await this.getChartTrabajadoresEstado();
    await this.getChartPuntosParticipacion();
    await this.cargarInformacionPuntosPedidos();
    await this.cargarInformacionPuntosDistribuidores();
    await this.cargarInformacionPuntosTrabajadores();
    /** Carga tablas */
    await this.configSmartTablePedidosPlaneador();
    await this.cargarDatosPedidosPlaneador();
    await this.configSmartTablePedidosAdministrador();
    await this.cargarDatosPedidosAdministrador();
    /** Cerrar Modal generíco cargando... */
    this.modalCarga?.close();
  }

  /**
   * Inicializa los datos de las gráficas y llama a los métodos adecuados para mostrar cada una
   */
  /******************************** Tipos de trabajadores *******************************/
  public async getChartTrabajadoresTipo() {
    let total_trabajadores_tipo = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/horeca/pie_chart/trabajadores_tipo/${this.id_horeca}`)
      .toPromise()
      .then((tipos: any) => {
        tipos = tipos.data;
        tipos.forEach((element: any) => {
          let estado = element._id;
          if (estado === 'Rechazado' || estado === 'Desactivado' || estado === 'Inactivo') {
            estado = 'INACTIVO/RECHAZADO';
          }
          if (estado === 'Aprobado') {
            estado = 'APROBADO';
          }
          this.data_pie_trabajadores_tipo.labels.push(estado);
          this.data_pie_trabajadores_tipo.datasets.push(element.total);
          total_trabajadores_tipo = total_trabajadores_tipo + element.total;
        });
        this.total_trabajadores_tipo = total_trabajadores_tipo;
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
    this.pie_chart_trabajadores_tipo = new Chart(this.pie_trabajadores_tipo.nativeElement, {
      type: 'doughnut',
      data: {
        labels: this.data_pie_trabajadores_tipo.labels,
        datasets: [
          {
            label: 'Tipo de trabajadores',
            backgroundColor: this.colors.slice(1, this.colors.length - 1),
            data: this.data_pie_trabajadores_tipo.datasets,
          },
        ],
      },
      options: {
        responsive: true,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          legend: {
            position: 'top',
            align: 'center',
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.label;
                const value = context.formattedValue;
                let sum = 0;
                const dataArr = context.dataset.data;
                dataArr.map((data: any) => {
                  sum += data;
                });
                const percentage = ((+value * 100) / sum).toFixed(2) + '%';
                return [label, value, percentage];
              },
            },
          },
        },
      },
    });
  }
  /********************************* Estado de trabajadores ********************************/
  public async getChartTrabajadoresEstado() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.pie_chart_trabajadores_estado) {
      this.pie_chart_trabajadores_estado.destroy();
      this.data_pie_trabajadores_estado.labels = [];
      this.data_pie_trabajadores_estado.datasets = [];
    }
    let total_trabajadores_estado = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/horeca/pie_chart/trabajadores_estado/${this.id_horeca}`)
      .toPromise()
      .then((estados: any) => {
        estados = estados.data;
        estados.forEach((element: any) => {
          let estado = element._id;
          if (estado === 'Rechazado' || estado === 'Desactivado' || estado === 'Inactivo') {
            estado = 'INACTIVO/RECHAZADO';
          }
          if (estado === 'Aprobado') {
            estado = 'APROBADO';
          }
          this.data_pie_trabajadores_estado.labels.push(estado);
          this.data_pie_trabajadores_estado.datasets.push(element.total);
          total_trabajadores_estado = total_trabajadores_estado + element.total;
        });
        this.total_trabajadores_estado = total_trabajadores_estado;
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
    this.pie_chart_trabajadores_estado = new Chart(this.pie_trabajadores_estado.nativeElement, {
      type: 'doughnut',
      data: {
        labels: this.data_pie_trabajadores_estado.labels,
        datasets: [
          {
            label: 'Estado de trabajadores',
            backgroundColor: this.colors.slice(1, this.colors.length - 1),
            data: this.data_pie_trabajadores_estado.datasets,
          },
        ],
      },
      options: {
        responsive: true,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          legend: {
            position: 'top',
            align: 'center',
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.label;
                const value = context.formattedValue;
                let sum = 0;
                const dataArr = context.dataset.data;
                dataArr.map((data: any) => {
                  sum += data;
                });
                const percentage = ((+value * 100) / sum).toFixed(2) + '%';
                return [label, value, percentage];
              },
            },
          },
        },
      },
    });
  }
  /************************** Participación en ventas por punto *************************/
  public async getChartPuntosParticipacion() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.pie_chart_puntos_participacion) {
      this.pie_chart_puntos_participacion.destroy();
      this.data_pie_puntos_participacion.labels = [];
      this.data_pie_puntos_participacion.datasets = [];
    }
    let total_puntos_participacion = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/horeca/pie_chart/punto_entrega_participacion/${this.id_horeca}`)
      .toPromise()
      .then((estados: any) => {
        estados = estados.data;
        estados.forEach((element: any) => {
          this.data_pie_puntos_participacion.labels.push(element._id);
          this.data_pie_puntos_participacion.datasets.push(element.total);
          total_puntos_participacion = total_puntos_participacion + element.total;
        });
        this.total_puntos_participacion = this.currency.transform(total_puntos_participacion, '$', 'symbol', '1.0-0');
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
    this.pie_chart_puntos_participacion = new Chart(this.pie_puntos_participacion.nativeElement, {
      type: 'doughnut',
      data: {
        labels: this.data_pie_puntos_participacion.labels,
        datasets: [
          {
            label: 'Estado de trabajadores',
            backgroundColor: this.colors.slice(1, this.colors.length - 1),
            data: this.data_pie_puntos_participacion.datasets,
          },
        ],
      },
      options: {
        responsive: true,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          legend: {
            position: 'top',
            align: 'center',
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.label;
                const value = context.formattedValue;
                let sum = 0;
                const dataArr = context.dataset.data;
                dataArr.map((data: any) => {
                  sum += data;
                });
                const percentage = ((+value * 100) / sum).toFixed(2) + '%';
                return [label, value, percentage];
              },
            },
          },
        },
      },
    });
  }
  /****************************************** Pedidos planeador *****************************************/
  /**
   * Configura los titulos y demas settings de la tabla
   * para recibir información de la base de datos
   */
  private configSmartTablePedidosPlaneador(): void {
    this.settings_pedidos_planeador = {
      pager: {
        display: true,
        perPage: 10,
      },
      actions: {
        columnTitle: 'Acción',
        add: false,
        edit: false,
        delete: false,
      },
      hideSubHeader: false,
      columns: {
        usuario: {
          title: 'Usuario',
          editable: false,
          filter: true,
        },
        punto_entrega: {
          title: 'Punto de entrega',
          editable: false,
          filter: true,
        },
        distribuidor: {
          title: 'Distribuidor',
          editable: false,
          filter: true,
        },
        total: {
          title: 'Total pedidos',
          editable: false,
          filter: true,
        },
      },
    };
  }
  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  private cargarDatosPedidosPlaneador(): void {
    this.restService
      .getJWT('informes/horeca/pie_chart/trabajadores_planeador_pedidos/' + this.id_horeca)
      .toPromise()
      .then((pedidos: any) => {
        pedidos.data.forEach((pedido: any) => {
          const obj_pedido_puntos_entrega = {
            punto_entrega: pedido.punto_nombre,
            distribuidor: pedido.distribuidor_nombre,
            usuario: pedido.trabajador,
            total: pedido.total,
          };
          this.total_pedidos_planeador = this.total_pedidos_planeador + pedido.total;
          /** Se arega objeto-fila a la tabla */
          this.data_pedidos_planeador.push(obj_pedido_puntos_entrega);
        });
        this.source_pedidos_planeador = new LocalDataSource(this.data_pedidos_planeador);
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /****************************************** Pedidos administrador *****************************************/
  /**
   * Configura los titulos y demas settings de la tabla
   * para recibir información de la base de datos
   */
  private configSmartTablePedidosAdministrador(): void {
    this.settings_pedidos_administrador = {
      pager: {
        display: true,
        perPage: 10,
      },
      actions: {
        columnTitle: 'Acción',
        add: false,
        edit: false,
        delete: false,
      },
      hideSubHeader: false,
      columns: {
        usuario: {
          title: 'Usuario',
          editable: false,
          filter: true,
        },
        punto_entrega: {
          title: 'Punto de entrega',
          editable: false,
          filter: true,
        },
        distribuidor: {
          title: 'Distribuidor',
          editable: false,
          filter: true,
        },
        total: {
          title: 'Total pedidos',
          editable: false,
          filter: true,
        },
      },
    };
  }
  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  private cargarDatosPedidosAdministrador(): void {
    this.restService
      .getJWT('informes/horeca/pie_chart/trabajadores_administrador_pedidos/' + this.id_horeca)
      .toPromise()
      .then((pedidos: any) => {
        pedidos.data.forEach((pedido: any) => {
          const obj_pedido_puntos_entrega = {
            punto_entrega: pedido.punto_nombre,
            distribuidor: pedido.distribuidor_nombre,
            usuario: pedido.trabajador,
            total: pedido.total,
          };
          this.total_pedidos_administrador = this.total_pedidos_administrador + pedido.total;
          /** Se arega objeto-fila a la tabla */
          this.data_pedidos_administrador.push(obj_pedido_puntos_entrega);
        });
        this.source_pedidos_administrador = new LocalDataSource(this.data_pedidos_administrador);
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /******************************** Pedidos realizados por puntos (3 meses) *******************************/
  public async cargarInformacionPuntosPedidos() {
    let total_puntos_pedidos = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/horeca/bar_chart/punto_entrega_pedidos/${this.id_horeca}`)
      .toPromise()
      .then((pedidos: any) => {
        pedidos = pedidos.data;
        pedidos.forEach((element: any) => {
          this.data_bar_puntos_pedidos.labels.push(element._id);
          this.data_bar_puntos_pedidos.datasets.push(element.total);
          total_puntos_pedidos = total_puntos_pedidos + element.total;
        });
        this.total_puntos_pedidos = total_puntos_pedidos;
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
    this.bar_chart_puntos_pedidos = new Chart(this.bar_puntos_pedidos.nativeElement, {
      type: 'bar',
      data: {
        labels: this.data_bar_puntos_pedidos.labels,
        datasets: [
          {
            label: 'Total pedidos en los ult. 3 meses por punto de entrega',
            backgroundColor: this.colors[0],
            data: this.data_bar_puntos_pedidos.datasets,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Cantidad de pedidos realizados',
            },
          },
          x: {
            title: {
              display: true,
              text: 'Puntos de entrega',
            },
          },
        },
      },
    });
  }
  /******************* Cantidad de distribuidores por punto de entrega ******************/
  public async cargarInformacionPuntosDistribuidores() {
    let total_punto_distribuidores = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/horeca/bar_chart/punto_entrega_distribuidores/${this.id_horeca}`)
      .toPromise()
      .then((puntos: any) => {
        puntos = puntos.data;
        puntos.forEach((element: any) => {
          this.data_bar_punto_distribuidores.labels.push(element._id);
          this.data_bar_punto_distribuidores.datasets.push(element.total);
          total_punto_distribuidores = total_punto_distribuidores + element.total;
        });
        this.total_punto_distribuidores = total_punto_distribuidores;
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
    this.bar_chart_punto_distribuidores = new Chart(this.bar_punto_distribuidores.nativeElement, {
      type: 'bar',
      data: {
        labels: this.data_bar_punto_distribuidores.labels,
        datasets: [
          {
            label: 'Cantidad de distribuidores por punto',
            backgroundColor: this.colors[0],
            data: this.data_bar_punto_distribuidores.datasets,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Cantidad de distribuidores',
            },
            beginAtZero: true,
            ticks: {
              stepSize: 1,
            },
          },
          x: {
            title: {
              display: true,
              text: 'Puntos de entrega',
            },
          },
        },
      },
    });
  }
  /******************************** Trabajadores por punto de entrega *******************************/
  public async cargarInformacionPuntosTrabajadores() {
    let total_puntos_trabajadores = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/horeca/bar_chart/punto_entrega_trabajadores/${this.id_horeca}`)
      .toPromise()
      .then((ventas_mes: any) => {
        ventas_mes = ventas_mes.data;
        ventas_mes.forEach((element: any) => {
          this.data_bar_puntos_trabajadores.labels.push(element._id);
          this.data_bar_puntos_trabajadores.datasets.push(element.total);
          total_puntos_trabajadores = total_puntos_trabajadores + element.total;
        });
        this.total_puntos_trabajadores = total_puntos_trabajadores;
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
    this.bar_chart_puntos_trabajadores = new Chart(this.bar_puntos_trabajadores.nativeElement, {
      type: 'bar',
      data: {
        labels: this.data_bar_puntos_trabajadores.labels,
        datasets: [
          {
            label: 'Trabajadores por punto de entrega',
            backgroundColor: this.colors[0],
            data: this.data_bar_puntos_trabajadores.datasets,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Número de trabajadores asignados',
            },
          },
          x: {
            title: {
              display: true,
              text: 'Puntos de entrega',
            },
          },
        },
      },
    });
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio']);
    };
  }
}
