import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { RestService } from 'src/app/services/rest/rest.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CrearTrabajadorComponent } from 'src/app/modal/crear-trabajador/crear-trabajador.component';
import { RegistrarMiembroOrganizacionComponent } from 'src/app/modal/registrar-miembro-organizacion/registrar-miembro-organizacion.component';

@Component({
  selector: 'app-trabajadores-organizaciones',
  templateUrl: './trabajadores-organizaciones.component.html',
  styleUrls: ['./trabajadores-organizaciones.component.css'],
})
export class TrabajadoresOrganizacionesComponent implements OnInit {
  trabajadores: any = [];
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private ngxsStore: Store,
    private rest: RestService
  ) {}

  detalleTrabajador(id: string) {
    this.router.navigate(['/detalle-trabajador-organizacion/', id]);
  }

  async ngOnInit() {
    const trabajadores: any = await this.rest
      .getJWT(`all_organizacion_trabajador/${this.ngxsStore.snapshot().auth.user_organizacion._id}`)
      .toPromise();
    trabajadores.forEach((element: any) => {
      if (element.solicitud_vinculacion != 'Rechazado') {
        this.trabajadores.push(element);
      }
    });
  }

  onCrearTrabajador() {
    this.modalService.open(RegistrarMiembroOrganizacionComponent, {
      centered: true,
      windowClass: 'modal-crear-miembro-org',
    });
  }
}
