<app-header-horeca></app-header-horeca>
<div id="main-container" class="p-5">
  <div class="row p-0">
    <div class="col-2">
      <button class="boton-volver" routerLink="/cuenta">
        <fa-icon [icon]="faChevronLeft"></fa-icon>
        Volver
      </button>
    </div>
    <div class="col-9 d-flex align-items-center" *ngIf="punto_entrega != undefined">
      <h2 class="m-0">{{ punto_entrega.nombre }}</h2>
    </div>
  </div>
  <div class="container" *ngIf="punto_entrega != undefined">
    <div [formGroup]="puntoForm">
      <br />
      <h6>Datos del punto de entrega</h6>
      <div class="form-Container">
        <!-- Nombre Punto -->
        <div class="formInputs">
          <span><span class="asterisco">*</span>Nombre punto de entrega</span>
          <input formControlName="puntoNombreEstablecimiento" type="text"
            [ngClass]="{'invalid-warning': ( puntoForm.get('puntoNombreEstablecimiento')?.hasError('required') && puntoForm.get('puntoNombreEstablecimiento')?.touched )}" />
        </div>
        <!-- Telefono Punto -->
        <div class="formInputs">
          <span><span class="asterisco">*</span>Teléfono (7 0 10 dígitos)</span>
          <input formControlName="puntoTelefono" type="text" (keydown)="validateNumber($event)"
            [ngClass]="{'invalid-warning': ( puntoForm.get('puntoTelefono')?.hasError('required') && puntoForm.get('puntoTelefono')?.touched ) || ( puntoForm.get('puntoTelefono')?.hasError('pattern') && puntoForm.get('puntoTelefono')?.touched )}" />
          <span class="w-100 text-danger fw-bold text-right"
            *ngIf="puntoForm.get('puntoTelefono')?.hasError('pattern') && puntoForm.get('puntoTelefono')?.touched">
            <span class="alerta">Númerico de 7 o 10 dígitos</span>
          </span>
        </div>
        <!-- Sillas Punto -->
        <div class="formInputs">
          <span><span class="asterisco">*</span>Número de sillas</span>
          <input formControlName="puntoSillas" type="number" (keydown)="validateNumber($event)"
            [ngClass]="{'invalid-warning': ( puntoForm.get('puntoSillas')?.hasError('required') && puntoForm.get('puntoSillas')?.touched )}" />
        </div>
        <!-- Domicilios Punto -->
        <div class="formInputs">
          <span><span class="asterisco">*</span>¿Servicio a domicilio?</span>
          <select formControlName="puntoDomicilio" name="select"
            [ngClass]="{'invalid-warning': ( puntoForm.get('puntoDomicilio')?.hasError('required') && puntoForm.get('puntoDomicilio')?.touched )}">
            <option [ngValue]=true>Sí ofrece domicilios</option>
            <option [ngValue]=false>No ofrece domicilios</option>
          </select>
        </div>
        <!-- Pais Punto -->
        <div class="formInputs">
          <span><span class="asterisco">*</span>País</span>
          <select name="pais" id="" formControlName="puntoPais"
            [ngClass]="{'invalid-warning': (puntoForm.get('puntoPais')?.hasError('required') && puntoForm.get('puntoPais')?.touched)}">
            <option value="Colombia" selected>Colombia</option>
          </select>
        </div>
        <!-- Departamento Punto -->
        <div class="formInputs">
          <span><span class="asterisco">*</span>Departamento</span>
          <select name="departamento" id="" formControlName="puntoDepartamento" (change)="getPlaces()"
            [ngClass]="{'invalid-warning': (puntoForm.get('puntoDepartamento')?.hasError('required') && puntoForm.get('puntoDepartamento')?.touched)}">
            <option *ngFor="let dpto of departamentos; index as i;" [ngValue]="dpto.name">
              {{ dpto.name }}
            </option>
          </select>
        </div>
        <!-- Ciudad Punto -->
        <div class="formInputs">
          <span><span class="asterisco">*</span>Ciudad</span>
          <select formControlName="puntoCiudad" name="select" (change)="buscarDireccion(true)"
            [ngClass]="{'invalid-warning': puntoForm.get('puntoCiudad')?.hasError('required') && puntoForm.get('puntoCiudad')?.touched }">
            <option *ngFor="let city of ciudades; index as i;" value={{city.name}}>
              {{ city.name }}
            </option>
          </select>
        </div>
        <!-- Direccion Punto -->
        <div class="formInputs">
          <span><span class="asterisco">*</span>Dirección</span>
          <input #editAddress formControlName="puntoDireccion" type="text"
            (keyup.enter)="enterMap()"
            [ngClass]="{'invalid-warning': ( puntoForm.get('puntoDireccion')?.hasError('required') && puntoForm.get('puntoDireccion')?.touched )}" />
        </div>
      </div>
      <h6><span class="asterisco">*</span>Confirma el punto en el mapa</h6>
      <div class="map">
        <agm-map
          #AgmMap
          fit="true"
          [latitude]="map.lat"
          [longitude]="map.lng"
          style="width: 100%; height: 15rem;"
          (mapReady)="mapReadyHandler($event)">
          <agm-marker [latitude]="map.lat" [longitude]="map.lng"></agm-marker>
        </agm-map>
      </div>
      <br />
      <h6>Horarios de atención</h6>
      <!-- Días Atención -->
      <h6><span class="asterisco">*</span>Días de atención</h6>
      <span class="w-100 pl-3 text-danger fw-bold text-right"
        *ngIf="validator_dia_seleccionado == false && puntoForm.get('puntoHorarios')?.touched">
        Seleccione al menos un día
      </span>
      <div class="checkBoxes">
        <mat-checkbox *ngFor="let item of dias; let i = index" [checked]="dias_atencion[i]" formControlName={{item}}
          (change)="getCheckbox(i)">
          {{item}}
        </mat-checkbox>
      </div>
      <!-- Horario -->
      <div class="row row-slider">
        <div class="col-12">
          <h6><span class="asterisco">*</span>Indica el horario</h6>
        </div>
        <div class="col-12 custom-slider">
          <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ngx-slider>
        </div>
        <div class="col-12">
          <p>
            {{ valueToTime(minValue) }} - {{ valueToTime(maxValue) }}
          </p>
        </div>
      </div>
      <br />
      <h6>Datos de los empleados</h6>
      <p class="pl-3 m-0 pt-2">Trabajador encargado</p>
      <div class="form-Container p-3">
        <!-- Nombre Encargado -->
        <div class="formInputs">
          <span>
            <span class="asterisco">*</span>Nombre
          </span>
          <input formControlName="puntoEmpleadoNombre" type="text"
            [ngClass]="{'invalid-warning': ( puntoForm.get('puntoEmpleadoNombre')?.hasError('required') && puntoForm.get('puntoEmpleadoNombre')?.touched )}"
            (keydown)="validateOnlyText($event)" />
        </div>
        <!-- Apellido Encargado -->
        <div class="formInputs">
          <span>
            <span class="asterisco">*</span>Apellido
          </span>
          <input formControlName="puntoEmpleadoApellido" type="text"
            [ngClass]="{'invalid-warning': ( puntoForm.get('puntoEmpleadoApellido')?.hasError('required') && puntoForm.get('puntoEmpleadoApellido')?.touched )}"
            (keydown)="validateOnlyText($event)" />
        </div>
        <!-- Correo Encargado -->
        <div class="formInputs">
          <span>
            <span class="asterisco">*</span>Correo
          </span>
          <input formControlName="puntoEmpleadoCorreo" type="text"
            [ngClass]="{'invalid-warning': (puntoForm.get('puntoEmpleadoCorreo')?.hasError('required') && puntoForm.get('puntoEmpleadoCorreo')?.touched) || puntoForm.get('puntoEmpleadoCorreo')?.hasError('email') && puntoForm.get('puntoEmpleadoCorreo')?.touched}" />
          <span class="reactive-text text-danger "
            *ngIf="puntoForm.get('puntoEmpleadoCorreo')?.hasError('email') && puntoForm.get('puntoEmpleadoCorreo')?.touched">
            <span class="alerta">Ingresa un email valido</span>
          </span>
        </div>
        <!-- Telefono Encargado -->
        <div class="formInputs">
          <span><span class="asterisco">*</span>Teléfono (7 o 10 dígitos)</span>
          <input formControlName="puntoEmpleadoCelular" type="text" (keydown)="validateNumber($event)"
            [ngClass]="{'invalid-warning': (puntoForm.get('puntoEmpleadoCelular')?.hasError('required') && puntoForm.get('puntoEmpleadoCelular')?.touched) || puntoForm.get('puntoEmpleadoCelular')?.hasError('pattern') && puntoForm.get('puntoEmpleadoCelular')?.touched}" />
          <span class="reactive-text text-danger"
            *ngIf="puntoForm.get('puntoEmpleadoCelular')?.hasError('pattern') && puntoForm.get('puntoEmpleadoCelular')?.touched">
            <span class="alerta">Númerico de 7 o 10 dígitos</span>
          </span>
        </div>
      </div>
      <p class="pl-3 m-0">Usuarios asignados a este punto</p>
      <div class="p-3">
        <div class="formInputs">
          <span>Usuario</span>
          <select name="select" class="seleccionar-trabajador mt-2 px-3 pt-1" multiple>
            <option *ngFor="let target of trabajadores; let i = index;" (click)="seleccionarTrabajador(target)">
              {{ target.nombres }} {{ target.apellidos }}</option>
          </select>
        </div>
      </div>
      <div class="d-flex flex-wrap pl-3 my-3">
        <div class="col-6 d-flex justify-content-between align-items-center"
          *ngFor="let target of trabajadores_asociados; index as i;">
          <p class="m-0 ">{{ target.nombres }} {{ target.apellidos }}</p>
          <button class="trash pl-2" (click)="trabajadores_asociados.splice(i, 1);">
            <fa-icon [icon]="faTrashAlt" id="fa-icon"></fa-icon>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button class="purple-button" (click)="!is_boton_habilitado && showAlert()"
            (click)="is_boton_habilitado && guardarCambios()"
            [style.background-color]="!is_boton_habilitado ? 'grey' : ''">
            Guardar cambios
          </button>
        </div>
      </div>
    </div>
    <!-- Activar/Desactivar punto -->
    <div>
      <p>Servicio a domicilio:&nbsp;<span> {{punto_entrega.domicilios ? 'Sí' : 'No'}}</span>
      </p>
      <div>
        <img src="../../assets/img/icons/icon-pin-map.png" alt="" srcset="" /><span>{{ punto_entrega.direccion }}</span>
      </div>
      <div>
        <fa-icon [icon]="faClock" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon><span>Horario: {{ horario_lbl
          }}</span>
      </div>
      <div class="estado-desactivar" *ngIf="punto_entrega.estado == 'Activo'" (click)="desactivarPunto()">
        <fa-icon [icon]="faTrashAlt" id="fa-icon"></fa-icon><span>Desactivar punto de entrega</span>
      </div>
      <div class="estado-activar" *ngIf="punto_entrega.estado == 'Desactivado'" (click)="activarPunto()">
        <fa-icon [icon]="faCheckCircle" id="fa-icon"></fa-icon><span>Activar punto de entrega</span>
      </div>
    </div>
  </div>
</div>