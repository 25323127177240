import { CurrencyPipe } from '@angular/common';
import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Producto } from 'src/app/models/producto.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { CargandoGenericoComponent } from '../cargando-generico/cargando-generico.component';
import { SimpleComponent } from '../simple/simple.component';
import { ConfirmacionComponent } from '../confirmacion/confirmacion.component';

@Component({
  selector: 'app-editar-promo',
  templateUrl: './editar-promo.component.html',
  styleUrls: ['./editar-promo.component.css'],
})
export class EditarPromoComponent implements OnInit, DoCheck {
  /** Para exponer las funciones de Number al template */
  public number = Number;
  /** Referencias a iconos FontAwesome para la UI */
  public faTimes = faTimes;
  public faTimesCircle = faTimesCircle;
  /** Flags para levantar errores de inventario */
  public err_max_unidades: boolean[] = [false, false, false];
  public err_min_unidades: boolean[] = [false, false, false];
  public err_inv_promo = false;
  /** Datos del formulario */
  public nombre_promo = '';
  public codigo_promo = '';
  public precio_promo?: any;
  public inventario_promo = 0;
  public inventario_maximo: any;
  /** Flag para habilitar el boton de editar promo */
  public btn_promo_enabled = false;

  /** Informacion de los productos que deben entrar por parametro */
  @Input() promo: any | undefined;
  @Input() prods: Producto[] = [];
  @Input() fotos: string[] = [];
  @Input() precios: any[] = [];
  @Input() max_inv: number[] = [];
  @Input() inventario_unidades: number[] = [];
  @Input() selfCreados: any;

  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private auth: AuthService,
    private currencyPipe: CurrencyPipe,
    private rest: RestService
  ) {}

  async ngOnInit() {
    if (this.promo) {
      this.prods = this.promo?.productos_promocion;
      const tmp_precios = [];
      const tmp_fotos = [];
      this.nombre_promo = this.promo.nombre;
      this.codigo_promo = this.promo.codigo_promo;
      this.precio_promo = this.promo.precios[0].precio_descuento;
      this.inventario_promo = this.promo.precios[0].inventario_unidad;
      for (const aux of this.prods) {
        tmp_fotos.push(aux.fotos?.[0] || '');
        tmp_precios.push(aux.precios?.[0]);
      }
      this.precios = tmp_precios;
      this.fotos = tmp_fotos;
    }
    const resp_prods: any = await this.rest.getJWT(`productoPromocion/${this.promo._id}`).toPromise();
    this.inventario_maximo = resp_prods.productos_promocion_inventario_unidades.map(
      (i: number) => i * this.inventario_promo
    );
  }

  ngDoCheck(): void {
    if (this.inventario_maximo) {
      let i = 0;
      /** Flag para señalar si hay algún inventario en 0 */
      let err_min = false;
      for (const precio_aux of this.precios) {
        /**
         * Revisa que las unidades no sean mayores a las que se pueden poner en saldo
         * Si se supera, se devuelve al maximo y se levanta un mensaje de alerta
         */
        if (this.inventario_unidades[i] * this.inventario_promo >= this.inventario_maximo[i]) {
          if (this.inventario_promo > 0) {
            this.inventario_unidades[i] = Math.floor(this.inventario_maximo[i] / this.inventario_promo);
          }
          this.err_max_unidades[i] = true;
        } else {
          this.err_max_unidades[i] = false;
        }
        /**
         * Revisa que haya al menos una unidad de cada producto
         * Si no, se levanta un error indicando esto y se pide al usuario que ponga al
         * menos una unidad o elimine el producto de la promoción
         */
        if (this.inventario_unidades[i] <= 0) {
          this.inventario_unidades[i] = 0;
          this.err_min_unidades[i] = true;
          err_min = true;
        } else {
          this.err_min_unidades[i] = false;
        }
        /** Actualizar número de cajas segun cuantas unidades se han puesto en saldo */
        if (this.precios[i].und_x_caja > 0) {
          this.precios[i].inventario_caja =
            Math.round((this.inventario_unidades[i] / this.precios[i].und_x_caja) * 100) / 100;
        } else {
          this.precios[i].inventario_caja = 0;
        }
        i++;
      }
      /** Ajusta el inventario de la promoción para que nunca sea menos de 0 */
      if (this.inventario_promo < 0) {
        this.inventario_promo = 0;
      }
      /** Revisa si se va a poner al menos una unidad */
      if (this.inventario_promo == 0) {
        this.err_inv_promo = true;
      } else {
        this.err_inv_promo = false;
      }
      /** Revisa que los datos del form sean validos */
      if (
        err_min ||
        this.nombre_promo == '' ||
        this.precio_promo == 0 ||
        this.precio_promo == undefined ||
        this.inventario_promo == 0
      ) {
        this.btn_promo_enabled = false;
      } else {
        this.btn_promo_enabled = true;
      }
    }
  }

  /**
   * Elimina el producto y su informacion cuya posicion en los
   * arreglos entra por parametro
   * @param index La posicion de la informacion del producto
   */
  deleteProd(index: number) {
    if (this.promo.estadoActualizacion === 'Aceptado') {
      if (this.prods.length === 1) {
        this.activarInactivarPromo('desactivar');
        return;
      }
    } else {
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        centered: true,
      };
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Alto ahí!';
      modalRef.componentInstance.msg = `Esta promoción ya está desactivada y no puedes dejarla sin productos`;
      modalRef.componentInstance.btn_msg = 'Entendido';
      modalRef.componentInstance.close_callback = () => {};
      return;
    }
    this.prods.splice(index, 1);
    this.fotos.splice(index, 1);
    this.precios.splice(index, 1);
    this.inventario_maximo.splice(index, 1);
    this.promo.productos_promocion_inventario_unidades.splice(index, 1);
    if (this.prods.length == 0) {
      this.close();
    }
  }

  /**
   * Cierra el modal y recarga la página de saldos y promociones
   * para poder reiniciar el arreglo de productos seleccionados.
   * Por como está hecho el proceso de pasar los datos de los
   * productos entre el modal y la tabla es necesario volver a
   * cargar
   */
  close() {
    this.activeModal.close();
    window.location.reload();
  }

  /**
   * Toma los datos del form, crea una nueva promoción, y
   * lo asocia al distribuidor que lo creó
   */
  async updatePromo() {
    /** Opciones para los modales de error y exito */
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    const modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    const id_dist = this.auth.user_distribuidor?._id || '';
    const nombre_dist = this.auth.user_distribuidor?.nombre || '';
    /** Nombres en string para la descripcion de la promocion */
    let nombres = this.prods[0].nombre;
    if (this.prods.length == 2) {
      nombres += `y ${this.prods[1].nombre}`;
    } else if (this.prods.length == 3) {
      nombres += `, ${this.prods[1].nombre}, y ${this.prods[2].nombre}`;
    }
    /** Arreglo de ids de los productos para asociar a la promocion */
    const prods_ids: string[] = [];
    for (const aux of this.prods) {
      prods_ids.push(aux._id || '');
    }
    /** Nueva promocion como producto */
    this.precio_promo = this.precio_promo.toString().replace(/[^\d,-]/g, '');
    const promo = new Producto(
      this.nombre_promo,
      `Promoción de ${nombres} por ${this.precio_promo}`,
      this.fotos,
      [
        {
          unidad_medida: undefined,
          cantidad_medida: undefined,
          estado: 'Disponible',
          precio_unidad: this.precio_promo,
          precio_caja: 0,
          precio_descuento: this.precio_promo,
          inventario_unidad: this.inventario_promo,
          inventario_caja: 0,
          puntos_ft_unidad: 0,
          puntos_ft_caja: 0,
          und_x_caja: 0,
        },
      ],
      undefined,
      undefined,
      true,
      false,
      undefined,
      id_dist,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      nombre_dist,
      prods_ids
    );
    promo.productos_promocion_inventario_unidades = this.inventario_unidades;
    try {
      /** Se actualiza la promocion */
      await this.rest.putJWT(`producto/${this.promo._id}`, promo).toPromise();
      /** Mensaje de exito por la carga de la promocion */
      modalCarga.close();
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../../assets/img/icon-check-verde.png';
      modalRef.componentInstance.title = '¡Genial!';
      modalRef.componentInstance.msg = 'La promoción fue actualizada en el sistema con éxito.';
      modalRef.componentInstance.btn_msg = 'Listo';
      modalRef.componentInstance.close_callback = () => {
        this.selfCreados.fetchData();
        this.activeModal.close();
      };
    } catch (err) {
      console.log(err);
      modalCarga.close();
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'No fue posible actualizar esta promoción. Por favor intenta de nuevo más tarde.';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {};
    }
  }

  /**************************************** Validadores ****************************************/
  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateNumber(evento: any) {
    const keyCode = evento.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || excludedKeys.includes(keyCode))) {
      evento.preventDefault();
    }
  }

  /**
   * Transforma el dinero minimo de compra de número a moneda y viceversa
   * a moneda se utilizará para reemplazar el input y dar mejor UX al usuario
   * y a número plano para guardar el dato correctamente en la base de datos
   * ademas de poder editar mejor el valor en el input
   */
  public transformCurrency(event: any) {
    this.precio_promo = event.value.replace(/[^\d,-]/g, '');
  }
  public transformAmount(event: any) {
    this.precio_promo = this.currencyPipe.transform(event.value, '$ ', 'symbol', '1.0-0');
  }

  cerrarModal() {
    this.activeModal.close();
  }

  activarInactivarPromo(opcion: string) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    const modalRef = this.modalService.open(ConfirmacionComponent, ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.msg = `¿Estás seguro de que deseas ${opcion} esta promoción?${
      opcion === 'desactivar' ? ' De hacerlo, podrás volver a activarla' : ''
    }`;
    modalRef.componentInstance.btn_msg_no = 'Cancelar';
    modalRef.componentInstance.btn_msg_yes = `Si, ${opcion}`;
    modalRef.componentInstance.callback_no = () => {};
    modalRef.componentInstance.callback_yes = () => {
      const body = {
        estadoActualizacion: opcion === 'desactivar' ? 'Inactivo' : 'Aceptado',
      };
      this.rest
        .putJWT('producto/' + this.promo._id, body)
        .toPromise()
        .then(() => {
          modalRef.close();
          const modalRefResp = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRefResp.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
          modalRefResp.componentInstance.title = '¡Genial!';
          modalRefResp.componentInstance.msg = `¡Tu promoción ha sido ${
            opcion === 'desactivar' ? 'desactivada' : 'Activada'
          } con éxito!`;
          modalRefResp.componentInstance.btn_msg = 'Listo';
          modalRefResp.componentInstance.close_callback = () => {
            this.selfCreados.fetchData();
            this.activeModal.close();
          };
        });
    };
  }
}
