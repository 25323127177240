export class GetTokens {
  static readonly type = '[Token] Get';
}

export class DeleteToken {
  static readonly type = '[Token] Delete';

  constructor() {}
}

export class SetSelectedToken {
  static readonly type = '[Token] Set';

  constructor(public payload: any) {}
}
