<div class="container-fluid overflow-hidden" id="banner">
  <div class="row pt-0 pt-sm-5">
    <div class="col-12 col-lg-6 contenedor-descripcion">
      <div>
        <img src="../../../assets/img/Landing/icono-negativo.png" id="logo-feat" alt="logo Feat">
      </div>
      <div>
        <h1 class="title-aliado">Somos tu aliado</h1>
      </div>
      <div class="mt-3 parrafo">
        <p>Conectamos establecimientos HoReCa's, Distribuidores y Organizaciones cumpliendo con sus principales
          necesidades</p>
      </div>
    </div>
    <div class="col-12 col-lg-6  container-img-banner">
      <img src="../../../assets/img/Landing/ilustracion1-landing.png" id="img-banner" alt="Banner del e-commerce">
    </div>
  </div>
</div>