<app-header-distribuidor></app-header-distribuidor>
<div id="inicio-grid" class="mt-4">
  <div class="perfil m-0 p-0 overflow-hidden">
    <app-inicio-perfil-dist></app-inicio-perfil-dist>
  </div>
  <div class="precios m-0 px-4 rounded row w-100 cursorPointer" routerLink="/comparar-precios-dist" id="saldos">
    <div class="col-2 p-0 d-flex justify-content-center align-items-center">
      <img class="logo-precios p-1" src="../../../assets/img/chequeo-precios.png" alt="Icono de moneda" />
    </div>
    <div class="col-10 p-3 card-body d-flex align-items-center rounded">
      <p class="m-0 main-title text-left">Chequeo de precios</p>
    </div>
  </div>
  <div class="comunidad m-0 p-0 rounded">
    <app-inicio-comunidad></app-inicio-comunidad>
  </div>
  <div class="indicadores m-0 p-0 rounded">
    <app-inicio-indicadores-dist></app-inicio-indicadores-dist>
  </div>
  <div class="top-productos m-0 p-0 rounded">
    <app-inicio-top-productos></app-inicio-top-productos>
  </div>
  <div class="informes m-0 p-0 rounded">
    <app-inicio-informes-dist></app-inicio-informes-dist>
  </div>
  <div id="banner-inicio" class=" banner carousel-container">
    <ngb-carousel *ngIf="banners">
      <ng-template ngbSlide *ngFor="let images of banners">
        <div class="picsum-img-wrapper d-flex justify-content-center align-items-center cursorPointer"
          (click)="goToLink(images.link)">
          <img src="{{images.imagen}}" alt="banner de {{images.titulo}}" class="img-banner">
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
</div>