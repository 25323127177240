<div id="content">
  <!-------------------- Pie Chart -------------------->
  <!-- Tipos de usuarios -->
  <div class="card usuarios-tipos">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Tipos de usuarios</div>
        <div class="d-flex justify-content-between">
          <span>Total: {{total_usuarios_tipos}}</span>
        </div>
      </div>
      <canvas #pie_usuarios_tipos [ngClass]="{'inactive': data_pie_usuarios_tipos.datasets.length == 0}"></canvas>
      <div *ngIf="data_pie_usuarios_tipos.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <!-- Estado de usuarios -->
  <div class="card usuarios-estados">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Estado de usuarios</div>
        <div class="d-flex justify-content-between">
          <span>Total: {{total_usuarios_estados}}</span>
        </div>
      </div>
      <canvas #pie_usuarios_estados [ngClass]="{'inactive': data_pie_usuarios_estados.datasets.length == 0}"></canvas>
      <div *ngIf="data_pie_usuarios_estados.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <!-------------------- Bar Chart -------------------->
  <!-- Ventas por usuarios -->
  <div class="card usuarios-ventas">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Ventas por usuario comercial</div>
        <div class="d-flex justify-content-between">
          <span>Total: {{total_usuarios_ventas}}</span>
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_usuarios_ventas"
            (ngModelChange)="getChartUsuariosVentas()">
            <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
              {{mes.filtro}}-{{mes.filtro_inicio}}
            </option>
          </select>
        </div>
      </div>
      <canvas #bar_usuarios_ventas [ngClass]="{'inactive': data_bar_usuarios_ventas.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_usuarios_ventas.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <!-- Establecimientos por usuario comercial -->
  <div class="card usuarios-puntos">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Establecimientos por usuario comercial</div>
        <div class="d-flex justify-content-between">
          <span>Total: {{total_usuarios_establecimientos}}</span>
        </div>
      </div>
      <canvas #bar_usuarios_establecimientos
        [ngClass]="{'inactive': data_bar_usuarios_establecimientos.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_usuarios_establecimientos.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <!--Número de referencias en pedidos  -->
  <div class="card usuarios-productos">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Número de referencias en pedidos</div>
        <div class="d-flex justify-content-between">
          <span>Promedio: {{total_usuarios_productos}}</span>
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_usuarios_productos"
            (ngModelChange)="getChartUsuariosProductos()">
            <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
              {{mes.filtro}}-{{mes.filtro_inicio}}
            </option>
          </select>
        </div>
      </div>
      <canvas #bar_usuarios_productos
        [ngClass]="{'inactive': data_bar_usuarios_productos.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_usuarios_productos.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <!-- Número de pedidos -->
  <div class="card usuarios-pedidos">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Número de pedidos</div>
        <div class="d-flex justify-content-between">
          <span>Total: {{total_usuarios_pedidos}}</span>
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_usuarios_pedidos"
            (ngModelChange)="getChartUsuariosPedidos()">
            <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
              {{mes.filtro}}-{{mes.filtro_inicio}}
            </option>
          </select>
        </div>
      </div>
      <canvas #bar_usuarios_pedidos [ngClass]="{'inactive': data_bar_usuarios_pedidos.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_usuarios_pedidos.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <!-- # de sillas alcazadas por usuario comercial -->
  <div class="card usuarios-sillas">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Número de sillas alcanzadas por usuario comercial</div>
        <div class="d-flex justify-content-between">
        </div>
      </div>
      <canvas #bar_usuarios_sillas [ngClass]="{'inactive': data_bar_usuarios_sillas.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_usuarios_sillas.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <!-- Manejo de errores -->
  <ng-template #mostrarError>
    <div class="d-flex justify-content-center mt-4">
      <div>
        <img src="assets/img/icon-warning-amarillo.png" alt="icono de una hoja de papel y una lupa sobre ella"
          style="max-width: 150px;" />
      </div>
    </div>
    <div class="mt-2">
      <p class="text-center">
        ¡Lo siento! No existen datos para ver aquí
      </p>
    </div>
  </ng-template>
</div>