import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-planes-card',
  templateUrl: './planes-card.component.html',
  styleUrls: ['./planes-card.component.css'],
})
export class PlanesCardComponent implements OnInit {
  @Input() isActual = false;
  @Input() clickable = false;
  @Input() plan: any;

  constructor() {}

  ngOnInit(): void {
    if (this.isActual) {
      this.clickable = false;
    }
  }

  public getBackground(color: string): string {
    const tColor = color + '55';
    return `transparent linear-gradient(180deg, ${tColor} 0%, ${color} 100%) 0% 0% no-repeat padding-box`;
  }

  public getHeaderColor(color: string): string {
    return color;
  }

  public getMarcoColor(color: string): string {
    return color + '55';
  }
}
