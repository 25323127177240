import { Component, OnInit } from '@angular/core';
import { faChevronLeft, faCamera } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chat-distribuidores-organizacion',
  templateUrl: './chat-distribuidores-organizacion.component.html',
  styleUrls: ['./chat-distribuidores-organizacion.component.css'],
})
export class ChatDistribuidoresOrganizacionComponent implements OnInit {
  constructor(private router: Router) {}
  faChevronLeft = faChevronLeft;
  faCamera = faCamera;
  ngOnInit(): void {}

  onDistribuidores() {
    this.router.navigate(['/distribuidores-organizacion']);
  }

  mensajes = [
    {
      fecha: '20 mayo de 2021, 3:00 pm',
      nombre: 'Bibiana',
      mensaje:
        'Hola Lorena, Quisiéramos comentarte que hay que hacer unos cambios en tu pedido así que adjuntamos un posible cambio para que nos confirmes si así está bien',
      accion: 'get',
    },
    {
      fecha: '20 mayo de 2021, 3:30 pm',
      nombre: 'Tú',
      mensaje:
        'Hola Lorena, Quisiéramos comentarte que hay que hacer unos cambios en tu pedido así que adjuntamos un posible cambio para que nos confirmes si así está bien',
      accion: 'post',
    },
  ];
}
