// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  gmaps_key: 'AIzaSyAIDSFRWmo4yNVf9hE4Yhk4C93SeBTsqwo',
  backendUrl: 'https://develop-feat.imagineapps.co',
  backendAPIUrl: 'https://develop-feat.imagineapps.co/api',
  urlSocketChat: 'https://develop-feat.imagineapps.co',
};
