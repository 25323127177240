import { CargarProductoExcelComponent } from 'src/app/modal/cargar-producto-excel/cargar-producto-excel.component';
import { Component, OnInit } from '@angular/core';
import { faChevronLeft, faCircle, faSearch, faShoppingCart, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { CarritoService } from 'src/app/services/carrito/carrito.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ProductsService } from 'src/app/services/products/products.service';

import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { DetalleProductoFlotanteComponent } from 'src/app/modal/detalle-producto-flotante/detalle-producto-flotante.component';
import { ConfirmacionComponent } from 'src/app/modal/confirmacion/confirmacion.component';

import * as moment from 'moment';
@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.css'],
})
export class CatalogoComponent implements OnInit {
  stBusq: any;
  ordenActual = 'asc'
  filtro_seleccionados = '';
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;
  //Imagen producto placeholder
  public product_placeholder = '../../assets/img/product-placeholder.png';
  public distribuidor_placeholder = '../../assets/img/icon-organizacion.png';
  // Data del distribuidor
  public id = '';
  public distribuidor: any = {};
  // Referencias a íconos FontAwesome para la UI
  public faStar = faStar;
  public faChevronLeft = faChevronLeft;
  public faCircle = faCircle;
  public faSearch = faSearch;
  public faTrash = faTrash;
  public faShoppingCart = faShoppingCart;
  //Data del pedido
  public order: any;
  //Guardamos dinamicamente los valores de autocompletado
  public filteredOptions: any;
  public mySearch = new FormControl();
  public searchTerm = '';
  // Variables de filtrado
  public lineas_productos_estado: boolean[] = [];
  public categorias_filtro_distribuidores: any[] = [];
  // Guarda los distribuidores sin filtrar
  public buffer_productos: any = [];
  // Nombre a buscar entre los productos y sus resultados
  public prods_filtrados: any[] = [];
  // Funcionalidad de filtros por categoría y líneas
  public buffer_categorias_productos: any[] = [];
  public categorias_productos: any[] = [];
  public categorias_productos_seleccionadas: any[] = [];
  public categorias_productos_estados: any[] = [];
  public categorias_productos_estados_todos = true;
  public buffer_lineas_productos: any[] = [];
  public lineas_productos: any[] = [];
  public lineas_productos_estado_todos = true;
  public lineas_productos_actuales_distribuidor: any[] = [];
  // Guarda las organizaciones asociadas a los productos
  public organizaciones: string[] = [];
  // Total productos del catalogo
  public total_productos_catalogo = 0;
  // Modal generíco cargando...
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
  };
  prodPendientes: any;
  prodAceptados: any;
  prodPendiente: any;
  prodRechazados: any;
  copyDataProductos: any;
  dataConstruccion: any;
  dataProductosAceptados: any;
  lineaSeleccionada: string | undefined;
  categoriaSeleccionada = '0';
  lineasMaster: any;
  seleccionCat!: { id: any; pos: any; data: any };
  totalSaldosPromociones = 0;

  constructor(
    private route: ActivatedRoute,
    private cartservice: CarritoService,
    private rest: RestService,
    private auth: AuthService,
    private prodService: ProductsService,
    private router: Router,
    public locallStorage: LocalStorageService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.productosDistribuidorLogin();
    this.saldosPromocionesDistribuidor();
    //this.fetchProductosDistribuidor();
  }
  ngDoCheck(): void {
    if(this.stBusq){
      this.gen(this.stBusq);
    }

  }
  gen(st: any) {
    if(st.length > 0){
      this.prods_filtrados = this.filterByValue(this.copyDataProductos, st);
    }else{
      this.seleccionarCategoria('0', -1);
      this.categoriaSeleccionada = '0'
    }




    this.prods_filtrados = this.filterByValue(this.copyDataProductos, st);
  }
  filterByValue(array: any, string: any) {
    return array.filter(
      (o: any) =>
        o.nombre.toLowerCase().includes(string.toLowerCase()) ||
        o.codigo_distribuidor_producto.toLowerCase().includes(string.toLowerCase())
    );
  }
  async goAggProductManual() {
    // eslint-disable-next-line prefer-const
    let validador = await this.validatePermissions();
    if (validador) {
      this.router.navigate(['/portafolio/agregar-producto']);
    }
  }
  async validatePermissions() {
    // eslint-disable-next-line prefer-const
    let blockUser = await this.auth.validatePermissionsUser();
    return blockUser;
  }
  /************************** Productos distribuidor logueado *************************/
  /**
   * Traerlos productos asociados al distribuidor logueado
   */
  public detalleFlotante(producto: any, pos: any) {
    /****** Modal Generico cargando *****/
    const ngbModalOptions: NgbModalOptions = {
      //backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'xl', //xl
    };
    const modalRef = this.modalService.open(DetalleProductoFlotanteComponent, ngbModalOptions);
    modalRef.componentInstance.dataProducto = producto;
    modalRef.componentInstance.posProd = pos;
    modalRef.componentInstance.close_callback = (respModal: any, posProd: any) => {
      if (posProd && respModal) {
        this.prods_filtrados[posProd] = respModal;
      }
    };
  }
  async fetchProductosDistribuidor() {
    const current_date = new Date(moment.utc().format('DD MMMM YYYY'));
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    /**
     * Recupera los productos del distribuidor
     */
    this.rest
      .getJWT(`productoGeneralDistribuidor/${this.auth.user_distribuidor?._id}`)
      .toPromise()
      .then((productos_data: any) => {
        // Se filtran los saldos y promociones del catalogo
        productos_data = productos_data.filter((producto: any) => {
          return producto.promocion === false && producto.saldos === false;
        });
        // Flag para mostrar puntos feat si estan dentro del rango de fechas
        productos_data.forEach((producto: any) => {
          producto.flag_aplica_puntos_feat = false;
          if (
            producto.fecha_apertura_puntosft &&
            producto.fecha_cierre_puntosft &&
            new Date(moment.utc(producto.fecha_apertura_puntosft).format('DD MMMM YYYY')) <= current_date &&
            new Date(moment.utc(producto.fecha_cierre_puntosft).format('DD MMMM YYYY')) >= current_date
          ) {
            producto.flag_aplica_puntos_feat = true;
          }
        });
        /**
         * Si no hay data, no ejecuta ninguna función
         */
        if (productos_data === null) {
          this.modalCarga?.close();
          return;
        }
        this.prods_filtrados = productos_data;
        this.buffer_productos = productos_data;
        // Total productos del catalogo
        this.total_productos_catalogo = this.buffer_productos.filter((element: any) => {
          return element.promocion === false && element.saldos === false;
        }).length;
        /**
         * Se ejecutan los metodos para cargar funcionalidades de filtros y busqueda
         */
        this.autoCompletadoBusqueda();
        this.fetchCategoriasYLineas();
        /**
         * Se recuperan las lineas de producto disponibles del distribuidor
         * estas solo contienen el ID de la linea, por lo que será el input
         * para filtrar en el arrya de objetos de lineas de productos los que coincidan
         * luego se eliminan los repetidos
         */
        this.prods_filtrados.forEach((element: any) => {
          if (element.linea_producto[0]) {
            this.lineas_productos_actuales_distribuidor.push(element.linea_producto[0]);
          }
        });
        this.lineas_productos_actuales_distribuidor = [...new Set(this.lineas_productos_actuales_distribuidor)];
        this.modalCarga?.close();
      })
      .catch(() => {
        const modalRef = this.modalService.open(SimpleComponent);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'No fue posible recuperar la información, intentalo de nuevo mas tarde';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {};
      });
  }
  /**
   * Metodo para eliminar producto
   * @param prod Data de producto seleccionado
   */
  async eliminarProducto(prod: any, pos: any){
    const modalRef = this.modalService.open(ConfirmacionComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-alert-amarillo.png';
    modalRef.componentInstance.title =
      `¿Estás seguro de eliminar el producto: '${prod.nombre}'?`;
    modalRef.componentInstance.msg =
      'Después de la eliminarlo no podrás recuperarlo.';
    modalRef.componentInstance.btn_msg_no = 'Cancelar';
    modalRef.componentInstance.btn_msg_yes = 'Eliminar';
    modalRef.componentInstance.callback_no = () => {};
    modalRef.componentInstance.callback_yes = () => {
      this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
      this.elimiarProd(prod, pos);
    };
  }
  public elimiarProd(producto:any, pos: any){
     
      this.prodService
      .deleteProduct(`api/eliminar_solicitud_producto/${producto._id}`)
      .toPromise()
      .then(async (response_delete: any) => {
        const modalRef = this.modalService.open(SimpleComponent);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
        modalRef.componentInstance.title = '¡Felicidades!';
        modalRef.componentInstance.msg = 'El producto fue eliminado.';
        modalRef.componentInstance.btn_msg = 'Listo';
        modalRef.componentInstance.close_callback = () => {
          //this.productosDistribuidorLogin();
          //this.saldosPromocionesDistribuidor();
          this.modalCarga?.close();
          //this.filtrarObj('Pendiente');

          this.prods_filtrados.splice(pos,1);

        };
      })
      .catch(() => {
        const modalRef = this.modalService.open(SimpleComponent);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'No fue posible eliminar el producto, inténtalo de nuevo más tarde.';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {};
      });
  }
  /************************** Busqueda autocompletado *************************/
  /**
   * Este metodo tiene como objetoautocompletar la busqueda del usuario
   */
  autoCompletadoBusqueda() {
    this.filteredOptions = this.mySearch.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.nombre)),
      map((nombre) => (nombre ? this._filter(nombre) : this.prods_filtrados.slice()))
    );
  }

  displayFn(user: any) {
    return user ? user.nombre : undefined;
  }

  returnFn(user: any) {
    return user ? user.value : undefined;
  }

  itemDisplayFn(item: any) {
    return item ? item.name : '';
  }

  private _filter(nombre: string) {
    const filterValue = this.normalizeString(nombre.toLowerCase());
    return this.prods_filtrados.filter((option: any) =>
      this.normalizeString(option.nombre).toLowerCase().includes(filterValue)
    );
  }

  clearSearch() {
    this.searchTerm = '';
  }

  /**
   * Estos metodos tienen como objeto ver el detalle de los distribuidores seleccionado en el buscador
   */
  verDetalleProducto(event: any) {
    const filtrado = event.option.value._id;
    this.router.navigate(['/portafolio/editar-producto/', filtrado]);
  }

  /**
   * Toma el string que entra por parámetro y cambia tildes y diéresis
   * por las letras sin acento, y lo pasa a minúsculas
   * @param pWord El string a filtrar
   * @returns El string filtrado
   */
  normalizeString(pWord: string): string {
    return pWord
      .trim()
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .toLowerCase();
  }

  /******************** Filtros por categoría y linea producto *******************/
  /**
   * Recupera las categorías y lineas de productos activas del distribuidor
   */
  async fetchCategoriasYLineas() {
    /********************* Categorías ********************/
    const resp: any = await this.rest
      .getJWT(`categorias_productos_generales_distribuidor/distribuidor/${this.auth.user_distribuidor?._id}`)
      .toPromise();
    // Elimina los objetos repetidos
    this.categorias_productos = resp.filter((value: any, index: any) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        resp.findIndex((obj: any) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });
    // Ordenar las categorías por orden alfabético
    this.categorias_productos = this.categorias_productos.sort(function (a: any, b: any) {
      if (a.nombre === undefined) return 1;
      if (b.nombre === undefined) return -1;
      if (a.nombre === b.nombre) return 0;
      return a.nombre.toLowerCase() < b.nombre.toLowerCase() ? -1 : 1;
    });
    // Guarda un buffer para la funcionalidad de filtros
    this.buffer_categorias_productos = this.categorias_productos;
    /**
     * Crea un array con el estado de las categorias de productos.
     * True (filtro activo) o False (filtro NO activo) Todas inician en false
     */
    this.categorias_productos.forEach(() => {
      this.categorias_productos_estados.push(false);
    });
    /********************* Líneas ********************/
    const resp_lineas: any = await this.rest
      .getJWT(`lineas_productos_generales_distribuidor/distribuidor/${this.auth.user_distribuidor?._id}`)
      .toPromise();
    // Elimina los objetos repetidos
    this.lineas_productos = resp_lineas.filter((value: any, index: any) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        resp_lineas.findIndex((obj: any) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });
    this.lineas_productos = this.lineas_productos.sort(function (a: any, b: any) {
      if (a.nombre === undefined) return 1;
      if (b.nombre === undefined) return -1;
      if (a.nombre === b.nombre) return 0;
      return a.nombre.toLowerCase() < b.nombre.toLowerCase() ? -1 : 1;
    });
    // Guarda un buffer para la funcionalidad de filtros
    this.buffer_lineas_productos = this.lineas_productos;
    // Recupera las lineas de productos
    this.handleLineasProductos();
  }

  /**
   * Este metodo tiene como objeto organizar los filtros por categorías
   * @param click_filtro_todos de darse click en el filtro Todos, reinicia todas las categorías
   */
  handleClickCategoria(click_filtro_todos: boolean) {
    /** Carga todas las categorías disponibles y deselecciona todas las categorias */
    this.categorias_productos = this.buffer_categorias_productos;
    this.categorias_productos_seleccionadas = [];
    /**
     * Verifica Si está seleccionada la categoría dentro de los filtros aplicados
     * si lo está, la agrega al array de seleccionados, si no, no la toma en cuenta
     */
    for (let index = 0; index < this.categorias_productos_estados.length; index++) {
      if (this.categorias_productos_estados[index] === true) {
        this.categorias_productos_seleccionadas.push(this.categorias_productos[index]);
      }
    }
    /*************** Verifica si existen filtros aplicados ***************/
    if (this.categorias_productos_seleccionadas.length == 0 || click_filtro_todos == true) {
      /********************* NO hay filtros aplicados ********************/
      // Garantiza todas las categorias deseleccionadas y "Todos" activo
      this.categorias_productos_estados_todos = true;
      for (let index = 0; index < this.categorias_productos_estados.length; index++) {
        this.categorias_productos_estados[index] = false;
      }
      // Todas las categorias disbonibles y seleccionadas
      this.categorias_productos_seleccionadas = this.categorias_productos;
    } else {
      /********************* SI hay filtros aplicados ********************/
      this.categorias_productos_estados_todos = false;
    }
    /****** Organiza nuevamente las lineas de productos disponibles ******/
    /**
     * Crea un array con el estado de la selección de filtros de lineas
     * de productos. Todas inician en false, es decir, se mostrarán todas
     * el estado es true (filtro activo) o false (filtro NO activo)
     */
    for (let index = 0; index < this.lineas_productos_estado.length; index++) {
      this.lineas_productos_estado[index] = false;
    }
    this.handleLineasProductos();
  }

  /**
   * Organiza las lineas de productos de las categorías
   * Esta función reorganiza la lineas de productos cada vez que se cambian las categorías
   * Solo se mostrarán las linea de las categorías seleccionadas
   */
  handleLineasProductos() {
    this.lineas_productos = [];
    // Si no hay categorías seleccionadas, las muestra todas
    if (this.categorias_productos_seleccionadas.length == 0) {
      this.categorias_productos_seleccionadas = this.categorias_productos;
    }
    // Crea un array con todas las lineas de productos disponibles de las categorias seleccionadas
    const lineas_productos_disponibles_por_categoria: any[] = [];
    for (const categoria of this.categorias_productos_seleccionadas) {
      categoria.lineas_producto.forEach((element: any) => {
        lineas_productos_disponibles_por_categoria.push(element);
      });
    }
    /**
     * Filtra dejando solo las lineas de productos que SI están disponibles en el distribuidor.
     * Hay mas de 100, por lo que se realiza este filtro para mejorar la experiencia del usuario.
     * De las lineas disponbiles del distribuidor, se filtran según las las categorías seleccionadas.
     */
    this.lineas_productos = this.buffer_lineas_productos.filter((el: any) => {
      return lineas_productos_disponibles_por_categoria.some((f: any) => {
        return f == el._id;
      });
    });
    /**
     * Crea un array con el estado de la selección de filtros de lineas de productos.
     * Todas inician en false. Opciones: True (filtro activdo) o False (filtro NO activo).
     */
    this.lineas_productos.forEach(() => {
      this.lineas_productos_estado.push(false);
    });
    this.lineasMaster = this.lineas_productos;
    /** Ya establecido los filtros se muestran los productos según aplique */
    this.handleClickLineaProducto(false);
  }
  /**
   * Este metodo tiene como objeto filtrar los productos por linea de producto
   * @param click_filtro_todos de darse click en el filtro Todos, habilita todas
   * las lineas de producto disponibles y muestra toda la data respectiva
   */
  handleClickLineaProducto(click_filtro_todos: boolean) {
    /** Reinicia el array de productos sin filtro */
    this.prods_filtrados = this.buffer_productos;
    /** Crea un array con las categorías de filtro seleccionadas */
    let lineas_productos_seleccionadas: any = [];
    for (let index = 0; index < this.lineas_productos_estado.length; index++) {
      if (this.lineas_productos_estado[index] === true) {
        lineas_productos_seleccionadas.push(this.lineas_productos[index]);
      }
    }
    /**************** Verifica si existen filtros aplicados ***************/
    if (lineas_productos_seleccionadas.length === 0 || click_filtro_todos === true) {
      /********************** NO hay filtros aplicados *********************/
      /** Reinicia botones y habilita todas las lineas para mostrar todos los productos */
      this.lineas_productos_estado_todos = true;
      this.lineas_productos_estado = this.lineas_productos_estado.map((element: boolean) => (element = false));
      lineas_productos_seleccionadas = this.lineas_productos;
    } else {
      /********************** SI hay filtros aplicados *********************/
      this.lineas_productos_estado_todos = false;
    }
    /******************************** Filtro *******************************/
    /** Filtra los productos según las lineas de productos seleccionadas */
    this.prods_filtrados = this.prods_filtrados.filter((el: any) => {
      return lineas_productos_seleccionadas.some((f: any) => {
        return f._id == el.linea_producto[0];
      });
    });
  }

  /********************************* Otras funcionalidas ********************************/
  /**
   * Lanza el modal que se encarga de recibir el archivo
   * de Excel con los productos, las imágenes correspondientes,
   * y toda la lógica necesaria para la creación de estos productos
   */
  async cargarProductosDesdeExcel() {
    // eslint-disable-next-line prefer-const
    let validador = await this.validatePermissions();
    if (validador) {
      this.modalService.open(CargarProductoExcelComponent, this.ngbModalOptions);
    }
  }
  /**
   * Alerta no puede hacer pedidos por estar en cartera
   */
  showAlertaEnCartera() {
    const modalRef = this.modalService.open(SimpleComponent);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg =
      'No puedes realizar pedidos porque tienes pagos pendientes con este distribuidor. Comunícate con el distribuidor para que puedas volver a crear pedidos.';
    modalRef.componentInstance.btn_msg = 'Volver';
  }
  /**
   * Nuevos Metodos de ordenar y filtrar
   */
  async productosDistribuidorLogin() {
    const current_date = new Date(moment.utc().format('DD MMMM YYYY'));
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    /**
     * Recupera los productos del distribuidor
     */
    this.rest
      .getJWT(`productoGeneralDistribuidor/${this.auth.user_distribuidor?._id}`)
      .toPromise()
      .then(async (productos_data: any) => {
        // Se filtran los saldos y promociones del catalogo
        productos_data = productos_data.filter((producto: any) => {
          return producto.promocion === false && producto.saldos === false;
        });
        // Se filtran los saldos y promociones del catalogo
        const prodPendientes = productos_data.filter((producto: any) => {
          return producto.estadoActualizacion === 'Pendiente';
        });
        // Se filtran los saldos y promociones del catalogo
        const prodRechazados = productos_data.filter((producto: any) => {
          return producto.estadoActualizacion === 'Rechazado' || producto.estadoActualizacion === 'Inactivo';
        });
        // Se filtran los saldos y promociones del catalogo
        const prodAceptados = productos_data.filter((producto: any) => {
          return producto.estadoActualizacion === 'Aceptado';
        });
        this.dataProductosAceptados = prodAceptados;
        this.prodAceptados = prodAceptados.length;
        this.prodPendiente = prodPendientes.length;
        this.prodRechazados = prodRechazados.length;
        this.dataConstruccion = this.prodAceptados;
        /**
         * Total organizaciones asociadas a los productos
         */
        this.dataProductosAceptados.forEach((element: any) => {
          if (!this.organizaciones.includes(element.codigo_organizacion)) {
            this.organizaciones.push(element.codigo_organizacion);
          }
        });
        // Flag para mostrar puntos feat si estan dentro del rango de fechas
        productos_data.forEach((producto: any) => {
          producto.flag_aplica_puntos_feat = false;
          if (
            producto.fecha_apertura_puntosft &&
            producto.fecha_cierre_puntosft &&
            new Date(moment.utc(producto.fecha_apertura_puntosft).format('DD MMMM YYYY')) <= current_date &&
            new Date(moment.utc(producto.fecha_cierre_puntosft).format('DD MMMM YYYY')) >= current_date
          ) {
            producto.flag_aplica_puntos_feat = true;
          }
        });
        /**
         * Si no hay data, no ejecuta ninguna función
         */
        if (productos_data === null) {
          this.modalCarga?.close();
          return;
        }
        this.prods_filtrados = productos_data;
        this.copyDataProductos = productos_data;
        await this.generarCategorias_lineas();
        await this.autoCompletadoBusqueda();
        this.modalCarga?.close();
      })
      .catch(() => {
        /*const modalRef = this.modalService.open(SimpleComponent);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'No fue posible recuperar la información, intentalo de nuevo mas tarde';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {};*/
      });
  }
  generarCategorias_lineas() {
    // this.dataConstruccion
    // eslint-disable-next-line prefer-const
    let categoriasFiltradas: any[] = [];
    // eslint-disable-next-line prefer-const
    let lineasFiltradas: any[] = [];
    for (const prod of this.dataProductosAceptados) {
       
      if(prod.categoria_producto){
 // eslint-disable-next-line prefer-const
 let i = categoriasFiltradas.findIndex((e) => e._id === prod.categoria_producto._id);
 if (i === -1) {
   categoriasFiltradas.push(prod.categoria_producto);
 }
 // eslint-disable-next-line prefer-const
 let j = lineasFiltradas.findIndex((e) => e._id === prod.linea_producto[0]._id);

 if (j === -1) {
   lineasFiltradas.push(prod.linea_producto[0]);
 }
      }
    }
    // Ordenar las categorías por orden alfabético
    this.categorias_productos = categoriasFiltradas.sort(function (a: any, b: any) {
      if (a.nombre === undefined) return 1;
      if (b.nombre === undefined) return -1;
      if (a.nombre === b.nombre) return 0;
      return a.nombre.toLowerCase() < b.nombre.toLowerCase() ? -1 : 1;
    });
    //this.categorias_productos = categoriasFiltradas;
    this.lineas_productos = lineasFiltradas;
    this.lineasMaster = lineasFiltradas;
  }
  ordenarPorNombre(orden: 'asc' | 'desc') {
    this.prods_filtrados.sort((a, b) => {
      const nombreA = a.nombre.toLowerCase();
      const nombreB = b.nombre.toLowerCase();
      if (orden === 'asc') {
        return nombreA.localeCompare(nombreB);
      } else {
        return nombreB.localeCompare(nombreA);
      }
    });
  }
  filtrarObj(filtro: string) {
    this.prods_filtrados = this.copyDataProductos;
    if(filtro === 'puntos'){
      this.categoriaSeleccionada = '0';
      this.lineaSeleccionada = '0';
      this.lineas_productos = this.lineasMaster;
      this.prods_filtrados = this.prods_filtrados.filter((producto: any) => {
        return producto.mostrarPF === true;
      });
    }else{
      if (filtro === 'Todos') {
        this.prods_filtrados = this.copyDataProductos;
      } else {
        this.categoriaSeleccionada = '0';
        this.lineaSeleccionada = '0';
        this.lineas_productos = this.lineasMaster;
        this.prods_filtrados = this.prods_filtrados.filter((producto: any) => {
          if (filtro === 'Rechazado') {
            return producto.estadoActualizacion === filtro || producto.estadoActualizacion === 'Inactivo';
          } else {
            return producto.estadoActualizacion === filtro;
          }
        });
      }
    }

  }
  seleccionarLinea(id: string, pos: number) {
    /*if (pos > -1) {
      this.categoriaSeleccionada = this.lineas_productos[pos].categoria;
    }*/
    this.lineaSeleccionada = id;
    this.seleccionarCategoria(this.seleccionCat.id, this.seleccionCat.pos);
    if (id === '0') {
      //this.prods_filtrados = this.copyDataProductos;
      this.prods_filtrados = this.seleccionCat.data;
    } else {
      this.prods_filtrados = this.dataProductosAceptados.filter((producto: any) => {
        return producto.linea_producto[0]._id === id;
      });
    }
  }
  seleccionarCategoria(id: any, pos: number) {
    this.lineas_productos = this.lineasMaster;
    if (id === '0') {
      this.categoriaSeleccionada = '0';
      this.prods_filtrados = this.copyDataProductos;
      // Elimina los objetos repetidos
    } else {
      this.categoriaSeleccionada = id._id;
      const lineasHijas = [];
      // eslint-disable-next-line prettier/prettier, @typescript-eslint/no-unused-vars
      for (const cat of id.lineas_producto) {
        // eslint-disable-next-line prefer-const
        for (let linea of this.lineas_productos) {
          if (linea._id === cat) {
            lineasHijas.push(linea);
          }
        }
      }
      this.lineas_productos = lineasHijas;
      this.prods_filtrados = this.dataProductosAceptados.filter((producto: any) => {
        return producto.categoria_producto._id === this.categoriaSeleccionada;
      });
    }
    this.seleccionCat = { id: id, pos: pos, data: this.prods_filtrados };
  }
  redondearIndicador(numero: number) {
    return Math.round(numero);
  }
  calcularPrecioCaja(producto: any) {
    let precioDescuento =
      producto?.precios[0]?.precio_unidad - producto?.precios[0]?.precio_unidad * (producto?.prodPorcentajeDesc / 100);
    precioDescuento = Math.round(precioDescuento);
    return precioDescuento * producto?.precios[0]?.und_x_caja;
  }
  saldosPromocionesDistribuidor() {
    this.rest
      .getJWT(`saldos_promos_por_distribuidor/distribuidor/${this.auth.user_distribuidor?._id}`)
      .toPromise()
      .then((promo: any) => {
        if (promo.data?.productos) {
          this.totalSaldosPromociones = promo.data?.productos?.length;
        }
      });
  }
}
