<div class="row">
  <div class="col-12">
    <h4>Indicadores de tus clientes</h4>
  </div>
  <div class="col-12 d-flex flex-row">
    <div class="number-card rounded mr-4">
      <p class="main-title">
        {{ estado_pendiente || 0 }}
      </p>
      <p class="sub-title">Pendientes</p>
    </div>
    <div class="number-card rounded mr-4">
      <p class="main-title">
        {{ estado_aprobado || 0 }}
      </p>
      <p class="sub-title">Aprobados / Automáticos</p>
    </div>
    <div class="number-card rounded mr-4">
      <p class="main-title">
        {{ estado_cancelado || 0 }}
      </p>
      <p class="sub-title">Rechazados</p>
    </div>
  </div>
  <div class="col-12 d-flex flex-row vew-responsive">
    <div class="row">
      <div class="col-6">
        <div class="card padding-card">
          <p class="main-title">
            {{ estado_pendiente || 0 }}
          </p>
          <p class="sub-title">Pendientes</p>
        </div>
      </div>
      <div class="col-6">
        <div class="card padding-card">
          <p class="main-title">
            {{ estado_aprobado || 0 }}
          </p>
          <p class="sub-title">Aprobados</p>
        </div>
      </div>
      <div class="col-6">
        <div class="card card-margin-top padding-card">
          <p class="main-title">
            {{ estado_cancelado || 0 }}
          </p>
          <p class="sub-title">Rechazados</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row my-3">
  <!-- No. de clientes por mes -->
  <div class="col-12 ">
    <div class="card rounded">
      <div class="card-body">
        <div class="d-flex justify-content-between w-100">
          <h6>No. de clientes por mes (Con historico de compras)</h6>
          <p>Total: {{total_clientes_mes}}</p>
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_cliente_mes"
            (ngModelChange)="getConsultaFiltroClientesMes()">
            <option [value]="anio" *ngFor="let anio of filtro_anio">
              {{anio}}
            </option>
          </select>
        </div>
        <canvas #bar_clientes_mes class="grafica"
          [ngClass]="{'inactive': data_bar_clientes_mes.datasets.length == 0}"></canvas>
        <div *ngIf="data_bar_clientes_mes.datasets.length > 0 else mostrarError"></div>
      </div>
    </div>
  </div>
  <!-- Mapa de clientes -->
  <div class="col-12 col-md-6 p-2">
    <div class="card rounded">
      <div class="card-body">
        <div class="d-flex justify-content-between w-100">
          <h6>Mapa de clientes</h6>
        </div>
        <div class="map grafica">
          <div *ngIf="coord_dist">
            <agm-map #AgmMapNat fit="true" [zoom]="10" [latitude]="coord_dist.lat" [longitude]="coord_dist.lng"
              style="width: 100%; height: 25rem">
              <agm-marker [latitude]="coord_dist.lat" [longitude]="coord_dist.lng"
                [label]="{color: 'white', text: 'Tù'}">
              </agm-marker>
              <agm-marker *ngFor="let puntos of locations_puntos_entrega; let i = index" [latitude]="puntos.lat"
                [longitude]="puntos.lng" [markerClickable]="true" (markerClick)="showDataPunto($event)">
              </agm-marker>
            </agm-map>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Cliente seleccionado de mapa -->
  <div class="col-12 col-md-6 p-2">
    <div class="card rounded" [ngClass]="{ clickable: 'selectedClient' }"
      (click)="selectedClient ? openClient() : undefined">
      <div class="card-body">
        <ng-container *ngIf="locations_puntos_entrega && locations_puntos_entrega.length > 0">
          <h6>Punto seleccionado</h6>
          <span class="d-flex flex-row">
            <img class="mr-2 img-icon" src="../../assets/img/icons/icon-store.png"
              alt="Icono de un pin para señalar un punto en un mapa" srcset="" />
            <p>{{punto_seleccionado_nombre || 'Selecciona un punto en el mapa'}}</p>
          </span>
          <span class="d-flex flex-row">
            <img class="mr-2 img-icon" src="../../assets/img/icons/icon-pin-map.png"
              alt="Icono de un pin para señalar un punto en un mapa" srcset="" />
            <p>{{punto_seleccionado_direccion || ''}}</p>
          </span>
        </ng-container>
        <ng-container *ngIf="locations_puntos_entrega && locations_puntos_entrega.length <= 0">
          <p>Selecciona un punto en el mapa para ver más información de un cliente.</p>
        </ng-container>
        <!-- <div class="d-flex align-items-center justify-content-center align-self-center flex-column"
          *ngIf="!data?.puntos_geo?.length">
          <img src="../../../assets/img/icon-warning-amarillo.png" alt="Advertencia" id="icon-warning"
            style="max-width: 200px" class="w-100 p-4" />
          <h6>Nada de nada</h6>
          <p>No hay clientes para mostrar en el mapa</p>
        </div> -->
      </div>
    </div>
  </div>
  <!-- No. de clientes por tipo de negocio -->
  <div class="col-12 p-2">
    <div class="card rounded">
      <div class="card-body">
        <div class="d-flex justify-content-between w-100">
          <h6>No. de clientes por tipo de negocio</h6>
          <p>Total: {{total_clientes_tipo}}</p>
        </div>
        <canvas #bar_clientes_tipo class="grafica"
          [ngClass]="{'inactive': data_bar_clientes_tipo.datasets.length == 0}"></canvas>
        <div *ngIf="data_bar_clientes_tipo.datasets.length > 0 else mostrarError"></div>
      </div>
    </div>
  </div>
  <!-- No. de clientes por número de sillas -->
  <div class="col-12 col-md-6 p-2">
    <div class="card rounded">
      <div class="card-body">
        <div class="d-flex justify-content-between w-100">
          <h6>No. de clientes por número de sillas</h6>
          <p>Total: {{total_clientes_sillas}}</p>
        </div>
        <canvas #bar_clientes_sillas class="grafica"
          [ngClass]="{'inactive': data_bar_clientes_sillas.datasets.length == 0}"></canvas>
        <div *ngIf="data_bar_clientes_sillas.datasets.length > 0 else mostrarError"></div>
      </div>
    </div>
  </div>
  <!-- No. de clientes por tipo de persona -->
  <div class="col-12 col-md-6 p-2">
    <div class="card rounded">
      <div class="card-body">
        <div class="d-flex justify-content-between w-100">
          <h6>No. de clientes por tipo de persona</h6>
          <p>Total: {{total_clientes_tipo_usuario}}</p>
        </div>
        <canvas #bar_clientes_tipo_usuario class="grafica"
          [ngClass]="{'inactive': data_bar_clientes_tipo_usuario.datasets.length == 0}"></canvas>
        <div *ngIf="data_bar_clientes_tipo_usuario.datasets.length > 0 else mostrarError"></div>
      </div>
    </div>
  </div>
  <!-- No. de sillas por negocio -->
  <div class="col-12 col-md-6 p-2">
    <div class="card rounded">
      <div class="card-body">
        <div class="d-flex justify-content-between w-100">
          <h6>No. de sillas por negocio</h6>
          <p>Total: {{total_clientes_sillas_x_negocio}}</p>
        </div>
        <canvas #bar_clientes_sillas_x_negocio class="grafica"
          [ngClass]="{'inactive': data_bar_clientes_sillas_x_negocio.datasets.length == 0}"></canvas>
        <div *ngIf="data_bar_clientes_sillas_x_negocio.datasets.length > 0 else mostrarError"></div>
      </div>
    </div>
  </div>
  <!-- Puntos de entrega en establecimientos -->
  <div class="col-12 col-md-6 p-2">
    <div class="card rounded">
      <div class="card-body">
        <div class="d-flex justify-content-between w-100">
          <h6>Puntos de entrega en establecimientos</h6>
          <p>Total: {{total_puntos_x_horeca}}</p>
        </div>
        <canvas #bar_puntos_x_horeca class="grafica"
          [ngClass]="{'inactive': data_bar_puntos_x_horeca.datasets.length == 0}"></canvas>
        <div *ngIf="data_bar_puntos_x_horeca.datasets.length > 0 else mostrarError"></div>
      </div>
    </div>
  </div>
  <!-- No. de clientes en cartera -->
  <div class="col-12 col-md-6 p-2">
    <div class="card rounded">
      <div class="card-body">
        <div class="d-flex justify-content-between w-100">
          <h6>No. de clientes en cartera</h6>
          <p>Total: {{total_clientes_cartera}}</p>
        </div>
        <canvas #pie_clientes_cartera class="grafica pie-chart"
          [ngClass]="{'inactive': data_pie_clientes_cartera.datasets.length == 0}"></canvas>
        <div *ngIf="data_pie_clientes_cartera.datasets.length > 0 else mostrarError"></div>
      </div>
    </div>
  </div>
  <!-- No. de clientes con convenio -->
  <div class="col-12 col-md-6 p-2">
    <div class="card rounded">
      <div class="card-body">
        <div class="d-flex justify-content-between w-100">
          <h6>No. de clientes con convenio</h6>
          <p>Total: {{total_clientes_convenio}}</p>
        </div>
        <canvas #pie_clientes_convenio class="grafica pie-chart"
          [ngClass]="{'inactive': data_pie_clientes_convenio.datasets.length == 0}"></canvas>
        <div *ngIf="data_pie_clientes_convenio.datasets.length > 0 else mostrarError"></div>
      </div>
    </div>
  </div>
  <!-- No. de clientes con domicilio -->
  <div class="col-12 col-md-6 p-2">
    <div class="card rounded">
      <div class="card-body">
        <div class="d-flex justify-content-between w-100">
          <h6>No. de clientes con domicilio</h6>
          <p>Total: {{total_clientes_domicilio}}</p>
        </div>
        <canvas #pie_clientes_domicilio class="grafica pie-chart"
          [ngClass]="{'inactive': data_pie_clientes_domicilio.datasets.length == 0}"></canvas>
        <div *ngIf="data_pie_clientes_domicilio.datasets.length > 0 else mostrarError"></div>
      </div>
    </div>
  </div>
</div>
<!-- Manejo de errores -->
<ng-template #mostrarError>
  <div class="d-flex flex-column w-100 justify-content-center mt-4">
    <div class="d-flex justify-content-center">
      <img src="assets/img/icon-warning-amarillo.png" alt="icono de una hoja de papel y una lupa sobre ella"
        style="max-width: 150px;" />
    </div>
    <p class="mt-2 text-center">
      ¡Lo siento! No existen datos para ver aquí
    </p>
  </div>
</ng-template>