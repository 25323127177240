<div class="row margin-row">
    <div class="col-12 text-center">
      <br />
      <div class="row">
        <div class="col-12">
          <img [src]="img_src" alt="Ícono modal" id="icon-modal" />
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-12">
          <h3>
            {{ title }}
          </h3>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-12 text-center">
          <p>
            {{ msg }}
          </p>
        </div>
      </div>
      <br />
      <div class="row tablew" *ngIf="dataResult" >
        <div class="col-12 text-center search-table-outter wrapper">

            <table class="table table-striped fontTable">
                <thead>
                  <tr>
                    <th scope="col">Imagen</th>
                    <th scope="col">Producto</th>
                    <th scope="col">Precio Anterior</th>
                    <th scope="col">Precio Actual</th>
                    <th scope="col">Observación</th>
                  </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let rest of dataResult">
                     <td><img class="imgProd" [src]="rest.imagenProd"></td>
                     <td>{{rest.producto}}</td>
                     <td>{{rest.precioAnterior}}</td>
                     <td>{{rest.precioActual}}</td>
                     <td>{{rest.comentario}}</td>
                  </tr>
                </tbody>
              </table>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-12">
            <button type="button" class="btn btn-success" (click)="onClose('cargar')">Aceptar cambios</button>
            <button type="button" class="btn btn-danger" (click)="onClose('cancelar')">Cancelar pedido</button>
         <!--<button
            id="btn_modal"
            (click)="onClose()"
          >
            Cancelar Sugerido
          </button>-->
        </div>
      </div>
      <br />
    </div>
    <div class="col-1">
    </div>
  </div>