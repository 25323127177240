import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-modal-plan',
  templateUrl: './modal-plan.component.html',
  styleUrls: ['./modal-plan.component.css']
})
export class ModalPlanComponent implements OnInit {

  @Input() img_src = '';
  @Input() title = '';
  @Input() msg = '';
  @Input() btn_msg = '';
  @Input() close_callback = () => {};

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  onClose() {
    this.activeModal.close('Close click');
    this.close_callback();
  }
}
