/* eslint-disable prettier/prettier */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { faCamera, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { SocketWebService } from '../services/socket/socket-web.service';
import { AuthService } from '../services/auth/auth.service';
import { RestService } from '../services/rest/rest.service';
import * as moment from 'moment';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CargarImagenMensajesComponent } from '../modal/cargar-imagen-mensajes/cargar-imagen-mensajes.component';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { Store } from '@ngxs/store';
import { SimpleComponent } from '../modal/simple/simple.component';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ChatComponent implements OnInit {
  // ID organizacion
  public id_organizacion = this.authService.user_organizacion?._id;
  // ID distribuidor
  public id_distribuidor = this.authService.user_distribuidor?._id;
  // Referencias a íconos
  public faChevronLeft = faChevronLeft;
  public faCamera = faCamera;
  /**Sala o Pedido ID */
  public room: string = '' as string;
  // Instancia del Socket
  public socket: any;
  // Mensaje escrito en la caja de input del chat
  public message: string | undefined;
  // Información transversal del usuario en el momento del logue
  public user: any;
  // Arreglo de objetos con los mensajes de la conversació
  public conversacion = <any>[];
  // Variable dinámica donde se construye las conversaciones en la caja grande de un cha
  public someHtmlCode = '';
  // Imagen producto placeholder
  public distribuidor_placeholder = '../../assets/img/product-placeholder.png';
  // Guarda la url de imagen guardada en el modal
  public url_imagen: any;
  // Flag para identificar el compenente previo y habilitar el boton volver segun corresponda
  public ruta_previa: any;
  // Variables para guardar información de mensajes y mostrarla en el DOM
  public data_conversacion_actual: any;
  // Punto de entrega seleccionado
  public punto_entrega: any = '';
  // Tipo de usuario logueado
  public tipo_usuario: any;
  // Modal de carga para darle feedback al usuario
  public id_empresas: any[] = [];
  public id_remitente = '';
  public id_destinatario = '';
  public empresas_asociadas_chat: any = [];
  public empresa_actual_chat: any;
  public url_dist = '';
  public url_org = '';
  // Guarda los ids de los mensajes no leidos del dist.
  public id_mensajes_no_leidos: any = [];
  // Modal generico
  public modal_carga?: NgbModalRef;
  // Modal configuraciones
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };

  constructor(
    private router: ActivatedRoute,
    private cookieService: CookieService,
    private socketWebService: SocketWebService,
    private authService: AuthService,
    private rest: RestService,
    private modalService: NgbModal,
    private ngxsStore: Store,
    private routerNavigation: Router
  ) {
    this.user = this.authService.user;
    /** Función para escuchar los mensajes y colocarlos en la caja de mensajes de chat actual */
    socketWebService.callback.subscribe((res) => {
      /** Marca el mensaje como leido */
      res.leido = true;
      /** Añade el mensaje al array de mensajes para guardar en la base */
      this.conversacion.push(res);
      /** Si viene una imagen lo formatea para mostrarlo en el DOM */
      /** Añade el mensaje al DOM desde el socket */
      if (res.mensaje.startsWith('https://feat-resources.s3')) {
        this.someHtmlCode =
          this.someHtmlCode +
          `<br><p class="text-center timestamp">${res.tiempo}</p>
          <div class="text-left"> <span >  ${res.usuarioNombre}</span><span class="letra-auxiliar"> (${res.tipo}) </span></div>
                <div class="d-flex flex-row card card-mensaje">
                <div class="text-left card-body mensaje mensaje-dist"><p class="m-0"><img src="${res.mensaje}" style="width: 100%;" alt="imagen mensaje" class="img-mensaje"></p></div></div>`;
      } else {
        this.someHtmlCode =
          this.someHtmlCode +
          `<br><p class="text-center timestamp">${res.tiempo}</p>
          <div class="text-left"> <span >  ${res.usuarioNombre}</span><span class="letra-auxiliar"> (${res.tipo}) </span></div>
                <div class="d-flex flex-row card card-mensaje">
                <div class="text-left card-body mensaje mensaje-dist"><p class="m-0">${res.mensaje}</p></div></div>`;
      }
      this.scrollToBottom();
      this.playSound();
    });
  }

  /**
   * Función inicial que valida los procesos de la sala o pedido Id , crea la cookie
   * para la conversación y trae información del historial y conversación actual
   */
  async ngOnInit() {
    try {
      this.modal_carga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
      this.user = this.ngxsStore.snapshot().auth.user;
      this.ruta_previa = this.router.snapshot.paramMap.get('ruta_previa') || '';
      await this.createRoom();
      this.modal_carga?.close();
    } catch (error) {
      this.mostrarMensajeError();
      this.modal_carga?.close();
    }
  }

  /**
   * Función inicial que valida los procesos de la sala o pedido Id , crea la cookie
   * para la conversación y trae información del historial y conversación actual
   */
  async createRoom() {
    this.room = this.router.snapshot.paramMap.get('room') || '';
    if (this.room != 'todos') {
      await this.rest
        .getJWT('vinculacion/distribuidor/organizacion/' + this.room)
        .toPromise()
        .then((resp: any) => {
          this.id_distribuidor = resp.codigo_distribuidor;
          this.id_organizacion = resp.codigo_organizacion;
          this.validaPermisosAcceso();
          this.cookieService.set('room', this.room);
          this.getMensajesDestinatariosLeidos();
        })
        .catch((err) => {
          this.mostrarMensajeError();
        });
    } else {
      this.validaPermisosAcceso();
    }
  }

  /**
   * Revisa si es un establecimiento o un distribuidor logueado
   * Valida permisos de acceso al room si no, sale del modulo
   */
  async validaPermisosAcceso() {
    this.rest
      .get(`tipo_empresa_trabajador/${this.user?._id}`)
      .toPromise()
      .then((resp: any) => {
        this.tipo_usuario = resp;
        /** Valida el usuario pueda visualizar la sala */
        if (this.tipo_usuario === 'usuario_horeca') {
          this.cerrarRoomAccesoNegado();
        } else if (this.tipo_usuario === 'distribuidor') {
          const id_dist = this.ngxsStore.snapshot().auth.user_distribuidor?._id;
          if (id_dist != this.id_distribuidor) {
            this.cerrarRoomAccesoNegado();
          }
        } else if (this.tipo_usuario === 'organizacion') {
          const id_org = this.ngxsStore.snapshot().auth.user_organizacion?._id;
          if (id_org != this.id_organizacion) {
            this.cerrarRoomAccesoNegado();
          }
        }
        this.getOtrasSalasDisponibles();
        if (this.room != 'todos') {
          this.getConversacionActual();
        }
      });
  }

  /**
   * Trae todos rooms asociados al establecimiento logueado
   * para armar menu de miniaturas de navegacion de chats disp.
   */
  async getOtrasSalasDisponibles() {
    if (this.tipo_usuario === 'distribuidor') {
      await this.rest
        .getJWT(`vinculacion/organizaciones_x_distribuidor/` + this.id_distribuidor)
        .toPromise()
        .then((resp: any) => {
          this.empresas_asociadas_chat = resp;
          this.empresa_actual_chat = this.empresas_asociadas_chat.find(
            (empresa: any) => empresa.codigo_organizacion === this.id_organizacion
          );
        });
    } else if (this.tipo_usuario === 'organizacion') {
      await this.rest
        .getJWT(`vinculacion/distribuidores_x_organizacion/` + this.id_organizacion)
        .toPromise()
        .then((resp: any) => {
          this.empresas_asociadas_chat = resp;
          this.empresa_actual_chat = this.empresas_asociadas_chat.find(
            (empresa: any) => empresa.codigo_distribuidor === this.id_distribuidor
          );
        });
    }
  }

  /**
   * Traer la informacion de la conversacion dependiendo del id
   * del pedido asociado, reconstruye la conversacion en el html
   */
  async getConversacionActual() {
    await this.rest
      .getJWT(`mensajes/completos/vinculacion/distribuidor/organizacion/${this.room}`)
      .toPromise()
      .then((res: any) => {
        this.data_conversacion_actual = res;
        if (this.data_conversacion_actual != undefined && this.data_conversacion_actual.length > 0) {
          for (const conversacion of this.data_conversacion_actual) {
            /** Valida si hay un mensaje del destiantario no leido y le cambia el estado */
            if (this.id_mensajes_no_leidos.includes(conversacion._id)) {
              conversacion.leido = true;
            }
            const objChat: any = this.getObjChat(conversacion, false);
            /** Si viene una imagen lo formatea para mostrarlo en el DOM */
            let mensaje_limpio = '';
            if (objChat.mensaje.startsWith('https://feat-resources.s3')) {
              mensaje_limpio = `<img src="${objChat.mensaje}" style="width: 100%;" alt="imagen mensaje" class="img-mensaje">`;
            } else {
              mensaje_limpio = objChat.mensaje;
            }
            /** Marca el mensaje como leido */
            objChat.leido = true;
            /** Arma las nubes de los mensajes dependiendo de quien escribe */
            this.conversacion.push(objChat);
            if (mensaje_limpio.endsWith(' ha iniciado el chat')) {
              this.someHtmlCode =
                this.someHtmlCode +
                `<br><p class="text-center  text-secondary inactive timestamp">${mensaje_limpio}</p>`;
            } else if (conversacion.usuario == this.user._id) {
              this.someHtmlCode =
                this.someHtmlCode +
                `<br><p class="text-center timestamp">${objChat.tiempo}</p>
                <div class="text-right"> <span >  ${objChat.usuarioNombre}</span><span class="letra-auxiliar"> (${objChat.tipo}) </span></div>
                <div class="d-flex flex-row-reverse card card-mensaje">
                <div class="text-right card-body mensaje mensaje-horeca"><p class="m-0">${mensaje_limpio}</p></div></div>`;
            } else {
              this.someHtmlCode =
                this.someHtmlCode +
                `<br><p class="text-center timestamp">${objChat.tiempo}</p>
                <div class="text-left"> <span >  ${objChat.usuarioNombre}</span><span class="letra-auxiliar"> (${objChat.tipo}) </span></div>
                <div class="d-flex flex-row card card-mensaje">
                <div class="text-left card-body mensaje mensaje-dist"><p class="m-0">${mensaje_limpio}</p></div></div>`;
            }
          }
        }
        setTimeout(() => {
          this.scrollToBottom();
          this.guardarConversacion();
        }, 2000);
        /**
         * Verifica si aun el usuario logueado no ha iniciado el chat de no haberlo hecho lo inicia
         * con un mensaje por defecto esto es encesario para iniciar el funcionamiento del socket
         */
        if (localStorage.getItem('chat_room_id') !== this.room) {
          this.crerarMensajePorDefecto();
        }
      })
      .catch((err: any) => {
        return;
      });
  }

  /**
   * Recupera los IDs de los mensaje del destinatario que no se han leido
   */
  async getMensajesDestinatariosLeidos() {
    let id_empresa_destino = this.id_distribuidor;
    if (this.tipo_usuario == 'distribuidor') {
      id_empresa_destino = this.id_organizacion || '';
    }
    await this.rest
      .getJWT(
        `chat/vinculacion/distribuidor_organizacion/no_leidos_destinatario/${id_empresa_destino}/room/${this.room}`
      )
      .toPromise()
      .then((res: any) => {
        res.forEach((mensaje: any) => {
          this.id_mensajes_no_leidos.push(mensaje._id);
        });
      })
      .catch(() => {});
  }
  /**
   * Abre modal para cargar la imagen, desde el modal se guarda en la base de datos;
   * luego de guardada se recibe la url de la imagen y se procede a enviar el mensaje al DOM
   */
  enviarImagen() {
    const modalRef = this.modalService.open(CargarImagenMensajesComponent, {
      centered: true,
    });
    modalRef.componentInstance.url_imagen = this.url_imagen;
    modalRef.componentInstance.callback = (url_imagen: any) => {
      this.message = url_imagen;
      this.enviarMensaje();
    };
  }

  /**
   * Emmite el evento de mensaje con la información ingresada en el campo de input del form de chat
   */
  enviarMensaje() {
    if (this.message !== '') {
      const objChat: any = this.getObjChat(this.message ? this.message : '', true);
      /** Marca el mensaje como NO leido */
      objChat.leido = false;
      this.conversacion.push(objChat);
      this.socketWebService.emitEven(objChat);
      /** Si viene una imagen lo formatea para mostrarlo en el DOM */
      if (objChat.mensaje.endsWith(' ha iniciado el chat')) {
        this.someHtmlCode =
          this.someHtmlCode + `<br><p class="text-center text-secondary inactive timestamp">${objChat.mensaje}</p>`;
      } else if (objChat.mensaje.startsWith('https://feat-resources.s3')) {
        this.someHtmlCode =
          this.someHtmlCode +
          `<br><p class="text-center timestamp">${objChat.tiempo}</p>
            <div class="text-right"> <span >  ${objChat.usuarioNombre}</span><span class="letra-auxiliar"> (${objChat.tipo}) </span></div>
            <div class="d-flex flex-row-reverse card card-mensaje">
            <div class="text-right card-body mensaje mensaje-horeca"><p class="m-0"><img src="${objChat.mensaje}" style="width: 100%;" alt="imagen mensaje" class="img-mensaje"></p></div></div>`;
      } else {
        this.someHtmlCode =
          this.someHtmlCode +
          `<br><p class="text-center timestamp">${objChat.tiempo}</p>
            <div class="text-right"> <span >  ${objChat.usuarioNombre}</span><span class="letra-auxiliar"> (${objChat.tipo}) </span></div>
            <div class="d-flex flex-row-reverse card card-mensaje">
            <div class="text-right card-body mensaje mensaje-horeca"><p class="m-0">${objChat.mensaje}</p></div></div>`;
      }
      this.message = '';
      this.scrollToBottom();
      this.guardarConversacion();
    }
  }

  /**
   * Funcion encargada de formatear el objeto que se muestra en una sola interaccion del chat
   * @mensaje se envia dinamicamente el mensaje escrito que viene del input
   * @nuevo_mensaje Crea dos objetos con informacion diferente dependiendo si el mensaje
   *  viene de la base de datos  o se esta agegando un nuevo mensaje
   */
  getObjChat(mensaje: any, nuevo_mensaje: boolean) {
    moment.locale('es-es');
    let objChat: any = {};
    if (nuevo_mensaje) {
      objChat = {
        usuario: this.user._id,
        usuarioNombre: this.user.nombres + ' ' + this.user.apellidos,
        mensaje: mensaje,
        tiempo: moment().format('DD MMMM YYYY, h:mm a'),
        tipo: this.user.tipo_trabajador,
      };
    } else {
      objChat = {
        usuario: mensaje.usuario,
        usuarioNombre: mensaje.data_trabajador.nombre,
        mensaje: mensaje.mensaje,
        tiempo: mensaje.tiempo,
        tipo: mensaje.data_trabajador.tipo_trabajador,
      };
    }
    return objChat;
  }

  /**
   * Cuando se le da volver o se cierra la conversacion esta
   * funcion se encarga de guardar el historial o actualizar
   * la conversacion si el chat ya existia
   */
  guardarConversacion() {
    /** Armar Objeto */
    const data = {
      pedido: this.room,
      conversacion: this.conversacion,
      estado: 'Activo',
      flag_room_dist_org: true,
    };
    /** Guardar Conversacion */
    this.rest
      .postJWT('mensajes', data)
      .toPromise()
      .then(() => {})
      .catch((err: any) => {});
  }

  /**
   * Abre un nuevo room para el chat de un pedido
   * @Params empresa: Objeto con detalles para abrir la sala
   */
  cargarNuevoRoom(empresa: any) {
    this.routerNavigation
      .navigate([
        '/chat/room/' +
          empresa.room +
          '/distribuidor/' +
          empresa.codigo_distribuidor +
          '/organizacion/' +
          empresa.codigo_organizacion +
          '/' +
          this.tipo_usuario,
      ])
      .then(() => {
        window.location.reload();
      });
  }

  /**
   * Envia un mensaje por defecto que incializa el chat
   * Esto se necesito por error al iniciar un chat, esto
   * es que si no hay un mensaje ya enviado no inicia el socket
   * por lo que se forza un mensaje inicial por defecto
   * que forza la creación del hijo en el socket para el chat.
   */
  async crerarMensajePorDefecto() {
    const objChat = {
      usuario: this.user._id,
      usuarioNombre: 'Super administrador FEAT',
      mensaje: `${this.user.nombres} ${this.user.apellidos} ha iniciado el chat`,
      tiempo: moment().format('DD MMMM YYYY, h:mm a'),
      tipo: 'FEAT',
      leido: false,
    };
    this.conversacion.push(objChat);
    this.socketWebService.emitEven(objChat);
    this.someHtmlCode =
      this.someHtmlCode + `<br><p class="text-center text-secondary inactive timestamp">${objChat.mensaje}</p>`;
    this.message = '';
    this.scrollToBottom();
    await this.guardarConversacion();
    // await this.postNotificacionMensajeNuevo();
    localStorage.setItem('chat_room_id', this.room);
    window.location.reload();
  }

  /**
   * Envia una notificacion a la contra parte que se ha abierto una sala
   */
  // postNotificacionMensajeNuevo() {
  //     this.rest
  //     .postJWT(`notificacion/chat-abierto/distribuidor/`, this.pedido)
  //     .toPromise()
  //     .then(() => {});
  // }

  ngOnDestroy() {
    localStorage.removeItem('punto_entrega_chat');
    localStorage.removeItem('chat_room_id');
    this.guardarConversacion();
  }

  /**
   * Voler a modulo anterior
   * dependiendo de donde haya ingresado, volvera al modulo anterior
   */
  volver() {
    localStorage.removeItem('punto_entrega_chat');
    localStorage.removeItem('chat_room_id');
    this.guardarConversacion();
    if (this.ruta_previa == 'organizacion') {
      this.routerNavigation.navigate(['/distribuidores-organizacion']);
    } else if (this.ruta_previa == 'distribuidor') {
      this.routerNavigation.navigate(['/inicio-distribuidor']);
    } else {
      this.routerNavigation.navigate(['/inicio-' + this.tipo_usuario]);
    }
  }

  /**
   * Scroll hasta el final del div, se da un time out a esperas que primero cargue el ultimo mensaje en el DOM
   */
  scrollToBottom() {
    const objDiv: HTMLElement | null = document.getElementById('chat-mensajes-caja');
    setTimeout(() => {
      objDiv!.scrollTop = objDiv!.scrollHeight;
    }, 300);
  }

  /**
   * Sonido para alertar sobre la llegada de un mensaje
   */
  playSound() {
    const audio = new Audio('../../../assets/bell-notification.wav');
    audio.play();
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private cerrarRoomAccesoNegado() {
    this.modal_carga?.close();
    this.routerNavigation.navigate(['/inicio-' + this.tipo_usuario]);
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Lo siento, no tienes acceso a esta conversación';
    modalRef.componentInstance.btn_msg = 'Volver';
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modal_carga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.routerNavigation.navigate(['/inicio-' + this.tipo_usuario]);
    };
  }
}
