import { NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, DoCheck, Input, OnInit, SecurityContext } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { faChevronLeft, faPlus, faTimesCircle, faEye, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { EditarInventarioProductoComponent } from 'src/app/modal/editar-inventario-producto/editar-inventario-producto.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { Categoria } from 'src/app/models/categoria.model';
import { LineaProducto } from 'src/app/models/linea_producto.model';
import { Marca } from 'src/app/models/marca.model';
import { Organizacion } from 'src/app/models/organizacion.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { CurrencyPipe } from '@angular/common';
import { ConfirmacionComponent } from 'src/app/modal/confirmacion/confirmacion.component';
@Component({
  selector: 'app-detalle-producto-flotante',
  templateUrl: './detalle-producto-flotante.component.html',
  styleUrls: ['./detalle-producto-flotante.component.css'],
})
export class DetalleProductoFlotanteComponent implements OnInit {
  @Input() dataProducto: any;
  @Input() posProd: any;
  @Input() close_callback = (data: any, post: any) => {};
  /** Formularios reactivos */
  public distribuidorForm: FormGroup;
  /** Validación de formulario que no entran en los validators */
  public is_boton_habilitado = true;
  public validator_telefono_2 = false;

  /** Texto faltante en textareas */
  public remainingTextNombre = 0;
  public remainingTextDescripcion = 0;

  /** Referencias a íconos FontAwesome para la UI */
  faChevronLeft = faChevronLeft;
  faTimesCircle = faTimesCircle;
  faPlus = faPlus;
  faEye = faEye;
  faFilePdf = faFilePdf;

  /** ID e información del producto a editar */
  private prod_id = '';
  public producto: any;

  /** Información de marcas, organizaciones, categorias, y lineas de producto */
  public marcas: Marca[] = [];
  public marcas_filtradas: Marca[] = [];
  public nueva_marca = '';
  public marca_seleccionada?: Marca;
  public organizaciones: Organizacion[] = [];
  public categorias: Categoria[] = [];
  public lineas: any;

  /** Se da manejo a las propiedades opcionales del Producto con estas variables (para evitar problemas de TypeScript) */
  public number = Number;
  public precios: {
    unidad_medida?: string;
    cantidad_medida?: string;
    estado?: string;
    precio_unidad?: number;
    precio_caja?: number;
    precio_descuento?: number;
    inventario_unidad?: number;
    inventario_caja?: number;
    puntos_ft_unidad?: number;
    puntos_ft_caja?: number;
    und_x_caja?: number;
  }[] = [];

  /** Variables para el manejo de las fotos y sus miniaturas */
  public fotos: (SafeUrl | string)[] = [];
  public file_fotos: (File | undefined)[] = [];

  /** Arreglo con los formatos validos de imagen */
  supported_imgs: string[] = ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp'];
  error_formato_img = false;
  error_max_imgs = false;

  /** Flag para controlar si es posible o no subir el producto con los datos ingresados */
  actualizar_enabled = false;

  /** Expresión regular para verificar que un string sólo tenga números */
  regex_solo_numeros = /^\d+$/;

  /** Referemcia al modal de proceso de carga para cerrarlo una vez termine la carga u ocurra un error */
  openModal?: NgbModalRef;

  /** Evita que al hacer click por fuera se cierre el modal */
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
  };

  /** Guarda la cantidad de unidades pend. para completar una caja */
  unidades_pendientes_caja = 0;

  /** Guarda el inventario */
  public cajas = 0;
  public unidades = 0;

  /** Guarda datos para API Whatsapp */
  dataCambios = {
    numero: '573184662904',
    mensaje: `Hola!%20Necesito%20un%20cambio%20para%20un%20producto%20`,
  };

  /**Imagen producto placeholder */
  public product_placeholder = '../../assets/img/product-placeholder.png';
  public distribuidor_placeholder = '../../assets/img/icon-organizacion.png';
  public prodDescuento = false;
  public prodPorcentajeDesc = 0;
  public prodBiodegradable = false;
  public prodPedido = false;
  constructor(
    public activeModal: NgbActiveModal,
    private activatedRoute: ActivatedRoute,
    private rest: RestService,
    private router: Router,
    private auth: AuthService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private currencyPipe: CurrencyPipe,
    private sanitizer: DomSanitizer
  ) {
    this.distribuidorForm = this.formBuilder.group({
      distribuidorInventarioUnidad: [''],
      distribuidorInventarioCaja: [''],
      distribuidorPrecioUnidad: ['', Validators.required],
      distribuidorPrecioCaja: ['', Validators.required],
      distribuidorPrecioUnidadMedida: ['', Validators.required],
      distribuidorCodigoProducto: ['', Validators.required],
      distribuidorCodigoOrganizacion: [''],
      distribuidorProductoNombre: ['', Validators.required],
      distribuidorProductoDescripcion: ['', Validators.required],
      distribuidorMarca: [''],
      distribuidorOrganizacion: [''],
      distribuidorCategoria: [''],
      distribuidorLinea: [''],
      distribuidorCantidadMedida: ['', Validators.required],
      distribuidorUnidadMedida: ['', Validators.required],
      distribuidorPresentacionCaja: ['', Validators.required],
      distribuidorEstado: [''],
    });
  }

  ngOnInit() {
    this.producto = this.dataProducto;
    this.prod_id = this.dataProducto._id;

    let catgoria = '';
    if (this.dataProducto.categoria_producto?._id) {
      catgoria = this.dataProducto.categoria_producto?._id;
    } else if (this.dataProducto.categoria_producto) {
      catgoria = this.dataProducto.categoria_producto;
    }
    let marca = '';
    if (this.dataProducto.marca_producto?._id) {
      marca = this.dataProducto.marca_producto?._id;
    } else if (this.dataProducto.marca_producto) {
      marca = this.dataProducto.marca_producto;
    }
    let linea = '';
    if (this.dataProducto.linea_producto && this.dataProducto.linea_producto[0]?._id) {
      linea = this.dataProducto.linea_producto[0]?._id;
    } else if (this.dataProducto.linea_producto) {
      linea = this.dataProducto.linea_producto;
    }
    this.precios = this.dataProducto.precios;
    if (this.dataProducto.prodDescuento) {
      this.prodDescuento = this.dataProducto.prodDescuento;
    }
    if (this.dataProducto.prodPorcentajeDesc) {
      this.prodPorcentajeDesc = this.dataProducto.prodPorcentajeDesc;
    }
    if (this.dataProducto.prodBiodegradable) {
      this.prodBiodegradable = this.dataProducto.prodBiodegradable;
    }
    if (this.dataProducto.prodPedido) {
      this.prodPedido = this.dataProducto.prodPedido;
    }
    const patchProduct: any = {};
    /** Se alimenta formulario del DOM */
    if (this.producto.estadoActualizacion === 'Pendiente') {
      this.distribuidorForm.addControl(
        'distribuidorOrganizacionManual',
        this.formBuilder.control('', [Validators.required])
      );
      this.distribuidorForm.addControl('distribuidorMarcaManual', this.formBuilder.control('', [Validators.required]));
      this.distribuidorForm.addControl(
        'distribuidorCategoriaManual',
        this.formBuilder.control('', [Validators.required])
      );
      this.distribuidorForm.addControl('distribuidorLineaManual', this.formBuilder.control('', [Validators.required]));
      patchProduct.distribuidorOrganizacionManual = this.producto?.organizacion_manual;
      patchProduct.distribuidorCategoriaManual = this.producto?.categoria_manual;
      patchProduct.distribuidorMarcaManual = this.producto?.marca_manual;
      patchProduct.distribuidorLineaManual = this.producto?.linea_manual;
    }
    this.distribuidorForm.patchValue({
      distribuidorPrecioUnidad: this.precios[0].precio_unidad,
      distribuidorPrecioCaja: this.precios[0].precio_caja,
      distribuidorPrecioUnidadMedida: 0,
      distribuidorProductoNombre: this.producto?.nombre,
      distribuidorCodigoProducto: this.producto?.codigo_distribuidor_producto,
      distribuidorCodigoOrganizacion: this.producto?.codigo_organizacion_producto,
      distribuidorMarca: marca,
      distribuidorOrganizacion: this.producto?.codigo_organizacion,
      distribuidorCategoria: catgoria,
      distribuidorLinea: linea,
      distribuidorUnidadMedida: this.precios[0].unidad_medida,
      distribuidorCantidadMedida: this.precios[0].cantidad_medida,
      distribuidorPresentacionCaja: this.precios[0].und_x_caja,
      distribuidorProductoDescripcion: this.producto?.descripcion,
      distribuidorInventarioUnidad: this.precios![0].inventario_unidad,
      distribuidorInventarioCaja: this.precios![0].inventario_caja,
      distribuidorEstado: this.producto!.estadoActualizacion,
      ...patchProduct,
    });
    /** Deshabilita los inputs si el estado no es Pendeinte */
    if (this.distribuidorForm.get('distribuidorEstado')?.value !== 'Pendiente') {
      this.disabledInputs();
    }
    /** Mensaje por defecto en Whatsapp */
    this.dataCambios.mensaje = `Hola!%20Necesito%20un%20cambio%20para%20el%20producto%20con%20%20código%0A*${this.producto?.codigo_distribuidor_producto}*%20-%20el%20cambio%20es:`;
    /** Formato para los campos de precio */
    this.transformAmount(this.distribuidorForm?.get('distribuidorPrecioUnidad'), 'unidad');
    this.transformAmount(this.distribuidorForm?.get('distribuidorPrecioCaja'), 'caja');
    this.transformAmount(this.distribuidorForm?.get('distribuidorPrecioUnidadMedida'), 'unidad_medida');
    /** Contador de caracteres */
    this.valueChange(this.distribuidorForm?.get('distribuidorProductoNombre')?.value, 'nombre');
    this.valueChange(this.distribuidorForm?.get('distribuidorProductoDescripcion')?.value, 'descripcion');
    /** Formulario reactivo */
    this.distribuidorForm.markAllAsTouched();
    /** Scroll de fotos */
    this.extraerFotos();
    /** Organizaciones */
    this.recuperarOrganizaciones();
    /** Marcas */
    this.recuperarMarcas();
    /** Lista de categorias y lineas */
    this.recuperarCategorias();
    /** Manejo de errores */
    if (!this.producto) {
      throw new Error('Producto no encontrado');
    }
  }

  ngDoCheck(): void {
    if (this.unidades < 0) {
      this.unidades = 0;
    }
    /** Calculo automatico precio por unidad de producto */
    if (
      this.distribuidorForm.get('distribuidorPrecioUnidad')?.value.replace(/[^\d,-]/g, '') > 0 &&
      this.distribuidorForm.get('distribuidorCantidadMedida')?.value > 0
    ) {
      const valor_actualizado =
        this.distribuidorForm.get('distribuidorPrecioUnidad')?.value.replace(/[^\d,-]/g, '') /
        this.distribuidorForm.get('distribuidorCantidadMedida')?.value;
      this.distribuidorForm.patchValue({
        distribuidorPrecioUnidadMedida: this.currencyPipe.transform(valor_actualizado, '$ ', 'symbol', '1.2-2'),
      });
    } else {
      this.distribuidorForm.patchValue({
        distribuidorPrecioUnidadMedida: this.currencyPipe.transform(0, '$ ', 'symbol', '1.2-2'),
      });
    }
    /** Verifica cantidad de cajas agregadas */
    if (this.distribuidorForm.get('distribuidorPresentacionCaja')?.value > 0) {
      this.distribuidorForm.patchValue({
        distribuidorInventarioCaja:
          Math.round(
            (this.distribuidorForm.get('distribuidorInventarioUnidad')?.value /
              this.distribuidorForm.get('distribuidorPresentacionCaja')?.value) *
              100
          ) / 100,
      });
    } else {
      this.distribuidorForm.patchValue({ distribuidorInventarioCaja: 0 });
    }
    /** Calcular  el precio por caja */
    if (
      this.distribuidorForm.get('distribuidorPresentacionCaja')?.value > 0 &&
      this.distribuidorForm.get('distribuidorPrecioUnidad')?.value.replace(/[^\d,-]/g, '') > 0
    ) {
      const precio_caja =
        this.distribuidorForm.get('distribuidorPresentacionCaja')?.value *
        this.distribuidorForm.get('distribuidorPrecioUnidad')?.value.replace(/[^\d,-]/g, '');
      this.distribuidorForm.patchValue({
        distribuidorPrecioCaja: this.currencyPipe.transform(precio_caja, '$ ', 'symbol', '1.0-0'),
      });
    } else {
      this.distribuidorForm.patchValue({ distribuidorPrecioCaja: 0 });
    }
    /** Verifica cuantas unidades hacen falta para completar otra caja */
    this.unidades_pendientes_caja =
      this.distribuidorForm.get('distribuidorPresentacionCaja')?.value -
      (this.distribuidorForm.get('distribuidorInventarioUnidad')?.value -
        this.distribuidorForm.get('distribuidorInventarioCaja')?.value *
          this.distribuidorForm.get('distribuidorPresentacionCaja')?.value);
  }

  /************************************* Fetch información producto *************************************/
  /**
   * Consulta la información del producto a editar. Si no encuentra el producto
   * u ocurre un error, redirige al usuario a la página de portafolio
   */
  recuperarInfoProducto() {
    this.rest
      .getJWT(`producto/${this.prod_id}`)
      .toPromise()
      .then((producto: any) => {
        const catgoria = producto.categoria_producto?._id || '';
        const marca = producto.marca_producto?._id || '';
        const linea = producto.linea_producto[0]?._id || '';
        this.producto = producto;
        this.precios = this.producto?.precios || [];
        /** Se alimenta formulario del DOM */
        this.distribuidorForm.patchValue({
          distribuidorPrecioUnidad: this.precios[0].precio_unidad,
          distribuidorPrecioCaja: this.precios[0].precio_caja,
          distribuidorPrecioUnidadMedida: 0,
          distribuidorProductoNombre: this.producto?.nombre,
          distribuidorCodigoProducto: this.producto?.codigo_distribuidor_producto,
          distribuidorCodigoOrganizacion: this.producto?.codigo_organizacion_producto,
          distribuidorMarca: marca,
          distribuidorOrganizacion: this.producto?.codigo_organizacion,
          distribuidorCategoria: catgoria,
          distribuidorLinea: linea,
          distribuidorUnidadMedida: this.precios[0].unidad_medida,
          distribuidorCantidadMedida: this.precios[0].cantidad_medida,
          distribuidorPresentacionCaja: this.precios[0].und_x_caja,
          distribuidorProductoDescripcion: this.producto?.descripcion,
          distribuidorInventarioUnidad: this.precios![0].inventario_unidad,
          distribuidorInventarioCaja: this.precios![0].inventario_caja,
          distribuidorEstado: this.producto!.estadoActualizacion,
        });
        /** Deshabilita los inputs si el estado no es Pendeinte */
        if (this.distribuidorForm.get('distribuidorEstado')?.value !== 'Pendiente') {
          this.disabledInputs();
        }
        /** Mensaje por defecto en Whatsapp */
        this.dataCambios.mensaje = `Hola!%20Necesito%20un%20cambio%20para%20el%20producto%20con%20%20código%0A*${this.producto?.codigo_distribuidor_producto}*%20-%20el%20cambio%20es:`;
        /** Formato para los campos de precio */
        this.transformAmount(this.distribuidorForm?.get('distribuidorPrecioUnidad'), 'unidad');
        this.transformAmount(this.distribuidorForm?.get('distribuidorPrecioCaja'), 'caja');
        this.transformAmount(this.distribuidorForm?.get('distribuidorPrecioUnidadMedida'), 'unidad_medida');
        /** Contador de caracteres */
        this.valueChange(this.distribuidorForm?.get('distribuidorProductoNombre')?.value, 'nombre');
        this.valueChange(this.distribuidorForm?.get('distribuidorProductoDescripcion')?.value, 'descripcion');
        /** Formulario reactivo */
        this.distribuidorForm.markAllAsTouched();
        /** Scroll de fotos */
        this.extraerFotos();
        /** Organizaciones */
        this.recuperarOrganizaciones();
        /** Marcas */
        this.recuperarMarcas();
        /** Lista de categorias y lineas */
        this.recuperarCategorias();
        /** Manejo de errores */
        if (!this.producto) {
          throw new Error('Producto no encontrado');
        }
      })
      .catch((err: any) => {
        console.log(err);
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg =
          'No fue posible recuperar la información de este producto. Por favor intenta de nuevo más tarde';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigate(['portafolio']);
        };
      });
  }

  /**************************************** Validadores ****************************************/
  /**
   * Deshabilita los inputs cuando el estado NO es Pendiente
   */
  disabledInputs() {
    this.distribuidorForm.get('distribuidorProductoNombre')?.disable();
    this.distribuidorForm.get('distribuidorMarca')?.disable();
    this.distribuidorForm.get('distribuidorOrganizacion')?.disable();
    this.distribuidorForm.get('distribuidorCategoria')?.disable();
    this.distribuidorForm.get('distribuidorLinea')?.disable();
    this.distribuidorForm.get('distribuidorUnidadMedida')?.disable();
    this.distribuidorForm.get('distribuidorCantidadMedida')?.disable();
    this.distribuidorForm.get('distribuidorPresentacionCaja')?.disable();
    this.distribuidorForm.get('distribuidorProductoDescripcion')?.disable();
    this.distribuidorForm.get('distribuidorInventarioUnidad')?.disable();
    this.distribuidorForm.get('distribuidorInventarioCaja')?.disable();
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateNumber(event: any) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || excludedKeys.includes(keyCode))) {
      event.preventDefault();
    }
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateOnlyText(event: any) {
    const keyCode = event.keyCode;
    if (keyCode >= 48 && keyCode <= 57) {
      event.preventDefault();
    }
  }

  /**
   * Transforma el dinero minimo de compra de número a moneda y viceversa
   * a moneda se utilizará para reemplazar el input y dar mejor UX al usuario
   * y a número plano para guardar el dato correctamente en la base de datos
   * ademas de poder editar mejor el valor en el input
   */
  public transformCurrency(event: any, precio: string) {
    if (precio === 'unidad') {
      this.distribuidorForm.patchValue({
        distribuidorPrecioUnidad: event.value.replace(/[^\d,-]/g, ''),
      });
    } else if (precio === 'caja') {
      this.distribuidorForm.patchValue({
        distribuidorPrecioCaja: event.value.replace(/[^\d,-]/g, ''),
      });
    } else if (precio === 'unidad_medida') {
      this.distribuidorForm.patchValue({
        distribuidorPrecioUnidadMedida: event.value.replace(/[^\d,-]/g, ''),
      });
    }
  }
  public transformAmount(event: any, precio: string) {
    if (precio === 'unidad') {
      this.distribuidorForm.patchValue({
        distribuidorPrecioUnidad: this.currencyPipe.transform(event.value, '$ ', 'symbol', '1.0-0'),
      });
    } else if (precio === 'caja') {
      this.distribuidorForm.patchValue({
        distribuidorPrecioCaja: this.currencyPipe.transform(event.value, '$ ', 'symbol', '1.0-0'),
      });
    } else if (precio === 'unidad_medida') {
      this.distribuidorForm.patchValue({
        distribuidorPrecioUnidadMedida: this.currencyPipe.transform(event.value, '$ ', 'symbol', '1.2-2'),
      });
    }
  }

  /**
   * Message box contador de letras
   */
  valueChange(value: string, textbox: string) {
    if (textbox === 'nombres') {
      this.remainingTextNombre = 50 - value.length - 1;
    } else if (textbox === 'descripcion') {
      this.remainingTextDescripcion = 300 - value.length - 1;
    }
  }

  /*************************** Recupera información para Selects de formulario **************************/
  /**
   * Consulta al back las organizaciones registradas
   * y las guarda en la lista para msotrarlas en el form
   */
  recuperarOrganizaciones() {
    this.rest
      .getJWT('organizacion')
      .toPromise()
      .then((resp: any) => {
        for (const aux of resp) {
          this.organizaciones.push(
            new Organizacion(aux.nombre, aux.correo, aux.logo, aux.solicitud_vinculacion, aux.marcas || [], aux._id)
          );
        }
      });
  }

  /**
   * Consulta al back las marcas que han sido registradas
   * y las guarda en la lista para mostrarlas en el form
   */
  recuperarMarcas() {
    this.rest
      .getJWT('marca_producto')
      .toPromise()
      .then((resp: any) => {
        for (const aux of resp) {
          this.marcas.push(new Marca(aux.nombre, aux._id));
        }
        this.marcas_filtradas = this.marcas;
        //Encontrar la marca que tiene registrada el producto y ponerla como marca seleccionada
        for (const aux of this.marcas_filtradas) {
          if (aux._id == this.producto?.marca_producto) {
            this.marca_seleccionada = aux;
          }
        }
      });
  }

  /**
   * Consulta al back las categorías de productos registradas
   * y las guarda en la lista para mostrarlas en el form
   */
  recuperarCategorias() {
    this.rest
      .getJWT('categoria_producto')
      .toPromise()
      .then((resp: any) => {
        this.categorias = resp.data;
        /** Lineas segun categoria */
        this.recuperarLineas(this.distribuidorForm?.get('distribuidorCategoria')?.value);
      });
  }

  /**
   * Consulta al back las lineas de productos registradas
   * y las guarda en la lsita para mostrarlas en el form
   */
  recuperarLineas(id_categoria: any) {
    this.lineas = this.categorias.find(
      (element: any) => element._id === this.distribuidorForm?.get('distribuidorCategoria')?.value
    )?.lineas_producto;
    if (this.lineas) {
      this.lineas = this.lineas.sort(function (a: any, b: any) {
        if (a.nombre === undefined) return 1;
        if (b.nombre === undefined) return -1;
        if (a.nombre === b.nombre) return 0;
        return a.nombre.toLowerCase() < b.nombre.toLowerCase() ? -1 : 1;
      });
    }
  }

  /******************************************* Guardar cambios ******************************************/
  /**
   * Guarda los cambios realizados por el usuario en el usuario y scroll de imagenes
   */
  async guardarCambios() {
    // eslint-disable-next-line prefer-const
    let validador = await this.validatePermissions();
    if (validador) {
      /****** Modal Generico cargando *****/
      this.openModal = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
      /** Gestiona las imagenes del producto **/
      const _id: string = this.prod_id;
      const upload_form: FormData = new FormData();
      let i = 1;
      for (const foto of this.file_fotos) {
        if (foto != undefined) {
          upload_form.append(`imagen${i}`, foto);
        }
        i++;
      }
      /** Si hay imagenes las guarda en AWS y luego actualiza producto */
      if (upload_form.has('imagen1') || upload_form.has('imagen2') || upload_form.has('imagen3')) {
        this.guardarFotos(_id, 'cod_ft', upload_form);
      } else {
        this.actualizarProducto();
      }
    }
  }

  /**
   * Guarda las imagenes de un un producto en AWS y retorna las URL para armar un array
   * de imagenes que se guardará en el objeto detalle del producto
   */
  guardarFotos(_id: any, cod_ft: any, upload_form: any) {
    this.rest
      .postJWT('recursos/producto/' + _id + '/' + cod_ft, upload_form)
      .toPromise()
      .then((resp: any) => {
        const nuevas_url: string[] = [];
        let i = 0;
        for (const str_path of this.fotos) {
          if (this.file_fotos[i] == undefined && typeof str_path === 'string') {
            nuevas_url.push(str_path);
          }
          i++;
        }
        for (const str_path of resp.logos) {
          nuevas_url.push(str_path);
        }
        this.producto!.fotos = nuevas_url;
        this.actualizarProducto();
      })
      .catch((err) => {
        this.openModal?.close();
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg =
          'Ocurrió un error cargando las nuevas fotos. Por favor, intenta de nuevo más tarde.';
        modalRef.componentInstance.btn_msg = 'Volver';
      });
  }

  /**
   * Envía la información ingresada por el usuario al back para
   * actualizar el producto, incluyendo las fotos ingresadas
   */
  actualizarProducto() {
    const _id: string = this.prod_id;
    /** Actualiza el objeto producto con la información del formulario */
    this.producto!.precios![0].precio_unidad = this.distribuidorForm
      .get('distribuidorPrecioUnidad')?.value.replace(/[^\d,-]/g, '');
    this.producto!.nombre = this.distribuidorForm.get('distribuidorProductoNombre')?.value;
    this.producto!.marca_producto = this.distribuidorForm.get('distribuidorMarca')?.value;
    this.producto!.codigo_organizacion = this.distribuidorForm.get('distribuidorOrganizacion')?.value;
    this.producto!.categoria_producto = this.distribuidorForm.get('distribuidorCategoria')?.value;
    this.producto!.linea_producto = this.distribuidorForm.get('distribuidorLinea')?.value;
    this.producto!.precios![0].unidad_medida = this.distribuidorForm.get('distribuidorUnidadMedida')?.value;
    this.producto!.precios![0].cantidad_medida = this.distribuidorForm.get('distribuidorCantidadMedida')?.value;
    this.producto!.precios![0].inventario_unidad = this.distribuidorForm.get('distribuidorInventarioUnidad')?.value;
    this.producto!.precios![0].inventario_caja = this.distribuidorForm.get('distribuidorInventarioCaja')?.value;
    this.producto!.precios![0].und_x_caja = this.distribuidorForm.get('distribuidorPresentacionCaja')?.value;
    this.producto!.descripcion = this.distribuidorForm.get('distribuidorProductoDescripcion')?.value;
    this.producto!.codigo_distribuidor_producto = this.distribuidorForm.get('distribuidorCodigoProducto')?.value;
    this.producto.prodDescuento = this.prodDescuento;
    this.producto.prodPorcentajeDesc = this.prodPorcentajeDesc;
    this.producto.prodBiodegradable = this.prodBiodegradable;
    this.producto.prodPedido = this.prodPedido;
    if (this.producto.estadoActualizacion === 'Pendiente') {
      this.producto.organizacion_manual = this.distribuidorForm.get('distribuidorOrganizacionManual')?.value;
      this.producto.categoria_manual = this.distribuidorForm.get('distribuidorCategoriaManual')?.value;
      this.producto.marca_manual = this.distribuidorForm.get('distribuidorMarcaManual')?.value;
      this.producto.linea_manual = this.distribuidorForm.get('distribuidorLineaManual')?.value;
    }
    if (!this.distribuidorForm.get('distribuidorOrganizacion')?.value) {
      delete this.producto.codigo_organizacion;
    }
    if (!this.distribuidorForm.get('distribuidorCategoria')?.value) {
      delete this.producto.categoria_producto;
    }
    if (!this.distribuidorForm.get('distribuidorMarca')?.value) {
      delete this.producto.marca_producto;
    }
    if (!this.distribuidorForm.get('distribuidorLinea')?.value) {
      delete this.producto.linea_producto;
    }
    if (this.producto.prodPorcentajeDesc === null) {
      this.producto.prodPorcentajeDesc = 0;
    }
    if (this.producto.prodDescuento && this.producto.prodPorcentajeDesc === 0) {
      this.openModal?.close();
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Debes agregar el porcentaje de descuento.';
      modalRef.componentInstance.btn_msg = 'Volver';
    } else {
      /** Actualiza el objeto producto en la base de datos */
      this.rest
        .putJWT('producto/' + _id, this.producto)
        .toPromise()
        .then((resp: any) => {
          this.openModal?.close();
          const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          modalRef.componentInstance.msg = '¡Tu producto ha sido actualizado con éxito!';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            this.activeModal.close('Close click');
            this.close_callback(this.producto, this.posProd);
          };
        })
        .catch((err) => {
          this.openModal?.close();
          const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg =
            'Ocurrió un error actualizando el producto. Por favor, intenta de nuevo más tarde.';
          modalRef.componentInstance.btn_msg = 'Volver';
        });
    }
  }
  volver() {
    this.activeModal.close('Close click');
    this.close_callback('', this.posProd);
  }
  /**
   * Activa o inactiva un producto
   * @param opcion Entrada para saber si el usuario quiere activar o desactivar el producto
   */
  async inactivarActivarProducto(opcion: string) {
    // eslint-disable-next-line prefer-const
    let validador = await this.validatePermissions();
    if (validador) {
      const _id: string = this.prod_id;
      try {
        /** Recupera la lista de productos del distribuidor */
        /*const productos_dist: any = await this.rest
        .getJWT(`productos_por_distribuidor/distribuidor/${this.auth.user_distribuidor?._id}`)
        .toPromise();
      const prods_ids: string[] = [];
      for (const aux of productos_dist.productos) {
        prods_ids.push(aux._id);
      }
      // Elimina el producto del array  de productos ID del distribuidor (AÚN NO DE LA BASE)
      const index = prods_ids.indexOf(this.prod_id);
      if (index > -1) {
        prods_ids.splice(index, 1);
      }*/
        // Activa o inactiva el prpoducto
        const modalRef = this.modalService.open(ConfirmacionComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.msg = `¿Estás seguro que deseas ${opcion} este producto?${
          opcion === 'inactivar'
            ? ', de hacerlo podrás volver a activar el producto pero este quedará con estado pendiente'
            : ''
        }`;
        modalRef.componentInstance.btn_msg_no = 'Cancelar';
        modalRef.componentInstance.btn_msg_yes = `Si, ${opcion}`;
        modalRef.componentInstance.callback_no = () => {};
        modalRef.componentInstance.callback_yes = () => {
          //Actualiza el objeto producto con el nuevo estado
          this.producto!.estadoActualizacion = opcion === 'inactivar' ? 'Inactivo' : 'Aceptado';
          this.rest
            .putJWT('producto/' + _id, this.producto)
            .toPromise()
            .then(() => {
              this.openModal?.close();
              const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
              modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
              modalRef.componentInstance.title = '¡Genial!';
              modalRef.componentInstance.msg = `¡Tu producto ha sido ${
                opcion === 'inactivar' ? 'desactivado' : 'Activado'
              } con éxito!`;
              modalRef.componentInstance.btn_msg = 'Listo';
              modalRef.componentInstance.close_callback = () => {
                window.location.reload();
              };
              // Se elimina el producto de la lista de productos ID del distribuidor en la base
              /*this.rest
              .putJWT(`productos_por_distribuidor/${productos_dist._id}`, { productos: prods_ids })
              .toPromise()
              .then(() => {
                this.openModal?.close();
                const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
                modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
                modalRef.componentInstance.title = '¡Genial!';
                modalRef.componentInstance.msg = `¡Tu producto ha sido ${
                  opcion === 'inactivar' ? 'desactivado' : 'activado (en estado pendiente)'
                } con éxito!`;
                modalRef.componentInstance.btn_msg = 'Listo';
                modalRef.componentInstance.close_callback = () => {
                  window.location.reload();
                };
              });*/
            });
        };
      } catch (error) {
        this.openModal?.close();
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg =
          'Ocurrió un error actualizando el producto. Por favor, intenta de nuevo más tarde.';
        modalRef.componentInstance.btn_msg = 'Volver';
      }
    }
  }

  /**************************************** Otras funcionalidades ***************************************/
  /**
   * Recibe un archivo del usuario y lo guarda
   * en el arreglo de imágenes del producto. Si
   * el archivo no tiene el formato correcto, no
   * se guarda y se muestra de error
   * @param event El evento generado al subir el archivo
   */
  handleFileInput(event: any) {
    this.error_formato_img = false;
    this.error_max_imgs = false;
    if (this.fotos.length == 3) {
      this.error_max_imgs = true;
      return;
    }
    const file: File = event.target.files[0];
    if (file != null && this.fotos.length < 3) {
      const file_split: string[] = file.name.split('.');
      const file_end: string = file_split[file_split.length - 1].trim().toLowerCase();
      if (this.supported_imgs.includes(file_end)) {
        //Se está intentando subir un archivo de imagen
        this.file_fotos.push(file);
        this.fotos.push(
          this.sanitizer.sanitize(
            SecurityContext.NONE,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.file_fotos[this.file_fotos.length - 1]!))
          ) || ''
        );
      } else {
        this.error_formato_img = true;
      }
    }
  }

  /**
   * Recorre el arreglo de fotos del producto y guarda las URLs para
   * usarlas en el template y permitir que se agreguen nuevas fotos
   * y se eliminen las que se quieran
   */
  extraerFotos() {
    if (this.producto && this.producto?.fotos) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      for (const str_foto of this.producto?.fotos) {
        this.file_fotos.push(undefined);
        this.fotos.push(str_foto);
      }
    }
  }

  /**
   * Elimina del arreglo de URLs la foto con el índice indicado,
   * y si fue una foto agregada, también elimina el archivo subido
   * @param index La posición en los arreglos de fotos y de archivos
   * a eliminar
   */
  eliminarFoto(index: number) {
    this.fotos.splice(index, 1);
    this.file_fotos.splice(index, 1);
    const nuevas_url: string[] = [];
    let i = 0;
    for (const str_path of this.fotos) {
      if (this.file_fotos[i] == undefined && typeof str_path === 'string') {
        nuevas_url.push(str_path);
      }
      i++;
    }
    this.producto!.fotos = nuevas_url;
  }
  /**
   * Método para verificar el tipo de usuario
   * @returns resultado boolean dependiente de validacion con el tipo de usuario
   */
  async validatePermissions() {
    // eslint-disable-next-line prefer-const
    let blockUser = await this.auth.validatePermissionsUser();
    return blockUser;
  }
  /**
   * Llama al modal de editar inventario del producto con la información
   * necesario, y con un callback que permita actualizar el inventario
   * en esta vista
   */
  async editarInventario(): Promise<void> {
    // eslint-disable-next-line prefer-const
    let validador = await this.validatePermissions();
    if (validador) {
      const modalRef = this.modalService.open(EditarInventarioProductoComponent, this.ngbModalOptions);
      modalRef.componentInstance.unidades = this.distribuidorForm.get('distribuidorInventarioUnidad')?.value;
      modalRef.componentInstance.und_x_caja = this.distribuidorForm.get('distribuidorPresentacionCaja')?.value;
      modalRef.componentInstance.foto = this.producto?.fotos?.[0];
      modalRef.componentInstance.producto = this.distribuidorForm.get('distribuidorProductoNombre')?.value;
      modalRef.componentInstance.descripcion = this.distribuidorForm.get('distribuidorProductoDescripcion')?.value;
      modalRef.componentInstance.precio_unidad = this.distribuidorForm
        .get('distribuidorPrecioUnidad')
        ?.value.replace(/[^\d,-]/g, '');
      modalRef.componentInstance.precio_caja = this.distribuidorForm
        .get('distribuidorPrecioCaja');
      modalRef.componentInstance.callback = (
        pCajas: number,
        pUnidades: number,
        precioCaja: number,
        precioUnidad: number
      ) => {
        this.distribuidorForm.patchValue({
          distribuidorInventarioUnidad: pUnidades,
          distribuidorInventarioCaja: pCajas,
          distribuidorPrecioCaja: this.currencyPipe.transform(precioCaja, '$ ', 'symbol', '1.0-0'),
          distribuidorPrecioUnidad: this.currencyPipe.transform(precioUnidad, '$ ', 'symbol', '1.0-0'),
        });
        this.actualizarProducto()
      };
    }
  }

  /**
   * Recibe cualquier string y devuelve el mismo string, pero
   * en minúsulcas y sin tildes ni diéresis
   * @param str El string a convertir
   * @returns El string convertido
   */
  toUnaccentedLowerCase(str: string): string {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  /**
   * Alerta si el formulario esta incompleto o un input es invalido
   */
  alertaFormularioInvalido() {
    const modalRef = this.modalService.open(SimpleComponent);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = '¡Por favor asegúrate de llenar todos los datos y vuelve a intentarlo!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {};
  }

  /**
   * Abre API Whatsapp para comunicarse con Feat
   */
  async goToLink() {
    // eslint-disable-next-line prefer-const
    let validador = await this.validatePermissions();
    if (validador) {
      const url = `https://api.whatsapp.com/send/?phone=${this.dataCambios.numero}&text=${this.dataCambios.mensaje}&type=phone_number&app_absent=0`;
      window.open(url, '_blank');
    }
  }

  goLinkFicha() {
    const url = `${this.producto.ficha_tecnica}`;
    window.open(url, '_blank');
  }

  handleFileFicha(event: any) {
    const file: File = event.target.files[0];

    if (file.size > 2000000) {
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = '¡Este archivo supera el máximo de 2MB permitido!';
      modalRef.componentInstance.btn_msg = 'Volver';
      return;
    } else if (file.name.slice(-4) != '.pdf') {
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = '¡Solo archivos PDF están permitidos!';
      modalRef.componentInstance.btn_msg = 'Volver';
      return;
    } else {
      const upload_form: FormData = new FormData();
      upload_form.append(`ficha_tecnica`, file);
      const modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
      this.rest.postLink(this.producto._id, upload_form).subscribe((resp: any) => {
        if (resp.success) {
          this.producto.ficha_tecnica = resp.logo_ruta;
          const update = {
            ficha_tecnica: this.producto.ficha_tecnica,
          };
          this.rest
            .putJWT(`productoAllCODFEAT/${this.producto?.codigo_ft}`, update)
            .toPromise()
            .then((resp: any) => {
              if (resp.success) {
                modalCarga.close();
                const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
                modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
                modalRef.componentInstance.title = '¡Enhorabuena!';
                modalRef.componentInstance.msg = 'La ficha se ha subido éxitosamente';
                modalRef.componentInstance.btn_msg = 'Ok';
                modalRef.componentInstance.close_callback = () => {};
              } else {
                modalCarga.close();
                this.mostrarMensajeError();
              }
            });
        }
      });
    }
  }

  private mostrarMensajeError() {
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {};
  }
}
