<app-header-horeca></app-header-horeca>
<div class="d-flex justify-content-center align-items-center flex-column">
  <div class="w-100 contenedor p-5" *ngIf="pedido">
    <!--Header-->
    <section class="wrapper-titulo mb-3">
      <div class="contenedor-titulos w-100 m-0">
        <div class="d-flex justify-content-start align-items-center rounded titulo-pedido">
          <div class="boton-volver rounded mr-3 cursorPointer" [routerLink]="['/pedidos']">
            <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }" id="fa-icon" class="mr-2"></fa-icon>
            <span>Volver</span>
          </div>
          <h3 class="m-1 m-sm-0" *ngIf="pedido">
            Pedido {{pedido.estado === 'Pendiente' ? 'por aprobar' : 'sugerido'}} ID: {{ pedido?.id_pedido }}
          </h3>
        </div>
        <div class="tarjeta rounded d-flex flex-row w-100" *ngIf="punto_entrega">
          <div class="col-2 d-flex justify-content-center align-items-center overflow-hidden p-0 rounded-left"
            id="icon-ubicacion">
            <img src="../../assets/img/icons/icon-pin-map.png" alt="Icono de un pin para señalar un punto en un mapa"
              srcset="" />
          </div>
          <div class="col-8 pr-0 d-flex justify-content-center align-items-center">
            <p class="p-card-ubicacion text-left w-100 sub-title m-0">
              {{  pedido?.punto_entrega.nombre }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <div class="row col-12 mt-4" *ngIf="pedido?.tiempo_tracking_hora == null">
      <div class="col-12 text-center">
        <img src="../../../assets/img/icon-warning-amarillo.png" alt="Advertencia" id="icon-warning" />
        <h5>Nada de nada</h5>
        <p>Este punto de entrega no tiene pedidos sugeridos, intenta con otro.</p>
      </div>
    </div>
    <!--Resumen-->
    <div class="contenedor-principal row mt-4" *ngIf="pedido?.tiempo_tracking_hora != null">
      <div class="contenedor-resumen">
        <div class="card">
          <div class="card-body p-4">
            <!--Sugerido-->
            <div class="row">
              <div class="col-11">
                <p>
                  <fa-icon [icon]="faCircle" size="sm" [styles]="{ color: '#FFFAF0' }" class="mr-2"></fa-icon>
                  {{ pedido?.estado }}
                  {{ pedido?.tiempo_tracking_hora | date: 'd/M/yyyy, hh:mm a' }}
                </p>
              </div>
            </div>
            <hr />
            <!--Distribuidor-->
            <div class="row mb-2">
              <div class="col-4 col-sm-3 col-xl-3">
                <img src="{{ distribuidor.distribuidor?.logo || distribuidor_placeholder }}" class="icon-dist rounded"
                  alt="logo {{ distribuidor?.nombre }}" />
              </div>
              <div class="col-8 col-sm-9 col-xl-9">
                <p>
                  {{ distribuidor.distribuidor?.nombre }}
                </p>
                <p class="pill-categoria">
                  {{ distribuidor.distribuidor?.tipo }}
                </p>
              </div>
            </div>
            <!--Vendedor-->
            <div class="row">
              <div class="col-6 text-left">
                <p>Vendedor:</p>
              </div>
              <div class="col-6 text-right">
                <p>{{ trabajatorDistribuidor }}</p>
              </div>
            </div>
            <!--Fecha creación-->
            <div class="row mb-1 mb-sm-4">
              <div class="col-6 text-left">
                <p>Fecha creación:</p>
              </div>
              <div class="col-6 text-right">
                <p>
                  {{ pedido?.fecha | date: 'd MMM y' }}
                </p>
              </div>
            </div>
            <hr />
            <!--Precio-->
            <div class="row">
              <div class="col-12">
                <p>Resumen</p>
              </div>
            </div>
            <div class="row">
              <div class="col-6 text-left">
                <p>Subtotal</p>
              </div>
              <div class="col-6 text-right">
                <p>
                  {{ pedido?.subtotal_pedido | currency: '$':'symbol':'1.0-0' }}
                </p>
              </div>
            </div>
            <div class="row mb-2 mb-sm-5">
              <div class="col-6 text-left">
                <h4>Total</h4>
              </div>
              <div class="col-6 text-right">
                <h4>{{ pedido?.total_pedido | currency: '$':'symbol':'1.0-0' }}</h4>
              </div>
            </div>
            <hr />
            <!--Detalles-->
            <div class="row">
              <div class="col-12">
                <p>Detalles</p>
              </div>
            </div>
            <div class="row">
              <div class="col-6 text-left">
                <p>Puntos FT que ganarías</p>
              </div>
              <div class="col-6 text-right">
                <p>
                  {{ pedido?.puntos_ganados }}
                </p>
              </div>
            </div>
            <hr />
            <!--Cargar pedido al carrito-->
            <div class="row">
              <div class="col-12">
                <button class="btn-cargar-carrito" (click)="agregarSugerido()">Cargar pedido al carrito</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Productos-->
      <div class="contenedor-productos">
        <div class="card">
          <div class="card-body p-4 my-0">
            <!--Productos - Unidades-->
            <div class="row">
              <div class="col-12 titulo-scroll mb-3">
                <p class="m-0">{{ total_productos }} productos - {{ total_und }} unidades</p>
              </div>
            </div>
            <hr />
            <!--Scroll de productos-->
            <div class="row">
              <div class="col-12">
                <div id="scroll-productos">

                  <!--Producto-->
                  <div class="producto" *ngFor="let item of productos; let i = index">
                    <div class="row mb-4">
                      <!--Imagen-->
                      <div class="
                                            col-12
                                            px-5
                                            pt-1
                                            px-sm-3
                                            col-xs-3 col-sm-2
                                            d-flex
                                            flex-column
                                            justify-content-start
                                            align-items-center
                                          ">
                        <div class="row carouselCont" *ngIf="item.promocion === true">
                          <ngb-carousel class="w-100">
                            <ng-template ngbSlide *ngFor="let img_src of item.fotos" class="w-100">
                              <div class="w-100 col-12 d-flex justify-content-center">
                                <img [src]="img_src[0]" class="img-prod rounded" [alt]="'Foto de ' + item.nombre" />
                              </div>
                            </ng-template>
                          </ngb-carousel>
                        </div>
                        <img *ngIf="item.promocion === false" src="{{ item.fotos || product_placeholder }}"
                          class="icon-producto" alt="'Foto de ' + {{ item?.nombre }}" />
                      </div>
                      <!--Descripción -->
                      <div class="col-12 col-xs-4 col-sm-7 d-flex flex-column justify-content-between">
                        <!-- Nombre -->
                        <p class="desc-prod text-right text-sm-left">
                          {{ item?.nombre }}
                        </p>
                        <!-- Precios -->
                        <div class="d-flex flex-column justify-content-between">
                          <!-- Precio Unidad -->
                          <p class="m-0" *ngIf="item.saldos === false">
                            {{ item.precios[0].precio_unidad | currency: '$ ':'symbol':'1.0-0' }}und.
                          </p>
                          <p class="m-0" *ngIf="item.saldos === true">
                            {{ item.precios[0].precio_descuento | currency: '$ ':'symbol':'1.0-0' }}und.
                          </p>
                          <!-- Precio Caja -->
                          <p class="m-0" *ngIf="item.promocion === false && item.saldos === false">
                            {{ item.precios[0].precio_caja | currency: '$ ':'symbol':'1.0-0' }}caja.
                          </p>
                          <!-- Precio por unidad de medida -->
                          <p class="m-0" *ngIf="item.promocion === false && item.saldos === false">
                            {{
                            item.precios[0].precio_unidad / item.precios[0].cantidad_medida
                            | currency: '$ ':'symbol':'1.0-0'
                            }}/{{ item.precios[0].unidad_medida }}
                          </p>
                          <p class="m-0" *ngIf="item.saldos === true">
                            {{
                            item.precios[0].precio_descuento / item.precios[0].cantidad_medida
                            | currency: '$ ':'symbol':'1.0-0'
                            }}/{{ item.precios[0].unidad_medida }}
                          </p>
                          <!-- Codigos del producto -->
                          <p class="desc-prod m-0" *ngIf="item.promocion === false">
                            Código distribuidor: {{ item?.codigo_distribuidor_producto }}
                          </p>
                          <p class="desc-prod m-0" *ngIf="item.promocion === true">
                            Código promoción: {{ item?.codigo_promo }}
                          </p>
                          <p class="desc-prod m-0" *ngIf="item.saldos === true">
                            Código saldo: {{ item?.codigo_promo }}
                          </p>
                          <!-- Puntos Feat -->
                          <p class="mt-2 desc-prod pts-feat" *ngIf="item.promocion === false && item.saldos === false">
                            {{ item?.precios[0].puntos_ft_unidad || 0 }} Pts FT
                          </p>
                        </div>
                      </div>
                      <!--Totales-->
                      <div class="col-12 col-sm-3 text-right d-flex flex-column justify-content-between pl-0">
                        <!-- Total unidades -->
                        <p class="desc-prod d-flex flex-column">
                          <strong class="m-0">
                            {{ item.unidad }} und{{ item.unidad !== 1 ? 's' : ''
                            }}.
                          </strong>
                          <span class="m-0 mt-2" *ngIf="item.promocion === false">
                            Total en cajas: {{ item.cajas_compradas || 0 }}
                          </span>
                        </p>
                        <!-- Total precio -->
                        <p class="desc-prod">
                          <strong *ngIf="item.saldos === false">
                            Total:
                            {{ item.precios[0].precio_unidad * item.unidad |
                            currency: '$ ':'symbol':'1.0-0'}}
                          </strong>
                          <strong *ngIf="item.saldos === true">
                            Total:
                            {{ item.precios[0].precio_descuento * item.unidad |
                            currency: '$ ':'symbol':'1.0-0'}}
                          </strong>
                        </p>
                      </div>
                    </div>
                    <hr />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>