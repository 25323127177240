import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { DistribuidorService } from '../../services/distribuidor/distribuidor.service';
import { Router } from '@angular/router';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-aprobarrechazar-distribuidores',
  templateUrl: './aprobarrechazar-distribuidores.component.html',
  styleUrls: ['./aprobarrechazar-distribuidores.component.css'],
})
export class AprobarrechazarDistribuidoresComponent implements OnInit {
  @Input() public idParam: any;
  @Input() public dataDistribuidor: any;
  @Input() public mensaje_aprobar: any;
  // icons
  faTimes = faTimes;

  updateData: any = {
    estado: 'Aprobado',
    vendedor: [],
    convenio: false,
    cartera: false,
  };

  constructor(
    public restService: RestService,
    public activeModal: NgbActiveModal,
    public distribuidorService: DistribuidorService,
    private router: Router
  ) {}

  close() {
    this.activeModal.close();
  }

  ngOnInit(): void {}

  /**Metodo para aprobar clientes */
  aprobarCliente() {
    const aprobar = this.updateData;
    if (!aprobar.vendedor || aprobar.vendedor.length === 0) {
      window.alert('Asigne al menos un vendedor');
      return;
    }
    this.distribuidorService.putDistribuidoresVinculados(this.idParam, aprobar).subscribe(() => {
      this.restService.putJWT(`notificar/trabajadores/horeca/generico`, this.mensaje_aprobar).toPromise();
      this.router.navigate(['/clientes']);
      this.close();
    });
  }

  /** Procesa el cambio temporal de los vendedores asignados a este establecimiento */
  public onVendedoresChange(event: any) {
    this.updateData.vendedor = event;
  }

  /** Procesa el cambio temporal de los vendedores asignados a este establecimiento */
  public onInfoAdicionalChange(event: any) {
    this.updateData.cartera = event.cartera.toString() === 'true' ? true : false;
    this.updateData.pazysalvo = event.cartera.toString() === 'true' ? false : true;
    this.updateData.convenio = event.convenio.toString() === 'true' ? true : false;
  }
}
