import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { GraficasService } from 'src/app/services/graficas-tablas/graficas.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-pedidos-graficas',
  templateUrl: './pedidos-graficas.component.html',
  styleUrls: ['./pedidos-graficas.component.css'],
})
export class PedidosGraficasComponent implements OnInit {
  // ID distribuidor
  public id_distribuidor = this.authService.user_distribuidor!._id;
  // Lista de meses para apoyar el formateo de la fecha
  public anos_filtro: any = [];
  public filtro_24_meses: any = [];
  private meses: string[] = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };
  // Biding HTML-Canvas y TS
  @ViewChild('pie_pedidos_estado', { static: true })
  private pie_pedidos_estado!: ElementRef;
  @ViewChild('pie_usuarios_estados', { static: true })
  private pie_usuarios_estados!: ElementRef;
  @ViewChild('bar_pedidos_entregados', { static: true })
  private bar_pedidos_entregados!: ElementRef;
  @ViewChild('bar_usuarios_establecimientos', { static: true })
  private bar_usuarios_establecimientos!: ElementRef;
  @ViewChild('bar_pedidos_tipo_negocio', { static: true })
  private bar_pedidos_tipo_negocio!: ElementRef;
  @ViewChild('bar_pedidos_establecimientos', { static: true })
  private bar_pedidos_establecimientos!: ElementRef;
  @ViewChild('bar_usuarios_productos', { static: true })
  private bar_usuarios_productos!: ElementRef;
  @ViewChild('bar_pedidos_ciudad', { static: true })
  private bar_pedidos_ciudad!: ElementRef;
  @ViewChild('bar_pedidos_estado_mes', { static: true })
  private bar_pedidos_estado_mes!: ElementRef;
  // Guarda datos de la talba y configuraciones
  public data_pie_pedidos_estado: any;
  public data_pie_usuarios_estados: any;
  public data_bar_pedidos_entregados: any;
  public data_bar_usuarios_establecimientos: any;
  public data_bar_pedidos_tipo_negocio: any;
  public data_bar_pedidos_establecimientos: any;
  public data_bar_usuarios_productos: any;
  public data_bar_pedidos_ciudad: any;
  public data_bar_pedidos_estado_mes: any;
  // Carga la grafica de chart JS
  public pie_chart_pedidos_estado: any;
  public pie_chart_usuarios_estados: any;
  public bar_chart_pedidos_entregados: any;
  public bar_chart_usuarios_establecimientos: any;
  public bar_chart_pedidos_tipo_negocio: any;
  public bar_chart_pedidos_establecimientos: any;
  public bar_chart_usuarios_productos: any;
  public bar_chart_pedidos_ciudad: any;
  public bar_chart_pedidos_estado_mes: any;
  // Total tabla
  public total_pedidos_estado: any;
  public total_usuarios_estados: any;
  public total_pedidos_entregados: any;
  public total_usuarios_establecimientos: any;
  public total_pedidos_tipo_negocio: any;
  public total_pedidos_establecimientosAnio: any;

  public total_usuarios_productos: any;
  public total_pedidos_ciudad: any;
  public total_pedidos_estado_mes: any;
  // Filtros graficas
  // public filtro_pedidos_estado: any;
  public filtro_usuarios_estados: any;
  public filtro_pedidos_entregados_ano: any;
  public filtro_pedidos_tipo_negocio: any;
  public filtro_pedidos_estblecimientos_anio: any;
  public filtro_usuarios_productos: any;
  public filtro_pedidos_ciudad: any;
  public filtro_pedidos_estado_mes: any;

  constructor(
    private restService: RestService,
    private router: Router,
    private ngxsStore: Store,
    private authService: AuthService,
    private modalService: NgbModal,
    private currency: CurrencyPipe,
    private graficasService: GraficasService
  ) {
    this.data_pie_pedidos_estado = {
      labels: [],
      datasets: [],
    };
    this.data_pie_usuarios_estados = {
      labels: [],
      datasets: [],
    };
    this.data_bar_pedidos_entregados = {
      labels: [],
      datasets: [],
    };
    this.data_bar_usuarios_establecimientos = {
      labels: [],
      datasets: [],
    };
    this.data_bar_pedidos_tipo_negocio = {
      labels: [],
      datasets: [],
    };
    this.data_bar_pedidos_establecimientos = {
      labels: [],
      datasets: [],
    };
    this.data_bar_usuarios_productos = {
      labels: [],
      datasets: [],
    };
    this.data_bar_pedidos_ciudad = {
      labels: [],
      datasets: [],
    };
    this.data_bar_pedidos_estado_mes = {
      labels: [],
      datasets: [],
    };
  }

  async ngOnInit() {
    this.id_distribuidor = await this.ngxsStore.snapshot().auth.user_distribuidor._id;
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    try {
      await this.getListaFiltroMeses();
      await this.getChartPedidosEstados();
      await this.getChartPedidosEntregadosMes();
      await this.getChartPedidosTipoNegocio();
      await this.getChartPedidosEstblecimientos();
      await this.getChartPedidosEstadoMes();
      await this.getChartPedidosCiudad();
      this.modalCarga?.close();
    } catch (error) {
      this.mostrarMensajeError();
    }
  }

  /**
   * Arma lista de los últimos 24 meses para el filtro de meses
   */
  getListaFiltroMeses() {
    const fecha_actual = new Date();
    let mes_actual = fecha_actual.getMonth();
    let ano_actual = fecha_actual.getFullYear();
    /** Filtro por años (últimos 10 años) */
    for (let index = 0; index < 10; index++) {
      this.anos_filtro.push(ano_actual - index);
    }
    this.filtro_pedidos_entregados_ano = this.anos_filtro[0];
    /** Filtro por meses y años */
    for (let index = 0; index < 23; index++) {
      if (mes_actual >= 0) {
        const data = {
          filtro: this.meses[mes_actual] + ', ' + ano_actual,
          filtro_inicio: this.meses[mes_actual - 2] + ', ' + ano_actual,
          crudo: Number(mes_actual + 1) + ',' + ano_actual + ',' + (mes_actual - 1) + ',' + ano_actual,
        };
        if (mes_actual <= 1) {
          data.filtro_inicio = this.meses[11 - (1 - mes_actual)] + ', ' + (ano_actual - 1);
          data.crudo = Number(mes_actual + 1) + ',' + ano_actual + ',' + (11 - mes_actual) + ',' + (ano_actual - 1);
        }
        this.filtro_24_meses.push(data);
        mes_actual = mes_actual - 1;
      } else {
        mes_actual = 11;
        ano_actual = ano_actual - 1;
      }
    }
    this.filtro_pedidos_tipo_negocio = this.filtro_24_meses[0].crudo;
    this.filtro_pedidos_estado_mes = this.filtro_24_meses[0].crudo;
    this.filtro_pedidos_ciudad = this.filtro_24_meses[0].crudo;
    this.filtro_pedidos_estblecimientos_anio = this.filtro_24_meses[0].crudo;
  }

  /**
   * Inicializa los datos de las gráficas y lo carga en el DOM
   */
  /***************** Estado actual de pedidos ****************/
  public async getChartPedidosEstados() {
    let total_pedidos_estado = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/pedidos/estados_actuales/${this.id_distribuidor}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          switch (element._id) {
            case 'Sugerido':
              element.pos = 0;
              break;
            case 'Pendiente':
              element.pos = 1;
              break;
            case 'Aprobado Interno':
              element.pos = 2;
              break;
            case 'Aprobado Externo':
              element.pos = 3;
              break;
            case 'Alistamiento':
              element.pos = 4;
              break;
            case 'Despachado':
              element.pos = 5;
              break;
            case 'Facturado':
              element.pos = 6;
              break;
            case 'Entregado':
              element.pos = 7;
              break;
            case 'Recibido':
              element.pos = 8;
              break;
            case 'Calificado':
              element.pos = 9;
              break;
            case 'Rechazado':
              element.pos = 10;
              break;
            case 'Cancelado por horeca':
              element.pos = 11;
              break;
            case 'Cancelado por distribuidor':
              element.pos = 12;
              break;
          }
        });
        tipos.data.sort((a: { pos: number }, b: { pos: number }) => a.pos - b.pos);
        tipos.data.forEach((element: any) => {
          if (element._id === 'Aprobado Interno') {
            element._id = 'Pendiente';
          }
          if (element._id === 'Aprobado Externo') {
            element._id = 'Aprobado';
          }
          this.data_pie_pedidos_estado.labels.push(element._id);
          this.data_pie_pedidos_estado.datasets.push(element.total);
          total_pedidos_estado = total_pedidos_estado + element.total;
        });
        this.total_pedidos_estado = total_pedidos_estado;
        /** Se crea la grafica */
        this.pie_chart_pedidos_estado = this.graficasService.createPieChart(
          this.pie_pedidos_estado,
          this.data_pie_pedidos_estado,
          'Estado actual de pedidos'
        );
      })
      .catch((err) => {
        throw err;
      });
  }
  /***************** Número de pedidos entregados por mes ****************/
  public async getChartPedidosEntregadosMes() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_pedidos_entregados) {
      this.bar_chart_pedidos_entregados.destroy();
      this.data_bar_pedidos_entregados.labels = [];
      this.data_bar_pedidos_entregados.datasets = [];
    }
    /** Se arma data para query */
    const first_day = this.filtro_pedidos_entregados_ano + '-01-01';
    const last_day = this.filtro_pedidos_entregados_ano + '-12-31';
    let total_pedidos_entregados = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/pedidos/entregados_x_mes/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_pedidos_entregados.labels.push(
            this.meses[element._id.split('-')[1] - 1] + '-' + element._id.split('-')[0]
          );
          this.data_bar_pedidos_entregados.datasets.push(element.total);
          total_pedidos_entregados = total_pedidos_entregados + element.total;
        });
        this.total_pedidos_entregados = total_pedidos_entregados;
        /** Se crea la grafica */
        this.bar_chart_pedidos_entregados = this.graficasService.createBarChart(
          this.bar_pedidos_entregados,
          this.data_bar_pedidos_entregados,
          'Número de pedidos entregados por mes',
          'Número de pedidos',
          'Meses'
        );
      })
      .catch((err) => {
        throw err;
      });
  }
  /***************** Número de pedidos por tipo de negocio ****************/
  public async getChartPedidosEstblecimientos() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_pedidos_establecimientos) {
      this.bar_chart_pedidos_establecimientos.destroy();
      this.data_bar_pedidos_establecimientos.labels = [];
      this.data_bar_pedidos_establecimientos.datasets = [];
    }
    /** Se arma data para query */
    const first_day =
      this.filtro_pedidos_estblecimientos_anio.split(',')[3] +
      '-' +
      this.filtro_pedidos_estblecimientos_anio.split(',')[2] +
      '-01';
    const last_day =
      this.filtro_pedidos_estblecimientos_anio.split(',')[1] +
      '-' +
      this.filtro_pedidos_estblecimientos_anio.split(',')[0] +
      '-31';
    this.total_pedidos_tipo_negocio = 0;
    let total_pedidos_establecimientosAnio = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/pedidos/entregados_x_mes_anio/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_pedidos_establecimientos.labels.push(element._id);
          this.data_bar_pedidos_establecimientos.datasets.push(element.total);
          total_pedidos_establecimientosAnio = total_pedidos_establecimientosAnio + element.total;
        });
        this.total_pedidos_establecimientosAnio = total_pedidos_establecimientosAnio;
        /** Se crea la grafica */
        this.bar_chart_pedidos_establecimientos = this.graficasService.createBarChart(
          this.bar_pedidos_establecimientos,
          this.data_bar_pedidos_establecimientos,
          'Número de pedidos por tipo de negocio',
          'Número de pedidos',
          'Tipos de negocio'
        );
      })
      .catch((err) => {
        throw err;
      });
  }
  /***************** Número de pedidos por tipo de negocio ****************/
  public async getChartPedidosTipoNegocio() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_pedidos_tipo_negocio) {
      this.bar_chart_pedidos_tipo_negocio.destroy();
      this.data_bar_pedidos_tipo_negocio.labels = [];
      this.data_bar_pedidos_tipo_negocio.datasets = [];
    }
    /** Se arma data para query */
    const first_day =
      this.filtro_pedidos_tipo_negocio.split(',')[3] + '-' + this.filtro_pedidos_tipo_negocio.split(',')[2] + '-01';
    const last_day =
      this.filtro_pedidos_tipo_negocio.split(',')[1] + '-' + this.filtro_pedidos_tipo_negocio.split(',')[0] + '-31';
    this.total_pedidos_tipo_negocio = 0;
    let total_pedidos_tipo_negocio = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/pedidos/tipo_negocio/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_pedidos_tipo_negocio.labels.push(element._id);
          this.data_bar_pedidos_tipo_negocio.datasets.push(element.total);
          total_pedidos_tipo_negocio = total_pedidos_tipo_negocio + element.total;
        });
        this.total_pedidos_tipo_negocio = total_pedidos_tipo_negocio;
        /** Se crea la grafica */
        this.bar_chart_pedidos_tipo_negocio = this.graficasService.createBarChart(
          this.bar_pedidos_tipo_negocio,
          this.data_bar_pedidos_tipo_negocio,
          'Número de pedidos por tipo de negocio',
          'Número de pedidos',
          'Tipos de negocio'
        );
      })
      .catch((err) => {
        throw err;
      });
  }
  /***************** Número de referencias en pedidos ****************/
  public async getChartUsuariosProductos() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_usuarios_productos) {
      this.bar_chart_usuarios_productos.destroy();
      this.data_bar_usuarios_productos.labels = [];
      this.data_bar_usuarios_productos.datasets = [];
    }
    /** Se arma data para query */
    const first_day =
      this.filtro_usuarios_productos.split(',')[3] + '-' + this.filtro_usuarios_productos.split(',')[2] + '-01';
    const last_day =
      this.filtro_usuarios_productos.split(',')[1] + '-' + this.filtro_usuarios_productos.split(',')[0] + '-31';
    this.total_usuarios_productos = 0;
    let total_usuarios_productos = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(
        `informes/distribuidor/equipo_comercial/productos_X_pedido/${this.id_distribuidor}/${first_day}/${last_day}`
      )
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_usuarios_productos.labels.push(element._id);
          this.data_bar_usuarios_productos.datasets.push(element.total);
          total_usuarios_productos = total_usuarios_productos + element.total;
        });
        this.total_usuarios_productos = total_usuarios_productos;
        /** Se crea la grafica */
        this.bar_chart_usuarios_productos = this.graficasService.createBarChart(
          this.bar_usuarios_productos,
          this.data_bar_usuarios_productos,
          'Número de referencias en pedidos',
          'Número de referencias promedio',
          'Usuarios comerciales'
        );
      })
      .catch((err) => {
        throw err;
      });
  }
  /***************** Número de pedidos por ciudad ****************/
  public async getChartPedidosCiudad() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_pedidos_ciudad) {
      this.bar_chart_pedidos_ciudad.destroy();
      this.data_bar_pedidos_ciudad.labels = [];
      this.data_bar_pedidos_ciudad.datasets = [];
    }
    /** Se arma data para query */
    const first_day = this.filtro_pedidos_ciudad.split(',')[3] + '-' + this.filtro_pedidos_ciudad.split(',')[2] + '-01';
    const last_day = this.filtro_pedidos_ciudad.split(',')[1] + '-' + this.filtro_pedidos_ciudad.split(',')[0] + '-31';
    this.total_pedidos_ciudad = 0;
    let total_pedidos_ciudad = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/pedidos/ciudad/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_pedidos_ciudad.labels.push(element._id);
          this.data_bar_pedidos_ciudad.datasets.push(element.total);
          total_pedidos_ciudad = total_pedidos_ciudad + element.total;
        });
        this.total_pedidos_ciudad = total_pedidos_ciudad;
        /** Se crea la grafica */
        this.bar_chart_pedidos_ciudad = this.graficasService.createBarChart(
          this.bar_pedidos_ciudad,
          this.data_bar_pedidos_ciudad,
          'Número de pedidos por ciudad',
          'Número de pedidos',
          'Ciudades'
        );
      })
      .catch((err) => {
        throw err;
      });
  }
  /***************** Estado Actual de pedidos por meses ****************/
  public async getChartPedidosEstadoMes() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_pedidos_estado_mes) {
      this.bar_chart_pedidos_estado_mes.destroy();
      this.data_bar_pedidos_estado_mes.labels = [];
      this.data_bar_pedidos_estado_mes.datasets = [];
    }
    /** Se arma data para query */
    const first_day =
      this.filtro_pedidos_estado_mes.split(',')[3] + '-' + this.filtro_pedidos_estado_mes.split(',')[2] + '-01';
    const last_day =
      this.filtro_pedidos_estado_mes.split(',')[1] + '-' + this.filtro_pedidos_estado_mes.split(',')[0] + '-31';
    this.total_pedidos_estado_mes = 0;
    let total_pedidos_estado_mes = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/pedidos/estados_actuales_x_mes/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((pedidos: any) => {
        for (const item of pedidos.data) {
          if (item._id === 'Aprobado Interno') {
            item._id = 'Pendiente';
          }
          if (item._id === 'Aprobado Externo') {
            item._id = 'Aprobado';
          }
        }
        // S
        // Se ordena por la fase del estado
        const sorted_data: any[] = [];
        const sugerido = pedidos.data.find((pedido: any) => pedido._id == 'Sugerido');
        sugerido ? sorted_data.push(sugerido) : '';
        const pendiente = pedidos.data.find((pedido: any) => pedido._id == 'Pendiente');
        pendiente ? sorted_data.push(pendiente) : '';
        const aprobado_interno = pedidos.data.find((pedido: any) => pedido._id == 'Aprobado Interno');
        aprobado_interno ? sorted_data.push(aprobado_interno) : '';
        const aprobado_externo = pedidos.data.find((pedido: any) => pedido._id == 'Aprobado');
        aprobado_externo ? sorted_data.push(aprobado_externo) : '';
        const alistamiento = pedidos.data.find((pedido: any) => pedido._id == 'Alistamiento');
        alistamiento ? sorted_data.push(alistamiento) : '';
        const despachado = pedidos.data.find((pedido: any) => pedido._id == 'Despachado');
        despachado ? sorted_data.push(despachado) : '';
        const facturado = pedidos.data.find((pedido: any) => pedido._id == 'Facturado');
        facturado ? sorted_data.push(facturado) : '';
        const entregado = pedidos.data.find((pedido: any) => pedido._id == 'Entregado');
        entregado ? sorted_data.push(entregado) : '';
        const recibido = pedidos.data.find((pedido: any) => pedido._id == 'Recibido');
        recibido ? sorted_data.push(recibido) : '';
        const calificado = pedidos.data.find((pedido: any) => pedido._id == 'Calificado');
        calificado ? sorted_data.push(calificado) : '';
        const rechazado = pedidos.data.find((pedido: any) => pedido._id == 'Rechazado');
        rechazado ? sorted_data.push(rechazado) : '';
        const cancelado_horeca = pedidos.data.find((pedido: any) => pedido._id == 'Cancelado por horeca');
        cancelado_horeca ? sorted_data.push(cancelado_horeca) : '';
        const cancelado_distri = pedidos.data.find((pedido: any) => pedido._id == 'Cancelado por distribuidor');
        cancelado_distri ? sorted_data.push(cancelado_distri) : '';
        for (const element of sorted_data) {
          this.data_bar_pedidos_estado_mes.labels.push(element._id);
          this.data_bar_pedidos_estado_mes.datasets.push(element.total);
          total_pedidos_estado_mes = total_pedidos_estado_mes + element.total;
        }
        this.total_pedidos_estado_mes = total_pedidos_estado_mes;
        /** Se crea la grafica */
        this.bar_chart_pedidos_estado_mes = this.graficasService.createBarChart(
          this.bar_pedidos_estado_mes,
          this.data_bar_pedidos_estado_mes,
          'Estado Actual de pedidos (tres meses)',
          'Número de pedidos',
          'Estados'
        );
      })
      .catch((err) => {
        throw err;
      });
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio-distribuidor']);
    };
  }
}
