<div class="container-fluid fondo overflow-hidden">
  <div class="row m-1 m-md-3 pl-md-5">
    <div class="col-12 col-lg-6 especificacion d-flex justify-content-center flex-column">
      <h2>Misión</h2>
      <p class="parrafo">
        Desarrollar tecnología que asegure la conexión, la información y digitalización del sector Gastronómico HoReCa,
        buscando articularlo para incrementar su productividad y competitividad, mejorando la calidad de vida de las
        personas con un impacto social, por medio de un trabajo en conjunto.
      </p>
    </div>
    <div class="col-12 col-lg-6 d-flex justify-content-center">
      <img src="../../../assets/img/Landing/ilustracion3-landing.png" class="img-landing"
        alt="flecha justo en el centro de un tablero o blanco">
    </div>
  </div>
</div>