<div class="graficas">
  <!-- Ventas 3 meses -->
  <div>
    <div class="d-flex flex-row justify-content-between">
      <p>Ventas acumuladas por distribuidor</p>
      <p class="m-0">Últimos 3 meses</p>
    </div>
    <div class="d-flex justify-content-start pt-1">
      Total: {{total_dist_ventas}}
    </div>
    <canvas #bar_dist_ventas class="mt-3 grafica"></canvas>
  </div>
  <!-- Ventas mes a mes -->
  <div>
    <span class="d-flex flex-row justify-content-between">
      <p>Ventas mes actual por distribuidor</p>
      <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_mes_actual"
        (ngModelChange)="getGraficaInformacionVentasMes('filtro')">
        <option [value]="ano" *ngFor="let ano of meses_ventas_distribuidor">
          {{ano}}
        </option>
      </select>
    </span>
    <div class="d-flex justify-content-start pt-1">
      Total: {{total_ventas_mes_actual}}
    </div>
    <canvas #bar_ventas_mes_actual class="mt-3 grafica"></canvas>
  </div>
  <!-- Productos por distribuidor -->
  <div>
    <div class="d-flex flex-row justify-content-between">
      <p class="m-0">No. Productos por distribuidor</p>
      <span>
        Total: {{total_productos_distribuidor}}
      </span>
    </div>
    <canvas #bar_productos_distribuidor class="mt-3 grafica"></canvas>
  </div>
  <!-- Vendedores por distribuidor -->
  <div>
    <div class="d-flex flex-row justify-content-between">
      <p class="m-0">No. De vendedores por distribuidor</p>
      <span>
        Total: {{total_vendedores_distribuidor}}
      </span>
    </div>
    <canvas #bar_vendedores_distribuidor class="mt-3 grafica"></canvas>
  </div>
  <!-- Puntos de entrega alcanzados -->
  <div>
    <span class="d-flex flex-row justify-content-between">
      <p>Establecimientos alcanzados</p>
    </span>
    <div class="d-flex justify-content-start pt-1">
      Total: {{total_puntos_distribuidor}}
    </div>
    <canvas #bar_puntos_distribuidor class="mt-3 grafica"></canvas>
  </div>
  <!-- Distribuidores por ciudad -->
  <div>
    <span class="d-flex flex-row justify-content-between">
      <p>Distribuidores por ciudad</p>
    </span>
    <div class="d-flex justify-content-start pt-1">
      Total: {{total_distribuidores_ciudad}}
    </div>
    <canvas #bar_distribuidores_ciudad class="mt-3 grafica"></canvas>
  </div>
  <!-- Ventas ciudad -->
  <div>
    <span class="d-flex flex-row justify-content-between">
      <p>Ventas por ciudad</p>
      <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_ciudad"
        (ngModelChange)="getVentasDistribuidorPorCiudad()">
        <option [value]="ciudad" *ngFor="let ciudad of ciudades_distribuidor">
          {{ciudad}}
        </option>
      </select>
    </span>
    <div class="d-flex justify-content-start pt-1">
      Total: {{total_ventas_ciudad}}
    </div>
    <canvas #pie_ventas_ciudad class="mt-3 grafica"></canvas>
  </div>
</div>
<!-- Manejo de errores -->
<ng-template #mostrarError>
  <div class="d-flex justify-content-center mt-4">
    <div>
      <img src="assets/img/icon-warning-amarillo.png" alt="icono de una hoja de papel y una lupa sobre ella"
        style="max-width: 150px;" />
    </div>
  </div>
  <div class="mt-2">
    <p class="text-center">
      ¡Lo siento! No existen datos para ver aquí
    </p>
  </div>
</ng-template>