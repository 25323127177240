<div class="contenedor">
  <div class="row">
    <div class="col-11 text-left">
      <h5>
        Actualiza el inventario del producto
      </h5>
    </div>
    <div class="col-1 text-right" style="cursor: pointer;" (click)="activeModal.close()">
      <fa-icon style="cursor: pointer;" [icon]="faTimes" size="lg">
      </fa-icon>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-12">
      <div class="card card-prod">
        <div class="card-body">
          <div class="row">
            <div class="col-3">
              <img [src]="foto || product_placeholder" class="img-prod" [alt]="'Foto de ' + producto">
            </div>
            <div class="d-flex justify-content-center align-items-center col-9">
              {{ producto }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-12">
      <p class="m-0">
        Escribe el número o utiliza las flechas
      </p>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-6">
      <p class="mb-2">
        Unidades
      </p>
      <div>
        <button class="btn_cantidad btn_menos" (click)="unidades = unidades - 1" [disabled]="unidades == 0">
          -
        </button>
        <input class="span_cnt" type="number" min="0" step="1" [(ngModel)]="unidades" />
        <button class="btn_cantidad btn_mas" (click)="unidades = unidades + 1">
          +
        </button>
      </div>
    </div>
    <div class="col-6 d-flex flex-column align-content-between justify-content-between">
      <p class="m-0">
        {{ und_x_caja }} unidad{{ und_x_caja != 1 ? 'es' : ''}} por caja
      </p>
      <p class="m-0">
        Total: {{ cajas }} caja{{ cajas != 1 ? 's' : ''}}
      </p>
    </div>
  </div>
  <div class="col-12 text-secondary p-0 mt-3 unidades-pendientes ">
    {{unidades_pendientes_caja}} und. pend. para completar otra caja
  </div>
  <div class="row">
    <div class="col-6">
      <p class="mb-2">
        Precio unidad
      </p>
      <div>
        <input type="number" min="0" step="1" [(ngModel)]="precio_unidad" (keydown)="validateNumber($event)" />
      </div>
    </div>
    <div class="col-6">
      <p class="mb-2">
        Precio caja
      </p>
      <div>
        <input type="number" min="0" step="1" [(ngModel)]="precio_caja" [disabled]="true" />
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-6">
      <!--Placeholder-->
    </div>
    <div class="col-6 text-center">
      <button class="btn-purple" (click)="actualizar()">
        Actualizar
      </button>
    </div>
  </div>
</div>