<div class="container">
  <div class="row">
    <div class="col-11">
      <h5>
        {{ title }}
      </h5>
    </div>
    <div class="col-1" style="cursor: pointer;">
      <fa-icon [icon]="faTimes" style="color: #41403E" size="lg" (click)="activeModal.close()">
      </fa-icon>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-12">
      <p>
        {{ instrucciones }}
      </p>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-12">
      <img [src]="img_src" alt="" />
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-8">
    </div>
    <div class="col-4">
      <button class="btn-purple" (click)="activeModal.close()">
        Listo
      </button>
    </div>
  </div>
</div>