import { Component, OnInit } from '@angular/core';
import { faTimes, faCheckCircle, faTrash, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmacionComponent } from 'src/app/modal/confirmacion/confirmacion.component';

@Component({
  selector: 'app-new-carrito',
  templateUrl: './new-carrito.component.html',
  styleUrls: ['./new-carrito.component.css'],
})
export class NewCarritoComponent implements OnInit {
  public newCa: any;
  public faTrash = faTrash;
  public faChevronDown = faChevronDown;
  public faChevronUp = faChevronUp;
  public faCheckCircle = faCheckCircle;
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'md',
  };
  constructor(private modalService: NgbModal) {
    this.newCa = [
      {
        dataDistribuidor: {
          logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOFYG7KtvelR3Kv0iJlQEnll4EFoBrOM_Mxw&usqp=CAU',
          nombre: 'Makro',
          convenio: true,
          paz: false,
          metodoPago: '',
          codigoDescuento: '',
        },
        productos: [
          {
            nombre: 'Pasta de Tomate Fruco Bolsa x 3 kg',
            imagen: 'https://feat-resources.s3.amazonaws.com/planes_membresias/FrucoPastaTomate30001.png',
            cantidad: 15,
            precioUn: 5000,
            precioTotal: 75000,
          },
          {
            nombre: 'Pasta de Tomate Fruco Bolsa x 3 kg',
            imagen: 'https://feat-resources.s3.amazonaws.com/planes_membresias/FrucoPastaTomate30001.png',
            cantidad: 15,
            precioUn: 5000,
            precioTotal: 75000,
          },
          {
            nombre: 'Pasta de Tomate Fruco Bolsa x 3 kg',
            imagen: 'https://feat-resources.s3.amazonaws.com/planes_membresias/FrucoPastaTomate30001.png',
            cantidad: 15,
            precioUn: 5000,
            precioTotal: 75000,
          },
          {
            nombre: 'Pasta de Tomate Fruco Bolsa x 3 kg',
            imagen: 'https://feat-resources.s3.amazonaws.com/planes_membresias/FrucoPastaTomate30001.png',
            cantidad: 15,
            precioUn: 5000,
            precioTotal: 75000,
          },
          {
            nombre: 'Pasta de Tomate Fruco Bolsa x 3 kg',
            imagen: 'https://feat-resources.s3.amazonaws.com/planes_membresias/FrucoPastaTomate30001.png',
            cantidad: 15,
            precioUn: 5000,
            precioTotal: 75000,
          },
          {
            nombre: 'Pasta de Tomate Fruco Bolsa x 3 kg',
            imagen: 'https://feat-resources.s3.amazonaws.com/planes_membresias/FrucoPastaTomate30001.png',
            cantidad: 15,
            precioUn: 5000,
            precioTotal: 75000,
          },
          {
            nombre: 'Salsa Tomate DP Fruco Cocineros x 1 kg',
            imagen: 'https://feat-resources.s3.amazonaws.com/planes_membresias/FrucoTomateCocinerosDP10001.png',
            cantidad: 20,
            precioUn: 2500,
            precioTotal: 50000,
          },
        ],
        subTotales: {
          total: 100000,
          subTotal: 125000,
          descuento: 25000,
        },
        procesado: false,
        idPedido: '',
        dataPunto: {
          nombre: 'Nombre punto',
        },
      },
      {
        dataDistribuidor: {
          logo: 'https://www.calypso.la/assets/icons/header/logo-calypso.png',
          nombre: 'Calypso',
          convenio: false,
          paz: true,
          metodoPago: '',
          codigoDescuento: '',
        },
        productos: [
          {
            nombre: 'Salsa Mayonesa Fruco Sachet 8 gr Bolsa x 102 und',
            imagen: 'https://feat-resources.s3.amazonaws.com/planes_membresias/FrucoMayonesaStickpack8161.png',
            cantidad: 10,
            precioUn: 5000,
            precioTotal: 50000,
          },
          {
            nombre: 'Detergente en Polvo Fab Pro x 6 kg',
            imagen: 'https://feat-resources.s3.amazonaws.com/productos/undefined-649c5f7c73a78100113c5b89-imagen1',
            cantidad: 20,
            precioUn: 2500,
            precioTotal: 50000,
          },
          {
            nombre: 'Detergente en Polvo Fab Pro x 6 kg',
            imagen: 'https://feat-resources.s3.amazonaws.com/productos/undefined-649c5f7c73a78100113c5b89-imagen1',
            cantidad: 20,
            precioUn: 2500,
            precioTotal: 50000,
          },
        ],
        subTotales: {
          total: 100000,
          subTotal: 125000,
          descuento: 25000,
        },
        procesado: false,
        idPedido: '',
        dataPunto: {
          nombre: 'Nombre punto',
        },
      },
    ];
  }

  ngOnInit(): void {}
  public eliminarDistri(pos: any) {
    const modalRef = this.modalService.open(ConfirmacionComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-alert-amarillo.png';
    modalRef.componentInstance.title =
      '¿Estas seguro de eliminar el pedido al distribuidor ' + this.newCa[pos].dataDistribuidor.nombre + '?';
    modalRef.componentInstance.msg =
      'Después de la eliminación de tu pedido, será necesario que realices un nuevo pedido desde cero.';
    modalRef.componentInstance.btn_msg_no = 'Cancelar';
    modalRef.componentInstance.btn_msg_yes = 'Eliminar pedido';
    modalRef.componentInstance.callback_no = () => {};
    modalRef.componentInstance.callback_yes = () => {
      this.cancelarPedidoDistribuidor(pos);
    };
  }
  public cancelarPedidoDistribuidor(pos: any) {
    this.newCa.splice(pos, 1);
  }
}
