import { Component, OnInit } from '@angular/core';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faCircle, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MultipasoFormPuntoVentaComponent } from 'src/app/modal/multipaso-form-punto-venta/multipaso-form-punto-venta.component';
import { PuntoEntrega } from 'src/app/models/punto_entrega.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-puntos-entrega',
  templateUrl: './puntos-entrega.component.html',
  styleUrls: ['./puntos-entrega.component.css'],
})
export class PuntosEntregaComponent implements OnInit {
  // Iconos FontAwesome para usar en el template
  public faMapMarkerAlt = faMapMarkerAlt;
  public faClock = faClock;
  public faCircle = faCircle;
  // Puntos de entrega asociados al usuario horeca
  public puntos_entrega: any[] = [];
  public puntos_entrega_desactivados: any[] = [];
  // Texto del horario de atención formateado para las cards
  public puntos_entrega_horarios: string[] = [];
  public puntos_entrega_desactivados_horarios: string[] = [];

  /**
   * Arreglos para facilitar el formateo del horario de atención
   * Si la posición 0 es true indica que se atiende el lunes, si es false
   * indica que no se atiende; si la posición 1 es true indica que se atiende
   * el martes, si es false indica que no se atiende; ... ;si la posición 7
   * es true indica que se atiende los festivos, si es false indica que no
   * se atiende.
   */
  public dias_atencion: boolean[] = [false, false, false, false, false, false, false, false];
  public dias_abreviados: string[] = ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom', 'Fes'];

  constructor(private restService: RestService, public authService: AuthService, private modalService: NgbModal) {}

  ngOnInit(): void {
    this.consultarPuntosDeEntrega();
  }

  /**
   * Toma el horario del punto de entrega y devuelve un string
   * formateado apropiadamente para la card del punto de entrega
   * @param dias Un arreglo con los días de atención del punto
   * @param horas Un string con las horas de atención
   * @returns Un string formateado para entrar en la card del punto
   */
  formatearHorario(dias: string[], horas: string) {
    let ans = '';

    this.flaggearDiasAtencion(dias);

    let i = 0;
    let lastIndex = -1; //Indica el índice del último día revisado
    let seAgregoRango = false; //Indica si en el texto ya se agregó algún rango (e.g. Mar-Vie)
    for (i = 0; i < this.dias_atencion.length; i++) {
      if (!seAgregoRango) {
        if (lastIndex == -1) {
          if (this.dias_atencion[i]) {
            ans += this.dias_abreviados[i];
            lastIndex = i;
          }
        } else if (lastIndex == i - 1) {
          //Hay días consecutivos
          if (this.dias_atencion[i]) {
            lastIndex = i;
          } else {
            if (ans != this.dias_abreviados[lastIndex]) {
              ans += `-${this.dias_abreviados[lastIndex]}`;
            }
            seAgregoRango = true;
          }
        }
      }
    }

    if (horas == '0:00 - 24:00') {
      horas = '24h';
    }
    ans += `, ${horas}`;
    return ans;
  }

  /**
   * Toma el arreglo de días de atención y levanta los flags
   * necesarios en la variable dias_atencion
   * @param dias Arreglo con los días de atención del punto
   */
  flaggearDiasAtencion(dias: string[]) {
    this.dias_atencion = [false, false, false, false, false, false, false, false];
    let i = 0;
    for (i = 0; i < dias.length; i++) {
      switch (dias[i]) {
        case 'Lunes':
          this.dias_atencion[0] = true;
          break;
        case 'Martes':
          this.dias_atencion[1] = true;
          break;
        case 'Miércoles':
          this.dias_atencion[2] = true;
          break;
        case 'Jueves':
          this.dias_atencion[3] = true;
          break;
        case 'Viernes':
          this.dias_atencion[4] = true;
          break;
        case 'Sábado':
          this.dias_atencion[5] = true;
          break;
        case 'Domingo':
          this.dias_atencion[6] = true;
          break;
        case 'Festivo':
          this.dias_atencion[7] = true;
          break;
        default:
          break;
      }
    }
  }

  /**
   * Consulta la lista de puntos de entrega para este usuario
   * y pobla las tarjetas con la información correspondiente
   */
  consultarPuntosDeEntrega() {
    this.puntos_entrega = [];
    this.puntos_entrega_desactivados = [];
    const id_horeca: string = this.authService.user_horeca?._id || '';
    this.restService
      .getJWT('punto_entrega/detalles/miniaturas/' + id_horeca)
      .toPromise()
      .then((resp: any) => {
        this.puntos_entrega = resp[0].activos ? resp[0].activos : [];
        this.puntos_entrega_desactivados = resp[0].desactivados ? resp[0].desactivados : [];
      });
  }

  /**
   * Lanza el modal de creación de punto de entrega
   */
  crearPuntoDeEntrega() {
    const ngbModalOptions3: NgbModalOptions = {
      centered: true,
      size: 'lg',
    };

    const modalRef = this.modalService.open(MultipasoFormPuntoVentaComponent, ngbModalOptions3);
    modalRef.result.finally(() => {
      this.consultarPuntosDeEntrega();
    });
  }
}
