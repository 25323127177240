<div class="contenedor-compra">
  <div class="card-container">
    <div class="mb-3">Historial de puntos acumulados</div>
    <div class="scroll-tabla">
      <ng2-smart-table [settings]="dataPuntos" [source]="sourceDataPuntos"></ng2-smart-table>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-4">
        <div>Puntos acumulados por mes</div>
        <div class="d-flex justify-content-end">
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_puntos_mes"
            (ngModelChange)="cargarInformacionPuntosMes()">
            <option [value]="ano" *ngFor="let ano of filtro_10_anos">
              {{ano}}
            </option>
          </select>
        </div>
      </div>
      <canvas #bar_puntos_mes [ngClass]="{'inactive': data_bar_puntos_mes.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_puntos_mes.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-4">
        <div>Puntos acumulados por distribuidor</div>
        <div class="d-flex justify-content-end">
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_puntos_distribuidor"
            (ngModelChange)="cargarInformacionPuntosDistribuidor()">
            <option [value]="ano" *ngFor="let ano of filtro_10_anos">
              {{ano}}
            </option>
          </select>
        </div>
      </div>
      <canvas #bar_puntos_distribuidor
        [ngClass]="{'inactive': data_bar_puntos_distribuidor.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_puntos_distribuidor.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-4">
        <div>Puntos acumulados por organización</div>
        <div class="d-flex justify-content-end">
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_puntos_organizacion"
            (ngModelChange)="cargarInformacionPuntosOrganizacion()">
            <option [value]="ano" *ngFor="let ano of filtro_10_anos">
              {{ano}}
            </option>
          </select>
        </div>
      </div>
      <canvas #bar_puntos_organizacion
        [ngClass]="{'inactive': data_bar_puntos_organizacion.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_puntos_organizacion.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <!-- Manejo de errores -->
  <ng-template #mostrarError>
    <div class="d-flex justify-content-center mt-4">
      <div>
        <img src="assets/img/icon-warning-amarillo.png" alt="icono de una hoja de papel y una lupa sobre ella"
          style="max-width: 150px;" />
      </div>
    </div>
    <div class="mt-2">
      <p class="text-center">
        ¡Lo siento! No existen datos para ver aquí
      </p>
    </div>
  </ng-template>
</div>