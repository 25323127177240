import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { faCircle, faSearch, faTimesCircle, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { LocalDataSource } from 'ng2-smart-table';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-historial-pagos-organizaciones',
  templateUrl: './historial-pagos-organizaciones.component.html',
  styleUrls: ['./historial-pagos-organizaciones.component.css'],
})
export class HistorialPagosOrganizacionesComponent implements OnInit {
  // Iconos
  public faSearch = faSearch;
  public faCircle = faCircle;
  public faTrash = faTrash;
  public faTimesCircle = faTimesCircle;
  public faEye = faEye;
  // ID organizacion
  public id_organizacion = this.auth.user_organizacion._id;
  // Variables tabla
  public settings_historial: any = {};
  public data_historial: any = [];
  public source_historial?: LocalDataSource;
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };

  constructor(
    private rest: RestService,
    private auth: AuthService,
    private datePipe: DatePipe,
    private currency: CurrencyPipe,
    private ngxsStore: Store,
    private modalService: NgbModal
  ) {}

  async ngOnInit() {
    this.id_organizacion = await this.ngxsStore.snapshot().auth.user_organizacion._id;
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    try {
      await this.configSmartTableHistorial();
      await this.getDatosTablaHistorial();
      this.modalCarga?.close();
    } catch (error) {
      this.mostrarMensajeError();
    }
  }

  /**
   * Configura los titulos y demas settings de la tabla
   * para recibir información de la base de datos
   */
  private configSmartTableHistorial(): void {
    this.settings_historial = {
      pager: {
        display: true,
        perPage: 10,
      },
      actions: {
        columnTitle: 'Acción',
        add: false,
        edit: false,
        delete: false,
      },
      hideSubHeader: false,
      columns: {
        fecha: {
          title: 'Fecha',
          editable: false,
          filter: true,
        },
        monto: {
          title: 'Montos',
          editable: false,
          filter: true,
        },
        detalle: {
          title: 'Detalles',
          editable: false,
          filter: true,
        },
        // metodo_pago: {
        //   title: 'Metodo de pago',
        //   editable: false,
        //   filter: true,
        // },
        estado: {
          title: 'Estado',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  private getDatosTablaHistorial(): void {
    this.rest
      .getJWT('bolsa_puntos_organizacion/informe/' + this.id_organizacion)
      .toPromise()
      .then((resp: any) => {
        console.log(resp);
        resp.forEach((fila: any) => {
          const obj_pedido = {
            fecha: this.datePipe.transform(fila.fecha, 'yyyy-MM-dd'),
            monto: this.currency.transform(fila.monto != undefined ? fila.monto : 0, '$ ', 'symbol', '1.0-0'),
            detalle:
              'Compra de ' +
              fila.cantidad_paquetes +
              ' paquete(s) de ' +
              fila.puntos_ft_paquete +
              ' puntosFt para un total de: ' +
              fila.total_puntos_ft +
              ' puntosFt',
            metodo_pago: fila.metodo_pago,
            estado: fila.estado,
          };
          this.data_historial.push(obj_pedido);
        });
        this.source_historial = new LocalDataSource(this.data_historial);
      })
      .catch((err) => {
        throw err;
      });
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {};
  }
}
