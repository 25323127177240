<div id="top-header" class="col-12 d-flex flex-row align-items-center sticky-top">
  <div class="col-8 d-flex justify-content-start" style="font-size: 14px;"></div>
  <div class="col-4 d-flex justify-content-between">
    <div><img src="../../../assets/img/Footer/Grupo 54.png" class="redes-sociales" alt="icono de Facebook"
        (click)="verDocumento('https://www.facebook.com/feat.B4B')"></div>
    <div><img src="../../../assets/img/Footer/Grupo 53.png" class="redes-sociales" alt="icono de Twitter"
        (click)="verDocumento('https://twitter.com/Feat_b4b')"></div>
    <div><img src="../../../assets/img/Footer/Grupo 52.png" class="redes-sociales" alt="icono de Instagram"
        (click)="verDocumento('https://www.instagram.com/feat.b4b/')"></div>
  </div>
</div>
<!-- 
  Nuevo componente
-->
<nav class="navbar col-12 navbar-expand-md navbar-dark sticky-top fondo sticky-top">
  <a class="logo-menu col-8 col-md-4">
    <img src="../../assets/img/logo-feat-largo.png" class="logo" alt="FEAT Logo Horizontal" />
  </a>
  <button class="navbar-toggler col-4 hidden-sm-up border-none" type="button"
    (click)="isMenuCollapsed = !isMenuCollapsed">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div [ngbCollapse]="isMenuCollapsed" class="col-md-8 collapse navbar-collapse" id="navbarsDefault">
    <ul class="navbar-nav fondo me-auto mb-2 mb-lg-0 h-100">
      <li class="nav-item">
        <a class="nav-link text-header" routerLink="/home" (click)="isMenuCollapsed = true"
          routerLinkActive="active-link">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-header" routerLink="/blog" (click)="isMenuCollapsed = true"
          routerLinkActive="active-link">Blog</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-header" routerLink="/login" (click)="isMenuCollapsed = true"
          routerLinkActive="active-link">Ingresa</a>
      </li>
    </ul>
  </div>
</nav>