import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-organizaciones-landing',
  templateUrl: './organizaciones-landing.component.html',
  styleUrls: ['./organizaciones-landing.component.css'],
})
export class OrganizacionesLandingComponent implements OnInit {
  /** Guarda los banners publicitarios */
  public banners: any;

  constructor(private restService: RestService) {}

  ngOnInit(): void {
    this.getBanners();
  }

  /** Devuelve los banners del home page */
  async getBanners() {
    this.banners = await this.restService.get('banner_landing').toPromise();
  }

  public openBlog(blog: any) {
    window.open(blog.link, '_blank');
  }
}
