<app-header-horeca></app-header-horeca>
<div class="container" *ngIf="producto">
  <div>
    <div [routerLink]="['/distribuidores', id]" class="px-5">
      <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
      <span>Volver</span>
    </div>
    <span>
      <span *ngIf="producto.saldos === true || producto.promocion === true">({{producto.saldos === true ? "Saldo" :
        "Promoción"}})</span> {{ producto?.nombre }}
    </span>
  </div>
  <div class="detalles-container">
    <div class="d-flex flex-column img-container h-100">
      <!-- Fotos producto -->
      <div class="">
        <div *ngFor="let foto of producto?.fotos" class="img-extra cursorPointer"
          style="background-image: url( '{{ foto }}' )" (click)="foto_principal = foto"></div>
      </div>
      <div class="container-discount-text" *ngIf=" producto?.descuentosEspeciales.length === 0">
        <div
          *ngIf="producto?.prodDescuento && producto?.prodPorcentajeDesc > 0"
          class="container-discount discount-img">
          <span>-{{ producto.prodPorcentajeDesc }}%</span>
        </div>
      </div>
      <div class="container-discount-text" *ngIf=" producto?.descuentosEspeciales.length > 0">
        <div
        *ngIf=" producto?.descuentosEspeciales[0].operacionApliada === 'descuento'"
          class="container-discount discount-img">
          <span>-{{producto?.descuentosEspeciales[0].aplicado }}%</span>
        </div>
      </div>
      <div class="img-principal h-75 py-4" style="background-image: url('{{ foto_principal }}')"></div>
      <!-- Inventario disponible -->
      <div class="h-25 d-flex align-items-center separacion-favoritos">
        <div class="inventario-pill">
          Quedan {{ inventario_maximo - order.products[index_producto_carrito]?.unidad }} unidades
        </div>
        <div
          *ngIf="horecaId && !producto?.establecimientos_interesados.includes(horecaId)"
          class="contenido-producto-favorito"
          (click)="actualizarProductoFavorito('agregar')">
          Agregar a favoritos
        </div>
        <div
          *ngIf="horecaId && producto?.establecimientos_interesados.includes(horecaId)"
          class="contenido-producto-favorito"
          (click)="actualizarProductoFavorito('quitar')">
          Quitar de favoritos
        </div>
      </div>
    </div>
    <div class="d-flex flex-column info-producto">
      <!-- Códigos producto -->
      <div>
        <div class="w-100 py-2" *ngIf="producto.promocion === false">
          <span>Código distribuidor</span>
          <span>{{ producto?.codigo_distribuidor_producto || '' }}</span>
        </div>
        <div class="w-100 py-2" *ngIf="producto.saldos === false && producto.promocion === false">
          <span>Código FEAT</span>
          <span>{{ producto?.codigo_ft }}</span>
        </div>
        <div class="w-100 py-2" *ngIf="producto.saldos === false && producto.promocion === false">
          <span>Código Org</span>
          <span>{{ producto?.codigo_organizacion_producto  }}</span>
        </div>
        <div class="w-100 py-2" *ngIf="producto.saldos === true || producto.promocion === true">
          <span>Código {{producto.saldos === true ? "saldo" : "promoción"}}</span>
          <span>{{ producto?.codigo_promo || '' }}</span>
        </div>
      </div>
      <div class="h-100">
        <div>
          <span *ngIf="vinculacion">
            Recuerda que {{estado_vinculacion_punto_distribuidor.convenio === true ? '' : 'no'}} tienes convenio con
            este distribuidor,
            {{estado_vinculacion_punto_distribuidor.convenio === true ? 'continuarás' : 'no contarás'}} con
            los precios originales que manejan
          </span>
        </div>
        <div class="data-pedido">
          <div *ngIf="producto?.descuentosEspeciales.length > 0">
            <div>
              <!-- Precio normal -->
              <span>Precio por unidad</span>
              <span >
                {{ producto?.descuentosEspeciales[0].precioActual | currency: "$":"symbol":"1.0-0" }}
              </span>
             
            </div>
            <div *ngIf="producto?.descuentosEspeciales[0].operacionApliada === 'descuento'">
              <span>Precio anterior</span>
              <span class="descuento">
                {{ producto?.descuentosEspeciales[0].precioAnterior | currency: "$":"symbol":"1.0-0" }}
              </span>
            </div>
            <div>
              <span>Precio por caja x {{producto.precios[0].und_x_caja}}</span>
             
              <span>
                {{ calcularPrecioCajaEspecial(producto) | currency: 'COP':'symbol-narrow':'1.0-0' }}
              </span>
            </div>
            <div>
              <span>
                Precio por {{producto?.precios[0].unidad_medida}}
              </span>
              <span *ngIf="producto.saldos === false && !producto?.prodDescuento">
                {{ producto?.descuentosEspeciales[0].precioActual / producto?.precios[0].cantidad_medida | currency:
                "$":"symbol":"1.2-2"}}
              </span>
            </div>









          </div>
          <div *ngIf=" producto?.descuentosEspeciales.length === 0">
            <!-- Precios -->
            <div>
              <!-- Precio normal -->
              <span>Precio por unidad</span>
              <span *ngIf="producto.saldos === false && !producto?.prodDescuento">
                {{ producto?.precios[0].precio_unidad | currency: "$":"symbol":"1.0-0" }}
              </span>
              <span *ngIf="producto.saldos === false && producto?.prodDescuento && producto?.prodPorcentajeDesc > 0">
                {{ ((producto?.precios[0]?.precio_unidad - (producto?.precios[0]?.precio_unidad * (producto?.prodPorcentajeDesc / 100))) | currency: 'COP':'symbol-narrow':'1.0-0') }}
              </span>
              <span *ngIf="producto.saldos === true">
                <!-- Precio para saldos -->
                {{ producto?.precios[0].precio_descuento | currency: "$":"symbol":"1.0-0" }}
              </span>
            </div>
            <!-- Precio anterior (solo en saldos) -->
            <div *ngIf="producto.saldos === true || (producto?.prodDescuento && producto?.prodPorcentajeDesc > 0)">
              <span>Precio anterior</span>
              <span class="descuento">
                {{producto?.precios[0].precio_unidad | currency: "$":"symbol":"1.0-0"}}
              </span>
            </div>
            <!-- Precio caja -->
            <div *ngIf="producto.saldos === false && producto.promocion === false">
              <span>Precio por caja x {{producto.precios[0].und_x_caja}}</span>
              <span *ngIf="!producto?.prodDescuento">
                {{
                  producto?.precios[0].precio_caja
                  | currency: "$":"symbol":"1.0-0"
                }}
              </span>
              <span *ngIf="producto?.prodDescuento && producto?.prodPorcentajeDesc > 0">
                {{ calcularPrecioCaja(producto) | currency: 'COP':'symbol-narrow':'1.0-0' }}
              </span>
            </div>
            <!-- Precio unidad medida -->
            <div *ngIf="producto.promocion === false">
              <span>
                Precio por {{producto?.precios[0].unidad_medida}}
              </span>
              <span *ngIf="producto.saldos === false && !producto?.prodDescuento">
                {{ producto?.precios[0].precio_unidad / producto?.precios[0].cantidad_medida | currency:
                "$":"symbol":"1.2-2"}}
              </span>
              <span *ngIf="producto.saldos === false && producto?.prodDescuento && producto?.prodPorcentajeDesc">
                {{
                  (producto?.precios[0]?.precio_unidad - (producto?.precios[0]?.precio_unidad * (producto?.prodPorcentajeDesc / 100))) / producto?.precios[0]?.cantidad_medida
                  | currency: 'COP':'symbol-narrow':'1.0-2'
                }}
              </span>
              <span *ngIf="producto.saldos === true">
                {{ producto?.precios[0].precio_descuento / producto?.precios[0].cantidad_medida | currency:
                "$":"symbol":"1.2-2"}}
              </span>
            </div>
            <!-- Fecha vencimiento (solo en saldos) -->
            <div *ngIf="producto.saldos === true">
              <span>Fecha de vencimiento</span>
              <span>
                {{ procesarFechaVencimiento(this.producto.fecha_vencimiento)}}
              </span>
            </div>
            <!-- Productos en promoción (solo en promos) 
            <div *ngIf="productos_promocion.length > 0" class="d-flex flex-column">
              <span>Productos en promoción</span>
              <ul>
                <li *ngFor="let prom of productos_promocion">
                  {{ prom.nombre }} | Cód. prod.: {{ prom.codigo_distribuidor_producto }}
                </li>
              </ul>
            </div>-->
            <!-- Productos en promoción (solo en promoción) -->
            <div *ngIf="producto.saldos === true">
              <span>Fecha de vencimiento</span>
              <span>
                {{ procesarFechaVencimiento(this.producto.fecha_vencimiento)}}
              </span>
            </div>
          </div>
          <div>
            <div
              *ngIf="producto?.prodPedido"
              class="indicator indicator-on-order">
              <img class="align-img-product-indicator" src="./assets/img/icons/box-open.png" alt="Icon box">
              Encargo bajo pedido
            </div>
            <div
              class="indicator indicator-bio"
              *ngIf="producto?.prodBiodegradable">
              <img class="align-img-product-indicator" src="./assets/img/icons/recycle.png" alt="Icon box">
              Producto biodegradable
            </div>
            <div *ngIf="producto?.ficha_tecnica" class="indicator">
              <fa-icon
                [icon]="faFilePdf"
                [styles]="{ color: '#8e6ff7', 'font-size': '17px' }"
                id="fa-eye"></fa-icon>
              <a href="{{producto.ficha_tecnica}}" class="ficha-label" target="_blank" rel="noopener">
                <span class="texto-ficha">Ver ficha técnica</span>
              </a>
            </div>
          </div>
          <!-- Botones carrito -->
          <div class="botones mt-3">
            <div class="agregar" *ngIf="!flag_producto_agregado">
              <span
                (click)="producto?.precios[0].inventario_unidad > 0 && agregarProduct(producto, 1, 0, (producto.precios[0].und_x_caja || 1), (producto.precios[0].inventario_unidad || 0))">
                + agregar
              </span>
            </div>
            <div class="suma" *ngIf="flag_producto_agregado">
              <div>
                <button
                  (click)="producto?.precios[0].inventario_unidad > 0 && quantity(false, producto?.index - 1, 'unidad')">-</button>
                  <span>
                    <input
                      type="number"
                      name=""
                      class="input-producto"
                      [(ngModel)]="order.products[index_producto_carrito].unidad"
                      (ngOnChanges)="quantity(true, producto?.index - 1, 'unidad')" 
                      />
                  </span>
                
                  <span hidden>{{ order.products[index_producto_carrito]?.unidad }}</span>
                <button
                  (click)="producto?.precios[0].inventario_unidad > 0 && quantity(true, producto?.index - 1, 'unidad')">+</button>
              </div>
            </div>
            <div>
              <div class="total-cajas">
                <span class="m-0">Total en cajas: &nbsp; </span>
                <span>{{totalCajasPedido(producto?.precios[0].und_x_caja,
                  order.products[index_producto_carrito]?.unidad)}}</span>
              </div>
            </div>
          </div>
          <!-- Alerta sin inventario -->
          <div class="w-100" *ngIf="producto?.precios[0].inventario_unidad <= 0">
            <p class="text-secondary text-danger w-100 text-center m-0">
              Sin unidades disponibles
            </p>
          </div>
          <hr />
          <!-- Descripción producto -->
          <div *ngIf="producto.productos_promocion.length > 0" >
            <div *ngIf="productos_promocion.length > 0" class="d-flex flex-column">
              <span>Productos en promoción:</span>
            
            </div>
            <hr />
            <div *ngFor="let item of producto.productos_promocion; let i = index">
              <span class="font-bold">Nombre: </span><span>{{ item?.nombre }}</span><br>
              <span class="font-bold">Marca: </span><span>{{ item?.marca_producto.nombre }}</span><br>
              <span class="font-bold">Cantidad: </span><span>{{ producto?.productos_promocion_inventario_unidades[i] }}</span><br>
              <hr />
            </div>
        
          </div>
          <div *ngIf="producto.productos_promocion.length === 0" >
            <span class="font-bold">Sobre este producto: </span><br>
            <span>{{ producto?.descripcion }}</span><br>
            <span class="font-bold">Marca: </span><span>{{ producto?.marca_producto.nombre }}</span><br>
            <span class="font-bold">Línea: </span><span>{{ producto?.linea_producto[0].nombre }}</span><br>
            <span class="font-bold">Unidades x Caja:</span> <span>{{ producto?.precios[0].und_x_caja }}</span><br>
            <span class="font-bold">Unidad de Medida:</span> <span>{{ producto?.precios[0].unidad_medida }}</span>
          </div>
          <!-- Continuar comprando -->
          <div>
            <button class="space-between seguir-comprando" routerLink="/carrito">
              <fa-icon [icon]="faShoppingCart" [styles]="{ color: '#fff' }" id="fa-icon"></fa-icon>
              <span>
                Carrito ({{productcounter}} producto{{productcounter === 1 ? '' : 's'}})
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>