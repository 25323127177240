import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-distribuidores',
  templateUrl: './distribuidores.component.html',
  styleUrls: ['./distribuidores.component.css'],
})
export class DistribuidoresComponent implements OnInit {
  buttonActive = 'Distribuidores';

  sectionActive(buttonActive: string) {
    this.buttonActive = buttonActive;
  }

  constructor() {}

  ngOnInit(): void {}
}
