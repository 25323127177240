import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MultipasoComponent } from '../../modal/multipaso/multipaso.component';
import { SimpleNoImgComponent } from '../../modal/simple-no-img/simple-no-img.component';
import { AuthService } from '../../services/auth/auth.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { RestService } from '../../services/rest/rest.service';
import { Store } from '@ngxs/store';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { faBell, faCommentDots, faBuilding, faHeart, faComment } from '@fortawesome/free-regular-svg-icons';
import { Chart } from 'chart.js';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SetSelectedToken } from 'src/app/actions/token.actions';
import { GraficasService } from 'src/app/services/graficas-tablas/graficas.service';
import { Component, AfterViewInit, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-inicio-organizacion',
  templateUrl: './inicio-organizacion.component.html',
  styleUrls: ['./inicio-organizacion.component.css'],
})
export class InicioOrganizacionComponent implements OnInit {
  public imgBanner: any = [];
  public publicaciones: any = [];
  // Indicadores
  public indicadores: any;
  public indicadores_notificaciones_total = 0;
  public indicadores_mensajes_nuevos_total = 0;
  public indicadores_clientes_total = 0;
  public indicadores_regiones_total = 0;
  public indicadores_ciudades_total = 0;
  public indicadores_productos_total = 0;
  // Indicadores puntosFT
  public puntos_paquetes: any;
  public puntos_disponibles: any;
  public puntos_usados: any;
  // Usuer logueado
  public id_user: any;
  //Imagen producto placeholder
  public logo_dummy = '../../../../../assets/img/icon-organizacion.png';
  // Configuracion del carousel para los banners
  public images = [62, 83, 466, 965, 982, 1043, 738].map((n) => `https://picsum.photos/id/${n}/900/500`);
  public paused = false;
  public unpauseOnArrow = false;
  public pauseOnIndicator = false;
  public pauseOnHover = true;
  public pauseOnFocus = true;
  public dataUser = this.ngxsStore.snapshot().auth.user;
  public dataOrg = this.ngxsStore.snapshot().auth.user_organizacion;
  // Referencias a íconos FontAwesome para la UI
  public faPen = faPen;
  public faBell = faBell;
  public faCommentDots = faCommentDots;
  public faBuilding = faBuilding;
  public faHeart = faHeart;
  public faComment = faComment;
  // Plan org
  public plan_actual: any;
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;
  // ID establecimiento
  public id_organizacion = this.auth.user_organizacion._id;
  // Colores para las gráficas
  public colors = [
    'rgba(115, 113, 156, 1)',
    'rgba(199, 195, 239, 1)',
    'rgba(247, 234, 194, 1)',
    'rgba(250, 211, 157, 1)',
    'rgba(179, 179, 210, 1)',
    'rgba(142, 111, 247, 1)',
  ];
  public colors_pie = ['rgba(247, 234, 194, 1)', 'rgba(250, 211, 157, 1)'];
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };
  // Carousel bienvenida
  @ViewChild('carousel', { static: true }) carousel!: NgbCarousel;
  // Total grafica
  public total_chart_ciudad = 0;
  public total_chart_negocio = 0;
  public total_chart_categoria = 0;
  public total_chart_sillas_ciudad = 0;
  public total_chart_sillas_negocio = 0;
  public total_chart_sillas_categoria = 0;
  public totalProductos = 0;
    // Biding HTML-Canvas y TS


    //@ViewChild('bar_establecimiento_ciudad') bar_establecimiento_ciudad2!: ElementRef;

    // Guarda datos de la talba y configuraciones
    public data_bar_establecimiento_ciudad: any;
    public bar_chart_establecimiento_ciudad: any;
    public data_bar_establecimiento_negocio: any;
    public bar_chart_establecimiento_negocio: any;
    public data_bar_establecimiento_categoria: any;
    public bar_chart_establecimiento_categoria: any;
    public data_bar_sillas_ciudad: any;
    public bar_chart_sillas_ciudad: any;
    public data_bar_sillas_negocio: any;
    public bar_chart_sillas_negocio: any;
    public data_bar_sillas_categoria: any;
    public bar_chart_sillas_categoria: any;
    @ViewChild('bar_establecimiento_categoria') bar_establecimiento_categoria!: ElementRef;
    @ViewChild('bar_establecimiento_negocio') bar_establecimiento_comprados!: ElementRef;
    @ViewChild('bar_establecimiento_ciudad') bar_establecimiento_ciudad!: ElementRef;
    @ViewChild('bar_establecimiento_negocio') bar_establecimiento_negocio!: ElementRef;
    @ViewChild('bar_establecimiento_negociobla') bar_establecimiento_negociobla!: ElementRef;
    @ViewChild('bar_sillas_ciudad') bar_sillas_ciudad!: ElementRef;
    @ViewChild('bar_sillas_negocio') bar_sillas_negocio!: ElementRef;
    @ViewChild('bar_sillas_categoria') bar_sillas_categoria!: ElementRef;

  constructor(
    public authService: AuthService,
    public rest: RestService,
    public localStorage: LocalStorageService,
    public modalService: NgbModal,
    public router: Router,
    public auth: AuthService,
    public graficasService: GraficasService,
    public ngxsStore: Store,
  ) {

    this.data_bar_establecimiento_ciudad = {
      labels: [],
      datasets: [],
    };
    this.data_bar_establecimiento_negocio = {
      labels: [],
      datasets: [],
    };
    this.data_bar_establecimiento_categoria = {
      labels: [],
      datasets: [],
    };
    this.data_bar_sillas_ciudad = {
      labels: [],
      datasets: [],
    };
    this.data_bar_sillas_negocio = {
      labels: [],
      datasets: [],
    };
    this.data_bar_sillas_categoria = {
      labels: [],
      datasets: [],
    };
  }

  async ngAfterViewInit() {
    // child is set
    this.id_organizacion = await this.ngxsStore.snapshot().auth.user_organizacion._id;
    this.id_user = await this.ngxsStore.snapshot().auth.user._id;
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    try {
      await this.getDataComponente();
      await this.lanzarTutorial();
      await this.getPublicaciones();
      await this.getBanners();
      await this.getPaquetePuntosFt();
      await this.getDataIndicadores();
      await this.cargarInformacionEstablecimientoCiudad();
      await this.cargarInformacionEstablecimientoNegocio();
      await this.cargarInformacionEstablecimientoCategoria();
      await this.cargarInformacionSillasCiudad();
      await this.cargarInformacionSillasNegocio();
      await this.cargarInformacionSillasCategoria();
      this.modalCarga?.close();
    } catch (error) {
      this.mostrarMensajeError();
    }
  }
  async ngOnInit() {
    
  }
  /**
   * Recupera toda la información de indicadores, productos
   * categorías, lineas de productos y top productos del compoente
   */
  getDataComponente() {
    this.rest
      .getJWT(`productos_por_organizacion/componente/portafolio/${this.id_organizacion}`)
      .toPromise()
      .then((resp: any) => {
        this.totalProductos = resp[0].portafolio.length;
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
  }
  /**
   * Recupera todos los indicadores de puntosFT de una organizacion
   */
  async getPaquetePuntosFt() {
    await this.rest
      .getJWT('bolsa_puntos_organizacion/balance/' + this.id_organizacion)
      .toPromise()
      .then((resp: any) => {
        this.puntos_paquetes = resp[0].cant_paquetes[0] ? resp[0].cant_paquetes[0].total : 0;
        this.puntos_disponibles = resp[0].puntos_disponibles[0] ? resp[0].puntos_disponibles[0].total : 0;
        this.puntos_usados = resp[0].puntos_usados[0] ? resp[0].puntos_usados[0].total : 0;
      })
      .catch((err) => {
        throw err;
      });
  }

  /**
   * Recupera todos los indicadores de una organizacion
   */
  async getDataIndicadores() {
    await this.rest
      .getJWT('indicadores_home_organizacion/' + this.id_organizacion + '/' + this.id_user)
      .toPromise()
      .then((resp_productos: any) => {
        resp_productos[0].notificaciones[0]
          ? (this.indicadores_notificaciones_total = resp_productos[0].notificaciones[0].total)
          : 0;
        resp_productos[0].mensajes_nuevos[0]
          ? (this.indicadores_mensajes_nuevos_total = resp_productos[0].mensajes_nuevos[0].total)
          : 0;
        resp_productos[0].clientes[0] ? (this.indicadores_clientes_total = resp_productos[0].clientes[0].total) : 0;
        resp_productos[0].regiones[0] ? (this.indicadores_regiones_total = resp_productos[0].regiones[0].total) : 0;
        resp_productos[0].ciudades[0] ? (this.indicadores_ciudades_total = resp_productos[0].ciudades[0].total) : 0;
        resp_productos[0].productos[0] ? (this.indicadores_productos_total = resp_productos[0].productos[0].total) : 0;
        this.indicadores = resp_productos[0];
        this.plan_actual = resp_productos[0].plan.find((plan: any) => {
          return plan._id == plan.plan_actual;
        });
      })
      .catch((err) => {
        throw err;
      });
      this.rest.setPlanOrg(parseInt(this.plan_actual.hasta),this.indicadores_clientes_total )
  }

  /**
   * Devuelve los banners del home page
   */
  getBanners() {
    this.rest
      .getJWT('banner/Organizaciones')
      .toPromise()
      .then((res5: any) => {
        this.imgBanner = res5.slice(0, 5);
      })
      .catch((err: any) => {
        throw err;
      });
  }

  /**
   * Funciones para obtener las publicaciones y poder navegar ene lals
   */
  async getPublicaciones() {
    const resp_publicaciones: any = await this.rest.getJWT(`/publicaciones/ultimas_publicaciones`).toPromise();
    this.publicaciones = resp_publicaciones;
    this.publicaciones.splice(0, 20);
  }
  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }
  onSlide(slideEvent: NgbSlideEvent) {
    if (
      this.unpauseOnArrow &&
      slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)
    ) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

  /**
   * Recupera la información de las graficas y las armas para el DOM
   */
  /****** Establecimientos por ciudad ******/
  public async cargarInformacionEstablecimientoCiudad() {
    this.data_bar_establecimiento_ciudad.labels = [];
    this.data_bar_establecimiento_ciudad.datasets = [];
    await this.rest
      .getJWT('puntos_ciudad_por_organizacion/' + this.id_organizacion)
      .toPromise()
      .then((puntos: any) => {
        puntos.data.forEach((punto: any) => {
          this.data_bar_establecimiento_ciudad.labels.push(punto._id);
          this.data_bar_establecimiento_ciudad.datasets.push(punto.total);
          this.total_chart_ciudad = this.total_chart_ciudad + punto.total;
        });
        this.bar_chart_establecimiento_ciudad = this.graficasService.createBarChart(
          this.bar_establecimiento_ciudad,
          this.data_bar_establecimiento_ciudad,
          'Establecimientos por ciudad',
          'Número de establecimientos',
          'Ciudad'
        );
      })
      .catch((err) => {
        throw err;
      });
    
  }
  /****** Establecimientos por tipo de negocio ******/
  public async cargarInformacionEstablecimientoNegocio() {
    this.data_bar_establecimiento_negocio.labels = [];
    this.data_bar_establecimiento_negocio.datasets = [];
    await this.rest
      .getJWT('tipos_negocio_por_organizacion/' + this.id_organizacion)
      .toPromise()
      .then((tipo_negocios: any) => {       
        tipo_negocios.data.forEach((tipoN: any) => {
          this.data_bar_establecimiento_negocio.datasets.push(tipoN.total);
          this.data_bar_establecimiento_negocio.labels.push(tipoN._id);
          this.total_chart_negocio = this.total_chart_negocio+ tipoN.total;
        });
      })
      .catch((err) => {
        throw err;
      });
    this.bar_chart_establecimiento_negocio = this.graficasService.createBarChart(
      this.bar_establecimiento_negocio,
      this.data_bar_establecimiento_negocio,
      'Establecimientos por tipo de negocio',
      'Número de establecimientos',
      'Tipo de negocio'
    );
  }
  /****** Establecimientos por categoría ******/
  public async cargarInformacionEstablecimientoCategoria() {
    this.data_bar_establecimiento_categoria.labels = [];
    this.data_bar_establecimiento_categoria.datasets = [];
    await this.rest
      .getJWT('categorias_productos_por_organizacion/' + this.id_organizacion)
      .toPromise()
      .then((categorias: any) => {
        categorias.data.forEach((categoria: any) => {
          this.data_bar_establecimiento_categoria.labels.push(categoria._id);
          this.data_bar_establecimiento_categoria.datasets.push(categoria.count);
          this.total_chart_categoria = this.total_chart_categoria + categoria.count;
        });
      })
      .catch((err) => {
        throw err;
      });
    this.bar_chart_establecimiento_categoria = this.graficasService.createBarChart(
      this.bar_establecimiento_categoria,
      this.data_bar_establecimiento_categoria,
      'Establecimientos por categoría',
      'Número de establecimientos',
      'Categorías'
    );
  }
  /****** Sillas por ciudad ******/
  public async cargarInformacionSillasCiudad() {
    this.data_bar_sillas_ciudad.labels = [];
    this.data_bar_sillas_ciudad.datasets = [];
    await this.rest
      .getJWT('sillas_ciudad_por_organizacion/' + this.id_organizacion)
      .toPromise()
      .then((categorias: any) => {
        categorias.data.forEach((categoria: any) => {
          this.data_bar_sillas_ciudad.labels.push(categoria._id);
          this.data_bar_sillas_ciudad.datasets.push(categoria.total);
          this.total_chart_sillas_ciudad = this.total_chart_sillas_ciudad + categoria.total;
        });
      })
      .catch((err) => {
        throw err;
      });
    this.bar_chart_sillas_ciudad = this.graficasService.createBarChart(
      this.bar_sillas_ciudad,
      this.data_bar_sillas_ciudad,
      'Sillas por ciudad',
      'Número de sillas',
      'Ciudad'
    );
  }
  /****** Sillas por tipo de negocio ******/
  public async cargarInformacionSillasNegocio() {
    this.data_bar_sillas_negocio.labels = [];
    this.data_bar_sillas_negocio.datasets = [];
    await this.rest
      .getJWT('sillas_tipo_negocio_por_organizacion/' + this.id_organizacion)
      .toPromise()
      .then((categorias: any) => {
        categorias.data.forEach((categoria: any) => {
          this.data_bar_sillas_negocio.labels.push(categoria._id);
          this.data_bar_sillas_negocio.datasets.push(categoria.total);
          this.total_chart_sillas_negocio = this.total_chart_sillas_negocio + categoria.total;
        });
      })
      .catch((err) => {
        throw err;
      });
    this.bar_chart_sillas_negocio = this.graficasService.createBarChart(
      this.bar_sillas_negocio,
      this.data_bar_sillas_negocio,
      'Sillas por tipo de negocio',
      'Número de sillas',
      'Tipo de negocio'
    );
  }
  /****** Sillas por categoría ******/
  public async cargarInformacionSillasCategoria() {
    this.data_bar_sillas_categoria.labels = [];
    this.data_bar_sillas_categoria.datasets = [];
    await this.rest
      .getJWT('sillas_categoria_por_organizacion/' + this.id_organizacion)
      .toPromise()
      .then((categorias: any) => {
        categorias.data.forEach((categoria: any) => {
          this.data_bar_sillas_categoria.labels.push(categoria._id);
          this.data_bar_sillas_categoria.datasets.push(categoria.total);
          this.total_chart_sillas_categoria = this.total_chart_sillas_categoria + categoria.total;
        });
      })
      .catch((err) => {
        throw err;
      });
    this.bar_chart_sillas_categoria = this.graficasService.createBarChart(
      this.bar_sillas_categoria,
      this.data_bar_sillas_categoria,
      'Sillas por categoría',
      'Número de sillas',
      'Categorías'
    );
  }

  /**
   * Abre sala de mensajes de organizaciones
   */
  abrirSalaChatDistribuidores() {
    this.router
      .navigate(['/chat/room/todos/distribuidor/todos/organizacion/' + this.id_organizacion + '/inicio_organizacion'])
      .then(() => {
        window.location.reload();
      });
  }

  /**
   * Revisa si es la primera vez de este usuario usando la plataforma. Si sí,
   * lanza un tutorial mostrándole al usuario cómo usar la plataforma.
   */
  lanzarTutorial() {
    if (this.authService.user?.show_slides === true) {
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        centered: true,
      };
      const modalRef = this.modalService.open(SimpleNoImgComponent, ngbModalOptions);
      modalRef.componentInstance.title = '¡Hola! Queremos que conozcas Feat';
      modalRef.componentInstance.msg =
        'Por eso te mostraremos los accesos que tendrás desde el inicio. ¡Es un gusto tenerte acá!';
      modalRef.componentInstance.btn_msg = 'Empecemos';
      modalRef.componentInstance.close_callback = () => {
        const ngbModalOptions2: NgbModalOptions = {
          backdrop: 'static',
          keyboard: false,
          centered: true,
          size: 'lg',
        };
        const modalRef = this.modalService.open(MultipasoComponent, ngbModalOptions2);
        const pasos: any[] = [
          {
            title: 'Todo tiene un inicio',
            msg: 'Busca el plan de cobertura y paquete de puntos Feat qué más se acomode a tus necesidades y empieza a disfrutar de efectividad.',
            img_src: '../../../assets/img/modal-bienvenida-org/1.png',
          },
          {
            title: 'Ten el plan de cobertura ideal',
            msg: 'Solicita el plan de cobertura Feat qué más se acomode a tus necesidades, luego de su aprobación podrás realizar el pago y disfrutar de los beneficios.',
            img_src: '../../../assets/img/modal-bienvenida-org/2.png',
          },
          {
            title: 'Puntos FT promocionales',
            msg: 'Compra un paquete de puntos FT para qué los puedas asignar a los productos de tu portafolio y estos puedan ser acumulados en los establecimientos manteniendo la lealtad y la fidelidad de tus marcas.',
            img_src: '../../../assets/img/modal-bienvenida-org/3.png',
          },
          {
            title: 'Distribuidores',
            msg: 'Tendrás acceso a los distribuidores vinculados a Feat para qué los conozcas y te puedas comunicar con ellos y así puedas aumentar la cobertura.',
            img_src: '../../../assets/img/modal-bienvenida-org/4.png',
          },
          {
            title: 'Información a la mano',
            msg: 'Tendrás acceso a información estratégica del mercado desde todos los puntos para qué puedas tomar mejores decisiones.',
            img_src: '../../../assets/img/modal-bienvenida-org/5.png',
          },
        ];
        modalRef.componentInstance.pasos = pasos;
        modalRef.componentInstance.close_callback = () => {
          /**
           * Cuando el usuario termine de revisar la información
           * se guarda en localStorage y base de datos el indicador
           * para no volver a mostrar cuando se vuelva a iniciar sesión
           */
          const show_slides = {
            show_slides: false,
          };
          /**Se actualiza el trabajador en el back para no mostrar los slides de nuevo*/
          this.rest
            .putJWT(`trabajador/${this.authService.user?._id}`, show_slides)
            .toPromise()
            .then((resp: any) => {
              /**Se actualiza la info de inicio de sesión en el Store con ShowSlide False*/
              /*this.ngxsStore
                .dispatch(
                  new SetSelectedToken({
                    token: this.authService.token,
                    email: this.authService.email,
                    user: resp,
                    user_horeca: undefined,
                    user_distribuidor: undefined,
                    user_organizacion: this.authService.user_organizacion,
                  })
                )
                .toPromise()
                .then(() => (this.authService.user = this.ngxsStore.snapshot().auth.user));*/
            });
        };
      };
    }
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  public mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio-organizacion']);
    };
  }
}
