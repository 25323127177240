<div class="contenedor modalContedor">
  <div class="boton-crear col-12 d-flex justify-content-end p-0 m-0">
    <button class="new-button" (click)="onCreateUser()">
      Crear un nuevo trabajador
    </button>
  </div>
  <!--Estado vacíos-->
  <div class="warning d-flex flex-column justify-content-center align-items-center h-100"
    *ngIf="trabajadores.length == 0">
    <img src="assets/img/icon-warning-amarillo.png" alt="" />
    <span>¡Vaya!</span>
    <span class="text-center">
      Todavía no tienes trabajadores en tu equipo
    </span>
  </div>
  <div *ngIf="trabajadores.length != 0 ">
    <!-- Solicitudes pendientes -->
    <div class="mt-2">
      <p class="m-0">Solicitudes ({{ trabajadores_pendientes.length }})</p>
    </div>
    <p class="pl-2 my-2" *ngIf="trabajadores_pendientes.length == 0">No posees solicitudes pendientes</p>
    <div class="carousel-container">
      <div class="card-container pb-3">
        <div class="card" *ngFor="let trab of trabajadores_pendientes; index as i;">
          <div class="pl-1 d-flex justify-content-between align-items-center m-0 pointer"
            [routerLink]="'/solicitudes-trabajor/' + trab._id">
            <div class="d-flex flex-row align-items-center">
              <span class="badge mr-2">
                {{ trab.nombres[0].toUpperCase() }}{{ trab.apellidos[0].toUpperCase() }}
              </span>
              <span class="pr-2">
                {{ trab.nombres }} {{ trab.apellidos }}
              </span>
            </div>
            <fa-icon [icon]="faCircle" size="sm" [styles]="{'color': '#ffff8d'}"> </fa-icon>
          </div>
          <span class="order-count px-3 py-1 m-0 pointer" [routerLink]="'/solicitudes-trabajor/' + trab._id">{{
            trab.tipo_trabajador
            }}</span>
          <span class="user-card border-top pt-3">
            <button (click)="validaRechazado(i, 'Rechazado')">
              Rechazar
            </button>
            <button (click)="actualizarSolicitudTrabajador(i, 'Aprobado')">
              Aprobar
            </button>
          </span>
        </div>
      </div>
    </div>
    <!--Equipo de trabajo-->
    <div class="mt-2">
      <p class="m-0">Mi equipos ({{ trabajadores_aprobados.length }})</p>
    </div>
    <p class="pl-2 my-2" *ngIf="trabajadores_aprobados.length == 0">No posees trabajadores aprobados</p>
    <div class="carousel-container">
      <div class="card-container pb-3">
        <div class="card pointer" *ngFor="let trab of trabajadores_aprobados; index as i;"
          [routerLink]="'/trabajador-aprobado/' + trab._id">
          <div class="pl-1 d-flex justify-content-between align-items-center m-0">
            <div class="d-flex flex-row align-items-center">
              <span class="badge mr-2">
                {{ trab.nombres[0].toUpperCase() }}{{ trab.apellidos[0].toUpperCase() }}
              </span>
              <span class="pr-2">{{ trab.nombres }} {{ trab.apellidos }}</span>
            </div>
            <fa-icon [icon]="faCircle" size="sm" [styles]="{'color': '#C7D389'}"> </fa-icon>
          </div>
          <span class="order-count px-3 py-1 m-0">{{ trab.tipo_trabajador }}</span>
          <span *ngIf="trab.puntos_entrega && trab.puntos_entrega!.length != 0" class="border-top pt-3">
            <img src="assets/img/icons/icon-store.png" alt="" class="mr-2" />
            {{ trab.puntos_entrega!.length }} cliente<span *ngIf="trab.puntos_entrega!.length != 1">s</span>
          </span>
          <span *ngIf="trab.puntos_entrega && trab.puntos_entrega!.length == 0" style="opacity: 0;"
            class="border-top pt-3">
            Nada para mostrar
          </span>
        </div>
      </div>
    </div>
    <!--Trabajadores desactivados-->
    <div class="mt-2">
      <p class="m-0">Trabajadores desactivados y rechazados ({{ trabajadores_desactivados.length }})</p>
    </div>
    <p class="pl-2 my-2" *ngIf="trabajadores_desactivados.length == 0">No posees trabajadores desactivados y rechazados
    </p>
    <div class="carousel-container">
      <div class="card-container pb-3">
        <div class="card" *ngFor="let trab of trabajadores_desactivados; index as i;">
          <div class="pl-1 d-flex justify-content-between align-items-center m-0 pointer"
            [routerLink]="'/trabajador-aprobado/' + trab._id">
            <div class="d-flex flex-row align-items-center">
              <span class="badge mr-2">
                {{ trab.nombres[0].toUpperCase() }}{{ trab.apellidos[0].toUpperCase() }}
              </span>
              <span class="pr-2">{{ trab.nombres }} {{ trab.apellidos }}</span>
            </div>
            <fa-icon [icon]="faCircle" size="sm" [styles]="{'color': '#D38989'}"> </fa-icon>
          </div>
          <span class="order-count px-3 py-1 m-0 pointer" [routerLink]="'/trabajador-aprobado/' + trab._id">
            {{trab.tipo_trabajador}}
          </span>
          <span class="user-card border-top pt-3">
            <p class="text-center align-content-center h-100 m-0" style="font-size: 14px;"
              *ngIf="!trab.puntos_entrega || trab.puntos_entrega!.length === 0">
              Asigna un cliente para reactivar al trabajador
            </p>
            <button *ngIf="trab.puntos_entrega && trab.puntos_entrega!.length > 0" (click)="reactivarTrabajador(i)">
              Reactivar
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>