<app-header-organizacion></app-header-organizacion>
<div class="contenedor">
  <div class="columnDir">
    <div class="banner-home">
      <ngb-carousel #carousel [interval]="5000" [pauseOnHover]="pauseOnHover" [pauseOnFocus]="pauseOnFocus"
        (slide)="onSlide($event)">
        <ng-template ngbSlide *ngFor="let img of imgBanner; index as i">
          <a href="{{ img.link }}" target="_blank" rel="nofollow noopener noreferrer">
            <div class="picsum-img-wrapper">
              <img [src]="img.imagen" alt="My image {{ i + 1 }} description" class="img-banner" />
            </div>
          </a>
        </ng-template>
      </ngb-carousel>
    </div>
    <div class="fullCard columnDir">
      <app-inicio-comunidad></app-inicio-comunidad>
    </div>
  </div>
  <div class="contenedorIndicadores">
    <div class="miniCard rowDir">
      <img class="logo" src="{{ dataOrg.logo }}" alt="" />
      <div class="columnDir">
        <span>{{ dataOrg.nombre }}</span>
        <span>¡Hola {{ dataUser.nombres }}!</span>
      </div>
      <div class="editarBtn pointer" routerLink="/cuenta-organizacion">
        <span>
          <fa-icon [icon]="faPen" [styles]="{ color: '#000' }" id="fa-icon"></fa-icon>
          Editar
        </span>
      </div>
    </div>
    <div class="miniCard rowDir">
      <div class="rowDir cursorPointer" routerLink="/expandir-plan-organizacion">
        <img class="logo" src="../../../assets/img/principiante.png" alt="" />
        <div class="columnDir" *ngIf="plan_actual !=  undefined; else sinPlan">
          <span>Plan: {{plan_actual.nombre}}</span>
          <span>{{plan_actual.desde | number:'1.0-2' }}/{{plan_actual.hasta | number:'1.0-2' }} Establecimientos</span>
        </div>
        <ng-template #sinPlan>
          <div class="row">
            <span>
              Ningún plan asociado <br>
              Procede a unirte
            </span>
          </div>
        </ng-template>
      </div>
      <div class="vl"></div>
      <div class="rowDir cursorPointer" routerLink="/puntos-ft-organizacion">
        <img class="logo" src="../../../assets/img/product-package.png" alt="" />
        <div class="columnDir">
          <span>Paquete de puntos</span>
          <span>{{puntos_usados | number:'1.0-2' }}/{{puntos_disponibles | number:'1.0-2' }} FT</span>
        </div>
      </div>
    </div>
    <div class="rowDir ">
      <div class="miniCard columnDir cursorPointer" style="width: 185px" routerLink="/notificaciones">
        <div class="indicadores iconos-indicador">
          <div style="position: relative">
            <img src="../../../assets/iconos-org/bell.png" alt="" />
            <div class="notificacion-indicador"
              *ngIf="indicadores_notificaciones_total">
            </div>
          </div>
          <span>{{indicadores_notificaciones_total || 0}}</span>
        </div>
        <span class="indicadoresTitle " routerLink="/mensajes">Notificaciones</span>
      </div>
      <div class="miniCard columnDir cursorPointer" style="width: 185px" (click)="abrirSalaChatDistribuidores()">
        <div class="indicadores iconos-indicador">
          <div style="position: relative; width: 30px">
            <img src="../../../assets/iconos-org/message.png" alt="" />
            <div class="notificacion-indicador"
              *ngIf="indicadores_mensajes_nuevos_total && indicadores_mensajes_nuevos_total > 0"></div>
          </div>
          <span>{{indicadores_mensajes_nuevos_total || 0}}</span>
        </div>
        <span class="indicadoresTitle">Mensajes nuevos</span>
      </div>
      <div class="miniCard columnDir cursorPointer" style="width: 185px" routerLink="/establecimientos-organizacion">
        <div class="indicadores iconos-indicador">
          <div style="position: relative">
            <img src="../../../assets/iconos-org/build.png" alt="" />
            <div class="notificacion-indicador" *ngIf="indicadores_clientes_total && indicadores_clientes_total > 0">
            </div>
          </div>
          <span>{{indicadores_clientes_total || 0}}</span>
        </div>
        <span class="indicadoresTitle">Establecimientos</span>
      </div>
    </div>
    <div class="miniCard rowDir">
      <div class="columnDir" style="width: 185px">
        <div class="indicadores iconos-indicador">
          <img style="border-radius: 50%" src="../../../assets/iconos-org/Flag_of_Colombia.png" alt="" />
          <span>{{ dataUser?.organizacion.pais }}</span>
        </div>
        <span class="indicadoresTitle">País</span>
      </div>
      <div class="columnDir" style="width: 185px">
        <div class="indicadores">
          <span>{{indicadores_regiones_total || 0}}</span>
        </div>
        <span class="indicadoresTitle">Regiones</span>
      </div>
      <div class="columnDir" style="width: 185px">
        <div class="indicadores">
          <span>{{indicadores_ciudades_total || 0}}</span>
        </div>
        <span class="indicadoresTitle">Ciudades</span>
      </div>
      <div class="columnDir" style="width: 185px">
        <div class="indicadores">
          <span>{{totalProductos || 0}}</span>
        </div>
        <span class="indicadoresTitle">Productos</span>
      </div>
    </div>
    <span>Indicadores</span>
    <div class="graficasCard columnDir" >
      <div class="graficas">
        <div class="card mt-3 p-0 establecimiento-ciudad">
          <div class="card-body">
            <div class="d-flex flex-row justify-content-between mb-4">
              <div>Establecimientos por ciudad</div>
              <span *ngIf="indicadores?.clientesHistorico > 0">Total: {{ indicadores?.clientesHistorico[0].total || 0 }}</span>
            </div>
            <canvas #bar_establecimiento_ciudad
              [ngClass]="{'inactive': data_bar_establecimiento_ciudad.datasets.length == 0}"></canvas>
            <div *ngIf="data_bar_establecimiento_ciudad.datasets.length > 0 else mostrarError"></div>
          </div>
        </div>
        <div class="card mt-3 p-0 establecimiento-negocio">
          <div class="card-body">
            <div class="d-flex flex-row justify-content-between mb-4">
              <div>Establecimientos por tipo de negocio</div>
              <span *ngIf="indicadores?.clientesHistorico > 0">Total: {{ indicadores?.clientesHistorico[0].total || 0 }}</span>
            </div>
            <canvas #bar_establecimiento_negocio
              [ngClass]="{'inactive': data_bar_establecimiento_negocio.datasets.length == 0}"></canvas>
            <div *ngIf="data_bar_establecimiento_negocio.datasets.length > 0 else mostrarError"></div>
          </div>
        </div>
        <div class="card mt-3 p-0 establecimiento-categoria">
          <div class="card-body">
            <div class="d-flex flex-row justify-content-between mb-4">
              <div>Establecimientos por categoría</div>
              <span *ngIf="indicadores?.clientesHistorico > 0">Total: {{ indicadores?.clientesHistorico[0].total || 0 }}</span>
            </div>
            <canvas #bar_establecimiento_categoria
              [ngClass]="{'inactive': data_bar_establecimiento_categoria.datasets.length == 0}"></canvas>
            <div *ngIf="data_bar_establecimiento_categoria.datasets.length > 0 else mostrarError"></div>
          </div>
        </div>
        <div class="card mt-3 p-0 sillas-ciudad">
          <div class="card-body">
            <div class="d-flex flex-row justify-content-between mb-4">
              <div>Sillas por ciudad</div>
              <span >Total: {{ total_chart_sillas_ciudad }}</span>
            </div>
            <canvas #bar_sillas_ciudad [ngClass]="{'inactive': data_bar_sillas_ciudad.datasets.length == 0}"></canvas>
            <div *ngIf="data_bar_sillas_ciudad.datasets.length > 0 else mostrarError"></div>
          </div>
        </div>
        <div class="card mt-3 p-0 sillas-negocio">
          <div class="card-body">
            <div class="d-flex flex-row justify-content-between mb-4">
              <div>Sillas por tipo de negocio</div>
              <span>Total: {{ total_chart_sillas_negocio }}</span>
            </div>
            <canvas #bar_sillas_negocio [ngClass]="{'inactive': data_bar_sillas_negocio.datasets.length == 0}"></canvas>
            <div *ngIf="data_bar_sillas_negocio.datasets.length > 0 else mostrarError"></div>
          </div>
        </div>
        <div class="card mt-3 p-0 sillas-categoria">
          <div class="card-body">
            <div class="d-flex flex-row justify-content-between mb-4">
              <div>Sillas por categoría</div>
              <span>Total: {{ total_chart_sillas_categoria }}</span>
            </div>
            <canvas #bar_sillas_categoria
              [ngClass]="{'inactive': data_bar_sillas_categoria.datasets.length == 0}"></canvas>
            <div *ngIf="data_bar_sillas_categoria.datasets.length > 0 else mostrarError"></div>
          </div>
        </div>
        <div class="button-container">
          <button routerLink="/sector-organizacion">Ver más</button>
        </div>
      </div>
    </div>
  </div>
  <ng-template #mostrarError>
    <div class="d-flex justify-content-center mt-4">
      <div>
        <img src="assets/img/icon-warning-amarillo.png" alt="icono de una hoja de papel y una lupa sobre ella"
          style="max-width: 150px;" />
      </div>
    </div>
    <div class="mt-2">
      <p class="text-center">
        ¡Lo siento! No existen datos para ver aquí
      </p>
    </div>
  </ng-template>
</div>
<!--<canvas #bar_establecimiento_categoria 
></canvas>
<canvas #bar_establecimiento_negocio 
></canvas>
<canvas #bar_establecimiento_ciudad 
></canvas>
<canvas #bar_establecimiento_negocio
></canvas>
<canvas #bar_establecimiento_negociobla
></canvas>
<canvas #bar_sillas_ciudad
></canvas>
<canvas #bar_sillas_negocio
></canvas>
<canvas #bar_sillas_categoria
></canvas>-->