import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private base_maps_url = 'https://maps.googleapis.com/maps/api/geocode/json?address=';
  private api_key = environment.gmaps_key;

  constructor(private http: HttpClient) {}

  /**
   * Consulta la API de Google Maps usando la URL base y la dirección que
   * entra por parámetro y devuelve una promesa para que quién llamó el
   * servicio pueda hacer su propio manejo de la respuesta
   * @param direccion La dirección a consultar
   * @returns Un objeto Observable para seguir el estado de la petición
   */
  getLatLong(direccion: string) {
    console.log('direccion', direccion);
    const url: string =
      this.base_maps_url +
      direccion.split(' ').join('%20').split('#').join('%23') +
      '&sensor=false&key=' +
      this.api_key;

    return this.http.get(url);
  }
  getUbicacionDireccion(object: string) {
    console.log(object);
    return this.http.get(this.base_maps_url + '' + object + this.api_key);
  }
  listaColoresPoligono() {
    return [
      '#8e6ff7',
      '#024466',
      '#530103',
      '#c77500',
      '#39389e',
      '#ac2e0d',
      '#008a94',
      '#287c27',
      '#413529',
      '#132227',
      '#045b6c',
      '#7a287c',
      '#ad121a',
      '#b27846',
      '#78500a',
      '#180000',
      '#515a55',
      '#b31e31',
      '#4C0027',
      '#29351f',
    ];
  }
}
