import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cargando-generico',
  templateUrl: './cargando-generico.component.html',
  styleUrls: ['./cargando-generico.component.css'],
})
export class CargandoGenericoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
