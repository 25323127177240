<app-header-distribuidor></app-header-distribuidor>
<div class="contenido">
  <div class="header-clients">
    <div class="mr-4">
      <button class="btn-back" routerLink="/clientes">
        <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
        Volver
      </button>
    </div>
    <h3>Historial de solicitudes</h3>
  </div>
  <div class="row mt-3 margin-0-responsive">
    <div class="col-md-12 cards" [hidden]="distribuidores_vinculados.length === 0">
      <app-solicitud-card *ngFor="let item of distribuidores_vinculados" [dist_vinculado]="item"></app-solicitud-card>
    </div>
    <div class="col-md-12" [hidden]="distribuidores_vinculados.length > 0">
      <div class="estado-vacio">
        <div class="w-100 d-flex flex-column mt-3 justify-content-center">
          <div class="d-flex justify-content-center">
            <img src="../../../assets/img/icon-warning-amarillo.png" class="aviso"
              alt="Icono de una banana en señal de advertencia" />
          </div>
          <div class="text-center">
            <p>¡Ups!</p>
            <p>Aún no tienes solicitudes</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
