<div class="container-info">
  <form (ngSubmit)="onSubmitUser()" [formGroup]="formPerson">
    <span class="titulo_Seccion">Tu información</span>
    <div class="grupos">
      <div>
        <span>Tipo de documento</span>
        <select name="tipo_documento" id="" formControlName="tipo_documento" [ngClass]="{
            'invalid-warning':
              formPerson.get('tipo_documento')?.hasError('required') && formPerson.get('tipo_documento')?.touched
          }">
          <option value="Cédula de ciudadanía">Cédula de ciudadanía</option>
          <option value="Pasaporte">Pasaporte</option>
          <option value="Cédula de extranjería">Cédula de extranjería</option>
        </select>
      </div>
      <div>
        <span>Número de documento*</span>
        <input type="number" formControlName="numero_documento" [ngClass]="{
            'invalid-warning':
              (formPerson.get('numero_documento')?.hasError('required') &&
                formPerson.get('numero_documento')?.touched) ||
              (formPerson.get('numero_documento')?.hasError('pattern') && formPerson.get('numero_documento')?.touched)
          }" />
      </div>
      <div>
        <span>Nombres*</span>
        <input type="text" formControlName="nombres" [ngClass]="{
            'invalid-warning': formPerson.get('nombres')?.hasError('required') && formPerson.get('nombres')?.touched
          }" />
      </div>
      <div>
        <span>Apellidos*</span>
        <input type="text" formControlName="apellidos" [ngClass]="{
            'invalid-warning': formPerson.get('apellidos')?.hasError('required') && formPerson.get('apellidos')?.touched
          }" />
      </div>
      <div>
        <span>País*</span>
        <input type="text" formControlName="pais" />
      </div>
      <div>
        <span>Departamento</span>
        <select name="" id="" (change)="fetchCity('person')" formControlName="departamento" *ngIf="depmentos">
          <option *ngFor="let departamento of departamentos; index as i" [value]="departamento.name">
            {{ departamento.name }}
          </option>
        </select>
      </div>
      <div>
        <span>Ciudad</span>
        <select name="" id="" formControlName="ciudad">
          <option *ngFor="let ciudad of ciudades; index as i" [value]="ciudad.name">
            {{ ciudad.name }}
          </option>
        </select>
      </div>
      <div>
        <span>Celular*</span>
        <input formControlName="celular" id="celular" name="celular" class="form-control"
          (keyup)="verificarNumero($event)" type="number" [ngClass]="{
            'invalid-warning': formPerson.get('celular')?.hasError('required') && formPerson.get('celular')?.touched
          }" />

        <div class="errores" *ngIf="!celularPerson">El celular debe tener 10 numeros</div>
      </div>
      <div>
        <span>Teléfono</span>
        <input formControlName="telefono" type="number" (keyup)="verificarNumero($event)" />
        <div class="errores" *ngIf="!telefonoPersona">El telefono debe tener 7 ó 10 numeros</div>
      </div>
      <div>
        <span>Tipo de persona</span>
        <select formControlName="tipo_trabajador" [ngClass]="{
            'invalid-warning':
              formPerson.get('tipo_trabajador')?.hasError('required') && formPerson.get('tipo_trabajador')?.touched
          }">
          <option value="ADMINISTRADOR">ADMINISTRADOR</option>
          <option value="OPERATIVO">OPERATIVO</option>
        </select>
      </div>
    </div>

    <div class="grupos centrar-vertical">
      <div>
        <span>Correo de ingreso</span>
        <input formControlName="correo" />
      </div>

      <span (click)="onContrasena()" style="font-size:12px">Cambiar contraseña</span>
    </div>
    <button class="guardar" type="submit" [disabled]="!formPerson.valid"
      [ngClass]="{'btn-submit-disabled': !formPerson.valid}">
      Guardar cambios
    </button>
  </form>
  <form (ngSubmit)="onSubmitOrg()" [formGroup]="formOrg">
    <span class="titulo_Seccion">Sobre la empresa</span>
    <div class="grupos">
      <div>
        <span>Nombre de empresa*</span>
        <input type="text" formControlName="nombre" [ngClass]="{
            'invalid-warning': formOrg.get('nombre')?.hasError('required') && formOrg.get('nombre')?.touched
          }" />
      </div>
      <div>
        <span>Razón social*</span>
        <input formControlName="razon_social" [ngClass]="{
            'invalid-warning': formOrg.get('razon_social')?.hasError('required') && formOrg.get('razon_social')?.touched
          }" />
      </div>
      <div>
        <span>NIT*</span>
        <input formControlName="nit" type="text" [ngClass]="{
            'invalid-warning': formOrg.get('nit')?.hasError('required') && formOrg.get('nit')?.touched
          }" />
      </div>
      <div>
        <span>Sector/Categoría*</span>
        <input formControlName="sector" />
      </div>
      <div>
        <span>Dirección*</span>
        <input formControlName="direccion" [ngClass]="{
            'invalid-warning': formOrg.get('direccion')?.hasError('required') && formOrg.get('direccion')?.touched
          }" />
      </div>
      <div>
        <span>País*</span>
        <input formControlName="pais" [ngClass]="{
            'invalid-warning': formOrg.get('pais')?.hasError('required') && formOrg.get('pais')?.touched
          }" />
      </div>
      <div>
        <span>Departamento*</span>
        <select name="" id="" (change)="fetchCity('org')" formControlName="departamento" *ngIf="depmentos" [ngClass]="{
            'invalid-warning': formOrg.get('departamento')?.hasError('required') && formOrg.get('departamento')?.touched
          }">
          <option *ngFor="let departamento of departamentos; index as i" [value]="departamento.name">
            {{ departamento.name }}
          </option>
        </select>
      </div>
      <div>
        <span>Ciudad*</span>
        <select name="" id="" formControlName="ciudad" [ngClass]="{
            'invalid-warning': formOrg.get('ciudad')?.hasError('required') && formOrg.get('ciudad')?.touched
          }">
          <option *ngFor="let ciudad of ciudadesOrg; index as i" [value]="ciudad.name">
            {{ ciudad.name }}
          </option>
        </select>
      </div>
      <div>
        <span>Celular*</span>
        <input type="number" formControlName="celular" (keyup)="verificarNumero($event)" [ngClass]="{
            'invalid-warning': formOrg.get('celular')?.hasError('required') && formOrg.get('celular')?.touched
          }" />
        <div class="errores" *ngIf="!celularOrg">El celular debe tener 10 numeros</div>
      </div>
      <div>
        <span>Teléfono*</span>
        <input formControlName="telefono" type="number" (keyup)="verificarNumero($event)" />
        <div class="errores" *ngIf="!telefonoOrg">El telefono debe tener 7 ó 10 numeros</div>
      </div>
    </div>
    <div class="cambiar-logo">
      <img *ngIf="logo_path == null" src="{{ logo }}" alt="" />
      <img *ngIf="logo_path != null" id="logo" [src]="imageSrc" alt="Logo" height="100px" />
      <input type="file" class="file-upload" (change)="captureLogo($event)" #fileUploadLogo />
      <button type="button" class="cambiar-logo-boton" (click)="fileUploadLogo.click()">Cambiar logo</button>
    </div>
    <button type="submit" class="guardar" [disabled]="!formOrg.valid"
      [ngClass]="{'btn-submit-disabled': !formOrg.valid}">
      Guardar cambios
    </button>
  </form>
</div>