import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MultipasoFormPuntoVentaComponent } from '../modal/multipaso-form-punto-venta/multipaso-form-punto-venta.component';
import { MultipasoComponent } from '../modal/multipaso/multipaso.component';
import { SimpleNoImgComponent } from '../modal/simple-no-img/simple-no-img.component';
import { SimpleComponent } from '../modal/simple/simple.component';
import { AuthService } from '../services/auth/auth.service';
import { LocalStorageService } from '../services/local-storage/local-storage.service';
import { RestService } from '../services/rest/rest.service';
import { Store } from '@ngxs/store';
import { SetSelectedToken } from 'src/app/actions/token.actions';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css'],
})
export class InicioComponent implements OnInit {
  /**Cantidad de puntos por distribuidor */
  puntos_entrega: any;
  /** Guarda los banners publicitarios */
  public banners: any;

  constructor(
    public authService: AuthService,
    private rest: RestService,
    public localStorage: LocalStorageService,
    private modalService: NgbModal,
    private router: Router,
    private ngxsStore: Store
  ) {}

  async ngOnInit() {
    /** Devuelve los puntos de entrega registrados */
    this.rest
      .getJWT('punto_entrega')
      .toPromise()
      .then((puntos: any) => {
        this.puntos_entrega = puntos;
        /** Solo mostrar slides si es primera vista */
        this.authService.user = this.ngxsStore.snapshot().auth.user;

        if (this.authService.user?.show_slides === true) {
          this.lanzarTutorial();
        } else {
          this.fetchPuntosPorDistribuidor();
        }
      });
    this.getBanners();
  }

  /**
   * Devuelve la cantidad de puntos activos que tiene el distribuidor
   * esto es el input para decidir si exige crear un punto o no
   */
  fetchPuntosPorDistribuidor() {
    const id_horeca: string = this.authService.user_horeca?._id || '';
    const puntos = this.puntos_entrega.filter(
      (punto: any) => punto.usuario_horeca == id_horeca && punto.estado == 'Activo'
    );
    /**Solo se pedirá que cree un punto si no hay puntos activos */
    if (puntos.length !== 0) {
      this.lanzarModalSinDistribuidores();
    }
  }

  /**
   * Revisa si es la primera vez de este usuario usando la plataforma. Si sí,
   * lanza un tutorial mostrándole al usuario cómo usar la plataforma. Si no,
   * se salta este paso y pasa a revisar las vinculaciones del usuario
   */
  async lanzarTutorial() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    const modalRef = this.modalService.open(SimpleNoImgComponent, ngbModalOptions);
    modalRef.componentInstance.title = '¡Hola! Queremos que conozcas Feat';
    modalRef.componentInstance.msg =
      'Por eso te mostraremos los accesos que tendrás desde el inicio. ¡Es un gusto tenerte acá!';
    modalRef.componentInstance.btn_msg = 'Empecemos';
    // Inicia Multimodal
    modalRef.componentInstance.close_callback = () => {
      const ngbModalOptions2: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        centered: true,
        size: 'xl',
        windowClass: 'multi-modal',
      };
      const modalRef = this.modalService.open(MultipasoComponent, ngbModalOptions2);
      const pasos: any[] = [
        {
          title: 'Primero lo primero',
          msg: 'Antes de acceder a los portafolios de los distribuidores, debes crear un punto de entrega. Puedes crear varios según las sedes de tu negocio.',
          img_src: '../../../assets/img/modal-bienvenida/1.png',
        },
        {
          title: 'Distribuidores en tu zona',
          msg: 'Una vez creado tu primer punto de entrega podrás acceder a los distribuidores con cobertura para tu zona y solicitar tu vinculación.',
          img_src: '../../../assets/img/modal-bienvenida/2.png',
        },
        {
          title: 'Crea todos tus pedidos en Feat',
          msg: 'Una vez el distribuidor te apruebe, podrás acceder al catálogo de productos.',
          img_src: '../../../assets/img/modal-bienvenida/3.png',
        },
        {
          title: 'Seguimiento paso a paso',
          msg: 'Tendrás el seguimiento del pedido, su estado en el proceso. También podrás modificarlo, enviando un mensaje al distribuidor y así ¡no se te quedará nada por fuera!',
          img_src: '../../../assets/img/modal-bienvenida/4.png',
        },
        {
          title: 'Toda la información a tan solo un clic',
          msg: '¡Con Feat podrás hacer un seguimiento de indicadores y estadísticas que son relevantes para tu negocio!',
          img_src: '../../../assets/img/modal-bienvenida/5.png',
        },
        {
          title: 'Mientras más compras, más ganas',
          msg: 'Con nuestro método de puntos FT podrás acumular puntos y redimirlos en tus próximas compras.',
          img_src: '../../../assets/img/modal-bienvenida/6.png',
        },
        {
          title: 'Entérate de todo',
          msg: 'En la sección de comunidad podrás publicar, encontrar noticias y contenidos para seguir conectándote con la comunidad FEAT.',
          img_src: '../../../assets/img/modal-bienvenida/7.png',
        },
        {
          title: 'Saldos y promociones',
          msg: 'Podrás acceder a descuentos y promociones especiales de tu distribuidor.',
          img_src: '../../../assets/img/modal-bienvenida/9.png',
        },
        {
          title: 'Chequeo de precios',
          msg: 'En esta sección, podrás buscar el precio del producto que necesitas en los distribuidores que tengas vinculados para que puedas tomar la mejor decisión.',
          img_src: '../../../assets/img/modal-bienvenida/10.png',
        },
        {
          title: 'Indicadores',
          msg: 'Cuando veas estos círculos rojos significa que tienes información que aún no has visto.',
          img_src: '../../../assets/img/modal-bienvenida/8.png',
        },
      ];
      modalRef.componentInstance.pasos = pasos;
      modalRef.componentInstance.close_callback = () => {
        /**
         * Cuando el usuario termine de revisar la información
         * se guarda en localStorage y base de datos el indicador
         * para no volver a mostrar cuando se vuelva a iniciar sesión
         */
        const show_slides = {
          show_slides: false,
        };
        /**Se actualiza el trabajador en el back para no mostrar los slides de nuevo*/
        this.rest
          .putJWT(`trabajador/${this.authService.user?._id}`, show_slides)
          .toPromise()
          .then((resp: any) => {
            /**Se actualiza la info de inicio de sesión en el Store con ShowSlide False*/
            this.ngxsStore
              .dispatch(
                new SetSelectedToken({
                  token: this.authService.token,
                  email: this.authService.email,
                  user: resp,
                  user_horeca: this.authService.user_horeca,
                  user_distribuidor: undefined,
                  user_organizacion: undefined,
                })
              )
              .toPromise()
              .then(() => (this.authService.user = this.ngxsStore.snapshot().auth.user));
          });
        if (!this.authService.user?.crado_por_horeca) {
          this.crearPuntoDeEntrega();
        }
        /**Verfica si no hay puntos activos y de no haberlo te obliga a crearlo */
      };
    };
  }
  /**
   * Lanza el modal de creación de punto de entrega
   */
  crearPuntoDeEntrega() {
    /**Evita que al hacer click por fuera se cierre el modal */
    const ngbModalOptions3: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg',
    };
    const modalRef = this.modalService.open(MultipasoFormPuntoVentaComponent, ngbModalOptions3);
    modalRef.result.finally(() => {
      this.fetchPuntosPorDistribuidor();
    });
  }
  /**
   * Revisa si el usuario horeca tiene distribuidores vinculados.
   * Si no tiene, lanza el modal para llevarlo a la parte de distribuidores
   * Si sí tiene, no se lanza el modal
   */
  lanzarModalSinDistribuidores() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const id_horeca: string = this.authService.user_horeca!._id!;
    const puntos_entrega: string[] = [];
    //Recupera todos los puntos de entrega activos del usuario horeca
    for (const pt of this.puntos_entrega) {
      if (pt.usuario_horeca == id_horeca && pt.estado == 'Activo') {
        puntos_entrega.push(pt._id);
      }
    }
    //Revisa si hay por lo menos un punto de entrega activo vinculado a un distribuidor
    this.rest
      .getJWT('distribuidores_vinculados')
      .toPromise()
      .then((resp_2: any) => {
        let flag_vinculado = false;
        for (const aux of resp_2) {
          if (puntos_entrega.includes(aux.punto_entrega) && (aux.estado == 'Aprobado' || aux.estado == 'Pendiente')) {
            flag_vinculado = true;
            break;
          }
        }
        //Si no encontró ninguna vinculación válida, lanza el modal
        if (!flag_vinculado) {
          const ngbModalOptions: NgbModalOptions = {
            centered: true,
            size: 'md',
          };
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg =
            'No tienes distribuidores vinculados. ¡Dirigete a Distribuidores, busca y  conecta tu negocio con ellos para empezar a hacer pedidos!';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            // this.router.navigateByUrl('/distribuidores');
          };
        }
      });
  }

  /**
   * Devuelve los banners del home page
   */
  getBanners() {
    this.rest
      .getJWT('banner/Horeca')
      .toPromise()
      .then((res5: any) => {
        this.banners = res5.filter((element: any) => element.estado === 'Activo');
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  /**
   * Abre url de un banner seleccionado
   */
  goToLink(url: string) {
    window.open(url, '_blank');
  }
}
