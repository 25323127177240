<div class="card" [class.seleccionado]="isActual" [routerLink]="clickable ? ['/actualiza-plan/' + plan._id] : []">
  <div class="card-header" [style.background-color]="getHeaderColor(plan.color)" id="header-principiante">
    <h5>{{ plan.nombre || 'Nombre del plan' }}</h5>
  </div>
  <div class="card-body" [class.body-seleccionado]="isActual" [style.background]="getBackground(plan.color)">
    <br />
    <br />
    <!--Ícono del plan-->
    <div class="row text-center">
      <div class="col-12">
        <div class="marco-icon" [style.background-color]="getMarcoColor(plan.color)">
          <img [src]="plan.imagen" class="icon" alt="Ícono plan" />
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <!--Precio plan-->
    <div class="row text-center" style="color: white">
      <div class="col-12">
        <h5>Al mes</h5>
        <h4>{{ plan.precio || 0 | currency }}</h4>
      </div>
    </div>
    <br />
    <!--Número de clientes-->
    <div class="row text-left" style="color: #140d4a">
      <div class="col-1"></div>
      <div class="col-10 clientes">
        <h5>{{ plan.desde + (plan.hasta ? ' - ' + plan.hasta : '') }}</h5>
        <h5>clientes</h5>
      </div>
      <div class="col-1"></div>
    </div>
  </div>
  <br />
  <div class="card-footer" *ngIf="isActual">
    <h5>Tu plan actual</h5>
  </div>
</div>
