import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RestService } from 'src/app/services/rest/rest.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css'],
})
export class ContactoComponent implements OnInit {
  /** Guarda las politicas y condiciones */
  public politicas_condiciones: any;

  contactoForm: FormGroup;
  remainingTextContacto = 2000;
  constructor(private FormBuilder: FormBuilder, public modalService: NgbModal, private restService: RestService) {
    this.contactoForm = this.FormBuilder.group({
      contactoName: ['', Validators.required],
      contactoEmpresa: [],
      contactoEmail: ['', [Validators.required, Validators.email]],
      contactoCelular: ['', [Validators.required, Validators.pattern(/^3[\d]{9}$/)]],
      contactoMensaje: ['', [Validators.required, Validators.maxLength(2000)]],
      contactoTerminos: [false, Validators.requiredTrue],
    });
  }
  valueChangeEmail(value: string) {
    this.remainingTextContacto = 2000 - value.length - 1;
  }

  ngOnInit(): void {
    this.fetchPolitiasCondiciones();
  }

  /**
   * Envia mail de confirmaci´ón al usuario y mail formulario a Feat
   */
  enviarEmail() {
    const href = '';
    const now: Date = new Date();
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
    };
    const obj_aux: any = {
      asunto: 'Contacto Feat - Te responderemos lo antes posible',
      destinatario: this.contactoForm.get('contactoEmail')?.value,
      mensaje: this.contactoForm.get('contactoMensaje')?.value,
      url: href,
      fecha: `${now.getFullYear}-${now.getMonth}-${now.getDate}`,
      tipo_mensaje: 'Formulario Contacto Home',
    };
    //Servicios para enviar correo a Feat y correo de confirmacion a interesado
    this.restService
      .post('correo/enviar-formulario', obj_aux)
      .toPromise()
      .then(() => {
        this.restService
          .post('correo/enviar-formulario-confirmacion', obj_aux)
          .toPromise()
          .then(() => {
            const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
            modalRef.componentInstance.title = '¡Genial!';
            modalRef.componentInstance.msg = `Se envió un mensaje de confirmación a ${
              this.contactoForm.get('contactoEmail')?.value
            }, por favor, revisa tu bandeja de entrada y de no deseados. Te responderemos lo antes posible.'`;
            modalRef.componentInstance.btn_msg = 'Listo';
            this.contactoForm.reset();
            this.remainingTextContacto = 2000;
          });
      });
  }

  /**
   * Recupera las politicas y condiciones
   */
  fetchPolitiasCondiciones() {
    this.restService
      .get('documentos_politicas')
      .toPromise()
      .then((resp: any) => {
        this.politicas_condiciones = resp;
      });
  }

  /**
   * Abre link en una nueva ventana
   */
  public verDocumento(link: any) {
    window.open(link, '_blank');
  }
}
