<div class="card-container">
    <!-- Establecimientos por tipo de negocio -->
    <div class="card establecimientos-negocio">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-2">
                <div>Establecimientos por tipo de negocio en Feat</div>
            </div>
            <div class="text-center mb-4 col-12">
                Total: {{total_establecimientos_negocio}}
            </div>
            <canvas #bar_establecimientos_negocio></canvas>
        </div>
    </div>
    <!-- Establecimientos por Ciudad -->
    <div class="card establecimientos-distribuidor">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-2">
                <div>Establecimientos por ciudad en Feat</div>
            </div>
            <div class="text-center mb-4 col-12">
                Total: {{total_establecimientos_ciudad}}
            </div>
            <canvas #bar_establecimientos_ciudad></canvas>
        </div>
    </div>
    <!-- Número de distribuidores por tipo de negocio -->
    <div class="card establecimientos-ciudad">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-2">
                <div>Número de distribuidores por tipo de negocio en Feat</div>
            </div>
            <div class="text-center mb-4 col-12">
                Total: {{total_distribuidores_negocio}}
            </div>
            <canvas #bar_distribuidores_negocio></canvas>
        </div>
    </div>
</div>