<app-header-distribuidor></app-header-distribuidor>
<section class="d-flex justify-content-center align-items-center px-4">
  <div id="content" class="container-fluid">
    <div class="w-100">
      <!--Header-->
      <div class="header flex-row d-flex align-items-center">
        <button class="boton-volver" routerLink="/pedidos-distribuidor">
          <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }"></fa-icon>
          &nbsp;Volver
        </button>
        <h3 class="m-0 pl-5">Historial de pedidos sugeridos</h3>
      </div>
      <!--Tabla-->
      <div class="w-100 pt-4">
        <div class="card">
          <div class="card-body scroll-tabla">
            <ng2-smart-table [settings]="settings" [source]="source"></ng2-smart-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>