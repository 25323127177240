<div>
  <div class="crear-user">
    <span>Mi equipo ({{ trabajadores.length }})</span>
    <button (click)="onCrearTrabajador()">Crear un nuevo usuario</button>
  </div>
  <div class="cards-container">
    <div class="user-card" *ngFor="let trabajador of trabajadores">
      <div>
        <span class="iniciales-nombre">{{ trabajador.nombres.charAt(0) }}{{ trabajador.apellidos.charAt(0) }}</span>
        <span>{{ trabajador.nombres }} {{ trabajador.apellidos }}</span>
      </div>
      <hr />
      <div>
        <span class="pildora-cargo">{{ trabajador.tipo_trabajador }}</span>
        <button (click)="detalleTrabajador(trabajador._id)">Ver</button>
      </div>
    </div>
  </div>
</div>
