import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Producto } from 'src/app/models/producto.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-inicio-top-productos',
  templateUrl: './inicio-top-productos.component.html',
  styleUrls: ['./inicio-top-productos.component.css'],
})
export class InicioTopProductosComponent implements OnInit {
  // Guarda los productos mas vendidos
  public top_productos: any[] = [];
  // Fecha actual
  public current_date = new Date(moment.utc().format('DD MMMM YYYY'));

  constructor(
    public authService: AuthService,
    public restService: RestService,
    private router: Router,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.getTopProductos();
  }
  /**
   * Método para verificar el tipo de usuario
   * @returns resultado boolean dependiente de validacion con el tipo de usuario
   */
  async validatePermissions() {
    // eslint-disable-next-line prefer-const
    let blockUser = await this.auth.validatePermissionsUser();
    return blockUser;
  }
  async validarAcceso() {
    // eslint-disable-next-line prefer-const
    let validador = await this.validatePermissions();
    if (validador) {
      this.router.navigate(['/portafolio/agregar-producto']);
    }
  }
  public getTopProductos(): void {
    const fecha_actual = new Date();
    const mes_actual = fecha_actual.getMonth() + 1;
    // eslint-disable-next-line prettier/prettier
    this.restService
      .getJWT(
        `pedido/distribuidor/${this.authService.user_distribuidor?._id}/fecha/2022-${mes_actual}-01/2022-${mes_actual}-31`
      )
      .toPromise()
      .then((productos: any) => {
        this.top_productos = productos;
        // Agrega flag para mostrar o no si tienes puntos ft activos
        for (const producto of this.top_productos) {
          producto.flag_aplica_puntos_feat = false;
          const fecha_apertura = new Date(moment.utc(producto.fecha_apertura_puntosft).format('DD MMMM YYYY'));
          const fecha_cierre = new Date(moment.utc(producto.fecha_cierre_puntosft).format('DD MMMM YYYY'));
          if (
            producto.fecha_apertura_puntosft &&
            producto.fecha_cierre_puntosft &&
            fecha_apertura <= this.current_date &&
            fecha_cierre >= this.current_date
          ) {
            producto.flag_aplica_puntos_feat = true;
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  }
}
