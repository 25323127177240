import { Component, OnInit } from '@angular/core';
import { faChevronLeft, faDownload, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { Router } from '@angular/router';
import { AprobarrechazarDistribuidoresComponent } from '../../modal/aprobarrechazar-distribuidores/aprobarrechazar-distribuidores.component';
import { EstablecimientosService } from '../../services/establecimientos/establecimientos.service';
import { DistribuidorService } from '../../services/distribuidor/distribuidor.service';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { RestService } from 'src/app/services/rest/rest.service';
import { ConfirmacionComponent } from 'src/app/modal/confirmacion/confirmacion.component';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-detalle-clientes',
  templateUrl: './detalle-clientes.component.html',
  styleUrls: ['./detalle-clientes.component.css'],
})
export class DetalleClientesComponent implements OnInit {
  // Furmulario reactivo
  public form!: FormGroup;
  public data = new FormData();
  // Iconos
  public faChevronLeft = faChevronLeft;
  public faArrowUp = faArrowUp;
  public iconDownload = faDownload;
  // Revisión de cambios
  public cambios: string[] = [];
  // Variables
  public idParam: any;
  public dataDistribuidor: any;
  public statisticsDistribuidor: any;
  // Otros puntos de entrega
  public puntosAdicionales: any;
  // Data para el DOM
  public datosDelDistribuidor: any;
  public datosPuntoEntrega: any;
  public idDistribuidor: any;
  public puntosEntrega: any;
  public idUserHoreca: any;
  public estado: any;
  public idEstablecimiento: any;
  public nombreEstablecimiento: any;
  public nombres: any;
  public apellidos: any;
  public nitCc: any;
  public tipoNegocios: any;
  public establecimiento: any;
  public puntoEntrega: any;
  // Documentos del punto
  public CCPDF: any;
  public camComPDF: any;
  public rutPDF: any;
  // Modal de carga para darle feedback al usuario
  modalCarga?: NgbModalRef;
  totalCompras: any;
  totalProductos: any;
  propietario: any;

  constructor(
    private formBuilder: FormBuilder,
    private establecimientosService: EstablecimientosService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    public authService: AuthService,
    private rest: RestService,
    private distribuidorService: DistribuidorService
  ) {
    this.form = this.formBuilder.group({
      estado: ['Rechazado'],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.idParam = params.id;
    });
    this.getDistribuidorVinculado();
  }

  /**
   * Metodo para traer data del punto de entrega,establecimiento y distribuidor
   */
  async getDistribuidorVinculado() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    try {
      /** Estadisticas cliente */
      const data = await this.distribuidorService.getDistribuidoresVinculadosById(this.idParam).toPromise();
      this.dataDistribuidor = data;
      /** Punto de entrega */
      const res = await this.establecimientosService
        .getFilteredPuntoEntrega(this.dataDistribuidor.punto_entrega._id)
        .toPromise();
      this.puntoEntrega = res;
      this.statisticsDistribuidor = await this.distribuidorService
        .getDistribuidoresVinculadosStatisticsById(this.idParam)
        .toPromise();
      /**Nuevo calculos de pedidos realizados */
      this.rest
        .getJWT('calculoPuntoEntrega_compras/' + this.puntoEntrega._id)
        .toPromise()
        .then((total_compras: any) => {
          this.totalCompras = total_compras.data[0].sumatoria[0]?.total || 0;
          this.totalProductos = total_compras.data[0]?.productos.length || 0;
        });
      /** Agrega el total de trabajadores del punto */
      this.rest
        .getJWT('total_trabajadores_en_punto/' + this.puntoEntrega._id + '/' + this.puntoEntrega.usuario_horeca)
        .toPromise()
        .then((total: any) => {
          this.puntoEntrega.numero_trabajadores = total;
        });
      /** Información Establecimiento */
      const resp = await this.establecimientosService
        .getFilteredEstablecimientos(this.puntoEntrega.usuario_horeca)
        .toPromise();
      this.establecimiento = resp;
      /** Información Establecimiento */
      const respUser = await this.establecimientosService.getPropietario(this.puntoEntrega.usuario_horeca).toPromise();
      this.propietario = respUser[0];
      /** Otros puntos asociados al establecimiento */
      this.getPuntosAdicionales();
      /** Documentos del punto de entrega */
      this.getDocuments();
      this.modalCarga?.close();
    } catch (error) {
      this.modalCarga?.close();
    }
  }

  /**
   * Trae la información de otros puntos de entrega asociados al establecimiento
   */
  async getPuntosAdicionales() {
    try {
      this.rest
        .getJWT('punto_entrega_establecimiento_adicionales/' + this.establecimiento._id)
        .toPromise()
        .then((res: any) => {
          this.puntosAdicionales = res;
        });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Metodo para rechazar distribuidor
   */
  rechazarDistribuidor() {
    if (this.form.valid) {
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        centered: true,
      };
      const modalRef = this.modalService.open(ConfirmacionComponent, ngbModalOptions);
      modalRef.componentInstance.msg = '¿Estás seguro de rechazar la vinculación con este punto de entrega?';
      modalRef.componentInstance.btn_msg_no = 'No, volver';
      modalRef.componentInstance.btn_msg_yes = 'Si, cancelar';
      modalRef.componentInstance.callback_no = () => {};
      modalRef.componentInstance.callback_yes = () => {
        const rechazar = this.form.value;
        this.distribuidorService.putDistribuidoresVinculados(this.idParam, rechazar).subscribe(() => {
          const mensaje = {
            tipo: 'Aprobacion de distribuidores',
            mensaje: `El distribuidor ${this.authService.user_distribuidor?.nombre} ha rechazado la solicitud de vinculación del punto de entrega ${this.puntoEntrega.nombre}`,
            punto_entrega: this.puntoEntrega?._id,
          };
          this.rest.putJWT(`notificar/trabajadores/horeca/generico`, mensaje).toPromise();
          this.router.navigate(['/clientes']);
        });
      };
    }
  }

  /**
   * Procesa el cambio temporal de los vendedores asignados a este establecimiento
   */
  public onVendedoresChange(event: any) {
    this.cambios.push('vendedores');
    this.dataDistribuidor.vendedor = event;
  }

  /**
   * Procesa el cambio temporal de los vendedores asignados a este establecimiento
   */
  public onInfoAdicionalChange(event: any) {
    this.cambios.push('infoAdicional');
    this.dataDistribuidor.cartera = event.cartera;
    this.dataDistribuidor.convenio = event.convenio;
  }

  /**
   * Consigue el link del archivo PDF guardado en el back
   * la función retorna el link de cada documento
   */
  getDocuments() {
    this.distribuidorService.getDocument(this.establecimiento._id).subscribe(
      (res) => {
        res.forEach((e: any) => {
          if (e.documento == 'Representante legal') {
            this.CCPDF = e.locacion;
          } else if (e.documento == 'Camara de comercio') {
            this.camComPDF = e.locacion;
          } else if (e.documento == 'Rut') {
            this.rutPDF = e.locacion;
          }
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /**
   * Metodo para lanzar el modal
   */
  onAprobar() {
    const mensaje = {
      tipo: 'Aprobacion de distribuidores',
      mensaje: `El distribuidor ${this.authService.user_distribuidor?.nombre} ha aprobado la solicitud de vinculación del punto de entrega ${this.puntoEntrega.nombre}`,
      punto_entrega: this.puntoEntrega?._id,
    };
    const modalRef = this.modalService.open(AprobarrechazarDistribuidoresComponent, {
      centered: true,
      windowClass: 'modal-Aprobar',
    });
    modalRef.componentInstance.idDistribuidor = this.idDistribuidor;
    modalRef.componentInstance.idParam = this.idParam;
    modalRef.componentInstance.dataDistribuidor = this.dataDistribuidor;
    modalRef.componentInstance.mensaje_aprobar = mensaje;
  }

  /**
   * Guarda los cambios con ayuda del servicio de distribuidores
   */
  public async onSaveChanges() {
    /** Opciones para los modales de error y exito */
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    const modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    try {
      if (this.cambios.includes('vendedores')) {
        await this.distribuidorService
          .setVendedoresDistribuidorVinculados(this.idParam, this.dataDistribuidor.vendedor)
          .toPromise();
        this.cambios.splice(this.cambios.indexOf('vendedores'), 1);
      }
      if (this.cambios.includes('infoAdicional')) {
        await this.distribuidorService
          .putDistribuidoresVinculados(this.idParam, {
            cartera: this.dataDistribuidor.cartera.toString() === 'true' ? true : false,
            pazysalvo: this.dataDistribuidor.cartera.toString() === 'true' ? false : true,
            convenio: this.dataDistribuidor.convenio.toString() === 'true' ? true : false,
          })
          .toPromise();
        this.cambios.splice(this.cambios.indexOf('infoAdicional'), 1);
      }
      /** Mensaje de exito por la carga de la promocion */
      modalCarga.close();
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../../assets/img/icon-check-verde.png';
      modalRef.componentInstance.title = '¡Genial!';
      modalRef.componentInstance.msg = 'La información se actualizó con éxito.';
      modalRef.componentInstance.btn_msg = 'Listo';
      modalRef.componentInstance.close_callback = () => {
        this.router.navigate(['/clientes']);
      };
    } catch (e) {
      modalCarga.close();
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'No fue posible guardar los cambios. Por favor intenta de nuevo más tarde.';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {};
    }
  }

  /**
   * Abre la url por parámetro en una nueva pestaña
   */
  public openDocument(url: string) {
    window.open(url, '_blank');
  }
}
