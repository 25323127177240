<div class="contenedor">
  <fa-icon [icon]="faTimes" [styles]="{ color: '#41403E' }" id="fa-icon" (click)="close()"></fa-icon>
  <h5>Selecciona un punto de entrega para continuar</h5>
  <span>Punto de entrega</span>
  <select name="select" [(ngModel)]="punto_seleccionado">
    <option *ngFor="let punto of puntos_entrega; index as i;" [ngValue]="punto">
      {{ punto.nombre }}
    </option>
  </select>
  <h6 (click)="crearPuntoDeEntrega()" class="agregar-punto w-100 text-right" *ngIf="mostrar_crear_punto">
    <fa-icon [icon]="faPlus" (click)="close()"></fa-icon> Crear un nuevo punto
  </h6>
  <button (click)="done()">
    Listo
  </button>
</div>