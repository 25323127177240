<div class="row margin-row">
    <div class="col-12 text-center">
      <br />
      <div class="row">
        <div class="col-12">
          <img [src]="img_src" alt="Ícono modal" id="icon-modal" />
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-12">
          <h3>
            {{ title }}
          </h3>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-12 text-center">
          <p>
            {{ msg }}
          </p>
          <p>
            ¿Desearías cargar el pedido sugerido? <br> o <br> 
            ¿Quisieras seguir tu compra con el punto de entrega seleccionado? </p>
        </div>
      </div>
      <br />
      <br />
      <div class="row">
        <div class="col-12">
            <button type="button" class="btn btn-success" (click)="onClose('cargar')">Cargar pedido sugerido a carrito</button>
        </div>
      </div>
      <br />
      <br />
      <div class="row">
        <div class="col-12">
            <button type="button" class="btn btn-info" (click)="onClose('seguir')">Seguir con mi compra</button>
        </div>
      </div>
      <br />
    </div>
    <div class="col-1">
    </div>
  </div>