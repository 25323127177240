import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  /** Compartir credendiales entre componentes */

  private fuenteClave = new BehaviorSubject<string>('');
  claveActual = this.fuenteClave.asObservable();

  sendClave(clave: string) {
    this.fuenteClave.next(clave);
  }

  private fuenteCorreo = new BehaviorSubject<string>('');
  correoActual = this.fuenteCorreo.asObservable();

  sendCorreo(correo: string) {
    this.fuenteCorreo.next(correo);
  }

  /** Compartir funcionalidad de login*/

  private subject = new Subject<any>();

  sendLoginEvent() {
    this.subject.next();
  }

  getLoginEvent(): Observable<any> {
    return this.subject.asObservable();
  }
}
