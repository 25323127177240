<div class="container-fluid fondo overflow-hidden">

  <div class="row">
    <div class="col-12">
          <h1 class="feat-experience expeText">Experiencia Feat</h1>

    </div>
    <div class="col-12">
    <div class="col d-flex justify-content-center w-100 padding-video">
      <div class="video w-100">
        <video controls (click)="toggleVideo()" #videoPlayer class="w-100 altoVideo">
          <source src='https://feat-resources.s3.amazonaws.com/recursos/feat_v2.mp4' type="video/mp4" />
          Browser not supported
        </video>
      </div>
    </div>
    </div>


  </div>
</div>