import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-producto-card',
  templateUrl: './top-producto-card.component.html',
  styleUrls: ['./top-producto-card.component.css'],
})
export class TopProductoCardComponent implements OnInit {
  @Input() producto: any;

  constructor() {}

  ngOnInit(): void {}

  calcularPrecioCaja(producto: any) {
    let precioDescuento =
      producto?.precios[0]?.precio_unidad - producto?.precios[0]?.precio_unidad * (producto?.prodPorcentajeDesc / 100);
    precioDescuento = Math.round(precioDescuento);
    return precioDescuento * producto?.precios[0]?.und_x_caja;
  }
}
