import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class TransaccionService {
  constructor(private http: HttpClient, private authService: AuthService) {}
  private headers = new HttpHeaders({ Authorization: this.authService.token });

  /**
   *
   * @param id id de la transacción
   * @returns
   */
  public getTransaccion(id: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/transaccion/${id}`, {
      headers: this.headers,
    });
  }

  /** Servicio de tipo put para vincular la referencia de pago de Wompi a una transaccion
   * @param id id de la transaccion
   * @param referencia Referencia de pago de Wompi
   */
  public vincularReferenciaWompi(id: string, referencia: any): Observable<any> {
    return this.http.put<any>(
      `${environment.backendUrl}/api/transaccion/${id}/vincular_referencia_wompi`,
      {
        referencia_wompi: referencia,
      },
      {
        headers: this.headers,
      }
    );
  }

  /** Servicio de tipo post para crear una transacción
   */
  createTransaccion(data: any): Observable<any> {
    data.usuario = this.authService.user?._id || null;
    return this.http.post<any>(`${environment.backendUrl}/api/transaccion`, data, {
      headers: this.headers,
    });
  }

  /** Servicio de tipo get para consultar estado de una transacción
   */
  getWompiPaymentData(id: string): Observable<any> {
    return this.http.get<any>(`https://production.wompi.co/v1/transactions/${id}`);
  }
}
