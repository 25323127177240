<app-header></app-header>
<div class="row">
  <div class="col-6">
    <app-banner-login></app-banner-login>
  </div>
  <div class="col-6">
    <div class="card" id="card-login">
      <div class="card-body">
        <h4 class="card-title">
          <fa-icon
            [icon]="faChevronLeft"
            size="1x"
            id="fa-icon-password"
            [styles]="{'color': 'black'}"
            pull="left"
            routerLink="/login"
          ></fa-icon>
          &nbsp;&nbsp;&nbsp;Recuperar contraseña
        </h4>
        <br />
        <p>Ingresa tu nueva contraseña</p>
        <br />
        <label for="clave">Contraseña</label>
        <br />
        <div class="input-group">
          <input
            [type]="fieldTextType ? 'text' : 'password'"
            id="password"
            name="password"
            placeholder="Escribe aquí"
            class="form-control input-pass"
            [(ngModel)]="clave"
          />
          <div class="input-group-append input-group-password">
            <span class="input-group-text input-group-password">
              <fa-icon
                [icon]="faEyeSlash"
                size="1x"
                [styles]="{'color': 'black'}"
                pull="right"
                (click)="mostrarOcultarPassword()"
                *ngIf="fieldTextType"
              ></fa-icon>
              <fa-icon
                [icon]="faEye"
                size="1x"
                [styles]="{'color': 'black'}"
                pull="right"
                (click)="mostrarOcultarPassword()"
                *ngIf="!fieldTextType"
              ></fa-icon>
            </span>
          </div>
        </div>
        <br />
        <br />
        <label for="clave_conf">Confirma tu contraseña</label>
        <br />
        <div class="input-group">
          <input
            [type]="fieldTextTypeConf ? 'text' : 'password'"
            id="password"
            name="password"
            placeholder="Escribe aquí"
            class="form-control input-pass"
            [(ngModel)]="clave_conf"
          />
          <div class="input-group-append input-group-password">
            <span class="input-group-text input-group-password">
              <fa-icon
                [icon]="faEyeSlash"
                size="1x"
                [styles]="{'color': 'black'}"
                pull="right"
                (click)="mostrarOcultarPasswordConf()"
                *ngIf="fieldTextTypeConf"
              ></fa-icon>
              <fa-icon
                [icon]="faEye"
                size="1x"
                [styles]="{'color': 'black'}"
                pull="right"
                (click)="mostrarOcultarPasswordConf()"
                *ngIf="!fieldTextTypeConf"
              ></fa-icon>
            </span>
          </div>
        </div>
        <br />
        <p class="error" *ngIf="error_form">Debes llenar todos los campos primero</p>
        <p class="error" *ngIf="error_clave">Las contraseñas deben ser iguales</p>
        <br />
        <br />
        <button
          (click)="reiniciarClave()"
          class="btn-submit">
          &nbsp;&nbsp;&nbsp;Enviar&nbsp;&nbsp;&nbsp;
        </button>
        <br />
        <br />
      </div>
    </div>
  </div>  
</div>