import { Component, OnInit } from '@angular/core';
import { faComment, faHeart } from '@fortawesome/free-regular-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { PublicacionService } from 'src/app/services/publicacion/publicacion.service';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-inicio-comunidad',
  templateUrl: './inicio-comunidad.component.html',
  styleUrls: ['./inicio-comunidad.component.css'],
})
export class InicioComunidadComponent implements OnInit {
  /** Íconos FontAwesome para la UI */
  faEllipsisV = faEllipsisV;
  faHeart = faHeart;
  faComment = faComment;
  publicaciones: any;
  /*Variabales*/
  comments: any;
  publication: any;
  amountLikes: any;
  likes: any;
  filterEstados: any;
  filterUser: any;
  idLike: any;
  estadoLikes: any;
  userLike: any;
  publicacionesOrdenadas: any;
  // Logos
  public logo_feat = '../../../assets/img/logo-feat-largo.png';
  public distribuidor_placeholder = '../../../assets/img/icon-organizacion.png';

  constructor(private publicationService: PublicacionService, private rest: RestService) {}

  async ngOnInit() {
    const publication: any = await this.rest.getJWT(`publicacion/landing/`).toPromise();
    this.publication = publication.data;
    this.publicacionesOrdenadas = publication.data;
    this.fetchComments();
  }

  /** Con este metodo unimos variables al objeto de la api de publicaciones */
  findCommentsByPublications() {
    for (const publi in this.publication) {
      const commentsOfPublications: any[] = [];
      for (const item in this.comments) {
        if (this.publication[publi]._id === this.comments[item].publicacion) {
          commentsOfPublications.push(this.comments[item]);
        }
      }
      this.publication[publi]['comments'] = commentsOfPublications;
    }
  }

  /** Con este metodo unimos variables al objeto de la api de publicaciones */
  findLikesByPublications() {
    for (const publis in this.publication) {
      const likesOfPublication: any[] = [];
      for (const key in this.likes) {
        if (this.publication[publis]._id === this.likes[key].publicacion) {
          likesOfPublication.push(this.likes[key]);
        }
        this.amountLikes = likesOfPublication.length;
      }
      this.publication[publis]['likes'] = likesOfPublication;
      this.publication[publis]['cantidad_likes'] = this.amountLikes;
      this.publication[publis]['estados_likes'] = this.filterEstados;
      this.publication[publis]['user_like'] = this.filterUser;
      this.publication[publis]['id_like'] = this.idLike;
    }
  }

  async fetchComments() {
    this.publicationService
      .getComments()
      .toPromise()
      .then((result) => {
        this.comments = result;
        this.findCommentsByPublications();
        this.publicationService
          .getLikes()
          .toPromise()
          .then((resp) => {
            this.likes = resp;
            for (const item in this.likes) {
              this.estadoLikes = this.likes[item].estado;
              this.userLike = this.likes[item].usuario;
            }
            this.findLikesByPublications();
          });
      });
  }
}
