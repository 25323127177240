import { Component, DoCheck, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { PlacesService } from 'src/app/services/places/places.service';
import { MapService } from 'src/app/services/map/map.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { SetSelectedToken } from 'src/app/actions/token.actions';
import { Store } from '@ngxs/store';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { faEye, faEyeSlash, faPaperclip, faTrash, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FormControl } from '@angular/forms';
import { AgmMap, MapsAPILoader } from '@agm/core';
declare const google: any;
@Component({
  selector: 'app-informacion-empresa',
  templateUrl: './informacion-empresa.component.html',
  styleUrls: ['./informacion-empresa.component.scss'],
})
export class InformacionEmpresaComponent implements OnInit, DoCheck {
  @ViewChild('search')
  public searchElementRef!: ElementRef;
  public latitude!: number;
  public longitude!: number;
  public searchControl!: FormControl;
  public zoom!: number;
  /** Formularios reactivos */
  public distribuidorForm: FormGroup;
  /**Placeholder para productos sin imagen */
  public empresa_Placeholder = '../../assets/img/icon-organizacion.png';
  /** Listado de tipos de distribuidores */
  public tipos_dist: string[] = [];
  /** Variables de control para los sliders de los horarios de atención */
  public flagEntreSemana = false;
  public minValueEntreSemana = 3;
  public maxValueEntreSemana = 17;
  public flagSabados = false;
  public minValueSabados = 6;
  public maxValueSabados = 18;
  public flagDomingos = false;
  public minValueDomingos = 6;
  public maxValueDomingos = 18;
  options: Options = {
    floor: 0,
    ceil: 24,
    step: 0.5,
    showTicks: false,
    translate: (): string => {
      return '';
    },
  };
  icon: any;
  /** Letras disponibles para la descripción */
  public remainingText = 500;
  /** Guarda el nombre de los documentos que se vayan subiendo */
  public rutName = '';
  public camComName = '';
  public CCName = '';
  public CCBancaria = '';
  public certName = '';
  public creditoName = '';
  public creacionName = '';
  /** Guarda el link de los documentos ya en la BD */
  public CCPDF = '';
  public camComPDF = '';
  public rutPDF = '';
  public bancariaPDF = '';
  public cBancaria = '';
  public credito = '';
  public creacionCliente = '';
  /** Guarda el logo y archivos que se vayan a subir */
  public archivos: File[] = [];
  public imageSrc: any;
  /** Iconos */
  public faTrash = faTrash;
  public faPaperclip = faPaperclip;
  public faEye = faEye;
  public faEyeSlash = faEyeSlash;
  public faTimesCircle = faTimesCircle;
  /** Validación de formulario que no entran en los validators */
  public is_boton_habilitado = true;
  public validator_tiempo_entrega = false;
  public validator_dias_atencion = false;
  public validator_telefono_2 = false;
  public validator_zona_cobertura = false;
  /** Variables para el manejo de la lista de departamentos y ciudades */
  public ciudades: any;
  public ciudades_empresa: any;
  public departamentos: any;
  public departamentos_empresa: any;
  public departamentosPoligono: any;
  public departamentoPoligono: any;
  public ciudadPoligono: any;
  public ciudadesPoligono: any;
  /** Datos de la empresa para editar */
  empresa: any = JSON.parse(JSON.stringify(this.auth.user_distribuidor));
  /** Decide que mapa mostrar (solo visualización o editable) */
  mapa_inicial = true;
  /** Guarda las coordenadas del poligono de cobertura */
  nueva_cobertura: any;
  poligono_final: { longitud: number; latitud: number }[] = [];

  /******************* Datos para el manejo del mapa 1 *******************/
  /**
   * Datos y funciones para el manejo del mapa de area de cobertura
   * Código tomado y adaptado de https://mariazacharia-k.medium.com/drawing-a-polygon-using-angular-google-maps-2ea7e5cd9360
   * y https://github.com/mzachari/agm-example/blob/master/src/app/agm-example/agm-example.component.html
   * Es necesario agregar el script de https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&sensor=false&libraries=drawing al index
   */
  @ViewChild('AgmMap') agmMap!: AgmMap;
  //@ViewChild('AgmMapNat') agmMapNat!: AgmMap;
  //@ViewChild('AgmMapArea') agmMapArea!: AgmMap;
  public map: any = { lat: 4.678508639544325, lng: -74.05550588007192 };
  public mapPoligono: any = { lat: 4.678508639544325, lng: -74.05550588007192 };
  public drawingManager: any;
  public selectedShape: any;
  public selectedArea = 0;
  public drawing_map: any;
  public descripcionModelo: any;

  GoogleAutocomplete: any;
  autocompletee: { input: string };
  autocompleteItems: any[];
  mapCenterPosition = {
    lat: 11.3821188,
    lng: 77.89472419999993,
  };

  polygon: any;

  polygonsList: any = [];
  valoresPedido: any = [];
  coordMulti: any = [];
  direccionAux: any;
  listaColores: any = [];
  referenciaMapa: any;

  managerOptions = {
    drawingControl: true,
    drawingControlOptions: {
      drawingModes: ['polygon'],
    },
    polygonOptions: {
      draggable: true,
      editable: true,
    },
    drawingMode: 'polygon',
  };
  optionss: any = {
    lat: 33.5362475,
    lng: -111.9267386,
    zoom: 10,
    fillColor: '#DC143C',
    draggable: true,
    editable: true,
    visible: true,
  };
  center: any = {
    lat: 4.570868,
    lng: -74.297333,
  };
  public paths = [
    { lat: 25.774, lng: -80.19 },
    { lat: 18.466, lng: -66.118 },
    { lat: 32.321, lng: -64.757 },
  ];
  nuevaCoo: any = [];
  histo: any = [];
  listaHoras: any = Array(48)
    .fill(1)
    .map((x, i) => i + 1);
  listaDias: any = Array(30)
    .fill(1)
    .map((x, i) => i + 1);
  diasSemana: any = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  coordenadasPuntos: any = [];
  @ViewChild('direccionInput') direccionInput!: ElementRef;
  constructor(
    public auth: AuthService,
    private rest: RestService,
    public places: PlacesService,
    private mapService: MapService,
    private distribuidorService: DistribuidorService,
    private modalService: NgbModal,
    private ngxsStore: Store,
    private formBuilder: FormBuilder,
    private currencyPipe: CurrencyPipe,
    private sanitizer: DomSanitizer,
    private mapsApiLoader: MapsAPILoader,
    public zone: NgZone
  ) {
    this.icon = {
      url: '../../../assets/img/icon-circle-red2.svg',
      scaledSize: {height: 8, width: 8}
      }
    this.autocompletee = { input: '' };
    this.autocompleteItems = [];
    this.distribuidorForm = this.formBuilder.group({
      distribuidorCorreo: ['', [Validators.required, Validators.email]],
      distribuidorNombre: ['', Validators.required],
      distribuidorRazon: ['', Validators.required],
      distribuidorNit: ['', Validators.required],
      distribuidorDescripcion: ['', [Validators.required, Validators.pattern(/^[\s\S]{20,500}$/)]],
      distribuidorTipo: ['', Validators.required],
      distribuidorTelefono: ['', [Validators.required, Validators.pattern(/^(?=[0-9]*$)(?:.{7}|.{10})$/)]],
      distribuidorTelefono2: [''],
      distribuidorPais: ['', Validators.required],
      distribuidorDepartamento: ['', Validators.required],
      distribuidorCiudad: ['', Validators.required],
      distribuidorDireccion: ['', Validators.required],
      distribuidorMetodoPagoCredito: [''],
      distribuidorMetodoPagoEfectivo: [''],
      distribuidorMetodoPagoTransferencia: [''],
      distribuidorMetodoPagoDatafono: [''],
      distribuidorHorario_L_V: [''],
      distribuidorHorario_S: [''],
      distribuidorHorario_D: [''],
      distribuidorCoberturaCoordenadas: [''],
      urlPago: [''],
    });
    this.direccionAux = this.auth.user_distribuidor?.direccion;
    this.descripcionModelo = this.auth.user_distribuidor?.descripcion;
    this.departamentoPoligono = this.auth.user_distribuidor?.departamento;
    this.ciudadPoligono = this.auth.user_distribuidor?.ciudad;
    this.distribuidorForm.patchValue({
      distribuidorCorreo: this.auth.user_distribuidor?.correo,
      distribuidorNombre: this.auth.user_distribuidor?.nombre,
      distribuidorRazon: this.auth.user_distribuidor?.razon_social,
      distribuidorNit: this.auth.user_distribuidor?.nit_cc,
      distribuidorDescripcion: this.auth.user_distribuidor?.descripcion,
      distribuidorTipo: this.auth.user_distribuidor?.tipo,
      distribuidorTelefono: this.auth.user_distribuidor?.celular,
      distribuidorTelefono2: '',
      distribuidorPais: 'Colombia',
      distribuidorDepartamento: this.auth.user_distribuidor?.departamento,
      distribuidorCiudad: this.auth.user_distribuidor?.ciudad,
      distribuidorDireccion: this.auth.user_distribuidor?.direccion,
      urlPago: this.auth.user_distribuidor?.urlPago,
      distribuidorMetodoPagoCredito: this.auth.user_distribuidor?.metodo_pago?.includes('Crédito'),
      distribuidorMetodoPagoEfectivo: this.auth.user_distribuidor?.metodo_pago?.includes('Efectivo'),
      distribuidorMetodoPagoTransferencia: this.auth.user_distribuidor?.metodo_pago?.includes('Transacción bancaria'),
      distribuidorMetodoPagoDatafono: this.auth.user_distribuidor?.metodo_pago?.includes('Datáfono'),
      distribuidorHorario_L_V: this.flagEntreSemana,
      distribuidorHorario_S: this.flagSabados,
      distribuidorHorario_D: this.flagDomingos,
      distribuidorCoberturaCoordenadas: this.auth.user_distribuidor?.cobertura_coordenadas,
    });
    if (this.auth.user_distribuidor?.telefono !== undefined) {
      this.distribuidorForm.patchValue({
        distribuidorTelefono2: this.auth.user_distribuidor?.telefono,
      });
    }
    this.poligono_final = this.distribuidorForm.get('distribuidorCoberturaCoordenadas')?.value;
  }
  onMapReady(map: any) {
    this.referenciaMapa = map;
    this.initDrawingManager(map);
    //this.drawPolygon(map);
  }
  initDrawingManager = (map: any) => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ['polygon'],
      },
      polygonOptions: {
        draggable: false,
        editable: true,
        fillColor: this.listaColores[this.coordMulti.length],
        strokeWeight: 0,
        fillOpacity: 0.6,
        //paths: this.histo,
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
    };
    this.drawingManager = new google.maps.drawing.DrawingManager(options);

    for (let i = 0; i < this.coordMulti.length; i++) {
      //Add the polygon
      const p = new google.maps.Polygon({
        paths: this.coordMulti[i],
        strokeWeight: 0,
        fillColor: this.listaColores[i],
        fillOpacity: 0.6,
        indexID: i,
        editable: true,
        draggable: false,
      });
      this.polygonsList = [...this.polygonsList, p];
      p.setMap(map);
    }
    this.drawingManager.setMap(map);
    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const indexPolygon = event.overlay.zIndex;
      if (event.type === google.maps.drawing.OverlayType.POLYGON) {
        const paths = event.overlay.getPaths();
        for (let p = 0; p < paths.getLength(); p++) {
          google.maps.event.addListener(paths.getAt(p), 'set_at', () => {
            if (!event.overlay.drag) {
              self.updatePointList(event.overlay.getPath(), indexPolygon);
            }
          });
          google.maps.event.addListener(paths.getAt(p), 'insert_at', () => {
            self.updatePointList(event.overlay.getPath(), indexPolygon);
          });
          google.maps.event.addListener(paths.getAt(p), 'remove_at', () => {});
        }
        self.updatePointList(event.overlay.getPath(), this.coordMulti.length, event.overlay, map);
      }
    });
  };
  ngOnInit(): void {
    this.listaColores = this.mapService.listaColoresPoligono();
    this.formarDatosPoligono();
    this.setOrderValues(1);
    /** Departamentos y ciudades */
    this.getPlaces();
    this.getPlacesPoligonos();
    /** Categorías */
    this.getTiposDist();
    /** Documentos */
    this.getDocuments();
    /** Horarios atención */
    this.procesarTiempoDeAtencion();
    /** Formulario Reactivo */
    this.distribuidorForm.markAllAsTouched();
    /** Contador de caracteres de textbox */
    this.determinarCaracteres();
    /** Carga mapa para establecer poligono de cobertura */
    this.buscarDireccion(true);
    this.obtenerPuntosEntrega();
    this.poligono_final = this.distribuidorForm.get('distribuidorCoberturaCoordenadas')?.value;
  }

  getCoordAutocompletar() {
    this.mapsApiLoader.load().then(() => {
      const autoCompleteMaps = new google.maps.places.Autocomplete(this.direccionInput.nativeElement);
      autoCompleteMaps.addListener(`place_changed`, () => {
        this.zone.run(() => {
          // some details
          const place: google.maps.places.PlaceResult = autoCompleteMaps.getPlace();
          const address = place.formatted_address;
          this.map.lat = place.geometry?.location.lat();
          this.map.lng = place.geometry?.location.lng();
          if (address) {
            this.distribuidorForm.get('distribuidorDireccion')?.setValue(address);
            this.direccionAux = address;
          }
        });
      });
    });
  }

  polygonCreated($event: any) {
    if (this.polygon) {
      this.polygon.setMap(null);
    }
    this.polygon = $event;
    //this.addPolygonChangeEvent(this.polygon);
    google.maps.event.addListener(this.polygon, 'coordinates_changed', function (index: any, obj: any) {
      // Polygon object: yourPolygon
    });
  }

  getPaths() {
    if (this.polygon) {
      const vertices = this.polygon.getPaths().getArray()[0];
      const paths: string[] = [];
      vertices.getArray().forEach(function (xy: any, i: any) {
        const latLng = {
          lat: xy.lat(),
          lng: xy.lng(),
        };
        paths.push(JSON.stringify(latLng));
      });
      return paths;
    }
    return [];
  }
  /*
  addPolygonChangeEvent(polygon: any) {
    // eslint-disable-next-line prefer-const
    let me = polygon,
      isBeingDragged = false,
      // eslint-disable-next-line prefer-const
      triggerCoordinatesChanged = function () {
        // Broadcast normalized event
        google.maps.event.trigger(me, 'coordinates_changed');
      };

    // If  the overlay is being dragged, set_at gets called repeatedly,
    // so either we can debounce that or igore while dragging,
    // ignoring is more efficient
    google.maps.event.addListener(me, 'dragstart', function () {
      isBeingDragged = true;
    });

    // If the overlay is dragged
    google.maps.event.addListener(me, 'dragend', function () {
      triggerCoordinatesChanged();
      isBeingDragged = false;
    });

    // Or vertices are added to any of the possible paths, or deleted
    const paths = me.getPaths();
    paths.forEach(function (path: any, i: any) {
      google.maps.event.addListener(path, 'insert_at', function () {
        triggerCoordinatesChanged();
      });
      google.maps.event.addListener(path, 'set_at', function () {
        if (!isBeingDragged) {
          triggerCoordinatesChanged();
        }
      });
      google.maps.event.addListener(path, 'remove_at', function () {
        triggerCoordinatesChanged();
      });
    });
  }
  */
  /* onMapReady(map: google.maps.Map) {
    this.initDrawingManager(map);
  }*/
  ngDoCheck(): void {
    this.validarFormulario();
  }
  ngAfterViewInit(): void {
    this.getCoordAutocompletar();
  }
  ejecuta() {
    const content = 'ejido med';
    const autocomplete2 = new google.maps.places.Autocomplete(content, {
      types: ['establishment', 'geocode'],
    });

    const autocomplete = new google.maps.places.Autocomplete(content);
    autocomplete.addListener('place_changed', async () => {
      ///WE CAN CONFIGURE MORE COMPLEX FUNCTIONS SUCH AS UPLOAD DATA TO FIRESTORE OR LINK IT TO SOMETHING
    });
  }
  /************************************* Validaciones para formularios *************************************/
  /** Estan son validaciones manuales dado que no son requeridas o dependen de otras validaciones */
  validarFormulario() {
    /** Todas inician el false */
    this.is_boton_habilitado = false;
    this.validator_tiempo_entrega = false;
    this.validator_dias_atencion = false;
    this.validator_telefono_2 = false;
    this.validator_zona_cobertura = false;
    let validator_poligono = false;
    /** Al menos un horario seleccionado */
    if (
      this.distribuidorForm.get('distribuidorHorario_L_V')?.value == true ||
      this.distribuidorForm.get('distribuidorHorario_S')?.value == true ||
      this.distribuidorForm.get('distribuidorHorario_D')?.value == true
    ) {
      this.validator_dias_atencion = true;
    }
    /** Al menos un metodo de pago seleccioando */
    if (
      this.distribuidorForm.get('distribuidorMetodoPagoCredito')?.value == true ||
      this.distribuidorForm.get('distribuidorMetodoPagoEfectivo')?.value == true ||
      this.distribuidorForm.get('distribuidorMetodoPagoTransferencia')?.value == true ||
      this.distribuidorForm.get('distribuidorMetodoPagoDatafono')?.value == true
    ) {
      this.validator_tiempo_entrega = true;
    }
    /** Si escribe un segundo telefono que tengas 7 o 10 digítos */
    if (
      this.distribuidorForm.get('distribuidorTelefono2')?.value.toString().length == 0 ||
      this.distribuidorForm.get('distribuidorTelefono2')?.value.toString().length == 7 ||
      this.distribuidorForm.get('distribuidorTelefono2')?.value.toString().length == 10
    ) {
      this.validator_telefono_2 = true;
    }
    /** Zona de cobertura seleccionada */
    if (this.selectedShape > 0 || this.poligono_final.length > 0) {
      this.validator_zona_cobertura = true;
    }
    /** Al menos una zona de cobertura completa */
    const valorPedido = this.valoresPedido[0]?.valor_pedido.replace(/[^\d,-]/g, '');
    if (this.coordMulti.length > 0 && this.valoresPedido.length > 0 && valorPedido > 0) {
      validator_poligono = true;
    }
    /** Compila todas las validaciones establecidas y si todo esta OK habilta el guardado*/
    if (
      this.validator_tiempo_entrega === true &&
      this.validator_dias_atencion === true &&
      this.validator_telefono_2 === true &&
      //this.validator_zona_cobertura === true &&
      validator_poligono === true
    ) {
      this.is_boton_habilitado = !this.distribuidorForm.invalid;
    }
  }

  /************************* Recupera y transforma la información del distribuidor *************************/
  /**
   * Consulta al back los tipos de distribuidores que existen
   */
  async getTiposDist() {
    const resp_tipos: any = await this.rest.getJWT('categoria_distribuidor').toPromise();
    if (resp_tipos != null) {
      for (const tipo_aux of resp_tipos.data) {
        this.tipos_dist.push(tipo_aux.nombre);
      }
    }
  }

  /**
   * Permite traducir un número del slider de horario a una hora legible por el usuario
   * @param value El valor a traducir entre 0.0 y 24.0 en saltos de 0.5
   * @returns Un String entre 0:00 y 24:00 pensado como horas en saltos de 30 minutos
   */
  valueToTime(value: number): string {
    const value_str: string[] = value.toString().split('.');
    const hr: string = value_str[0];
    let mn = '00';
    if (value_str[1] != null) {
      mn = '30';
    }
    return hr + ':' + mn;
  }

  /**
   * Transforma el dinero minimo de compra de número a moneda y viceversa
   * a moneda se utilizará para reemplazar el input y dar mejor UX al usuario
   * y a número plano para guardar el dato correctamente en la base de datos
   * ademas de poder editar mejor el valor en el input
   */
  public transformCurrency(event: any) {
    this.distribuidorForm.patchValue({
      distribuidorValorPedido: event.target.value.replace(/[^\d,-]/g, ''),
    });
  }
  public transformAmount(event: any) {
    this.distribuidorForm.patchValue({
      distribuidorValorPedido: this.currencyPipe.transform(event.target.value, '$ ', 'symbol', '1.0-0'),
    });
  }
  public transformCurrencyValue(index: number, event: any) {
    this.valoresPedido[index].valor_pedido = event.target.value.replace(/[^\d,-]/g, '');
  }
  public transformAmountValue(index: number, event: any) {
    this.valoresPedido[index].valor_pedido = this.currencyPipe.transform(event.target.value, '$ ', 'symbol', '1.0-0');
  }
  setOrderValues(type: number) {
    for (const pedido of this.valoresPedido) {
      if (type === 1 && typeof pedido.valor_pedido !== 'string') {
        pedido.valor_pedido = this.currencyPipe.transform(pedido.valor_pedido, '$ ', 'symbol', '1.0-0');
      } else if (type === 2) {
        pedido.valor_pedido = pedido.valor_pedido.replace(/[^\d,-]/g, '');
      }
    }
  }
  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateNumber(event: any) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || excludedKeys.includes(keyCode))) {
      event.preventDefault();
    }
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateOnlyText(event: any) {
    const keyCode = event.keyCode;
    if (keyCode >= 48 && keyCode <= 57) {
      event.preventDefault();
    }
  }

  /**
   * Message box contador de letras
   */
  valueChange(value: string) {
    this.remainingText = 500 - value.length - 1;
  }

  /**
   * Toma el horario de atención del distribuidor y lo interpreta para asignarle los valores
   * correctos a los checkboxes y sliders del horario de atención
   */
  procesarTiempoDeAtencion() {
    const split_horario = this.auth.user_distribuidor?.horario_atencion?.split(', ');
    split_horario?.forEach((element: any) => {
      const horario = element.split(' ');
      /** Inicio */
      const horario_inicio = horario[1].split(':');
      let hora_inicio = horario_inicio[0];
      if (horario_inicio[1] === '30') {
        hora_inicio = Number(hora_inicio) + 0.5;
      } else {
        hora_inicio = Number(hora_inicio) + 0;
      }
      /** Fin */
      const horario_fin = horario[3].split(':');
      let hora_fin = horario_fin[0];
      if (horario_fin[1] === '30') {
        hora_fin = Number(hora_fin) + 0.5;
      } else {
        hora_fin = Number(hora_fin) + 0;
      }
      if (element.startsWith('Lu-Vi')) {
        this.distribuidorForm.patchValue({ distribuidorHorario_L_V: true });
        this.minValueEntreSemana = hora_inicio;
        this.maxValueEntreSemana = hora_fin;
      } else if (element.startsWith('Sab')) {
        this.distribuidorForm.patchValue({ distribuidorHorario_S: true });
        this.minValueSabados = hora_inicio;
        this.maxValueSabados = hora_fin;
      } else if (element.startsWith('Dom')) {
        this.distribuidorForm.patchValue({ distribuidorHorario_D: true });
        this.minValueDomingos = hora_inicio;
        this.maxValueDomingos = hora_fin;
      }
    });
  }

  /************************************************* Mapas *************************************************/
  /**
   * Metodo para obtener departamentos y ciudades mediante API Imagine Apps
   */
  async getPlaces() {
    let id_dpto = 0;
    this.departamentos = this.departamentos_empresa = await this.places.getDepartmentFromServerImagine();
    for (const dpto of this.departamentos) {
      if (dpto.name == this.distribuidorForm.get('distribuidorDepartamento')?.value) {
        break;
      }
      id_dpto++;
    }
    if (this.departamentos[id_dpto] != undefined) {
      this.ciudades = await this.places.getCitiesFromServerImagine(this.departamentos[id_dpto].code);
    } else {
      this.ciudades = [];
    }
  }

  async getPlacesPoligonos() {
    let id_dpto = 0;
    this.departamentosPoligono = await this.places.getDepartmentFromServerImagine();
    for (const dpto of this.departamentosPoligono) {
      if (dpto.name == this.departamentoPoligono) {
        break;
      }
      id_dpto++;
    }
    if (this.departamentosPoligono[id_dpto] != undefined) {
      this.ciudadesPoligono = await this.places.getCitiesFromServerImagine(this.departamentosPoligono[id_dpto].code);
    } else {
      this.ciudadesPoligono = [];
    }
  }

  /**
   * Consulta el servicio de mapas para recuperar la latitud y la longitud de la dirección del distribuidor
   */
  async getLatLongDireccion(clean?: boolean) {
    let direccion = `${this.distribuidorForm.get('distribuidorCiudad')?.value},
    ${this.distribuidorForm.get('distribuidorDepartamento')?.value}`;
    if (clean) {
      direccion = `${this.departamentoPoligono}, ${this.ciudadPoligono}`;
    }
    const resp: any = await this.mapService.getLatLong(direccion).toPromise();
    if (resp) {
      if (clean) {
        this.mapPoligono.lat = resp.results[0].geometry.location.lat;
        this.mapPoligono.lng = resp.results[0].geometry.location.lng;
      } else {
        this.map.lat = resp.results[0].geometry.location.lat;
        this.map.lng = resp.results[0].geometry.location.lng;
      }
    }
    if (!clean) {
      this.direccionAux = resp.results[0].formatted_address;
    }
  }

  /********* VISUALIZAR MAPA PREVIAMENTE GUARDADO *********/
  /**
   * Carga la cobertura ya guardada en la base de datos solo como visualización para el usuario
   * de necesitar editarla o cambiarla deberá crear una nueva
   */
  drawPolygon(map: any) {
    this.nueva_cobertura = new google.maps.Polygon({
      paths: this.histo,
      editable: true,
      draggable: true,
    });
    this.nueva_cobertura.setMap(map);
  }

  /**
   * Borra el poligono guardado en la base de datos para dar paso a la creación de un nuevo poligono
   */
  deleteDefaultShape() {
    for (const datosPoli of this.polygonsList) {
      datosPoli.setMap(null);
    }
    this.polygonsList = [];
    this.valoresPedido = [];
    this.coordMulti = [];
    this.mapa_inicial = false;
  }
  /********* CREAR NUEVO MAPA *********/
  /**
   * Inicializa el manejador del mapa que muestra la dirección ingresada para el distribuidor
   * @param map La referencia al mapa a inicializar
   */

  /**
   * Inicializa el manejador de dibujo del mapa para el area de cobertura
   * @param map La referencia al mapa a inicializar
   
  initDrawingManager = (map: any) => {
    this.drawing_map = map;
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ['polygon'],
      },
      polygonOptions: {
        draggable: true,
        editable: true,
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
    };
    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);
    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event: any) => {
      if (event.type === google.maps.drawing.OverlayType.POLYGON) {
        const paths = event.overlay.getPaths();
        for (let p = 0; p < paths.getLength(); p++) {
          google.maps.event.addListener(paths.getAt(p), 'set_at', () => {
            if (!event.overlay.drag) {
              this.updatePointList(event.overlay.getPath());
            }
          });
          google.maps.event.addListener(paths.getAt(p), 'insert_at', () => {
            this.updatePointList(event.overlay.getPath());
          });
          google.maps.event.addListener(paths.getAt(p), 'remove_at', () => {
            this.updatePointList(event.overlay.getPath());
          });
        }
        this.updatePointList(event.overlay.getPath());
        this.selectedShape = event.overlay;
        this.selectedShape.type = event.type;
      }
      if (event.type !== google.maps.drawing.OverlayType.MARKER) {
        // Switch back to non-drawing mode after drawing a shape.
        this.drawingManager.setDrawingMode(null);
        // To hide:
        this.drawingManager.setOptions({
          drawingControl: false,
        });
      }
    });
  };
*/
  /**
   * Toma la figura creada por el usuario y guarda las coordenadas de los nodos para que luego puedan ser guardados
   * @param path El camino hecho por el usuario
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updatePointList(path: any, index: any, overlay?: any, map?: any) {
    const nueva_cobertura = [];
    const len = path.getLength();

    if (this.coordMulti[index]) {
      for (let i = 0; i < len; i++) {
        nueva_cobertura.push(path.getAt(i).toJSON());
      }
      this.coordMulti[index] = nueva_cobertura;
    } else {
      for (let i = 0; i < len; i++) {
        nueva_cobertura.push(path.getAt(i).toJSON());
      }
      if (nueva_cobertura.length < 3) {
        overlay.setMap(null);
        const modalRef = this.modalService.open(SimpleComponent);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'La figura debe de tener al menos 3 lados';
        modalRef.componentInstance.btn_msg = 'Volver';
      } else {
        nueva_cobertura.push(nueva_cobertura[0]);
        this.agregarPoligono();
        this.nuevaCoo.push(nueva_cobertura);
        this.coordMulti.push(nueva_cobertura);
        this.polygonsList = [...this.polygonsList, overlay];
        this.changePolygonMapOptions();
      }
    }
  }

  changePolygonMapOptions() {
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ['polygon'],
      },
      polygonOptions: {
        draggable: false,
        editable: true,
        fillColor: this.listaColores[this.coordMulti.length],
        strokeWeight: 0,
        fillOpacity: 0.6,
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
    };
    this.drawingManager.setOptions(options);
  }

  /**
   * Borra el poligono seleccionado por el usuario en el mapa
   */
  deleteSelectedShape() {
    if (this.selectedShape) {
      this.selectedShape.setEditable(false);
      this.selectedShape.setMap(null);
      this.selectedShape = null;
    }
    this.selectedArea = 0;
    this.nueva_cobertura = [];
    //this.initDrawingManager(this.drawing_map);
    this.drawingManager.setOptions({
      drawingControl: true,
    });
  }

  /******************************************* Documentos y Logo *******************************************/
  /**
   * Consigue el link del archivo PDF guardado en el back la función retorna el link de cada documento
   */
  getDocuments() {
    this.distribuidorService.getDocumentDistribuidor(this.auth.user_distribuidor?._id).subscribe(
      (res) => {
        res.forEach((e: any) => {
          if (e.documento == 'Representante legal') {
            this.CCPDF = e.locacion;
          } else if (e.documento == 'Camara de comercio') {
            this.camComPDF = e.locacion;
          } else if (e.documento == 'Rut') {
            this.rutPDF = e.locacion;
          } else if (e.documento == 'Certificacion') {
            this.bancariaPDF = e.locacion;
          } else if (e.documento == 'Certificacion Cuenta') {
            this.cBancaria = e.locacion;
          } else if (e.documento == 'Creacion de cliente') {
            this.creacionCliente = e.locacion;
          } else if (e.documento == 'Aprobacion de credito') {
            this.credito = e.locacion;
          }
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /**
   * Muestra el nombre del documento seleccionado en el input el cual se va a subir
   * @param event Selecciòn que viene del input tipo type desde el HTML, sería cada documento a cargar
   * la función guarda el nombre del documento para mostrarlo en el DOM
   */
  documentName(event: any, id: number) {
    for (const i of event.target.files) {
      if (id == 1) {
        this.rutName = i.name;
      } else if (id == 2) {
        this.camComName = i.name;
      } else if (id == 3) {
        this.CCName = i.name;
      } else if (id == 4) {
        this.certName = i.name;
      } else if (id == 5) {
        this.creacionName = i.name;
      } else if (id == 6) {
        this.creditoName = i.name;
      }
    }
  }

  /**
   * el Primer metodo captura el logo y - el segundo metodo captura el los documentos cargados en el DOM
   * @param event Selecciòn que viene del input tipo type desde el HTML
   * la función no devuelve nada
   * el cuarto metodo guarda los documentos y el logo
   */
  captureLogo(event: any) {
    const logoCapturado = <File>event.target.files[0];
    if (logoCapturado.size > 2000000) {
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Este archivo supera el máximo de 2MB permitido!';
      modalRef.componentInstance.btn_msg = 'Volver';
      return;
    } else {
      // Una vez el usuario sube una imagen, esta se mostrará en el DOM
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => (this.imageSrc = reader.result);
        reader.readAsDataURL(file);
      }
      this.archivos[0] = logoCapturado;
    }
  }

  captureDocument(event: any, id: number) {
    const doc = <File>event.target.files[0];
    if (doc.size > 2000000) {
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = '¡Este archivo supera el máximo de 2MB permitido!';
      modalRef.componentInstance.btn_msg = 'Volver';
      return;
    } else if (doc.name.slice(-4) != '.pdf') {
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = '¡Solo archivos PDF están permitidos!';
      modalRef.componentInstance.btn_msg = 'Volver';
      return;
    } else {
      this.archivos[id] = doc;
      this.documentName(event, id);
    }
  }

  guardarDocumentos(idUser: any, document: any) {
    try {
      if (this.archivos[0] != undefined) {
        const upload_form: FormData = new FormData();
        upload_form.append(`logo`, this.archivos[0]);
        this.distribuidorService.postLogo(idUser, upload_form, 'Distribuidor').subscribe(() => {
          this.guardarTrabajadorEnStore();
        });
      }
      if (this.archivos[1] != undefined) {
        const uploadRUT: FormData = new FormData();
        uploadRUT.append(`rut`, this.archivos[1]);
        this.distribuidorService.postRUT(document, idUser, uploadRUT).subscribe(() => {});
      }
      if (this.archivos[2] != undefined) {
        const uploadCamaraComercio: FormData = new FormData();
        uploadCamaraComercio.append(`camara_comerio`, this.archivos[2]);
        this.distribuidorService.postCamaraComercio(document, idUser, uploadCamaraComercio).subscribe(() => {});
      }
      if (this.archivos[3] != undefined) {
        const uploadCC: FormData = new FormData();
        uploadCC.append(`documento_identidad`, this.archivos[3]);
        this.distribuidorService.postCC(document, idUser, uploadCC).subscribe(() => {});
      }
      const arrDocumentsCerts = [4, 5, 6];
      for (const doc of arrDocumentsCerts) {
        if (this.archivos[doc] != undefined) {
          const uploadCert: FormData = new FormData();
          uploadCert.append(`documento`, this.archivos[doc]);
          this.distribuidorService
            .postDocumentosDistribuidor(this.auth.user_distribuidor?._id, doc, uploadCert)
            .subscribe(() => {});
        }
      }
      // Limpia los campos de documentos y vuelve a cargarlos en el front para visualizar los nuevos
      this.clearSelection(0);
      this.clearSelection(1);
      this.clearSelection(2);
      this.clearSelection(3);
      this.clearSelection(4);
      this.clearSelection(5);
      this.clearSelection(6);
      this.getDocuments();
    } catch (error) {
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'Ocurrió un error inesperado, uno o todos tus documentos/logo no pudieron ser guardados ¡Por favor intenta de nuevo más tarde!';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {};
    }
  }

  //Borrar el input de documentos
  @ViewChild('fileInputRUT') fileInputRUT!: ElementRef;
  @ViewChild('fileInputCamCom') fileInputCamCom!: ElementRef;
  @ViewChild('fileInputCC') fileInputCC!: ElementRef;
  @ViewChild('fileInputCert') fileInputCert!: ElementRef;
  @ViewChild('fileInputCredito') fileInputCredito!: ElementRef;
  @ViewChild('fileInputCreacion') fileInputCreacion!: ElementRef;
  clearSelection(id: number) {
    delete this.archivos[id];
    if (id == 1) {
      this.rutName = '';
      this.fileInputRUT.nativeElement.value = '';
    } else if (id == 2) {
      this.camComName = '';
      this.fileInputCamCom.nativeElement.value = '';
    } else if (id == 3) {
      this.CCName = '';
      this.fileInputCC.nativeElement.value = '';
    } else if (id == 4) {
      this.certName = '';
      this.fileInputCert.nativeElement.value = '';
    } else if (id == 5) {
      this.creacionName = '';
      this.fileInputCreacion.nativeElement.value = '';
    } else if (id == 6) {
      this.creditoName = '';
      this.fileInputCredito.nativeElement.value = '';
    }
  }

  /**
   * Cuando se realizan cambias en el logo se debe reescribir la información en el localStorage
   * para ver los resultados del cambio sin neceisdad de hacer logout y login
   */
  guardarTrabajadorEnStore() {
    //El trabajador que inició sesión es parte de un Distribuidor
    this.rest
      .getJWT('distribuidor/' + this.auth.user?.distribuidor)
      .toPromise()
      .then((resp_2: any) => {
        this.auth.user_distribuidor = resp_2;
        //Se guarda la info de inicio de sesión en el Store
        this.ngxsStore
          .dispatch(
            new SetSelectedToken({
              token: this.auth.token,
              email: this.auth.email,
              user: this.auth.user,
              user_horeca: undefined,
              user_distribuidor: this.auth.user_distribuidor,
              user_organizacion: undefined,
            })
          )
          .toPromise()
          .then();
      });
  }

  /******************************************** Guardar cambios ********************************************/

  /**
   * Guarda los cambios realizados sobre el distribuidor en el servidor
   */
  async guardarCambios() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
    };
    try {
      /** Guarda inicialmente los documentos */
      this.guardarDocumentos(this.auth.user_distribuidor?._id, this.auth.user_distribuidor?._id);
      /** Guardar la nueva area de cobertura */
      if (this.nueva_cobertura?.length >= 3) {
        this.empresa.cobertura_coordenadas = [];
        for (const coord_aux of this.nueva_cobertura) {
          this.empresa.cobertura_coordenadas.push({
            latitud: coord_aux.lat,
            longitud: coord_aux.lng,
          });
        }
      }
      delete this.empresa._id;
      this.empresa.nombre = this.distribuidorForm.get('distribuidorNombre')?.value;
      this.empresa.correo = this.distribuidorForm.get('distribuidorCorreo')?.value;
      this.empresa.descripcion = this.distribuidorForm.get('distribuidorDescripcion')?.value;
      this.empresa.ciudad = this.distribuidorForm.get('distribuidorCiudad')?.value;
      this.empresa.departamento = this.distribuidorForm.get('distribuidorDepartamento')?.value;
      this.empresa.tipo = this.distribuidorForm.get('distribuidorTipo')?.value;
      this.empresa.cobertura_coordenadas = this.poligono_final;
      this.empresa.horario_atencion = this.procesarHorarioAtencion();
      this.empresa.metodo_pago = this.procesarMetodoPago();
      this.empresa.direccion = this.distribuidorForm.get('distribuidorDireccion')?.value;
      this.empresa.nit_cc = this.distribuidorForm.get('distribuidorNit')?.value;
      this.empresa.razon_social = this.distribuidorForm.get('distribuidorRazon')?.value;
      this.empresa.celular = this.distribuidorForm.get('distribuidorTelefono')?.value;
      this.empresa.telefono = this.distribuidorForm.get('distribuidorTelefono2')?.value;
      this.empresa.urlPago = this.distribuidorForm.get('urlPago')?.value;
      this.setOrderValues(2);
      this.replicarValoresPoligono();
      this.empresa.datos_poligono = this.valoresPedido;
      this.empresa.zonas_cobertura = this.formatearCoordenadasEdicion();
      this.empresa.valor_minimo_pedido = this.valoresPedido[0].valor_pedido;
      this.empresa.tiempo_entrega = this.formatearPromesaTexto();
      const resp: any = await this.rest
        .putJWT(`distribuidor/${this.auth.user_distribuidor?._id}`, this.empresa)
        .toPromise();
      this.auth.user_distribuidor = resp;
      //Se guarda la info el Store
      this.ngxsStore
        .dispatch(
          new SetSelectedToken({
            token: this.auth.token,
            email: this.auth.email,
            user: this.auth.user,
            user_horeca: this.auth.user_horeca,
            user_distribuidor: this.auth.user_distribuidor,
          })
        )
        .toPromise()
        .then(() => {
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          modalRef.componentInstance.msg = '¡Se guardaron los cambios con éxito!';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            window.location.reload();
          };
        });
    } catch (err) {
      console.log(err);
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Hubo un problema guardando los cambios. Por favor intenta de nuevo más tarde';
      modalRef.componentInstance.btn_msg = 'Volver';
    }
  }

  /**
   * Toma los datos de horario de atención ingresados para el distribuidor
   * y devuelve un string que represente estos datos
   * @returns String representando el horario de atención del distribuidor
   */
  procesarHorarioAtencion(): string {
    /**Captura la información de los sliders */
    let horario = '';
    if (this.distribuidorForm.get('distribuidorHorario_L_V')?.value) {
      horario +=
        'Lu-Vi: ' + this.valueToTime(this.minValueEntreSemana) + ' - ' + this.valueToTime(this.maxValueEntreSemana);
    }
    if (this.distribuidorForm.get('distribuidorHorario_S')?.value) {
      if (horario != '') {
        horario += ', ';
      }
      horario += 'Sab: ' + this.valueToTime(this.minValueSabados) + ' - ' + this.valueToTime(this.maxValueSabados);
    }
    if (this.distribuidorForm.get('distribuidorHorario_D')?.value) {
      if (horario != '') {
        horario += ', ';
      }
      horario += 'Dom: ' + this.valueToTime(this.minValueDomingos) + ' - ' + this.valueToTime(this.maxValueDomingos);
    }
    return horario;
  }
  buscarDireccion(inicio?: boolean) {
    this.mapsApiLoader.load().then(() => {
      if (this.distribuidorForm.get('distribuidorDireccion')?.value != '') {
        const dir =
          this.distribuidorForm.get('distribuidorDireccion')?.value +
          ', ' +
          this.distribuidorForm.get('distribuidorCiudad')?.value +
          ', ' +
          this.distribuidorForm.get('distribuidorDepartamento')?.value;
        this.mapService
          .getLatLong(dir)
          .toPromise()
          .then((resp: any) => {
            if (resp.status == 'OK') {
              if (resp.results[0]) {
                this.map.lat = resp.results[0].geometry.location.lat;
                this.map.lng = resp.results[0].geometry.location.lng;
                if (inicio) {
                  this.mapPoligono.lat = resp.results[0].geometry.location.lat;
                  this.mapPoligono.lng = resp.results[0].geometry.location.lng;
                }
                const bounds = new google.maps.LatLngBounds();
                bounds.extend(this.map);
                this.agmMap.mapReady.subscribe((map) => {
                  map.fitBounds(bounds);
                });
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
        this.deleteSelectedShape();
      }
    });
  }
  /**
   * Toma los datos de metodos de pago ingresados para el distribuidor
   * y devuelve un string que represente estos datos
   * @returns String representando el horario de atención del distribuidor
   */
  procesarMetodoPago(): string {
    let metodo_pago = '';
    if (this.distribuidorForm.get('distribuidorMetodoPagoCredito')?.value) {
      metodo_pago += 'Crédito';
    }
    if (this.distribuidorForm.get('distribuidorMetodoPagoEfectivo')?.value) {
      if (metodo_pago != '') {
        metodo_pago += ', ';
      }
      metodo_pago += 'Efectivo contra entrega';
    }
    if (this.distribuidorForm.get('distribuidorMetodoPagoTransferencia')?.value) {
      if (metodo_pago != '') {
        metodo_pago += ', ';
      }
      metodo_pago += 'Transacción bancaria';
    }
    if (this.distribuidorForm.get('distribuidorMetodoPagoDatafono')?.value) {
      if (metodo_pago != '') {
        metodo_pago += ', ';
      }
      metodo_pago += 'Datáfono contra entrega';
    }
    return metodo_pago;
  }

  /**
   * Alerta si el formulario esta incompleto o un input es invalido
   */
  alertaFormularioInvalido() {
    const modalRef = this.modalService.open(SimpleComponent);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = '¡Por favor asegúrate de llenar todos los datos y vuelve a intentarlo!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {};
  }

  alertaDocumentoNoEncotrado() {
    const modalRef = this.modalService.open(SimpleComponent);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = '¡Este documento no fue encontrado, favor subirlo a la plataforma!';
    modalRef.componentInstance.btn_msg = 'Volver';
  }

  removePolygon(index: number) {
    this.deleteOnlyPolygon();
    this.polygonsList.splice(index, 1);
    this.coordMulti.splice(index, 1);
    this.valoresPedido.splice(index, 1);
    this.changePolygonMapOptions();
    this.actualizarPoligonosExistentes();
  }

  deleteOnlyPolygon() {
    for (const datosPoli of this.polygonsList) {
      datosPoli.setMap(null);
    }
  }

  actualizarPoligonosExistentes() {
    for (let i = 0; i < this.coordMulti.length; i++) {
      //Add the polygon
      const p = new google.maps.Polygon({
        paths: this.coordMulti[i],
        strokeWeight: 0,
        fillColor: this.listaColores[i],
        fillOpacity: 0.6,
        indexID: i,
        editable: true,
        draggable: false,
      });
      p.setOptions({ fillOpacity: 0.6 });
      this.polygonsList = [...this.polygonsList, p];
      p.setMap(this.referenciaMapa);
    }
  }

  formarDatosPoligono() {
    this.valoresPedido = this.auth.user_distribuidor?.datos_poligono?.map((valores) => ({ ...valores }));
    const zonas = this.auth.user_distribuidor;
    if (zonas?.zonas_cobertura?.coordinates) {
      const coordenadas = zonas?.zonas_cobertura?.coordinates;
      for (const coord of coordenadas) {
        let arrCoord: any = [];
        for (const latlng of coord[0]) {
          arrCoord = [
            ...arrCoord,
            {
              lat: latlng[1],
              lng: latlng[0],
            },
          ];
        }
        this.coordMulti = [...this.coordMulti, arrCoord];
      }
    }
  }

  agregarPoligono() {
    this.valoresPedido = [
      ...this.valoresPedido,
      {
        tipo_promesa: 'horas',
        valor_promesa: this.valoresPedido?.length > 0 ? this.valoresPedido[0].valor_promesa : 1,
        valor_pedido:
          this.valoresPedido?.length > 0
            ? this.valoresPedido[0].valor_pedido
            : this.currencyPipe.transform(0, '$ ', 'symbol', '1.0-0'),
      },
    ];
  }

  formatearCoordenadasEdicion() {
    let totalCoords: any = [];
    for (const coord of this.coordMulti) {
      let arrCoord: any = [];
      for (const latLng of coord) {
        arrCoord = [...arrCoord, [latLng.lng, latLng.lat]];
      }
      totalCoords = [...totalCoords, [arrCoord]];
    }
    const objetoMultiPoligono = {
      type: 'MultiPolygon',
      coordinates: totalCoords,
    };
    return objetoMultiPoligono;
  }

  determinarCaracteres() {
    if (this.descripcionModelo) {
      this.remainingText = 500 - this.descripcionModelo.length;
    } else {
      this.remainingText = 500;
    }
  }

  public mapReadyHandler(map: google.maps.Map): void {
    map.addListener('click', (e: google.maps.MouseEvent) => {
      this.zone.run(() => {
        this.map.lat = e.latLng.lat();
        this.map.lng = e.latLng.lng();

        this.buscarDireccionAutocompletada();
      });
    });
  }

  buscarDireccionAutocompletada() {
    this.mapsApiLoader.load().then(() => {
      const geocoder = new google.maps.Geocoder();
      const latlng = { lat: this.map.lat, lng: this.map.lng };
      geocoder.geocode({ location: latlng }, (results: any) => {
        if (results[0]) {
          this.direccionAux = results[0].formatted_address;
          this.distribuidorForm.get('distribuidorDireccion')?.setValue(results[0].formatted_address);
        }
      });
    });
  }

  enterMapRegistro() {
    let direccionBusqueda = '';
    if (this.distribuidorForm.get('distribuidorCiudad')?.value) {
      direccionBusqueda = `${this.distribuidorForm.get('distribuidorDepartamento')?.value},
        ${this.distribuidorForm.get('distribuidorCiudad')?.value}`;
    }
    if (this.direccionAux) {
      direccionBusqueda = direccionBusqueda ? `${this.direccionAux}, ${direccionBusqueda}` : this.direccionAux;
    }
    this.mapService
      .getLatLong(direccionBusqueda)
      .toPromise()
      .then((resp_map: any) => {
        if (resp_map.status == 'OK') {
          if (resp_map.results[0]) {
            this.map.lat = resp_map.results[0].geometry.location.lat;
            this.map.lng = resp_map.results[0].geometry.location.lng;
          }
        }
      });
  }

  obtenerPuntosEntrega() {
    //El trabajador que inició sesión es parte de un Distribuidor
    this.rest
      .getJWT('punto_entrega/')
      .toPromise()
      .then((resp: any) => {
        const puntos = resp;
        for (const punto of puntos) {
          if (punto.coord && punto.estado === 'Activo') {
            this.coordenadasPuntos = [...this.coordenadasPuntos, { lat: punto.coord?.lat, lng: punto.coord?.lng }];
          }
        }
      });
  }

  replicarValoresPoligono() {
    let promesa;
    let valor;
    let index = 0;
    for (const dato of this.valoresPedido) {
      if (index === 0) {
        promesa = dato.valor_promesa;
        valor = dato.valor_pedido;
      } else {
        dato.valor_promesa = promesa;
        dato.valor_pedido = valor;
      }
      index++;
    }
  }

  construirRangosPromesa() {
    let indexHoras = 0;
    let rangosHoras: any = [];
    for (const hora of this.listaHoras) {
      if (indexHoras > 0 && hora % 2 === 0 && hora < 47) {
        rangosHoras = [...rangosHoras, `${hora} - ${hora + 2}`];
      }
      indexHoras++;
    }
    return rangosHoras;
  }

  formatearPromesaTexto() {
    const rangosHoras = this.construirRangosPromesa();
    const promesaEntrega = parseInt(this.valoresPedido[0].valor_promesa);
    if (promesaEntrega === 1 || promesaEntrega === 2) {
      return '2 - 4 horas';
    }
    for (const rango of rangosHoras) {
      const separacionRango = rango.split(' - ');
      const primerRango = parseInt(separacionRango[0]);
      const segundoRango = parseInt(separacionRango[1]);
      if (promesaEntrega > primerRango && promesaEntrega <= segundoRango) {
        return `${rango} horas`;
      }
    }
    return '46 - 48 horas';
  }
}
