import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-informes-distribuidor',
  templateUrl: './informes-distribuidor.component.html',
  styleUrls: ['./informes-distribuidor.component.css'],
})
export class InformesDistribuidorComponent implements OnInit {
  active = 1;
  constructor() {}

  ngOnInit(): void {}
}
