<!--<object data="https://feat-resources.s3.amazonaws.com/politicas/TE%CC%81RMINOS%20Y%20CONDICIONES%20DE%20USO%20DE%20LA%20PLATAFORMA.pdf" type="application/pdf" width="auto" height="auto">
  <embed src="https://feat-resources.s3.amazonaws.com/politicas/TE%CC%81RMINOS%20Y%20CONDICIONES%20DE%20USO%20DE%20LA%20PLATAFORMA.pdf" type="application/pdf">
</object>-->
<!--<div class="container">
  <div class="row">

  </div>
</div>-->
<!--
Autorización de Tratamiento de datos personales Usuarios – Xlabon S.A.S.
<div class="container">
  <div class="row">
    
  </div>
</div>
-->
<!--
Política de Cookies
<div class="container">
  <div class="row">
   
</div>
</div>
-->
<div class="container margin-bot">
  <div class="row">
    <div class="col-12 text-center">
      <img src="../../assets/img/ImagenPegada-1_1.jpg" alt="Logo feat" class="img-banner">
    </div>
    <div
      class="col-12"
      [ngClass]="{
        active: seleccionado === 'tab1',
        inactivo: seleccionado !== 'tab1'
      }"
      >
      <div class="row">
        <div class="col-10">
          Autorización de Tratamiento de datos personales Usuarios - Xlabon S.A.S.
        </div>
        <div class="col-2 text-right">
          <fa-icon class="w-25 text-right" [icon]="faChevronDown"  *ngIf="seleccionado !== 'tab1'" [styles]="{ color: '#312d4e' }" id="fa-icon" (click)="seleccionado = 'tab1'"></fa-icon>
          <fa-icon class="w-25 text-right" [icon]="faChevronUp"  *ngIf="seleccionado === 'tab1'" [styles]="{ color: '#ffffff' }" id="fa-icon" (click)="reiniciar()"></fa-icon>

        </div>
      </div>
    </div>
    <div class="col-12 fondo" *ngIf="seleccionado === 'tab1'">
      <div class="row">
        <div class="col-12">
          
          <p>
            Para Xlabon S.A.S., sociedad debidamente constituida bajo las normas de la República de Colombia, identificada
            con Nit No. 901.174.863-4 (en adelante, “XLABON”), tiene como prioridad proteger la privacidad de sus usuarios.
            Por lo tanto, XLABON da estricto cumplimiento a lo dispuesto en la Ley Estatutaria 1581 de 2012, reglamentada
            parcialmente por los Decretos 1377 de 2013 y Decreto 886 de 2014, normas compiladas en el Decreto Único Nacional
            del Sector Comercio, Industria y Turismo 1074 de 2015 y aquellas normas que lo modifiquen. XLABON le informa que
            sus datos serán tratados conforme a la POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES que se encuentra publicada
            tanto en la plataforma virtual Feat. de Xlabon y en el siguiente link para su consulta
            https://featapp.co/politicas
          </p>
          <p>
            <b>XLABON</b>
            actúa como Responsable del Tratamiento de los Datos Personales, por lo que mantendrá almacenados estos datos y
            guardará su confidencialidad atendiendo los requisitos y medidas de seguridad exigidos por la ley. 
          </p>
          <p>
            su controlante, otras subordinadas o de sus proveedores, para las finalidades descritas en esta autorización,
            por medio de las aplicaciones móviles, sitios web y otros productos y servicios online.  Una vez se cumplan con
            las finalidades del tratamiento para la cual se otorgó autorización por parte del titular, los datos personales
            serán suprimidos de las bases de datos de
            <b>XLABON.</b>
          </p>
          <p>
            su controlante, otras subordinadas o de sus proveedores, para las finalidades descritas en esta autorización,
            por medio de las aplicaciones móviles, sitios web y otros productos y servicios online. Una vez se cumplan con
            las finalidades del tratamiento para la cual se otorgó autorización por parte del titular, los datos personales
            serán suprimidos de las bases de datos de
            <b>XLABON.</b>
          </p>
          <p>
            Finalidades de Tratamiento: El titular de la información acepta de manera expresa, voluntaria e informada el
            tratamiento de sus datos personales y para que estos sean consignados, sean recopilados y almacenados en las
            bases de datos de la Plataforma Virtual Feat. de
            <b>XLABON.</b>
            conforme a las finalidades que a continuación se enuncian:
          </p>
          <p>
            1) Recabar o recolectar los datos personales e incorporarlos y almacenarlos en nuestra base de datos; (2)
            Ordenar, catalogar, clasificar, dividir o separar la información suministrada; (3) Utilizar los datos
            suministrados en campañas de comunicación, divulgación y promoción u oferta de productos, actividades y/o
            servicios desarrollados como parte de estrategias internas de la compañía; (4) Conservar registros históricos de
            la compañía y mantener contacto con los titulares del dato; (5) Verificar, comprobar o validar los datos
            suministrados; (6) Estudiar y analizar la información entregada para el seguimiento y mejoramiento de los
            productos, el servicio y la atención; (7) Entregar la información recolectada a terceros con los que la compañía
            contrate el almacenamiento y administración de los datos personales, bajo los estándares de seguridad y
            confidencialidad a los cuales
            <b>XLABON.</b>
            está obligada según lo contenido en las leyes pertinentes; (8) Transferir los datos personales a cualquier país
            o servidor en otro país; (9) Comunicar y permitir el acceso a los datos personales suministrados a terceros
            proveedores de servicios de apoyo general y a las personas naturales o jurídicas accionistas de
            <b>XLABON.</b>
            ; (10) Recabar, tener, manejar y utilizar la información para realizar control y prevención del fraude, control
            y prevención de lavado de activos y financiación del terrorismo; (11) Celebración, ejecución y gestión de las
            propuestas de negocios y contratos objeto de los servicios prestados; (12) Realizar de conformidad con la ley
            los reportes a centrales de riesgo por incumplimiento de las obligaciones financieras derivadas de la relación
            comercial; (13) Envío de comunicaciones a través de cualquier canal incluyendo pero, sin limitarse a, redes
            sociales, mensajes de texto, notificaciones push, correo electrónico, llamada telefónica, etc., relacionadas con
            el objeto social de
            <b>XLABON</b>
            tales como actividades de mercadeo y/o cualquier solicitud realizada por el titular a
            <b>XLABON</b>
            , entre otros; (14) Prestar los servicios ofrecidos y/o contratados de forma adecuada y con una excelente
            calidad; (15) Creación y administración de la cuenta del usuario; (16) Prestar el mantenimiento, desarrollo y/o
            control de la relación comercial entre el usuario y
            <b>XLABON</b>
            ; (17) Proveer a los usuarios de la información necesaria, a través de la página Web y/o plataforma de contacto,
            sobre los productos de los oferentes, para formalizar la relación de consumo de dichos productos; (18) Realizar
            procesos al interior de la empresa, con fines de desarrollo operativo y/o de administración de sistemas; (19)
            Prestar los servicios de la empresa y realizar el seguimiento de acuerdo con las necesidades particulares del
            usuario, con el fin de brindar los servicios y productos adecuados para cubrir sus necesidades específicas; (20)
            Llevar un registro histórico de la información, con fines de satisfacción al usuario, desarrollando análisis
            sobre los intereses y necesidades; brindando de esta manera un mejor servicio; (21) Realizar estrategias de
            mercado mediante el estudio del comportamiento del usuario frente a las ofertas y con ello mejorar en su
            contenido, personalizando presentación y servicio; (22) Elaboración de prospecciones comerciales y segmentación
            de mercados; (23) Realizar encuestas de satisfacción y ofrecimiento o reconocimiento de beneficios propios de
            nuestro programa de lealtad y servicio postventa, para calificar el servicio y la atención por medio de los
            canales dispuestos para ello; (24) Adelantar las actividades necesarias para gestionar las solicitudes, quejas y
            reclamos de los usuarios de la empresa o terceros y direccionarlos a las áreas responsables de emitir las
            respuestas correspondientes; (25) Presentar reportes ante las autoridades de inspección, vigilancia y control, y
            tramitar los requerimientos realizados por entidades administrativas o judiciales; (26) Gestión contable,
            económica, fiscal y administrativa de usuarios; (27) Conservación de la información por los términos
            establecidos en la Ley, especialmente el referente a la información de los libros y papeles del comerciante que
            deberá ser almacenada por un período de diez (10) años, según lo dispone el artículo 28 de la Ley 962 de 2005;
            (28) Transferencia o Transmisión de Datos Personales nacional o internacionalmente a terceros con los que
            <b>XLABON.</b>
            desarrolle actividades en cumplimiento de su objeto social. Asimismo, se podrá hacer Transmisión o Transferencia
            a los aliados estratégicos de la empresa para que ejecuten actividades de marketing, publicidad y promociones
            asociadas con el objeto social; todo de acuerdo con las disposiciones de la normativa colombiana; (29) Remitir
            información a los Encargados del Tratamiento para facilitar y mejorar la calidad del servicio de
            <b>XLABON.</b>
            ; (30) Solicitar la autorización de cobro ante las entidades definidas y autorizadas para ello.
          </p>
          <p>
            <b>XLABON</b>
            podrá transmitir o transferir a terceros los datos personales de los que es Responsable. Los datos también
            podrán ser transferidos a otras empresas que se encuentren bajo la misma órbita de control de
            <b>XLABON</b>
            . Dichos terceros podrán estar ubicados en Colombia, o en otros países que, a juicio de la Superintendencia de
            Industria y Comercio, garanticen un nivel adecuado de protección de datos personales. Igualmente, podrán ser
            transferidos a, Argentina, Brasil y México. Así mismo, el Titular autoriza a XLABON a transferir y transmitir
            sus datos personales a terceros aliados comerciales de XLABON que ofrecen sus productos y servicios en la
            Plataforma de
            <b>XLABON</b>
            , con el fin de hacer eficiente el contacto entre el Titular y el aliado comercial, en los términos del artículo
            53 de la Ley 1480 de 2011.
          </p>
          <p>
            <b>Derechos que le asisten al Titular:</b>
            En caso en que el Titular de información otorgue autorización a
            <b>XLABON</b>
            para el tratamiento y procesamiento de sus datos personales, los titulares de información tendrán los siguientes
            derechos:(1) Conocer, actualizar y rectificar y/o suprimir sus datos personales recolectados por
            <b>XLABON</b>
            . Este derecho se puede ejercer entre otros frente a datos parciales, inexactos, incompletos, fraccionados o que
            induzcan al error, (2) Solicitar prueba de la autorización otorgada a
            <b>XLABON</b>
            , (3) Ser informado por
            <b>XLABON</b>
            , previa solicitud, respecto del uso que le han dado a los datos del titular, (4) Presentar ante la
            Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la ley, (5) Revocar en
            cualquier tiempo la autorización de tratamiento de datos personales, teniendo en cuenta los límites legales para
            ello, (6) Acceder en forma gratuita a los datos del titular contenidos en la base de datos de
            <b>XLABON</b>
            .
          </p>
          <p>
            Forma de conocer la información del Titular y cómo ejercer los derechos:
            <b>XLABON</b>
            presenta a elección del Titular las siguientes formas de ejercer sus derechos de "Habeas Data":
          </p>
          <p>
            •	Atención electrónica: El Titular del dato podrá realizar la solicitud a través del correo electrónico  servicioalcliente@xlabon.com disponible en La Plataforma Virtual Feat. de XLABON. <br>
            •	Atención escrita: El Titular del dato deberá realizar su requerimiento formal en la dirección Cra. 2 Este # 70ª - 41, Bogotá D.C. Colombia, acreditando de forma previa y suficiente su identidad. <br>
            •	Atención telefónica: El Titular del dato podrá realizar la solicitud a través de la línea 3184662904 disponible en La Plataforma Virtual Feat. de XLABON. <br>
            •	SIC Facilita: El Titular tiene la posibilidad de acudir al canal dispuesto por la Superintendencia de Industria y Comercio. <br>
          </p>
          <p>
            Se podrá acceder a los Datos Personales si se cumple alguna las condiciones establecidas a continuación:
          </p>
          <p>
            •	Ser Titular de la información, acreditando la identidad en forma suficiente mediante cualquier medio que <b>XLABON</b> disponga para ello. <br>
            •	Ser causahabiente, quien deberá acreditar tal calidad. <br>
            •	Ser el representante/apoderado del Titular de la información, quien deberá acreditar tal calidad.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div
      class="col-12"
      id="Cookies"
      [ngClass]="{
        active: seleccionado === 'tab2',
        inactivo: seleccionado !== 'tab2'
      }"      
      >
      <div class="row">
        <div class="col-10">
          Política de Cookies
        </div>
        <div class="col-2 text-right">
          <fa-icon class="w-25 text-right" [icon]="faChevronDown"  *ngIf="seleccionado !== 'tab2'" [styles]="{ color: '#312d4e' }" id="fa-icon" (click)="seleccionado = 'tab2'"></fa-icon>
          <fa-icon class="w-25 text-right" [icon]="faChevronUp"  *ngIf="seleccionado === 'tab2'" [styles]="{ color: '#ffffff' }" id="fa-icon" (click)="reiniciar()"></fa-icon>

        </div>
      </div>
      
    </div>
    <div class="col-12" *ngIf="seleccionado === 'tab2'">
      <div class="row">
        <div class="col-12">
          <p>
            XLABON S.A.S. (en adelante, “XLABON”) es una sociedad constituida conforme a las leyes colombianas, identificada con
            NIT. 901.174.863-4, con domicilio en la ciudad de Bogotá D.C., la cual administra y opera la Plataforma Virtual FEAT.
            por medio de la cual se conectan tres (3) tipos de usuarios: 1) USUARIO ESTABLECIMIENTO HORECA: Negocio del SECTOR
            vinculados a la Plataforma Virtual Feat. que realiza compras de insumos a DISTRIBUIDORES FOODSERVICE registrados en la
            Plataforma para desarrollar platillos y atender el consumo de alimentos fuera del hogar. 2) USUARIO DISTRIBUIDOR
            FOODSERVICE: Negocio de distribución de insumos para el sector, que ofrece productos y/o servicios de ORGANIZACIONES
            PRODUCTORAS a ESTABLECIMIENTOS por medio de la Plataforma Virtual Feat.; y 3) USUARIO ORGANIZACIÓN PRODUCTORA:
            Negocio, empresa u organización del SECTOR, que comercializa y promociona sus productos y/o servicios a
            ESTABLECIMIENTOS por medio de DISTRIBUIDORES a través de la Plataforma Virtual Feat. Por medio de la presente
            <b>POLÍTICA DE COOKIES</b>
            (en adelante “Política de Cookies”) se da a conocer a los Usuarios que tengan relación con
            <b>XLABON S.A.S.</b>
            el tratamiento que se le da a las cookies en su Plataforma Virtual Feat. y a la información personal recolectada y
            almacenada por las mismas. Se aclara que con el propósito de proteger la información personal recolectada y almacenada
            por las Cookies sobre los Usuarios que tengan relación con
            <b>XLABON S.A.S.</b>
            la presente Política de Cookies da cumplimiento estricto a la política de Privacidad de XLABON S.A.S., la cual se
            encuentra en la siguiente dirección electrónica: https://featapp.co/politicas, De igual forma, la presente Política de
            Cookies da cumplimiento estricto a la autorización de Tratamiento de Datos Personales Usuarios de XLABON S.A.S., la
            cual se encuentra en la siguiente dirección electrónica: https://featapp.co/politicas
          </p>
          <h3>NATURALEZA JURÍDICA</h3>
          <p>
            Por medio de la presente Política de Cookies se identifican el tipo de Cookies que se usan en la Plataforma Virtual
            Feat. de XLABON S.A.S. y el uso que se le dará a las mismas.
          </p>
          <h3>DEFINICIONES</h3>
          <p>
            Dato personal: Cualquier información concerniente o vinculada a personas naturales determinadas o determinables. Base
            de datos personales: Conjunto organizado de datos personales que son objeto de tratamiento por una persona natural o
            jurídica. Cookies: Archivos que recolectan información sobre los hábitos de navegación e inclinaciones de consumo de
            un Usuario en la Plataforma Virtual Feat. de XLABON S.A.S. y eventualmente podrían conformar una base de datos. Las
            Cookies son recuperadas y almacenadas por XLABON S.A.S., para la consulta de las actividades y preferencias de los
            Usuarios. Por medio de la información recuperada, XLABON S.A.S. podrá identificar como mejorar el servicio ofrecido.
            Comercio Electrónico: Comprende el envío, transmisión, recepción, almacenamiento de mensajes de datos por vía
            electrónica. Las dudas que surjan respecto de la eficacia y validez de los mensajes de datos y demás actividades
            vinculadas al comercio electrónico se interpretarán de conformidad con la ley 527 de 1999. Plataforma Virtual Feat.:
            Serie de archivos o páginas web interrelacionadas entre sí e identificadas por un mismo nombre de dominio, los cuales
            pueden ser accedidos por cualquier persona utilizando un navegador de internet. La Plataforma Virtual Feat. de XLABON
            se encuentra en la siguiente dirección electrónica: www.featapp.co
          </p>
          <p>
            Mensajes de datos: La información generada, enviada, recibida, almacenada o comunicada por medios electrónicos,
            ópticos o similares, como pudieran ser, entre otros, el Intercambio Electrónico de Datos (EDI), Internet, el correo
            electrónico, el telegrama, el télex o el telefax (Ley 527 de 1999 artículo. 2 literal (a). Titular del dato
            personal: Persona natural cuyos datos son objeto de tratamiento. En el contexto de la presente Política de Cookies,
            los titulares podrán ser: 1) Usuarios vinculados a Establecimientos registrados en la plataforma; 2) Usuarios
            vinculados a Distribuidores registrados en la plataforma; 3) Usuarios vinculados a Organizaciones registradas en la
            plataforma 4) todas aquellas personas no vinculadas a la Plataforma Virtual Feat. de Xlabon de quien se haga
            tratamiento de los datos personales. Tratamiento. Es cualquier operación o conjunto de operaciones sobre Datos
            Personales, tales como la recolección, almacenamiento, uso, circulación o supresión. Usuarios. Toda persona natural
            que, como destinatario final, usa la Plataforma Virtual Feat. para solicitar funcionalidades o adquisición de
            productos y/o servicios exhibidos, ofrecidos y comercializados a través de la misma. Web Beacon: Imágenes insertadas
            en un sitio web o correo electrónico y permiten monitorear el comportamiento de un visitante del sitio web, almacenar
            información sobre la dirección IP del Usuario/Consumidor, conocer la duración del tiempo de interacción en dicho sitio
            y el tipo de navegador utilizado.
          </p>
          <h3>TIPOS DE COOKIES UTILIZADAS</h3>
          <p>
            XLABON S.A.S. utiliza en su Plataforma Virtual Feat. las siguientes Cookies:
            <br />
            <br />
            <b>Cookies de Sesión:</b>
            Aquellas cookies que recolectan información relativa al acceso y uso de la Plataforma Virtual Feat. mientras el
            Usuario navega en el mismo y se conservan únicamente durante el tiempo de navegación.
            <b>Cookies Persistentes:</b>
            Aquellas que se almacenan durante un tiempo limitado en el navegador del Usuario, aun cuando éste ha finalizado de
            navegar por la Plataforma Virtual Feat.
            <b>Cookies Técnicas:</b>
            Aquellas que son imprescindibles para el efectivo funcionamiento de la Plataforma Virtual. 
            <b>Cookies Funcionales:</b>
            Aquellas que almacenan las preferencias establecidas por los Usuarios y son necesarias para ofrecer correctamente los
            servicios solicitados por los mismos. Por ejemplo, se almacena información como el idioma de preferencia del Usuario,
            la dirección usual de envió etc.<br />
            <br />
            <b>Cookies Analíticas:</b>
            Aquellas que permiten conocer el comportamiento de navegación de los visitantes en la Plataforma Virtual, así como
            registrar los contenidos que ven y los que son de su interés. La finalidad del uso de estas Cookies es mejorar el
            servicio que se le ofrece a los Usuarios.<br />
            <br />
            <b>Cookies Sociales:</b>
            Aquellas que permiten ofrecer funcionalidades relacionadas con redes sociales (Facebook, Google y Twitter) al permitir
            y controlar la interacción de estas con widgets sociales en la Plataforma Virtual Feat. de XLABON S.A.S.<br />
            <br />
            <b>Cookies de Publicidad:</b>
            Aquellas que recopilan información sobre las preferencias y elecciones del Usuario con el fin de ofrecerle publicidad
            en la Plataforma Virtual Feat. relacionada con sus intereses.<br />
            <br />
            <b>Cookies de Identificación:</b>
            Aquellas que permiten identificar a los Usuarios visitantes de la Plataforma Virtual y así establecer coincidencias
            con los datos personales incluidos en nuestros archivos, para poder enviar comunicaciones personales pertinentes.<br />
            <br />
          </p>
          <h3>FINALIDADES DEL USO DE COOKIES</h3>
          <p>
            <b>Seguridad:</b>
            Se utilizan Cookies y tecnologías similares para que las interacciones del Usuario con la Plataforma Virtual sean más
            rápidas y seguras. De esta manera, se busca detectar actividad sospechosa o fraudulenta con la cuenta del Usuario que
            está visitando la Plataforma Virtual.
            <br />
            <br />
            <b>Autenticación:</b>
            Se utilizan Cookies y tecnologías similares para reconocer e identificar al Usuario cuando visite la Plataforma
            Virtual.
            <br />
            <br />
            <b>Personalización:</b>
            Se utilizan Cookies y tecnologías similares para personalizar la experiencia del Usuario al visitar la Plataforma
            Virtual. Por ejemplo, se utilizan Cookies para recordar las búsquedas anteriores, de modo que, cuando el Usuario
            vuelva a visitar la Plataforma Virtual, podamos ofrecerte información adicional relacionada con su búsqueda anterior.
            <br />
            <br />
            <b>Preferencias:</b>
            Se utilizan Cookies para recordar información sobre el navegador y las preferencias del Usuario.
            <br />
            <br />
            <b>Publicidad:</b>
            Se utilizan Cookies y tecnologías similares para mostrarle al Usuario publicidad relevante de forma más eficaz, tanto
            dentro como fuera de la Plataforma Virtual, y a medir el rendimiento de los anuncios. De igual forma, se utilizan para
            obtener más información sobre el rendimiento de la Plataforma Virtual, los servicios ofrecidos a través de la misma y
            complementos en diferentes ubicaciones.
            <br />
            <br />
            <b>Rendimiento, análisis e investigación:</b>
            Se Utilizan Cookies y tecnologías similares para obtener más información sobre el rendimiento de la Plataforma Virtual
            y los servicios ofrecidos a través de la misma y complementos en diferentes ubicaciones. Se utilizan para desarrollar
            estudios internos sobre los intereses, comportamientos y demografía de los Usuarios, para comprender mejor sus
            necesidades e intereses, mejorar las iniciativas comerciales y promocionales de XLABON S.A.S., y así personalizar sus
            contenidos, su presentación y servicios, mostrar publicidad o promociones, banners, de interés para los Usuarios.
            <br />
            <br />
            Se aclara que en caso de realizarse recolección de Datos Personales por medio del uso de Cookies en la Plataforma
            Virtual Feat., dichos Datos Personales serán tratados únicamente conforme a las finalidades y principios rectores que
            están establecidos en la política de Privacidad de XLABON S.A.S. y a la Autorización de Tratamiento de Datos
            Personales Usuarios de XLABON S.A.S., las cuales se encuentran en la siguiente dirección
            electrónica: https://featapp.co/politicas
          </p>
          <h3>FORMAS DE CONTROLAR EL USO DE COOKIES</h3>
          <p>
            Los Usuarios tienen la posibilidad de borrar, restringir o bloquear el uso de Cookies en el Sitio Web de XLABON S.A.S.
            o cualquier otro sitio o página web. Las Cookies se pueden controlar por medio del navegador, no obstante, cada
            navegador tiene su propia forma operativa de control de Cookies.
            <br />
            A continuación, se encuentran los diferentes tipos de navegadores y haciendo click sobre cada uno se encuentra la
            opción de controlar las Cookies:
            <br /> <br />
            • Internet Explorer
            <br />
            • Mozilla
            <br />
            • Google Chrome
            <br />
            • Safari
            <br />
          </p>
          <p>
            Se aclara que el uso de Cookies permite a XLABON S.A.S. darle al Usuario una mejor experiencia al visitar la
            Plataforma Virtual y al acceder a los servicios ofrecidos en la misma. En caso de realizar modificaciones en la
            configuración de las Cookies, XLABON S.A.S. no asegura el correcto funcionamiento de las distintas funcionalidades de
            su Plataforma Virtual.
          </p>
          <h3>CANAL DE CONTACTO SOBRE EL USO DE COOKIES</h3>
          <p>
            El Usuario que desee realizar cualquier consulta sobre el uso de Cookies en el Sitio Web podrá contactarse
            a: servicioalcliente@xlabon.com
          </p>
          <h3>CAMBIO A LA POLÍTICA DE COOKIES</h3>
          <p>
            Con el propósito de mantener el mejoramiento de la Plataforma Virtual Feat., se aclara que esta Política de Cookies
            podrá tener actualizaciones. Por lo tanto, se le recomienda al Usuario visitar periódicamente la misma con el fin de
            estar enterado de los cambios.
          </p>
          </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div
      class="col-12"
      [ngClass]="{
        active: seleccionado === 'tab3',
        inactivo: seleccionado !== 'tab3'
      }">
      <div class="row">
        <div class="col-10">
          Política de Protección de Datos ( Aviso de Provacidad)
        </div>
        <div class="col-2 text-right">
          <fa-icon class="w-25 text-right" [icon]="faChevronDown"  *ngIf="seleccionado !== 'tab3'" [styles]="{ color: '#312d4e' }" id="fa-icon" (click)="seleccionado = 'tab3'"></fa-icon>
          <fa-icon class="w-25 text-right" [icon]="faChevronUp"  *ngIf="seleccionado === 'tab3'" [styles]="{ color: '#ffffff' }" id="fa-icon" (click)="reiniciar()"></fa-icon>

        </div>
      </div>
      
    </div>
    <div class="col-12" *ngIf="seleccionado === 'tab3'">
      <div class="row">
        <div class="col-12"><br><br>
         <h3>POLÍTICA DE TRATAMIENTO Y PROTECCIÓN DE DATOS PERSONALES</h3> 

 <p>En cumplimiento a lo dispuesto en la Ley Estatutaria 1581 de 2012 y sus Decretos Reglamentarios, la empresa establece la Política General y Especiales aplicables para el Tratamiento y Protección de Datos Personales en la organización. </p> 
 <p>
	•	IDENTIFICACIÓN DEL RESPONSABLE <br><br>
XLABON S.A.S sociedad comercial identificada con NIT 901.174.863-4, compañía dedicada a la actividad del sector tecnológico enfocada en articular sectores económicos por medio de herramientas digitales. Adicionalmente, a través de la Plataforma Feat. busca generar vínculos directos entre los diferentes actores del sector Gastronomico (Establecimientos, Distribuidores, Organizaciones entre otros) con el fin de facilitar su actividad comercial y transferencia de información para la toma de decisiones.
 </p>
<p>
  DIRECCIÓN FÍSICA: Cra 2 Este # 70ª -41, Bogotá D.C, Colombia<br>
  CORREO DE CONTACTO: ricardo.ruiz@xlabon.com<br>
  TELEFONO: 318 4662904<br>
</p>
<p>
  •	OBJETIVO<br><br>
  La presente Política establece las directrices generales para la protección y el tratamiento de datos personales al interior de la empresa permitiendo de esta manera fortalecer el nivel de confianza entre Responsable y Titulares con relación al tratamiento de su información; Informar a los Titulares de las finalidades y transferencias a que son sometidos sus datos personales y los mecanismos y formas para el ejercicio de sus derechos.
</p>
<p>
	•	ALCANCE <br><br>
Esta Política de Tratamiento y Protección de Datos Personales será aplicada a todas las bases de datos y/o archivos que incluyan datos personales que sean objeto de tratamiento por parte de XLABON S.A.S. como responsable del tratamiento de datos personales.
</p>

<p>
	•	DEFINICIONES<br><br>
	•	Habeas Data: Derecho que tiene toda persona de conocer, actualizar y rectificar la información que se haya recogido sobre ella en archivos y bancos de datos de naturaleza pública o privada.<br>
	•	Dato personal: Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables.<br>
	•	Base de Datos: Conjunto organizado de datos personales que sea objeto de tratamiento.<br>
	•	Tratamiento: Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, uso, circulación o supresión.<br>
	•	Autorización: Consentimiento previo, expreso e informado del Titular para llevar a cabo el tratamiento de datos personales.<br>
	•	Aviso de privacidad: Es el documento físico, electrónico o en cualquier otro formato conocido o por conocer, que es puesto a disposición del Titular con el fin de informar sobre el tratamiento de sus datos personales.<br>
	•	Titular: Persona natural cuyos datos personales sean objeto de tratamiento<br>
	•	Causahabiente: Persona que por sucesión o transmisión adquiere los derechos de otra persona.<br>
	•	Responsable del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la base de datos y/o el tratamiento de los datos.<br>
	•	Encargado del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el tratamiento de datos personales por cuenta del Responsable del tratamiento.


</p>


<p>
	•	PRINCIPIOS RECTORES APLICABLES EN MATERIA DE DATOS PERSONALES<br><br>
En materia de protección de datos personales se aplicarán los siguientes principios rectores:<br><br>
a) Principio de legalidad en materia de Tratamiento de datos: El tratamiento a que se refiere la Ley de Habeas Data es una actividad reglada que debe sujetarse a lo establecido en ella y en las demás disposiciones que la desarrollen.<br>
b) Principio de finalidad: El tratamiento debe obedecer a una finalidad legítima de acuerdo con la Constitución y la ley, la cual debe ser informada al Titular.<br>
c) Principio de libertad: El tratamiento sólo puede ejercerse con el consentimiento, previo, expreso e informado del Titular. Los datos personales no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal o judicial que releve el consentimiento.<br>
d) Principio de veracidad o calidad: La información sujeta a tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error.<br>
e) Principio de transparencia: En el tratamiento debe garantizarse el derecho del Titular a obtener del Responsable del tratamiento o del Encargado del tratamiento, en cualquier momento y sin restricciones, información acerca de la existencia de datos que le conciernan.<br>
f) Principio de acceso y circulación restringida: El tratamiento se sujeta a los límites que se derivan de la naturaleza de los datos personales, de las disposiciones de la ley y la Constitución. En este sentido, el tratamiento sólo podrá hacerse por personas autorizadas por el Titular y/o por las personas previstas en la ley.<br>
Los datos personales, salvo la información pública, no podrán estar disponibles en Internet u otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para brindar un conocimiento restringido sólo a los Titulares o terceros autorizados conforme a la ley.<br>
g) Principio de seguridad: La información sujeta a tratamiento por el Responsable del tratamiento o Encargado del tratamiento a que se refiere la Ley de Habeas Data, se deberá manejar con las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.<br>
h) Principio de confidencialidad: Todas las personas que intervengan en el tratamiento de datos personales que no tengan la naturaleza de públicos están obligadas a garantizar la reserva de la información, inclusive después de finalizada su relación con alguna de las labores que comprende el tratamiento, pudiendo sólo realizar suministro o comunicación de datos personales cuando ello corresponda al desarrollo de las actividades autorizadas en la ley y en los términos de la misma.

	•	DERECHOS DE LOS TITULARES<br><br>
Los Titulares de datos personales gozarán de los siguientes derechos, y de aquellos que les otorgue la ley:<br><br>
a) Conocer, actualizar y rectificar sus datos personales frente al Responsable del tratamiento o Encargados del tratamiento. Este derecho se podrá ejercer, entre otros frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo tratamiento esté expresamente prohibido o no haya sido autorizado;<br>
b) Solicitar prueba de la autorización otorgada al Responsable del tratamiento salvo cuando expresamente se exceptúe como requisito para el tratamiento, de conformidad con lo previsto en el artículo 10 de la ley;<br>
c) Ser informado por el Responsable del tratamiento o el Encargado del tratamiento, previa solicitud, respecto del uso que les ha dado a sus datos personales;<br>
d) Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la ley y las demás normas que la modifiquen, adicionen o complementen;<br>
e) Revocar la autorización y/o solicitar la supresión del dato cuando en el tratamiento no se respeten los principios, derechos y garantías constitucionales y legales. La revocatoria y/o supresión procederá cuando la Superintendencia de Industria y Comercio haya determinado que en el tratamiento el Responsable o Encargado han incurrido en conductas contrarias a la ley y a la Constitución;<br>
f) Acceder en forma gratuita a sus datos personales que hayan sido objeto de tratamiento.<br>

	•	AUTORIZACIÓN DEL TITULAR DE DATOS PERSONALES<br><br>

Sin perjuicio de las excepciones previstas en la Ley Estatutaria 1581 de 2012, por regla general en el tratamiento de datos de carácter personal, XLABON SAS. Recolectará la autorización previa e informada del Titular, la cual podrá ser obtenida por cualquier medio que pueda ser objeto de consulta posterior.<br><br>
	•	Eventos en los cuales no es necesaria la autorización<br>
La autorización del Titular no será necesaria cuando se trate de:<br>
	•	Información requerida por una entidad pública o administrativa en ejercicio de sus funciones legales o por orden judicial;<br>
	•	Datos de naturaleza pública;<br>
	•	Casos de urgencia médica o sanitaria;<br>
	•	Tratamiento de información autorizado por la ley para fines históricos, estadísticos o científicos;<br>
	•	Datos relacionados con el Registro Civil de las Personas.<br><br>
	•	DEBERES DE XLABON SAS. COMO RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES
XLABON SAS. como responsable del tratamiento de datos personales, cumplirá los siguientes deberes: <br><br>
	•	Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data.<br>
	•	Solicitar y conservar, en las condiciones previstas en la ley, copia de la respectiva autorización otorgada por el Titular.<br>
	•	Informar debidamente al Titular sobre la finalidad de la recolección y los derechos que le asisten por virtud de la autorización otorgada.<br>
	•	Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.<br>
	•	Garantizar que la información que se suministre al Encargado del tratamiento sea veraz, completa, exacta, actualizada, comprobable y comprensible.<br>
	•	Actualizar la información, comunicando de forma oportuna al Encargado del tratamiento, todas las novedades respecto de los datos que previamente le haya suministrado y adoptar las demás medidas necesarias para que la información suministrada a este se mantenga actualizada.<br>
	•	Rectificar la información cuando sea incorrecta y comunicar lo pertinente al Encargado del tratamiento.<br>
	•	Suministrar al Encargado del tratamiento, según el caso, únicamente datos cuyo tratamiento esté previamente autorizado de conformidad con lo previsto en la presente ley.<br>
	•	Exigir al Encargado del tratamiento en todo momento, el respeto a las condiciones de seguridad y privacidad de la información del Titular.<br>
	•	Tramitar las consultas y reclamos formulados en los términos señalados en la Ley Estatutaria 1581 de 2012.<br>
	•	Adoptar un manual interno de políticas y procedimientos para garantizar el adecuado cumplimiento de la ley y en especial, para la atención de consultas y reclamos.<br>
	•	Informar al Encargado del tratamiento cuando determinada información se encuentra en discusión por parte del Titular, una vez se haya presentado la reclamación y no haya finalizado el trámite respectivo.<br>
	•	Informar a solicitud del Titular sobre el uso dado a sus datos.<br>
	•	Informar a la autoridad de protección de datos cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los Titulares.<br>
	•	Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio. <br><br>

	•	POLÍTICAS ESPECÍFICAS PARA EL TRATAMIENTO DE DATOS PERSONALES.<br>
	•	Tratamiento de datos personales de Empleados
</p>
<p>
XLABON SAS. recolecta los datos personales de sus Trabajadores los cuales son calificados por la compañía como de reserva, y solo serán revelados por la empresa con la expresa autorización del titular o cuando una Autoridad Competente lo solicite.  
</p>
<p>
Las finalidades para las cuales son utilizados los datos personales de los empleados de la empresa serán:<br><br>

	•	Dar cumplimiento a las obligaciones que impone la ley laboral colombiana a los empleadores, o bien las órdenes que impartan las autoridades colombianas competentes;<br>
	•	Emitir certificaciones relativas a la relación del titular del dato con la empresa.<br>
	•	Cumplir con las obligaciones impuestas a la empresa como empleador, en relación con las normas de Seguridad y Salud Ocupacional, y el denominado Sistema de Gestión de Seguridad y Salud en el Trabajo (SG-SST).<br>
	•	Gestionar las funciones desarrolladas por los trabajadores.<br>
	•	Consultar memorandos o llamados de atención.<br>
	•	Desarrollar y aplicar el proceso disciplinario. <br>
	•	Contactar a familiares en casos de emergencia. <br>
	•	Procesos de Nómina, pago y su contabilización<br>
	•	Reportes fiscales, judiciales y demás informes requeridos por las autoridades. 
</p>
<p>XLABON SAS. almacena los datos personales de sus empleados, incluidos los que hayan sido obtenidos en desarrollo del proceso de selección, y los conserva en una carpeta identificada con el nombre de cada uno de ellos. 

A tal carpeta solo tendrá acceso y será tratada por el Área de Recursos Humanos, con la finalidad de administrar la relación contractual entre XLABON SAS y el empleado.

</p><p>XLABON SAS. datos sensibles de sus trabadores para los efectos de este tratamiento se recolecta la respectiva autorización que en todo caso será expresa y facultativa, indicando claramente los datos sensibles objeto de tratamiento y la finalidad del mismo. Adicionalmente, la empresa trata Datos Personales Sensibles de sus trabajadores como los datos de sus hijos menores de edad con la única finalidad de registrarlos como beneficiarios del sistema seguridad social y parafiscales. Para los efectos de este Tratamiento se recolecta la respectiva autorización que en todo caso será expresa y facultativa, indicando claramente los Datos Personales Sensibles objeto de Tratamiento y la finalidad del mismo.

Así mismo, contará con elevados sistemas de seguridad para el manejo de aquellos datos sensibles y su reserva, en el entendido que tales datos sensibles solo serán usados por XLABON SAS Para los fines antes mencionados. 

</p><p>Terminada la relación laboral, XLABON SAS procederá a almacenar todos los datos personales que haya obtenido del proceso de selección y la documentación generada en desarrollo de la relación laboral, en un archivo central con acceso restringido, sometiendo en todo momento la información a medidas y niveles de seguridad adecuados, dado que la información laboral pueda contener datos de carácter sensible.

</p><p>En todo caso, la información no será objeto de tratamiento por un período superior a veinte (20) años contados a partir de la terminación de la relación laboral, o de acuerdo con las circunstancias legales o contractuales que hagan necesario el manejo de la información. 
<p>
  •	Tratamiento de datos personales de Aprendices SENA: 
  <br><br>
  XLABON SAS recolecta los datos personales de sus aprendices SENA y los almacena en una base de datos la cual es calificada por la empresa como de reserva, y sólo será revelada con la expresa autorización del titular o cuando una Autoridad Competente lo solicite.  
  <br><br>
  Las finalidades para las cuales son utilizadas los datos personales de los aprendices SENA del XLABON SAS. serán:
  <br><br>
    •	Dar cumplimiento a las obligaciones que impone la ley laboral colombiana a los empleadores, en especial a lo dispuesto en la Ley 789 de 2002 así como sus Decretos Reglamentarios.
    <br>•	Emitir certificaciones relativas a la relación del titular del dato con la empresa.
    <br>•	Corroborar cualquier requerimiento que se ocasione en desarrollo del proceso de vinculación de aprendices.
    <br>•	Cumplir con las obligaciones impuestas a la empresa como empleador, en relación con las normas de Seguridad y Salud Ocupacional, y el denominado Sistema de Gestión de Seguridad y Salud en el Trabajo (SG-SST).
  Gestionar las funciones desarrolladas por los aprendices. Controlar el desarrollo de los aprendices en la etapa lectiva y etapa práctica. <br>
    •	Contactar a familiares en casos de emergencia. 
    <br><br>
  En todo caso, la información no será objeto de tratamiento por un período superior al tiempo que dure la relación del postulante con la empresa que en ningún caso podrá ser superior a dos (2) años, y el tiempo adicional que se requiera de acuerdo con las circunstancias legales o contractuales que hagan necesario el manejo de la información.
  <br><br>
    •	 Tratamiento de datos personales de candidatos o aspirantes a procesos de selección.
  XLABON SAS  recolecta los datos personales de sus postulantes y los almacena en una base de datos la cual es calificada por la empresa como de reserva, y sólo será revelada con la expresa autorización del titular o cuando una Autoridad Competente lo solicite.  
  <br><br>
  Las finalidades para las cuales son utilizadas los datos personales de los postulantes de XLABON SAS serán:
  <br><br>
    •	Realización de gestiones para el proceso de selección del personal.<br>
    •	Envío de comunicaciones programados por la empresa, para realizar diferentes pruebas de selección.<br>
    •	Corroborar la información suministrada por el aspirante durante el proceso de selección. <br>
    •	Vinculación en general de cada postulante. <br>
    <br><br>
  En todo caso, la información no será objeto de tratamiento por un período superior al tiempo que dure la relación del postulante con la empresa, y el tiempo adicional que se requiera de acuerdo con las circunstancias legales o contractuales que hagan necesario el manejo de la información.
  
  <br><br>
    •	 Tratamiento de datos personales de Clientes<br><br>
  XLABON SAS Recolecta los datos personales de sus Clientes y los almacena en una base de datos la cual es calificada por la empresa como de reserva, y solo será revelada con la expresa autorización del titular o cuando una Autoridad Competente lo solicite.  
  <br><br>
  Las finalidades para las cuales son utilizadas los datos personales de los Clientes de XLABON SAS. serán:
  <br><br>
    •	Realización de gestiones para las etapas precontractual, contractual y pos contractual<br>
    •	Envío de invitaciones a eventos programados por la empresa.<br>
    •	Corroborar cualquier requerimiento que se ocasione en desarrollo del contrato celebrado.<br>
    •	Cumplir con el objeto del contrato celebrado. <br>
    •	Verificar casos en que se de incumplimiento de alguna de las partes.<br>
    •	Vinculación en general de cada cliente.<br>
    •	Realizar actividades de fidelización de clientes y operaciones de marketing. <br>
    •	Información sobre la oferta de productos de la empresa. <br>
    •	Permitir a través de la plataforma Feat. la interacción entre cada uno de nuestros afiliados. 
    <br><br>
  En todo caso, la información no será objeto de tratamiento por un período superior al tiempo que dure la relación del cliente con la empresa, y el tiempo adicional que se requiera de acuerdo con las circunstancias legales o contractuales que hagan necesario el manejo de la información.
  <br><br>
    •	 Tratamiento de datos personales de Prospectos clientes: 
  XLABON SAS recolecta datos personales de participantes en encuestas prácticas con fines estadísticos y mercantiles, con los cuales busca encontrar o establecer estrategias de mercadeo para sus clientes. 
  <br><br>
  Las finalidades para las cuales son utilizadas los datos personales contenidos en las encuestas de participantes que realiza XLABON SAS., serán:
  <br><br>
    •	Recolectar Información que permita implementar campañas de mercadeo. 
    <br>•	Análisis de la información recolectada que permita la implementación de estadísticas mercantiles.
    <br>•	Analizar el perfil de personas jurídicas o naturales en el desarrollo de procesos comerciales. 
    <br>•	Permitir a través de la plataforma Feat. la interacción entre cada uno de nuestros afiliados. 
  En todo caso, la información no será objeto de tratamiento por un período superior a el tiempo que dure la relación laboral con el empleado y de acuerdo con las circunstancias legales o contractuales que hacen necesario el manejo de la información.
  <br>•	Tratamiento de datos personales de Proveedores y contratistas <br><br>
  XLABON SAS Recolecta los datos personales de sus Proveedores y contratistas y los almacena en una base de datos la cual, aunque se compone en su mayoría de datos públicos, es calificada por la compañía como de reserva, y que, en el caso de datos privados, solo los revelará por la empresa con la expresa autorización del titular o cuando una Autoridad Competente lo solicite.  
  <br><br>
  Las finalidades para las cuales son utilizadas los datos personales de los Proveedores y contratistas de XLABON SAS. Serán:
  <br><br>
    •	Envío de invitaciones a contratar y realización de gestiones para las etapas precontractual, contractual y pos contractual <br>
    •	Creación del registro contable como proveedor de la empresa <br>
    •	Procesos de pago y su respectiva contabilización <br>
    •	Solicitud, custodia de reportes de seguridad social requeridos para procesar el pago. <br> 
    •	Reportes judiciales, fiscales y demás <br>
    •	Las demás establecidas específicamente en las autorizaciones que sean otorgadas por los propios proveedores y contratistas.<br><br>
  XLABON SAS sólo recaudará de sus proveedores y contratistas los datos que sean necesarios, pertinentes y no excesivos para la finalidad de selección, evaluación y ejecución del contrato a que haya lugar.
  La recolección de los datos personales de empleados de los proveedores y contratistas por parte de XLABON SAS. tendrá en todo caso como finalidad verificar la idoneidad y competencia de los contratistas y proveedores; es decir, una vez verificado este requisito, XLABON SAS. devolverá tal información al Proveedor y contratistas, salvo cuando se autorice expresamente su conservación.
  <br><br>
  En todo caso, la información no será objeto de tratamiento por un período superior al tiempo que dure la relación del Proveedor y contratista con la empresa, y el tiempo adicional que se requiera de acuerdo con las circunstancias legales o contractuales que hagan necesario el manejo de la información.
  <br><br>
    •	Tratamiento de datos personales de Socios: <br><br>
  XLABON SAS. recolecta los datos personales de sus Accionistas los almacena en una base de datos la cual, es calificada por la compañía como de reserva, y que solo los revelará por la empresa con la expresa autorización del titular o cuando una Autoridad Competente lo solicite. 
  Las finalidades para las cuales son utilizadas los datos personales de los Proveedores serán: <br><br>
  a) Permitir el ejercicio de los deberes y derechos derivados de la calidad de socio. <br> 
  b) Permitir el envío de invitaciones a eventos programados por la compañía <br>
  c) Emitir certificaciones relativas a la relación del Titular con la sociedad <br><br>
  En todo caso, la información no será objeto de tratamiento por un período superior a el establecido a través de la autorización otorgada por el accionista para usar sus datos personales contado a partir de su recolección de acuerdo con las circunstancias legales o contractuales que hacen necesario el manejo de la información.  
  <br><br>
  9.8 Tratamiento de datos personales de Visitantes en el Control Ingreso <br><br>
  XLABON SAS recolecta los datos personales de sus visitantes y los almacena en una base de datos la cual es calificada por la empresa como de reserva, y solo será́ revelada por la empresa con la expresa autorización del titular o cuando una Autoridad Competente lo solicite. 
  Las finalidades para las cuales son utilizadas los datos personales de quienes ingresan a las instalaciones de XLABON SAS serán: <br><br>
  <br> a)  Asegurar el ingreso a las instalaciones de la empresa a personas que cuentan con la autorización de libre tránsito y restringir el paso a aquellas personas que no estén autorizadas. 
    <br>b)  Garantizar la seguridad en los ambientes vigilados. 
  <br>c)  Permitir ambientes de trabajo adecuados para el desarrollo seguro de las actividades al interior de la empresa. 
  <br>d) Cumplir con los requerimientos del Sistema de Gestión de Seguridad y Salud en el trabajo.  
  En todo caso, la información no será́ objeto de tratamiento por un período superior a un (1) año contado a partir de su recolección de acuerdo con las circunstancias legales o contractuales que hacen necesario el manejo de la información. 
  <br><br>9.9 Tratamiento de datos personales de Registro de Video vigilancia:
  XLABON SAS. datos biométricos de sus trabajadores y visitantes a través de sus Cámaras de Vigilancia y los almacena en una base de datos la cual es calificada por la compañía como de reserva, y solo será́ revelada con la expresa autorización del titular o cuando una Autoridad Competente lo solicite. 
  Las finalidades para las cuales son utilizadas los datos personales contenidos en las Cámaras de Vigilancia de serán: <br><br>
  <br>a)  Garantizar la seguridad en los ambientes laborales. 
  <br>b)  Permitir ambientes de trabajo adecuados para el desarrollo seguro de actividades laborales de la compañía. 
  <br>c)  Controlar el ingreso, permanencia y salida empleados y contratistas en las instalaciones de la empresa. 
  Para cumplir con el deber de información que le corresponde a XLABON SAS. como administrador de datos personales, la empresa implementará Avisos de Privacidad en las zonas en donde se realice la captura de imágenes que impliquen tratamiento de datos personales. 
  En todo caso, la información no será́ objeto de tratamiento por un período superior a quince (15) días contados a partir de su recolección de acuerdo con las circunstancias legales o contractuales que hacen necesario el manejo de la información. 
  <br><br>
  9.10 Tratamiento de datos personales de página web:  
  <br><br>
  XLABON SAS. recolecta datos de sus clientes, terceros y prospectos a través de su página web, redes sociales,  y los almacena en una base de datos la cual es calificada por la compañía como de reserva, y solo será revelada con la expresa autorización del titular o cuando una Autoridad Competente lo solicite.  
  <br><br>
  Las finalidades para las cuales son utilizadas los datos personales recolectados en la página web de XLABON SAS. Serán:
  <br><br>
    •	Publicidad y prospección comercial de los diferentes productos y servicios ofrecidos por la empresa.
    <br>•	Recolectar la información a través de la sección contáctenos. 
  En todo caso, la información no será objeto de tratamiento por un período superior a el pactado con el cliente o tercero a través de una autorización para usar sus datos personales contado a partir de su recolección de acuerdo con las circunstancias legales o contractuales que hacen necesario el manejo de la información.
  <br><br>
  9.11 Tratamiento de datos personales de la plataforma Feat. 
  <br><br>
  XLABON SAS recolecta datos de sus clientes, terceros y prospectos a través de su página web, redes sociales y PLATAFORMA VIRTUAL FEAT. los almacena en una base de datos la cual es calificada por la compañía como de reserva, y solo será revelada con la expresa autorización del titular o cuando una Autoridad Competente lo solicite.  
  
  Las finalidades para las cuales son utilizadas los datos personales recolectados en la PLATAFORMA VIRTUAL FEAT. de XLABON SAS. serán:
  <br><br>
  <br>•	Realizar análisis y estudios de mercado en el sector gatroomico, con el fin de segmentar la información de forma que pueda ser utilizada en procesos de prospección comercial por parte de nuestros usuarios u organizaciones del sector. 
  
    <br>•	Enviar información que resulte de interés a través de canales y medios efectivos para la comunicación de contenidos, como lo son SMS, mailings, Notificación, banners, Noticias, entre otros. 
  
    <br> •	Crear perfiles de usuario dentro de la Plataforma Feat. de cada uno de los Establecimientos y Distribuidores, tanto personas naturales como jurídicas, perfiles que van a ser compartidos entre todas las organizaciones, de acuerdo al interés del afiliado. 
  
    <br>•	La información contenida en las bases de datos de la Plataforma Feat., será tratada únicamente dentro de la plataforma, por las organizaciones vinculadas con fines comercial, de acuerdo al alcance de cada usuario. 
  
    <br>•	La información contenida en las bases de datos será utilizada para publicar noticias y datos de interés en el portal Web para los interesados del sector gastronómico. 
  
    <br>•	La información contenida en las bases de datos de la plataforma puede ser compartida con otras plataformas con el fin de generar alianzas que contribuyan al cumplimiento de los objetivos de la plataforma. 
  En todo caso, la información no será objeto de tratamiento por un período superior a el pactado con el cliente, usuario o tercero a través de una autorización para usar sus datos personales contado a partir de su recolección de acuerdo con las circunstancias legales o contractuales que hacen necesario el manejo de la información.
  
  <br>
    •	TRANSFERENCIA Y TRANSMISIÓN INTERNACIONAL DE DATOS PERSONALES
    <br><br>
  XLABON SAS. actualmente no realiza Transmisión o Transferencia internacional de datos personales. En el evento que el centro decida realizar la Transferencia Internacional de datos personales, además de contar con la autorización expresa e inequívoca por parte del Titular, se asegurará que la acción proporcione los niveles adecuados de protección de datos y atienda a los requisitos fijados en Colombia por la Ley Estatutaria 1581 de 2012 y sus decretos reglamentarios. De otro lado, cuando el decida realizar Transmisión Internacional de datos, podrá XLABON SAS. hacerlo sin autorización de los titulares, siempre y cuando garantice la seguridad de la información, confidencialidad y las condiciones que regulen en alcance del tratamiento de los datos, de acuerdo al artículo 10 de la Ley 1581 de 2012.
  <br><br>
  
    •	DATOS DE NIÑOS, NIÑAS Y ADOLESCENTES
  XLABON SAS. no realiza de forma directa el tratamiento de datos personales de menores de Edad. Sin embargo, de forma particular la empresa recolecta y trata los datos personales de los hijos menores de edad de sus trabajadores, con la única finalidad de cumplir con las obligaciones que impone la ley a los empleadores en relación con las afiliaciones a sistema de seguridad social y parafiscales, y en particular para permitir el disfrute de los derechos fundamentales de los niños a la salud y a la recreación. 
  En todo caso, XLABON SAS. recolectará cuando corresponda la respectiva autorización para su tratamiento, teniendo siempre de presente el interés superior del menor y el respeto de los derechos prevalentes de los niños, niñas y adolescentes.
  <br>
    •	PROCEDIMIENTO PARA LA ATENCIÓN DE CONSULTAS, RECLAMOS Y PETICIONES, Y MECANISMOS PARA EJERCER LOS DERECHOS DE LOS TITULARES
    <br><br>
  El Titular, sus causahabientes, su representante y/o apoderado, o quien se determine por estipulación a favor de otro; podrá hacer ejercicio de sus derechos contactándose con nosotros a través de comunicación escrita dirigida al área encargada de la protección de datos personales en la compañía, ÁREA ADMINISTRATIVA. La comunicación podrá ser enviada al siguiente correo electrónico para el caso de Xlabon SAS: servicioalcliente@xlabon.com  y para el caso de la Plataforma Feat: Info@featapp.co Como a través de comunicación escrita radicada en Cra 2 Este # 70ª-41 Bogotá D.C
  <br><br>
  12.1 Consultas
  <br><br>Se podrá consultar la información personal del Titular que repose en las bases de datos de XLABON SAS. y la empresa se encargará de suministrar toda la información contenida en el registro individual o que esté vinculada con la identificación del solicitante.
  La consulta una vez recibida por la empresa, será atendida en un término máximo de diez (10) días hábiles contados a partir de la fecha de recibo de la misma. 
  Cuando no fuere posible atender la consulta dentro de dicho término, se informará al interesado, expresando los motivos de la demora y señalando la nueva fecha en que se atenderá tal consulta, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término.
  <br> •	Reclamos <br><br>
  Cuando se considere que la información contenida en una base de datos de XLABON SAS. debe ser objeto de corrección, actualización o supresión, o cuando se advierta el presunto incumplimiento de cualquiera de los deberes contenidos en la Ley de Habeas Data, se podrán presentar reclamación ante XLABON SAS. la cual será tramitada bajo las siguientes reglas:
  <br><br>1. El reclamo se formulará mediante comunicación escrita dirigida a XLABON SAS. con la identificación del Titular, la descripción de los hechos que dan lugar al reclamo, la dirección, y acompañando los documentos que se quiera hacer valer. 
  Si el reclamo resulta incompleto, se requerirá al interesado dentro de los cinco (5) días siguientes a la recepción del reclamo para que subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el solicitante presente la información requerida, se entenderá que ha desistido del reclamo.
  En caso de que XLABON SAS. reciba un Reclamo del cual no sea competente para resolverlo, la empresa dará traslado a quien efectivamente corresponda en un término máximo de dos (2) días hábiles e informará al Titular. 
  <br><br>2. Una vez recibido el reclamo completo, la empresa incluirá en la respectiva base de datos una leyenda que diga "reclamo en trámite" y el motivo del mismo, en un término no mayor a dos (2) días hábiles. La empresa conservará dicha leyenda en el dato objeto de discusión hasta que el reclamo sea decidido.
  <br><br>3. El término máximo para atender el reclamo será de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, la empresa informará al Titular los motivos de la demora y la nueva fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.
  <br><br>
  CONTENIDO MÍNIMO DE LA SOLICITUD<br><br>
  Las solicitudes que presente el titular con el fin de realizar una consulta o reclamo sobre el uso y manejo de sus datos personales deberán contener unas especificaciones mínimas, en aras de brindar al titular una respuesta clara y coherente con lo solicitado. Los requisitos de la solicitud son:
  <br><br>
    •	Estar dirigida a XLABON SAS.<br>
    •	Contener la identificación del Titular (Nombre y Documento de identificación).<br>
    •	Contener la descripción de los hechos que motivan la consulta o el reclamo.<br>
    •	El objeto de la petición.<br>
    •	Indicar la dirección de notificación del Titular, física y/o electrónica (e-mail). <br> 
    •	Anexar los documentos que se quieren hacer valer. (Especialmente para reclamos)<br>
  En el evento en que la consulta o reclamo sea presentado presencialmente el Titular deberá plasmar su solicitud o reclamo por escrito sin ninguna formalidad más que los requisitos exigidos en el punto anterior.
  
  <br><br>12.3 Requisito de procedibilidad
  El Titular, sus causahabientes, su representante y/o apoderado, o quien se determine por estipulación a favor de otro; sólo podrá presentar queja ante la Superintendencia de Industria y Comercio por el ejercicio de sus derechos una vez haya agotado el trámite de Consulta o Reclamo directamente ante la empresa.
  <br><br>12.4 Petición de actualización y/o rectificación
  <br><br>
  XLABON SAS. rectificará y actualizará, a solicitud del titular, la información que sea inexacta o se encuentre incompleta, atendiendo al procedimiento y los términos antes señalados, para lo cual el Titular deberá allegar la solicitud según los canales dispuestos por la compañía, indicando la actualización y rectificación del dato y a su vez deberá aportar la documentación que soporte tal petición. 
  <br><br>
    •	Revocatoria de la autorización y/o supresión del Dato 
  El Titular podrá revocar en cualquier momento el consentimiento o autorización dada para el tratamiento de sus datos personales, siempre y cuando no se encuentre un impedimento consagrado en una disposición legal o contractual. Así también el Titular tiene derecho a solicitar en todo momento a XLABON SAS. La supresión o eliminación de sus datos personales cuando:
  <br><br>
    •	Considere que los mismos no están siendo tratados conforme a los principios, deberes y obligaciones previstas en la normatividad vigente.<br>
    •	 Hayan dejado de ser necesarios o pertinentes para la finalidad para la cual fueron obtenidos. <br>
    •	Se haya cumplido el tiempo necesario para el cumplimiento de los fines para los que fueron obtenidos.
    <br>
  Tal supresión implica la eliminación bien sea de manera total o parcial de la información personal, de acuerdo con lo solicitado por el titular en los registros, archivos, bases de datos o tratamientos realizados por XLABON SAS.
  <br>
  El derecho de cancelación no es absoluto y por lo tanto XLABON SAS. podrá negar revocatoria de autorización o eliminación de los datos personales en los siguientes casos: 
  <br><br>
    •	El titular tenga un deber legal o contractual de permanecer en la base de datos. <br>
    •	La eliminación de datos obstaculice actuaciones judiciales o administrativas vinculadas a obligaciones fiscales, la investigación y persecución de delitos o la actualización de sanciones administrativas.<br> 
    •	Los datos sean necesarios para proteger los intereses jurídicamente tutelados del titular; para realizar una acción en función del interés público, o para cumplir con una obligación legalmente adquirida por el titular.
    <br><br>
  
    •	MODIFICACIÓN DE LAS POLÍTICAS<br>
  
  XLABON SAS. se reserva el derecho de modificar la Política de Tratamiento y Protección de Datos Personales en cualquier momento. Sin embargo, toda modificación será comunicada de forma oportuna a los titulares de los datos personales a través de los medios habituales de contacto con diez (10) días hábiles de antelación a su entrada en vigencia. 
  
  En el evento que un titular no esté de acuerdo con la nueva Política General o especial y con razones válidas que se constituyan en una justa causa para no continuar con la autorización para el tratamiento de datos personales, el Titular podrá solicitar a la empresa el retiro de su información a través de los canales indicados en el Capítulo 12. Sin embargo, los Titulares no podrán solicitar el retiro de sus datos personales cuando la empresa tenga un deber legal o contractual de tratar los datos. 
  <br><br>
  
    •	VIGENCIA <br>
  La presente Política rige a partir del 1 de Enero de 2023 

</p>
	

        </div>
      </div>



    </div>
  </div>
  <div class="row">
    <div
      class="col-12"
      [ngClass]="{
        active: seleccionado === 'tab4',
        inactivo: seleccionado !== 'tab4'
      }">
      
      <div class="row">
        <div class="col-10">
          Términos y Condiciones de Uso de la Plataforma
        </div>
        <div class="col-2 text-right">
          <fa-icon class="w-25 text-right" [icon]="faChevronDown"  *ngIf="seleccionado !== 'tab4'" [styles]="{ color: '#312d4e' }" id="fa-icon" (click)="seleccionado = 'tab4'"></fa-icon>
          <fa-icon class="w-25 text-right" [icon]="faChevronUp"  *ngIf="seleccionado === 'tab4'" [styles]="{ color: '#ffffff' }" id="fa-icon" (click)="reiniciar()"></fa-icon>

        </div>
      </div>
    </div>
    <div class="col-12 fondo" *ngIf="seleccionado === 'tab4'">
      <div class="row">
        <div class="col-12">
          <h2>
            TÉRMINOS Y CONDICIONES DE USO DE LA
            <br />
            PLATAFORMA “FEAT.”
          </h2>
          <br />
          <h3 class="aspectos">ASPECTOS GENERALES</h3>
          <br />
          <h3>1. INFORMACIÓN</h3>
          <p>
            XLABON S.A.S. es una sociedad constituida conforme a las leyes Colombianas, identificada con NIT:
            901.174.863-4, con domicilio en la ciudad Bogotá D.C que para los efectos de los presentes términos se
            denominará
            <b>"XLABON".</b>
          </p>
          <h3>2. NATURALEZA JURÍDICA</h3>
          <p>
            Los presentes términos y condiciones de uso regula la relación contractual de carácter comercial que une a
            todo usuario, que accede a La Plataforma virtual FEAT., especialmente en la autorización de uso que esta
            otorga en favor de aquel.
          </p>
          <p>
            Este documento contiene información legal que se recomienda leer completamente en conjunto con la Política
            de Privacidad. Por medio de la aprobación de los presentes Términos y Condiciones, se entiende que el
            usuario los ha leído y aceptado, en todas sus partes, y entiende que estos le son legalmente vinculantes y
            obligatorios. Por tanto, acepta las condiciones de utilización y aprovechamiento de La Plataforma virtual
            FEAT., sus Contenidos y Servicios. En caso contrario, el usuario deberá abstenerse de acceder a esta, ya
            sea directa o indirectamente, y de utilizar cualquier información o servicio provisto por la misma.
          </p>
          <h3>3. ACEPTACIÓN DE TÉRMINOS Y CONDICIONES</h3>
          <p>
            Mediante la creación del Perfil y/o la utilización en cualquier forma de los Servicios de La Plataforma
            Virtual FEAT., el usuario acepta los Términos de Uso aquí contenidos y la Política de Privacidad.
            Asimismo, se entiende que acepta las demás reglas de operación, políticas y procedimientos que puedan ser
            publicados por
            <b>XLABON</b>
            , cada uno de los cuales se incorpora por referencia.
          </p>
          <h3>4. DEFINICIONES</h3>
          <p>
            <b>XLABON:</b>
            significa XLABON S.A.S, Compañía dedicada a la actividad del sector tecnológico, enfocada en articular el
            SECTOR GASTRONÓMICO por medio de la Plataforma Virtual Feat.
          </p>
          <p>
            <b>SECTOR GASTRONÓMICO:</b>
            Sector económico que abarca todos los ESTABLECIMIENTOS HORECA (Hoteles, restaurantes y Casinos) que
            atienden el consumo de alimentos fuera del hogar. En adelante será denominado “ SECTOR”.
          </p>
          <p>
            <b>PLATAFORMA VIRTUAL FEAT. :</b>
            Plataforma virtual desarrollada y operada por XLABON, bajo aplicativo web (www.featapp.co) y aplicativo
            móvil (Apple Store, Google Play y Huawei AppGallery), que tramita pedidos y promociones de productos y/o
            servicios de ORGANIZACIONES PRODUCTORAS a ESTABLECIMIENTOS HORECA por medio de DISTRIBUIDORES FOODSERVICE.
            En adelante será denominada
            <b>“PLATAFORMA”.</b>
          </p>
          <p>
            <b>ESTABLECIMIENTO HORECA:</b>
            Negocio del SECTOR que realiza compras de insumos a DISTRIBUIDORES FOODSERVICE para desarrollar platillos
            y atender el consumo de alimentos fuera del hogar. En adelante será denominado
            <b>“ESTABLECIMIENTO”.</b>
          </p>
          <p>
            <b>DISTRIBUIDOR FOODSERVICE:</b>
            Negocio, empresa u organización de distribución de insumos para el SECTOR, que ofrece productos y/o
            servicios de ORGANIZACIONES PRODUCTORAS a los ESTABLECIMIENTOS por medio de la PLATAFORMA. En adelante
            será denominado
            <b>“DISTRIBUIDOR”.</b>
          </p>
          <p>
            <b>ORGANIZACIÓN PRODUCTORA:</b>
            Negocio, empresa u organización del SECTOR, que comercializa y promociona sus productos y/o servicios a
            ESTABLECIMIENTOS por medio de DISTRIBUIDORES a través de la PLATAFORMA. En adelante será denominada
            <b>“ORGANIZACIÓN”.</b>
          </p>
          <p>
            <b>USUARIO ESTABLECIMIENTO:</b>
            Persona natural registrada en un ESTABLECIMIENTO que, como destinatario final usa la PLATAFORMA para
            solicitar ordenes de compra a DISTRIBUIDORES. Están definidos bajo tipo de usuario perfil; usuario
            administrador o usuario planeador dependiendo de su alcance operativo en el ESTABLECIMIENTO. En adelante
            serán denominados
            <b>“USUARIO”.</b>
          </p>
          <p>
            <b>USUARIO ADMINISTRADOR ESTABLECIMIENTO:</b>
            Persona natural que actúa como Representante Legal o encargado autorizado de la decisión operativa de
            compras del ESTABLECIMIENTO. Es quien registra el ESTABLECIMIENTO en la PLATAFORMA, aprueba pedidos a
            DISTRIBUIDORES y aprueba las personas del equipo que actuaran en ella bajo los tipos de usuario definidos.
            Será responsable de las ordenes de compra realizadas al DISTRIBUIDOR y solo podrá estar vinculado a un
            solo ESTABLECIMIENTO. En adelante será denominado
            <b>“ADMINISTRADOR”.</b>
          </p>

          <p>
            <b>USUARIO PLANEADOR ESTABLECIMIENTO:</b>
            Persona natural que ha sido registrada en un ESTABLECIMIENTO por aprobación del ADMINISTRADOR. Es quien
            elabora los pedidos del ESTABLECIMIENO a los DISTRIBUIDORES de acuerdo a necesidades y solo podrá estar
            vinculado a un solo ESTABLECIMIENTO. En adelante será denominado “PLANEADOR”.
          </p>
          <p>
            <b>PERFIL DE NEGOCIO:</b>
            Información del ESTABLECIMIENTO o DISTRIBUIDOR registrada en la PLATAFORMA como; Razón social, NIT, RUT,
            Dirección, teléfono, tipo de negocio, usuarios y otros. EQUIPO PLATAFORMA: Personas encargadas de
            administrar la operación de servicios ofrecidos en la PLATAFORMA. Será representado para los efectos de
            los presentes términos por XLABON o por las personas naturales o jurídicas que esta designe.
          </p>
          <p>
            <b>ORDEN DE COMPRA:</b>
            Solicitud de compra de productos y/o servicios realizada por el ESTABLECIMIENTO a DISTRIBUIDORES por medio
            de la PLATAFORMA.
          </p>
          <p>
            <b>FACTURA DE COMPRA:</b>
            Documento oficial de la transacción realizada entre las partes que envía el DISTRIBUIDOR al
            ESTABLECIMIENTO por la orden de compra solicitada en la PLATAFORMA.
          </p>
          <p>
            <b>PRODUCTO:</b>
            Portafolio de productos de la ORGANIZACIÓN ofrecidos por el DISTRIBUIDOR a través de la PLATAFORMA.
          </p>
          <p>
            <b>SERVICIO:</b>
            Portafolio de Servicios de la ORGANIZACIÓN ofrecidos por el DISTRIBUIDOR a través de la PLATAFORMA.
          </p>
          <p>
            <b>PUNTOS DE LEALTAD FT. :</b>
            Puntos promocionales que podrán ser acumulados en los ESTABLECIMIENTOS por pedidos realizados en la
            PLATAFORMA a DISTRIBUIDORES en productos y/o servicios promocionados por las ORGANIZACIONES. Son
            redimibles en valor descuento en futuros pedidos a los DISTRIBUIDORES de acuerdo a los rangos de redención
            definidos.
          </p>
          <p>
            <b>PASARELA DE PAGOS:</b>
            Servicio que permite la realización de pagos por parte del ESTABLECIMIENTO directamente a DISTRIBUIDORES a
            través de medios electrónicos definidos por el DISTRIBUIDOR, utilizando plataformas tecnológicas
            (software).
          </p>
          <p>
            <b>PROMOCIONES Y SALDOS:</b>
            Significa la promoción, el descuento o valor agregado que se otorga a el ESTABLECMIENTO por parte del
            DISTRIBUIDOR con el objetivo de brindar mejor precio y mayor beneficio a este.
          </p>
          <p>
            <b>EQUIPOS TECNOLÓGICOS:</b>
            significa dispositivos móviles, smartphones, tabletas, computadoras y cualquier otro aparato electrónico
            por el cual se pueda acceder a la PLATAFORMA.
          </p>
          <p>
            <b>DATOS PERSONALES:</b>
            Toda información que permite identificar a una persona natural dentro de la PLATAFORMA.
          </p>
          <p>
            <b>MAYOR DE EDAD:</b>
            Persona natural mayor de dieciocho (18) años.
          </p>

          <p>
            <b>INTERACCIÓN:</b>
            Facultad de acceso a USUARIOS de ESTABLECIMIENTOS Y DISTRIBUIDORES para operar funcionalidades, visualizar
            publicidad y tramitar ordenes de compra/venta de productos y/o servicios en la PLATAFORMA.
          </p>
          <p>
            <b>VENTANA EMERGENTE (POP-UP):</b>
            Ventana o aviso de internet que emerge automáticamente en cualquier momento cuando se utiliza la
            PLATAFORMA.
          </p>
          <p>
            <b>MENSAJE DE DATO:</b>
            Información generada, enviada, recibida, almacenada o comunicada por medios electrónicos, ópticos o
            similares, como pudieran ser, entre otros, el Intercambio Electrónico de Datos (EDI), Internet, el correo
            electrónico, el telegrama, el télex o el telefax (Ley 527 de 1999 art. 2 lit. a).
          </p>
          <p>
            <b>CORREO ELECTRÓNICO:</b>
            Comprende el envío, transmisión, recepción y almacenamiento de mensajes de datos por vía electrónica. Las
            dudas que surjan respecto de la eficacia y validez de los mensajes de datos y demás actividades vinculadas
            al correo electrónico se interpretarán de conformidad con la ley 527 de 1999.
          </p>
          <p>
            <b>COOKIE:</b>
            Cadena de texto enviada virtualmente que es almacenada por el uso de la PLATAFORMA. y usada por XLABON
            para la consulta de las actividades y preferencias de los usuarios.
          </p>
          <p>
            <b>MENSAJE DE TEXTO (SMS):</b>
            Toda comunicación y/o notificación enviada por XLABON por medio de mensaje de texto (SMS). Adicionalmente,
            XLABON podrá comunicarse con el usuario a través de otros canales como Whatsapp y cualquier otro medio de
            acceso al usuario siempre y cuando este haya compartido la información con XLABON mediante su previa
            autorización. XLABON únicamente será responsable por las comunicaciones y/o notificaciones enviadas desde
            el número definido y/o canales adicionales que establezca para dicho fin. XLABON no será responsable
            frente a ESTABLECIMIENTOS, DISTRIBUIDORES, ORGANIZACIONES o terceros, por mensajes y comunicaciones
            enviadas desde canales que XLABON no reconozca como propios.
          </p>
          <p>
            <b>PUBLICIDAD:</b>
            Es toda forma de comunicación realizada por XLABON, con el fin de brindar información sobre productos,
            actividades comerciales y comunicar estrategias o campañas publicitarias o de mercadeo, propias o de
            terceros ; realizada como mecanismo de referencia y no como oferta pública.
          </p>
          <p>
            <b>CONTENIDO DIRECTO:</b>
            significa todo tipo de información, ya sea gráfica o escrita, imágenes, audios y videos, incluyendo, pero
            no limitándose a la ubicación, anuncios, comentarios, noticias, datos, información, guiones, dibujos,
            textos, diseños, esquemas, mapas y características interactivas presentadas por XLABON en la PLATAFORMA y
            en cualquier otro canal administrado por XLABON.
          </p>
          <p>
            <b>CONTENDIDO COMPARTIDO:</b>
            significa toda clase de fotografía, video, comentario, comunicación u otro contenido generado por los
            usuarios, que haya sido subido o cargado a la PLATAFORMA y/o a cualquier canal administrado por XLABON.
          </p>
          <p>
            <b>TÉRMINOS Y CONDICIONES DE USO:</b>
            Constituye los términos que han de regular el uso que los usuarios del ESTABLECIMIENTO y DISTRIBUIDOR da a
            la PLATAFORMA. así como la relacion comercial que se puede generar entre ESTABLECIMIENTO y el
            DISTRIBUIDOR.
          </p>
          <p>
            <b>POLÍTICA DE PRIVACIDAD:</b>
            Significa las políticas de privacidad de XLABON, las cuales se encuentran debidamente publicadas en la
            PLATAFORMA
          </p>
          <h4>5. OBJETO</h4>
          <p>
            Los presentes términos y condiciones regulan la autorización de uso que otorga XLABON a los USUARIOS de
            ESTABLECIMIENTOS Y DISTRIBUIDORES, para que estos ingresen a la PLATAFORMA, se informen y realicen ordenes
            de compra a DISTRIBUIDORES sobre los productos y servicios de las ORGANIZACIONES.
          </p>
          <p>
            XLABON a través de la PLATAFORMA realiza las siguientes acciones: i) Exhibe productos y/o servicios de la
            ORGANIZACIÓN de forma comercial y/o publicitaria por medio de DISTRIBUIDORES para que puedan ser
            visualizados por ESTABLECIMIENTOS y se puedan generar ordenes de compra por medio de la PLATAFORMA. ii)
            Facilita el contacto entre la ORGANIZACIÓN, el ESTABLECIMIENTO y el DISTRIBUIDOR para una relacion
            comercial mas fuerte. iii) Genera información para la ORGANIZACION, el DISTRIBUIDOR y el ESTABLECIMIENTO
            para la toma de decisiones.
          </p>
          <h4>6. MODIFICACIÓN</h4>
          <p>
            XLABON podrá modificar autónomamente y en cualquier momento en aspectos formales, procedimentales o
            sustanciales los presentes Términos y Condiciones de uso, los cuales serán actualizados y puestos a
            disposición de los usuarios en la PLATAFORMA, siendo la última versión publicada la que regulará las
            relaciones comerciales que se generen al momento de realizarse las ordenes de compra. Así mismo, cuenta
            con plena autonomía para modificar el uso de la PLATAFORMA permitido a el USUARIO, con el único deber de
            informarlo por un medio virtual que permita su publicación y comunicación al público.
          </p>
          <h4>DETALLE DEL SERVICIO</h4>
          <h4>7. USUARIO ESTABLECIMIENTO</h4>
          <p>
            Toda persona natural registrada en la PLATAFORMA, vinculada a un ESTABLECIMIENTO que, como destinatario
            final, use la PLATAFORMA para solicitar por medio de esta órdenes de compra a los DISTRIBUIDORES
            vinculados en ella.
          </p>
          <p>
            El uso de la PLATAFORMA lo realiza el USUARIO del ESTABLECIMIENTO como persona capaz, manifestando que,
            para solicitar órdenes de compra a un DISTRIBUIDOR, cuenta con plena capacidad legal para ser sujeto de
            derechos y obligaciones, calidades que refrenda al momento de generar su registro.
          </p>
          <p>
            El ESTABLECIMIENTO para su registro tiene la obligación de: 1) Cargar en la PLATAFORMA copia de un
            documento de identidad nacional del propietario o representante legal (Cédula de
            Ciudadanía/Extranjería/Pasaporte); 2) Cargar el registro único tributario (RUT) del ESTABLECIMIENTO; 3)
            Cargar Cámara de Comercio actualizada con vigencia no mayor a 3 meses. Estos documentos serán mandatorios
            con fin de permitir la aprobación de vinculación por parte de los DISTRIBUIDORES.
          </p>
          <h4>8. USUARIO DISTRIBUIDOR</h4>
          <p>
            Toda persona natural registrada en la PLATAFORMA, vinculada a un DISTRIBUIDOR que, use la PLATAFORMA para,
            recibir y tramitar órdenes de compra solicitadas por los ESTABLECIMIENTOS.
          </p>
          <p>
            El uso de la PLATAFORMA lo realiza el USUARIO del DISTRIBUIDOR como persona capaz, manifestando que, para
            recibir y operar órdenes de compra de un ESTABLECIMIENTO, cuenta con plena capacidad legal para ser sujeto
            de derechos y obligaciones, calidades que refrenda al momento de generar su registro.
          </p>
          <p>
            El DISTRIBUIDOR para su registro tiene la obligación de: 1) Cargar en la PLATAFORMA copia de un documento
            de identidad nacional del propietario o representante legal (Cédula de Ciudadanía/Extranjería/Pasaporte);
            2) Cargar el registro único tributario (RUT) del DISTRIBUIDOR; 3) Cargar Cámara de Comercio actualizada
            con vigencia no mayor a 3 meses.
          </p>

          <h4>9. CUENTA DE USUARIO</h4>
          <p>
            El USUARIO de ESTABLECIMIENTO solicita órdenes de compra de productos y/o servicios ofrecidos en la
            PLATAFORMA a DISTRIBUIDORES, teniendo como condición necesaria una cuenta vinculada a un ESTABLECIMIENTO,
            con un perfil de negocio ingresado y aprobado. El USUARIO podrá ver el portafolio del DISTRIBUIDOR
            seleccionado, una vez el ESTABLECIMIENTO al que pertenece el USUARIO, esté validado y aprobado en la base
            de clientes de ese DISTRIBUIDOR, de lo contrario no podrá visualizarlo.
          </p>
          <p>
            Para el ingreso del ESTABLECIMIENTO a la PLATAFORMA, se solicitará el registro de los datos por parte de
            un ADMINISTRADOR como; Razón Social, NIT, RUT, tipo de negocio, dirección de los puntos de entrega, otros
            usuarios e información personal. Esta información se utiliza para la plena identificación de las personas
            que pretenden realizar las órdenes de compra al DISTRIBUIDOR y de esta manera adquirir los productos, para
            el cumplimiento de los presentes términos y condiciones, para la prevención de fraudes, para vincular al
            ESTABLECIMIENTO con el DISTRIBUIDOR y en general para los fines definidos en el manejo de información.
          </p>
          <p>
            El USUARIO de DISTRIBUIDOR tramita ordenes de compra de productos y/o servicios ofrecidos en la PLATAFORMA
            a ESTABLECIMIENTOS, teniendo como condición necesaria una cuenta vinculada a un DISTRIBUIDOR, con un
            perfil de negocio ingresado y aprobado. El USUARIO podrá ver la información de los ESTABLECIMIENTOS que
            estén validados y aprobados en la base de clientes de ese DISTRIBUIDOR, de lo contrario no podrán ser
            visualizarlos.
          </p>
          <p>
            Para el ingreso del DISTRIBUIDOR a la PLATAFORMA, se solicitará el registro de los datos por parte de un
            USUARIO ADMINISTRADOR como; Razón Social, NIT, RUT, tipo de distribuidor, zona de cobertura, horario,
            tiempos de entrega, portafolio, precios, inventarios, medios de pago, otros usuarios e información
            personal. Esta información se utiliza para la plena identificación de las personas que pretenden tramitar
            las órdenes de compra de los ESTABLECIMIENTOS y de esta manera entregar los productos, para el
            cumplimiento de los presentes términos y condiciones, para la prevención de fraudes, para vincular al
            DISTRIBUIDOR con los ESTABLECIMIENTOS y en general para los fines definidos en el manejo de información.
          </p>
          <p>
            El uso de la cuenta es personal e intransferible, por lo cual el ESTABLECIMIENTO y DISTRIBUIDOR no se
            encuentra facultado para ceder los datos de validación para el acceso a la PLATAFORMA, ni el uso de su
            cuenta a ningún tipo de terceros. El incumplimiento de lo anterior, acarreará la suspensión y bloqueo
            definitivo de la cuenta (incluye correo electrónico y número de identificación personal). En caso de
            olvido de los datos de validación o de usurpación de éstos, es obligación del USUARIO informarlo a XLABON
            a través de la opción “olvidó su contraseña” dentro de la PLATAFORMA. Las cuentas de los usuarios serán
            administradas por XLABON o por la persona que este designe, teniendo plena facultad para la conservación o
            no de la cuenta, cuando la información suministrada por el ESTABLECIMIENTO y DISTRIBUIDOR no es veraz,
            completa o segura; o cuando se presente incumplimiento de las obligaciones del USUARIO. En ningún momento
            XLABON solicitará al ESTABLECIMIENTO o DISTRIBUIDOR información que NO resulte necesaria para su
            vinculación comercial. Por lo tanto, los datos de tarjetas débito o crédito, solo se solicitarán al
            momento de realizar el pago virtual con el Distribuidor si ese es el método de pago habilitado en la
            PLATAFORMA aprobado por el DISTRIBUIDOR y deseado por el ESTABLECIMIENTO.
          </p>
          <p>
            Con la creación de la Cuenta de Usuario, el USUARIO del ESTABLECIMIENTO y DISTRIBUIDOR está manifestando
            su voluntad de aceptación expresa e inequívoca de los presentes Términos y Condiciones de uso de la
            PLATAFORMA.
          </p>
          <p>
            <b>Parágrafo.</b>
            Autoriza expresamente el USUARIO del ESTABLECIMIENTO y DISTRIBUIDOR al momento de la aceptación de los
            presentes Términos, el uso de Cookies por parte de XLABON en toda actividad de uso que realice de la
            PLATAFORMA.
          </p>
          <h4>10.CAPACIDAD</h4>
          <p>
            Es claro para el ESTABLECIMIENTO que la relación comercial que se puede llegar a generar por el uso de la
            PLATAFORMA no vincula de ninguna manera a XLABON. Lo anterior, puesto que la relación comercial será
            directamente con el DISTRIBUIDOR.
          </p>
          <p>
            En virtud de las condiciones de capacidad legal establecidas en el Código Civil Colombiano y de la validez
            de la manifestación de voluntad a través de medios electrónicos establecida en la ley 527 de 1999, El
            ESTABLECIMIENTO y DISTRIBUIDOR al momento de la creación de la Cuenta de Usuario, manifiestan expresamente
            tener capacidad para celebrar el tipo de transacciones que se pueden realizar usando la PLATAFORMA.
          </p>
          <h4>11.DESCRIPCIÓN DEL SERVICIO</h4>
          <p>
            XLABON exhibe a ESTABLECIMIENTOS a través de la PLATAFORMA productos y/o servicios de ORGANIZACIONES por
            medio de DISTRIBUIDORES vinculados para que estos puedan tramitar las ordenes de compra.
          </p>
          <p>
            Para la solicitud de orden de compra, el USUARIO deben estar plenamente identificado en su cuenta de
            usuario vinculado a un ESTABLECIMIENTO y seguir el siguiente procedimiento:
          </p>
          <p>
            a) Ingresar a la PLATAFORMA y seleccionar de la lista de puntos de entrega registrados (lista autorizada
            en el perfil del ESTABLECIMIENTO de los puntos de entrega registrados) , el punto de entrega del que se
            desea realizar la orden de compra para mostrar los DISTRIBUIDORES vinculados que cubren ese sector. De no
            haber aún un DISTRIBUIDOR vinculado cubriendo esa zona, el USUARIO será notificado.
          </p>
          <p>
            b) Seleccionar el DISTRIBUIDOR al que desea hacer la orden de compra, recuerde que el ESTABLECIMIENTO al
            cual pertenece el USUARIO, ya debe estar aprobado en la base de clientes por parte de este, y de esta
            forma podrá visualizar su portafolio y realizar las respectivas órdenes de compra. De no estarlo, podrá
            envíar la solicitud de vinculación para que el DISTRIBUIDOR lo apruebe y sea ingresado en su base de
            clientes para dar inicio a la relación comercial.
          </p>
          <p>
            c) Seleccione el producto. Una vez seleccionado se pone a disposición del USUARIO las características, el
            valor del producto por medio de fotografías y las notas de referencias, así mismo, se pone a disposición
            los puntos promocionales FT. ofrecidos por la ORGANIZACIÓN en ese producto, para que puedan ser acumulados
            por su compra y redimidos como parte del saldo de un pedido futuro a un DISTRIBUIDOR.
          </p>
          <p>
            d) Validación del producto. Cumplido el paso anterior, XLABON exhibe al ESTABLECIMIENTO, el resumen del
            producto con sus condiciones generales tales como la marca, precio e inventario definido por el
            DISTRIBUIDOR y puntos promocionales FT asignados por la ORGANIZACIÓN. De esta manera el PLANEADOR o
            AMINISTRADOR del ESTABLECIMIENTO podrá validar y confirmar la información del producto seleccionado.
          </p>
          <p>
            e) Ingreso del producto al carrito de compras. Este ingreso corresponde a la voluntad inequívoca del
            ESTABLECIMIENTO para hacer una solicitud (orden de compra) al DISTRIBUIDOR, consistente por adquirir los
            productos determinados, ya que se ha informado con suficiencia las características del mismo, teniendo la
            posibilidad de adquirirlo o no.
          </p>
          <p>
            f) Horario de órdenes de compra. Toda solicitud de orden de compra será cargada en la PLATAFORMA en
            horario abierto de 24 horas en el día, los 7 días de la semana para su aprobación por parte del
            ADMINISTRADOR del ESTABLECIMIENTO. La PLATAFORMA hará su posterior envío al DISTRIBUIDOR de acuerdo al
            horario de operación que este tenga estipulado en su perfil de la PLATAFORMA para que la orden de compra
            sea aprobada, entregada y cobrada de acuerdo a los tiempos estipulados de entrega en su perfil.
          </p>
          <p>
            g) Orden de compra Mínima. El valor de la orden de compra mínima varía dependiendo del DISTRIBUIDOR y este
            será estipulado en su perfil dentro de la PLATAFORMA. De igual forma, la PLATAFORMA no permitirá enviar al
            DISTRIBUIDOR una orden de compra que no cumpla con el valor comunicado.
          </p>
          <p>
            h) Valor. Una vez se han agotados los pasos precedentes, se pone a disposición del ESTABLECIMIENTO el
            valor de la orden de compra de los productos seleccionados, junto con los beneficios en puntos
            promocionales Ft adquiridos. Dicho valor refleja la suma que se encuentra discriminada unitariamente y en
            conjunto por todos los productos y/o servicios solicitados de acuerdo a información de oferta del
            DISTRIBUIDOR.
          </p>
          <p>
            XLABON se reserva el derecho de no comunicar variación en el precio de los productos una vez la orden de
            compra ha sido recibida y aprobada por el DISTRIBUIDOR, ya que existen convenios de precios especiales
            entre los ESTABLECIMIENTOS y los DISTRIBUIDORES que la plataforma respeta. De igual forma, el DISTRIBUIDOR
            podrá sugerir cambios en los productos solicitados, ya sea por desabasto u otro factor por medio del chat
            de la plataforma, WhatsApp o llamada para su respectiva aprobación por parte del ESTABLECIMIENTO. El
            usuario reconoce y acepta la anterior condición.
          </p>
          <p>
            i) Pago. El ESTABLECIMIENTO realizará el pago directamente al DISTRIBUIDOR de acuerdo a las condiciones o
            convenio que este tenga con el ESTABLECIMIENTO y su medio de pago habilitado, ya sea contra-entrega, con
            plazo, efectivo o pago virtual.
          </p>
          <p>
            j) Resumen. Una vez completados los pasos anteriores se le exhibe a través de una ventana emergente al
            ESTABLECIMIENTO un resumen detallado de la orden de compra. Dicho resumen quedará guardado en el módulo de
            pedidos para su respectivo seguimiento de la entrega que irá siendo notificado.
          </p>
          <p>
            k) Resumen del encargo. Quedará guardado el resumen de la orden de compra solicitada con su respectivo
            detalle como pre-factura. XLABON no emite facturas oficiales por ser una PLATAFORMA VIRTUAL que no
            comercializa productos ni servicios directamente. El vendedor directo de los productos y servicios es el
            DISTRIBUIDOR con quien el ESTABLECIMIENTO, ha aprobado una relación comercial. De esta manera, la factura
            será enviada directamente por el DISTRIBUIDOR por medio digital o entregada con el pedido. En caso de no
            recibirla y de requerir la factura de compra de los productos de manera anticipada, el ESTABLECIMIENTO
            deberá informar al DISTRIBUIDOR, bien sea por el chat de la PLATAFORMA o por vía telefónica. XLABON no
            será responsable por la entrega de facturas y el USUARIO del ESTABLECIMIENTO deberá entenderse
            directamente con el DISTRIBUIDOR, toda vez que este lo requiera.
          </p>
          <p>
            l) Entrega. Verificados todos los datos correspondientes a la orden de compra y al ámbito de cobertura de
            entrega de los productos, el DISTRIBUIDOR entregará en la dirección suministrada en el término definido.
            En caso de no poderse realizar la entrega por razones imputables al ESTABLECIMIENTO, deberá el
            DISTRIBUIDOR dejar constancia de este hecho y responder en virtud de su calidad de servicio.
          </p>
          <p>
            <b>Parágrafo.</b>
            El perfeccionamiento de la orden de compra solicitada por medios electrónicos, se da al momento en que el
            DISTRIBUIDOR entrega la orden de compra aprobada al ESTABLECIMIENTO.
          </p>
          <p>
            m) Garantía. El ESTABLECIMIENTO entiende y acepta que la relación jurídica de la orden de compra es
            directamente con el DISTRIBUIDOR, por lo tanto, las reclamaciones por garantía se deben realizar
            directamente al DISTRIBUIDOR, quien tiene la obligación de responder por la gestión del encargo.
          </p>
          <p>
            n) Calificación de servicio: Una vez entregada la orden de compra. El ESTABLECIMIENTO tendrá la
            posibilidad de poder calificar el DISTRIBUIDOR en la PLATAFORMA, de acuerdo a la experiencia obtenida en
            esa orden de compra en particular, esta irá generando la calificación general del DISTRIBUIDOR que se
            podrá observar en el perfil de este dentro de la PLATAFORMA.
          </p>
          <p>
            o) Programa de puntos de lealtad FT. Es un programa mediante el cual las ORGANIZACIONES, generan
            fidelización a su clientes finales (ESTABLECIMIENTOS) respecto a la adquisición de sus productos y/o
            servicios en los DISTRIBUIDORES vinculados a la PLATAFORMA. Dicha fidelización se materializa en el
            ESTABLECIMIENTO mediante la compra de productos y/o servicios promocionados de la ORGANIZACIÓN, y a manera
            de recompensa, se traduce en la asignación de PUNTOS FT.
          </p>
          <p>
            XLABON no se hace responsable por los productos adquiridos ni por la relación entre el ESTABLECIMIENTO, el
            DISTRIBUIDOR y la ORGANIZACIÓN. El servicio de XLABON en esta modalidad consiste únicamente en la entrega
            y administración de los PUNTOS FT. por las compras realizadas en la PLATAFORMA y el pago de códigos de
            puntos redimidos al DISTRIBUIDOR, con el fin de cubrir descuentos generados.
          </p>
          <p>
            p) PUNTOS FT. Son puntos ofrecidos por las ORGANIZACIONES a ESTABLECIMIENTOS por la compra de sus
            productos y/o servicios promocionados, son acumulables y pueden ser redimidos y/o usados como parte del
            saldo de futuros PEDIDOS a DISTRIBUIDORES generando un descuento de acuerdo a los rangos de redención
            definidos en la PLATAFORMA.
          </p>
          <h4>12.DEBERES DEL USUARIO</h4>
          <p>
            Con la aceptación de los presentes términos y condiciones el ESTABLECIMIENTO y el DISTRIBUIDOR se obliga
            a: (1) Suministrar información veraz y fidedigna al momento de crear su Cuenta de Usuario; (2) Abstenerse
            de transferir a terceros los datos de validación (nombre de usuario y contraseña); (3) Abstenerse de
            utilizar la PLATAFORMA para realizar actos contrarios a la moral, la ley, el orden público y buenas
            costumbres en contra de XLABON, sus USUARIOS o de terceros; (4) Pagar y entregar oportunamente las ordenes
            de compra contraídas en la PLATAFORMA; (5) Informar inmediatamente a XLABON en caso de olvido o usurpación
            de los datos de validación; (6) Abstenerse de realizar conductas atentatorias del funcionamiento de la
            PLATAFORMA; (7) Abstenerse de suplantar la identidad de otros ESTABLECIMIENTOS o DISTRIBUIDORES; (8)
            Abstenerse de descifrar, descompilar o desensamblar cualquier elemento de la PLATAFORMA o de cualquiera de
            sus partes; (9) Habilitar la utilización de ventanas emergentes durante la operación; (10) En general
            todas aquellas conductas necesarias para la ejecución del negocio jurídico, como i) la entrega y recepción
            de los productos solicitados, ii) exhibir la identificación en caso de venta de productos de uso
            restringido, iii) verificar al momento de la validación que los productos seleccionados sí corresponden a
            los necesitados.
          </p>
          <h4>13.DEBERES DE XLABON</h4>
          <p>
            En virtud de los presentes términos XLABON, se obliga a (1) Suministrar información cierta, fidedigna,
            suficiente, clara y actualizada respecto de los productos que exhibe; (2) Indicar las características
            generales del producto para que sirvan de referencia a los usuarios; (3) Enviar al correo electrónico
            suministrado por el USUARIO ESTABLECIMIENTO resumen del encargo; (4) Poner a disposición de los usuarios
            los términos y condiciones de uso de La Plataforma de forma actualizada; (5) Utilizar la información
            únicamente para los fines establecidos en los presentes términos; (6) Utilizar mecanismos de información y
            validación durante la transacción como ventanas emergentes (Pop Ups), que permitan al ESTABLECIMIENTO y
            DISTRIBUIDOR aceptar o no cada paso de la operación.
          </p>
          <h4>14.DESCRIPCIÓN DE LOS PRODUCTOS Y SERVICIOS</h4>
          <p>
            Los productos y servicios exhibidos por XLABON en la PLATAFORMA son para el uso del SECTOR. Todos los
            productos cuentan con una descripción general; esta descripción es validada por XLABON a partir de
            prácticas legales de visualización. La disponibilidad será definida en cada caso en concreto al momento en
            que el DISTRIBUIDOR apruebe la orden de compra. Dentro de la ejecución de la orden, el ESTABLECIMIENTO
            determinará qué acción debe realizar el DISTRIBUIDOR en caso de no hallar el producto o productos
            solicitados, entre: (i) Cumplir con la orden de compra excluyendo el producto o productos solicitados no
            hallados, caso en el cual se descontará su valor del valor total la orden. (ii) Comunicarse para concertar
            el cumplimiento con un producto sustituto; y (iii) Autorizar al DISTRIBUIDOR para llevar un producto
            similar en precio y tipo.
          </p>
          <p>
            Es claro para el ESTABLECIMIENTO que XLABON no es productor, proveedor, expendedor, agente, distribuidor y
            en general ningún tipo de comercializador de los productos que exhibe, ya que opera solo como una
            PLATAFORMA tecnológica que permite el encuentro de ESTABLECIMIENTOS y el DISTRIBUIDORES para la gestión
            eficiente de ordenes de compra.
          </p>
          <p>
            Cuando por errores de caracter tecnológico se exhiban precios erróneos de los productos en la PLATAFORMA,
            que evidentemente resultan desproporcionados, comparativamente con el precio del producto en el mercado,
            XLABON podrá cancelar la(s) ordenes realizadas de productos con dichos precios, a su libre discreción y
            con plena autonomía.
          </p>
          <p>
            XLABON se reserva el derecho de actualizar, modificar y/o descontinuar los productos exhibidos en la
            PLATAFORMA.
          </p>
          <h4>CONSIDERACIONES FINALES</h4>
          <h4>15. CONTENIDO</h4>
          <p>
            A través de la PLATAFORMA, XLABON podrá poner a disposición de los ESTABLECIMIENTOS, DISTRIBUIDORES y
            ORGANIZACIONES información de carácter comercial y publicitario, propio o de terceros de conformidad a las
            buenas costumbres comerciales. En estos casos XLABON no avala, garantiza o compromete su responsabilidad
            frente a los servicios y/o productos que se comercialicen por éstos terceros, ya que la PLATAFORMA sirve
            como canal de comunicación y publicidad. En consecuencia, es total responsabilidad del ESTABLECIMIENTO,
            DISTRIBUIDOR u ORGANIZACIÓN el acceso a los sitios que remite la publicidad, asumiendo la obligación de
            verificar y conocer los términos de los servicios ofrecidos por los terceros.
          </p>
          <p>
            Toda la información puesta a disposición en la PLATAFORMA como imágenes, publicidad, nombres, marcas,
            datos, lemas y demás elementos de propiedad intelectual; son utilizados legítimamente por
            <b>XLABON</b>
            , sea porque son de su propiedad, porque tiene autorización para ponerlos a disposición, o porque se
            encuentra facultado para hacerlo en virtud de las decisiones 351 de 1993 y 486 de 2000 de la Comunidad
            Andina de Naciones y la ley 23 de 1982 .
          </p>
          <p>
            En caso de que la PLATAFORMA permita la publicación, subida o carga de contenido a los USUARIOS, éstos
            serán considerados en todo momento como no confidenciales. Además, mediante la publicación o carga de los
            mismos a la PLATAFORMA, el USUARIO otorga a XLABON un derecho perpetuo, mundial, no exclusivo, libre de
            regalías, sublicenciable y transferible para usar, reproducir, distribuir, modificar, mostrar y realizar
            el envío de contenidos de usuarios, para la promoción y apoyo de XLABON. El ESTABLECIMIENTO, DISTRIBUIDOR
            u ORGANIZACIÓN otorga a los usuarios una licencia no exclusiva para acceder a sus contenidos compartidos a
            través de la PLATAFORMA y de mostrar y representar públicamente dicho Material, en virtud de estos
            Términos y Condiciones.
          </p>
          <p>
            En relación con lo anterior, el USUARIO declara acerca del contenido compartido que: (i) es titular de los
            derechos de autor sobre los mismos, o que tiene la autorización del propietario de los mismos para su
            publicación en XLABON; (ii) no es ilegal, obsceno, difamatorio, injurioso, pornográfico, odioso, racista,
            o inadecuado por cualquier motivo; (iii) no dañará la reputación de XLABON o cualquier tercero; y (iv) no
            se hace pasar por otra persona. XLABON se reserva el derecho de eliminar cualquier contenido compartido
            por el USUARIO a su entera discreción y sin previo aviso ni responsabilidad hacia el USUARIO o cualquier
            otra persona.
          </p>
          <p>
            XLABON puede proporcionar enlaces a sitios web pertenecientes o gestionados por terceros, sin que por este
            hecho pueda entenderse, bajo ninguna circunstancia, que XLABON respalda el contenido, productos o
            servicios disponibles en dichos sitios web, y que no es responsable de su contenido o su seguridad. El
            enlace o conexión del USUARIO a cualquier otro sitio web es de su exclusiva responsabilidad.
          </p>
          <h4>16. FUNCIONAMIENTO DE LA PLATAFORMA</h4>
          <p>
            XLABON administra directamente o por medio de terceras personas la PLATAFORMA, toda la información que se
            comunica allí corresponde a información cierta y actualizada. En ningún caso responderá por daños directos
            o indirectos que sufra el USUARIO por la utilización o incapacidad de utilización de la PLATAFORMA.
          </p>
          <p>
            La PLATAFORMA se encuentra disponible las 24 horas del día para su acceso y consulta. Para la realización
            de solicitud de ordenes de compra, la disponibilidad de la PLATAFORMA es también de 24 horas, entendiendo
            que la aprobación de estos dependerá del horario y disponibilidad del DISTRIBUIDOR. XLABON realiza los
            mejores esfuerzos para mantener en operación la PLATAFORMA, pero en ningún caso garantiza disponibilidad y
            continuidad permanente.
          </p>
          <p>
            XLABON se reserva el derecho de cancelar las cuentas de usuarios y/o de prohibir el acceso a la PLATAFORMA
            a los USUARIOS que realicen conductas violatorias de los presentes términos o que incumplan las
            obligaciones contraídas.
          </p>
          <h4>17. COMERCIO ELECTRÓNICO</h4>
          <p>
            En cumplimiento de las disposiciones colombianas sobre mensajes de datos según la ley 527 de 1999, se
            comunica que la legislación nacional reconoce validez a los mensajes de datos y por tanto ellos adquieren
            carácter y entidad probatoria. En consecuencia, entienden los USUARIOS, que mediante el cruce de mensajes
            de datos los intervinientes pueden dar lugar al nacimiento, modificación y extinción de obligaciones,
            siendo de su resorte exclusivo el contenido, consecuencias, responsabilidades y efectos de la información
            generada.
          </p>
          <h4>18. MANEJO DE INFORMACIÓN</h4>
          <p>
            La información recolectada por XLABON en las ordenes de compra , es suministrada por los USUARIOS de forma
            libre y voluntaria, para que esta sea administrada por XLABON o por quien este designe para el
            cumplimiento de los deberes adquiridos, lo que implica su recolección; almacenamiento en servidores o
            repositorios de XLABON o de terceros; circulación de los mismos dentro de la organización XLABON;
            comunicación al USUARIO, información comercial, publicitaria y de mercadeo relacionada con su actividad
            comercial.
          </p>
          <p>
            Así mismo, los datos recolectados serán objeto de análisis para fines de mejorar la estrategia de negocios
            del portal web, apoyada en herramientas de inteligencia de negocios y minería de datos, que permiten
            adquirir conocimientos prospectivos para fines de predicción, clasificación y segmentación de mercado.
          </p>
          <p>
            El USUARIO podrá ejercer su derecho de conocer, actualizar, modificar y suprimir los datos personales
            existentes en las bases de datos asociadas a la PLATAFORMA. Para ello deberá realizar la solicitud de
            consulta, reclamo o supresión a la dirección electrónica servicioalcliente@xlabon.com detallando las
            modificaciones a realizar y aportando los documentos que lo soportan.
          </p>
          <p>
            XLABON es responsable del tratamiento de la información personal recolectada a través del portal web,
            responsabilidad que podrá delegar en un tercero, en calidad de responsable o encargado de la información,
            asegurando contractualmente adecuado tratamiento de la misma.
          </p>
          <h4>19. DOMICILIO Y LEGISLACIÓN APLICABLE</h4>
          <p>
            Los presentes Términos y Condiciones de Uso de la PLATAFORMA se acogen en el territorio Colombiano,
            conforme a su normatividad general y sectorial. Su adopción implica el ejercicio de su libre voluntad y
            que la relación que surge de este documento se regirá en todos sus efectos por su contenido y en su
            defecto por la ley comercial colombiana.
          </p>
          <h4>20. ACEPTACIÓN TOTAL DE LOS TÉRMINOS</h4>
          <p>
            El USUARIO manifiesta expresamente tener capacidad legal para usar la PLATAFORMA y poder realizar órdenes
            de compra entre los ESTABLECIMIENTOS y DISTRIBUIDORES vinculados. Así mismo, manifiesta haber suministrado
            información real, veraz y fidedigna; por ende de forma expresa e inequívoca declara que ha leído, que
            entiende y que acepta la totalidad de las situaciones reguladas en el presente escrito de Términos y
            Condiciones de uso de la PLATAFORMA por lo que se compromete al cumplimiento total de los deberes,
            obligaciones, acciones y omisiones aquí expresadas.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
