<div class="container contenedor-modal">
  <div class="limit-height">
    <div class="row mt-3 no-gutters">
      <div class="col-9">Pre aprobar cliente</div>
      <div class="col-3 d-flex justify-content-end">
        <fa-icon [icon]="faTimes" class="cursorPointer" [styles]="{ color: '#41403E' }" id="fa-icon" (click)="close()">
        </fa-icon>
      </div>
    </div>
    <div class="row mt-4 no-gutters">
      <div class="col">
        <p>
          Ingresa los datos de tu cliente, registra sus asignaciones para agilizar su vinculación al distribuidor con un
          pre aprobado.
        </p>
      </div>
    </div>
    <div class="scroll">
      <div class="row no-gutters">
        <div class="col-12">Indica el cliente</div>
      </div>
      <div class="row mt-3 no-gutters">
        <div class="col-12">
          <div>NIT o CC</div>
          <span class="d-flex flex-row">
            <input type="text" placeholder="Escribe aquí" class="input-defecto" [(ngModel)]="documento_cliente"
              (input)="getEmpresaPorNit()" />
            <span class="d-flex justify-content-center align-items-center pl-3">{{empresa_seleccionada}}</span>
          </span>
        </div>
      </div>
      <div class="row mt-3 no-gutters">
        <div class="col-12">
          <span>Podrás invitarlo para que se vincule también a Feat. (Opcional)</span>
        </div>
      </div>
      <div class="row mt-3 no-gutters">
        <div class="col-12">
          <input type="checkbox" name="invitarCB" id="invitarCB" [(ngModel)]="invitar" />
          <label for="invitarCB" class="mx-2">¿Quieres invitarlo?</label>
        </div>
      </div>
      <ng-container *ngIf="invitar">
        <div class="row mt-3 no-gutters">
          <div class="col-12">
            <span>Nombre del cliente</span>
            <input type="text" placeholder="Escribe aquí" class="input-defecto" [(ngModel)]="nombre_cliente" />
          </div>
        </div>
        <div class="row mt-3 no-gutters">
          <div class="col-12 input-group">
            <span>Correo</span>
            <input type="email" placeholder="Escribe aquí" class="input-defecto" [(ngModel)]="correo_cliente"
              [email]="true" />
          </div>
        </div>
      </ng-container>
    </div>
    <div class="row my-3 no-gutters">
      <div class="col-12 d-flex justify-content-end">
        <button class="btn-preaprobar" (click)="preaprobar()">Pre aprobar cliente</button>
      </div>
    </div>
  </div>
</div>