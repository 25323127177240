<div class="d-flex justify-content-center">
  <section class="main-container p-0 pb-4 w-100">
    <div class="catalogo">
      <!--Saldos y promociones-->
      <div class="saldos-promociones p-0 rounded" routerLink="/saldos-promos-dist">
        <div
          class="saldos-imagen col-4 p-0 d-flex justify-content-center align-items-center overflow-hidden rounded-left"></div>
        <div class="col-8 p-0 pl-3 card-body align-items-center rounded cursorPointer">
          <div class="content-badge w-100">
            <div class="badge-promo">{{totalSaldosPromociones}}</div>
          </div>
          <p class="m-0 main-title text-left pl-2 texto-saldo-promo">Saldos y promociones.</p>
        </div>
      </div>
      <!--Categorías-->
      <div class="categorias rounded maxHeight">
        <div class="pt-0">
          <div class="row">
            <div class="col-12">
              <div class="cat-title">Categorías</div>
            </div>
          </div>
          <div class="d-flex flex-column align-items-start">
            
            <div class="cat-container">
              <div class="cat-icon-container">
                <img src="assets/iconos-org/todos.png" style="width: 60%" class="cursorPointer" alt="" />
              </div>
              
              <div class="cat-name-container">
                <span
                  (click)="seleccionarCategoria('0', -1)"
                  class="cursorPointer"
                  [style]="categoriaSeleccionada === '0' ? 'color: #8e6ff7' : ''">
                  Todos
                </span>
              </div>
            </div>
            <div *ngFor="let cat of categorias_productos; index as i" class="w-100 cat-container">
              <div class="cat-icon-container">
                <span *ngIf="cat.logoOn">
                  <img
                    *ngIf="categorias_productos_estados[i]"
                    class="cursorPointer"
                    src="{{ cat.logoOn }}"
                    style="width: 60%"
                    alt="" />
                  <img
                    *ngIf="!categorias_productos_estados[i]"
                    class="cursorPointer"
                    src="{{ cat.logoOff || cat.logoOn }}"
                    style="width: 60%"
                    alt="" />
                </span>
                <span *ngIf="!cat.logoOn">
                  <img
                    *ngIf="!categorias_productos_estados[i]"
                    src="assets/iconos-org/todos.png"
                    class="cursorPointer"
                    style="width: 60%"
                    alt="" />
                  <img
                    *ngIf="categorias_productos_estados[i]"
                    src="assets/iconos-org/todos-selected.png"
                    class="cursorPointer"
                    style="width: 60%"
                    alt="" />
                </span>
              </div>
              <div class="cat-name-container">
                <span
                  class="cursorPointer"
                  (click)="seleccionarCategoria(cat, i)"
                  [style]="categoriaSeleccionada === cat._id ? 'color: #8e6ff7' : ''">
                  {{ cat.nombre }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Buscador-->
      <div class="barra-busqueda rounded">
        <div class="d-flex flex-row autocompletado-busqueda px-0 h-100 w-100">
          <button class="col-2 col-lg-1 col-sm-0 p-0 boton-buscar d-flex justify-content-center">
            <fa-icon class="fa-icon-search m-0" [icon]="faSearch" size="lg" [styles]="{ color: '#41403E' }"></fa-icon>
          </button>
          <form class="example-form col-8 col-sm-8 col-md-10 pr-3">
            <mat-form-field class="example-full-width">
              <input
              #escogido
              type="text"
              placeholder="Buscar por nombre del producto / Cod Dist"
              aria-label="Buscar distribuidor"
              [(ngModel)]="stBusq"
              [ngModelOptions]="{standalone: true}"
              class="w-100 h-100 input-busqueda pr-0"  />
            </mat-form-field>
             
          </form>
          <div class="col-2 col-lg-1 col-sm-0 p-0 boton-buscar d-flex justify-content-center">
            <button class="orderBot" *ngIf="ordenActual === 'desc'" (click)="ordenarPorNombre('asc'); ordenActual = 'asc'">Z-A</button>
            <button class="orderBot" *ngIf="ordenActual === 'asc'" (click)="ordenarPorNombre('desc'); ordenActual = 'desc'">A-Z</button>
          </div>
        </div>
      </div>
      <!--Header y btns crear productos-->
      <div class="crear-productos row m-0">
        <div class="col-12 col-md-6 px-2">
          <button class="btn-white" (click)="goAggProductManual()">Agregar producto manualmente</button>
        </div>
        <div class="col-12 col-md-6 px-2 margin-top-md">
          <button class="btn-purple" (click)="cargarProductosDesdeExcel()">Cargar productos desde Excel</button>
        </div>
      </div>
      <!--Indicadores-->
      <div class="indicadores-container row m-0">
        <div class="col-12 col-sm-6 px-2">
          <div class="card">
            <div class="card-body card-indicador d-flex flex-column m-0">
              <span class="d-flex flex-row col-12">
                <div class="div-col-icon">
                  <img
                    src="../../../assets/img/icons/icon-store.png"
                    class="img-icon"
                    alt="Icono de tres cajas apiladas" />
                </div>
                <div class="ml-3">
                  <h3>{{ organizaciones.length || 0 }}</h3>
                </div>
              </span>
              <div class="col-12">
                <p class="m-0">Organizaciones</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 px-2 margin-top-md2">
          <div class="card">
            <div class="card-body deletePadLeftRigth">
              <div class="col-12 deleteMargin">
                <div class="row deleteMargin">
                  <div class="col-md-4 text-center deleteMargin">
                    <p class="tam-indicador">Aceptados</p>
                    <h3>{{ prodAceptados || 0 }}</h3>
                  </div>
                  <div class="col-md-4 text-center deleteMargin">
                    <p class="tam-indicador">Pendientes</p>
                    <h3 class="text-center">{{ prodPendiente || 0 }}</h3>
                  </div>
                  <div class="col-md-4 text-center deleteMargin">
                    <p class="tam-indicador">Inacti / Rechaz</p>
                    <h3 class="text-center">{{ prodRechazados || 0 }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Líneas de producto-->
      <div class="lineas-productos pb-2">
        <span class="pb-3">Líneas de producto</span>
       
        <div class="categorias-lineas">
          <span
            (click)="seleccionarLinea('0', -1)"
            class="cursorPointer"
            [ngClass]="{ 'categorias-lineas-selected': lineaSeleccionada === '0' }">
            Todos
          </span>
          <span
            *ngFor="let linea of lineas_productos; index as i"
            (click)="seleccionarLinea(linea._id, i)"
            class="cursorPointer"
            [ngClass]="{ 'categorias-lineas-selected': lineaSeleccionada === linea._id }">
            {{ linea.nombre }}
          </span>
        </div>
      </div>
      <!--Productos-->
      <div class="products-container">
        <div
          class="col-12 d-flex flex-row p-0 mb-2"
          style="
            display: flex;
            grid-column-gap: 10px;
            column-gap: 10px;
            overflow-x: scroll !important;
            overflow: hidden;
          ">
          <span
           [ngClass]="{ 'filtro--selected': filtro_seleccionados === 'Todos'}"
            class="aprobado margin-left d-flex flex-row align-items-center justify-content-center text-secondary pointer"
            (click)="filtrarObj('Todos'); filtro_seleccionados = 'Todos'">
            <div class="convencion mr-1"></div>
            Todos
          </span>
          <span
          [ngClass]="{ 'filtro--selected': filtro_seleccionados === 'puntos'}"
          class="pendiente d-flex flex-row align-items-center justify-content-center text-secondary mx-4 pointer"
          (click)="filtrarObj('puntos'); filtro_seleccionados = 'puntos'">
            <div class="convencion mr-1 producto-pf"></div>
            Puntos FT
          </span>
          <span
          [ngClass]="{ 'filtro--selected': filtro_seleccionados === 'Aceptado'}"
            class="aprobado d-flex flex-row align-items-center justify-content-center text-secondary pointer"
            (click)="filtrarObj('Aceptado'); filtro_seleccionados = 'Aceptado'">
            <div class="convencion mr-1 producto-aprobado"></div>
            Aprobado
          </span>
          <span
          [ngClass]="{ 'filtro--selected': filtro_seleccionados === 'Pendiente'}"
            class="pendiente d-flex flex-row align-items-center justify-content-center text-secondary mx-4 pointer"
            (click)="filtrarObj('Pendiente'); filtro_seleccionados = 'Pendiente'">
            <div class="convencion mr-1 producto-pendiente"></div>
            Pendiente
          </span>
          <span
          [ngClass]="{ 'filtro--selected': filtro_seleccionados === 'Rechazado'}"
            class="rechazado d-flex flex-row align-items-center justify-content-center text-secondary pointer"
            (click)="filtrarObj('Rechazado'); filtro_seleccionados = 'Rechazado'">
            <div class="convencion mr-1 producto-rechazado"></div>
            Inactivo / Rechazado
          </span>
        </div>
        <div class="productos-contenedor top-productos">
          <div class="product-card" *ngFor="let producto of prods_filtrados; let i = index">
            <div
              [ngClass]="{
                'producto-rechazado':
                  producto.estadoActualizacion === 'Rechazado' || producto.estadoActualizacion === 'Inactivo',
                'producto-pendiente': producto.estadoActualizacion === 'Pendiente'
              }">
              <span>
                SKU Distribuidor:
                <br />
                {{ producto?.codigo_distribuidor_producto || '' }}
              </span> 
             
            </div>
            <div>
              <div class="space" *ngIf="producto.mostrarPF" >
                <span>{{ producto?.precios[0].puntos_ft_unidad || 0 }} puntos ft</span>
              </div>
              <div
                *ngIf="producto?.prodDescuento && producto?.prodPorcentajeDesc > 0"
                class="container-discount discount-indicator">
                <span>-{{ producto.prodPorcentajeDesc }}%</span>
              </div>
              <div
                *ngIf="producto?.prodPedido"
                class="absolute-indicator order-indicator">
                <img src="./assets/img/icons/box-open.png" alt="Icon box" title="Encargo bajo pedido">
              </div>
              <div
                class="absolute-indicator bio-indicator"
                *ngIf="producto?.prodBiodegradable">
                <img src="./assets/img/icons/recycle.png" alt="Icon box" title="Producto biodegradable">
              </div>
              <div class="container-img" >
                <img *ngIf ='producto.fotos[0]' src="{{ producto.fotos[0]}}" alt="{{ producto?.nombre || 'Producto' }}" />
                <img  *ngIf ='!producto.fotos[0]' src="{{product_placeholder}}" alt="{{ producto?.nombre || 'Producto' }}" />
              </div>
              <span>{{ producto?.nombre }}</span>
              <div class="space" *ngIf="producto?.prodDescuento && producto?.prodPorcentajeDesc">
                <span class="color-previous-price">Precio ant.</span>
                <span class="color-previous-price previous-line">{{ producto?.precios[0]?.precio_unidad | currency: 'COP':'symbol-narrow':'1.0-2' }}</span>
              </div>
              <div class="space">
                <span>Precio und.</span>
                <span class="price" *ngIf="producto?.prodDescuento && producto?.prodPorcentajeDesc">
                  {{ ((producto?.precios[0]?.precio_unidad - (producto?.precios[0]?.precio_unidad * (producto?.prodPorcentajeDesc / 100))) | currency: 'COP':'symbol-narrow':'1.0-0') }}
                </span>
                <span class="price" *ngIf="!producto?.prodDescuento">
                  {{ producto?.precios[0].precio_unidad | currency : '$ ' : 'symbol' : '1.0-0' }}
                </span>
              </div>
              <div class="space">
                <span>Precio {{ producto?.precios[0].unidad_medida }}</span>
                <span *ngIf="!producto?.prodDescuento">
                  {{
                    producto?.precios[0].precio_unidad / producto?.precios[0].cantidad_medida
                      | currency : '$' : 'symbol' : '1.2-2'
                  }}
                </span>
                <span *ngIf="producto?.prodDescuento && producto?.prodPorcentajeDesc">
                  {{
                    (producto?.precios[0]?.precio_unidad - (producto?.precios[0]?.precio_unidad * (producto?.prodPorcentajeDesc / 100))) / producto?.precios[0]?.cantidad_medida
                    | currency: 'COP':'symbol-narrow':'1.0-2'
                  }}
                </span>
              </div>
              <div class="space" [ngClass]="{'separator-indicators': producto.prodPedido || producto.prodBiodegradable}">
                <span>Caja x{{ producto?.precios[0].und_x_caja || 1 }}</span>
                <span *ngIf="!producto?.prodDescuento">{{ producto?.precios[0].precio_caja | currency : '$' : 'symbol' : '1.0-0' }}</span>
                <span *ngIf="producto?.prodDescuento && producto?.prodPorcentajeDesc">
                  {{ calcularPrecioCaja(producto) | currency: 'COP':'symbol-narrow':'1.0-0' }}
                </span>
              </div>
              <div
                class="space"
                *ngIf="!producto?.precios[0].inventario_unidad || producto?.precios[0].inventario_unidad == 0">
                <span class="text-danger text-center w-100 font-weight-800 mt-2">Sin inventario</span>
              </div>
              <div class="botones-contenedor">
                <button class="btn-purple-border" (click)="detalleFlotante(producto, i)">Editar</button>
                <button class="btn-white btn-orange " *ngIf=" producto.estadoActualizacion === 'Pendiente'" 
                (click)="eliminarProducto(producto, i)">Eliminar</button>

                <!--<button class="btn-white" [routerLink]="'/portafolio/editar-producto/' + producto._id">Editar</button>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Estado vacío de productos filtrados-->
      <div class="products-container top-productos" *ngIf="!prods_filtrados || prods_filtrados.length == 0">
        <div class="row text-center">
          <div class="col-12" style="margin-top: 3rem;">
            <img
              src="../../../assets/img/icon-warning-amarillo.png"
              class="img-estado-vacio"
              alt="ícono para indicar estado vacío" />
          </div>
          <div class="col-12">
            <h5>No se encontraron productos</h5>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
