import { Component, OnInit } from '@angular/core';
import { SeleccionarCiudadesComponent } from 'src/app/modal/seleccionar-ciudades/seleccionar-ciudades.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { faChevronLeft, faTruck, faMapPin } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { Router } from '@angular/router';
import { RestService } from 'src/app/services/rest/rest.service';
import { Store } from '@ngxs/store';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
@Component({
  selector: 'app-contenido-distribuidores',
  templateUrl: './contenido-distribuidores.component.html',
  styleUrls: ['./contenido-distribuidores.component.css'],
})
export class ContenidoDistribuidoresComponent implements OnInit {
  public distribuidor_placeholder = '../../../../assets/img/icon-organizacion.png';
  /** ID organizacion */
  public id_organizacion = this.auth.user_organizacion?._id;
  /** ID distribuidor */
  public id_distribuidor = 'this.authService.user_distribuidor?._id';
  // Data distribuidor
  public distribuidor: any;
  public promedio_ventas = 0;
  public sillas = 0;
  public total_trabajadores = 0;
  public total_productos = 0;
  public total_establecimientos = 0;
  public productos_distribuidor: any;
  public data_top_productos: any;
  // Calificaicon del distribuidor
  public calificacion = {
    abastecimiento: 0,
    ponderado: 0,
    precio: 0,
    puntualidad_entrega: 0,
  };
  /** Flag para solo intentar crear un room una vez */
  public flag_intento_crear_room = false;
  /** Modal generico */
  public modal_carga?: NgbModalRef;
  /** Modal configuraciones */
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
  };

  faChevronLeft = faChevronLeft;
  faStar = faStar;
  faTruck = faTruck;
  faMapPin = faMapPin;
  data: any = [];
  ciudades: any;
  vacio = true;
  distribuidoresData: any;

  constructor(
    private rest: RestService,
    private modalService: NgbModal,
    private auth: AuthService,
    private router: Router,
    private ngxsStore: Store
  ) {}

  ngOnInit(): void {
    this.id_organizacion = this.ngxsStore.snapshot().auth.user_organizacion?._id;
    //this.selecionarCiudad();
  }

  async onDetalleDistribuidor(id: any) {
    this.id_distribuidor = id;
    if (this.id_distribuidor != null && this.id_distribuidor != undefined) {
      this.flag_intento_crear_room = false;
      this.getDistribuidor();
    }
    this.vacio = false;
  }

  /**
   * Recupera la información de un distribuidor y sus indicadores
   */
  private async getDistribuidor() {
    this.sillas = 0;
    this.total_trabajadores = 0;
    this.total_productos = 0;
    this.total_establecimientos = 0;
    this.promedio_ventas = 0;
    await this.rest
      .getJWT(`portafolio-organizacion/distribuidor/${this.id_distribuidor}`)
      .toPromise()
      .then((resp: any) => {
        resp = resp[0];
        console.log('Ejemplo', resp);
        this.sillas = resp.sillas;
        this.total_establecimientos = resp.total_establecimientos;
        this.total_trabajadores = resp.trabajadores;
        this.total_productos = resp.total_productos;
        this.promedio_ventas = resp.promedio_ventas;
        this.distribuidor = resp.distribuidor;
        console.log('this.distribuidor', resp.distribuidor);
        this.getCalificacionesDistribuidor();
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
  }

  /**
   * Recupera las calificaciones por item de un distribuidor
   */
  getCalificacionesDistribuidor() {
    this.rest
      .getJWT(`calificacion/${this.id_distribuidor}`)
      .toPromise()
      .then((calificacion: any) => {
        this.calificacion.abastecimiento = Math.round(calificacion.calificacion.abastecimiento * 100) / 100;
        this.calificacion.precio = Math.round(calificacion.calificacion.precio * 100) / 100;
        this.calificacion.puntualidad_entrega = Math.round(calificacion.calificacion.puntualidad_entrega * 100) / 100;
      });
  }

  /**
   * Consulta el room establecido para la relación entre Dist. y Org.
   * Si el room existe navega a el, si no, crea uno nuevo y luego navega a el
   */
  async getChatRoomId() {
    await this.rest
      .getJWT(
        'vinculacion/distribuidor/' + this.id_distribuidor + '/organizacion/' + this.id_organizacion + '/get_room/'
      )
      .toPromise()
      .then((resp: any) => {
        /** Si el room está creado navega a el, si no lo está, lo crea */
        if (resp != null && resp != undefined) {
          this.navigateToRoom(resp._id);
        } else {
          /** Si ya habia intentado crear el room no lo intenta mas */
          if (this.flag_intento_crear_room == false) {
            this.createNewRoom();
          } else {
            return;
          }
        }
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
  }

  /**
   * Navega al room correspondiente al distribuidor y organizacion seleccionados
   * @param room ID de la sala de chat asociada al dist. y a la org.
   */
  navigateToRoom(room: any) {
    this.router.navigate([
      '/chat/room/' +
        room +
        '/distribuidor/' +
        this.id_distribuidor +
        '/organizacion/' +
        this.id_organizacion +
        '/organizacion',
    ]);
  }

  /**
   * Crea una room asociada a un distribuidor y organizacion cuando no existe
   */
  createNewRoom() {
    /** Cmabia el flag para que no permita se intente crear otra vez la sala */
    this.flag_intento_crear_room = true;
    /** Crea el room */
    const config_new_room = {
      codigo_distribuidor: this.id_distribuidor,
      codigo_organizacion: this.id_organizacion,
    };
    this.rest
      .postJWT('vinculacion/distribuidor/organizacion/', config_new_room)
      .toPromise()
      .then((resp: any) => {
        /** Ya creada la sala vuelve a intentar entrar */
        this.getChatRoomId();
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
  }

  onPortafolioDistribuidor(id: any) {
    this.router.navigate([`/portafolio-distribuidor-organizacion/${id}`]);
  }

  async fetchDistribuidores() {
    const respDistribuidores: any = await this.rest.postJWT(`distribuidor/ciudades`, this.data).toPromise();
    this.distribuidoresData = respDistribuidores;
  }

  /**
   * Lanza un modal para seleccionar el punto de entrega sobre el que se
   * van a gestionar las solicitudes
   */
  selecionarCiudad() {
    const modalRef = this.modalService.open(SeleccionarCiudadesComponent, this.ngbModalOptions);
    modalRef.componentInstance.ciudad = this.data;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.data = receivedEntry;
      this.fetchDistribuidores();
    });
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modal_carga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {};
  }
}
