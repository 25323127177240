<app-header-organizacion></app-header-organizacion>
<div id="content">
  <div class="row">
    <div class="col-12 titulo">
      <div class="btn-volver" [routerLink]="'/portafolio-distribuidor-organizacion/' + id_distribuidor">
        <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
        Volver
      </div>
      <h4>{{ producto?.nombre }}</h4>
    </div>
  </div>
  <div class="contendor-producto">
    <div class="contenedor-fotos">
      <img src="{{ foto_seleccionada }}" alt="" />
      <div>
        <img src="{{foto}}" alt="foto producto" class="cursorPointer" *ngFor="let foto of producto?.fotos"
          (click)="foto_seleccionada = foto" />
      </div>
    </div>
    <div>
      <!-- Precios -->
      <div class="codigos">
        <div class="card-body-ft">
          <span>Código FEAT</span>
          <span>{{ producto?.codigo_ft }}</span>
        </div>
        <div class="card-body-ft">
          <span>Código Organizacion</span>
          <span>{{ producto?.codigo_organizacion_producto }}</span>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="precios">
            <h6 class="m-0">Precios</h6>
            <div class="row">
              <div class="col-sm-4">
                <p>Precios por unidad promedio:</p>
                <span id="precios">
                  {{ producto?.precio_unidad | currency: '$ ':'symbol': '1.0-0' }}
                </span>
              </div>
              <div class="col-sm-4">
                <p>Precios por caja promedio:</p>
                <span>
                  {{ producto?.precio_caja | currency: '$ ':'symbol': '1.0-0' }}
                </span>
              </div>
              <div class="col-4">
                <p>Precios por unidad de medida promedio:</p>
                <span *ngIf="producto?.cantidad_medida != null">
                  {{ producto?.precio_unidad / producto?.cantidad_medida
                  | currency: '$ ':'symbol': '1.0-0' }}
                </span>
                <span *ngIf="producto?.cantidad_medida == null">
                  0
                </span>
              </div>
            </div>
            <h6 class="m-0">Información del producto</h6>
            <div class="row">
              <div class="col-sm-8">
                <p>Nombre del producto:</p>
                <span>{{ producto?.nombre }}</span>
              </div>
              <div class="col-sm-4">
                <p>Marca:</p>
                <span>{{ producto?.marca_producto }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <p>Organización:</p>
                <span>{{organizacion_nombre}}</span>
              </div>
              <div class="col-sm-4">
                <p>Categoria:</p>
                <span>{{ producto?.categoria_producto }}</span>
              </div>
              <div class="col-sm-4">
                <p>Linea de producto:</p>
                <span>{{ producto?.linea_producto }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <p>Unidad de medida:</p>
                <span>{{ producto?.unidad_medida }}</span>
              </div>
              <div class="col-sm-4">
                <p>Cantidad de medida:</p>
                <span>{{ producto?.cantidad_medida }}</span>
              </div>
              <div class="col-sm-4">
                <p>Presentación por caja:</p>
                <span>{{ producto?.und_x_caja }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <p>Descripción:</p>
                <span>
                  {{ producto?.descripcion }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>