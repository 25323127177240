import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PuntoEntrega } from 'src/app/models/punto_entrega.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { SimpleComponent } from '../simple/simple.component';

@Component({
  selector: 'app-seleccionar-ciudades',
  templateUrl: './seleccionar-ciudades.component.html',
  styleUrls: ['./seleccionar-ciudades.component.css'],
})
export class SeleccionarCiudadesComponent implements OnInit {
  /** ciudades y departamentos */
  public ciudades: any;
  public ciudadSelect: any = '';
  public departamentos: any;
  public departamento_seleccionado: any = '';
  public deptos = false;
  public ciudades_seleccionadas: any = [];
  /** Referencias a íconos FontAwesome para la UI */
  public faTimes = faTimes;
  public faTimesCircle = faTimesCircle;
  /** Lista de puntos de entrega */
  public puntos_entrega: PuntoEntrega[] = [];
  public punto_seleccionado?: PuntoEntrega;
  /** Modal generíco cargando... */
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
  };
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() callback = () => {
    console.log('Close');
  };
  @Input() public ciudad: any;

  constructor(
    private rest: RestService,
    private auth: AuthService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private router: Router
  ) {}

  async ngOnInit() {
    await this.fetchDepartamentos();
    this.ciudad.forEach((element: string) => {
      this.ciudades_seleccionadas.push(element);
    });
  }

  /**
   * Recupera todos los departamentos de Colombia
   */
  fetchDepartamentos() {
    this.rest
      .getImagine('countrylibrary/es/SA/COL/')
      .toPromise()
      .then((resp: any) => {
        this.departamentos = resp.data;
        this.deptos = true;
      })
      .catch(() => {
        this.mostrarMensajeError();
      });
  }

  /**
   * Recupera las ciudades según departamento seleccionado
   */
  fetchCiudades() {
    const code = this.departamento_seleccionado;
    this.rest
      .getImagine(`countrylibrary/es/SA/COL/${code}/`)
      .toPromise()
      .then((resp: any) => {
        this.ciudades = resp.data;
      })
      .catch(() => {
        this.mostrarMensajeError();
      });
  }

  /**
   * Agrega la ciudad al array de seleccionadas
   */
  agregarCiudad() {
    const idx = this.ciudades_seleccionadas.indexOf(this.ciudadSelect);
    if (idx == -1 && this.ciudadSelect != '') {
      this.ciudades_seleccionadas.push(this.ciudadSelect);
    }
  }

  /**
   * Quita la ciudad del array de seleccionados
   * @param ciudad a retirar
   */
  quitarCiudad(ciudad: string) {
    const idx = this.ciudades_seleccionadas.indexOf(ciudad);
    this.ciudades_seleccionadas.splice(idx, 1);
  }

  /**
   * Cierra el modal
   */
  close() {
    if (this.ciudades_seleccionadas.length > 0) {
      localStorage.setItem('ciudades', this.ciudades_seleccionadas);
      this.activeModal.close();
    } else {
      this.activeModal.close();
      this.router.navigate(['/inicio-organizacion']);
    }
  }

  /**
   * Cierra el modal y asigna el punto de entrega seleccionado
   */
  done() {
    if (this.ciudades_seleccionadas.length > 0) {
      this.passEntry.emit(this.ciudades_seleccionadas);
      this.callback();
      this.close();
    } else {
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Debes escoger al menos una ciudad para continuar.';
      modalRef.componentInstance.btn_msg = 'Volver';
    }
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
  }
}
