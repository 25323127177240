<div id="contenedor" class="container-fluid row mx-0 px-0">
  <div class="container-busqueda px-0 overflow-hidden">
    <section class="buscador-container w-100 pt-0 mb-3">
      <span class="titulo d-flex align-items-center">Encuentra un nuevo distribuidor</span>
      <div class="barra-busqueda">
        <div class="d-flex flex-row autocompletado-busqueda px-0 h-100 w-100">
          <button class="col-2 col-sm-1 p-0 boton-buscar d-flex justify-content-center">
            <fa-icon class="fa-icon-search m-0" [icon]="faSearch" size="lg" [styles]="{ color: '#41403E' }"></fa-icon>
          </button>
          <form class="example-form col-10 col-sm-11 pr-0">
            <mat-form-field class="example-full-width">
              <input #escogido type="text" placeholder="Buscar por nombre del distribuidor."
                aria-label="Buscar distribuidor" matInput [formControl]="mySearch" [matAutocomplete]="auto"
                class="w-100 h-100 input-busqueda pr-0" />
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" [displayWith]="itemDisplayFn"
                class="w-100" (optionSelected)="verDetalleDistribuidor($event)">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{ option?.nombre }} | {{ option?.tipo }} | {{ option?.ciudad }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </form>
        </div>
      </div>
      <div class="punto-entrega-container rounded overflow-hidden p-0 pr-3">
        <div class="punto-entrega-icono col-1">
          <img src="../../assets/img/icons/icon-pin-map.png" alt="icono de un pin para señalar un sitio en un mapa"
            srcset="" />
        </div>
        <span class="text-left punto-entrega-texto col-6 col-sm-7 col-md-8 p-0" id="point">
          <span class="texto-novisible">Punto de entrega: </span>
          <span>{{ punto_entrega?.nombre || 'Selecciona un punto' }}</span>
        </span>
        <span (click)="selecionarPunto()" class="punto-entrega-cambiar col-5 col-sm-4 col-md-3 text-center m-0">
          Cambiar
        </span>
      </div>
      <div (click)="onFiltrar()" class="boton-filtro">
        <fa-icon [icon]="faFilter" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
        <span>Filtrar</span>
      </div>
      <div class="categorias">
        <div (click)="todos_filtros_desactivados = !todos_filtros_desactivados" (click)="handleClickCategoria(true)">
          <img alt="Todos" srcset=""
            [src]="todos_filtros_desactivados ? 'assets/img/icons/icono-caja-on.png' : 'assets/img/icons/icono-caja-off.png'" />
          <span [style]="todos_filtros_desactivados ? 'color: #8E6FF7;' : 'color: #41403E;'">&nbsp;Todos</span>
        </div>
        <div *ngFor="let cat of categorias_filtro_distribuidores; index as i;"
          (click)="categorias_estado[i] = !categorias_estado[i]" (click)="handleClickCategoria(false)">
          <img
            [src]="categorias_estado[i] ? 'assets/iconos-org/' + iconosCategorias[cat._id] + '-selected.png' : 'assets/iconos-org/' + iconosCategorias[cat._id] + '.png'"
            alt="icono {{cat.nombre}}" srcset="" />
          <span [style]="categorias_estado[i] ? 'color: #8E6FF7;' : 'color: #41403E;'">&nbsp;{{cat.nombre}}</span>
        </div>
      </div>
    </section>
    <section class="sin-resultados" *ngIf="!distribuidores?.length">
      <img src="../../assets/img/icon-warning-amarillo.png" alt="Icono de advertencia" />
      <span>Nada de nada</span>
      <span>Tu búsqueda no arrojó resultados</span>
    </section>
    <section class="distribuidores-encontrados">
      <div class="card-container card-distribuidor" *ngFor="let distribuidor of distribuidores; index as i">
        <div>
          <button [id]="'btn_vincular_' + i" class="btn_vincular" [disabled]="vinculado[i]"
            (click)="solicitarVinculacion(i)">
            Solicitar vinculación
          </button>
        </div>
        <div [routerLink]="['/distribuidores/novinculado', distribuidor?._id]" class="cursorPointer">
          <div class="header-card">
            <div>
              <img src="../../assets/img/icons/icon-pin-map.png" alt="Icono de un pin para señalar un punto en un mapa"
                srcset="" />
              <span>{{ distribuidor.ciudad }}</span>
            </div>
            <div>
              <fa-icon [icon]="faStar" [styles]="{ color: '#41403E' }" class="mr-1" id="fa-icon"></fa-icon>
              <span>{{ distribuidor.ranking | number: '1.1-1' }}</span>
            </div>
          </div>
          <div class="price-shipping">
            <div class="w-100 d-flex flex-row justify-content-start align-items-end">
              <img src="../../assets/img/icons/icon-bus.png" alt="Logo de un bus para indicar tiempo de transporte"
                srcset="" class="m-0 mr-2" />
              <span>{{ distribuidor.tiempo_entrega }}</span>
            </div>
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center w-100 mb-2">
            <img src="{{ distribuidor.logo || product_placeholder }}" alt="dist-logo" srcset="" class="img-logo py-1" />
            <p class="mb-2">
              {{ distribuidor.nombre }}
            </p>
          </div>
          <span class="etiqueta-categoria etiqueta-categoria-principal">{{ distribuidor.tipo }}</span>
          <div class="scroll_desc pb-2 mt-3">
            <p class="descripcion_dist">
              {{ distribuidor.descripcion }}
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="solicitudes-container pr-0">
    <div class="solicitudes w-100 mb-0" routerLink="/solicitudes-distribuidor">
      <div class="mr-3">
        <fa-icon [icon]="faArrowDown" class="text-center" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
        <fa-icon [icon]="faBoxOpen" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
      </div>
      <div class="solicitud">
        <span>{{ distVinculadosPendientes.length }}</span>
        <span>Solicitudes distribuidores</span>
      </div>
    </div> 
    <div class="w-100 container-mis-distribuidores" *ngIf="distVinculadosAprobados.length > 0; else mostrarError">
      <div routerLink="/mis-distribuidores"
        class="d-flex justify-content-between w-100 p-3 border-bottom cursorPointer">
        <span class=" mr-2">Mis distribuidores</span>
        <fa-icon [icon]="faAngleRight" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
      </div>
      <div class="d-flex row w-100 m-0 pb-3 cursorPointer border-bottom tarjetas-distribuidores"
        *ngFor="let item of distVinculadosAprobados"
        routerLink='/distribuidores/{{item._id}}'>
        <div class="card-logo">
          <img src="{{ item.logo || product_placeholder }}" alt="dist-logo" srcset="" />
        </div>
        <div class="card-nombre d-flex flex-row justify-content-between">
          <span>{{ item.nombre }}</span>
          <span>
            <fa-icon [icon]="faStar" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
            <span> {{item.ranking}}</span>
          </span>
        </div>
        <div class="card-categoria">
          <span class="categoria">{{ item.tipo }}</span>
        </div>
        <div class="card-compras text-secondary">
          <span>{{item.pedidos || 0}} compra{{item.pedidos > 1 ? 's' : ''}} al dist.</span>
        </div>
      </div>
    </div>
    <ng-template #mostrarError>
      <div class="container-mis-distribuidores">
        <div class="mis-distribuidores w-100 p-3">
          <div class="d-flex justify-content-center">
            <img src="../../../assets/img/icon-warning-amarillo.png" style="width: 100px"
              alt="Icono de una banana como señal de advertencia" />
          </div>
          <div class="d-flex justify-content-center">
            <p class="text-center">No tienes distribuidores vinculados con este punto de entrega</p>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>