import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faChevronLeft, faPencilAlt, faTimes, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmacionComponent } from 'src/app/modal/confirmacion/confirmacion.component';
import { EditarPuntosAsignadosComponent } from 'src/app/modal/editar-puntos-asignados/editar-puntos-asignados.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { PuntoEntrega } from 'src/app/models/punto_entrega.model';
import { Trabajador } from 'src/app/models/trabajador.model';
import { PlacesService } from 'src/app/services/places/places.service';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-solicitud',
  templateUrl: './solicitud.component.html',
  styleUrls: ['./solicitud.component.css'],
})
export class SolicitudComponent implements OnInit {
  /** Iconos FontAwesome para usar en el template */
  faTimes = faTimes;
  faChevronLeft = faChevronLeft;
  faTimesCircle = faTimesCircle;
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;

  constructor(
    private activatedRoute: ActivatedRoute,
    private restService: RestService,
    private modalService: NgbModal,
    private router: Router,
    public places: PlacesService
  ) {}

  /** Variable para tener los datos del trabajador y poder editarlos */
  public trabajador!: Trabajador;

  /** Arreglo de los puntos de venta asociados al trabajador */
  public puntos_entrega: PuntoEntrega[] = [];

  /** Variables para el manejo de la lista de departamentos y ciudades */
  id_dpto = 0;
  ciudades: string[] = [];

  ngOnInit(): void {
    if (this.places.places_colombia.length == 0) {
      this.places.getPlacesFromServer();
    }
    const id_trab: string = this.activatedRoute.snapshot.params.id;
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    /**
     * Trae los datos del trabajador
     */
    this.restService
      .getJWT('trabajador/' + id_trab)
      .toPromise()
      .then((resp: any) => {
        this.trabajador = new Trabajador(
          resp.nombres,
          resp.apellidos,
          resp.correo,
          resp.clave,
          resp.telefono,
          resp.celular || '',
          resp.pais,
          resp.departamento,
          resp.ciudad,
          resp.tipo_documento.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
          resp.numero_documento.toString(),
          resp.tipo_trabajador,
          resp.solicitud_vinculacion,
          resp.show_slides,
          resp.usuario_horeca,
          resp.puntos_entrega || [],
          undefined,
          undefined,
          resp._id
        );
        console.log(this.trabajador);
        this.trabajador.telefono = resp.telefono;
        let i = 0;
        let id_punto_actual = '';
        for (i = 0; i < (this.trabajador.puntos_entrega?.length || 0); i++) {
          id_punto_actual = this.trabajador.puntos_entrega?.[i] || '';
          /**
           * Trae la información de los puntos de venta asociados a este trabajador
           */
          this.restService
            .getJWT('punto_entrega/' + id_punto_actual)
            .toPromise()
            .then((resp_2: any) => {
              //Por temas de optimización, esta declaración iría por fuera del for
              //Pero, por temas de asincronía por la petición HTTP, se debe declarar
              //e inicializar una variable por cada promesa dentro del mismo callback
              const nuevo_punto: PuntoEntrega = new PuntoEntrega(
                resp_2.usuario_horeca,
                resp_2.nombre,
                resp_2.pais,
                resp_2.departamento,
                resp_2.ciudad,
                resp_2.telefono,
                resp_2.direccion,
                resp_2.informacion_contacto,
                resp_2.sillas,
                resp_2.domicilios,
                resp_2.numero_trabajadores,
                resp_2.tipo_aprobador,
                resp_2.dias_atencion,
                resp_2.horario,
                resp_2._id
              );
              this.puntos_entrega.push(nuevo_punto);
            });
        }
      })
      .catch(() => {
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg =
          'No fue posible recuperar la información de este trabajador. Por favor intenta de nuevo más tarde.';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigate(['/cuenta']);
        };
      });
  }

  /**
   * Editar los puntos de entrega asociados al trabajador
   */
  editarPuntosEntrega() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
    };

    const modalRef = this.modalService.open(EditarPuntosAsignadosComponent, ngbModalOptions);
    modalRef.componentInstance.puntos_entrega_asignados = this.puntos_entrega;
    modalRef.componentInstance.id_trabajador = this.trabajador._id;
    modalRef.componentInstance.close_callback = () => {
      let i = 0;
      let id_punto_actual = '';
      this.puntos_entrega = [];
      for (i = 0; i < (this.trabajador.puntos_entrega?.length || 0); i++) {
        id_punto_actual = this.trabajador.puntos_entrega?.[i] || '';
        //Trae la información de los puntos de venta asociados a este trabajador
        this.restService
          .getJWT('punto_entrega/' + id_punto_actual)
          .toPromise()
          .then((resp_2: any) => {
            //Por temas de optimización, esta declaración iría por fuera del for
            //Pero, por temas de asincronía por la petición HTTP, se debe declarar
            //e inicializar una variable por cada promesa dentro del mismo callback
            const nuevo_punto: PuntoEntrega = new PuntoEntrega(
              resp_2.usuario_horeca,
              resp_2.nombre,
              resp_2.pais,
              resp_2.departamento,
              resp_2.ciudad,
              resp_2.telefono,
              resp_2.direccion,
              resp_2.informacion_contacto,
              resp_2.sillas,
              resp_2.domicilios,
              resp_2.numero_trabajadores,
              resp_2.tipo_aprobador,
              resp_2.dias_atencion,
              resp_2.horario,
              resp_2._id
            );
            this.puntos_entrega.push(nuevo_punto);
          });
      }
    };
  }

  /**
   * Actualiza los datos del trabajador en la base de datos
   */
  actualizarTrabajador() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    const obj_aux = {
      solicitud_vinculacion: 'Aprobado',
      tipo_trabajador: this.trabajador.tipo_trabajador,
    };
    this.restService
      .putJWT('trabajador/' + this.trabajador._id, obj_aux)
      .toPromise()
      .then(() => {
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../../assets/img/icon-check-verde.png';
        modalRef.componentInstance.title = '¡Genial!';
        modalRef.componentInstance.msg =
          '¡El trabajador fue aprobado con los datos ingresados! Ya puede ingresar a Feat con el correo y contraseñas indicados.';
        modalRef.componentInstance.btn_msg = 'Listo';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigate(['/cuenta']);
        };
      })
      .catch(() => {
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg =
          'No fue posible aprobar a este trabajador. Por favor intenta de nuevo más tarde.';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          //this.router.navigate(["/cuenta"]);
        };
      });
  }

  /**
   * Lanza un diálogo de confirmación. Si el usuario selecciona
   * que sí, se desactiva al trabajador actual. Si no, se cierra
   * el diálogo sin hacer nada
   */
  desactivarTrabajador() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
    };

    const modalRef = this.modalService.open(ConfirmacionComponent, ngbModalOptions);
    modalRef.componentInstance.msg =
      '¿Estás seguro de rechazar a este trabajador? Es una acción que no se puede deshacer.';
    modalRef.componentInstance.btn_msg_no = 'Cancelar';
    modalRef.componentInstance.btn_msg_yes = 'Si, rechazar';
    modalRef.componentInstance.callback_no = () => {};
    modalRef.componentInstance.callback_yes = () => {
      const obj_aux: any = {
        solicitud_vinculacion: 'Rechazado',
      };
      this.restService
        .putJWT('trabajador/' + this.trabajador._id, obj_aux)
        .toPromise()
        .then(() => {
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          modalRef.componentInstance.msg = 'El trabajador fue rechazado correctamente.';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            this.router.navigate(['/cuenta']);
          };
        })
        .catch(() => {
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg =
            'El trabajador no pudo ser rechazado por un error inesperado. Intenta de nuevo más tarde.';
          modalRef.componentInstance.btn_msg = 'Volver';
          modalRef.componentInstance.close_callback = () => {
            //this.router.navigate(["/cuenta"]);
          };
        });
    };
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateOnlyText(event: any) {
    const keyCode = event.keyCode;
    if (keyCode >= 48 && keyCode <= 57) {
      event.preventDefault();
    }
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   * */
  validateNumber(event: any) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || excludedKeys.includes(keyCode))) {
      event.preventDefault();
    }
  }
}
