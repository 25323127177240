import { Component } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PopUpSaldosPromosComponent } from '../modal/pop-up-saldos-promos/pop-up-saldos-promos.component';
import { AuthService } from '../services/auth/auth.service';

@Component({
  selector: 'app-saldos-promos-distribuidor',
  templateUrl: './saldos-promos-distribuidor.component.html',
  styleUrls: ['./saldos-promos-distribuidor.component.css'],
})
export class SaldosPromosDistribuidorComponent {
  /** Referencias a iconos FontAwesome para mostrar en la UI */
  public faChevronLeft = faChevronLeft;

  /** Para controlar la navegación entre Crear nuevo y Creados */
  public active = 1;

  constructor(private modalService: NgbModal, private auth: AuthService) {}

  /** Opciones del pop-up a abrir */
  ngbModalOptions: NgbModalOptions = {
    centered: true,
  };
  async validatePermissions() {
    // eslint-disable-next-line prefer-const
    let blockUser = await this.auth.validatePermissionsUser();
    return blockUser;
  }
  /**
   * Llama al modal de crear promoción con los
   * datos necesarios según el diseño
   */
  async popUpPromos() {
    // eslint-disable-next-line prefer-const
    let validador = await this.validatePermissions();
    if (validador) {
      const modalRef = this.modalService.open(PopUpSaldosPromosComponent, this.ngbModalOptions);
      modalRef.componentInstance.title = 'Crea una promoción';
      modalRef.componentInstance.instrucciones =
        'Selecciona hasta 3 productos en la tabla para armar el combo y poder crear la promoción';
      modalRef.componentInstance.img_src = '../../../assets/img/pop-up-saldos-promos-dist/pop-up-promos-dist.jpg';
    }
  }

  /**
   * Llama al modal de crear saldo con los
   * datos necesarios según el diseño
   */
  async popUpSaldos() {
    // eslint-disable-next-line prefer-const
    let validador = await this.validatePermissions();
    if (validador) {
      const modalRef = this.modalService.open(PopUpSaldosPromosComponent, this.ngbModalOptions);
      modalRef.componentInstance.title = 'Crea un saldo';
      modalRef.componentInstance.instrucciones =
        'Selecciona en la tabla “Poner en saldo” al producto al que le desees crear un nuevo saldo.';
      modalRef.componentInstance.img_src = '../../../assets/img/pop-up-saldos-promos-dist/pop-up-saldos-dist.jpg';
    }
  }
}
