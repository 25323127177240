import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class DistribuidorService {
  constructor(private http: HttpClient, private authService: AuthService) {}
  private headers = new HttpHeaders({ Authorization: this.authService.token });

  /**
   *
   * @param id identificación del distribuidor
   * @returns
   */
  public getDistribuidorData(id: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/distribuidor/${id}`, {
      headers: this.headers,
    });
  }

  /**
   *
   * @param id identificación del distribuidor
   * @returns
   */
  public getInformeClientes(id: string, anio: any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/distribuidor/${id}/informe-clientes/${anio}`, {
      headers: this.headers,
    });
  }

  /**
   *
   * @param id identificación del producto
   * @returns
   */
  public getProductoData(id: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/producto/${id}`, {
      headers: this.headers,
    });
  }

  /**
   *
   * @param id identificación del producto
   * @returns
   */
  public getDistribuidorProductosData(id: string | null): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/productos_por_distribuidor/distribuidor/${id}`, {
      headers: this.headers,
    });
  }

  /**
   *
   * @param estado estado de la vinculación del distribuidor
   * @param puntoId identificación del punto de entrega
   * @returns
   */
  public getDistribuidoresVinculados(estado: string, puntoId: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/distribuidores_vinculados/${puntoId}/${estado}`, {
      headers: this.headers,
    });
  }
  /**Consumir servicio para traer todos los
   distribuidores que piden vinculacion */
   public getPortafolioVinculaciones(id: any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/portafolioVinculaciones/${id}`, {
      headers: this.headers,
    });
  }
    /**Consumir servicio para traer todos los productos del cual el punto
     * tiene descuento
     */
   public getPortafolioDescuento(dist: any, punto:any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/productos_descuentos/${dist}/${punto}`, {
      headers: this.headers,
    });
  }
  /**Consumir servicio para traer todos los
   distribuidores que piden vinculacion */
  public getAllDistribuidoresVinculados(): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/distribuidores_vinculados`, {
      headers: this.headers,
    });
  }
  /** Servicio para traer un distribuidor vinculado
   * @param id identificador del distribuidor
   */
  public getFilteredDistribuidoresVinculados(id: any): Observable<any> {
    console.log('UData', `${environment.backendUrl}/api/distribuidores_vinculados_detallado/${id}`);
    return this.http.get<any>(`${environment.backendUrl}/api/distribuidores_vinculados_detallado/${id}`, {
      headers: this.headers,
    });
  }
  /** Servicio para traer el tipo de establecimiento de un punto de entrega
   * @param id identificador del distribuidor
   */
  public getDistribuidorTipoEstablecimiento(id: any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/distribuidores_vinculados_tipo_establecimiento/${id}`, {
      headers: this.headers,
    });
  }
  /** Servicio para traer un distribuidor vinculado aprobado
   * @param id identificador del distribuidor
   */
  public getFilteredDistribuidoresVinculadosAprobados(id: any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/distribuidores_vinculados_clientes_aprobados/${id}`, {
      headers: this.headers,
    });
  }

  /** Servicio de tipo put para modificar el estado del
    distribuidor que solicito vinculación
    * @param id identificador del distribuidor vinculado
    * @param data datos del distribuidor para cambiar el estado
     */
  public putDistribuidoresVinculados(id: string, data: any): Observable<any> {
    return this.http.put<any>(`${environment.backendUrl}/api/distribuidores_vinculados/${id}`, data, {
      headers: this.headers,
    });
  }

  /** Servicio para establecer los vendedores del distribuidor vinculado */
  public setVendedoresDistribuidorVinculados(id: string, data: any): Observable<any> {
    return this.http.put<any>(`${environment.backendUrl}/api/distribuidores_vinculados/${id}/vendedores`, data, {
      headers: this.headers,
    });
  }

  /** Servicio para traer un distribuidor vinculado segun el id
   * @param id identificador del distribuidor vinculado
   */
  public getDistribuidoresVinculadosById(id: any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/distribuidores_vinculados/${id}`, {
      headers: this.headers,
    });
  }

  /** Servicio para traer estadísticas de un distribuidor vinculado segun el id
   * @param id identificador del distribuidor vinculado
   */
  public getDistribuidoresVinculadosStatisticsById(id: any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/distribuidores_vinculados/${id}/statistics`, {
      headers: this.headers,
    });
  }

  /** Servicio para traer distribuidores vinculados adicionales al del id
   * @param id identificador del distribuidor vinculado
   * @param establecimiento_id identificador del establecimiento
   */
  public getDistribuidoresVinculadosAdicionales(id: any, establecimiento_id: any): Observable<any> {
    return this.http.get<any>(
      `${environment.backendUrl}/api/distribuidores_vinculados/${id}/adicionales/${establecimiento_id}`,
      {
        headers: this.headers,
      }
    );
  }

  /** Servicio para traer los distribuidores vinculados a un distribuidor
   * @param id identificador del distribuidor vinculado
   */
  public getDistribuidoresVinculadosByDistribuidor(id: any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/distribuidores_vinculados_by_distribuidor/${id}`, {
      headers: this.headers,
    });
  }

  /** Servicio para traer los distribuidores vinculados a un distribuidor
   * @param id_dist id_puntoentificador del distribuidor vinculado
   * @param id_punto identificador del punto vinculado
   */
  public getEstadoVinculacionByDistribuidorPunto(id_dist: any, id_punto: any): Observable<any> {
    return this.http.get<any>(
      `${environment.backendUrl}/api/distribuidores_vinculados_dist_punto/${id_dist}/${id_punto}`,
      { headers: this.headers }
    );
  }

  /**
   * Trae el top 10 de los productos de un distribuidor
   * @param id identificación del distribuidor
   * @returns
   */
  public getTopProductsByDistribuidor(id: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/top_productos_por_distribuidor/${id}`, {
      headers: this.headers,
    });
  }

  /**
   * Trae los venedores asociados a un distribuidor
   * @param id identificador del distribuidor
   * @returns
   */
  public getVendedoresByDistribuidor(id: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/distribuidor/${id}/vendedores`, {
      headers: this.headers,
    });
  }

  /**
   * Preaprueba un cliente para que pueda vincularse a un distribuidor
   * @param idDistribuidor identificador del distribuidor
   * @param obj Datos para la preaprobación. documento, invitar (true/false), nombre, correo.
   * @returns
   */
  public preaprobarCliente(idDistribuidor: string, obj: any): Observable<any> {
    const { documento, invitar, nombre, correo, correo_distribuidor } = obj;
    if (idDistribuidor == '') {
      throw new Error('El id del distribuidor no puede estar vacio');
    }
    return this.http.post<any>(
      `${environment.backendUrl}/api/distribuidor/${idDistribuidor}/preaprobar_cliente`,
      {
        documento,
        invitar,
        nombre,
        correo,
        correo_distribuidor,
      },
      {
        headers: this.headers,
      }
    );
  }

  /**
   *
   * @param q termino que se utilizara para buscar al distribuidor
   * @returns
   */
  getFilteredDistribuidores(q: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/distribuidor/search/${q}`, {
      headers: this.headers,
    });
  }
  /**
   *
   * @param id termino que se utilizara para buscar al distribuidor
   * @returns Devuelve el trabajador tipo "Vendedor" asociado al distribuidor
   */
  getDistribuidoresByTrabajador(id: any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/distribuidor_trabajador/${id}`, {
      headers: this.headers,
    });
  }
  getDistribuidoresVinculadosPunto(): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/distribuidores_vinculados/`, {
      headers: this.headers,
    });
  }

  postLogo(id: any, data: any, entidad: any): Observable<any> {
    return this.http.post<any>(`${environment.backendUrl}/api/recursos/logo/${id}/${entidad}`, data, {
      headers: this.headers,
    });
  }
  postDocuments(document: any, id: any, tipo_user: any, data: any): Observable<any> {
    return this.http.post<any>(
      `${environment.backendUrl}/api/documentos_solicitante/cargaDocumentos/${document}/${id}/${tipo_user}/web`,
      data,
      {
        headers: this.headers,
      }
    );
  }

  /**Tres Servicios para cargar solo un documento y no todos a la vez */
  postRUT(document: any, id: any, data: any): Observable<any> {
    return this.http.post<any>(
      `${environment.backendUrl}/api/documentos_solicitante/cargaRUT/${document}/${id}`,
      data,
      {
        headers: this.headers,
      }
    );
  }

  postCamaraComercio(document: any, id: any, data: any): Observable<any> {
    return this.http.post<any>(
      `${environment.backendUrl}/api/documentos_solicitante/cargaCamaraComercio/${document}/${id}`,
      data,
      {
        headers: this.headers,
      }
    );
  }

  postCC(document: any, id: any, data: any): Observable<any> {
    return this.http.post<any>(`${environment.backendUrl}/api/documentos_solicitante/cargaCC/${document}/${id}`, data, {
      headers: this.headers,
    });
  }
  postCertificacion(document: any, id: any, data: any): Observable<any> {
    return this.http.post<any>(
      `${environment.backendUrl}/api/documentos_solicitante/cargaCertif/${document}/${id}`,
      data,
      {
        headers: this.headers,
      }
    );
  }
  postDocumentosDistribuidor(id: any, tipo: any, data: any): Observable<any> {
    return this.http.post<any>(
      `${environment.backendUrl}/api/documentos_solicitante/documentos_distribuidor/${id}/${tipo}`,
      data,
      {
        headers: this.headers,
      }
    );
  }
  getDocument(id: any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/documentos_solicitante/${id}`, {
      headers: this.headers,
    });
  }
  getDocumentDistribuidor(id: any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/documentos_solicitante_distribuidor/${id}`, {
      headers: this.headers,
    });
  }

  getPlanes(): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/PlanesMembresias`, {
      headers: this.headers,
    });
  }

  getPlan(id: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/PlanesMembresias/${id}`, {
      headers: this.headers,
    });
  }

  getCurrentPlan(id: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/distribuidor/${id}/vinculacion_plan`, {
      headers: this.headers,
    });
  }

  getClientsLength(id: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/distribuidores_vinculados/${id}/length`, {
      headers: this.headers,
    });
  }

  getOrCreateVinculacionPlan(vinculacion: any): Observable<any> {
    return this.http.post<any>(`${environment.backendUrl}/api/distribuidor/vinculacion_plan`, vinculacion, {
      headers: this.headers,
    });
  }

  addTransaccionToVinculacionPlan(vinculacion_id: string, transaccion_id: string): Observable<any> {
    return this.http.put<any>(
      `${environment.backendUrl}/api/distribuidor/vinculacion_plan/${vinculacion_id}/add_transaccion`,
      {
        transaccion: transaccion_id,
      },
      {
        headers: this.headers,
      }
    );
  }

  checkPlanPayment(vinculacion_id: string | undefined): Observable<any> {
    return this.http.get<any>(
      `${environment.backendUrl}/api/distribuidor/vinculacion_plan/${vinculacion_id}/check_payment`,
      {
        headers: this.headers,
      }
    );
  }
}
