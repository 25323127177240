import { Component, OnInit } from '@angular/core';
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.css'],
})
export class MobileComponent implements OnInit {
  /**Icons */
  faGooglePlay = faGooglePlay;
  faApple = faApple;
  constructor() {}

  ngOnInit(): void {}
  descargarApp() {
    window.open('https://me-qr.com/u2bHGo7V', '_blank');
  }
}
