<app-header-horeca></app-header-horeca>
<div id="content">
  <div class="row">
    <div class="col-12">
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills">
        <li [ngbNavItem]="1">
          <a ngbNavLink>Informes</a>
          <ng-template ngbNavContent>
            <app-informes-general></app-informes-general>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink>Info del Sector</a>
          <ng-template ngbNavContent>
            <app-graficas-general></app-graficas-general>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</div>