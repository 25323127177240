import { Component } from '@angular/core';
import { faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ProcesandoInfoClientesComponent } from '../procesando-info-clientes/procesando-info-clientes.component';
import { ProcesandoInfoComponent } from '../procesando-info/procesando-info.component';

@Component({
  selector: 'app-cargar-clientes-excel',
  templateUrl: './cargar-clientes-excel.component.html',
  styleUrls: ['./cargar-clientes-excel.component.css'],
})
export class CargarClientesExcelComponent {
  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal) {}

  /** Referencias a iconos FontAwesome */
  faTimes = faTimes;
  faTimesCircle = faTimesCircle;

  /** Flag de error de formato */
  error_formato_xlsx = false;

  /** Archivo de excel */
  plantilla_clientes?: File;

  /**
   * Revisa que se haya subido un archivo de Excel
   * Si sí se subió uno, continua con el proceso
   * Si no, le avisa al usuario del error
   */
  siguiente() {
    this.activeModal.close();

    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };

    const modalRef = this.modalService.open(ProcesandoInfoClientesComponent, ngbModalOptions);
    modalRef.componentInstance.clientes_xlsx = this.plantilla_clientes;
  }

  /**
   * Recibe un archivo del usuario y lo guarda. Si
   * el archivo no tiene el formato correcto, no
   * se guarda y se muestra de error
   * @param event El evento generado al subir el archivo
   */
  handleFileInput(event: any) {
    this.error_formato_xlsx = false;

    if (event) {
      const file: File = event.target.files[0];

      if (file) {
        const file_split: string[] = file.name.split('.');
        const file_end: string = file_split[file_split.length - 1].trim().toLowerCase();

        if (file_end.toLowerCase() == 'xlsx') {
          //Se está intentando subir un archivo de imagen
          this.plantilla_clientes = file;
        } else {
          this.error_formato_xlsx = true;
        }
      } else {
        this.plantilla_clientes = undefined;
      }
    } else {
      this.plantilla_clientes = undefined;
    }
  }
}
