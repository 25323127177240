import { Component, Input, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash, faEye } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cambiar-contrasena',
  templateUrl: './cambiar-contrasena.component.html',
  styleUrls: ['./cambiar-contrasena.component.css'],
})
export class CambiarContrasenaComponent implements OnInit {
  /** Referencias a íconos FontAwesome para la UI */
  faTimes = faTimes;
  faEyeSlash = faEyeSlash;
  faEye = faEye;

  current: any = '';
  newPass: any = '';
  id: any;
  mensajeError = false;
  /**
   * Needed to toggle between the type attribute of the password entry
   * true, type: password
   * false, type: text
   */
  hideOld = true;
  hideNew = true;
  @Input() id_user: any;
  @Input() clave_actual: any;
  constructor(private modalService: NgbModal, private authService: AuthService, public activeModal: NgbActiveModal) {}

  close() {
    this.activeModal.close();
  }

  onCambiarContrasena() {
    if (this.clave_actual) {
      alert('MetodoNuevo');
      if (this.newPass != '') {
        const data = {
          newPass: this.newPass,
        };
        this.authService.updatePasswordAdmin(data, this.id_user).subscribe(
          () => {
            const ngbModalOptions: NgbModalOptions = {
              backdrop: 'static',
              keyboard: false,
            };
            const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
            modalRef.componentInstance.title = '¡Genial!';
            modalRef.componentInstance.msg = 'La contraseña ha sido cambia con éxito';
            modalRef.componentInstance.btn_msg = 'Listo';
            this.activeModal.close();
          },
          (err) => {
            if (err.status == 500) {
              this.mensajeError = true;
            }
          }
        );
      }
    } else {
      if (this.newPass != '' && this.current != '') {
        const data = {
          current: this.current,
          newPass: this.newPass,
        };
        this.authService.updatePassword(data, this.id._id).subscribe(
          () => {
            const ngbModalOptions: NgbModalOptions = {
              backdrop: 'static',
              keyboard: false,
            };
            const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
            modalRef.componentInstance.title = '¡Genial!';
            modalRef.componentInstance.msg = 'La contraseña ha sido cambia con éxito';
            modalRef.componentInstance.btn_msg = 'Listo';
            this.activeModal.close();
          },
          (err) => {
            if (err.status == 500) {
              this.mensajeError = true;
            }
          }
        );
      }
    }
  }

  ngOnInit(): void {
    this.id = this.authService.user;
    console.log(this.id_user);
    console.log(this.clave_actual);
  }
}
