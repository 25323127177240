/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, DoCheck, OnInit, SecurityContext } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { faChevronLeft, faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { Categoria } from 'src/app/models/categoria.model';
import { Marca } from 'src/app/models/marca.model';
import { Organizacion } from 'src/app/models/organizacion.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { CurrencyPipe } from '@angular/common';
import { ProductoManual } from 'src/app/models/producto_manual.model';
@Component({
  selector: 'app-agregar-producto',
  templateUrl: './agregar-producto.component.html',
  styleUrls: ['./agregar-producto.component.css'],
})
export class AgregarProductoComponent implements OnInit, DoCheck {
  // Referencias a íconos FontAwesome para la UI
  public faChevronLeft = faChevronLeft;
  public faTimesCircle = faTimesCircle;
  public faPlus = faPlus;
  // Información de marcas, organizaciones, categorias, y lineas de producto
  public marcas: Marca[] = [];
  public marcas_filtradas: Marca[] = [];
  public nueva_marca = '';
  public marca_seleccionada?: Marca;
  public organizaciones: Organizacion[] = [];
  public categorias: Categoria[] = [];
  public lineas: any;
  // Validación de formulario que no entran en los validators
  public is_boton_habilitado = true;
  // Texto faltante en textareas
  public remainingTextNombre = 0;
  public remainingTextDescripcion = 0;
  // Guarda la cantidad de unidades pend. para completar una caja
  public unidades_pendientes_caja = 0;
  // ID e información del producto a editar
  private prod_id = '';
  public producto?: ProductoManual;

  // Guarda el inventario
  public cajas = 0;
  public unidades = 0;
  // Se da manejo a las propiedades opcionales del Producto con estas variables (para evitar problemas de TypeScript)
  public number = Number;
  public precios: {
    unidad_medida?: string;
    cantidad_medida?: string;
    estado?: string;
    precio_unidad?: number;
    precio_caja?: number;
    precio_descuento?: number;
    inventario_unidad?: number;
    inventario_caja?: number;
    puntos_ft_unidad?: number;
    puntos_ft_caja?: number;
    und_x_caja?: number;
  }[] = [];
  // Variables para el manejo de las fotos y sus miniaturas
  public imgs: File[] = [];
  public imgs_path: SafeUrl[] = [];
  public file_fotos: (File | undefined)[] = [];
  public fotos: (SafeUrl | string)[] = [];
  // Arreglo con los formatos validos de imagen
  public error_formato_img = false;
  public error_max_imgs = false;
  supported_imgs: string[] = ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp'];
  // Flag para controlar si es posible o no subir el producto con los datos ingresados
  public actualizar_enabled = false;
  // Referemcia al modal de proceso de carga para cerrarlo una vez termine la carga u ocurra un error
  public openModal?: NgbModalRef;
  // Formularios reactivos
  public distribuidorForm: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private rest: RestService,
    private formBuilder: FormBuilder,
    private router: Router,
    private currencyPipe: CurrencyPipe,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) {
    this.distribuidorForm = this.formBuilder.group({
      distribuidorCodigoProducto: ['', Validators.required],
      distribuidorProductoNombre: ['', [Validators.required, Validators.pattern(/^.{1,50}$/)]],
      distribuidorMarca: ['', Validators.required],
      distribuidorPrecioUnidadMedida: ['', Validators.required],
      distribuidorPrecioUnidad: ['', Validators.required],
      distribuidorPrecioCaja: ['', Validators.required],
      distribuidorCategoria: ['', Validators.required],
      distribuidorLinea: ['', Validators.required],
      distribuidorOrganizacion: ['', Validators.required],
      distribuidorCantidadMedida: ['', Validators.required],
      distribuidorUnidadMedida: ['', Validators.required],
      distribuidorInventarioUnidad: ['', Validators.required],
      distribuidorInventarioCaja: ['', Validators.required],
      distribuidorPresentacionCaja: ['', Validators.required],
      distribuidorProductoDescripcion: ['', [Validators.required, Validators.pattern(/^.{1,300}$/)]],
    });
  }

  ngOnInit() {
    this.recuperarOrganizaciones();
    this.recuperarMarcas();
    this.recuperarCategorias();
    this.distribuidorForm.patchValue({
      distribuidorInventarioCaja: 0,
      distribuidorInventarioUnidad: 0,
    });
  }

  ngDoCheck(): void {
    if (this.unidades < 0) {
      this.unidades = 0;
    }
    /** Verifica cantidad de cajas agregadas */
    this.distribuidorForm.patchValue({
      distribuidorInventarioCaja:
        Math.round(
          (this.distribuidorForm.get('distribuidorInventarioUnidad')?.value /
            this.distribuidorForm.get('distribuidorPresentacionCaja')?.value) *
            100
        ) / 100,
    });
    /** Verifica cuantas unidades hacen falta para completar otra caja */
    this.unidades_pendientes_caja =
      this.distribuidorForm.get('distribuidorPresentacionCaja')?.value -
      (this.distribuidorForm.get('distribuidorInventarioUnidad')?.value %
        this.distribuidorForm.get('distribuidorPresentacionCaja')?.value);
    /** Calculo automatico precio por unidad de producto */
    if (
      this.distribuidorForm.get('distribuidorPrecioUnidad')?.value.replace(/[^\d,-]/g, '') > 0 &&
      this.distribuidorForm.get('distribuidorCantidadMedida')?.value > 0
    ) {
      const valor_actualizado =
        this.distribuidorForm.get('distribuidorPrecioUnidad')?.value.replace(/[^\d,-]/g, '') /
        this.distribuidorForm.get('distribuidorCantidadMedida')?.value;
      this.distribuidorForm.patchValue({
        distribuidorPrecioUnidadMedida: this.currencyPipe.transform(valor_actualizado, '$ ', 'symbol', '1.2-2'),
      });
    } else {
      this.distribuidorForm.patchValue({
        distribuidorPrecioUnidadMedida: this.currencyPipe.transform(0, '$ ', 'symbol', '1.2-2'),
      });
    }
    /** Calcular  el precio por caja */
    if (
      this.distribuidorForm.get('distribuidorPresentacionCaja')?.value > 0 &&
      this.distribuidorForm.get('distribuidorPrecioUnidad')?.value.replace(/[^\d,-]/g, '') > 0
    ) {
      const precio_caja =
        this.distribuidorForm.get('distribuidorPresentacionCaja')?.value *
        this.distribuidorForm.get('distribuidorPrecioUnidad')?.value.replace(/[^\d,-]/g, '');
      this.distribuidorForm.patchValue({
        distribuidorPrecioCaja: this.currencyPipe.transform(precio_caja, '$ ', 'symbol', '1.0-0'),
      });
    }
  }

  /**************************************** Validadores ****************************************/
  /**
   * Este metodo evita que en los inputs texto se ingrese numeros
   */
  validateOnlyText(evento: any) {
    const keyCode = evento.keyCode;
    if (keyCode >= 48 && keyCode <= 57) {
      evento.preventDefault();
    }
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateNumber(evento: any) {
    const keyCode = evento.keyCode;
    const excludedKeys = [8, 37, 3];
    if (
      !(
        keyCode == 190 ||
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      evento.preventDefault();
    }
  }

  /**
   * Message box contador de letras
   */
  valueChange(value: string, textbox: string) {
    if (textbox === 'nombre') {
      this.remainingTextNombre = 50 - value.length - 1;
    } else if (textbox === 'descripcion') {
      this.remainingTextDescripcion = 300 - value.length - 1;
    }
  }

  /**
   * Transforma el dinero minimo de compra de número a moneda y viceversa
   * a moneda se utilizará para reemplazar el input y dar mejor UX al usuario
   * y a número plano para guardar el dato correctamente en la base de datos
   * ademas de poder editar mejor el valor en el input
   */
  public transformCurrency(event: any, precio: string) {
    if (precio === 'unidad') {
      this.distribuidorForm.patchValue({
        distribuidorPrecioUnidad: event.value.replace(/[^\d,-]/g, ''),
      });
    } else if (precio === 'unidad_medida') {
      this.distribuidorForm.patchValue({
        distribuidorPrecioUnidadMedida: event.value.replace(/[^\d,-]/g, ''),
      });
    } else if (precio === 'caja') {
      this.distribuidorForm.patchValue({
        distribuidorPrecioCaja: event.value.replace(/[^\d,-]/g, ''),
      });
    }
  }
  public transformAmount(event: any, precio: string) {
    if (precio === 'unidad') {
      this.distribuidorForm.patchValue({
        distribuidorPrecioUnidad: this.currencyPipe.transform(event.value, '$ ', 'symbol', '1.0-0'),
      });
    } else if (precio === 'unidad_medida') {
      this.distribuidorForm.patchValue({
        distribuidorPrecioUnidadMedida: this.currencyPipe.transform(event.value, '$ ', 'symbol', '1.2-2'),
      });
    } else if (precio === 'caja') {
      this.distribuidorForm.patchValue({
        distribuidorPrecioCaja: this.currencyPipe.transform(event.value, '$ ', 'symbol', '1.0-0'),
      });
    }
  }

  /*************************** Recupera información para Selects de formulario **************************/
  /**
   * Consulta al back las marcas que han sido registradas y las guarda en la lista para mostrarlas en el form
   */
  recuperarMarcas() {
    this.rest
      .getJWT('marca_producto')
      .toPromise()
      .then((resp: any) => {
        for (const aux of resp) {
          this.marcas.push(new Marca(aux.nombre, aux._id));
        }
        this.marcas = this.marcas.sort((a: any, b: any) => {
          return a.nombre.localeCompare(b.nombre);
        });
        this.marcas_filtradas = this.marcas;
        /** Encontrar la marca que tiene registrada el producto y ponerla como marca seleccionada */
        for (const aux of this.marcas_filtradas) {
          if (aux._id == this.producto?.marca_producto) {
            this.marca_seleccionada = aux;
          }
        }
      });
  }

  /**
   * Consulta al back las organizaciones registradas y las guarda en la lista para msotrarlas en el form
   */
  recuperarOrganizaciones() {
    this.rest
      .getJWT('organizacion')
      .toPromise()
      .then((resp: any) => {
        for (const aux of resp) {
          this.organizaciones.push(
            new Organizacion(aux.nombre, aux.correo, aux.logo, aux.solicitud_vinculacion, aux.marcas || [], aux._id)
          );
        }
        this.organizaciones = this.organizaciones.sort((a: any, b: any) => {
          return a.nombre.localeCompare(b.nombre);
        });
      });
  }

  /**
   * Consulta al back las categorías de productos registradas y las guarda en la lista para mostrarlas en el form
   */
  recuperarCategorias() {
    this.rest
      .getJWT('categoria_producto')
      .toPromise()
      .then((resp: any) => {
        this.categorias = resp.data;
        /** Lineas segun categoria */
        this.recuperarLineas(this.distribuidorForm?.get('distribuidorCategoria')?.value);
      });
  }

  /**
   * Consulta al back las lineas de productos registradas y las guarda en la lsita para mostrarlas en el form
   */
  recuperarLineas(id_categoria: any) {
    this.lineas = this.categorias.find((element: any) => element._id === id_categoria)?.lineas_producto;
    this.lineas = this.lineas.sort(function (a: any, b: any) {
      if (a.nombre === undefined) return 1;
      if (b.nombre === undefined) return -1;
      if (a.nombre === b.nombre) return 0;
      return a.nombre.toLowerCase() < b.nombre.toLowerCase() ? -1 : 1;
    });
  }

  /******************************************* Guardar cambios ******************************************/
  /**
   * Guarda los cambios realizados por el usuario en el usuario y scroll de imagenes
   * Primero guarda las imagenes y luego guarará el producto con la url de las imagenes
   */
  guardarCambios() {
    /****** Modal Generico cargando *****/
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    this.openModal = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    /** Guarda el producto */
    this.actualizarProducto();
  }

  /**
   * Envía la información ingresada por el usuario al back para
   * actualizar el producto, incluyendo las fotos ingresadas
   */
  actualizarProducto() {
    const _id: string = this.prod_id;
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    /** Actualiza el objeto producto con la información del formulario */
    const nuevo_prod: ProductoManual = new ProductoManual(
      this.distribuidorForm.get('distribuidorProductoNombre')?.value,
      this.distribuidorForm.get('distribuidorProductoDescripcion')?.value,
      [],
      [
        {
          unidad_medida: this.distribuidorForm.get('distribuidorUnidadMedida')?.value,
          cantidad_medida: this.distribuidorForm.get('distribuidorCantidadMedida')?.value.toString(),
          estado:
            this.distribuidorForm.get('distribuidorInventarioUnidad')?.value > 0 ||
            this.distribuidorForm.get('distribuidorInventarioCaja')?.value > 0
              ? 'Disponible'
              : 'Agotado',
          precio_unidad: Number.parseFloat(
            this.distribuidorForm.get('distribuidorPrecioUnidad')?.value.replace(/[^\d,-]/g, '')
          ),
          precio_caja: Number.parseFloat(
            this.distribuidorForm.get('distribuidorPrecioCaja')?.value.replace(/[^\d,-]/g, '')
          ),
          precio_descuento: undefined,
          inventario_unidad: this.distribuidorForm.get('distribuidorInventarioUnidad')?.value,
          inventario_caja: this.distribuidorForm.get('distribuidorInventarioCaja')?.value,
          puntos_ft_unidad: undefined,
          puntos_ft_caja: undefined,
          und_x_caja: this.distribuidorForm.get('distribuidorPresentacionCaja')?.value,
        },
      ],
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      this.auth.user_distribuidor?._id,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      this.distribuidorForm.get('distribuidorCodigoProducto')?.value,
      'Pendiente',
      undefined,
      undefined,
      this.distribuidorForm.get('distribuidorOrganizacion')?.value,
      this.distribuidorForm.get('distribuidorMarca')?.value,
      this.distribuidorForm.get('distribuidorCategoria')?.value,
      this.distribuidorForm.get('distribuidorLinea')?.value
    );
    this.producto = nuevo_prod;
    /** Actualiza el objeto producto en la base de datos */
    this.rest
      .postJWT('producto/', nuevo_prod)
      .toPromise()
      .then((resp: any) => {
        // Si no hay imagenes, no guarda fotos
        if (this.imgs.length > 0) this.guardarFotos(resp.data._id, 'codigo_Ft');
        else {
          this.openModal?.close();
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          modalRef.componentInstance.msg =
            '¡Tu producto ha sido creado con éxito!, recuerda que deberás actualizar las imágenes asociadas a este.';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            this.router.navigate(['/portafolio']);
          };
        }
      })
      .catch((err) => {
        console.log(err);
        this.openModal?.close();
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'Ocurrió un error creando el producto. Por favor, intenta de nuevo más tarde.';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {};
      });
  }

  /**
   * Asociar el producto creado a la colección de distribuidor
   */
  asociarProductoAlDistribuidor(codigo_distribuidor: string, _id: string) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    this.rest
      .getJWT('productos_por_distribuidor/distribuidor/' + codigo_distribuidor)
      .toPromise()
      .then((prods_x_dist: any) => {
        if (prods_x_dist == null) {
          this.rest
            .postJWT('productos_por_distribuidor', {
              distribuidor: codigo_distribuidor,
              productos: [_id],
            })
            .toPromise()
            .then(() => {})
            .catch((err) => {
              console.log(err);
              this.openModal?.close();
              const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
              modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
              modalRef.componentInstance.title = '¡Oh oh!';
              modalRef.componentInstance.msg =
                'Ocurrió un error creando el producto. Por favor, intenta de nuevo más tarde.';
              modalRef.componentInstance.btn_msg = 'Volver';
            });
        } else {
          const prods = prods_x_dist.productos;
          prods.push(_id);
          this.rest
            .putJWT('productos_por_distribuidor/' + prods_x_dist._id, { productos: prods })
            .toPromise()
            .then(() => {})
            .catch((err) => {
              console.log(err);
              this.openModal?.close();
              const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
              modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
              modalRef.componentInstance.title = '¡Oh oh!';
              modalRef.componentInstance.msg =
                'Ocurrió un error creando el producto. Por favor, intenta de nuevo más tarde.';
              modalRef.componentInstance.btn_msg = 'Volver';
            });
        }
      });
  }

  /**
   * Guarda las imagenes cargadas por el usuario al producto
   */
  guardarFotos(_id: string, cod_ft: string) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    /** Gestiona las imagenes del producto **/
    const upload_form: FormData = new FormData();
    let i = 1;
    /** Guarda las imagenes en AWS */
    for (const img_aux of this.imgs) {
      upload_form.append(`imagen${i}`, img_aux);
      i++;
    }
    /** Guarda las fotos */
    this.rest
      .postJWT('recursos/producto/' + _id + '/' + cod_ft, upload_form)
      .toPromise()
      .then((resp: any) => {
        const nuevas_url: string[] = [];
        let i = 0;
        for (const str_path of this.fotos) {
          if (this.file_fotos[i] == undefined && typeof str_path === 'string') {
            nuevas_url.push(str_path);
          }
          i++;
        }
        for (const str_path of resp.logos) {
          nuevas_url.push(str_path);
        }
        this.producto!.fotos = nuevas_url;
        /** Guarda objeto producto nuevo */
        this.rest
          .putJWT('producto/' + _id, this.producto)
          .toPromise()
          .then((resp: any) => {
            this.openModal?.close();
            const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
            modalRef.componentInstance.title = '¡Genial!';
            modalRef.componentInstance.msg = '¡Tu producto ha sido creado con éxito!';
            modalRef.componentInstance.btn_msg = 'Listo';
            modalRef.componentInstance.close_callback = () => {
              this.router.navigate(['/portafolio']);
            };
          });
      })
      .catch((err) => {
        console.log(err);
        this.openModal?.close();
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg =
          'Ocurrió un error cargando las nuevas fotos, sin embargo, tu producto fue creado sin estas, favor ve al detalle de este y agrega las imagenes correspondientes.';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {};
      });
  }

  /***************************************** Maneja las imagenes ****************************************/
  /**
   * Recibe un archivo del usuario y lo guarda
   * en el arreglo de imágenes del producto. Si
   * el archivo no tiene el formato correcto, no
   * se guarda y se muestra de error
   * @param event El evento generado al subir el archivo
   */
  handleFileInput(event: any) {
    this.error_formato_img = false;
    const file: File = event.target.files[0];
    if (file != null) {
      const file_split: string[] = file.name.split('.');
      const file_end: string = file_split[file_split.length - 1].trim().toLowerCase();
      if (this.supported_imgs.includes(file_end)) {
        //Se está intentando subir un archivo de imagen
        this.imgs.push(file);
        this.imgs_path.push(
          this.sanitizer.sanitize(
            SecurityContext.NONE,
            this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.imgs[this.imgs.length - 1]))
          ) || ''
        );
      } else {
        this.error_formato_img = true;
      }
    }
  }

  /**
   * Elimina del arreglo de URLs la foto con el índice indicado,
   * y si fue una foto agregada, también elimina el archivo subido
   * @param index La posición en los arreglos de fotos y de archivos
   * a eliminar
   */
  eliminarImg(index: number) {
    this.imgs.splice(index, 1);
    this.imgs_path.splice(index, 1);
  }

  /**************************************** Otras funcionalidades ***************************************/
  /**
   * Recibe cualquier string y devuelve el mismo string, pero
   * en minúsulcas y sin tildes ni diéresis
   * @param str El string a convertir
   * @returns El string convertido
   */
  toUnaccentedLowerCase(str: string): string {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  /**
   * Alerta si el formulario esta incompleto o un input es invalido
   */
  alertaFormularioInvalido() {
    /** Formulario reactivo */
    this.distribuidorForm.markAllAsTouched();
    const modalRef = this.modalService.open(SimpleComponent);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = '¡Por favor asegúrate de llenar todos los datos y vuelve a intentarlo!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {};
  }
}
