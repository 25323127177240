import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { CargarCatalogoComponent } from '../cargar-catalogo/cargar-catalogo.component';
import { SimpleComponent } from '../simple/simple.component';

@Component({
  selector: 'app-procesando-info-clientes',
  templateUrl: './procesando-info-clientes.component.html',
  styleUrls: ['./procesando-info-clientes.component.css'],
})
export class ProcesandoInfoClientesComponent implements OnInit {
  // El archivo XLSX a subir para ser procesado
  @Input() clientes_xlsx?: File;
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
  };

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private rest: RestService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.cargarXLSX();
  }

  /**
   * Se envía el archivo de Excel al back para ser procesado
   * y espera una respuesta
   */
  async cargarXLSX() {
    if (this.clientes_xlsx) {
      const upload_form: FormData = new FormData();
      upload_form.append('xlsx', this.clientes_xlsx);
      try {
        const resp: any = await this.rest
          .postJWT(`distribuidor/${this.auth.user_distribuidor?._id}/preaprobar_por_xlsx`, upload_form)
          .toPromise();
        if (resp.success) {
          this.success(resp.msg);
        } else {
          this.error(resp.msg, true);
        }
      } catch (err) {
        this.error(err);
      }
    }
  }

  /**
   * Cierra este modal y lanza un mensaje de error genérico
   * También imprime el error por consola para facilitar el debugging
   * @param err El error que disparó este método
   */
  error(err: any, show_error?: boolean) {
    this.activeModal.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = show_error
      ? err
      : 'Ocurrió un error subiendo la información. Por favor, intenta de nuevo más tarde.';
    modalRef.componentInstance.btn_msg = 'Volver';
  }

  /**
   * Cierra este modal y lanza un mensaje de éxito genérico
   */
  success(msg: any) {
    this.activeModal.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
    modalRef.componentInstance.title = '¡Éxito!';
    modalRef.componentInstance.msg = 'Los clientes fueron pre aprobados correctamente.';
    modalRef.componentInstance.btn_msg = 'Cerrar';
  }
}
