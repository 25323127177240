import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalPlanComponent} from 'src/app/modal/modal-plan/modal-plan.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  /** Dirección del backend */
  private serverAddress = `${environment.backendUrl}/api/`;
  private apiImagine = 'https://api.imagineapps.co/';
  public modalVencimiento:any= {};
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg',
  };
  constructor(private http: HttpClient, private authService: AuthService, private modalService: NgbModal,private routerNavigation: Router) {
    this.modalVenc();
  }
  modalVenc() {
    if(this.modalVencimiento.limit && this.modalVencimiento.establecimiento_actuales){
      if(this.modalVencimiento.establecimiento_actuales >= this.modalVencimiento.limit){
        const modalRef = this.modalService.open(ModalPlanComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Has superado tu plan actual, tienes más de (0) establecimientos, actualízalo y sigue disfrutando';
    modalRef.componentInstance.btn_msg = 'Actualizar plan';
    modalRef.componentInstance.close_callback = () => {
      this.routerNavigation.navigate(['/expandir-plan-organizacion']);
    };
      }
    }
  }
  
  setPlanOrg(params1: any, params2: any){
    this.modalVencimiento.limit= params1;
    this.modalVencimiento.establecimiento_actuales = params2;
    this.modalVenc();
  }
  /**
   * Manda una petición POST al servidor en la ruta especificada con el objeto indicado
   * No requiere token
   * @param path La ruta indicada para la petición
   * @param object El objeto a enviar
   * @returns Un Observable para conocer el estado de la petición y cuándo complete
   */
  post(path: string, object: any) {
    return this.http.post(this.serverAddress + path, object);
  }
  postLink(id: any, data: any): Observable<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Authorization', this.authService.token || '');
    return this.http.post<any>(`${environment.backendUrl}/api/recursos/ficha_tecnica/${id}`, data, {
      headers: httpHeaders,
    });
  }
  /**
   * Manda una petición POST al servidor en la ruta especificada
   * Requiere enviar token en el header
   * @param path La ruta indicada para la petición
   * @returns Un Observable para conocer el estado de la petición y cuándo complete
   */
  postJWT(path: string, object: any) {
    let httpHeaders: HttpHeaders = new HttpHeaders();

    httpHeaders = httpHeaders.append('Authorization', this.authService.token || '');

    return this.http.post(this.serverAddress + path, object, {
      headers: httpHeaders,
    });
  }

  /**
   * Manda una petición GET al servidor en la ruta especificada
   * No requiere token
   * @param path La ruta indicada para la petición
   * @returns Un Observable para conocer el estado de la petición y cuándo complete
   */
  get(path: string) {
    return this.http.get(this.serverAddress + path);
  }

  /**
   * Manda una petición GET al servidor en la ruta especificada
   * Requiere enviar token en el header
   * @param path La ruta indicada para la petición
   * @returns Un Observable para conocer el estado de la petición y cuándo complete
   */
  getJWT(path: string) {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Authorization', this.authService.token || '');

    return this.http.get(this.serverAddress + path, { headers: httpHeaders });
  }

  /**
   * Manda una petición GET al servidor en la ruta especificada
   * Requiere enviar token en el header
   * @param path La ruta indicada para la petición
   * @returns Un Observable para conocer el estado de la petición y cuándo complete
   */
  getImagine(path: string) {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('apiKey', 'VQYHJtawdLuyqg4bDEm5kEwHnZfZ5lKW');

    return this.http.get(this.apiImagine + path, { headers: httpHeaders });
  }

  /**
   * Manda una petición PUT al servidor en la ruta especificada con el objeto indicado
   * @param path La ruta indicada para la petición
   * @param object El objeto a enviar
   * @returns Un Observable para conocer el estado de la petición y cuándo complete
   */
  put(path: string, object: any) {
    return this.http.put(this.serverAddress + path, object);
  }

  /**
   * Manda una petición PUT al servidor en la ruta especificada con el objeto indicado
   * Requiere enviar token en el header
   * @param path La ruta indicada para la petición
   * @param object El objeto a enviar
   * @returns Un Observable para conocer el estado de la petición y cuándo complete
   */
  putJWT(path: string, object: any) {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Authorization', this.authService.token || '');

    return this.http.put(this.serverAddress + path, object, {
      headers: httpHeaders,
    });
  }
}
