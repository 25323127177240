/* eslint-disable prefer-const */
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { LocalDataSource } from 'ng2-smart-table';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import 'moment/locale/es';
moment.locale('es');
@Component({
  selector: 'app-tablas-informes-distribuidor',
  templateUrl: './tablas-informes-distribuidor.component.html',
  styleUrls: ['./tablas-informes-distribuidor.component.css'],
})
export class TablasInformesDistribuidorComponent implements OnInit {
  /** ID Distribuidor */
  public id_distribuidor = this.authService.user_distribuidor!._id;
  /**Iconos */
  faChevronUp = faChevronUp;
  /** Datos y configuracion de la tabla  */
  public settings_clientes: any = { noDataMessage: 'Aún no tienes información para ver por aquí' };
  public settings_pedidos: any = { noDataMessage: 'Aún no tienes información para ver por aquí' };
  public settings_usuarios: any = { noDataMessage: 'Aún no tienes información para ver por aquí' };
  public settings_portafolio: any = { noDataMessage: 'Aún no tienes información para ver por aquí' };
  public settings_pedido_sugerido: any = { noDataMessage: 'Aún no tienes información para ver por aquí' };
  public settings_ventas: any = { noDataMessage: 'Aún no tienes información para ver por aquí' };
  public settings_clientes_asignados: any = { noDataMessage: 'Aún no tienes información para ver por aquí' };
  public settings_saldos_promos: any = { noDataMessage: 'Aún no tienes información para ver por aquí' };
  public data_clientes: any = [];
  public data_pedidos: any = [];
  public data_usuarios: any = [];
  public data_portafolio: any = [];
  public data_pedido_sugerido: any = [];
  public data_ventas: any = [];
  public data_clientes_asignados: any = [];
  public data_saldos_promos: any = [];
  public source_clientes?: LocalDataSource;
  public source_pedidos?: LocalDataSource;
  public source_usuarios?: LocalDataSource;
  public source_portafolio?: LocalDataSource;
  public source_pedido_sugerido?: LocalDataSource;
  public source_ventas?: LocalDataSource;
  public source_clientes_asignados?: LocalDataSource;
  public source_saldos_promos?: LocalDataSource;
  /** Modal de carga para darle feedback al usuario */
  public modalCarga?: NgbModalRef;
  /** Evita que al hacer click por fuera se cierre el modal */
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };
  blockUser: boolean | undefined;
  constructor(
    private restService: RestService,
    private router: Router,
    private ngxsStore: Store,
    private authService: AuthService,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private currency: CurrencyPipe
  ) {
    this.validatePermissions();
  }

  async ngOnInit() {
    this.id_distribuidor = await this.ngxsStore.snapshot().auth.user_distribuidor._id;
    /** Modal generíco cargando... */
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    /** Configura las tablas */
    this.configSmartTablePedidos();
    this.configSmartTableUsuarios();
    this.configSmartTableClientes();
    this.configSmartTablePortafolio();
    this.configSmartTableVentas();
    /** Fetch data para las tablas */
    await this.cargarDatosTablaPedidos();
    await this.cargarDatosTablaUsuarios();
    await this.cargarDatosTablaClientes();
    await this.cargarDatosTablaPortafolio();
    await this.cargarDatosTablaVentas();
    /** Cerrar Modal generíco cargando... */
    this.modalCarga?.close();
  }
  async validatePermissions() {
    // eslint-disable-next-line prefer-const
    let blockUser = await this.authService.validatePermissionsUser();
    this.blockUser = blockUser;
  }
  /**
   * Configura la tabla
   */
  private configSmartTable(): void {
    this.settings_pedido_sugerido = {
      noDataMessage: 'Aún no tienes información para ver por aquí',
      pager: {
        display: true,
        perPage: 4,
      },
      actions: false,
      hideSubHeader: false,
      columns: {
        distribuidor: {
          title: 'Distribuidor',
          editable: false,
          filter: true,
        },
        personaComercial: {
          title: 'Persona del equipo comercial',
          editable: false,
          filter: true,
        },
        establecimiento: {
          title: 'Establecimiento',
          editable: false,
          filter: true,
        },
        tipoDePersona: {
          title: 'Tipo de persona',
          editable: false,
          filter: true,
        },
        nit: {
          title: 'NIT/CC/CE',
          editable: false,
          filter: true,
        },
        tipoNegocio: {
          title: 'Tipo de negocio',
          editable: false,
          filter: true,
        },
        fechaPedido: {
          title: 'Fecha de pedido',
          editable: false,
          filter: true,
        },
        noPedido: {
          title: 'No. pedido',
          editable: false,
          filter: true,
        },
        estadoActual: {
          title: 'Estado actual',
          editable: false,
          filter: true,
        },
        horaAprobado: {
          title: 'Hora aprobado',
          editable: false,
          filter: true,
        },
        horaEntrega: {
          title: 'Hora entrega',
          editable: false,
          filter: true,
        },
        valorPedido: {
          title: 'Valor del pedido',
          editable: false,
          filter: true,
        },
        noReferencias: {
          title: 'No. de referencias',
          editable: false,
          filter: true,
        },
        puntosRedimidos: {
          title: 'Puntos redimidos',
          editable: false,
          filter: true,
        },
        codigoRedencion: {
          title: 'Código de redención',
          editable: false,
          filter: true,
        },
        estadoCodigo: {
          title: 'Estado código',
          editable: false,
          filter: true,
        },
      },
    };

    this.settings_clientes_asignados = {
      noDataMessage: 'Aún no tienes información para ver por aquí',
      pager: {
        display: true,
        perPage: 4,
      },
      actions: false,
      hideSubHeader: false,
      columns: {
        distribuidor: {
          title: 'Distribuidor',
          editable: false,
          filter: true,
        },
        personaDelEquipoComercial: {
          title: 'Persona del equipo comercial',
          editable: false,
          filter: true,
        },
        tipoNegocio: {
          title: 'Tipo de Negocio',
          editable: false,
          filter: true,
        },
        tipoDePersonas: {
          title: 'Tipo de persona',
          editable: false,
          filter: true,
        },
        nit: {
          title: 'NIT/CC/CE',
          editable: false,
          filter: true,
        },
        razonSocial: {
          title: 'Razón social',
          editable: false,
          filter: true,
        },
        nombreComercialEstablecimiento: {
          title: 'Nombre comercial establecimiento',
          editable: false,
          filter: true,
        },
        email: {
          title: 'Email',
          editable: false,
          filter: true,
        },
        telefono: {
          title: 'Teléfono',
          editable: false,
          filter: true,
        },
        pais: {
          title: 'País',
          editable: false,
          filter: true,
        },
        departamento: {
          title: 'Departamento',
          editable: false,
          filter: true,
        },
        ciudad: {
          title: 'Ciudad',
          editable: false,
          filter: true,
        },
        nombrePunto: {
          title: 'Nombre punto entrega',
          editable: false,
          filter: true,
        },
        direccion: {
          title: 'Dirección',
          editable: false,
          filter: true,
        },
        noSillas: {
          title: 'No. sillas',
          editable: false,
          filter: true,
        },
        domicilio: {
          title: 'Domicilio',
          editable: false,
          filter: true,
        },
        precioEspecial: {
          title: 'Precio especial',
          editable: false,
          filter: true,
        },
        temaCartera: {
          title: 'Tema cartera',
          editable: false,
          filter: true,
        },
        nombreAdmin: {
          title: 'Nombre admininistrador del punto',
          editable: false,
          filter: true,
        },
        emailPunto: {
          title: 'Email',
          editable: false,
          filter: true,
        },
        telefonoPunto: {
          title: 'Teléfono',
          editable: false,
          filter: true,
        },
        pedidosComprados: {
          title: 'Pedidos comprados y entregados (últimos 3 meses)',
          editable: false,
          filter: true,
        },
        numeroReferencias: {
          title: '# de referencias (últimos 3 meses)',
          editable: false,
          filter: true,
        },
        valorPedido: {
          title: 'Valor pedido (últimos 3 meses)',
          editable: false,
          filter: true,
        },
      },
    };
    this.settings_saldos_promos = {
      noDataMessage: 'Aún no tienes información para ver por aquí',
      pager: {
        display: true,
        perPage: 4,
      },
      actions: false,
      hideSubHeader: false,
      columns: {
        distribuidor: {
          title: 'Distribuidor',
          editable: false,
          filter: true,
        },
        tipoActividad: {
          title: 'Tipo de actividad',
          editable: false,
          filter: true,
        },
        codigoDistri: {
          title: 'Código distribuidor promoción o saldo',
          editable: false,
          filter: true,
        },
        nombrePromo: {
          title: 'Nombre de la promoción o saldo',
          editable: false,
          filter: true,
        },
        codigoDist1: {
          title: 'Código dist producto 1',
          editable: false,
          filter: true,
        },
        nombreProducto1: {
          title: 'Nombre producto 1',
          editable: false,
          filter: true,
        },
        unidadesCodigo: {
          title: 'Unidades codigo dist producto 1',
          editable: false,
          filter: true,
        },
        codigoDist2: {
          title: 'Código dist producto 2',
          editable: false,
          filter: true,
        },
        nombreProducto2: {
          title: 'Nombre producto 2',
          editable: false,
          filter: true,
        },
        unidadesCodigo2: {
          title: 'Unidades codigo dist producto 2',
          editable: false,
          filter: true,
        },
        codigoDist3: {
          title: 'Código dist producto 3',
          editable: false,
          filter: true,
        },
        nombreProducto3: {
          title: 'Nombre producto 3',
          editable: false,
          filter: true,
        },
        unidadesCodigo3: {
          title: 'Unidades codigo dist producto 3',
          editable: false,
          filter: true,
        },
        precioConPromocion: {
          title: 'Precio con promoción',
          editable: false,
          filter: true,
        },
        precioSinPromocion: {
          title: 'Precio sin promoción',
          editable: false,
          filter: true,
        },
        fechaInicio: {
          title: 'Fecha de inicio',
          editable: false,
          filter: true,
        },
        fechaCierre: {
          title: 'Fecha de cierre',
          editable: false,
          filter: true,
        },
        unidadesOfrecidas: {
          title: 'Unidades ofrecidas',
          editable: false,
          filter: true,
        },
        unidadesVendidas: {
          title: 'Unidades vendidas',
          editable: false,
          filter: true,
        },
        ventaTotal: {
          title: 'Venta total',
          editable: false,
          filter: true,
        },
        establecimientosAlcanzados: {
          title: '# Establecimientos alcanzados',
          editable: false,
          filter: true,
        },
        sillasAlcanzadas: {
          title: '# de sillas alcanzadas',
          editable: false,
          filter: true,
        },
        estado: {
          title: 'Estado',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /*************************** Clientes **************************/
  private configSmartTableClientes(): void {
    this.settings_clientes = {
      noDataMessage: 'Aún no tienes información para ver por aquí',
      pager: {
        display: true,
        perPage: 10,
      },
      actions: false,
      hideSubHeader: false,
      columns: {
        vinculacion_estado: {
          title: 'Estado de vinculación',
          editable: false,
          filter: true,
        },
        horeca_tipo_negocio: {
          title: 'Tipo de negocio',
          editable: false,
          filter: true,
        },
        horeca_tipo_usuario: {
          title: 'Tipo de persona',
          editable: false,
          filter: true,
        },
        horeca_nit: {
          title: 'NIT/CC/CE',
          editable: false,
          filter: true,
        },
        horeca_razon_social: {
          title: 'Razón social',
          editable: false,
          filter: true,
        },
        horeca_nombre: {
          title: 'Nombre comercial establecimiento',
          editable: false,
          filter: true,
        },
        horeca_correo: {
          title: 'Email',
          editable: false,
          filter: true,
        },
        horeca_celular: {
          title: 'Teléfono',
          editable: false,
          filter: true,
        },
        horeca_telefono: {
          title: 'Celular',
          editable: false,
          filter: true,
        },
        punto_nombre: {
          title: 'Punto de entrega',
          editable: false,
          filter: true,
        },
        punto_pais: {
          title: 'País',
          editable: false,
          filter: true,
        },
        punto_departamento: {
          title: 'Departamento',
          editable: false,
          filter: true,
        },
        punto_ciudad: {
          title: 'Ciudad',
          editable: false,
          filter: true,
        },
        punto_direccion: {
          title: 'Dirección',
          editable: false,
          filter: true,
        },
        punto_sillas: {
          title: 'No. sillas',
          editable: false,
          filter: true,
        },
        punto_domicilios: {
          title: 'Domicilio',
          editable: false,
          filter: true,
        },
        vinculacion_convenio: {
          title: 'Convenio especial',
          editable: false,
          filter: true,
        },
        vinculacion_cartera: {
          title: 'Cartera vencida',
          editable: false,
          filter: true,
        },
        punto_encargado: {
          title: 'Nombre del encargado del punto',
          editable: false,
          filter: true,
        },
        punto_encargado_email: {
          title: 'Email del encargado del punto',
          editable: false,
          filter: true,
        },
        punto_encargado_telefono: {
          title: 'Teléfono del encargado del punto',
          editable: false,
          filter: true,
        },
        vinculacion_nombre_trabajador: {
          title: 'Persona del equipo comercial',
          editable: false,
          filter: true,
        },
        compra_pedidos_numero: {
          title: 'Pedidos comprados y  entregados (últimos 3 meses)',
          editable: false,
          filter: true,
        },
        compra_pedidos_productos: {
          title: '# referencias (últimos 3 meses)',
          editable: false,
          filter: true,
        },
        compra_pedidos_valor: {
          title: 'Valor pedidos (últimos 3 meses COP)',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  private cargarDatosTablaClientes(): void {
    this.restService
      .getJWT('informes/distribuidor/tabla/clientes/' + this.id_distribuidor)
      .toPromise()
      .then((clientes: any) => {
        clientes.data.forEach((cliente: any) => {
          const obj_clientes = {
            horeca_tipo_negocio: cliente.horeca_tipo_negocio,
            horeca_tipo_usuario: cliente.horeca_tipo_usuario,
            horeca_correo: cliente.horeca_correo,
            horeca_nit: cliente.horeca_nit,
            horeca_telefono: cliente.horeca_telefono,
            horeca_nombre: cliente.horeca_nombre,
            horeca_razon_social: cliente.horeca_razon_social,
            horeca_celular: cliente.horeca_celular,
            punto_nombre: cliente.punto_nombre,
            punto_pais: cliente.punto_pais,
            punto_departamento: cliente.punto_departamento,
            punto_ciudad: cliente.punto_ciudad,
            punto_direccion: cliente.punto_direccion,
            punto_telefono: cliente.punto_telefono,
            punto_celular: cliente.punto_celular,
            punto_encargado: cliente.punto_encargado[0] + ' ' + cliente.punto_encargado[1],
            punto_encargado_email: cliente.punto_encargado[2] || '-',
            punto_encargado_telefono: cliente.punto_encargado[3] || '-',
            punto_sillas: cliente.punto_sillas,
            punto_domicilios: cliente.punto_domicilios == true ? 'Si' : 'No',
            vinculacion_estado: cliente.vinculacion_estado,
            vinculacion_convenio: cliente.vinculacion_convenio == true ? 'Si' : 'No',
            vinculacion_cartera: cliente.vinculacion_cartera == true ? 'Si' : 'No',
            vinculacion_nombre_trabajador: cliente.vinculacion_nombre_trabajador[0] || '-',
            compra_pedidos_numero: cliente.compra_pedidos_numero,
            compra_pedidos_valor: cliente.compra_pedidos_valor != undefined ? cliente.compra_pedidos_valor : 0,
            compra_pedidos_productos: cliente.compra_pedidos_productos,
          };
          this.data_clientes.push(obj_clientes);
        });
        this.source_clientes = new LocalDataSource(this.data_clientes);
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
  }

  /**
   * Llevar tabla a archivo excel y descargarlo
   */
  public descargaArchivoClientes() {
    const object_keys = Object.keys(this.settings_clientes.columns);
    const primera_fila: any[] = [];
    object_keys.forEach((element: any) => {
      primera_fila.push(this.settings_clientes.columns[element].title);
    });
    /** Arma el contenido de la tabla con titulos*/
    const arreglo_final: any[] = [];
    arreglo_final.push(primera_fila);
    this.data_clientes.forEach((element: any) => {
      const array = [];
      for (const column of object_keys) {
        array.push(element[column]);
      }
      arreglo_final.push(array);
    });
    this.DescargaExcel({ data: arreglo_final, nombreArchivo: `Clientes.xlsx` });
  }

  /*************************** Pedidos **************************/
  private configSmartTablePedidos(): void {
    this.settings_pedidos = {
      noDataMessage: 'Aún no tienes información para ver por aquí',
      pager: {
        display: true,
        perPage: 10,
      },
      actions: false,
      hideSubHeader: false,
      columns: {
        horeca_nombre: {
          title: 'Establecimiento',
          editable: false,
          filter: true,
        },
        horeca_tipo_usuario: {
          title: 'Tipo de persona',
          editable: false,
          filter: true,
        },
        horeca_nit: {
          title: 'NIT/CC/CE Establecimiento',
          editable: false,
          filter: true,
        },
        horeca_pais: {
          title: 'País',
          editable: false,
          filter: true,
        },
        horeca_departamento: {
          title: 'Departamento',
          editable: false,
          filter: true,
        },
        horeca_ciudad: {
          title: 'Ciudad',
          editable: false,
          filter: true,
        },
        punto_nombre: {
          title: 'Punto de entrega',
          editable: false,
          filter: true,
        },
        horeca_tipo_negocio: {
          title: 'Tipo Negocio',
          editable: false,
          filter: true,
        },
        pedido_fecha: {
          title: 'Fecha de pedido',
          editable: false,
          filter: true,
        },
        pedido_id: {
          title: 'No. pedido',
          editable: false,
          filter: true,
        },
        pedido_estado: {
          title: 'Estado Actual',
          editable: false,
          filter: true,
        },
        pedido_fecha_aprobado: {
          title: 'Hora aprobado',
          editable: false,
          filter: true,
        },
        pedido_fecha_entregado: {
          title: 'Hora entrega',
          editable: false,
          filter: true,
        },
        pedido_valor: {
          title: 'Valor pedido (COP)',
          editable: false,
          filter: true,
        },
        pedido_numero_productos: {
          title: 'No. De referencias',
          editable: false,
          filter: true,
        },
        pedido_puntos: {
          title: 'Puntos Ft. ganados',
          editable: false,
          filter: true,
        },
        pedido_puntos_redimidos: {
          title: 'Puntos Ft. redimidos',
          editable: false,
          filter: true,
        },
        pedido_cod_descuento_generado: {
          title: 'Código de redención',
          editable: false,
          filter: true,
        },
        pedido_estado_cod_descuento: {
          title: 'Estado código',
          editable: false,
          filter: true,
        },
        equipo_comercial: {
          title: 'Persona del equipo comercial',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  private cargarDatosTablaPedidos(): void {
    this.restService
      .getJWT('informes/distribuidor/tabla/pedidos/' + this.id_distribuidor)
      .toPromise()
      .then((pedidos: any) => {
        pedidos.data.forEach((pedido: any) => {
          // Ajusta zonas horarios
          let fecha_pedido: any;
          let pedido_fecha_aprobado: any;
          let pedido_fecha_entregado: any;
          if (pedido.pedido_fecha && pedido.pedido_fecha != null) {
            //fecha_pedido = new Date(pedido.pedido_fecha);
            //fecha_pedido.setMinutes(fecha_pedido.getMinutes() + fecha_pedido.getTimezoneOffset());
            fecha_pedido = moment(pedido.pedido_fecha).format('YYYY/MM/DD hh:mm A');
          }
          if (pedido.pedido_fecha_aprobado && pedido.pedido_fecha_aprobado != null) {
            //pedido_fecha_aprobado = new Date(pedido.pedido_fecha_aprobado);
            pedido_fecha_aprobado = moment(pedido.pedido_fecha_aprobado).format('YYYY/MM/DD hh:mm A');
          }
          if (pedido.pedido_fecha_entregado && pedido.pedido_fecha_entregado != null) {
            //pedido_fecha_entregado = new Date(pedido.pedido_fecha_entregado);
            pedido_fecha_entregado = moment(pedido.pedido_fecha_entregado).format('YYYY/MM/DD hh:mm A');
          }
          const obj_pedido_pedidos = {
            horeca_nombre: pedido.horeca_nombre,
            horeca_tipo_usuario: pedido.horeca_tipo_usuario,
            horeca_nit: pedido.horeca_nit,
            horeca_pais: pedido.horeca_pais,
            horeca_departamento: pedido.horeca_departamento,
            horeca_ciudad: pedido.horeca_ciudad,
            punto_nombre: pedido.punto_nombre,
            horeca_tipo_negocio: pedido.horeca_tipo_negocio,
            pedido_fecha: fecha_pedido ? pedido_fecha_entregado : '',
            pedido_id: pedido.pedido_id,
            pedido_estado: pedido.pedido_estado,
            pedido_fecha_aprobado: pedido_fecha_aprobado ? pedido_fecha_aprobado : '',
            pedido_fecha_entregado: pedido_fecha_entregado ? pedido_fecha_entregado : '',
            pedido_valor: pedido.pedido_valor != undefined ? pedido.pedido_valor : 0,
            pedido_numero_productos: pedido.pedido_numero_productos,
            pedido_puntos: pedido.pedido_puntos,
            pedido_puntos_redimidos: pedido.pedido_puntos_redimidos,
            pedido_cod_descuento_generado: pedido.pedido_cod_descuento_generado,
            pedido_estado_cod_descuento: pedido.pedido_estado_cod_descuento,
            equipo_comercial: this.getNombresComercial(pedido.equipo_comercial),
          };
          this.data_pedidos.push(obj_pedido_pedidos);
        });
        this.source_pedidos = new LocalDataSource(this.data_pedidos);
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
  }

  /**
   * Llevar tabla a archivo excel y descargarlo
   */
  public descargaArchivoPedidos() {
    const object_keys = Object.keys(this.settings_pedidos.columns);
    const primera_fila: any[] = [];
    object_keys.forEach((element: any) => {
      primera_fila.push(this.settings_pedidos.columns[element].title);
    });
    /** Arma el contenido de la tabla con titulos*/
    const arreglo_final: any[] = [];
    arreglo_final.push(primera_fila);
    this.data_pedidos.forEach((element: any) => {
      const array = [];
      for (const column of object_keys) {
        array.push(element[column]);
      }
      arreglo_final.push(array);
    });
    this.DescargaExcel({ data: arreglo_final, nombreArchivo: `Pedidos.xlsx` });
  }

  /*************************** Usuarios **************************/
  private configSmartTableUsuarios(): void {
    this.settings_usuarios = {
      noDataMessage: 'Aún no tienes información para ver por aquí',
      pager: {
        display: true,
        perPage: 10,
      },
      actions: false,
      hideSubHeader: false,
      columns: {
        trabajador_tipo: {
          title: 'Tipo Usuario',
          editable: false,
          filter: true,
        },
        trabajador_estado: {
          title: 'Estado',
          editable: false,
          filter: true,
        },
        trabajador_nombres: {
          title: 'Nombre',
          editable: false,
          filter: true,
        },
        trabajador_apellidos: {
          title: 'Apellido',
          editable: false,
          filter: true,
        },
        trabajador_tipo_documento: {
          title: 'Tipo de documento',
          editable: false,
          filter: true,
        },
        trabajador_numero_documento: {
          title: 'No. documento',
          editable: false,
          filter: true,
        },
        trabajador_correo: {
          title: 'Email',
          editable: false,
          filter: true,
        },
        trabajador_telefono: {
          title: 'Teléfono',
          editable: false,
          filter: true,
        },
        trabajador_celular: {
          title: 'Celular',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  private cargarDatosTablaUsuarios(): void {
    this.restService
      .getJWT('informes/distribuidor/tabla/usuarios/' + this.id_distribuidor)
      .toPromise()
      .then((usuarios: any) => {
        usuarios.data.forEach((usuario: any) => {
          let estado = '';
          if (usuario.trabajador_estado === 'Cancelado') {
            estado = 'Inactivo / Rechazado';
          } else {
            estado = usuario.trabajador_estado;
          }
          const obj_usuarios = {
            trabajador_tipo: usuario.trabajador_tipo,
            trabajador_estado: estado,
            trabajador_nombres: usuario.trabajador_nombres,
            trabajador_apellidos: usuario.trabajador_apellidos,
            trabajador_tipo_documento: usuario.trabajador_tipo_documento,
            trabajador_numero_documento: usuario.trabajador_numero_documento,
            trabajador_correo: usuario.trabajador_correo,
            trabajador_telefono: usuario.trabajador_telefono,
            trabajador_celular: usuario.trabajador_celular,
          };
          this.data_usuarios.push(obj_usuarios);
        });
        this.source_usuarios = new LocalDataSource(this.data_usuarios);
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
  }

  /**
   * Llevar tabla a archivo excel y descargarlo
   */
  public descargaArchivoUsuarios() {
    const object_keys = Object.keys(this.settings_usuarios.columns);
    const primera_fila: any[] = [];
    object_keys.forEach((element: any) => {
      primera_fila.push(this.settings_usuarios.columns[element].title);
    });
    /** Arma el contenido de la tabla con titulos*/
    const arreglo_final: any[] = [];
    arreglo_final.push(primera_fila);
    this.data_usuarios.forEach((element: any) => {
      const array = [];
      for (const column of object_keys) {
        array.push(element[column]);
      }
      arreglo_final.push(array);
    });
    this.DescargaExcel({ data: arreglo_final, nombreArchivo: `Usuarios.xlsx` });
  }

  /*************************** Portafolio **************************/
  private configSmartTablePortafolio(): void {
    this.settings_portafolio = {
      noDataMessage: 'Aún no tienes información para ver por aquí',
      pager: {
        display: true,
        perPage: 10,
      },
      actions: false,
      hideSubHeader: false,
      columns: {
        codigo_feat: {
          title: 'Código Feat',
          editable: false,
          filter: true,
        },
        codigo_organizacion_producto: {
          title: 'Código organización',
          editable: false,
          filter: true,
        },
        codigo_distribuidor_producto: {
          title: 'Código producto distribuidor',
          editable: false,
          filter: true,
        },
        nombre: {
          title: 'Nombre de Producto',
          editable: false,
          filter: true,
        },
        categoria: {
          title: 'Categoría de producto',
          editable: false,
          filter: true,
        },
        linea: {
          title: 'Línea de producto',
          editable: false,
          filter: true,
        },
        marca: {
          title: 'Marca',
          editable: false,
          filter: true,
        },
        organizacion: {
          title: 'Organización',
          editable: false,
          filter: true,
        },
        cantidad_medida: {
          title: 'Tamaño/cantidad',
          editable: false,
          filter: true,
        },
        unidad_medida: {
          title: 'Unidad de medida',
          editable: false,
          filter: true,
        },
        presentacion_Caja: {
          title: 'Unidades por caja',
          editable: false,
          filter: true,
        },
        precio_unidad: {
          title: 'Precio publico por unidad (COP)',
          editable: false,
          filter: true,
        },
        precio_caja: {
          title: 'Precio publico por caja (COP)',
          editable: false,
          filter: true,
        },
        inventario_unidad: {
          title: 'Inventario/stock por und.',
          editable: false,
          filter: true,
        },
        puntos_ft_unidad: {
          title: 'Puntos Ft. asignado por organización',
          editable: false,
          filter: true,
        },
        pedidos_valor: {
          title: 'Ventas del producto (últ. 3 meses COP)',
          editable: false,
          filter: true,
        },
        puntos_alcanzados: {
          title: '# Establecimientos alcanzados',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  private cargarDatosTablaPortafolio(): void {
    this.restService
      .getJWT('informes/distribuidor/tabla/productos/' + this.id_distribuidor)
      .toPromise()
      .then((portafolio: any) => {
        portafolio.data.forEach((producto: any) => {
          const obj_portafolio = {
            organizacion: producto.organizacion,
            codigo_organizacion_producto: producto.codigo_organizacion_producto,
            codigo_distribuidor_producto: producto.codigo_distribuidor_producto,
            codigo_feat: producto.codigo_feat,
            nombre: producto.nombre,
            categoria: producto.categoria,
            linea: producto.linea,
            marca: producto.marca,
            unidad_medida: producto.unidad_medida,
            cantidad_medida: producto.cantidad_medida,
            precio_unidad: producto.precio_unidad,
            precio_caja: producto.precio_caja,
            puntos_ft_unidad: producto.puntos_ft_unidad,
            presentacion_Caja: producto.presentacion_Caja,
            inventario_unidad: producto.inventario_unidad,
            puntos_alcanzados: producto.puntos_alcanzados,
            pedidos_valor: producto.pedidos_valor,
          };
          this.data_portafolio.push(obj_portafolio);
        });
        this.source_portafolio = new LocalDataSource(this.data_portafolio);
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
  }

  /**
   * Llevar tabla a archivo excel y descargarlo
   */
  descargaArchivoPortafolio() {
    const object_keys = Object.keys(this.settings_portafolio.columns);
    const primera_fila: any[] = [];
    object_keys.forEach((element: any) => {
      primera_fila.push(this.settings_portafolio.columns[element].title);
    });
    /** Arma el contenido de la tabla con titulos*/
    const arreglo_final: any[] = [];
    arreglo_final.push(primera_fila);
    this.data_portafolio.forEach((element: any) => {
      const array = [];
      for (const column of object_keys) {
        array.push(element[column]);
      }
      arreglo_final.push(array);
    });
    this.DescargaExcel({ data: arreglo_final, nombreArchivo: `Portafolio.xlsx` });
  }

  /*************************** Ventas **************************/
  private configSmartTableVentas(): void {
    this.settings_ventas = {
      noDataMessage: 'Aún no tienes información para ver por aquí',
      pager: {
        display: true,
        perPage: 10,
      },
      actions: false,
      hideSubHeader: false,
      columns: {
        pedido_id: {
          title: 'Número pedido',
          editable: false,
          filter: true,
        },
        pedido_fecha: {
          title: 'Fecha pedido',
          editable: false,
          filter: true,
        },
        horeca_nombre: {
          title: 'Nombre establecimiento',
          editable: false,
          filter: true,
        },
        horeca_tipo_usuario: {
          title: 'Tipo persona',
          editable: false,
          filter: true,
        },
        horeca_nit: {
          title: 'NIT/CC/CE establecimiento',
          editable: false,
          filter: true,
        },
        horeca_tipo_negocio: {
          title: 'Tipo negocio',
          editable: false,
          filter: true,
        },
        punto_pais: {
          title: 'País',
          editable: false,
          filter: true,
        },
        punto_departamento: {
          title: 'Departamento',
          editable: false,
          filter: true,
        },
        punto_ciudad: {
          title: 'Ciudad',
          editable: false,
          filter: true,
        },
        punto_nombre: {
          title: 'Punto de entrega',
          editable: false,
          filter: true,
        },
        punto_sillas: {
          title: 'Número de sillas',
          editable: false,
          filter: true,
        },
        punto_domicilios: {
          title: 'Tiene domicilio',
          editable: false,
          filter: true,
        },
        producto_nombre: {
          title: 'Nombre producto',
          editable: false,
          filter: true,
        },
        producto_categoria: {
          title: 'Categoría producto',
          editable: false,
          filter: true,
        },
        producto_linea: {
          title: 'Linea producto',
          editable: false,
          filter: true,
        },
        producto_marca: {
          title: 'Marca producto',
          editable: false,
          filter: true,
        },
        producto_organizacion: {
          title: 'Organización',
          editable: false,
          filter: true,
        },
        producto_codigo_organizacion: {
          title: 'Código organización',
          editable: false,
          filter: true,
        },
        producto_codigo_distribuidor: {
          title: 'Código producto distribuidor',
          editable: false,
          filter: true,
        },
        producto_unidad_medida: {
          title: 'Tamaño/cantidad',
          editable: false,
          filter: true,
        },
        producto_cantidad_medida: {
          title: 'Unidad de medida',
          editable: false,
          filter: true,
        },
        pedido_cajas: {
          title: 'Cajas solicitadas',
          editable: false,
          filter: true,
        },
        pedido_unidades: {
          title: 'Unidades solicitadas',
          editable: false,
          filter: true,
        },
        pedido_total: {
          title: 'Valor total (COP)',
          editable: false,
          filter: true,
        },
        pedido_puntos_acumulados: {
          title: 'Puntos Feat acumulados',
          editable: false,
          filter: true,
        },
        equipo_comercial: {
          title: 'Vendedor distribuidor asignado',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  private cargarDatosTablaVentas(): void {
    this.restService
      .getJWT('informes/distribuidor/tabla/ventas/' + this.id_distribuidor)
      .toPromise()
      .then((ventas: any) => {
        ventas.data.forEach((venta: any) => {
          const fecha_pedido = new Date(venta.pedido_fecha);
          fecha_pedido.setMinutes(fecha_pedido.getMinutes() + fecha_pedido.getTimezoneOffset());
          const obj_ventas = {
            pedido_id: venta.pedido_id,
            pedido_fecha: this.datePipe.transform(fecha_pedido, 'yyyy-MM-dd'),
            horeca_nombre: venta.horeca_nombre,
            horeca_tipo_usuario: venta.horeca_tipo_usuario,
            horeca_nit: venta.horeca_nit,
            horeca_tipo_negocio: venta.horeca_tipo_negocio,
            punto_pais: venta.punto_pais,
            punto_departamento: venta.punto_departamento,
            punto_ciudad: venta.punto_ciudad,
            punto_nombre: venta.punto_nombre,
            punto_sillas: venta.punto_sillas,
            punto_domicilios: venta.punto_domicilios == true ? 'Si' : 'No',
            producto_nombre: venta.producto_nombre,
            producto_categoria: venta.producto_categoria,
            producto_linea: venta.producto_linea,
            producto_marca: venta.producto_marca,
            producto_organizacion: venta.producto_organizacion,
            producto_codigo_organizacion: venta.producto_codigo_organizacion,
            producto_codigo_distribuidor: venta.producto_codigo_distribuidor,
            producto_unidad_medida: venta.producto_unidad_medida,
            producto_cantidad_medida: venta.producto_cantidad_medida,
            pedido_cajas: venta.pedido_cajas,
            pedido_unidades: venta.pedido_unidades,
            pedido_total: venta.pedido_total != undefined ? venta.pedido_total : 0,
            pedido_puntos_acumulados: venta.pedido_puntos_acumulados,
            equipo_comercial: venta.equipo_comercial,
          };
          this.data_ventas.push(obj_ventas);
        });
        this.source_ventas = new LocalDataSource(this.data_ventas);
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
  }

  /**
   * Llevar tabla a archivo excel y descargarlo
   */
  descargaArchivoVentas() {
    const object_keys = Object.keys(this.settings_ventas.columns);
    const primera_fila: any[] = [];
    object_keys.forEach((element: any) => {
      primera_fila.push(this.settings_ventas.columns[element].title);
    });
    /** Arma el contenido de la tabla con titulos*/
    const arreglo_final: any[] = [];
    arreglo_final.push(primera_fila);
    this.data_ventas.forEach((element: any) => {
      const array = [];
      for (const column of object_keys) {
        array.push(element[column]);
      }
      arreglo_final.push(array);
    });
    this.DescargaExcel({ data: arreglo_final, nombreArchivo: `Ventas.xlsx` });
  }

  /**
   * Descargue a excel un listado
   */
  public DescargaExcel(obj: any) {
    const readyToExport = obj.data;
    const workBook = XLSX.utils.book_new(); // create a new blank book
    const workSheet = XLSX.utils.aoa_to_sheet(readyToExport);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
    XLSX.writeFile(workBook, obj.nombreArchivo); // initiate a file download in browser
  }

  /**
   * Traduce objeto con nombres y apellidos de trabajadores a un string
   */
  getNombresComercial(nombres: any) {
    let array_nombres: any[] = [];
    nombres.forEach((nombre: any) => {
      if (nombre.data_trabajador[0]) {
        array_nombres.push(nombre.data_trabajador[0].nombres + ' ' + nombre.data_trabajador[0].apellidos);
      }
      /*if (nombre[0]) {
        array_nombres.push(nombre[0].nombres + ' ' + nombre[0].apellidos);
      }*/
    });
    array_nombres = array_nombres.splice(0, 2);
    return array_nombres.join(' - ');
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      // this.router.navigate(['/inicio-distribuidor']);
    };
  }
}
