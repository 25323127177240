import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.css'],
})
export class PedidosComponent implements OnInit {
  constructor() {}

  /** Variable de control para la navegación del panel */
  active = 1;

  ngOnInit(): void {}
}
