<div class="w-100 graficas-container row m-0 overflow-hidden">
    <!--Productos comprados-->
    <div class="productos-comprados col-12 col-lg-6">
        <div class="d-flex justify-content-between mb-4">
            <div class="pr-3">Referencias compradas al distribuidor</div>
            <div class="total-wrapper">
                Total: {{ total_compras_distribuidor }} prod.
            </div>
        </div>
        <div class="d-flex flex-row justify-content-center card p-3 mb-3"
            [ngClass]="{'inactive': total_compras_distribuidor == 0}" *ngFor="let compra of compras_distribuidor">
            <div class="col-3">
                <img [src]="compra.foto" alt="recipiente de aceite de oliva" class="producto-imagen" />
            </div>
            <div class="col-9 d-flex flex-column justify-content-between">
                <p class="m-0">{{compra._id}}</p>
                <p class="m-0">
                    {{compra.unidades}} und. &nbsp; {{compra.cajas}} caja{{compra.cajas == 1 ? '' :
                    's'}}
                </p>
            </div>
        </div>
        <div *ngIf="total_compras_distribuidor > 0 else mostrarError"></div>
    </div>
    <!--Compras por categoría-->
    <div class="compras-categorias col-12 col-lg-6 card">
        <div class="d-flex justify-content-between mb-4">
            <div class="pr-3">Compras por categoría</div>
            <div class="total-wrapper">
                Total: {{ total_compras_categoria }}
            </div>
        </div>
        <div [ngClass]="{'inactive': data_pie_compras_categoria.datasets.length == 0}">
            <canvas #pie_compras_categoria style="height: 50px; width: 100px"></canvas>
        </div>
        <div *ngIf="data_pie_compras_categoria.datasets.length > 0 else mostrarError"></div>
    </div>
    <!-- Manejo de errores -->
    <ng-template #mostrarError>
        <div class="d-flex justify-content-center mt-4">
            <div>
                <img src="assets/img/icon-warning-amarillo.png" alt="icono de una hoja de papel y una lupa sobre ella"
                    style="max-width: 150px;" />
            </div>
        </div>
        <div class="mt-2">
            <p class="text-center">
                ¡Lo siento! No existen datos para ver aquí
            </p>
        </div>
    </ng-template>
</div>