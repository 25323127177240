import { Component, DoCheck } from '@angular/core';
import { faSignInAlt, faEyeSlash, faEye, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { Trabajador } from 'src/app/models/trabajador.model';
import { RestService } from 'src/app/services/rest/rest.service';
import { Store } from '@ngxs/store';
import { SetSelectedToken } from 'src/app/actions/token.actions';
import { Distribuidor } from 'src/app/models/distribuidor.model';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/services/tools/shared.service';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';

@Component({
  selector: 'app-form-login',
  templateUrl: './form-login.component.html',
  styleUrls: ['./form-login.component.css'],
})
export class FormLoginComponent implements DoCheck {
  public faSignInAlt = faSignInAlt;
  public faEyeSlash = faEyeSlash;
  public faEye = faEye;
  public faChevronLeft = faChevronLeft;
  /** Flag para mostrar u ocultar contraseña */
  public fieldTextType = false;
  /** Flag para cambiar entre login y recordar clave */
  public showForgotPassword = false;
  /** Modal de carga para darle feedback al usuario */
  public modalCarga?: NgbModalRef;
  /** Datos de autenticación */
  public email = '';
  public password = '';
  /** Flags para indicar errrores */
  public error_email = false;
  public error_password = false;
  public error_form = false;
  /** Data no completa login */
  public loign_valido = false;
  /**Observable para compartir funcion de login */
  public clickEventSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    public modalService: NgbModal,
    private restService: RestService,
    private sharedService: SharedService,
    private ngxsStore: Store
  ) {
    this.sharedService.correoActual.subscribe((email) => (this.email = email));
    this.sharedService.claveActual.subscribe((password) => (this.password = password));
    this.clickEventSubscription = this.sharedService.getLoginEvent().subscribe(() => {
      this.login(this.email, this.password);
    });
  }

  ngDoCheck(): void {
    if (this.email === '' || this.password === '') {
      this.loign_valido = false;
    } else {
      this.loign_valido = true;
    }
  }
  /**
   * Se llama con el formulario de inicio de sesión
   */
  login(email: string, password: string) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    this.error_email = false;
    this.error_password = false;
    this.error_form = false;
    /**
     * Cuando se hace el login a través de otro compenente, se envian las credenciales
     * en la función, de lo contrario se toman del input de esta sección.
     */
    if (email == '') {
      email = this.email;
    }
    if (password == '') {
      password = this.password;
    }
    /**
     * se abre el modal generico solo si es un login que no viene de un registro
     */
    if (this.password === '') {
      this.modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    }
    /**
     * Proceso de Login
     */
    if (email.trim() == '' || password.trim() == '') {
      this.error_form = true;
    } else {
      this.authService
        .signIn(email, password)
        .toPromise()
        .then((resp: any) => {
          if (resp.success) {
            this.modalCarga?.close();
            const usuario_resp: any = resp.usuario;
            this.authService.email = email;
            this.authService.token = resp.token;
            this.authService.user = new Trabajador(
              usuario_resp.nombres,
              usuario_resp.apellidos,
              usuario_resp.correo,
              usuario_resp.clave,
              usuario_resp.telefono,
              usuario_resp.celular,
              usuario_resp.pais,
              usuario_resp.departamento,
              usuario_resp.ciudad,
              usuario_resp.tipo_documento,
              usuario_resp.numero_documento || '',
              usuario_resp.tipo_trabajador,
              usuario_resp.solicitud_vinculacion,
              usuario_resp.show_slides,
              usuario_resp.usuario_horeca,
              usuario_resp.puntos_entrega,
              usuario_resp.distribuidor,
              usuario_resp.organizacion,
              usuario_resp.crado_por_horeca,
              usuario_resp._id
            );
            if (this.authService.user?.solicitud_vinculacion != 'Aprobado') {
              const ngbModalOptions: NgbModalOptions = {
                //Evita que al hacer click por fuera se cierre el modal
                backdrop: 'static',
                keyboard: false,
              };
              const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
              modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
              modalRef.componentInstance.title = '¡Oh oh!';
              modalRef.componentInstance.msg = `Este usuario tiene una solicitud en estado "${this.authService.user?.solicitud_vinculacion}". Si eres un trabajador,
                ponte en contacto con tu empleador, y si eres un propietario o administrador, por favor ponte en contacto con Feat.`;
              modalRef.componentInstance.btn_msg = 'Volver';
              modalRef.componentInstance.close_callback = () => {
                this.authService.signOut();
              };
              return;
            }
            if (this.authService.user?.usuario_horeca != undefined) {
              //El trabajador que inició sesión es parte de un Horeca
              this.restService
                .getJWT('usuario_horeca/' + this.authService.user?.usuario_horeca)
                .toPromise()
                .then((resp_2: any) => {
                  this.authService.user_horeca = resp_2;
                  //Se guarda la info de inicio de sesión en el Store
                  this.ngxsStore
                    .dispatch(
                      new SetSelectedToken({
                        token: this.authService.token,
                        email: this.authService.email,
                        user: this.authService.user,
                        user_horeca: this.authService.user_horeca,
                        user_distribuidor: undefined,
                        user_organizacion: undefined,
                      })
                    )
                    .toPromise()
                    .then();
                  this.router.navigate(['/inicio']);
                });
            } else if (this.authService.user?.distribuidor != undefined) {
              //El trabajador que inició sesión es parte de un Distribuidor
              this.restService
                .getJWT('distribuidor/' + this.authService.user?.distribuidor)
                .toPromise()
                .then((resp_2: any) => {
                  this.authService.user_distribuidor = new Distribuidor(
                    resp_2.nombre,
                    resp_2.correo,
                    resp_2.descripcion,
                    resp_2.tiempo_entrega,
                    resp_2.ciudad,
                    resp_2.ranking,
                    resp_2.valor_minimo_pedido,
                    resp_2.departamento,
                    resp_2.tipo || '',
                    resp_2.logo || '',
                    resp_2._id,
                    resp_2.cobertura_coordenadas || [],
                    resp_2.horario_atencion,
                    resp_2.metodo_pago,
                    resp_2.direccion,
                    resp_2.nit_cc,
                    resp_2.razon_social || '',
                    resp_2.solicitud_vinculacion,
                    resp_2.celular,
                    resp_2.telefono || '',
                    resp_2.top_productos,
                    resp_2.max_establecimientos || 0,
                    resp_2.urlPago || '',
                    resp_2.datos_poligono || [],
                    resp_2.zonas_cobertura || {}
                  );
                  //Se guarda la info de inicio de sesión en el Store
                  this.ngxsStore
                    .dispatch(
                      new SetSelectedToken({
                        token: this.authService.token,
                        email: this.authService.email,
                        user: this.authService.user,
                        user_horeca: undefined,
                        user_distribuidor: this.authService.user_distribuidor,
                        user_organizacion: undefined,
                      })
                    )
                    .toPromise()
                    .then();
                  this.router.navigate(['/inicio-distribuidor']);
                });
            } else if (this.authService.user?.organizacion != undefined) {
              //El trabajador que inició sesión es parte de una organizacion
              this.restService
                .getJWT('organizacion/' + this.authService.user?.organizacion)
                .toPromise()
                .then((resp_2: any) => {
                  //TODO: Definir el modelo correctamente
                  this.authService.user_organizacion = resp_2;
                  //Se guarda la info de inicio de sesión en el Store
                  this.ngxsStore
                    .dispatch(
                      new SetSelectedToken({
                        token: this.authService.token,
                        email: this.authService.email,
                        user: this.authService.user,
                        user_horeca: undefined,
                        user_distribuidor: undefined,
                        user_organizacion: this.authService.user?.organizacion,
                      })
                    )
                    .toPromise()
                    .then();

                  this.router.navigate(['/inicio-organizacion']);
                });
            }
          } else {
            this.modalCarga?.close();
            if (resp.msg == 'Clave incorrecta') {
              this.error_password = true;
            } else if (resp.msg == 'usuario no encontrado') {
              this.error_email = true;
            }
          }
        })
        .catch((error) => {
          this.modalCarga?.close();
          //TODO: Hacer mejor manejo de errores
          console.log(error);
        });
    }
  }

  /**
   * Revisa el correo ingresado por el usuario y, de encontrarse
   * en el sistema, envía un mensaje de recuperación al
   * correo ingresado
   */
  forgotPassword() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
    };
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);

    this.error_form = false;

    if (this.email.trim() == '') {
      this.modalCarga?.close();
      this.error_form = true;
    } else {
      let href = 'https://featapp.co/recordar-clave/';
      const now: Date = new Date();

      //Encuentra el id del correo ingresado
      this.restService
        .get('trabajador')
        .toPromise()
        .then((resp: any) => {
          let i = 0;
          for (i = 0; i < resp.length; i++) {
            if (resp[i].correo == this.email.trim()) {
              href += resp[i]._id;
              break;
            }
          }

          if (href.endsWith('/recordar-clave/')) {
            this.modalCarga?.close();
            const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
            modalRef.componentInstance.title = '¡Oh oh!';
            modalRef.componentInstance.msg =
              'El correo ingresado no se encontró en nuestro sistema. Por favor revisa el correo e intenta de nuevo.';
            modalRef.componentInstance.btn_msg = 'Listo';
          } else {
            const obj_aux: any = {
              asunto: 'Recupera tu contraseña Feat',
              destinatario: this.email.trim(),
              mensaje: href,
              url: href,
              fecha: `${now.getFullYear}-${now.getMonth}-${now.getDate}`,
              tipo_mensaje: 'Recuperar contraseña',
            };

            this.restService
              .post('correo/recuperar-clave', obj_aux)
              .toPromise()
              .then(() => {
                const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
                modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
                modalRef.componentInstance.title = '¡Genial!';
                modalRef.componentInstance.msg =
                  'Se envió un correo de recuperación a ' +
                  this.email +
                  '. Revisa tu bandeja de entrada y de no deseados, y sigue las instrucciones para iniciar sesión.';
                modalRef.componentInstance.btn_msg = 'Listo';
                modalRef.componentInstance.close_callback = () => {
                  this.showForgotPassword = false;
                  this.modalCarga?.close();
                };
              });
          }
        })
        .catch(() => {
          this.modalCarga?.close();
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg = 'Ocurrió un error inesperado. Por favor intenta de nuevo más tarde.';
          modalRef.componentInstance.btn_msg = 'Listo';
        });
    }
  }

  /**
   * Es llamado con el texto de "Olvidé mi contraseña"
   */
  toggleForgotPassword() {
    this.error_form = false;
    this.showForgotPassword = !this.showForgotPassword;
  }

  /**
   * Oculta o revela la contraseña al usuario
   */
  mostrarOcultarPassword() {
    this.fieldTextType = !this.fieldTextType;
  }
}
