<div class="row">
  <div class="col-1">
  </div>
  <div class="col-12 text-left px-5 py-4">
    <br />
    <!--Header-->
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <h4>
              Crea nuevo punto
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ngb-progressbar type="warning" [max]="2" height="0.7rem" [value]="paso_actual"></ngb-progressbar>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-6">
            <h6>
              Datos del punto
            </h6>
          </div>
          <div class="col-6 text-right">
            <h6>
              Paso {{ paso_actual }}/2
            </h6>
          </div>
        </div>
        <br />
      </div>
    </div>
    <!--Contenido-->
    <div class="row">
      <!--Paso 1-->
      <div class="col-12" id="form-scroll" [hidden]="paso_actual !== 1" [formGroup]="crearPuntoFormPaso1">
        <div class="row locacion mb-3">
          <!--Nombre del punto-->
          <div class="col-md-6 col-sm-12">
            <label for="nombre_punto" class="w-100">
              <span class="asterisco">*</span>Nombre del punto
            </label>
            <input formControlName="puntoNombre" name="nombre_punto" type="text" placeholder="Escribe aquí"
              [(ngModel)]="nombre"
              [ngClass]="{'invalid-warning': (crearPuntoFormPaso1.get('puntoNombre')?.hasError('required') && crearPuntoFormPaso1.get('puntoNombre')?.touched)}" />
          </div>
          <!--Pais-->
          <div class="col-md-6 col-sm-12">
            <label for="pais"><span class="asterisco">*</span>País</label>
            <select formControlName="puntoPais" name="pais" id="" class="w-100" [(ngModel)]="pais"
              [ngClass]="{'invalid-warning': (crearPuntoFormPaso1.get('puntoPais')?.hasError('required') && crearPuntoFormPaso1.get('puntoPais')?.touched)}">
              <option value="Colombia" selected>Colombia</option>
            </select>
          </div>
        </div>
        <div class="row locacion mb-3">
          <!--Departamento-->
          <div class="col-md-6 col-sm-12">
            <label for="departamento"><span class="asterisco">*</span>Departamento</label>
            <select [disabled]="true" formControlName="puntoDepartamento" name="departamento" id=""
              [(ngModel)]="departamento" class="w-100" (change)='getPlaces()'
              [ngClass]="{'invalid-warning': (crearPuntoFormPaso1.get('puntoDepartamento')?.hasError('required') && crearPuntoFormPaso1.get('puntoDepartamento')?.touched)}">
              <option *ngFor="let dpto of departamentos; index as i;" [ngValue]="dpto.name">
                {{ dpto.name }}
              </option>
            </select>
          </div>
          <!--Ciudad-->
          <div class="col-md-6 col-sm-12">
            <label for="ciudad"><span class="asterisco">*</span>Ciudad</label>
            <select formControlName="puntoCiudad" class="option_city" name="ciudad" id="" [(ngModel)]="ciudad" (ngModelChange)="buscarDireccion()"
              class="w-100" (keydown)="buscarDireccion()"
              [ngClass]="{'invalid-warning': (crearPuntoFormPaso1.get('puntoCiudad')?.hasError('required') && crearPuntoFormPaso1.get('puntoCiudad')?.touched)}">
              <option *ngFor="let city of ciudades; index as i;" [ngValue]="city.name" class="option_city">
                {{ city.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mb-3">
          <!--Dirección-->
          <div class="col-md-6 col-sm-12">
            <label for="direccion">
              <span class="asterisco">*</span>Dirección
            </label>
            <input
              #addressInput
              class="form-control"
              type="text"
              formControlName="puntoDireccion"
              placeholder="Ej: Carrera 2 este # 70 - 41"
              [ngClass]="{'invalid-warning': (crearPuntoFormPaso1.get('puntoDireccion')?.hasError('required') && crearPuntoFormPaso1.get('puntoDireccion')?.touched)}"
              [(ngModel)]="direccion"
              (keyup.enter)="enterMapRegistro()"
              [ngModelOptions]="{standalone: true}">
          </div>
          <!--Teléfono-->
          <div class="col-md-6 col-sm-12">
            <label for="telefono">
              <span class="asterisco">*</span>Teléfono (7 o 10 dígitos)
            </label>
            <input maxlength="10" formControlName="puntoTelefonoFijo" name="telefono" type="text"
              placeholder="Escribe aquí" [(ngModel)]="telefono" (keydown)="validateNumber($event)"
              [ngClass]="{'invalid-warning': (crearPuntoFormPaso1.get('puntoTelefonoFijo')?.hasError('pattern') && crearPuntoFormPaso1.get('puntoTelefonoFijo')?.touched) || (crearPuntoFormPaso1.get('puntoTelefonoFijo')?.hasError('required') && crearPuntoFormPaso1.get('puntoTelefonoFijo')?.touched)}" />
            <span class="col-12 text-danger fw-bold text-right"
              *ngIf="crearPuntoFormPaso1.get('puntoTelefonoFijo')?.hasError('pattern') && crearPuntoFormPaso1.get('puntoTelefonoFijo')?.touched">
              <span class="alerta">Númerico de 7 o 10 dígitos</span>
            </span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <h6>
              <span class="asterisco">*</span>Confirma el punto en el mapa
            </h6>
          </div>
        </div>
        <div class="row mb-3">
          <!--Mapa-->
          <div class="col-1"></div>
          <div class="col-10">
            <agm-map
              #AgmMap
              fit="true"
              [latitude]="map.lat"
              [longitude]="map.lng"
              [mapTypeControl]="true"
              style="width: 100%; height: 15rem;"
              (mapReady)="mapReadyHandler($event)">
              <agm-marker [latitude]="map.lat" [longitude]="map.lng"></agm-marker>
            </agm-map>
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row mb-3">
          <!--Número de sillas-->
          <div class="col-md-6 col-sm-12">
            <label for="numero_sillas">
              <span class="asterisco">*</span>Número de sillas
            </label>
            <input formControlName="puntoSillas" name="numero_sillas" type="number" placeholder="Escribe aquí"
              [(ngModel)]="sillas" (keydown)="validateNumber($event)"
              [ngClass]="{'invalid-warning': (crearPuntoFormPaso1.get('puntoSillas')?.hasError('required') && crearPuntoFormPaso1.get('puntoSillas')?.touched)}" />
          </div>
          <!--Domicilio-->
          <div class="col-md-6 col-sm-12">
            <label for="domicilio"><span class="asterisco">*</span>Domicilio</label><br>
            <div class="d-inline-block w-100">
              <select formControlName="puntoDomicilio" [(ngModel)]="domicilio" class="w-100 domicilio-select"
                aria-labelledby="dropdownBasic2"
                [ngClass]="{'invalid-warning': (crearPuntoFormPaso1.get('puntoDomicilio')?.hasError('required') && crearPuntoFormPaso1.get('puntoDomicilio')?.touched)}">
                <option value="true">Sí ofrece domicilios</option>
                <option value="false">No ofrece domicilios</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h6>
              <span class="asterisco">*</span>Días de atención
            </h6>
            <span class="w-100 text-danger fw-bold text-right rounded"
              *ngIf="validatorDiaSeleccionado == false && crearPuntoFormPaso1.get('puntoLunes')?.touched">
              <span class="alerta">Debe seleccionar por lo menos un día</span>
            </span>
          </div>
        </div>
        <div class="row">
          <!--Lunes-->
          <div class="col-md-3 col-sm-6">
            <input type="checkbox" class="checkbox" name="lunes" formControlName="puntoLunes">
            <label for="lunes">&nbsp;Lunes</label>
          </div>
          <!--Martes-->
          <div class="col-md-3 col-sm-6">
            <input type="checkbox" class="checkbox" name="martes" formControlName="puntoMartes"
              formControlName="puntoMartes">
            <label for="martes">&nbsp;Martes</label>
          </div>
          <!--Miércoles-->
          <div class="col-md-3 col-sm-6">
            <input type="checkbox" class="checkbox" name="miercoles" formControlName="puntoMiercoles">
            <label for="miercoles">&nbsp;Miércoles</label>
          </div>
          <!--Jueves-->
          <div class="col-md-3 col-sm-6">
            <input type="checkbox" class="checkbox" name="jueves" formControlName="puntoJueves">
            <label for="jueves">&nbsp;Jueves</label>
          </div>
        </div>
        <div class="row">
          <!--Viernes-->
          <div class="col-md-3 col-sm-6">
            <input type="checkbox" class="checkbox" name="lunvierneses" formControlName="puntoViernes">
            <label for="viernes">&nbsp;Viernes</label>
          </div>
          <!--Sábado-->
          <div class="col-md-3 col-sm-6">
            <input type="checkbox" class="checkbox" name="sabado" formControlName="puntoSabado">
            <label for="sabado">&nbsp;Sábado</label>
          </div>
          <!--Domingo-->
          <div class="col-md-3 col-sm-6">
            <input type="checkbox" class="checkbox" name="domingo" formControlName="puntoDomingo">
            <label for="domingo">&nbsp;Domingo</label>
          </div>
          <!--Festivos-->
          <div class="col-md-3 col-sm-6">
            <input type="checkbox" class="checkbox" name="festivos" formControlName="puntoFestivos">
            <label for="festivos">&nbsp;Festivos</label>
          </div>
        </div>
        <!--Slider-->
        <div class="row">
          <div class="col-12 custom-slider">
            <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ngx-slider>
          </div>
          <div class="col-12">
            <p>
              {{ valueToTime(minValue) }} - {{ valueToTime(maxValue) }}
            </p>
          </div>
        </div>
      </div>
      <!--Paso 2-->
      <div class="col-12" id="form-scroll" [hidden]="paso_actual == 1" [formGroup]="crearPuntoFormPaso2">
        <div class="row mb-3">
          <!--Nombre del encargado-->
          <div class="col-md-6 col-sm-12">
            <label for="nombre_encargado">
              <span class="asterisco">*</span>Nombre del encargado
            </label>
            <input formControlName="puntoNombreEncargado" name="nombre_encargado" type="text" placeholder="Escribe aquí"
              [(ngModel)]="e_nombres" (keydown)="validateOnlyText($event)"
              [ngClass]="{'invalid-warning': (crearPuntoFormPaso2.get('puntoNombreEncargado')?.hasError('required') && crearPuntoFormPaso2.get('puntoNombreEncargado')?.touched)}" />
          </div>
          <!--Apellido del encargado-->
          <div class="col-md-6 col-sm-12">
            <label for="apellido_encargado">
              <span class="asterisco">*</span>Apellido del encargado
            </label>
            <input formControlName="puntoApellidoEncargado" name="apellido_encargado" type="text"
              placeholder="Escribe aquí" [(ngModel)]="e_apellidos" (keydown)="validateOnlyText($event)"
              [ngClass]="{'invalid-warning': (crearPuntoFormPaso2.get('puntoApellidoEncargado')?.hasError('required') && crearPuntoFormPaso2.get('puntoApellidoEncargado')?.touched)}" />
          </div>
        </div>
        <div class="row mb-3">
          <!--Correo-->
          <div class="col-md-6 col-sm-12">
            <label for="correo">
              <span class="asterisco">*</span>Correo
            </label>
            <input formControlName="puntoCorreoEncargado" name="correo" type="email" placeholder="Escribe aquí"
              [(ngModel)]="e_correo"
              [ngClass]="{'invalid-warning': (crearPuntoFormPaso2.get('puntoCorreoEncargado')?.hasError('required') && crearPuntoFormPaso2.get('puntoCorreoEncargado')?.touched) || (crearPuntoFormPaso2.get('puntoCorreoEncargado')?.hasError('email') && crearPuntoFormPaso2.get('puntoCorreoEncargado')?.touched)}" />
            <span class="col-12 text-danger fw-bold text-left p-0"
              *ngIf="crearPuntoFormPaso2.get('puntoCorreoEncargado')?.hasError('email') && crearPuntoFormPaso2.get('puntoCorreoEncargado')?.touched">
              <span class="alerta">Ingrese un email valido</span>
            </span>
          </div>
          <!--Teléfono-->
          <div class="col-md-6 col-sm-12">
            <label for="telefono">
              <span class="asterisco">*</span>Teléfono (7 o 10 dígitos)
            </label>
            <input maxlength="10" formControlName="puntoTelefonoEncargado" name="telefono" type="text"
              placeholder="Escribe aquí" [(ngModel)]="e_telefono" (keydown)="validateNumber($event)"
              [ngClass]="{'invalid-warning': (crearPuntoFormPaso2.get('puntoTelefonoEncargado')?.hasError('required') && crearPuntoFormPaso2.get('puntoTelefonoEncargado')?.touched) || (crearPuntoFormPaso2.get('puntoTelefonoEncargado')?.hasError('pattern') && crearPuntoFormPaso2.get('puntoTelefonoEncargado')?.touched)}" />
            <span class="col-12 text-danger fw-bold text-right"
              *ngIf="crearPuntoFormPaso2.get('puntoTelefonoEncargado')?.hasError('pattern') && crearPuntoFormPaso2.get('puntoTelefonoEncargado')?.touched">
              <span class="alerta">Númerico con 7 o 10 dígitos</span>
            </span>
          </div>
        </div>
        <div class="d-flex flex-row">
          <!--Usuarios asignados-->
          <div class="col-md-6 col-sm-12 pl-0">
            <label for="usuario_asignado">Usuario asignado</label><br>
            <select name="select" class="seleccionar-trabajador py-2 w-100" multiple>
              <option *ngFor="let trabajador of trabajadores; index as i;" (click)="
                    trabajador_str = trabajador.nombres + ' ' + trabajador.apellidos;
                    trabajador_actual = trabajador
                  " (click)="asignarTrabajador(trabajador)">
                {{ trabajador.nombres }} {{ trabajador.apellidos }}</option>
            </select>
          </div>
          <br />
          <div class="d-flex flex-wrap pl-3 m-0 mt-1 p-0 w-100" *ngIf="trabajadores_asignados.length != 0">
            <div class="col-6 m-0 p-1 d-flex justify-content-between align-items-center"
              *ngFor="let trabajador of trabajadores_asignados; index as i;">
              <p class="m-0 trabajador-seleccionado">
                {{ trabajador!.nombres }} {{ trabajador!.apellidos }}
              </p>
              <button class="trash" (click)="trabajadores_asignados.splice(i, 1);">
                <fa-icon [icon]="faTrashAlt" id="fa-icon"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Navegación-->
    <div class="row mt-3">
      <div class="col-12 col-12 d-flex justify-content-between">
        <!--Anterior-->
        <div class="col-6 text-center">
          <button id="btn_modal_anterior" *ngIf="paso_actual != 1" (click)="anterior()"
            [disabled]="boton_atras_deshabilitado">
            <fa-icon [icon]="faChevronLeft" size="1x" [styles]="{'color': '#41403E'}" id="fa-icon"></fa-icon>
            &nbsp;Página anterior
          </button>
        </div>
        <!--Siguiente-->
        <div class="col-6 text-center">
          <button class="btn_modal" *ngIf="paso_actual == 1" (click)="!isBotonHabilitado && showAlert()"
            (click)="isBotonHabilitado && siguiente()" [style.background-color]="!isBotonHabilitado ? 'grey' : ''">
            Continuar
          </button>
          <button class="btn_modal" *ngIf="paso_actual == 2" (click)="!isFormularioCompleto && showAlert()"
            (click)="isFormularioCompleto && registrarPunto($event)"
            [style.background-color]="!isFormularioCompleto ? 'grey' : ''">
            Crear
          </button>
        </div>
      </div>
    </div>
  </div>
</div>