import { Component, OnInit } from '@angular/core';
import { faChevronLeft, faCircle, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { PedidosService } from '../../services/pedidos/pedidos.service';
import { ActivatedRoute, Params } from '@angular/router';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
@Component({
  selector: 'app-detalle-pedido-sugerido',
  templateUrl: './detalle-pedido-sugerido.component.html',
  styleUrls: ['./detalle-pedido-sugerido.component.css'],
})
export class DetallePedidoSugeridoComponent implements OnInit {
  /** Referencias a íconos FontAwesome */
  faChevronLeft = faChevronLeft;
  faMapMarkerAlt = faMapMarkerAlt;
  faCircle = faCircle;
  /** Variables*/
  idParam: any;
  pedido: any;
  distribuidor: any = {};
  product: any[] = [];
  products: any;
  productsId: any;
  vendedor: any;
  /**Arrays con detalles*/
  // fotosProducto: any = [];
  // nombresProducto: any = [];
  // descripcionProducto: any = [];
  // codigoDistProducto: any = [];
  // precioUndProducto: any = [];
  // precioCajaProducto: any = [];
  // unidadProducto: any = [];
  // cantidadProducto: any = [];
  trabajatorDistribuidor: any = [];
  productos: any[] = [];
  /**Datos globales*/
  total_productos = 0;
  total_und = 0;
  /**Punto de entrega */
  punto_entrega: any = {};
  /**Imagen producto placeholder */
  product_placeholder = '../../assets/img/product-placeholder.png';
  distribuidor_placeholder = '../../assets/img/icon-organizacion.png';
  existePedidoCurso = false;
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'md',
  };
  constructor(
    private pedidosService: PedidosService,
    private auth: AuthService,
    private restService: RestService,
    private activatedRoute: ActivatedRoute,
    private distribuidorService: DistribuidorService,
    private productsService: ProductsService,
    private router: Router,
    private modalService: NgbModal,
    public localStorage: LocalStorageService
  ) {}

  async ngOnInit(): Promise<void> {
    //Validar si es un pedido sugerido
    await this.localStorage.getItem<any>('volver_pedir_pedido').then((resp) => {
      console.log('aca', resp);
      if (Object.entries(resp).length !== 0) {
        console.log('es v');
        this.existePedidoCurso = true;
      }
    });
    this.punto_entrega = JSON.parse(localStorage.getItem('punto_entrega_seleccionado') || '{}');
    this.getData();
  }

  getData() {
    this.activatedRoute.params.subscribe((params: Params) => {
      const idParam = params.id;
      //Trae la información del pedido que seleccionamos
      this.pedidosService.getMyPedidos(idParam).subscribe((resp) => {
        /** Si el trabajador es un Planeador de Pedidos y este pedido no es suyo, sale de la pagina
        if (this.auth.user?.tipo_trabajador === 'PLANEADOR PEDIDOS' && this.auth.user?._id !== resp.trabajador) {
          this.router.navigate(['/pedidos']);
          const modalRef = this.modalService.open(SimpleComponent);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg =
            'Lo sentimos, pero no puedes acceder a este pedido dado que no hace parte de tu portafolio';
          modalRef.componentInstance.btn_msg = 'Volver a Pedidos';
        } */
        this.pedido = resp;
        this.total_productos = resp.productos.length;
        resp.productos.forEach((x: any) => {
          this.total_und += x.unidad;
        });

        //Detalles del distribuidor
        this.distribuidorService.getDistribuidorProductosData(resp.distribuidor).subscribe((resp2) => {
          this.distribuidor = resp2;
        });

        //Arrays para recuperar la info de cada pedido dado que en las consultas anteriores no teniamos esta informacion
        resp.productos.forEach((element: any) => {
          this.productsService.getFilteredProducts(element.product).subscribe((resp3: any) => {
            const index = this.findIndicePedido(resp3._id);
            resp3.fotos = resp3.fotos[0];
            resp3.unidad = this.pedido.productos[index].unidad;
            resp3.cajas_compradas = Math.floor(this.pedido.productos[index].unidad / resp3.precios[0].und_x_caja);
            this.productos.push(resp3);
          });
        });

        //Detalles del vendedor
        this.restService
          .getJWT('trabajador/' + this.pedido.trabajador)
          .toPromise()
          .then((resp3: any) => {
            this.trabajatorDistribuidor = `${resp3.nombres} ${resp3.apellidos}`;
          });
      });
    });
  }

  /**
   * Esta función tiene como objeto recuperar el indice del producto con el fin de saber las unidades pedidas
   * @param idProducto Id del producto a buscar en el array de unidades pedidos
   * @returns indice donde se encuentra las cantidades pedidas de ese producto
   */
  findIndicePedido(idProducto: any) {
    const index = this.pedido.productos.findIndex((producto: any) => {
      return producto.product === idProducto;
    });
    return index;
  }

  agregarSugerido() {
    if (!this.existePedidoCurso) {
      this.localStorage.setItem<any>('volver_pedir_pedido', this.pedido);
      this.router.navigate(['/carrito']);
    } else {
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = `Ya existe un pedido sugerido cargado en tu carrito`;
      modalRef.componentInstance.btn_msg = 'Volver';
    }
  }
}
