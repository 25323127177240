import { Component, Input, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pop-up-saldos-promos',
  templateUrl: './pop-up-saldos-promos.component.html',
  styleUrls: ['./pop-up-saldos-promos.component.css'],
})
export class PopUpSaldosPromosComponent implements OnInit {
  /** Referencias a iconos FontAwesome para la UI */
  public faTimes = faTimes;

  /** Datos a mostrar en el modal */
  @Input() public title = '';
  @Input() public instrucciones = '';
  @Input() public img_src = '';

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
