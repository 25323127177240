<div class="container-fluid fondo-futuro overflow-hidden">
    <div class="row m-3">
        <div class="col-12 col-lg-6 section-img">
            <img src="../../../assets/img/Landing/ilustracion2-landing.png" class="img-landing"
                alt="Una mujer saltando en señal de exito y una fecha señalando hacia arriba">
        </div>
        <div class="col-12 col-lg-6 especificacion">
            <h2 class="mt-3 mt-lg-0">Tu salto, hacia el futuro</h2>
            <p class="parrafo">
                Somos la herramienta Web y Móvil de compra online de negocio a negocio que trabaja por el beneficio y
                enlace de los <a class="eslabones">eslabones</a> del sector para que estos se conecten, se articulen y
                desarrollen la evolución del sector.
            </p>
        </div>
    </div>
</div>