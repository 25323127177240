<app-header-organizacion></app-header-organizacion>
<div id="content">
  <div class="row">
    <div class="col-12 titulo">
      <div class="btn-volver" (click)="onDistribuidores()">
        <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
        Volver
      </div>
      <h4>Mensajes</h4>
    </div>
  </div>
  <div class="contendor-mensajes">
    <div>
      <div class="button-mensaje">
        <button>Nuevo mensaje</button>
      </div>

      <div class="mensajes">
        <img src="../../../../../assets/img/brand-2.png" alt="" />
        <div>
          <span>Dipezcar</span>
          <span>Buenas tardes, …</span>
        </div>
        <span>20 mayo de 2021</span>
      </div>
      <div class="mensajes">
        <img src="../../../../../assets/img/brand-1.png" alt="" />
        <div>
          <span>Dipezcar</span>
          <span>Buenas tardes, …</span>
        </div>
        <span>20 mayo de 2021</span>
      </div>
    </div>
    <div>
      <div class="encabezado">
        <img src="../../../../../assets/img/brand-1.png" alt="" />
        <div>
          <span>Bibiana Pedraza</span>
          <span>Dipezcar - Neiva</span>
        </div>
      </div>
      <div class="contenido-mensaje">
        <div *ngFor="let msg of mensajes" [ngClass]="{ recibe: msg.accion == 'get', envia: msg.accion == 'post' }">
          <span>{{ msg.fecha }}</span>
          <span>{{ msg.nombre }}</span>
          <div class="mensaje-parrafo">
            <p>
              {{ msg.mensaje }}
            </p>
          </div>
        </div>
      </div>

      <div class="acciones-mensaje">
        <input type="text" placeholder="Escribe aquí" />
        <div><fa-icon [icon]="faCamera" [styles]="{ color: '#8E6FF7' }" id="fa-icon"></fa-icon></div>
        <button>Enviar</button>
      </div>
    </div>
  </div>
</div>
