import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { LocalDataSource } from 'ng2-smart-table';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-tabla-puntos-ft',
  templateUrl: './tabla-puntos-ft.component.html',
  styleUrls: ['./tabla-puntos-ft.component.css'],
})
export class TablaPuntosFtComponent implements OnInit {
  /** ID Distribuidor */
  public id_distribuidor = this.authService.user_distribuidor!._id;
  /**Iconos */
  faChevronUp = faChevronUp;
  /** Datos y configuracion de la tabla  */
  public settings_puntosFt: any = {};
  public data_puntosFt: any = [];
  public source_puntosFt?: LocalDataSource;
  /** Modal de carga para darle feedback al usuario */
  public modalCarga?: NgbModalRef;
  /** Evita que al hacer click por fuera se cierre el modal */
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };
  constructor(
    private restService: RestService,
    private router: Router,
    private ngxsStore: Store,
    private authService: AuthService,
    private modalService: NgbModal,
    private datePipe: DatePipe
  ) {}
  async ngOnInit() {
    /** Configura las tablas */
    this.configSmartTablePuntosFt();
    this.id_distribuidor = await this.ngxsStore.snapshot().auth.user_distribuidor._id;
    /** Modal generíco cargando... */
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);

    /** Fetch data para las tablas */
    await this.cargarDatosTablaPuntosFt();
    /** Cerrar Modal generíco cargando... */
    this.modalCarga?.close();
  }
  async ngOnChanges() {}

  /*************************** Puntos Feat **************************/
  private configSmartTablePuntosFt(): void {
    this.settings_puntosFt = {
      pager: {
        display: true,
        perPage: 10,
      },
      noDataMessage: '',
      actions: false,
      hideSubHeader: false,
      columns: {
        pedido_id: {
          title: 'No. pedido',
          editable: false,
          filter: true,
        },
        subtotal_pedido: {
          title: 'Valor pedido (COP)',
          editable: false,
          filter: true,
        },
        cod_redimido: {
          title: 'Código de redención',
          editable: false,
          filter: true,
        },
        pedido_puntos: {
          title: '# Puntos Feat',
          editable: false,
          filter: true,
        },
        cod_redimido_valor: {
          title: 'Valor del código de descuento (COP)',
          editable: false,
          filter: true,
        },
        cod_redimido_estado: {
          title: 'Estado pago código',
          editable: false,
          filter: true,
        },
        horeca_nombre: {
          title: 'Establecimiento',
          editable: false,
          filter: true,
        },
        horeca_nit: {
          title: 'NIT/CC/CE',
          editable: false,
          filter: true,
        },
        horeca_tipo_usuario: {
          title: 'Tipo de persona',
          editable: false,
          filter: true,
        },
        horeca_tipo_negocio: {
          title: 'Tipo de negocio',
          editable: false,
          filter: true,
        },
        punto_nombre: {
          title: 'Punto de entrega',
          editable: false,
          filter: true,
        },
        puntos_fecha: {
          title: 'Fecha del pedido',
          editable: false,
          filter: true,
        },
        cod_redimido_puntos: {
          title: 'Valor en puntos FT',
          editable: false,
          filter: true,
        },
        // estadoCodigo: {
        //   title: 'Estado código',
        //   editable: false,
        //   filter: true,
        // },
        // descontadoPedido: {
        //   title: 'Descontado pedido',
        //   editable: false,
        //   filter: true,
        // },
      },
    };
  }

  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  private cargarDatosTablaPuntosFt(): void {
    this.restService
      .getJWT('informedistribuidorPuntos/' + this.id_distribuidor)
      .toPromise()
      .then((puntos: any) => {
        puntos.data.forEach((puntoFt: any) => {
          /* const obj_puntos = {
            horeca_nombre: puntoFt.establecimiento,
            horeca_tipo_usuario: puntoFt.tipo_persona_establecimiento,
            horeca_nit: puntoFt.nit_establecimiento,
            horeca_tipo_negocio: puntoFt.tipo_negocio_establecimiento,
            punto_nombre: puntoFt.puntoEntrega,
            puntos_fecha: puntoFt.fecha,
            puntos_codigo_creado: puntoFt.codigoRedencion,
            pedido_puntos: puntoFt.puntos_ganados,
            pedido_id: puntoFt.idPedido,
          };
          if (puntoFt.puntos_ganados > 0) {
            this.data_puntosFt.push(obj_puntos);
          }*/
          for (let i = 0; i < puntoFt.codigoRedencion.length; i++) {
            const obj_puntos = {
              horeca_nombre: puntoFt.establecimiento,
              horeca_tipo_usuario: puntoFt.tipo_persona_establecimiento,
              horeca_nit: puntoFt.nit_establecimiento,
              horeca_tipo_negocio: puntoFt.tipo_negocio_establecimiento,
              punto_nombre: puntoFt.puntoEntrega,
              puntos_fecha: puntoFt.fecha,
              puntos_codigo_creado: puntoFt.codigoRedencion,
              pedido_puntos: puntoFt.puntos_ganados,
              pedido_id: puntoFt.idPedido,
              cod_redimido: puntoFt.codigoRedencion[i],
              cod_redimido_valor: puntoFt.list_code[0][i].valor_moneda,
              cod_redimido_puntos: puntoFt.list_code[0][i].valor_paquete,
              cod_redimido_estado: puntoFt.list_code[0][i].estadoPago,
              subtotal_pedido: puntoFt.valor_pedido,
            };
            this.data_puntosFt.push(obj_puntos);
          }
        });
        this.source_puntosFt = new LocalDataSource(this.data_puntosFt);
        this.settings_puntosFt.noDataMessage = 'Sin información';
        /*if (this.data_puntosFt.length > 0) {
          this.source_puntosFt = new LocalDataSource(this.data_puntosFt);
          this.settings_puntosFt.noDataMessage = 'Sin información';
        }*/
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
  }

  /**
   * Llevar tabla a archivo excel y descargarlo
   */
  descargaArchivoPuntosFt() {
    const object_keys = Object.keys(this.settings_puntosFt.columns);
    const primera_fila: any[] = [];
    object_keys.forEach((element: any) => {
      primera_fila.push(this.settings_puntosFt.columns[element].title);
    });
    /** Arma el contenido de la tabla con titulos*/
    const arreglo_final: any[] = [];
    arreglo_final.push(primera_fila);
    this.data_puntosFt.forEach((element: any) => {
      const array = [];
      for (const column of object_keys) {
        array.push(element[column]);
      }
      arreglo_final.push(array);
    });
    this.DescargaExcel({ data: arreglo_final, nombreArchivo: `PuntosFt.xlsx` });
  }

  /**
   * Descargue a excel un listado
   */
  public DescargaExcel(obj: any) {
    /** Genera el worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(obj.data);
    /* Genera el workbook y agrega el  worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');
    /** Guarda el archivo */
    XLSX.writeFile(wb, obj.nombreArchivo);
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      // this.router.navigate(['/inicio-distribuidor']);
    };
  }
}
