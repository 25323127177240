/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCamera, faChevronLeft, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SeleccionarPuntoEntregaComponent } from 'src/app/modal/seleccionar-punto-entrega/seleccionar-punto-entrega.component';
import { CargarImagenMensajesComponent } from '../../modal/cargar-imagen-mensajes/cargar-imagen-mensajes.component';
import { AuthService } from '../../services/auth/auth.service';
import { RestService } from '../../services/rest/rest.service';
import { SocketWebService } from '../../services/socket/socket-web.service';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-mensajes-pedidos',
  templateUrl: './mensajes-pedidos.component.html',
  styleUrls: ['./mensajes-pedidos.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MensajesPedidosComponent implements OnInit {
  public faFilePdf = faFilePdf;
  // Referencias a íconos
  public faChevronLeft = faChevronLeft;
  public faCamera = faCamera;
  public faPaperclip = faPaperclip;
  //Sala o Pedido ID
  public room: string = '' as string;
  // Instancia del Socket
  public socket: any;
  // data del pedido
  public pedido: any;
  // Mensaje escrito en la caja de input del chat
  public message: string | undefined;
  // Información transversal del usuario en el momento del logue
  public user: any;
  // Arreglo de objetos con los mensajes de la conversació
  public conversacion = <any>[];
  // Arreglo de objetos de los pedidos que se tienen como historial de conversaciones pueden o no haber sido iniciada
  public historial_conversaciones: any;
  // Bandera para mostrar o no historial de conversacione
  public banderaHistorial?: boolean = false;
  // Bandera para marcar el estado de la conversación actual según estado pedid
  public estadoChat?: boolean = false;
  // Variable dinámica donde se construye las conversaciones en la caja grande de un cha
  public someHtmlCode = '';
  // Imagen producto placeholder
  public distribuidor_placeholder = '../../assets/img/icon-organizacion.png';
  // Guarda la url de imagen guardada en el modal
  public url_imagen: any;
  // Flag para identificar el compenente previo y habilitar el boton volver segun corresponda
  public rutaprevia: any;
  // Guarda el histroial de conversaciones del usuario logueado
  public miniaturas_conversaciones: any[] = [];
  // Variables para guardar información de mensajes y mostrarla en el DOM
  public data_conversacion_actual: any;
  public dataTrabjadorDestinatario: any;
  public header_conversacion_actual: any;
  //Punto de entrega seleccionado
  public punto_entrega: any = '';
  // Guarda la data del usuario horeca
  public user_horeca: any;
  // Tipo de usuario logueado
  public tipo_usuario: any;
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;

  constructor(
    private router: ActivatedRoute,
    private cookieService: CookieService,
    private socketWebService: SocketWebService,
    private authService: AuthService,
    private rest: RestService,
    private modalService: NgbModal,
    private ngxsStore: Store,
    private routerNavigation: Router
  ) {
    this.user = this.authService.user;
    this.user_horeca = this.authService.user_horeca;
    // Función para escuchar los mensajes y colocarlos en la caja de mensajes de chat actual
    socketWebService.callback.subscribe((res) => {
      // Marca el mensaje como leido
      res.leido = true;
      // Añade el mensaje al array de mensajes para guardar en la base
      this.conversacion.push(res);
      // Si viene una imagen lo formatea para mostrarlo en el DOM
      // Añade el mensaje al DOM desde el socket
      if (res.mensaje.startsWith('https://feat-resources.s3')) {
        if (res.mensaje.substr(-9) === '.pdf-logo') {
          this.someHtmlCode =
            this.someHtmlCode +
            `<br><p class="text-center timestamp">${res.tiempo}</p>
          <div class="text-right"> <span >  ${res.usuarioNombre}</span><span class="letra-auxiliar"> (${res.tipo}) </span></div>
          <div class="d-flex flex-row-reverse card card-mensaje">
          <div class="text-right card-body mensaje mensaje-horeca">
          <p class="m-0">
          <a href="${res.mensaje}" target="_blank">Ver PDF</a>
          </p>
          </div></div>`;
        } else {
          this.someHtmlCode =
            this.someHtmlCode +
            `<br><p class="text-center timestamp">${res.tiempo}</p>
          <div class="text-right"> <span >  ${res.usuarioNombre}</span><span class="letra-auxiliar"> (${res.tipo}) </span></div>
          <div class="d-flex flex-row-reverse card card-mensaje">
          <div class="text-right card-body mensaje mensaje-horeca"><p class="m-0"><img src="${res.mensaje}" style="width: 100%;" alt="imagen mensaje" class="img-mensaje"></p></div></div>`;
        }
      } else {
        this.someHtmlCode =
          this.someHtmlCode +
          `<br><p class="text-center timestamp">${res.tiempo}</p>
          <div class="text-left"> <span >  ${res.usuarioNombre}</span><span class="letra-auxiliar"> (${res.tipo}) </span></div>
                <div class="d-flex flex-row card card-mensaje">
                <div class="text-left card-body mensaje mensaje-dist"><p class="m-0">${res.mensaje}</p></div></div>`;
      }
      this.scrollToBottom();
      this.playSound();
    });
  }

  /**
   * Función inicial que valida los procesos de la sala o pedido Id , crea la cookie
   * para la conversación y trae información del historial y conversación actual
   */
  async ngOnInit() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.room = this.router.snapshot.paramMap.get('room')!;
    await this.cookieService.set('room', this.room);
    // Revisa si es un establecimiento o un distribuidor logueado
    await this.rest
      .get(`tipo_empresa_trabajador/${this.user?._id}`)
      .toPromise()
      .then((resp: any) => {
        this.tipo_usuario = resp;
        if (this.tipo_usuario === 'usuario_horeca') {
          this.getPuntoEntregaID();
        }
        if (this.tipo_usuario === 'distribuidor') {
          this.getHistorial_conversaciones_Distribuidor();
        }
        // Revision para Cargue de historial de conversaciones
        if (this.router.snapshot.paramMap.get('rutaprevia')) {
          this.rutaprevia = this.router.snapshot.paramMap.get('rutaprevia');
        }
      });
  }
  async validatePermissions() {
    // eslint-disable-next-line prefer-const
    let blockUser = await this.authService.validatePermissionsUser();
    return blockUser;
  }
  /**
   * Recupera el ID del punto de entrega en base del room/pedido actual
   */
  getPuntoEntregaID() {
    this.rest
      .getJWT(`pedido/${this.room}`)
      .toPromise()
      .then((resp: any) => {
        this.pedido = resp;
        if (resp.punto_entrega) {
          if (resp.punto_entrega._id) {
            this.punto_entrega = resp.punto_entrega;
            if (this.punto_entrega === undefined) {
              this.selecionarPunto();
            } else {
              this.getHistorial_conversaciones();
            }
          } else {
            this.rest
              .getJWT(`punto_entrega/${resp.punto_entrega}`)
              .toPromise()
              .then((resp_2: any) => {
                this.punto_entrega = resp_2;
                if (this.punto_entrega === undefined) {
                  this.selecionarPunto();
                } else {
                  this.getHistorial_conversaciones();
                }
              });
          }
        } else {
          this.selecionarPunto();
        }
      });
  }
  /**
   * Recupera las conversaciones según el punto seleccionado
   */
  async getHistorial_conversaciones() {
    /** Consulta todas las conversaciones por id del trabajador */
    this.rest
      .getJWT(`mensajes/historial-activos/punto/${this.punto_entrega._id}`)
      .toPromise()
      .then((res: any) => {
        this.modalCarga?.close();
        this.banderaHistorial = true;
        /*
        this.historial_conversaciones = res.filter(
          (element: any) => element.data_tracking[0].estado_nuevo !== 'Pendiente'
        );
        */
        /** Filtra los mensajes que no tengan conversación activa */
        this.historial_conversaciones = res;
        /** Encuentra el destinatario del mensaje */
        this.historial_conversaciones.forEach((element: any) => {
          /** El usuario NO logueado será el destinatario */
          let empresa_destinatario: any;
          if (this.user['usuario_horeca'] !== undefined) {
            empresa_destinatario = element.data_distribuidor![0];
          } else {
            empresa_destinatario = element.data__horeca![0];
          }
          /**
           * Se arma un objeto con toda la info necesaria para crear la lista de
           * conversaciones del trabajador que se mostrarán en el panel de historial
           */
          if (empresa_destinatario !== undefined) {
            const objectMiniatura = {
              nombre_empresa: '',
              id_pedido: element.id_pedido,
              logo_empresa: empresa_destinatario.logo,
              conversaciones: element.data_mensajes,
              pedido: element._id,
              leido: this.obtenerEstadoLeido(element),
              punto: element.data_punto[0].nombre || '',
            };
            if (empresa_destinatario['nombre'] !== undefined) {
              objectMiniatura.nombre_empresa = empresa_destinatario.nombre;
            } else {
              objectMiniatura.nombre_empresa = empresa_destinatario.nombre_establecimiento;
            }
            this.miniaturas_conversaciones.push(objectMiniatura);
          }
        });
        this.getConversacionActual();
      })
      .catch((err: any) => {
        this.modalCarga?.close();
        this.banderaHistorial = false;
        console.log(err);
      });
  }

  /**
   * Recupera las conversaciones según el punto seleccionado
   */
  async getHistorial_conversaciones_Distribuidor() {
    /** Consulta todas las conversaciones por id del trabajador */
    this.rest
      .getJWT(`mensajes/historial-activos/distribuidor/${this.authService!.user!.distribuidor}`)
      .toPromise()
      .then((res: any) => {
        this.modalCarga?.close();
        /** Filtra los mensajes que no tengan conversación activa */
        /**
                 this.historial_conversaciones = res.filter((element: any) => element.estado !== 'Pendiente');
         */
        this.historial_conversaciones = res;
        this.banderaHistorial = true;

        /** Encuentra el destinatario del mensaje */
          //delete this.historial_conversaciones[this.historial_conversaciones.length-1]
        this.historial_conversaciones.forEach((element: any) => {
          /** El usuario NO logueado será el destinatario */
          let empresa_destinatario: any;
          let nombrePuntoMensaje: any;
          if (this.user['usuario_horeca']) {
            empresa_destinatario = element.data_distribuidor![0];
          } else {
            empresa_destinatario = element.data__horeca[0] ?   element.data__horeca[0] : undefined;
            nombrePuntoMensaje = element.data_punto[0] ? element.data_punto[0].nombre: undefined;
          }
          /**
           * Se arma un objeto con toda la info necesaria para crear la lista de
           * conversaciones del trabajador que se mostrarán en el panel de historial
           */
          if (empresa_destinatario !== undefined) {
            const objectMiniatura = {
              nombre_empresa: '',
              id_pedido: element.id_pedido,
              logo_empresa: empresa_destinatario.logo,
              conversaciones: element.data_mensajes,
              pedido: element._id,
              nombrePunto: nombrePuntoMensaje,
              leido: this.obtenerEstadoLeido(element),
            };
            if (empresa_destinatario['nombre'] !== undefined) {
              objectMiniatura.nombre_empresa = empresa_destinatario.nombre;
            } else {
              objectMiniatura.nombre_empresa = empresa_destinatario.nombre_establecimiento;
            }
            if (!objectMiniatura.conversaciones || objectMiniatura.conversaciones.length == 0) {
              objectMiniatura.leido = true;
            }
            this.miniaturas_conversaciones.push(objectMiniatura);
          }
        });
        this.getConversacionActual();
      })
      .catch((err: any) => {
        this.modalCarga?.close();
        this.banderaHistorial = false;
      });
  }

  /**
   * Traer la informacion de la conversacion dependiendo del id del pedido asociado, reconstruye la conversacion en el html
   */
  async getConversacionActual() {
    let empresa_destinatario: any;
    let nombre_punto_destinatario: any;
    let tipo_trabajador = '';
    /** Encuentra la conversación del room/pedido seleccionado */
    this.data_conversacion_actual = this.historial_conversaciones.find((room: any) => room._id == this.room);
    /** Si no existe el chat consulta la información del pedido para crear un nuevo chat*/
    if (this.data_conversacion_actual === undefined) {
      await this.rest
        .getJWT(`mensajes/completos/pedidos/${this.room}`)
        .toPromise()
        .then((res: any) => {
          if (res.success === true) {
            this.data_conversacion_actual = res[0];
          }
        })
        .catch((err: any) => {
          return;
        });
    }
    /** Información empresa destinataria del mensaje */
    if (this.user['usuario_horeca'] !== undefined) {
      empresa_destinatario = this.data_conversacion_actual.data_distribuidor
        ? this.data_conversacion_actual.data_distribuidor[0]
        : null;
    } else {
      empresa_destinatario = this.data_conversacion_actual.data__horeca[0];
      nombre_punto_destinatario = this.data_conversacion_actual.data_punto[0].nombre;
    }
    /** Información del trabajador destinatario */
    this.dataTrabjadorDestinatario = this.data_conversacion_actual.data_trabajador.find(
      (trabajador_remitente: any) => trabajador_remitente._id !== this.user._id
    );
    /** Tipo de empresa */
    if (this.dataTrabjadorDestinatario === undefined) {
      tipo_trabajador = 'Inicia este chat';
    } else {
      tipo_trabajador = this.dataTrabjadorDestinatario.tipo_trabajador;
    }
    /** Objeto para header de la caja de chat con el destinatario */
    this.header_conversacion_actual = {
      // eslint-disable-next-line prettier/prettier
      nombre_empresa:
        empresa_destinatario.nombre == undefined
          ? empresa_destinatario.nombre_establecimiento
          : empresa_destinatario.nombre,
      logo: empresa_destinatario.logo,
      punto_nombre: nombre_punto_destinatario,
      nombre_trabajador:
        this.dataTrabjadorDestinatario == undefined
          ? 'Ningún trabajador'
          : `${this.dataTrabjadorDestinatario.nombres} ${this.dataTrabjadorDestinatario.apellidos}`,
      tipo_trabajador: tipo_trabajador,
    };
    /** Arreglo con los html de los mensajes a mostrar en la caja de chat */
    if (this.data_conversacion_actual.data_mensajes[0] != undefined) {
      for (const conversacion of this.data_conversacion_actual.data_mensajes[0].conversacion) {
        const objChat: any = this.getObjChat(conversacion, false);
        /** Si viene una imagen lo formatea para mostrarlo en el DOM */
        let mensaje_limpio = '';
        if (objChat.mensaje.startsWith('https://feat-resources.s3')) {
          if (objChat.mensaje.substr(-9) === '.pdf-logo') {
            mensaje_limpio = `<a href="${objChat.mensaje}" target="_blank">Ver PDF</a>`;
          } else {
            mensaje_limpio = `<img src="${objChat.mensaje}" style="width: 100%;" alt="imagen mensaje" class="img-mensaje">`;
          }
        } else if (objChat.mensaje.startsWith('<a href="https://feat-resources.s3')) {
          const urlMensaje: any = this.extraerHrefDesdeString(objChat.mensaje);
          if (urlMensaje?.substr(-9) === '.pdf-logo') {
            mensaje_limpio = `<a href="${urlMensaje}" target="_blank">Ver PDF</a>`;
          } else {
            mensaje_limpio = `<img src="${urlMensaje}" style="width: 100%;" alt="imagen mensaje" class="img-mensaje">`;
          }
        } else {
          mensaje_limpio = objChat.mensaje;
        }
        /** Marca el mensaje como leido */
        objChat.leido = true;
        /** Arma las nubes de los mensajes dependiendo de quien escribe */
        this.conversacion.push(objChat);
        if (mensaje_limpio.endsWith(' ha iniciado el chat')) {
          this.someHtmlCode =
            this.someHtmlCode + `<br><p class="text-center text-secondary inactive timestamp">${mensaje_limpio}</p>`;
        } else if (conversacion.usuario == this.user._id) {
          this.someHtmlCode =
            this.someHtmlCode +
            `<br><p class="text-center timestamp">${objChat.tiempo}</p>
            <div class="text-right"> <span >  ${objChat.usuarioNombre}</span><span class="letra-auxiliar"> (${objChat.tipo}) </span></div>
            <div class="d-flex flex-row-reverse card card-mensaje">
            <div class="text-right card-body mensaje mensaje-horeca"><p class="m-0">${mensaje_limpio}</p></div></div>`;
        } else {
          this.someHtmlCode =
            this.someHtmlCode +
            `<br><p class="text-center timestamp">${objChat.tiempo}</p>
            <div class="text-left"> <span >  ${objChat.usuarioNombre}</span><span class="letra-auxiliar"> (${objChat.tipo}) </span></div>
            <div class="d-flex flex-row card card-mensaje">
            <div class="text-left card-body mensaje mensaje-dist"><p class="m-0">${mensaje_limpio}</p></div></div>`;
        }
      }
    }
    setTimeout(() => {
      this.scrollToBottom();
    }, 2000);
    /**
     * Verifica si aun el usuario logueado no ha iniciado el chat de no haberlo hecho lo inicia
     * con un mensaje por defecto esto es encesario para iniciar el funcionamiento del socket
     */
    if (localStorage.getItem('chat_room_id') !== this.room) {
      this.crerarMensajePorDefecto();
    }
  }

  extraerHrefDesdeString(tagAString: string): string | null {
    const regex = /href=["'](.*?)["']/;
    const match = regex.exec(tagAString);

    if (match && match.length > 1) {
      return match[1];
    } else {
      return null;
    }
  }

  /**
   * Recuper el objeto con la información completa de un trabajador según id ingresado
   * @params id id del trabajador
   */
  getInfoTrabajador(id: any) {
    for (const iterator of this.data_conversacion_actual.data_trabajador) {
      if (iterator._id === id) {
        return iterator;
      }
    }
    return [];
  }

  /**
   * Recuper el tipo de establecimeinto del trabajador que está enviando el mensaje
   * @params objeto con la información del trabajador
   * Verifica si existe la key dentro del objeto, si existe devuelve el tipo de empresa
   */
  getTipoEmpresa(infoTrabajador: any) {
    if (infoTrabajador['usuario_horeca'] !== undefined) {
      return 'Punto Entrega';
    } else if (infoTrabajador['distribuidor'] !== undefined) {
      return 'distribuidor';
    } else if (infoTrabajador['organizacion'] !== undefined) {
      return 'organizacion';
    }
    return '';
  }

  /**
   * Abre modal para cargar la imagen, desde el modal se guarda en la base de datos;
   * luego de guardada se recibe la url de la imagen y se procede a enviar el mensaje al DOM
   */
  enviarImagen() {
    const modalRef = this.modalService.open(CargarImagenMensajesComponent, {
      centered: true,
    });
    modalRef.componentInstance.url_imagen = this.url_imagen;
    modalRef.componentInstance.callback = (url_imagen: any) => {
      this.message = url_imagen;
      this.EnviarMensaje();
    };
  }

  /**
   * Emmite el evento de mensaje con la información ingresada en el campo de input del form de chat
   */
  async EnviarMensaje() {
    if (this.message !== '') {
      const dataMsj = await this.formatearMensaje(this.message);
      const objChat: any = this.getObjChat(dataMsj ? dataMsj : '', true);
      /** Marca el mensaje como NO leido */
      objChat.leido = false;
      this.conversacion.push(objChat);
      this.socketWebService.emitEven(objChat);
      /** Si viene una imagen lo formatea para mostrarlo en el DOM */
      if (objChat.mensaje.endsWith(' ha iniciado el chat')) {
        this.someHtmlCode =
          this.someHtmlCode + `<br><p class="text-center text-secondary inactive timestamp">${objChat.mensaje}</p>`;
      } else if (objChat.mensaje.startsWith('https://feat-resources.s3')) {
        if (objChat.mensaje.substr(-9) === '.pdf-logo') {
          this.someHtmlCode =
            this.someHtmlCode +
            `<br><p class="text-center timestamp">${objChat.tiempo}</p>
            <div class="text-right"> <span >  ${objChat.usuarioNombre}</span><span class="letra-auxiliar"> (${objChat.tipo}) </span></div>
            <div class="d-flex flex-row-reverse card card-mensaje">
            <div class="text-right card-body mensaje mensaje-horeca">
            <p class="m-0">
            <a href="${objChat.mensaje}" target="_blank">Ver PDF</a>
            </p>
            </div></div>`;
        } else {
          this.someHtmlCode =
            this.someHtmlCode +
            `<br><p class="text-center timestamp">${objChat.tiempo}</p>
            <div class="text-right"> <span >  ${objChat.usuarioNombre}</span><span class="letra-auxiliar"> (${objChat.tipo}) </span></div>
            <div class="d-flex flex-row-reverse card card-mensaje">
            <div class="text-right card-body mensaje mensaje-horeca"><p class="m-0"><img src="${objChat.mensaje}" style="width: 100%;" alt="imagen mensaje" class="img-mensaje"></p></div></div>`;
        }
      } else {
        this.someHtmlCode =
          this.someHtmlCode +
          `<br><p class="text-center timestamp">${objChat.tiempo}</p>
            <div class="text-right"> <span >  ${objChat.usuarioNombre}</span><span class="letra-auxiliar"> (${objChat.tipo}) </span></div>
            <div class="d-flex flex-row-reverse card card-mensaje">
            <div class="text-right card-body mensaje mensaje-horeca"><p class="m-0">${objChat.mensaje}</p></div></div>`;
      }
      this.message = '';
      this.scrollToBottom();
      this.GuardarConversacion();
    }
  }
  /*
  Funcion para obtener una url y transformarla en etiqueta html
  */
  formatearMensaje(msj: any) {
    return new Promise((resolve, reject) => {
      let dataResult = ' ';
      if (!msj.startsWith('https://feat-resources.s3')) {
        // eslint-disable-next-line prefer-const
        let arrDataMsj = msj.split(' ');
        // eslint-disable-next-line prefer-const
        for (const item of arrDataMsj) {
          let stringConsulta = '';
          if (item.substr(0, 4) === 'www.') {
            stringConsulta += 'https://' + item;
          } else {
            stringConsulta = item;
          }
          let resp = this.isValidUrl(stringConsulta);
          if (resp) {
            dataResult += `<a href="${stringConsulta}" target="_blank">${stringConsulta}</a> `;
          } else {
            dataResult += item + ' ';
          }
        }
      } else {
        dataResult = msj;
      }

      resolve(dataResult);
    });
  }
  /*
  Funcion para verificar una url
  */
  isValidUrl(string: any) {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  }
  /**
   * Funcion encargada de formatear el objeto que se muestra en una sola interaccion del chat
   * @mensaje se envia dinamicamente el mensaje escrito que viene del input
   * @nuevo_mensaje Crea dos objetos con informacion diferente dependiendo si el mensaje
   *  viene de la base de datos  o se esta agegando un nuevo mensaje
   */
  getObjChat(mensaje: any, nuevo_mensaje: boolean) {
    moment.locale('es-es');
    let objChat: any = {};
    if (nuevo_mensaje) {
      const tipo_usuario = this.getTipoEmpresa(this.user);
      objChat = {
        usuario: this.user._id,
        usuarioNombre: this.user.nombres + ' ' + this.user.apellidos,
        mensaje: mensaje,
        tiempo: moment().format('DD MMMM YYYY, h:mm a'),
        tipo: this.user.tipo_trabajador,
      };
    } else {
      const info_trabajador = this.getInfoTrabajador(mensaje.usuario);
      const tipo_usuario = this.getTipoEmpresa(info_trabajador);
      objChat = {
        usuario: info_trabajador._id,
        usuarioNombre: info_trabajador.nombres + ' ' + info_trabajador.apellidos,
        mensaje: mensaje.mensaje,
        tiempo: mensaje.tiempo,
        tipo: info_trabajador.tipo_trabajador,
      };
    }
    return objChat;
  }

  /**
   * Cuando se le da volver o se cierra la conversacion esta
   * funcion se encarga de guardar el historial o actualizar
   * la conversacion si el chat ya existia
   */
  GuardarConversacion() {
    /** Armar Objeto */
    const data = {
      pedido: this.room,
      conversacion: this.conversacion,
      estado: 'Activo',
    };
    /** Guardar Conversacion */
    this.rest
      .postJWT('mensajes', data)
      .toPromise()
      .then(() => {})
      .catch((err: any) => {
        console.log(err);
      });
  }

  /**
   * Abre un nuevo room para el chat de un pedido
   * @Params1 conversacion: ID del pedido
   */
  CargarConversacionDeHistorial(conversacion: any) {
 
    if (this.tipo_usuario === 'usuario_horeca') {
      this.routerNavigation.navigate(['/pedidos/mensajes/' + conversacion.pedido]).then(() => {
        window.location.reload();
      });
    } else if (this.tipo_usuario === 'distribuidor') {
      this.routerNavigation.navigate(['/pedidos/mensajes/' + conversacion.pedido + '/inicio-distribuidor']).then(() => {
        window.location.reload();
      });
    }
  }

  /**
   * Envia un mensaje por defecto que incializa el chat
   * Esto se necesito por error al iniciar un chat, esto
   * es que si no hay un mensaje ya enviado no inicia el socket
   * por lo que se forza un mensaje inicial por defecto
   * que forza la creación del hijo en el socket para el chat.
   */
  async crerarMensajePorDefecto() {
    const objChat = {
      usuario: this.user._id,
      usuarioNombre: 'Super administrador FEAT',
      mensaje: `${this.user.nombres} ${this.user.apellidos} ha iniciado el chat`,
      tiempo: moment().format('DD MMMM YYYY, h:mm a'),
      tipo: 'FEAT',
      leido: true,
    };
    this.conversacion.push(objChat);
    this.socketWebService.emitEven(objChat);
    this.someHtmlCode =
      this.someHtmlCode + `<br><p class="text-center text-secondary timestamp">${objChat.mensaje}</p>`;
    this.message = '';
    this.scrollToBottom();
    await this.GuardarConversacion();
    await this.postNotificacionMensajeNuevo();
    localStorage.setItem('chat_room_id', this.room);
    window.location.reload();
  }

  /**
   * Envia una notificacion a la contra parte que se ha abierto una sala
   */
  postNotificacionMensajeNuevo() {
    this.rest
      .postJWT(`notificacion/chat-abierto/distribuidor/`, this.pedido)
      .toPromise()
      .then(() => {});
  }

  /**
   * Lanza un modal para seleccionar el punto de entrega
   * si este no está asociado al distribuidor saldra de esta pagina
   */
  selecionarPunto() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
      centered: true,
      windowClass: 'modal-selecionar-punto',
    };
    const modalRef = this.modalService.open(SeleccionarPuntoEntregaComponent, ngbModalOptions);
    modalRef.componentInstance.callback = () => {
      this.punto_entrega = this.authService.punto_seleccionado;
      this.historial_conversaciones = [];
      this.miniaturas_conversaciones = [];
      this.getHistorial_conversaciones();
      localStorage.setItem('punto_entrega_chat', this.punto_entrega._id);
    };
  }

  ngOnDestroy() {
    localStorage.removeItem('punto_entrega_chat');
    localStorage.removeItem('chat_room_id');
    this.GuardarConversacion();
  }

  /**
   * Voler a modulo anterior
   * dependiendo de donde haya ingresado, volvera al modulo anterior
   */
  volver() {
    localStorage.removeItem('punto_entrega_chat');
    localStorage.removeItem('chat_room_id');
    this.GuardarConversacion();
    if (this.rutaprevia == 'inicio') {
      this.routerNavigation.navigate(['/inicio']);
    } else if (this.rutaprevia == 'inicio-distribuidor') {
      this.routerNavigation.navigate(['/inicio-distribuidor']);
    } else if (this.tipo_usuario === 'usuario_horeca') {
      this.routerNavigation.navigate(['/pedidos/' + this.room]);
    } else if (this.tipo_usuario === 'distribuidor') {
      this.routerNavigation.navigate(['/pedidos-distribuidor/' + this.room]);
    }
  }

  /**
   * Scroll hasta el final del div, se da un time out a esperas que primero cargue el ultimo mensaje en el DOM
   */
  scrollToBottom() {
    const objDiv: HTMLElement | null = document.getElementById('chat-mensajes-caja');
    setTimeout(() => {
      objDiv!.scrollTop = objDiv!.scrollHeight;
    }, 300);
  }

  /**
   * Sonido para alertar sobre la llegada de un mensaje
   */
  playSound() {
    const audio = new Audio('../../../assets/bell-notification.wav');
    audio.play();
  }

  /**
   * Abre sala de mensajes de organizaciones
   */
  async abrirSalaOrganizaciones() {
    // eslint-disable-next-line prefer-const
    let validador = await this.validatePermissions();
    if (validador) {
      const id_dist = this.ngxsStore.snapshot().auth.user_distribuidor?._id;
      this.routerNavigation
        .navigate(['/chat/room/todos/distribuidor/' + id_dist + '/organizacion/todos/distribuidor'])
        .then(() => {
          window.location.reload();
        });
    }
  }

  private obtenerEstadoLeido(element: any): boolean {
    const dataMessages = element?.data_mensajes;
    if (dataMessages?.length) {
      const { conversacion: conversaciones } = dataMessages[0];
      if (conversaciones?.length) {
        return conversaciones[conversaciones.length - 1].leido;
      }
    }
    return false;
  }
}
