<app-header-organizacion></app-header-organizacion>
<div id="content">
  <div class="row">
    <div class="col-12">
      <div class="btn-volver" (click)="onDataUsuarios()">
        <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
        Volver
      </div>

      <div class="contenedor-cuenta">
        <div>
          <div class="user-card">
            <div>
              <span class="iniciales-nombre">
                {{ formUser.get('nombres')?.value.charAt(0) }}{{ formUser.get('apellidos')?.value.charAt(0) }}
              </span>
              <div>
                <span>{{ formUser.get('nombres')?.value }} {{ formUser.get('apellidos')?.value }}</span>
                <span class="pildora-cargo">{{ formUser.get('tipo_trabajador')?.value }}</span>
              </div>
            </div>
          </div>
          <hr />
          <button class="boton-expandir" *ngIf="cambiarClave" (click)="onCambiarContrasena()">Cambiar contraseña</button>
          <span class="fila-display pointer" style="color: #ff7e7e" (click)="onEliminar()" *ngIf="eliminarOpc">
            <fa-icon [icon]="faTrashAlt" [styles]="{ color: '#FF7E7E' }" id="fa-icon"></fa-icon>
            Eliminar
          </span>
        </div>
        <div [formGroup]="formUser">
          <div class="container-info">
            <span>Datos personales</span>
            <div class="grupos">
              <div>
                <span>Tipo de documento</span>
                <select name="tipo_documento" id="" formControlName="tipo_documento" [ngClass]="{
                    'invalid-warning':
                      formUser.get('tipo_documento')?.hasError('required') && formUser.get('tipo_documento')?.touched
                  }">
                  <option value="Cédula de ciudadanía">Cédula de ciudadanía</option>
                  <option value="Pasaporte">Pasaporte</option>
                  <option value="Cédula de extranjería">Cédula de extranjería</option>
                </select>
              </div>

              <div>
                <span>Número de documento</span>
                <input type="text" formControlName="numero_documento" [ngClass]="{
                    'invalid-warning':
                      formUser.get('numero_documento')?.hasError('required') &&
                      formUser.get('numero_documento')?.touched
                  }" />
              </div>
              <div>
                <span>Nombre</span>
                <input type="text" formControlName="nombres" [ngClass]="{
                    'invalid-warning': formUser.get('nombres')?.hasError('required') && formUser.get('nombres')?.touched
                  }" />
              </div>
              <div>
                <span>Apellidos</span>
                <input type="text" formControlName="apellidos" [ngClass]="{
                    'invalid-warning':
                      formUser.get('apellidos')?.hasError('required') && formUser.get('apellidos')?.touched
                  }" />
              </div>
              <div>
                <span>País</span>
                <input type="text" formControlName="pais" />
              </div>
              <!-- <div>
                <span>Departamento</span>
                <input type="text" formControlName="departamento" />
              </div> -->
              <div>
                <span>Departamento</span>

                <select name="departamento" id="" (change)="getPlaces()" formControlName="departamento"
                  (change)="formUser.patchValue({ ciudad: '' })" [ngClass]="{
                    'invalid-warning':
                      formUser.get('departamento')?.hasError('required') && formUser.get('departamento')?.touched
                  }">
                  <option *ngFor="let dpto of departamentos; index as i" [ngValue]="dpto.name">
                    {{ dpto.name }}
                  </option>
                </select>
              </div>
              <div>
                <span>Ciudad</span>
                <select class="option_city" name="ciudad" id="" formControlName="ciudad" [ngClass]="{
                    'invalid-warning': formUser.get('ciudad')?.hasError('required') && formUser.get('ciudad')?.touched
                  }">
                  <option *ngFor="let city of ciudades; index as i" [ngValue]="city.name" class="option_city">
                    {{ city.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="grupos">
              <div>
                <span>Celular</span>
                <input type="text" formControlName="celular" [ngClass]="{
                    'invalid-warning': formUser.get('celular')?.hasError('required') && formUser.get('celular')?.touched
                  }" (keyup)="verificarNumero($event)" />
                <div class="errores" *ngIf="!celularPerson">El celular debe tener 10 números</div>
              </div>
              <div>
                <span>Teléfono</span>
                <input type="text" formControlName="telefono" (keyup)="verificarNumero($event)" />
                <div class="errores" *ngIf="!telefonoPersona">El teléfono debe tener 7 ó 10 números</div>
              </div>
            </div>
            <span>Información cuenta</span>

            <div class="grupos">
              <div>
                <span>Correo de ingreso</span>
                <input type="text" formControlName="correo" />
              </div>

              <div>
                <span>Tipo de persona</span>
                <select formControlName="tipo_trabajador" [ngClass]="{
                    'invalid-warning':
                      formUser.get('tipo_trabajador')?.hasError('required') && formUser.get('tipo_trabajador')?.touched
                  }">
                  <option value="ADMINISTRADOR">ADMINISTRADOR</option>
                  <option value="OPERATIVO">OPERATIVO</option>
                </select>
              </div>
            </div>
          </div>
          <div class="boton-guardar">
            <button class="guardar" [disabled]="!formUser.valid" (click)="onActualizarInformacion()">
              Guardar cambios
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>