<div id="content">
    <!-------------------- Bar Chart -------------------->
    <!-- Referencias por categorías -->
    <div class="card referencias-categorias">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-3">
                <div class="mb-3">Número de referencias por categorías</div>
                <div class="d-flex justify-content-between">
                </div>
            </div>
            <canvas #bar_referencias_categorias
                [ngClass]="{'inactive': data_bar_referencias_categorias.datasets.length == 0}"></canvas>
            <div *ngIf="data_bar_referencias_categorias.datasets.length > 0 else mostrarError"></div>
        </div>
    </div>
    <!-- Referencias por organizaciones -->
    <div class="card referencias-organizaciones">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-3">
                <div class="mb-3">Número de referencias por organizaciones</div>
                <div class="d-flex justify-content-between">
                </div>
            </div>
            <canvas #bar_referencias_organizacion
                [ngClass]="{'inactive': data_bar_referencias_organizacion.datasets.length == 0}"></canvas>
            <div *ngIf="data_bar_referencias_organizacion.datasets.length > 0 else mostrarError"></div>
        </div>
    </div>
    <!-- Ventas por categoría de portafolio -->
    <div class="card ventas-categoria">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-3">
                <div class="mb-3">Ventas por categoría de portafolio</div>
                <div class="d-flex justify-content-between">
                    <span>Total: {{total_ventas_categoria}}</span>
                    <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_categoria"
                        (ngModelChange)="getChartVentasPorCategoria()">
                        <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                            {{mes.filtro}}-{{mes.filtro_inicio}}
                        </option>
                    </select>
                </div>
            </div>
            <canvas #bar_ventas_categoria
                [ngClass]="{'inactive': data_bar_ventas_categoria.datasets.length == 0}"></canvas>
            <div *ngIf="data_bar_ventas_categoria.datasets.length > 0 else mostrarError"></div>
        </div>
    </div>
    <!-- Ventas por organizaciones -->
    <div class="card ventas-organizaciones">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-3">
                <div class="mb-3">Ventas por organización</div>
                <div class="d-flex justify-content-between">
                    <span>Total: {{total_ventas_organizaciones}}</span>
                    <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_organizaciones"
                        (ngModelChange)="getChartVentasPorOrganziones()">
                        <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                            {{mes.filtro}}-{{mes.filtro_inicio}}
                        </option>
                    </select>
                </div>
            </div>
            <canvas #bar_ventas_organizaciones
                [ngClass]="{'inactive': data_bar_ventas_organizaciones.datasets.length == 0}"></canvas>
            <div *ngIf="data_bar_ventas_organizaciones.datasets.length > 0 else mostrarError"></div>
        </div>
    </div>
    <!-- Top 10 productos -->
    <div class="card top-producto">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-3">
                <div class="mb-3">Top 10 productos</div>
                <div class="d-flex justify-content-between">
                    <span>Total: {{total_top_productos}}</span>
                    <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_top_productos"
                        (ngModelChange)="getChartTop10Productos()">
                        <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                            {{mes.filtro}}-{{mes.filtro_inicio}}
                        </option>
                    </select>
                </div>
            </div>
            <canvas #bar_top_productos [ngClass]="{'inactive': data_bar_top_productos.datasets.length == 0}"></canvas>
            <div *ngIf="data_bar_top_productos.datasets.length > 0 else mostrarError"></div>
        </div>
    </div>
    <!-- Saldos -->
    <div class="card saldos">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-3">
                <div class="mb-3">Saldos</div>
                <div class="d-flex justify-content-between">
                    <!-- <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_saldos"
                        (ngModelChange)="getChartSaldos()">
                        <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                            {{mes.filtro}}-{{mes.filtro_inicio}}
                        </option>
                    </select> -->
                </div>
            </div>
            <canvas #bar_saldos [ngClass]="{'inactive': errorSaldos}"></canvas>
            <div *ngIf="!errorSaldos else mostrarError"></div>
        </div>
    </div>
    <!-- Promociones -->
    <div class="card promociones">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-3">
                <div class="mb-3">Promociones</div>
                <div class="d-flex justify-content-between">
                    <!-- <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_promociones"
                        (ngModelChange)="getChartTop10Productos()">
                        <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                            {{mes.filtro}}-{{mes.filtro_inicio}}
                        </option>
                    </select> -->
                </div>
            </div>
            <canvas #bar_promociones [ngClass]="{'inactive': errorPromo}"></canvas>
            <div *ngIf="!errorPromo else mostrarError"></div>
        </div>
    </div>
    <!-- Manejo de errores -->
    <ng-template #mostrarError>
        <div class="d-flex justify-content-center mt-4">
            <div>
                <img src="assets/img/icon-warning-amarillo.png" alt="icono de una hoja de papel y una lupa sobre ella"
                    style="max-width: 150px;" />
            </div>
        </div>
        <div class="mt-2">
            <p class="text-center">
                ¡Lo siento! No existen datos para ver aquí
            </p>
        </div>
    </ng-template>
</div>