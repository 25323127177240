<div class="rounded overflow-hidden punto-entrega-container d-flex flex-row align-items-center">
  <div class="punto-entrega-icono PX-5">
    <img src="../../assets/img/icons/icon-pin-map.png" alt="icono de un pin para señalar un sitio en un mapa"
      srcset="" />
  </div>
  <span class="text-left punto-entrega-texto p-0" id="point">
    <span class="texto-novisible">Punto de entrega:</span>
    <span>{{ punto_entrega?.nombre || 'Selecciona un punto' }}</span>
  </span>
  <span (click)="selecionarPunto()" class="punto-entrega-cambiar px-5 text-center">Cambiar</span>
</div>
<div class="main-container row">
  <!--Calificacion distribuidores-->
  <div class="ranking-distribuidores h-100">
    <div class="card p-4 h-100 overflow-scroll">
      <div class="w-100 border-bottom pb-3">
        <h5 class="font-weight-300">Calificación de distribuidores</h5>
      </div>
      <div class="scroll">
        <div class="dist">
          <!--Info distribuidor-->
          <div class="row row-dist border-bottom my-3 mx-0"
            *ngFor="let distribuidor of ranking_distribuidores; index as i" (click)="verDistribuidor(distribuidor)">
            <!--Icono del distribuidor-->
            <div class="col-3">
              <img src="{{ distribuidor.logo || distribuidor_placeholder }}" class="img-dist"
                alt="Logo del distribuidor {{ distribuidor.nombre }}" />
            </div>
            <!--Info del distribuidor-->
            <div class="col-7">
              <p>
                {{ distribuidor.nombre }}
              </p>
            </div>
            <!--Icono collapse-->
            <div class="col-2 px-0 rating-text text-right">
              <fa-icon [icon]="faStar"></fa-icon>
              {{ distribuidor.ranking }}
            </div>
            <p class="pill-cat col-12 my-3">
              {{ distribuidor.tipo }}
            </p>
          </div>
          <div *ngIf="ranking_distribuidores.length != 0 else mostrarError"></div>
        </div>
      </div>
    </div>
  </div>
  <!------- Graficas ------->
  <!-- Pie - Estados de distribuidores por punto de entrega -->
  <div class="pie-estados card w-100 p-4">
    <div class="row px-3">
      <div class="col-12 text-center pb-1">
        <p class="p-header mb-0">Estados de distribuidores</p>
      </div>
      <div class="col-12 text-center pb-3">
        Total: {{total_estado_distribuidor}}
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="canvas-wrapper" [ngClass]="{'inactive': total_estado_distribuidor == 0}">
          <canvas #pie_estado_distribuidor style="height: 400px; width: 100%"></canvas>
        </div>
        <div *ngIf="total_estado_distribuidor > 0 else mostrarError"></div>
      </div>
    </div>
  </div>
  <!-- Pie - Participacion en compras por punto de entrega -->
  <div class="pie-participacion card w-100 p-4">
    <div class="px-3 row">
      <div class="col-12 text-center pb-1">
        <p class="p-header mb-0">Participación en compras</p>
      </div>
      <div class="col-12 text-center pb-3">
        Total: {{total_distribuidor_compras}}
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div [ngClass]="{'inactive': data_pie_distribuidor_compras.datasets.length == 0}">
          <canvas #pie_distribuidor_compras style="height: 400px; width: 100%"></canvas>
        </div>
        <div *ngIf="data_pie_distribuidor_compras.datasets.length != 0 else mostrarError"></div>
      </div>
    </div>
  </div>
  <!-- Bar - Número de pedidos por distribuidor por punto de entrega -->
  <div class="bar-pedidos card w-100 p-4">
    <div class="row px-3">
      <div class="col-12 text-center pb-1">
        <p class="p-header mb-0">Número de pedidos por distribuidor</p>
      </div>
      <div class="col-12 text-center pb-3">
        Total: {{total_distribuidor_pedidos}}
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="canvas-wrapper" [ngClass]="{'inactive': total_distribuidor_pedidos == 0}">
          <canvas #pie_distribuidor_pedidos style="height: 250px; width: 100%"></canvas>
        </div>
        <div *ngIf="total_distribuidor_pedidos > 0 else mostrarError"></div>
      </div>
    </div>
  </div>
  <!-- Manejo de errores -->
  <ng-template #mostrarError>
    <div class="d-flex justify-content-center mt-4">
      <div>
        <img src="assets/img/icon-warning-amarillo.png" alt="icono de una hoja de papel y una lupa sobre ella"
          style="max-width: 150px;" />
      </div>
    </div>
    <div class="mt-2">
      <p class="text-center">
        ¡Lo siento! No existen datos para ver aquí
      </p>
    </div>
  </ng-template>
</div>