import { HttpClient } from '@angular/common/http';
import { Component, OnInit, SecurityContext } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RestService } from '../services/rest/rest.service';
import { DomSanitizer } from '@angular/platform-browser';
import { faChevronUp, faChevronDown, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-politicas',
  templateUrl: './politicas.component.html',
  styleUrls: ['./politicas.component.css'],
})
export class PoliticasComponent implements OnInit {
  public faChevronDown = faChevronDown;
  public faChevronUp = faChevronUp;
  politicas: any;
  loading = false;
  terminos: any;
  seleccionado = 'tab0';
  constructor(
    public route: ActivatedRoute,
    private restService: RestService,
    private form: FormBuilder,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}
  ngOnInit(): void {
    this.getDocuments();
  }
  public reiniciar() {
    this.seleccionado = 'tab0';
    console.log(this.seleccionado);
  }
  public getDocuments() {
    this.restService
      .getJWT('documentos_politicas')
      .toPromise()
      .then((result: any) => {
        if (result) {
          this.politicas = result[0];
          this.loading = true;
          this.terminos = this.politicas.establecimientos.terminos;
        }
      });
  }
}
