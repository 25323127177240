<!--Indicadores generales-->
<div class="row m-0">
    <div class="col-12 text-left pl-2">
        <p>
            Indicadores generales
        </p>
    </div>
</div>
<div id="canvas-container">
    <!--Establecimientos por tipo de negocio-->
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-12 text-left">
                    Establecimientos por tipo de negocio
                </div>
                <div class="col-12 text-right">
                    Total: {{total_chart_negocio}}
                </div>
            </div>
            <canvas #bar_establecimiento_negocio style="height:250px; width:100%;"></canvas>
        </div>
    </div>
    <!--Establecimientos por categoría-->
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-12 text-left">
                    Establecimientos por categoría
                </div>
                <div class="col-12 text-right">
                    Total: {{total_chart_categoria}}
                </div>
            </div>
            <canvas #bar_establecimiento_categoria style="height:250px; width:100%;"></canvas>
        </div>
    </div>
    <!--Sillas por ciudad-->
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-12 text-left">
                    Sillas por ciudad
                </div>
                <div class="col-12 text-right">
                    Total: {{total_chart_sillas_ciudad}}
                </div>
            </div>
            <canvas #bar_sillas_ciudad style="height:250px; width:100%;"></canvas>
        </div>
    </div>
    <!--Sillas por tipo de negocio-->
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-12 text-left">
                    Sillas por tipo de negocio
                </div>
                <div class="col-12 text-right">
                    Total: {{total_chart_sillas_negocio}}
                </div>
            </div>
            <canvas #bar_sillas_negocio style="height:250px; width:100%;"></canvas>
        </div>
    </div>
    <!--Sillas por categoría-->
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-12 text-left">
                    Sillas por categoría
                </div>
                <div class="col-12 text-right">
                    Total: {{total_chart_sillas_categoria}}
                </div>
            </div>
            <canvas #bar_sillas_categoria style="height:250px; width:100%;"></canvas>
        </div>
    </div>
</div>
<!--Btn ver mas-->
<div class="col-12 text-center my-4">
    <button class="btn-morado" routerLink="/informes-distribuidor">
        Ver más
    </button>
</div>