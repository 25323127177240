import { Component, Input, OnInit } from '@angular/core';
import {
  faChevronRight,
  faMapMarkerAlt,
  faSearch,
  faStoreAlt,
  faTimesCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { EstablecimientosService } from 'src/app/services/establecimientos/establecimientos.service';

@Component({
  selector: 'app-solicitud-card',
  templateUrl: './solicitud-card.component.html',
  styleUrls: ['./solicitud-card.component.css'],
})
export class SolicitudCardComponent implements OnInit {
  /** Referencias a íconos FontAwesome para la UI */
  faSearch = faSearch;
  faTimesCircle = faTimesCircle;
  faChevronRight = faChevronRight;
  faStoreAlt = faStoreAlt;
  faMapMarkerAlt = faMapMarkerAlt;
  faTrash = faTrash;

  nombreEstablecimiento = '';
  _dist_vinculado: any;

  @Input() set dist_vinculado(value: any) {
    this._dist_vinculado = value;
    this.loadNombreEstablecimiento();
  }

  get dist_vinculado(): any {
    return this._dist_vinculado;
  }

  constructor(private establecimientosService: EstablecimientosService) {}

  ngOnInit(): void {
    this.loadNombreEstablecimiento();
  }

  public async loadNombreEstablecimiento() {
    const id =
      this.dist_vinculado.punto_entrega?.usuario_horeca?._id || this.dist_vinculado.punto_entrega?.usuario_horeca || '';
    if (id !== '') {
      this.establecimientosService.getFilteredEstablecimientos(id).subscribe((res) => {
        this.nombreEstablecimiento = res.nombre_establecimiento;
      });
    }
  }
}
