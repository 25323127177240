import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-calificaciones-distribuidor',
  templateUrl: './calificaciones-distribuidor.component.html',
  styleUrls: ['./calificaciones-distribuidor.component.css'],
})
export class CalificacionesDistribuidorComponent implements OnInit {
  /** Guarda la calificación de un distribuidor */
  calificacion = {
    distribuidor: this.auth.user_distribuidor?._id || '',
    calificacion: {
      abastecimiento: 0,
      precio: 0,
      puntualidad_entrega: 0,
      ponderado: 0,
    },
  };
  /** Referencias a íconos FontAwesome para la UI */
  faTimes = faTimes;
  faStar = faStar;

  constructor(private activeModal: NgbActiveModal, private rest: RestService, private auth: AuthService) {}

  ngOnInit(): void {
    this.rest
      .getJWT(`calificacion/${this.auth.user_distribuidor?._id}`)
      .toPromise()
      .then((resp: any) => {
        console.log(resp);
        this.calificacion = resp;
      });
  }

  /**
   * Cierra el modal
   */
  close() {
    this.activeModal.close();
  }
}
