<app-header-distribuidor></app-header-distribuidor>
<div class="container container-fluid p-5">
  <div class="row m-0">
    <div class="col-12">
      <!--Header-->
      <div class="row">
        <!--Titulo-->
        <div class="col-12 col-lg-6">
          <h2>Chequeo de precios..</h2>
        </div>
        <!--Seleccionar ciudad-->
        <div class="col-12 col-lg-6">
          <div class="card card-ubicacion">
            <div class="card-body body-ubicacion">
              <div class="row m-0">
                <div class="col-2 d-flex justify-content-center align-items-center" id="icon-ubicacion">
                  <img src="../../../../assets/img/icons/icon-pin-map.png" style="height: 22px;"
                    alt="Ícono marcador mapa" />
                </div>
                <div class="col-6">
                  <p class="p-card-ubicacion text-left"><span class="text-temporal">Ciudad:</span> {{ ciudad }}</p>
                </div>
                <div class="col-4">
                  <h6 class="p-card-ubicacion" id="p-cambiar-ubicacion" style="cursor: pointer" (click)="changeCity()">
                    Cambiar
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <!--Instrucciones-->
      <div class="row m-0">
        <div class="col-12">
          <p>
            Busca el producto que necesites para comparar los precios con los distribuidores y tomes la mejor decisión
          </p>
        </div>
      </div>
      <br />
      <!--Buscar-->
      <div class="row d-flex justify-content-between">
        <div class="col-12">
          <div class="card card-search">
            <div class="card-body card-body-search p-0 h-100">
              <div class="row h-100 m-0">
                <div class="d-flex flex-row col-12  autocompletado-busqueda p-0">
                  <button class="col-2 col-md-1 p-0 boton-buscar d-flex justify-content-center align-items-center">
                    <fa-icon class="fa-icon-search m-0" [icon]="faSearch" size="lg" [styles]="{ color: '#41403E' }">
                    </fa-icon>
                  </button>
                  <form class="example-form col-10 col-md-11 p-0">
                    <mat-form-field class="example-full-width">
                      <input #escogido type="text" placeholder="Buscar producto" aria-label="Buscar producto" matInput
                        [formControl]="mySearch" [matAutocomplete]="auto" class="w-100 h-100 input-busqueda" />
                      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" [displayWith]="itemDisplayFn"
                        class="w-100" (optionSelected)="fetchProdsThatMatch($event)">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                          {{ option?.nombre }} &nbsp;|&nbsp; {{ option?.codigo_ft}} &nbsp;|&nbsp; {{
                          option?.descripcion}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <!--Estado vacío-->
      <div class="row m-0" *ngIf="producto_buscado == undefined">
        <div class="col-12 text-center">
          <img style="width: 15rem; height: auto" src="../../../../assets/img/esperando-apro.png"
            alt="Ícono de estado vacío" />
          <br />
          <br />
          <p>¡Busca y compara!</p>
          <p>Todavía no has buscado un producto para comparar el precio con tus distribuidores</p>
        </div>
      </div>
      <!--Resultados-->
      <div class="row m-0 p-0" *ngIf="producto_buscado != undefined">
        <div class="col-xl-6 col-lg-8 col-12 p-0">
          <div class="row">
            <!--Producto-->
            <div class="col-12 col-sm-6">
              <div class="card card-prod h-100">
                <div class="card-body img-container">
                  <p>{{ producto_buscado.nombre }}</p>
                  <h4>
                    {{ producto_buscado.cantidad_medida || '' }}
                    {{ producto_buscado.unidad_medida || '' }}
                  </h4>
                  <img class="img-producto" [src]="producto_buscado.logo || product_placeholder" alt="logo" />
                </div>
              </div>
            </div>
            <!--Precios-->
            <div class="col-12 col-sm-6 precios">
              <p>Resultados</p>
              <div class="card">
                <div class="card-body">
                  <p>Precio mínimo</p>
                  <h4>
                    {{ producto_buscado.precio_minimo | currency: '$':'symbol':'1.0-0' }}
                  </h4>
                </div>
              </div>
              <br />
              <div class="card">
                <div class="card-body">
                  <p>Precio máximo</p>
                  <h4>
                    {{ producto_buscado.precio_maximo | currency: '$':'symbol':'1.0-0' }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>