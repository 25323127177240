import { Component, OnDestroy, OnInit } from '@angular/core';
import { Producto } from 'src/app/models/producto.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DateFormatService } from 'src/app/services/date-format/date-format.service';
import { EditarPromoComponent } from 'src/app/modal/editar-promo/editar-promo.component';
import { EditarSaldoComponent } from 'src/app/modal/editar-saldo/editar-saldo.component';
import { RestService } from 'src/app/services/rest/rest.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';

@Component({
  selector: 'app-s-y-p-creados',
  templateUrl: './s-y-p-creados.component.html',
  styleUrls: ['./s-y-p-creados.component.css'],
})
export class SYPCreadosComponent implements OnInit, OnDestroy {
  /** Datos de saldos y promociones */
  public productos: Producto[] = [];
  private id_dist = '';
  modalSaldo: any;
  modalPromo: any;

  constructor(
    private rest: RestService,
    private auth: AuthService,
    private dateFormat: DateFormatService,
    private modalService: NgbModal
  ) {}

  async ngOnInit() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    const modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    this.id_dist = this.auth.user_distribuidor?._id || '';
    await this.fetchData();
    modalCarga.close();
  }

  ngOnDestroy() {
    this.modalSaldo?.close();
    this.modalPromo?.close();
  }

  /**
   * Trae los datos de los saldos y promociones del back y los
   * asigna al arreglo de productos para mostrar en la UI
   */
  async fetchData() {
    try {
      const resp_prods: any = await this.rest
        .getJWT(`saldos_promos_por_distribuidor/todos_distribuidor/${this.id_dist}`)
        .toPromise();
      this.productos = resp_prods.data.productos;
      console.log('Produtos', this.productos);
      if (this.productos.length == 0) {
        const modalRef = this.modalService.open(SimpleComponent);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg =
          'No tienes saldos ni promociones actualmente, empieza hoy creando tu primer saldo/promoción.';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          window.location.reload();
        };
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Toma una fecha que entra por parámetro en formato {YYYY}-{MM}-{DD}T{HH}:{MM}:{SS}.{MS}Z
   * y la devuelve
   * @param pFecha
   */
  procesarFechaVencimiento(pFecha: any) {
    const split_date: string[] = pFecha.split('T')[0].split('-');
    return `${split_date[2]} de ${this.dateFormat.months[Number.parseInt(split_date[1]) - 1]} ${split_date[0]}`;
  }

  popUpEditarSaldo(producto: any) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg',
    };
    this.modalSaldo = this.modalService.open(EditarSaldoComponent, ngbModalOptions);
    this.modalSaldo.componentInstance.producto = producto;
    this.modalSaldo.componentInstance.precios = producto.precios?.slice(0) || [];
    this.modalSaldo.componentInstance.precios_form = producto.precios?.slice(0) || [];
    this.modalSaldo.componentInstance.fotos = producto.fotos?.slice(0) || [];
    this.modalSaldo.componentInstance.selfCreados = this;
  }

  async popUpEditarPromocion(promo: any) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg',
    };
    const modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    const promoCompleta = await this.rest.getJWT(`productoPromocion/${promo._id}`).toPromise();
    modalCarga.close();
    this.modalPromo = this.modalService.open(EditarPromoComponent, ngbModalOptions);
    this.modalPromo.componentInstance.promo = promoCompleta;
    this.modalPromo.componentInstance.inventario_unidades = promo.productos_promocion_inventario_unidades;
    this.modalPromo.componentInstance.selfCreados = this;
  }
}
