<app-header-horeca *ngIf="tipo_usuario === 'usuario_horeca'"></app-header-horeca>
<app-header-distribuidor *ngIf="tipo_usuario === 'distribuidor'"></app-header-distribuidor>
<div class="main-contenedor d-flex justify-content-center">
  <div class="contenedor w-100 h-100">
    <!--Header-->
    <div class="d-flex flex-wrap">
      <div class="col-12 d-flex flex-row justify-content-between p-0">
        <div class="d-flex flex-row align-items-center">
          <div>
            <button class="boton-volver mr-4" (click)="volver()">
              <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }"></fa-icon>
              Volver
            </button>
          </div>
          <div>
            <h3>Mensajes  <i class="fa fa-file-pdf">
              </i></h3>
          </div>
        </div>
        <div>
          <button *ngIf="tipo_usuario === 'distribuidor'" class="btn-enviar p-3"
            (click)="abrirSalaOrganizaciones()">Mensajes con organizaciones</button>
        </div>
      </div>
      <div class="col-12 col-md-6 p-0 mt-3">
        <!--Punto de entrega-->
        <div class="punto-entrega-container rounded overflow-hidden p-0 pr-3" *ngIf="tipo_usuario === 'usuario_horeca'">
          <div class="punto-entrega-icono col-1">
            <img src="../../assets/img/icons/icon-pin-map.png" alt="icono de un pin para señalar un sitio en un mapa"
              srcset="" />
          </div>
          <span class="text-left punto-entrega-texto col-6 col-md-7 col-lg-8 p-0" id="point">
            <span class="texto-novisible">Punto de entrega: </span>
            <span>{{ punto_entrega?.nombre || 'Selecciona un punto' }}</span>
          </span>
          <span (click)="selecionarPunto()" class="punto-entrega-cambiar col-5 col-md-4 col-lg-3 text-center m-0">
            Cambiar
          </span>
        </div>
      </div>
    </div>
    <br />
    <!--Mensajes-->
    <!--Placeholder no data-->
    <div class="col-12 text-center" *ngIf="!historial_conversaciones || historial_conversaciones?.length == 0">
      <img src="../../../assets/img/icon-warning-amarillo.png" alt="Advertencia" id="icon-warning"
        class="w-100 p-4 imagen-vacia" />
      <h5>Nada de nada</h5>
      <p>No se tienen mensajes/pedidos relacionados a este punto de entrega</p>
    </div>

    <!--Todos los mensajes-->
    <div class="row contenedor-conversaciones" *ngIf="historial_conversaciones && historial_conversaciones?.length > 0">
      <!--Minuaturas conversaciones-->
      <div class="container-top col-12 col-md-6 col-lg-5 h-100">
        <div class="h-100 card py-2">
          <div class="scroll" id="scroll-todos">
            <div class="col-12 text-center" *ngIf="!historial_conversaciones">
              <img src="../../../assets/img/icon-warning-amarillo.png" alt="Advertencia" id="icon-warning"
                style="max-width: 200px" class="w-100 p-4" />
              <h5>Nada de nada</h5>
              <p>No tienes mensajes que ver para este pedido</p>
            </div>
            <div class="card-mensaje" *ngIf="historial_conversaciones">
              <div class="card-mensaje" *ngIf="banderaHistorial">
                <div *ngFor="let conversacion of miniaturas_conversaciones">
                  <div class="row m-0 p-3 cursorPointer" (click)="CargarConversacionDeHistorial(conversacion)"
                    [ngClass]="{ 'conversacion-activa': room == conversacion.pedido }">
                    <div class="col-2 p-0">
                      <div class="logo-container">
                        <img src="{{ conversacion.logo_empresa || distribuidor_placeholder }}"
                          alt="logo destinatario" />
                      </div>
                    </div>
                    <div class="row col-10 p-0 pl-3 m-0">
                      <div class="col-12 m-0 p-0">
                        <div class="w-100 d-flex justify-content-between">
                          <p class="mb-2 col-7 p-0">
                            <span class="ellipsis">
                              {{ conversacion.nombre_empresa }} <br>
                              <span class="nombrePunto" *ngIf="conversacion.punto">                                  
                               ({{ conversacion.punto }})
                              </span>
                            </span>
                          </p>
                          
                          <span class="col-5 p-0 text-right d-flex flex-row justify-content-between align-items-center">
                            <a *ngIf="tipo_usuario === 'usuario_horeca'" routerLink="/pedidos/{{ conversacion.pedido }}"
                              style="color: #f6b96c" class="cursosrPointer w-100">
                              {{ conversacion.id_pedido || 'No registra ID' }}
                            </a>
                            <a *ngIf="tipo_usuario === 'distribuidor'"
                              routerLink="/pedidos-distribuidor/{{ conversacion.pedido }}" style="color: #f6b96c"
                              class="cursosrPointer w-100">
                              {{ conversacion.id_pedido || 'No registra ID' }}
                            </a>
                            <span *ngIf="conversacion.leido === false" class="badge-no-leido"></span>
                            <span *ngIf="conversacion.leido === true" class="badge-leido"></span>
                          </span>
                        </div>
                      </div>
                      <div class="col-12 d-flex flex-row justify-content-between p-0"
                        *ngIf="conversacion.conversaciones.length > 0">
                        <p class="overflow-elipsis m-0 text-secondary"
                          *ngIf="conversacion.conversaciones[0].conversacion.at(-1)">
                          {{
                          conversacion.conversaciones[0].conversacion.at(-1).mensaje.includes('="' )
                          ? 'Documento Adjunto'
                          : conversacion.conversaciones[0].conversacion.at(-1).mensaje
                          }}
                        </p>
                        <p class="fecha m-0 text-secondary" *ngIf="conversacion.conversaciones[0].conversacion.at(-1)">
                          {{ conversacion.conversaciones[0].conversacion.at(-1).tiempo }}
                        </p>
                      </div>
                      <div class="col-12 d-flex flex-row justify-content-between p-0"
                        *ngIf="conversacion.conversaciones.length === 0 || conversacion.conversaciones.length === null">
                        <p class="overflow-elipsis m-0 text-secondary">!Aún no tienes mensajes para este pedido!</p>
                      </div>
                    </div>
                  </div>
                  <hr class="my-1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Conversación-->
      <div class="col-12 col-md-6 col-lg-7 h-100">
        <div class="card">
          <div class="card-body" *ngIf="data_conversacion_actual !== undefined">
            <!--Header-->
            <div class="row m-0 mb-0">
              <div class="col-3 d-flex justify-content-center align-items-center p-0">
                <div class="img-container d-flex justify-content-center align-items-center">
                  <div class="logo-container">
                    <img src="{{ header_conversacion_actual.logo || distribuidor_placeholder }}"
                      alt="logo distribuidor" />
                  </div>
                </div>
              </div>
              <div class="col-9 d-flex flex-column justify-content-between">
                <div>
                  <h5 class="m-0">
                    {{ header_conversacion_actual.nombre_empresa }} 
                  </h5>
                </div>
                <div>
                  <p class="m-0">
                    <a *ngIf="tipo_usuario === 'usuario_horeca'"
                      routerLink="/pedidos/{{ data_conversacion_actual._id }}" style="color: #f6b96c"
                      class="cursosrPointer w-100">
                      Pedido: {{ data_conversacion_actual.id_pedido || 'No registra ID' }} - <span *ngIf="header_conversacion_actual.nombre_punto_destinatario" class="namePunto">{{header_conversacion_actual.nombre_punto_destinatario}} </span>
                     
                    </a>
                    <a *ngIf="tipo_usuario === 'distribuidor'"
                      routerLink="/pedidos-distribuidor/{{ data_conversacion_actual._id }}" style="color: #f6b96c"
                      class="cursosrPointer w-100">
                      Pedido: {{ data_conversacion_actual.id_pedido || 'No registra ID' }}  ({{ header_conversacion_actual.punto_nombre }} )
                    </a>
                  </p>
                </div>
                <p class="m-0">
                  {{ header_conversacion_actual.nombre_trabajador }}
                  <span class="text-secondary">({{ header_conversacion_actual.tipo_trabajador }})</span>
                </p>
              </div>
            </div>
            <hr />
            <!--Mensajes-->
            <div class="row h-100">
              <div id="chat-mensajes-caja" class="col-12 caja-mensajes">
                <div class="mensajes h-100" [innerHTML]="someHtmlCode"></div>
              </div>
            </div>
            <hr />
            <!--Acciones-->
            <div class="row">
              <div class="col-12">
                <input type="text" [(ngModel)]="message" placeholder="Escribe aquí" (keydown.enter)="EnviarMensaje()" />
              </div>
              <div class="col-12 d-flex justify-content-end align-items-center pt-3">
                <div class="col-2 d-flex justify-content-end">
                  <button class="btn-img" (click)="enviarImagen()">
                    <fa-icon [icon]="faPaperclip" [styles]="{ color: '#8E6FF7' }"></fa-icon>
                  </button>
                </div>
                <div class="offset-1 col-3 h-100 p-0">
                  <button class="btn-enviar" (click)="EnviarMensaje()">Enviar</button>
                </div>
                <div class="offset-1 col-4 h-100 p-0">
                  <button class="btn-enviar" (click)="GuardarConversacion()" (click)="volver()">Guardar y salir</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 text-center py-3" *ngIf="data_conversacion_actual === undefined">
            <img src="../../../assets/img/icon-warning-amarillo.png" alt="Advertencia" id="icon-warning"
              class="w-100 p-4 imagen-vacia" />
            <h5>Nada de nada</h5>
            <p *ngIf="room !== '-'; else selecciona_room">
              El pedido: "{{ room }}"" no existe para este punto de entrega por lo tanto no puedes acceder al chat.
            </p>
            <ng-template #selecciona_room>
              <p>Selecciona un pedido para poder acceder a su chat.
              </p>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>