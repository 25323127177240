import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-informes-organizaciones',
  templateUrl: './informes-organizaciones.component.html',
  styleUrls: ['./informes-organizaciones.component.css'],
})
export class InformesOrganizacionesComponent implements OnInit {
  /** Variable de control para la navegación del panel */
  active = 1;

  constructor() {}

  ngOnInit(): void {}
}
