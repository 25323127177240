<app-header-distribuidor></app-header-distribuidor>
<div class="d-flex justify-content-center">
  <div id="content" class="m-0">
    <div class="row d-flex justify-content-between m-0">
      <div class="col m-0 col-12 col-lg-7">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills">
          <li [ngbNavItem]="1">
            <a ngbNavLink>Pedidos</a>
            <ng-template ngbNavContent>
              <app-pedidos-info (setListaInfo)="getInfo($event)"></app-pedidos-info>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink>Sugeridos</a>
            <ng-template ngbNavContent>
              <app-pedidos-sugeridos></app-pedidos-sugeridos>
            </ng-template>
          </li>
          <li [ngbNavItem]="3">
            <a ngbNavLink>Gráficas</a>
            <ng-template ngbNavContent>
              <app-pedidos-graficas></app-pedidos-graficas>
            </ng-template>
          </li>

        </ul>
      </div>
      <div *ngIf="active === 1" class="col-12 col-lg-5 justify-content-end d-flex">
        <!--<button class="button-pedidos" (click)="DescargaArchivo()" *ngIf="pedidos?.length > 0">
          Descargar datos de pedidos
        </button>-->
        <button class="button-pedidos font-dowload" (click)="DescargaArchivoPlano('curso', 'reporte_pedidos_en_curso')">
          Descargar pedidos en curso
        </button>
        <button class="button-pedidos font-dowload" (click)="DescargaArchivoPlano('historico', 'reporte_pedidos_historico')" *ngIf="dataHistorico?.length > 0">
          Descargar pedidos historico
        </button>
      </div>
      <div *ngIf="active === 2" class="col-12 col-lg-5 justify-content-end d-flex">
        <button class="button-history" routerLink="/pedidos-sugeridos-historial">
          Historial de pedidos sugeridos
        </button>
      </div>
    </div>
    <div [ngbNavOutlet]="nav" class="mt-2 ml-2"></div>
  </div>
</div>