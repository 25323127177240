<form action="" autocomplete="off">
  <div class="contenedor" *ngIf="step === 1" [formGroup]="crearTrabajadorFormPaso1">
    <div class="space-between">
      <span>Crea nuevo trabajador</span>
      <fa-icon style="cursor: pointer;" [icon]="faTimes" [styles]="{ color: '#41403E' }"
        (click)="activeModal.close('Click close')"></fa-icon>
    </div>
    <ngb-progressbar type="warning" [value]="progressBarValue"></ngb-progressbar>
    <div class="space-between">
      <span>Datos personales</span>
      <span>Paso 1/2</span>
    </div>
    <div class="form-container">
      <div>
        <label for="tipo_documento"><span class="asterisco">*</span>Tipo de documento</label>
        <select name="tipo_documento" id="" formControlName="trabajadorTipo"
          (change)="crearTrabajadorFormPaso1.patchValue({ trabajadorDocumento: '' })"
          [ngClass]="{'invalid-warning': (crearTrabajadorFormPaso1.get('trabajadorTipo')?.hasError('required') && crearTrabajadorFormPaso1.get('trabajadorTipo')?.touched)}">
          <option selected value="" [disabled]="true"> Selecciona</option>
          <option value="Cédula de ciudadanía" (click)="tipo_documento = 'Cédula de ciudadanía'">
            Cédula de ciudadanía
          </option>
          <option value="Pasaporte" (click)="tipo_documento = 'Pasaporte'">
            Pasaporte
          </option>
          <option value="Cédula de extranjería" (click)="tipo_documento = 'Cédula de extranjería'">
            Cédula de extranjería
          </option>
        </select>
      </div>
      <div>
        <label for="numero_documento"><span class="asterisco">*</span>Número de documento</label>
        <input *ngIf="crearTrabajadorFormPaso1.get('trabajadorTipo')?.value === 'Pasaporte'" type="text"
          placeholder="Escribe aquí" formControlName="trabajadorDocumento"
          [ngClass]="{'invalid-warning': (crearTrabajadorFormPaso1.get('trabajadorDocumento')?.hasError('required') && crearTrabajadorFormPaso1.get('trabajadorDocumento')?.touched)}" />
        <input *ngIf="crearTrabajadorFormPaso1.get('trabajadorTipo')?.value !== 'Pasaporte'" type="number"
          placeholder="Escribe aquí" formControlName="trabajadorDocumento" (keydown)="validateNumber($event)"
          [ngClass]="{'invalid-warning': (crearTrabajadorFormPaso1.get('trabajadorDocumento')?.hasError('required') && crearTrabajadorFormPaso1.get('trabajadorDocumento')?.touched)}" />
      </div>
      <div>
        <label for="nombres"><span class="asterisco">*</span>Nombres</label>
        <input name="nombres" type="text" placeholder="Escribe aquí" formControlName="trabajadorNombre"
          (keydown)="validateOnlyText($event)"
          [ngClass]="{'invalid-warning': (crearTrabajadorFormPaso1.get('trabajadorNombre')?.hasError('required') && crearTrabajadorFormPaso1.get('trabajadorNombre')?.touched)}" />
      </div>
      <div>
        <label for="apellidos"><span class="asterisco">*</span>Apellidos</label>
        <input name="apellidos" type="text" placeholder="Escribe aquí" formControlName="trabajadorApellido"
          (keydown)="validateOnlyText($event)"
          [ngClass]="{'invalid-warning': (crearTrabajadorFormPaso1.get('trabajadorApellido')?.hasError('required') && crearTrabajadorFormPaso1.get('trabajadorApellido')?.touched)}" />
      </div>
      <div>
        <label for="pais"><span class="asterisco">*</span>País</label>
        <select name="pais" id="" formControlName="trabajadorPais"
          [ngClass]="{'invalid-warning': (crearTrabajadorFormPaso1.get('trabajadorPais')?.hasError('required') && crearTrabajadorFormPaso1.get('trabajadorPais')?.touched)}">
          <option value="Colombia" (click)="pais = 'Colombia'" selected>Colombia</option>
        </select>
      </div>
      <div>
        <label for="departamento"><span class="asterisco">*</span>Departamento</label>
        <select name="departamento" id="" (change)='getPlaces()' class="w-100" formControlName="trabajadorDepartamento"
          (change)="crearTrabajadorFormPaso1.patchValue({ trabajadorCiudad: '' })"
          [ngClass]="{'invalid-warning': (crearTrabajadorFormPaso1.get('trabajadorDepartamento')?.hasError('required') && crearTrabajadorFormPaso1.get('trabajadorDepartamento')?.touched)}">
          <option *ngFor="let dpto of departamentos; index as i;" [ngValue]="dpto.name">
            {{ dpto.name }}
          </option>
        </select>
      </div>
      <div>
        <label for="ciudad"><span class="asterisco">*</span>Ciudad</label>
        <select class="option_city" name="ciudad" id="" formControlName="trabajadorCiudad"
          [ngClass]="{'invalid-warning': (crearTrabajadorFormPaso1.get('trabajadorCiudad')?.hasError('required') && crearTrabajadorFormPaso1.get('trabajadorCiudad')?.touched)}">
          <option *ngFor="let city of ciudades; index as i;" [ngValue]="city.name" class="option_city">
            {{ city.name }}
          </option>
        </select>
      </div>
      <div>
        <label for="celular"><span class="asterisco">*</span>Celular (10 dígitos)</label>
        <input name="celular" type="number" placeholder="Escribe aquí" (keydown)="validateNumber($event)"
          formControlName="trabajadorCelular"
          [ngClass]="{'invalid-warning': (crearTrabajadorFormPaso1.get('trabajadorCelular')?.hasError('required') && crearTrabajadorFormPaso1.get('trabajadorCelular')?.touched) || crearTrabajadorFormPaso1.get('trabajadorCelular')?.hasError('pattern') && crearTrabajadorFormPaso1.get('trabajadorCelular')?.touched}" />
        <span class="reactive-text text-danger"
          *ngIf="crearTrabajadorFormPaso1.get('trabajadorCelular')?.hasError('pattern') && crearTrabajadorFormPaso1.get('trabajadorCelular')?.touched">
          <span class="alerta">Númerico de 10 dígitos</span>
        </span>
      </div>
      <div>
        <label for="telefono">Teléfono (opcional)</label>
        <input name="telefono" type="text" placeholder="Escribe aquí" (keydown)="validateNumber($event)"
          formControlName="trabajadorTelefono" [ngClass]="{
          'invalid-warning':
            crearTrabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length >= 1 &&
              crearTrabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length != 7 &&
              crearTrabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length != 10 &&
              crearTrabajadorFormPaso1.get('trabajadorTelefono')?.touched }" />
        <span class="reactive-text text-danger" *ngIf="
                crearTrabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length >= 1 &&
                    crearTrabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length != 7 &&
                    crearTrabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length != 10 &&
                    crearTrabajadorFormPaso1.get('trabajadorTelefono')?.touched
              ">
          <span class="alerta">Númerico de 7 o 10 dígitos</span>
        </span>
      </div>
    </div>
    <div>
      <button class="btn-siguiente" (click)="onPaso(2)" *ngIf="is_boton_habilitado">Siguiente</button>
      <!-- Si el formulario es invalido cambia los botones para mostrar alertas -->
      <button class="btn-siguiente" *ngIf="!is_boton_habilitado" (click)="showAlert()"
        [style.background-color]="!is_boton_habilitado ? 'grey' : ''">
        Crear
      </button>
    </div>
  </div>
  <div class="contenedor" *ngIf="step == 2" [formGroup]="crearTrabajadorFormPaso2">
    <div class="space-between">
      <span>Crea nuevo integrante</span>
      <fa-icon [icon]="faTimes" [styles]="{ color: '#41403E' }" (click)="activeModal.close('Click close')"></fa-icon>
    </div>
    <ngb-progressbar type="warning" [value]="progressBarValue"></ngb-progressbar>
    <div class="space-between">
      <span>Datos de cuenta</span>
      <span>Paso 2/2</span>
    </div>
    <div class="form-container">
      <div>
        <label for="correo"><span class="asterisco">*</span>Correo de ingreso.</label>
        <input type="text" name="names" 
       autocorrect="off"
       autocapitalize="none"
       spellcheck="false"
        placeholder="Escribe aquí" [(ngModel)]="correo"
         autocomplete = "new-password"
          formControlName="trabajadorCorreo"
          [ngClass]="{'invalid-warning': (crearTrabajadorFormPaso2.get('trabajadorCorreo')?.hasError('required') && crearTrabajadorFormPaso2.get('trabajadorCorreo')?.touched) || crearTrabajadorFormPaso2.get('trabajadorCorreo')?.hasError('email') && crearTrabajadorFormPaso2.get('trabajadorCorreo')?.touched}" 
           />
        <span class="reactive-text text-danger "
          *ngIf="crearTrabajadorFormPaso2.get('trabajadorCorreo')?.hasError('email') && crearTrabajadorFormPaso2.get('trabajadorCorreo')?.touched">
          <span class="alerta">Ingresa un email valido</span>
        </span>
      </div>
      <div>
        <label for="clave"><span class="asterisco">*</span>Crea una contraseña</label>
        <div class="d-flex flex-row input-group-password">
          <input [type]="fieldTextType ? 'text' : 'password'" autocomplete = "new-password" id="password" name="claves" placeholder="Escribe aquí"
            [(ngModel)]="clave" 
            formControlName="trabajadorContrasena" 
            [ngClass]="{'invalid-warning': (crearTrabajadorFormPaso2.get('trabajadorContrasena')?.hasError('required') && crearTrabajadorFormPaso2.get('trabajadorContrasena')?.touched)}" 
            />
          <div class="input-group-append input-group-password h-100">
            <span class="input-group-text input-group-password h-100">
              <fa-icon [icon]="faEyeSlash" size="sm" [styles]="{'color': 'black'}" (click)="mostrarOcultarPassword()"
                *ngIf="fieldTextType"></fa-icon>
              <fa-icon [icon]="faEye" size="sm" [styles]="{'color': 'black'}" (click)="mostrarOcultarPassword()"
                *ngIf="!fieldTextType"></fa-icon>
            </span>
          </div>
        </div>
      </div>
      <div>
        <label for="tipo_trabajador"><span class="asterisco">*</span>Tipo de usuario</label>
        <select name="tipo_trabajador" id="" [(ngModel)]="tipo_trabajador" formControlName="trabajadorTipoUsuario"
          [ngClass]="{'invalid-warning': (crearTrabajadorFormPaso2.get('trabajadorTipoUsuario')?.hasError('required') && crearTrabajadorFormPaso2.get('trabajadorTipoUsuario')?.touched)}">
          <option selected value="" [disabled]="true">Selecciona</option>
          <option value="PLANEADOR PEDIDOS" (click)="tipo_trabajador = 'PLANEADOR PEDIDOS'"
            *ngIf="tipo_usuario === 'usuario_horeca'">
            PLANEADOR PEDIDOS
          </option>
          <option value="ADMINISTRADOR APROBADOR" (click)="tipo_trabajador = 'ADMINISTRADOR APROBADOR'"
            *ngIf="tipo_usuario === 'usuario_horeca'">
            ADMINISTRADOR APROBADOR
          </option>
          <option value="ADMINISTRADOR" (click)="tipo_trabajador = 'ADMINISTRADOR'"
            *ngIf="tipo_usuario === 'distribuidor'">
            ADMINISTRADOR
          </option>
          <option value="OPERATIVO COMERCIAL" (click)="tipo_trabajador = 'OPERATIVO COMERCIAL'"
            *ngIf="tipo_usuario === 'distribuidor'">
            OPERATIVO COMERCIAL
          </option>
          <option value="PROPIETARIO/REP LEGAL" (click)="tipo_trabajador = 'PROPIETARIO/REP LEGAL'">
            PROPIETARIO/REP LEGAL
          </option>
        </select>
      </div>
      <div class="input-group">
        <label for="punto_venta"><span class="asterisco">*</span>Puntos de entrega asignados</label>
        <select name="select" class="seleccionar-trabajador py-2 w-100" multiple>
          <option *ngFor="let punto_venta of puntos_venta; index as i;" class=""
            (click)="punto_entrega = punto_venta._id!; punto_entrega_nom = punto_venta.nombre;"
            (click)="asignarTrabajador(punto_venta)">
            <span *ngIf="tipo_usuario === 'distribuidor'">{{nombre_establecimientos_por_punto[i]}} &nbsp; | &nbsp;
            </span>
            {{ punto_venta.nombre }}
          </option>
        </select>
      </div>
    </div>
    <div class="d-flex flex-wrap w-100">
      <div *ngFor="let nombre of puntos_entrega_nombre; index as i;" class="col-4 p-1 pr-4 align-items-center">
        <span *ngIf="nombre != null && nombre != ''"
          class="noms_punto_entrega col-12 d-flex justify-content-between align-items-center w-100 p-0">
          <span class="trabajador-seleccionado m-0 p-0">
            {{ nombre }}
          </span>
          <button class="trash" (click)="removerTrabajador(i);">
            <fa-icon [icon]="faTrashAlt" id="fa-icon"></fa-icon>
          </button>
        </span>
      </div>
    </div>
    <div>
      <button class="btn-siguiente boton-volver" (click)="onPaso(1)">
        <fa-icon [icon]="faChevronLeft"></fa-icon>
        Página anterior
      </button>
      <button class="btn-siguiente" (click)="registrarTrabajador()" *ngIf="!crearTrabajadorFormPaso2.invalid">
        Crear
      </button>
      <!-- Si el formulario es invalido cambia los botones para mostrar alertas -->
      <button class="btn-siguiente" *ngIf="crearTrabajadorFormPaso2.invalid" (click)="showAlert()"
        [style.background-color]="crearTrabajadorFormPaso2.invalid ? 'grey' : ''">
        Crear
      </button>
    </div>
  </div>
</form>