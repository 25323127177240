import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-simple-no-img',
  templateUrl: './simple-no-img.component.html',
  styleUrls: ['./simple-no-img.component.css'],
})
export class SimpleNoImgComponent implements OnInit {
  @Input() title = '';
  @Input() msg = '';
  @Input() btn_msg = '';
  @Input() close_callback = () => {
    console.log('simpleComp');
  };

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  onClose() {
    this.activeModal.close('Close click');
    this.close_callback();
  }
}
