export class PuntoEntrega {
  constructor(
    public usuario_horeca: string,
    public nombre: string,
    public pais: string | undefined,
    public departamento: string | undefined,
    public ciudad: string,
    public telefono: number,
    public direccion: string,
    public informacion_contacto: string[],
    public sillas: number,
    public domicilios: boolean,
    public numero_trabajadores: number,
    public tipo_aprobador: string,
    public dias_atencion: string[],
    public horario: string,
    public _id?: string,
    public estado?: string,
    public registerVinculation?: any,
    public coord?: { lat: string; lng: string },
    public formato_coordenada?: any
  ) {}
}
