import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PublicarPublicacionComponent } from '../modal/publicar-publicacion/publicar-publicacion.component';
import { PublicacionService } from '../services/publicacion/publicacion.service';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-comunidad',
  templateUrl: './comunidad.component.html',
  styleUrls: ['./comunidad.component.css'],
})
export class ComunidadComponent implements OnInit {
  // Tipo de usuario logueado
  public tipo_usuario: any;
  // Guarda todas las publicaciones para mostrar en el DOM
  public publication: any;
  // Solo las publicaciones del usuario logueado
  public misPublicaciones = false;
  // Credenciales
  public userInfo = JSON.parse(localStorage.getItem('auth') || '');
  public idUserInfo = this.userInfo['user']['_id'];

  constructor(
    private modalService: NgbModal,
    private publicationService: PublicacionService,
    private restService: RestService
  ) {}

  ngOnInit(): void {
    /** Revisa si es un establecimiento o un distribuidor logueado */
    this.restService
      .get(`tipo_empresa_trabajador/${this.userInfo['user']['_id']}`)
      .toPromise()
      .then((resp: any) => {
        this.tipo_usuario = resp;
        /**Datos del formulario para guardar un comentario */
        //this.fetchPublication();
      });
  }

  /**
   * Abre modal para cargar una publicación
   */
  onPublicar() {
    this.modalService.open(PublicarPublicacionComponent, {
      centered: true,
      windowClass: 'modal-publicar',
    });
  }

  /**
   * Recupera todas las publicaciones para el feed
   */
  fetchPublication() {
    this.publicationService.getPublications().subscribe((result) => {
      this.publication = result;
    });
  }

  /**
   * Metodo para comunicarse con hijo y ver si se muestran todas las publicaciones o solo las propias
   */
  verMisPublicaciones() {
    this.misPublicaciones = !this.misPublicaciones;
  }
}
