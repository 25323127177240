<app-header-horeca></app-header-horeca>
<div id="inicio-grid" class="mt-4">
  <div class="perfil m-0 p-0 overflow-hidden">
    <app-inicio-perfil></app-inicio-perfil>
  </div>
  <div class="promociones m-0 p-0 rounded row" routerLink="/saldos-promociones" id="saldos">
    <div class="col-2 col-sm-4 banner-promociones rounded"></div>
    <div class="col-10 col-sm-8 card-body d-flex align-items-center">
      <p class="m-0 main-title text-left">Saldos y promociones</p>
    </div>
  </div>
  <div class="comunidad m-0 p-0">
    <app-inicio-comunidad></app-inicio-comunidad>
  </div>
  <div class="indicadores m-0 p-0">
    <app-inicio-indicadores></app-inicio-indicadores>
  </div>
  <div class="distribuidores m-0 p-0">
    <app-inicio-distribuidores-conectados></app-inicio-distribuidores-conectados>
  </div>
  <div id="banner-inicio" class="banner carousel-container">
    <ngb-carousel *ngIf="banners">
      <ng-template ngbSlide *ngFor="let images of banners">
        <div class="picsum-img-wrapper d-flex justify-content-center align-items-center cursorPointer"
          (click)="goToLink(images.link)">
          <img src="{{images.imagen}}" alt="banner de {{images.titulo}}" class="img-banner">
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
</div>