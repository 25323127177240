<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <!--Header-->
      <div class="row">
        <div class="col-11">
          <h5>
            Selecciona una ciudad para continuar
          </h5>
        </div>
        <div (click)="activeModal.close()" style="cursor: pointer;" class="col-1 text-right">
          <fa-icon [icon]="faTimes" size="lg">
          </fa-icon>
        </div>
      </div>
      <br />
      <!--Departamento-->
      <div class="row">
        <div class="col-12">
          <h6>
            Departamento
          </h6>
          <select id="departamento" [(ngModel)]="departamento" placeholder="Selecciona" (change)="getPlaces()">
            <option value="Selecciona">Selecciona</option>
            <option *ngFor="let dpto of departamentos; index as i;" [value]="dpto.name">
              {{ dpto.name }}
            </option>
          </select>
        </div>
      </div>
      <br />
      <!--Ciudad-->
      <div class="row">
        <div class="col-12">
          <h6>
            Ciudad
          </h6>
          <select id="ciudad" [(ngModel)]="ciudad" placeholder="Selecciona">
            <option value="Selecciona">Selecciona</option>
            <option *ngFor="let city of ciudades;" [value]="city.name">
              {{ city.name }}
            </option>
          </select>
        </div>
      </div>
      <br />
      <br />
      <!--Btn submit-->
      <div class="row">
        <div class="col-12 text-right">
          <button class="btn-purple" (click)="handleSubmit()"
            [disabled]="departamento == 'Selecciona' || ciudad == 'Selecciona'">
            Listo
          </button>
        </div>
      </div>
    </div>
  </div>
</div>