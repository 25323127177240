<app-header-horeca></app-header-horeca>
<div class="container">
  <h2 class="main-title">
    <label>Mi carrito de compra</label>
    <label class="f-right">
        <span class="tamTotal">Total compras: </span>
      1.125.600 $
    </label>
  </h2>
  <div class="container">
    <div class="row" *ngFor="let compra of newCa; let i = index">
      <div class="col-12">
        <div class="card">
          <div class="row no-gutters h-100">
            <div class="col-md-2 my-auto">
              <img [src]="compra.dataDistribuidor.logo" class="card-img imgPadding" [alt]="compra.dataDistribuidor.nombre" />
            </div>
            <div class="col-md-6 my-auto">
              <div class="card-body">
                <h5 class="card-title">
                    {{compra.dataDistribuidor.nombre}}
                </h5>
                <p class="card-text"> Total compra: {{compra.subTotales.total | number}} $</p>
                <p class="card-text">
                    <small class="text-muted smallSinConvenio" *ngIf="!compra.dataDistribuidor.convenio">Sin convenio</small>
                    <small class="text-muted smallConvenio" *ngIf="compra.dataDistribuidor.convenio">Convenio</small>
                    <small class="text-muted smallCartera" *ngIf="!compra.dataDistribuidor.paz">Cartera</small>
                    <small class="text-muted smallSinCartera" *ngIf="compra.dataDistribuidor.paz">Paz y salvo</small>

                </p>
              </div>
            </div>
            <div class="col-md-4 my-auto">
                <button class="btnProductos">Ver productos ({{compra.productos.length}})</button>
                <fa-icon [icon]="faTrash" [styles]="{ color: '#41403E' }" id="fa-icon" class="iconPapelera" (click)="eliminarDistri(i)"></fa-icon>                
                <fa-icon [icon]="faChevronDown" [styles]="{ color: '#41403E' }" id="fa-icon" class="iconPapelera"></fa-icon>
                <fa-icon [icon]="faChevronUp" [styles]="{ color: '#41403E' }" id="fa-icon" class="iconPapelera"></fa-icon>                
                <fa-icon [icon]="faCheckCircle" [styles]="{ color: 'green' }" id="fa-icon" class="iconPapelera"></fa-icon>                
                
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
