import { Component, DoCheck, OnInit } from '@angular/core';
import { faShoppingCart, faChevronLeft, faCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { faStar, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { CarritoService } from 'src/app/services/carrito/carrito.service';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from 'src/app/services/rest/rest.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DateFormatService } from 'src/app/services/date-format/date-format.service';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';

@Component({
  selector: 'app-detalle-producto',
  templateUrl: './detalle-producto.component.html',
  styleUrls: ['./detalle-producto.component.css'],
})
export class DetalleProductoComponent implements OnInit, DoCheck {
  public id = '';
  // Punto de entrega seleccionado
  public punto_entrega: any = '';
  // Data del producto
  public producto: any;
  // Referencias a íconos FontAwesome para la UI
  public faStar = faStar;
  public faFilePdf = faFilePdf;
  public faChevronLeft = faChevronLeft;
  public faShoppingCart = faShoppingCart;
  public faCircle = faCircle;
  public faSearch = faSearch;
  public productoId: any;
  public distribuidor: any;
  public modalCarga?: NgbModalRef;
  // Guarda la fecha de vencimiento del producto
  public model: any;
  // Guarda el estado de vinculacion
  public vinculacion: any;
  // Total cajas segun unidades seleccionadas
  public total_cajas = 0;
  // Inventario Maximo que se puede acceder
  public inventario_maximo = 0;
  // Guarda el estado de vinculacion del distribuidor
  public estado_vinculacion_punto_distribuidor: any;
  // Cantidad de productos en carrito
  public productcounter = 0;
  // Productos en carrito
  public productosencarrito: any[] = [];
  // Data del pedido
  public order: any;
  // Total a pagar
  public totalPrice = 0;
  // Pocision producto en el carrito
  public index_producto_carrito: any;
  // Producto agregado o no
  public flag_producto_agregado = false;
  // Foto principal
  public foto_principal: any;
  // Data productos en promoción
  public productos_promocion: any[] = [];
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };
  // Almacenar horeca id del usuario
  horecaId = '';

  constructor(
    private rest: RestService,
    private modalService: NgbModal,
    private cartservice: CarritoService,
    private router: Router,
    private auth: AuthService,
    private distribuidorService: DistribuidorService,
    private dateFormat: DateFormatService,
    private route: ActivatedRoute
  ) {
    this.cartservice.orderNext();
    this.cartservice.cart$.subscribe(async (productos) => {
      this.productcounter = productos.length;
    });
    this.cartservice.totalPrice$.subscribe((totalPrice) => {
      this.totalPrice = totalPrice;
    });
    this.cartservice.orderList$.subscribe(async (order) => {
      this.order = order;
    });
    this.cartservice.cart$.subscribe(async (product) => {
      this.productosencarrito = product;
      this.index_producto_carrito = this.productosencarrito.findIndex((x: any) => x._id === this.producto._id);
      if (this.index_producto_carrito >= 0) {
        this.flag_producto_agregado = true;
      }
      await this.encontrarProductoEnCarrito();
    });
  }

  async ngOnInit() {
    try {
      this.punto_entrega = await this.auth.punto_seleccionado;
      if (this.auth?.user_horeca?._id) {
        this.horecaId = this.auth?.user_horeca?._id;
      }
      await this.route.params.subscribe(async (params) => {
        this.id = params.id;
        this.productoId = params.productoid;
      });
      await this.fetchProducto();
      await this.fetchDistribuidor();
    } catch (error) {
      this.mostrarMensajeError();
    }
  }

  ngDoCheck(): void {
    // No se puede pedir mas del inventario o menos que 0
    if (this.index_producto_carrito) {
      if (this.order.products[this.index_producto_carrito]?.unidad < 0) {
        this.order.products[this.index_producto_carrito].unidad = 0;
      } else if (this.order.products[this.index_producto_carrito]?.unidad > this.inventario_maximo) {
        this.order.products[this.index_producto_carrito].unidad = this.inventario_maximo;
      }
    }
  }

  /**
   * Traer data de producto
   */
  fetchProducto() {
    //productoDesc
    this.rest
      .getJWT(`productoDetailValidatorDescuiento/${this.productoId}/${this.punto_entrega._id}/${this.id}`)
      .toPromise()
      .then((resp: any) => {
        this.producto = resp;
        if (this.producto?.precios[0].und_x_caja > 0) {
          this.total_cajas = Math.floor(
            this.producto?.precios[0].inventario_unidad / this.producto?.precios[0].und_x_caja
          );
        } else {
          this.total_cajas = 0;
        }
        this.inventario_maximo = this.producto?.precios[0].inventario_unidad;
        this.foto_principal = this.producto?.fotos[0];
        // Si es una promo
        if (this.producto.promocion === true) {
          this.fetchProductosPromocion();
        }
      })
      .catch((error) => {
        throw error;
      });
  }

  /**
   * Para promociones recupera el nombre y cód de cada producto
   */
  async fetchProductosPromocion() {
    for await (const iterator of this.producto.productos_promocion) {
      this.rest
        .getJWT(`producto/${iterator}`)
        .toPromise()
        .then((resp: any) => {
          console.log(resp);
          const producto = {
            nombre: resp.nombre,
            codigo_distribuidor_producto: resp.codigo_distribuidor_producto,
          };
          this.productos_promocion.push(producto);
        })
        .catch((error) => {
          throw error;
        });
    }
  }

  /**
   * Traer data de distribuidor
   * */
  fetchDistribuidor() {
    this.rest
      .getJWT(`distribuidor/${this.id}`)
      .toPromise()
      .then((resp: any) => {
        this.distribuidor = resp;
        this.getEstadoVinculacionDistribuidorPunto();
      })
      .catch((error) => {
        throw error;
      });
  }

  /**
   * Recupera el estado de vinculacion entre un distribuidor y el punto
   * principlamente se usa para verificar si el distribuidor está en cartera
   */
  getEstadoVinculacionDistribuidorPunto() {
    this.rest
      .getJWT(`/distribuidores_vinculados_dist_punto/${this.id}/${this.punto_entrega._id}`)
      .toPromise()
      .then((vinculacion: any) => {
        this.vinculacion = vinculacion;
        this.estado_vinculacion_punto_distribuidor = vinculacion.data[0];
        if (this.estado_vinculacion_punto_distribuidor.cartera === true) {
          this.showAlertaEnCartera();
        }
      })
      .catch((error) => {
        throw error;
      });
  }

  /**
   * Alerta no puede hacer pedidos por estar en cartera
   */
  showAlertaEnCartera() {
    const modalRef = this.modalService.open(SimpleComponent);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg =
      'No puedes realizar pedidos porque tienes pagos pendientes con este distribuidor. Comunícate con el distribuidor para que puedas volver a crear pedidos.';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/distribuidores']);
    };
  }

  /**
   * El objetivo de esto es sumar o restar la cantidad de cajas o unidades que se comprará de cada producto
   * @param operation es la operación que sera ejecutada (false: resta, true: suma)
   * @param i index del producto en el que se aplicara la operación.
   * @param item identifica si se ejecutará la operación en cajas o productos
   */
  quantity(operation: boolean, i: number, item: string) {
    /** Si el punto está en cartera no podra hacer pedidos */
    if (this.estado_vinculacion_punto_distribuidor === true) {
      this.showAlertaEnCartera();
      return;
    }
    this.cartservice.sumaresta(operation, this.index_producto_carrito, item, 0);
  }

  /**
   * Esta función tiene como objetivo identificar que productos existen
   * en el carrito de compras. Gracias a esto podemos saber en qué momentos hacer el cambio de botones
   * de agregar item por el de agregar y restar items
   */
  async encontrarProductoEnCarrito() {
    /** Si el punto está en cartera no podra hacer pedidos */
    if (this.distribuidor) {
      Promise.all(
        this.distribuidor.productos?.map(async (element: any) => {
          let enCarrito;
          this.productosencarrito.forEach(async (carrito) => {
            if (carrito._id == element._id) {
              enCarrito = carrito;
              const index = this.productosencarrito.indexOf(enCarrito);
              if (index > -1) {
                element.index = index + 1;
              }
            }
          });
        })
      );
    }
  }

  /**
   * Esta función tiene como objetivo añadir un nuevo producto al carrito
   * @param product es el producto que se va a añadir
   * @param unidad cantidad de unidades a agregar
   * @param caja cantidad de cajas a agregar
   */
  agregarProduct(product: any, unidad: number, caja: number, und_x_caja: number, inv_unidades: number) {
    const unidades = parseInt(unidad.toString());
    const cajas = parseInt(caja.toString());
    const unds_x_caja = parseInt(und_x_caja.toString());
    const inventario = parseInt(inv_unidades.toString());
    localStorage.setItem('distribuidorCarrito', this.id);
    this.cartservice.addCart(product, unidades, cajas, unds_x_caja, inventario);
    this.index_producto_carrito = this.productosencarrito.findIndex((x: any) => x._id === this.producto._id);
    this.flag_producto_agregado = true;
  }

  /**
   * Calcula el total de cajas de un producto en base a las unidades pedidas
   * @param und_x_caja unidades que vienen por caja.
   * @param total_unidades total unidades pedidas por el usuario de un producto.
   */
  totalCajasPedido(und_x_caja: number, total_unidades: number) {
    if (und_x_caja !== 0) {
      const total_cajas_pedido = Math.round((total_unidades / und_x_caja) * 100) / 100;
      return total_cajas_pedido;
    } else {
      return 0;
    }
  }

  /**
   * Toma una fecha que entra por parámetro en formato {YYYY}-{MM}-{DD}T{HH}:{MM}:{SS}.{MS}Z
   * y la devuelve
   * @param pFecha
   */
  procesarFechaVencimiento(pFecha: any) {
    const split_date: string[] = pFecha.split('T')[0].split('-');
    return `${split_date[2]} de ${this.dateFormat.months[Number.parseInt(split_date[1]) - 1]} ${split_date[0]}`;
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    // this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/distribuidores']);
    };
  }
  calcularPrecioCaja(producto: any) {
    let precioDescuento =
      producto?.precios[0]?.precio_unidad - producto?.precios[0]?.precio_unidad * (producto?.prodPorcentajeDesc / 100);
    precioDescuento = Math.round(precioDescuento);
    return precioDescuento * producto?.precios[0]?.und_x_caja;
  }
  calcularPrecioCajaEspecial(producto: any) {
    return producto?.descuentosEspeciales[0].precioActual * producto?.precios[0]?.und_x_caja;
  }
  actualizarProductoFavorito(accion: string) {
    if (accion === 'agregar') {
      if (!this.producto?.establecimientos_interesados) {
        this.producto.establecimientos_interesados = [];
      }
      this.producto?.establecimientos_interesados.push(this.horecaId);
    } else if (accion == 'quitar') {
      const index = this.producto?.establecimientos_interesados.indexOf(this.horecaId);
      if (index >= 0) {
        this.producto?.establecimientos_interesados.splice(index, 1);
      }
    }
    const bodyEstablecimientos = {
      establecimientos_interesados: this.producto?.establecimientos_interesados,
    };
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    this.rest
      .putJWT(`producto/${this.producto._id}`, bodyEstablecimientos)
      .toPromise()
      .then((res: any) => {
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
        modalRef.componentInstance.title = '¡Genial!';
        if (accion === 'agregar') {
          modalRef.componentInstance.msg = 'Has agregado este producto a tu lista de favoritos';
        } else {
          modalRef.componentInstance.msg = 'Has eliminado este producto de tu lista de favoritos';
        }
        modalRef.componentInstance.btn_msg = 'Volver';
        this.modalCarga?.close();
      })
      .catch(() => {
        if (accion === 'agregar') {
          const index = this.producto?.establecimientos_interesados.indexOf(this.horecaId);
          if (index >= 0) {
            this.producto?.establecimientos_interesados.splice(index, 1);
          }
        } else {
          this.producto?.establecimientos_interesados.push(this.horecaId);
        }

        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        if (accion === 'agregar') {
          modalRef.componentInstance.msg = 'No fue posible agregar este producto a tu lista de favoritos';
        } else {
          modalRef.componentInstance.msg = 'No fue posible eliminar este producto de tu lista de favoritos';
        }
        modalRef.componentInstance.btn_msg = 'Volver';
        this.modalCarga?.close();
      });
  }
}
