import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Chart } from 'chart.js';
import { LocalDataSource } from 'ng2-smart-table';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import * as moment from 'moment';

@Component({
  selector: 'app-graficas-pedidos',
  templateUrl: './graficas-pedidos.component.html',
  styleUrls: ['./graficas-pedidos.component.css'],
})
export class GraficasPedidosComponent implements OnInit {
  /** ID horeca */
  public id_horeca = this.authService.user_horeca!._id;
  /** Lista de meses para apoyar el formateo de la fecha */
  public filtro_24_meses: any = [];
  public filtro_10_anos: any = [];
  private meses: string[] = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  /** Modal de carga para darle feedback al usuario */
  public modalCarga?: NgbModalRef;
  /** Evita que al hacer click por fuera se cierre el modal */
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };
  /** Número de pedidos por distribuidor */
  @ViewChild('bar_pedidos_distribuidor', { static: true })
  public bar_pedidos_distribuidor!: ElementRef;
  public data_bar_pedidos_distribuidor: any;
  public bar_chart_pedidos_distribuidor: any;
  public filtro_pedidos_distribuidor: any;
  public total_pedidos_distribuidor: any;
  /** Número de pedidos cada mes por año indicado */
  @ViewChild('bar_pedidos_mes', { static: true })
  public bar_pedidos_mes!: ElementRef;
  public data_bar_pedidos_mes: any;
  public bar_chart_pedidos_mes: any;
  public filtro_pedidos_mes: any;
  public total_pedidos_mes: any;
  /** Productos comprados últimos 3 meses por distribuidor */
  @ViewChild('bar_productos', { static: true })
  public bar_productos!: ElementRef;
  public data_bar_productos: any;
  public bar_chart_productos: any;
  public filtro_productos: any;
  public total_productos: any;
  /** Compras por categoria */
  @ViewChild('bar_categorias', { static: true })
  public bar_categorias!: ElementRef;
  public data_bar_categorias: any;
  public bar_chart_categorias: any;
  public filtro_categorias: any;
  public total_categorias: any;
  /** Compras por distribuidor */
  @ViewChild('bar_ventas_distribuidor', { static: true })
  public bar_ventas_distribuidor!: ElementRef;
  public data_bar_ventas_distribuidor: any;
  public bar_chart_ventas_distribuidor: any;
  public filtro_ventas_distribuidor: any;
  public total_ventas_distribuidor: any;
  /** Compras por organizacion */
  @ViewChild('bar_organizacion', { static: true })
  public bar_organizacion!: ElementRef;
  public data_bar_organizacion: any;
  public bar_chart_organizacion: any;
  public filtro_organizacion: any;
  public total_organizacion: any;
  /** Data source de tiempos de entrega últimos 5 pedidos*/
  public settings_tiempo_entrega: any = {};
  public data_tiempo_entrega: any = [];
  public source_tiempo_entrega?: LocalDataSource;
  /** Colores para las gráficas */
  public colors = [
    'rgba(115, 113, 156, 1)',
    'rgba(199, 195, 239, 1)',
    'rgba(247, 234, 194, 1)',
    'rgba(250, 211, 157, 1)',
    'rgba(179, 179, 210, 1)',
    'rgba(142, 111, 247, 1)',
  ];

  constructor(
    private restService: RestService,
    private currency: CurrencyPipe,
    public authService: AuthService,
    private ngxsStore: Store,
    private datePipe: DatePipe,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.data_bar_pedidos_distribuidor = {
      labels: [],
      datasets: [],
    };
    this.data_bar_pedidos_mes = {
      labels: [],
      datasets: [],
    };
    this.data_bar_productos = {
      labels: [],
      datasets: [],
    };
    this.data_bar_categorias = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_distribuidor = {
      labels: [],
      datasets: [],
    };
    this.data_bar_organizacion = {
      labels: [],
      datasets: [],
    };
  }

  async ngOnInit() {
    this.id_horeca = await this.ngxsStore.snapshot().auth.user_horeca._id;
    this.getListaFiltroMeses();
    /** Modal generíco cargando... */
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    /** Configuración y carga de data a tabla tiempos entrega */
    this.configSmartTableTiemposEntrega();
    await this.cargarDatosTablaTiemposEntrega();
    /** Carga datos a graficas */
    await this.getChartPedidosDistribuidor();
    await this.getChartPedidosMes();
    await this.getChartPedidosProducto();
    await this.getChartPedidosCategoria();
    await this.getChartPedidosComprasDistribuidor();
    await this.getChartPedidosOrganizacion();
    /** Cerrar Modal generíco cargando... */
    this.modalCarga?.close();
  }

  /**
   * Arma lista de los últimos 24 meses para el filtro de meses
   */
  getListaFiltroMeses() {
    /** Variables */
    const fecha_actual = new Date();
    let mes_actual = fecha_actual.getMonth();
    let ano_actual = fecha_actual.getFullYear();
    /** Arma filtro para 10 años */
    for (let index = 0; index < 9; index++) {
      this.filtro_10_anos.push(ano_actual - index);
    }
    this.filtro_pedidos_mes = this.filtro_10_anos[0];
    /** Arma filtro 24 meses */
    for (let index = 0; index < 23; index++) {
      if (mes_actual >= 0) {
        const data = {
          filtro: this.meses[mes_actual] + ', ' + ano_actual,
          filtro_inicio: this.meses[mes_actual - 2] + ', ' + ano_actual,
          crudo: Number(mes_actual + 1) + ',' + ano_actual + ',' + (mes_actual - 1) + ',' + ano_actual,
        };
        if (mes_actual <= 1) {
          data.filtro_inicio = this.meses[11 - (1 - mes_actual)] + ', ' + (ano_actual - 1);
          data.crudo = Number(mes_actual + 1) + ',' + ano_actual + ',' + (11 - mes_actual) + ',' + (ano_actual - 1);
        }
        this.filtro_24_meses.push(data);
        mes_actual = mes_actual - 1;
      } else {
        mes_actual = 11;
        ano_actual = ano_actual - 1;
      }
    }
    this.filtro_pedidos_distribuidor = this.filtro_24_meses[0].crudo;
    this.filtro_productos = this.filtro_24_meses[0].crudo;
    this.filtro_categorias = this.filtro_24_meses[0].crudo;
    this.filtro_ventas_distribuidor = this.filtro_24_meses[0].crudo;
    this.filtro_organizacion = this.filtro_24_meses[0].crudo;
  }

  /**
   * Inicializa los datos de las gráficas y llama a los métodos adecuados para mostrar cada una
   */
  /********************************* Cant. pedidos por dist. ********************************/
  public async getChartPedidosDistribuidor() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_pedidos_distribuidor) {
      this.bar_chart_pedidos_distribuidor.destroy();
      this.data_bar_pedidos_distribuidor.labels = [];
      this.data_bar_pedidos_distribuidor.datasets = [];
    }
    /** Se arma data para query  (últimos 12 meses)*/
    const first_day =
      this.filtro_pedidos_distribuidor.split(',')[1] - 1 + '-' + this.filtro_pedidos_distribuidor.split(',')[0] + '-01';
    const last_day =
      this.filtro_pedidos_distribuidor.split(',')[1] + '-' + this.filtro_pedidos_distribuidor.split(',')[0] + '-31';
    let total_pedidos_distribuidor = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/horeca/pedidos/barra/pedidos_distribuidor/${this.id_horeca}/${first_day}/${last_day}`)
      .toPromise()
      .then((pedidos_dist: any) => {
        pedidos_dist = pedidos_dist.data;
        pedidos_dist.forEach((element: any) => {
          this.data_bar_pedidos_distribuidor.labels.push(element.distribuidor_nombre);
          this.data_bar_pedidos_distribuidor.datasets.push(element.total);
          total_pedidos_distribuidor = total_pedidos_distribuidor + element.total;
        });
        this.total_pedidos_distribuidor = total_pedidos_distribuidor;
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
    this.bar_chart_pedidos_distribuidor = new Chart(this.bar_pedidos_distribuidor.nativeElement, {
      type: 'bar',
      data: {
        labels: this.data_bar_pedidos_distribuidor.labels,
        datasets: [
          {
            label: 'Número de pedidos por distribuidor (Últ. 3 meses)',
            backgroundColor: this.colors[0],
            data: this.data_bar_pedidos_distribuidor.datasets,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Número de pedidos realizados últimos tres meses',
            },
          },
          x: {
            title: {
              display: true,
              text: 'Distribuidores',
            },
          },
        },
      },
    });
  }
  /********************************* Cant. pedidos por mes. ********************************/
  public async getChartPedidosMes() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_pedidos_mes) {
      this.bar_chart_pedidos_mes.destroy();
      this.data_bar_pedidos_mes.labels = [];
      this.data_bar_pedidos_mes.datasets = [];
    }
    /** Se arma data para query  (últimos 12 meses)*/
    const first_day = this.filtro_pedidos_mes + '-01-01';
    const last_day = this.filtro_pedidos_mes + '-12-31';
    let total_pedidos_mes = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/horeca/pedidos/barra/pedidos_mes/${this.id_horeca}/${first_day}/${last_day}`)
      .toPromise()
      .then((pedidos_dist: any) => {
        pedidos_dist = pedidos_dist.data;
        pedidos_dist.forEach((element: any) => {
          this.data_bar_pedidos_mes.labels.push(
            this.meses[element._id.split('-')[1] - 1] + '-' + element._id.split('-')[0]
          );
          this.data_bar_pedidos_mes.datasets.push(element.total);
          total_pedidos_mes = total_pedidos_mes + element.total;
        });
        this.total_pedidos_mes = total_pedidos_mes;
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
    this.bar_chart_pedidos_mes = new Chart(this.bar_pedidos_mes.nativeElement, {
      type: 'bar',
      data: {
        labels: this.data_bar_pedidos_mes.labels,
        datasets: [
          {
            label: 'Número de pedidos por distribuidor (Últ. 3 meses)',
            backgroundColor: this.colors[0],
            data: this.data_bar_pedidos_mes.datasets,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Número de pedidos realizados ',
            },
          },
          x: {
            title: {
              display: true,
              text: 'Mes',
            },
          },
        },
      },
    });
  }
  /******************************* Cant. ventas por producto. ******************************/
  public async getChartPedidosProducto() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_productos) {
      this.bar_chart_productos.destroy();
      this.data_bar_productos.labels = [];
      this.data_bar_productos.datasets = [];
    }
    /** Se arma data para query  (últimos 12 meses)*/
    const first_day = this.filtro_productos.split(',')[1] - 1 + '-' + this.filtro_productos.split(',')[0] + '-01';
    const last_day = this.filtro_productos.split(',')[1] + '-' + this.filtro_productos.split(',')[0] + '-31';
    let total_productos = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/horeca/pedidos/barra/productos/${this.id_horeca}/${first_day}/${last_day}`)
      .toPromise()
      .then((pedidos_dist: any) => {
        pedidos_dist = pedidos_dist.data;
        pedidos_dist.forEach((element: any) => {
          this.data_bar_productos.labels.push(element.producto_cod_feat + ' | ' + element.producto_nombre);
          this.data_bar_productos.datasets.push(element.total);
          total_productos = total_productos + element.total;
        });
        this.total_productos = this.currency.transform(total_productos, '$', 'symbol', '1.0-0');
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
    this.bar_chart_productos = new Chart(this.bar_productos.nativeElement, {
      type: 'bar',
      data: {
        labels: this.data_bar_productos.labels,
        datasets: [
          {
            label: 'Productos comprados (Últ. 3 meses)',
            backgroundColor: this.colors[0],
            data: this.data_bar_productos.datasets,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: '$ Valor comprado',
            },
          },
          x: {
            title: {
              display: true,
              text: 'Referencia/producto comprado',
            },
          },
        },
      },
    });
  }
  /******************************* Cant. ventas por categoría ******************************/
  public async getChartPedidosCategoria() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_categorias) {
      this.bar_chart_categorias.destroy();
      this.data_bar_categorias.labels = [];
      this.data_bar_categorias.datasets = [];
    }
    /** Se arma data para query  (últimos 12 meses)*/
    const first_day = this.filtro_categorias.split(',')[1] - 1 + '-' + this.filtro_categorias.split(',')[0] + '-01';
    const last_day = this.filtro_categorias.split(',')[1] + '-' + this.filtro_categorias.split(',')[0] + '-31';
    let total_categorias = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/horeca/pedidos/barra/categorias/${this.id_horeca}/${first_day}/${last_day}`)
      .toPromise()
      .then((pedidos_dist: any) => {
        pedidos_dist = pedidos_dist.data;
        pedidos_dist.forEach((element: any) => {
          this.data_bar_categorias.labels.push(element.categoria_nombre);
          this.data_bar_categorias.datasets.push(element.total);
          total_categorias = total_categorias + element.total;
        });
        this.total_categorias = this.currency.transform(total_categorias, '$', 'symbol', '1.0-0');
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
    this.bar_chart_categorias = new Chart(this.bar_categorias.nativeElement, {
      type: 'bar',
      data: {
        labels: this.data_bar_categorias.labels,
        datasets: [
          {
            label: 'Categorías compradas (Últ. 3 meses)',
            backgroundColor: this.colors[0],
            data: this.data_bar_categorias.datasets,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: '$ Valor categoría comprada',
            },
          },
          x: {
            title: {
              display: true,
              text: 'Categorias compradas',
            },
          },
        },
      },
    });
  }
  /******************************* Cant. ventas por distribuidor ******************************/
  public async getChartPedidosComprasDistribuidor() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_distribuidor) {
      this.bar_chart_ventas_distribuidor.destroy();
      this.data_bar_ventas_distribuidor.labels = [];
      this.data_bar_ventas_distribuidor.datasets = [];
    }
    /** Se arma data para query  (últimos 12 meses)*/
    const first_day =
      this.filtro_ventas_distribuidor.split(',')[1] - 1 + '-' + this.filtro_ventas_distribuidor.split(',')[0] + '-01';
    const last_day =
      this.filtro_ventas_distribuidor.split(',')[1] + '-' + this.filtro_ventas_distribuidor.split(',')[0] + '-31';
    let total_ventas_distribuidor = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/horeca/pedidos/barra/ventas_distribuidor/${this.id_horeca}/${first_day}/${last_day}`)
      .toPromise()
      .then((pedidos_dist: any) => {
        pedidos_dist = pedidos_dist.data;
        pedidos_dist.forEach((element: any) => {
          this.data_bar_ventas_distribuidor.labels.push(element.distribuidor_nombre);
          this.data_bar_ventas_distribuidor.datasets.push(element.total);
          total_ventas_distribuidor = total_ventas_distribuidor + element.total;
        });
        this.total_ventas_distribuidor = this.currency.transform(total_ventas_distribuidor, '$', 'symbol', '1.0-0');
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
    this.bar_chart_ventas_distribuidor = new Chart(this.bar_ventas_distribuidor.nativeElement, {
      type: 'bar',
      data: {
        labels: this.data_bar_ventas_distribuidor.labels,
        datasets: [
          {
            label: 'Compras por distribuidor (Últ. 3 meses)',
            backgroundColor: this.colors[0],
            data: this.data_bar_ventas_distribuidor.datasets,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: '$ Valor de la compra',
            },
          },
          x: {
            title: {
              display: true,
              text: 'Distribuidores a los que se compra',
            },
          },
        },
      },
    });
  }
  /******************************* Cant. ventas por organizacion ******************************/
  public async getChartPedidosOrganizacion() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_organizacion) {
      this.bar_chart_organizacion.destroy();
      this.data_bar_organizacion.labels = [];
      this.data_bar_organizacion.datasets = [];
    }
    /** Se arma data para query  (últimos 12 meses)*/
    const first_day = this.filtro_organizacion.split(',')[1] - 1 + '-' + this.filtro_organizacion.split(',')[0] + '-01';
    const last_day = this.filtro_organizacion.split(',')[1] + '-' + this.filtro_organizacion.split(',')[0] + '-31';
    let total_organizacion = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/horeca/pedidos/barra/organizacion/${this.id_horeca}/${first_day}/${last_day}`)
      .toPromise()
      .then((pedidos_org: any) => {
        pedidos_org = pedidos_org.data;
        pedidos_org.forEach((element: any) => {
          this.data_bar_organizacion.labels.push(element.organizacion_nombre);
          this.data_bar_organizacion.datasets.push(element.total);
          total_organizacion = total_organizacion + element.total;
        });
        this.total_organizacion = this.currency.transform(total_organizacion, '$', 'symbol', '1.0-0');
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
    this.bar_chart_organizacion = new Chart(this.bar_organizacion.nativeElement, {
      type: 'bar',
      data: {
        labels: this.data_bar_organizacion.labels,
        datasets: [
          {
            label: 'Compras por organización (Últ. 3 meses)',
            backgroundColor: this.colors[0],
            data: this.data_bar_organizacion.datasets,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: '$ Valor de compra productos de organizaciones',
            },
          },
          x: {
            title: {
              display: true,
              text: 'Organizaciones de los productos que se compran',
            },
          },
        },
      },
    });
  }
  /**************************************** Tiempos entrega ***************************************/
  /**
   * Configura los titulos y demas settings de la tabla
   * para recibir información de la base de datos
   */
  private configSmartTableTiemposEntrega(): void {
    this.settings_tiempo_entrega = {
      noDataMessage: 'Aún no has realizado pedidos',
      pager: {
        display: true,
        perPage: 10,
      },
      actions: {
        columnTitle: 'Acción',
        add: false,
        edit: false,
        delete: false,
      },
      hideSubHeader: false,
      columns: {
        distribuidor: {
          title: 'Distribuidor',
          editable: false,
          filter: true,
        },
        aprobado: {
          title: 'Hora y fecha pedido aprobado',
          editable: false,
          filter: true,
        },
        entregado: {
          title: 'Hora y fecha pedido entregado',
          editable: false,
          filter: true,
        },
        total: {
          title: 'Tiempo total en horas',
          editable: false,
          filter: true,
        },
      },
    };
  }
  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  private cargarDatosTablaTiemposEntrega(): void {
    this.restService
      .getJWT('informes/horeca/pedidos/tabla/tiempo_entrega/' + this.id_horeca)
      .toPromise()
      .then((distribuidores: any) => {
        distribuidores.data.forEach((tiempo_entrega: any) => {
          //console.log('tiempo entrega', tiempo_entrega);
          const obj_tiempo_entrega = {
            distribuidor: tiempo_entrega.distribuidor_nombre,
            aprobado: this.datePipe.transform(tiempo_entrega.fecha_pedido_aprobado, 'yyyy-MM-dd / HH:mm'),
            entregado: this.datePipe.transform(tiempo_entrega.fecha_pedido_entregado, 'yyyy-MM-dd / HH:mm'),
            total: '',
          };
          const fecha_diferencia = Math.floor(tiempo_entrega.diff / 60e3);
          //console.log('fecha_diferencia', fecha_diferencia);
          const horaA = moment(tiempo_entrega.fecha_pedido_aprobado);
          const horaB = moment(tiempo_entrega.fecha_pedido_entregado);
          const dif = horaB.diff(horaA);
          const semanas = moment.duration(dif).asWeeks();
          const dias = moment.duration(dif).asDays();
          const horas = moment.duration(dif).asHours();
          const minutos = moment.duration(dif).asMinutes();
          const segundos = moment.duration(dif).asSeconds();
          if (segundos >= 1) {
            obj_tiempo_entrega.total = Math.floor(segundos) + ' Segundos(s)';
          }
          if (minutos >= 1) {
            obj_tiempo_entrega.total = Math.floor(minutos) + ' Minutos(s)';
          }
          if (horas >= 1) {
            obj_tiempo_entrega.total = Math.floor(horas) + ' Hora(s)';
          }
          if (dias >= 1) {
            obj_tiempo_entrega.total = Math.floor(dias) + ' Dias(s)';
          }
          if (semanas >= 1) {
            obj_tiempo_entrega.total = Math.floor(semanas) + ' Semana(s)';
          }
          /*if (fecha_diferencia > 1440) {
            obj_tiempo_entrega.total = Math.floor(fecha_diferencia / 1440) + ' día(s)';
          } else if (fecha_diferencia > 60) {
            obj_tiempo_entrega.total = Math.floor(fecha_diferencia / 60) + ' hora(s)';
          } else if (fecha_diferencia > 0) {
            obj_tiempo_entrega.total = Math.floor(fecha_diferencia) + ' minuto(s)';
          }*/
          this.data_tiempo_entrega.push(obj_tiempo_entrega);
        });
        this.source_tiempo_entrega = new LocalDataSource(this.data_tiempo_entrega);
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio']);
    };
  }
}
