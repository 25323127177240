import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest/rest.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-mi-cuenta',
  templateUrl: './mi-cuenta.component.html',
  styleUrls: ['./mi-cuenta.component.css'],
})
export class MiCuentaComponent implements OnInit {
  active = 1;

  /**Guarda el tipo de usuaro logueado y sus puntos */
  tipo_trabajador_logueago = '';

  constructor(private rest: RestService, public auth: AuthService) {
    /**
     * Por temas de permiso de acceso, los usuarios tipo Planeador tendran restringido el acceso
     * a información que no hace parte de su cobertura, por ello se debe traer la información
     * del trabajador loguqeado para verificar que tipo de usuario es, si bien esta se encuentra
     * en el local storage, desde allí puede ser manipulable y el acceso violado
     */
    this.rest
      .getJWT('trabajador/' + this.auth.user?._id)
      .toPromise()
      .then((trabajador_logueado: any) => {
        this.tipo_trabajador_logueago = trabajador_logueado.tipo_trabajador;
      });
  }

  ngOnInit(): void {}
}
