import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Chart } from 'chart.js';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-grafica-detalle-distribuidor',
  templateUrl: './grafica-detalle-distribuidor.component.html',
  styleUrls: ['./grafica-detalle-distribuidor.component.css'],
})
export class GraficaDetalleDistribuidorComponent implements OnInit {
  /** ID distribuidor */
  public id_distribuidor = '';
  /** ID horeca */
  public id_horeca = this.authService.user_horeca!._id;
  /** Compras al distribuidor */
  public compras_distribuidor: any;
  public total_compras_distribuidor: any;
  /** Pie - Compras por categorias */
  @ViewChild('pie_compras_categoria', { static: true })
  public pie_compras_categoria!: ElementRef;
  public data_pie_compras_categoria: any;
  public pie_chart_compras_categoria: any;
  public filtro_compras_categoria: any;
  public total_compras_categoria: any;
  /** Paleta colores */
  public colors = [
    'rgba(199, 195, 239, 1)',
    'rgba(247, 234, 194, 1)',
    'rgba(250, 211, 157, 1)',
    'rgba(179, 179, 210, 1)',
    'rgba(142, 111, 247, 1)',
  ];
  /** Modal de carga para darle feedback al usuario */
  public modalCarga?: NgbModalRef;
  /** Evita que al hacer click por fuera se cierre el modal */
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };

  constructor(
    private route: ActivatedRoute,
    private restService: RestService,
    private authService: AuthService,
    private modalService: NgbModal,
    private currency: CurrencyPipe,
    private router: Router,
    private ngxsStore: Store
  ) {
    this.data_pie_compras_categoria = {
      labels: [],
      datasets: [],
    };
  }

  async ngOnInit() {
    await this.route.params.subscribe(async (params) => {
      this.id_distribuidor = params.id;
    });
    this.id_horeca = await this.ngxsStore.snapshot().auth.user_horeca._id;
    /** Modal generíco cargando... */
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    /** Carga datos graficas */
    await this.getComprasDistribuidor();
    await this.getComprasPorCategoria();
    /** Cerrar Modal generíco cargando... */
    this.modalCarga?.close();
  }

  /**
   * Encuentra los productos comprados al distribuidor y sus cantidades
   */
  public async getComprasDistribuidor() {
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/horeca/pie_chart/distribuidor/compras_producto/${this.id_horeca}/${this.id_distribuidor}`)
      .toPromise()
      .then((compras: any) => {
        this.compras_distribuidor = compras.data;
        this.total_compras_distribuidor = this.compras_distribuidor.length;
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }

  /**
   * Inicializa los datos de la gráfica y carga la data en el dom
   */
  public async getComprasPorCategoria() {
    let total_compras_categoria = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/horeca/pie_chart/distribuidor/compras_categoria/${this.id_horeca}/${this.id_distribuidor}`)
      .toPromise()
      .then((compras: any) => {
        compras.data.forEach((element: any) => {
          this.data_pie_compras_categoria.labels.push(element._id);
          this.data_pie_compras_categoria.datasets.push(element.total);
          total_compras_categoria = total_compras_categoria + element.total;
        });
        this.total_compras_categoria = this.currency.transform(total_compras_categoria, '$', 'symbol', '1.0-0');
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
    this.pie_chart_compras_categoria = new Chart(this.pie_compras_categoria.nativeElement, {
      type: 'doughnut',
      data: {
        labels: this.data_pie_compras_categoria.labels,
        datasets: [
          {
            label: 'Compra por categorias',
            backgroundColor: this.colors.slice(1, this.colors.length - 1),
            data: this.data_pie_compras_categoria.datasets,
          },
        ],
      },
      options: {
        responsive: true,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          legend: {
            position: 'top',
            align: 'center',
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.label;
                const value = context.formattedValue;
                let sum = 0;
                const dataArr = context.dataset.data;
                dataArr.map((data: any) => {
                  sum += data;
                });
                const percentage = ((+value * 100) / sum).toFixed(2) + '%';
                return [label, value, percentage];
              },
            },
          },
        },
      },
    });
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/distribuidores']);
    };
  }
}
