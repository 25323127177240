<div class="row">
  <div class="col-1">
  </div>
  <div class="col-10 text-center">
    <br />
    <div class="row">
      <div class="col-12">
        <img src="../../../assets/img/icon-alert-amarillo.png" alt="Ícono modal" id="icon-modal" />
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-12">
        <h3>
          ¡Espera!
        </h3>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-12 text-center">
        <p>
          {{ title }}
        </p>
        <p>
          {{ msg }}
        </p>
      </div>
    </div>
    <br />
    <br />
    <div class="row">
      <div class="col-6">
        <button id="btn_modal-no" (click)="onNo()">
          {{ btn_msg_no }}
        </button>
      </div>
      <div class="col-6">
        <button id="btn_modal-yes" (click)="onYes()">
          {{ btn_msg_yes }}
        </button>
      </div>
    </div>
    <br />
  </div>
  <div class="col-1">
  </div>
</div>