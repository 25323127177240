<div class="comunidad d-flex flex-column rounded">
  <p class="main-title mb-3">Comunidad</p>
  <!--Feed de posts de comunidad-->
  <div class="row" routerLink="/comunidad">
    <div class="col-12" id="feed">
      <div class="" *ngFor="let item of publication">
        <span class="post-comunidad d-flex flex-column border-bottom">
          <div class="row">
            <div class="col-10 d-flex justify-content-center align-items-center">
              <div class="col-2 px-0">
                <img *ngIf="!item.autorAdmin" src="{{ item.logo || distribuidor_placeholder}}" alt="Logo empresa"
                  class="logo-empresa" />
                <img *ngIf="item.autorAdmin" src="{{logo_feat}}" alt="Logo empresa" class="logo-empresa" />
              </div>
              <div class="col-10 px-0">
                <p class="sub-title col-12">{{ item.titulo }}</p>
                <p class="sub-sub-title col-12">Hace: {{ item.fecha | dateDiff }}</p>
              </div>
            </div>
            <div class="col-2 d-flex justify-content-center align-items-center">
              <fa-icon [icon]="faEllipsisV" size="1x" pull="right" [styles]="{ color: '#41403E' }"></fa-icon>
            </div>
          </div>
          <!--Img-->
          <div class="row">
            <div class="col-12 text-center img-publication rounded my-3 mx-0">
              <div class="w-100" *ngIf="item.imagen_publicacion[0]?.includes('mp4'); else mostrarImagenes">
                <app-video [options]="{
                        autoplay: false,
                        fluid: false,
                        width: 500,
                        preload: 'metadata',
                        sources: [{ src: item.imagen_publicacion[0], type: 'video/mp4' }]
                      }"></app-video>
              </div>
              <ng-template #mostrarImagenes>
                <ngb-carousel>
                  <ng-template ngbSlide *ngFor="let img of item.imagen_publicacion">
                    <img src="{{ img }}" class="img-publication" alt="Imagen de la publicacion" />
                  </ng-template>
                </ngb-carousel>
              </ng-template>
            </div>
          </div>
          <!--Acciones-->
          <div class="row">
            <div class="col-3">
              <p class="mb-1">
                <fa-icon [icon]="faHeart" size="1x" [styles]="{ color: '#41403E' }"></fa-icon>
                &nbsp;{{item.likes?.length}}
              </p>
            </div>
            <div class="col-3">
              <p class="mb-1">
                <fa-icon [icon]="faComment" size="1x" [styles]="{ color: '#41403E' }"></fa-icon>
                &nbsp;{{item.comments?.length}}
              </p>
            </div>
          </div>
          <!--Content-->
          <div class="row">
            <div class="col-12">
              <p>{{ item.contenido }}</p>
            </div>
          </div>
        </span>
      </div>
      <br />
    </div>
  </div>
</div>