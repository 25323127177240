<div class="w-100 graficas-container row m-0 overflow-hidden">
  <!--Tipos de usuario-->
  <div class="tipos-usuarios card">
    <div class="d-flex justify-content-between mb-4">
      <div class="pr-3">Tipos de usuario</div>
      <div class="total-wrapper">
        Total: {{ total_trabajadores_tipo }}
      </div>
    </div>
    <div [ngClass]="{'inactive': total_trabajadores_tipo == 0}">
      <canvas #pie_trabajadores_tipo style="height: 50px; width: 100px"></canvas>
    </div>
    <div *ngIf="total_trabajadores_tipo > 0 else mostrarError"></div>
  </div>
  <!--Estados de usuario-->
  <div class="estado-usuarios card">
    <div class="d-flex justify-content-between mb-4">
      <div class="pr-3">Estados de usuarios</div>
      <div class="total-wrapper">
        Total: {{ total_trabajadores_estado }}
      </div>
    </div>
    <div [ngClass]="{'inactive': total_trabajadores_estado == 0}">
      <canvas #pie_trabajadores_estado style="height: 50px; width: 100px"></canvas>
    </div>
    <div *ngIf="total_trabajadores_estado > 0 else mostrarError"></div>
  </div>
  <!--Pedidos realizados por equipo planeador - 3 meses-->
  <div class="pedidos-planeador card">
    <span class="d-flex flex-row align-items-center justify-content-between pb-0">
      <p class="m-0">Pedidos realizados por equipo planeador - 3 meses</p>
      <div class="total">
        Total: {{ total_pedidos_planeador }}
      </div>
    </span>
    <div class="card-body scroll-tabla mt-3 p-0" [ngClass]="{'inactive': data_pedidos_planeador.length  == 0}">
      <ng2-smart-table [settings]="settings_pedidos_planeador" [source]="source_pedidos_planeador"></ng2-smart-table>
    </div>
    <div *ngIf="data_pedidos_planeador.length > 0 else mostrarError"></div>
  </div>
  <!--Pedidos realizados por equipo aprobador - 3 meses-->
  <div class="pedidos-aprobador card">
    <span class="d-flex flex-row align-items-center justify-content-between pb-0">
      <p class="m-0">Pedidos realizados por equipo aprobador - 3 meses</p>
      <div class="total">
        Total: {{ total_pedidos_administrador }}
      </div>
    </span>
    <div class="card-body scroll-tabla mt-3 p-0" [ngClass]="{'inactive': data_pedidos_administrador.length  == 0}">
      <ng2-smart-table [settings]="settings_pedidos_administrador" [source]="source_pedidos_administrador">
      </ng2-smart-table>
    </div>
    <div *ngIf="data_pedidos_administrador.length > 0 else mostrarError"></div>
  </div>
  <!--Participación de compra en punto de entrega (últ. 3 meses)-->
  <div class="participacion-punto card">
    <div class="d-flex justify-content-between mb-4">
      <div class="pr-3">Participación de compra en punto de entrega (últ. 3 meses)</div>
      <div class="total-wrapper">
        Total: {{ total_puntos_participacion }}
      </div>
    </div>
    <div [ngClass]="{'inactive': data_pie_puntos_participacion.datasets.length == 0}">
      <canvas #pie_puntos_participacion style="height: 250px; width: 100%"></canvas>
    </div>
    <div *ngIf="data_pie_puntos_participacion.datasets.length != 0 else mostrarError"></div>
  </div>
  <!--Número de distribuidores vinculados a cada punto de entrega-->
  <div class="distribuidores-punto card" [ngClass]="{'inactive': total_punto_distribuidores == 0}">
    <div class="d-flex justify-content-between mb-4">
      <div class="pr-3">Número de distribuidores vinculados a cada punto de entrega</div>
      <div class="total-wrapper">
        Total: {{ total_punto_distribuidores }}
      </div>
    </div>
    <div class="canvas-wrapper" [ngClass]="{'inactive': total_punto_distribuidores == 0}">
      <canvas #bar_punto_distribuidores style="height: 250px; width: 100%"></canvas>
    </div>
    <div *ngIf="total_punto_distribuidores > 0 else mostrarError"></div>
  </div>
  <!-- Pedidos por puntos de entrega (últ. 3 meses) -->
  <div class="pedidos-punto card">
    <div class="d-flex justify-content-between mb-4">
      <div class="pr-3">Pedidos por puntos de entrega (últ. 3 meses)</div>
      <div class="total-wrapper">
        Total: {{ total_puntos_pedidos }}
      </div>
    </div>
    <div class="canvas-wrapper" [ngClass]="{'inactive': total_puntos_pedidos == 0}">
      <canvas #bar_puntos_pedidos style="height: 250px; width: 100%"></canvas>
    </div>
    <div *ngIf="total_puntos_pedidos > 0 else mostrarError"></div>
  </div>
  <!--Número de usuarios trabajadores por punto de entrega-->
  <div class="trabajadores-punto card">
    <div class="d-flex justify-content-between mb-4">
      <div class="pr-3">Número de usuarios trabajadores por punto de entrega</div>
      <div class="total-wrapper">
        Total: {{ total_puntos_trabajadores }}
      </div>
    </div>
    <div class="canvas-wrapper" [ngClass]="{'inactive': total_puntos_trabajadores == 0}">
      <canvas #bar_puntos_trabajadores style="height: 250px; width: 100%"></canvas>
    </div>
    <div *ngIf="total_puntos_trabajadores > 0 else mostrarError"></div>
  </div>
  <!-- Manejo de errores -->
  <ng-template #mostrarError>
    <div class="d-flex justify-content-center mt-4">
      <div>
        <img src="assets/img/icon-warning-amarillo.png" alt="icono de una hoja de papel y una lupa sobre ella"
          style="max-width: 150px;" />
      </div>
    </div>
    <div class="mt-2">
      <p class="text-center">
        ¡Lo siento! No existen datos para ver aquí
      </p>
    </div>
  </ng-template>
</div>