import { CurrencyPipe } from '@angular/common';
import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Producto } from 'src/app/models/producto.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { CargandoGenericoComponent } from '../cargando-generico/cargando-generico.component';
import { SimpleComponent } from '../simple/simple.component';

@Component({
  selector: 'app-crear-promo',
  templateUrl: './crear-promo.component.html',
  styleUrls: ['./crear-promo.component.css'],
})
export class CrearPromoComponent implements OnInit, DoCheck {
  /** Para exponer las funciones de Number al template */
  public number = Number;
  /** Referencias a iconos FontAwesome para la UI */
  public faTimes = faTimes;
  public faTimesCircle = faTimesCircle;
  /** Flags para levantar errores de inventario */
  public err_max_unidades: boolean[] = [false, false, false];
  public err_min_unidades: boolean[] = [false, false, false];
  public err_inv_promo = false;
  /** Datos del formulario */
  public nombre_promo = '';
  public codigo_promo = '';
  public precio_promo: any;
  public inventario_promo = 0;
  /** Flag para habilitar el boton de crear promo */
  public btn_promo_enabled = false;
  /** Nuevos inventarios para los productos originales */
  public nuevo_inventario_producto: any;
  public producto_base: any;
  /** Informacion de los productos que deben entrar por parametro */
  @Input() prods: Producto[] = [];
  @Input() fotos: string[] = [];
  @Input() precios: any[] = [];
  @Input() max_inv: number[] = [];

  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private auth: AuthService,
    private currencyPipe: CurrencyPipe,
    private rest: RestService
  ) {}

  ngOnInit(): void {
    this.producto_base = this.precios;
  }

  ngDoCheck(): void {
    let i = 0;
    /** Flag para señalar si hay algún inventario en 0 */
    let err_min = false;
    for (const precio_aux of this.precios) {
      /**
       * Revisa que las unidades no sean mayores a las que se pueden poner en saldo
       * Si se supera, se devuelve al maximo y se levanta un mensaje de alerta
       */
      if (precio_aux.inventario_unidad * this.inventario_promo >= this.max_inv[i]) {
        this.precios[i].inventario_unidad = Math.floor(this.max_inv[i] / this.inventario_promo);
        this.err_max_unidades[i] = true;
      } else {
        this.err_max_unidades[i] = false;
      }
      /** Si no hay inventario no permite agregar unidades a la promo */
      if (!this.max_inv[i] || this.max_inv[i] === 0) {
        this.precios[i].inventario_unidad = 0;
      }
      /**
       * Revisa que haya al menos una unidad de cada producto
       * Si no, se levanta un error indicando esto y se pide al usuario que ponga al
       * menos una unidad o elimine el producto de la promoción
       */
      if (precio_aux.inventario_unidad <= 0) {
        precio_aux.inventario_unidad = 0;
        this.err_min_unidades[i] = true;
        err_min = true;
      } else {
        this.err_min_unidades[i] = false;
      }
      /** Actualizar número de cajas segun cuantas unidades se han puesto en saldo */
      this.precios[i].inventario_caja =
        Math.round((this.precios[i].inventario_unidad / this.precios[i].und_x_caja) * 100) / 100;
      i++;
    }
    /** Ajusta el inventario de la promoción para que nunca sea menos de 0 */
    if (this.inventario_promo < 0) {
      this.inventario_promo = 0;
    }
    /** Revisa si se va a poner al menos una unidad */
    if (this.inventario_promo == 0) {
      this.err_inv_promo = true;
    } else {
      this.err_inv_promo = false;
    }
    /** Revisa que los datos del form sean validos */
    if (
      err_min ||
      this.nombre_promo == '' ||
      this.codigo_promo == '' ||
      this.precio_promo == 0 ||
      this.precio_promo == undefined ||
      this.inventario_promo == 0
    ) {
      this.btn_promo_enabled = false;
    } else {
      this.btn_promo_enabled = true;
    }
  }

  /**
   * Elimina el producto y su informacion cuya posicion en los
   * arreglos entra por parametro
   * @param index La posicion de la informacion del producto
   */
  deleteProd(index: number) {
    this.prods.splice(index, 1);
    this.fotos.splice(index, 1);
    this.precios.splice(index, 1);
    this.max_inv.splice(index, 1);
    if (this.prods.length == 0) {
      this.close();
    }
  }

  /**
   * Cierra el modal y recarga la página de saldos y promociones
   * para poder reiniciar el arreglo de productos seleccionados.
   * Por como está hecho el proceso de pasar los datos de los
   * productos entre el modal y la tabla es necesario volver a
   * cargar
   */
  close() {
    this.activeModal.close();
    window.location.reload();
  }

  /**
   * Toma los datos del form, crea una nueva promoción, y
   * lo asocia al distribuidor que lo creó
   */
  async createPromo() {
    /** Opciones para los modales de error y exito */
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    const modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    const id_dist = this.auth.user_distribuidor?._id || '';
    const nombre_dist = this.auth.user_distribuidor?.nombre || '';
    /** Nombres en string para la descripcion de la promocion */
    let nombres = this.prods[0].nombre;
    if (this.prods.length == 2) {
      nombres += `y ${this.prods[1].nombre}`;
    } else if (this.prods.length == 3) {
      nombres += `, ${this.prods[1].nombre}, y ${this.prods[2].nombre}`;
    }
    /** Arreglo de ids de los productos para asociar a la promocion */
    const prods_ids: string[] = [];
    const precios = [];
    const data_inventarios_actualizados: any = [];
    let i = 0;
    for (const aux of this.prods) {
      prods_ids.push(aux._id || '');
      const inv_und = this.max_inv[i] - aux.precios![0].inventario_unidad! * this.inventario_promo;
      const inv_caja = aux.precios![0].und_x_caja === 0 ? 0 : Math.floor(inv_und / aux.precios![0].und_x_caja!);
      /** Se actualiza producto base */
      const objeto_actualizado = {
        precios: [
          {
            estado: aux.precios![0].estado,
            inventario_caja: inv_caja,
            inventario_unidad: inv_und,
            precio_caja: aux.precios![0].precio_caja,
            precio_descuento: aux.precios![0].precio_descuento,
            precio_unidad: aux.precios![0].precio_unidad,
            puntos_ft_caja: aux.precios![0].puntos_ft_caja,
            puntos_ft_unidad: aux.precios![0].puntos_ft_unidad,
            und_x_caja: aux.precios![0].und_x_caja,
            unidad_medida: aux.precios![0].unidad_medida,
            cantidad_medida: aux.precios![0].cantidad_medida,
          },
        ],
      };
      data_inventarios_actualizados.push(objeto_actualizado);
      ++i;
    }
    /** Nueva promocion como producto */
    this.precio_promo = this.precio_promo.toString().replace(/[^\d,-]/g, '');
    const promo = new Producto(
      this.nombre_promo,
      `Promoción de ${nombres} por ${this.precio_promo}`,
      this.fotos,
      [
        {
          unidad_medida: undefined,
          cantidad_medida: undefined,
          estado: 'Disponible',
          precio_unidad: this.precio_promo,
          precio_caja: 0,
          precio_descuento: this.precio_promo,
          inventario_unidad: Number(this.inventario_promo),
          inventario_caja: 0,
          puntos_ft_unidad: 0,
          puntos_ft_caja: 0,
          und_x_caja: 0,
        },
      ],
      undefined,
      undefined,
      true,
      false,
      undefined,
      id_dist,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      nombre_dist,
      prods_ids,
      this.codigo_promo,
      undefined,
      undefined,
      'Aceptado'
    );
    const unidades_promo_producto: number[] = [];
    this.precios.forEach((element: any) => {
      unidades_promo_producto.push(element.inventario_unidad);
    });
    promo.productos_promocion_inventario_unidades = unidades_promo_producto;
    promo.estadoActualizacion = 'Aceptado';
    promo.codigo_distribuidor = id_dist;
    try {
      console.log('promo', promo);
      /** Se publica la promocion y se guarda su id */
      const resp_promo: any = await this.rest.postJWT('producto', promo).toPromise();
      const id_promo: string = resp_promo.data._id;
      console.log('id_promo', id_promo);

      /** Se actualizan productos base */
      for (let i = 0; i < this.prods.length; i++) {
        await this.rest.putJWT(`producto/${prods_ids[i]}`, data_inventarios_actualizados[i]).toPromise();
      }
      /**
       * Se buscan los productos asociados al distribuidor para actualizar
       * el listado de sus productos en productos_por_distribuidor con
       * la promocion recien publicada
       */
      const resp_prods_dist: any = await this.rest
        .getJWT(`productos_por_distribuidor/distribuidor/${id_dist}`)
        .toPromise();
      const id_prods_x_dist: string = resp_prods_dist._id;
      const nuevos_prods: string[] = [];
      if (resp_prods_dist) {
        for (const prod_aux of resp_prods_dist.productos) {
          nuevos_prods.push(prod_aux._id);
        }
      }
      nuevos_prods.push(id_promo);
      await this.rest.putJWT(`productos_por_distribuidor/${id_prods_x_dist}`, { productos: nuevos_prods }).toPromise();
      /** Mensaje de exito por la carga de la promocion */
      modalCarga.close();
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../../assets/img/icon-check-verde.png';
      modalRef.componentInstance.title = '¡Genial!';
      modalRef.componentInstance.msg = 'La promoción fue cargada al sistema con éxito.';
      modalRef.componentInstance.btn_msg = 'Listo';
      modalRef.componentInstance.close_callback = () => {
        this.close();
      };
    } catch (err) {
      console.log(err);
      modalCarga.close();
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'No fue posible cargar esta promoción. Por favor intenta de nuevo más tarde.';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {};
    }
  }

  /**************************************** Validadores ****************************************/
  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateNumber(evento: any) {
    const keyCode = evento.keyCode;
    const excludedKeys = [8, 37, 3];
    if (
      !(
        keyCode == 190 ||
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      evento.preventDefault();
    }
  }

  /**
   * Transforma el dinero minimo de compra de número a moneda y viceversa
   * a moneda se utilizará para reemplazar el input y dar mejor UX al usuario
   * y a número plano para guardar el dato correctamente en la base de datos
   * ademas de poder editar mejor el valor en el input
   */
  public transformCurrency(event: any) {
    this.precio_promo = event.value.replace(/[^\d,-]/g, '');
  }
  public transformAmount(event: any) {
    this.precio_promo = this.currencyPipe.transform(event.value, '$ ', 'symbol', '1.0-0');
  }
}
