import { Component, Input, OnInit } from '@angular/core';
import { faCamera, faUpload } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';
import { RestService } from '../../services/rest/rest.service';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-cargar-imagen-mensajes',
  templateUrl: './cargar-imagen-mensajes.component.html',
  styleUrls: ['./cargar-imagen-mensajes.component.css'],
})
export class CargarImagenMensajesComponent implements OnInit {
  /** Referencias a íconos */
  faUpload = faUpload;
  faCamera = faCamera;

  /** Guarda el archivo cargado por el usuario */
  public archivos: File[] = [];

  /** Guarda el logo que se vayan a subir */
  imageSrc: any;

  /** Guarda la url de la imagen guardada */
  @Input() public url_imagen = '';

  /** Modal de carga para darle feedback al usuario */
  modalCarga?: NgbModalRef;

  callback = (url_imagen: string) => {};
  typeFilePDF: boolean | undefined;
  public faFilePdf = faFilePdf;

  constructor(
    private rest: RestService,
    private distribuidorService: DistribuidorService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {}

  /**
   * Se carga la imagen y se muestra en el modal
   */
  cargarImagen(event: any) {
    const logoCapturado = <File>event.target.files[0];
    if (logoCapturado.size > 2000000) {
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Este archivo supera el máximo de 2MB permitido!';
      modalRef.componentInstance.btn_msg = 'Volver';
      return;
    } else {
      this.archivos[0] = logoCapturado;
      if (this.archivos[0].type === 'application/pdf') {
        this.typeFilePDF = true;
      } else {
        this.typeFilePDF = false;
      }
      // Una vez el usuario sube una imagen, esta se mostrará en el DOM
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => (this.imageSrc = reader.result);
        reader.readAsDataURL(file);
      }
    }
  }

  /**
   * Se carga imagen en AWS y se recibe url que se manda a componente padre
   */
  enviarImagen() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    try {
      if (this.archivos[0] != undefined) {
        const upload_form: FormData = new FormData();
        upload_form.append(`logo`, this.archivos[0]);
        this.rest
          .postJWT('recursos/mensajes', upload_form)
          .toPromise()
          .then((res: any) => {
            this.modalCarga?.close();
            this.url_imagen = res.logo_ruta[0];
            /** Se envia información al padre */
            this.callback(this.url_imagen);
            /** Alerta exito y cierra */
            const modalRef = this.modalService.open(SimpleComponent);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
            modalRef.componentInstance.title = '¡Genial!';
            modalRef.componentInstance.msg = '¡Tu Publicacion ha sido creada con éxito!';
            modalRef.componentInstance.btn_msg = 'Listo';
            modalRef.componentInstance.close_callback = () => {
              this.activeModal.close();
            };
          });
      }
    } catch (error) {
      this.modalCarga?.close();
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'Ocurrió un error inesperado, uno o todos tus documentos/logo no pudieron ser guardados ¡Por favor intenta de nuevo más tarde!';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {};
    }
  }
}
