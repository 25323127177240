import { AgmMap, MapsAPILoader } from '@agm/core';
import { Options } from '@angular-slider/ngx-slider';
import {
  Component,
  OnInit,
  ViewChild,
  DoCheck,
  NgZone,
  OnDestroy,
  ElementRef,
  AfterViewInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { faChevronLeft, faSearch, faTimesCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { PuntoEntrega } from 'src/app/models/punto_entrega.model';
import { Trabajador } from 'src/app/models/trabajador.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MapService } from 'src/app/services/map/map.service';
import { PlacesService } from 'src/app/services/places/places.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { SimpleComponent } from '../simple/simple.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/tools/toast.service';

@Component({
  selector: 'app-multipaso-form-punto-venta',
  templateUrl: './multipaso-form-punto-venta.component.html',
  styleUrls: ['./multipaso-form-punto-venta.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MultipasoFormPuntoVentaComponent implements OnInit, DoCheck, OnDestroy, AfterViewInit {
  /** Íconos FontAwesome para la UI */
  faChevronLeft = faChevronLeft;
  faTimesCircle = faTimesCircle;
  faSearch = faSearch;
  faTrashAlt = faTrashAlt;

  /** Variables de control para la navegación del formulario */
  paso_actual = 1;

  /**Deshabilita botón atras */
  boton_atras_deshabilitado = false;

  /** Datos para el registro del punto de venta */
  nombre: any;
  ciudad: any;
  pais = 'Colombia';
  direccion: any;
  telefono: any;
  sillas: any;
  domicilio: any;
  trabajador_str: any;
  trabajador_actual!: Trabajador | null;
  trabajadores_asignados: (Trabajador | null)[] = [];

  /** Variables para el manejo de la lista de departamentos y ciudades */
  departamento: any;
  ciudades: any;
  departamentos: any;
  mapClickListener: any;
  autoCompleteMaps!: google.maps.places.Autocomplete;

  /**
   * Listado de trabajadores asociados a la empresa para escoger cuáles agregar
   * al punto de venta que está siendo creado
   */
  trabajadores: Trabajador[] = [];

  /**
   * Datos del encargado del punto de venta
   * Son de la forma e_<variable>
   */
  e_nombres = '';
  e_apellidos = '';
  e_correo = '';
  e_telefono: any;

  /** Variables de control para el slider del horario de atención */
  minValue = 8;
  maxValue = 17;
  options: Options = {
    floor: 0,
    ceil: 24,
    step: 0.5,
    showTicks: false,
    translate: (): string => {
      return '';
    },
  };

  /** Modal de carga para darle feedback al usuario */
  modalCarga?: NgbModalRef;

  /** Variables de control para el registro del punto y la actualización de los trabajadores */
  cnt = 0;
  trab_success: string[] = [];

  /** Datos para el manejo del mapa */
  public map: any = { lat: 4.678508639544325, lng: -74.05550588007192 };
  @ViewChild('AgmMap') agmMap!: AgmMap;
  @ViewChild('addressInput') addressInput!: ElementRef;

  /** Guarda booleano que verifica si al menos un día de atención fue seleccionado */
  public validatorDiaSeleccionado = false;

  /**Guardara booleano para habilitar o no boton de continuar */
  public isBotonHabilitado = false;
  public isFormularioCompleto = false;

  /**Formularios reactivos */
  crearPuntoFormPaso1: FormGroup;
  crearPuntoFormPaso2: FormGroup;
  listDistribuidoresVIP: any;

  constructor(
    private authService: AuthService,
    private restService: RestService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private router: Router,
    private mapService: MapService,
    public places: PlacesService,
    public toastService: ToastService,
    private FormBuilder: FormBuilder,
    private zone: NgZone,
    private mapsAPILoader: MapsAPILoader
  ) {
    this.crearPuntoFormPaso1 = this.FormBuilder.group({
      puntoLunes: [false],
      puntoMartes: [''],
      puntoMiercoles: [''],
      puntoJueves: [''],
      puntoViernes: [''],
      puntoSabado: [''],
      puntoDomingo: [''],
      puntoFestivos: [''],
      puntoNombre: ['', Validators.required],
      puntoPais: ['', Validators.required],
      puntoDepartamento: ['Bogotá D.C.'],
      puntoCiudad: ['Bogotá'],
      puntoDireccion: ['', Validators.required],
      puntoTelefonoFijo: ['', [Validators.required, Validators.pattern(/^(?=[0-9]*$)(?:.{7}|.{10})$/)]],
      puntoSillas: ['', [Validators.required, Validators.pattern('[0-9]+')]],
      puntoDomicilio: ['', Validators.required],
    });
    this.crearPuntoFormPaso2 = this.FormBuilder.group({
      puntoNombreEncargado: ['', Validators.required],
      puntoApellidoEncargado: ['', Validators.required],
      puntoCorreoEncargado: ['', [Validators.required, Validators.email]],
      puntoTelefonoEncargado: ['', [Validators.required, Validators.pattern(/^(?=[0-9]*$)(?:.{7}|.{10})$/)]],
    });
  }

  ngDoCheck(): void {
    this.checkIfFormIsValid();
  }

  ngOnInit(): void {
    this.departamento = this.authService.user_horeca?.departamento || '';
    this.getPlaces();
    this.getDistribuidoresVinculadosAutomaticos();
    this.getTrabajadores();
  }

  ngOnDestroy(): void {
    if (this.mapClickListener) {
      this.mapClickListener.remove();
    }
  }

  ngAfterViewInit() {
    this.getCoordAutocompletar();
  }

  getCoordAutocompletar() {
    this.mapsAPILoader.load().then(() => {
      if (this.autoCompleteMaps) {
        this.autoCompleteMaps.unbindAll();
      }
      this.autoCompleteMaps = new google.maps.places.Autocomplete(this.addressInput.nativeElement);
      this.autoCompleteMaps.addListener(`place_changed`, () => {
        this.zone.run(() => {
          // some details
          const place: google.maps.places.PlaceResult = this.autoCompleteMaps.getPlace();
          const address = place.formatted_address;
          this.map.lat = place.geometry?.location.lat();
          this.map.lng = place.geometry?.location.lng();
          if (address) {
            this.direccion = address;
            this.crearPuntoFormPaso1.get('puntoDireccion')?.setValue(address);
          }
        });
      });
    });
  }

  /**
   * Recuperar la lista de puntos de venta asociados a la empresa para
   * luego recuperar la lista de trabajadores asociados a la empresa
   */
  getTrabajadores() {
    const id_horeca: string = this.authService.user?.usuario_horeca || '';
    let tiene_puntos_venta = false;
    this.restService
      .getJWT('punto_entrega')
      .toPromise()
      .then((resp: any) => {
        let i = 0;
        for (i = 0; i < resp.length && !tiene_puntos_venta; i++) {
          if (resp[i].usuario_horeca == id_horeca) {
            tiene_puntos_venta = true;
          }
        }
        this.restService
          .get('trabajador')
          .toPromise()
          .then((resp_2: any) => {
            let i = 0;
            let trab_aux: Trabajador;
            let resp_act: any;
            for (i = 0; i < resp_2.length; i++) {
              resp_act = resp_2[i];
              if (resp_act.usuario_horeca == id_horeca && resp_act.solicitud_vinculacion === 'Aprobado') {
                trab_aux = new Trabajador(
                  resp_act.nombres,
                  resp_act.apellidos,
                  resp_act.correo,
                  resp_act.clave,
                  resp_act.telefono,
                  resp_act.celular || '',
                  resp_act.pais,
                  resp_act.departamento,
                  resp_act.ciudad,
                  resp_act.tipo_documento,
                  resp_act.numero_documento,
                  resp_act.tipo_trabajador,
                  resp_act.soliticutd_vinculacion,
                  resp_act.show_slides,
                  resp_act.usuario_horeca,
                  resp_act.puntos_entrega || [],
                  undefined,
                  undefined,
                  resp_act._id
                );
                this.trabajadores.push(trab_aux);
              }
            }
            this.trabajadores.sort(function (a, b) {
              return a.nombres.localeCompare(b.nombres);
            });
          })
          .catch(() => {
            const ngbModalOptions: NgbModalOptions = {
              //Evita que al hacer click por fuera se cierre el modal
              backdrop: 'static',
              keyboard: false,
            };
            this.activeModal.close('Close click');
            const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
            modalRef.componentInstance.title = '¡Oh oh!';
            modalRef.componentInstance.msg =
              'La lista de trabajadores que puedes agregar a tu punto de venta no está disponible. Por favor inténtalo de nuevo más tarde.';
            modalRef.componentInstance.btn_msg = 'Listo';
            modalRef.componentInstance.close_callback = () => {
              this.router.navigate(['/cuenta']);
            };
          });
      });
  }
  async getDistribuidoresVinculadosAutomaticos() {
    this.restService
      .getJWT('consultar_vinculaciones_web')
      .toPromise()
      .then((resp: any) => {
        this.listDistribuidoresVIP = resp;
      });
  }
  /**
   * Metodo para conseguir ciudades y departamentos del pais
   * */
  async getPlaces() {
    await this.places.getDepartmentFromServerImagine();
    this.departamentos = this.places.departments_colombia;
    let id_dpto = 0;
    for (const dpto of this.places.departments_colombia) {
      if (dpto.name == this.departamento) {
        break;
      }
      id_dpto++;
    }
    if (this.places.departments_colombia[id_dpto] != undefined) {
      await this.places.getCitiesFromServerImagine(this.places.departments_colombia[id_dpto].code);
      this.ciudades = this.places.cities_colombia;
    } else {
      this.ciudades = [];
    }
  }

  /**
   * Tomar la dirección ingresada, consultar en Google Maps y poner el marcador en el mapa
   */
  buscarDireccion() {
    if (this.ciudad != '') {
      //La dirección más corta posible es de 8 caracteres
      const dir = this.ciudad + ', ' + this.departamento;
      this.mapService
        .getLatLong(dir)
        .toPromise()
        .then((resp: any) => {
          if (resp.status == 'OK') {
            if (resp.results[0]) {
              this.map.lat = resp.results[0].geometry.location.lat;
              this.map.lng = resp.results[0].geometry.location.lng;
              const address = resp.results[0].formatted_address;
              if (address) {
                this.direccion = address;
              }
              const bounds = new google.maps.LatLngBounds();
              bounds.extend(this.map);
              this.agmMap.mapReady.subscribe((map) => {
                map.fitBounds(bounds);
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  enterMapRegistro() {
    let direccionBusqueda = '';
    if (this.ciudad) {
      direccionBusqueda = `${this.departamento},
        ${this.ciudad}`;
    }
    if (this.direccion) {
      direccionBusqueda = direccionBusqueda ? `${this.direccion}, ${direccionBusqueda}` : this.direccion;
    }
    this.mapService
      .getLatLong(direccionBusqueda)
      .toPromise()
      .then((resp_map: any) => {
        if (resp_map.status == 'OK') {
          if (resp_map.results[0]) {
            this.map.lat = resp_map.results[0].geometry.location.lat;
            this.map.lng = resp_map.results[0].geometry.location.lng;
          }
        }
      });
  }

  /**
   * Avanza al siguiente paso del formulario
   */
  siguiente() {
    this.paso_actual++;
    if (this.paso_actual > 2) {
      this.paso_actual = 2;
    }
  }

  /**
   * Retrocede al paso anterior del formulario
   */
  anterior() {
    this.paso_actual--;
    if (this.paso_actual < 1) {
      this.paso_actual = 1;
    }
  }

  /**
   * Permite traducir un número del slider de horario a una
   * hora legible por el usuario
   * @param value El valor a traducir entre 0.0 y 24.0 en saltos de 0.5
   * @returns Un String entre 0:00 y 24:00 pensado como horas en saltos de 30 minutos
   */
  valueToTime(value: number): string {
    const value_str: string[] = value.toString().split('.');
    const hr: string = value_str[0];
    let mn = '00';
    if (value_str[1] != null) {
      mn = '30';
    }
    return hr + ':' + mn;
  }

  /**
   * Toma el trabajador que fue seleccionado por el usuario y lo agrega a la lista de
   * trabajadores asignados al punto de venta
   */
  asignarTrabajador(target: any) {
    for (const iterator of this.trabajadores_asignados) {
      //Salir si ya el trabajador fue seleccionado
      if (iterator == target) {
        return;
      }
    }
    this.trabajadores_asignados.push(target);
  }
  procesarVinculaciones(direccion: any, ciudad: any) {
    const dir = direccion + ', ' + ciudad + ', ';
    return new Promise((succes, reject) => {
      this.mapService
        .getLatLong(dir)
        .toPromise()
        .then(async (resp: any) => {
          if (resp.status == 'OK') {
            if (resp.results[0]) {
              const lat = resp.results[0].geometry.location.lat;
              const lng = resp.results[0].geometry.location.lng;
              let respVinculacion;
              // eslint-disable-next-line prefer-const
              respVinculacion = await this.procesarVinculacionesPunto(lat, lng);
              return succes({ success: true, data: respVinculacion });
            } else {
              return succes({ success: false, data: undefined });
            }
          } else {
            return succes({ success: false, data: undefined });
          }
        })
        .catch((err) => {
          return succes({ success: false, data: err });
        });
    });
  }
  procesarVinculacionesPunto(lat: any, lng: any) {
    return new Promise((succes, reject) => {
      const ArrDis = [];
      const ArrInsert = [];
      const ubicacion_punto_entrega: google.maps.LatLng = new google.maps.LatLng(lat, lng);
      for (const dist_aux of this.listDistribuidoresVIP) {
        console.log('DIstribuidor...', dist_aux);
        if (dist_aux.data_distribuidor && dist_aux.data_distribuidor[0].cobertura_coordenadas.length >= 3) {
          const path = [];
          for (const pt_aux of dist_aux.data_distribuidor[0].cobertura_coordenadas) {
            path.push({
              lat: pt_aux.latitud,
              lng: pt_aux.longitud,
            });
          }
          //Recupera una figura con el area de cobertura
          const area_cobertura = new google.maps.Polygon({ paths: path });
          //Revisa si las coordenadas del punto de entrega está dentro del area de cobertura
          if (google.maps.geometry.poly.containsLocation(ubicacion_punto_entrega, area_cobertura)) {
            dist_aux.flag_vinculacion = true;
            ArrDis.push(dist_aux);
            console.log('dist_aux....', dist_aux);
            //Buscar los trabajadores tipo Administrador que correspondan al usuario distribuidor.
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const id_dist = dist_aux.data_distribuidor[0]._id;
            const id_trab: any[] = [];
            // eslint-disable-next-line prefer-const
            for (let item of dist_aux.data_trabajadores) {
              console.log('Data distribuidor a registrar', item);
              id_trab.push(item._id);
            }
            console.log('lista de administradores...', id_trab);
            ArrInsert.push({
              estado: 'Aprobado',
              convenio: false,
              cartera: false,
              punto_entrega: 'agregar punto de entrega',
              distribuidor: id_dist,
              vendedor: id_trab,
              pazysalvo: true,
            });
          }
        }
      }
      return succes(ArrInsert);
    });
  }
  /**
   * Intenta el registro del nuevo punto de entrega.
   * De ser exitoso, muestra un modal confirmando el registro.
   * Si el servidor arroja un error, se lanza un modal informando del error,
   * o se muestra con un mensaje genérico si se desconoce la naturaleza del
   * error (e.g. cuando ocurre un error 500)
   */
  async registrarPunto(event: Event) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    const target = event.target as HTMLButtonElement;
    /**
     * Si el botón está habilitado dejá registrar el punto
     */
    if (target.disabled != true) {
      /**No permite dar hacia atras mientras se culmina o cancela el post */
      this.boton_atras_deshabilitado = true;
      try {
        const dias_atencion: string[] = [];
        if (this.crearPuntoFormPaso1.value.puntoLunes) {
          dias_atencion.push('Lunes');
        }
        if (this.crearPuntoFormPaso1.value.puntoMartes) {
          dias_atencion.push('Martes');
        }
        if (this.crearPuntoFormPaso1.value.puntoMiercoles) {
          dias_atencion.push('Miércoles');
        }
        if (this.crearPuntoFormPaso1.value.puntoJueves) {
          dias_atencion.push('Jueves');
        }
        if (this.crearPuntoFormPaso1.value.puntoViernes) {
          dias_atencion.push('Viernes');
        }
        if (this.crearPuntoFormPaso1.value.puntoSabado) {
          dias_atencion.push('Sábado');
        }
        if (this.crearPuntoFormPaso1.value.puntoDomingo) {
          dias_atencion.push('Domingo');
        }
        if (this.crearPuntoFormPaso1.value.puntoFestivos) {
          dias_atencion.push('Festivos');
        }

        const info_contacto = [this.e_nombres, this.e_apellidos, this.e_correo, this.e_telefono];

        const nuevo_punto: PuntoEntrega = new PuntoEntrega(
          this.authService.user?.usuario_horeca || '',
          this.nombre,
          this.pais,
          this.departamento,
          this.ciudad,
          this.telefono,
          this.direccion,
          info_contacto,
          this.sillas,
          this.domicilio,
          this.trabajadores.length,
          'Aprobador',
          dias_atencion,
          this.valueToTime(this.minValue) + ' - ' + this.valueToTime(this.maxValue),
          undefined,
          undefined,
          undefined,
          {
            lat: this.map.lat,
            lng: this.map.lng,
          },
          {
            type: 'Point',
            coordinates: [parseFloat(this.map.lng), parseFloat(this.map.lat)],
          }
        );
        /**Asignar trabajadores al punto de venta*/
        this.restService
          .post('punto_entrega', nuevo_punto)
          .toPromise()
          .then((resp: any) => {
            if (resp.success) {
              this.trab_success = [];
              const punto_id: string = resp.data._id;
              let i = 0;
              if (this.trabajadores_asignados.length > 0) {
                for (i = 0; i < this.trabajadores_asignados.length; i++) {
                  const trab_id = this.trabajadores_asignados[i]?._id || '';
                  this.actualizarTrabajador(trab_id, punto_id);
                }
              }
              /**
               * Ya actualizo a los trabajadores, procede a confirmar exito en el registro
               */
              let mensajeExito =
                'Tu punto de venta quedó registrado. ¡Ya puedes empezar a conectar tu negocio con distribuidores en Feat!';
              if (resp.vinculaciones) {
                mensajeExito = `${mensajeExito} Ya tienes distribuidores vinculados en tu punto de entrega.`;
              }
              this.modalCarga?.close();
              const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
              modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
              modalRef.componentInstance.title = '¡Genial!';
              modalRef.componentInstance.msg = mensajeExito;
              modalRef.componentInstance.btn_msg = 'Listo';
              modalRef.componentInstance.close_callback = () => {
                this.activeModal.close();
                window.location.reload();
                if (this.router.url != '/inicio') {
                  this.router.navigate(['/distribuidores']);
                }
              };
            } else {
              this.modalCarga?.close();
              const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
              modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
              modalRef.componentInstance.title = '¡Oh oh!';
              modalRef.componentInstance.msg = resp.message;
              modalRef.componentInstance.btn_msg = 'Listo';
              modalRef.componentInstance.close_callback = () => {
                // this.router.navigate(['/cuenta']);
              };
            }
          })
          .catch(() => {
            this.modalCarga?.close();
            const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
            modalRef.componentInstance.title = '¡Oh oh!';
            modalRef.componentInstance.msg =
              'No fue posible crear el punto de venta por un error inesperado. Por favor, intenta de nuevo más tarde.';
            modalRef.componentInstance.btn_msg = 'Listo';
            modalRef.componentInstance.close_callback = () => {
              this.router.navigate(['/cuenta']);
            };
            this.boton_atras_deshabilitado = false;
          });
      } catch (error) {
        /**
         * Si no se guarda el punto, deja el boton habilitado
         */
        target.disabled = false;
        this.boton_atras_deshabilitado = false;
        this.modalCarga?.close();
      }
    }
    /**
     * Se deshabilita el boton para evitar que guarde el punto mas de una vez
     */
    target.disabled = true;
  }

  /**
   * Permite actualizar la información del trabajador cuyo id entra por parámetro de forma que quede
   * asignado al punto de venta cuyo id también entra por parámetro
   * @param trab_id El id del trabajador a actualizar
   * @param punto_id El id del punto de venta a poner
   */
  actualizarTrabajador(trab_id: string, punto_id: string) {
    let update_aux: string[] = [];
    let obj = {};
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
    };
    this.restService
      .get('trabajador/' + trab_id)
      .toPromise()
      .then((resp: any) => {
        //Se recuperan los puntos de venta donde el trabajador ya ha sido asignado
        if (resp.puntos_entrega) {
          update_aux = resp.puntos_entrega;
        }
        update_aux.push(punto_id);
        obj = {
          puntos_entrega: update_aux,
        };
        console.log(trab_id, obj)
        this.restService
          .putJWT('trabajador/' + trab_id, obj)
          .toPromise()
          .then()
          .catch((err) => {
            const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
            modalRef.componentInstance.title = '¡Oh oh!';
            modalRef.componentInstance.msg =
              'Lo sentimos, se presentaron problemas con el registro de trabajadores a este punto de venta, Sin embargo, el punto de venta en sí fue registrado correctamente.';
            modalRef.componentInstance.btn_msg = 'Listo';
            modalRef.componentInstance.close_callback = () => {
              this.router.navigate(['/cuenta']);
            };
            console.log(err);
          });
      });
  }

  /**
   * Verifica si el formulario es balido y habilita o deshabilita el botón
   */
  checkIfFormIsValid() {
    this.isBotonHabilitado = false;
    this.isFormularioCompleto = false;
    this.validatorDiaSeleccionado = false;
    /**Propietario Natural o Jurídico*/
    if (this.paso_actual == 1) {
      /**Verifica que por lo menos un día de atención haya sido seleccionado*/
      if (
        this.crearPuntoFormPaso1.value.puntoLunes == true ||
        this.crearPuntoFormPaso1.value.puntoMartes == true ||
        this.crearPuntoFormPaso1.value.puntoMiercoles == true ||
        this.crearPuntoFormPaso1.value.puntoJueves == true ||
        this.crearPuntoFormPaso1.value.puntoViernes == true ||
        this.crearPuntoFormPaso1.value.puntoSabado == true ||
        this.crearPuntoFormPaso1.value.puntoDomingo == true ||
        this.crearPuntoFormPaso1.value.puntoFestivos == true
      ) {
        this.validatorDiaSeleccionado = true;
        this.isBotonHabilitado = !this.crearPuntoFormPaso1.invalid;
      }
    }
    /**Trabajador*/
    if (this.paso_actual == 2) {
      this.isFormularioCompleto = !this.crearPuntoFormPaso2.invalid;
    }
  }

  /**
   * No Avanza al siguiente paso y alerta/muestra lo errado
   */
  showAlert() {
    this.showDanger();
    if (this.paso_actual == 1 && this.crearPuntoFormPaso1.invalid == true) {
      this.crearPuntoFormPaso1.markAllAsTouched();
    } else if (this.paso_actual != 1 && this.crearPuntoFormPaso2.invalid == true) {
      this.crearPuntoFormPaso2.markAllAsTouched();
    }
  }

  /**
   * Metodos para mostrar un toast si existe un input invalido en el formulario
   */
  showDanger() {
    this.toastService.show('Tienes campos pendientes por revisar', { classname: 'bg-danger text-light', delay: 10000 });
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateNumber(event: any) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || excludedKeys.includes(keyCode))) {
      event.preventDefault();
    }
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateOnlyText(event: any) {
    const keyCode = event.keyCode;
    if (keyCode >= 48 && keyCode <= 57) {
      event.preventDefault();
    }
  }

  public mapReadyHandler(map: google.maps.Map): void {
    this.mapClickListener = map.addListener('click', (e: google.maps.MouseEvent) => {
      this.zone.run(() => {
        this.map.lat = e.latLng.lat();
        this.map.lng = e.latLng.lng();

        this.buscarDireccionAutocompletada();
      });
    });
  }

  buscarDireccionAutocompletada() {
    this.mapsAPILoader.load().then(() => {
      const geocoder = new google.maps.Geocoder();
      const latlng = { lat: this.map.lat, lng: this.map.lng };
      geocoder.geocode({ location: latlng }, (results) => {
        if (results[0]) {
          this.direccion = results[0].formatted_address;
          this.crearPuntoFormPaso1.get('puntoDireccion')?.setValue(results[0].formatted_address);
        }
      });
    });
  }
}
