import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class NoticiaService {
  constructor(private http: HttpClient, private authService: AuthService) {}
  private headers = new HttpHeaders({ Authorization: this.authService.token });

  //**Servicio para traer noticias */
  public getNoticias(): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/getNoticias`, {
      headers: this.headers,
    });
  }

  //**Servicio para traer una noticia */
  public getNoticia(id: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/noticias/${id}`, {
      headers: this.headers,
    });
  }
}
