<app-header></app-header>
<div class="d-flex justify-content-center align-items-center flex-column">
  <div id="registro-comp" class="d-flex justify-content-start align-items-center flex-column p-5 mb-4">
    <div class="col-12 text-left btn-volver padleft">
      <button routerLink="/login" id="btn-volver">
        <fa-icon [icon]="faChevronLeft" size="lg" [styles]="{'color': 'black'}" id="fa-icon" pull="left"></fa-icon>
        Vuelve al login
      </button>
    </div>
    <div class="col-12 text-center mt-4">
      <h1 class="main-title">
        Únete a Feat.
      </h1>
    </div>
    <br />
    <!--Barra de progreso-->
    <div class="col-12 padleft">
      <ngb-progressbar type="warning" [max]="maxStep" height="1 rem" [value]="step"></ngb-progressbar>
    </div>
    <br />
    <div class="row col-12">
      <div class="col-8 text-left">
        <h5>
          {{instruction}}
        </h5>
      </div>
      <div class="col-4 text-right">
        <h6>
          Paso {{step}}
        </h6>
      </div>
    </div>
    <br />
    <div class="horeca-distribuidor" *ngIf="step == 1">
      <div class="row col-12 mx-0">
        <div class="col-md-6 col-12 mt-2">
          <!--Card HORECA-->
          <div class="card text-center" (click)="selectHoreca()">
            <div class="card-body">
              <img src="../../../assets/img/registro-horeca.png" alt="Registro distribuidor" class="icon-registro" />
              <br><br>
              <h5 class="card-title">Establecimiento Horeca</h5>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12 mt-2">
          <!--Card Distribuidores-->
          <div class="card text-center" (click)="selectDistribuidor()">
            <div class="card-body">
              <img src="../../../assets/img/registro-distribuidor.png" alt="Registro distribuidor"
                class="icon-registro" />
              <br><br>
              <h5 class="card-title">Distribuidor</h5>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
    <div class="container-fluid p-0">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>