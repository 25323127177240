<div id="indicadores" class="p-0 m-0 h-100">
  <div class="notificaciones">
    <div class="card rounded" routerLink="/notificaciones" style="cursor: pointer">
      <p class="main-title">
        <img src="../../../assets/img/icons/icono-24-off.png"
          *ngIf="indicador_notificaciones == 0 || indicador_notificaciones == undefined" alt="icono notificaciones off"
          class="icono-indicadores">
        <img src="../../../assets/img/icons/icono-24.png" *ngIf="indicador_notificaciones > 0"
          alt="icono notificaciones on" class="icono-indicadores">
        <span class="title">{{ indicador_notificaciones || 0 }}</span>
      </p>
      <p class="sub-title">Notificaciones</p>
    </div>
  </div>
  <div class="mensajes">
    <div class="card rounded" routerLink="/pedidos/mensajes/-/inicio" style="cursor: pointer">
      <p class="main-title">
        <img src="../../../assets/img/icons/icono-25-off.png"
          *ngIf="indicador_mensajes == 0 || indicador_mensajes == undefined" alt="icono mensaje off"
          class="icono-indicadores">
        <img src="../../../assets/img/icons/icono-25.png" *ngIf="indicador_mensajes > 0" alt="icono mensaje on"
          class="icono-indicadores">
        <span class="title">{{ indicador_mensajes || 0 }}</span>
      </p>
      <p class="sub-title">Mensajes sin leer</p>
    </div>
  </div>
  <div class="realizados">
    <div class="card rounded" routerLink="/pedidos" style="cursor: pointer">
      <p class="main-title">
        <img src="../../../assets/img/icons/icono-26.png" alt="icono pedidos realziados" class="icono-indicadores">
        <span class="title">{{ indicador_pedidos_realizados || 0}}</span>
      </p>
      <p class="sub-title">Pedidos realizados</p>
    </div>
  </div>
  <div class="sugeridos">
    <div class="card rounded" routerLink="/pedidos" style="cursor: pointer">
      <p class="main-title">
        <img src="../../../assets/img/icons/icono-19.png"
          *ngIf="indicador_pedidos_sugeridos == 0 || indicador_pedidos_sugeridos == undefined"
          alt="icono pedidos sugeridos off" class="icono-indicadores-2">
        <img src="../../../assets/img/icons/icono-19-on.png" *ngIf="indicador_pedidos_sugeridos > 0"
          alt="icono pedidos sugeridos on" class="icono-indicadores-2">
        <span class="title">{{ indicador_pedidos_sugeridos || 0 }}</span>
      </p>
      <p class="sub-title">Pedidos sugeridos</p>
    </div>
  </div>
  <div class="trabajadores">
    <div class="card rounded flex-row p-2" routerLink="/cuenta" style="cursor: pointer">
      <p class="sub-title">
        <fa-icon [icon]="faUser" size="1x" [styles]="{ color: '#41403E' }"></fa-icon>&nbsp; Trabajadores pendientes ({{
        indicador_trabajadores_pendientes || 0 }})
      </p>
    </div>
  </div>
  <div class="distribuidores">
    <div class="card rounded flex-row p-2" routerLink="/solicitudes-distribuidor" style="cursor: pointer">
      <p class="sub-title">
        <fa-icon [icon]="faBuilding" size="1x" [styles]="{ color: '#41403E' }"></fa-icon>&nbsp; Solicitudes a
        distribuidores
        ({{ indicador_distribuidores_pendientes || 0 }})
      </p>
    </div>
  </div>
  <div class="banner rounded">
    <div class="carousel-container">
      <ngb-carousel *ngIf="banners">
        <ng-template ngbSlide *ngFor="let images of banners">
          <div class="picsum-img-wrapper d-flex justify-content-center align-items-center cursorPointer"
            (click)="goToLink(images.link)">
            <img src="{{images.imagen}}" alt="banner de {{images.titulo}}" class="img-banner">
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
  </div>
</div>