/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Chart } from 'chart.js';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { GraficasService } from 'src/app/services/graficas-tablas/graficas.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-graficas-portafolio',
  templateUrl: './graficas-portafolio.component.html',
  styleUrls: ['./graficas-portafolio.component.css'],
})
export class GraficasPortafolioComponent implements OnInit {
  /** ID distribuidor */
  public id_distribuidor = this.authService.user_distribuidor!._id;
  /** Lista de meses para apoyar el formateo de la fecha */
  public filtro_24_meses: any = [];
  private meses: string[] = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  private meses_largo: string[] = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  /** Modal de carga para darle feedback al usuario */
  public modalCarga?: NgbModalRef;
  /** Evita que al hacer click por fuera se cierre el modal */
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };
  /** Biding HTML-Canvas y TS */
  @ViewChild('bar_referencias_categorias', { static: true })
  private bar_referencias_categorias!: ElementRef;
  @ViewChild('bar_referencias_organizacion', { static: true })
  private bar_referencias_organizacion!: ElementRef;
  @ViewChild('bar_ventas_categoria', { static: true })
  private bar_ventas_categoria!: ElementRef;
  @ViewChild('bar_ventas_organizaciones', { static: true })
  private bar_ventas_organizaciones!: ElementRef;
  @ViewChild('bar_top_productos', { static: true })
  private bar_top_productos!: ElementRef;
  @ViewChild('bar_saldos', { static: true })
  private bar_saldos!: ElementRef;
  @ViewChild('bar_promociones', { static: true })
  private bar_promociones!: ElementRef;
  /** Guarda datos de la talba y configuraciones */
  public data_bar_referencias_categorias: any;
  public data_bar_referencias_organizacion: any;
  public data_bar_ventas_categoria: any;
  public data_bar_ventas_organizaciones: any;
  public data_bar_top_productos: any;
  public data_bar_saldos: any;
  public data_bar_promociones: any;
  /** Carga la grafica de chart JS */
  public bar_chart_referencias_categorias: any;
  public bar_chart_referencias_organizacion: any;
  public bar_chart_ventas_categoria: any;
  public bar_chart_ventas_organizaciones: any;
  public bar_chart_top_productos: any;
  public bar_chart_saldos: any;
  public bar_chart_promociones: any;
  /** Total tabla */
  public total_referencias_categorias: any;
  public total_referencias_organizacion: any;
  public total_ventas_categoria: any;
  public total_ventas_organizaciones: any;
  public total_top_productos: any;
  public total_saldos: any;
  public total_promociones: any;
  /** Filtros graficas */
  public filtro_ventas_categoria: any;
  public filtro_ventas_organizaciones: any;
  public filtro_top_productos: any;
  public filtro_saldos: any;
  public filtro_promociones: any;
  public errorSaldos = false;
  public errorPromo = false;
  constructor(
    private restService: RestService,
    private router: Router,
    private ngxsStore: Store,
    private authService: AuthService,
    private modalService: NgbModal,
    private currency: CurrencyPipe,
    private graficasService: GraficasService
  ) {
    this.data_bar_referencias_categorias = {
      labels: [],
      datasets: [],
    };
    this.data_bar_referencias_organizacion = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_categoria = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_organizaciones = {
      labels: [],
      datasets: [],
    };
    this.data_bar_top_productos = {
      labels: [],
      datasets: [],
    };
    this.data_bar_saldos = {
      labels: [],
      datasets: [],
    };
    this.data_bar_promociones = {
      labels: [],
      datasets: [],
    };
  }

  async ngOnInit() {
    this.id_distribuidor = await this.ngxsStore.snapshot().auth.user_distribuidor._id;
    /** Modal generíco cargando... */
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    /** Arma array de filtros de meses y años */
    await this.getListaFiltroMeses();
    /** Carga datos a tabla */
    await this.getChartReferenciasPorCategoria();
    await this.getChartReferenciasPorOrganizacion();
    await this.getChartVentasPorCategoria();
    await this.getChartVentasPorOrganziones();
    await this.getChartTop10Productos();
    await this.getChartSaldos();
    await this.getChartPromociones();
    /** Cerrar Modal generíco cargando... */
    this.modalCarga?.close();
  }

  /**
   * Arma lista de los últimos 24 meses para el filtro de meses
   */
  getListaFiltroMeses() {
    const fecha_actual = new Date();
    let mes_actual = fecha_actual.getMonth();
    let ano_actual = fecha_actual.getFullYear();
    /** Filtro por meses y años */
    for (let index = 0; index < 23; index++) {
      if (mes_actual >= 0) {
        const data = {
          filtro: this.meses[mes_actual] + ', ' + ano_actual,
          filtro_inicio: this.meses[mes_actual - 2] + ', ' + ano_actual,
          crudo: Number(mes_actual + 1) + ',' + ano_actual + ',' + (mes_actual - 1) + ',' + ano_actual,
        };
        if (mes_actual <= 1) {
          data.filtro_inicio = this.meses[11 - (1 - mes_actual)] + ', ' + (ano_actual - 1);
          data.crudo = Number(mes_actual + 1) + ',' + ano_actual + ',' + (11 - mes_actual) + ',' + (ano_actual - 1);
        }
        this.filtro_24_meses.push(data);
        mes_actual = mes_actual - 1;
      } else {
        mes_actual = 11;
        ano_actual = ano_actual - 1;
      }
    }
    this.filtro_ventas_categoria = this.filtro_24_meses[0].crudo;
    this.filtro_ventas_organizaciones = this.filtro_24_meses[0].crudo;
    this.filtro_top_productos = this.filtro_24_meses[0].crudo;
    this.filtro_saldos = this.filtro_24_meses[0].crudo;
    this.filtro_promociones = this.filtro_24_meses[0].crudo;
  }

  /**
   * Inicializa los datos de las gráficas y lo carga en el DOM
   */
  /***************** Referencias por categoria ****************/
  public async getChartReferenciasPorCategoria() {
    let total_referencias_categorias = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/portafolio/barra/ref_X_cat/${this.id_distribuidor}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_referencias_categorias.labels.push(element._id);
          this.data_bar_referencias_categorias.datasets.push(element.total);
          total_referencias_categorias = total_referencias_categorias + element.total;
        });
        this.total_referencias_categorias = total_referencias_categorias;
        /** Se crea la grafica */
        this.graficasService.createBarChart(
          this.bar_referencias_categorias,
          this.data_bar_referencias_categorias,
          'Número de referencias por categorías',
          'Número de referencias',
          'Categorías'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /***************** Referencias por organizacion ****************/
  public async getChartReferenciasPorOrganizacion() {
    let total_referencias_organizacion = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/portafolio/barra/ref_X_org/${this.id_distribuidor}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_referencias_organizacion.labels.push(element._id);
          this.data_bar_referencias_organizacion.datasets.push(element.total);
          total_referencias_organizacion = total_referencias_organizacion + element.total;
        });
        this.total_referencias_organizacion = total_referencias_organizacion;
        /** Se crea la grafica */
        this.graficasService.createBarChart(
          this.bar_referencias_organizacion,
          this.data_bar_referencias_organizacion,
          'Número de referencias por organizaciones',
          'Número de referencias',
          'Organizaciones'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /***************** Ventas por categoria ****************/
  public async getChartVentasPorCategoria() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_categoria) {
      this.bar_chart_ventas_categoria.destroy();
      this.data_bar_ventas_categoria.labels = [];
      this.data_bar_ventas_categoria.datasets = [];
    }
    /** Se arma data para query */
    const first_day =
      this.filtro_ventas_categoria.split(',')[3] + '-' + this.filtro_ventas_categoria.split(',')[2] + '-01';
    const last_day =
      this.filtro_ventas_categoria.split(',')[1] + '-' + this.filtro_ventas_categoria.split(',')[0] + '-31';
    this.total_ventas_categoria = 0;
    let total_ventas_categoria = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/portafolio/barra/ventas_X_cat/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_ventas_categoria.labels.push(element._id);
          this.data_bar_ventas_categoria.datasets.push(element.total);
          total_ventas_categoria = total_ventas_categoria + element.total;
        });
        this.total_ventas_categoria = this.currency.transform(total_ventas_categoria, '$ ', 'symbol', '1.0-0');
        /** Se crea la grafica */
        this.bar_chart_ventas_categoria = this.graficasService.createBarChart(
          this.bar_ventas_categoria,
          this.data_bar_ventas_categoria,
          'Ventas por categoría de portafolio',
          'Ventas',
          'Categorías de producto'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /***************** Ventas por organizaciones ****************/
  public async getChartVentasPorOrganziones() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_organizaciones) {
      this.bar_chart_ventas_organizaciones.destroy();
      this.data_bar_ventas_organizaciones.labels = [];
      this.data_bar_ventas_organizaciones.datasets = [];
    }
    /** Se arma data para query */
    const first_day =
      this.filtro_ventas_organizaciones.split(',')[3] + '-' + this.filtro_ventas_organizaciones.split(',')[2] + '-01';
    const last_day =
      this.filtro_ventas_organizaciones.split(',')[1] + '-' + this.filtro_ventas_organizaciones.split(',')[0] + '-31';
    this.total_ventas_organizaciones = 0;
    let total_ventas_organizaciones = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/portafolio/barra/ventas_X_org/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_ventas_organizaciones.labels.push(element._id);
          this.data_bar_ventas_organizaciones.datasets.push(element.total);
          total_ventas_organizaciones = total_ventas_organizaciones + element.total;
        });
        this.total_ventas_organizaciones = this.currency.transform(
          total_ventas_organizaciones,
          '$ ',
          'symbol',
          '1.0-0'
        );
        /** Se crea la grafica */
        this.bar_chart_ventas_organizaciones = this.graficasService.createBarChart(
          this.bar_ventas_organizaciones,
          this.data_bar_ventas_organizaciones,
          'Ventas por organización',
          'Ventas',
          'Organizaciones'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /***************** Top 10 producots ****************/
  public async getChartTop10Productos() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_top_productos) {
      this.bar_chart_top_productos.destroy();
      this.data_bar_top_productos.labels = [];
      this.data_bar_top_productos.datasets = [];
    }
    /** Se arma data para query */
    const first_day = this.filtro_top_productos.split(',')[3] + '-' + this.filtro_top_productos.split(',')[2] + '-01';
    const last_day = this.filtro_top_productos.split(',')[1] + '-' + this.filtro_top_productos.split(',')[0] + '-31';
    this.total_top_productos = 0;
    let total_top_productos = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(
        `informes/distribuidor/portafolio/barra/top_10_productos/${this.id_distribuidor}/${first_day}/${last_day}`
      )
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_top_productos.labels.push(element._id + ' (' + element.cod_prod + ')');
          this.data_bar_top_productos.datasets.push(element.total);
          total_top_productos = total_top_productos + element.total;
        });
        this.total_top_productos = this.currency.transform(total_top_productos, '$ ', 'symbol', '1.0-0');
        /** Se crea la grafica */
        this.bar_chart_top_productos = this.graficasService.createBarChart(
          this.bar_top_productos,
          this.data_bar_top_productos,
          'Top 10 productos',
          'Ventas',
          'Productos (codigo producto distribuidor)'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /***************** Saldos ****************/
  public async getChartSaldos() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_saldos) {
      this.bar_chart_saldos.destroy();
      this.data_bar_saldos.labels = [];
      this.data_bar_saldos.datasets = [];
    }
    /** Se arma data para query */
    const first_day = this.filtro_saldos.split(',')[3] + '-' + this.filtro_saldos.split(',')[2] + '-01';
    const last_day = this.filtro_saldos.split(',')[1] + '-' + this.filtro_saldos.split(',')[0] + '-31';
    this.total_saldos = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`productoGraficasSaldos/${this.id_distribuidor}`)
      .toPromise()
      .then((data: any) => {
        if (data.length === 0) {
          this.errorSaldos = true;
        }
        // eslint-disable-next-line prefer-const
        let labelsP: any[] = [];
        // eslint-disable-next-line prefer-const
        let unidadesOf: any[] = [];
        // eslint-disable-next-line prefer-const
        let unidadesVendidas: any[] = [];
        data.forEach((producto: any) => {
          labelsP.push(producto.producto);
          unidadesOf.push(producto.unidades_ofrecidas[0]);
          unidadesVendidas.push(producto.unidades_compradas);
        });
        this.bar_chart_saldos = this.graficasService.createBarChartMultiple(
          this.bar_saldos,
          labelsP,
          unidadesVendidas,
          unidadesOf,
          'hola',
          'Cantidad',
          'Productos'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /***************** Promociones ****************/
  public async getChartPromociones() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_promociones) {
      this.bar_chart_promociones.destroy();
      this.data_bar_promociones.labels = [];
      this.data_bar_promociones.datasets = [];
    }
    /** Se arma data para query */
    const first_day = this.filtro_promociones.split(',')[3] + '-' + this.filtro_promociones.split(',')[2] + '-01';
    const last_day = this.filtro_promociones.split(',')[1] + '-' + this.filtro_promociones.split(',')[0] + '-31';
    this.total_promociones = 0;
    /** Query de la data para la grafica */
    //getJWT(`informes/distribuidor/portafolio/barra/promociones/${this.id_distribuidor}/${first_day}/${last_day}`)
    await this.restService
      .getJWT(`productoGraficasPromo/${this.id_distribuidor}`)
      .toPromise()
      .then((data: any) => {
        if (data.length === 0) {
          this.errorPromo = true;
        }
        // eslint-disable-next-line prefer-const
        let labelsP: any[] = [];
        // eslint-disable-next-line prefer-const
        let unidadesOf: any[] = [];
        // eslint-disable-next-line prefer-const
        let unidadesVendidas: any[] = [];
        data.forEach((producto: any) => {
          labelsP.push(producto.producto);
          unidadesOf.push(producto.unidades_ofrecidas[0]);
          unidadesVendidas.push(producto.unidades_compradas);
        });
        this.bar_chart_promociones = this.graficasService.createBarChartMultiple(
          this.bar_promociones,
          labelsP,
          unidadesVendidas,
          unidadesOf,
          'hola',
          'Cantidad',
          'Productos'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio-distribuidor']);
    };
  }
}
