import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faChevronLeft, faEye, faEyeSlash, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-nuevo-trabajador',
  templateUrl: './nuevo-trabajador.component.html',
  styleUrls: ['./nuevo-trabajador.component.scss'],
})
export class NuevoTrabajadorComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal) {}

  /** Variables de control para la navegación del formulario */
  paso_actual = 1;

  /**Icons */
  faTimes = faTimes;
  faChevronLeft = faChevronLeft;
  faEyeSlash = faEyeSlash;
  faEye = faEye;

  /** Flag para mostrar u ocultar contraseña */
  fieldTextType = false;

  ngOnInit(): void {}

  close() {
    this.activeModal.close();
  }

  /**
   * Avanza al siguiente paso del formulario
   */
  siguiente() {
    this.paso_actual++;
    if (this.paso_actual > 2) {
      this.paso_actual = 2;
    }
  }

  /**
   * Retrocede al paso anterior del formulario
   */
  anterior() {
    this.paso_actual--;
    if (this.paso_actual < 1) {
      this.paso_actual = 1;
    }
  }
  /**
   * Oculta o revela la contraseña al usuario
   */
  mostrarOcultarPassword() {
    this.fieldTextType = !this.fieldTextType;
  }
}
