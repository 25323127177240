<section infiniteScroll (scrolled)="onScrollDown()" *ngIf="publicaciones_scroll_infinito.length > 0">
  <!-- Cards de publicaciones -->
  <div class="card-noticias mx-0 my-3"
    *ngFor="let item of (mis_publicaciones_child ? publicaciones_scroll_infinito_propias : publicaciones_scroll_infinito )">
    <div class="container" *ngIf="item">
      <!-- Header: Logo autor, nombres, nombre empresa y fecha -->
      <div class="header-publicaciones">
        <div class="titulo-publicacion d-flex align-items-center">
          <img *ngIf="!item.dataPublicacion.autorAdmin"
            src="{{ getDataAutor(item, 'logo_empresa') || distribuidor_placeholder }}" alt="logo"
            class="border border-light rounded-circle" />
          <img *ngIf="item.dataPublicacion.autorAdmin" src="{{ feat_placeholder || distribuidor_placeholder }}"
            alt="logo" class="border border-light rounded-circle" />
          <div class="d-flex flex-column pl-3">
            <p class="m-0 font-weight-bold">
              {{getDataAutor(item, 'nombre_empresa')}}
              <span *ngIf="item.dataPublicacion.autorAdmin">Feat</span>
            </p>
            <p *ngIf="!item.dataPublicacion.autorAdmin" class="text-secondary m-0" style="font-size: 14px;">
                <!--{{item.dataAutor.nombre[0]}} {{item.dataAutor.apellido[0]}}-->
              <span *ngIf="item.dataEmpresaAutorDistribuidor.tipo[0]">
                {{item.dataEmpresaAutorDistribuidor.tipo[0]}}
              </span>
              <span  *ngIf="item.dataEmpresaAutorOrganizacion.tipo[0]">
                {{item.dataEmpresaAutorOrganizacion.tipo[0]}}
              </span>
              <span  *ngIf="item.dataEmpresaAutor.tipo[0]">
                {{item.dataEmpresaAutor.tipo[0]}}
              </span>
            </p>
            <p *ngIf="item.dataPublicacion.autorAdmin" class="text-secondary m-0" style="font-size: 14px;">
              {{item.dataPublicacion.nombresAdmin}}
            </p>
            <p class="text-secondary m-0" style="font-size: 14px;">{{ item.dataPublicacion.titulo }}</p>
          </div>
        </div>
        <p *ngIf="!mis_publicaciones_child">
          Hace {{ item.dataPublicacion.fechaCreacion | dateDiff }}
        </p>
        <p *ngIf="mis_publicaciones_child" (click)="eliminarPublicacion(item)" class="eliminar">
          Eliminar
        </p>
      </div>
      <!-- Cotenido: video e imagenes -->
      <div class="body-publicaciones">
        <div class="imagen-publicacion">
          <div class="w-100" *ngIf="item.dataPublicacion.imagen[0]?.includes('mp4'); else mostrarImagenes">
            <app-video [options]="{
              autoplay: false,
              fluid: false,
              width: 500,
              preload: 'metadata',
              sources: [{ src: item.dataPublicacion.imagen[0], type: 'video/mp4' }]
            }">
            </app-video>
          </div>
          <ng-template #mostrarImagenes>
            <ngb-carousel class="w-100">
              <ng-template ngbSlide *ngFor="let img of item.dataPublicacion.imagen" class="w-100">
                <img src={{img||publicacion_placeholder}} class="img-publication rounded"
                  alt="Imagen cargada para esta publicación" />
              </ng-template>
            </ngb-carousel>
          </ng-template>
        </div>
        <div class="d-flex flex-row mt-4" *ngIf="item.dataPublicacion.id">
          <!-- Icono para likes -->
          <div class="d-flex flex-row likes">
            <div class="d-flex" *ngIf="!findLikesByPublications(item.dataPublicacion.id, 'liked_by_user')">
              <button (click)="postLike(item.dataPublicacion.id, $event)" class="boton-like">
                <fa-icon [icon]="faHeart" id="fa-icon"></fa-icon>
              </button>
              <p class="ml-2">
                {{ findLikesByPublications(item.dataPublicacion.id, 'total_likes') || 0}}
              </p>
            </div>
            <div class="d-flex" *ngIf="findLikesByPublications(item.dataPublicacion.id, 'liked_by_user')">
              <a (click)="deleteLike(findLikesByPublications(item.dataPublicacion.id, 'get_id'))">
                <fa-icon [icon]="faSHeart" id="fa-icon" [styles]="{ color: 'red' }"></fa-icon>
              </a>
              <p class="ml-2">
                {{ findLikesByPublications(item.dataPublicacion.id, 'total_likes') || 0}}
              </p>
            </div>
          </div>
          <!-- Icono para comentarios -->
          <div class="d-flex flex-row ml-4 comentario" (click)="item.showFeed = !item.showFeed">
            <fa-icon [icon]="faComment" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
            <p class="ml-2">
              {{item.dataComentario?.length || '0'}}
            </p>
          </div>
        </div>
        <div class="contenido-publicacion">
          <p [class.showMainComment]="item.showMainComment" class="mb-0">
            {{ item.dataPublicacion.contenido }}
          </p>
        </div>
        <h6 (click)="item.showMainComment = !item.showMainComment" class="text-right leer-mas py-2 mb-3"
          *ngIf="item.dataPublicacion.contenido.length > 150">
          {{ item.showMainComment ? 'Leer menos...': 'Leer mas...' }}
        </h6>
        <!-- Input para comentar -->
        <div class="mt-2 comentario-container" [class.showFeed]="item.showFeed">
          <form [formGroup]="form" method="POST" (ngSubmit)="postComment(item._id)" class="mb-3"
            (ngSubmit)="comentarioPublicacion.value = ''">
            <div class="d-flex flex-row justify-content-between my-3">
              <div class="logo-container pl-3">
                <img *ngIf="tipo_usuario=='usuario_horeca'" src={{userInfo.user_horeca.logo}}
                  alt="logo del usuario conectado" class="border border-light rounded-circle" />
                <img *ngIf="tipo_usuario=='distribuidor'" src={{userInfo.user_distribuidor.logo}}
                  alt="logo del usuario conectado" class="border border-light rounded-circle" />
                <img *ngIf="tipo_usuario=='organizacion'" src={{userInfo.user_organizacion.logo}}
                  alt="logo del usuario conectado" class="border border-light rounded-circle" />
              </div>
              <div class="w-100 p-0 input-comment-fake d-flex align-items-center overflow-hidden">
                <textarea #comentarioPublicacion class="input" role="textbox" contenteditable type="text"
                  class="input-comment p-3 w-100" placeholder="Tu comentario..." formControlName="contenido" rows="1">
              </textarea>
                <button class="button-comment  m-0 p-0 text-left" type="submit"
                  (click)="comentarioPublicacion.value = ''">
                  <fa-icon [icon]="faPaperPlane"></fa-icon>
                </button>
              </div>
            </div>
          </form>
          <!-- Comentarios -->
          <div *ngFor="let key of item.dataComentario" class="comment mb-2">
            <div class="d-flex flex-row m-0">
              <div class="logo-comentarios-container  mr-2">
                <img src="{{ key.logo_autor || distribuidor_placeholder }}" alt="logo"
                  class="logo-comentarios rounded-circle" />
              </div>
              <div class="comentarios-publicaciones w-100 p-3">
                <div class="titulo-publicacion d-flex justify-content-between mb-2">
                  <p *ngIf="!key.autorAdmin" class="m-0">
                    {{ key.dataComentarioUser[0].nombres }} {{ key.dataComentarioUser[0].apellidos }}
                    <span class="text-secondary">
                      <span *ngIf="key.dataEmpresaUserComentario[0]"> y
                        ({{ key.dataEmpresaUserComentario[0]?.nombre_establecimiento }})
                      </span>
                      <span *ngIf="!key.dataEmpresaUserComentario[0] && key.dataEmpresaUserComentarioDistribuidor[0]">
                        ({{ key.dataEmpresaUserComentarioDistribuidor[0]?.nombre }})
                      </span>
                    </span>
                  </p>
                  <p *ngIf="key.autorAdmin" class="m-0">
                    {{ key.nombresAdmin }}
                  </p>
                  <p class="m-0">
                    Hace {{ key.createdAt | dateDiff }}
                  </p>
                </div>
                <div class="comentarios-externos overflow-hidden" [class.showComment]="key.showComment">
                  {{ key.contenido }}
                </div>
                <h6 (click)="key.showComment= !key.showComment" class="text-right leer-mas-comentarios m-0 p-0 pt-2"
                  *ngIf="key.contenido > 100">
                  {{ key.showComment? 'Leer menos...': 'Leer mas...' }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Estado Vacio -->
  <div class="container" *ngIf="mis_publicaciones_child && publicaciones_scroll_infinito_propias.length === 0">
    <section class="d-flex flex-column justify-conent-center align-items-center">
      <img src="../../assets/img/icon-warning-amarillo.png" class="col-lg-4 col-sm-6 col-10"
        alt="Icono de advertencia" />
      <h5 class="my-2">Nada de nada</h5>
      <span class="text-center">No has realizado aún publicaciones, crea ahora mismo tu primera publicación</span>
    </section>
  </div>
  <!-- Botón flotante para subir a la primera publicación -->
  <button *ngIf="show_button" (click)="onScrollTop()" class="btn-float">
    <fa-icon [icon]="faChevronUp" id="fa-icon"></fa-icon>
  </button>
</section>
<!-- Spinner -->
<div *ngIf="show_spinner" class="spinner d-flex flex-column align-items-center">
  <p>
    Cargando mas publicaciones...
  </p>
  <mat-spinner [diameter]="40"></mat-spinner>
</div>