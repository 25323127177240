<app-header-horeca></app-header-horeca>
<div class="main-container container-fluid">
  <div class="container-nav">
    <div class="title-nav">
      <button class="button-volver" routerLink="/distribuidores">
        <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
        Volver
      </button>
      <h2 class="main-title text-left">Solicitudes a distribuidores</h2>
    </div>
  </div>
  <div class="content-cards mx-5"
    *ngIf="(distVinculadosPendientes.length + distVinculadosAprobados.length + distVinculadosRechazados.length) > 0; else mostrarError">
    <!-- Dist Pendientes -->
    <div class="col-12 w-100 border-bot text-left p-0 mt-2">
      <p class="m-0">Distribuidores Pendientes ({{ distVinculadosPendientes.length }})</p>
    </div>
    <div class="d-flex flex-row justify-content-evenly align-items-center card-container overflow-hidden pl-3">
      <div class="d-flex row justify-content-start flex-nowrap my-1">
        <div *ngFor="let item of distVinculadosPendientes"
          [routerLink]="['/solicitudes-distribuidor/novinculado', item?._id]">
          <div class="card p-3 m-2" (click)="verDetalleDistribuidor(item)">
            <div class="d-flex justify-content-between align-items-center pb-2 border-bot">
              <div class="d-flex align-items-center justify-content-start">
                <fa-icon [icon]="faCircle" size="lg" [styles]="{ color: '#E8E8E8' }"></fa-icon>
                <p class="estado m-0 p-1">Pendiente</p>
              </div>
              <div class="col d-flex justify-content-center" *ngIf="item.convenio === true">
                <button class="btn-card">$ Convenio</button>
              </div>
            </div>
            <div class="my-1 overflow-hidden">
              <div class="d-flex justify-content-between m-2 align-items-center">
                <div class="place">
                  <img src="../../../assets/img/icons/icon-pin-map.png"
                    alt="Icono de pin para señalar un sitio en un mapa" class="my-2" />
                  {{ item.ciudad }}
                </div>
                <div>
                  <fa-icon [icon]="faStar" size="lg" [styles]="{ color: '#41403E' }"></fa-icon>
                  {{ item.ranking }}
                </div>
              </div>
              <div class="logo-distribuidor">
                <img src="{{ item.logo || distribuidor_placeholder }}" alt="" />
              </div>
              <div>
                <p class="name-distribuitor my-3">{{ item.nombre }}</p>
              </div>
              <div class="d-flex justify-content-between my-2 align-items-center">
                <span class="especification-distribuitor">
                  {{ item.tipo }}
                </span>
                <div>
                  <img class="icon-bus" src="../../../assets/img/icons/icon-bus.png" alt="icono de un bus" />
                  {{ item.tiempo_entrega }}
                </div>
              </div>
              <div class="mx-0 mb-9">
                <p class="m-0 descripcion-distribuidor">
                  {{ item.descripcion }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Dist Aprobados -->
    <div class="col-12 w-100 border-bot text-left p-0 mt-2">
      <p class="m-0">Distribuidores Aprobados ({{ distVinculadosAprobados.length }})</p>
    </div>
    <div class="d-flex flex-row justify-content-evenly align-items-center card-container overflow-hidden pl-3">
      <div class="d-flex row justify-content-start flex-nowrap my-1">
        <div *ngFor="let item of distVinculadosAprobados" [routerLink]="['/solicitudes-distribuidor', item?._id]">
          <div class="card p-3 m-2" (click)="verDetalleDistribuidor(item)">
            <div class="d-flex justify-content-between align-items-center pb-2 border-bot">
              <div class="d-flex align-items-center justify-content-start">
                <fa-icon [icon]="faCircle" size="lg" [styles]="{ color: '#C7D389' }"></fa-icon>
                <p class="estado m-0 p-1">Aprobado</p>
              </div>
              <div class="col d-flex justify-content-center" *ngIf="item.convenio === true">
                <button class="btn-card">$ Convenio</button>
              </div>
            </div>
            <div class="my-1 overflow-hidden">
              <div class="d-flex justify-content-between m-2 align-items-center">
                <div class="place">
                  <img src="../../../assets/img/icons/icon-pin-map.png"
                    alt="Icono de pin para señalar un sitio en un mapa" class="my-2" />
                  {{ item.ciudad }}
                </div>
                <div>
                  <fa-icon [icon]="faStar" size="lg" [styles]="{ color: '#41403E' }"></fa-icon>
                  {{ item.ranking }}
                </div>
              </div>
              <div class="logo-distribuidor">
                <img src="{{ item.logo || distribuidor_placeholder }}" alt="" />
              </div>
              <div>
                <p class="name-distribuitor my-3">{{ item.nombre }}</p>
              </div>
              <div class="d-flex justify-content-between my-2 align-items-center">
                <span class="especification-distribuitor">
                  {{ item.tipo }}
                </span>
                <div>
                  <img class="icon-bus" src="../../../assets/img/icons/icon-bus.png" alt="icono de un bus" />
                  {{ item.tiempo_entrega }}
                </div>
              </div>
              <div class="mx-0 mb-9">
                <p class="m-0 descripcion-distribuidor">
                  {{ item.descripcion }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Dist Rechazados -->
    <div class="col-12 w-100 border-bot text-left p-0 mt-2">
      <p class="m-0">Distribuidores Rechazados ({{ distVinculadosRechazados.length }})</p>
    </div>
    <div class="d-flex flex-row justify-content-evenly align-items-center card-container overflow-hidden pl-3">
      <div class="d-flex row justify-content-start flex-nowrap my-1">
        <div *ngFor="let item of distVinculadosRechazados">
          <div class="card p-3 m-2">
            <div class="d-flex justify-content-between align-items-center pb-2 border-bot">
              <div class="d-flex align-items-center justify-content-start">
                <fa-icon [icon]="faCircle" size="lg" [styles]="{ color: '#D38989' }"></fa-icon>
                <p class="estado m-0 p-1">Rechazado</p>
              </div>
              <div class="col d-flex justify-content-center" *ngIf="item.convenio === true">
                <button class="btn-card">$ Convenio</button>
              </div>
            </div>
            <div class="my-1 overflow-hidden">
              <div class="d-flex justify-content-between m-2 align-items-center">
                <div class="place">
                  <img src="../../../assets/img/icons/icon-pin-map.png"
                    alt="Icono de pin para señalar un sitio en un mapa" class="my-2" />
                  {{ item.ciudad }}
                </div>
                <div>
                  <fa-icon [icon]="faStar" size="lg" [styles]="{ color: '#41403E' }"></fa-icon>
                  {{ item.ranking }}
                </div>
              </div>
              <div class="logo-distribuidor">
                <img src="{{ item.logo || distribuidor_placeholder }}" alt="" />
              </div>
              <div>
                <p class="name-distribuitor my-3">{{ item.nombre }}</p>
              </div>
              <div class="d-flex justify-content-between my-2 align-items-center">
                <span class="especification-distribuitor">
                  {{ item.tipo }}
                </span>
                <div>
                  <img class="icon-bus" src="../../../assets/img/icons/icon-bus.png" alt="icono de un bus" />
                  {{ item.tiempo_entrega }}
                </div>
              </div>
              <div>
                <button (click)="volverSolicitud(item)" class="btn-solicitud">Volver a solicitar vinculación</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #mostrarError>
    <div class="row">
      <div class="error-message text-center">
        <div class="text-center">
          <img src="../../../assets/img/icon-warning-amarillo.png"
            alt="Icono de una banana para indicar  advertencia" />
          <p class="text-center">Parece que este punto de entrega no tiene solicitudes aún.</p>
        </div>
      </div>
    </div>
  </ng-template>
</div>