import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { LocalDataSource } from 'ng2-smart-table';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-informes-general-organizaciones',
  templateUrl: './informes-general-organizaciones.component.html',
  styleUrls: ['./informes-general-organizaciones.component.css'],
})
export class InformesGeneralOrganizacionesComponent implements OnInit {
  // ID organizacion
  public id_organizacion = this.auth.user_organizacion._id;
  // Datos y configuración de la tabla
  public settings_portafolio: any = {};
  public settings_distribuidores: any = {};
  public settings_ventas: any = {};
  public settings_usuarios: any = {};
  public settings_puntos_feat: any = {};
  public settings_puntos_feat2: any = {};
  public settings_establecimientos: any = {};
  public data_portafolio: any = [];
  public data_distribuidores: any = [];
  public data_ventas: any = [];
  public data_usuarios: any = [];
  public data_puntos_feat: any = [];
  public data_establecimientos: any = [];
  public source_distribuidores?: LocalDataSource;
  public source_portafolio?: LocalDataSource;
  public source_ventas?: LocalDataSource;
  public source_puntos_feat?: LocalDataSource;
  public source_usuarios?: LocalDataSource;
  public source_establecimientos?: LocalDataSource;
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };

  constructor(
    private rest: RestService,
    private auth: AuthService,
    private currency: CurrencyPipe,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private ngxsStore: Store,
    private router: Router
  ) {}

  async ngOnInit() {
    this.id_organizacion = await this.ngxsStore.snapshot().auth.user_organizacion._id;
    /** Modal generíco cargando... */
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    /** Configuración tabla */
    this.configSmartTablePortafolio();
    this.configSmartTableDistribuidores();
    this.configSmartTableVentas();
    this.configSmartTableUsuarios();
    this.configSmartTablePuntosFeat();
    this.configSmartTableEstablecimientos();
    /** Carga datos a tabla */
    await this.cargarDatosTablaPortafolio();
    await this.cargarDatosTablaDistribuidores();
    await this.cargarDatosTablaVentas();
    await this.cargarDatosTablaUsuarios();
    await this.cargarDatosTablaPuntosFeat();
    await this.cargarDatosTablaEstablecimientos();
    /** Cerrar Modal generíco cargando... */
    this.modalCarga?.close();
  }

  /**
   * Descargue a excel un listado
   */
  DescargaExcel(obj: any) {
    /** Genera el worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(obj.data);
    /* Genera el workbook y agrega el  worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');
    /** Guarda el archivo */
    XLSX.writeFile(wb, obj.nombreArchivo);
  }

  /******************************************** Portafolio *******************************************/
  /**
   * Configura los titulos y demas settings de la tabla
   * para recibir información de la base de datos
   */
  private configSmartTablePortafolio(): void {
    this.settings_portafolio = {
      pager: {
        display: true,
        perPage: 10,
      },
      actions: {
        columnTitle: 'Acción',
        add: false,
        edit: false,
        delete: false,
      },
      hideSubHeader: false,
      columns: {
        nombre: {
          title: 'Nombre Producto ',
          editable: false,
          filter: true,
        },
        cod_feat: {
          title: 'Código Feat',
          editable: false,
          filter: true,
        },
        cod_org: {
          title: 'Código Organización',
          editable: false,
          filter: true,
        },
        cat: {
          title: 'Categoría',
          editable: false,
          filter: true,
        },
        linea_prod: {
          title: 'Línea de productos',
          editable: false,
          filter: true,
        },
        marca: {
          title: 'Marca',
          editable: false,
          filter: true,
        },
        cantidad: {
          title: 'Tamaño/cantidad',
          editable: false,
          filter: true,
        },
        und_medida: {
          title: 'Unidad de medida',
          editable: false,
          filter: true,
        },
        precio: {
          title: 'Precio promedio público (COP)',
          editable: false,
          filter: true,
        },
        puntos: {
          title: 'Puntos asignados Feat (Organización x unidad)',
          editable: false,
          filter: true,
        },
        ventas: {
          title: 'Venta últimos 3 meses en distribuidores (COP)',
          editable: false,
          filter: true,
        },
        establecimientos: {
          title: 'No. de establecimientos alcanzados',
          editable: false,
          filter: true,
        },
        distribuidores: {
          title: 'No. de distribuidores que distribuyen',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  private cargarDatosTablaPortafolio(): void {
    this.rest
      .getJWT('portafolio_organizacion/' + this.id_organizacion)
      .toPromise()
      .then((resp: any) => {
        resp.forEach((producto: any) => {
          const obj_pedido = {
            nombre: producto.nombre,
            cod_feat: producto.codigoFeat,
            cod_org: producto.codigo_organizacion_producto,
            cat: producto.categoria,
            linea_prod: producto.linea,
            marca: producto.marca,
            descripcion: producto.descripcion,
            cantidad: producto.cantMedida,
            und_medida: producto.unidadMedida,
            precio: producto.precio_promedio != undefined ? producto.precio_promedio : 0,
            puntos: producto.puntos_ft_unidad,
            distribuidores: producto.total_distribuidores,
            ventas: producto.ventas_tres_meses != undefined ? producto.ventas_tres_meses : 0,
            establecimientos: producto.estab_alcanzados,
          };
          this.data_portafolio.push(obj_pedido);
        });
        this.source_portafolio = new LocalDataSource(this.data_portafolio);
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }

  /**
   * Descargue a excel listado de Portafolio
   */
  descargaArchivoPortafolio() {
    const primeraFila: any[] = [
      'Nombre',
      'Código Feat',
      'Código organización',
      'Categoría',
      'Línea de productos',
      'Marca',
      'Tamaño/cantidad',
      'Unidad de medida',
      'Precio promedio público (COP)',
      'Puntos asignados Feat (Organización x unidad)',
      'Venta (COP) últimos 3 meses en distribuidores',
      'No. de establecimientos alcanzados',
      'No. de distribuidores que distribuyen',
    ];
    const object_keys = [
      'nombre',
      'cod_feat',
      'cod_org',
      'cat',
      'linea_prod',
      'marca',
      'cantidad',
      'und_medida',
      'precio',
      'puntos',
      'ventas',
      'establecimientos',
      'distribuidores',
    ];
    /** Arma el contenido de la tabla con titulos*/
    const arreglo_final: any[] = [];
    arreglo_final.push(primeraFila);
    this.data_portafolio.forEach((element: any) => {
      const array = [];
      for (const column of object_keys) {
        if (
          column == 'precio' ||
          column == 'ventas' ||
          column == 'puntos' ||
          column == 'establecimientos' ||
          column == 'distribuidores'
        ) {
          element[column] = parseFloat(element[column]);
        }
        array.push(element[column]);
      }
      arreglo_final.push(array);
    });
    this.DescargaExcel({ data: arreglo_final, nombreArchivo: `Portafolio.xlsx` });
  }

  /**************************************** Distribuidores ***************************************/
  /**
   * Configura los titulos y demas settings de la tabla
   * para recibir información de la base de datos
   */
  private configSmartTableDistribuidores(): void {
    this.settings_distribuidores = {
      pager: {
        display: true,
        perPage: 10,
      },
      actions: {
        columnTitle: 'Acción',
        add: false,
        edit: false,
        delete: false,
      },
      hideSubHeader: false,
      columns: {
        distribuidor: {
          title: 'Distribuidor',
          editable: false,
          filter: true,
        },
        razon_social: {
          title: 'Razón social / nombre',
          editable: false,
          filter: true,
        },
        tipo_persona: {
          title: 'Tipo de persona',
          editable: false,
          filter: true,
        },
        tipo_documento: {
          title: 'Tipo de documento',
          editable: false,
          filter: true,
        },
        numero_documento: {
          title: 'Número de documento',
          editable: false,
          filter: true,
        },
        tipo_distribuidor: {
          title: 'Tipo de distribuidor',
          editable: false,
          filter: true,
        },
        pais: {
          title: 'País',
          editable: false,
          filter: true,
        },
        departamento: {
          title: 'Departamento',
          editable: false,
          filter: true,
        },
        ciudad: {
          title: 'Ciudad',
          editable: false,
          filter: true,
        },
        direccion: {
          title: 'Dirección',
          editable: false,
          filter: true,
        },
        correo: {
          title: 'Correo',
          editable: false,
          filter: true,
        },
        telefono: {
          title: 'Teléfono fijo',
          editable: false,
          filter: true,
        },
        rep_legal: {
          title: 'Nombre representante legal o propietario',
          editable: false,
          filter: true,
        },
        tipo_documento_rep: {
          title: 'Tipo de documento (rep./prop.)',
          editable: false,
          filter: true,
        },
        documento_rep: {
          title: 'Número de documento (rep./prop.)',
          editable: false,
          filter: true,
        },
        telefono_rep: {
          title: 'Teléfono (rep./prop.)',
          editable: false,
          filter: true,
        },
        tiempo_entrega: {
          title: 'Tiempo de entrega',
          editable: false,
          filter: true,
        },
        horario_atencion: {
          title: 'Horario de atención',
          editable: false,
          filter: true,
        },
        valor_min_pedido: {
          title: 'Valor mínimo de pedido (COP)',
          editable: false,
          filter: true,
        },
        medio_pago: {
          title: 'Medios de pago',
          editable: false,
          filter: true,
        },
        calificacion_abastecimiento: {
          title: 'Calificación (Abastecimiento)',
          editable: false,
          filter: true,
        },
        calificacion_servicio: {
          title: 'Calificación (Precio)',
          editable: false,
          filter: true,
        },
        calificacion_puntualidad: {
          title: 'Calificación (Puntualidad entrega)',
          editable: false,
          filter: true,
        },
        totalidadVentas: {
          title: 'Ventas últimos 3 meses (COP)',
          editable: false,
          filter: true,
        },
        ventas_bar_discoteca: {
          title: 'Ventas bar discoteca (COP)',
          editable: false,
          filter: true,
        },
        ventas_cafeteria: {
          title: 'Ventas cafeteria (COP)',
          editable: false,
          filter: true,
        },
        ventas_catering: {
          title: 'Ventas catering (COP)',
          editable: false,
          filter: true,
        },
        ventas_cocina_oculta: {
          title: 'Ventas cocina oculta (COP)',
          editable: false,
          filter: true,
        },
        ventas_diversion: {
          title: 'Ventas diversion (COP)',
          editable: false,
          filter: true,
        },
        ventas_centro_deportivo: {
          title: 'Ventas centro deportivo (COP)',
          editable: false,
          filter: true,
        },
        ventas_club_social: {
          title: 'Ventas club social (COP)',
          editable: false,
          filter: true,
        },
        ventas_comedor_empleados: {
          title: 'Ventas comedor empleados (COP)',
          editable: false,
          filter: true,
        },
        ventas_comida_rapida: {
          title: 'Ventas comida rapida (COP)',
          editable: false,
          filter: true,
        },
        ventas_hogar: {
          title: 'Ventas hogar (COP)',
          editable: false,
          filter: true,
        },
        ventas_may_min: {
          title: 'Ventas mayorista / minorista (COP)',
          editable: false,
          filter: true,
        },
        ventas_oficina: {
          title: 'Ventas oficina (COP)',
          editable: false,
          filter: true,
        },
        ventas_panaderia: {
          title: 'Ventas panaderia (COP)',
          editable: false,
          filter: true,
        },
        ventas_propiedad__horizontal: {
          title: 'Ventas propiedad horizontal (COP)',
          editable: false,
          filter: true,
        },
        ventas_restaurante: {
          title: 'Ventas restaurante (COP)',
          editable: false,
          filter: true,
        },
        ventas_restaurante_cadena: {
          title: 'Ventas restaurante cadena (COP)',
          editable: false,
          filter: true,
        },
        ventas_sector_educacion: {
          title: 'Ventas sector educación (COP)',
          editable: false,
          filter: true,
        },
        ventas_sector_hotelero: {
          title: 'Ventas sector hotelero (COP)',
          editable: false,
          filter: true,
        },
        ventas_sector_salud: {
          title: 'Ventas sector salud (COP)',
          editable: false,
          filter: true,
        },
        ventas_sector_publico_privado: {
          title: 'Ventas sector publico / privado (COP)',
          editable: false,
          filter: true,
        },
        productos_portafolio: {
          title: 'No. de productos en el portafolio',
          editable: false,
          filter: true,
        },
        numero_vendedores: {
          title: 'No. de vendedores ',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  private cargarDatosTablaDistribuidores(): void {
    this.rest
      .getJWT('distribuidores_organizacion/' + this.id_organizacion)
      .toPromise()
      .then((distribuidores: any) => {
        console.log('distribuidores', distribuidores);
        distribuidores.forEach((distribuidor: any) => {
          // eslint-disable-next-line prefer-const
          let costoProdTotal = 0;

          if (distribuidor.data_ventas_3_meses) {
            costoProdTotal = distribuidor.data_ventas_3_meses.costoTotalProducto;
          }

          const obj_pedido_distribuidores = {
            distribuidor: distribuidor.nombre,
            razon_social: distribuidor.razon_social,
            tipo_persona: distribuidor.tipo_persona,
            tipo_documento: distribuidor.nit_cc === 'Juridica' ? 'NIT' : 'CC/CE/Pasaporte',
            numero_documento: distribuidor.nit_cc,
            tipo_distribuidor: distribuidor.tipo,
            pais: distribuidor.pais,
            departamento: distribuidor.departamento,
            ciudad: distribuidor.ciudad,
            direccion: distribuidor.direccion,
            correo: distribuidor.correo,
            telefono: distribuidor.celular
              ? distribuidor.celular
              : '' + '  ' + distribuidor.telefono
              ? distribuidor.telefono
              : '',
            rep_legal: distribuidor.rep_legal_nombres
              ? distribuidor.rep_legal_nombres
              : ' ' + '  ' + distribuidor.rep_legal_apellidos
              ? distribuidor.rep_legal_apellidos
              : ' ',
            tipo_documento_rep: distribuidor.rep_legal_tipo_documento ? distribuidor.rep_legal_tipo_documento : '',
            documento_rep: distribuidor.rep_legal_numero_documento ? distribuidor.rep_legal_numero_documento : '',
            telefono_rep: distribuidor.rep_legal_telefono
              ? distribuidor.rep_legal_telefono
              : '' + '  ' + distribuidor.rep_legal_celular
              ? distribuidor.rep_legal_celular
              : '' + '  ',
            descripcion: distribuidor.descripcion,
            tiempo_entrega: distribuidor.tiempo_entrega,
            horario_atencion: distribuidor.horario_atencion,
            valor_min_pedido: distribuidor.valor_minimo_pedido != undefined ? distribuidor.valor_minimo_pedido : 0,
            medio_pago: distribuidor.metodo_pago,
            calificacion_abastecimiento: 0,
            calificacion_puntualidad: 0,
            calificacion_servicio: 0,
            ventas_tres_meses: costoProdTotal,
            ventas_bar_discoteca:
            distribuidor.data_ventas_tipo_punto.ventas_bar_disco != undefined
              ? parseFloat(distribuidor.data_ventas_tipo_punto.ventas_bar_disco)
              : 0,
              ventas_cafeteria:
              distribuidor.data_ventas_tipo_punto.ventas_cafeteria != undefined
                ? parseFloat(distribuidor.data_ventas_tipo_punto.ventas_cafeteria)
                : 0,
                ventas_catering:
                distribuidor.data_ventas_tipo_punto.ventas_catering != undefined
                  ? parseFloat(distribuidor.data_ventas_tipo_punto.ventas_catering)
                  : 0,
                  ventas_cocina_oculta:
                  distribuidor.data_ventas_tipo_punto.ventas_cocina_oculta != undefined
                    ? parseFloat(distribuidor.data_ventas_tipo_punto.ventas_cocina_oculta)
                    : 0,
                    ventas_diversion:
                    distribuidor.data_ventas_tipo_punto.ventas_diversion != undefined
                      ? parseFloat(distribuidor.data_ventas_tipo_punto.ventas_diversion)
                      : 0,
                      ventas_centro_deportivo_gymnasios:
              distribuidor.data_ventas_tipo_punto.ventas_centro_deportivo_gymnasios != undefined
                ? parseFloat(distribuidor.data_ventas_tipo_punto.ventas_centro_deportivo_gymnasios)
                : 0,
                ventas_club_social_negocios:
              distribuidor.data_ventas_tipo_punto.ventas_club_social_negocios != undefined
                ? parseFloat(distribuidor.data_ventas_tipo_punto.ventas_club_social_negocios)
                : 0,
                ventas_comedores:
                distribuidor.data_ventas_tipo_punto.ventas_comedores != undefined
                  ? parseFloat(distribuidor.data_ventas_tipo_punto.ventas_comedores)
                  : 0,
                  ventas_comida_rapida:
                  distribuidor.data_ventas_tipo_punto.ventas_comida_rapida != undefined
                    ? parseFloat(distribuidor.data_ventas_tipo_punto.ventas_comida_rapida)
                    : 0,

                    ventas_hogar:
                    distribuidor.data_ventas_tipo_punto.ventas_hogar != undefined
                      ? parseFloat(distribuidor.data_ventas_tipo_punto.ventas_hogar)
                      : 0,
                      ventas_mayoristas:
                      distribuidor.data_ventas_tipo_punto.ventas_mayoristas != undefined
                        ? parseFloat(distribuidor.data_ventas_tipo_punto.ventas_mayoristas)
                        : 0,
                        ventas_oficina_coworkig:
                        distribuidor.data_ventas_tipo_punto.ventas_oficina_coworkig != undefined
                          ? parseFloat(distribuidor.data_ventas_tipo_punto.ventas_oficina_coworkig)
                          : 0,
                          ventas_panaderia:
                          distribuidor.data_ventas_tipo_punto.ventas_panaderia != undefined
                            ? parseFloat(distribuidor.data_ventas_tipo_punto.ventas_panaderia)
                            : 0,
                            ventas_propiedad__horizontal:
                            distribuidor.data_ventas_tipo_punto.ventas_propiedad__horizontal != undefined
                              ? parseFloat(distribuidor.data_ventas_tipo_punto.ventas_propiedad__horizontal)
                              : 0,
                              ventas_restaurante:
                              distribuidor.data_ventas_tipo_punto.ventas_restaurante != undefined
                                ? parseFloat(distribuidor.data_ventas_tipo_punto.ventas_restaurante)
                                : 0,
                            ventas_cadena:
                              distribuidor.data_ventas_tipo_punto.ventas_cadena != undefined
                                ? parseFloat(distribuidor.data_ventas_tipo_punto.ventas_cadena)
                                : 0,
                                ventas_colegios:
                                distribuidor.data_ventas_tipo_punto.ventas_colegios != undefined
                                  ? parseFloat(distribuidor.data_ventas_tipo_punto.ventas_colegios)
                                  : 0,
                                  ventas_hoteles:
              distribuidor.data_ventas_tipo_punto.ventas_hoteles != undefined
                ? parseFloat(distribuidor.data_ventas_tipo_punto.ventas_hoteles)
                : 0,
                ventas_salud:
              distribuidor.data_ventas_tipo_punto.ventas_salud != undefined
                ? parseFloat(distribuidor.data_ventas_tipo_punto.ventas_salud)
                : 0,
                ventas_publico_privado:
              distribuidor.data_ventas_tipo_punto.ventas_publico_privado != undefined
                ? parseFloat(distribuidor.data_ventas_tipo_punto.ventas_publico_privado)
                : 0,


            ventas_establecimientos: distribuidor.data_estab_alcanzados,
            productos_portafolio: distribuidor.total_productos,
            numero_vendedores: distribuidor.total_trabajadores.length,
            totalidadVentas: 0,
          };
          /** Se arma data de calificación */
          const calificacion_abastecimiento: any[] = [];
          const calificacion_puntualidad: any[] = [];
          const calificacion_servicio: any[] = [];
          distribuidor.data_calificacion.forEach((calificacion: any) => {
            calificacion_abastecimiento.push(Number(calificacion.calificacion.abastecimiento));
            calificacion_puntualidad.push(Number(calificacion.calificacion.precio));
            calificacion_servicio.push(Number(calificacion.calificacion.puntualidad_entrega));
          });
          obj_pedido_distribuidores.calificacion_abastecimiento =
            Math.round(
              (calificacion_abastecimiento.reduce((a, b) => a + b, 0) / calificacion_abastecimiento.length || 0) * 100
            ) / 100;
          obj_pedido_distribuidores.calificacion_puntualidad =
            Math.round(
              (calificacion_puntualidad.reduce((a, b) => a + b, 0) / calificacion_puntualidad.length || 0) * 100
            ) / 100;
          obj_pedido_distribuidores.calificacion_servicio =
            Math.round((calificacion_servicio.reduce((a, b) => a + b, 0) / calificacion_servicio.length || 0) * 100) /
            100;
          obj_pedido_distribuidores.totalidadVentas =
            obj_pedido_distribuidores.ventas_bar_discoteca +
            obj_pedido_distribuidores.ventas_cafeteria +
            obj_pedido_distribuidores.ventas_catering +
            obj_pedido_distribuidores.ventas_cocina_oculta +
            obj_pedido_distribuidores.ventas_diversion +
            obj_pedido_distribuidores.ventas_centro_deportivo_gymnasios +
            obj_pedido_distribuidores.ventas_club_social_negocios +
            obj_pedido_distribuidores.ventas_comedores +
            obj_pedido_distribuidores.ventas_comida_rapida +
            obj_pedido_distribuidores.ventas_hogar +
            obj_pedido_distribuidores.ventas_mayoristas +
            obj_pedido_distribuidores.ventas_oficina_coworkig +
            obj_pedido_distribuidores.ventas_panaderia +
            obj_pedido_distribuidores.ventas_propiedad__horizontal +
            obj_pedido_distribuidores.ventas_restaurante +
            obj_pedido_distribuidores.ventas_cadena +
            obj_pedido_distribuidores.ventas_colegios +
            obj_pedido_distribuidores.ventas_hoteles +
            obj_pedido_distribuidores.ventas_salud +
            obj_pedido_distribuidores.ventas_publico_privado;
          /** Se arega objeto-fila a la tabla */
          this.data_distribuidores.push(obj_pedido_distribuidores);
        });
        this.source_distribuidores = new LocalDataSource(this.data_distribuidores);
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }

  /**
   * Descargue a excel listado de Distribuidores
   */
  descargaArchivoDistribuidores() {
    const primeraFila: any[] = [
      'Distribuidor',
      'Razón social / nombre',
      'Tipo de persona',
      'Tipo de documento',
      'Número de documento',
      'Tipo de distribuidor',
      'País',
      'Departamento',
      'Ciudad',
      'Dirección',
      'Correo',
      'Teléfono fijo',
      'Nombre representante legal o propietario',
      'Tipo de documento (rep./prop.)',
      'Número de documento (rep./prop.)',
      'Teléfono (rep./prop.)',
      'Tiempo de entrega',
      'Horario de atención',
      'Valor mínimo de pedido (COP)',
      'Medios de pago',
      'Calificación (Abastecimiento)',
      'Calificación (Puntualidad entrega)',
      'Calificación (Servicio)',
      'Ventas últimos 3 meses (COP)',



      'Ventas bar discoteca (COP)',
      'Ventas cafeteria (COP)',
      'Ventas catering (COP)',
      'Ventas cocina oculta (COP)',
      'Ventas diversion (COP)',
      'Ventas centro deportivo (COP)',
      'Ventas club social (COP)',
      'Ventas comedor empleados (COP)',
      'Ventas comida rapida (COP)',
      'Ventas hogar (COP)',
      'Ventas mayorista / minorista (COP)',
      'Ventas oficina (COP)',
      'Ventas panaderia (COP)',
      'Ventas propiedad horizontal (COP)',
      'Ventas restaurante (COP)',
      'Ventas restaurante cadena (COP)',
      'Ventas sector educación (COP)',
      'Ventas sector hotelero (COP)',
      'Ventas sector salud (COP)',
      'Ventas sector publico / privado (COP)',



      'Establecimientos alcanzados',
      'No. de productos en el portafolio',
      'No. de vendedores ',
    ];
    const object_keys = [
      'distribuidor',
      'razon_social',
      'tipo_persona',
      'tipo_documento',
      'numero_documento',
      'tipo_distribuidor',
      'pais',
      'departamento',
      'ciudad',
      'direccion',
      'correo',
      'telefono',
      'rep_legal',
      'tipo_documento_rep',
      'documento_rep',
      'telefono_rep',
      'tiempo_entrega',
      'horario_atencion',
      'valor_min_pedido',
      'medio_pago',
      'calificacion_abastecimiento',
      'calificacion_puntualidad',
      'calificacion_servicio',
      'ventas_tres_meses',
      'ventas_bar_discoteca',
      'ventas_cafeteria',
      'ventas_catering',
      'ventas_cocina_oculta',
      'ventas_diversion',
      'ventas_centro_deportivo_gymnasios',
      'ventas_club_social_negocios',
      'ventas_comedores',
      'ventas_comida_rapida',
      'ventas_hogar',
      'ventas_mayoristas',
      'ventas_oficina_coworkig',
      'ventas_panaderia',
      'ventas_propiedad__horizontal',
      'ventas_restaurante',
      'ventas_cadena',
      'ventas_colegios',
      'ventas_hoteles',
      'ventas_salud',
      'ventas_publico_privado',
      'ventas_establecimientos',
      'productos_portafolio',
      'numero_vendedores',
    ];
    /** Arma el contenido de la tabla con titulos*/
    const arreglo_final: any[] = [];
    arreglo_final.push(primeraFila);
    this.data_distribuidores.forEach((element: any) => {
      const array = [];
      for (const column of object_keys) {
        array.push(element[column]);
      }
      arreglo_final.push(array);
    });
    this.DescargaExcel({ data: arreglo_final, nombreArchivo: `Distribuidores.xlsx` });
  }

  /********************************************** Ventas *********************************************/
  /**
   * Configura los titulos y demas settings de la tabla
   * para recibir información de la base de datos
   */
  private configSmartTableVentas(): void {
    this.settings_ventas = {
      pager: {
        display: true,
        perPage: 10,
      },
      actions: {
        columnTitle: 'Acción',
        add: false,
        edit: false,
        delete: false,
      },
      hideSubHeader: false,
      columns: {
        distribuidor: {
          title: 'Distribuidor',
          editable: false,
          filter: true,
        },
        distribuidor_nit: {
          title: 'Distribuidor NIT/CC/CE',
          editable: false,
          filter: true,
        },
        horeca_nombre: {
          title: 'Horeca Nombre',
          editable: false,
          filter: true,
        },
        horeca_tipo_persona: {
          title: 'Horeca Tipo Persona',
          editable: false,
          filter: true,
        },
        horeca_nit: {
          title: 'Horeca NIT/CC/CE',
          editable: false,
          filter: true,
        },
        horeca_tipo_negocio: {
          title: 'Horeca Tipo de negocio',
          editable: false,
          filter: true,
        },
        punto_pais: {
          title: 'Punto País',
          editable: false,
          filter: true,
        },
        punto_departamento: {
          title: 'Punto Departamento',
          editable: false,
          filter: true,
        },
        punto_ciudad: {
          title: 'Punto Ciudad',
          editable: false,
          filter: true,
        },
        punto_nombre: {
          title: 'Punto de Entrega Nombre',
          editable: false,
          filter: true,
        },
        pedido_fecha: {
          title: 'Pedido Fecha',
          editable: false,
          filter: true,
        },
        pedido_id: {
          title: 'Pedido ID',
          editable: false,
          filter: true,
        },
        producto_categoria: {
          title: 'Producto Categoría',
          editable: false,
          filter: true,
        },
        producto_linea: {
          title: 'Producto Línea',
          editable: false,
          filter: true,
        },
        producto_marca: {
          title: 'Producto Marca',
          editable: false,
          filter: true,
        },
        producto_cod_organizacion: {
          title: 'Código de organización',
          editable: false,
          filter: true,
        },
        producto_cod_distribuidor: {
          title: 'Producto Código de Distribuidor',
          editable: false,
          filter: true,
        },
        producto_nombre: {
          title: 'Nombre del Producto',
          editable: false,
          filter: true,
        },
        producto_cantidad: {
          title: 'Producto Cantidad/Tamaño',
          editable: false,
          filter: true,
        },
        producto_unidad_medida: {
          title: 'Producto Unidad Medida',
          editable: false,
          filter: true,
        },
        venta_unidades: {
          title: 'Unidades compradas',
          editable: false,
          filter: true,
        },
        venta_cajas: {
          title: 'Cajas compradas',
          editable: false,
          filter: true,
        },
        venta_valor: {
          title: 'Valor total Producto (COP)',
          editable: false,
          filter: true,
        },
        venta_puntos: {
          title: 'Puntos Acumulados Compra Producto',
          editable: false,
          filter: true,
        },
        vendedor: {
          title: 'Vendedor Distribuidor Asignado',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  private cargarDatosTablaVentas(): void {
    this.rest
      .getJWT('reporte_detallado_ventas/' + this.id_organizacion)
      .toPromise()
      .then((ventas: any) => {
        console.log('ventas', ventas.data)
        ventas.data = ventas.data.reverse();
        ventas.data.forEach((venta: any) => {
          let estadoPedido = '';
          if (venta.estado_pedido) {
            estadoPedido = venta.estado_pedido[0];
          }
          let vendedor_nombre = '';
          venta.data_vinculacion.forEach((vendedor: any) => {
            if (vendedor.distribuidor === venta.distribuidor_id) {
              return (vendedor_nombre = vendedor.trabajador_asignado);
            }
          });
          const obj_pedido_ventas = {
            distribuidor: venta.distribuidor_nombre,
            distribuidor_nit: venta.distribuidor_nit_cc,
            horeca_nombre: venta.horeca_nombre_establecimiento,
            horeca_tipo_persona: venta.horeca_tipo_usuario,
            horeca_nit: venta.horeca_nit,
            horeca_tipo_negocio: venta.horeca_tipo_negocio,
            punto_pais: venta.punto_pais,
            punto_departamento: venta.punto_departamento,
            punto_ciudad: venta.punto_ciudad,
            punto_nombre: venta.punto_nombre,
            pedido_fecha: this.datePipe.transform(venta.pedido_fecha, 'yyyy-MM-dd'),
            pedido_id: venta.pedido_id,
            producto_categoria: venta.categoria_producto,
            producto_linea: venta.linea_producto,
            producto_marca: venta.marca_producto,
            producto_cod_organizacion: venta.codigo_organizacion,
            producto_cod_distribuidor: venta.producto_codigo_distribuidor,
            producto_descripcion: venta.producto_descripcion,
            producto_nombre: venta.nombre_producto,
            producto_cantidad: venta.producto_cantidad_medida,
            producto_unidad_medida: venta.producto_unidad_medida,
            venta_unidades: venta.venta_unidades,
            venta_cajas:
              venta.producto_und_x_caja === 0
                ? 0
                : Math.round((venta.venta_unidades / venta.producto_und_x_caja) * 100) / 100,
            venta_valor: venta.venta_costo != undefined ? venta.venta_costo : 0,
            venta_puntos: venta.venta_puntos_FT,
            vendedor: vendedor_nombre,
          };
          /** Se arega objeto-fila a la tabla si el pedido ya tiene estado entregado*/
          this.data_ventas.push(obj_pedido_ventas);
        });
        this.source_ventas = new LocalDataSource(this.data_ventas);
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }

  /**
   * Descargue a excel listado de Ventas
   */
  descargaArchivoVentas() {
    const primeraFila: any[] = [
      'Distribuidor',
      'Distribuidor NIT/CC/CE',
      'Horeca Nombre',
      'Horeca Tipo Persona',
      'Horeca NIT/CC/CE',
      'Horeca Tipo de negocio',
      'Punto País',
      'Punto Departamento',
      'Punto Ciudad',
      'Punto de Entrega Nombre',
      'Pedido Fecha',
      'Pedido ID',
      'Producto Categoría',
      'Producto Línea',
      'Producto Marca',
      'Código de organización',
      'Producto Código de Distribuidor',
      'Producto Nombre',
      'Producto Cantidad/Tamaño',
      'Producto Unidad Medida',
      'Unidades compradas',
      'Cajas compradas',
      'Valor total Producto (COP)',
      'Puntos Acumulados Compra Producto',
      'Vendedor Distribuidor Asignado',
    ];
    const object_keys = [
      'distribuidor',
      'distribuidor_nit',
      'horeca_nombre',
      'horeca_tipo_persona',
      'horeca_nit',
      'horeca_tipo_negocio',
      'punto_pais',
      'punto_departamento',
      'punto_ciudad',
      'punto_nombre',
      'pedido_fecha',
      'pedido_id',
      'producto_categoria',
      'producto_linea',
      'producto_marca',
      'producto_cod_organizacion',
      'producto_cod_distribuidor',
      'producto_nombre',
      'producto_cantidad',
      'producto_unidad_medida',
      'venta_unidades',
      'venta_cajas',
      'venta_valor',
      'venta_puntos',
      'vendedor',
    ];
    /** Arma el contenido de la tabla con titulos*/
    const arreglo_final: any[] = [];
    arreglo_final.push(primeraFila);
    this.data_ventas.forEach((element: any) => {
      const array = [];
      for (const column of object_keys) {
        if (
          column == 'venta_valor' ||
          column == 'venta_unidades' ||
          column == 'venta_cajas' ||
          column == 'venta_puntos'
        ) {
          //element[column] = element[column].replace('$', '').replace('.', '');
          element[column] = parseFloat(element[column]);
        }
        array.push(element[column]);
      }
      arreglo_final.push(array);
    });
    this.DescargaExcel({ data: arreglo_final, nombreArchivo: `Ventas.xlsx` });
  }

  /********************************************** Usuarios *********************************************/
  /**
   * Configura los titulos y demas settings de la tabla
   * para recibir información de la base de datos
   */
  private configSmartTableUsuarios(): void {
    this.settings_usuarios = {
      pager: {
        display: true,
        perPage: 10,
      },
      actions: {
        columnTitle: 'Acción',
        add: false,
        edit: false,
        delete: false,
      },
      hideSubHeader: false,
      columns: {
        tipo_usuario: {
          title: 'Tipo de usuario',
          editable: false,
          filter: true,
        },
        estado: {
          title: 'Estado',
          editable: false,
          filter: true,
        },
        nombre: {
          title: 'Nombre(s)',
          editable: false,
          filter: true,
        },
        apellidos: {
          title: 'Apellidos',
          editable: false,
          filter: true,
        },
        tipo_documento: {
          title: 'Tipo de documento',
          editable: false,
          filter: true,
        },
        numero_documento: {
          title: 'Número de documento',
          editable: false,
          filter: true,
        },
        correo: {
          title: 'Correo',
          editable: false,
          filter: true,
        },
        telefono: {
          title: 'Teléfono',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  private cargarDatosTablaUsuarios(): void {
    this.rest
      .getJWT('all_organizacion_trabajador/' + this.id_organizacion)
      .toPromise()
      .then((trabajadores: any) => {
        trabajadores.forEach((trabajador: any) => {
          const obj_pedido_usuarios = {
            tipo_usuario: trabajador.tipo_trabajador,
            estado: trabajador.solicitud_vinculacion,
            nombre: trabajador.nombres,
            apellidos: trabajador.apellidos,
            tipo_documento: trabajador.tipo_documento,
            numero_documento: trabajador.numero_documento,
            correo: trabajador.correo,
            telefono: trabajador.celular,
            usuario: trabajador._id,
          };
          /** Se arega objeto-fila a la tabla */
          this.data_usuarios.push(obj_pedido_usuarios);
        });
        this.source_usuarios = new LocalDataSource(this.data_usuarios);
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }

  /**
   * Descargue a excel listado de Usuarios
   */
  descargaArchivoUsuarios() {
    const primeraFila: any[] = [
      'Tipo de usuario',
      'Estado',
      'Nombre(s)',
      'Apellidos',
      'Tipo de documento',
      'Número de documento',
      'Correo',
      'Telefono',
    ];
    const object_keys = [
      'tipo_usuario',
      'estado',
      'nombre',
      'apellidos',
      'tipo_documento',
      'numero_documento',
      'correo',
      'telefono',
    ];
    /** Arma el contenido de la tabla con titulos*/
    const arreglo_final: any[] = [];
    arreglo_final.push(primeraFila);
    this.data_usuarios.forEach((element: any) => {
      const array = [];
      for (const column of object_keys) {
        array.push(element[column]);
      }
      arreglo_final.push(array);
    });
    this.DescargaExcel({ data: arreglo_final, nombreArchivo: `Usuarios.xlsx` });
  }

  /********************************************** Puntos Feat *********************************************/
  /**
   * Configura los titulos y demas settings de la tabla
   * para recibir información de la base de datos
   */
  private configSmartTablePuntosFeat(): void {
    this.settings_puntos_feat = {
      pager: {
        display: true,
        perPage: 10,
      },
      actions: {
        columnTitle: 'Acción',
        add: false,
        edit: false,
        delete: false,
      },
      hideSubHeader: false,
      columns: {
        acumulacion: {
          title: 'Mes de acumulación',
          editable: false,
          filter: true,
        },
        cod_organizacion: {
          title: 'Código de organización',
          editable: false,
          filter: true,
        },
        categoria: {
          title: 'Categoría',
          editable: false,
          filter: true,
        },
        linea: {
          title: 'Línea de productos',
          editable: false,
          filter: true,
        },
        marca: {
          title: 'Marca',
          editable: false,
          filter: true,
        },
        nombre_producto: {
          title: 'Nombre del producto',
          editable: false,
          filter: true,
        },
        puntos_acumulados: {
          title: 'No. de puntos acumulados',
          editable: false,
          filter: true,
        },
        puntos_asingados: {
          title: 'Puntos actuales asignados',
          editable: false,
          filter: true,
        },
        fecha_inicio: {
          title: 'Fecha de inicio',
          editable: false,
          filter: true,
        },
        fecha_cierre: {
          title: 'Fecha de cierre',
          editable: false,
          filter: true,
        },
      },
    };
    this.settings_puntos_feat2 = {
      pager: {
        display: true,
        perPage: 10,
      },
      actions: {
        columnTitle: 'Acción',
        add: false,
        edit: false,
        delete: false,
      },
      hideSubHeader: false,
      columns: {
        distribuidor: {
          title: 'Distribuidor',
          editable: false,
          filter: true,
        },
        distribuidor_nit: {
          title: 'Distribuidor NIT/CC/CE',
          editable: false,
          filter: true,
        },
        horeca_nombre: {
          title: 'Horeca Nombre',
          editable: false,
          filter: true,
        },
        horeca_tipo_persona: {
          title: 'Horeca Tipo Persona',
          editable: false,
          filter: true,
        },
        horeca_nit: {
          title: 'Horeca NIT/CC/CE',
          editable: false,
          filter: true,
        },
        horeca_tipo_negocio: {
          title: 'Horeca Tipo de negocio',
          editable: false,
          filter: true,
        },
        punto_pais: {
          title: 'Punto País',
          editable: false,
          filter: true,
        },
        punto_departamento: {
          title: 'Punto Departamento',
          editable: false,
          filter: true,
        },
        punto_ciudad: {
          title: 'Punto Ciudad',
          editable: false,
          filter: true,
        },
        punto_nombre: {
          title: 'Punto de Entrega Nombre',
          editable: false,
          filter: true,
        },
        pedido_fecha: {
          title: 'Pedido Fecha',
          editable: false,
          filter: true,
        },
        pedido_id: {
          title: 'Pedido ID',
          editable: false,
          filter: true,
        },
        producto_categoria: {
          title: 'Producto Categoría',
          editable: false,
          filter: true,
        },
        producto_linea: {
          title: 'Producto Línea',
          editable: false,
          filter: true,
        },
        producto_marca: {
          title: 'Producto Marca',
          editable: false,
          filter: true,
        },
        producto_cod_organizacion: {
          title: 'Código de organización',
          editable: false,
          filter: true,
        },
        producto_cod_distribuidor: {
          title: 'Producto Código de Distribuidor',
          editable: false,
          filter: true,
        },
        producto_nombre: {
          title: 'Nombre del Producto',
          editable: false,
          filter: true,
        },
        producto_cantidad: {
          title: 'Producto Cantidad/Tamaño',
          editable: false,
          filter: true,
        },
        producto_unidad_medida: {
          title: 'Producto Unidad Medida',
          editable: false,
          filter: true,
        },
        venta_unidades: {
          title: 'Unidades compradas',
          editable: false,
          filter: true,
        },
        venta_cajas: {
          title: 'Cajas compradas',
          editable: false,
          filter: true,
        },
        venta_valor: {
          title: 'Valor total Producto (COP)',
          editable: false,
          filter: true,
        },
        venta_puntos: {
          title: 'Puntos Acumulados Compra Producto',
          editable: false,
          filter: true,
        },
        vendedor: {
          title: 'Vendedor Distribuidor Asignado',
          editable: false,
          filter: true,
        },
        puntos_ganados: {
          title: 'Puntos otorgados',
          editable: false,
          filter: true,
        },
        fecha_inicio: {
          title: 'Fecha puntos incio',
          editable: false,
          filter: true,
        },
        fecha_fin: {
          title: 'Fecha puntos fin',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  private cargarDatosTablaPuntosFeat(): void {
    this.rest
      .getJWT('reporte_detallado_ventas_pf_org/' + this.id_organizacion)
      .toPromise()
      .then((ventas: any) => {
        console.log('ventas', ventas.data)
        ventas.data = ventas.data.reverse();
        ventas.data.forEach((venta: any) => {
          let estadoPedido = '';
          if (venta.estado_pedido) {
            estadoPedido = venta.estado_pedido[0];
          }
          let vendedor_nombre = '';
          venta.data_vinculacion.forEach((vendedor: any) => {
            if (vendedor.distribuidor === venta.distribuidor_id) {
              return (vendedor_nombre = vendedor.trabajador_asignado);
            }
          });
          const obj_pedido_ventas = {
            distribuidor: venta.distribuidor_nombre,
            distribuidor_nit: venta.distribuidor_nit_cc,
            horeca_nombre: venta.horeca_nombre_establecimiento,
            horeca_tipo_persona: venta.horeca_tipo_usuario,
            horeca_nit: venta.horeca_nit,
            horeca_tipo_negocio: venta.horeca_tipo_negocio,
            punto_pais: venta.punto_pais,
            punto_departamento: venta.punto_departamento,
            punto_ciudad: venta.punto_ciudad,
            punto_nombre: venta.punto_nombre,
            pedido_fecha: this.datePipe.transform(venta.pedido_fecha, 'yyyy-MM-dd'),
            pedido_id: venta.pedido_id,
            producto_categoria: venta.categoria_producto,
            producto_linea: venta.linea_producto,
            producto_marca: venta.marca_producto,
            producto_cod_organizacion: venta.codigo_organizacion,
            producto_cod_distribuidor: venta.producto_codigo_distribuidor,
            producto_descripcion: venta.producto_descripcion,
            producto_nombre: venta.nombre_producto,
            producto_cantidad: venta.producto_cantidad_medida,
            producto_unidad_medida: venta.producto_unidad_medida,
            venta_unidades: venta.venta_unidades,
            venta_cajas:
              venta.producto_und_x_caja === 0
                ? 0
                : Math.round((venta.venta_unidades / venta.producto_und_x_caja) * 100) / 100,
            venta_valor: venta.venta_costo != undefined ? venta.venta_costo : 0,
            venta_puntos: venta.venta_puntos_FT,
            vendedor: vendedor_nombre,
            puntos_ganados: venta.puntosGanados,
            fecha_inicio:moment(venta.fechaInicio).format('YYYY-MM-DD'),
            fecha_fin: moment(venta.fechaFin).format('YYYY-MM-DD'),

          };
          /** Se arega objeto-fila a la tabla si el pedido ya tiene estado entregado*/
          this.data_puntos_feat.push(obj_pedido_ventas);
        });
       /* productos.data.forEach((producto: any) => {
          if (producto.producto_puntos_actual > 0) {
            let current_date: any = new Date(producto._id.producto_fecha_pedido);
            current_date = new Date(current_date.setMonth(current_date.getMonth() + 1));
            const obj_pedido_puntos_feat = {
              acumulacion: producto._id.producto_fecha_pedido,
              cod_organizacion: producto.producto_codigo_organizacion,
              categoria: producto.categoria,
              linea: producto.linea,
              marca: producto.marca,
              descripcion: producto.producto_descripcion,
              nombre_producto: producto.producto_nombre,
              puntos_acumulados: 0,
              puntos_asingados: producto.producto_puntos_actual,
              fecha_inicio: this.datePipe.transform(producto.producto_inicio_puntos_actual, 'yyyy-MM-dd'),
              fecha_cierre: this.datePipe.transform(producto.producto_cierre_puntos_actual, 'yyyy-MM-dd'),
            };
            // Si está dentro del rango de fecha, los puntos aplican
            if (
              obj_pedido_puntos_feat.fecha_inicio &&
              obj_pedido_puntos_feat.fecha_cierre &&
              new Date(moment.utc(obj_pedido_puntos_feat.fecha_inicio).format('DD MMMM YYYY')) <= current_date &&
              new Date(moment.utc(obj_pedido_puntos_feat.fecha_cierre).format('DD MMMM YYYY')) >= current_date
            ) {
              obj_pedido_puntos_feat.puntos_acumulados = producto.unidades_que_acumulan * producto.producto_puntos_mes;
            }
            this.data_puntos_feat.push(obj_pedido_puntos_feat);
          }
        });*/
        this.source_puntos_feat = new LocalDataSource(this.data_puntos_feat);
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }

  /**
   * Descargue a excel listado de PuntosFT
   */
  descargaArchivoPuntosFeat() {
   
    const primeraFila: any[] = [
      'Distribuidor',
      'Distribuidor NIT/CC/CE',
      'Horeca Nombre',
      'Horeca Tipo Persona',
      'Horeca NIT/CC/CE',
      'Horeca Tipo de negocio',
      'Punto País',
      'Punto Departamento',
      'Punto Ciudad',
      'Punto de Entrega Nombre',
      'Pedido Fecha',
      'Pedido ID',
      'Producto Categoría',
      'Producto Línea',
      'Producto Marca',
      'Código de organización',
      'Producto Código de Distribuidor',
      'Nombre del Producto',
      'Producto Cantidad/Tamaño',
      'Producto Unidad Medida',
      'Unidades compradas',
      'Cajas compradas',
      'Valor total Producto (COP)',
      'Puntos Acumulados Compra Producto',
      'Vendedor Distribuidor Asignado',
      'Puntos otorgados',
      'Fecha puntos incio',
      'Fecha puntos fin',
    ];
    const object_keys = [
      'distribuidor',
      'distribuidor_nit',
      'horeca_nombre',
      'horeca_tipo_persona',
      'horeca_nit',
      'horeca_tipo_negocio',
      'punto_pais',
      'punto_departamento',
      'punto_ciudad',
      'punto_nombre',
      'pedido_fecha',
      'pedido_id',
      'producto_categoria',
      'producto_linea',
      'producto_marca',
      'producto_cod_organizacion',
      'producto_cod_distribuidor',
      'producto_nombre',
      'producto_cantidad',
      'producto_unidad_medida',
      'venta_unidades',
      'venta_cajas',
      'venta_valor',
      'venta_puntos',
      'vendedor',
      'puntos_ganados',
      'fecha_inicio',
      'fecha_fin',
    ];
    /** Arma el contenido de la tabla con titulos*/
    const arreglo_final: any[] = [];
    arreglo_final.push(primeraFila);
    this.data_puntos_feat.forEach((element: any) => {
      const array = [];
      for (const column of object_keys) {
        array.push(element[column]);
      }
      arreglo_final.push(array);
    });
    this.DescargaExcel({ data: arreglo_final, nombreArchivo: `Puntos Feat.xlsx` });
  }

  /********************************************** Establecimientos *********************************************/
  /**
   * Configura los titulos y demas settings de la tabla
   * para recibir información de la base de datos
   */
  private configSmartTableEstablecimientos(): void {
    this.settings_establecimientos = {
      pager: {
        display: true,
        perPage: 10,
      },
      actions: {
        columnTitle: 'Acción',
        add: false,
        edit: false,
        delete: false,
      },
      hideSubHeader: false,
      columns: {
        horeca_tipo_negocio: {
          title: 'Horeca Tipo de negocio',
          editable: false,
          filter: true,
        },
        horeca_tipo_usuario: {
          title: 'Horeca Tipo de Persona',
          editable: false,
          filter: true,
        },
        horeca_nit: {
          title: 'Horeca NIT/CC/CE',
          editable: false,
          filter: true,
        },
        horeca_razon_social: {
          title: 'Horeca Razón Social / Nombre',
          editable: false,
          filter: true,
        },
        horeca_nombre: {
          title: 'Horeca Nombre Comercial',
          editable: false,
          filter: true,
        },
        punto_pais: {
          title: 'Punto País',
          editable: false,
          filter: true,
        },
        punto_departamento: {
          title: 'Punto Departamento',
          editable: false,
          filter: true,
        },
        punto_ciudad: {
          title: 'Punto Ciudad',
          editable: false,
          filter: true,
        },
        punto_nombre: {
          title: 'Punto Nombre',
          editable: false,
          filter: true,
        },
        punto_sillas: {
          title: 'Punto Sillas',
          editable: false,
          filter: true,
        },
        punto_domicilios: {
          title: 'Punto Domicilios',
          editable: false,
          filter: true,
        },
        data_ventas_tres_meses: {
          title: 'Valor Pedidos (COP) Últimos 3 Meses ',
          editable: false,
          filter: true,
        },
        data_referencias_tres_meses: {
          title: '# de Referencias Últimos 3 Meses',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  private cargarDatosTablaEstablecimientos(): void {
    this.rest
      .getJWT('detalle_punto_establecimiento/' + this.id_organizacion)
      .toPromise()
      .then((puntos: any) => {
        console.log('puntos', puntos)

        puntos.data.forEach((punto: any) => {
          const amountFile = punto.data_ventas_tres_meses != undefined ? punto.data_ventas_tres_meses : 0;
          const puntoDomicilio = punto.punto_domicilios == true ? 'Si' : 'No';
          const obj_pedido_establecimientos = {
            horeca_tipo_negocio: punto.horeca_tipo_negocio,
            horeca_tipo_usuario: punto.horeca_tipo_usuario,
            horeca_nit: punto.horeca_nit,
            horeca_razon_social: punto.horeca_razon_social,
            horeca_nombre: punto.horeca_nombre,
            punto_pais: punto.punto_pais,
            punto_departamento: punto.punto_departamento,
            punto_ciudad: punto.punto_ciudad,
            punto_nombre: punto.punto_nombre,
            punto_sillas: punto.punto_sillas,
            punto_domicilios: puntoDomicilio,
            data_ventas_tres_meses: parseInt(amountFile),
            
            data_referencias_tres_meses: punto.data_referencias_tres_meses,
          };
          this.data_establecimientos.push(obj_pedido_establecimientos);
        });
        this.source_establecimientos = new LocalDataSource(this.data_establecimientos);
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }

  /**
   * Descargue a excel listado de Establecimientos
   */
  descargaArchivoEstablecimiento() {
    const primeraFila: any[] = [
      'Horeca Tipo de Negocio',
      'Horeca Tipo de Persona',
      'Horeca NIT/CC/CE',
      'Horeca Razón Social / Nombre',
      'Horeca Nombre Comercial',
      'Punto País',
      'Punto Departamento',
      'Punto Ciudad',
      'Punto Nombre',
      'Punto Sillas',
      'Punto Domicilios',
      'Valor Pedidos (COP) Últimos 3 Meses ',
      '# de Referencias Últimos 3 Meses',
    ];
    const object_keys = [
      'horeca_tipo_negocio',
      'horeca_tipo_usuario',
      'horeca_nit',
      'horeca_razon_social',
      'horeca_nombre',
      'punto_pais',
      'punto_departamento',
      'punto_ciudad',
      'punto_nombre',
      'punto_sillas',
      'punto_domicilios',
      'data_ventas_tres_meses',
      'data_referencias_tres_meses',
    ];
    /** Arma el contenido de la tabla con titulos*/
    const arreglo_final: any[] = [];
    arreglo_final.push(primeraFila);
    this.data_establecimientos.forEach((element: any) => {
      const array = [];
      for (const column of object_keys) {
        if (column == 'data_referencias_tres_meses' || column == 'data_ventas_tres_meses' || column == 'punto_sillas') {
          //element[column] = element[column].replace('$', '').replace('.', '');
          element[column] = parseFloat(element[column]);
        }
        array.push(element[column]);
      }

      arreglo_final.push(array);
    });
    this.DescargaExcel({ data: arreglo_final, nombreArchivo: `Establecimientos.xlsx` });
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio-organizacion']);
    };
  }
}
