<div class="container">
  <div class="row">
    <div class="col-11">
      <h5>Carga tu catálogo</h5>
    </div>
    <div class="col-1" style="cursor: pointer;">
      <fa-icon [icon]="faTimes" size="lg" (click)="activeModal.close()"></fa-icon>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-12">
      <p>
        Debes subir las imágenes de tus productos, mínimo 1 imagen, máximo 3 en esta carga, podrás editar las imágenes
        luego. (Queda{{ productos.length == 1 ? '' : 'n' }} {{ productos.length }} producto{{ productos.length == 1 ? ''
        : 's' }} pendiente{{ productos.length == 1 ? '' : 's' }})
      </p>
    </div>
  </div>
  <div class="row" *ngIf="prods_saltados != 0" hidden>
    <div class="col-12">
      <p *ngIf="prods_saltados == 1" style="color: red;">
        Ten en cuenta que hubo 1 producto del Excel que no pudo ser cargado al sistema
      </p>
      <p *ngIf="prods_saltados != 1" style="color: red;">
        Ten en cuenta que hubo {{ prods_saltados }} productos del Excel que no pudieron ser cargados al sistema
      </p>
    </div>
  </div>
  <div class="row" *ngIf="error_formato_img">
    <div class="col-12">
      <p style="color: red;">
        La imagen debe ser en .png o .jpg
      </p>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-12">
      <!--Scroll de productos a los que agregarles las fotos-->
      <div class="scroll">
        <div class="producto" *ngFor="let prod of pag_actual; index as i;">
          <div class="row">
            <div class="col-3">
              <p>{{ prod.nombre }}</p>
            </div>
            <!--TODO: ngFor fotos-->
            <div class="col-2" *ngFor="let foto of fotos[i]; index as j">
              <div class="row">
                <div class="col-1">
                  <fa-icon [icon]="faTimesCircle" pull="right" (click)="quitarFoto(i, j)"
                    (click)="fileUpload.value = ''"></fa-icon>
                </div>
                <div class="col-11">
                  <img class="img-prod" [src]="foto" alt="producto" />
                </div>
              </div>
            </div>
            <div class="col-3">
              <input type="file" class="file-upload" (change)="handleFileInput($event, i)" #fileUpload />
              <button class="btn-blanco" (click)="fileUpload.click()" *ngIf="fotos[i].length <  3">
                + Agregar foto
              </button>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-12 text-right">
      <button class="btn-morado" (click)="cargar()">
        Cargar {{ pag_actual.length < max_prods_x_pag ? ' y terminar' : ' y siguiente' }} </button>
    </div>
  </div>
</div>