<app-header-horeca></app-header-horeca>
<div class="d-flex justify-content-center">
  <section class="contenido p-5 w-100" *ngIf="tipo_trabajador_logueago === 'PLANEADOR PEDIDOS'">
    <app-informacion-usuario></app-informacion-usuario>
  </section>
  <section class="contenido p-5 w-100" *ngIf="tipo_trabajador_logueago !== 'PLANEADOR PEDIDOS'">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills">
      <li [ngbNavItem]="1" class="text-center p-0">
        <a ngbNavLink>Trabajadores</a>
        <ng-template ngbNavContent>
          <div class="column">
            <app-informacion-usuario></app-informacion-usuario>
            <app-trabajadores *ngIf="auth.user?.tipo_trabajador !== 'PLANEADOR PEDIDOS'"></app-trabajadores>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2" class="text-center p-0">
        <a ngbNavLink>Puntos de entrega</a>
        <ng-template ngbNavContent>
          <div class="column">
            <app-informacion-usuario *ngIf="auth.user?.tipo_trabajador !== 'PLANEADOR PEDIDOS'">
            </app-informacion-usuario>
            <app-puntos-entrega></app-puntos-entrega>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="3" class="text-center p-0" *ngIf="auth.user?.tipo_trabajador !== 'PLANEADOR PEDIDOS'">
        <a ngbNavLink>Información de cuenta</a>
        <ng-template ngbNavContent>
          <app-informacion-cuenta></app-informacion-cuenta>
        </ng-template>
      </li>
      <li [ngbNavItem]="4" class="text-center p-0" *ngIf="auth.user?.tipo_trabajador !== 'PLANEADOR PEDIDOS'">
        <a ngbNavLink>Gráficas</a>
        <ng-template ngbNavContent>
          <app-graficas></app-graficas>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </section>
</div>