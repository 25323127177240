import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  faTimes,
  faChevronLeft,
  faTimesCircle,
  faEyeSlash,
  faEye,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PuntoEntrega } from 'src/app/models/punto_entrega.model';
import { Trabajador } from 'src/app/models/trabajador.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PlacesService } from 'src/app/services/places/places.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { SimpleComponent } from '../simple/simple.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-registrar-miembro-organizacion',
  templateUrl: './registrar-miembro-organizacion.component.html',
  styleUrls: ['./registrar-miembro-organizacion.component.css'],
})
export class RegistrarMiembroOrganizacionComponent implements OnInit, DoCheck {
  /** Iconos FontAwesome para usar en el template */
  faTimes = faTimes;
  faChevronLeft = faChevronLeft;
  faTimesCircle = faTimesCircle;
  faEyeSlash = faEyeSlash;
  faEye = faEye;
  faTrashAlt = faTrashAlt;

  /** Flag para mostrar u ocultar contraseña */
  fieldTextType = false;

  /** Datos del trabajador a registrar */
  nombres = '';
  apellidos = '';
  correo = '';
  clave = '';
  telefono = 0;
  celular = 0;
  pais = 'Colombia';
  departamento = '';
  ciudad = '';
  tipo_documento = '';
  numero_documento = '';
  tipo_trabajador = '';
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  user = this.authService.user;
  usuario_horeca = '';
  distribuidor = '';

  punto_entrega = '';
  punto_entrega_nom = '';
  puntos_entrega: string[] = [];

  /** Variables para el manejo de la lista de departamentos y ciudades */
  ciudades: any;
  departamentos: any;

  /** Se guardan los nombres de los puntos de entrega para mostrar en el form */
  puntos_entrega_nombre: string[] = [];

  /** Todas los puntos de venta de este usuario horeca */
  puntos_venta: PuntoEntrega[] = [];

  /** número del paso en el proceso de creacion de un trabajador */
  step = 1;

  /** Variable de control para la barra de progreso */
  progressBarValue = 50;

  /** Guarda estado de validacion de telefono opcional */
  is_boton_habilitado = false;

  @Input() callback = () => {};

  crearTrabajadorFormPaso1: FormGroup;
  crearTrabajadorFormPaso2: FormGroup;
  organizacion: any;

  constructor(
    private authService: AuthService,
    private restService: RestService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private router: Router,
    private formBuilder: FormBuilder,
    public places: PlacesService
  ) {
    this.crearTrabajadorFormPaso1 = this.formBuilder.group({
      trabajadorTipo: ['', Validators.required],
      trabajadorDocumento: ['', [Validators.required, Validators.pattern(/^[0-9]{5,}$/)]],
      trabajadorNombre: ['', Validators.required],
      trabajadorApellido: ['', Validators.required],
      trabajadorPais: ['', Validators.required],
      trabajadorDepartamento: ['', Validators.required],
      trabajadorCiudad: ['', Validators.required],
      trabajadorCelular: ['', [Validators.required, Validators.pattern(/^3[\d]{9}$/)]],
      trabajadorTelefono: [''],
    });
    this.crearTrabajadorFormPaso2 = this.formBuilder.group({
      trabajadorCorreo: ['', [Validators.required, Validators.email]],
      trabajadorContrasena: ['', Validators.required],
      trabajadorTipoUsuario: ['', Validators.required],
    });
    this.step = 1;
    this.organizacion = this.user?.organizacion;
    this.departamento = this.authService.user_organizacion?.departamento || '';
  }

  ngDoCheck(): void {
    this.habilitarBoton();
  }

  ngOnInit(): void {
    this.getPlaces();
  }

  /**
   * Metodo para conseguir ciudades y departamentos del país
   */
  async getPlaces() {
    await this.places.getDepartmentFromServerImagine();
    this.departamentos = this.places.departments_colombia;
    let id_dpto = 0;
    for (const dpto of this.places.departments_colombia) {
      if (dpto.name == this.crearTrabajadorFormPaso1.get('trabajadorDepartamento')?.value) {
        break;
      }
      id_dpto++;
    }
    if (this.places.departments_colombia[id_dpto] != undefined) {
      await this.places.getCitiesFromServerImagine(this.places.departments_colombia[id_dpto].code);
      this.ciudades = this.places.cities_colombia;
    } else {
      this.ciudades = [];
    }
  }

  /**
   * Intenta el registro del trabjaador usando los datos llenados en el formulario
   */
  registrarTrabajador() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    const nuevo_trab: Trabajador = new Trabajador(
      this.crearTrabajadorFormPaso1.get('trabajadorNombre')?.value,
      this.crearTrabajadorFormPaso1.get('trabajadorApellido')?.value,
      this.crearTrabajadorFormPaso2.get('trabajadorCorreo')?.value,
      this.crearTrabajadorFormPaso2.get('trabajadorContrasena')?.value,
      this.crearTrabajadorFormPaso1.get('trabajadorTelefono')?.value,
      this.crearTrabajadorFormPaso1.get('trabajadorCelular')?.value,
      this.crearTrabajadorFormPaso1.get('trabajadorPais')?.value,
      this.crearTrabajadorFormPaso1.get('trabajadorDepartamento')?.value,
      this.crearTrabajadorFormPaso1.get('trabajadorCiudad')?.value,
      this.crearTrabajadorFormPaso1.get('trabajadorTipo')?.value,
      this.crearTrabajadorFormPaso1.get('trabajadorDocumento')?.value,
      this.crearTrabajadorFormPaso2.get('trabajadorTipoUsuario')?.value,
      'Aprobado',
      true,
      undefined,
      this.puntos_entrega,
      undefined
    );
    /*  if ('usuario_horeca' in this.user!) {
      nuevo_trab.usuario_horeca = this.usuario_horeca;
    } else if ('distribuidor' in this.user!) {
      nuevo_trab.distribuidor = this.distribuidor;
    } */

    nuevo_trab.organizacion = this.organizacion._id;
    this.restService
      .postJWT('trabajador/registro', nuevo_trab)
      .toPromise()
      .then((resp: any) => {
        if (resp.success) {
          this.activeModal.close('Click close');
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          modalRef.componentInstance.msg = 'El trabajador que registraste fue creado con éxito';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            this.callback();
            window.location.reload();
          };
        } else {
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg = resp.msg;
          modalRef.componentInstance.btn_msg = 'Volver';
          modalRef.componentInstance.close_callback = () => {
            if (
              resp.msg !==
              'El correo ingresado ya está registrado con nosotros. ¡Por favor revisa tus datos e intenta de nuevo!'
            ) {
              window.location.reload();
            }
          };
        }
      })
      .catch(() => {
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'Ocurrió un error inesperado. ¡Por favor intenta de nuevo más tarde!';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {};
      });
  }

  /**
   * Si no cumple no entra a la asignacion según estado del formulario.
   * Se realiza este paso dado que el telefono del trabajador
   * solo se verifica si se ingresa al menos un digito en el input
   */
  habilitarBoton() {
    this.is_boton_habilitado = false;
    if (
      this.crearTrabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length >= 1 &&
      this.crearTrabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length != 7 &&
      this.crearTrabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length != 10
    ) {
      return;
    } else {
      this.is_boton_habilitado = !this.crearTrabajadorFormPaso1.invalid;
    }
  }

  /**
   * No Avanza al siguiente paso y alerta/muestra lo errado
   */
  showAlert() {
    if (this.step == 1 && this.crearTrabajadorFormPaso1.invalid == true) {
      this.crearTrabajadorFormPaso1.markAllAsTouched();
    } else if (this.step != 1 && this.crearTrabajadorFormPaso2.invalid == true) {
      this.crearTrabajadorFormPaso2.markAllAsTouched();
    }
  }

  /**
   * Permite avanzar o retroceder en el formulario
   * @param paso El paso en el que se encuentra el usuario
   */
  onPaso(paso: number) {
    if (paso == 1) {
      this.step = 1;
      this.progressBarValue = 50;
    } else {
      this.step = 2;
      this.progressBarValue = 100;
    }
  }

  /**
   * Oculta o revela la contraseña al usuario
   */
  mostrarOcultarPassword() {
    this.fieldTextType = !this.fieldTextType;
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   * */
  validateNumber(event: any) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || excludedKeys.includes(keyCode))) {
      event.preventDefault();
    }
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateOnlyText(event: any) {
    const keyCode = event.keyCode;
    if (keyCode >= 48 && keyCode <= 57) {
      event.preventDefault();
    }
  }
}
