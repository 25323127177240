import { Component, OnInit } from '@angular/core';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { RestService } from 'src/app/services/rest/rest.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';

@Component({
  selector: 'app-cuenta-organizaciones',
  templateUrl: './cuenta-organizaciones.component.html',
  styleUrls: ['./cuenta-organizaciones.component.css'],
})
export class CuentaOrganizacionesComponent implements OnInit {
  // Variable de control para la navegación del panel
  public active = 1;
  public activePrevio = 1;
  // Usuario
  public userData: any;
  // Iconos
  public faUser = faUser;
  public faSignOutAlt = faSignOutAlt;
  // Data trabajadores de la org.
  public trabajadores: any = [];
  // Organizacion
  public id_organizacion = this.auth.user_organizacion._id;
  public data_organizacion: any;
  // Guarda el plan asociado a la org.
  public plan_actual: any;
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };

  constructor(
    public auth: AuthService,
    private router: Router,
    private ngxsStore: Store,
    private rest: RestService,
    private modalService: NgbModal
  ) {}

  async ngOnInit() {
    // Modal generíco cargando...
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    try {
      this.id_organizacion = await this.ngxsStore.snapshot().auth.user_organizacion._id;
      this.userData = this.ngxsStore.snapshot().auth.user;
      this.data_organizacion = this.ngxsStore.snapshot().auth.user_organizacion;
      await this.getTrabajadores();
      await this.getPlanAsociado();
      this.modalCarga?.close();
    } catch (error) {
      this.mostrarMensajeError();
    }
  }

  /**
   * Recupera los trabajadpres activos de una organizacion.
   */
  private getTrabajadores() {
    this.rest
      .getJWT('all_organizacion_trabajador/' + this.id_organizacion)
      .toPromise()
      .then((resp: any) => {
        resp.forEach((element: any) => {
          if (element.solicitud_vinculacion != 'Rechazado') {
            this.trabajadores.push(element);
          }
        });
      })
      .catch((err) => {
        throw err;
      });
  }

  /**
   * Recupera el plan al cual se encuentra asociado la org.
   */
  private getPlanAsociado() {
    if (
      this.data_organizacion.tipo_plan != 'N/A' &&
      this.data_organizacion.tipo_plan != '' &&
      this.data_organizacion.tipo_plan != undefined
    ) {
      this.rest
        .getJWT('planes-membresias/activos/organizacion/')
        .toPromise()
        .then((resp: any) => {
          this.plan_actual = resp.find((plan: any) => {
            return plan._id == this.data_organizacion.tipo_plan;
          });
        })
        .catch((err) => {
          throw err;
        });
    }
  }

  /**
   * Permite cerrar sesión y navega al inicio de la plataforma
   */
  signOut() {
    this.auth.signOut();
    this.router.navigateByUrl('/home');
  }

  onChangeNav(item: number) {
    const status: any = [];
    status.push(this.activePrevio);
    let conf;
    if (localStorage.getItem('Organizacion-form-pristine') || localStorage.getItem('Persona-form-pristine')) {
      conf = confirm('Esta a punto de salir sin guardar cambios, ¿Desea continuar?');
      if (conf) {
        localStorage.removeItem('Organizacion-form-pristine');
        localStorage.removeItem('Persona-form-pristine');
        this.active = item;
      } else {
        this.active = status[0];
      }
    } else {
      this.active = item;
    }
    this.activePrevio = this.active;
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio-organizacion']);
    };
  }
}
