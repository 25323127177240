<app-header-distribuidor></app-header-distribuidor>
<section class="pt-5 d-flex justify-content-center">
  <div class="contenido">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Portafolio</a>
        <ng-template ngbNavContent>
          <app-catalogo></app-catalogo>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Inventario | Precios</a>
        <ng-template ngbNavContent>
          <app-inventario></app-inventario>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink>Gráficas</a>
        <ng-template ngbNavContent>
          <app-graficas-portafolio></app-graficas-portafolio>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</section>