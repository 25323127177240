<section>
  <!-- Puntos Feat -->
  <div class="card p-4 mt-4">
    <span class="d-flex flex-row align-items-center justify-content-between pt-4 pb-0">
      <h5 class="m-0">Puntos Feat</h5>
      <button class="btn-purple" (click)="descargaArchivoPuntosFt()">Descargar datos</button>
    </span>
    <div class="card-body scroll-tabla mt-3 p-0">
      <ng2-smart-table [settings]="settings_puntosFt" [source]="source_puntosFt"></ng2-smart-table>
    </div>
  </div>
</section>