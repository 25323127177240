<app-header-distribuidor></app-header-distribuidor>
<div class="d-flex justify-content-center align-items-center">
  <div class="contenedor">
    <!--Header-->
    <div class="row pb-4">
      <div class="col-12 col-md-3 col-lg-2 text-center pb-3">
        <button class="boton-volver" routerLink="/portafolio">
          <fa-icon [icon]="faChevronLeft"></fa-icon>
          &nbsp;Volver
        </button>
      </div>
      <div class="col-12 col-md-8 col-lg-10  pb-3">
        <h3>Mis saldos y promociones</h3>
      </div>
    </div>
    <!--Cards crear saldos y promociones-->
    <div class="row pb-5" *ngIf="active == 1">
      <div class="col-12 col-xl-11">
        <div class="row h-100">
          <!--Card crear promoción-->
          <div class="col-12 col-md-6 w-100 pt-2">
            <div class="card h-100">
              <div class="card-body">
                <div class="row h-100">
                  <div class="col-3 text-center">
                    <img src="../../assets/img/img-crear-promo.png"
                      style="max-width: 100%; height: auto; max-height: 6rem" alt="Ícono de crear promociones" />
                  </div>
                  <div class="d-flex flex-column align-content-between justify-content-between col-9 h-100">
                    <div class="row">
                      <div class="col-12">
                        <h5 class="h5-purple">Podrás armar promociones hasta de 3 productos</h5>
                      </div>
                    </div>
                    <div class="row h-100 align-content-end">
                      <div class="col-12 col-lg-8">
                        <button class="btn-purple" (click)="popUpPromos()">Crear promoción</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="footer-saldos-promos">
                <h6 class="h5-purple">Varios productos a un precio especial</h6>
              </div>
            </div>
          </div>
          <!--Card crear saldos-->
          <div class="col-12 col-md-6 w-100 pt-2">
            <div class="card h-100">
              <div class="card-body">
                <div class="row h-100">
                  <div class="col-3 text-center">
                    <img src="../../assets/img/img-crear-saldo.png"
                      style="max-width: 100%; height: auto; max-height: 6rem" alt="Ícono de crear saldos" />
                  </div>
                  <div class="d-flex flex-column align-content-between justify-content-between col-9 h-100">
                    <div class="row">
                      <div class="col-12">
                        <h5 class="h5-purple">Pon en descuento aquellos productos que no han tenido salida</h5>
                      </div>
                    </div>
                    <div class="row h-100 align-content-end">
                      <div class="col-12 col-lg-8">
                        <button class="btn-purple" (click)="popUpSaldos()">Crear saldo</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="footer-saldos-promos">
                <h6 class="h5-purple">¿Próximos a vencer? Ponlos en descuento</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Navegacion Crear nuevo y Creados-->
    <div class="row">
      <div class="col-12">
        <section class="contenido">
          <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills pb-0">
            <li [ngbNavItem]="1" class="nav-item col-12 col-sm-4 col-lg-3 col-xl-3 p-0">
              <a ngbNavLink>Crear nuevo</a>
              <ng-template ngbNavContent>
                <app-s-y-p-crear-nuevo></app-s-y-p-crear-nuevo>
              </ng-template>
            </li>
            <li [ngbNavItem]="2" class="nav-item col-12 col-sm-4 col-lg-3 col-xl-3 p-0">
              <a ngbNavLink>Creados</a>
              <ng-template ngbNavContent>
                <app-s-y-p-creados></app-s-y-p-creados>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"></div>
        </section>
      </div>
    </div>
  </div>
</div>