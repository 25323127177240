/* eslint-disable prefer-const */
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateDiff',
})
export class DateDiffPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(value: any, ...args: any): any {
    moment.locale('es-es');
    let actual = moment();
    let sufijo = ' dia(s)';
    let difference = actual.diff(moment(value), 'days');
    if (difference === 0) {
      sufijo = ' hr';
      difference = actual.diff(moment(value), 'hours');
    }
    if (difference === 0) {
      sufijo = ' min';
      difference = actual.diff(moment(value), 'minutes');
    }
    if (difference === 0) {
      sufijo = ' seg';
      difference = actual.diff(moment(value), 'seconds');
    }
    return difference + sufijo;
  }
}
