<div class="contenedor-graficas">
  <div class="graficas">
    <!-- Referencias por categoria -->
    <div>
      <span class="d-flex flex-row justify-content-between">
        <p>No. de referencias por categoría</p>
        <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_referencias_categoria"
          (ngModelChange)="getGraficaInformacionReferenciasCategoria()">
          <option [value]="ano" *ngFor="let ano of filtro_24_meses">
            {{ano}}
          </option>
        </select>
      </span>
      <div class="d-flex justify-content-start pt-1">
        Total: {{total_referencias_categoria}}
      </div>
      <canvas #bar_referencias_categoria class="mt-4 grafica"
        [ngClass]="{'inactive': data_bar_referencias_categoria.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_referencias_categoria.datasets.length > 0 else mostrarError"></div>
    </div>
    <!-- Referencias por marca -->
    <div>
      <span class="d-flex flex-row justify-content-between">
        <p>No. de referencias por marca</p>
        <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_categoria"
          (ngModelChange)="getGraficaInformacionReferenciasMarcas()">
          <option [value]="ano" *ngFor="let ano of filtro_24_meses">
            {{ano}}
          </option>
        </select>
      </span>
      <div class="d-flex justify-content-start pt-1">
        Total: {{total_referencias_marca}}
      </div>
      <canvas #bar_referencias_marca class="mt-4 grafica"
        [ngClass]="{'inactive': data_bar_referencias_marca.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_referencias_marca.datasets.length > 0 else mostrarError"></div>
    </div>
    <!-- Ventas por categoria -->
    <div>
      <span class="d-flex flex-row justify-content-between">
        <p>No. ventas por categoría de portafolio</p>
        <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_categoria"
          (ngModelChange)="getGraficaInformacionVentasCategoria()">
          <option [value]="ano" *ngFor="let ano of filtro_24_meses">
            {{ano}}
          </option>
        </select>
      </span>
      <div class="d-flex justify-content-start pt-1">
        Total: {{total_ventas_categoria}}
      </div>
      <canvas #bar_ventas_categoria class="mt-4 grafica"
        [ngClass]="{'inactive': data_bar_ventas_categoria.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_ventas_categoria.datasets.length > 0 else mostrarError"></div>

    </div>
    <!-- Ventas por marca -->
    <div>
      <span class="d-flex flex-row justify-content-between">
        <p>No. de ventas por marca</p>
        <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_marca"
          (ngModelChange)="getGraficaInformacionVentasMarca()">
          <option [value]="ano" *ngFor="let ano of filtro_24_meses">
            {{ano}}
          </option>
        </select>
      </span>
      <div class="d-flex justify-content-start pt-1">
        Total: {{total_ventas_marca}}
      </div>
      <canvas #bar_ventas_marca class="mt-4 grafica"
        [ngClass]="{'inactive': data_bar_ventas_marca.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_ventas_marca.datasets.length > 0 else mostrarError"></div>

    </div>
  </div>
  <div class="topVentas">
    <div class="d-flex flex-column align-items-start">
      <span>Top 10 más vendidos</span>
      <span>Últimos 3 meses</span>
    </div>
    <div class="productos-container" *ngIf="data_top_productos.length > 0 else mostrarError">
      <div class="productos" *ngFor="let product of data_top_productos">
        <img src="{{ product.foto_producto }}" alt="logo producto" />
        <span class="d-flex d-flex flex-column">
          <p class="descripcion m-0">{{ product.nombre_producto }}</p>
          <p class="m-0">{{ product.total | currency: '$ ':'symbol':'1.0-0' }}</p>
        </span>
      </div>
    </div>
  </div>
</div>

<!-- Manejo de errores -->
<ng-template #mostrarError>
  <div class="d-flex justify-content-center mt-4">
    <div>
      <img src="assets/img/icon-warning-amarillo.png" alt="icono de una hoja de papel y una lupa sobre ella"
        style="max-width: 150px;" />
    </div>
  </div>
  <div class="mt-2">
    <p class="text-center">
      ¡Lo siento! No existen datos para ver aquí
    </p>
  </div>
</ng-template>