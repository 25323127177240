<div class="container">
  <!--Header-->
  <div class="row">
    <div class="col-11">
      <h5>Crear promoción</h5>
    </div>
    <div class="col-1" style="cursor: pointer">
      <fa-icon [icon]="faTimes" size="lg" (click)="close()"></fa-icon>
    </div>
  </div>
  <br />
  <!--Instrucciones-->
  <div class="row">
    <div class="col-12">
      <p>Debes seleccionar un tipo de unidad de cada producto para la promoción</p>
    </div>
  </div>
  <br />
  <!--Info y formulario de producto-->
  <div class="scroll">
    <!--Productos-->
    <div *ngFor="let prod of prods; index as i">
      <div class="row">
        <!--Eliminar producto-->
        <div class="col-1">
          <fa-icon [icon]="faTimesCircle" (click)="deleteProd(i)"></fa-icon>
        </div>
        <!--Foto-->
        <div class="text-center col-4 col-xl-2">
          <img [src]="fotos[i]" style="max-width: 100%; height: auto" alt="producto {{ prod.nombre }}"
            class="imagen-producto" />
        </div>
        <!--Informacion-->
        <div class="col-7 col-xl-4">
          <p class="p-info">
            {{ prod.nombre }}
          </p>
          <p class="p-info">Precio und. {{ precios[i].precio_unidad | currency: '$ ':'symbol':'1.0-0' }}</p>
          <p class="p-info">
            Caja x{{ precios[i].und_x_caja }} {{ precios[i].precio_caja | currency: '$ ':'symbol':'1.0-0' }}
          </p>
        </div>
        <!--Unidades-->
        <div class="col-12 col-xl-4 d-flex flex-column justify-content-center align-items-center"
          *ngIf="max_inv[i] <= 0 ">
          <p class="text-danger text-center">No tienes unidades disponibles para este producto, debes eliminaro.</p>
        </div>
        <div class="col-12 col-xl-4 d-flex flex-column justify-content-center align-items-center"
          *ngIf="max_inv[i] > 0">
          <label class="text-center">Unidades por unidad de promoción</label>
          <div>
            <button class="btn_cantidad btn_menos"
              (click)="precios[i].inventario_unidad = precios[i].inventario_unidad - 1"
              [disabled]="precios[i].inventario_unidad == 0">
              -
            </button>
            <input class="span_cnt" type="number" [(ngModel)]="precios[i].inventario_unidad" />
            <button class="btn_cantidad btn_mas"
              (click)="precios[i].inventario_unidad = precios[i].inventario_unidad + 1">
              +
            </button>
          </div>
          <p>{{ precios[i].inventario_caja }} caja{{ precios[i].inventario_caja == 1 ? '' : 's' }}</p>
        </div>
      </div>
      <!--Advertencias de inventario-->
      <div class="row" *ngIf="max_inv[i] > 0">
        <div class="col-12">
          <!--Unidades maximas-->
          <p *ngIf="err_max_unidades[i]" class="text-danger">
            Ten en cuenta que estás poniendo en promoción todo el inventario de este producto para cada unidad de la
            promoción.
          </p>
          <!--Unidades minimas-->
          <p *ngIf="err_min_unidades[i]" class="text-danger">
            Debes poner al menos una unidad en promoción, o eliminar el producto de esta promoción.
          </p>
        </div>
      </div>
      <hr />
    </div>
    <br />
    <!--Especificaciones-->
    <div class="row">
      <div class="col-12">
        <h6>Especificaciones</h6>
      </div>
    </div>
    <br />
    <div class="row">
      <!--Nombre promoción-->
      <div class="col-12 col-md-6 mt-2">
        <label>Nombre promoción</label>
        <input type="text" placeholder="Escribe aquí" [(ngModel)]="nombre_promo" />
      </div>
      <div class="col-12 col-md-6 mt-2">
        <label>Código promoción</label>
        <input type="text" placeholder="Escribe aquí" [(ngModel)]="codigo_promo" />
      </div>
      <!--Precio de la promoción-->
      <div class="col-12 col-md-6 mt-2">
        <label>Precio de la promoción</label>
        <input type="text" placeholder="$0" (ngModel)="(precio_promo)" [(ngModel)]="precio_promo"
          (keydown)="validateNumber($event)" (blur)="transformAmount($event.target)"
          (focus)="transformCurrency($event.target)" />
      </div>
    </div>
    <br />
    <!--Inventario de la promocións-->
    <div class="row">
      <div class="col-12 text-center">
        <label>Unidades disponibles de la promoción</label>
        <div>
          <button class="btn_cantidad btn_menos" (click)="inventario_promo = inventario_promo - 1"
            [disabled]="inventario_promo == 0">
            -
          </button>
          <input class="span_cnt" type="number" [(ngModel)]="inventario_promo" />
          <button class="btn_cantidad btn_mas" (click)="inventario_promo = inventario_promo + 1">+</button>
        </div>
      </div>
    </div>
    <br />
  </div>
  <!--Btn crear promo-->
  <div class="row">
    <div class="col-12 text-right">
      <button class="btn-purple btn-purple col-12 col-sm-8 col-md-5 col-xl-4" [disabled]="!btn_promo_enabled"
        (click)="createPromo()">Crear promoción</button>
    </div>
  </div>
</div>