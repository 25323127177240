import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmacionComponent } from 'src/app/modal/confirmacion/confirmacion.component';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { RestService } from 'src/app/services/rest/rest.service';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';
type AOA = any[][];

@Component({
  selector: 'app-portafolio-dist-convenios',
  templateUrl: './portafolio-dist-convenios.component.html',
  styleUrls: ['./portafolio-dist-convenios.component.css']
})
export class PortafolioDistConveniosComponent implements OnInit {
  @ViewChild('myElement') myElement: ElementRef | undefined;
  @ViewChild('fileUploader') fileUploader:ElementRef  | any;

  stateCtrl: FormControl | undefined;
  filteredStates: Observable<any[]> | undefined;
  productosSeleccionado: any = [];
  myDropDown : any;
  stBusq='';
  filtroNombre='';
  showOptions: boolean = false;
  textSearch: string = '';
    /** Evita que se pueda hacer click fuera del modal */
    ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'md'
    };
  states = [
    {
      name: 'Arkansas',
      population: '2.978M',
      // https://commons.wikimedia.org/wiki/File:Flag_of_Arkansas.svg
      flag: 'https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Arkansas.svg'
    },
    {
      name: 'California',
      population: '39.14M',
      // https://commons.wikimedia.org/wiki/File:Flag_of_California.svg
      flag: 'https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_California.svg'
    },
    {
      name: 'Florida',
      population: '20.27M',
      // https://commons.wikimedia.org/wiki/File:Flag_of_Florida.svg
      flag: 'https://upload.wikimedia.org/wikipedia/commons/f/f7/Flag_of_Florida.svg'
    },
    {
      name: 'Texas',
      population: '27.47M',
      // https://commons.wikimedia.org/wiki/File:Flag_of_Texas.svg
      flag: 'https://upload.wikimedia.org/wikipedia/commons/f/f7/Flag_of_Texas.svg'
    }
  ];

  @Input() msg = '';
  @Input() idPunto = '';
  @Input() btn_msg_yes = '';
  @Input() btn_msg_no = '';
  @Input() distribuidor: any;
  @Input() callback_yes = () => {};
  @Input() callback_no = () => {};
  productos: any;
  prods_filtrados: any;
  copySeleccionados: any;
  productosPortafolio: any;
  productosXLS: any 

  data: AOA = [[1, 2], [3, 4]];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';
  idPreciosEspeciales: any;
  productosEspecialesXls:any;
  copySeleccionadosXLS: any;
  filtroNombreXLS: any;
  loading = false;
  productosXLS_respaldo: any=[];
  constructor(public activeModal: NgbActiveModal, private distribuidorService: DistribuidorService,     
    private rest: RestService,
    private modalService: NgbModal
  ) {
    this.stateCtrl = new FormControl();
    this.filteredStates = this.stateCtrl.valueChanges
      .pipe(
        startWith(''),
        map(state => state ? this.filterStates(state) : this.states.slice())
      );
   }
   filterStates(name: string) {
    return this.states.filter(state =>
      state.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
  async ngOnInit(): Promise<void> {
    if(this.distribuidor){
      this.distribuidorService
      .getPortafolioVinculaciones(this.distribuidor)
      .subscribe((data: any) => {
        this.productos=data;
      });
    }
    this.productosSeleccionado = await  this.listaPuntosEntrega('descuentos')
    this.copySeleccionados = this.productosSeleccionado;  
    this.productosPortafolio = await  this.listaPuntosEntrega('portafolio');
    this.construyeData( this.productosSeleccionado, this.productosPortafolio)

  }
  async generate(){
    if(this.distribuidor){
      this.distribuidorService
      .getPortafolioVinculaciones(this.distribuidor)
      .subscribe((data: any) => {
        this.productos=data;
      });
    }
    this.productosSeleccionado = await  this.listaPuntosEntrega('descuentos')
    this.copySeleccionados = this.productosSeleccionado;  
    this.productosPortafolio = await  this.listaPuntosEntrega('portafolio');
    this.construyeData( this.productosSeleccionado, this.productosPortafolio)
    this.loading = false
  }
  ngDoCheck(): void {
    if (this.stBusq && this.stBusq.length > 3) {
      this.showOptions=true;
      this.gen(this.stBusq);
    }
    if (this.filtroNombre) {
      this.genFiltro(this.filtroNombre);
    }
    if(this.filtroNombreXLS){
      this.genFiltroXLS(this.filtroNombreXLS);

    }
  }
  genFiltroXLS(st: any) {
    if(this.filtroNombreXLS.length > 2){
      this.productosEspecialesXls= this.filterByValueName(this.productosEspecialesXls, this.filtroNombreXLS);
    }else{
      this.productosEspecialesXls=this.copySeleccionadosXLS;
    }
  }
  genFiltro(st: any) {
    if(this.filtroNombre.length > 2){
      this.productosSeleccionado= this.filterByValueName(this.productosSeleccionado, this.filtroNombre);
    }else{
      this.productosSeleccionado=this.copySeleccionados;
    }
  }
   limitDecimalPlaces(e: any, count: any) {
    if (e.target.value.indexOf('.') == -1) { return; }
    if ((e.target.value.length - e.target.value.indexOf('.')) > count) {
      e.target.value = parseFloat(e.target.value).toFixed(count);
    }
  }
  checkPin(event: any, i: any, tipo: any){

    if (this.filterInteger(event, event.target) === false) {
      event.preventDefault();
  }
    //this.productosSeleccionado[i][tipo] = this.productosSeleccionado[i][tipo].toFixed(2);
    //console.log(this.productosSeleccionado[i][tipo])

 }
  keyPresss(dat: any){
    //this.productosSeleccionado[dat].porcentaDescuento.toString()replace(/[^0-9,.]/g, '').replace(/,/g, '.')
  }
   isNumberKey(evt: any)
  {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
  
    return true;
  }
  filterFloat(evt: any,input: any){
    // Backspace = 8, Enter = 13, ‘0′ = 48, ‘9′ = 57, ‘.’ = 46, ‘-’ = 43
    var key = window.Event ? evt.which : evt.keyCode;    
    var chark = String.fromCharCode(key);
    var tempValue = input.value+chark;
    if(key >= 48 && key <= 57){
        if(this.filter(tempValue)=== false){
            return false;
        }else{       
            return true;
        }
    }else{
          if(key == 8 || key == 13 || key == 46 || key == 0) {            
              return true;              
          }else{
              return false;
          }
    }
}
public filter(__val__: any){
    var preg = /^([0-9]+\.?[0-9]{0,2})$/; 
    if(preg.test(__val__) === true){
        return true;
    }else{
       return false;
    }
    
}
  gen(st: any) {
    this.prods_filtrados = this.filterByValue(this.productos, st);
  }
  onChangeofOptions(value: any) {
    this.showOptions= false;
    value.porcentaDescuento = '';
    value.porcentaIncremento = '';
    value.montoFijo = '';
    if(this.productosSeleccionado.length === 0 || !this.productosSeleccionado){
      this.productosSeleccionado = [];
    }
    let insert = {
      productoID: value._id,
      nombre: value.nombre,
      porcentaDescuento: value.porcentaDescuento,
      porcentaIncremento: value.porcentaIncremento,
      montoFijo: value.montoFijo,
      imagenRef: value.fotos[0],
      codigoDist: value.codigo_distribuidor_producto,
      codigoOrg: value.codigo_organizacion_producto,

    }
    if(this.productosEspecialesXls){

      let index =   _.findIndex(this.copySeleccionadosXLS, function(o: any) { 
        return o.productoID == value._id; 
      });
      if(index < 0){
        this.productosEspecialesXls.push(insert);
       
      }
       this.copySeleccionadosXLS = this.productosEspecialesXls;
        this.stBusq = ''
    } else {
      let index =   _.findIndex(this.copySeleccionados, function(o: any) { 
        return o.productoID == value._id; 
      });
      if(index < 0){
      this.productosSeleccionado.push(insert);
    }
    this.copySeleccionados = this.productosSeleccionado;
    this.stBusq = ''
    }
  }
  onYes() {
    let nextUpate = true
    this.copySeleccionados.forEach((prod: any) => {
      if(
        (!prod.montoFijo || prod.montoFijo === null || prod.montoFijo === 0) &&
        (!prod.porcentaDescuento || prod.porcentaDescuento === null || prod.porcentaDescuento === 0) &&
        (!prod.porcentaIncremento || prod.porcentaIncremento === null || prod.porcentaIncremento === 0)
      ){
        nextUpate = false
        const ngbModalOptions: NgbModalOptions = {
          //Evita que al hacer click por fuera se cierre el modal
          backdrop: 'static',
          keyboard: false,
          size: 'md'

        };
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'El producto: '+ prod.nombre+', no contiene parametros de configuración para: descuento, incremento o monto fijo.';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          this.resetXLS();
        };
      }
      else{
        if((prod.porcentaDescuento!= null && prod.porcentaDescuento > 99) || (prod.porcentaIncremento != null && prod.porcentaIncremento > 99)){
          nextUpate = false
          const ngbModalOptions: NgbModalOptions = {
            //Evita que al hacer click por fuera se cierre el modal
            backdrop: 'static',
            keyboard: false,
            size: 'md'

          };
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg = 'El producto: '+ prod.nombre+', contiene el % de descuento y/o incremento superior a 2 digitos.';
          modalRef.componentInstance.btn_msg = 'Volver';
          modalRef.componentInstance.close_callback = () => {
            this.resetXLS();
          };
        }
      }
    });
    if(nextUpate){
      let insert ={
        idPunto:this.idPunto,
        idDistribuidor:this.distribuidor,
        productosDescuento:this.productosSeleccionado
      }
      this.rest
          .postJWT('generar_productos_Descuento', insert)
          .toPromise()
          .then((resp: any) => {
            if(resp.success){
              //this.activeModal.close('Close click');
              //this.callback_yes();
              const ngbModalOptions: NgbModalOptions = {
                //Evita que al hacer click por fuera se cierre el modal
                backdrop: 'static',
                keyboard: false,
                size: 'md'

              };
              const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
              modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
              modalRef.componentInstance.title = '¡Genial!';
              modalRef.componentInstance.msg = 'Se han actualizado con éxito';
              modalRef.componentInstance.btn_msg = 'Listo';
              modalRef.componentInstance.close_callback = () => {
                this.stBusq = '';
                this.filtroNombre = '';
                this.filtroNombreXLS = ''
                //this.productosSeleccionado=this.copySeleccionadosXLS;
                this.copySeleccionadosXLS =  undefined;
                this.productosEspecialesXls =  undefined;
                this.resetXLS();
              };
            }
          });
    }
   // 
  }
  public descargarPortafolio(){

  }
  public eliminarProd(prod: any){

    const modalRef = this.modalService.open(ConfirmacionComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-alert-amarillo.png';
    modalRef.componentInstance.title ='¿Estas seguro de eliminar producto del convenio?';
    modalRef.componentInstance.msg =
      '¿Estás seguro de eliminar producto del convenio?';
    modalRef.componentInstance.btn_msg_no = 'Cancelar';
    modalRef.componentInstance.btn_msg_yes = 'Eliminar';
    modalRef.componentInstance.callback_no = () => {};
    modalRef.componentInstance.callback_yes = () => {

      let index =   _.findIndex(this.copySeleccionados, function(o: any) { 
        return o.productoID == prod.productoID; 
      });
      if(index >= 0){
       this.copySeleccionados.splice(index, 1);
       this.productosSeleccionado=this.copySeleccionados
       this.filtroNombre = '';
      }
    };
  }
  public eliminarProdXLS(prod: any){
    const modalRef = this.modalService.open(ConfirmacionComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-alert-amarillo.png';
    modalRef.componentInstance.title ='¿Estas seguro de eliminar producto del convenio?';
    modalRef.componentInstance.msg =
      '¿Estás seguro de eliminar producto del convenio?';
    modalRef.componentInstance.btn_msg_no = 'Cancelar';
    modalRef.componentInstance.btn_msg_yes = 'Eliminar';
    modalRef.componentInstance.callback_no = () => {};
    modalRef.componentInstance.callback_yes = () => {
      let index =   _.findIndex(this.productosEspecialesXls, function(o: any) { 
        return o.productoID == prod.productoID; 
      });
      if(index >= 0){
       this.productosEspecialesXls.splice(index, 1);
       this.filtroNombreXLS = '';
  
      }
    };
  }
  onNo() {
    this.activeModal.close('Close click');
    this.callback_no();
  }
  public showSearchResults(event: any){
    if(this.textSearch.length > 3){
      this.prods_filtrados = this.filterByValue(this.productos, this.textSearch+'');
    }
   
  }

  filterByValue(array: any, string: any) {
    return array.filter(
      (o: any) => 
       
        o.nombre.toLowerCase().includes(string.toLowerCase()) ||
        o.codigo_distribuidor_producto.includes(string) ||
        o.codigo_organizacion_producto.includes(string)
    );
  }
  filterByValueName(array: any, string: any) {
    return array.filter(
      (o: any) => 
       
        o.nombre.toLowerCase().includes(string.toLowerCase())
    );
  }

  /** Lista en promesa para retornar la imagen del producto cargado del xls.
 *  @type tipo de bsuqueda
 */
  public buscaImagen(id: any) {
    return new Promise((success) => {
      let index =   _.findIndex(this.productosPortafolio.productos, function(o: any) { 
        return o._id == id; 
      });
      if(index >= 0){
        success( this.productosPortafolio.productos[index].fotos[0]);  
      }else{
        success('');  

      }

    });
  }

  /** Lista en promesa productos totales en portafolio o lista de precios especiales
 *  @type tipo de bsuqueda
 */
public listaPuntosEntrega(type: any) {
  return new Promise((success) => {
    if(type === 'descuentos'){
      this.distribuidorService
      .getPortafolioDescuento(this.distribuidor, this.idPunto)
      .subscribe((result: any) => {
        if(result.data.length > 0){
          this.idPreciosEspeciales = result.data[0]._id
          success( result.data[0].productosDescuento);  
        }else{
          success( false);  
        }
      });
    } else {
      this.distribuidorService
      .getDistribuidorProductosData(this.distribuidor)
      .subscribe((result: any) => {
        success( result);
      });
    }
  });
}
public construyeData(productosEspeciales: any, portafolio: any){
const arreglo_final: any[] = [];
const cabeceraXLS: any[] = [
  'idProducto',
  'Nombre Producto',
  'Cod Distribuidor',
  'Cod ORG',
  'Precio portafolio',
  'Descuento %',
  'Incremento %',
  '$ Monto Fijo'
];
  arreglo_final.push(cabeceraXLS);
  const array = [];
  portafolio.productos.forEach((prod: any) => {
      let insert 
      let index =   _.findIndex(productosEspeciales, function(o: any) { 
        return o.productoID == prod._id; 
      });
      if(index < 0){
        //productos portafolio
        insert = [
          prod._id,
          prod.nombre,
          prod.codigo_distribuidor_producto,
          prod.codigo_organizacion_producto,
          prod.precios[0].precio_unidad,
          0,
          0,
          0,
        ]
        arreglo_final.push(insert);
      } else {
        insert = [
          prod._id,
          prod.nombre,
          prod.codigo_distribuidor_producto,
          prod.codigo_organizacion_producto,
          prod.precios[0].precio_unidad,
          productosEspeciales[index].porcentaDescuento || 0,
          productosEspeciales[index].porcentaIncremento || 0,
          productosEspeciales[index].montoFijo || 0,
        ]
        arreglo_final.push(insert);
      }
  });
    this.productosXLS = arreglo_final
    this.productosXLS_respaldo = arreglo_final
  }

public descargaExcel(){
  const readyToExport = this.productosXLS;
  const workBook = XLSX.utils.book_new(); // create a new blank book
  const workSheet = XLSX.utils.aoa_to_sheet(readyToExport);
  XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
  XLSX.writeFile(workBook, 'Portafolio_descuentosEspeciales.xlsx'); // initiate a file download in browser
}
onFileChange(evt: any) {
  /* wire up file reader */
  const target: DataTransfer = <DataTransfer>(evt.target);
  if (target.files.length !== 1) throw new Error('Cannot use multiple files');
  const reader: FileReader = new FileReader();
  reader.onload = (e: any) => {
    /* read workbook */
    const bstr: string = e.target.result;
    const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

    /* grab first sheet */
    const wsname: string = wb.SheetNames[0];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];

    /* save data */
    this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
    this.organizerData(this.data)
  };
  reader.readAsBinaryString(target.files[0]);
}
/**
 * Funcion para ordenar data y seleccionar productos con los cuales aplica descuento y poder mostrar en card de confirmacion
 * @param data Objeto de xls
 */
public async organizerData(data: any){
  let dataClean: any = []
  let dataNotification: any = []

  for(let camp of data){
   if(
      (Number.isFinite(camp[5]) &&  camp[5] > 0)||
      (Number.isFinite(camp[6])  &&  camp[6] > 0) ||
      (Number.isFinite(camp[7])  &&  camp[7] > 0)
    ){
      let pase = true
      let paseA = true
      let paseB = true
      let paseC = true

      let validationA = 0
      let validationB = 0
      let validationC = 0

      if(!camp[5]){
        camp[5]=0
      }
      if(!camp[6]){
        camp[6]=0
      }
      if(!camp[7]){
        camp[7]=0
      }
      if(( Number.isFinite(camp[5])) && (Number.isFinite(camp[6])) && (Number.isFinite(camp[7]))){
        //console.log('Campo A', camp[5], 'campo B', camp[6], 'campo C', camp[7])
        let itemA = 0
        let itemB = 0
        let itemC = 0
        let msj

        if(!Number.isInteger(camp[5]) || !Number.isInteger(camp[6]) || !Number.isInteger(camp[7])){
          dataNotification.push(
            {
              productoID:camp[0],
          nombre:camp[1],
          codigoDist: camp[2],
          codigoOrg: camp[3],
          precioPortafolio: camp[4],
          porcentaDescuento: camp[5], 
          porcentaIncremento: camp[6],
          montoFijo: camp[7],
              mensaje: 'El número no debe contener caracteres (% / , . $ # @ - +)'
            }
          )
        } else {
          if(
            (camp[5] > 0 || camp[5] == 0) &&
            (camp[6] > 0 || camp[6] == 0) &&
            (camp[7] > 0 || camp[7] == 0)
        ){
            if(camp[5] > 0){
              itemA = 1
            }
            if(camp[6] > 0){
              itemB = 1
            }
            if(camp[7] > 0){
              itemC = 1
            }
            if((itemA+itemB+itemC) === 1){
              let image
              image = await this.buscaImagen(camp[0]);
              dataClean.push({
                productoID:camp[0],
                nombre:camp[1],
                porcentaDescuento: camp[5], 
                porcentaIncremento: camp[6],
                codigoDist: camp[2],
                codigoOrg: camp[3],
                imagenRef: image,
                montoFijo: camp[7],
              })
            }else{
              dataNotification.push(
                {
                  productoID:camp[0],
              nombre:camp[1],
              codigoDist: camp[2],
              codigoOrg: camp[3],
              precioPortafolio: camp[4],
              porcentaDescuento: camp[5], 
              porcentaIncremento: camp[6],
              montoFijo: camp[7],
                  mensaje: 'No puede haber más de un campo lleno'
                }
              )
            }
          } else {
            dataNotification.push(
              {
                productoID:camp[0],
                nombre:camp[1],
                codigoDist: camp[2],
                codigoOrg: camp[3],
                precioPortafolio: camp[4],
                porcentaDescuento: camp[5], 
                porcentaIncremento: camp[6],
                montoFijo: camp[7],
                mensaje: 'El número no debe contener caracteres (% / , . $ # @ - +)'
              }
            )
          }
        }

        




      } else {
        pase = false
      }
      if(!pase){
        dataNotification.push(
          {
            productoID:camp[0],
            nombre:camp[1],
            codigoDist: camp[2],
            codigoOrg: camp[3],
            precioPortafolio: camp[4],
            porcentaDescuento: camp[5], 
            porcentaIncremento: camp[6],
            montoFijo: camp[7],
            mensaje: 'El número no debe contener caracteres (% / , . $ # @ - + A-Z)'
          }
        )
      }
     
    }else{
      if(camp[5] < 0 || camp[6] < 0 || camp[7] < 0){
        dataNotification.push(
          {
            productoID:camp[0],
            nombre:camp[1],
            codigoDist: camp[2],
            codigoOrg: camp[3],
            precioPortafolio: camp[4],
            porcentaDescuento: camp[5], 
            porcentaIncremento: camp[6],
            montoFijo: camp[7],
            mensaje: 'El número no debe contener caracteres (% / , . $ # @ - + A-Z)'
          }
        )
      }
      
    }
  }
  if(dataNotification.length > 0){
    const arreglo_final: any[] = [];
    const cabeceraXLS: any[] = [
      'idProducto',
      'Nombre Producto',
      'Cod Distribuidor',
      'Cod ORG',
      'Precio portafolio',
      'Descuento %',
      'Incremento %',
      '$ Monto Fijo',
      'Observacion FEAT'

    ];
    arreglo_final.push(cabeceraXLS);
    const array = [];
    dataNotification.forEach((prod: any) => {
        let insert 
        insert = [
          prod.productoID,
          prod.nombre,
          prod.codigoDist,
          prod.codigoOrg,
          prod.precioPortafolio,
          prod.porcentaDescuento,
          prod.porcentaIncremento,
          prod.montoFijo,
          prod.mensaje,

        ]
        arreglo_final.push(insert);

    });

    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
      size: 'md'

    };
    const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Hemos encontrado errores en el archivo cargado, se descargará un XLS de forma automática indicándote los errores. ';
    modalRef.componentInstance.btn_msg = 'Listo';
    modalRef.componentInstance.close_callback = () => {
      this.resetXLS();
      const readyToExport = arreglo_final;
    const workBook = XLSX.utils.book_new(); // create a new blank book
    const workSheet = XLSX.utils.aoa_to_sheet(readyToExport);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
    XLSX.writeFile(workBook, 'Portafolio_descuentosEspeciales_fallos.xlsx'); // initiate a file download in browser
    }; 


    
  }else{
    this.productosEspecialesXls = dataClean;
    this.copySeleccionadosXLS =  dataClean 
  }
}
openInput(nameInput: any){
}
resetXLS(){
    this.productosEspecialesXls = undefined;
    this.copySeleccionadosXLS = undefined;
    this.fileUploader.nativeElement.value = null;
}
guardarXLS(){
  console.log(this.idPreciosEspeciales);
  console.log(this.copySeleccionadosXLS);

  if(this.idPreciosEspeciales){
    let nextUpate = true
    this.copySeleccionadosXLS.forEach((prod: any) => {
      if(
        (!prod.montoFijo || prod.montoFijo === null || prod.montoFijo === 0) &&
        (!prod.porcentaDescuento || prod.porcentaDescuento === null || prod.porcentaDescuento === 0 ) &&
        (!prod.porcentaIncremento || prod.porcentaIncremento === null || prod.porcentaIncremento === 0 )
      ){
        nextUpate = false
        const ngbModalOptions: NgbModalOptions = {
          //Evita que al hacer click por fuera se cierre el modal
          backdrop: 'static',
          keyboard: false,
          size:'md'
        };
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'El producto: '+ prod.nombre+', no contiene parametros de configuracion para: descuento, incremento o monto fijo.';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          this.resetXLS();
          this.generate();
        }; 
      }

    /*if(prod.montoFijo > 0 && !Number.isInteger(prod.montoFijo) ){
      nextUpate = false
      const ngbModalOptions: NgbModalOptions = {
        //Evita que al hacer click por fuera se cierre el modal
        backdrop: 'static',
        keyboard: false,
      };
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'El producto: '+ prod.nombre+', no puede contener monto fijo con decimales.';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {
      }; 
    }*/
  });
    if(nextUpate){
      const ngbModalOptions: NgbModalOptions = {
        //Evita que al hacer click por fuera se cierre el modal
        backdrop: 'static',
        keyboard: false,
        size: 'md'
      };
      this.rest
      .putJWT('updateProductoDescuentosEspeciales/' + this.idPreciosEspeciales, {
        productosDescuento: this.copySeleccionadosXLS
      })
          .toPromise()
          .then(() => {
            const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
            modalRef.componentInstance.title = '¡Genial!';
            modalRef.componentInstance.msg = 'Se han actualizado con éxito.';
            modalRef.componentInstance.btn_msg = 'Listo';
            modalRef.componentInstance.close_callback = () => {
              this.stBusq = '';
              this.filtroNombre = '';
              this.filtroNombreXLS = ''
              this.productosSeleccionado=this.copySeleccionadosXLS;
              this.copySeleccionados=this.copySeleccionadosXLS;
              this.copySeleccionadosXLS =  undefined;
              this.productosEspecialesXls =  undefined;
              this.resetXLS();
              this.generate();
            };
          })
          .catch(() => {
            const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
            modalRef.componentInstance.title = '¡Oh oh!';
            modalRef.componentInstance.msg = 'Esta solicitud no pudo ser actualizada. Intenta de nuevo más tarde';
            modalRef.componentInstance.btn_msg = 'Volver';
            modalRef.componentInstance.close_callback = () => {};
          });
    }
  } else {
    let insert ={
      idPunto:this.idPunto,
      idDistribuidor:this.distribuidor,
      productosDescuento:this.productosEspecialesXls
    }
    this.rest
        .postJWT('generar_productos_Descuento', insert)
        .toPromise()
        .then((resp: any) => {
          if(resp._id){
            this.generate()
            //this.activeModal.close('Close click');
            //this.callback_yes();
          }
        });
  }
}
update(){
  let nextUpate = true
  this.copySeleccionados.forEach((prod: any) => {
    if(
      (!prod.montoFijo || prod.montoFijo === null || prod.montoFijo === 0) &&
      (!prod.porcentaDescuento || prod.porcentaDescuento === null || prod.porcentaDescuento === 0) &&
      (!prod.porcentaIncremento || prod.porcentaIncremento === null || prod.porcentaIncremento === 0)
    ){
      nextUpate = false
      const ngbModalOptions: NgbModalOptions = {
        //Evita que al hacer click por fuera se cierre el modal
        backdrop: 'static',
        keyboard: false,
        size: 'md'
      };
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'El producto: '+ prod.nombre+', no contiene parametros de configuración para: descuento, incremento o monto fijo.';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {
      };
    }
    else{
      if((
        prod.porcentaDescuento!= null && prod.porcentaDescuento > 99) 
        || (prod.porcentaIncremento != null && prod.porcentaIncremento > 99)
      ){
        nextUpate = false
        const ngbModalOptions: NgbModalOptions = {
          //Evita que al hacer click por fuera se cierre el modal
          backdrop: 'static',
          keyboard: false,
          size: 'md'

        };
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'El producto: '+ prod.nombre+', contiene el % de descuento y/o incremento superior a 2 digitos.';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
        };
      }
     
    }
    if(prod.montoFijo > 0 && !Number.isInteger(prod.montoFijo) ){
      nextUpate = false
      const ngbModalOptions: NgbModalOptions = {
        //Evita que al hacer click por fuera se cierre el modal
        backdrop: 'static',
        keyboard: false,
        size: 'md'

      };
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'El producto: '+ prod.nombre+', no puede contener monto fijo con decimales.';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {
      }; 
    }
});
  if(nextUpate){
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
      size: 'md'

    };
    this.rest
    .putJWT('updateProductoDescuentosEspeciales/' + this.idPreciosEspeciales, {
      productosDescuento: this.copySeleccionados
    })
        .toPromise()
        .then(() => {
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          modalRef.componentInstance.msg = 'Se han actualizado con éxito.';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            this.stBusq = '';
            this.filtroNombre = '';
            this.productosSeleccionado=this.copySeleccionados;
            this.loading=true;
            this.productosEspecialesXls = undefined;
            this.copySeleccionadosXLS = undefined;
            this.resetXLS()
            this.generate();
          };
        })
        .catch(() => {
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg = 'Esta solicitud no pudo ser actualizada. Intenta de nuevo más tarde';
          modalRef.componentInstance.btn_msg = 'Volver';
          modalRef.componentInstance.close_callback = () => {};
        });
  }
}







 filterInteger(evt: any,input: any) {
  // ASCII https://elcodigoascii.com.ar/
  // ‘0′ = 48, ‘9′ = 57, ‘-’ = 45
  // Backspace = 8, Enter = 13, NULL = 0
  var key = window.Event ? evt.which : evt.keyCode;    
  var chark = String.fromCharCode(key);
  var tempValue = input.value+chark;
  if((key >= 48 && key <= 57) /* || key == 45 */) {
      return this.filter2(tempValue);
  } else {
      return key == 8 || key == 13 || key == 0;
  }
}

 filter2(__val__: any) {
  // /^-?[0-9]*$/; // positivos y negativos
  // /^[0-9]*$/; // solo positivos
  var preg = /^[0-9]*$/;
  return preg.test(__val__);
}


}


