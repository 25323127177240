/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, OnInit, Input, DoCheck } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PedidosService } from '../../services/pedidos/pedidos.service';
import { Router } from '@angular/router';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-calificacion-pedido',
  templateUrl: './calificacion-pedido.component.html',
  styleUrls: ['./calificacion-pedido.component.css'],
})
export class CalificacionPedidoComponent implements OnInit, DoCheck {
  /** Iconos */
  public faTimes = faTimes;
  /** Formulario reactivo */
  public form!: FormGroup;
  public data = new FormData();
  /** variables para traer los datos del pedido */
  public idParam: any;
  public pedido: any;
  public id: any;
  public currentRateAbas = 0;
  public currentRatePrice = 0;
  public currentRateCalification = 0;
  public calificacion_promedio = 0;
  public formCalificacion = 'Calificado';
  /** Acceder al localStorage para hacer la comparacion de id */
  public userInfo = JSON.parse(localStorage.getItem('auth')!);
  public idUserInfo = this.userInfo['user']['_id'];
  public idDistribuidor = this.userInfo['user']['_id'];

  @Input() public pedidos: any;
  @Input() public estado_pedido: any;
  @Input() public nombre_distribuidor: any;
  @Input() public logo_distribuidor: any;
  @Input() public fecha_pedido: any;
  @Input() public total_productos: any;

  constructor(
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private pedidosService: PedidosService,
    private restService: RestService,
    private router: Router
  ) {
    this.buildForm();
  }

  close() {
    this.activeModal.close();
  }

  ngDoCheck(): void {
    if (this.currentRateAbas !== 0 && this.currentRatePrice !== 0 && this.currentRateCalification !== 0) {
      this.calificacion_promedio =
        Math.round(
          ((Number(this.currentRateAbas) + Number(this.currentRatePrice) + Number(this.currentRateCalification)) / 3) *
            10
        ) / 10;
    } else {
      this.calificacion_promedio = 0;
    }
  }

  ngOnInit(): void {
    this.fetchPedido();
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      abastecimiento: ['', Validators.required],
      precio: ['', Validators.required],
      puntualidad_entrega: ['', Validators.required],
    });
  }

  /**
   * Metodo para traer los datos del pedido
   */
  fetchPedido() {
    this.pedidosService.getMyPedidos(this.pedidos).subscribe((res) => {
      this.pedido = res;
      if (this.pedido.calificacion !== undefined) {
        this.currentRateAbas = this.pedido.calificacion.abastecimiento;
        this.currentRatePrice = this.pedido.calificacion.precio;
        this.currentRateCalification = this.pedido.calificacion.puntualidad_entrega;
      }
    });
  }

  /**
   * Metodo para calificar un pedido
   */
  calificarPedido() {
    if (this.form.valid) {
      try {
        /** Califica al distribuidor */
        const calificacion = { calificacion: this.form.value, estado: 'Calificado' };
        this.pedidosService
          .getTotalCalificacionesDistribuidor(this.pedido.distribuidor)
          .toPromise()
          .then((resp: any) => {
            const puntaje_actual =
              (Number(calificacion.calificacion.precio) +
                Number(calificacion.calificacion.puntualidad_entrega) +
                Number(calificacion.calificacion.abastecimiento)) /
              3;
            /** Se determina el ranking final con un decimal */
            const ranking_final = {
              ranking:
                Math.round(((resp.total_calificaciones + puntaje_actual) / (resp.cant_calificaciones + 1)) * 10) / 10,
            };
            this.restService
              .putJWT(`distribuidor/${this.pedido.distribuidor}`, ranking_final)
              .toPromise()
              .then((resp2: any) => {
                /** Actualiza el pedido con las calificaciones respectivas */
                this.pedidosService.putCalificarPedido(this.pedidos, this.idUserInfo, calificacion).subscribe(() => {
                  const modalRef = this.modalService.open(SimpleComponent);
                  modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
                  modalRef.componentInstance.title = '¡Genial!';
                  modalRef.componentInstance.msg = '¡Calificación generada satisfactoriamente!';
                  modalRef.componentInstance.btn_msg = 'Listo';
                  modalRef.componentInstance.close_callback = () => {
                    this.router.navigate(['/pedidos']);
                    this.activeModal.close();
                  };
                });
              });
          });
      } catch (error) {
        console.log(error);
        const modalRef = this.modalService.open(SimpleComponent);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'No fue posible calificar a este distribuidor, intentalo mas tarde.';
        modalRef.componentInstance.btn_msg = 'Volver';
      }
    }
  }
}
