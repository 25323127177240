import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Chart } from 'chart.js';
import { Store } from '@ngxs/store';
import { RestService } from '../../services/rest/rest.service';
import { LocalDataSource } from 'ng2-smart-table';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
@Component({
  selector: 'app-puntos-compras',
  templateUrl: './puntos-compras.component.html',
  styleUrls: ['./puntos-compras.component.css'],
})
export class PuntosComprasComponent implements OnInit {
  @Input() idPunto: any;
  /** Filtro de años */
  public filtro_10_anos: any = [];
  /** Label meses para graficas */
  private meses: string[] = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  /** Colores para las gráficas */
  public colors = [
    'rgba(115, 113, 156, 1)',
    'rgba(199, 195, 239, 1)',
    'rgba(247, 234, 194, 1)',
    'rgba(250, 211, 157, 1)',
    'rgba(179, 179, 210, 1)',
    'rgba(142, 111, 247, 1)',
  ];
  /** Guarda la data del horeca logueado */
  public usuario_horeca: any;
  /** Guarda la información de la tabla de puntos por producto-punto */
  public sourceDataPuntos?: LocalDataSource;
  public dataPuntos: any = [];
  /** Biding HTML-Canvas y TS */
  @ViewChild('bar_puntos_mes', { static: true })
  private bar_puntos_mes!: ElementRef;
  @ViewChild('bar_puntos_distribuidor', { static: true })
  private bar_puntos_distribuidor!: ElementRef;
  @ViewChild('bar_puntos_organizacion', { static: true })
  private bar_puntos_organizacion!: ElementRef;
  /** Guarda datos de la talba y configuraciones */
  public data_bar_puntos_mes: any = {};
  public data_bar_puntos_distribuidor: any = {};
  public data_bar_puntos_organizacion: any = {};
  public bar_chart_puntos_mes: any;
  public bar_chart_puntos_distribuidor: any;
  public bar_chart_puntos_organizacion: any;
  public filtro_puntos_mes: any;
  public filtro_puntos_distribuidor: any;
  public filtro_puntos_organizacion: any;
  /** Modal generico */
  public modalCarga?: NgbModalRef;
  /** Evita que al hacer click por fuera se cierre el modal */
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };

  constructor(
    private ngxsStore: Store,
    private restService: RestService,
    private currency: CurrencyPipe,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private router: Router
  ) {
    this.data_bar_puntos_mes = {
      labels: [],
      datasets: [],
    };
    this.data_bar_puntos_distribuidor = {
      labels: [],
      datasets: [],
    };
    this.data_bar_puntos_organizacion = {
      labels: [],
      datasets: [],
    };
  }
  async ngOnChanges() {
    /** Modal generíco cargando... */
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    await this.getListaFiltroAnos();
    this.usuario_horeca = await this.ngxsStore.snapshot().auth.user_horeca;
    await this.configSmartTable();
    await this.getDataTablaResumenPuntosProductos();
    await this.cargarInformacionPuntosMes();
    await this.cargarInformacionPuntosDistribuidor();
    await this.cargarInformacionPuntosOrganizacion();
    this.modalCarga?.close();
  }
  async ngOnInit() {}

  /**
   * Arma lista de los últimos 10 años para el filtro de años
   */
  public getListaFiltroAnos() {
    const fecha_actual = new Date();
    const ano_actual = fecha_actual.getFullYear();
    for (let index = 0; index < 10; index++) {
      this.filtro_10_anos.push(ano_actual - index);
    }
    this.filtro_puntos_mes = this.filtro_10_anos[0];
    this.filtro_puntos_distribuidor = this.filtro_10_anos[0];
    this.filtro_puntos_organizacion = this.filtro_10_anos[0];
  }

  /**
   * Método para configurar la Nebular Smart Table
   * @returns void
   */
  private configSmartTable(): void {
    this.dataPuntos = {
      noDataMessage: 'No has ganado puntos Feat',
      pager: {
        display: true,
        perPage: 10,
      },
      actions: false,
      hideSubHeader: false,
      columns: {
        punto_entrega: {
          title: 'Punto Entrega',
          editable: false,
          filter: true,
        },
        distribuidor: {
          title: 'Distribuidor',
          editable: false,
          filter: true,
        },
        nit_cc: {
          title: 'NIT/CC/CE',
          editable: false,
          filter: true,
        },
        fecha: {
          title: 'Fecha pedido',
          editable: false,
          filter: true,
          sort: true,
        },
        pedidoId: {
          title: 'ID pedido',
          editable: false,
          filter: true,
        },
        subtotal: {
          title: 'Valor del pedido',
          editable: false,
          filter: true,
        },
        puntosAcumulados: {
          title: 'Puntos acumulados',
          editable: false,
          filter: true,
          sort: true,
        },
        descuento: {
          title: 'Descuento acumulado',
          editable: false,
          filter: true,
          sort: true,
        },
        estado_pedido: {
          title: 'Estado del pedido',
          editable: false,
          filter: true,
          sort: true,
        },
      },
    };
  }

  /**
   * Recupera la información para la la tabla resumen de puntos ganados por producto-punto entrega
   */
  public getDataTablaResumenPuntosProductos() {
    this.restService
      .getJWT('informes/puntos_feat/producto_establecimiento/' + this.idPunto)
      .toPromise()
      .then((resp: any) => {
        const fila_tabla: any[] = [];
        resp.forEach((puntos: any) => {
          const obj_data_puntos = {
            distribuidor: puntos.distribuidor,
            punto_entrega: puntos.punto,
            pedidoId: puntos._id,
            fecha: this.datePipe.transform(puntos.fecha, 'yyyy-MM-dd'),
            puntosAcumulados: puntos.acumulados,
            nit_cc: puntos.nit,
            subtotal: this.currency.transform(puntos.valor_pedido, '$ ', 'symbol', '1.0-0'),
            estado_pedido: puntos.estado,
            descuento: puntos.acumulados * puntos.valor_punto_feat,
          };
          fila_tabla.push(obj_data_puntos);
        });
        this.sourceDataPuntos = new LocalDataSource(fila_tabla);
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }

  /**
   * Recupera la informacion para la grafica de barras
   * de puntos ganados por mes y arma la tabla
   */
  public async cargarInformacionPuntosMes() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_puntos_mes) {
      this.bar_chart_puntos_mes.destroy();
      this.data_bar_puntos_mes.labels = [];
      this.data_bar_puntos_mes.datasets = [];
    }
    /** Se arma data para query (año seleccionado) */
    const first_day = this.filtro_puntos_mes + '-' + '01-01';
    const last_day = this.filtro_puntos_mes + '-' + '12-31';
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/puntos_feat/puntos_mes/${this.idPunto}/${first_day}/${last_day}`)
      .toPromise()
      .then((puntos_mes: any) => {
        puntos_mes.forEach((element: any) => {
          this.data_bar_puntos_mes.labels.push(
            this.meses[element._id.split('-')[1] - 1] + '-' + element._id.split('-')[0]
          );
          this.data_bar_puntos_mes.datasets.push(element.total);
        });
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
    this.bar_chart_puntos_mes = new Chart(this.bar_puntos_mes.nativeElement, {
      type: 'bar',
      data: {
        labels: this.data_bar_puntos_mes.labels,
        datasets: [
          {
            label: 'Puntos ganados por mes',
            backgroundColor: this.colors[0],
            data: this.data_bar_puntos_mes.datasets,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Puntos acumulados',
            },
            beginAtZero: true,
            ticks: {
              stepSize: 1,
            },
          },
          x: {
            title: {
              display: true,
              text: 'Mes',
            },
          },
        },
      },
    });
  }

  /**
   * Recupera la informacion para la grafica de barras
   * de puntos ganados por distribuidor y arma la tabla
   */
  public async cargarInformacionPuntosDistribuidor() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_puntos_distribuidor) {
      this.bar_chart_puntos_distribuidor.destroy();
      this.data_bar_puntos_distribuidor.labels = [];
      this.data_bar_puntos_distribuidor.datasets = [];
    }
    /** Se arma data para query (año seleccionado) */
    const first_day = this.filtro_puntos_distribuidor + '-' + '01-01';
    const last_day = this.filtro_puntos_distribuidor + '-' + '12-31';
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/puntos_feat/puntos_distribuidor/${this.idPunto}/${first_day}/${last_day}`)
      .toPromise()
      .then((puntos_distribuidor: any) => {
        puntos_distribuidor.forEach((element: any) => {
          this.data_bar_puntos_distribuidor.labels.push(element._id);
          this.data_bar_puntos_distribuidor.datasets.push(element.total);
        });
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
    this.bar_chart_puntos_distribuidor = new Chart(this.bar_puntos_distribuidor.nativeElement, {
      type: 'bar',
      data: {
        labels: this.data_bar_puntos_distribuidor.labels,
        datasets: [
          {
            label: 'Puntos ganados por distribuidor',
            backgroundColor: this.colors[0],
            data: this.data_bar_puntos_distribuidor.datasets,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Puntos redimidos',
            },
            beginAtZero: true,
            ticks: {
              stepSize: 1,
            },
          },
          x: {
            title: {
              display: true,
              text: 'Distribuidor',
            },
          },
        },
      },
    });
  }

  /**
   * Recupera la informacion para la grafica de barras
   * de puntos ganados por organización y arma la tabla
   */
  public async cargarInformacionPuntosOrganizacion() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_puntos_organizacion) {
      this.bar_chart_puntos_organizacion.destroy();
      this.data_bar_puntos_organizacion.labels = [];
      this.data_bar_puntos_organizacion.datasets = [];
    }
    /** Se arma data para query (año seleccionado) */
    const first_day = this.filtro_puntos_organizacion + '-' + '01-01';
    const last_day = this.filtro_puntos_organizacion + '-' + '12-31';
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/puntos_feat/puntos_organizacion/${this.idPunto}/${first_day}/${last_day}`)
      .toPromise()
      .then((puntos_organizacion: any) => {
        puntos_organizacion.forEach((element: any) => {
          this.data_bar_puntos_organizacion.labels.push(element._id);
          this.data_bar_puntos_organizacion.datasets.push(element.total);
        });
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
    this.bar_chart_puntos_organizacion = new Chart(this.bar_puntos_organizacion.nativeElement, {
      type: 'bar',
      data: {
        labels: this.data_bar_puntos_organizacion.labels,
        datasets: [
          {
            label: 'Puntos ganados por organización',
            backgroundColor: this.colors[0],
            data: this.data_bar_puntos_organizacion.datasets,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Puntos acumulados',
            },
            beginAtZero: true,
            ticks: {
              stepSize: 1,
            },
          },
          x: {
            title: {
              display: true,
              text: 'Organización',
            },
          },
        },
      },
    });
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio']);
    };
  }
}
