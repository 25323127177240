import { Component, Input, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest/rest.service';
import { Chart } from 'chart.js';
import { Store } from '@ngxs/store';
import { faCircle, faSearch, faTimesCircle, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { LocalDataSource } from 'ng2-smart-table';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-puntos-compras-organizacion',
  templateUrl: './puntos-compras-organizacion.component.html',
  styleUrls: ['./puntos-compras-organizacion.component.css'],
})
export class PuntosComprasOrganizacionComponent implements OnInit {
  public top_10_productos: any;
  public puntosUsados = 0;
  public puntosDisponibles = 0;
  public copyProductos: any;
  // Spinner progreso
  public progreso = 0;
  // Plan org
  public plan_actual: any;
  // Indicadores puntosFT
  public puntos_paquetes = 0;
  public puntos_disponibles = 0;
  public puntos_usados = 0;
  // Organización
  public data_organizacion = this.ngxsStore.snapshot().auth.user_organizacion;
  public id_organizacion: any = this.auth.user_organizacion._id;
  // Iconos
  public faSearch = faSearch;
  public faCircle = faCircle;
  public faTrash = faTrash;
  public faTimesCircle = faTimesCircle;
  public faEye = faEye;
  // Datos y configuracion de la tabla
  public settings_puntos_por_producto: any = {};
  public settings_puntos_mes: any = {};
  public settings_puntos_mes_marca: any = {};
  public settings_puntos_mes_categoria: any = {};
  // public settings_puntos_filtro_mes: any = {};
  public data_puntos_por_producto: any = [];
  public data_puntos_mes_actual: any = [];
  public data_puntos_filtro_mes: any = [];
  public source_puntos_por_producto?: LocalDataSource;
  public source_puntos_mes_actual?: LocalDataSource;
  public source_puntos_x_mes?: LocalDataSource;
  // Guarda el objeto con toda la data sin filtros
  public data_completa_mes: any;
  public data_completa_puntos_x_mes: any;
  // Filtro tablas
  public filtro_mes_actual = 'producto';
  public filtro_puntos_por_mes = 'producto';
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;
  // Lista de meses para apoyar el formateo de la fecha
  public anos_filtro: any = [];
  public filtro_24_meses: any = [];
  public meses: string[] = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  public filtro_puntos_mes: any;
  public restaPuntos = 0;
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
  };
  data_completa_trimestre: any;

  constructor(
    public rest: RestService,
    public ngxsStore: Store,
    public auth: AuthService,
    public router: Router,
    public datePipe: DatePipe,
    public modalService: NgbModal,
    private changeDetectorRef: ChangeDetectorRef
  ) {}
  async ngOnInit() {
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    this.id_organizacion = await this.ngxsStore.snapshot().auth.user_organizacion._id;
    this.getTopProductos();
    this.getListaFiltroMeses();
    try {
      await this.getPuntosFtPorProducto();
      await this.getPaquetePuntosFt();
      await this.getDataTablaMesActual();
      await this.getPlanAsociado();
      await this.configSmartTablePuntosFtMes();
      await this.getDataTablaFiltroMes();
      this.modalCarga?.close();
    } catch (error) {
      this.mostrarMensajeError();
    }
  }

  /**
   * Arma lista de los últimos 24 meses para el filtro de meses
   */
  getListaFiltroMeses() {
    const fecha_actual = new Date();
    let mes_actual = fecha_actual.getMonth();
    let ano_actual = fecha_actual.getFullYear();
    for (let index = 0; index < 23; index++) {
      if (mes_actual >= 0) {
        const data = {
          filtro: this.meses[mes_actual] + ', ' + ano_actual,
          filtro_inicio: this.meses[mes_actual - 2] + ', ' + ano_actual,
          crudo: Number(mes_actual + 1) + ',' + ano_actual + ',' + (mes_actual - 1) + ',' + ano_actual,
        };
        if (mes_actual <= 1) {
          data.filtro_inicio = this.meses[11 - (1 - mes_actual)] + ', ' + (ano_actual - 1);
          data.crudo = Number(mes_actual + 1) + ',' + ano_actual + ',' + (11 - mes_actual) + ',' + (ano_actual - 1);
        }
        this.filtro_24_meses.push(data);
        mes_actual = mes_actual - 1;
      } else {
        mes_actual = 11;
        ano_actual = ano_actual - 1;
      }
    }
    this.filtro_puntos_mes = this.filtro_24_meses[0].crudo;
  }


  /**
   * Recupera todos los indicadores de puntosFT de una organizacion
   */
  async getPaquetePuntosFt() {
    await this.rest
      .getJWT('bolsa_puntos_organizacion/balance/' + this.id_organizacion)
      .toPromise()
      .then((resp: any) => {
        if (resp[0].cant_paquetes.length > 0) {
          this.puntos_paquetes = resp[0].cant_paquetes[0].total ? resp[0].cant_paquetes[0].total : 0;
        }
        if (resp[0].puntos_disponibles.length > 0) {
          this.puntos_disponibles = resp[0].puntos_disponibles[0].total ? resp[0].puntos_disponibles[0].total : 0;
        }
        if (resp[0].puntos_usados.length > 0) {
          this.puntos_usados = resp[0].puntos_usados[0].total ? resp[0].puntos_usados[0].total : 0;
        }
        this.restaPuntos = this.puntos_disponibles - this.puntos_usados;
      })
      .catch((err) => {
        throw err;
      });
  }

  /**
   * Recupera el plan al cual se encuentra asociado la org.
   */
  private getPlanAsociado() {
    if (
      this.data_organizacion.tipo_plan != 'N/A' &&
      this.data_organizacion.tipo_plan != '' &&
      this.data_organizacion.tipo_plan != undefined
    ) {
      this.rest
        .getJWT('planes-membresias/activos/organizacion/')
        .toPromise()
        .then((resp: any) => {
          this.plan_actual = resp.find((plan: any) => {
            return plan._id == this.data_organizacion.tipo_plan;
          });
          this.progreso = Math.round((this.puntos_usados / this.plan_actual.hasta) * 100) / 100;
        })
        .catch((err) => {
          throw err;
        });
    }
  }

  /**
   * Establece la configuracion de la ng smart table
   */
  public configSmartTablePuntosFtMes(): void {
    // Tabla puntos FT por productos
    this.settings_puntos_por_producto = {
      noDataMessage: 'Cargando datos...',
      pager: {
        display: true,
        perPage: 10,
      },
      actions: {
        add: false,
        edit: false,
        delete: false,
      },
      hideSubHeader: false,
      columns: {
        idProducto: {
          title: 'Acción',
          type: 'custom',
          editable: false,
          filter: false,
          renderComponent: BtnEditarPedidoDist,
          valuePrepareFunction: (value: any, row: any) => {
            return { 
              idProducto: value, 
              nombreProducto: row.nombreProducto, 
              copyProductos: this.copyProductos // Pasar copyProductos aquí
            };
          }
        },
        nombreProducto: {
          title: 'Producto',
          editable: false,
          filter: true,
        },
        puntosOfrecidos: {
          title: 'Puntos asignados',
          editable: false,
          filter: true,
        },
        puntosFTConsumidos:{
          title: 'Puntos acumulados por la compra del producto',
          editable: false,
          filter: true,
        },
        fechainicioPunto: {
          title: 'Fecha de inicio',
          editable: false,
          filter: true,
        },
        fechaFinPunto: {
          title: 'Fecha de cierre',
          editable: false,
          filter: true,
        },
      },
    };
    // Tabla para productos
    this.settings_puntos_mes = {
      noDataMessage: 'Cargando datos...',
      pager: {
        display: true,
        perPage: 10,
      },
      actions: {
        add: false,
        edit: false,
        delete: false,
      },
      hideSubHeader: false,
      columns: {
        idProducto: {
          title: 'Acción',
          type: 'custom',
          editable: false,
          filter: false,
          renderComponent: BtnEditarPedidoDist,
          valuePrepareFunction: (value: any, row: any) => {
            return { 
              idProducto: value, 
              nombreProducto: row.nombreProducto, 
              copyProductos: this.copyProductos // Pasar copyProductos aquí
            };
          }
        },
        nombreProducto: {
          title: 'Producto',
          editable: false,
          filter: true,
        },
        puntosOfrecidos: {
          title: 'Puntos asignados por compra',
          editable: false,
          filter: true,
        },
        puntosFTConsumidos: {
          title: 'Puntos acumulados por la compra del producto',
          editable: false,
          filter: true,
        },
        fechainicioPunto: {
          title: 'Fecha de inicio',
          editable: false,
          filter: true,
        },
        fechaFinPunto: {
          title: 'Fecha de cierre',
          editable: false,
          filter: true,
        },
      },
    };
    // Tabla marcas
    this.settings_puntos_mes_marca = {
      noDataMessage: 'Cargando datos...',
      pager: {
        display: true,
        perPage: 10,
      },
      actions: false,
      hideSubHeader: false,
      columns: {
        nombreMarca: {
          title: 'Nombre marca',
          editable: false,
          filter: true,
        },
        puntosFTConsumidos: {
          title: 'Puntos acumulados por la compra del producto',
          editable: false,
          filter: true,
        },
      },
    };
    // Tabla categorías
    this.settings_puntos_mes_categoria = {
      noDataMessage: 'Cargando datos...',
      pager: {
        display: true,
        perPage: 10,
      },
      actions: false,
      hideSubHeader: false,
      columns: {
        nombreCat: {
          title: 'Nombre categoría',
          editable: false,
          filter: true,
        },
        puntosFTConsumidos: {
          title: 'Puntos acumulados por la compra del producto',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /**
   * Recupera toda la data de las tablas de mes actual
   * viene la data de los tres filtros en la misma consulta
   */
  public async getPuntosFtPorProducto() {
    await this.rest
       //.getJWT(`productos_por_organizacion/puntos_feat/${this.id_organizacion}`)
       .getJWT(`productos_por_organizacion/componente/puntos_feat_new2/${this.id_organizacion}/0/0`)
      .toPromise()
      .then((productos: any) => {
        let suma = 0
        productos.forEach((producto: any) => {
          suma = suma+producto.puntosFTConsumidos;
          producto.fechainicioPunto = moment( producto.fechainicioPunto).format('YYYY-MM-DD')
          producto.fechaFinPunto = moment( producto.fechaFinPunto).format('YYYY-MM-DD')
        });
        this.data_puntos_por_producto = productos;
        this.copyProductos = productos;

        this.source_puntos_por_producto = new LocalDataSource(productos);
        this.puntos_usados  = suma;
      });
      this.changeDetectorRef.detectChanges();

  }

  /**
   * Recupera toda la data de las tablas de mes actual
   * viene la data de los tres filtros en la misma consulta
   */
  public async getDataTablaMesActual() {
    let mes = new Date().getMonth() + 1;
    let fecha_actual = `${new Date().getFullYear()}-${mes}`;
    if(mes < 10){
      fecha_actual = `${new Date().getFullYear()}-0${mes}`;
     }else{
      fecha_actual = `${new Date().getFullYear()}-${mes}`;
     }
    await this.rest
      .getJWT(
        `productos_por_organizacion/componente/puntos_feat_new/${this.id_organizacion}/${fecha_actual}-01/${fecha_actual}-31`
      )
      .toPromise()
      .then((resp: any) => {
        if (resp) {
          this.data_completa_mes = resp[0];
          setTimeout(() => {
            this.armaTablaMesActual();
          }, 3000);
        }
      });
  }

  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   * Los datos a cargas serán los que corresponden al filtro aplicado
   */
  public async armaTablaMesActual() {
    let data_tabla: any;
    this.data_puntos_mes_actual = [];
    // Dependiendo del filtro seleccionado carga la tabla requerida
    if (this.filtro_mes_actual === 'producto') {
      data_tabla = this.data_completa_mes.producto;
    } else if (this.filtro_mes_actual === 'marca') {
      data_tabla = this.data_completa_mes.marca;
    } else if (this.filtro_mes_actual === 'categoría') {
      data_tabla = this.data_completa_mes.categoria;
    } else {
      return;
    }
    data_tabla.forEach((elemento: any) => {
      const fechaInicio =  elemento.fechainicioPunto;
      const fechaMoment = moment(fechaInicio);
      fechaMoment.add(1, 'day');
      const fechaFin =  elemento.fechaFinPunto;
      const fechaMomentFin = moment(fechaFin);
      fechaMomentFin.add(1, 'day');
      const fechaInicial = fechaMoment.format('YYYY-MM-DD');
      const fechaFinal = fechaMomentFin.format('YYYY-MM-DD');
      elemento.fechainicioPunto = fechaInicial;
      elemento.fechaFinPunto = fechaFinal;

    });
    this.data_puntos_mes_actual = data_tabla;
    this.source_puntos_mes_actual = new LocalDataSource(this.data_puntos_mes_actual);
  }

  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  public getDataTablaFiltroMes(): void {
    let first_day
    let last_day
    if(this.filtro_puntos_mes.split(',')[2] < 10){
      first_day = this.filtro_puntos_mes.split(',')[3] + '-0' + this.filtro_puntos_mes.split(',')[2] + '-01';
     }else{
      first_day = this.filtro_puntos_mes.split(',')[3] + '-' + this.filtro_puntos_mes.split(',')[2] + '-01';
     }

     if(this.filtro_puntos_mes.split(',')[0] < 10){
      last_day = this.filtro_puntos_mes.split(',')[1] + '-0' + this.filtro_puntos_mes.split(',')[0] + '-31';
    }else{
      last_day = this.filtro_puntos_mes.split(',')[1] + '-' + this.filtro_puntos_mes.split(',')[0] + '-31';
    }
    /** Se arma data para query */

    this.rest
      .getJWT(`productos_por_organizacion/componente/puntos_feat_new/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((resp: any) => {
        this.data_completa_trimestre = resp[0];
        this.armaTablaPuntosPorMes();
      });
  }
  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   * Los datos a cargas serán los que corresponden al filtro aplicado
   */
  public async armaTablaPuntosPorMes() {
    let data_tabla: any;
    this.data_completa_puntos_x_mes = [];
    // Dependiendo del filtro seleccionado carga la tabla requerida
    if (this.filtro_puntos_por_mes === 'producto') {
      data_tabla = this.data_completa_trimestre.producto;
    } else if (this.filtro_puntos_por_mes === 'marca') {
      data_tabla = this.data_completa_trimestre.marca;
    } else if (this.filtro_puntos_por_mes === 'categoría') {
      data_tabla = this.data_completa_trimestre.categoria;
    } else {
      return;
    }
    data_tabla.forEach((elemento: any) => {
      const fechaInicio =  elemento.fechainicioPunto;
      const fechaMoment = moment(fechaInicio);
      fechaMoment.add(1, 'day');
      const fechaFin =  elemento.fechaFinPunto;
      const fechaMomentFin = moment(fechaFin);
      fechaMomentFin.add(1, 'day');
      const fechaInicial = fechaMoment.format('YYYY-MM-DD');
      const fechaFinal = fechaMomentFin.format('YYYY-MM-DD');
      elemento.fechainicioPunto = fechaInicial;
      elemento.fechaFinPunto = fechaFinal;
    });
    this.data_completa_puntos_x_mes = data_tabla;
    this.source_puntos_x_mes = new LocalDataSource(this.data_completa_puntos_x_mes);
  }

  /**
   * Recupera el top 10 de productos vendidos
   */
  async getTopProductos() {
    const respTopProductos: any = await this.rest
      .getJWT(`organizacion/portafolio/top_10_productos/${this.id_organizacion}`)
      .toPromise();
    this.top_10_productos = respTopProductos.data;
  }
  /**
   * Manejo de errores por fallo en peticiones al back
   */
  public mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio-organizacion']);
    };
  }
}
@Component({
  selector: 'app-btn-crear-saldo',
  template: `
    <button class="btn-purple-trans" (click)="verDetalleProducto()">Ver</button>
  `,
  styles: [
    `
      .btn-purple-trans {
        border: none;
        background-color: transparent;
        color: #8e6ff7;
      }
    `,
  ],
})
export class BtnEditarPedidoDist {
  @Input()
  value!: { idProducto: any; nombreProducto: any; copyProductos: any[] };

  constructor(public router: Router) {
  }
  
  verDetalleProducto() {
    if(!this.value.nombreProducto){
      this.router.navigate([`/detalle-producto-organizacion/${this.value}/puntos`]);
    }else{
     for(let item of this.value.copyProductos){
      if(item.nombreProducto === this.value.nombreProducto){
        this.router.navigate([`/detalle-producto-organizacion/${item.idProducto}/puntos`]);

      }
     }
    }

  }
}
