<app-header-distribuidor></app-header-distribuidor>
<br />
<div class="d-flex justify-content-center">
  <div class="contenedor">
    <!--Volver-->
    <div class="volver-container d-flex flex-row w-100 pb-5">
      <div class="col-2 p-0">
        <button class="boton-volver" routerLink="/portafolio">
          <fa-icon [icon]="faChevronLeft"></fa-icon>
          Volver
        </button>
      </div>
      <div class="col-9 d-flex align-items-center">
        <h2 class="d-flex align-items-center p-0">Agregar producto</h2>
      </div>
    </div>
    <!--Banner-->
    <div class="row">
      <div class="col-12">
        <p>
          Nombra producto bajo la siguiente estructura e instrucciones. Si no cumple con los requisitos, no podremos
          aprobarlo en el sistema.
        </p>
        <div class="overflow-hidden">
          <div class="img-scroll">
            <img src="../../../assets/img/banner-agregar-producto.png" class="banner-img"
              alt="banner indicando nombre, marca y gramaja de los productos" />
          </div>
        </div>
      </div>
    </div>
    <div class="card bottom-container mt-4 m-0 p-5">
      <div class="mb-1 bottom-form " [formGroup]="distribuidorForm">
        <div class="informacion-titulo w-100">
          <h5 class="mb-0 mt-3">Información del producto</h5>
        </div>
        <!--Nombre del producto-->
        <div class="nombre-producto">
          <label class="d-flex flex-row justify-content-between">
            <span>
              <span class="asterisco">*</span>Nombre del producto
            </span>
            <span class="text-secondary" id="characterCount"> {{remainingTextNombre}}/50</span>
          </label>
          <textarea #text_nombre formControlName="distribuidorProductoNombre" name="nombre" placeholder="Escribe aquí"
            required="true" value="" maxlength="49" data-attr="49" rows="1" class="p-3"
            (keydown)="valueChange(text_nombre.value, 'nombre')" (change)="valueChange(text_nombre.value, 'nombre')"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorProductoNombre')?.hasError('required') && distribuidorForm.get('distribuidorProductoNombre')?.touched || ( distribuidorForm.get('distribuidorProductoNombre')?.hasError('pattern') && distribuidorForm.get('distribuidorProductoNombre')?.touched ) )}">
          </textarea>
        </div>
        <!--Organización-->
        <div class="organizacion">
          <label for="organizacion"><span class="asterisco">*</span>Organización</label>
          <input formControlName="distribuidorOrganizacion" name="organizacion_manual" type="text"
            placeholder="Escribe aquí"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorOrganizacion')?.hasError('required') && distribuidorForm.get('distribuidorOrganizacion')?.touched )}" />
        </div>
        <!--Marca-->
        <div class="marca-producto">
          <label for="marca"><span class="asterisco">*</span>Marca</label>
          <input formControlName="distribuidorMarca" name="marca_manual" type="text"
            placeholder="Escribe aquí"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorMarca')?.hasError('required') && distribuidorForm.get('distribuidorMarca')?.touched )}" />
        </div>
        <!--Categoría-->
        <div class="categoria">
          <label for="categoria"><span class="asterisco">*</span>Categoría</label>
          <input formControlName="distribuidorCategoria" name="categoria_manual" type="text"
            placeholder="Escribe aquí"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorCategoria')?.hasError('required') && distribuidorForm.get('distribuidorCategoria')?.touched )}" />
        </div>
        <!--Línea de producto-->
        <div class="linea">
          <label for="linea"><span class="asterisco">*</span>Línea de producto</label>
          <input formControlName="distribuidorLinea" name="linea_manual" type="text"
            placeholder="Escribe aquí"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorLinea')?.hasError('required') && distribuidorForm.get('distribuidorLinea')?.touched )}" />
        </div>
        <!--Unidad de Medida-->
        <div class="unidad-medida h-100">
          <label for="unidad-medida"><span class="asterisco">*</span>Unidad de Medida</label>
          <select formControlName="distribuidorUnidadMedida" class="dropdown-form-1 d-inline-block"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorUnidadMedida')?.hasError('required') && distribuidorForm.get('distribuidorUnidadMedida')?.touched )}">
            <option disabled value="">Selecciona</option>
            <option value="kg">kg</option>
            <option value="lb">lb</option>
            <option value="g">g</option>
            <option value="L">L</option>
            <option value="ml">ml</option>
            <option value="und">und</option>
            <option value="otro">Otro</option>
          </select>
        </div>
        <!--Cantidad de medida-->
        <div class="cantidad-medida h-100">
          <label for="cantidad_medida"><span class="asterisco">*</span>Cantidad de medida</label>
          <input formControlName="distribuidorCantidadMedida" name="cantidad_medida" type="number"
            placeholder="Escribe aquí" (keydown)="validateNumber($event)"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorCantidadMedida')?.hasError('required') && distribuidorForm.get('distribuidorCantidadMedida')?.touched )}" />
        </div>
        <!--Presentación Und. X caja-->
        <div class="Presentacion-caja h-100">
          <label for="presentacion_caja"><span class="asterisco">*</span>Presentación Und. X caja</label>
          <input formControlName="distribuidorPresentacionCaja" name="presentacion_caja" type="number"
            placeholder="Escribe aquí" (keydown)="validateNumber($event)"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorPresentacionCaja')?.hasError('required') && distribuidorForm.get('distribuidorPresentacionCaja')?.touched )}" />
        </div>
        <!--Código producto-->
        <div class="codigo-producto h-100">
          <label for="cantidad_medida"><span class="asterisco">*</span>Código producto</label>
          <input formControlName="distribuidorCodigoProducto" name="cantidad_medida" type="text"
            placeholder="Escribe aquí"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorCodigoProducto')?.hasError('required') && distribuidorForm.get('distribuidorCodigoProducto')?.touched )}" />
        </div>
        <!--Descripción-->
        <div class="descripcion-producto">
          <label for="descripcion" class="d-flex flex-row justify-content-between">
            <span>
              <span class="asterisco">*</span>Descripción
            </span>
            <span id="characterCount" class="text-secondary"> {{remainingTextDescripcion}}/300</span>
          </label>
          <textarea #text formControlName="distribuidorProductoDescripcion" name="descripcion"
            placeholder="Escribe aquí" (keydown)="valueChange(text.value, 'descripcion')"
            (change)="valueChange(text.value, 'descripcion')" required="true" value="" maxlength="299"
            data-attr="299" rows="3" class="p-3"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorProductoDescripcion')?.hasError('required') && distribuidorForm.get('distribuidorProductoDescripcion')?.touched || ( distribuidorForm.get('distribuidorProductoDescripcion')?.hasError('pattern') && distribuidorForm.get('distribuidorProductoDescripcion')?.touched ) )}">
            </textarea>
        </div>
        <!--Inventario-->
        <div class="w-100 inventario-titulo">
          <h6 class="mt-3 mb-0">
            Inventario inicial
          </h6>
          <p class="m-0">
            Escribe el número o utiliza las flechas
          </p>
        </div>
        <!--Precio por unidad-->
        <div class="editar-inventario">
          <div class="row m-0">
            <!--Unidades-->
            <div class="col-lg-3 col-md-4 col-sm-5 col-6 p-0">
              <p>
                Unidades
              </p>
              <div>
                <button class="btn_cantidad btn_menos"
                  (click)="distribuidorForm.patchValue({distribuidorInventarioUnidad: this.distribuidorForm.get('distribuidorInventarioUnidad')?.value - 1})"
                  [disabled]="this.distribuidorForm.get('distribuidorInventarioUnidad')?.value <= 0">
                  -
                </button>
                <input class="span_cnt" type="number" formControlName="distribuidorInventarioUnidad" minlength="1" min=0/>
                <button class="btn_cantidad btn_mas"
                  (click)="distribuidorForm.patchValue({distribuidorInventarioUnidad: this.distribuidorForm.get('distribuidorInventarioUnidad')?.value + 1})">
                  +
                </button>
              </div>
            </div>
            <!--Cajas-->
            <div class="col-lg-3 col-md-4 col-sm-5 col-6 p-0">
              <p>
                Cajas
              </p>
              <div>
                <input class="span_cnt_caja" type="number" formControlName="distribuidorInventarioCaja"
                  [attr.disabled]="true" />
              </div>
            </div>
          </div>
          <div class="col-12 text-secondary p-0 mt-2"
            *ngIf="distribuidorForm.get('distribuidorPresentacionCaja')?.value && distribuidorForm.get('distribuidorInventarioUnidad')?.value">
            {{unidades_pendientes_caja}} und. pend. para completar otra caja
          </div>
        </div>
        <!--Precios-->
        <div class="w-100 precio-titulo">
          <h5 class="mt-3 mb-0">
            Precios
          </h5>
        </div>
        <!--Precio por unidad-->
        <div class="precio-unidad">
          <label><span class="asterisco">*</span>Precio por unidad</label>
          <input formControlName="distribuidorPrecioUnidad" (keydown)="validateNumber($event)"
            (blur)="transformAmount($event.target, 'unidad')" (focus)="transformCurrency($event.target, 'unidad')"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorPrecioUnidad')?.hasError('required') && distribuidorForm.get('distribuidorPrecioUnidad')?.touched )}"
            name="tipo documento" placeholder="Escribe aquí" />
        </div>
        <!--Precio por caja-->
        <div class="precio-caja">
          <label><span class="asterisco">*</span>Precio por caja</label>
          <input formControlName="distribuidorPrecioCaja" [attr.disabled]="true"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorPrecioCaja')?.hasError('required') && distribuidorForm.get('distribuidorPrecioCaja')?.touched )}"
            name="tipo documento" placeholder="Escribe aquí" />
        </div>
        <!--Precio por unidad de medida-->
        <div class="precio-unidad-medida">
          <label><span class="asterisco">*</span>Precio por unidad de medida</label>
          <input formControlName="distribuidorPrecioUnidadMedida" [attr.disabled]="true"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorPrecioUnidadMedida')?.hasError('required') && distribuidorForm.get('distribuidorPrecioUnidadMedida')?.touched )}"
            name="tipo documento" placeholder="Escribe aquí" />
        </div>
        <!--Titulo imagenes-->
        <div class="w-100 titulo-imagenes">
          <h5 class="mt-3 mb-0">
            Cargar imágenes
          </h5>
        </div>
        <!--Imagenes-->
        <div class="imagenes-cargadas w-100 overflow-hidden">
          <div class="w-100 mb-3">
            <input type="file" class="file-upload" (change)="handleFileInput($event)" #fileUpload />
            <button class="btn-white" (click)="fileUpload.click()" *ngIf="imgs_path.length < 3">
              + Agregar foto
            </button>
          </div>
          <div class="img-wrap d-flex flex-row">
            <div class="img-container" *ngFor="let path of imgs_path; index as i">
              <fa-icon [icon]="faTimesCircle" size="sm" class="btn_delete" (click)="eliminarImg(i)"
                (click)="fileUpload.value = ''">
              </fa-icon>
              <img class="img_prod w-100" [src]="path" alt="">
            </div>
          </div>
          <div class="w-100 my-2">
            <p *ngIf="error_formato_img" class="text-danger">
              Los archivos que subas deben ser de tipo imagen, como .png o .jpg
            </p>
            <p *ngIf="imgs_path.length === 0 && distribuidorForm.get('distribuidorPrecioUnidadMedida')?.touched"
              class="text-danger">
              Te recomendamos cargar al menos una imágen, sin embargo, podrías actualizarlo luego.
            </p>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <button class="btn-submit px-5" (click)="!distribuidorForm.invalid && guardarCambios()"
          (click)="distribuidorForm.invalid && alertaFormularioInvalido()"
          [ngClass]="{'btn-submit-disabled': distribuidorForm.invalid}">
          Guardar cambios
        </button>
      </div>
    </div>
  </div>