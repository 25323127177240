import { ElementRef, Injectable } from '@angular/core';
import { Chart } from 'chart.js';

@Injectable({
  providedIn: 'root',
})
export class GraficasService {
  /** Colores para las gráficas */
  public colors = [
    'rgba(115, 113, 156, 1)',
    'rgba(199, 195, 239, 1)',
    'rgba(247, 234, 194, 1)',
    'rgba(250, 211, 157, 1)',
    'rgba(179, 179, 210, 1)',
    'rgba(142, 111, 247, 1)',
  ];
  constructor() {}

  /**
   * Crea la grafica de ChartJS de tipo barra
   * @param elem ElementRef del DOM donde se cargará la grafica
   * @param data Información que alimentará la grafica
   * @param title Titulo de la grafica
   * @param y_axis_label Titulo de los datos en el eje y
   * @param x_axis_label Titulo de los datos en el eje x
   * @returns La grafica creada que se cargará en el DOM
   */
  createBarChart(elem: ElementRef, data: any, title: string, y_axis_label: string, x_axis_label: string) {
    return new Chart(elem.nativeElement, {
      type: 'bar',
      data: {
        labels: data.labels,
        datasets: [
          {
            label: title,
            backgroundColor: this.colors[0],
            data: data.datasets,
          },
        ],
      },
      options: {
        responsive: true,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          y: {
            display: true,
            title: {
              display: true,
              text: y_axis_label,
            },
            ticks: {
              stepSize: 1,
            },
          },
          x: {
            display: true,
            title: {
              display: true,
              text: x_axis_label,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.label;
                const value = context.formattedValue;
                let sum = 0;
                const dataArr = context.dataset.data;
                dataArr.map((data: any) => {
                  sum += data;
                });
                const percentage = ((+value.replace(/,/g, '') * 100) / sum).toFixed(2) + '%';
                return [label, value, percentage];
              },
            },
          },
        },
      },
    });
  }
  createBarChartMultiple(
    elem: ElementRef,
    data: any,
    unidades_vendidas: any,
    unidades_ofrecidas: any,
    title: string,
    y_axis_label: string,
    x_axis_label: string
  ) {
    return new Chart(elem.nativeElement, {
      type: 'bar',
      data: {
        labels: data,
        datasets: [
          {
            label: 'Unidades ofrecidas',
            backgroundColor: '#8E6FF7',
            data: unidades_ofrecidas,
          },
          {
            label: 'Unidades vendidas',
            backgroundColor: '#DADBF0',
            data: unidades_vendidas,
          },
        ],
      },
      options: {
        responsive: true,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          y: {
            display: true,
            title: {
              display: true,
              text: y_axis_label,
            },
            /*ticks: {
              stepSize: 1,
            },*/
          },
          x: {
            display: true,
            title: {
              display: true,
              text: x_axis_label,
            },
          },
        },

        plugins: {
          legend: {
            display: true,
          },
          title: {
            display: true,
            //text: 'Chart.js Bar Chart - Stacked',
          },
          /*tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.label;
                const value = context.formattedValue;
                let sum = 0;
                const dataArr = context.dataset.data;
                dataArr.map((data: any) => {
                  sum += data;
                });
                const percentage = ((+value.replace(/,/g, '') * 100) / sum).toFixed(2) + '%';
                return [label, value, percentage];
              },
            },
          },*/
        },
      },
    });
  }
  /**
   * Crea la grafica de ChartJS de tipo torta
   * @param elem ElementRef del DOM donde se cargará la grafica
   * @param data Información que alimentará la grafica
   * @param title Titulo de la grafica
   * @returns La grafica creada que se cargará en el DOM
   */
  createPieChart(elem: ElementRef, data: any, title: string) {
    return new Chart(elem.nativeElement, {
      type: 'doughnut',
      data: {
        labels: data.labels,
        datasets: [
          {
            backgroundColor: this.colors.slice(1, this.colors.length - 1),
            label: title,
            data: data.datasets,
          },
        ],
      },
      options: {
        responsive: true,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          legend: {
            position: 'top',
            align: 'center',
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.label;
                const value = context.formattedValue;
                let sum = 0;
                const dataArr = context.dataset.data;
                dataArr.map((data: any) => {
                  sum += data;
                });
                const percentage = ((+value.replace(/,/g, '') * 100) / sum).toFixed(2) + '%';
                return [label, value, percentage];
              },
            },
          },
        },
      },
    });
  }
}
