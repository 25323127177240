<div class="container-fluid overflow-hidden d-flex flex-column justify-content-around align-items-center"
  id="ultimas-noticias">
  <div class="row m-4 w-100">
    <div class="col-12 col-md-8 text-left pl-lg-5">
      <h1>Últimas noticias</h1>
    </div>
    <div class="col-12 col-md-4 text-right btn-container pr-lg-5">
      <button id="btn-todas" routerLink="/blog">
        Ver todas
      </button>
    </div>
  </div>
  <div class="d-flex flex-wrap">
    <div class="row m-0 d-fex justify-content-around col-12 col-md-6 px-3" *ngFor="let noticia of filteredNoticias">
      <div class="card d-flex flex-row m-0 h-100 w-100 cursorPointer" (click)="verDocumento(noticia.url)">
        <div class="notificacion-img m-0">
          <img [src]="noticia.imagen" class="img-notification" [alt]="'noticia del ' + noticia.site" />
        </div>
        <div class="card-notice">
          <div class="m-3">
            <div class="specification text-center">Forbes</div>
            <div class="mt-2">
              <p>{{noticia.titulo}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>