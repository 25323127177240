<div class="row m-0">
  <div class="col-12  col-lg-4 d-flex align-items-center justify-content-start titulo">
    <h5 class="m-0">
      Mis productos en inventario
    </h5>
  </div>
  <div class="col-12 col-md-6 col-lg-4 botones-container">
    <button class="btn-blanco" (click)="descargarLista()">
      Descargar formato de xls
    </button>
  </div>
  <div class="col-12 col-md-6 col-lg-4 botones-container">
    <button class="btn-morado" (click)="openModalCargarExcel()">
      Actualizar desde xls
    </button>
  </div>
</div>
<br />
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <ng2-smart-table [settings]="settings" [source]="source">
        </ng2-smart-table>
      </div>
    </div>
  </div>
</div>