<app-header-distribuidor></app-header-distribuidor>
<br />
<br />
<div class="row">
  <div class="col-1"></div>
  <div class="col-10">
    <!--Header-->
    <div class="row">
      <div class="col-2">
        <button class="boton-volver" routerLink="/selecciona-plan">
          <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }"></fa-icon>
          &nbsp;Volver
        </button>
      </div>
      <div class="col-5">
        <h3>Actualiza tu plan</h3>
      </div>
      <div class="col-5 text-right">
        <button id="btn-wa">
          &nbsp; &nbsp;
          <fa-icon [icon]="faWhatsapp" [styles]="{ color: '#FFFFFF' }"></fa-icon>
          &nbsp; Necesito ayuda de Feat &nbsp; &nbsp;
        </button>
      </div>
    </div>
  </div>
  <div class="col-1"></div>
</div>
<br />
<div class="row">
  <div class="col-1"></div>
  <!--Plan seleccionado -->
  <div class="col-md-3 col-sm-6">
    <app-planes-card [plan]="plan" *ngIf="plan"></app-planes-card>
  </div>
  <!--Info de pago-->
  <div class="col-6">
    <div id="card-pago">
      <!--Título-->
      <div class="row">
        <div class="col-12">
          <h4>Pago</h4>
          A continuación se dirigirá a la pasarela de pagos para hacer la transacción.
          <br />
          Recibimos los siguientes métodos de pagos:
          <br />
          <ul>
            <li>Tarjeta de crédito/débito</li>
            <li>Nequi</li>
            <li>PSE</li>
            <li>Efectivo</li>
            <li>Botón Bancolombia</li>
          </ul>
        </div>
      </div>
      <br />
      <!--Continuar-->
      <div class="row">
        <div class="col-4">
          <button class="btn-purple" (click)="startPayment()">Continuar</button>
        </div>
      </div>
    </div>
  </div>
</div>
<br />
<br />
<br />
