<div class="contenedor">
  <div class="contenido">
    <div>
      <h5>Selecciona las ciudades para filtrar resultados</h5>
      <fa-icon [icon]="faTimes" [styles]="{ color: '#41403E' }" id="fa-icon" (click)="close()"></fa-icon>
    </div>
    <!-- Seleccionar departamento -->
    <span>Departamento</span>
    <select name="select" (change)="fetchCiudades()" [(ngModel)]="departamento_seleccionado" *ngIf="deptos">
      <option *ngFor="let departamento of departamentos; index as i" [value]="departamento.code">
        {{ departamento.name }}
      </option>
    </select>
    <!-- Seleccionar ciudad -->
    <span>Ciudad</span>
    <select name="select" [(ngModel)]="ciudadSelect" (change)="agregarCiudad()">
      <option *ngFor="let ciudad of ciudades; index as i" [value]="ciudad.name">
        {{ ciudad.name }}
      </option>
    </select>
    <!-- Seleccionados -->
    <div class="ciudadesSeleccionadas">
      <span *ngFor="let ciudad of ciudades_seleccionadas">
        {{ ciudad }}
        <fa-icon [icon]="faTimesCircle" [styles]="{ color: '#000' }" id="fa-icon" (click)="quitarCiudad(ciudad)">
        </fa-icon>
      </span>
    </div>
  </div>
  <!-- Salir -->
  <div>
    <button (click)="done()">Listo</button>
  </div>
</div>