import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Chart } from 'chart.js';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GraficasService } from 'src/app/services/graficas-tablas/graficas.service';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-graficas-ventas-organizaciones',
  templateUrl: './graficas-ventas-organizaciones.component.html',
  styleUrls: ['./graficas-ventas-organizaciones.component.css'],
})
export class GraficasVentasOrganizacionesComponent implements OnInit {
  /** ID organizacion */
  public id_organizacion = this.auth.user_organizacion._id;
  /** Lista de meses para apoyar el formateo de la fecha */
  public filtro_10_anios: any = [];
  public filtro_24_meses: any = [];
  private meses: string[] = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  private meses_largo: string[] = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  /** Colores para las gráficas */
  public colors = [
    'rgba(115, 113, 156, 1)',
    'rgba(199, 195, 239, 1)',
    'rgba(247, 234, 194, 1)',
    'rgba(250, 211, 157, 1)',
    'rgba(179, 179, 210, 1)',
    'rgba(142, 111, 247, 1)',
  ];
  /** Modal de carga para darle feedback al usuario */
  public modalCarga?: NgbModalRef;
  /** Evita que al hacer click por fuera se cierre el modal */
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };
  /** Biding HTML-Canvas y TS */
  @ViewChild('bar_ventas_mes', { static: true })
  private bar_ventas_mes!: ElementRef;
  @ViewChild('bar_ventas_negocio', { static: true })
  private bar_ventas_negocio!: ElementRef;
  @ViewChild('bar_ventas_distribuidor', { static: true })
  private bar_ventas_distribuidor!: ElementRef;
  @ViewChild('bar_ventas_categoria', { static: true })
  private bar_ventas_categoria!: ElementRef;
  @ViewChild('bar_ventas_marca', { static: true })
  private bar_ventas_marca!: ElementRef;
  @ViewChild('bar_ventas_ciudad', { static: true })
  private bar_ventas_ciudad!: ElementRef;
  @ViewChild('bar_numero_pedidos', { static: true })
  private bar_numero_pedidos!: ElementRef;
  @ViewChild('bar_numero_referencia', { static: true })
  private bar_numero_referencia!: ElementRef;
  @ViewChild('bar_ventas_sillas', { static: true })
  private bar_ventas_sillas!: ElementRef;
  @ViewChild('bar_ventas_domicilios', { static: true })
  private bar_ventas_domicilios!: ElementRef;
  /** Guarda datos de la talba y configuraciones */
  private data_bar_ventas_mes: any;
  private data_bar_ventas_negocio: any;
  private data_bar_ventas_distribuidor: any;
  private data_bar_ventas_categoria: any;
  private data_bar_ventas_marca: any;
  private data_bar_ventas_ciudad: any;
  private data_bar_numero_pedidos: any;
  private data_bar_numero_referencia: any;
  private data_bar_ventas_sillas: any;
  private data_bar_ventas_domicilios: any;
  private bar_chart_ventas_mes: any;
  private bar_chart_ventas_negocio: any;
  private bar_chart_ventas_distribuidor: any;
  private bar_chart_ventas_categoria: any;
  private bar_chart_ventas_marca: any;
  private bar_chart_ventas_ciudad: any;
  private bar_chart_numero_pedidos: any;
  private bar_chart_numero_referencia: any;
  private bar_chart_ventas_sillas: any;
  private bar_chart_ventas_domicilios: any;
  public filtro_ventas_mes: any;
  public filtro_ventas_negocio: any;
  public filtro_ventas_distribuidor: any;
  public filtro_ventas_categoria: any;
  public filtro_ventas_marca: any;
  public filtro_ventas_ciudad: any;
  public filtro_ventas_numero_pedidos: any;
  public filtro_anio_numero_referencias: any;
  public filtro_ventas_numero_sillas: any;
  public filtro_ventas_domicilio: any;
  /** Total tabla */
  public total_ventas_mes: any;
  public total_ventas_negocio: any;
  public total_ventas_distribuidor: any;
  public total_ventas_categoria: any;
  public total_ventas_marca: any;
  public total_ventas_ciudad: any;
  public total_ventas_numero_pedidos = 0;
  public total_ventas_numero_referencias = 0;
  public total_ventas_numero_sillas: any;
  public total_ventas_domicilio: any;

  constructor(
    private graficasService: GraficasService,
    private rest: RestService,
    private auth: AuthService,
    private currency: CurrencyPipe,
    private ngxsStore: Store,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.data_bar_ventas_mes = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_negocio = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_distribuidor = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_categoria = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_marca = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_ciudad = {
      labels: [],
      datasets: [],
    };
    this.data_bar_numero_pedidos = {
      labels: [],
      datasets: [],
    };
    this.data_bar_numero_referencia = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_sillas = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_domicilios = {
      labels: [],
      datasets: [],
    };
  }

  async ngOnInit() {
    this.id_organizacion = await this.ngxsStore.snapshot().auth.user_organizacion._id;
    this.getListaFiltroMeses();
    this.cargarInformacionVentasMes();
    this.cargarInformacionVentasNegocio();
    this.cargarInformacionVentasDistribuidor();
    this.cargarInformacionVentasCategoria();
    this.cargarInformacionVentasMarca();
    this.cargarInformacionVentasCiudad();
    this.cargarInformacionNumeroPedidos();
    this.cargarInformacionNumeroReferencia();
    this.cargarInformacionVentasSillas();
    this.cargarInformacionVentasDomicilios();
  }

  /**
   * Arma lista de los últimos 24 meses para el filtro de meses
   */
  getListaFiltroMeses() {
    const fecha_actual = new Date();
    let mes_actual = fecha_actual.getMonth();
    let ano_actual = fecha_actual.getFullYear();
    // Filtro años
    for (let index = 0; index < 10; index++) {
      this.filtro_10_anios.push(ano_actual - index);
    }
    this.filtro_anio_numero_referencias = this.filtro_10_anios[0];
    // Filtro mes
    for (let index = 0; index < 23; index++) {
      if (mes_actual >= 0) {
        const data = {
          filtro: this.meses[mes_actual] + ', ' + ano_actual,
          filtro_inicio: this.meses[mes_actual - 2] + ', ' + ano_actual,
          crudo: Number(mes_actual + 1) + ',' + ano_actual + ',' + (mes_actual - 1) + ',' + ano_actual,
        };
        if (mes_actual <= 1) {
          data.filtro_inicio = this.meses[11 - (1 - mes_actual)] + ', ' + (ano_actual - 1);
          data.crudo = Number(mes_actual + 1) + ',' + ano_actual + ',' + (11 - mes_actual) + ',' + (ano_actual - 1);
        }
        this.filtro_24_meses.push(data);
        mes_actual = mes_actual - 1;
      } else {
        mes_actual = 11;
        ano_actual = ano_actual - 1;
      }
    }
    this.filtro_ventas_mes = this.filtro_24_meses[0].crudo;
    this.filtro_ventas_negocio = this.filtro_24_meses[0].crudo;
    this.filtro_ventas_distribuidor = this.filtro_24_meses[0].crudo;
    this.filtro_ventas_categoria = this.filtro_24_meses[0].crudo;
    this.filtro_ventas_marca = this.filtro_24_meses[0].crudo;
    this.filtro_ventas_ciudad = this.filtro_24_meses[0].crudo;
    this.filtro_ventas_numero_pedidos = this.filtro_24_meses[0].crudo;
    this.filtro_ventas_numero_sillas = this.filtro_24_meses[0].crudo;
    this.filtro_ventas_domicilio = this.filtro_24_meses[0].crudo;
  }

  /**
   * Inicializa los datos de las gráficas y llama a los métodos adecuados para mostrar cada una
   */
  /********************************* Ventas por mes ********************************/
  public async cargarInformacionVentasMes() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_mes) {
      this.bar_chart_ventas_mes.destroy();
      this.data_bar_ventas_mes.labels = [];
      this.data_bar_ventas_mes.datasets = [];
    }
    /** Se arma data para query  (últimos 12 meses)*/
    const first_day = this.filtro_ventas_mes.split(',')[1] - 1 + '-' + this.filtro_ventas_mes.split(',')[0] + '-01';
    const last_day = this.filtro_ventas_mes.split(',')[1] + '-' + this.filtro_ventas_mes.split(',')[0] + '-31';
    let total_ventas_mes = 0;
    /** Query de la data para la grafica */
    await this.rest
      .getJWT(`reporteVentasXmes/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((ventas_mes: any) => {
        ventas_mes = ventas_mes.data;
        ventas_mes.forEach((element: any) => {
          this.data_bar_ventas_mes.labels.push(
            this.meses[element._id.split('-')[1] - 1] + '-' + element._id.split('-')[0]
          );
          this.data_bar_ventas_mes.datasets.push(element.total);
          total_ventas_mes = total_ventas_mes + element.total;
        });
        this.total_ventas_mes = this.currency.transform(total_ventas_mes, '$ ', 'symbol', '1.0-0');
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
    /** Se crea la grafica */
    this.bar_chart_ventas_mes = this.graficasService.createBarChart(
      this.bar_ventas_mes,
      this.data_bar_ventas_mes,
      'Ventas por mes',
      '$ Ventas',
      'Mes'
    );
  }

  /******************************** Ventas por negocio *******************************/
  public async cargarInformacionVentasNegocio() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_negocio) {
      this.bar_chart_ventas_negocio.destroy();
      this.data_bar_ventas_negocio.labels = [];
      this.data_bar_ventas_negocio.datasets = [];
    }
    console.log('this.filtro_ventas_negocio', this.filtro_ventas_negocio);
    /** Se arma data para query */
    const first_day = this.filtro_ventas_negocio.split(',')[3] + '-' + this.filtro_ventas_negocio.split(',')[2] + '-01';
    const last_day = this.filtro_ventas_negocio.split(',')[1] + '-' + this.filtro_ventas_negocio.split(',')[0] + '-31';
    let total_ventas_negocio = 0;
    console.log('first_day: ', first_day, 'last_day: ', last_day);

    /** Query de la data para la grafica */
    await this.rest
      .getJWT(`reporteVentasXtipoNegocio/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((ventas_mes: any) => {
        console.log('ventas', ventas_mes);

        ventas_mes = ventas_mes.data;
        ventas_mes.forEach((element: any) => {
          this.data_bar_ventas_negocio.labels.push(element._id || '-');
          this.data_bar_ventas_negocio.datasets.push(element.total);
          total_ventas_negocio = total_ventas_negocio + element.total;
        });
        this.total_ventas_negocio = this.currency.transform(total_ventas_negocio, '$ ', 'symbol', '1.0-0');
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
    /** Se crea la grafica */
    this.bar_chart_ventas_negocio = this.graficasService.createBarChart(
      this.bar_ventas_negocio,
      this.data_bar_ventas_negocio,
      'Ventas por tipo de negocio',
      '$ Ventas',
      'Tipos de negocio'
    );
  }
  /******************************** Ventas por distribuidor *******************************/
  public async cargarInformacionVentasDistribuidor() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_distribuidor) {
      this.bar_chart_ventas_distribuidor.destroy();
      this.data_bar_ventas_distribuidor.labels = [];
      this.data_bar_ventas_distribuidor.datasets = [];
    }
    /** Se arma data para query */
    const first_day =
      this.filtro_ventas_distribuidor.split(',')[3] + '-' + this.filtro_ventas_distribuidor.split(',')[2] + '-01';
    const last_day =
      this.filtro_ventas_distribuidor.split(',')[1] + '-' + this.filtro_ventas_distribuidor.split(',')[0] + '-31';
    let total_ventas_distribuidor = 0;
    /** Query de la data para la grafica */
    await this.rest
      .getJWT(`reporteVentasXDistribuidor/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((ventas_mes: any) => {
        ventas_mes = ventas_mes.data;
        ventas_mes.forEach((element: any) => {
          this.data_bar_ventas_distribuidor.labels.push(element._id[0] || '-');
          this.data_bar_ventas_distribuidor.datasets.push(element.total);
          total_ventas_distribuidor = total_ventas_distribuidor + element.total;
        });
        this.total_ventas_distribuidor = this.currency.transform(total_ventas_distribuidor, '$ ', 'symbol', '1.0-0');
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
    /** Se crea la grafica */
    this.bar_chart_ventas_distribuidor = this.graficasService.createBarChart(
      this.bar_ventas_distribuidor,
      this.data_bar_ventas_distribuidor,
      'Ventas por distribuidor',
      '$ Ventas',
      'Distribuidor'
    );
  }
  /******************************** Ventas por categoria *******************************/
  public async cargarInformacionVentasCategoria() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_categoria) {
      this.bar_chart_ventas_categoria.destroy();
      this.data_bar_ventas_categoria.labels = [];
      this.data_bar_ventas_categoria.datasets = [];
    }
    /** Se arma data para query */
    const first_day =
      this.filtro_ventas_categoria.split(',')[3] + '-' + this.filtro_ventas_categoria.split(',')[2] + '-01';
    const last_day =
      this.filtro_ventas_categoria.split(',')[1] + '-' + this.filtro_ventas_categoria.split(',')[0] + '-31';
    let total_ventas_categoria = 0;
    /** Query de la data para la grafica */
    await this.rest
      .getJWT(`reporteVentasXcategorias/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((ventas_mes: any) => {
        ventas_mes = ventas_mes.data;
        ventas_mes.forEach((element: any) => {
          this.data_bar_ventas_categoria.labels.push(element._id[0] || '-');
          this.data_bar_ventas_categoria.datasets.push(element.total);
          total_ventas_categoria = total_ventas_categoria + element.total;
        });
        this.total_ventas_categoria = this.currency.transform(total_ventas_categoria, '$ ', 'symbol', '1.0-0');
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
    /** Se crea la grafica */
    this.bar_chart_ventas_categoria = this.graficasService.createBarChart(
      this.bar_ventas_categoria,
      this.data_bar_ventas_categoria,
      'Ventas por categoría',
      '$ Ventas',
      'Categorías'
    );
  }
  /******************************** Ventas por marcas *******************************/
  public async cargarInformacionVentasMarca() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_marca) {
      this.bar_chart_ventas_marca.destroy();
      this.data_bar_ventas_marca.labels = [];
      this.data_bar_ventas_marca.datasets = [];
    }
    /** Se arma data para query */
    const first_day = this.filtro_ventas_marca.split(',')[3] + '-' + this.filtro_ventas_marca.split(',')[2] + '-01';
    const last_day = this.filtro_ventas_marca.split(',')[1] + '-' + this.filtro_ventas_marca.split(',')[0] + '-31';
    let total_ventas_marca = 0;
    /** Query de la data para la grafica */
    await this.rest
      .getJWT(`reporteVentasXmarcas/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((ventas_mes: any) => {
        ventas_mes = ventas_mes.data;
        ventas_mes.forEach((element: any) => {
          this.data_bar_ventas_marca.labels.push(element._id[0] || '-');
          this.data_bar_ventas_marca.datasets.push(element.total);
          total_ventas_marca = total_ventas_marca + element.total;
        });
        this.total_ventas_marca = this.currency.transform(total_ventas_marca, '$ ', 'symbol', '1.0-0');
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
    /** Se crea la grafica */
    this.bar_chart_ventas_marca = this.graficasService.createBarChart(
      this.bar_ventas_marca,
      this.data_bar_ventas_marca,
      'Ventas por marca',
      '$ Ventas',
      'Marcas'
    );
  }
  /******************************** Ventas por ciudad *******************************/
  public async cargarInformacionVentasCiudad() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_ciudad) {
      this.bar_chart_ventas_ciudad.destroy();
      this.data_bar_ventas_ciudad.labels = [];
      this.data_bar_ventas_ciudad.datasets = [];
    }
    /** Se arma data para query */
    const first_day = this.filtro_ventas_ciudad.split(',')[3] + '-' + this.filtro_ventas_ciudad.split(',')[2] + '-01';
    const last_day = this.filtro_ventas_ciudad.split(',')[1] + '-' + this.filtro_ventas_ciudad.split(',')[0] + '-31';
    let total_ventas_ciudad = 0;
    /** Query de la data para la grafica */
    await this.rest
      .getJWT(`reporteVentasXCiudad/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((ventas_mes: any) => {
        ventas_mes = ventas_mes.data;
        ventas_mes.forEach((element: any) => {
          this.data_bar_ventas_ciudad.labels.push(element._id || '-');
          this.data_bar_ventas_ciudad.datasets.push(element.total);
          total_ventas_ciudad = total_ventas_ciudad + element.total;
        });
        this.total_ventas_ciudad = this.currency.transform(total_ventas_ciudad, '$ ', 'symbol', '1.0-0');
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
    /** Se crea la grafica */
    this.bar_chart_ventas_ciudad = this.graficasService.createBarChart(
      this.bar_ventas_ciudad,
      this.data_bar_ventas_ciudad,
      'Ventas por ciudad',
      '$ Ventas',
      'Ciudades'
    );
  }
  /******************************** Número de pedidos *******************************/
  public async cargarInformacionNumeroPedidos() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_numero_pedidos) {
      this.bar_chart_numero_pedidos.destroy();
      this.data_bar_numero_pedidos.labels = [];
      this.data_bar_numero_pedidos.datasets = [];
    }
    /** Se arma data para query  (últimos 12 meses)*/
    const first_day =
      this.filtro_ventas_numero_pedidos.split(',')[1] -
      1 +
      '-' +
      this.filtro_ventas_numero_pedidos.split(',')[0] +
      '-01';
    const last_day =
      this.filtro_ventas_numero_pedidos.split(',')[1] + '-' + this.filtro_ventas_numero_pedidos.split(',')[0] + '-31';
    /** Query de la data para la grafica */
    await this.rest
      .getJWT(`reportePedidosXmes/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((ventas_mes: any) => {
        ventas_mes = ventas_mes.data;
        ventas_mes.forEach((element: any) => {
          this.data_bar_numero_pedidos.labels.push(
            this.meses[element._id.split('-')[1] - 1] + '-' + element._id.split('-')[0]
          );
          this.data_bar_numero_pedidos.datasets.push(element.total);
          this.total_ventas_numero_pedidos = this.total_ventas_numero_pedidos + Number(element.total);
        });
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
    /** Se crea la grafica */
    this.bar_chart_numero_pedidos = this.graficasService.createBarChart(
      this.bar_numero_pedidos,
      this.data_bar_numero_pedidos,
      'Número de pedidos por mes',
      'Número de pedidos',
      'Meses'
    );
  }
  /******************************** Número de referencias *******************************/
  public async cargarInformacionNumeroReferencia() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_numero_referencia) {
      this.bar_chart_numero_referencia.destroy();
      this.data_bar_numero_referencia.labels = [];
      this.data_bar_numero_referencia.datasets = [];
    }
    /** Se arma data para query  (últimos 12 meses)*/
    const first_day = '01-01-' + this.filtro_anio_numero_referencias;
    const last_day = '12-31-' + this.filtro_anio_numero_referencias;
    this.total_ventas_numero_referencias = 0;
    /** Query de la data para la grafica */
    await this.rest
      .getJWT(`reporteReferenciasXmes/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((ventas_mes: any) => {
        ventas_mes = ventas_mes.data;
        ventas_mes.forEach((element: any) => {
          this.data_bar_numero_referencia.labels.push(
            this.meses[element._id.split('-')[1] - 1] + '-' + element._id.split('-')[0]
          );
          this.data_bar_numero_referencia.datasets.push(element.total);
          this.total_ventas_numero_referencias = this.total_ventas_numero_referencias + element.total;
        });
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
    /** Se crea la grafica */
    this.bar_chart_numero_referencia = this.graficasService.createBarChart(
      this.bar_numero_referencia,
      this.data_bar_numero_referencia,
      'Número de referencias por mes',
      'Número de referencia',
      'Meses'
    );
  }
  /******************************** Ventas por número de sillas *******************************/
  public async cargarInformacionVentasSillas() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_sillas) {
      this.bar_chart_ventas_sillas.destroy();
      this.data_bar_ventas_sillas.labels = [];
      this.data_bar_ventas_sillas.datasets = [];
    }
    /** Se arma data para query */
    const first_day =
      this.filtro_ventas_numero_sillas.split(',')[3] + '-' + this.filtro_ventas_numero_sillas.split(',')[2] + '-01';
    const last_day =
      this.filtro_ventas_numero_sillas.split(',')[1] + '-' + this.filtro_ventas_numero_sillas.split(',')[0] + '-31';
    let total_ventas_numero_sillas = 0;
    /** Query de la data para la grafica */
    await this.rest
      .getJWT(`reporteVentasXsillas/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((ventas_mes: any) => {
        ventas_mes = ventas_mes.data[0].cant_sillas;
        ventas_mes.forEach((element: any) => {
          this.data_bar_ventas_sillas.labels.push(element.label);
          this.data_bar_ventas_sillas.datasets.push(element.count);
          total_ventas_numero_sillas = total_ventas_numero_sillas + element.count;
        });
        this.total_ventas_numero_sillas = this.currency.transform(total_ventas_numero_sillas, '$ ', 'symbol', '1.0-0');
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
    /** Se crea la grafica */
    this.bar_chart_ventas_sillas = this.graficasService.createBarChart(
      this.bar_ventas_sillas,
      this.data_bar_ventas_sillas,
      'Ventas por número de sillas',
      '$ Ventas',
      'Rangos de número de sillas'
    );
  }
  /******************************** Ventas por establecimientos con domicilios *******************************/
  public async cargarInformacionVentasDomicilios() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_domicilios) {
      this.bar_chart_ventas_domicilios.destroy();
      this.data_bar_ventas_domicilios.labels = [];
      this.data_bar_ventas_domicilios.datasets = [];
    }
    /** Se arma data para query */
    const first_day =
      this.filtro_ventas_domicilio.split(',')[3] + '-' + this.filtro_ventas_domicilio.split(',')[2] + '-01';
    const last_day =
      this.filtro_ventas_domicilio.split(',')[1] + '-' + this.filtro_ventas_domicilio.split(',')[0] + '-31';
    let total_ventas_domicilio = 0;
    /** Query de la data para la grafica */
    await this.rest
      .getJWT(`reporteVentasEstablecimientoConDomicilio/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((ventas_mes: any) => {
        ventas_mes = ventas_mes.data[0];
        this.data_bar_ventas_domicilios.labels.push('Con domicilio', 'Sin domicilio');
        if (ventas_mes.con_domicilio.length > 0) {
          this.data_bar_ventas_domicilios.datasets.push(ventas_mes.con_domicilio[0].total);
          total_ventas_domicilio += ventas_mes.con_domicilio[0].total;
        } else {
          this.data_bar_ventas_domicilios.datasets.push(0);
        }
        if (ventas_mes.sin_Domicilio.length > 0) {
          this.data_bar_ventas_domicilios.datasets.push(ventas_mes.sin_Domicilio[0].total);
          total_ventas_domicilio += ventas_mes.sin_Domicilio[0].total;
        } else {
          this.data_bar_ventas_domicilios.datasets.push(0);
        }
        this.total_ventas_domicilio = this.currency.transform(total_ventas_domicilio, '$ ', 'symbol', '1.0-0');
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
    /** Se crea la grafica */
    this.bar_chart_ventas_domicilios = this.graficasService.createBarChart(
      this.bar_ventas_domicilios,
      this.data_bar_ventas_domicilios,
      'Ventas por establecimientos con domicilios',
      '$ Ventas',
      'Con domicilio. Sin domicilio'
    );
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio-organizacion']);
    };
  }
}
