import { Component, OnInit } from '@angular/core';
import { faArrowUp, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { Location } from '@angular/common';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';

@Component({
  selector: 'app-solicitudes',
  templateUrl: './solicitudes.component.html',
  styleUrls: ['./solicitudes.component.css'],
})
export class SolicitudesComponent implements OnInit {
  /** Iconos */
  faChevronLeft = faChevronLeft;
  faArrowUp = faArrowUp;

  distribuidores_vinculados: any[] = [];

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
  };

  constructor(
    private modalService: NgbModal,
    private distribuidorService: DistribuidorService,
    private location: Location
  ) {}

  ngOnInit(): void {
    /** Extraer el id del distribuidor logeado mediante localstorage */
    const userInfo = JSON.parse(localStorage.getItem('auth') || '');
    const idUserInfo = userInfo['user_distribuidor']['_id'];
    this.loadSolicitudes(idUserInfo);
  }

  public async loadSolicitudes(idDistribuidor: string) {
    const modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    try {
      this.distribuidores_vinculados = await this.distribuidorService
        .getDistribuidoresVinculadosByDistribuidor(idDistribuidor)
        .toPromise();
      modalCarga.close();
    } catch (error) {
      modalCarga.close();
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'No fue posible cargar las solicitudes. Por favor intenta de nuevo más tarde.';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {
        this.location.back();
      };
    }
  }
}
