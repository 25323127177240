<div id="form-login" class="container-fluid d-flex justify-content-center flex-column align-items-center">
  <div class="w-100 container-top d-flex flex-column justify-content-center align-items-center">
    <!--Login-->
    <div class="d-flex justify-content-center align-items-center rounded" id="card-login">
      <div class="card-body d-flex flex-column justify-content-start align-items-around " *ngIf="!showForgotPassword">
        <p class="card-title m-0">Ingresa a tu cuenta</p>
        <label for="email" class="mt-3 card-text">Correo</label>
        <p class="error m-0" *ngIf="error_email">
          El usuario ingresado no pudo ser encontrado
        </p>
        <input type="email" id="email" name="email" placeholder="Escribe aquí" [(ngModel)]="email" required>
        <label for="password" class="mt-3 card-text">
          Contraseña
        </label>
        <p class="error m-0" *ngIf="error_password">
          La contraseña ingresada no es correcta
        </p>
        <div class="input-group mb-4">
          <input [type]="fieldTextType ? 'text' : 'password'" id="password" name="password" placeholder="Escribe aquí"
            class="form-control" [(ngModel)]="password" />
          <div class="input-group-append">
            <span class="input-group-text">
              <fa-icon [icon]="faEyeSlash" size="1x" [styles]="{'color': 'black'}" pull="right"
                (click)="mostrarOcultarPassword()" *ngIf="fieldTextType"></fa-icon>
              <fa-icon [icon]="faEye" size="1x" [styles]="{'color': 'black'}" pull="right"
                (click)="mostrarOcultarPassword()" *ngIf="!fieldTextType"></fa-icon>
            </span>
          </div>
          <p class="error " *ngIf="error_form">Debes llenar todos los campos primero</p>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <button (click)="loign_valido && login('', '')" class="btn-submit rounded row w-100 text-center mb-1"
            [ngClass]="{'btn-submit-disabled': loign_valido == false }">
            <span class="text-center w-100">
              Inicia sesión
            </span>
          </button>
          <p id="forgot-password" class="mt-4 link" (click)="toggleForgotPassword()">Olvidé mi contraseña</p>
        </div>
      </div>
      <!--Olvidé contraseña-->
      <div class="card-body" *ngIf="showForgotPassword">
        <h4 class="card-title">
          <fa-icon [icon]="faChevronLeft" size="1x" id="fa-icon-password" [styles]="{'color': 'black'}" pull="left"
            (click)="toggleForgotPassword()" class="link"></fa-icon>
          &nbsp;&nbsp;&nbsp;Recuperar contraseña
        </h4>
        <br />
        <p>Te enviaremos un formato a tu correo para que puedas recuperar tu contraseña e ingresar a tu cuenta</p>
        <br />
        <label for="correo-forgot">Correo</label>
        <br />
        <input type="email" placeholder="Escribe aquí" [(ngModel)]="email" />
        <br />
        <p class="error" *ngIf="error_form">Debes llenar todos los campos primero</p>
        <br />
        <button (click)="forgotPassword()" class="btn-submit">
          &nbsp;&nbsp;&nbsp;Enviar&nbsp;&nbsp;&nbsp;
        </button>
        <br />
        <br />
      </div>
    </div>
  </div>
  <div class="row w-100 d-flex justify-content-center align-items-center mt-3">
    <a class="w-100 d-flex justify-content-center align-items-center">
      <div class="row rounded" id="card-registro">
        <div class="row w-100 m-0 p-0">
          <div class="col-3 text- m-0" id="icon-registro">
            <fa-icon [icon]="faSignInAlt" size="3x" [styles]="{'color': 'white'}" id="fa-icon" pull="right">
            </fa-icon>
          </div>
          <div class="col-9" id="call-registro">
            <p class="card-text link mb-0 mt-2" >¿No tienes cuenta?</p>
            <h3 class="card-title link" routerLink="/registro">Regístrate ya</h3>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>