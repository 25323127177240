<div class="container p-5">
  <div class="row">
    <div class="col-11">
      <h5>Cargar pre aprobados desde Excel</h5>
    </div>
    <div class="col-1">
      <fa-icon [icon]="faTimes" style="cursor: pointer" size="lg" (click)="activeModal.close()"></fa-icon>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-12">
      <p>
        Carga los clientes pre aprobados con la siguiente estructura e instrucciones. Si no cumple con los requisitos, no
        podremos aprobarlo en el sistema.
      </p>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-12">
      <button class="btn-naranja-transp">
        <a
          href="../../../assets/Docs/formato_clientes_preaprobados.xlsx"
          download="formato_clientes_preaprobados.xlsx"
          class="btn-naranja-transp">
          Descarga el formato de Excel
        </a>
      </button>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-12">
      <input type="file" class="file-upload" (change)="handleFileInput($event)" #fileUpload />
      <button class="btn-blanco" (click)="fileUpload.click()">+ Subir archivo Excel</button>
      <div *ngIf="error_formato_xlsx">
        <br />
        <p class="error">
          El archivo a subir debe ser de Excel (.xlsx). Puedes dar click arriba en 'Descarga el formato de Excel' para
          tener el archivo en el formato correcto.
        </p>
      </div>
      <div *ngIf="plantilla_clientes">
        <br />
        <p>
          <strong>
            {{ plantilla_clientes.name }}
          </strong>
          <fa-icon [icon]="faTimesCircle" size="lg" (click)="handleFileInput(undefined)"></fa-icon>
        </p>
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-12 text-right">
      <button class="btn-morado" *ngIf="plantilla_clientes" (click)="siguiente()">Siguiente</button>
      <button class="btn-morado disabled" *ngIf="!plantilla_clientes" disabled>Siguiente</button>
    </div>
  </div>
</div>
