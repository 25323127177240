import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest/rest.service';
import { faChevronLeft, faTruck, faMapPin } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
@Component({
  selector: 'app-portafolio-distribuidor-org',
  templateUrl: './portafolio-distribuidor-org.component.html',
  styleUrls: ['./portafolio-distribuidor-org.component.css'],
})
export class PortafolioDistribuidorOrgComponent implements OnInit {
  // Data distribuidor
  public distribuidor: any;
  public promedio_ventas = 0;
  public sillas = 0;
  public total_trabajadores = 0;
  public total_establecimientos = 0;
  public productos_distribuidor: any;
  public data_top_productos: any;
  // Iconocs
  public faChevronLeft = faChevronLeft;
  public faStar = faStar;
  public faTruck = faTruck;
  public faMapPin = faMapPin;
  // ID distribuidor
  public id_distribuidor: any;
  // Calificaicon del distribuidor
  public calificacion = {
    abastecimiento: 0,
    ponderado: 0,
    precio: 0,
    puntualidad_entrega: 0,
  };
  // ID organizacion
  public id_organizacion = this.auth.user_organizacion._id;
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };

  constructor(
    private route: ActivatedRoute,
    private ngxsStore: Store,
    private rest: RestService,
    private modalService: NgbModal,
    private auth: AuthService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    this.id_organizacion = await this.ngxsStore.snapshot().auth.user_organizacion._id;
    this.route.params.subscribe(async (params) => {
      this.id_distribuidor = params.id;
    });
    try {
      await this.getDistribuidor();
      await this.getTopProductos();
      await this.getProductos();
      await this.getCalificacionesDistribuidor();
      this.modalCarga?.close();
    } catch (error) {
      this.mostrarMensajeError();
    }
  }

  /**
   * Recupera la información de un distribuidor y sus indicadores
   */
  private async getDistribuidor() {
    await this.rest
      .getJWT(`portafolio-organizacion/distribuidor/${this.id_distribuidor}`)
      .toPromise()
      .then((resp: any) => {
        resp = resp[0];
        this.sillas = resp.sillas;
        this.total_establecimientos = resp.total_establecimientos;
        this.total_trabajadores = resp.trabajadores;
        this.promedio_ventas = resp.promedio_ventas;
        this.distribuidor = resp.distribuidor;
      })
      .catch((err) => {});
  }

  /**
   * Recupera todos los productos de un distribuidor
   */
  private async getProductos() {
    await this.rest
      .getJWT(`productos_por_distribuidor/distribuidor/${this.id_distribuidor}/organizacion/${this.id_organizacion}`)
      .toPromise()
      .then((resp: any) => {
        this.productos_distribuidor = resp;
      })
      .catch((err) => {});
  }

  /**
   * Recupera el top 10 de productos de la organización
   */
  private async getTopProductos() {
    await this.rest
      .getJWT(`productos_por_organizacion/topVentas/${this.id_organizacion}`)
      .toPromise()
      .then((resp: any) => {
        this.data_top_productos = resp;
      })
      .catch((err) => {});
  }

  /**
   * Recupera las calificaciones por item de un distribuidor
   */
  getCalificacionesDistribuidor() {
    this.rest
      .getJWT(`calificacion/${this.id_distribuidor}`)
      .toPromise()
      .then((calificacion: any) => {
        this.calificacion.abastecimiento = Math.round(calificacion.calificacion.abastecimiento * 100) / 100;
        this.calificacion.precio = Math.round(calificacion.calificacion.precio * 100) / 100;
        this.calificacion.puntualidad_entrega = Math.round(calificacion.calificacion.puntualidad_entrega * 100) / 100;
      });
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio-organizacion']);
    };
  }
}
