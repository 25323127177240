<div class="card-container">
    <!-- Ventas por mes -->
    <div class="card ventas-mes">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-4">
                <div>Ventas por mes (últimos 12 meses)</div>
                <div class="d-flex justify-content-between pt-2">
                    Total: {{total_ventas_mes}}
                </div>
            </div>
            <canvas #bar_ventas_mes></canvas>
        </div>
    </div>
    <!-- Ventas por tipo de negocio -->
    <div class="card ventas-negocio">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-4">
                <div>Ventas por tipo de negocio</div>
                <div class="d-flex justify-content-between pt-2">
                    <span>
                        Total: {{total_ventas_negocio}}
                    </span>
                    <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_negocio"
                        (ngModelChange)="cargarInformacionVentasNegocio()">
                        <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                            {{mes.filtro}}-{{mes.filtro_inicio}}
                        </option>
                    </select>
                </div>
            </div>
            <canvas #bar_ventas_negocio></canvas>
        </div>
    </div>
    <!-- Ventas por distribuidor -->
    <div class="card ventas-distribuidor">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-4">
                <div>Ventas por distribuidor</div>
                <div class="d-flex justify-content-between pt-2">
                    <span>
                        Total: {{total_ventas_distribuidor}}
                    </span>
                    <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_distribuidor"
                        (ngModelChange)="cargarInformacionVentasDistribuidor()">
                        <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                            {{mes.filtro}}-{{mes.filtro_inicio}}
                        </option>
                    </select>
                </div>
            </div>
            <canvas #bar_ventas_distribuidor></canvas>
        </div>
    </div>
    <!-- Ventas por categoría -->
    <div class="card ventas-categoria">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-4">
                <div>Ventas por categoría</div>
                <div class="d-flex justify-content-between pt-2">
                    <span>
                        Total: {{total_ventas_categoria}}
                    </span>
                    <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_categoria"
                        (ngModelChange)="cargarInformacionVentasCategoria()">
                        <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                            {{mes.filtro}}-{{mes.filtro_inicio}}
                        </option>
                    </select>
                </div>
            </div>
            <canvas #bar_ventas_categoria></canvas>
        </div>
    </div>
    <!-- Ventas por marcas -->
    <div class="card ventas-marcas">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-4">
                <div>Ventas por marca</div>
                <div class="d-flex justify-content-between pt-2">
                    <span>
                        Total: {{total_ventas_marca}}
                    </span>
                    <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_marca"
                        (ngModelChange)="cargarInformacionVentasMarca()">
                        <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                            {{mes.filtro}}-{{mes.filtro_inicio}}
                        </option>
                    </select>
                </div>
            </div>
            <canvas #bar_ventas_marca></canvas>
        </div>
    </div>
    <!-- Ventas por tipo de ciudad -->
    <div class="card ventas-ciudad">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-4">
                <div>Ventas por ciudad</div>
                <div class="d-flex justify-content-between pt-2">
                    <span>
                        Total: {{total_ventas_ciudad}}
                    </span>
                    <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_ciudad"
                        (ngModelChange)="cargarInformacionVentasCiudad()">
                        <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                            {{mes.filtro}}-{{mes.filtro_inicio}}
                        </option>
                    </select>
                </div>
            </div>
            <canvas #bar_ventas_ciudad></canvas>
        </div>
    </div>
    <!-- Número de pedidos por mes-->
    <div class="card numero-pedidos">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-4">
                <div>Número de pedidos por mes (últimos 12 meses)</div>
                <div class="d-flex justify-content-between pt-2">
                    <span>
                        Total: {{total_ventas_numero_pedidos}}
                    </span>
                </div>
            </div>
            <canvas #bar_numero_pedidos></canvas>
        </div>
    </div>
    <!-- Número de referencias por mes-->
    <div class="card numero-referencias">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-4">
                <div>Número de referencias por mes</div>
                <div class="d-flex justify-content-between pt-2">
                    <span>
                        Total: {{total_ventas_numero_referencias}}
                    </span>
                    <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_anio_numero_referencias"
                        (ngModelChange)="cargarInformacionNumeroReferencia()">
                        <option [value]="anio" *ngFor="let anio of filtro_10_anios">
                            {{anio}}
                        </option>
                    </select>
                </div>
            </div>
            <canvas #bar_numero_referencia></canvas>
        </div>
    </div>
    <!-- Ventas por número de sillas< -->
    <div class="card ventas-sillas">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-4">
                <div>Ventas por número de sillas</div>
                <div class="d-flex justify-content-between pt-2">
                    <span>
                        Total: {{total_ventas_numero_sillas}}
                    </span>
                    <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_numero_sillas"
                        (ngModelChange)="cargarInformacionVentasSillas()">
                        <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                            {{mes.filtro}}-{{mes.filtro_inicio}}
                        </option>
                    </select>
                </div>
            </div>
            <canvas #bar_ventas_sillas></canvas>
        </div>
    </div>
    <!-- Ventas por establecimientos con domicilio -->
    <div class="card ventas-domicilio">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-4">
                <div>Ventas por establecimientos con domicilio</div>
                <div class="d-flex justify-content-between pt-2">
                    <span>
                        Total: {{total_ventas_domicilio}}
                    </span>
                    <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_domicilio"
                        (ngModelChange)="cargarInformacionVentasDomicilios()">
                        <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                            {{mes.filtro}}-{{mes.filtro_inicio}}
                        </option>
                    </select>
                </div>
            </div>
            <canvas #bar_ventas_domicilios></canvas>
        </div>
    </div>
</div>