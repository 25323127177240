<div class="d-flex flex-column justify-content-center align-items-center">
  <div class="col-12 m-0 p-0">
    <h4 class="">
      {{ pasos[paso_actual].title }}
    </h4>
  </div>
  <div class="col-12 m-0 p-0">
    <p>
      {{ pasos[paso_actual].msg }}
    </p>
  </div>
  <div class="col-12 m-0 p-0 text-center">
    <img [src]="pasos[paso_actual].img_src" alt="Imagen modal" id="icon-modal" />
  </div>
  <div class="btn-wrapper">
    <div class="col-5 text-left px-0">
      <button id="btn_modal_anterior" (click)="pasoAnterior()" *ngIf="paso_actual != 0">
        <fa-icon [icon]="faChevronLeft" size="1x" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
        &nbsp;Página anterior
      </button>
    </div>
    <div class="d-flex justify-content-center col-2">
      <span>{{ paso_actual + 1 }}/{{ pasos.length }}</span>
    </div>
    <div class="col-5 text-right px-0">
      <button class="btn_modal" (click)="pasoSiguiente()" *ngIf="paso_actual != pasos.length - 1">Siguiente</button>
      <button class="btn_modal" (click)="onClose()" *ngIf="paso_actual == pasos.length - 1">¡Listo!</button>
    </div>
  </div>
</div>