<app-header-organizacion></app-header-organizacion>
<div id="content">
  <div class="row">
    <div class="col-12 titulo">
      <div class="btn-volver" routerLink="/cuenta-organizacion">
        <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
        Volver
      </div>
      <h4>Selecciona tu plan de cobertura ideal</h4>
    </div>
  </div>
  <div class="overflow-hidden">
    <div class="planes-container py-2">
      <div *ngFor="let plan of planes_organizacion ; index as i;"
        [ngClass]="{ 'planActivo': plan._id == organizacion_plan_id }">
        <div class="card-plan principiante">
          <div class="card-titulo">
            <span>{{plan.nombre}}</span>
          </div>
          <div class="contenido-plan">
            <div class="icono-plan">
              <img src="{{plan.imagen}}" alt="" />
            </div>
            <div class="precio">
              <span>Al mes</span>
              <span>{{ plan.precio | currency: '$ ':'symbol':'1.0-0' }}</span>
            </div>
            <div class="cantidad-plan py-2">
              <span>{{plan.desde}}-{{plan.hasta}}</span>
              <span>Establecimientos</span>
            </div>
          </div>
          <div class="label-plan-activo" *ngIf="plan._id == organizacion_plan_id">
            <span>Tu plan actual</span>
          </div>
          <div class="button-solicitud" *ngIf="plan._id != organizacion_plan_id">
            <button (click)="enviarEmail(plan.nombre)">Enviar solicitud</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>