import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';

@Component({
  selector: 'app-cuenta-distribuidor',
  templateUrl: './cuenta-distribuidor.component.html',
  styleUrls: ['./cuenta-distribuidor.component.css'],
})
export class CuentaDistribuidorComponent implements OnInit {
  active = 1;
  blockUserModule: boolean | undefined;
  constructor(private auth: AuthService) {
    this.validatePermissions();
  }

  ngOnInit(): void {}
  async validatePermissions() {
    // eslint-disable-next-line prefer-const
    let blockUser = await this.auth.validatePermissionsUser();
    this.blockUserModule = blockUser;
  }
}
