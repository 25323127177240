<app-header-organizacion></app-header-organizacion>
<div id="content">
  <div class="row">
    <div class="col-12 titulo">
      <div class="btn-volver" (click)="onPuntosFT()">
        <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
        Volver
      </div>
      <h4>Paquete de puntos FT promocionales</h4>
    </div>
  </div>
  <div class="planes-container">
    <div class="card-plan avanzado">
      <div class="card-titulo">
        <span>Paquete de puntos</span>
      </div>
      <div class="contenido-plan">
        <div class="icono-plan">
          <img src="../../../../assets/img/iconos-planes/3-avanzado.png" alt="" />
        </div>
        <div class="precio">
          <span>${{ dataPaquete?.paquete_base_puntos * dataPaquete?.valor_1puntoft | number }}</span>
        </div>

        <div class="cantidad-plan">
          <span>{{ dataPaquete?.paquete_base_puntos }} Puntos FT</span>
          <!-- <span>Establecimientos</span> -->
        </div>
        <span style="font-size: 13px">+ {{ dataPaquete?.porcentaje_administracion_puntos }}% de administración</span>
      </div>
    </div>
    <div>
      <div class="infoPuntos">
        <div>
          <h6>Información de puntos FT</h6>
          <span>
            Cada paquete de puntos tiene una vigencia de 1 años para ser utilizados pasado el año los puntos se vencerán
          </span>
        </div>

        <div>
          <h6>Catidad de paquetes</h6>
          <div>
            <fa-icon
              (click)="sumaPaquetes(false)"
              [icon]="faChevronLeft"
              [styles]="{ color: '#41403E', cursor: 'pointer' }"
              id="fa-icon"></fa-icon>
            <input type="text" value="1" type="number" [(ngModel)]="cantidadPaquetes" />
            <fa-icon
              (click)="sumaPaquetes(true)"
              [icon]="faChevronRight"
              [styles]="{ color: '#41403E', cursor: 'pointer' }"
              id="fa-icon"></fa-icon>
          </div>
          <span>{{ dataPaquete?.paquete_base_puntos * cantidadPaquetes }} puntos FT</span>
        </div>
        <div>
          <h6>Precios</h6>
          <div class="preciosDetalles">
            <span>Paquete</span>
            <span>${{ valorSinAdmin | number }}</span>
          </div>
          <div class="preciosDetalles">
            <span>Administración {{ dataPaquete?.porcentaje_administracion_puntos }}%</span>
            <span>${{ valorAdmin | number }}</span>
          </div>
        </div>
        <div class="preciosDetalles">
          <span>Total a pagar</span>
          <span>${{ valorSinAdmin + valorAdmin | number }}</span>
        </div>
      </div>
      <div class="button-container">
        <button (click)="solicitarPaquete()">Enviar solicitud</button>
      </div>
    </div>
  </div>
</div>
