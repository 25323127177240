import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { Router } from '@angular/router';
import { RestService } from 'src/app/services/rest/rest.service';
import { Store } from '@ngxs/store';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CurrencyPipe } from '@angular/common';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';

@Component({
  selector: 'app-graficas-organizacion',
  templateUrl: './graficas-organizacion.component.html',
  styleUrls: ['./graficas-organizacion.component.css'],
})
export class GraficasOrganizacionComponent implements OnInit {
  dataGraficas: any;
  dataTopProductos: any;
  dataCantidades: any;
  dataVentas: any;
  data_top_productos: any = [];
  /** ID organizacion */
  public id_organizacion = this.auth.user_organizacion._id;
  /** Lista de meses para apoyar el formateo de la fecha */
  public filtro_24_meses: any = [];
  private meses: string[] = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  /** Colores para las gráficas */
  public colors = [
    'rgba(115, 113, 156, 1)',
    'rgba(199, 195, 239, 1)',
    'rgba(247, 234, 194, 1)',
    'rgba(250, 211, 157, 1)',
    'rgba(179, 179, 210, 1)',
    'rgba(142, 111, 247, 1)',
  ];
  /** Modal de carga para darle feedback al usuario */
  public modalCarga?: NgbModalRef;
  /** Evita que al hacer click por fuera se cierre el modal */
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };
  /** Biding HTML-Canvas y TS */
  @ViewChild('bar_referencias_categoria', { static: true })
  private bar_referencias_categoria!: ElementRef;
  @ViewChild('bar_referencias_marca', { static: true })
  private bar_referencias_marca!: ElementRef;
  @ViewChild('bar_ventas_categoria', { static: true })
  private bar_ventas_categoria!: ElementRef;
  @ViewChild('bar_ventas_marca', { static: true })
  private bar_ventas_marca!: ElementRef;
  /** Guarda datos de la talba y configuraciones */
  public data_bar_referencias_categoria: any;
  public data_bar_referencias_marca: any;
  public data_bar_ventas_categoria: any;
  public data_bar_ventas_marca: any;
  public bar_chart_referencias_categoria: any;
  public bar_chart_referencias_marca: any;
  public bar_chart_ventas_categoria: any;
  public bar_chart_ventas_marca: any;
  public filtro_referencias_categoria: any;
  public filtro_referencias_marca: any;
  public filtro_ventas_categoria: any;
  public filtro_ventas_marca: any;
  public total_referencias_categoria: any;
  public total_referencias_marca: any;
  public total_ventas_categoria: any;
  public total_ventas_marca: any;

  constructor(
    private rest: RestService,
    private auth: AuthService,
    private currency: CurrencyPipe,
    private modalService: NgbModal,
    private ngxsStore: Store,
    private router: Router
  ) {
    this.data_bar_referencias_categoria = {
      labels: [],
      datasets: [],
    };
    this.data_bar_referencias_marca = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_categoria = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_marca = {
      labels: [],
      datasets: [],
    };
  }

  async ngOnInit() {
    this.id_organizacion = await this.ngxsStore.snapshot().auth.user_organizacion._id;
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    try {
      this.getListaFiltroMeses();
      this.getTopProductos();
      await this.getGraficaInformacionReferenciasCategoria();
      await this.getGraficaInformacionReferenciasMarcas();
      await this.getGraficaInformacionVentasCategoria();
      await this.getGraficaInformacionVentasMarca();
      this.modalCarga?.close();
    } catch (error) {
      this.mostrarMensajeError();
    }
  }

  /**
   * Arma lista de los últimos 24 meses para el filtro de meses
   */
  getListaFiltroMeses() {
    const fecha_actual = new Date();
    const ano_actual = fecha_actual.getFullYear();
    for (let index = 0; index < 10; index++) {
      this.filtro_24_meses.push(ano_actual - index);
    }
    this.filtro_referencias_categoria = this.filtro_24_meses[0];
    this.filtro_referencias_marca = this.filtro_24_meses[0];
    this.filtro_ventas_categoria = this.filtro_24_meses[0];
    this.filtro_ventas_marca = this.filtro_24_meses[0];
  }

  /**
   * Inicializa los datos de las gráficas y llama a los métodos adecuados para mostrar cada una
   */
  /******************************** Top 10 productos *******************************/
  public async getTopProductos() {
    /** Query de la data para la grafica */
    await this.rest
      .getJWT(`organizacion/portafolio/top_10_productos/${this.id_organizacion}`)
      .toPromise()
      .then((productos: any) => {
        this.data_top_productos = productos.data;
      })
      .catch((err) => {
        throw err;
      });
  }
  /********************************* Puntos por mes ********************************/
  public async getGraficaInformacionReferenciasCategoria() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_referencias_categoria) {
      this.bar_chart_referencias_categoria.destroy();
      this.data_bar_referencias_categoria.labels = [];
      this.data_bar_referencias_categoria.datasets = [];
    }
    /** Se arma data para query  (últimos 12 meses)*/
    const first_day = this.filtro_referencias_categoria + '-01-01';
    const last_day = this.filtro_referencias_categoria + '-12-31';
    this.total_referencias_categoria = 0;
    console.log(first_day, last_day);
    /** Query de la data para la grafica */
    await this.rest
      .getJWT(`referencias_X_categorias/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((referencias_categoria: any) => {
        referencias_categoria = referencias_categoria.data;
        referencias_categoria.forEach((element: any) => {
          this.data_bar_referencias_categoria.labels.push(element._id);
          this.data_bar_referencias_categoria.datasets.push(element.total_ventas);
          this.total_referencias_categoria = this.total_referencias_categoria + element.total_ventas;
        });
      })
      .catch((err) => {
        throw err;
      });
    this.bar_chart_referencias_categoria = new Chart(this.bar_referencias_categoria.nativeElement, {
      type: 'bar',
      data: {
        labels: this.data_bar_referencias_categoria.labels,
        datasets: [
          {
            label: 'Referencias por categorías',
            backgroundColor: this.colors[0],
            data: this.data_bar_referencias_categoria.datasets,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Número de referencias',
            },
            beginAtZero: true,
            grid: {
              display: false,
            },
          },
          x: {
            title: {
              display: true,
              text: 'Categorías',
            },
          },
        },
      },
    });
  }
  /********************************* Referencias por marca ********************************/
  public async getGraficaInformacionReferenciasMarcas() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_referencias_marca) {
      this.bar_chart_referencias_marca.destroy();
      this.data_bar_referencias_marca.labels = [];
      this.data_bar_referencias_marca.datasets = [];
    }
    /** Se arma data para query  (últimos 12 meses)*/
    const first_day = this.filtro_referencias_marca + '-01-01';
    const last_day = this.filtro_referencias_marca + '-12-31';
    this.total_referencias_marca = 0;
    /** Query de la data para la grafica */
    await this.rest
      .getJWT(`referencias_X_marcas/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((referencias_marca: any) => {
        referencias_marca = referencias_marca.data;
        referencias_marca.forEach((element: any) => {
          this.data_bar_referencias_marca.labels.push(element._id);
          this.data_bar_referencias_marca.datasets.push(element.total_ventas);
          this.total_referencias_marca = this.total_referencias_marca + element.total_ventas;
        });
      })
      .catch((err) => {
        throw err;
      });
    this.bar_chart_referencias_marca = new Chart(this.bar_referencias_marca.nativeElement, {
      type: 'bar',
      data: {
        labels: this.data_bar_referencias_marca.labels,
        datasets: [
          {
            label: 'Referencias por marca',
            backgroundColor: this.colors[0],
            data: this.data_bar_referencias_marca.datasets,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Número de referencias',
            },
          },
          x: {
            title: {
              display: true,
              text: 'Marcas',
            },
          },
        },
      },
    });
  }
  /********************************* Ventas por categoria ********************************/
  public async getGraficaInformacionVentasCategoria() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_categoria) {
      this.bar_chart_ventas_categoria.destroy();
      this.data_bar_ventas_categoria.labels = [];
      this.data_bar_ventas_categoria.datasets = [];
    }
    /** Se arma data para query  (últimos 12 meses)*/
    const first_day = this.filtro_ventas_categoria + '-01-01';
    const last_day = this.filtro_ventas_categoria + '-12-31';
    let total_ventas_categoria = 0;
    /** Query de la data para la grafica */
    await this.rest
      .getJWT(`ventas_X_categorias/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((ventas_categoria: any) => {
        ventas_categoria = ventas_categoria.data;
        ventas_categoria.forEach((element: any) => {
          this.data_bar_ventas_categoria.labels.push(element._id);
          this.data_bar_ventas_categoria.datasets.push(element.total_ventas);
          total_ventas_categoria = total_ventas_categoria + element.total_ventas;
        });
        this.total_ventas_categoria = this.currency.transform(total_ventas_categoria, '$ ', 'symbol', '1.0-0');
      })
      .catch((err) => {
        throw err;
      });
    this.bar_chart_ventas_categoria = new Chart(this.bar_ventas_categoria.nativeElement, {
      type: 'bar',
      data: {
        labels: this.data_bar_ventas_categoria.labels,
        datasets: [
          {
            label: 'Ventas por categorías',
            backgroundColor: this.colors[0],
            data: this.data_bar_ventas_categoria.datasets,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: '$ Ventas',
            },
          },
          x: {
            title: {
              display: true,
              text: 'Categorías',
            },
          },
        },
      },
    });
  }
  /********************************* Ventas por marca ********************************/
  public async getGraficaInformacionVentasMarca() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_marca) {
      this.bar_chart_ventas_marca.destroy();
      this.data_bar_ventas_marca.labels = [];
      this.data_bar_ventas_marca.datasets = [];
    }
    /** Se arma data para query  (últimos 12 meses)*/
    const first_day = this.filtro_ventas_marca + '-01-01';
    const last_day = this.filtro_ventas_marca + '-12-31';
    let total_ventas_marca = 0;
    /** Query de la data para la grafica */
    await this.rest
      .getJWT(`ventas_X_marcas/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((ventas_marca: any) => {
        ventas_marca = ventas_marca.data;
        ventas_marca.forEach((element: any) => {
          this.data_bar_ventas_marca.labels.push(element._id);
          this.data_bar_ventas_marca.datasets.push(element.total_ventas);
          total_ventas_marca = total_ventas_marca + element.total_ventas;
        });
        this.total_ventas_marca = this.currency.transform(total_ventas_marca, '$ ', 'symbol', '1.0-0');
      })
      .catch((err) => {
        throw err;
      });
    this.bar_chart_ventas_marca = new Chart(this.bar_ventas_marca.nativeElement, {
      type: 'bar',
      data: {
        labels: this.data_bar_ventas_marca.labels,
        datasets: [
          {
            label: 'Ventas por marca',
            backgroundColor: this.colors[0],
            data: this.data_bar_ventas_marca.datasets,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: '$ Ventas',
            },
          },
          x: {
            title: {
              display: true,
              text: 'Marcas',
            },
          },
        },
      },
    });
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio-organizacion']);
    };
  }
}
