import { Component, OnInit } from '@angular/core';
import { faChevronLeft, faFilePdf, faEye } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { ActivatedRoute } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import { Router } from '@angular/router';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';

@Component({
  selector: 'app-detalle-producto',
  templateUrl: './detalle-producto.component.html',
  styleUrls: ['./detalle-producto.component.css'],
})
export class DetalleProductoOrganizacionComponent implements OnInit {
  public id_organizacion = this.auth.user_organizacion._id;
  // Iconos
  public faChevronLeft = faChevronLeft;
  public faFilePdf = faFilePdf;
  public faEye = faEye;
  // Data producto
  public producto: any;
  // WhtasApp
  public data_mensaje_whatsapp = {
    numero: '573184662904',
    mensaje:
      'Hola!%20Necesito%20un%20cambio%20para%20el%20producto%20**%20-%20código%0A*COS2335*%20-%20el%20cambio%20es',
  };
  modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
  };
  // Producto
  public id: any;
  // Datos y configuracion de la tabla
  public settings: any = {};
  public data: any = [];
  public source?: LocalDataSource;
  // Puntos ft
  public nuevos_puntos: any;
  public nueva_fecha_cierre: any;
  public nueva_fecha_apertura: any;
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };
  preciosDistribuidores: any;
  guardar = false;
  errorFechas = false;
  errorFechasAnterior= false;
  errorFechasIguales= false;
  cambiosGuardados = false;
  indicadoresProducto: any = [];
  foto_seleccionada: any;
  mensajePuntos: any;
  puntosDisponibles = true;
  ficha_tecnica: any;
  pdf: any;
  origen: any;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private rest: RestService,
    public auth: AuthService,
    private route: ActivatedRoute,
    private ngxsStore: Store
  ) {}

  async ngOnInit() {
    this.id_organizacion = await this.ngxsStore.snapshot().auth.user_organizacion._id;
    this.route.params.subscribe(async (params) => {
      this.id = params.id;
      this.origen = params.origen;
    });
    try {
      this.configSmartTable();
      await this.getBolsaOrg();
      await this.getProducto();
      await this.fetchIndicadores();
      this.fetchPrecios();
    } catch (error) {
      this.mostrarMensajeError();
    }
  }
  public volver(){
    if(this.origen != 'puntos'){
      this.router.navigate([`/portafolio-organizacion`]);
    }else{
      this.router.navigate([`/puntos-ft-organizacion`]);
    }
  }
  /**
   * Recibe un archivo del usuario y lo guarda en el indice
   * correcto del arreglo de archivos a subir. Si el archivo
   * a subir no tiene el formato correcto, no se guarda y
   * se muestra un mensaje de error
   * @param event El evento generado al subir el archivo
   * @param index El indice del arreglo de archivos
   */
  handleFileInput(event: any) {
    //this.registroComp.error_formato_pdf = false;

    const file: File = event.target.files[0];
    if (file.size > 2000000) {
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Este archivo supera el máximo de 2MB permitido!';
      modalRef.componentInstance.btn_msg = 'Volver';
      return;
    } else if (file.name.slice(-4) != '.pdf') {
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = '¡Solo archivos PDF están permitidos!';
      modalRef.componentInstance.btn_msg = 'Volver';
      return;
    } else {
      if (file != null) {
        const modalCarga = this.modalService.open(CargandoGenericoComponent, this.modalOptions);
        const file_split: string[] = file.name.split('.');
        const file_end: string = file_split[file_split.length - 1].trim().toLowerCase();
        this.pdf = file;
        const upload_form: FormData = new FormData();
        upload_form.append(`ficha_tecnica`, this.pdf);
        this.rest.postLink(this.producto._id, upload_form).subscribe((resp: any) => {
          if (resp.success) {
            this.ficha_tecnica = resp.logo_ruta;
            const update = {
              ficha_tecnica: this.ficha_tecnica,
            };
            this.rest
              .putJWT(`productoAllCODFEAT/${this.producto?.producto_codigoFeat}`, update)
              .toPromise()
              .then((resp: any) => {
                if (resp.success) {
                  modalCarga.close();
                  const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
                  modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
                  modalRef.componentInstance.title = '¡Enhorabuena!';
                  modalRef.componentInstance.msg = 'La ficha se ha subido éxitosamente';
                  modalRef.componentInstance.btn_msg = 'Ok';
                  modalRef.componentInstance.close_callback = () => {
                    // this.router.navigate(['/distribuidores-organizacion']);
                  };
                } else {
                  modalCarga.close();
                  this.mostrarMensajeError();
                }
              });
          } else {
            modalCarga.close();
            this.mostrarMensajeError();
          }
        });
      }
    }
  }
  /**
   * Recupera la información del producto
   */
  async getProducto() {
    const resp_producto: any = await this.rest.getJWT(`producto/completo/${this.id}`).toPromise();
    this.producto = resp_producto[0];
    this.ficha_tecnica = this.producto.producto_ficha_tecnica;
    this.foto_seleccionada = this.producto?.producto_fotos[0];
    this.nuevos_puntos = this.producto.producto_puntosFt_und;
    if(this.producto.producto_fecha_cierre_puntosft && this.producto.producto_fecha_apertura_puntosft){
      this.nueva_fecha_cierre = moment.utc(this.producto.producto_fecha_cierre_puntosft).format('YYYY-MM-DD');
      this.nueva_fecha_apertura = moment.utc(this.producto.producto_fecha_apertura_puntosft).format('YYYY-MM-DD');
      
    }
   this.data_mensaje_whatsapp.mensaje = `Hola!%20Necesito%20un%20cambio%20para%20el%20producto%20*${this.producto.producto_nombre}*%20-%20código%20FEAT%20%0A*${this.producto.producto_codigoFeat}*%20-%20el%20cambio%20es`;
  }
  async getBolsaOrg() {
    const resp_bolsa: any = await this.rest
      .getJWT(`busqueda_puntos_feat_por_organizacion/${this.id_organizacion}`)
      .toPromise();
    if (resp_bolsa.indicador.puntos_totales === 0) {
      this.mensajePuntos =
        'No tienes paquetes disponibles, recuerda comprar tus paquetes y así podrás asignarle puntos a tus productos';
      this.puntosDisponibles = false;
    }
    if (resp_bolsa.resultado[0] && resp_bolsa.resultado[0].bolsa_a_tope95_porciento !== 'No') {
      this.mensajePuntos =
        'No tienes paquetes disponibles, recuerda comprar tus paquetes y así podrás asignarle puntos a tus productos';
      this.puntosDisponibles = false;
    }
  }
  /**
   * Recupera los indicadores del componente
   */
  async fetchIndicadores() {
    const resp_indicadores: any = await this.rest
      .getJWT(`organizacion/portafolio/detalle_producto/indicadores/${this.producto.producto_codigoFeat}`)
      .toPromise();
    this.indicadoresProducto = resp_indicadores.data[0];
    this.indicadoresProducto.puntos_entrega
      ? this.indicadoresProducto.puntos_entrega
      : (this.indicadoresProducto.puntos_entrega = 0);
    this.indicadoresProducto.participacion_cat
      ? this.indicadoresProducto.participacion_cat
      : (this.indicadoresProducto.participacion_cat = 0);
    this.indicadoresProducto.participacion_cat = Math.floor(this.indicadoresProducto?.participacion_cat * 100);
  }

  /**
   * Recupera la informaciñon de distribuidores por producto
   */
  async fetchPrecios() {
    const resp_precio: any = await this.rest
      .getJWT(`distribuidor_por_producto/${this.producto.producto_codigoFeat}`)
      .toPromise();
    this.preciosDistribuidores = resp_precio;
    let avgPrecioUnidad = 0;
    let avgPrecioCaja = 0;
    let index = 0;
    for (const iterator of this.preciosDistribuidores) {
      if (iterator.producto_precio_unidad){
        avgPrecioUnidad= avgPrecioUnidad + iterator.producto_precio_unidad;
      } 
      if (iterator.producto_precio_caja){
        avgPrecioCaja = avgPrecioCaja +iterator.producto_precio_caja;
      } 
      index++;
    }

    this.producto.producto_precio_unidad = avgPrecioUnidad / index;
    this.producto.producto_precio_caja = avgPrecioCaja / index;
    this.source = new LocalDataSource(this.preciosDistribuidores);
  }

  /**
   * Configura la tabla de distribuidores del producto seleccionado
   */
  private configSmartTable(): void {
    this.settings = {
      pager: {
        display: true,
        perPage: 10,
      },
      actions: {
        display: false,
        columnTitle: 'Acción',
        add: false,
        edit: false,
        delete: false,
      },
      hideSubHeader: false,
      columns: {
        distribuidor: {
          title: 'Distribuidor',
          editable: false,
          filter: true,
        },
        producto_precio_unidad: {
          title: 'Precio Por Unidad',
          editable: false,
          filter: true,
          valuePrepareFunction: (value: any) => {
            return value === 'Total'
              ? value
              : Intl.NumberFormat('de-DE', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(
                  value
                );
          },
        },
        producto_precio_caja: {
          title: 'Precio Por Caja',
          editable: false,
          filter: true,
          valuePrepareFunction: (value: any) => {
            return value === 'Total'
              ? value
              : Intl.NumberFormat('de-DE', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(
                  value
                );
          },
        },
      },
    };
  }
  validarPuntos() {
    if (!this.puntosDisponibles) {
      this.mostrarMensajePuntos();
      return false;
    } else {
      return true;
    }
  }
  /**
   * Guarda los cambios realizados en los puntos Ft del producto
   */
  async guardarCambiosPuntosFT() {
    let cambio;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, prefer-const
    cambio = await this.validarPuntos();
    if (cambio) {
      await this.rest
        .getJWT(`producto/${this.producto?._id}`)
        .toPromise()
        .then((producto: any) => {
          const precios = producto.precios[0];
          precios.puntos_ft_unidad = this.nuevos_puntos;
          const data = {
            precios: [precios],
            fecha_cierre_puntosft: new Date(this.nueva_fecha_cierre),
            fecha_apertura_puntosft: new Date(this.nueva_fecha_apertura),
            mostrarPF: true,
            comentarioVencimiento: '',
          };
          this.rest
            .putJWT(`productoAllCODFEAT/${this.producto?.producto_codigoFeat}`, data)
            .toPromise()
            .then((resp: any) => {
              if (resp.success) {
                this.cambiosGuardados = true;
                setTimeout(() => {
                  this.cambiosGuardados = false;
                }, 2000);
              }
            });
        })
        .catch((err) => {
          this.mostrarMensajeError();
        });
    }
  }

  /**
   * Verifica si se han hecho cambios en los inputs de puntos ft
   */
  verificarCambios() {
    this.errorFechasAnterior = false;
    this.errorFechas= false;
    this.errorFechasIguales = false;
    if(this.nueva_fecha_apertura <  moment().format('YYYY-MM-DD')){
      this.guardar = false;
      this.errorFechasAnterior = true;
    } else if(this.nueva_fecha_apertura === this.nueva_fecha_cierre){
      this.guardar = false;
      this.errorFechasIguales = true;
    }
    else{
      this.errorFechasAnterior = false;
      if (this.nueva_fecha_apertura > this.nueva_fecha_cierre) {
        this.errorFechas = true;
      } else {
        this.errorFechas = false;
        if (
          this.nuevos_puntos != this.producto.producto_puntosFt_und ||
          this.nueva_fecha_cierre != this.producto.producto_fecha_cierre_puntosft ||
          this.nueva_fecha_apertura != this.producto.producto_fecha_apertura_puntosft
        ) {
          this.guardar = true;
        } else {
          this.guardar = false;
        }
      }
    }
  }

  /**
   * Redirige a usuario a la api de Whatsapp para comunicarse con FEAT
   */
  goToLink() {
    const url = `https://api.whatsapp.com/send?phone=${this.data_mensaje_whatsapp.numero}&text=${this.data_mensaje_whatsapp.mensaje}`;
    window.open(url, '_blank');
  }
  goLinkFicha() {
    const url = `${this.ficha_tecnica}`;
    window.open(url, '_blank');
  }
  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      // this.router.navigate(['/distribuidores-organizacion']);
    };
  }
  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajePuntos() {
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = this.mensajePuntos;
    modalRef.componentInstance.btn_msg = 'Ver paquetes';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/comprar-puntos-ft-organizacion']);
    };
  }
}
