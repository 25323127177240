import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../services/auth/auth.service';
import { RestService } from '../services/rest/rest.service';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from '../modal/simple/simple.component';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css'],
})
export class NotificacionesComponent implements OnInit {
  // Icons
  public faChevronLeft = faChevronLeft;
  // Tipo de usuario logueado
  public tipo_usuario: any;
  // lista de notificaciones sin leer
  public notificaciones: any;
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;
  // No permite se cierre modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };

  constructor(
    private restService: RestService,
    public authService: AuthService,
    private router: Router,
    private modalService: NgbModal
  ) {}

  async ngOnInit() {
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    /** Revisa si es un establecimiento o un distribuidor logueado */
    try {
      await this.getTipoTrabajador();
      await this.getNotificaciones();
      this.modalCarga?.close();
    } catch (error) {
      this.mostrarMensajeError();
    }
  }

  /**
   * Cambia el estado de las notificaciones a LEIDO
   */
  marcarLeido() {
    // Actualiza en la base
    let notificaciones_filtradas = this.notificaciones.filter((notificacion: any) => notificacion.estado != 'Leido');
    notificaciones_filtradas = this.extractIds(this.notificaciones);
    const body = {
      notificaciones: notificaciones_filtradas,
    };
    this.servicioMarcarLeidas(body);
    // Actualiza el componente
    this.notificaciones.map((notificacion: any) => (notificacion.estado = 'Leido'));
  }

  /**
   * Recupera el tipo de trabajador logueado
   */
  getTipoTrabajador() {
    this.restService
      .get(`tipo_empresa_trabajador/${this.authService.user?._id}`)
      .toPromise()
      .then((resp: any) => {
        this.tipo_usuario = resp;
      })
      .catch((err) => {
        throw err;
      });
  }

  /**
   * Recupera todas las notificaciones de un trabajador
   */
  getNotificaciones() {
    this.restService
      .getJWT('notificaciones/' + this.authService.user?._id)
      .toPromise()
      .then((resp: any) => {
        this.modalCarga?.close();
        this.notificaciones = resp;
      })
      .catch((err) => {
        throw err;
      });
  }

  /**
   * Funcion para Redireccionamiento de cada una de las
   * notificaciones segun el detalle al que se requiera
   * @tipo tipo de notificacion para saber a que ruta se transfire
   */
  redireccionNotificacion(notificacion: any) {
    switch (notificacion.tipo) {
      case 'Nuevo distribuidor en la zona': {
        this.router.navigate(['/distribuidores']);
        this.servicioMarcarLeidas({
          notificaciones: [notificacion._id],
        });
        break;
      }
      case 'Nueva noticia': {
        this.router.navigate(['/comunidad']);
        this.servicioMarcarLeidas({
          notificaciones: [notificacion._id],
        });
        break;
      }
      case 'Actualizacion de pedido': {
        const listTerminos = notificacion.mensaje.split(' ', 3);
        const ID = listTerminos[2].split(':');
        this.router.navigate(['/pedidos/', ID[1]]);
        this.servicioMarcarLeidas({
          notificaciones: [notificacion._id],
        });
        break;
      }
      case 'mensaje': {
        this.router.navigate(['/pedidos/mensajes/']);
        this.servicioMarcarLeidas({
          notificaciones: [notificacion._id],
        });
        break;
      }
      case 'Puntos Feat': {
        break;
      }
      case 'Nuevo producto en catalogo': {
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * Retorna el la url del icono de notificación según aplique
   * @param data objeto con la información. d la notificacion y su tipo
   * @returns el logo de la notificación
   */
  getIconoNotificacion(data: any) {
    if (data.tipo == 'Nuevo distribuidor en la zona') {
      return '../../assets/img/notificaciones/notificacion-1.png';
    } else if (data.tipo == 'Nueva noticia') {
      return '../../assets/img/notificaciones/notificaciones-2.png';
    } else if (data.tipo == 'Actualizacion de pedido') {
      return '../../assets/img/notificaciones/notificacion-3.png';
    } else if (data.tipo == 'Novedad producto organización') {
      return '../../assets/img/notificaciones/notificacion-4.png';
    } else if (data.tipo == 'Puntos Feat') {
      return '../../assets/img/notificaciones/notificacion-4.png';
    } else if (data.tipo == 'Nuevo producto en catalogo') {
      return '../../assets/img/notificaciones/notificacion-5.png';
    } else if (data.tipo == 'Nuevo mensaje') {
      return '../../assets/img/notificaciones/notificacion-4.png';
    } else {
      return '../../assets/img/notificaciones/notificacion-3.png';
    }
  }

  /**
   * Marca las notificaciones como leidas
   * @param body Notificaciones a marcar como leidas
   */
  servicioMarcarLeidas(body: any) {
    this.restService.postJWT('notificaciones/marcar_leidas', body).toPromise().then();
  }

  /**
   * Extrae los _ids de un arreglo
   * @arr arreglo para analizar
   */
  extractIds(arr: any) {
    const _id = [];
    for (const u in arr) {
      _id.push(arr[u]._id);
    }
    return _id;
  }

  /**
   * Voler a modulo anterior
   * dependiendo de donde haya ingresado, volvera al modulo anterior
   */
  volver() {
    if (this.tipo_usuario === 'usuario_horeca') {
      this.router.navigate(['/inicio']);
    } else if (this.tipo_usuario === 'distribuidor') {
      this.router.navigate(['/inicio-distribuidor']);
    } else if (this.tipo_usuario === 'organizacion') {
      this.router.navigate(['/inicio-organizacion']);
    }
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.volver();
    };
  }
}
