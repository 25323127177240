<div id="top-header" class="col-12 d-flex flex-row align-items-center sticky-top">
  <div class="col-8 d-flex justify-content-start" style="font-size: 14px;"></div>
  <div class="col-4 d-flex justify-content-between">
    <div><img src="../../../assets/img/Footer/Grupo 54.png" class="redes-sociales cursorPointer" alt="Ícono Facebook"
        (click)="verDocumento('https://www.facebook.com/feat.B4B')"></div>
    <div><img src="../../../assets/img/Footer/Grupo 53.png" class="redes-sociales cursorPointer" alt="Ícono Twitter"
        (click)="verDocumento('https://twitter.com/Feat_b4b')"></div>
    <div><img src="../../../assets/img/Footer/Grupo 52.png" class="redes-sociales cursorPointer" alt="Ícono Instagram"
        (click)="verDocumento('https://www.instagram.com/feat.b4b/')"></div>
  </div>
</div>
<div class="fondo sticky-top">
  <nav class="navbar col-12 navbar-expand-lg navbar-light sticky-top m-0 p-0">
    <a class="logo-menu col-6 col-sm-8 col-lg-2">
      <img src="../../assets/img/logo-feat-largo.png" class="logo" alt="FEAT Logo Horizontal" />
    </a>
    <button class="navbar-toggler col-3 col-sm-2 hidden-sm-up text-light" type="button"
      (click)="isMenuCollapsed = !isMenuCollapsed">
      <fa-icon [icon]="faBars"></fa-icon>
    </button>
    <div [ngbCollapse]="isMenuCollapsed" class="col-12 col-lg-10 collapse navbar-collapse" id="navbarsDefault">
      <ul class="navbar-nav fondo me-auto mb-2 mb-lg-0 h-100 d-flex justify-content-end w-100">
        <li class="nav-item">
          <a class="nav-link" routerLink="/inicio-distribuidor" (click)="isMenuCollapsed = true"
            routerLinkActive="active-link">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/clientes" (click)="isMenuCollapsed = true"
            routerLinkActive="active-link">Establecimientos</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/portafolio" (click)="isMenuCollapsed = true"
            routerLinkActive="active-link">Portafolio</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/comunidad" (click)="isMenuCollapsed = true"
            routerLinkActive="active-link">Comunidad</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/informes-distribuidor" (click)="isMenuCollapsed = true"
            routerLinkActive="active-link">Informes</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/puntos-ft" (click)="isMenuCollapsed = true"
            routerLinkActive="active-link">Puntos FT</a>
        </li>
        <li class="nav-item">
          <a class="nav-link carrito d-flex flex-row pr-3" routerLink="/pedidos-distribuidor"
            (click)="isMenuCollapsed = true" routerLinkActive="active-link">
            <fa-icon [icon]="faShoppingCart"></fa-icon>
            <span class="temp-text">
              &nbsp;Pedidos
            </span>
            <div class="badge-car" *ngIf="indicadores">{{ indicadores.pedidos || 0 }}</div>
          </a>
          <a class="nav-link  border-left d-flex flex-row pl-3" routerLink="/cuenta-distribuidor"
            (click)="isMenuCollapsed = true" routerLinkActive="active-link">
            <fa-icon [icon]="faUser"></fa-icon>
            <span class="temp-text">
              &nbsp;Cuenta
            </span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</div>