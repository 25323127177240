<div class="container-fluid fondo-planes d-flex flex-column align-items-center justify-content-center">
  <div class="row">
    <div class="col text-center">
      <h1 class="titulo-planes">Te ofrecemos justo lo que necesitas</h1>
    </div>
  </div>
  <!-- Planes -->
  <div class="row mt-5">
    <div class="col d-flex justify-content-center col-12 col-lg-4 mb-3">
      <div class="card-plan container">
        <div class="text-center mt-5">
          <img src="../../../assets/img/Landing/horeca.png" class="img-plan" alt="icono de una campana" />
        </div>
        <div class="mt-2">
          <span class="title-plan">Horeca</span>
        </div>
        <div class="mt-3">
          <p class="parrafo">- Acceso efectivo a portafolio de insumos de los principales distribuidores.</p>
          <p class="parrafo">- Módulos para el control y la eficiencia operacional.</p>
          <p class="parrafo">- Módulo de información estratégica para tomar mejores decisiones.</p>
          <p class="parrafo">
            - Vinculación al programa de lealtad y fidelidad PUNTOS FT. obteniendo beneficios relevantes.
          </p>
        </div>
      </div>
    </div>
    <div class="col d-flex justify-content-center col-12 col-lg-4 mb-3">
      <div class="card-plan container">
        <div class="text-center mt-5">
          <img src="../../../assets/img/Landing/distribuidor.png" class="img-plan"
            alt="icono de una caja abierta y una flecha saliendo de ella apuntando hacia arriba" />
        </div>
        <div class="mt-2">
          <span class="title-plan">Distribuidor</span>
        </div>
        <div class="mt-3">
          <p class="parrafo">
            - Oferta efectiva de su portafolio con un propósito de Grip / Grab para así llegar a más clientes con un
            mayor número de
            referencias compradas.
          </p>
          <p class="parrafo">- Módulos para el control y la eficiencia operacional.</p>
          <p class="parrafo">- Módulo de información estratégica para tomar mejores decisiones.</p>
        </div>
      </div>
    </div>
    <div class="col d-flex justify-content-center col-12 col-lg-4 mb-3">
      <div class="card-plan container">
        <div class="text-center mt-5">
          <img src="../../../assets/img/Landing/organizacion.png" class="img-plan"
            alt="rompecabezas de cuatro piezas con una de las piezas fuera de su lugar" />
        </div>
        <div class="mt-2">
          <span class="title-plan">Organización</span>
        </div>
        <div class="mt-3">
          <p class="parrafo">
            - Contáctanos para mayor información.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- Clientes -->
  <div class="container-fluid fondo-cliente">
    <div class="row">
      <div class="col d-flex flex-column justify-content-center align-items-center mb-lg-5 mt-lg-2">
        <h1 class="title-clientes text-center">Nuestros distribuidores</h1>
        <div class="w-100 overflow-auto">
          <div class="d-flex justify-content-center container-clientes fit-content">
            <div class="ml-3" *ngFor="let distribuidor of distribuidores">
              <img src="{{distribuidor.logo || distribuidor_placeholder}}" class="img-client rounded-circle"
                alt="logo cliente de una copa de vino con liquido y un pitillo en este" />
              <div class="text-center">{{distribuidor.nombre}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>