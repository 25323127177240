<app-header-distribuidor></app-header-distribuidor>
<div id="content">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills">
    <li [ngbNavItem]="1">
      <a ngbNavLink>Puntos FT</a>
      <ng-template ngbNavContent>
        <app-tabla-puntos-ft></app-tabla-puntos-ft>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>Gráficas</a>
      <ng-template ngbNavContent>
        <app-grafica-puntos-ft></app-grafica-puntos-ft>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
