<app-header-organizacion></app-header-organizacion>
<div id="content">
  <div class="row">
    <div class="col-12" *ngIf="data_organizacion">
      <h2>¡Hola, {{ data_organizacion.nombre }}!</h2>
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills">
        <li (click)="onChangeNav(1)" [ngbNavItem]="1">
          <a ngbNavLink>Trabajadores</a>
          <ng-template ngbNavContent>
            <app-trabajadores-organizaciones></app-trabajadores-organizaciones>
          </ng-template>
        </li>
        <li (click)="onChangeNav(2)" [ngbNavItem]="2">
          <a ngbNavLink>Información cuenta</a>
          <ng-template ngbNavContent>
            <app-info-cuenta-organizaciones></app-info-cuenta-organizaciones>
          </ng-template>
        </li>
        <li (click)="onChangeNav(3)" [ngbNavItem]="3">
          <a ngbNavLink>Historial de pagos</a>
          <ng-template ngbNavContent>
            <app-historial-pagos-organizaciones></app-historial-pagos-organizaciones>
          </ng-template>
        </li>
      </ul>
      <!-- [ngbNavItem]="2" -->
      <div class="contenedor-cuenta">
        <div>
          <div class="container-logo iconoGrande">
            <img src="{{ data_organizacion.logo }}" alt="" />
            <span class="pildora-color">{{ userData.tipo_trabajador }}</span>
          </div>
          <hr />
          <div class="fila-display iconoGrande">
            <img src="../../../assets/img/principiante.png" alt="" />
            <div class="columna-display">
              <span>
              </span>
              <span *ngIf="plan_actual != undefined && plan_actual; else noPlan">
                Plan: {{ plan_actual.nombre }} <br>
                {{plan_actual.desde}}/{{plan_actual.hasta}} establecimientos
              </span>
              <ng-template #noPlan>
                No posees ningún plan <br>
                procede a vincularte a uno
              </ng-template>
            </div>
          </div>
          <div class="fila-display user-count iconoPequeno">
            <img src="../../../assets/iconos-org/persona.png" alt="" />
            <div class="columna-display">
              <span>{{ trabajadores.length }}</span>
              <span>Trabajadores en el equipo</span>
            </div>
          </div>
          <hr />
          <button class="boton-expandir" routerLink="/expandir-plan-organizacion">Expandir plan</button>
          <span class="fila-display pointer iconoPequeno" (click)="signOut()">
            <img src="../../../assets/iconos-org/logout.png" alt="" />
            Cerrar sesión
          </span>
        </div>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</div>