import { Component, DoCheck, OnInit } from '@angular/core';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-inicio-perfil',
  templateUrl: './inicio-perfil.component.html',
  styleUrls: ['./inicio-perfil.component.css'],
})
export class InicioPerfilComponent implements OnInit {
  /** Íconos FontAwesome para la UI */
  public faPen = faPen;

  /** Datos del punto de entrega seleccionado para filtrar distribuidores */
  public punto_entrega: any;

  /** Puntos Feats disponibles */
  public puntos_feat = 0;

  /**Cantidad de digítos en los puntos feat */
  public total_digitos = 1;

  /**No hay información de puntos */
  isPuntosNull = false;
  minPF: any = 0;
  puntosProceso: any;
  puntosPorCalificar: any;
  diasRestantes: any;

  constructor(public auth: AuthService, public rest: RestService) {}

  ngOnInit(): void {
    this.punto_entrega = JSON.parse(localStorage.getItem('punto_entrega_seleccionado')!);
    if (this.punto_entrega) {
      this.getPuntosFeat();
      this.getLimitCalification();
      this.getPuntosConfiguracion();
    }
  }
  ionViewDidEnter() {
    if (this.punto_entrega) {
      this.getPuntosFeat();
    }
  }
  /*ngDoCheck(): void {
    if (this.punto_entrega && this.isPuntosNull == false) {
      if (this.punto_entrega._id != JSON.parse(localStorage.getItem('punto_entrega_seleccionado')!)._id) {
        this.punto_entrega = JSON.parse(localStorage.getItem('punto_entrega_seleccionado')!);
        this.getPuntosFeat();
      }
    }
  }*/

  /**
   * Información de puntos por punto de entrega
   */
  public getPuntosFeat() {
    this.rest
      .getJWT('/puntos_feat/punto_entrega/' + this.punto_entrega._id)
      .toPromise()
      .then((resp: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let xCalificar = 0;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let disponibles = 0;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let proceso = 0;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let perdidos;
        for (const item of resp) {
          if (item._id === 'Por congelar') {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            xCalificar = item.total_puntos;
          }
          if (item._id === 'Congelados') {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            disponibles = item.total_puntos;
          }
          if (item._id === 'Aplica') {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            proceso = item.total_puntos;
          }
          if (item._id === 'Congelados feat') {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            perdidos = item.total_puntos;
          }
        }
        //resp[0].disponibles[0] ? (this.puntos_feat = resp[0].disponibles[0].total) : 0;
        /**se toma la cantidad de digitos para ubicar el número en el centro del spinner */
        this.puntos_feat = disponibles;
        this.total_digitos = disponibles.toString().trim().length;
        this.puntosProceso = proceso;
        this.puntosPorCalificar = xCalificar;
        this.isPuntosNull = false;
      })
      .catch((err: any) => {
        this.isPuntosNull = true;
      });
  }
  /**
   * Consultando limite de calificacion
   */
  public getLimitCalification() {
    this.rest
      .getJWT('/getDateConfigCalification')
      .toPromise()
      .then((resp: any) => {
        this.diasRestantes = resp.data;
      })
      .catch((err: any) => {});
  }
  /**
   * Metodo para obtener el minimo de puntos para redimir
   */
  public getPuntosConfiguracion() {
    this.rest
      .getJWT('codigos_niveles_all/')
      .toPromise()
      .then((resp: any) => {
        this.minPF = resp[0].nivelA;
      })
      .catch((err: any) => {
        this.isPuntosNull = true;
      });
  }
}
