<app-header-distribuidor></app-header-distribuidor>
<br />
<br />
<div class="row">
  <div class="col-1"></div>
  <div class="col-10">
    <!--Header-->
    <div class="row">
      <div class="col-2">
        <button class="boton-volver" routerLink="/inicio-distribuidor">
          <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }"></fa-icon>
          &nbsp;Volver
        </button>
      </div>
      <div class="col-10">
        <h3>Selecciona tu plan ideal</h3>
      </div>
    </div>
  </div>
  <div class="col-1"></div>
</div>
<br />
<!--Planes-->
<div class="row text-center fila-planes">
  <div class="col-1"></div>
  <!--La card que esté seleccionada tendrá en el card la clase "seleccionado"
  y en el card'body la clase "body-seleccionado"-->
  <!-- Tarjetas de planes -->
  <div *ngFor="let plan of planes; let i = index">
    <app-planes-card [isActual]="plan == actualPlan" [plan]="plan" [clickable]="i != 0"></app-planes-card>
  </div>
  <div class="col-1"></div>
</div>
<br />
