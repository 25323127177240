import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';
import { TransaccionService } from 'src/app/services/transaccion/transaccion.service';
declare const WidgetCheckout: any;

@Component({
  selector: 'app-actualiza-plan',
  templateUrl: './actualiza-plan.component.html',
  styleUrls: ['./actualiza-plan.component.css'],
})
export class ActualizaPlanComponent implements OnInit {
  /** Íconos */
  faChevronLeft = faChevronLeft;
  faWhatsapp = faWhatsapp;
  faCreditCard = faCreditCard;

  id: any;
  plan: any;
  valor = 0;

  chekoutModal: any;

  baseTransaction: any;

  vinculacion: any;

  colors = ['#f8c684', '#e8cd7a', '#a591f4', '#8e6ff7', '#4b3e9d'];

  ngbModalOptions: NgbModalOptions = {
    //Evita que al hacer click por fuera se cierre el modal
    backdrop: 'static',
    keyboard: false,
    centered: true,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private transaccionService: TransaccionService,
    private distribuidorService: DistribuidorService,
    public authService: AuthService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.loadPlan();
  }

  private async loadPlan() {
    const modalLoading = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    try {
      this.id = await this.route.snapshot.params.id;
      this.plan = await this.distribuidorService.getPlan(this.id).toPromise();
      this.plan.color = this.colors[Math.floor(Math.random() * this.colors.length)];
      this.valor = +this.plan.precio;
      if (!this.valor) {
        this.valor = 0;
        this.plan = null;
      }
    } catch (error) {
      console.error(error);
    }
    modalLoading.close();
  }

  private async getVinculacion() {
    const vinculacion = await this.distribuidorService
      .getOrCreateVinculacionPlan({
        distribuidor: this.authService?.user_distribuidor?._id,
        plan: this.plan._id,
      })
      .toPromise();

    return vinculacion;
  }

  private async addTransaccionVinculacion() {
    if (this.vinculacion.transaccion_actual) {
      const transaccion = await this.transaccionService.getTransaccion(this.vinculacion.transaccion_actual).toPromise();
      if (!transaccion.success) {
        throw new Error('No se pudo obtener la transacción');
      }
      if (transaccion.data.estado === 'Pendiente') {
        const modalRef = this.modalService.open(SimpleComponent);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Cuidado!';
        modalRef.componentInstance.msg =
          'Actualmente existe una transacción pendiente para este plan. Si continuas con la actualización, la transacción pendiente se cancelará.';
        modalRef.componentInstance.btn_msg = 'Continuar';
        await modalRef.closed.toPromise();
      }
    }

    const vinculacion = await this.distribuidorService
      .addTransaccionToVinculacionPlan(this.vinculacion._id, this.baseTransaction._id)
      .toPromise();
    return vinculacion;
  }

  private async createTransaction(): Promise<string> {
    const response = await this.transaccionService
      .createTransaccion({
        descripcion: 'Actualización de plan Feat: ' + this.plan.nombre + ' Vinculación:' + this.vinculacion._id,
        valor: this.valor,
      })
      .toPromise();
    return response.data;
  }

  private async configPayment() {
    this.vinculacion = await this.getVinculacion();

    if (this.vinculacion && this.vinculacion.estado === 'Activo' && this.vinculacion.fecha_fin > new Date()) {
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Ya tienes este plan activo, no hay necesidad de actualizarlo.';
      modalRef.componentInstance.btn_msg = 'Listo';
    } else if (this.vinculacion) {
      this.baseTransaction = await this.createTransaction();
      if (this.baseTransaction) {
        const new_vinculacion = await this.addTransaccionVinculacion();
        if (new_vinculacion) {
          this.vinculacion = new_vinculacion;
        } else {
          throw new Error('No se pudo actualizar la vinculación');
        }
      } else {
        throw new Error('No se pudo crear la transacción');
      }

      const customerData = {
        email: this.authService.user?.correo,
        fullName: this.authService.user?.nombres + ' ' + this.authService.user?.apellidos,
        phoneNumber: this.authService.user?.telefono,
        phoneNumberPrefix: '+57',
        legalId: this.authService.user?.numero_documento + '',
        legalIdType: 'CC',
      };

      this.chekoutModal = new WidgetCheckout({
        currency: 'COP',
        amountInCents: this.valor * 100,
        reference: this.baseTransaction._id,
        publicKey: 'pub_test_B7FCg3vOQjj5KziOYlNEEnvBceHojpiI',
        redirectUrl: 'https://transaction-redirect.wompi.co/check', //'https://develop_feat.imagineapps.co/consultar-pago-plan',
        customerData: customerData,
      });
    }
  }

  public async startPayment() {
    const modalLoading = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    try {
      await this.configPayment();
      this.openPaymentModal();
    } catch (error) {
      console.error('Error modal:', error);
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Ocurrió un error al procesar la transacción. Por favor, intente nuevamente.';
      modalRef.componentInstance.btn_msg = 'Listo';
      console.error('ERROR: ', error);
    }
    modalLoading.close();
  }

  public openPaymentModal() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.chekoutModal.open(async function (result: any) {
      const transaction = result.transaction;
      try {
        await self.transaccionService.vincularReferenciaWompi(self.baseTransaction._id, transaction.id).toPromise();
        const result = await self.distribuidorService.checkPlanPayment(self.vinculacion._id).toPromise();

        if (result.success) {
          if (result.data.updated && result.data.vinculacion.estado === 'Activo') {
            const modalRef = self.modalService.open(SimpleComponent);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-success-verde.png';
            modalRef.componentInstance.title = '¡Felicidades!';
            modalRef.componentInstance.msg = 'El pago se realizó correctamente. Ya tienes activo el nuevo plan.';
            modalRef.componentInstance.btn_msg = 'Listo';
            modalRef.componentInstance.close_callback = () => {
              self.router.navigate(['/inicio-distribuidor']);
            };
          } else if (result.data.vinculacion === 'Activo') {
            const modalRef = self.modalService.open(SimpleComponent);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-success-verde.png';
            modalRef.componentInstance.title = '¡Felicidades!';
            modalRef.componentInstance.msg = 'El plan ya se encuentra activo.';
            modalRef.componentInstance.btn_msg = 'Listo';
            modalRef.componentInstance.close_callback = () => {
              self.router.navigate(['/inicio-distribuidor']);
            };
          } else if (result.data.transaccion.estado == 'Rechazado') {
            const modalRef = self.modalService.open(SimpleComponent);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
            modalRef.componentInstance.title = '¡Oh oh!';
            modalRef.componentInstance.msg = 'El pago no se realizó correctamente. Por favor intenta nuevamente.';
            modalRef.componentInstance.btn_msg = 'Listo';
          } else {
            const modalRef = self.modalService.open(SimpleComponent);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-alert-amarillo.png';
            modalRef.componentInstance.title = 'Transacción pendiente';
            modalRef.componentInstance.msg =
              'La transacción está pendiente de revisión. Cuando se complete, podrá ver el nuevo plan activo.';
            modalRef.componentInstance.btn_msg = 'Listo';
            modalRef.componentInstance.close_callback = () => {
              self.router.navigate(['/inicio-distribuidor']);
            };
          }
        } else {
          throw new Error(result.error);
        }
      } catch (error) {
        console.error('Error modal:', error);
        const modalRef = self.modalService.open(SimpleComponent);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'Ocurrió un error al procesar la transacción. Por favor, intente nuevamente.';
        modalRef.componentInstance.btn_msg = 'Listo';
        modalRef.componentInstance.close_callback = () => {
          self.router.navigate(['/inicio-distribuidor']);
        };
      }
    });
  }
}
