<div class="container">
  <div>
    <span>Cambia la contraseña</span>
    <fa-icon
      [icon]="faTimes"
      class="cursorPointer"
      [styles]="{ color: '#41403E' }"
      id="fa-icon"
      (click)="close()"></fa-icon>
  </div>
  <span *ngIf="!clave_actual">Contraseña anterior</span>
  <div class="pass" *ngIf="!clave_actual">
    <input
      type="text"
      placeholder="Escribe aquí"
      [(ngModel)]="current"
      [type]="hideOld ? 'password' : 'text'"
      #contrasenaActual />
    <fa-icon
      class="cursorPointer"
      [icon]="hideOld ? faEye : faEyeSlash"
      [styles]="{ color: '#41403E' }"
      id="fa-icon"
      (click)="hideOld = !hideOld"></fa-icon>
  </div>
  <label *ngIf="mensajeError" for="" class="mensajeError">La contraseña actual es incorrecta</label>
  <span>Nueva contraseña</span>
  <div class="pass">
    <input
      type="text"
      placeholder="Crea una aquí"
      [(ngModel)]="newPass"
      [type]="hideNew ? 'password' : 'text'"
      #contrasenaNueva />
    <fa-icon
      class="cursorPointer"
      [icon]="hideNew ? faEye : faEyeSlash"
      [styles]="{ color: '#41403E' }"
      id="fa-icon"
      (click)="hideNew = !hideNew"></fa-icon>
  </div>

  <button
    class="purple-button"
    [ngClass]="{ disabledButton: newPass == '' }"
    (click)="onCambiarContrasena()"
    [disabled]="newPass == ''">
    Cambiar
  </button>
</div>
