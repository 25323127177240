<div class="contenedor py-4">
  <div class="header">
    <div>
      <img [src]="auth.user_distribuidor?.logo || company_Placeholder" alt="Logo de la empresa" />
      <div>
        <h5>{{ auth.user_distribuidor!.nombre }}</h5>
        <span>¡Hola {{ auth.user!.nombres }}!</span>
      </div>
    </div>
    <div class="cargo py-1 .">
      <span>{{ auth.user!.tipo_trabajador | titlecase }}</span>
    </div>
  </div>
  <hr />
  <div class="header">
    <div [routerLink]="flag_plan_activo ? ['/selecciona-plan'] : []" class="cursorPointer">
      <img src="../../../assets/img/principiante.png" alt="Logo de la empresa" />
      <div>
        <h5 class="m-0" *ngIf="flag_plan_activo">Plan: {{nombrePlan}}</h5>
        <span *ngIf="flag_plan_activo">{{ cantidadClientes }}/{{ cantidadMaxima }} establecimientos</span>
        <h5 class="m-0" *ngIf="!flag_plan_activo">Sé un eslabón</h5>
        <span *ngIf="!flag_plan_activo">Aprovecha y vincula tus clientes</span>
      </div>
    </div>
    <div class="calificacion p-3 d-flex flex-row cursorPointer" (click)="verCalificaciones()">
      <div class="mr-2">
        <fa-icon class="fa-icon-search" [icon]="faStar" [styles]="{'font-size': '25px'}">
        </fa-icon>
      </div>
      <div>
        <h5 class="m-0">
          {{ auth.user_distribuidor!.ranking }}
        </h5>
        <p class="m-0">
          Promedio calificación
        </p>
      </div>
    </div>
  </div>
  <hr />
  <div>
    <button (click)="onContrasena()" style="font-size:12px">Cambiar contraseña</button>
    <div class="info" style="cursor: pointer;" (click)="auth.signOut()" routerLink="/inicio">
      <fa-icon [icon]="faSignOutAlt" size="lg" color="#41403E">
      </fa-icon>
      <span>Cerrar sesión</span>
    </div>
  </div>
</div>