<div class="d-flex flex-column">
  <div id="distribuidores" class="col-12">
    <div class="titulo d-flex align-items-center justify-content-start rounded">
      <p class="main-title pl-1">Mis distribuidores</p>
    </div>
    <div class="encuentra rounded" routerLink="/distribuidores">
      <button id="btn-encuentra-dist" class="rounded sub-title">Encuentra distribuidor</button>
    </div>
    <div class="punto tarjeta rounded d-flex flex-row">
      <div class="col-2 d-flex justify-content-center align-items-center overflow-hidden p-0 rounded-left"
        id="icon-ubicacion">
        <img src="../../assets/img/icons/icon-pin-map.png" alt="Icono de un pin para señalar un punto en un mapa"
          srcset="" />
      </div>
      <div class="col-7 pr-0 d-flex justify-content-center align-items-center">
        <p class="p-card-ubicacion text-left w-100 sub-title m-0">
          Punto de entrega: {{ punto_entrega?.nombre || 'Ningún punto seleccionado' }}
        </p>
      </div>
      <div class="col-3 p-0 d-flex align-items-center justify-content-center">
        <h6 class="p-card-ubicacion px-2" id="p-cambiar-ubicacion" (click)="seleccionarPunto()">Cambiar</h6>
      </div>
    </div>
    <div (click)="onFiltrar()"
      class="filtro d-flex align-items-center justify-content-center align-items-center tarjeta">
      <p class="p-card">
        <fa-icon [icon]="faSlidersH" size="1x" [styles]="{ color: '#41403E' }"></fa-icon>
        &nbsp;Filtrar
      </p>
    </div>
  </div>
  <!--Distribuidores conectados-->
  <div class="col-12 mt-3 p-0">
    <div class="col-12 text-center" *ngIf="!distribuidores?.length">
      <img src="../../../assets/img/icon-warning-amarillo.png" alt="Advertencia" id="icon-warning" />
      <h5>Nada de nada</h5>
      <p>Todavía no tienes distribuidores ¿Qué estás esperando?</p>
    </div>

    <div class="w-100" *ngIf="distribuidores?.length">
      <!--Card distribuidor-->
      <div class="carousel-container">
        <div class="card-container pb-3 m-0">
          <div class="card" *ngFor="let dist of distribuidores"
            [routerLink]="'/inicio/distribuidores/' + dist.distribuidor._id" style="cursor: pointer">
            <div class="card-body"
              style="padding: 0.6rem !important; padding-left: 1rem !important; padding-right: 1rem !important">
              <!--A paz y salvo - Convenio-->
              <div class="d-flex justify-content-between align-items-center pt-4 mb-3" style="height: 2rem">
                <div>
                  <p *ngIf="dist.pazysalvo === true">
                    <fa-icon [icon]="faCircle" [styles]="{ color: '#c7d389' }" size="sm"></fa-icon>
                    &nbsp;A paz y salvo
                  </p>
                  <p *ngIf="dist.pazysalvo === false">
                    <fa-icon [icon]="faCircle" [styles]="{ color: '#d38989' }" size="sm"></fa-icon>
                    &nbsp;Pago pendiente
                  </p>
                </div>
                <!--Convenio-->
                <div>
                  <p class="pill-convenio" *ngIf="dist.convenio === true">$ Convenio</p>
                </div>
              </div>
              <!--Info distribuidor-->
              <div class="row w-100 m-0">
                <!--Logo-->
                <div class="col-3 pl-0">
                  <img [src]="dist.distribuidor.logo || distribuidor_placeholder"
                    style="max-width: 100%; height: auto; max-height: 100%"
                    [alt]="'Logo de ' + dist.distribuidor.nombre" class="rounded" />
                </div>
                <!--Nombre, tipo, compras-->
                <div class="col-7 d-flex flex-column align-items-between p-0">
                  <p class="info-dist">
                    {{ dist.distribuidor.nombre }}
                  </p>
                  <p class="info-dist order-count">
                    {{ dist.distribuidor.tipo }}
                  </p>
                  <p class="info-dist">
                    {{ dist.pedidos }} compra{{ dist.pedidos == 1 ? '' : 's' }} realizada{{
                    dist.pedidos == 1 ? '' : 's'
                    }}
                  </p>
                </div>
                <!--Ranking-->
                <div class="col-2">
                  <p style="width: 3rem !important">
                    <fa-icon [icon]="faStar" size="sm"></fa-icon>
                    {{ dist.distribuidor.ranking }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>