import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-noticia-card',
  templateUrl: './noticia-card.component.html',
  styleUrls: ['./noticia-card.component.css'],
})
export class NoticiaCardComponent implements OnInit {
  @Input() noticia: any;

  constructor() {}

  ngOnInit(): void {}

  public openNoticia(noticia: any) {
    if (noticia.tipo == 'Externa') {
      window.open(noticia.url, '_blank');
    } else {
      window.location.href = '/noticia-blog/' + noticia._id;
    }
  }
}
