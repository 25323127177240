import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-puntos-ft',
  templateUrl: './puntos-ft.component.html',
  styleUrls: ['./puntos-ft.component.css'],
})
export class PuntosFtComponent implements OnInit {
  active = 1;
  constructor() {}

  ngOnInit(): void {}
}
