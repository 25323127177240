import { Component } from '@angular/core';
import { faBell, faCommentDots, faClipboard, faStar, faUser, faBuilding } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-inicio-indicadores',
  templateUrl: './inicio-indicadores.component.html',
  styleUrls: ['./inicio-indicadores.component.css'],
})
export class InicioIndicadoresComponent {
  /** ID usuario y organizacion */
  public id_user: any;
  public id_horeca: any;
  /** Íconos FontAwesome para la UI */
  faBell = faBell;
  faCommentDots = faCommentDots;
  faClipboard = faClipboard;
  faStar = faStar;
  faUser = faUser;
  faBuilding = faBuilding;
  /** Guarda los banners publicitarios */
  public banners: any;
  /**Objeto para Indicadores del Dashboard principal */
  public indicador_notificaciones: any;
  public indicador_mensajes: any;
  public indicador_pedidos_sugeridos: any;
  public indicador_pedidos_realizados: any;
  public indicador_trabajadores_pendientes: any;
  public indicador_distribuidores_pendientes: any;
  constructor(private restService: RestService, private authService: AuthService) {}

  async ngOnInit() {
    this.id_horeca = await this.authService.user_horeca?._id;
    this.id_user = await this.authService.user?._id;
    this.getIndicadoresDashboard();
    this.getBanners();
  }

  /**
   * Recupera los indicadores del dashboard
   */
  getIndicadoresDashboard() {
    this.restService
      .getJWT(`indicadores/horeca/home/${this.id_horeca}/${this.id_user}`)
      .toPromise()
      .then((resp: any) => {
        if (resp.length > 0) {
          resp = resp[0];
        } else {
          return;
        }
        this.indicador_mensajes =
          resp.mensajes_nuevos.length > 0 && resp.mensajes_nuevos[0].total ? resp.mensajes_nuevos[0].total : 0;
        this.indicador_notificaciones =
          resp.notificaciones.length > 0 && resp.notificaciones[0].total ? resp.notificaciones[0].total : 0;
        this.indicador_pedidos_realizados =
          resp.pedidos.length > 0 && resp.pedidos[0].total ? resp.pedidos[0].total : 0;
        this.indicador_pedidos_sugeridos =
          resp.sugeridos.length > 0 && resp.sugeridos[0].total ? resp.sugeridos[0].total : 0;
        this.indicador_trabajadores_pendientes =
          resp.trabajadores_pend.length > 0 && resp.trabajadores_pend[0].total ? resp.trabajadores_pend[0].total : 0;
        this.indicador_distribuidores_pendientes =
          resp.solicitudes_dist.length > 0 && resp.solicitudes_dist[0].total ? resp.solicitudes_dist[0].total : 0;
      })
      .catch((err: any) => {
        throw err;
      });
  }

  /**
   * Devuelve los banners del home page
   */
  getBanners() {
    this.restService
      .getJWT('banner/Horeca')
      .toPromise()
      .then((res5: any) => {
        this.banners = res5.filter((element: any) => element.estado === 'Activo');
      })
      .catch((err: any) => {
        throw err;
      });
  }

  /**
   * Abre url de un banner seleccionado
   */
  goToLink(url: string) {
    window.open(url, '_blank');
  }
}
