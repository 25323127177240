import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-informacion-encargado-punto',
  templateUrl: './informacion-encargado-punto.component.html',
  styleUrls: ['./informacion-encargado-punto.component.css'],
})
export class InformacionEncargadoPuntoComponent implements OnInit {
  @Input() info_punto: any;
  @Input() info_horeca: any;
  @Input() info_distribuidor: any;
  @Input() info_encargado: any;
  @Input() vista_distribuidor = false;
  @Input() close_callback = () => {};
  /** Guarda los datos basicos del encargado luego de traducida la data */
  e_nombres = '';
  e_apellidos = '';
  e_correo = '';
  e_telefono = '';

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    if (this.vista_distribuidor === false) {
      /** Datos del encargado */
      if (this.info_punto?.informacion_contacto != '') {
        this.e_nombres = this.info_punto?.informacion_contacto[0];
        this.e_apellidos = this.info_punto?.informacion_contacto[1];
        this.e_correo = this.info_punto?.informacion_contacto[2];
        this.e_telefono = this.info_punto?.informacion_contacto[3];
      }
    } else {
      this.e_nombres = this.info_encargado.nombres;
      this.e_apellidos = this.info_encargado.apellidos;
      this.e_correo = this.info_encargado.correo;
      this.e_telefono = this.info_encargado.celular;
    }
  }

  onClose() {
    this.activeModal.close('Close click');
    this.close_callback();
  }
}
