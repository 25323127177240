<app-header-organizacion></app-header-organizacion>
<div class="d-flex justify-content-center">
    <div id="content">
        <div class="row">
            <div class="col-12">
                <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>Informes</a>
                        <ng-template ngbNavContent>
                            <app-informes-general-organizaciones></app-informes-general-organizaciones>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink>Gráficas de venta</a>
                        <ng-template ngbNavContent>
                            <app-graficas-ventas-organizaciones></app-graficas-ventas-organizaciones>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
        </div>
    </div>
</div>