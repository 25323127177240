<footer class="fondo">
  <div class="d-flex container justify-content-between py-3 pt-md-4">
    <div class="col-10 col-sm-10 col-lg-9">
      <img src="../../../assets/img/Footer/logo-footer.png" class="footer-logo" alt="logo de Feat">
      <div class="d-flex my-2 mt-md-4 terms-conditions">
        <span class="especification-footer mr-3">
          © Feat. All rights reserved.
        </span>
        <span class="especification-footer mr-3 cursorPointer" (click)="fetchPolitiasCondiciones('terminos')">
          Términos y Condiciones</span>
        <span class="especification-footer mr-3 cursorPointer" (click)="fetchPolitiasCondiciones('politicas')">
          Políticas de privacidad
        </span>
      </div>
    </div>
    <div class="d-flex logos col-2 col-sm-2 col-lg-3">
      <div>
        <img src="../../../assets/img/Footer/Grupo 54.png" class="redes-sociales cursorPointer" alt="logo de Facebook"
          (click)="verDocumento('https://www.facebook.com/feat.B4B')">
      </div>
      <div>
        <img src="../../../assets/img/Footer/Grupo 53.png" class="redes-sociales cursorPointer" alt="logo de Twitter"
          (click)="verDocumento('https://twitter.com/Feat_b4b')">
      </div>
      <div>
        <img src="../../../assets/img/Footer/Grupo 52.png" class="redes-sociales cursorPointer" alt="logo de Instagram"
          (click)="verDocumento('https://www.instagram.com/feat.b4b/')">
      </div>
    </div>
  </div>
</footer>