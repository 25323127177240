import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DeleteToken, GetTokens as GetToken, SetSelectedToken } from '../actions/token.actions';
import { Distribuidor } from '../models/distribuidor.model';
import { Trabajador } from '../models/trabajador.model';
import { UsuarioHoreca } from '../models/usuario-horeca.model';

export class TokenStateModel {
  email = '';
  selectedToken = '';
  user?: Trabajador;
  user_horeca?: UsuarioHoreca;
  user_distribuidor?: Distribuidor;
  user_organizacion?: any;
}

@State<TokenStateModel>({
  name: 'auth',
  defaults: {
    email: '',
    selectedToken: '',
    user: undefined,
    user_horeca: undefined,
    user_distribuidor: undefined,
    user_organizacion: undefined,
  },
})
@Injectable()
export class TokenState {
  rawToken = '';
  email = '';
  user?: Trabajador;
  user_horeca?: UsuarioHoreca;
  user_distribuidor?: Distribuidor;
  user_organizacion?: any;

  @Selector()
  static getSelectedToken(state: TokenStateModel) {
    return state.selectedToken;
  }

  @Selector()
  static getUser(state: TokenStateModel) {
    return state.user;
  }

  @Selector()
  static getUserHoreca(state: TokenStateModel) {
    return state.user_horeca;
  }

  @Selector()
  static getEmail(state: TokenStateModel) {
    return state.email;
  }

  @Action(GetToken)
  getToken({ getState }: StateContext<TokenStateModel>) {
    return getState().selectedToken;
  }

  @Action(DeleteToken)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  deleteToken({ getState, setState }: StateContext<TokenStateModel>, _pDelete: DeleteToken) {
    const state = getState();
    this.rawToken = '';
    setState({
      ...state,
      email: '',
      selectedToken: '',
      user: undefined,
      user_horeca: undefined,
      user_distribuidor: undefined,
      user_organizacion: undefined,
    });
  }

  @Action(SetSelectedToken)
  setSelectedTodoId({ getState, setState }: StateContext<TokenStateModel>, { payload }: SetSelectedToken) {
    const state = getState();
    this.rawToken = payload.token;
    this.email = payload.email;
    this.user = payload.user;
    this.user_horeca = payload.user_horeca;
    this.user_distribuidor = payload.user_distribuidor;
    this.user_organizacion = payload.user_organizacion;
    setState({
      ...state,
      email: payload.email,
      selectedToken: payload.token,
      user: payload.user,
      user_horeca: payload.user_horeca,
      user_distribuidor: payload.user_distribuidor,
      user_organizacion: payload.user_organizacion,
    });
  }
}
