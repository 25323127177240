<app-header></app-header>
<div class="contenedor">
  <!-- Header -->
  <div class="row">
    <!--<div class="col-12 d-flex justify-content-between row">
      <div>
        <h2>Entérate de todo</h2>
      </div>
      <div>
        <div class="search d-flex">
          <img
            src="../../assets/img/icons/icono-search.png"
            class="icono m-3"
            alt="Simbolo de lupa para input de busqueda" />
          <input type="text" placeholder="Encuentra una noticia" class="search-input" (input)="filterResults($event)" />
        </div>
      </div>
    </div>-->
    <div class="col-12">
      <div class="row">
        <div class="col-md-6">
          <h2>Entérate de todo</h2>
        </div>
        <div class="col-md-6 search  d-flex">
          <img
          src="../../assets/img/icons/icono-search.png"
          class="icono m-3"
          alt="Simbolo de lupa para input de busqueda" />
        <input type="text" placeholder="Encuentra una noticia" class="search-input" (input)="filterResults($event)" />
        </div>
      </div>
      
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-md-4 margin-top-card" *ngFor="let noticia of filteredNoticias">
          <app-noticia-card [noticia]="noticia"></app-noticia-card>
        </div>
      </div>
    </div>
    <!-- Noticias -->
    <div class="col-12">
      <h5 class="text-center m-auto" *ngIf="(filteredNoticias?.length || 0) <= 0">No se encontraron noticias.</h5>
    </div>
  </div>

</div>
