<div class="container">
  <div class="main-pedidos" *ngIf="puntoSeleccionado !== 'todos'">
    <!--Barra de búsquedas-->
    <div class="col-12 p-0 mb-4">
      <div class="card card-search m-0">
        <div class="card-body card-search">
          <div class="barra-busqueda">
            <div class="d-flex flex-row autocompletado-busqueda px-0 h-100 w-100">
              <div class="col-1 text-center">
                <fa-icon class="fa-icon-search m-0" [icon]="faSearch" size="lg" [styles]="{ color: '#41403E' }">
                </fa-icon>
              </div>
              <form class="example-form col-11 pr-0">
                <mat-form-field class="example-full-width">
                  <input #escogido type="text" placeholder="Buscar por nombre del distribuidor"
                    aria-label="Buscar distribuidor" matInput [formControl]="mySearch" [matAutocomplete]="auto"
                    class="w-100 h-100 input-busqueda pr-0">
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" [displayWith]="itemDisplayFn"
                    class="w-100" (optionSelected)='verDetalleDistribuidor($event)'>
                    <mat-option *ngFor="let option of filteredOptions  | async" [value]="option">
                      {{option?.nombre_distribuidor}} | {{option?.estado}} | {{option?.fecha}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Pedidos en curso-->
    <ng-template #mostrarError>
      <div class="error-message">
        <p>
          !No se encontraron pedidos!
        </p>
      </div>
    </ng-template>
    <div class="my-3 w-100">
      <strong class="m-0 text-rigth">Total pedidos ({{ pedidos_filtrados.length }})</strong>
    </div>
    <!-- Pendientes -->
    <span *ngIf="auth.user?.tipo_trabajador === 'PLANEADOR PEDIDOS'">
      <p class="mt-3 mb-0">Pendientes ({{ pedidosPendientes.length }})</p>
      <div class="card-container d-flex flex-row" *ngIf="pedidosPendientes.length > 0 else mostrarError">
        <div class="card card-pedido p-3 mr-3" *ngFor="let ped of pedidosPendientes; index as i;"
          [routerLink]="'/pedidos/' + ped._id">
          <div>
            <div class="col-12" style="cursor: pointer;">
              <fa-icon [icon]="faCircle" size="lg" [styles]="{'color': '#D2D2D2'}"></fa-icon>
              &nbsp;
              {{ ped.estado }}
            </div>
          </div>
          <hr />
          <div class="row h-100">
            <div class="col-3 pr-0">
              <img src="{{ ped.logo || distribuidor_placeholder }}" [alt]="ped.nombre_distribuidor + ' logo'" />
            </div>
            <div class="col-9 d-flex flex-column justify-content-between">
              <h5 class="m-0">{{ ped.nombre_distribuidor }}</h5>
              <p>{{ ped.fecha }}</p>
              <p class="d-flex flex-row justify-content-between">
                Precio total: <span> {{ ped.precio | currency:'$':'symbol':'1.0-0' }} </span>
              </p>
              <p class="d-flex justify-content-between">ID Pedido: <span>{{ ped.id_pedido }}</span></p>
              <p class="d-flex justify-content-between">
                {{ ped.productos }} producto{{ ped.productos == 1 ? '' : 's' }} <span>-</span> {{ ped.unidades }}
                unidad{{ ped.unidades == 1 ? '' : 'es'}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </span>
    <!-- Aprobados Interno -->
    <p class="mt-3 mb-0">Aprobados Interno ({{ pedidosAprobadosInterno.length }})</p>
    <div class="card-container d-flex flex-row" *ngIf="pedidosAprobadosInterno.length > 0 else mostrarError">
      <div class="card card-pedido p-3 mr-3" *ngFor="let ped of pedidosAprobadosInterno; index as i;"
        [routerLink]="'/pedidos/' + ped._id">
        <div>
          <div class="col-12" style="cursor: pointer;">
            <fa-icon [icon]="faCircle" size="lg" [styles]="{'color': '#FDEAEA'}"></fa-icon>
            &nbsp;
            {{ ped.estado }}
          </div>
        </div>
        <hr />
        <div class="row h-100">
          <div class="col-3 pr-0">
            <img src="{{ ped.logo || distribuidor_placeholder }}" [alt]="ped.nombre_distribuidor + ' logo'" />
          </div>
          <div class="col-9 d-flex flex-column justify-content-between">
            <h5 class="m-0">{{ ped.nombre_distribuidor }}</h5>
            <p>{{ ped.fecha }}</p>
            <p class="d-flex flex-row justify-content-between">
              Precio total: <span> {{ ped.precio | currency:'$':'symbol':'1.0-0' }} </span>
            </p>
            <p class="d-flex justify-content-between">ID Pedido: <span>{{ ped.id_pedido }}</span></p>
            <p class="d-flex justify-content-between">
              {{ ped.productos }} producto{{ ped.productos == 1 ? '' : 's' }} <span>-</span> {{ ped.unidades }}
              unidad{{ ped.unidades == 1 ? '' : 'es'}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Aprobados Externo -->
    <p class="mt-3 mb-0">Aprobados Externo ({{ pedidosAprobadosExterno.length }})</p>
    <div class="card-container d-flex flex-row" *ngIf="pedidosAprobadosExterno.length > 0 else mostrarError">
      <div class="card card-pedido p-3 mr-3" *ngFor="let ped of pedidosAprobadosExterno; index as i;"
        [routerLink]="'/pedidos/' + ped._id">
        <div>
          <div class="col-12" style="cursor: pointer;">
            <fa-icon [icon]="faCircle" size="lg" [styles]="{'color': '#FAF1CC'}"></fa-icon>
            &nbsp;
            {{ ped.estado }}
          </div>
        </div>
        <hr />
        <div class="row h-100">
          <div class="col-3 pr-0">
            <img src="{{ ped.logo || distribuidor_placeholder }}" [alt]="ped.nombre_distribuidor + ' logo'" />
          </div>
          <div class="col-9 d-flex flex-column justify-content-between">
            <h5 class="m-0">{{ ped.nombre_distribuidor }}</h5>
            <p>{{ ped.fecha }}</p>
            <p class="d-flex flex-row justify-content-between">
              Precio total: <span> {{ ped.precio | currency:'$':'symbol':'1.0-0' }} </span>
            </p>
            <p class="d-flex justify-content-between">ID Pedido: <span>{{ ped.id_pedido }}</span></p>
            <p class="d-flex justify-content-between">
              {{ ped.productos }} producto{{ ped.productos == 1 ? '' : 's' }} <span>-</span> {{ ped.unidades }}
              unidad{{ ped.unidades == 1 ? '' : 'es'}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Alistamiento -->
    <p class="mt-3 mb-0">Alistamiento ({{ pedidosAlistamientos.length }})</p>
    <div class="card-container d-flex flex-row" *ngIf="pedidosAlistamientos.length > 0 else mostrarError">
      <div class="card card-pedido p-3 mr-3" *ngFor="let ped of pedidosAlistamientos; index as i;"
        [routerLink]="'/pedidos/' + ped._id">
        <div>
          <div class="col-12" style="cursor: pointer;">
            <fa-icon [icon]="faCircle" size="lg" [styles]="{'color': '#EEF2DA'}"></fa-icon>
            &nbsp;
            {{ ped.estado }}
          </div>
        </div>
        <hr />
        <div class="row h-100">
          <div class="col-3 pr-0">
            <img src="{{ ped.logo || distribuidor_placeholder }}" [alt]="ped.nombre_distribuidor + ' logo'" />
          </div>
          <div class="col-9 d-flex flex-column justify-content-between">
            <h5 class="m-0">{{ ped.nombre_distribuidor }}</h5>
            <p>{{ ped.fecha }}</p>
            <p class="d-flex flex-row justify-content-between">
              Precio total: <span> {{ ped.precio | currency:'$':'symbol':'1.0-0' }} </span>
            </p>
            <p class="d-flex justify-content-between">ID Pedido: <span>{{ ped.id_pedido }}</span></p>
            <p class="d-flex justify-content-between">
              {{ ped.productos }} producto{{ ped.productos == 1 ? '' : 's' }} <span>-</span> {{ ped.unidades }}
              unidad{{ ped.unidades == 1 ? '' : 'es'}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Despachado -->
    <p class="mt-3 mb-0">Despachado ({{ pedidosDespachados.length }})</p>
    <div class="card-container d-flex flex-row" *ngIf="pedidosDespachados.length > 0 else mostrarError">
      <div class="card card-pedido p-3 mr-3" *ngFor="let ped of pedidosDespachados; index as i;"
        [routerLink]="'/pedidos/' + ped._id">
        <div>
          <div class="col-12" style="cursor: pointer;">
            <fa-icon [icon]="faCircle" size="lg" [styles]="{'color': '#E6F0B4'}"></fa-icon>
            &nbsp;
            {{ ped.estado }}
          </div>
        </div>
        <hr />
        <div class="row h-100">
          <div class="col-3 pr-0">
            <img src="{{ ped.logo || distribuidor_placeholder }}" [alt]="ped.nombre_distribuidor + ' logo'" />
          </div>
          <div class="col-9 d-flex flex-column justify-content-between">
            <h5 class="m-0">{{ ped.nombre_distribuidor }}</h5>
            <p>{{ ped.fecha }}</p>
            <p class="d-flex flex-row justify-content-between">
              Precio total: <span> {{ ped.precio | currency:'$':'symbol':'1.0-0' }} </span>
            </p>
            <p class="d-flex justify-content-between">ID Pedido: <span>{{ ped.id_pedido }}</span></p>
            <p class="d-flex justify-content-between">
              {{ ped.productos }} producto{{ ped.productos == 1 ? '' : 's' }} <span>-</span> {{ ped.unidades }}
              unidad{{ ped.unidades == 1 ? '' : 'es'}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="puntoSeleccionado === 'todos'"  class="main-pedidos text-center">
    <img src="../../assets/img/icon-warning-amarillo.png"
        alt="Icono de advertencia" class="icon-ad"/>
      <h5 class="my-2">Para revisar tus pedidos primero <br> selecciona un punto de entrega</h5>
  </div>
  <div class="second-pedidos">
    <div class="col-12">
      <h6>Selecciona un punto de entrega</h6>
    </div>
    <div class="col-12">
      <select name="" id="" class="selectPunto" [(ngModel)]="puntoSeleccionado"
            (ngModelChange)="filtrarPedidos()">
            <option value="todos">
              Seleccionar
            </option>
            <option [value]="punto._id" *ngFor="let punto of listaPuntos">
              {{punto.nombre}}
            </option>
          </select>
    </div>
    <div class="col-12" *ngIf="puntoSeleccionado !== 'todos' && pedidos_sugeridos_view">
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills pestanas-pedidos">
        <!-- Pedidos Sugeridos -->
        <li [ngbNavItem]="1">
          <a ngbNavLink class="p-2">Pedidos sugeridos <span *ngIf="puntoSeleccionado !== 'todos'">({{ pedidos_sugeridos.length }})</span> </a>
          <ng-template ngbNavContent>
            <div *ngIf="puntoSeleccionado !== 'todos'">
              <div class="row" *ngIf="pedidos_sugeridos.length != 0">
                <div class="col-12 sugerido-aprobado" *ngFor="let ped of pedidos_sugeridos; index as i;">
                  <div class="card card-pedido d-flex flex-row w-100 p-3 m-0">
                    <div class="col-3 pl-0">
                      <img class="w-100" src="{{ ped.logo || distribuidor_placeholder }}"
                        [alt]="ped.nombre_distribuidor + ' logo'" />
                    </div>
                    <div class="col-9 d-flex row align-content-between pr-0">
                      <div class="w-100 d-flex flex-column justify-content-between">
                        <h5 class="m-0">{{ ped.nombre_distribuidor }}</h5>
                        <p class="d-flex justify-content-between">ID Pedido: <span>{{ ped.id_pedido }}</span></p>
                        <p>{{ ped.fecha }}</p>
                        <p class="d-flex flex-row justify-content-between">
                          Precio total: <span> {{ ped.precio | currency:'$':'symbol':'1.0-0' }} </span>
                        </p>
                        <p class="d-flex justify-content-between">
                          {{ ped.productos }} producto{{ ped.productos == 1 ? '' : 's' }} <span>-</span> {{ ped.unidades
                          }}
                          unidad{{ ped.unidades == 1 ? '' : 'es'}}
                        </p>
                      </div>
                      <button class="btn-revisar" (click)="goSugerido(ped._id)">
                        Revisar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="pedidos_sugeridos.length == 0">
                <div class="col-12 text-center">
                  <h5>
                    No tienes pedidos sugeridos
                  </h5>
                </div>
              </div>
            </div>
           
          </ng-template>
        </li>
        <!-- Pedidos por Aprobar -->
        <li [ngbNavItem]="2" *ngIf="auth.user?.tipo_trabajador !== 'PLANEADOR PEDIDOS'">
          <a ngbNavLink class="p-2">
            Pedidos por aprobar <span *ngIf="puntoSeleccionado !== 'todos'">({{ pedidos_por_aprobar.length }})</span>
          </a>
          <ng-template ngbNavContent>
            <div *ngIf="puntoSeleccionado !== 'todos'">
              <div class="row" *ngIf="pedidos_por_aprobar.length != 0">
                <div class="col-12 sugerido-aprobado" *ngFor="let ped of pedidos_por_aprobar; index as i;">
                  <div class="card card-pedido d-flex flex-row w-100 p-3 m-0">
                    <div class="col-3 pl-0">
                      <img class="w-100" src="{{ ped.logo || distribuidor_placeholder }}"
                        [alt]="ped.nombre_distribuidor + ' logo'" />
                    </div>
                    <div class="col-9 d-flex row align-content-between pr-0">
                      <div class="w-100 d-flex flex-column justify-content-between">
                        <h5 class="m-0">{{ ped.nombre_distribuidor }}</h5>
                        <p class="d-flex justify-content-between">ID Pedido: <span>{{ ped.id_pedido }}</span></p>
                        <p>{{ ped.fecha }}</p>
                        <p class="d-flex flex-row justify-content-between">
                          Precio total: <span> {{ ped.precio | currency:'$':'symbol':'1.0-0' }} </span>
                        </p>
                        <p class="d-flex justify-content-between">
                          {{ ped.productos }} producto{{ ped.productos == 1 ? '' : 's' }} <span>-</span> {{ ped.unidades
                          }}
                          unidad{{ ped.unidades == 1 ? '' : 'es'}}
                        </p>
                      </div>
                      <button class="btn-revisar" [routerLink]="'/pedidos/sugeridos/' + ped._id">
                        Revisar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="pedidos_por_aprobar.length == 0">
                <div class="col-12 text-center">
                  <h5>
                    No tienes pedidos por aprobar
                  </h5>
                </div>
              </div>
            </div>
           
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2" ></div>
    </div>
  </div>
</div>