import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portafolio-organizacion',
  templateUrl: './portafolio-organizacion.component.html',
  styleUrls: ['./portafolio-organizacion.component.css'],
})
export class PortafolioOrganizacionComponent implements OnInit {
  /** Variable de control para la navegación del panel */
  active = 1;
  constructor() {}

  ngOnInit(): void {}
}
