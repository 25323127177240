import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CambiarContrasenaComponent } from 'src/app/modal/cambiar-contrasena/cambiar-contrasena.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalificacionesDistribuidorComponent } from 'src/app/modal/calificaciones-distribuidor/calificaciones-distribuidor.component';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';

@Component({
  selector: 'app-informacion-distribuidor',
  templateUrl: './informacion-distribuidor.component.html',
  styleUrls: ['./informacion-distribuidor.component.css'],
})
export class InformacionDistribuidorComponent implements OnInit, OnDestroy {
  /** Icons */
  faStar = faStar;
  faSignOutAlt = faSignOutAlt;

  /** Placeholder para productos sin imagen */
  product_Placeholder = '../../assets/img/product-placeholder.png';
  company_Placeholder = '../../assets/img/icon-organizacion.png';

  /** Info Plan */
  flag_plan_activo = false;
  nombrePlan = 'Principiantes';
  cantidadClientes = 0;
  cantidadMaxima = 0;
  modalCambioClave: any;

  constructor(
    public auth: AuthService,
    private router: Router,
    private modalService: NgbModal,
    private distribuidorService: DistribuidorService
  ) {}

  ngOnInit(): void {
    this.getInfoDistribuidor();
  }

  ngOnDestroy() {
    this.modalCambioClave?.close();
  }

  async getInfoDistribuidor() {
    const id = this.auth.user_distribuidor?._id || '';
    this.cantidadClientes = await this.distribuidorService.getClientsLength(id).toPromise();
    this.distribuidorService
      .getCurrentPlan(id)
      .toPromise()
      .then((resp: any) => {
        if (resp.plan) {
          this.nombrePlan = resp.plan?.nombre || '';
        }
        //this.flag_plan_activo = true;
      })
      .catch((err: any) => {
        console.log(err);
        //this.flag_plan_activo = false;
      });
    const cantidad = (await this.distribuidorService.getDistribuidorData(id).toPromise()).max_establecimientos;
    this.cantidadMaxima = cantidad || '+2000';
    this.cantidadClientes = this.cantidadClientes > this.cantidadMaxima ? this.cantidadMaxima : this.cantidadClientes;
  }

  /**
   * Permite cerrar sesión y navega al inicio de la plataforma
   */
  signOut() {
    this.auth.signOut();
    this.router.navigateByUrl('/home');
  }

  onContrasena() {
    this.modalCambioClave = this.modalService.open(CambiarContrasenaComponent, {
      centered: true,
      windowClass: 'modal-cambiar-contrasena',
    });
  }

  verCalificaciones() {
    this.modalService.open(CalificacionesDistribuidorComponent, {
      centered: true,
      windowClass: 'modal-cambiar-contrasena',
    });
  }
}
