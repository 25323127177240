import { Component, OnInit } from '@angular/core';
import {
  faChevronLeft,
  faCircle,
  faMapMarkerAlt,
  faPhoneAlt,
  faTimes,
  faTruck,
  faArrowAltCircleRight,
} from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { PedidosService } from '../../services/pedidos/pedidos.service';
import { DistribuidorService } from '../../services/distribuidor/distribuidor.service';
import { ProductsService } from '../../services/products/products.service';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CalificacionPedidoComponent } from 'src/app/modal/calificacion-pedido/calificacion-pedido.component';
import { RestService } from '../../services/rest/rest.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { SimpleComponent } from '../../modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { InformacionEncargadoPuntoComponent } from 'src/app/modal/informacion-encargado-punto/informacion-encargado-punto.component';
import { ConfirmacionComponent } from 'src/app/modal/confirmacion/confirmacion.component';
import { CarritoService } from 'src/app/services/carrito/carrito.service';

@Component({
  selector: 'app-detalle-pedido',
  templateUrl: './detalle-pedido.component.html',
  styleUrls: ['./detalle-pedido.component.css'],
})
export class DetallePedidoComponent implements OnInit {
  // Referencias a íconos FontAwesome
  public faChevronLeft = faChevronLeft;
  public faMapMarkerAlt = faMapMarkerAlt;
  public faCircle = faCircle;
  public faTimes = faTimes;
  public faTruck = faTruck;
  public faPhoneAlt = faPhoneAlt;
  public faArrowAltCircleRight = faArrowAltCircleRight;
  // Variables de control para la barra de estado
  public num_estado = 0;
  // Variables
  public idParam: any;
  public pedido: any;
  public idCodigo: any;
  public codDescuento: any;
  public distribuidor: any;
  public product: any[] = [];
  public idProduct: any;
  public productos: any;
  public products: any;
  public completedProduct: any[] = [];
  public trackingPedido: any;
  public idPedido: any;
  public calificacion: any;
  // Variables boolean para validacion de si puedo o no acceder al cha
  public validadorMensaje?: boolean = false;
  // Variables boolean para ver la prefactura de un pedido segun su estado
  public validacionVerPrefactura?: boolean = true;
  // Variables boolean para habilitar la opcion de cancelar un pedid
  public validacionCancelarPedido?: boolean = false;
  // Variables boolean para habilitar la opcion de editar un pedid
  public validacionBotonEditar?: boolean = false;
  // Variables cantidad de productos que tiene el pedid
  public cantProductos: number | undefined;
  // Cantidad de unidades incluyendo lo que tienen las cajas de lo que tiene el pedid
  public cantUnidades: number | undefined;
  // nombre visible punto entreg
  public nombrePuntoEntrega: string | undefined;
  // Imagen producto placeholder
  public product_placeholder = '../../../assets/img/product-placeholder.png';
  public distribuidor_placeholder = '../../../assets/img/icon-organizacion.png';
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;
  // Guarda la hora y fecha de modificado el pedido
  public fecha_estado = '';
  public hora_estado = '';
  // Cargando generíco
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };
  // Flag detalle distribuidor
  public flag_tiempo_total_entrega = false;
  // Tiempos totales pedido finalziado
  public tiempos_pedido_finalizado: any;
  estadosVolverPedir: any = ['Aprobado Externo', 'Despachado', 'Alistamiento', 'Entregado', 'Recibido', 'Calificado'];

  constructor(
    private pedidosService: PedidosService,
    private activatedRoute: ActivatedRoute,
    private distribuidorService: DistribuidorService,
    private productsService: ProductsService,
    private modalService: NgbModal,
    private restService: RestService,
    private router: Router,
    public localStorage: LocalStorageService,
    public authService: AuthService,
    private cartservice: CarritoService,
    public activeModal: NgbActiveModal
  ) {
    this.fetchData();
  }

  close() {
    this.activeModal.close();
  }

  async ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.idParam = params.id;
    });
    const resp_estado: any = await this.restService.getJWT(`pedido/ultimoTracking/${this.idParam}`).toPromise();
    this.fetchTrackingPedido(resp_estado.estado_nuevo);
    this.processTimeDate(resp_estado.createdAt);
    this.getFlagTiempoTotalEntrega(resp_estado);
  }

  /**
   * Decide si mostrar el detalle de tiempos y organiza la data
   * Si el pedido ya fue entregado no muestra el detalle del distribuidor
   * Debe mostrar el total del tiempo de entrega y detalles
   */
  async getFlagTiempoTotalEntrega(resp_estado: any) {
    if (
      resp_estado.estado_nuevo == 'Entregado' ||
      resp_estado.estado_nuevo == 'Recibido' ||
      resp_estado.estado_nuevo == 'Calificado'
    ) {
      this.tiempos_pedido_finalizado = await this.restService
        .getJWT(`pedido/tiempo_entrega/${this.idParam}`)
        .toPromise();
      this.tiempos_pedido_finalizado = this.tiempos_pedido_finalizado[0];
      // Tiempo total entrega
      if (this.tiempos_pedido_finalizado.total_horas) {
        this.tiempos_pedido_finalizado.total_horas = Math.round(this.tiempos_pedido_finalizado.total_horas * 100) / 100;
      }
      // Tiempo aprobado
      if (this.tiempos_pedido_finalizado.aprobado) {
        const fecha = new Date(this.tiempos_pedido_finalizado.aprobado).toLocaleString('en-US').split(',')[0].trim();
        const hora = new Date(this.tiempos_pedido_finalizado.aprobado).toLocaleString('en-US').split(',')[1].trim();
        this.tiempos_pedido_finalizado.aprobado = fecha + ' ' + hora;
      }
      // Tiempo entregado
      if (this.tiempos_pedido_finalizado.entregado) {
        const fecha = new Date(this.tiempos_pedido_finalizado.entregado).toLocaleString('en-US').split(',')[0].trim();
        const hora = new Date(this.tiempos_pedido_finalizado.entregado).toLocaleString('en-US').split(',')[1].trim();
        this.tiempos_pedido_finalizado.entregado = fecha + ' ' + hora;
      }
      // Flag ventana tiempos de entrega, solo se muestra si estan los 3 datos
      if (
        this.tiempos_pedido_finalizado.total_horas &&
        this.tiempos_pedido_finalizado.entregado &&
        this.tiempos_pedido_finalizado.aprobado
      ) {
        this.flag_tiempo_total_entrega = true;
      }
    }
  }

  /**
   * Trae información de productos, pedidos y distribuidor para el detalle
   */
  fetchData() {
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    this.activatedRoute.params.subscribe((params: Params) => {
      this.idParam = params.id;
      try {
        // Pedido
        this.pedidosService.getMyPedidos(this.idParam).subscribe((res) => {
          this.modalCarga?.close();
          this.pedido = res;
          // Tracking Pedido
          this.products = res.productos;
          //console.log('this.products', this.products)
          this.cantProductos = res.productos.length;
          this.idCodigo = res.codigo_descuento;
          this.pedidosService.getPuntoEntrega(res.punto_entrega._id).subscribe((res) => {
            this.nombrePuntoEntrega = res.nombre;
          });
          // Código descuento
          this.pedidosService.getCodigo(this.idCodigo).subscribe((result) => {
            this.codDescuento = result;
            this.distribuidorService.getDistribuidorData(this.pedido.distribuidor).subscribe((result) => {
              this.distribuidor = result;
              for (const key in this.products) {
                this.idProduct = this.products[key].product;
                this.productsService.getFilteredProducts(this.idProduct).subscribe((resp) => {
                  //console.log('resp',resp);
                  //console.log('this.products[key]', this.products[key])
                  
                  const mergeData = { ...this.products[key], ...resp };
                  for (const precio in resp.precios) {
                    mergeData[`valor_caja${precio}`] = resp.precios[precio].precio_caja;
                  }
                  this.completedProduct.push(mergeData);
                });
              }
              this.cantUnidades = this.calculoUnidades(this.products);
            });
          });
          // Si es planeador de Pedidos y este pedido no es suyo, sale de la pagina
          if (
            this.authService.user?.tipo_trabajador === 'PLANEADOR PEDIDOS' &&
            this.authService.user?._id !== res.trabajador
          ) {
            this.router.navigate(['/pedidos']);
            const modalRef = this.modalService.open(SimpleComponent);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
            modalRef.componentInstance.title = '¡Oh oh!';
            modalRef.componentInstance.msg =
              'Lo sentimos, pero no puedes acceder a este pedido dado que no hace parte de tu portafolio';
            modalRef.componentInstance.btn_msg = 'Volver a Pedidos';
          }
        });
      } catch (error) {
        this.modalCarga?.close();
        const modalRef = this.modalService.open(SimpleComponent);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = `No fue posible recuperar la información del encargado, intenta mas tarde.`;
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          window.location.reload();
        };
      }
    });
  }

  /**
   * Información de Tracking del pedido para validación de estado en cuanto mensajes chat y prefactura
   */
  fetchTrackingPedido(estado_pedido: any) {
    // Validacion correcto en la barra
    switch (estado_pedido) {
      case 'Pendiente':
        this.num_estado = 1;
        break;

      case 'Aprobado Interno':
        this.num_estado = 2;
        break;

      case 'Aprobado Externo':
        this.num_estado = 3;
        break;

      case 'Alistamiento':
        this.num_estado = 4;
        break;

      case 'Despachado':
        this.num_estado = 5;
        break;

      case 'Facturado':
        this.num_estado = 5;
        break;

      case 'Entregado':
        this.num_estado = 6;
        break;

      case 'Recibido':
        this.num_estado = 7;
        break;

      case 'Calificado':
        this.num_estado = 8;
        break;

      default:
        break;
    }
    // Validación de Mensajes
    if (
      estado_pedido == 'Aprobado Interno' ||
      estado_pedido == 'Aprobado Externo' ||
      estado_pedido == 'Alistamiento' ||
      estado_pedido == 'Despachado' ||
      estado_pedido == 'Pendiente' ||
      estado_pedido == 'Facturado'
    ) {
      this.validadorMensaje = true;
    }

    // Validación ver pre-factura
    if (estado_pedido == 'Sugerido') {
      this.validacionVerPrefactura = false;
    }

    // Validación cancelar pedido
    if (
      estado_pedido == 'Alistamiento' ||
      estado_pedido == 'Pendiente' ||
      estado_pedido == 'Aprobado Interno' ||
      estado_pedido == 'Aprobado Externo'
    ) {
      this.validacionCancelarPedido = true;
    }
    // Validación editar pedido
    if (estado_pedido === 'Pendiente' || estado_pedido === 'Aprobado Interno') {
      this.validacionBotonEditar = true;
    }
  }

  /**
   * Modal para calificación de un pedido después de
   * ser entregado y validado por el punto de entrega
   */
  onCalificar() {
    const modalRef = this.modalService.open(CalificacionPedidoComponent, {
      centered: true,
      windowClass: 'modal-calificacion',
    });
    modalRef.componentInstance.pedidos = this.idParam;
    modalRef.componentInstance.estado_pedido = this.pedido.estado;
    modalRef.componentInstance.nombre_distribuidor = this.distribuidor?.nombre;
    modalRef.componentInstance.logo_distribuidor = this.distribuidor?.logo;
    modalRef.componentInstance.fecha_pedido = this.fecha_estado + ' a las ' + this.hora_estado;
    modalRef.componentInstance.total_productos = this.cantProductos;
  }

  calculoUnidades(productos: any) {
    let unidades = 0;
    for (const iterator of productos) {
      unidades = unidades + iterator.unidad;
    }
    return unidades;
  }

  /**
   * Calcula las unidades pendientes a pedir para completar otra caja
   * @param und_x_caja unidades que vienen por caja.
   * @param total_unidades total unidades pedidas por el usuario de un producto.
   */
  totalCajas(und_x_caja: number, total_unidades: number) {
    if (und_x_caja !== 0) {
      const total_cajas = Math.round((total_unidades / und_x_caja) * 100) / 100;
      return total_cajas;
    } else {
      return 0;
    }
  }
  goUrlPago(url: string) {
    window.open(url, '_blank');
  }
  /**
   * Llama al servicio necesario para copiar el pedido al carrito y
   * hacer el mismo pedido de nuevo
   */
  volverPedir() {
    this.localStorage.setItem<any>('volver_pedir_pedido', this.pedido);
    this.router.navigate(['/carrito']);
  }

  /**
   * Hace la validación para saber si sí es posible editar el pedido.
   * Si es posible, llama al servicio necesario y va al carrito para la edición.
   * Si no es posible, lanza un mensaje con el motivo
   */
  async editar() {
    if (this.pedido.estado == 'Alistamiento') {
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-alert-amarillo.png';
      modalRef.componentInstance.title = '¡Alto ahí!';
      modalRef.componentInstance.msg =
        'Este pedido ya se encuentra en alistamiento por parte del distribuidor. Para generar cambios escribe un mensaje y la persona encargada revisará si puede hacer los cambios que necesitas.';
      modalRef.componentInstance.btn_msg = 'OK';
    } else {
      const productos = this.pedido.productos;
      const punto_entrega = this.pedido.punto_entrega?._id ? this.pedido.punto_entrega?._id : this.pedido.punto_entrega;
      const punto: any = await this.restService.getJWT(`punto_entrega/${punto_entrega}`).toPromise();
      let productos_data_completa: any = await this.restService
        .postJWT(`/producto/multiples_id`, productos)
        .toPromise();
      productos_data_completa = productos_data_completa.data;
      localStorage.setItem('punto_entrega_seleccionado', JSON.stringify(punto));
      for (const i in productos_data_completa) {
        const unidades = parseInt(productos[i].unidad.toString());
        const cajas = parseInt(productos[i].caja.toString());
        const unds_x_caja = productos_data_completa[i].precios[0].und_x_caja;
        // Se agrega el inv actual y las unidades ya pedidas al inv total
        const inventario = productos[i].unidad + productos_data_completa[i].precios[0].inventario_unidad;
        localStorage.setItem('distribuidorCarrito', this.pedido.distribuidor);
        this.cartservice.addCart(productos_data_completa[i], unidades, cajas, unds_x_caja, inventario);
      }
      this.localStorage.setItem<any>('editar_pedido_curso', JSON.stringify(this.pedido));
      this.router.navigate(['/carrito']);
    }
  }

  /**
   * Esta función tiene como objetivo añadir un nuevo producto al carrito
   * @param product es el producto que se va a añadir
   * @param unidad cantidad de unidades a agregar
   * @param caja cantidad de cajas a agregar
   */
  agregarProduct(
    product: any,
    unidad: number,
    caja: number,
    und_x_caja: number,
    inv_unidad: number,
    flag_nuevo_producto: boolean
  ) {
    const unidades = parseInt(unidad.toString());
    const cajas = parseInt(caja.toString());
    const unds_x_caja = parseInt(und_x_caja.toString());
    const inventario = parseInt(inv_unidad.toString());
    localStorage.setItem('distribuidorCarrito', this.pedido.distribuidor);
    this.cartservice.addCart(product, unidades, cajas, unds_x_caja, inventario);
    if (flag_nuevo_producto === true) {
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
      modalRef.componentInstance.title = '¡Genial!';
      modalRef.componentInstance.msg = 'Se ha agregado el producto al carrito, modifica las unidades desde allí.';
      modalRef.componentInstance.btn_msg = 'Listo';
    }
  }

  /**
   * Lanza el pop-up de confirmación para que el usuario decida si cancelar o no el pedido
   */
  popUpCancelarPedido() {
    const modalRef = this.modalService.open(ConfirmacionComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-alert-amarillo.png';
    modalRef.componentInstance.title = '¡Alto ahí!';
    modalRef.componentInstance.msg = '¿Estás seguro que deseas cancelar el pedido en curso?';
    modalRef.componentInstance.btn_msg_no = 'Cancelar';
    modalRef.componentInstance.btn_msg_yes = 'Continuar';
    modalRef.componentInstance.callback_no = () => {};
    modalRef.componentInstance.callback_yes = () => {
      this.cancelarPedido();
      this.activeModal.close();
    };
  }

  /**
   * Actualiza el estado en el back de este pedido a 'Cancelado por horeca'
   */
  async cancelarPedido() {
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    const pedido_id = this.idParam;
    this.restService
      .getJWT(`/pedido/cancelar/horeca/${pedido_id}`)
      .toPromise()
      .then((resp: any) => {
        this.modalCarga?.close();
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
        modalRef.componentInstance.title = '¡Genial!';
        modalRef.componentInstance.msg = 'El pedido fue cancelado';
        modalRef.componentInstance.btn_msg = 'Listo';
        modalRef.componentInstance.close_callback = () => {
          window.location.reload();
        };
      })
      .catch((error) => {
        this.modalCarga?.close();
        const modalRef = this.modalService.open(SimpleComponent);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = error.error.message;
        modalRef.componentInstance.btn_msg = 'Volver';
      });
  }

  /**
   * Actualiza el estado en el back de este pedido a 'Recibido'
   */
  async marcarRecibido() {
    const pedidoId = this.idParam;
    const trabajadorId: string = this.authService.user?._id || '';
    await this.restService.putJWT(`pedido/${pedidoId}/${trabajadorId}`, { estado: 'Recibido' }).toPromise();
    window.location.reload();
  }

  /**
   * Abre un modal con la informaci´pn del encargado de la tienda
   */
  openModalInfoEncargado() {
    this.restService
      .getJWT(`/distribuidores_vinculados_dist_punto/${this.pedido.distribuidor}/${this.pedido.punto_entrega._id}`)
      .toPromise()
      .then((vinculacion: any) => {
        this.restService
          .getJWT(`/trabajador/${vinculacion.data[0].vendedor[0]}`)
          .toPromise()
          .then((trabajador: any) => {
            const modalRef = this.modalService.open(InformacionEncargadoPuntoComponent);
            modalRef.componentInstance.info_distribuidor = this.distribuidor;
            modalRef.componentInstance.info_encargado = trabajador;
            modalRef.componentInstance.vista_distribuidor = true;
          });
      })
      .catch((error) => {
        const modalRef = this.modalService.open(SimpleComponent);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = `No fue posible recuperar la información del encargado, intenta mas tarde.`;
        modalRef.componentInstance.btn_msg = 'Volver';
      });
  }

  /**
   * Toma la fecha y hora que entra por parámetro y lo interpreta
   * para mostrarlo en el estado del pedido
   * @param fecha_hora La fecha y hora a interpretar. Se trae del último
   * estado del pedido y es de la forma {YYYY}-{MM}-{DD}T{HH}:{MM}:{SS}.{MS}Z
   */
  processTimeDate(fecha_hora: string) {
    const fecha_pedido_entrega = new Date();
    fecha_pedido_entrega.setTime(new Date(fecha_hora).getTime());
    this.hora_estado = new Date(fecha_pedido_entrega).toLocaleString('en-US').split(',')[1].trim();
    this.fecha_estado = new Date(fecha_pedido_entrega).toLocaleString('en-US').split(',')[0].trim();
  }
}
