<div class="container">
  <div class="row">
    <div class="col-12 text-center">
      <div class="spinner-border text-warning" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-12 text-center">
      <h5>
        Procesando información
      </h5>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-12 text-center">
      <p>
        Espera un momento, estamos procesando los datos ingresados
      </p>
    </div>
  </div>
</div>