import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-distribuidores-organizaciones',
  templateUrl: './distribuidores-organizaciones.component.html',
  styleUrls: ['./distribuidores-organizaciones.component.css'],
})
export class DistribuidoresOrganizacionesComponent implements OnInit {
  /** Variable de control para la navegación del panel */
  active = 1;
  constructor() {}

  ngOnInit(): void {}
}
