import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  faTimes,
  faChevronLeft,
  faTimesCircle,
  faEyeSlash,
  faEye,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PuntoEntrega } from 'src/app/models/punto_entrega.model';
import { Trabajador } from 'src/app/models/trabajador.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PlacesService } from 'src/app/services/places/places.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { SimpleComponent } from '../simple/simple.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CargandoGenericoComponent } from '../cargando-generico/cargando-generico.component';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-crear-trabajador',
  templateUrl: './crear-trabajador.component.html',
  styleUrls: ['./crear-trabajador.component.css'],
})
export class CrearTrabajadorComponent implements OnInit, DoCheck {
  // Iconos FontAwesome para usar en el template
  public faTimes = faTimes;
  public faChevronLeft = faChevronLeft;
  public faTimesCircle = faTimesCircle;
  public faEyeSlash = faEyeSlash;
  public faEye = faEye;
  public faTrashAlt = faTrashAlt;
  // Flag para mostrar u ocultar contraseña
  public fieldTextType = false;
  // Datos del trabajador a registrar
  public nombres = '';
  public apellidos = '';
  public correo = '';
  public clave = '';
  public telefono = 0;
  public celular = 0;
  public pais = 'Colombia';
  public departamento = '';
  public ciudad = '';
  public tipo_documento = '';
  public numero_documento = '';
  public tipo_trabajador = '';
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  public user = this.authService.user;
  public usuario_horeca = '';
  public distribuidor = '';
  public punto_entrega = '';
  public punto_entrega_nom = '';
  public puntos_entrega: string[] = [];
  // número del paso en el proceso de creacion de un trabajador
  public step = 1;
  // Variables para el manejo de la lista de departamentos y ciudades
  public ciudades: any;
  public departamentos: any;
  // Todas los puntos de venta de este usuario horeca
  public puntos_venta: any[] = [];
  // Se guardan los nombres de los puntos de entrega para mostrar en el form
  public puntos_entrega_nombre: string[] = [];
  // Variable de control para la barra de progreso
  public progressBarValue = 50;
  // Guarda estado de validacion de telefono opcional
  public is_boton_habilitado = false;
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;
  // Tipo de usuario logueado, distribuidor o establecimiento
  public tipo_usuario: any;
  // Guarda los nombre de los establecimientos de los puntos de venta
  public nombre_establecimientos_por_punto: string[] = [];
  // Información completa de establecimientos vinculados
  public lista_establecimientos_vinculados: any;
  // Evita salir del modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };
  // Form
  public crearTrabajadorFormPaso2: FormGroup;
  public crearTrabajadorFormPaso1: FormGroup;
  @Input() callback = () => {};
  registerPuntos: any = [];

  constructor(
    private authService: AuthService,
    private restService: RestService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private router: Router,
    private ngxsStore: Store,
    private formBuilder: FormBuilder,
    public places: PlacesService
  ) {
    this.crearTrabajadorFormPaso1 = this.formBuilder.group({
      trabajadorDocumento: ['', Validators.required],
      trabajadorTipo: ['', Validators.required],
      trabajadorPais: ['', Validators.required],
      trabajadorDepartamento: ['', Validators.required],
      trabajadorCiudad: ['', Validators.required],
      trabajadorNombre: ['', Validators.required],
      trabajadorApellido: ['', Validators.required],
      trabajadorTelefono: [''],
      trabajadorCelular: ['', [Validators.required, Validators.pattern(/^3[\d]{9}$/)]],
    });
    this.crearTrabajadorFormPaso2 = this.formBuilder.group({
      trabajadorTipoUsuario: ['', Validators.required],
      trabajadorCorreo: ['', [Validators.required, Validators.email]],
      trabajadorContrasena: ['', Validators.required],
    });
    this.step = 1;
  }

  ngOnInit(): void {
    this.user = this.ngxsStore.snapshot().auth.user;
    this.restService
      .get(`tipo_empresa_trabajador/${this.user?._id}`)
      .toPromise()
      .then((resp: any) => {
        this.tipo_usuario = resp;
        if (this.tipo_usuario === 'usuario_horeca' && this.user) {
          this.usuario_horeca = this.user.usuario_horeca!;
          this.departamento = this.authService.user_horeca?.departamento || '';
        }
        if (this.tipo_usuario === 'distribuidor' && this.user) {
          this.distribuidor = this.user.distribuidor!;
          this.departamento = this.authService.user_distribuidor?.departamento || '';
        }
        this.getPlaces();
        this.getPuntosEntrega();
      });
  }

  ngDoCheck(): void {
    this.habilitarBoton();
  }

  /**
   * Recupera la información de los puntos de entrega del usuario autenticado
   */
  async getPuntosEntrega() {
    try {
      if (this.tipo_usuario == 'usuario_horeca') {
        /** Establecimientos */
        this.restService
          .getJWT('punto_entrega')
          .toPromise()
          .then((resp: any) => {
            let i = 0;
            let punto_venta_aux: PuntoEntrega;
            for (i = 0; i < resp.length; i++) {
              if (resp[i].usuario_horeca == this.authService.user?.usuario_horeca && resp[i].estado == 'Activo') {
                punto_venta_aux = new PuntoEntrega(
                  resp[i].usuario_horeca,
                  resp[i].nombre,
                  resp[i].pais,
                  resp[i].departamento,
                  resp[i].ciudad,
                  resp[i].telefono.toString(),
                  resp[i].direccion,
                  resp[i].informacion_contacto,
                  resp[i].sillas,
                  resp[i].domicilios,
                  resp[i].numero_trabajadores,
                  resp[i].tipo_aprobador,
                  resp[i].dias_atencion,
                  resp[i].horario,
                  resp[i]._id,
                  resp[i].estado
                );
                this.puntos_venta.push(punto_venta_aux);
              }
            }
          });
      } else if (this.tipo_usuario == 'distribuidor') {
        /** Distribuidores */
        this.restService
          .getJWT(`/punto_entrega_distribuidor/${this.authService.user?.distribuidor}`)
          .toPromise()
          .then((resp: any) => {
            this.puntos_venta = resp;
          });
        this.restService
          .getJWT(`/establecimientos_vinculados_a_distribuidores_aprobados/${this.authService.user?.distribuidor}`)
          .toPromise()
          .then((resp: any) => {
            this.lista_establecimientos_vinculados = resp.data;
            this.getEstablecimientoNombre();
          });
      }
    } catch (err) {
      this.activeModal.close('Click close');
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'No fue posible recuperar la lista de puntos de venta. Por favor intenta de nuevo más tarde';
      modalRef.componentInstance.btn_msg = 'Volver';
    }
  }

  /**
   * Metodo para conseguir ciudades y departamentos del país
   */
  async getPlaces() {
    await this.places.getDepartmentFromServerImagine();
    this.departamentos = this.places.departments_colombia;
    let id_dpto = 0;
    for (const dpto of this.places.departments_colombia) {
      if (dpto.name == this.crearTrabajadorFormPaso1.get('trabajadorDepartamento')?.value) {
        break;
      }
      id_dpto++;
    }
    if (this.places.departments_colombia[id_dpto] != undefined) {
      await this.places.getCitiesFromServerImagine(this.places.departments_colombia[id_dpto].code);
      this.ciudades = this.places.cities_colombia;
    } else {
      this.ciudades = [];
    }
  }
  /**
   * Crea un array con el nombre del establecimiento al que pertenece cada punto de venta
   */
  getEstablecimientoNombre() {
    this.puntos_venta.forEach((_id_punto: any) => {
      this.lista_establecimientos_vinculados.forEach((element: any) => {
        if (element.puntos[0]._id === _id_punto._id) {
          _id_punto.DataVinculacion = element;
          this.nombre_establecimientos_por_punto.push(element.puntos[0].horeca[0].nombre_establecimiento);
        }
      });
    });
  }

  /**
   * Intenta el registro del trabjaador usando los datos llenados en el formulario
   */
  registrarTrabajador() {
    const nuevo_trab: Trabajador = new Trabajador(
      this.crearTrabajadorFormPaso1.get('trabajadorNombre')?.value,
      this.crearTrabajadorFormPaso1.get('trabajadorApellido')?.value,
      this.crearTrabajadorFormPaso2.get('trabajadorCorreo')?.value,
      this.crearTrabajadorFormPaso2.get('trabajadorContrasena')?.value,
      this.crearTrabajadorFormPaso1.get('trabajadorTelefono')?.value,
      this.crearTrabajadorFormPaso1.get('trabajadorCelular')?.value,
      this.crearTrabajadorFormPaso1.get('trabajadorPais')?.value,
      this.crearTrabajadorFormPaso1.get('trabajadorDepartamento')?.value,
      this.crearTrabajadorFormPaso1.get('trabajadorCiudad')?.value,
      this.crearTrabajadorFormPaso1.get('trabajadorTipo')?.value,
      this.crearTrabajadorFormPaso1.get('trabajadorDocumento')?.value,
      this.crearTrabajadorFormPaso2.get('trabajadorTipoUsuario')?.value,
      'Aprobado',
      true,
      undefined,
      this.puntos_entrega,
      undefined
    );
    this.usuario_horeca && this.usuario_horeca != '' ? (nuevo_trab.usuario_horeca = this.usuario_horeca) : '';
    this.distribuidor && this.distribuidor != '' ? (nuevo_trab.distribuidor = this.distribuidor) : '';
    nuevo_trab.puntos_entrega = this.puntos_entrega;
    nuevo_trab.crado_por_horeca = true;
    this.restService
      .post('trabajador/registro', nuevo_trab)
      .toPromise()
      .then((resp: any) => {
        if (resp.success) {
          if (resp.data._id && this.tipo_usuario !== 'usuario_horeca') {
            this.insertVinculaciones(resp.data._id);
          } else {
            this.activeModal.close('Click close');
            const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
            modalRef.componentInstance.title = '¡Genial!';
            modalRef.componentInstance.msg = 'El trabajador que registraste fue creado con éxito';
            modalRef.componentInstance.btn_msg = 'Listo';
            modalRef.componentInstance.close_callback = () => {
              window.location.reload();
              this.callback();
            };
          }
        } else {
          const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg = resp.msg;
          modalRef.componentInstance.btn_msg = 'Volver';
        }
      })
      .catch(() => {
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'Ocurrió un error inesperado. ¡Por favor intenta de nuevo más tarde!';
        modalRef.componentInstance.btn_msg = 'Volver';
      });
  }
  async insertVinculaciones(idUser: any) {
    await this.registerPuntos.forEach((punto: any) => {
      punto.vendedor.push(idUser);
      this.restService
        .putJWT('distribuidores_vinculados_vende/' + punto._id, {
          vendedor: punto.vendedor,
        })
        .toPromise()
        .then(() => {})
        .catch(() => {});
    });
    this.activeModal.close('Click close');
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
    modalRef.componentInstance.title = '¡Genial!';
    modalRef.componentInstance.msg = 'El trabajador que registraste fue creado con éxito';
    modalRef.componentInstance.btn_msg = 'Listo';
    modalRef.componentInstance.close_callback = () => {
      window.location.reload();
      this.callback();
    };
  }
  /**
   * Si no cumple no entra a la asignacion según estado del formulario.
   * Se realiza este paso dado que el telefono del trabajador
   * solo se verifica si se ingresa al menos un digito en el input
   */
  habilitarBoton() {
    this.is_boton_habilitado = false;
    if (
      this.crearTrabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length >= 1 &&
      this.crearTrabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length != 7 &&
      this.crearTrabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length != 10
    ) {
      return;
    } else {
      this.is_boton_habilitado = !this.crearTrabajadorFormPaso1.invalid;
    }
  }

  /**
   * No Avanza al siguiente paso y alerta/muestra lo errado
   */
  showAlert() {
    if (this.step == 1 && this.crearTrabajadorFormPaso1.invalid == true) {
      this.crearTrabajadorFormPaso1.markAllAsTouched();
    } else if (this.step != 1 && this.crearTrabajadorFormPaso2.invalid == true) {
      this.crearTrabajadorFormPaso2.markAllAsTouched();
    }
  }

  /**
   * Agrega un punto de venta al arreglo de puntos de ventas asignados a este trabajador
   * Solo se agrega si el punto de entrega a agregar ya fue escogido y si ese punto no
   * ha sido agregado antes
   */
  asignarTrabajador(target: any) {
    for (const iterator of this.puntos_entrega) {
      //Salir si ya el trabajador fue seleccionado
      if (iterator == target._id) {
        return;
      }
    }
    this.registerPuntos.push(target.DataVinculacion);
    this.puntos_entrega.push(this.punto_entrega);
    this.puntos_entrega_nombre.push(this.punto_entrega_nom);
  }

  /**
   * Elimina un punto de venta al arreglo de puntos de ventas asignados a este trabajador
   */
  removerTrabajador(index: number) {
    this.puntos_entrega_nombre.splice(index, 1);
    this.puntos_entrega.splice(index, 1);
  }

  /**
   * Permite avanzar o retroceder en el formulario
   * @param paso El paso en el que se encuentra el usuario
   */
  onPaso(paso: number) {
    if (paso == 1) {
      this.step = 1;
      this.progressBarValue = 50;
    } else {
      this.step = 2;
      this.progressBarValue = 100;
    }
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateOnlyText(evento: any) {
    const keyCode = evento.keyCode;
    if (keyCode >= 48 && keyCode <= 57) {
      evento.preventDefault();
    }
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   * */
  validateNumber(evento: any) {
    const keyCode = evento.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || excludedKeys.includes(keyCode))) {
      evento.preventDefault();
    }
  }

  /**
   * Oculta o revela la contraseña al usuario
   */
  mostrarOcultarPassword() {
    this.fieldTextType = !this.fieldTextType;
  }
}
