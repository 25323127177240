export class PedidoResumen {
  constructor(
    public estado: string,
    public nombre_distribuidor: string,
    public fecha: string,
    public productos: number,
    public unidades: number,
    public logo: string,
    public _id: string,
    public precio: number,
    public id_pedido: string
  ) {}
}
