import { Component, OnInit } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';

@Component({
  selector: 'app-selecciona-plan-dist',
  templateUrl: './selecciona-plan-dist.component.html',
  styleUrls: ['./selecciona-plan-dist.component.css'],
})
export class SeleccionaPlanDistComponent implements OnInit {
  /** Íconos */
  faChevronLeft = faChevronLeft;
  planes: any[] = [
    {
      nombre: 'Principiante',
      desde: '0',
      hasta: '100',
      precio: '0',
      imagen: '../../../assets/img/iconos-planes/1-principiante.png',
      color: '#f8c684',
    },
    {
      nombre: 'Básico',
      desde: '101',
      hasta: '500',
      precio: '2',
      imagen: '../../../assets/img/iconos-planes/2-basico.png',
      color: '#e8cd7a',
    },
    {
      nombre: 'Avanzado',
      desde: '501',
      hasta: '1000',
      precio: '3',
      imagen: '../../../assets/img/iconos-planes/3-avanzado.png',
      color: '#a591f4',
    },
    {
      nombre: 'Crack',
      desde: '1001',
      hasta: '2000',
      precio: '4',
      imagen: '../../../assets/img/iconos-planes/4-crack.png',
      color: '#8e6ff7',
    },
    {
      nombre: 'Pro',
      desde: '+2000',
      precio: '5',
      imagen: '../../../assets/img/iconos-planes/5-pro.png',
      color: '#4b3e9d',
    },
  ];

  colors = ['#f8c684', '#e8cd7a', '#a591f4', '#8e6ff7', '#4b3e9d'];

  actualPlan: any;

  constructor(
    private modalService: NgbModal,
    private distribuidoresService: DistribuidorService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loadPlanes();
  }

  private async loadPlanes() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    const modalLoading = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    this.planes = await this.distribuidoresService.getPlanes().toPromise();
    const vinculacion = await this.distribuidoresService
      .getCurrentPlan(this.authService.user_distribuidor?._id || '')
      .toPromise();
    this.actualPlan = this.planes.find((plan) => plan._id === vinculacion.plan._id);
    this.planes = this.planes.filter((plan) => plan.estado == 'Activo');
    for (let index = 0; index < this.planes.length; index++) {
      const element = this.planes[index];
      element.color = this.colors[index % this.colors.length];
    }
    await new Promise((resolve) => setTimeout(resolve, 500));
    modalLoading.close();
  }
}
