<div id="content">
  <!-------------------- Bar Chart -------------------->
  <!-- Puntos Fet por mes -->
  <div class="card puntosFT-mes">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Puntos Feat por mes</div>
        <div class="d-flex justify-content-between">
          <span>Total: {{total_puntosFt_mes}}</span>
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_puntosFt_mes"
            (ngModelChange)="getChartPuntosFtMes()">
            <option [value]="ano" *ngFor="let ano of anos_filtro">
              {{ano}}
            </option>
          </select>
        </div>
      </div>
      <canvas #bar_puntosFt_mes [ngClass]="{'inactive': data_bar_puntosFt_mes.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_puntosFt_mes.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <!-- Número de establecimientos redimiendo códigos -->
  <div class="card puntosFT-establecimiento">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Número de establecimientos redimiendo códigos</div>
        <div class="d-flex justify-content-between">
          <span>Total: {{total_puntosFt_establecimientos}}</span>
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_puntosFt_establecimientos_ano"
            (ngModelChange)="getChartPuntosFtEstablecimiento()">
            <option [value]="ano" *ngFor="let ano of anos_filtro">
              {{ano}}
            </option>
          </select>
        </div>
      </div>
      <canvas #bar_puntosFt_establecimientos
        [ngClass]="{'inactive': data_bar_puntosFt_establecimientos.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_puntosFt_establecimientos.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <!-- Número de códigos en redención -->
  <div class="card puntosFT-codigo">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Número de códigos en redención</div>
        <div class="d-flex justify-content-between">
          <span>Total: {{total_puntosFt_codigo}}</span>
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_puntosFt_codigo"
            (ngModelChange)="getChartPuntosFtCodigo()">
            <option [value]="ano" *ngFor="let ano of anos_filtro">
              {{ano}}
            </option>
          </select>
        </div>
      </div>
      <canvas #bar_puntosFt_codigo [ngClass]="{'inactive': data_bar_puntosFt_codigo.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_puntosFt_codigo.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <!-------------------- Pie Chart -------------------->
  <!-- Estados códgios de redención
  <div class="card puntosFT-estados" hidden>
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Estados códgios de redención</div>
        <div class="d-flex justify-content-between">
          <span>Total: {{total_puntosFt_estados}}</span>
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_puntosFt_estados"
            (ngModelChange)="getChartPuntosFtEstados()">
            <option [value]="ano" *ngFor="let ano of anos_filtro">
              {{ano}}
            </option>
          </select>
        </div>
      </div>
      <canvas #pie_puntosFt_estados [ngClass]="{'inactive': data_pie_puntosFt_estados.datasets.length == 0}"></canvas>
      <div *ngIf="data_pie_puntosFt_estados.datasets.length > 0 else mostrarError"></div>
    </div>
  </div> -->
  <!-- Manejo de errores -->
  <ng-template #mostrarError>
    <div class="d-flex justify-content-center mt-4">
      <div>
        <img src="assets/img/icon-warning-amarillo.png" alt="icono de una hoja de papel y una lupa sobre ella"
          style="max-width: 150px;" />
      </div>
    </div>
    <div class="mt-2">
      <p class="text-center">
        ¡Lo siento! No existen datos para ver aquí
      </p>
    </div>
  </ng-template>
</div>