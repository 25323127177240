import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { SetSelectedToken } from '../actions/token.actions';
import { MultipasoComponent } from '../modal/multipaso/multipaso.component';
import { SimpleNoImgComponent } from '../modal/simple-no-img/simple-no-img.component';
import { AuthService } from '../services/auth/auth.service';
import { DistribuidorService } from '../services/distribuidor/distribuidor.service';
import { LocalStorageService } from '../services/local-storage/local-storage.service';
import { RestService } from '../services/rest/rest.service';

@Component({
  selector: 'app-inicio-distribuidor',
  templateUrl: './inicio-distribuidor.component.html',
  styleUrls: ['./inicio-distribuidor.component.css'],
})
export class InicioDistribuidorComponent implements OnInit {
  /** Guarda los banners publicitarios */
  public banners: any;

  constructor(
    public authService: AuthService,
    private rest: RestService,
    private distribuidorService: DistribuidorService,
    public localStorage: LocalStorageService,
    private modalService: NgbModal,
    private ngxsStore: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.user = this.ngxsStore.snapshot().auth.user;
    if (this.authService.user?.show_slides === true) {
      this.lanzarTutorial();
    }
    this.getBanners();
  }

  /**
   * Revisa si es la primera vez de este usuario usando la plataforma. Si sí,
   * lanza un tutorial mostrándole al usuario cómo usar la plataforma.
   */
  lanzarTutorial() {
    this.localStorage.getItem<boolean>('primera visita ' + this.authService.email).then((resp) => {
      if (resp !== false) {
        const ngbModalOptions: NgbModalOptions = {
          //Evita que al hacer click por fuera se cierre el modal
          backdrop: 'static',
          keyboard: false,
          centered: true,
        };

        const modalRef = this.modalService.open(SimpleNoImgComponent, ngbModalOptions);
        modalRef.componentInstance.title = '¡Hola! Queremos que conozcas Feat';
        modalRef.componentInstance.msg =
          'Por eso te mostraremos los accesos que tendrás desde el inicio. ¡Es un gusto tenerte acá!';
        modalRef.componentInstance.btn_msg = 'Empecemos';
        modalRef.componentInstance.close_callback = () => {
          //Lanza el modal multipaso
          const ngbModalOptions2: NgbModalOptions = {
            //Evita que al hacer click por fuera se cierre el modal
            backdrop: 'static',
            keyboard: false,
            centered: true,
            size: 'lg',
          };
          const modalRef = this.modalService.open(MultipasoComponent, ngbModalOptions2);
          const pasos: any[] = [
            {
              title: 'Primero lo primero',
              msg: 'Agrega tu catálogo de productos a la plataforma, puedes hacerlo manualmente o con un archivo de excel.',
              img_src: '../../../assets/img/modal-bienvenida-dist/3.png',
            },
            {
              title: 'Pedidos',
              msg: 'Gestiona y actualiza todos los pedidos que realicen los establecimientos.',
              img_src: '../../../assets/img/modal-bienvenida-dist/4.png',
            },
            {
              title: 'Actualiza los productos de tu catálogo',
              msg: 'Puedes modificar los productos de tu catálogo cuando quieras, puedes agregar imágenes y hacer seguimiento del inventario.',
              img_src: '../../../assets/img/modal-bienvenida-dist/1.png',
            },
            {
              title: 'Entérate de todo',
              msg: 'En la sección de comunidad podrás publicar y encontrar noticias con contenidos para seguir conectándote y seguir siendo parte de la comunidad Feat.',
              img_src: '../../../assets/img/modal-bienvenida-dist/6.png',
            },
            {
              title: 'Saldos y promociones',
              msg: 'No dejes que se te escape la oportunidad de vender promociones que sean atractivas para tus clientes, también podrás poner en descuento productos próximos a vencer.',
              img_src: '../../../assets/img/modal-bienvenida-dist/7.png',
            },
            {
              title: 'Chequeo de precios',
              msg: 'Podrás saber el rango mínimo y máximo de precios de un producto en comparación a otros distribuidores y así tomes la mejor decisión.',
              img_src: '../../../assets/img/modal-bienvenida-dist/2.png',
            },
            {
              title: 'Indicadores',
              msg: 'Cuando veas estos círculos rojos significa que tienes información que aún no has visto. ¡Ingresa y explora por tu cuenta!',
              img_src: '../../../assets/img/modal-bienvenida-dist/5.png',
            },
          ];
          modalRef.componentInstance.pasos = pasos;
          modalRef.componentInstance.close_callback = () => {
            /**
             * Cuando el usuario termine de revisar la información
             * se guarda en localStorage y base de datos el indicador
             * para no volver a mostrar cuando se vuelva a iniciar sesión
             */
            const show_slides = {
              show_slides: false,
            };
            /**Se actualiza el trabajador en el back para no mostrar los slides de nuevo*/
            this.rest
              .putJWT(`trabajador/${this.authService.user?._id}`, show_slides)
              .toPromise()
              .then((resp: any) => {
                /**Se actualiza la info de inicio de sesión en el Store con ShowSlide False*/
                this.ngxsStore
                  .dispatch(
                    new SetSelectedToken({
                      token: this.authService.token,
                      email: this.authService.email,
                      user: resp,
                      user_horeca: undefined,
                      user_distribuidor: this.authService.user_distribuidor,
                      user_organizacion: undefined,
                    })
                  )
                  .toPromise()
                  .then(() => (this.authService.user = this.ngxsStore.snapshot().auth.user));
              });
          };
        };
      }
    });
  }

  /**
   * Devuelve los banners del home page
   */
  getBanners() {
    this.rest
      .getJWT('banner/Distribuidores')
      .toPromise()
      .then((res5: any) => {
        this.banners = res5.filter((element: any) => element.estado === 'Activo');
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  /**
   * Abre url de un banner seleccionado
   */
  goToLink(url: string) {
    window.open(url, '_blank');
  }
}
