<section class="w-100 container px-4 py-5 m-0">
    <h5 class="w-100 text-center">
        ¡Cargue su imagen o archivo aquí!
    </h5>
    <div class="img-container  w-100 d-flex justify-content-center align-items-center">
        <div *ngIf="imageSrc === undefined" class="cursorPointer d-flex justify-content-center align-items-center">
            <fa-icon [icon]="faUpload" class="text-secondary"></fa-icon>
        </div>
        <div *ngIf="imageSrc !== undefined" class="d-flex justify-content-center align-items-center">
            <img *ngIf="!typeFilePDF" [src]="imageSrc" id="logo-empresa" alt="Logo del horeca" />
            <fa-icon *ngIf="typeFilePDF" [icon]="faFilePdf" class="text-secondary"></fa-icon>
        </div>
    </div>
    <div class="img-boton w-100 d-flex justify-content-evenly align-items-center">
        <input type="file" id="test" class="w-75 pr-3" (change)="cargarImagen($event)" />
        <button class="w-25" (click)="enviarImagen()">
            Enviar
        </button>
    </div>
</section>