import { Component, OnInit, ViewChild, ElementRef, DoCheck } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { UsuarioHoreca } from 'src/app/models/usuario-horeca.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PlacesService } from 'src/app/services/places/places.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';
import { faTrash, faPaperclip, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngxs/store';
import { SetSelectedToken } from 'src/app/actions/token.actions';
import { Distribuidor } from 'src/app/models/distribuidor.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';

@Component({
  selector: 'app-informacion-cuenta',
  templateUrl: './informacion-cuenta.component.html',
  styleUrls: ['./informacion-cuenta.component.css'],
})
export class InformacionCuentaComponent implements OnInit, DoCheck {
  /**
   * Copia del objeto user de authService
   * Se copia para hacer todas las operaciones de edición sobre
   * este objeto y garantizar la integridad de los datos en
   * caso de que los cambios no sean guardados u ocurra
   * algún error inesperado
   */
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  user: UsuarioHoreca = this.authService.user_horeca!;

  /** Variables*/
  logoCapturado: any;
  logoPath: SafeUrl[] = [];
  rutCapturado: any;
  repLegalCapturado: any;
  comercioCapturado: any;
  public archivos: File[] = [];
  archivoCapturado: any;

  /** Guarda el logo que se vayan a subir */
  imageSrc: any;

  /** Guarda el nombre de los documentos que se vayan subiendo */
  rutName = '';
  camComName = '';
  CCName = '';

  /** Guarda el link de los documentos ya en la BD */
  CCPDF = '';
  camComPDF = '';
  rutPDF = '';

  /** Iconos */
  faTrash = faTrash;
  faPaperclip = faPaperclip;
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  /** Variables para el manejo de la lista de departamentos y ciudades */
  ciudades: any;
  ciudades_empresa: any;
  departamentos: any;
  departamentos_empresa: any;

  /**Guardara booleano para habilitar o no boton de continuar */
  public is_boton_habilitado = false;

  /** Formularios reactivos */
  public cuentaForm: FormGroup;

  /** Modal de carga para darle feedback al usuario */
  modalCarga?: NgbModalRef;

  constructor(
    public authService: AuthService,
    private modalService: NgbModal,
    private router: Router,
    private restService: RestService,
    public places: PlacesService,
    private formBuilder: FormBuilder,
    private distribuidorService: DistribuidorService,
    private ngxsStore: Store
  ) {
    this.cuentaForm = this.formBuilder.group({
      cuentaPropietarioTipo: ['', Validators.required],
      cuentaPropietarioCedula: ['', Validators.required],
      cuentaPropietarioNombre: ['', Validators.required],
      cuentaPropietarioApellido: ['', Validators.required],
      cuentaPropietarioTelefono: ['', [Validators.required, Validators.pattern(/^(?=[0-9]*$)(?:.{7}|.{10})$/)]],
      cuentaPropietarioCorreo: ['', [Validators.required, Validators.email]],
      cuentaCorreo: ['', [Validators.required, Validators.email]],
      cuentaNombreEstablecimiento: ['', Validators.required],
      cuentaRazon: ['', Validators.required],
      cuentaNit: ['', Validators.required],
      cuentaEstablecimiento: ['', Validators.required],
      cuentaPais: ['', Validators.required],
      cuentaDepartamento: ['', Validators.required],
      cuentaCiudad: ['', Validators.required],
      cuentaTelefono: ['', [Validators.required, Validators.pattern(/^(?=[0-9]*$)(?:.{7}|.{10})$/)]],
      cuentaTelefono2: [''],
    });
    this.cuentaForm.patchValue({
      cuentaPropietarioTipo: this.authService.user_horeca?.propietario_tipo_documento,
      cuentaPropietarioCedula: this.authService.user_horeca?.propietario_numero_documento,
      cuentaPropietarioNombre: this.authService.user_horeca?.propietario_nombres,
      cuentaPropietarioApellido: this.authService.user_horeca?.propietario_apellidos,
      cuentaPropietarioTelefono: this.authService.user_horeca?.propietario_telefono,
      cuentaPropietarioCorreo: this.authService.user_horeca?.propietario_correo,
      cuentaCorreo: this.authService.user?.correo,
      cuentaNombreEstablecimiento: this.authService.user_horeca?.nombre_establecimiento,
      cuentaRazon: this.authService.user_horeca?.razon_social,
      cuentaNit: this.authService.user_horeca?.nit,
      cuentaEstablecimiento: this.authService.user_horeca?.tipo_negocio,
      cuentaPais: 'Colombia',
      cuentaDepartamento: this.authService.user_horeca?.empresa_departamento,
      cuentaCiudad: this.authService.user_horeca?.empresa_ciudad,
      cuentaTelefono: this.authService.user_horeca?.empresa_telefono,
      cuentaTelefono2: 0,
    });
    if (this.authService.user_horeca?.empresa_telefono2 !== undefined) {
      this.cuentaForm.patchValue({
        cuentaTelefono2: this.authService.user_horeca?.empresa_telefono2,
      });
    }
  }

  ngDoCheck(): void {
    this.habilitarBoton();
  }

  ngOnInit() {
    this.getDocuments();
    this.user.empresa_pais = 'Colombia';
    if (this.places.places_colombia.length == 0) {
      this.places.getPlacesFromServer();
    }
    this.getPlaces();
    this.cuentaForm.markAllAsTouched();
  }

  /**
   * Metodo para obtener departamentos y ciudades mediante API Imagine Apps
   */
  async getPlaces() {
    let id_dpto = 0;
    this.departamentos = this.departamentos_empresa = await this.places.getDepartmentFromServerImagine();
    for (const dpto of this.departamentos) {
      if (dpto.name == this.cuentaForm.get('cuentaDepartamento')?.value) {
        break;
      }
      id_dpto++;
    }
    if (this.departamentos[id_dpto] != undefined) {
      this.ciudades = await this.places.getCitiesFromServerImagine(this.departamentos[id_dpto].code);
    } else {
      this.ciudades = [];
    }
  }

  /**
   * Muestra el nombre del documento seleccionado en el input el cual se va a subir
   * @param event Selecciòn que viene del input tipo type desde el HTML, sería cada documento a cargar
   * la función guarda el nombre del documento para mostrarlo en el DOM
   */
  documentName(event: any, id: number) {
    for (const i of event.target.files) {
      if (id == 1) {
        this.rutName = i.name;
      } else if (id == 2) {
        this.camComName = i.name;
      } else if (id == 3) {
        this.CCName = i.name;
      }
    }
  }

  /**
   * el Primer metodo captura el logo y - el segundo metodo captura el los documentos cargados en el DOM
   * @param event Selecciòn que viene del input tipo type desde el HTML
   * la función no devuelve nada
   * el cuarto metodo guarda los documentos y el logo
   */
  captureLogo(event: any) {
    this.logoCapturado = <File>event.target.files[0];
    if (this.logoCapturado.size > 2000000) {
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Este archivo supera el máximo de 2MB permitido!';
      modalRef.componentInstance.btn_msg = 'Volver';
      return;
    } else {
      this.archivos[0] = this.logoCapturado;
      // Una vez el usuario sube una imagen, esta se mostrará en el DOM
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => (this.imageSrc = reader.result);
        reader.readAsDataURL(file);
      }
    }
  }

  captureDocument(event: any, id: number) {
    const doc = <File>event.target.files[0];
    if (doc.size > 2000000) {
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = '¡Este archivo supera el máximo de 2MB permitido!';
      modalRef.componentInstance.btn_msg = 'Volver';
      return;
    } else if (doc.name.slice(-4) != '.pdf') {
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = '¡Solo archivos PDF están permitidos!';
      modalRef.componentInstance.btn_msg = 'Volver';
      return;
    } else {
      this.archivos[id] = doc;
      this.documentName(event, id);
    }
  }

  /**
   * Guarda documentos y logo en caso de haberlo y luego finaliza cerrando modal de carga y recargando pagina
   */
  async guardarDocumentos(idUser: any, document: any) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    try {
      if (this.archivos[0] != undefined) {
        const upload_form: FormData = new FormData();
        upload_form.append(`logo`, this.archivos[0]);
        await this.distribuidorService.postLogo(idUser, upload_form, 'Usuario_horeca').subscribe(() => {
          this.guardarTrabajadorEnStore();
        });
      }
      if (this.archivos[1] != undefined) {
        const uploadRUT: FormData = new FormData();
        uploadRUT.append(`rut`, this.archivos[1]);
        await this.distribuidorService.postRUT(document, idUser, uploadRUT).subscribe(() => {});
      }
      if (this.archivos[2] != undefined) {
        const uploadCamaraComercio: FormData = new FormData();
        uploadCamaraComercio.append(`camara_comerio`, this.archivos[2]);
        await this.distribuidorService.postCamaraComercio(document, idUser, uploadCamaraComercio).subscribe(() => {});
      }
      if (this.archivos[3] != undefined) {
        const uploadCC: FormData = new FormData();
        uploadCC.append(`documento_identidad`, this.archivos[3]);
        await this.distribuidorService.postCC(document, idUser, uploadCC).subscribe(() => {});
      }
      /** Guarda info en el Store */
      this.ngxsStore
        .dispatch(
          new SetSelectedToken({
            token: this.authService.token,
            email: this.authService.email,
            user: this.authService.user,
            user_horeca: this.authService.user_horeca,
            user_distribuidor: this.authService.user_distribuidor,
          })
        )
        .toPromise()
        .then();
      /** Fin exito */
      this.modalCarga?.close();
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
      modalRef.componentInstance.title = '¡Genial!';
      modalRef.componentInstance.msg = 'Tus datos han sido actualizados con éxito';
      modalRef.componentInstance.btn_msg = 'Listo';
      modalRef.componentInstance.close_callback = () => {
        /** Limpia los campos de documentos y vuelve a cargarlos en el front para visualizar los nuevos */
        this.clearSelection(0);
        this.clearSelection(1);
        this.clearSelection(2);
        this.clearSelection(3);
        this.getDocuments();
        window.location.reload();
      };
    } catch (error) {
      this.modalCarga?.close();
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'Ocurrió un error inesperado, uno o todos tus documentos/logo no pudieron ser guardados ¡Por favor intenta de nuevo más tarde!';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {};
    }
  }

  /**
   * Guarda todos los cambios del formulario
   */
  guardarCambios(idUser: any, document: any) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);

    const nuevo_usuario: UsuarioHoreca = this.user;
    nuevo_usuario.propietario_tipo_documento = this.cuentaForm.get('cuentaPropietarioTipo')?.value;
    nuevo_usuario.propietario_numero_documento = this.cuentaForm.get('cuentaPropietarioCedula')?.value;
    nuevo_usuario.propietario_nombres = this.cuentaForm.get('cuentaPropietarioNombre')?.value;
    nuevo_usuario.propietario_apellidos = this.cuentaForm.get('cuentaPropietarioApellido')?.value;
    nuevo_usuario.propietario_telefono = this.cuentaForm.get('cuentaPropietarioTelefono')?.value;
    nuevo_usuario.propietario_correo = this.cuentaForm.get('cuentaPropietarioCorreo')?.value;
    nuevo_usuario.nombre_establecimiento = this.cuentaForm.get('cuentaNombreEstablecimiento')?.value;
    nuevo_usuario.razon_social = this.cuentaForm.get('cuentaRazon')?.value;
    nuevo_usuario.nit = this.cuentaForm.get('cuentaNit')?.value;
    nuevo_usuario.tipo_negocio = this.cuentaForm.get('cuentaEstablecimiento')?.value;
    nuevo_usuario.empresa_pais = this.cuentaForm.get('cuentaPais')?.value;
    nuevo_usuario.empresa_departamento = this.cuentaForm.get('cuentaDepartamento')?.value;
    nuevo_usuario.empresa_ciudad = this.cuentaForm.get('cuentaCiudad')?.value;
    nuevo_usuario.empresa_telefono = this.cuentaForm.get('cuentaTelefono')?.value;
    nuevo_usuario.empresa_telefono2 = this.cuentaForm.get('cuentaTelefono2')?.value;
    this.restService
      .putJWT('usuario_horeca/' + this.user._id, nuevo_usuario)
      .toPromise()
      .then(() => {
        this.authService.user_horeca = this.user;
        /** Continua la ejecución con el guardado de documentos */
        this.guardarDocumentos(idUser, document);
      })
      .catch(() => {
        this.modalCarga?.close();
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigate(['/cuenta']);
        };
      });
  }
  //Borrar el input de documentos
  @ViewChild('fileInputRUT') fileInputRUT!: ElementRef;
  @ViewChild('fileInputCamCom') fileInputCamCom!: ElementRef;
  @ViewChild('fileInputCC') fileInputCC!: ElementRef;
  clearSelection(id: number) {
    this.archivos = [];
    if (id == 1) {
      this.rutName = '';
      this.fileInputRUT.nativeElement.value = '';
    } else if (id == 2) {
      this.camComName = '';
      this.fileInputCamCom.nativeElement.value = '';
    } else if (id == 3) {
      this.CCName = '';
      this.fileInputCC.nativeElement.value = '';
    }
  }

  /**
   * Consigue el link del archivo PDF guardado en el back
   * la función retorna el link de cada documento
   */
  getDocuments() {
    this.distribuidorService.getDocument(this.user._id).subscribe(
      (res) => {
        res.forEach((e: any) => {
          if (e.documento == 'Representante legal') {
            this.CCPDF = e.locacion;
          } else if (e.documento == 'Camara de comercio') {
            this.camComPDF = e.locacion;
          } else if (e.documento == 'Rut') {
            this.rutPDF = e.locacion;
          }
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /**
   * Cuando se realizan cambias en el logo se debe reescribir la información en el localStorage
   * para ver los resultados del cambio sin neceisdad de hacer logout y login
   */
  guardarTrabajadorEnStore() {
    if (this.authService.user?.usuario_horeca != undefined) {
      //El trabajador que inició sesión es parte de un Horeca
      this.restService
        .getJWT('usuario_horeca/' + this.authService.user?.usuario_horeca)
        .toPromise()
        .then((resp_2: any) => {
          this.authService.user_horeca = resp_2;
          //Se guarda la info de inicio de sesión en el Store
          this.ngxsStore
            .dispatch(
              new SetSelectedToken({
                token: this.authService.token,
                email: this.authService.email,
                user: this.authService.user,
                user_horeca: this.authService.user_horeca,
                user_distribuidor: undefined,
                user_organizacion: undefined,
              })
            )
            .toPromise()
            .then();
          this.router.navigate(['/cuenta']);
        });
    } else if (this.authService.user?.distribuidor != undefined) {
      //El trabajador que inició sesión es parte de un Distribuidor
      this.restService
        .getJWT('distribuidor/' + this.authService.user?.distribuidor)
        .toPromise()
        .then((resp_2: any) => {
          this.authService.user_distribuidor = new Distribuidor(
            resp_2.nombre,
            resp_2.correo,
            resp_2.descripcion,
            resp_2.tiempo_entrega,
            resp_2.ciudad,
            resp_2.ranking,
            resp_2.valor_minimo_pedido,
            resp_2.departamento,
            resp_2.tipo || '',
            resp_2.logo || '',
            resp_2._id,
            resp_2.cobertura_coordenadas || [],
            resp_2.horario_atencion,
            resp_2.metodo_pago,
            resp_2.direccion,
            resp_2.nit_cc,
            resp_2.razon_social || '',
            resp_2.celular,
            resp_2.telefono || ''
          );
          //Se guarda la info de inicio de sesión en el Store
          this.ngxsStore
            .dispatch(
              new SetSelectedToken({
                token: this.authService.token,
                email: this.authService.email,
                user: this.authService.user,
                user_horeca: undefined,
                user_distribuidor: this.authService.user_distribuidor,
                user_organizacion: undefined,
              })
            )
            .toPromise()
            .then();
          this.router.navigate(['/cuenta']);
        });
    } else if (this.authService.user?.organizacion != undefined) {
      //El trabajador que inició sesión es parte de una organizacion
      this.restService
        .getJWT('organizacion/' + this.authService.user?.organizacion)
        .toPromise()
        .then((resp_2: any) => {
          //TODO: Definir el modelo correctamente
          this.authService.user_organizacion = resp_2;
          //Se guarda la info de inicio de sesión en el Store
          this.ngxsStore
            .dispatch(
              new SetSelectedToken({
                token: this.authService.token,
                email: this.authService.email,
                user: this.authService.user,
                user_horeca: undefined,
                user_distribuidor: undefined,
                user_organizacion: this.authService.user_organizacion,
              })
            )
            .toPromise()
            .then();
          this.router.navigate(['/cuenta']);
        });
    }
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateNumber(event: any) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || excludedKeys.includes(keyCode))) {
      event.preventDefault();
    }
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateOnlyText(event: any) {
    const keyCode = event.keyCode;
    if (keyCode >= 48 && keyCode <= 57) {
      event.preventDefault();
    }
  }

  /**
   * Si no cumple se cierra la función con Return, si cumple no entra al return y se habilita el botón.
   */
  habilitarBoton() {
    if (
      this.cuentaForm.get('cuentaTelefono2')?.value.toString().length >= 1 &&
      this.cuentaForm.get('cuentaTelefono2')?.value.toString().length != 7 &&
      this.cuentaForm.get('cuentaTelefono2')?.value.toString().length != 10
    ) {
      this.is_boton_habilitado = this.cuentaForm.invalid;
      return;
    } else if (this.CCPDF == '' && this.rutPDF == '') {
      if (this.camComPDF == '' && this.authService.user_horeca?.tipo_usuario == 'Empresa') {
        this.is_boton_habilitado = this.cuentaForm.invalid;
        return;
      }
      this.is_boton_habilitado = this.cuentaForm.invalid;
      return;
    } else {
      this.is_boton_habilitado = !this.cuentaForm.invalid;
    }
  }

  /**
   * Alerta si el formulario esta incompleto o un input es invalido
   */
  alertaFormularioInvalido() {
    const modalRef = this.modalService.open(SimpleComponent);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = '¡Por favor asegúrate de llenar todos los datos y vuelve a intentarlo!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {};
  }

  alertaDocumentoNoEncotrado() {
    const modalRef = this.modalService.open(SimpleComponent);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = '¡Este documento no fue encontrado, favor subirlo a la plataforma!';
    modalRef.componentInstance.btn_msg = 'Volver';
  }
}
