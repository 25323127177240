<app-header-organizacion></app-header-organizacion>
<div id="content">
  <div class="row">
    <div class="col-12 titulo">
      <div class="btn-volver cursorPointer" routerLink="/distribuidores-organizacion">
        <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
        Volver
      </div>
      <h4>{{ distribuidor?.nombre || '' }}</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-8">
          <div class="contendioContenedor my-4 margin-top-0">
            <div *ngIf="productos_distribuidor">
              <div class="cardDistribuidor" *ngFor="let producto of productos_distribuidor"
                [routerLink]="'/detalle-producto-distribuidor-organizacion/' + id_distribuidor + '/' + producto._id + '/distribuidor/' + id_distribuidor">
                <div class="codDistribuidor text-center">
                  <span>Código producto dist.<br>{{ producto?.codigo_distribuidor_producto || '' }}</span>
                </div>
                <div class="ubicacion_Puntaje">
                  <div>
                    <span class="iconosCard">{{ producto?.precios[0]?.estado }}</span>
                  </div>
                  <div>
                    <span class="iconosCard">{{ producto?.precios[0]?.puntos_ft_unidad }} puntos ft</span>
                  </div>
                </div>
                <div class="imgContainer">
                  <img src="{{ producto.fotos[0] || '../../../../assets/img/brand-2.png' }}" alt="" />
                </div>
                <div>
                  <span>{{ producto.nombre }}</span>
                </div>
                <div class="preciosDetalle">
                  <div>
                    <span>Precio und.</span>
                    <span class="pUnidad">${{ producto?.precios[0]?.precio_unidad | currency: '$ ':'symbol': '1.0-0' }}</span>
                  </div>
                  <div>
                    <span>Precio caja</span>
                    <span class="pUnidad">${{ producto?.precios[0]?.precio_caja | currency: '$ ':'symbol': '1.0-0' }}</span>
                  </div>
                  <div>
                    <span>Precio {{ producto?.precios[0].unidad_medida || 'und' }}</span>
                    <span>
                      {{ (producto?.precios[0].precio_unidad/producto?.precios[0].cantidad_medida)
                      | currency: '$ ':'symbol':'1.2-2' }}
                    </span>
                  </div>
                  <div>
                    <span>Caja x</span>
                    <span>{{ producto?.precios[0].und_x_caja || 1 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
           <div class="col-4">
          <div class="scrollDetalles contenidoDetalles">
            <div class="preciosDetalle flexSpace" *ngIf="distribuidor">
              <div>
                <span class="iconosCard">
                  <img src="../../../../../assets/iconos-org/location.png" alt="" />
                  {{ distribuidor.ciudad }}
                </span>
                <span class="cantidadVendedores">{{total_trabajadores}} Vendedores</span>
              </div>
              <div>
                <img src="{{ distribuidor.logo }}" alt="" />
                <div>
                  <span>{{ distribuidor.nombre }}</span>
                  <span class="sectorPildora">{{ distribuidor.tipo }}</span>
                  <div>
                    <span class="iconosCard">
                      <img src="../../../../assets/iconos-org/transport.png" alt="" />
                      {{ distribuidor.tiempo_entrega }}
                    </span>
                  </div>
                </div>
              </div>
              <span>Evaluación</span>
              <div class="evaluacionData">
                <span class="iconosCard">
                  <img src="../../../../../assets/iconos-org/star.png" alt="" />
                  {{calificacion.abastecimiento}}
                </span>
                <span>Abastecimiento</span>
              </div>
              <div class="evaluacionData">
                <span class="iconosCard">
                  <img src="../../../../../assets/iconos-org/star.png" alt="" />
                  {{calificacion.precio}}
                </span>
                <span>Precio</span>
              </div>
              <div class="evaluacionData">
                <span class="iconosCard">
                  <img src="../../../../../assets/iconos-org/star.png" alt="" />
                  {{calificacion.puntualidad_entrega}}
                </span>
                <span>Puntualidad</span>
              </div>
              <div class="metodosPago">
                <span>Métodos de pago: </span>
                <span>{{distribuidor.metodo_pago}}</span>
              </div>
              <hr />
              <div>
                <span>Pedido mínimo: </span>
                <span>{{ distribuidor.valor_minimo_pedido | currency: '$ ':'symbol': '1.0-0' }}</span>
              </div>
              <div>
                <span>Productos: </span>
                <span>{{productos_distribuidor?.length || 0}}</span>
              </div>
              <div>
                <span>Establecimientos alcanzados: </span>
                <span>{{sillas}}</span>
              </div>
              <div>
                <span>Sillas alcanzadas: </span>
                <span>{{total_establecimientos}}</span>
              </div>
              <div>
                <span>Promedio de ventas: </span>
                <span>{{promedio_ventas | currency: '$ ':'symbol': '1.0-0'}}</span>
              </div>
              <hr />
              <h6>Información adicional</h6>
              <div class="d-flex flex-row justify-content-between">
                <span>Dirección: </span>
                <span class="text-right">{{ distribuidor.direccion }} - {{ distribuidor.ciudad }}</span>
              </div>
              <div class="d-flex flex-row justify-content-between">
                <span>Teléfono: </span>
                <span class="text-right">{{ distribuidor.celular }} - {{ distribuidor.telefono }}</span>
              </div>
              <div class="d-flex flex-row justify-content-between">
                <span>Correo: </span>
                <span class="text-right">{{ distribuidor.correo }}</span>
              </div>
            </div>
          </div>
          <div class="chatBoton contenidoDetalles">
            <button routerLink="/chat-distribuidor-organizacion">Chat</button>
          </div>
          <div class="topVentas" *ngIf="data_top_productos">
            <div>
              <span>Top 10 más vendidos</span>
            </div>
            <div class="productos-container">
              <div class="productos" *ngFor="let producto of data_top_productos">
                <img src="{{ producto.logo[0] }}" alt="logo producto" />
                <div class="d-flex flex-column">
                  <p class="descripcionProd">{{ producto.nombre }}</p>
                  <p class="descripcionProd">Cod. Org.: {{ producto.cod_org || 'No registra' }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>