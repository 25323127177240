<div class="container-fluid fondo overflow-hidden d-flex justify-content-center align-items-center">
  <div class="row contenedor">
    <div class="col">
      <h2 class="title-app">App móvil</h2>
      <div class="mt-4">
        <p class="description">
          Si eres un Establecimiento Horeca podrás descargar la app desde nuestras tiendas para tener a la mano el
          control y seguimiento de todo lo que Feat. Ofrece para ti.
        </p>
      </div>
      <div class="d-flex">
        <div class="btn-app mr-3 d-flex point" (click)="descargarApp()">
          <div class="ml-3 mt-2 mr-2">
            <fa-icon [icon]="faGooglePlay" [styles]="{ color: '#FFFFFF', width: '30px', height: '30px' }" id="fa-icon">
            </fa-icon>
          </div>
          <div>
            <div>
              <span class="avaible">Disponible en
                <br>
                <span class="app">Google play</span>
              </span>
            </div>
          </div>
        </div>
        <div class="btn-app d-flex point" (click)="descargarApp()">
          <div class="ml-3 mt-2 mr-2">
            <fa-icon [icon]="faApple" [styles]="{ color: '#FFFFFF', width: '30px', height: '30px' }" id="fa-icon">
            </fa-icon>
          </div>
          <div>
            <div>
              <span class="avaible">Disponible en
                <br>
                <span class="app">App Store</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col image-container">
      <div class="circulo point" (click)="descargarApp()">
        <img src="../../../assets/img/Landing/appmovil.png" class="img-app" alt="Dos celulars mostrando la app de Feat">
      </div>
    </div>
  </div>
</div>