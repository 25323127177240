<div class="card p-5 m-0">
  <h5>Datos personales</h5>
  <div class="bottom-form mb-1" [formGroup]="distribuidorForm">
    <!--Tipo de documento-->
    <div class="tipo-documento">
      <label><span class="asterisco">*</span>Tipo de documento</label>
      <input formControlName="distribuidorTipoDocumento" [attr.disabled]="true"
        [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorTipoDocumento')?.hasError('required') && distribuidorForm.get('distribuidorTipoDocumento')?.touched )}"
        name="tipo documento" placeholder="Escribe aquí" />
    </div>
    <!--Número de documento-->
    <div class="numero-documento">
      <label for="nit"><span class="asterisco">*</span>Número de documento</label>
      <input formControlName="distribuidorNumeroDocumento" [attr.disabled]="true"
        [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorNumeroDocumento')?.hasError('required') && distribuidorForm.get('distribuidorNumeroDocumento')?.touched )}"
        name="nit" placeholder="Escribe aquí" (keydown)="validateNumber($event)" />
    </div>
    <!--Nombre-->
    <div class="nombres">
      <label for="nombres"><span class="asterisco">*</span>Nombres</label>
      <input formControlName="distribuidorNombre" (keydown)="validateOnlyText($event)" name="nombre_establecimiento"
        placeholder="Escribe aquí"
        [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorNombre')?.hasError('required') && distribuidorForm.get('distribuidorNombre')?.touched )}" />
    </div>
    <!--Apellidos-->
    <div class="apellidos">
      <label for="apellidos"><span class="asterisco">*</span>Apellidos</label>
      <input formControlName="distribuidorApellido" (keydown)="validateOnlyText($event)" name="apellidos"
        placeholder="Escribe aquí"
        [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorApellido')?.hasError('required') && distribuidorForm.get('distribuidorApellido')?.touched )}" />
    </div>
    <!--País-->
    <div class="pais">
      <label for="pais"><span class="asterisco">*</span>País</label>
      <select formControlName="distribuidorPais" class="d-inline-block dropdown-form-1"
        [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorPais')?.hasError('required') && distribuidorForm.get('distribuidorPais')?.touched )}">
        <option selected disabled value="Colombia">Colombia</option>
      </select>
    </div>
    <!--Departamento-->
    <div class="departamento">
      <label for="departamento"><span class="asterisco">*</span>Departamento</label>
      <select formControlName="distribuidorDepartamento" class="d-inline-block dropdown-form-1" (change)="getPlaces()"
        (change)="distribuidorForm.patchValue({ distribuidorCiudad: '' })"
        [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorDepartamento')?.hasError('required') && distribuidorForm.get('distribuidorDepartamento')?.touched )}">
        <option [ngValue]="dpto.name" *ngFor="let dpto of departamentos_empresa">
          {{ dpto.name }}
        </option>
      </select>
    </div>
    <!--Ciudad-->
    <div class="ciudad">
      <label for="ciudad"><span class="asterisco">*</span>Ciudad</label>
      <select formControlName="distribuidorCiudad" class="d-inline-block dropdown-form-1"
        [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorCiudad')?.hasError('required') && distribuidorForm.get('distribuidorCiudad')?.touched )}">
        <option selected disabled value="">Selecciona</option>
        <option value={{city.name}} *ngFor="let city of ciudades">{{city.name}}</option>
      </select>
    </div>
    <!--Celular-->
    <div class="celular">
      <label for="telefono"><span class="asterisco">*</span>Celular</label>
      <input formControlName="distribuidorTelefono" name="telefono" type="number" placeholder="Escribe aquí"
        (keydown)="validateNumber($event)"
        [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorTelefono')?.hasError('required') && distribuidorForm.get('distribuidorTelefono')?.touched ) || ( distribuidorForm.get('distribuidorTelefono')?.hasError('pattern') && distribuidorForm.get('distribuidorTelefono')?.touched )}" />
      <span class="w-100 text-danger fw-bold text-right"
        *ngIf="distribuidorForm.get('distribuidorTelefono')?.hasError('pattern') && distribuidorForm.get('distribuidorTelefono')?.touched">
        <span class="alerta">Númerico de 10 dígitos</span>
      </span>
    </div>
    <!--Teléfono 2-->
    <div class="telefono">
      <label for="telefono">Teléfono (Opcional)</label>
      <input formControlName="distribuidorTelefono2" name="telefono2" type="text" placeholder="Escribe aquí"
        (keydown)="validateNumber($event)"
        [ngClass]="{'invalid-warning': this.distribuidorForm.get('distribuidorTelefono2')?.value.toString().length >=  1 && distribuidorForm.get('distribuidorTelefono2')?.touched && this.distribuidorForm.get('distribuidorTelefono2')?.value.toString().length != 7 && this.distribuidorForm.get('distribuidorTelefono2')?.value.toString().length != 10 }" />
      <span class="reactive-text text-danger"
        *ngIf="this.distribuidorForm.get('distribuidorTelefono2')?.value.toString().length >=  1 && distribuidorForm.get('distribuidorTelefono2')?.touched && this.distribuidorForm.get('distribuidorTelefono2')?.value.toString().length != 7 && this.distribuidorForm.get('distribuidorTelefono2')?.value.toString().length != 10">
        <span class="alerta">Númerico de 7 o 10 dígitos</span>
      </span>
    </div>
    <!--Correo de ingreso-->
    <div class="correo">
      <label for="correo"><span class="asterisco">*</span>Correo de ingreso</label>
      <input formControlName="distribuidorCorreo" name="correo" type="text" placeholder="Escribe aquí"
        [attr.disabled]="true"
        [ngClass]="{'invalid-warning': distribuidorForm.get('distribuidorCorreo')?.hasError('required') && distribuidorForm.get('distribuidorCorreo')?.touched }" />
    </div>
    <!--Tipo de usuario-->
    <div class="tipo-usuario">
      <label for="pais"><span class="asterisco">*</span>Tipo de usuario</label>
      <select formControlName="distribuidorTipo" class="d-inline-block dropdown-form-1"
        [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorTipo')?.hasError('required') && distribuidorForm.get('distribuidorTipo')?.touched )}">
        <option disabled value="Selecciona">Selecciona</option>
        <option value="PROPIETARIO/REP LEGAL">PROPIETARIO/REP LEGAL</option>
        <option value="ADMINISTRADOR">ADMINISTRADOR</option>
        <option value="OPERATIVO COMERCIAL">OPERATIVO COMERCIAL</option>
      </select>
    </div>
  </div>
  <div class="mt-3">
    <button class="btn-submit px-5" (click)=" is_boton_habilitado && guardarCambios()"
      (click)="!is_boton_habilitado && alertaFormularioInvalido()"
      [ngClass]="{'btn-submit-disabled': !is_boton_habilitado}">
      Guardar cambios
    </button>
  </div>
</div>