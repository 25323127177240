<div class="container heigthCont">
  <div>
    <span>Filtrar búsqueda</span>
    <fa-icon style="cursor: pointer;" [icon]="faTimes" [styles]="{ color: '#41403E' }" id="fa-icon"
      (click)="activeModal.close('Close click')">
    </fa-icon>
  </div>

  <div>
    <span>Cantidad de estrellas</span>
    <div class="stars-container">
      <fa-icon class="cursorPointer" [icon]=" (1 > ranking) ? faStar : faStarSolid" [styles]="{ color: '#41403E' }"
        size="lg" id="fa-icon" (click)="setRanking(1)"></fa-icon>
      <fa-icon class="cursorPointer" [icon]=" (2 > ranking) ? faStar : faStarSolid" [styles]="{ color: '#41403E' }"
        size="lg" id="fa-icon" (click)="setRanking(2)"></fa-icon>
      <fa-icon class="cursorPointer" [icon]=" (3 > ranking) ? faStar : faStarSolid" [styles]="{ color: '#41403E' }"
        size="lg" id="fa-icon" (click)="setRanking(3)"></fa-icon>
      <fa-icon class="cursorPointer" [icon]=" (4 > ranking) ? faStar : faStarSolid" [styles]="{ color: '#41403E' }"
        size="lg" id="fa-icon" (click)="setRanking(4)"></fa-icon>
      <fa-icon class="cursorPointer" [icon]=" (5 > ranking) ? faStar : faStarSolid" [styles]="{ color: '#41403E' }"
        size="lg" id="fa-icon" (click)="setRanking(5)"></fa-icon>
    </div>
  </div>

  <div class="tiempos">
    <span>Tiempos de entrega</span>
    <select name="tiempo_entrega" id="" [(ngModel)]="tiempo_entrega" (change)="filtroTiempoEntrega()"
      class="cursorPointer">
      <option value="Selecciona">Selecciona</option>
      <option value="2 - 4 horas">2 - 4 horas</option>
      <option value="4 - 6 horas">4 - 6 horas</option>
      <option value="6 - 8 horas">6 - 8 horas</option>
      <option value="8 - 10 horas">8 - 10 horas</option>
      <option value="10 - 12 horas">10 - 12 horas</option>
      <option value="12 - 14 horas">12 - 14 horas</option>
      <option value="14 - 16 horas">14 - 16 horas</option>
      <option value="16 - 18 horas">16 - 18 horas</option>
      <option value="18 - 20 horas">18 - 20 horas</option>
      <option value="20 - 22 horas">20 - 22 horas</option>
      <option value="22 - 24 horas">22 - 24 horas</option>
    </select>
  </div>

  <span>Categoría</span>

  <div>
    <div (click)="todos_filtros_desactivados = !todos_filtros_desactivados" (click)="seleccionCategoria(true)"
      class="cursorPointer">
      <img
        [src]="todos_filtros_desactivados ? '../../assets/img/icons/icono-caja-on.png' : '../../assets/img/icons/icono-caja-off.png'"
        alt="Todos" srcset="" />
      <span [style]="todos_filtros_desactivados ? 'color: #8E6FF7;' : 'color: #41403E;'">&nbsp;Todos</span>
    </div>
    <hr class="m-2">
    <div *ngFor="let cat of categorias_filtro_distribuidores; index as i;">
      <div class="cursorPointer"
        (click)="seleccionCategoria(false, cat._id)">
        <img [src]="cat.logoOn" alt="icono {{cat.nombre}}" srcset="" />
        <span [style]="categoriasSeleccionadas.includes(cat._id) ? 'color: #8E6FF7;' : 'color: #41403E;'">&nbsp;{{cat.nombre}}</span>
      </div>
      <hr class="m-2">
    </div>
  </div>
  <div class="actions">
    <span (click)="limpiarFiltros()" class=" cursorPointer">Limpiar filtros</span>
    <button (click)="filtrar()" class="purple-button cursorPointer">Filtrar</button>
  </div>
</div>