<div class="card-info p-3 mb-0 d-flex flex-column align-items-between">
  <div class="">
    <label for="vendedor-dropdown" class="m-0">Vendedor</label>
    <div class="row no-gutters">
      <select class="form-control col-8" id="vendedor-dropdown" [(ngModel)]="vendedorTmp" aria-placeholder="Selecciona"
        required>
        <option value="" disabled selected hidden>Selecciona</option>
        <option *ngFor="let vendedor of vendedores" [value]="vendedor._id">
          {{ vendedor.nombres + ' ' + vendedor.apellidos }}
        </option>
      </select>
      <a (click)="asignarVendedor()" class="asignar-btn col-4 my-auto text-center">+ Asignar</a>
    </div>
    <div class="row m-2 vendedores-div pr-4" *ngIf="vendedoresCliente.length >= 1">
      <div *ngFor="let vendedorAsignado of vendedoresCliente" class="vendedor-chip">
        <span class="vendedor-chip-text">{{ vendedorAsignado.nombres + ' ' + vendedorAsignado.apellidos }}</span>
        <fa-icon [icon]="faTimes" [styles]="{ color: '#41403E' }" id="fa-icon"
          (click)="eliminarVendedor(vendedorAsignado)" class="eliminar-btn"></fa-icon>
      </div>
    </div>
  </div>
  <div class="row no-gutters">
    <label for="precios-dropdown" class="m-0 mt-2">Convenio de precios</label>
    <select class="form-control col-8" id="precios-dropdown" aria-placeholder="Selecciona" required
      [(ngModel)]="tieneConvenio" (ngModelChange)="changeConvenio($event)">
      <option value="" [disabled]="true" [selected]="true">Selecciona</option>
      <option *ngFor="let opcionPrecios of opcionesPrecios" [value]="opcionPrecios.value">
        {{ opcionPrecios.label }}
      </option>
    </select>
    <a *ngIf="selectProducts" (click)="verProductos()" class="asignar-btn col-4 my-auto text-center"> Ver productos</a>
  </div>

  <div>
    <label for="estado-dropdown" class="m-0 mt-2">Estado del cliente</label>
    <select class="form-control col-8" id="estado-dropdown" aria-placeholder="Selecciona" required
      [(ngModel)]="tieneCartera" (ngModelChange)="changeCartera($event)">
      <option value="" [disabled]="true" [selected]="true">Selecciona</option>
      <option *ngFor="let estado of opcionesCartera" [value]="estado.value">{{ estado.label }}</option>
    </select>
  </div>
</div>