<app-header-horeca></app-header-horeca>
<div class="d-flex justify-content-center mt-2">
  <div class="contenedor w-100 p-5" *ngIf="trabajador != undefined">
    <div class="align-center">
      <button class="boton-volver" routerLink="/cuenta">
        <fa-icon [icon]="faChevronLeft"></fa-icon>
        Volver
      </button>
      <h2 class="main-title text-left ml-4 mb-0">{{ trabajador.nombres }} {{ trabajador.apellidos }}</h2>
    </div>
    <div class="container-column">
      <form class="p-5">
        <div class="mb-4">
          <span class="section-title">Datos personales</span>
        </div>
        <div class="form-input d-flex row">
          <div class="w-100 col-12 col-sm-6 col-lg-4">
            <label for="tipo_documento" class="w-100">Tipo de documento</label>
            <select [attr.disabled]="true" name="tipo_documento" class="w-100 disabled"
              [(ngModel)]="trabajador.tipo_documento">
              <option>Selecciona</option>
              <option value="Cedula de ciudadania" (click)="trabajador.tipo_documento = 'Cedula de ciudadania'">
                Cédula de ciudadanía
              </option>
              <option value="Cedula de extranjeria" (click)="trabajador.tipo_documento = 'Cedula de extranjeria'">
                Cédula de extranjería
              </option>
              <option value="Pasaporte" (click)="trabajador.tipo_documento = 'Pasaporte'">
                Pasaporte
              </option>
            </select>
          </div>
          <div class="w-100 col-12 col-sm-6 col-lg-4">
            <label for="num_doc" class="w-100">Número de documento</label>
            <input [attr.disabled]="true" name="num_doc" class="w-100 disabled" type="text"
              [(ngModel)]="trabajador.numero_documento" />
          </div>
          <div class="w-100 col-12 col-sm-6 col-lg-4">
            <label for="nombres" class="w-100">Nombres</label>
            <input [attr.disabled]="true" name="nombres" type="text" class="w-100 disabled"
              [(ngModel)]="trabajador.nombres" />
          </div>
          <div class="w-100 col-12 col-sm-6 col-lg-4">
            <label for="apellidos" class="w-100">Apellidos</label>
            <input [attr.disabled]="true" name="apellidos" type="text" class="w-100 disabled"
              [(ngModel)]="trabajador.apellidos" />
          </div>
          <div class="w-100 col-12 col-sm-6 col-lg-4">
            <label for="pais" class="w-100">País</label>
            <input [attr.disabled]="true" name="pais" class="w-100 disabled" [(ngModel)]="trabajador.pais" />
          </div>
          <div class="w-100 col-12 col-sm-6 col-lg-4">
            <label for="departamento" class="w-100">Departamento</label>
            <input [attr.disabled]="true" name="departamento" class="w-100 disabled"
              [(ngModel)]="trabajador.departamento" />
          </div>
          <div class="w-100 col-12 col-sm-6 col-lg-4">
            <label for="ciudad" class="w-100">Ciudad</label>
            <input name="ciudad" class="w-100 disabled" [attr.disabled]="true" [(ngModel)]="trabajador.ciudad" />
          </div>
          <div class="w-100 col-12 col-sm-6 col-lg-4">
            <label for="celular" class="w-100">Celular</label>
            <input [attr.disabled]="true" name="celular" type="text" class="w-100 disabled"
              [(ngModel)]="trabajador.celular" />
          </div>
          <div class="w-100 col-12 col-sm-6 col-lg-4">
            <label for="telefono" class="w-100">Teléfono</label>
            <span class="ml-2">{{trabajador.telefono}}</span>
          </div>
        </div>
        <div class="mb-4">
          <span class="section-title" class="w-100">Información cuenta</span>
        </div>
        <div class="form-input input-info-cuenta w-100 row">
          <div class="w-100 col-12 col-sm-6 col-lg-4 mb-0">
            <label for="correo" class="w-100">Correo de ingreso</label>
            <input [attr.disabled]="true" name="telefono" type="text" class="w-100 disabled"
              [(ngModel)]="trabajador.correo " />
          </div>
          <div class="w-100 col-12 col-sm-6 col-lg-4 mb-0">
            <label for="tipo_trabajador" class="w-100">Tipo de usuario</label>
            <select name="tipo_trabajador" class="w-100" [(ngModel)]="trabajador.tipo_trabajador">
              <option value="ADMINISTRADOR APROBADOR">ADMINISTRADOR APROBADOR</option>
              <option value="PLANEADOR PEDIDOS">PLANEADOR PEDIDOS</option>
              <option value="PROPIETARIO/REP LEGAL">PROPIETARIO/REP LEGAL</option>
            </select>
          </div>
        </div>
      </form>
    </div>
    <div class="btn-container px-4 pt-4 pb-0">
      <button class="btn-modal" id="btn-rechazar" (click)="desactivarTrabajador()">Rechazar</button>
      <button class="btn-modal" id="btn-aprobar" (click)="actualizarTrabajador()">Aprobar</button>
    </div>
  </div>
</div>