import { Component, OnDestroy, OnInit } from '@angular/core';
import { CambiarContrasenaComponent } from 'src/app/modal/cambiar-contrasena/cambiar-contrasena.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RestService } from 'src/app/services/rest/rest.service';
import { Trabajador } from 'src/app/models/trabajador.model';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-informacion-usuario',
  templateUrl: './informacion-usuario.component.html',
  styleUrls: ['./informacion-usuario.component.css'],
})
export class InformacionUsuarioComponent implements OnInit, OnDestroy {
  constructor(public authService: AuthService, private modalService: NgbModal, private restService: RestService) {}

  /** Referencias a iconos FontAwesome */
  public faSignOutAlt = faSignOutAlt;

  /** Listado de trabajadores asociados cuya solicitud está en "Aprobado" */
  trabajadores_aprobados: Trabajador[] = [];

  puntos_entrega: any[] = [];
  modalCambioClave: any;

  ngOnInit(): void {
    this.traerTrabajadores();
    this.puntosEntrega();
  }

  ngOnDestroy() {
    this.modalCambioClave?.close();
  }

  /**
   * Recorre la lista de trabajadores y toma aquellos que estén registrados con este
   * usuario horeca. Luego, revisa el estado de la solicitud de cada trabajador y
   * los pone en trabajadores_pendientes o trabajadores_aprobados según sea apropiado
   */
  async traerTrabajadores() {
    this.trabajadores_aprobados = [];
    const id_horeca: string = this.authService.user?.usuario_horeca || '';

    const resp: any = await this.restService.getJWT(`trabajadores_de_horeca/${id_horeca}`).toPromise();

    resp.forEach((actual: any) => {
      if (actual.usuario_horeca == id_horeca) {
        if (actual.solicitud_vinculacion == 'Aprobado') {
          this.trabajadores_aprobados.push(actual);
        }
      }
    });
  }

  puntosEntrega() {
    const id_horeca: string = this.authService.user_horeca?._id || '';
    this.restService
      .getJWT('punto_entrega')
      .toPromise()
      .then((resp: any) => {
        Promise.all(
          resp.map(async (actual: any) => {
            if (actual.usuario_horeca == id_horeca && actual.estado == 'Activo') {
              this.puntos_entrega.push(actual);
            }
          })
        );
      });
  }

  onContrasena() {
    this.modalCambioClave = this.modalService.open(CambiarContrasenaComponent, {
      backdrop: 'static',
      centered: true,
      windowClass: 'modal-cambiar-contrasena',
    });
  }
}
