<app-header-horeca *ngIf="tipo_usuario === 'usuario_horeca'"></app-header-horeca>
<app-header-distribuidor *ngIf="tipo_usuario === 'distribuidor'"></app-header-distribuidor>
<app-header-organizacion *ngIf="tipo_usuario === 'organizacion'"></app-header-organizacion>
<div class="main-container p-5">
  <div class="container-nav row mb-3 w-100 px-4">
    <h3 class="titulo-noticias main-title col-12 col-md-6">Comunidad Feat</h3>
    <div class="d-flex col-12 col-md-6 justify-content-end buttons-container">
      <a (click)="verMisPublicaciones()" class="publicaciones" *ngIf="!misPublicaciones">
        Mis publicaciones
      </a>
      <a (click)="verMisPublicaciones()" class="publicaciones" *ngIf="misPublicaciones">
        Todas las publicaciones
      </a>
      <button class="button-publicar" (click)="onPublicar()">+ Publica</button>
    </div>
  </div>
  <div class="container">
    <div class="noticias">
      <app-publicaciones [misPublicaciones]="misPublicaciones" class="w-100"></app-publicaciones>
    </div>
  </div>
</div>