import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class PublicacionService {
  constructor(private http: HttpClient, private authService: AuthService) {}
  private headers = new HttpHeaders({ Authorization: this.authService.token });

  //**Servicio para traer mis publicaciones */
  public getPublicationsFullData(userHoreca: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/publicacion/mispublicaciones/${userHoreca}`, {
      headers: this.headers,
    });
  }

  //**Servicio para traer mis publicaciones */
  public getPublications(): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/publicacion/`, {
      headers: this.headers,
    });
  }

  //**Servicio para obtener publicaciones */
  public getMyPublications(userHoreca: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/publicacion/mispublicaciones/${userHoreca}`, {
      headers: this.headers,
    });
  }

  //**Servicio para obtener publicaciones */
  public getPublication(idPublication: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/publicacion/${idPublication}`, {
      headers: this.headers,
    });
  }

  //**Servicio para eliminar publicaciones */
  public deleteMyPublication(idPublication: string): Observable<any> {
    return this.http.delete<any>(`${environment.backendUrl}/api/publicacion/${idPublication}`, {
      headers: this.headers,
    });
  }

  //Servicio para Enviar publicacion
  public postMyPublication(publicacion: any) {
    return this.http.post<any>(`${environment.backendUrl}/api/publicacion/`, publicacion, {
      headers: this.headers,
    });
  }

  //**Servicio para enviar imagen de la publicacion al s3 */
  public uploadImgPublication(id: any, dato: any) {
    return this.http.post<any>(`${environment.backendUrl}/api/recursos/publicaciones/${id}`, dato, {
      headers: this.headers,
    });
  }

  //** Servicio para traer al usuario horeca */
  public getUserHoreca(id: any) {
    return this.http.get<any>(`${environment.backendUrl}/api/usuario_horeca/${id}`, {
      headers: this.headers,
    });
  }

  //** Servicio para traer al distribuidor */
  public getUserDistribuidor(id: any) {
    return this.http.get<any>(`${environment.backendUrl}/api/distribuidor/${id}`, {
      headers: this.headers,
    });
  }

  //** Servicio para traer a la organizacion */
  public getUserOrganizacion(id: any) {
    return this.http.get<any>(`${environment.backendUrl}/api/organizacion/${id}`, {
      headers: this.headers,
    });
  }

  //**Servicio para obtener los comentarios */
  public getComments() {
    return this.http.get<any>(`${environment.backendUrl}/api/comentario`, {
      headers: this.headers,
    });
  }

  //** Servicio para enviar comentarios */
  public postComment(comment: any) {
    return this.http.post<any>(`${environment.backendUrl}/api/comentario`, comment, {
      headers: this.headers,
    });
  }

  //* Servicio para traer data de los likes*/
  public getLikes() {
    return this.http.get<any>(`${environment.backendUrl}/api/likes`, {
      headers: this.headers,
    });
  }

  //* Servicio para enviar data de los likes*/
  public postLike(like: any) {
    return this.http.post<any>(`${environment.backendUrl}/api/likes`, like, {
      headers: this.headers,
    });
  }

  //* Servicio para eliminar likes*/
  public deleteLike(id: any) {
    return this.http.delete<any>(`${environment.backendUrl}/api/likes/${id}`, {
      headers: this.headers,
    });
  }
}
