<app-header-distribuidor></app-header-distribuidor>
<div class="d-flex justify-content-center">
  <section class="contenido p-5 w-100">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills">
      <li [ngbNavItem]="1" class="text-center p-0">
        <a ngbNavLink *ngIf="blockUserModule">Trabajadores</a>
        <a ngbNavLink *ngIf="!blockUserModule">Mi perfil</a>
        <ng-template ngbNavContent>
          <div class="column">
            <app-informacion-distribuidor></app-informacion-distribuidor>
            <app-trabajadores-distribuidor *ngIf="blockUserModule"></app-trabajadores-distribuidor>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2" class="text-center p-0" *ngIf="blockUserModule">
        <a ngbNavLink>Información empresa</a>
        <ng-template ngbNavContent>
          <app-informacion-empresa class="w-100"></app-informacion-empresa>
        </ng-template>
      </li>
      <li [ngbNavItem]="3" class="text-center p-0" *ngIf="blockUserModule">
        <a ngbNavLink>Información de cuenta</a>
        <ng-template ngbNavContent>
          <div class="column cuenta">
            <app-informacion-distribuidor></app-informacion-distribuidor>
            <app-informacion-cuenta-distribuidor></app-informacion-cuenta-distribuidor>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="4" class="text-center p-0" *ngIf="blockUserModule">
        <a ngbNavLink>Gráficas</a>
        <ng-template ngbNavContent>
          <app-graficas-distribuidor class="w-100"></app-graficas-distribuidor>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </section>
</div>