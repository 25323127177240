import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';
import { TransaccionService } from 'src/app/services/transaccion/transaccion.service';

@Component({
  selector: 'app-respuesta-pago-plan',
  templateUrl: './respuesta-pago-plan.component.html',
  styleUrls: ['./respuesta-pago-plan.component.css'],
})
export class RespuestaPagoPlanComponent implements OnInit {
  faWhatsapp = faWhatsapp;

  wompiId: string | undefined;
  payment: any;
  transaction: any;

  ngbModalOptions: NgbModalOptions = {
    //Evita que al hacer click por fuera se cierre el modal
    backdrop: 'static',
    keyboard: false,
    centered: true,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private distribuidorService: DistribuidorService,
    private modalService: NgbModal,
    private transaccionService: TransaccionService
  ) {}

  ngOnInit(): void {
    this.wompiId = this.route.snapshot.queryParams.id;
    this.loadPayment();
  }

  private async loadPayment() {
    const modalLoading = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    try {
      this.payment = await this.transaccionService.getWompiPaymentData(this.wompiId || '').toPromise();
      if (this.payment.error) {
        throw this.payment.error;
      } else {
        this.payment = this.payment.data;
        await this.transaccionService.vincularReferenciaWompi(this.payment.reference, this.payment.id).toPromise();
        this.transaction = await this.transaccionService.getTransaccion(this.payment.reference).toPromise();

        const vinculacion_id = (this.transaction.descripcion as string).split(' ').pop();

        const result = await this.distribuidorService.checkPlanPayment(vinculacion_id).toPromise();

        if (result.success) {
          if (result.data.updated && result.data.vinculacion.estado === 'Activo') {
            const modalRef = this.modalService.open(SimpleComponent);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-success-verde.png';
            modalRef.componentInstance.title = '¡Felicidades!';
            modalRef.componentInstance.msg = 'El pago se realizó correctamente. Ya tienes activo el nuevo plan.';
            modalRef.componentInstance.btn_msg = 'Listo';
            modalRef.componentInstance.close_callback = () => {
              this.router.navigate(['/']);
            };
          } else if (result.data.vinculacion === 'Activo') {
            const modalRef = this.modalService.open(SimpleComponent);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-success-verde.png';
            modalRef.componentInstance.title = '¡Felicidades!';
            modalRef.componentInstance.msg = 'El plan ya se encuentra activo.';
            modalRef.componentInstance.btn_msg = 'Listo';
            modalRef.componentInstance.close_callback = () => {
              this.router.navigate(['/']);
            };
          } else {
            const modalRef = this.modalService.open(SimpleComponent);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-alert-amarillo.png';
            modalRef.componentInstance.title = 'Transacción pendiente';
            modalRef.componentInstance.msg =
              'La transacción está pendiente de revisión. Cuando se complete, podrá ver el nuevo plan activo.';
            modalRef.componentInstance.btn_msg = 'Listo';
            modalRef.componentInstance.close_callback = () => {
              this.router.navigate(['/']);
            };
          }
        } else {
          throw new Error(result.error);
        }
      }
    } catch (error) {
      console.error(error);
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Ocurrió un error al procesar la transacción. Por favor, intente nuevamente.';
      modalRef.componentInstance.btn_msg = 'Listo';
      modalRef.componentInstance.close_callback = () => {
        this.router.navigate(['/']);
      };
    }
    modalLoading.close();
  }
}
