<div class="w-100 p-5 d-flex flex-column justify-content-between">
    <!-- Titulo -->
    <strong class="w-100 pb-3">
            <!-- Ajusta cuando es usuario tipo establecimiento:  
             <h5>Comercial encargado del punto</h5>
            -->
<!-- Ajusta cuando es usuario tipo distribuidor:  
             <h5>Encargado del punto de entrega</h5>
            -->
        <h5>Comercial encargado del punto</h5>
    </strong>
    <!-- Logo y nombre -->
    <div class="w-100 pb-3 d-flex flex-row justify-content-between">
        <div class="col-3 p-0">
            <img [src]="info_horeca.logo || '../../../../assets/img/icon-organizacion.png'" class="img-logo" alt="logo"
                *ngIf="!vista_distribuidor">
            <img [src]="info_distribuidor.logo || '../../../../assets/img/icon-organizacion.png'" class="img-logo"
                alt="logo" *ngIf="vista_distribuidor">
        </div>
        <div class="col-9 d-flex flex-column justify-content-start">
            <strong>{{e_nombres}} {{e_apellidos}}</strong>
            <p class="m-0">{{!vista_distribuidor ? info_punto.nombre : info_distribuidor.nombre}}</p>
            <p class="text-secondary m-0" *ngIf="!vista_distribuidor">{{info_horeca.nombre_establecimiento}}</p>
        </div>
    </div>
    <!-- Subtitulo -->
    <h6 class="w-100 pb-2">Información de contacto</h6>
    <!-- Información general de contacto -->
    <div class="pb-3">
        <div class="d-flex flex-row justify-content-between">
            <p class="w-50">Ciudad:</p>
            <p class="w-50 text-right">{{!vista_distribuidor ? info_punto.ciudad : info_distribuidor.ciudad}}</p>
        </div>
        <div class="d-flex flex-row justify-content-between">
            <p class="w-50">Dirección:</p>
            <p class="w-50 text-right">{{!vista_distribuidor ? info_punto.direccion : info_distribuidor.direccion}}</p>
        </div>
        <div class="d-flex flex-row justify-content-between">
            <p class="w-50">Teléfono:</p>
            <p class="w-50 text-right">{{e_telefono}}</p>
        </div>
        <div class="d-flex flex-row justify-content-between">
            <p class="w-50">Email:</p>
            <p class="w-65 text-right">{{e_correo}}</p>
        </div>
    </div>
    <!-- Salir -->
    <div class="w-100 d-flex flex-row justify-content-end">
        <button class="btn_modal" (click)="onClose()">Listo</button>
    </div>
</div>