<app-header-organizacion></app-header-organizacion>
<div class="container">
  <div class="row titulo-Container">
    <div class="col-sm-2">
      <div class="btn-volver" (click)="volver()">
        <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
        Volver
      </div>
    </div>
    <div class="col-sm-10">
      <h2 class="titulo-producto">{{ producto?.producto_nombre }}</h2>
    </div>
  </div>
  <div class="row" *ngIf="producto">
    <!--Scroll--> 
    <div class="col-sm-2">
      <div class="cat-scroll">
        <div class="cat-div cursorPointer" *ngFor="let foto of producto?.producto_fotos; let i = index">
          <img src="{{foto}}" width="100%" height="100%" alt="foto producto" (click)="foto_seleccionada = foto" />
        </div>
      </div>
    </div>
    <!--   imagen principal -->
    <div class="col-sm-5">
      <!--Header y btns crear productos-->
      <div class="card">
        <div class="">
          <div class="card-body">
            <div class="row row_data_prod">
              <div class="col-sm-12 text-center">
                <img class="imgpro" src="{{foto_seleccionada}}" alt="foto producto seleccionada" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-5">
      <div class="row">
        <div class="col-sm-6">
          <div class="card">
            <div class="card-body-ft">
              <span>Cód. FEAT</span>
              <div>
                <span>{{ producto?.producto_codigoFeat }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="card">
            <div class="card-body">
              <div class="card-body-ft">
                <span>Cód. Organización</span>
                <div>
                  <span>{{ producto?.codigo_organizacion }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="puntos-asignados">
        <div class="card">
          <div class="card-body">
            <div class="feat-asignados">
              <h5>Puntos Feat asignados</h5>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <div class="feat-asignados">
                      <h6>Puntos FT por unidad</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="ftpuntos">
                  <div class="row header_codigo">
                    <input type="number" style="width: 100%" [(ngModel)]="nuevos_puntos"
                      (change)="verificarCambios()" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="card">
                  <div class="card-body">
                    <div class="feat-asignados">
                      <h6>Fecha de Inicio</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="card">
                  <div class="card-body">
                    <div class="feat-asignados">
                      <h6>Fecha de cierre</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="fechaInput">
              <div>
                <input type="date" [(ngModel)]="nueva_fecha_apertura" (change)="verificarCambios()" />
              </div>
              <div>
                <input type="date" [(ngModel)]="nueva_fecha_cierre" (change)="verificarCambios()" />
              </div>
            </div>
            <div class="fechaInput">
              <div class="msgError">
                <h2 style="color: red; font-size: 12px;" *ngIf="errorFechasAnterior">
                  - La fecha de apertura no puede ser menor a la fecha actual
                </h2>
                <h2 style="color: red; font-size: 12px;" *ngIf="errorFechas">
                  - La fecha de apertura debe ser menor a la fecha de cierre
                </h2>
                <h2 style="color: red; font-size: 12px;" *ngIf="errorFechasIguales">
                  - La fecha de apertura no puede ser igual o superior a la fecha de cierre
                </h2>
                <span style="color: #8e6ff7" *ngIf="cambiosGuardados">Cambios guardados con correctamente</span>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6"></div>
              <div class="col-sm-6">
                <div class="btnguardar">
                  <button class="btn-purple" (click)="guardarCambiosPuntosFT()" [ngClass]="{ disabledButton: !guardar }"
                    [disabled]="!guardar">
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="estadisticas">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <div class="porcentaje">
                  <h3>
                    {{ indicadoresProducto?.puntos_entrega || 0 }}
                  </h3>
                  <p>Puntos que lo compran</p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="porcentaje">
                  <h3>{{ indicadoresProducto?.participacion_cat || 0}}%</h3>
                  <p>Participacion del producto en categoria</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
  <br />
  <!-- Precios -->
  <div class="card">
    <div class="card-body">
      <div class="precios">
        <h5>Precios</h5>
        <div class="row">
          <div class="col-sm-4">
            <h6>Precios por unidad promedio</h6>
            <span id="precios">{{ producto?.producto_precio_unidad || 0 | currency: "$":"symbol":"1.0-0"}}</span>
          </div>
          <div class="col-sm-4">
            <h6>Precios por caja promedio</h6>
            <span>{{ producto?.producto_precio_caja || 0 | currency: "$":"symbol":"1.0-0"}}</span>
          </div>
          <div class="col-4">
            <h6>Precios por unidad de medida</h6>
            <span *ngIf="producto?.producto_cantidad_medida > 0">
              {{ producto?.producto_precio_unidad / producto?.producto_cantidad_medida | currency:
              "$":"symbol":"1.2-2" }}
            </span>
          </div>
        </div>
        <h5>Información del producto</h5>
        <div class="row">
          <div class="col-sm-8">
            <h6>Nombre del producto</h6>
            <span>{{ producto?.producto_nombre }}</span>
          </div>
          <div class="col-sm-4">
            <h6>Marca</h6>
            <span>{{ producto?.marca }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <h6>Organización</h6>
            <span>{{ producto?.organizacion }}</span>
          </div>
          <div class="col-sm-4">
            <h6>Categoria</h6>
            <span>{{ producto?.categoria }}</span>
          </div>
          <div class="col-sm-4">
            <h6>Linea de producto</h6>
            <span>{{ producto?.linea }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <h6>Unidad de medida</h6>
            <span>{{ producto?.producto_unidad_medida || 'und'}}</span>
          </div>
          <div class="col-sm-4">
            <h6>Cantidad de medida</h6>
            <span>{{ producto?.producto_cant_medida || 1 }}</span>
          </div>
          <div class="col-sm-4">
            <h6>Presentación por caja</h6>
            <span>{{producto?.producto_und_x_caja}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-3" *ngIf="ficha_tecnica">
          <fa-icon
            [icon]="faEye"
            [styles]="{ color: '#41403E' }"
            id="fa-eye"></fa-icon>
            <span (click)="goLinkFicha()" class="cursorPointer">Ver ficha técnica</span>
          </div>
          <div class="col-4">
            <input type="file" class="file-upload" (change)="handleFileInput($event)" #fileUploadFicha hidden/>
            <button (click)="fileUploadFicha.click()" class="btn-ficha">
              <fa-icon [icon]="faFilePdf" [styles]="{ color: '#ffffff' }" id="fa-icon"></fa-icon>
              <span *ngIf="ficha_tecnica">
                Cambiar ficha técnica 
              </span>
              <span *ngIf="!ficha_tecnica">
                Subir ficha técnica 
              </span>
            </button>
            
          </div>
        </div>
        <div class="row">
          <div class="col-sm-8">
            <h6>Descripción</h6>
            <span>
              {{ producto?.producto_descripcion }}
            </span>
          </div>
          <div class="col-sm-4">
            <button type="button" class="btn btn-success" (click)="goToLink()">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-whatsapp"
                viewBox="0 0 16 16">
                <path
                  d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
              </svg>
              Solicitar cambio a Feat
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
  <!-- Precios por distribuidor -->
  <div class="row" style="margin-bottom: 50px">
    <div class="col-sm-9">
      <div class="card">
        <div class="card-body">
          <div class="preciosdistribuidor">
            <h5>Precios por distribuidor</h5>
            <div class="row">
              <div class="col-sm-12">
                <ng2-smart-table [settings]="settings" [source]="source"></ng2-smart-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>