<app-header></app-header>
<app-banner></app-banner>
<app-middle-banner></app-middle-banner>
<app-ultimas-noticias></app-ultimas-noticias>
<app-future-section></app-future-section>
<app-especificacion-feat></app-especificacion-feat>
<app-mision></app-mision>
<app-planes-clientes></app-planes-clientes>
<app-experiencia-feat></app-experiencia-feat>
<app-mobile></app-mobile>
<app-organizaciones-landing></app-organizaciones-landing>
<app-contacto></app-contacto>
