import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-future-section',
  templateUrl: './future-section.component.html',
  styleUrls: ['./future-section.component.css'],
})
export class FutureSectionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
