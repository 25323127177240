import { Component, Input, OnInit } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-multipaso',
  templateUrl: './multipaso.component.html',
  styleUrls: ['./multipaso.component.css'],
})
export class MultipasoComponent implements OnInit {
  /** Íconos FontAwesome para la UI */
  faChevronLeft = faChevronLeft;

  /**
   * Cada paso de pasos[] tiene los siguientes atributos:
   *   title: string
   *   msg: string
   *   img_src: string
   */
  @Input() pasos: any[] = [];
  paso_actual = 0;

  @Input() close_callback = () => {
    console.log('multipasoComp');
  };

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  /**
   * Permite la navegación a través del modal
   * Avanza al siguiente paso del multipaso
   * Si se encuentra en el último paso, no permite avanzar más
   */
  pasoSiguiente() {
    this.paso_actual++;
    if (this.paso_actual >= this.pasos.length) {
      this.paso_actual = this.pasos.length - 1;
    }
  }

  /**
   * Permite la navegación a través del modal
   * Retrocede al paso anterior del multipaso
   * Si se encuentra en el primer paso, no permite retroceder más
   */
  pasoAnterior() {
    this.paso_actual--;
    if (this.paso_actual < 0) {
      this.paso_actual = 0;
    }
  }

  onClose() {
    this.activeModal.close('Close click');
    this.close_callback();
  }
}
