<app-header-horeca></app-header-horeca>
<!--Componente completo-->
<div class="container">
    <!--Volver-->
    <div class="volver-container d-flex flex-row w-100 pb-4">
        <div class="col-3 p-0" (click)="volver()">
            <button class="boton-volver">
                <fa-icon [icon]="faChevronLeft"></fa-icon>
                Volver
            </button>
        </div>
        <div class="p-0 col-9 d-flex align-items-center">
            <h2 class="d-flex align-items-center p-0">{{ distribuidor?.nombre}}</h2>
        </div>
    </div>
    <!--Detalles del distribuidor y la vinculación-->
    <div class="info-detalles-container">
        <div class="info-aprobado">
            <fa-icon [icon]="faCircle" [styles]="{ color: '#41403E' }" id="fa-icon" *ngIf="vinculacion == null">
            </fa-icon>
            <fa-icon [icon]="faCircle" [styles]="{ color: '#E8E8E8' }" id="fa-icon" *ngIf="vinculacion == 'Pendiente'">
            </fa-icon>
            <fa-icon [icon]="faCircle" [styles]="{ color: '#97AE22' }" id="fa-icon" *ngIf="vinculacion == 'Aprobado'">
            </fa-icon>
            <fa-icon [icon]="faCircle" [styles]="{ color: '#D38989' }" id="fa-icon" *ngIf="vinculacion == 'Cancelado'">
            </fa-icon>
            <fa-icon [icon]="faCircle" [styles]="{ color: '#D38989' }" id="fa-icon" *ngIf="vinculacion == 'Rechazado'">
            </fa-icon>
            <span>{{ vinculacion || 'Sin vinculación' }}</span>
        </div>
        <div class="info-tiempo-entrega">
            <img src="../../assets/img/icons/icon-bus.png" alt="icono de un bus" srcset="" />
            <span>Tiempos de entrega: {{ distribuidor?.tiempo_entrega }}</span>
        </div>
        <div class="info-ciudad">
            <img src="../../assets/img/icons/icon-pin-map.png" alt="icono de un pin para señalar un punto en un mapa"
                srcset="" />
            <span>{{ distribuidor?.ciudad }}</span>
        </div>
        <div class="info-convenio">
            <!-- <div>
                <span>$Convenio</span>
            </div> -->
        </div>
        <div class="info-logo">
            <img src='{{distribuidor?.logo  || distribuidor_placeholder}}' alt="logo del distribuidor" srcset="" />
        </div>
        <div class="info-tipo d-flex w-100 flex-row justify-content-between align-items-center">
            <span>
                {{ distribuidor?.tipo }}
            </span>
        </div>
        <div class="info-pedido-minimo d-flex flex-row justify-content-between">
            <span>
                Pedido mínimo:
                {{ distribuidor?.valor_minimo_pedido | currency: '$ ':'symbol':'1.0-0'}}
            </span>
            <span>
                Método de pago: {{distribuidor?.metodo_pago}}
            </span>
        </div>
        <div class="info-descripcion">
            <p>{{ distribuidor?.descripcion }}</p>
        </div>
        <!--Calificaciones-->
        <div class="calificacion info-calificacion-abastecimiento">
            <div>
                <fa-icon [icon]="faStar" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
                <span>{{ distribuidor?.ranking | number:'1.0-2' }}</span>
            </div>
            <span class="pl-2">Abastecimiento</span>
        </div>
        <div class="calificacion info-calificacion-precio">
            <div>
                <fa-icon [icon]="faStar" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
                <span>{{ distribuidor?.ranking | number:'1.0-2' }}</span>
            </div>
            <span class="pl-2">Precio</span>
        </div>
        <div class="calificacion info-calificacion-puntualidad">
            <div>
                <fa-icon [icon]="faStar" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
                <span>{{ distribuidor?.ranking | number:'1.0-2' }}</span>
            </div>
            <span class="pl-2">Puntualidad</span>
        </div>
        <div class="info-vinculacion">
            <button class="btn-solicitar-2" [ngClass]="{'btn-solicitar-disabled': vinculacion !== null}"
                (click)="solicitarVinculacion()" [disabled]="vinculacion !== null">
                Solicitar vinculación
            </button>
        </div>
    </div>
    <div class="catalogo mt-4" *ngIf="distribuidor !== null">
        <!--Saldos y promociones-->
        <div class="saldos-promociones p-0 rounded cursorPointer" (click)="alertaNoVinculacion()">
            <div
                class="saldos-imagen col-4 p-0 d-flex justify-content-center align-items-center overflow-hidden rounded-left">
            </div>
            <div class="col-8 p-0 pl-3 card-body d-flex align-items-center rounded">
                <p class="m-0 main-title text-left pl-2">Saldos y promociones</p>
            </div>
        </div>
        <!--Categorías-->
        <div class="categorias rounded cursorPointer" (click)="alertaNoVinculacion()">
            <h6 class="mb-0">Categorías</h6>
            <div class="pt-0">
                <div class="d-flex flex-column align-items-start">
                    <div class="cat-container">
                        <div class="cat-icon-container">
                            <img src="assets/iconos-org/todos.png" style="width: 60%;" alt="">
                        </div>
                        <div class="cat-name-container">
                            <span style="color: #8e6ff7">
                                Todos
                            </span>
                        </div>
                    </div>
                    <div *ngFor="let cat of categorias_productos; index as i;" class="w-100 cat-container">
                        <div class="cat-icon-container">
                            <div *ngIf="cat.logoOn">
                                <img src="{{cat.logoOff || cat.logoOn}}" style="width: 60%;" alt="">
                            </div>
                            <div *ngIf="!cat.logoOn">
                                <img src="assets/iconos-org/todos.png" style="width: 60%;" alt="">
                            </div>
                        </div>
                        <div class="cat-name-container">
                            <span>
                                {{ cat.nombre }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Buscador-->
        <div class="barra-busqueda rounded cursorPointer" (click)="alertaNoVinculacion()">
            <div class="d-flex flex-row autocompletado-busqueda px-0 h-100 w-100">
                <button class="col-2 col-lg-1 col-sm-0 p-0 boton-buscar d-flex justify-content-center">
                    <fa-icon class="fa-icon-search m-0" [icon]="faSearch" size="lg" [styles]="{ color: '#41403E' }">
                    </fa-icon>
                </button>
                <form class="example-form col-12 col-sm-10 col-md-11 pr-3">
                </form>
            </div>
        </div>
        <!--Punto de entrega-->
        <div class="punto-entrega-container rounded overflow-hidden p-0 pr-3">
            <div class="punto-entrega-icono col-1">
                <img src="../../assets/img/icons/icon-pin-map.png"
                    alt="icono de un pin para señalar un sitio en un mapa" srcset="" />
            </div>
            <span class="text-left punto-entrega-texto col-6 col-sm-7 col-md-8 p-0" id="point">
                <span class="texto-novisible">Punto de entrega: </span>
                <span>{{ punto_entrega?.nombre || 'Selecciona un punto' }}</span>
            </span>
            <span (click)="selecionarPunto()" *ngIf="vinculacion !== 'Pendiente'"
                class="punto-entrega-cambiar col-5 col-sm-4 col-md-3 text-center m-0">
                Cambiar
            </span>
        </div>
        <!--Líneas de producto-->
        <div class="lineas-productos pb-2 cursorPointer" (click)="alertaNoVinculacion()">
            <span class="pb-3">Líneas de producto</span>
            <div class="categorias-lineas">
                <span class="categorias-lineas-selected">
                    Todos
                </span>
                <span *ngFor="let linea of lineas_productos; index as i;">
                    {{ linea.nombre }}
                </span>
            </div>
        </div>
        <!--Productos-->
        <div class="products-container ">
            <div class="productos-contenedor" *ngIf="prods_filtrados && prods_filtrados.length > 0">
                <div class="product-card" *ngFor="let producto of prods_filtrados" (click)="alertaNoVinculacion()">
                    <div>
                        <span>Código producto distribuidor:<br>{{ producto?.codigo_distribuidor_producto || '' }}</span>
                    </div>
                    <div>
                        <div class="space">
                            <span>{{ producto?.precios[0].estado }}</span>
                            <span *ngIf="producto?.flag_aplica_puntos_feat">{{ producto?.precios[0].puntos_ft_unidad }}
                                punto ft</span>
                        </div>
                        <div class="container-img">
                            <img src='{{ producto?.fotos[0] || product_placeholder }}'
                                alt="{{ producto?.nombre || 'Producto'}}" />
                        </div>
                        <span>{{ producto?.nombre }}</span>
                        <div class="space">
                            <span>Precio und.</span>
                            <span class="price">
                                {{ producto?.precios[0].precio_unidad | currency: '$ ':'symbol':'1.2-2'}}
                            </span>
                        </div>
                        <div class="space">
                            <span>Precio {{ producto?.precios[0].unidad_medida || 'und' }}</span>
                            <span>
                                {{ (producto?.precios[0].precio_unidad/producto?.precios[0].cantidad_medida)
                                | currency: '$ ':'symbol':'1.2-2' }}
                            </span>
                        </div>
                        <div class="space">
                            <span>Caja x{{ producto?.precios[0].und_x_caja || 1 }}</span>
                            <span>{{ producto?.precios[0].precio_caja | currency: '$ ':'symbol':'1.0-0' }}</span>
                        </div>
                        <div class="botones-contenedor">
                            <div class="botones">
                                <div class="agregar">
                                    <span>+ caja</span>
                                </div>
                            </div>
                            <div class="botones">
                                <div class="agregar">
                                    <span>+ unidad</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Estado vacío de productos filtrados-->
            <div *ngIf="!prods_filtrados || prods_filtrados.length == 0">
                <div class="row text-center m-0">
                    <div class="col-12">
                        <img src="../../../assets/img/icon-warning-amarillo.png" style="max-width: 200px; height: auto;"
                            alt="ícono para indicar estado vacío" />
                    </div>
                    <div class="col-12">
                        <h6>
                            No se encontraron productos para este distribuidor
                        </h6>
                    </div>
                </div>
            </div>
            <div class="w-100 d-flex justify-content-center align-items-center my-4">
                <button class="btn-solicitar" [ngClass]="{'btn-solicitar-disabled': vinculacion !== null}"
                    (click)="solicitarVinculacion()" [disabled]="vinculacion !== null || flag_vinculacion == false">
                    Solicitar vinculación
                </button>
            </div>
        </div>
    </div>
</div>