<app-header-organizacion></app-header-organizacion>
<div class="d-flex justify-content-center">
    <div id="content">
        <h4 class="mb-3">Establecimientos</h4>
        <div class="card-container">
            <!-------------------- Bar Chart -------------------->
            <!-- Establecimientos comprados -->
            <div class="card establecimiento-comprados">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-between mb-3">
                        <div class="mb-3">No. de establecimientos alcanzados</div>
                        <div class="d-flex justify-content-between">
                            <!--<span>Total: 16</span>-->
                            <span>Total: {{total_clientes_mes}}</span>

                            <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_clientes_mes"
                                (ngModelChange)="cargarInformacionEstablecimientosComprado()">
                                <option [value]="ano" *ngFor="let ano of anos_filtro">
                                    {{ano}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <canvas #bar_establecimiento_comprados></canvas>
                </div>
            </div>
            <!-- Indicadores resumen establecimientos -->
            <div class="indicadores">
                <div class="card d-flex flex-column p-3">
                    <h5>{{promedioPedidosMes | currency: '$':'symbol':'1.0-0'}}</h5>
                    <p>Valor promedio de pedidos por mes de mis productos</p>
                </div>
                <div class="card d-flex flex-column p-3 mt-3">
                    <h5>{{promedioPedidosMesCantidad }}</h5>
                    <p>Numero de referencias promedio por pedidos</p>
                </div>
            </div>
            <!-- Establecimientos por tipo de negocio -->
            <div class="card establecimiento-negocio">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-between mb-3">
                        <div class="mb-3">No. de establecimientos por tipo de negocio</div>
                        <div class="d-flex justify-content-between">
                            <span>Total: {{total_establecimiento_negocio}}</span>
                            <select name="" id="" class="text-secondary px-3"
                                [(ngModel)]="filtro_establecimiento_negocio"
                                (ngModelChange)="cargarInformacionEstablecimientosNegocio()">
                                <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                                    {{mes.filtro}}-{{mes.filtro_inicio}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <canvas #bar_establecimiento_negocio></canvas>
                </div>
            </div>
            <!-- Establecimientos por número de sillas -->
            <div class="card establecimiento-sillas">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-between mb-3">
                        <div class="mb-3">No. de establecimientos por número de sillas</div>
                        <div class="d-flex justify-content-between">
                            <span>Total: {{total_establecimiento_sillas}}</span>
                            <select name="" id="" class="text-secondary px-3"
                                [(ngModel)]="filtro_establecimiento_sillas"
                                (ngModelChange)="cargarInformacionEstablecimientosSillas()">
                                <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                                    {{mes.filtro}}-{{mes.filtro_inicio}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <canvas #bar_establecimiento_sillas></canvas>
                </div>
            </div>
            <!-- Establecimientos por ciudad -->
            <div class="card establecimiento-ciudad">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-between mb-3">
                        <div class="mb-3">No. de establecimientos por ciudad</div>
                        <div class="d-flex justify-content-between">
                            <span>Total: {{total_establecimiento_ciudad}}</span>
                            <select name="" id="" class="text-secondary px-3"
                                [(ngModel)]="filtro_establecimiento_ciudad"
                                (ngModelChange)="cargarInformacionEstablecimientosCiudad()">
                                <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                                    {{mes.filtro}}-{{mes.filtro_inicio}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <canvas #bar_establecimiento_ciudad></canvas>
                </div>
            </div>
            <!-- Puntos por establecimientos -->
            <div class="card establecimiento-punto">
                <div class="card-body">
                    <div class="d-flex justify-content-between mb-3">
                        <div>Puntos de entrega por establecimiento</div>
                    </div>
                    <div class="d-flex justify-content-start mb-3">
                        <span>Total: {{total_establecimiento_puntos}}</span>
                    </div>
                    <canvas #bar_establecimiento_punto></canvas>
                </div>
            </div>
            <!-- Promedio productos por pedidos -->
            <div class="card establecimiento-promedio-pedidos">
                <div class="card-body">
                    <div class="d-flex justify-content-between mb-3">
                        <div>Promedio productos por pedidos</div>
                    </div>
                    <div class="d-flex justify-content-start mb-3">
                        <span>Total: {{total_establecimiento_prom_prod}}</span>
                    </div>
                    <canvas #bar_establecimiento_prom_prod></canvas>
                </div>
            </div>
            <!-------------------- Pie Chart -------------------->
            <!-- Establecimiento por tipo -->
            <div class="card establecimiento-tipo">
                <div class="card-body">
                    <div class="d-flex justify-content-between mb-3">
                        <div>Establecimientos naturales y persona júridica</div>
                    </div>
                    <div class="d-flex justify-content-start mb-3">
                        <span>Total: {{total_establecimiento_tipo}}</span>
                    </div>
                    <canvas #pie_establecimiento_tipo></canvas>
                </div>
            </div>
            <!-- Establecimiento por domicilio -->
            <div class="card establecimiento-domicilio">
                <div class="card-body">
                    <div class="d-flex justify-content-between mb-3">
                        <div>Establecimientos con domicilios</div>
                    </div>
                    <div class="d-flex justify-content-start mb-3">
                        <span>Total: {{total_establecimiento_domicilios}}</span>
                    </div>
                    <canvas #pie_establecimiento_domicilio></canvas>
                </div>
            </div>
            <!-- Establecimientos en cartera -->
            <div class="card establecimiento-cartera">
                <div class="card-body">
                    <div class="d-flex justify-content-between mb-3">
                        <div>Establecimientos de cartera vencida</div>
                    </div>
                    <div class="d-flex justify-content-start mb-3">
                        <span>Total: {{total_establecimiento_cartera}}</span>
                    </div>
                    <canvas #pie_establecimiento_cartera></canvas>
                </div>
            </div>
            <!-- Establecimientos en convenio -->
            <div class="card establecimiento-convenio">
                <div class="card-body">
                    <div class="d-flex justify-content-between mb-3">
                        <div>No. de establecimientos con convenio de precios</div>
                    </div>
                    <div class="d-flex justify-content-start mb-3">
                        <span>Total: {{total_establecimiento_convenio}}</span>
                    </div>
                    <canvas #pie_establecimiento_convenio></canvas>
                </div>
            </div>
            <!-- Titulo Mapa -->
            <div class="titulo-mapa">
                <h4 class="mt-4 mb-2">Mapa de Establecimientos</h4>
            </div>
            <!-- Mapa -->
            <div class="mapa-establecimiento" *ngIf="coord_org">
                <agm-map #AgmMapNat fit="true" [zoom]="5" [latitude]="coord_org.lat" [longitude]="coord_org.lng"
                    style="width: 100%; height: 25rem">
                    <agm-marker [latitude]="coord_org.lat" [longitude]="coord_org.lng"
                        [label]="{color: 'white', text: 'Tù'}"></agm-marker>
                    <agm-marker *ngFor="let puntos of locations_puntos_entrega; let i = index" [latitude]="puntos.lat"
                        [longitude]="puntos.lng" [markerClickable]="true" (markerClick)="showDataPunto($event)">
                    </agm-marker>
                </agm-map>
            </div>
            <!-- Data punto seleccionado -->
            <div class="punto-seleccionado">
                <div class="card d-flex flex-column p-3">
                    <h6>Punto seleccionado</h6>
                    <p>{{punto_seleccionado_nombre_establecimiento}}</p>
                    <span class="d-flex flex-row">
                        <img class="mr-2" src="../../assets/img/icons/icon-store.png"
                            alt="Icono de un pin para señalar un punto en un mapa" srcset="" />
                        <p>{{punto_seleccionado_nombre}}</p>
                    </span>
                    <span class="d-flex flex-row">
                        <img class="mr-2" src="../../assets/img/icons/icon-pin-map.png"
                            alt="Icono de un pin para señalar un punto en un mapa" srcset="" />
                        <p>{{punto_seleccionado_direccion}}</p>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>