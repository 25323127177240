<div class="main-container" [formGroup]="cuentaForm">
  <!--Información propietario-->
  <div class="card p-5">
    <div class="mb-4 d-flex flex-row">
      <div class="logo-wrapper">
        <img [src]="imageSrc || user.logo" id="logo-empresa" alt="Logo del horeca" />
      </div>
      <div class="d-flex justify-content-between align-content-between flex-column pl-4">
        <h5>
          {{ authService.user_horeca!.nombre_establecimiento }}
        </h5>
        <div class="upload">
          <input type="file" id="upload" hidden (change)="captureLogo($event)" />
          <label for="upload" class="btn-upload m-0 p-0">Cambiar logo</label>
        </div>
      </div>
    </div>
    <div class="">
      <div class="col-12 mt-2 mb-4 p-0">
        <h5>Información representante legal / propietario</h5>
      </div>
    </div>
    <div class="top-form">
      <!--Tipo de documento de identidad-->
      <div class="documento">
        <label for="tipo documento"><span class="asterisco">*</span>Tipo de doc de identidad</label>
        <select [attr.disabled]="true" formControlName="cuentaPropietarioTipo" class="d-inline-block dropdown-form-1"
          (change)="cuentaForm.patchValue({ cuentaPropietarioCedula: '' })"
          [ngClass]="{'invalid-warning': (user.tipo_documento == '' && cuentaForm.get('horecaTipo')?.touched)}">
          <option value="Cédula de ciudadanía">Cédula de ciudadanía</option>
          <option value="Cédula de extranjería">Cédula de extranjería</option>
          <option value="Pasaporte">Pasaporte</option>
        </select>
      </div>
      <!--Número de documento-->
      <div class="numero-documento">
        <label for="documento"><span class="asterisco">*</span>Número de doc identidad</label>
        <input *ngIf="cuentaForm.get('cuentaPropietarioTipo')?.value === 'Pasaporte'" [attr.disabled]="true"
          formControlName="cuentaPropietarioCedula" name="documento" placeholder="Escribe aquí" type="text"
          [ngClass]="{'invalid-warning': ( cuentaForm.get('cuentaPropietarioCedula')?.hasError('required') && cuentaForm.get('cuentaPropietarioCedula')?.touched)}" />
        <input *ngIf="cuentaForm.get('cuentaPropietarioTipo')?.value !== 'Pasaporte'" [attr.disabled]="true"
          formControlName="cuentaPropietarioCedula" name="documento" placeholder="Escribe aquí" type="number"
          (keydown)="validateNumber($event)"
          [ngClass]="{'invalid-warning': ( cuentaForm.get('cuentaPropietarioCedula')?.hasError('required') && cuentaForm.get('cuentaPropietarioCedula')?.touched)}" />
      </div>
      <!--Nombres-->
      <div class="nombres">
        <label for="nombres"><span class="asterisco">*</span>Nombres</label>
        <input [attr.disabled]="true" formControlName="cuentaPropietarioNombre" name="nombres"
          placeholder="Escribe aquí" (keydown)="validateOnlyText($event)"
          [ngClass]="{'invalid-warning': ( cuentaForm.get('cuentaPropietarioNombre')?.hasError('required') && cuentaForm.get('cuentaPropietarioNombre')?.touched )}" />
      </div>
      <!--Apellidos-->
      <div class="apellidos">
        <label for="apellidos"><span class="asterisco">*</span>Apellidos</label>
        <input [attr.disabled]="true" formControlName="cuentaPropietarioApellido" name="apellidos"
          placeholder="Escribe aquí" (keydown)="validateOnlyText($event)"
          [ngClass]="{'invalid-warning': ( cuentaForm.get('cuentaPropietarioApellido')?.hasError('required') && cuentaForm.get('cuentaPropietarioApellido')?.touched )}" />
      </div>
      <!--Teléfono fijo-->
      <div class="telefono">
        <label for="telefono"><span class="asterisco">*</span>Teléfono fijo (7 o 10 dígitos)</label>
        <input formControlName="cuentaPropietarioTelefono" name="telefono" type="number" placeholder="Escribe aquí"
          (keydown)="validateNumber($event)"
          [ngClass]="{'invalid-warning': (cuentaForm.get('cuentaPropietarioTelefono')?.hasError('required') && cuentaForm.get('cuentaPropietarioTelefono')?.touched) || cuentaForm.get('cuentaPropietarioTelefono')?.hasError('pattern') && cuentaForm.get('cuentaPropietarioTelefono')?.touched}" />
        <span class="reactive-text text-danger"
          *ngIf="cuentaForm.get('cuentaPropietarioTelefono')?.hasError('pattern') && cuentaForm.get('cuentaPropietarioTelefono')?.touched">
          <span class="alerta">Númerico de 7 o 10 dígitos</span>
        </span>
      </div>
      <div class="correo-rep">
        <label for="telefono">Correo electrónico</label>
        <input formControlName="cuentaPropietarioCorreo" type="email" placeholder="Escribe aquí" />
        <span class="reactive-text text-danger "
          *ngIf="cuentaForm.get('authService.user!')?.hasError('email') && cuentaForm.get('authService.user!')?.touched">
          <span class="alerta">Ingresa un email valido</span>
        </span>
      </div>
    </div>
  </div>
  <!---------Información empresa--------->
  <div class="card p-5 mt-4">
    <!--Correo de ingreso-->
    <div class="correo-ingreso col-12 p-0 mb-2">
      <h5><span class="asterisco">*</span>Correo de ingreso</h5>
      <div class="col-4 p-0 mb-2">
        <input formControlName="cuentaCorreo" type="email" placeholder="Escribe aquí" [attr.disabled]="true" />
        <span class="reactive-text text-danger "
          *ngIf="cuentaForm.get('cuentaCorreo')?.hasError('email') && cuentaForm.get('cuentaCorreo')?.touched">
          <span class="alerta">Ingresa un email valido</span>
        </span>
      </div>
    </div>
    <div class="col-12 p-0 mt-4 mb-2">
      <h5>Sobre la empresa</h5>
    </div>
    <div class="bottom-form mb-1">
      <!--Nombre de empresa/persona-->
      <div class="nombre-empresa">
        <label for="nombre_establecimiento"><span class="asterisco">*</span>Nombre de empresa/persona</label>
        <input formControlName="cuentaNombreEstablecimiento" name="nombre_establecimiento" placeholder="Escribe aquí"
          [ngClass]="{'invalid-warning': ( cuentaForm.get('cuentaNombreEstablecimiento')?.hasError('required') && cuentaForm.get('cuentaNombreEstablecimiento')?.touched )}" />
      </div>
      <!--Razón social-->
      <div class="razon-social">
        <label for="razon_social"><span class="asterisco">*</span>Razón social</label>
        <input formControlName="cuentaRazon" [attr.disabled]="true"
          [ngClass]="{'invalid-warning': ( cuentaForm.get('cuentaRazon')?.hasError('required') && cuentaForm.get('cuentaRazon')?.touched )}"
          name="razon_social" placeholder="Escribe aquí" />
      </div>
      <!--NIT-->
      <div class="nit-empresa">
        <label for="nit" *ngIf="this.user.tipo_usuario !== 'Natural' && this.user.tipo_usuario !== 'Persona'"><span
            class="asterisco">*</span>NIT</label>
        <label for="nit" *ngIf="this.user.tipo_usuario === 'Natural' || this.user.tipo_usuario === 'Persona'"><span
            class="asterisco">*</span>NIT/Documento
          propietario</label>
        <input formControlName="cuentaNit" [attr.disabled]="true"
          [ngClass]="{'invalid-warning': ( cuentaForm.get('cuentaNit')?.hasError('required') && cuentaForm.get('cuentaNit')?.touched )}"
          name="nit" placeholder="Escribe aquí" (keydown)="validateNumber($event)" />
      </div>
      <!--Tipo de establecimiento-->
      <div class="tipo-empresa">
        <label for="tipo_negocio"><span class="asterisco">*</span>Tipo de establecimiento</label>
        <select formControlName="cuentaEstablecimiento" class="d-inline-block dropdown-form-1"
          [ngClass]="{'invalid-warning': (cuentaForm.get('cuentaEstablecimiento')?.hasError('required') && cuentaForm.get('cuentaEstablecimiento')?.touched)}">
          <option selected disabled value="">Selecciona</option>
            <option value="BAR / DISCOTECA">BAR / DISCOTECA</option>
            <option value="CAFETERÍA / HELADERÍA / SNACK">CAFETERÍA / HELADERÍA / SNACK</option>
            <option value="CATERING SERVICE / SERVICIO ALIMENTACIÓN">CATERING SERVICE / SERVICIO ALIMENTACIÓN</option>
            <option value="COCINA OCULTA">COCINA OCULTA</option>
            <option value="CENTRO DE DIVERSIÓN">CENTRO DE DIVERSIÓN</option>
            <option value="CENTRO DEPORTIVO Y GIMNASIOS">CENTRO DEPORTIVO Y GIMNASIOS</option>
            <option value="CLUB SOCIAL / NEGOCIOS">CLUB SOCIAL / NEGOCIOS</option>
            <option value="COMEDOR DE EMPLEADOS">COMEDOR DE EMPLEADOS</option>
            <option value="COMIDA RÁPIDA">COMIDA RÁPIDA</option>
            <option value="HOGAR">HOGAR</option>
            <option value="MAYORISTA / MINORISTA">MAYORISTA / MINORISTA</option>
            <option value="OFICINA / COWORKING">OFICINA / COWORKING</option>
            <option value="PANADERÍA / REPOSTERÍA">PANADERÍA / REPOSTERÍA</option>
            <option value="PROPIEDAD HORIZONTAL">PROPIEDAD HORIZONTAL</option>
            <option value="RESTAURANTE">RESTAURANTE</option>
            <option value="RESTAURANTE DE CADENA">RESTAURANTE DE CADENA</option>
            <option value="SECTOR EDUCACIÓN">SECTOR EDUCACIÓN</option>
            <option value="SECTOR HOTELERO">SECTOR HOTELERO</option>
            <option value="SECTOR SALUD">SECTOR SALUD</option>
            <option value="SECTOR PÚBLICO / PRIVADO">SECTOR PÚBLICO / PRIVADO</option>
        </select>
      </div>
      <!--País-->
      <div class="pais-empresa">
        <label for="pais"><span class="asterisco">*</span>País</label>
        <select formControlName="cuentaPais" class="d-inline-block dropdown-form-1"
          [ngClass]="{'invalid-warning': ( cuentaForm.get('cuentaPais')?.hasError('required') && cuentaForm.get('cuentaPais')?.touched )}">
          <option selected disabled value="Colombia">Colombia</option>
        </select>
      </div>
      <!--Departamento-->
      <div class="departamento-empresa">
        <label for="departamento"><span class="asterisco">*</span>Departamento</label>
        <select formControlName="cuentaDepartamento" class="d-inline-block dropdown-form-1" (change)="getPlaces()"
          (change)="user.empresa_ciudad = ''"
          [ngClass]="{'invalid-warning': ( cuentaForm.get('cuentaDepartamento')?.hasError('required') && cuentaForm.get('cuentaDepartamento')?.touched )}">
          <option [ngValue]="dpto.name" *ngFor="let dpto of departamentos_empresa">
            {{ dpto.name }}
          </option>
        </select>
      </div>
      <!--Ciudad-->
      <div class="ciudad-empresa">
        <label for="ciudad"><span class="asterisco">*</span>Ciudad</label>
        <select formControlName="cuentaCiudad" class="d-inline-block dropdown-form-1"
          [ngClass]="{'invalid-warning': ( cuentaForm.get('cuentaCiudad')?.hasError('required') && cuentaForm.get('cuentaCiudad')?.touched )}">
          <option selected disabled value="">Selecciona</option>
          <option value={{city.name}} *ngFor="let city of ciudades">{{city.name}}</option>
        </select>
      </div>
      <!--Telefono-->
      <div class="celular-empresa">
        <label for="telefono"><span class="asterisco">*</span>Teléfono (7 o 10 dígitos)</label>
        <input formControlName="cuentaTelefono" name="telefono" type="number" placeholder="Escribe aquí"
          (keydown)="validateNumber($event)"
          [ngClass]="{'invalid-warning': ( cuentaForm.get('cuentaTelefono')?.hasError('required') && cuentaForm.get('cuentaTelefono')?.touched ) || ( cuentaForm.get('cuentaTelefono')?.hasError('pattern') && cuentaForm.get('cuentaTelefono')?.touched )}" />
        <span class="w-100 text-danger fw-bold text-right"
          *ngIf="cuentaForm.get('cuentaTelefono')?.hasError('pattern') && cuentaForm.get('cuentaTelefono')?.touched">
          <span class="alerta">Númerico de 7 o 10 dígitos</span>
        </span>
      </div>
      <!--Teléfono-->
      <div class="telefono-empresa">
        <label for="telefono">Teléfono 2 (7 o 10 dígitos)</label>
        <input formControlName="cuentaTelefono2" name="telefono2" type="text" placeholder="Escribe aquí"
          (keydown)="validateNumber($event)"
          [ngClass]="{'invalid-warning': this.cuentaForm.get('cuentaTelefono2')?.value.toString().length >=  1 && cuentaForm.get('cuentaTelefono2')?.touched && this.cuentaForm.get('cuentaTelefono2')?.value.toString().length != 7 && this.cuentaForm.get('cuentaTelefono2')?.value.toString().length != 10 }" />
        <span class="reactive-text text-danger"
          *ngIf="this.cuentaForm.get('cuentaTelefono2')?.value.toString().length >=  1 && cuentaForm.get('cuentaTelefono2')?.touched && this.cuentaForm.get('cuentaTelefono2')?.value.toString().length != 7 && this.cuentaForm.get('cuentaTelefono2')?.value.toString().length != 10">
          <span class="alerta">Númerico de 7 o 10 dígitos</span>
        </span>
      </div>
    </div>
  </div>
  <!-------Documentación------->
  <div class="card p-5 mt-4">
    <div class="row">
      <div class="col-12 mb-2">
        <h5>
          <span class="asterisco">*</span>Documentación
          <h6 class="text-secondary font-weight-light">Máximo 2MB por archivo</h6>
        </h5>
      </div>
    </div>
    <div class="documents-container p-1">
      <!--RUT-->
      <div class="w-100 rut-documento">
        <h6 class="w-100">RUT:</h6>
        <div class="w-100 row m-0 p-0" *ngIf="rutPDF !== ''">
          <span class="col-10 text-right d-flex align-items-center justify-content-end">RUT_{{
            authService.user_horeca!.nombre_establecimiento }}.pdf</span>
          <a target="_blank" rel=noopener href={{rutPDF}} class="col-2 btn ojo">
            <fa-icon [icon]="faEye"></fa-icon>
          </a>
        </div>
        <div class="w-100 row m-0 p-0" *ngIf="rutPDF === ''">
          <span class="col-10 text-right d-flex align-items-center justify-content-end">
            No está cargado este archivo
          </span>
          <a class="col-2 btn ojo">
            <fa-icon [icon]="faEyeSlash"></fa-icon>
          </a>
        </div>
        <div class="w-100  m-0 p-0">
          <input type="file" id="file" #fileInputRUT required hidden (change)="captureDocument($event, 1)">
          <label for="file" class="w-100 row m-0">
            <span class="col-10 text-right d-flex align-items-center justify-content-end">Cambiar Documento</span>
            <fa-icon [icon]="faPaperclip" class="col-2 btn clip"></fa-icon>
          </label>
          <div class="w-100 row m-0">
            <span *ngIf="rutName" class="col-10 text-right d-flex align-items-center justify-content-end">Nuevo
              Doc.:
              {{rutName}}</span>
            <button *ngIf="rutName" type="button" class="col-2 btn borrar" (click)="clearSelection(1)">
              <fa-icon [icon]="faTrash"></fa-icon>
            </button>
          </div>
        </div>
        <span class="reactive-text text-danger" *ngIf="rutPDF == ''">
          <span class="alerta">Requerido</span>
        </span>
      </div>
      <!--Cámara de comercio-->
      <div class="w-100 camara-documento">
        <h6 class="w-100">Cámara de Comercio:</h6>
        <div class="w-100 row m-0 p-0" *ngIf="camComPDF !== ''">
          <span class="col-10 text-right d-flex align-items-center justify-content-end">
            Camara_{{authService.user_horeca!.nombre_establecimiento }}.pdf
          </span>
          <a target="_blank" rel=noopener href={{camComPDF}} class="col-2 btn ojo">
            <fa-icon [icon]="faEye"></fa-icon>
          </a>
        </div>
        <div class="w-100 row m-0 p-0" *ngIf="camComPDF === ''">
          <span class="col-10 text-right d-flex align-items-center justify-content-end">
            No está cargado este archivo
          </span>
          <a class="col-2 btn ojo">
            <fa-icon [icon]="faEyeSlash"></fa-icon>
          </a>
        </div>
        <div class="w-100  m-0 p-0">
          <input type="file" id="file2" #fileInputCamCom required hidden (change)="captureDocument($event, 2)">
          <label for="file2" class="w-100 row m-0">
            <span class="col-10 text-right d-flex align-items-center justify-content-end">
              Cambiar Documento
            </span>
            <fa-icon [icon]="faPaperclip" class="col-2 btn clip"></fa-icon>
          </label>
          <div class="w-100 row m-0">
            <span *ngIf="camComName" class="col-10 text-right d-flex align-items-center justify-content-end">
              Nuevo Doc.: {{camComName}}
            </span>
            <button *ngIf="camComName" type="button" class="col-2 btn borrar" (click)="clearSelection(2)">
              <fa-icon [icon]="faTrash"></fa-icon>
            </button>
          </div>
          <span class="reactive-text text-danger"
            *ngIf="camComPDF == '' && authService.user_horeca!.tipo_usuario == 'Empresa'">
            <span class="alerta">Requerido</span>
          </span>
        </div>
      </div>
      <!--Doc. Representante legal-->
      <div class="w-100 representante-documento">
        <h6 class="w-100">Doc. Representante Legal:</h6>
        <div class="w-100 row m-0 p-0" *ngIf="CCPDF !== ''">
          <span class="col-10 text-right d-flex align-items-center justify-content-end">
            Cedula_{{ authService.user!.nombres }}.pdf
          </span>
          <a target="_blank" rel=noopener href={{CCPDF}} class="col-2 btn ojo">
            <fa-icon [icon]="faEye"></fa-icon>
          </a>
        </div>
        <div class="w-100 row m-0 p-0" *ngIf="CCPDF === ''">
          <span class="col-10 text-right d-flex align-items-center justify-content-end">
            No está cargado este archivo
          </span>
          <a class="col-2 btn ojo">
            <fa-icon [icon]="faEyeSlash"></fa-icon>
          </a>
        </div>
        <div class="w-100  m-0 p-0">
          <input type="file" id="file3" #fileInputCC required hidden (change)="captureDocument($event, 3)">
          <label for="file3" class="w-100 row m-0">
            <span class="col-10 text-right d-flex align-items-center justify-content-end">Cambiar Documento</span>
            <fa-icon [icon]="faPaperclip" class="col-2 btn clip"></fa-icon>
          </label>
          <div class="w-100 row m-0">
            <span *ngIf="CCName" class="col-10 text-right d-flex align-items-center justify-content-end">Nuevo Doc.:
              {{CCName}}</span>
            <button *ngIf="CCName" type="button" class="col-2 btn borrar" (click)="clearSelection(3)">
              <fa-icon [icon]="faTrash"></fa-icon>
            </button>
          </div>
        </div>
        <span class="reactive-text text-danger" *ngIf="CCPDF == ''">
          <span class="alerta">Requerido</span>
        </span>
      </div>
    </div>
  </div>
</div>
<!--Guardar cambios-->
<div class="mt-4">
  <button class="btn-submit px-5" (click)=" is_boton_habilitado && guardarCambios(user._id, user.numero_documento)"
    (click)="!is_boton_habilitado && alertaFormularioInvalido()"
    [ngClass]="{'btn-submit-disabled': !is_boton_habilitado}">
    Guardar cambios
  </button>
</div>