<div class="col-12">
  <!--Barra de búsquedas-->
  <div class="busqueda-container row mb-4">
    <div class="col-12">
      <h6>Selecciona un punto de entrega</h6>
    </div>
    <div class="col-md-6">
      <select name="" id="" class="selectPunto" [(ngModel)]="puntoSeleccionado"
            (ngModelChange)="filtrarPedidos()">
            <option value="todos">
              Seleccionar
            </option>
            <option [value]="punto._id" *ngFor="let punto of listaPuntos">
              {{punto.nombre}}
            </option>
          </select>
    </div>
    <!-- Buscar por nombre de distribuidor-->
    <div class="col-12 col-md-7 pr-3 pl-0 busqueda-nombre" *ngIf="puntoSeleccionado !== 'todos'">
      <div class="card card-search m-0">
        <div class="card-body card-search">
          <div class="barra-busqueda">
            <div class="d-flex flex-row autocompletado-busqueda px-0 h-100 w-100">
              <div class="col-1 text-center">
                <fa-icon class="fa-icon-search m-0" [icon]="faSearch" size="sm" [styles]="{ color: '#41403E' }">
                </fa-icon>
              </div>
              <form class="example-form col-11 pr-0">
                <mat-form-field class="example-full-width">
                  <input #escogido type="text" placeholder="Buscar por nombre del distribuidor"
                    aria-label="Buscar distribuidor" matInput [formControl]="mySearch" [matAutocomplete]="auto"
                    class="w-100 h-100 input-busqueda pr-0">
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" [displayWith]="itemDisplayFn"
                    class="w-100" (optionSelected)='verDetalleDistribuidor($event)'>
                    <mat-option *ngFor="let option of filteredOptions  | async" [value]="option">
                      {{option?.nombre_distribuidor}} | {{option?.estado}} | {{option?.fecha}} | {{option?.precio}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Buscar por fecha-->
    <div class="col-6 col-md-3 p-0 busqueda-fecha" *ngIf="puntoSeleccionado !== 'todos'">
      <div class="input-group">
        <input class="form-control input-date" placeholder="Fecha" name="dp" [(ngModel)]="model" ngbDatepicker
          #d="ngbDatepicker" />
        <div class="input-group-append input-date">
          <button class="btn btn-outline-secondary calendar input-date" (click)="d.toggle()" type="button">
            <fa-icon class="fa-icon-calendar" [icon]="faChevronDown" size="sm" [styles]="{'color': '#41403E'}">
            </fa-icon>
          </button>
        </div>
      </div>
    </div>
    <!--Limpiar fecha-->
    <div class="col-6 col-md-2 busqueda-fecha-borrar" *ngIf="puntoSeleccionado !== 'todos'">
      <button class="btn_transparent" *ngIf="model != undefined" (click)="model = undefined">
        Limpiar Fecha
      </button>
    </div>
  </div>
  <!-- Total Pedidos y temptale error -->
  <p class="my-3 mb-0 font-weight-bold" *ngIf="puntoSeleccionado !== 'todos'">Total Pedidos ({{ pedidos_filtrados.length }})</p>
  <ng-template #mostrarError>
    <div class="error-message">
      <p>
        !No se encontraron pedidos!
      </p>
    </div>
  </ng-template>
  <!--Pedidos Facturado -->
  <!-- <p class="mb-2 mt-4 mb-0">Pedidos Facturados ({{ pedidos_facturado_filtrados.length }})</p>
  <div class="row card-container" *ngIf="pedidos_facturado_filtrados.length != 0  else mostrarError">
    <div class="pedido" *ngFor="let ped of pedidos_facturado_filtrados; index as i;">
      <div class="card card-pedido" style="cursor: pointer;" [routerLink]="'/pedidos/' + ped._id">
        <div class="card-body card-body-pedido">
          <div class="w-100 d-flex flex-row justify-content-between">
            <div>
              <fa-icon [icon]="faCircle" size="sm" [styles]="{'color': '#C7D389'}"></fa-icon>&nbsp;{{ ped.estado }}:
            </div>
            <div>{{ ped.fecha }}</div>
          </div>
          <hr>
          <div class="w-100 row m-0">
            <div class="col-3 pl-0">
              <img src="{{ ped.logo || distribuidor_placeholder }}" [alt]="ped.nombre_distribuidor + ' logo'" />
            </div>
            <div class="col-9 p-0 d-flex flex-column align-content-between">
              <h5 class="mb-3">
                {{ ped.nombre_distribuidor }}
              </h5>
              <p class="mb-3 d-flex flex-row justify-content-between">
                ID Pedido: <span>{{ ped.id_pedido }}</span>
              </p>
              <p class="mb-3 d-flex flex-row justify-content-between">
                Precio total: <span> {{ ped.precio | currency:'$':'symbol':'1.0-0' }} </span>
              </p>
              <p class="m-0 d-flex justify-content-between">
                <span>{{ ped.productos }} productos</span>
                <span>-</span>
                <span>{{ ped.unidades }} unidades</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!--Pedidos Entregados -->
  <div *ngIf="puntoSeleccionado !== 'todos'">
    <p class="mb-2 mt-4 mb-0">Pedidos Entregados ({{ pedidos_entregado_filtrados.length }})</p>
    <div class="row card-container" *ngIf="pedidos_entregado_filtrados.length != 0  else mostrarError">
      <div class="pedido" *ngFor="let ped of pedidos_entregado_filtrados; index as i;">
        <div class="card card-pedido" style="cursor: pointer;" [routerLink]="'/pedidos/' + ped._id">
          <div class="card-body card-body-pedido">
            <div class="w-100 d-flex flex-row justify-content-between">
              <div>
                <fa-icon [icon]="faCircle" size="sm" [styles]="{'color': '#97AE22'}"></fa-icon>&nbsp;{{ ped.estado }}:
              </div>
              <div>{{ ped.fecha }}</div>
            </div>
            <hr>
            <div class="w-100 row m-0">
              <div class="col-3 pl-0">
                <img src="{{ ped.logo || distribuidor_placeholder }}" [alt]="ped.nombre_distribuidor + ' logo'" />
              </div>
              <div class="col-9 p-0 d-flex flex-column align-content-between">
                <h5 class="mb-3">
                  {{ ped.nombre_distribuidor }}
                </h5>
                <p class="mb-3 d-flex flex-row justify-content-between">
                  ID Pedido: <span>{{ ped.id_pedido }}</span>
                </p>
                <p class="mb-3 d-flex flex-row justify-content-between">
                  Precio total: <span> {{ ped.precio | currency:'$':'symbol':'1.0-0' }} </span>
                </p>
                <p class="m-0 d-flex justify-content-between">
                  <span>{{ ped.productos }} productos</span>
                  <span>-</span>
                  <span>{{ ped.unidades }} unidades</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Pedidos Recibidos -->
    <p class="mb-2 mt-4 mb-0">Pedidos Recibidos ({{ pedidos_recibido_filtrados.length }})</p>
    <div class="row card-container" *ngIf="pedidos_recibido_filtrados.length != 0  else mostrarError">
      <div class="pedido" *ngFor="let ped of pedidos_recibido_filtrados; index as i;">
        <div class="card card-pedido" style="cursor: pointer;" [routerLink]="'/pedidos/' + ped._id">
          <div class="card-body card-body-pedido">
            <div class="w-100 d-flex flex-row justify-content-between">
              <div>
                <fa-icon [icon]="faCircle" size="sm" [styles]="{'color': '#97AE22'}"></fa-icon>&nbsp;{{ ped.estado }}:
              </div>
              <div>{{ ped.fecha }}</div>
            </div>
            <hr>
            <div class="w-100 row m-0">
              <div class="col-3 pl-0">
                <img src="{{ ped.logo || distribuidor_placeholder }}" [alt]="ped.nombre_distribuidor + ' logo'" />
              </div>
              <div class="col-9 p-0 d-flex flex-column align-content-between">
                <h5 class="mb-3">
                  {{ ped.nombre_distribuidor }}
                </h5>
                <p class="mb-3 d-flex flex-row justify-content-between">
                  ID Pedido: <span>{{ ped.id_pedido }}</span>
                </p>
                <p class="mb-3 d-flex flex-row justify-content-between">
                  Precio total: <span> {{ ped.precio | currency:'$':'symbol':'1.0-0' }} </span>
                </p>
                <p class="m-0 d-flex justify-content-between">
                  <span>{{ ped.productos }} productos</span>
                  <span>-</span>
                  <span>{{ ped.unidades }} unidades</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Pedidos Calificado -->
    <p class="mb-2 mt-4 mb-0">Pedidos Calificados ({{ pedidos_calificado_filtrados.length }})</p>
    <div class="row card-container" *ngIf="pedidos_calificado_filtrados.length != 0  else mostrarError">
      <div class="pedido" *ngFor="let ped of pedidos_calificado_filtrados; index as i;">
        <div class="card card-pedido" style="cursor: pointer;" [routerLink]="'/pedidos/' + ped._id">
          <div class="card-body card-body-pedido">
            <div class="w-100 d-flex flex-row justify-content-between">
              <div>
                <fa-icon [icon]="faCircle" size="sm" [styles]="{'color': '#C7D389'}"></fa-icon>&nbsp;{{ ped.estado }}:
              </div>
              <div>{{ ped.fecha }}</div>
            </div>
            <hr>
            <div class="w-100 row m-0">
              <div class="col-3 pl-0">
                <img src="{{ ped.logo || distribuidor_placeholder }}" [alt]="ped.nombre_distribuidor + ' logo'" />
              </div>
              <div class="col-9 p-0 d-flex flex-column align-content-between">
                <h5 class="mb-3">
                  {{ ped.nombre_distribuidor }}
                </h5>
                <p class="mb-3 d-flex flex-row justify-content-between">
                  ID Pedido: <span>{{ ped.id_pedido }}</span>
                </p>
                <p class="mb-3 d-flex flex-row justify-content-between">
                  Precio total: <span> {{ ped.precio | currency:'$':'symbol':'1.0-0' }} </span>
                </p>
                <p class="m-0 d-flex justify-content-between">
                  <span>{{ ped.productos }} productos</span>
                  <span>-</span>
                  <span>{{ ped.unidades }} unidades</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Pedidos Rechazados -->
    <p class="mb-2 mt-4 mb-0">Pedidos Rechazados ({{ pedidos_rechazado_filtrados.length }})</p>
    <div class="row card-container" *ngIf="pedidos_rechazado_filtrados.length != 0  else mostrarError">
      <div class="pedido" *ngFor="let ped of pedidos_rechazado_filtrados; index as i;">
        <div class="card card-pedido" style="cursor: pointer;" [routerLink]="'/pedidos/' + ped._id">
          <div class="card-body card-body-pedido">
            <div class="w-100 d-flex flex-row justify-content-between">
              <div>
                <fa-icon [icon]="faCircle" size="sm" [styles]="{'color': '#D38989'}"></fa-icon>&nbsp;{{ ped.estado }}:
              </div>
              <div>
                {{ ped.fecha }}
              </div>
            </div>
            <hr>
            <div class="w-100 row m-0">
              <div class="col-3 pl-0">
                <img src="{{ ped.logo || distribuidor_placeholder }}" [alt]="ped.nombre_distribuidor + ' logo'" />
              </div>
              <div class="col-9 p-0 d-flex flex-column align-content-between">
                <h5 class="mb-3">
                  {{ ped.nombre_distribuidor }}
                </h5>
                <p class="mb-3 d-flex flex-row justify-content-between">
                  ID Pedido: <span>{{ ped.id_pedido }}</span>
                </p>
                <p class="mb-3 d-flex flex-row justify-content-between">
                  Precio total: <span> {{ ped.precio | currency:'$':'symbol':'1.0-0' }} </span>
                </p>
                <p class="m-0 d-flex justify-content-between">
                  <span>{{ ped.productos }} productos</span>
                  <span>-</span>
                  <span>{{ ped.unidades }} unidades</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Pedidos Cancelados -->
    <p class="mb-2 mt-4 mb-0">Pedidos Cancelados ({{ pedidos_cancelado_filtrados.length }})</p>
    <div class="row card-container" *ngIf="pedidos_cancelado_filtrados.length != 0  else mostrarError">
      <div class="pedido" *ngFor="let ped of pedidos_cancelado_filtrados; index as i;">
        <div class="card card-pedido" style="cursor: pointer;" [routerLink]="'/pedidos/' + ped._id">
          <div class="card-body card-body-pedido">
            <div class="w-100 d-flex flex-row justify-content-between">
              <div>
                <fa-icon [icon]="faCircle" size="sm" [styles]="{'color': '#D38989'}"></fa-icon>&nbsp;{{ ped.estado }}:
              </div>
              <div>{{ ped.fecha }}</div>
            </div>
            <hr>
            <div class="w-100 row m-0">
              <div class="col-3 pl-0">
                <img src="{{ ped.logo || distribuidor_placeholder }}" [alt]="ped.nombre_distribuidor + ' logo'" />
              </div>
              <div class="col-9 p-0 d-flex flex-column align-content-between">
                <h5 class="mb-3">
                  {{ ped.nombre_distribuidor }}
                </h5>
                <p class="mb-3 d-flex flex-row justify-content-between">
                  ID Pedido: <span>{{ ped.id_pedido }}</span>
                </p>
                <p class="mb-3 d-flex flex-row justify-content-between">
                  Precio total: <span> {{ ped.precio | currency:'$':'symbol':'1.0-0' }} </span>
                </p>
                <p class="m-0 d-flex justify-content-between">
                  <span>{{ ped.productos }} productos</span>
                  <span>-</span>
                  <span>{{ ped.unidades }} unidades</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="pedidos_filtrados.length == 0">
      <div class="col-12 text-center my-4">
        <h5>
          No se encontraron pedidos en el historial
        </h5>
      </div>
    </div>
  </div>
  <div  *ngIf="puntoSeleccionado === 'todos'">
    <img src="../../assets/img/icon-warning-amarillo.png"
    alt="Icono de advertencia" class="icon-ad"/>
  <h5 class="my-2">Para revisar tus pedidos primero <br> selecciona un punto de entrega</h5>
  </div>
</div>