<app-header-horeca></app-header-horeca>
<div class="container-fluid p-5 contain">
  <div class="header-precios row m-0">
    <div class="col-lg-5">
      <h2 class="main-title text-left">Chequeo de precios.</h2>
    </div>
    <div class="
        d-flex
        flex-row
        offset-md-4
        col-md-8
        offset-lg-0
        col-lg-7
        align-items-center
        punto-entrega-container
        rounded
        overflow-hidden
        p-0
      ">
      <div class="container-pin col-2 col-sm-1 h-100">
        <img src="../../assets/img/icons/icon-pin-map.png" alt="icono de un pin para señalar un sitio en un mapa" />
      </div>
      <span class="text-left col-6 col-sm-9" id="point">
        <span class="texto-ocultar">Punto de entrega:</span>
        <span>{{ punto_entrega?.nombre || 'Selecciona un punto' }}</span>
      </span>
      <span class="col-4 col-sm-2 pl-0 cambiar-punto d-flex justify-content-center" (click)="selecionarPunto()">
        Cambiar
      </span>
    </div>
    <div class="col-12 text-left my-4 texto-banner">
      <p>Busca el producto que necesites para comparar los precios con tus distribuidores y tomes la mejor decisión</p>
    </div>
  </div>
  <div class="inputs-forms col-12 row m-0 p-0 mb-4">
    <div class="d-flex flex-row col-12 autocompletado-busqueda p-0">
      <button class="col-2 col-md-1 p-0 boton-buscar d-flex justify-content-center">
        <fa-icon class="fa-icon-search m-0" [icon]="faSearch" size="lg" [styles]="{ color: '#41403E' }"></fa-icon>
      </button>
      <form class="example-form col-10 col-md-11">
        <mat-form-field class="example-full-width">
          <input #escogido type="text" placeholder="Buscar producto" aria-label="Buscar producto" matInput
            [formControl]="mySearch" [matAutocomplete]="auto" class="w-100 h-100 input-busqueda" />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" [displayWith]="itemDisplayFn"
            class="w-100" (optionSelected)="getProductosFiltrados($event)">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{ option?.nombre }} <span class="text-secondary">
                | Cod. Feat: {{ option.codigo_ft}} | {{ option.descripcion}}
              </span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>
  </div>
  <div>
    <div class="containt-information row mx-0 mt-4" *ngIf="productos_filtrados.length > 0; else mostrarError">
      <div class="col-12 d-flex flex-row justify-content-between mb-4 texto-banner">
        <p>Resultados de {{ productos_filtrados?.length || '' }} distribuidores</p>
      </div>
      <div class="d-flex justify-content-between col-12 p-0 m-0 row">
        <div class="card-product p-4 m-0">
          <div class="container">
            <p class="border-bottom py-2 mb-2">{{ productos_filtrados[0]?.nombre }}</p>
            <div class="d-flex">
              <div>
                {{ (productos_filtrados[0]?.precios)![0].cantidad_medida }}
              </div>
              <p *ngIf="(productos_filtrados[0]?.precios)![0].unidad_medida === undefined; else mostrarUnidad">gr</p>
              <ng-template #mostrarUnidad>
                <p>{{ (productos_filtrados[0]?.precios)![0].unidad_medida }}</p>
              </ng-template>
            </div>
            <div class="imagen rounded overflow-hidden">
              <img src="{{ (productos_filtrados[0]?.fotos)![0] || product_Placeholder }}"
                alt="{{ productos_filtrados[0]?.nombre }}" />
            </div>
          </div>
        </div>
        <div class="containt-table p-4 m-0">
          <div class="overflow-hidden">
            <div class="scroll-tabla">
              <ng2-smart-table [settings]="settings_tabla" [source]="source_tabla"></ng2-smart-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #mostrarError>
    <div class="d-flex justify-content-center mt-4">
      <div>
        <img src="../../assets/img/esperando-apro.png" alt="icono de una hoja de papel y una lupa sobre ella" />
      </div>
    </div>
    <div class="mt-2">
      <p class="text-center">
        ¡Busca y compara! Todavía no has buscado un producto para comparar el precio con tus distribuidores
      </p>
    </div>
  </ng-template>
</div>