<div class="contenedor-graficas">
  <div class="graficas">
    <!-- Puntos por mes -->
    <div>
      <span class="d-flex flex-row justify-content-between">
        <p>No. de puntos acumulados por trimestre</p>
        <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_puntos_mes"
          (ngModelChange)="getGraficaInformacionPuntosMes()">
          <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
            {{mes.filtro}}-{{mes.filtro_inicio}}
          </option>
        </select>
      </span>
      <div class="d-flex justify-content-start pt-1">
        Total: {{total_puntos_mes}}
      </div>
      <canvas #bar_puntos_mes class="mt-3 grafica"></canvas>
    </div>
    <!-- Puntos por producto -->
    <div>
      <span class="d-flex flex-row justify-content-between">
        <p>No. de puntos acumulados por producto</p>
        <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_puntos_productos"
          (ngModelChange)="getGraficaInformacionPuntosProducto()">
          <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
            {{mes.filtro}}-{{mes.filtro_inicio}}
          </option>
        </select>
      </span>
      <div class="d-flex justify-content-start pt-1">
        Total: {{total_puntos_productos}}
      </div>
      <canvas #bar_puntos_productos class="mt-3 grafica"></canvas>
    </div>
    <!-- Puntos por categoria -->
    <div>
      <span class="d-flex flex-row justify-content-between">
        <p>No. de puntos acumulados por categoría</p>
        <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_puntos_categoria"
          (ngModelChange)="getGraficaInformacionPuntosCategoria()">
          <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
            {{mes.filtro}}-{{mes.filtro_inicio}}
          </option>
        </select>
      </span>
      <div class="d-flex justify-content-start pt-1">
        Total: {{total_puntos_categoria}}
      </div>
      <canvas #bar_puntos_categoria class="mt-3 grafica"></canvas>
    </div>
    <!-- Puntos por marca -->
    <div>
      <span class="d-flex flex-row justify-content-between">
        <p>No. de puntos acumulados por marca</p>
        <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_puntos_marca"
          (ngModelChange)="getGraficaInformacionPuntosMarca()">
          <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
            {{mes.filtro}}-{{mes.filtro_inicio}}
          </option>
        </select>
      </span>
      <div class="d-flex justify-content-start pt-1">
        Total: {{total_puntos_marca}}
      </div>
      <canvas #bar_puntos_marca class="mt-3 grafica"></canvas>
    </div>
    <!-- Puntos por distribuidor -->
    <div>
      <span class="d-flex flex-row justify-content-between">
        <p>No. de puntos acumulados por distribuidor</p>
        <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_puntos_distribuidor"
          (ngModelChange)="getGraficaInformacionPuntosDistribuidor()">
          <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
            {{mes.filtro}}-{{mes.filtro_inicio}}
          </option>
        </select>
      </span>
      <div class="d-flex justify-content-start pt-1">
        Total: {{total_puntos_distribuidor}}
      </div>
      <canvas #bar_puntos_distribuidor class="mt-3 grafica"></canvas>
    </div>
    <!-- Top 10 productos -->
    <div>
      <span class="d-flex flex-row justify-content-between">
        <p>Top 10 puntos acumulados por producto (Últimos 3 meses)</p>
      </span>
      <div class="d-flex justify-content-start pt-1">
        Total: {{total_top_productos}}
      </div>
      <canvas #bar_top_productos class="mt-3 grafica"></canvas>
    </div>
  </div>
</div>