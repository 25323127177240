<section>
    <!-- Distribuidores -->
    <div class="card p-4 mt-4">
        <span class="d-flex flex-row align-items-center justify-content-between pt-4 pb-0">
            <h5 class="m-0">Distribuidores</h5>
            <button class="btn-purple" (click)="descargaArchivoDistribuidores()">Descargar datos</button>
        </span>
        <div class="card-body scroll-tabla mt-3 p-0">
            <ng2-smart-table [settings]="settings_distribuidores" [source]="source_distribuidores"></ng2-smart-table>
        </div>
    </div>
    <!-- Usuarios -->
    <div class="card p-4 mt-4">
        <span class="d-flex flex-row align-items-center justify-content-between pt-4 pb-0">
            <h5 class="m-0">Usuarios</h5>
            <button class="btn-purple" (click)="descargaArchivoUsuarios()">Descargar datos</button>
        </span>
        <div class="card-body scroll-tabla mt-3 p-0">
            <ng2-smart-table [settings]="settings_usuarios" [source]="source_usuarios"></ng2-smart-table>
        </div>
    </div>
    <!-- Puntos de Entrega -->
    <div class="card p-4 mt-4">
        <span class="d-flex flex-row align-items-center justify-content-between pt-4 pb-0">
            <h5 class="m-0">Puntos de Entrega</h5>
            <button class="btn-purple" (click)="descargaArchivoPuntosEntrega()">Descargar datos</button>
        </span>
        <div class="card-body scroll-tabla mt-3 p-0">
            <ng2-smart-table [settings]="settings_puntos_entrega" [source]="source_puntos_entrega"></ng2-smart-table>
        </div>
    </div>
    <!-- Puntos Feat -->
    <div class="card p-4 mt-4">
        <span class="d-flex flex-row align-items-center justify-content-between pt-4 pb-0">
            <h5 class="m-0">Puntos Feat</h5>
            <button class="btn-purple" (click)="descargaArchivoPuntosFeat()">Descargar datos</button>
        </span>
        <div class="card-body scroll-tabla mt-3 p-0">
            <ng2-smart-table [settings]="settings_puntos_feat" [source]="source_puntos_feat"></ng2-smart-table>
        </div>
    </div>
    <!-- Pedidos -->
    <div class="card p-4 mt-4">
        <span class="d-flex flex-row align-items-center justify-content-between pt-4 pb-0">
            <h5 class="m-0">Pedidos</h5>
            <button class="btn-purple" (click)="descargaArchivoPedidos()">Descargar datos</button>
        </span>
        <div class="card-body scroll-tabla mt-3 p-0">
            <ng2-smart-table [settings]="settings_pedidos" [source]="source_pedidos">
            </ng2-smart-table>
        </div>
    </div>
</section>