<section class="mb-5 pb-5">
  <form autocomplete="off">
    <div
      *ngIf="registroComp.step == 2 || registroComp.step == 3"
      class="d-flex flex-wrap container-fluid main-container p-0">
      <!--Paso 2-->
      <div class="card-propietario col-6 m-0">
        <!--Card propietario ON-->
        <div
          class="card text-center"
          style="cursor: pointer"
          (click)="seleccionarPerfil('Propietario o representante legal')"
          *ngIf="
            registroComp.horeca_perfil_seleccionado == 'Propietario o representante legal' ||
            registroComp.horeca_perfil_seleccionado == ''
          ">
          <div class="card-body row">
            <div class="col-12 col-md-4">
              <img
                src="../../../../assets/img/horeca-legal-on.png"
                alt="Representante legal o dueño"
                class="icon-registro" />
            </div>
            <div class="col-12 col-md-8">
              <h5 class="card-title title-tipo-usuario text-left">Propietario o representante legal</h5>
            </div>
          </div>
        </div>
        <!--Card propietario OFF-->
        <div
          class="card text-center"
          style="cursor: pointer"
          (click)="seleccionarPerfil('Propietario o representante legal')"
          *ngIf="
            registroComp.horeca_perfil_seleccionado != 'Propietario o representante legal' &&
            registroComp.horeca_perfil_seleccionado != ''
          ">
          <div class="card-body row">
            <div class="col-12 col-md-4">
              <img
                src="../../../../assets/img/horeca-legal-off.png"
                alt="Representante legal o dueño"
                class="icon-registro" />
            </div>
            <div class="col-12 col-md-8">
              <h5 class="card-title title-tipo-usuario text-left" style="color: #aeaead">
                Propietario o representante legal
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="card-trabajador col-6 m-0">
        <!--Card trabajador ON-->
        <div
          class="card text-center"
          style="cursor: pointer"
          (click)="seleccionarPerfil('Trabajador')"
          *ngIf="
            registroComp.horeca_perfil_seleccionado == 'Trabajador' || registroComp.horeca_perfil_seleccionado == ''
          ">
          <div class="card-body row">
            <div class="col-12 col-md-4">
              <img src="../../../../assets/img/horeca-trabajador-on.png" alt="Trabajador" class="icon-registro" />
            </div>
            <div class="col-12 col-md-8">
              <h5 class="card-title title-tipo-usuario text-left">Trabajador del establecimiento</h5>
            </div>
          </div>
        </div>
        <!--Card trabajador OFF-->
        <div
          class="card text-center"
          style="cursor: pointer"
          (click)="seleccionarPerfil('Trabajador')"
          *ngIf="
            registroComp.horeca_perfil_seleccionado != 'Trabajador' && registroComp.horeca_perfil_seleccionado != ''
          ">
          <div class="card-body row">
            <div class="col-12 col-md-4">
              <img src="../../../../assets/img/horeca-trabajador-off.png" alt="Trabajador" class="icon-registro" />
            </div>
            <div class="col-12 col-md-8">
              <h5 class="card-title title-tipo-usuario text-left" style="color: #aeaead">
                Trabajador del establecimiento
              </h5>
            </div>
          </div>
        </div>
      </div>
      <br />
      <!--Paso 3-->
      <div
        class="col-12 mt-4 p-0"
        *ngIf="
          registroComp.horeca_perfil_seleccionado == 'Trabajador' ||
          registroComp.horeca_perfil_seleccionado == 'Propietario o representante legal'
        ">
        <!--Propietario o representante legal-->
        <div *ngIf="registroComp.horeca_perfil_seleccionado == 'Propietario o representante legal'">
          <div class="card">
            <div class="card-body p-4 flex-column">
              <div class="w-100 text-left">
                <h5 class="card-title">Selecciona según tu perfil</h5>
              </div>
              <div class="w-100 text-left">
                <p class="card-subtitle my-2 w-100">Tipo de persona</p>
              </div>
              <div class="w-100">
                <select
                  #tipo_value
                  class="d-inline-block dropdown-form-1 input-nit"
                  [(ngModel)]="registroComp.tipo_persona"
                  (change)="seleccionarTipoPersona(tipo_value.value)">
                  <option ngValue="Natural">Natural</option>
                  <option ngValue="Jurídica">Jurídica</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <!--Trabajador-->
        <div *ngIf="registroComp.horeca_perfil_seleccionado == 'Trabajador'">
          <div class="card">
            <div class="card-body p-4 flex-column">
              <div class="w-100 text-left">
                <h5 class="card-title">
                  La empresa a la que haces parte debe estar registrada para que te unas al equipo desde la plataforma
                </h5>
              </div>
              <div class="w-100 text-left nit-cedula">
                <p class="card-subtitle my-2 w-100">NIT empresa o cédula del propietario</p>
              </div>
              <div class="row w-100 m-0">
                <div class="col-md-6 col-12">
                  <input
                    type="text"
                    placeholder="Escribe aquí"
                    [(ngModel)]="registroComp.t_nit"
                    class="input-nit w-100" />
                </div>
                <div class="col-md-6 col-12 pl-2">
                  <div *ngIf="registroComp.t_nit != ''">
                    <p *ngIf="empresa_encontrada != undefined" class="m-0">
                      <fa-icon [icon]="faCheck" [styles]="{ color: '#41403E' }"></fa-icon>
                      {{ empresa_encontrada.nombre_establecimiento }}
                      <span *ngIf="empresa_encontrada.nit === empresa_encontrada.numero_documento">
                        Propietatio: {{ empresa_encontrada.prop_nombre }} {{ empresa_encontrada.prop_apellido }}
                      </span>
                    </p>
                    <p *ngIf="empresa_encontrada == undefined" class="m-0">
                      <fa-icon [icon]="faTimes" [styles]="{ color: '#41403E' }"></fa-icon>
                      No se encontró el NIT ingresado
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Propietario-->
    <div *ngIf="registroComp.horeca_perfil_seleccionado == 'Propietario o representante legal'">
      <!--Paso 4-->
      <div *ngIf="registroComp.step == 4">
        <div class="formulario-propietario p-4" [formGroup]="horecaForm">
          <!--Tipo de documento-->
          <div class="propietario-documento">
            <label for="tipo documento">
              <span class="asterisco">*</span>
              Tipo de documento de identidad
            </label>
            <select
              formControlName="horecaTipo"
              class="d-inline-block dropdown-form-1"
              [(ngModel)]="registroComp.tipo_doc_identidad"
              (change)="registroComp.doc_identidad = ''"
              [ngClass]="{
                'invalid-warning':
                  horecaForm.get('horecaTipo')?.hasError('required') && horecaForm.get('horecaTipo')?.touched
              }">
              
              <option value="Cédula de ciudadanía">Cédula de ciudadanía</option>
              <option value="Cédula de extranjería">Cédula de extranjería</option>
              <option value="Pasaporte">Pasaporte</option>
            </select>
          </div>
          <!--Número de documento-->
          <div class="propietario-cedula">
            <label for="documento">
              <span class="asterisco">*</span>
              Número de documento de identidad
            </label>
            <input
              *ngIf="registroComp.tipo_doc_identidad === 'Pasaporte'"
              placeholder="Escribe aquí"
              [(ngModel)]="registroComp.doc_identidad"
              class="w-100"
              formControlName="horecaCedula"
              value=""
              type="text"
              [ngClass]="{
                'invalid-warning':
                  horecaForm.get('horecaCedula')?.hasError('required') && horecaForm.get('horecaCedula')?.touched
              }" />
            <input
              *ngIf="registroComp.tipo_doc_identidad !== 'Pasaporte'"
              placeholder="Escribe aquí"
              [(ngModel)]="registroComp.doc_identidad"
              class="w-100"
              formControlName="horecaCedula"
              value=""
              type="text"
              (keydown)="validateNumber($event)"
              [ngClass]="{
                'invalid-warning':
                  horecaForm.get('horecaCedula')?.hasError('required') && horecaForm.get('horecaCedula')?.touched
              }" />
          </div>
          <!--Nombres-->
          <div class="propietario-nombre">
            <label for="nombres">
              <span class="asterisco">*</span>
              Nombres
            </label>
            <input
              placeholder="Escribe aquí"
              [(ngModel)]="registroComp.nombres"
              class="w-100"
              formControlName="horecaNombre"
              (keydown)="validateOnlyText($event)"
              [ngClass]="{
                'invalid-warning':
                  horecaForm.get('horecaNombre')?.hasError('required') && horecaForm.get('horecaNombre')?.touched
              }" />
          </div>
          <!--Apellidos-->
          <div class="propietario-apellido">
            <label for="apellidos">
              <span class="asterisco">*</span>
              Apellidos
            </label>
            <input
              placeholder="Escribe aquí"
              [(ngModel)]="registroComp.apellidos"
              class="w-100"
              formControlName="horecaApellido"
              (keydown)="validateOnlyText($event)"
              [ngClass]="{
                'invalid-warning':
                  horecaForm.get('horecaApellido')?.hasError('required') && horecaForm.get('horecaApellido')?.touched
              }" />
          </div>
          <!--Celular-->
          <div class="propietario-celular w-100">
            <label for="celular">
              <span class="asterisco">*</span>
              Celular (10 dígitos)
            </label>
            <input
              type="number"
              placeholder="Escribe aquí"
              [(ngModel)]="registroComp.celular"
              class="w-100"
              formControlName="horecaCelular"
              (keydown)="validateNumber($event)"
              [ngClass]="{
                'invalid-warning':
                  (horecaForm.get('horecaCelular')?.hasError('required') && horecaForm.get('horecaCelular')?.touched) ||
                  (horecaForm.get('horecaCelular')?.hasError('pattern') && horecaForm.get('horecaCelular')?.touched)
              }" />
            <span
              class="reactive-text text-danger"
              *ngIf="horecaForm.get('horecaCelular')?.hasError('pattern') && horecaForm.get('horecaCelular')?.touched">
              <span class="alerta">Númerico de 10 dígitos</span>
            </span>
          </div>
          <!--Teléfono-->
          <div class="propietario-telefono w-100">
            <label for="telefono">Teléfono (opcional)</label>
            <input
              type="number"
              placeholder="Escribe aquí"
              formControlName="horecaTelefono"
              (keydown)="validateNumber($event)"
              [(ngModel)]="registroComp.telefono"
              class="w-100"
              [ngClass]="{
                'invalid-warning':
                  this.registroComp.telefono != null &&
                  this.registroComp.telefono != 0 &&
                  this.registroComp.telefono.toString().length >= 1 &&
                  horecaForm.get('horecaTelefono')?.touched &&
                  this.registroComp.telefono.toString().length != 7 &&
                  this.registroComp.telefono.toString().length != 10
              }" />
            <span
              class="reactive-text text-danger"
              *ngIf="
                this.registroComp.telefono != null &&
                this.registroComp.telefono != 0 &&
                this.registroComp.telefono.toString().length >= 1 &&
                horecaForm.get('horecaTelefono')?.touched &&
                this.registroComp.telefono.toString().length != 7 &&
                this.registroComp.telefono.toString().length != 10
              ">
              <span class="alerta">Númerico de 7 o 10 dígitos</span>
            </span>
          </div>
          <!--País-->
          <div class="propietario-pais">
            <label for="país">
              <span class="asterisco">*</span>
              País
            </label>
            <select
              formControlName="horecaPais"
              class="d-inline-block dropdown-form-1"
              [(ngModel)]="registroComp.pais"
              [ngClass]="{
                'invalid-warning':
                  horecaForm.get('horecaPais')?.hasError('required') && horecaForm.get('horecaPais')?.touched
              }">
              <option selected disabled value="Colombia">Colombia</option>
            </select>
          </div>
          <!--Departamento-->
          <div class="propietario-departamento">
            <label for="departamento">
              <span class="asterisco">*</span>
              Departamento
            </label>
            <select
              formControlName="horecaDepartamento"
              class="d-inline-block dropdown-form-1"
              [(ngModel)]="registroComp.departamento"
              (change)="getPlaces('horeca')"
              (change)="registroComp.ciudad = ''"
              [ngClass]="{
                'invalid-warning':
                  horecaForm.get('horecaDepartamento')?.hasError('required') &&
                  horecaForm.get('horecaDepartamento')?.touched
              }">
              <option [ngValue]="dpto.name" *ngFor="let dpto of places.departments_colombia">
                {{ dpto.name }}
              </option>
            </select>
          </div>
          <!--Ciudad-->
          <div class="propietario-ciudad">
            <label for="ciudad">
              <span class="asterisco">*</span>
              Ciudad
            </label>
            <select
              formControlName="horecaCiudad"
              class="d-inline-block dropdown-form-1"
              [(ngModel)]="registroComp.ciudad"
              [ngClass]="{
                'invalid-warning':
                  horecaForm.get('horecaCiudad')?.hasError('required') && horecaForm.get('horecaCiudad')?.touched
              }">
              <option value="{{ city.name }}" *ngFor="let city of ciudades">{{ city.name }}</option>
            </select>
          </div>
          <div class="propietario-credenciales">
            <h5>Credenciales de acceso</h5>
          </div>
          <!--Correo-->
          <div class="propietario-email w-100">
            <label for="correo">
              <span class="asterisco">*</span>
              Correo (con este ingresas)
            </label>
            <input
              autocomplete="new-password"
              placeholder="Escribe aquí"
              type="email"
              [(ngModel)]="registroComp.correo"
              class="w-100"
              formControlName="horecaCorreo"
              [ngClass]="{
                'invalid-warning':
                  (horecaForm.get('horecaCorreo')?.hasError('required') && horecaForm.get('horecaCorreo')?.touched) ||
                  (horecaForm.get('horecaCorreo')?.hasError('email') && horecaForm.get('horecaCorreo')?.touched)
              }" />
            <span
              class="reactive-text text-danger"
              *ngIf="horecaForm.get('horecaCorreo')?.hasError('email') && horecaForm.get('horecaCorreo')?.touched">
              <span class="alerta">Ingresa un email valido</span>
            </span>
          </div>
          <!--Contraseña-->
          <div class="propietario-contraseña w-100">
            <label for="contrasena">
              <span class="asterisco">*</span>
              Contraseña
            </label>
            <div class="w-100 row m-0 rounded">
              <input
                autocomplete="new-password"
                [type]="fieldTextType ? 'text' : 'password'"
                id="password"
                name="password"
                placeholder="Escribe aquí"
                class="form-control input-pass"
                [(ngModel)]="registroComp.contrasena"
                formControlName="horecaContraseña"
                [ngClass]="{
                  'invalid-warning':
                    horecaForm.get('horecaContraseña')?.hasError('required') &&
                    horecaForm.get('horecaContraseña')?.touched
                }" />
              <span class="input-group-text">
                <fa-icon
                  [icon]="faEyeSlash"
                  size="1x"
                  [styles]="{ color: 'black' }"
                  (click)="mostrarOcultarPassword()"
                  *ngIf="fieldTextType"></fa-icon>
                <fa-icon
                  [icon]="faEye"
                  size="1x"
                  [styles]="{ color: 'black' }"
                  (click)="mostrarOcultarPassword()"
                  *ngIf="!fieldTextType"></fa-icon>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!--Paso 5-->
      <div *ngIf="registroComp.step == 5" class="formulario-juridico p-4" [formGroup]="juridicoForm">
        <div class="w-100 juridico-info">
          <h5>Sobre la empresa</h5>
        </div>
        <!--Nombre del establecimiento-->
        <div class="w-100 h-100 juridico-nombre-establecimiento">
          <label for="nombre establecimiento">
            <span class="asterisco">*</span>
            Nombre del establecimiento
          </label>
          <br />
          <input
            type="text"
            placeholder="Escribe aquí"
            [(ngModel)]="registroComp.nombre_establecimiento"
            formControlName="juridicoNombreEstablecimiento"
            [ngClass]="{
              'invalid-warning':
                juridicoForm.get('juridicoNombreEstablecimiento')?.hasError('required') &&
                juridicoForm.get('juridicoNombreEstablecimiento')?.touched
            }" />
        </div>
        <!--Tipo de negocio-->
        <div class="juridico-tipo">
          <label for="tipo_negocio">
            <span class="asterisco">*</span>
            Tipo de negocio
          </label>
          <select
            formControlName="juridicoEstablecimiento"
            class="d-inline-block dropdown-form-1"
            [(ngModel)]="registroComp.tipo_negocio"
            [ngClass]="{
              'invalid-warning':
                juridicoForm.get('juridicoEstablecimiento')?.hasError('required') &&
                juridicoForm.get('juridicoEstablecimiento')?.touched
            }">
            <option value="BAR / DISCOTECA">BAR / DISCOTECA</option>
            <option value="CAFETERÍA / HELADERÍA / SNACK">CAFETERÍA / HELADERÍA / SNACK</option>
            <option value="CATERING SERVICE / SERVICIO ALIMENTACIÓN">CATERING SERVICE / SERVICIO ALIMENTACIÓN</option>
            <option value="COCINA OCULTA">COCINA OCULTA</option>
            <option value="CENTRO DE DIVERSIÓN">CENTRO DE DIVERSIÓN</option>
            <option value="CENTRO DEPORTIVO Y GIMNASIOS">CENTRO DEPORTIVO Y GIMNASIOS</option>
            <option value="CLUB SOCIAL / NEGOCIOS">CLUB SOCIAL / NEGOCIOS</option>
            <option value="COMEDOR DE EMPLEADOS">COMEDOR DE EMPLEADOS</option>
            <option value="COMIDA RÁPIDA">COMIDA RÁPIDA</option>
            <option value="HOGAR">HOGAR</option>
            <option value="MAYORISTA / MINORISTA">MAYORISTA / MINORISTA</option>
            <option value="OFICINA / COWORKING">OFICINA / COWORKING</option>
            <option value="PANADERÍA / REPOSTERÍA">PANADERÍA / REPOSTERÍA</option>
            <option value="PROPIEDAD HORIZONTAL">PROPIEDAD HORIZONTAL</option>
            <option value="RESTAURANTE">RESTAURANTE</option>
            <option value="RESTAURANTE DE CADENA">RESTAURANTE DE CADENA</option>
            <option value="SECTOR EDUCACIÓN">SECTOR EDUCACIÓN</option>
            <option value="SECTOR HOTELERO">SECTOR HOTELERO</option>
            <option value="SECTOR SALUD">SECTOR SALUD</option>
            <option value="SECTOR PÚBLICO / PRIVADO">SECTOR PÚBLICO / PRIVADO</option>
          </select>
        </div>
        <!--Razón social-->
        <div class="w-100 h-100 juridico-razon" *ngIf="registroComp.tipo_persona == 'Jurídica'">
          <label for="razón social">
            <span class="asterisco">*</span>
            Razón social
          </label>
          <input
            placeholder="Escribe aquí"
            [(ngModel)]="registroComp.razon_social"
            formControlName="juridicoRazon"
            [ngClass]="{
              'invalid-warning': registroComp.razon_social == '' && juridicoForm.get('juridicoRazon')?.touched
            }" />
        </div>
        <!--NIT-->
        <div class="w-100 h-100 juridico-nit" *ngIf="registroComp.tipo_persona == 'Jurídica'">
          <label for="nit">
            <span class="asterisco">*</span>
            NIT
          </label>
          <input
            type="text"
            placeholder="Escribe aquí"
            (keydown)="validateNumber($event)"
            [(ngModel)]="registroComp.nit"
            formControlName="juridicoNit"
            [ngClass]="{ 'invalid-warning': registroComp.nit == '' && juridicoForm.get('juridicoNit')?.touched }" />
        </div>
        <!--País-->
        <div class="w-100 h-100 juridico-pais">
          <label for="país">
            <span class="asterisco">*</span>
            País
          </label>
          <br />
          <select
            formControlName="juridicoPais"
            class="d-inline-block dropdown-form-1"
            [(ngModel)]="registroComp.pais_empresa"
            [ngClass]="{
              'invalid-warning':
                juridicoForm.get('juridicoPais')?.hasError('required') && juridicoForm.get('juridicoPais')?.touched
            }">
            <option selected disabled value="Colombia">Colombia</option>
          </select>
        </div>
        <!--Departamento-->
        <div class="w-100 h-100 juridico-departamento">
          <label for="departamento">
            <span class="asterisco">*</span>
            Departamento
          </label>
          <br />
          <select
            formControlName="juridicoDepartamento"
            class="d-inline-block dropdown-form-1"
            [(ngModel)]="registroComp.departamento_empresa"
            (change)="getPlaces('juridico')"
            (change)="registroComp.ciudad_empresa = ''"
            [ngClass]="{
              'invalid-warning':
                juridicoForm.get('juridicoDepartamento')?.hasError('required') &&
                juridicoForm.get('juridicoDepartamento')?.touched
            }">
            <option [ngValue]="dpto.name" *ngFor="let dpto of places.departments_colombia">
              {{ dpto.name }}
            </option>
          </select>
        </div>
        <!--Ciudad-->
        <div class="w-100 h-100 juridico-ciudad">
          <label for="ciudad">
            <span class="asterisco">*</span>
            Ciudad
          </label>
          <br />
          <select
            formControlName="juridicoCiudad"
            class="d-inline-block dropdown-form-1"
            [(ngModel)]="registroComp.ciudad_empresa"
            [ngClass]="{
              'invalid-warning':
                juridicoForm.get('juridicoCiudad')?.hasError('required') && juridicoForm.get('juridicoCiudad')?.touched
            }">
            <option value="{{ city.name }}" *ngFor="let city of ciudades_empresa">{{ city.name }}</option>
          </select>
        </div>
        <!--Teléfono empresa 1-->
        <div class="w-100 h-100 juridico-etelefono">
          <label for="celular">
            <span class="asterisco">*</span>
            Teléfono empresa (7 o 10 dígitos)
          </label>
          <input
            type="number"
            placeholder="Escribe aquí"
            [(ngModel)]="registroComp.telefono_empresa_1"
            formControlName="juridicoTelefono"
            (keydown)="validateNumber($event)"
            [ngClass]="{
              'invalid-warning':
                (juridicoForm.get('juridicoTelefono')?.hasError('required') &&
                  juridicoForm.get('juridicoTelefono')?.touched) ||
                (juridicoForm.get('juridicoTelefono')?.hasError('pattern') &&
                  juridicoForm.get('juridicoTelefono')?.touched)
            }" />
          <span
            class="w-100 text-danger fw-bold text-right"
            *ngIf="
              juridicoForm.get('juridicoTelefono')?.hasError('pattern') && juridicoForm.get('juridicoTelefono')?.touched
            ">
            <span class="alerta">Númerico de 7 o 10 dígitos</span>
          </span>
        </div>
        <!--Teléfono emrpesa 2-->
        <div class="w-100 h-100 juridico-etelefono-2">
          <label for="telefono">Teléfono empresa 2 (opcional)</label>
          <input
            type="number"
            placeholder="Escribe aquí"
            formControlName="juridicoTelefono2"
            (keydown)="validateNumber($event)"
            [(ngModel)]="registroComp.telefono_empresa_2"
            class="w-100"
            [ngClass]="{
              'invalid-warning':
                this.registroComp.telefono_empresa_2 != null &&
                this.registroComp.telefono_empresa_2 != 0 &&
                this.registroComp.telefono_empresa_2.toString().length >= 1 &&
                juridicoForm.get('juridicoTelefono2')?.touched &&
                this.registroComp.telefono_empresa_2.toString().length != 7 &&
                this.registroComp.telefono_empresa_2.toString().length != 10
            }" />
          <span
            class="reactive-text text-danger"
            *ngIf="
              this.registroComp.telefono_empresa_2 != null &&
              this.registroComp.telefono_empresa_2 != 0 &&
              this.registroComp.telefono_empresa_2.toString().length >= 1 &&
              juridicoForm.get('juridicoTelefono2')?.touched &&
              this.registroComp.telefono_empresa_2.toString().length != 7 &&
              this.registroComp.telefono_empresa_2.toString().length != 10
            ">
            <span class="alerta">Númerico de 7 o 10 dígitos</span>
          </span>
        </div>
        <!--Información del propietario-->
        <div class="w-100 h-100 mt-2 juridico-info-propietario">
          <h5>Información del propietario o representante legal</h5>
          <p
            class="subtitulo-juridico p-0 m-0 cursorPointer"
            (click)="soy_propietario = !soy_propietario"
            (click)="soy_propietario && duplicarDatosPropeitario(true)"
            (click)="!soy_propietario && borrarDuplicadoDatosPropeitario()">
            {{
              soy_propietario
                ? 'No soy el propietario o representante legal'
                : 'Yo soy el propietario o representante legal'
            }}
          </p>
        </div>
        <!--Tipo de documento-->
        <div class="w-100 h-100 juridico-propietario-documento">
          <label for="tipo documento">
            <span class="asterisco">*</span>
            Tipo de documento de identidad
          </label>
          <select
            formControlName="juridicoPropietarioTipo"
            class="d-inline-block dropdown-form-1"
            [(ngModel)]="registroComp.tipo_doc_identidad_prop"
            (change)="registroComp.doc_identidad_prop = ''"
            [ngClass]="{
              'invalid-warning':
                juridicoForm.get('juridicoPropietarioTipo')?.hasError('required') &&
                juridicoForm.get('juridicoPropietarioTipo')?.touched
            }">
            <option value="Cédula de ciudadanía">Cédula de ciudadanía</option>
            <option value="Cédula de extranjería">Cédula de extranjería</option>
            <option value="Pasaporte">Pasaporte</option>
          </select>
        </div>
        <!--Número de documento-->
        <div class="w-100 h-100 juridico-propietario-cedula">
          <label for="documento">
            <span class="asterisco">*</span>
            Número de documento de identidad
          </label>
          <input
            *ngIf="registroComp.tipo_doc_identidad_prop === 'Pasaporte'"
            placeholder="Escribe aquí"
            [(ngModel)]="registroComp.doc_identidad_prop"
            formControlName="juridicoPropietarioCedula"
            (keydown)="comparaIDTrabajadorYPropietario()"
            [ngClass]="{
              'invalid-warning':
                juridicoForm.get('juridicoPropietarioCedula')?.hasError('required') &&
                juridicoForm.get('juridicoPropietarioCedula')?.touched
            }" />
          <input
            *ngIf="registroComp.tipo_doc_identidad_prop !== 'Pasaporte'"
            placeholder="Escribe aquí"
            [(ngModel)]="registroComp.doc_identidad_prop"
            formControlName="juridicoPropietarioCedula"
            (keydown)="comparaIDTrabajadorYPropietario()"
            (keydown)="validateNumber($event)"
            [ngClass]="{
              'invalid-warning':
                juridicoForm.get('juridicoPropietarioCedula')?.hasError('required') &&
                juridicoForm.get('juridicoPropietarioCedula')?.touched
            }" />
        </div>
        <!--Nombres-->
        <div class="w-100 h-100 juridico-propietario-nombre">
          <label for="nombres">
            <span class="asterisco">*</span>
            Nombres
          </label>
          <input
            placeholder="Escribe aquí"
            [(ngModel)]="registroComp.nombres_prop"
            formControlName="juridicoPropietarioNombre"
            (keydown)="validateOnlyText($event)"
            [ngClass]="{
              'invalid-warning':
                juridicoForm.get('juridicoPropietarioNombre')?.hasError('required') &&
                juridicoForm.get('juridicoPropietarioNombre')?.touched
            }" />
        </div>
        <!--Apellidos-->
        <div class="w-100 h-100 juridico-propietario-apellido">
          <label for="apellidos">
            <span class="asterisco">*</span>
            Apellidos
          </label>
          <input
            placeholder="Escribe aquí"
            [(ngModel)]="registroComp.apellidos_prop"
            formControlName="juridicoPropietarioApellido"
            (keydown)="validateOnlyText($event)"
            [ngClass]="{
              'invalid-warning':
                juridicoForm.get('juridicoPropietarioApellido')?.hasError('required') &&
                juridicoForm.get('juridicoPropietarioApellido')?.touched
            }" />
        </div>
        <!--Celular-->
        <div class="w-100 h-100 juridico-propietario-telefono">
          <label for="documento">
            <span class="asterisco">*</span>
            Teléfono propietario (7 o 10 dígitos)
          </label>
          <input
            placeholder="Escribe aquí"
            [(ngModel)]="registroComp.telefono_prop"
            formControlName="juridicoPropietarioTelefono"
            (keydown)="validateNumber($event)"
            [ngClass]="{
              'invalid-warning':
                (juridicoForm.get('juridicoPropietarioTelefono')?.hasError('required') &&
                  juridicoForm.get('juridicoPropietarioTelefono')?.touched) ||
                (juridicoForm.get('juridicoPropietarioTelefono')?.hasError('pattern') &&
                  juridicoForm.get('juridicoPropietarioTelefono')?.touched)
            }" />
          <span
            class="w-100 text-danger fw-bold text-right"
            *ngIf="
              juridicoForm.get('juridicoPropietarioTelefono')?.hasError('pattern') &&
              juridicoForm.get('juridicoPropietarioTelefono')?.touched
            ">
            <span class="alerta">Númerico de 7 o 10 dígitos</span>
          </span>
        </div>
        <!--Correo-->
        <div class="w-100 h-100 juridico-propietario-correo">
          <label for="correo">
            <span class="asterisco">*</span>
            Correo
          </label>
          <input
            placeholder="Escribe aquí"
            type="email"
            [(ngModel)]="registroComp.correo_prop"
            formControlName="juridicoPropietarioCorreo"
            [ngClass]="{
              'invalid-warning':
                (juridicoForm.get('juridicoPropietarioCorreo')?.hasError('required') &&
                  juridicoForm.get('juridicoPropietarioCorreo')?.touched) ||
                (juridicoForm.get('juridicoPropietarioCorreo')?.hasError('email') &&
                  juridicoForm.get('juridicoPropietarioCorreo')?.touched)
            }" />
          <span
            class="reactive-text text-danger"
            *ngIf="
              juridicoForm.get('juridicoPropietarioCorreo')?.hasError('email') &&
              juridicoForm.get('juridicoPropietarioCorreo')?.touched
            ">
            <span class="alerta">Ingresa un email valido</span>
          </span>
        </div>
      </div>
      <!--Paso 6-->
      <div *ngIf="registroComp.step == 6">
        <div class="w-100">
          <div class="card p-4">
            <div class="w-100 mb-3">
              <h5>
                Carga los siguientes documentos para la verificación
                <h6 class="text-secondary font-weight-light">Máximo 2MB por archivo</h6>
              </h5>
            </div>
            <div class="document-upload-container row text-center p-4">
              <!--RUT-->
              <div class="upload-rut">
                <input type="file" class="file-upload" (change)="handleFileInput($event, 0)" #fileUploadRUT />
                <button
                  class="btn-upload h-100 d-flex flex-column align-items-center justify-content-start pt-4"
                  (click)="fileUploadRUT.click()">
                  <fa-icon
                    [icon]="faUpload"
                    size="2x"
                    [styles]="{ color: '#41403E' }"
                    class="d-flex justify-content-center mb-2 w-100"></fa-icon>
                  RUT
                  <div *ngIf="registroComp.archivos[0] != null">
                    <p class="break-word">
                      <strong>
                        {{ registroComp.archivos[0].name }}
                      </strong>
                    </p>
                  </div>
                </button>
              </div>
              <!--Cámara de comercio-->
              <div class="upload-camara">
                <input type="file" class="file-upload" (change)="handleFileInput($event, 1)" #fileUploadCamara />
                <button
                  class="btn-upload h-100 d-flex flex-column align-items-center justify-content-start pt-4"
                  (click)="fileUploadCamara.click()">
                  <fa-icon
                    [icon]="faUpload"
                    size="2x"
                    [styles]="{ color: '#41403E' }"
                    class="d-flex justify-content-center mb-2 w-100"></fa-icon>
                  Cámara de comercio {{ registroComp.tipo_persona == 'Natural' ? '(opcional)' : '' }}
                  <div *ngIf="registroComp.archivos[1] != null">
                    <p class="break-word">
                      <strong>
                        {{ registroComp.archivos[1].name }}
                      </strong>
                    </p>
                  </div>
                </button>
              </div>
              <!--Documento de representante legal-->
              <div class="upload-representante">
                <input type="file" class="file-upload" (change)="handleFileInput($event, 2)" #fileUploadRepresentante />
                <button
                  class="btn-upload h-100 d-flex flex-column align-items-center justify-content-start pt-4"
                  (click)="fileUploadRepresentante.click()">
                  <fa-icon
                    [icon]="faUpload"
                    size="2x"
                    [styles]="{ color: '#41403E' }"
                    class="d-flex justify-content-center mb-2 w-100"></fa-icon>
                  Documento representante legal
                  <div *ngIf="registroComp.archivos[2] != null">
                    <p class="break-word">
                      <strong>
                        {{ registroComp.archivos[2].name }}
                      </strong>
                    </p>
                  </div>
                </button>
              </div>
              <!--Logo-->
              <div class="upload-logo">
                <input type="file" class="file-upload" (change)="handleFileInput($event, 3)" #fileUploadLogo />
                <button class="btn-upload" (click)="fileUploadLogo.click()">
                  <fa-icon
                    [icon]="faUpload"
                    size="2x"
                    [styles]="{ color: '#41403E' }"
                    class="d-flex justify-content-center mb-2 w-100"></fa-icon>
                  Logo
                  <div *ngIf="logo_path != null">
                    <img id="logo" [src]="logo_path" alt="Logo" height="100px" />
                  </div>
                </button>
              </div>
            </div>
            <!--Aceptó términos y condiciones-->
            <div class="w-100">
              <input
                type="checkbox"
                id="acepto_terminos"
                name="acepto_terminos"
                [(ngModel)]="registroComp.acepto_terminos" />
              <label for="t_acepto_terminos" class="col-11 text-dark">
                Acepto los
                <a
                  class="link-terminos cursorPointer"
                  (click)="verDocumento(registroComp.politicas_condiciones[0].establecimientos.terminos)">
                  Términos y Condiciones
                </a>
                y
                <a
                  class="link-terminos cursorPointer"
                  (click)="verDocumento(registroComp.politicas_condiciones[0].establecimientos.politicas)">
                  Políticas de Privacidad
                </a>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Trabajador-->
    <div *ngIf="registroComp.horeca_perfil_seleccionado == 'Trabajador'">
      <div *ngIf="registroComp.step == 4">
        <div class="formulario-establecimiento p-4" [formGroup]="establecimientoForm">
          <!--Card de resumen de empresa-->
          <div class="card establecimiento-resumen" id="resumen-empresa">
            <div class="card-body row">
              <div class="col-3 d-flex align-items-center justify-content-center">
                <!--Logo de la empresa-->
                <img
                  [src]="registroComp.t_horeca.logo || distribuidor_placeholder"
                  alt="Logo de la empresa"
                  width="auto"
                  height="80px" />
              </div>
              <div class="col col-9 d-flex flex-column justify-content-center">
                <div>
                  {{ registroComp.t_horeca.nombre_establecimiento }}
                </div>
                <div>CC/NIT: {{ registroComp.t_horeca.nit }}</div>
              </div>
            </div>
          </div>
          <!--Tipo de documento de identidad-->
          <div class="establecimiento-documento w-100">
            <label for="t tipo documento">
              <span class="asterisco">*</span>
              Tipo de documento de identidad
            </label>
            <br />
            <select
              formControlName="establecimientoTipo"
              class="d-inline-block dropdown-form-1"
              [(ngModel)]="registroComp.t_tipo_doc_identidad"
              (change)="registroComp.t_doc_identidad = ''"
              [ngClass]="{
                'invalid-warning':
                  establecimientoForm.get('establecimientoTipo')?.hasError('required') &&
                  establecimientoForm.get('establecimientoTipo')?.touched
              }">
              <option value="Cédula de ciudadanía">Cédula de ciudadanía</option>
              <option value="Cédula de extranjería">Cédula de extranjería</option>
              <option value="Pasaporte">Pasaporte</option>
            </select>
          </div>
          <!--Número de documento de identidad-->
          <div class="establecimiento-cedula w-100">
            <label for="t documento">
              <span class="asterisco">*</span>
              Número de documento de identidad
            </label>
            <input
              *ngIf="registroComp.t_tipo_doc_identidad === 'Pasaporte'"
              placeholder="Escribe aquí"
              [(ngModel)]="registroComp.t_doc_identidad"
              class="w-100 input-nit"
              formControlName="establecimientoCedula"
              [ngClass]="{
                'invalid-warning':
                  establecimientoForm.get('establecimientoCedula')?.hasError('required') &&
                  establecimientoForm.get('establecimientoCedula')?.touched
              }" />
            <input
              *ngIf="registroComp.t_tipo_doc_identidad !== 'Pasaporte'"
              placeholder="Escribe aquí"
              [(ngModel)]="registroComp.t_doc_identidad"
              class="w-100 input-nit"
              formControlName="establecimientoCedula"
              (keydown)="validateNumber($event)"
              [ngClass]="{
                'invalid-warning':
                  establecimientoForm.get('establecimientoCedula')?.hasError('required') &&
                  establecimientoForm.get('establecimientoCedula')?.touched
              }" />
            <span
              class="w-100 text-danger fw-bold text-right"
              *ngIf="
                establecimientoForm.get('establecimientoCedula')?.hasError('pattern') &&
                establecimientoForm.get('establecimientoCedula')?.touched
              ">
              <span class="alerta">Numérico mayor a 5 dígitos</span>
            </span>
          </div>
          <!--Nombres-->
          <div class="establecimiento-nombre w-100">
            <label for="t nombres">
              <span class="asterisco">*</span>
              Nombres
            </label>
            <input
              placeholder="Escribe aquí"
              [(ngModel)]="registroComp.t_nombres"
              class="w-100 input-nit"
              formControlName="establecimientoNombre"
              (keydown)="validateOnlyText($event)"
              [ngClass]="{
                'invalid-warning':
                  establecimientoForm.get('establecimientoNombre')?.hasError('required') &&
                  establecimientoForm.get('establecimientoNombre')?.touched
              }" />
          </div>
          <!--Apellidos-->
          <div class="establecimiento-apellido w-100">
            <label for="t apellidos">
              <span class="asterisco">*</span>
              Apellidos
            </label>
            <input
              placeholder="Escribe aquí"
              [(ngModel)]="registroComp.t_apellidos"
              class="w-100 input-nit"
              formControlName="establecimientoApellido"
              (keydown)="validateOnlyText($event)"
              [ngClass]="{
                'invalid-warning':
                  establecimientoForm.get('establecimientoApellido')?.hasError('required') &&
                  establecimientoForm.get('establecimientoApellido')?.touched
              }" />
          </div>
          <!--País-->
          <div class="establecimiento-pais w-100">
            <label for="país">
              <span class="asterisco">*</span>
              País
            </label>
            <br />
            <select
              formControlName="establecimientoPais"
              class="d-inline-block dropdown-form-1"
              [(ngModel)]="registroComp.t_pais"
              [ngClass]="{
                'invalid-warning':
                  establecimientoForm.get('establecimientoPais')?.hasError('required') &&
                  establecimientoForm.get('establecimientoPais')?.touched
              }">
              <option selected disabled value="Colombia">Colombia</option>
            </select>
          </div>
          <!--Departamento-->
          <div class="establecimiento-departamento w-100">
            <label for="departamento">
              <span class="asterisco">*</span>
              Departamento
            </label>
            <select
              formControlName="establecimientoDepartamento"
              class="d-inline-block dropdown-form-1"
              [(ngModel)]="registroComp.t_departamento"
              (change)="getPlaces('trabajador')"
              (change)="registroComp.t_ciudad = ''"
              [ngClass]="{
                'invalid-warning':
                  establecimientoForm.get('establecimientoDepartamento')?.hasError('required') &&
                  establecimientoForm.get('establecimientoDepartamento')?.touched
              }">
              <option [ngValue]="dpto.name" *ngFor="let dpto of places.departments_colombia">
                {{ dpto.name }}
              </option>
            </select>
          </div>
          <!--Ciudad-->
          <div class="establecimiento-ciudad w-100">
            <label for="ciudad">
              <span class="asterisco">*</span>
              Ciudad
            </label>
            <select
              formControlName="establecimientoCiudad"
              class="d-inline-block dropdown-form-1"
              [(ngModel)]="registroComp.t_ciudad"
              [ngClass]="{
                'invalid-warning':
                  establecimientoForm.get('establecimientoCiudad')?.hasError('required') &&
                  establecimientoForm.get('establecimientoCiudad')?.touched
              }">
              <option value="{{ city.name }}" *ngFor="let city of ciudades_trabajador">{{ city.name }}</option>
            </select>
          </div>
          <!--Celular-->
          <div class="establecimiento-celular w-100">
            <label for="celular">
              <span class="asterisco">*</span>
              Celular (10 dígitos)
            </label>
            <input
              type="number"
              placeholder="Escribe aquí"
              [(ngModel)]="registroComp.t_celular"
              class="w-100 input-nit"
              (keydown)="validateNumber($event)"
              formControlName="establecimientoCelular"
              [ngClass]="{
                'invalid-warning':
                  (establecimientoForm.get('establecimientoCelular')?.hasError('required') &&
                    establecimientoForm.get('establecimientoCelular')?.touched) ||
                  (establecimientoForm.get('establecimientoCelular')?.hasError('pattern') &&
                    establecimientoForm.get('establecimientoCelular')?.touched)
              }" />
            <span
              class="reactive-text text-danger"
              *ngIf="
                establecimientoForm.get('establecimientoCelular')?.hasError('pattern') &&
                establecimientoForm.get('establecimientoCelular')?.touched
              ">
              <span class="alerta">Númerico de 10 dígitos</span>
            </span>
          </div>
          <!--Teléfono-->
          <div class="establecimiento-telefono w-100">
            <label for="telefono">Teléfono (opcional)</label>
            <input
              type="number"
              placeholder="Escribe aquí"
              [(ngModel)]="registroComp.t_telefono"
              (keydown)="validateNumber($event)"
              [ngClass]="{
                'invalid-warning':
                  registroComp.t_telefono != null &&
                  registroComp.t_telefono.toString().length >= 1 &&
                  establecimientoForm.get('establecimientoTelefono')?.touched &&
                  registroComp.t_telefono.toString().length != 7 &&
                  registroComp.t_telefono.toString().length != 10
              }"
              class="w-100 input-nit"
              formControlName="establecimientoTelefono" />
            <span
              class="reactive-text text-danger"
              *ngIf="
                registroComp.t_telefono != null &&
                registroComp.t_telefono.toString().length >= 1 &&
                establecimientoForm.get('establecimientoTelefono')?.touched &&
                registroComp.t_telefono.toString().length != 7 &&
                registroComp.t_telefono.toString().length != 10
              ">
              <span class="alerta">Númerico de 7 o 10 dígitos</span>
            </span>
          </div>
          <!--Tipo de usuario-->
          <div class="establecimiento-tipo w-100">
            <label for="tipo_usuario">
              <span class="asterisco">*</span>
              Tipo de usuario
            </label>
            <select
              formControlName="establecimientoEstablecimiento"
              class="d-inline-block dropdown-form-1"
              [(ngModel)]="registroComp.t_tipo_usuario"
              [ngClass]="{
                'invalid-warning':
                  establecimientoForm.get('establecimientoCiudad')?.hasError('required') &&
                  establecimientoForm.get('establecimientoEstablecimiento')?.touched
              }">
              <option value="ADMINISTRADOR APROBADOR">ADMINISTRADOR APROBADOR</option>
              <option value="PLANEADOR PEDIDOS">PLANEADOR PEDIDOS</option>
            </select>
          </div>
        </div>
      </div>
      <div *ngIf="registroComp.step == 5">
        <div class="formulario-establecimiento-credenciales row p-4 m-0" [formGroup]="establecimientoCredencialesForm">
          <div class="establecimiento-credenciales w-100">
            <h5>Ingresa las credenciales con las cuales ingresarás a la plataforma</h5>
          </div>
          <!--Correo-->
          <div class="establecimiento-correo w-100">
            <label for="t correo" class="w-100">
              <span class="asterisco">*</span>
              Correo (con este ingresas)
            </label>
            <input
              autocomplete="new-password"
              placeholder="Escribe aquí"
              type="email"
              autocomplete="nope"
              [(ngModel)]="registroComp.t_correo"
              class="w-100 mt-0 input-nit"
              formControlName="establecimientoCorreo"
              [ngClass]="{
                'invalid-warning':
                  (establecimientoCredencialesForm.get('establecimientoCorreo')?.hasError('required') &&
                    establecimientoCredencialesForm.get('establecimientoCorreo')?.touched) ||
                  (establecimientoCredencialesForm.get('establecimientoCorreo')?.hasError('email') &&
                    establecimientoCredencialesForm.get('establecimientoCorreo')?.touched)
              }" />
            <span
              class="reactive-text text-danger"
              *ngIf="
                establecimientoCredencialesForm.get('establecimientoCorreo')?.hasError('email') &&
                establecimientoCredencialesForm.get('establecimientoCorreo')?.touched
              ">
              <span class="alerta">Ingresa un email valido</span>
            </span>
          </div>
          <!--Contraseña-->
          <div class="establecimiento-contraseña w-100">
            <label for="t contrasena" class="w-100">
              <span class="asterisco">*</span>
              Contraseña
            </label>
            <div
              class="w-100 row m-0 rounded"
              [ngClass]="{
                'invalid-warning':
                  establecimientoCredencialesForm.get('establecimientoContraseña')?.hasError('required') &&
                  establecimientoCredencialesForm.get('establecimientoContraseña')?.touched
              }">
              <input
                [type]="fieldTextType ? 'text' : 'password'"
                autocomplete="new-password"
                id="password"
                name="password"
                placeholder="Escribe aquí"
                class="form-control input-pass"
                [(ngModel)]="registroComp.t_contrasena"
                formControlName="establecimientoContraseña"
                style="height: 42px !important" />
              <span class="input-group-text" style="height: 42px !important">
                <fa-icon
                  [icon]="faEyeSlash"
                  size="1x"
                  [styles]="{ color: 'black' }"
                  (click)="mostrarOcultarPassword()"
                  *ngIf="fieldTextType"></fa-icon>
                <fa-icon
                  [icon]="faEye"
                  size="1x"
                  [styles]="{ color: 'black' }"
                  (click)="mostrarOcultarPassword()"
                  *ngIf="!fieldTextType"></fa-icon>
              </span>
            </div>
          </div>
          <!--Aceptó términos y condiciones-->
          <div class="establecimiento-terminos w-100 d-flex align-items-start">
            <input
              type="checkbox"
              id="t_acepto_terminos"
              name="t_acepto_terminos"
              [(ngModel)]="registroComp.t_acepto_terminos"
              class="col-1 text-center"
              formControlName="establecimientoTerminos"
              [ngClass]="{
                'invalid-warning':
                  establecimientoCredencialesForm.get('establecimientoTerminos')?.hasError('required') &&
                  establecimientoCredencialesForm.get('establecimientoTerminos')?.touched
              }" />
            <label for="t_acepto_terminos" class="col-11 text-dark">
              Acepto los
              <a
                class="link-terminos cursorPointer"
                (click)="verDocumento(registroComp.politicas_condiciones[0].establecimientos.terminos)">
                Términos y Condiciones
              </a>
              y
              <a
                class="link-terminos cursorPointer"
                (click)="verDocumento(registroComp.politicas_condiciones[0].establecimientos.politicas)">
                Políticas de Privacidad
              </a>
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
<!-- TEMPLATE TOAST FORUMARIO INVALIDO -->
<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="toast.classname"
  [autohide]="true"
  [delay]="toast.delay || 5000"
  (hidden)="toastService.remove(toast)">
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>
  <ng-template #text>{{ toast.textOrTpl }}</ng-template>
</ngb-toast>

<!-- BOTONOES DE NAVEGACIÓN -->
<div id="registro-nav" class="d-flex flex-row w-100 justify-content-around align-items-center py-4 fixed-bottom mediaQuery"
*ngIf="registroComp.step !== 1">
<button (click)="stepBack()" class="btn-volver">
  <fa-icon [icon]="faChevronLeft" size="lg" class="mr-2"></fa-icon>
  Paso anterior
</button>
<button
  (click)="isBotonHabilitado && registroComp.continue()"
  (click)="!isBotonHabilitado && checkIfFormIsInvalid()"
  Class="btn-continuar-enabled"
  [ngClass]="{ 'btn-continuar-disabled': !isBotonHabilitado }"
  *ngIf="!isFormularioCompleto">
  Continuar
</button>
<button Class="btn-continuar-enabled" *ngIf="isFormularioCompleto" (click)="registroComp.registroUsuarioHoreca()">
  Regístrate
</button>
 
</div>
