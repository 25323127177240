import { AgmMap, AgmMarker } from '@agm/core';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Chart } from 'chart.js';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';
import { GraficasService } from 'src/app/services/graficas-tablas/graficas.service';
import { MapService } from 'src/app/services/map/map.service';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-clientes-graficas',
  templateUrl: './clientes-graficas.component.html',
  styleUrls: ['./clientes-graficas.component.css'],
})
export class ClientesGraficasComponent implements OnInit {
  // ID organizacion
  public id_distribuidor = this.authService.user_distribuidor?._id;
  // Data filtros
  public filtro_anio: any[] = [];
  public filtro_cliente_mes: any;
  // Estados clientes
  public estado_pendiente: any;
  public estado_aprobado: any;
  public estado_cancelado: any;
  // Array de meses
  public meses: string[] = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  // Toda la coleccion de información para las graficas
  public data_graficas: any;
  // Biding HTML-Canvas y TS
  @ViewChild('bar_clientes_mes', { static: true })
  public bar_clientes_mes!: ElementRef;
  @ViewChild('bar_clientes_tipo', { static: true })
  public bar_clientes_tipo!: ElementRef;
  @ViewChild('bar_clientes_sillas', { static: true })
  public bar_clientes_sillas!: ElementRef;
  @ViewChild('bar_clientes_tipo_usuario', { static: true })
  public bar_clientes_tipo_usuario!: ElementRef;
  @ViewChild('bar_clientes_sillas_x_negocio', { static: true })
  public bar_clientes_sillas_x_negocio!: ElementRef;
  @ViewChild('bar_puntos_x_horeca', { static: true })
  public bar_puntos_x_horeca!: ElementRef;
  @ViewChild('pie_clientes_cartera', { static: true })
  public pie_clientes_cartera!: ElementRef;
  @ViewChild('pie_clientes_convenio', { static: true })
  public pie_clientes_convenio!: ElementRef;
  @ViewChild('pie_clientes_domicilio', { static: true })
  public pie_clientes_domicilio!: ElementRef;
  // Guarda datos de la talba y configuraciones
  public data_bar_clientes_mes: any;
  public data_bar_clientes_tipo: any;
  public data_bar_clientes_sillas: any;
  public data_bar_clientes_tipo_usuario: any;
  public data_bar_clientes_sillas_x_negocio: any;
  public data_bar_puntos_x_horeca: any;
  public data_pie_clientes_cartera: any;
  public data_pie_clientes_convenio: any;
  public data_pie_clientes_domicilio: any;
  public bar_chart_clientes_mes: any;
  public bar_chart_clientes_tipo: any;
  public bar_chart_clientes_sillas: any;
  public bar_chart_clientes_tipo_usuario: any;
  public bar_chart_clientes_sillas_x_negocio: any;
  public bar_chart_puntos_x_horeca: any;
  public pie_chart_clientes_cartera: any;
  public pie_chart_clientes_convenio: any;
  public pie_chart_clientes_domicilio: any;
  // Total grafica
  public total_clientes_mes = 0;
  public total_clientes_tipo = 0;
  public total_clientes_sillas = 0;
  public total_clientes_tipo_usuario = 0;
  public total_clientes_sillas_x_negocio = 0;
  public total_puntos_x_horeca = 0;
  public total_clientes_cartera = 0;
  public total_clientes_convenio = 0;
  public total_clientes_domicilio = 0;
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };
  // Estados
  public data: any;
  public selectedClient: any;
  public anio: number = new Date().getFullYear();
  // Variables de control para el mapa
  public map: any = { lat: 4.678508639544325, lng: -74.05550588007192 };
  public currentCoords: any = Object.assign({}, this.map);
  public mapaClientes: any;
  // Mapa
  public coord_dist: any;
  public establecimientos: any[] = [];
  public locations_puntos_entrega: any[] = [];
  public punto_seleccionado_nombre = '';
  public punto_seleccionado_direccion = '';

  constructor(
    private mapService: MapService,
    private distribuidorService: DistribuidorService,
    private restService: RestService,
    private graficasService: GraficasService,
    private ngxsStore: Store,
    private modalService: NgbModal,
    private router: Router,
    private authService: AuthService
  ) {
    this.data_bar_clientes_mes = {
      labels: [],
      datasets: [],
    };
    this.data_bar_clientes_tipo = {
      labels: [],
      datasets: [],
    };
    this.data_bar_clientes_sillas = {
      labels: [],
      datasets: [],
    };
    this.data_bar_clientes_tipo_usuario = {
      labels: [],
      datasets: [],
    };
    this.data_bar_clientes_sillas_x_negocio = {
      labels: [],
      datasets: [],
    };
    this.data_bar_puntos_x_horeca = {
      labels: [],
      datasets: [],
    };
    this.data_pie_clientes_cartera = {
      labels: [],
      datasets: [],
    };
    this.data_pie_clientes_convenio = {
      labels: [],
      datasets: [],
    };
    this.data_pie_clientes_domicilio = {
      labels: [],
      datasets: [],
    };
  }

  async ngOnInit() {
    this.id_distribuidor = await this.ngxsStore.snapshot().auth.user_distribuidor._id;
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    try {
      await this.getListaAnios();
      await this.getData();
      await this.getConsultaFiltroClientesMes();
      await this.cargarMapa();
      this.modalCarga?.close();
    } catch (error) {
      console.log(error);
      this.mostrarMensajeError();
    }
  }

  /**
   * Arma lista de los últimos 12 años para el filtro
   */
  getListaAnios() {
    const fecha_actual = new Date();
    const ano_actual = fecha_actual.getFullYear();
    for (let index = 0; index < 12; index++) {
      this.filtro_anio.push(ano_actual - index);
    }
    this.filtro_cliente_mes = this.filtro_anio[0];
  }

  /**
   * Recupera toda la data para las graficas
   */
  async getData() {
    await this.restService
      .getJWT(
        `informes/distribuidor/clientes/graficas/${this.id_distribuidor}/${this.filtro_cliente_mes}-01-01/${this.filtro_cliente_mes}-12-31`
      )
      .toPromise()
      .then((resp: any) => {
        if (resp.data[0]) {
          this.data_graficas = resp.data[0];
        }
      })
      .catch((err) => {
        throw err;
      });
    // Grafica de sillas viene en otra consulta pero se agrega a arreglo general
    await this.restService
      .getJWT(`informes/distribuidor/clientes/graficas/sillas/${this.id_distribuidor}`)
      .toPromise()
      .then((resp: any) => {
        if (resp.data[0].cant_sillas) {
          this.data_graficas.clientes_sillas = resp.data[0].cant_sillas;
        }
      })
      .catch((err) => {
        throw err;
      });
    // Grafica de puntos por establecimientos viene en otra consulta pero se agrega a arreglo general
    await this.restService
      .getJWT(`informes/distribuidor/clientes/graficas/puntos_por_establecimiento/${this.id_distribuidor}`)
      .toPromise()
      .then((resp: any) => {
        if (resp.data[0]) {
          this.data_graficas.clientes_horeca = resp.data[0];
        }
      })
      .catch((err) => {
        throw err;
      });
    if (this.data_graficas) {
      this.ordenarDataCharts();
    }
  }

  /**
   * Organiza la data recuperada del back en ids y totales
   * según cada tipo de grafica y la información que require
   * para así ser enviada a Chart JS como datasets y labels
   */
  ordenarDataCharts() {
    // Estados
    if (this.data_graficas.clientes_estado) {
      this.estado_aprobado = this.data_graficas.clientes_estado.find((element: any) => element._id === 'Aprobado');
      this.estado_aprobado = this.estado_aprobado && this.estado_aprobado.total ? this.estado_aprobado.total : 0;
      this.estado_pendiente = this.data_graficas.clientes_estado.find((element: any) => element._id === 'Pendiente');
      this.estado_pendiente = this.estado_pendiente && this.estado_pendiente.total ? this.estado_pendiente.total : 0;
      this.estado_cancelado = this.data_graficas.clientes_estado.find((element: any) => element._id === 'Cancelado');
      this.estado_cancelado = this.estado_cancelado && this.estado_cancelado.total ? this.estado_cancelado.total : 0;
    }
    // Clientes sillas
    if (this.data_graficas.clientes_sillas.length > 0) {
      const insertArray = {
        label: '0',
        count: 0,
      };
      this.data_graficas.clientes_sillas.unshift(insertArray);
      let i = 0;
      this.data_graficas.clientes_sillas.forEach((element: any) => {
        this.data_bar_clientes_sillas.labels.push(element.label);
        this.data_bar_clientes_sillas.datasets.push(element.count);
        this.total_clientes_sillas += element.count;
        if (i === 7 && this.data_graficas.clientes_cartera.length > 0) {
          if (this.data_graficas.clientes_cartera[0].total !== this.total_clientes_sillas) {
            const faltantes = this.data_graficas.clientes_cartera[0].total - this.total_clientes_sillas;
            this.data_bar_clientes_sillas.datasets[0] = faltantes;
            this.total_clientes_sillas = this.total_clientes_sillas + faltantes;
          }
        }
        i++;
      });
    }
    // Tipos de negocio
    if (this.data_graficas.clientes_tipo.length > 0) {
      this.data_graficas.clientes_tipo.forEach((element: any) => {
        this.data_bar_clientes_tipo.labels.push(element.label);
        this.data_bar_clientes_tipo.datasets.push(element.total);
        this.total_clientes_tipo += element.total;
      });
    }
    // Tipos usuario
    if (this.data_graficas.clientes_tipo_usuario.length > 0) {
      this.data_graficas.clientes_tipo_usuario.forEach((element: any) => {
        this.data_bar_clientes_tipo_usuario.labels.push(element.label);
        this.data_bar_clientes_tipo_usuario.datasets.push(element.total);
        this.total_clientes_tipo_usuario += element.total;
      });
    }
    // Sillas por tipo negocio
    if (this.data_graficas.clientes_sillas_x_negocio.length > 0) {
      this.data_graficas.clientes_sillas_x_negocio.forEach((element: any) => {
        this.data_bar_clientes_sillas_x_negocio.labels.push(element.label);
        this.data_bar_clientes_sillas_x_negocio.datasets.push(element.total);
        this.total_clientes_sillas_x_negocio += element.total;
      });
    }
    // Puntos por horeca
    if (this.data_graficas.clientes_horeca) {
      this.data_bar_puntos_x_horeca.labels = ['1', '2', '3-5', '6-10', '11-20', '+21'];
      const data_puntos = this.data_graficas.clientes_horeca;
      this.data_bar_puntos_x_horeca.datasets.push(data_puntos.puntos1[0].total);
      this.data_bar_puntos_x_horeca.datasets.push(data_puntos.puntos2[0].total);
      this.data_bar_puntos_x_horeca.datasets.push(data_puntos.puntos3_5[0].total);
      this.data_bar_puntos_x_horeca.datasets.push(data_puntos.puntos6_10[0].total);
      this.data_bar_puntos_x_horeca.datasets.push(data_puntos.puntos11_20[0].total);
      this.data_bar_puntos_x_horeca.datasets.push(data_puntos.puntos21[0].total);
      this.total_puntos_x_horeca =
        data_puntos.puntos1[0].total +
        data_puntos.puntos2[0].total +
        data_puntos.puntos3_5[0].total +
        data_puntos.puntos6_10[0].total +
        data_puntos.puntos11_20[0].total +
        data_puntos.puntos21[0].total;
    }
    // Clientes en cartera
    if (this.data_graficas.clientes_cartera.length > 0) {
      this.data_graficas.clientes_cartera.forEach((element: any) => {
        this.data_pie_clientes_cartera.labels.push(element._id == true ? 'En cartera' : 'Paz y salvo');
        this.data_pie_clientes_cartera.datasets.push(element.total);
        this.total_clientes_cartera += element.total;
      });
    }
    // Clientes con convenio
    if (this.data_graficas.clientes_convenio.length > 0) {
      this.data_graficas.clientes_convenio.forEach((element: any) => {
        this.data_pie_clientes_convenio.labels.push(element._id == true ? 'Convenio' : 'Sin convenio');
        this.data_pie_clientes_convenio.datasets.push(element.total);
        this.total_clientes_convenio += element.total;
      });
    }
    // Clientes con domicilio
    if (this.data_graficas.clientes_domicilio.length > 0) {
      this.data_graficas.clientes_domicilio.forEach((element: any) => {
        this.data_pie_clientes_domicilio.labels.push(
          element._id[0] && element._id[0] == true ? 'Domicilio' : 'Sin domicilio'
        );
        this.data_pie_clientes_domicilio.datasets.push(element.total);
        this.total_clientes_domicilio += element.total;
      });
    }
    this.cargarChartJS();
  }

  /**
   * Llama al servicio de graficas para cada grafica enviando
   * sus datos, labels, ejes y parametros de carga
   */
  cargarChartJS() {
    // Clientes Sillas
    this.bar_chart_clientes_sillas = this.graficasService.createBarChart(
      this.bar_clientes_sillas,
      this.data_bar_clientes_sillas,
      'No. de clientes por cantidad de sillas',
      'No. de clientes',
      'Cantidad de sillas'
    );
    // Tipos de negocio
    this.bar_chart_clientes_tipo = this.graficasService.createBarChart(
      this.bar_clientes_tipo,
      this.data_bar_clientes_tipo,
      'Clientes por Negocio',
      'Cantidad de clientes',
      'Negocio'
    );
    // Tipos usuario
    this.bar_chart_clientes_tipo_usuario = this.graficasService.createBarChart(
      this.bar_clientes_tipo_usuario,
      this.data_bar_clientes_tipo_usuario,
      'Clientes por tipo de persona',
      'Cantidad de clientes',
      'Tipo de persona'
    );
    // Sillas por tipo negocio
    this.bar_chart_clientes_sillas_x_negocio = this.graficasService.createBarChart(
      this.bar_clientes_sillas_x_negocio,
      this.data_bar_clientes_sillas_x_negocio,
      'Cantidad de sillas por tipo de negocio',
      'Cantidad de sillas',
      'Tipo de negocio'
    );
    // Puntos por horeca
    this.bar_chart_puntos_x_horeca = this.graficasService.createBarChart(
      this.bar_puntos_x_horeca,
      this.data_bar_puntos_x_horeca,
      'Cantidad de establecimientos por número de puntos de entrega',
      'Cantidad de establecmientos',
      'Número de puntos de entrega'
    );
    // Clientes en cartera
    this.pie_chart_clientes_cartera = this.graficasService.createPieChart(
      this.pie_clientes_cartera,
      this.data_pie_clientes_cartera,
      'Cantidad de clientes por cartera'
    );
    // Clientes con convenio
    this.pie_chart_clientes_convenio = this.graficasService.createPieChart(
      this.pie_clientes_convenio,
      this.data_pie_clientes_convenio,
      'Cantidad de clientes por convenio'
    );
    // Clientes con domicilio
    this.pie_chart_clientes_domicilio = this.graficasService.createPieChart(
      this.pie_clientes_domicilio,
      this.data_pie_clientes_domicilio,
      'Cantidad de clientes con domicilio'
    );
  }

  /**
   * Recupera y arma la data de cliente mes cuando se aplica un filtro de año
   * Se hace de esta forma independiente, para no armar todas las graficas de nuevo
   */
  async getConsultaFiltroClientesMes() {
    this.total_clientes_mes = 0;
    if (this.bar_chart_clientes_mes) {
      this.bar_chart_clientes_mes.destroy();
      this.data_bar_clientes_mes.labels = [];
      this.data_bar_clientes_mes.datasets = [];
    }
    await this.restService
      .getJWT(
        `informes/distribuidor/clientes/graficas/filtro/${this.id_distribuidor}/${this.filtro_cliente_mes}-01-01/${this.filtro_cliente_mes}-12-31`
      )
      .toPromise()
      .then((resp: any) => {
        if (resp.data[0]) {
          if (resp.data[0].clientes_mes.length > 0) {
            resp.data[0].clientes_mes.forEach((element: any) => {
              this.data_bar_clientes_mes.labels.push(this.meses[element._id.split('-')[1] - 1]);
              this.data_bar_clientes_mes.datasets.push(element.total);
              this.total_clientes_mes += element.total;
            });
            this.bar_chart_clientes_mes = this.graficasService.createBarChart(
              this.bar_clientes_mes,
              this.data_bar_clientes_mes,
              'No. de clientes por mes',
              'Clientes',
              'Mes'
            );
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  /**
   * Carga el mapa con la ubicacion de los puntos de entrega
   */
  public async onMapReady(map: any) {
    this.mapaClientes = map;
    const id = this.authService.user_distribuidor?._id || '';
    this.data = await this.distribuidorService.getInformeClientes(id, this.filtro_cliente_mes).toPromise();
    this.setMapCoords();
  }
  selectClient(cliente: any) {
    this.selectedClient = cliente;
    this.setMapCoords();
  }
  setMapCoords() {
    if (this.selectedClient) {
      this.currentCoords = { lat: this.selectedClient.lat, lng: this.selectedClient.lng };
    } else if (this.data?.puntos_geo?.length) {
      this.currentCoords = { lat: this.data.puntos_geo[0].lat, lng: this.data.puntos_geo[0].lng };
    } else {
      this.currentCoords = { lat: 4.678508639544325, lng: -74.05550588007192 };
    }
  }

  /**
   * Recupera los establecimientos que han hecho pedidos de los productos de la organización
   * y recupera las coordenadas de cada punto de entrega para actualziar el mapa en el DOM
   */
  async cargarMapa() {
    const ciudad = this.ngxsStore.snapshot().auth.user_distribuidor.ciudad;
    const dataDistri = this.ngxsStore.snapshot().auth.user_distribuidor;
    await this.buscarDireccion(dataDistri);
    // this.coord_dist = await this.mapService.getLatLong(direction).toPromise();
    // this.coord_dist = this.coord_dist.results[0].geometry.location;
    const puntos_mapa = this.data_graficas.puntos_mapa;
    for (let index = 0; index < puntos_mapa.length; index++) {
      // Si no tiene las coordenadas guardadas las consulta y actualiza luego el punto
      if (!puntos_mapa[index].coord && puntos_mapa[index].direccion && puntos_mapa[index].ciudad) {
        const direccion = puntos_mapa[index].direccion + ' ' + puntos_mapa[index].ciudad;
        const resp: any = await this.mapService.getLatLong(direccion).toPromise();
        if (resp.status == 'OK') {
          if (resp.results[0]) {
            puntos_mapa[index].coord = resp.results[0].geometry.location;
            // Actualiza el punto para no volver a usar la API de google
            const obj_aux: any = { coord: resp.results[0].geometry.location };
            await this.restService.putJWT('punto_entrega/' + puntos_mapa[index]._id, obj_aux).toPromise();
          }
        }
      }
      this.establecimientos.push(puntos_mapa[index]);
      this.locations_puntos_entrega.push(puntos_mapa[index].coord || {});
    }
  }

  buscarDireccion(data: any) {
    const dir = data.direccion + ', ' + data.ciudad + ', ' + data.departamento;
    this.mapService
      .getLatLong(dir)
      .toPromise()
      .then((resp: any) => {
        if (resp.results) {
          this.coord_dist = resp.results[0].geometry.location;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  /**
   * Recibe la información del punto seleccionado y actualiza la tabla que meustra la inforamción del punto
   * @param pin data del punto seleccionado en el mapa del DOM
   */
  showDataPunto(pin: any) {
    this.establecimientos.forEach((element: any) => {
      if (element.coord.lat == pin.latitude && element.coord.lng == pin.longitude) {
        this.punto_seleccionado_nombre = element.nombre;
        this.punto_seleccionado_direccion = element.direccion + ', ' + element.ciudad;
      }
    });
  }

  /**
   * Abre en una nueva url el detalle del cliente/punto
   */
  openClient() {
    window.open(`/detalle-cliente/${this.selectedClient?._id}`, '_blank');
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio-distribuidor']);
    };
  }
}
