<app-header-horeca *ngIf="tipo_usuario === 'usuario_horeca'"></app-header-horeca>
<app-header-distribuidor *ngIf="tipo_usuario === 'distribuidor'"></app-header-distribuidor>
<div class="d-flex flex-column justify-content-center align-items-center w-100 p-4">
  <!--Datos del pedido-->
  <div class="contenedor w-100">
    <!--Header-->
    <div class="row p-3">
      <div class="">
        <button class="boton-volver d-flex align-items-center flex-row justify-content-center" (click)="volver()">
          <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }"></fa-icon>
          &nbsp;&nbsp;Volver
        </button>
      </div>
      <div class="ml-4 d-flex align-items-center justify-content-center">
        <h3 class="m-0">Pre-Factura</h3>
      </div>
    </div>
    <!--Tabla-->
    <div class="card-container" *ngIf="datos_prefactura">
      <div class="card-body d-flex p-0">
        <label class="title"></label>
        <button class="btn-descarga ml-auto px-4" (click)="DescargaArchivo()">Descargar datos</button>
      </div>
      <hr>
      <ng2-smart-table class="scrolling-wrapper" [settings]="config_tabla" [source]="source"></ng2-smart-table>
    </div>
  </div>
</div>