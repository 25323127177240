import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-portafolio',
  templateUrl: './portafolio.component.html',
  styleUrls: ['./portafolio.component.css'],
})
export class PortafolioComponent implements OnInit {
  /** Control de navegación */
  active = 1;
  /** Referencias a íconos FontAwesome para la UI */
  public faChevronLeft = faChevronLeft;

  constructor(private router: Router) {}

  ngOnInit(): void {}
}
