import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faTimes, faTimesCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { PuntoEntrega } from 'src/app/models/punto_entrega.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { SimpleComponent } from '../simple/simple.component';

@Component({
  selector: 'app-editar-puntos-asignados',
  templateUrl: './editar-puntos-asignados.component.html',
  styleUrls: ['./editar-puntos-asignados.component.css'],
})
export class EditarPuntosAsignadosComponent implements OnInit {
  @Input() puntos_entrega_asignados: PuntoEntrega[] = [];
  @Input() id_trabajador = '';
  @Input() dataTrabajador: any;
  @Input() close_callback = () => {};

  /** Variables para control del form */
  public puntos_entrega: any;
  public updatePuntos: any = [];
  public copyDataPuntos: any = [];

  /** Referencias a íconos FontAwesome */
  faTimes = faTimes;
  faTimesCircle = faTimesCircle;
  faTrashAlt = faTrashAlt;

  /** Información completa de establecimientos vinculados */
  lista_establecimientos_vinculados: any;

  /** Guarda los nombre de los establecimientos de los puntos de venta */
  nombre_establecimientos_por_punto: string[] = [];

  /** Tipo de usuario logueado, distribuidor o establecimiento */
  user = this.authService.user;
  tipo_usuario: any;

  constructor(
    public activeModal: NgbActiveModal,
    private restService: RestService,
    private authService: AuthService,
    private modalService: NgbModal,
    private router: Router
  ) {
    if ('usuario_horeca' in this.user!) {
      this.tipo_usuario = 'establecimiento';
    } else if ('distribuidor' in this.user!) {
      this.tipo_usuario = 'distribuidor';
    }
  }

  ngOnInit(): void {
    this.getPuntosEntrega();
    // eslint-disable-next-line prefer-const
    for (let item of this.puntos_entrega_asignados) {
      this.copyDataPuntos.push(item);
    }
  }
  /**
   * Recupera la información de los puntos de entrega del usuario autenticado
   */
  getPuntosEntrega() {
    if (this.tipo_usuario === 'establecimiento') {
      this.getPuntosEstablecimientos();
    } else if (this.tipo_usuario === 'distribuidor') {
      this.getPuntosDistribuidores();
    }
  }

  /**
   * Recupera la información de los puntos de entrega para un establecimiento
   */
  getPuntosEstablecimientos() {
    try {
      this.restService
        .getJWT('punto_entrega_establecimiento/' + this.authService.user?.usuario_horeca)
        .toPromise()
        .then((resp: any) => {
          /*const insert = [];
          let i = 0;
          let punto_venta_aux: PuntoEntrega;
          for (i = 0; i < resp.length; i++) {
            if (resp[i].usuario_horeca == this.authService.user?.usuario_horeca && resp[i].estado == 'Activo') {
              punto_venta_aux = new PuntoEntrega(
                resp[i].usuario_horeca,
                resp[i].nombre,
                resp[i].pais,
                resp[i].departamento,
                resp[i].ciudad,
                resp[i].telefono.toString(),
                resp[i].direccion,
                resp[i].informacion_contacto,
                resp[i].sillas,
                resp[i].domicilios,
                resp[i].numero_trabajadores,
                resp[i].tipo_aprobador,
                resp[i].dias_atencion,
                resp[i].horario,
                resp[i]._id,
                resp[i].estado
              );
              insert.push(punto_venta_aux);
            }
          }
          console.log('insert', insert);
          this.puntos_entrega = insert;*/
          this.puntos_entrega = resp;
        });
    } catch (err) {
      this.activeModal.close('Click close');
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
      };
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'No fue posible recuperar la lista de puntos de venta. Por favor intenta de nuevo más tarde';
      modalRef.componentInstance.btn_msg = 'Volver';
    }
  }

  /**
   * Recupera la información de los puntos de entrega para un distribuidor
   */
  async getPuntosDistribuidores() {
    try {
      await this.restService
        .getJWT(`/punto_entrega_distribuidor/${this.authService.user?.distribuidor}`)
        .toPromise()
        .then((resp: any) => {
          this.puntos_entrega = resp;
        });
      await this.restService
        .getJWT(`/establecimientos_vinculados_a_distribuidores_aprobados/${this.authService.user?.distribuidor}`)
        .toPromise()
        .then((resp: any) => {
          this.lista_establecimientos_vinculados = resp.data;
          this.getEstablecimientoNombre();
        });
    } catch (err) {
      this.activeModal.close('Click close');
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
      };
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'No fue posible recuperar la lista de puntos de venta. Por favor intenta de nuevo más tarde';
      modalRef.componentInstance.btn_msg = 'Volver';
    }
  }

  /**
   * Crea un array con el nombre del establecimiento al que pertenece cada punto de venta
   */
  getEstablecimientoNombre() {
    this.puntos_entrega.forEach((_id_punto: any) => {
      this.lista_establecimientos_vinculados.forEach((element: any) => {
        if (element.puntos[0]._id === _id_punto._id) {
          _id_punto.nameSelect = element.puntos[0].horeca[0].nombre_establecimiento;
          _id_punto.dataMod = element;
          this.nombre_establecimientos_por_punto.push(element.puntos[0].horeca[0].nombre_establecimiento);
        }
      });
    });
  }

  /**Esta funcion tiene como objeto seleccionar un punto de entrega de la lista
   * Para así agregarlo a la lista de puntos seleccionados  que se muestra en el DOM
   * pero ante verifica si ya se encuentra dentro de los seleccionados, si está no lo agrega
   * Todos estos se guardan en un array para que al guardar los cambios se reflejen en la base de datos
   * @param e objeto que se va a guardar en el array y mostrar en el DOM*/
  seleccionarTrabajador(e: any) {
    const seleccioando = this.puntos_entrega[e.target.value];
    if (this.dataTrabajador) {
      const indicaExiste = this.copyDataPuntos.findIndex((x: any) => x._id === seleccioando._id);
      if (indicaExiste === -1) {
        this.copyDataPuntos.push(seleccioando);
        this.dataTrabajador.puntos_entrega.push(seleccioando._id);
        this.puntos_entrega_asignados.push(seleccioando);
      }
    } else {
      for (const iterator of this.puntos_entrega_asignados) {
        if (iterator._id == seleccioando._id) {
          return;
        }
      }
      const indicaExiste = this.copyDataPuntos.findIndex(
        (x: any) => x._id === this.puntos_entrega[e.target.value].dataMod._id
      );
      if (indicaExiste < 0) {
        /*id_modificar: '64cc00946a6d9d00120e15e3';
      id_punto: ['64cc00946a6d9d00120e15e1'];
      nombre_punto: ['Barrios Unidos'];
      vendedor: (2)[('63d7f506ef21790011c058ed', '63d7f5b9ef21790011c05940')];
      _id: '64cc00946a6d9d00120e15e3';*/
        this.puntos_entrega[e.target.value].dataMod.vendedor.push(this.id_trabajador);
        const insert = {
          id_modificar: this.puntos_entrega[e.target.value].dataMod._id,
          id_punto: this.puntos_entrega[e.target.value]._id,
          nombre_punto: this.puntos_entrega[e.target.value].nombre,
          vendedor: this.puntos_entrega[e.target.value].dataMod.vendedor,
          _id: this.puntos_entrega[e.target.value].dataMod._id,
        };
        this.copyDataPuntos.push(insert);
        this.updatePuntos.push(insert);
      } else {
        console.log('ya existe');
      }
    }
  }
  getPlaces(e: any) {}
  /**Esta funcion tiene como objeto eliminar un punto de entrega
   * lo elimina de la lista de seleccionados que es el input para al final guardar cambios
   * @param target objeto que se va a eliminar del array*/
  elminarTrabajador(target: any, id: any) {
    if (this.dataTrabajador) {
      const indicaExiste = this.dataTrabajador.puntos_entrega.findIndex((x: any) => x._id === target._id);
      if (indicaExiste >= 0) {
        this.dataTrabajador.puntos_entrega.splice(indicaExiste, 1);
        this.copyDataPuntos.splice(id, 1);
        this.puntos_entrega_asignados.splice(id, 1);
      }
    } else {
      const indicaExiste = target.vendedor.findIndex((x: any) => x === this.id_trabajador);
      if (indicaExiste >= 0) {
        target.vendedor.splice(indicaExiste, 1);
        this.copyDataPuntos.splice(id, 1);
      }
      this.updatePuntos.push(target);
    }
  }

  /**Este metodo tiene como objeto cerrar el modal sin guardar cambios*/
  onClose() {
    window.location.reload();
  }

  /**
   * Actualiza los puntos de entrega asignados al trabajador. Si la
   * petición HTTP es exitosa, cierra este modal y muestra un mensaje
   * de éxito. Si ocurre un error, se le informa al usuario mediante
   * un mensaje de error
   */
  async guardarCambios() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
    };
    if (this.dataTrabajador) {
      console.log('dataTrabajador');
      delete this.dataTrabajador._id;
      this.restService
        .putJWT('trabajador/' + this.id_trabajador, this.dataTrabajador)
        .toPromise()
        .then(() => {
          this.activeModal.close('Click close');
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          modalRef.componentInstance.msg =
            'Los puntos de entrega asignados a este trabajador fueron actualizados correctamente.';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            window.location.reload();
          };
        })
        .catch(() => {
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg =
            'No fue posible actualiazar la información de los puntos de entrega asignados. Por favor intenta de nuevo más tarde.';
          modalRef.componentInstance.btn_msg = 'Volver';
        });
    } else {
      console.log('no dataTrabajador', this.puntos_entrega_asignados);
      console.log('no update', this.updatePuntos);

      if (this.updatePuntos.length > 0) {
        const put_data = { puntos_entrega: this.puntos_entrega_asignados };

        await this.updatePuntos.forEach((punto: any) => {
          this.restService
            .putJWT('distribuidores_vinculados_vende/' + punto._id, {
              vendedor: punto.vendedor,
            })
            .toPromise()
            .then(() => {})
            .catch(() => {});
        });
        console.log(this.id_trabajador, put_data);
        this.restService
          .putJWT('trabajador/' + this.id_trabajador, put_data)
          .toPromise()
          .then(() => {
            this.activeModal.close('Click close');
            const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
            modalRef.componentInstance.img_src = '../../../../assets/img/icon-check-verde.png';
            modalRef.componentInstance.title = '¡Genial!';
            modalRef.componentInstance.msg =
              'Los puntos de entrega asignados a este trabajador fueron actualizados correctamente.';
            modalRef.componentInstance.btn_msg = 'Listo';
            modalRef.componentInstance.close_callback = () => {
              window.location.reload();
            };
          })
          .catch(() => {
            const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
            modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
            modalRef.componentInstance.title = '¡Oh oh!';
            modalRef.componentInstance.msg =
              'No fue posible actualiazar la información de los puntos de entrega asignados. Por favor intenta de nuevo más tarde.';
            modalRef.componentInstance.btn_msg = 'Volver';
          });
      } else {
        this.activeModal.close('Click close');
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../../assets/img/icon-check-verde.png';
        modalRef.componentInstance.title = '¡Genial!';
        modalRef.componentInstance.msg =
          'Los puntos de entrega asignados a este trabajador fueron actualizados correctamente.';
        modalRef.componentInstance.btn_msg = 'Listo';
        modalRef.componentInstance.close_callback = () => {
          window.location.reload();
        };
      }
    }
  }
}
