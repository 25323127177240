<app-header-horeca></app-header-horeca>
<div class="container">
  <div>
    <div class="volver" [routerLink]="['/inicio']">
      <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
      <span>Volver</span>
    </div>
    <span class="titulo">Puntos Feat</span>
  </div>
  <div class="puntos-data-container">
    <div class="pestanas-container">
      <section class="botones-menu">
        <button (click)="sectionActive('compras')" [ngClass]="{ selected: buttonActive == 'compras' }">
          Puntos por compras
        </button>
        <button (click)="sectionActive('redenciones')" [ngClass]="{ selected: buttonActive == 'redenciones' }">
          Redenciones de códigos
        </button>
      </section>
      <app-puntos-compras *ngIf="buttonActive == 'compras' && punto_entrega" [idPunto]="idPuntoSeleccionado"></app-puntos-compras>
      <app-redenciones *ngIf="buttonActive == 'redenciones' && punto_entrega" [idPunto]="idPuntoSeleccionado"></app-redenciones>
    </div>
    <div class="generar-container">
      <div class="punto-entrega-container d-flex flex-row  align-items-center justify-content-between">
        <p class="col-9 m-0 p-0 punto-seleccionado">
          Punto de entrega: {{ punto_entrega?.nombre || 'Ningún punto seleccionado'}}
        </p>
        <span class="text-center col-4 p-0 cursorPointer" (click)="seleccionarPunto()">
          <h6 class="m-0 p-0 punto-cambiar" id="p-cambiar-ubicacion">Cambiar</h6>
        </span>
      </div>
      <div class="generar-codigo">
        <div class="fila padding-lados cantidad-puntos">
          <span>{{ balancePuntos?.disponibles[0].total }}</span>
          <div class="columna">
            <span>Tus puntos Ft.</span>
            <span *ngIf="balancePuntos?.disponibles[0].total > 500">Ya puedes generar tu código de descuento</span>
          </div>
        </div>
        <img src="../../assets/img/imagen-35.png" alt="" class="padding-lados" />
        <span class="padding-lados"  *ngIf="punto_entrega && punto_entrega.puntos_inscripcion > 0"> <input type="checkbox" [(ngModel)]="descontarBilletera" /> Quiero usar los {{punto_entrega.puntos_inscripcion }} puntos Ft. de obsequio.</span>
        <div class="div-info" *ngIf="punto_entrega && descontarBilletera">
            <h6 class="textDescuentoPositivo">Puntos obsequio a descontar: 
              <span [hidden]="punto_entrega.puntos_inscripcion <= minPF">
                 {{minPF}} Puntos FT
              </span>
              <span [hidden]="punto_entrega.puntos_inscripcion > minPF">
                {{punto_entrega.puntos_inscripcion}} Puntos FT
             </span>
            </h6>
            <h6 [hidden]="punto_entrega.puntos_inscripcion <= minPF" class="textDescuentoPositivo">Puntos obsequio restantes: 
              <span>
               {{punto_entrega.puntos_inscripcion - minPF }} Puntos FT
             </span> 
            </h6>
            <h6 [hidden]="punto_entrega.puntos_inscripcion >= minPF"> 
              <span class="textDescuento">
                Se descontarán  <span class="negrita">{{minPF - punto_entrega.puntos_inscripcion }} Puntos FT</span>  adicionales de tu saldo para poder generar el código
             </span> 
            </h6>
        </div>
        <div class="row padding-lados">
          <div class="col-12 text-center paddingTituloDescuento negrita">
            <span>
             Redime un código de descuento
            </span> 
           </div>
           <div class="col-12">
             <p>
              Puntos por código a redimir:
             <span class="negrita">{{ minPF }} Puntos FT</span> 
             </p>
             <p *ngIf="copXpunto">
              Valor del código a redimir:
             <span class="negrita"> {{minPF * copXpunto | currency:'COP'}}</span> 
             </p>
            </div>
        </div>
        <div class="padding-lados">
          <div class="row">
            <div class="col-12 boton-container">
              <button (click)="onGenerarCodigo()">Generar código</button>
            </div>
          </div>
        </div>
        <div class="grid-div padding-lados" hidden>
          <div><span>Código</span></div>
        </div>
        <div class="grid-div data-codigo" hidden>
          <div>
            <span>{{ codigo_creado }}</span>
          </div>
        </div>
      </div>
      <div class="balance-container scrollDiv" *ngIf="listaCodigosDescuento">
        <div class="row">
          <div class="col-12 text-center">
            <h5>Códigos disponibles</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-4 text-center">
            Código
          </div>
          <div class="col-4 text-center">
            Valor COP
          </div>
          <div class="col-4 text-center">
            Fecha generado
          </div>
        </div>
        <div class="row paddingAmount" *ngFor="let cod of listaCodigosDescuento">
          <div class="col-4 text-center fontCod" [class.model]="cod.codigo_creado.length > 2">
              {{cod.codigo_creado}}
          </div>
          <div class="col-4 text-center fontCod">
            {{cod.valor_moneda | currency:'COP' }}
        </div>
          <div class="col-4 text-center fontCod">
            {{cod.fecha | date: 'shortDate'}}
          </div>
        </div>
      </div>
      <div class="balance-container">
        <div class="fila balance-titulo padding-lados">
          <img src="../../assets/img/icons/icono-23.png" alt="" />
          <span>Balance de puntos FT</span>
        </div>
        <div class="grid-div padding-lados borders">
          <div>
            <span>Puntos</span>
          </div>
          <div class="alineado-derecha">
            <span>Total</span>
          </div>
        </div>
        <div class="grid-div padding-lados puntos-data">
          <div>
            <span>Puntos FT acumulados</span>
          </div>
          <div class="alineado-derecha">
            <span>{{ historicoPuntos || 0}}</span>
          </div>
        </div>
        <div class="grid-div padding-lados puntos-data">
          <div>
            <span>Puntos FT redimidos</span>
          </div>
          <div class="alineado-derecha">
            <span>{{ codGenerados || 0}}</span>
          </div>
        </div>
        <div class="grid-div padding-lados puntos-data">
          <div>
            <span>Puntos FT disponibles</span>
          </div>
          <div class="alineado-derecha">
            <span>{{ puntos_feat || 0}}</span>
          </div>
        </div>
        <div class="grid-div padding-lados puntos-data">
          <div>
            <span>Puntos FT en pedidos por calificar.</span>
          </div>
          <div class="alineado-derecha">
            <span>{{ puntosPorCalificar || 0}}</span>
          </div>
        </div>
        <div class="grid-div padding-lados puntos-data">
          <div>
            <span>Puntos FT en transito.</span>
          </div>
          <div class="alineado-derecha">
            <span>{{ puntosProceso || 0 }}</span>
          </div>
        </div>
        <!--<div class="grid-div padding-lados puntos-data">
          <div>
            <span>Puntos acumulados</span>
          </div>
          <div class="alineado-derecha">
            <span>{{ balancePuntos?.acumulados[0].total }}</span>
          </div>
        </div>
        <div class="grid-div padding-lados puntos-data" style="background-color: #f0f1f9">
          <div>
            <span>Puntos redimidos</span>
          </div>
          <div class="alineado-derecha">
            <span>{{ balancePuntos?.redimidos[0].total }}</span>
          </div>
        </div>
        <div class="grid-div padding-lados puntos-data">
          <div>
            <span>Puntos disponibles</span>
          </div>
          <div class="alineado-derecha">
            <span>{{ balancePuntos?.disponibles[0].total }}</span>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</div>