import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Chart } from 'chart.js';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GraficasService } from 'src/app/services/graficas-tablas/graficas.service';
import { PlacesService } from 'src/app/services/places/places.service';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-informes-sector',
  templateUrl: './informes-sector.component.html',
  styleUrls: ['./informes-sector.component.css'],
})
export class InformesSectorComponent implements OnInit {
  // ID distribuidor
  public id_distribuidor = this.auth.user_distribuidor?._id;
  // Colores para las gráficas
  public colors = [
    'rgba(115, 113, 156, 1)',
    'rgba(199, 195, 239, 1)',
    'rgba(247, 234, 194, 1)',
    'rgba(250, 211, 157, 1)',
    'rgba(179, 179, 210, 1)',
    'rgba(142, 111, 247, 1)',
  ];
  public colors_pie = ['rgba(247, 234, 194, 1)', 'rgba(250, 211, 157, 1)'];
  public filtro_24_meses: any = [];
  // Lista de departamentos
  public departamentos_empresa: any;
  // Lista de distribuidores
  public distribuidores: any;
  // Lista de tipos de negocio
  public tipos_negocio = [
    'PANADERÍA / REPOSTERÍA',
    'COLEGIO / UNIVERSIDAD / CLUB',
    'HOTEL / HOSPITAL',
    'COMEDOR DE EMPLEADOS',
    'CENTRO DE DIVERSIÓN',
    'CAFETERÍA / HELADERÍA / SNACK',
    'CATERING SERVICE / SERVICIO ALIMENTACIÓN',
    'RESTAURANTE DE CADENA',
    'COMIDA RÁPIDA',
    'MAYORISTA / MINORISTA',
    'RESTAURANTE',
  ];
  // Filtro de ciudades
  public ciudades_ventas: any[] = [];
  public ciudades_clientes: any[] = [];
  public ciudades_sillas: any[] = [];
  // Lineas y categorias
  public categorias_productos: any[] = [];
  public lineas_productos: any[] = [];
  // Filtros
  public filtro_departamento_ventas = 'todos';
  public filtro_departamento_clientes = 'todos';
  public filtro_departamento_sillas = 'todos';
  public filtro_ciudad_ventas = 'todos';
  public filtro_ciudad_clientes = 'todos';
  public filtro_ciudad_sillas = 'todos';
  public filtro_categorias = 'todos';
  public filtro_lineas = 'todos';
  public filtro_tipo_negocio = 'todos';
  public filtro_establecimiento_negocio: any;
  // Totales grafica
  public total_ventas: any;
  public total_clientes = 0;
  public total_sillas = 0;
  public total_categorias: any;
  public total_lineas: any;
  public total_tipo_negocio: any;
  public total_chart_ciudad = 0;
  public total_chart_negocio = 0;
  public total_chart_categoria = 0;
  public total_chart_sillas_ciudad = 0;
  public total_chart_sillas_negocio = 0;
  public total_chart_sillas_categoria = 0;
  // Biding HTML-Canvas y TS
  @ViewChild('pie_ventas_mercado', { static: true })
  private pie_ventas_mercado!: ElementRef;
  @ViewChild('pie_establecimiento_mercado', { static: true })
  private pie_establecimiento_mercado!: ElementRef;
  @ViewChild('pie_sillas_mercado', { static: true })
  private pie_sillas_mercado!: ElementRef;
  @ViewChild('pie_categoria_mercado', { static: true })
  private pie_categoria_mercado!: ElementRef;
  @ViewChild('pie_linea_mercado', { static: true })
  private pie_linea_mercado!: ElementRef;
  @ViewChild('pie_negocios_mercado', { static: true })
  private pie_negocios_mercado!: ElementRef;
  @ViewChild('bar_establecimiento_ciudad', { static: true })
  public bar_establecimiento_ciudad!: ElementRef;
  @ViewChild('bar_establecimiento_negocio', { static: true })
  public bar_establecimiento_negocio!: ElementRef;
  @ViewChild('bar_establecimiento_categoria', { static: true })
  public bar_establecimiento_categoria!: ElementRef;
  @ViewChild('bar_sillas_ciudad', { static: true })
  public bar_sillas_ciudad!: ElementRef;
  @ViewChild('bar_sillas_negocio', { static: true })
  public bar_sillas_negocio!: ElementRef;
  @ViewChild('bar_sillas_categoria', { static: true })
  public bar_sillas_categoria!: ElementRef;
  // Guarda datos de la talba y configuraciones
  public data_pie_ventas_mercado: any;
  public pie_chart_ventas_mercado: any;
  public data_pie_establecimiento_mercado: any;
  public pie_chart_establecimiento_mercado: any;
  public data_pie_sillas_mercado: any;
  public pie_chart_sillas_mercado: any;
  public data_pie_categoria_mercado: any;
  public pie_chart_categoria_mercado: any;
  public data_pie_linea_mercado: any;
  public pie_chart_linea_mercado: any;
  public data_pie_negocios_mercado: any;
  public pie_chart_negocios_mercado: any;
  public data_bar_establecimiento_ciudad: any;
  public bar_chart_establecimiento_ciudad: any;
  public data_bar_establecimiento_negocio: any;
  public bar_chart_establecimiento_negocio: any;
  public data_bar_establecimiento_categoria: any;
  public bar_chart_establecimiento_categoria: any;
  public data_bar_sillas_ciudad: any;
  public bar_chart_sillas_ciudad: any;
  public data_bar_sillas_negocio: any;
  public bar_chart_sillas_negocio: any;
  public data_bar_sillas_categoria: any;
  public bar_chart_sillas_categoria: any;
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };

  constructor(
    private rest: RestService,
    private auth: AuthService,
    private currency: CurrencyPipe,
    private modalService: NgbModal,
    private ngxsStore: Store,
    public places: PlacesService,
    public graficasService: GraficasService,
    private router: Router
  ) {
    this.data_pie_ventas_mercado = {
      labels: [],
      datasets: [],
    };
    this.data_pie_establecimiento_mercado = {
      labels: [],
      datasets: [],
    };
    this.data_pie_sillas_mercado = {
      labels: [],
      datasets: [],
    };
    this.data_pie_categoria_mercado = {
      labels: [],
      datasets: [],
    };
    this.data_pie_linea_mercado = {
      labels: [],
      datasets: [],
    };
    this.data_pie_negocios_mercado = {
      labels: [],
      datasets: [],
    };
    this.data_bar_establecimiento_ciudad = {
      labels: [],
      datasets: [],
    };
    this.data_bar_establecimiento_negocio = {
      labels: [],
      datasets: [],
    };
    this.data_bar_establecimiento_categoria = {
      labels: [],
      datasets: [],
    };
    this.data_bar_sillas_ciudad = {
      labels: [],
      datasets: [],
    };
    this.data_bar_sillas_negocio = {
      labels: [],
      datasets: [],
    };
    this.data_bar_sillas_categoria = {
      labels: [],
      datasets: [],
    };
  }

  async ngOnInit() {
    this.id_distribuidor = await this.ngxsStore.snapshot().auth.user_distribuidor._id;
    /**
     * Por ajuste se comenta toda la funcionalidad de la grafica y se muestra mensaje error Mientras la plataforma toma mayor proyeccion en data bar.
     */
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Estamos haciendo cambios, pronto las gráficas estarán disponibles';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      // this.router.navigate(['/inicio-organizacion']);
    };
    //this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    /*try {
      this.departamentos_empresa = await this.places.getDepartmentFromServerImagine();
      this.distribuidores = await this.rest.getJWT('distribuidor_logo_nombre').toPromise();
      await this.cargarInformacionVentasMercado();
      await this.cargarInformacionEstablecimientoMercado();
      await this.cargarInformacionSillasMercado();
      await this.cargarInformacionCategoriaMercado();
      await this.cargarInformacionLineaMercado();
      await this.cargarInformacionNegociosMercado();
      await this.cargarInformacionEstablecimientoCiudad();
      await this.cargarInformacionEstablecimientoNegocio();
      await this.cargarInformacionEstablecimientoCategoria();
      await this.cargarInformacionSillasCiudad();
      await this.cargarInformacionSillasNegocio();
      await this.cargarInformacionSillasCategoria();
      await this.fetchCategoriasYLineas();
      this.modalCarga?.close();
    } catch (error) {
      this.mostrarMensajeError();
    }*/
  }

  /**
   * Metodo para obtener departamentos y ciudades mediante API Imagine Apps
   */
  async getPlaces(departamento: any, chart: any) {
    let id_dpto = 0;
    for (const dpto of this.departamentos_empresa) {
      if (dpto.name == departamento) {
        break;
      }
      id_dpto++;
    }
    if (this.departamentos_empresa[id_dpto] != undefined) {
      if (chart === 'ventas') {
        this.ciudades_ventas = await this.places.getCitiesFromServerImagine(this.departamentos_empresa[id_dpto].code);
      } else if (chart === 'clientes') {
        this.ciudades_clientes = await this.places.getCitiesFromServerImagine(this.departamentos_empresa[id_dpto].code);
      } else if (chart === 'sillas') {
        this.ciudades_sillas = await this.places.getCitiesFromServerImagine(this.departamentos_empresa[id_dpto].code);
      }
    }
  }

  /**
   * Recupera las categorías y lineas de productos activas del distribuidor
   */
  async fetchCategoriasYLineas() {
    /********************* Categorías ********************/
    const resp: any = await this.rest.getJWT('categoria_producto').toPromise();
    /** Elimina los objetos repetidos */
    this.categorias_productos = resp.data;
    this.categorias_productos = resp.data.filter((value: any, index: any) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        resp.data.findIndex((obj: any) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });
    /********************* Líneas ********************/
    const resp_lineas: any = await this.rest.getJWT('linea_producto').toPromise();
    /** Elimina los objetos repetidos */
    this.lineas_productos = resp_lineas;
    this.lineas_productos = resp_lineas.filter((value: any, index: any) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        resp_lineas.findIndex((obj: any) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });
  }

  /**
   * Inicializa los datos de las gráficas y llama a los métodos adecuados para mostrar cada una
   */
  /****** Participación ventas  ******/
  public async cargarInformacionVentasMercado() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.pie_chart_ventas_mercado) {
      this.pie_chart_ventas_mercado.destroy();
      this.data_pie_ventas_mercado.labels = [];
      this.data_pie_ventas_mercado.datasets = [];
      this.total_ventas = 0;
    }
    /** Query de la data para la grafica */
    if (this.filtro_ciudad_ventas !== '') {
      const ciudad = { ciudad: this.filtro_ciudad_ventas };
      await this.rest
        .putJWT('distribuidor/informes/sector/' + this.id_distribuidor, ciudad)
        .toPromise()
        .then((resp: any) => {
          resp = resp.data[0];
          if (resp.miOrganizacion[0].total + resp.otrasOrganizaciones[0].total == 0) {
            this.total_ventas = 0;
            return;
          }
          this.data_pie_ventas_mercado.labels.push('Distribuidor' + ' (' + resp.miOrganizacion[0].total + ')');
          this.data_pie_ventas_mercado.labels.push(
            'Otros distribuidores' + ' (' + (resp.otrasOrganizaciones[0].total - resp.miOrganizacion[0].total) + ')'
          );
          this.data_pie_ventas_mercado.datasets.push(resp.miOrganizacion[0].total);
          this.data_pie_ventas_mercado.datasets.push(resp.otrasOrganizaciones[0].total - resp.miOrganizacion[0].total);
          this.total_ventas = this.currency.transform(resp.otrasOrganizaciones[0].total, '$ ', 'symbol', '1.0-0');
        })
        .catch((err) => {
          throw err;
        });
      this.pie_chart_ventas_mercado = this.graficasService.createPieChart(
        this.pie_ventas_mercado,
        this.data_pie_ventas_mercado,
        'Participación de ventas en el mercado'
      );
    }
  }

  /****** Establecimientos en el mercado  ******/
  public async cargarInformacionEstablecimientoMercado() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.pie_chart_establecimiento_mercado) {
      this.pie_chart_establecimiento_mercado.destroy();
      this.data_pie_establecimiento_mercado.labels = [];
      this.data_pie_establecimiento_mercado.datasets = [];
      this.total_clientes = 0;
    }
    /** Query de la data para la grafica */
    if (this.filtro_ciudad_clientes !== '') {
      const ciudad = { ciudad: this.filtro_ciudad_clientes };
      await this.rest
        .putJWT('distribuidor/informes/clientes/' + this.id_distribuidor, ciudad)
        .toPromise()
        .then((resp: any) => {
          resp = resp.data[0];
          if (resp.miOrganizacion[0].total + resp.otrasOrganizaciones[0].total == 0) {
            this.total_clientes = 0;
            return;
          }
          this.data_pie_establecimiento_mercado.labels.push('Distribuidor' + ' (' + resp.miOrganizacion[0].total + ')');
          this.data_pie_establecimiento_mercado.labels.push(
            'Otros distribuidores' + ' (' + (resp.otrasOrganizaciones[0].total - resp.miOrganizacion[0].total) + ')'
          );
          this.data_pie_establecimiento_mercado.datasets.push(resp.miOrganizacion[0].total);
          this.data_pie_establecimiento_mercado.datasets.push(
            resp.otrasOrganizaciones[0].total - resp.miOrganizacion[0].total
          );
          this.total_clientes = resp.otrasOrganizaciones[0].total;
        })
        .catch((err) => {
          throw err;
        });
      this.pie_chart_establecimiento_mercado = this.graficasService.createPieChart(
        this.pie_establecimiento_mercado,
        this.data_pie_establecimiento_mercado,
        'Número de establecimiento en el mercado'
      );
    }
  }

  /****** Sillas en el mercado  ******/
  public async cargarInformacionSillasMercado() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.pie_chart_sillas_mercado) {
      this.pie_chart_sillas_mercado.destroy();
      this.data_pie_sillas_mercado.labels = [];
      this.data_pie_sillas_mercado.datasets = [];
      this.total_sillas = 0;
    }
    /** Query de la data para la grafica */
    if (this.filtro_ciudad_sillas !== '') {
      const ciudad = { ciudad: this.filtro_ciudad_sillas };
      await this.rest
        .putJWT('distribuidor/informes/sillas/' + this.id_distribuidor, ciudad)
        .toPromise()
        .then((resp: any) => {
          resp = resp.data[0];
          if (resp.miOrganizacion[0].total + resp.otrasOrganizaciones[0].total == 0) {
            this.total_sillas = 0;
            return;
          }
          this.data_pie_sillas_mercado.labels.push('Distribuidor' + ' (' + resp.miOrganizacion[0].total + ')');
          this.data_pie_sillas_mercado.labels.push(
            'Otros distribuidores' + ' (' + (resp.otrasOrganizaciones[0].total - resp.miOrganizacion[0].total) + ')'
          );
          this.data_pie_sillas_mercado.datasets.push(resp.miOrganizacion[0].total);
          this.data_pie_sillas_mercado.datasets.push(resp.otrasOrganizaciones[0].total - resp.miOrganizacion[0].total);
          this.total_sillas = resp.otrasOrganizaciones[0].total;
        })
        .catch((err) => {
          throw err;
        });
      this.pie_chart_sillas_mercado = this.graficasService.createPieChart(
        this.pie_sillas_mercado,
        this.data_pie_sillas_mercado,
        'Sillas alcanzadas en el mercado'
      );
    }
  }

  /****** Categoría en el mercado  ******/
  public async cargarInformacionCategoriaMercado() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.pie_chart_categoria_mercado) {
      this.pie_chart_categoria_mercado.destroy();
      this.data_pie_categoria_mercado.labels = [];
      this.data_pie_categoria_mercado.datasets = [];
    }
    /** Query de la data para la grafica */
    if (this.filtro_categorias !== '') {
      await this.rest
        .getJWT('distribuidor/informes/categorias/' + this.id_distribuidor + '/' + this.filtro_categorias)
        .toPromise()
        .then((categorias_sector: any) => {
          categorias_sector = categorias_sector.data[0];
          if (categorias_sector.miOrganizacion[0].total + categorias_sector.otrasOrganizaciones[0].total == 0) {
            this.total_categorias = 0;
            return;
          }
          this.data_pie_categoria_mercado.labels.push(
            'Distribuidor' + ' (' + categorias_sector.miOrganizacion[0].total + ')'
          );
          this.data_pie_categoria_mercado.labels.push(
            'Otros distribuidores' +
              ' (' +
              (categorias_sector.otrasOrganizaciones[0].total - categorias_sector.miOrganizacion[0].total) +
              ')'
          );
          this.data_pie_categoria_mercado.datasets.push(categorias_sector.miOrganizacion[0].total);
          this.data_pie_categoria_mercado.datasets.push(
            categorias_sector.otrasOrganizaciones[0].total - categorias_sector.miOrganizacion[0].total
          );
          this.total_categorias = this.currency.transform(
            categorias_sector.otrasOrganizaciones[0].total,
            '$ ',
            'symbol',
            '1.0-0'
          );
        })
        .catch((err) => {
          throw err;
        });
      this.pie_chart_categoria_mercado = this.graficasService.createPieChart(
        this.pie_categoria_mercado,
        this.data_pie_categoria_mercado,
        'Particiación en categorías del mercado'
      );
    }
  }

  /****** Lineas productos en el mercado  ******/
  public async cargarInformacionLineaMercado() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.pie_chart_linea_mercado) {
      this.pie_chart_linea_mercado.destroy();
      this.data_pie_linea_mercado.labels = [];
      this.data_pie_linea_mercado.datasets = [];
    }
    /** Query de la data para la grafica */
    if (this.filtro_lineas !== '') {
      await this.rest
        .getJWT('distribuidor/informes/lineas/' + this.id_distribuidor + '/' + this.filtro_lineas)
        .toPromise()
        .then((lineas_sector: any) => {
          lineas_sector = lineas_sector.data[0];
          if (lineas_sector.miOrganizacion[0].total + lineas_sector.otrasOrganizaciones[0].total == 0) {
            this.total_lineas = 0;
            return;
          }
          this.data_pie_linea_mercado.labels.push('Distribuidor' + ' (' + lineas_sector.miOrganizacion[0].total + ')');
          this.data_pie_linea_mercado.labels.push(
            'Otros distribuidores' +
              ' (' +
              (lineas_sector.otrasOrganizaciones[0].total - lineas_sector.miOrganizacion[0].total) +
              ')'
          );
          this.data_pie_linea_mercado.datasets.push(lineas_sector.miOrganizacion[0].total);
          this.data_pie_linea_mercado.datasets.push(
            lineas_sector.otrasOrganizaciones[0].total - lineas_sector.miOrganizacion[0].total
          );
          this.total_lineas = this.currency.transform(
            lineas_sector.otrasOrganizaciones[0].total,
            '$ ',
            'symbol',
            '1.0-0'
          );
        })
        .catch((err) => {
          throw err;
        });
      this.pie_chart_linea_mercado = this.graficasService.createPieChart(
        this.pie_linea_mercado,
        this.data_pie_linea_mercado,
        'Particiación en lineas de productos en el mercado'
      );
    }
  }

  /****** Tipos de negocios en el mercado  ******/
  public async cargarInformacionNegociosMercado() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.pie_chart_negocios_mercado) {
      this.pie_chart_negocios_mercado.destroy();
      this.data_pie_negocios_mercado.labels = [];
      this.data_pie_negocios_mercado.datasets = [];
    }
    /** Query de la data para la grafica */
    const tipo_negocio = { tipo_negocio: this.filtro_tipo_negocio };
    await this.rest
      .putJWT('distribuidor/informes/tipos_negocio/' + this.id_distribuidor, tipo_negocio)
      .toPromise()
      .then((lineas_sector: any) => {
        lineas_sector = lineas_sector.data[0];
        if (lineas_sector.miOrganizacion[0].total + lineas_sector.otrasOrganizaciones[0].total == 0) {
          this.total_tipo_negocio = 0;
          return;
        }
        this.data_pie_negocios_mercado.labels.push('Distribuidor' + ' (' + lineas_sector.miOrganizacion[0].total + ')');
        this.data_pie_negocios_mercado.labels.push(
          'Otros distribuidores' +
            ' (' +
            (lineas_sector.otrasOrganizaciones[0].total - lineas_sector.miOrganizacion[0].total) +
            ')'
        );
        this.data_pie_negocios_mercado.datasets.push(lineas_sector.miOrganizacion[0].total);
        this.data_pie_negocios_mercado.datasets.push(
          lineas_sector.otrasOrganizaciones[0].total - lineas_sector.miOrganizacion[0].total
        );
        this.total_tipo_negocio = this.currency.transform(
          lineas_sector.otrasOrganizaciones[0].total,
          '$ ',
          'symbol',
          '1.0-0'
        );
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
    this.pie_chart_negocios_mercado = this.graficasService.createPieChart(
      this.pie_negocios_mercado,
      this.data_pie_negocios_mercado,
      'Particiación en tipos de negocio en el mercado'
    );
  }

  /****** Establecimientos por ciudad ******/
  public async cargarInformacionEstablecimientoCiudad() {
    this.data_bar_establecimiento_ciudad.labels = [];
    this.data_bar_establecimiento_ciudad.datasets = [];
    await this.rest
      .getJWT('sector/puntos_ciudad_por_organizacion/')
      .toPromise()
      .then((puntos: any) => {
        puntos.data.forEach((punto: any) => {
          this.data_bar_establecimiento_ciudad.labels.push(punto._id);
          this.data_bar_establecimiento_ciudad.datasets.push(punto.total);
          this.total_chart_ciudad = this.total_chart_ciudad + punto.total;
        });
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
    this.bar_chart_establecimiento_ciudad = this.graficasService.createBarChart(
      this.bar_establecimiento_ciudad,
      this.data_bar_establecimiento_ciudad,
      'Establecimientos por ciudad',
      'Número de establecimientos',
      'Ciudad'
    );
  }

  /****** Establecimientos por tipo de negocio ******/
  public async cargarInformacionEstablecimientoNegocio() {
    this.data_bar_establecimiento_negocio.labels = [];
    this.data_bar_establecimiento_negocio.datasets = [];
    await this.rest
      .getJWT('sector/tipos_negocio_por_organizacion/')
      .toPromise()
      .then((tipo_negocios: any) => {
        tipo_negocios = tipo_negocios.data[0];
        const labels: any = {
          ventas_diversion: 'CENTRO DE DIVERSIÓN',
          ventas_cafeteria: 'CAFETERÍA / HELADERÍA / SNACK',
          ventas_catering: 'CATERING SERVICE / SERVICIO ALIMENTACIÓN',
          ventas_colegios: 'COLEGIO / UNIVERSIDAD / CLUB',
          ventas_comedores: 'COMEDOR DE EMPLEADOS',
          ventas_rapidas: 'COMIDA RÁPIDA',
          ventas_hoteles: 'HOTEL / HOSPITAL',
          ventas_mayoristas: 'MAYORISTA / MINORISTA',
          ventas_panaderia: 'PANADERÍA / REPOSTERÍA',
          ventas_restaurantes: 'RESTAURANTE',
          ventas_cadena: 'RESTAURANTE DE CADENA',
        };
        const keys = Object.keys(labels);
        keys.forEach((key: any) => {
          this.data_bar_establecimiento_negocio.datasets.push(tipo_negocios[key]);
          this.data_bar_establecimiento_negocio.labels.push(labels[key]);
          this.total_chart_negocio = this.total_chart_negocio + tipo_negocios[key];
        });
      })
      .catch((err) => {
        throw err;
      });
    this.bar_chart_establecimiento_negocio = this.graficasService.createBarChart(
      this.bar_establecimiento_negocio,
      this.data_bar_establecimiento_negocio,
      'Establecimientos por tipo de negocio',
      'Número de establecimientos',
      'Tipo de negocio'
    );
  }

  /****** Establecimientos por categoría ******/
  public async cargarInformacionEstablecimientoCategoria() {
    this.data_bar_establecimiento_categoria.labels = [];
    this.data_bar_establecimiento_categoria.datasets = [];
    await this.rest
      .getJWT('sector/categorias_productos_por_organizacion/')
      .toPromise()
      .then((categorias: any) => {
        categorias.data.forEach((categoria: any) => {
          this.data_bar_establecimiento_categoria.labels.push(categoria._id);
          this.data_bar_establecimiento_categoria.datasets.push(categoria.count);
          this.total_chart_categoria = this.total_chart_categoria + categoria.count;
        });
      })
      .catch((err) => {
        throw err;
      });
    this.bar_chart_establecimiento_categoria = this.graficasService.createBarChart(
      this.bar_establecimiento_categoria,
      this.data_bar_establecimiento_categoria,
      'Establecimientos por categoría',
      'Número de establecimientos',
      'Categorías'
    );
  }

  /****** Sillas por ciudad ******/
  public async cargarInformacionSillasCiudad() {
    this.data_bar_sillas_ciudad.labels = [];
    this.data_bar_sillas_ciudad.datasets = [];
    await this.rest
      .getJWT('sector/sillas_ciudad_por_organizacion/')
      .toPromise()
      .then((categorias: any) => {
        categorias.data.forEach((categoria: any) => {
          this.data_bar_sillas_ciudad.labels.push(categoria._id);
          this.data_bar_sillas_ciudad.datasets.push(categoria.total);
          this.total_chart_sillas_ciudad = this.total_chart_sillas_ciudad + categoria.total;
        });
      })
      .catch((err) => {
        throw err;
      });
    this.bar_chart_sillas_ciudad = this.graficasService.createBarChart(
      this.bar_sillas_ciudad,
      this.data_bar_sillas_ciudad,
      'Sillas por ciudad',
      'Número de sillas',
      'Ciudad'
    );
  }

  /****** Sillas por tipo de negocio ******/
  public async cargarInformacionSillasNegocio() {
    this.data_bar_sillas_negocio.labels = [];
    this.data_bar_sillas_negocio.datasets = [];
    await this.rest
      .getJWT('sector/sillas_tipo_negocio_por_organizacion/')
      .toPromise()
      .then((categorias: any) => {
        categorias.data.forEach((categoria: any) => {
          this.data_bar_sillas_negocio.labels.push(categoria._id);
          this.data_bar_sillas_negocio.datasets.push(categoria.total);
          this.total_chart_sillas_negocio = this.total_chart_sillas_negocio + categoria.total;
        });
      })
      .catch((err) => {
        throw err;
      });
    this.bar_chart_sillas_negocio = this.graficasService.createBarChart(
      this.bar_sillas_negocio,
      this.data_bar_sillas_negocio,
      'Sillas por tipo de negocio',
      'Número de sillas',
      'Tipo de negocio'
    );
  }

  /****** Sillas por categoría ******/
  public async cargarInformacionSillasCategoria() {
    this.data_bar_sillas_categoria.labels = [];
    this.data_bar_sillas_categoria.datasets = [];
    await this.rest
      .getJWT('sector/sillas_categoria_por_organizacion/')
      .toPromise()
      .then((categorias: any) => {
        categorias.data.forEach((categoria: any) => {
          this.data_bar_sillas_categoria.labels.push(categoria._id);
          this.data_bar_sillas_categoria.datasets.push(categoria.total);
          this.total_chart_sillas_categoria = this.total_chart_sillas_categoria + categoria.total;
        });
      })
      .catch((err) => {
        throw err;
      });
    this.bar_chart_sillas_categoria = this.graficasService.createBarChart(
      this.bar_sillas_categoria,
      this.data_bar_sillas_categoria,
      'Sillas por categoría',
      'Número de sillas',
      'Categorías'
    );
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio-organizacion']);
    };
  }
}
