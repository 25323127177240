import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  constructor() {}

  public isMenuCollapsed = true;

  ngOnInit(): void {}

  public verDocumento(link: any) {
    window.open(link, '_blank');
  }
}
