import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { NoticiaService } from 'src/app/services/noticia/noticia.service';

@Component({
  selector: 'app-noticia',
  templateUrl: './noticia.component.html',
  styleUrls: ['./noticia.component.css'],
})
export class NoticiaComponent implements OnInit {
  /* Iconos**/
  faChevronLeft = faChevronLeft;

  noticiaId: string | undefined;
  noticia: any;

  modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
  };

  constructor(
    private activeRoute: ActivatedRoute,
    private noticiaService: NoticiaService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.noticiaId = this.activeRoute.snapshot.params.id;
    this.loadPost();
  }

  async loadPost() {
    const modalCarga = this.modalService.open(CargandoGenericoComponent, this.modalOptions);
    try {
      this.noticia = await this.noticiaService.getNoticia(this.noticiaId || '').toPromise();
    } catch (error) {
      const modalRef = this.modalService.open(SimpleComponent, this.modalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {
        window.location.href = '/blog';
      };
    }
    modalCarga.close();
  }
}
