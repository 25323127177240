<div class="content">
  <div class="container">
    <div class="header-title">
      <span>Califica el pedido</span>
      <div>
        <fa-icon [icon]="faTimes" class="cursorPointer" [styles]="{ color: '#41403E' }" id="fa-icon" (click)="close()">
        </fa-icon>
      </div>
    </div>
    <!-- Info distribuidor -->
    <div class="card-distribuitor">
      <div class="contain-distribuitor">
        <div class="info-distribuitor">
          <img src="{{ logo_distribuidor }}" class="img-distruidor"
            alt="logo del distribuidor {{ nombre_distribuidor }}" />
          <div class="text-distribuitor">
            <div class="data-status">
              <p class="text-center">{{estado_pedido}}</p>
            </div>
            <p class="pl-2 pt-2">{{ nombre_distribuidor }}</p>
          </div>
        </div>
        <div>
          <p class="m-0">{{fecha_pedido}}</p>
          <p class="pt-2">{{total_productos}} producto{{total_productos === 1 ? '' : 's' }}</p>
        </div>
      </div>
    </div>
    <form [formGroup]="form" method="POST" (ngSubmit)="calificarPedido()">
      <div class="mt-3">
        <label class="label-input mb-0 mt-3" for="">Abastecimiento</label>
        <div>
          <ng-template #t let-fill="fill">
            <span class="star" [class.full]="fill === 100">
              <span class="half" [style.width.%]="fill">&#9733;</span>
              &#9733;
            </span>
          </ng-template>
          <ngb-rating [(rate)]="currentRateAbas" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
          <select name="" id="calification-input" [(ngModel)]="currentRateAbas" formControlName="abastecimiento">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>
        <div *ngIf="currentRateAbas > 5">
          <p class="text-danger">La calificación maxima solo puede ser de 5</p>
        </div>
        <div *ngIf="currentRateAbas < 0">
          <p class="text-danger">Tu calificación debe ser un número positivo</p>
        </div>
        <label class="label-input mb-0 mt-3" for="">Precio</label>
        <div>
          <ng-template #t let-fill="fill">
            <span class="star" [class.full]="fill === 100">
              <span class="half" [style.width.%]="fill">&#9733;</span>
              &#9733;
            </span>
          </ng-template>
          <ngb-rating [(rate)]="currentRatePrice" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
          <select name="" id="calification-input" [(ngModel)]="currentRatePrice" formControlName="precio">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>
        <div *ngIf="currentRatePrice > 5">
          <p class="text-danger">La calificación maxima solo puede ser de 5</p>
        </div>
        <div *ngIf="currentRatePrice < 0">
          <p class="text-danger">Tu calificación debe ser un número positivo</p>
        </div>
        <label class="label-input mb-0 mt-3" for="">Puntualidad de entrega</label>
        <div>
          <ng-template #t let-fill="fill">
            <span class="star" [class.full]="fill === 100">
              <span class="half" [style.width.%]="fill">&#9733;</span>
              &#9733;
            </span>
          </ng-template>
          <ngb-rating [(rate)]="currentRateCalification" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
          <select name="" id="calification-input" [(ngModel)]="currentRateCalification"
            formControlName="puntualidad_entrega">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
          <div *ngIf="currentRateCalification > 5">
            <p class="text-danger">La calificación maxima solo puede ser de 5</p>
          </div>
          <div *ngIf="currentRateCalification < 0">
            <p class="text-danger">Tu calificación debe ser un número positivo</p>
          </div>
        </div>
        <h5 class="my-3 text-center w-100">
          Promedio: {{ calificacion_promedio}}
        </h5>
        <div class="calification">
          <button class="btn btn-calificar" type="submit"
            [ngClass]="{'btn-continuar-disabled': calificacion_promedio === 0}"
            [disabled]="calificacion_promedio === 0">
            Calificar
          </button>
        </div>
      </div>
    </form>
  </div>
</div>