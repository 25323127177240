import { CurrencyPipe } from '@angular/common';
import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LocalDataSource } from 'ng2-smart-table';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { CrearPromoComponent } from 'src/app/modal/crear-promo/crear-promo.component';
import { CrearSaldoComponent } from 'src/app/modal/crear-saldo/crear-saldo.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { Categoria } from 'src/app/models/categoria.model';
import { LineaProducto } from 'src/app/models/linea_producto.model';
import { Marca } from 'src/app/models/marca.model';
import { Organizacion } from 'src/app/models/organizacion.model';
import { Producto } from 'src/app/models/producto.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-s-y-p-crear-nuevo',
  templateUrl: './s-y-p-crear-nuevo.component.html',
  styleUrls: ['./s-y-p-crear-nuevo.component.css'],
})
export class SYPCrearNuevoComponent implements OnInit, DoCheck {
  /** Datos y configuración de la tabla */
  public settings: any = {};
  public data: any = [];
  public source?: LocalDataSource;
  /** Datos para armar lo que se muestra en la tabla */
  productos: Producto[] = [];
  organizaciones: Organizacion[] = [];
  marcas: Marca[] = [];
  lineas: LineaProducto[] = [];
  categorias: Categoria[] = [];
  modalCargaRef?: NgbModalRef;
  /** Productos seleccionados para la promoción */
  prods_promos: Producto[] = [];
  /** Flag para indicar que hay más productos seleccionados de los permitidos */
  err_prods_promo = false;
  /** Referencia al modal de carga y sus opciones */
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
  };

  constructor(
    private modalService: NgbModal,
    private rest: RestService,
    private auth: AuthService,
    private currency: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.configSmartTable();
    this.loadData();
  }

  ngDoCheck(): void {
    if (this.prods_promos.length > 3) {
      this.err_prods_promo = true;
    } else {
      this.err_prods_promo = false;
    }
  }

  /**
   * Configura la tabla
   */
  private configSmartTable(): void {
    this.settings = {
      noDataMessage: 'Aún no tienes productos agregados',
      pager: {
        display: true,
        perPage: 10,
      },
      actions: {
        add: false,
        edit: false,
        delete: false,
      },
      hideSubHeader: false,
      columns: {
        promociones: {
          title: 'Promociones',
          type: 'custom',
          editable: false,
          filter: false,
          renderComponent: ChkCrearPromo,
          class: 'ng2-column',
        },
        saldos: {
          title: 'Saldos',
          type: 'custom',
          editable: false,
          filter: false,
          renderComponent: BtnCrearSaldo,
          class: 'ng2-column',
        },
        codigo_distribuidor_producto: {
          title: 'Código producto distribuidor',
          editable: false,
          filter: true,
        },
        codigo_organizacion_producto: {
          title: 'Código Organización Producto',
          editable: false,
          filter: true,
        },
        nombre_producto: {
          title: 'Nombre de producto',
          editable: false,
          filter: true,
          class: 'ng2-column',
        },
        unidades: {
          title: 'Unidades',
          editable: false,
          filter: true,
          class: 'ng2-column',
        },
        cajas: {
          title: 'Cajas',
          editable: false,
          filter: true,
          class: 'ng2-column',
        },
        und_x_caja: {
          title: 'Unidades por caja',
          editable: false,
          filter: true,
          class: 'ng2-column',
        },
        uniddad_medida: {
          title: 'Unidad de medida',
          editable: false,
          filter: true,
          class: 'ng2-column',
        },
        cantidad_medida: {
          title: 'Cantidad de medida',
          editable: false,
          filter: true,
          class: 'ng2-column',
        },
        precio_unidad: {
          title: 'Precio de venta por unidad (COP)',
          editable: false,
          filter: true,
          class: 'ng2-column',
        },
        precio_caja: {
          title: 'Precio de venta por caja (COP)',
          editable: false,
          filter: true,
          class: 'ng2-column',
        },
        ventas_3_meses: {
          title: 'Ventas del producto (últ. 3 meses COP)',
          editable: false,
          filter: true,
          class: 'ng2-column',
        },
        codigo_ft: {
          title: 'Código Feat',
          editable: false,
          filter: true,
          class: 'ng2-column',
        },
        nombre_org: {
          title: 'Organización',
          editable: false,
          filter: true,
          class: 'ng2-column',
        },
        puntos_org: {
          title: 'Puntos FT. asignado por organización',
          editable: false,
          filter: true,
          class: 'ng2-column',
        },
        categoria: {
          title: 'Categoría de producto',
          editable: false,
          filter: true,
          class: 'ng2-column',
        },
        linea: {
          title: 'Linea de producto',
          editable: false,
          filter: true,
          class: 'ng2-column',
        },
        marca: {
          title: 'Marca',
          editable: false,
          filter: true,
          class: 'ng2-column',
        },
        establecimientos: {
          title: 'Establecimientos alcanzados',
          editable: false,
          filter: true,
          class: 'ng2-column',
        },
      },
    };
  }

  /**
   * Recupera del back toda la información necesaria para construir la tabla
   */
  async loadData() {
    this.modalCargaRef = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    try {
      //Recupera la info de los productos
      const resp_prod: any = await this.rest
        .getJWT(`productos_info_completa/distribuidor/${this.auth.user_distribuidor?._id}`)
        .toPromise();
      this.productos = resp_prod;
      //Armar los datos a mostrar
      this.data = [];
      let i = 0;
      for (const prod_aux of resp_prod) {
        this.data.push({
          promociones: {
            agregar: (pIndex: number) => {
              this.prods_promos.push(this.productos[pIndex]);
            },
            eliminar: (pIndex: number) => {
              const prod = this.productos[pIndex];
              this.prods_promos.splice(this.prods_promos.indexOf(prod), 1);
            },
            index: i,
          },
          saldos: { producto: prod_aux },
          codigo_distribuidor_producto: prod_aux.codigo_distribuidor_producto,
          codigo_organizacion_producto: prod_aux.codigo_organizacion_producto,
          nombre_producto: prod_aux.nombre,
          cajas:
            prod_aux.und_x_caja && prod_aux.und_x_caja > 0
              ? Math.round((prod_aux.inventario_unidad / prod_aux.und_x_caja) * 100) / 100
              : 0,
          unidades: (prod_aux.inventario_unidad || 0).toString(),
          uniddad_medida: prod_aux.unidad_medida,
          cantidad_medida: prod_aux.cantidad_medida,
          marca_producto: prod_aux.marca_producto,
          categoria_producto: prod_aux.categoria_producto,
          linea_producto: prod_aux.linea_producto,
          codigo_organizacion: prod_aux.codigo_organizacion,
          codigo_ft: prod_aux.codigo_ft,
          nombre_org: prod_aux.organizacion,
          categoria: prod_aux.categoria,
          linea: prod_aux.linea,
          marca: prod_aux.marca,
          precio_unidad: prod_aux.precio_unidad,
          und_x_caja: prod_aux.und_x_caja || 0,
          precio_caja: prod_aux.precio_caja,
          puntos_org: prod_aux.puntos_ft_unidad,
          ventas_3_meses: prod_aux.data_ventas_tres_meses > 0 ? prod_aux.data_ventas_tres_meses : 0,
          establecimientos: prod_aux.data_puntos_alcanzados > 0 ? prod_aux.data_puntos_alcanzados : 0,
        });
        i++;
      }
      this.source = new LocalDataSource(this.data);
      this.modalCargaRef.close();
    } catch (err) {
      console.log(err);
      this.modalCargaRef.close();
      this.settings.noDataMessage = 'Error cargando los productos';
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'Ocurrió un error recuperando la lista de productos. Por favor, intenta de nuevo más tarde.';
      modalRef.componentInstance.btn_msg = 'Volver';
    }
  }
  async validatePermissions() {
    // eslint-disable-next-line prefer-const
    let blockUser = await this.auth.validatePermissionsUser();
    return blockUser;
  }
  onRowSelect(e: any) {
    console.log(e);
  }
  onUserRowSelect(e: any) {
    console.log(e);
  }
  /**
   * Toma los productos del arreglo de promoción
   * y los pasa al modal de crear promoción
   */
  async crearPromocion() {
    // eslint-disable-next-line prefer-const
    let validador = await this.validatePermissions();
    if (validador) {
      const ngbOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        centered: true,
        size: 'lg',
      };
      const fotos: string[] = [];
      const precios: any[] = [];
      const max_inv: number[] = [];
      for (const aux of this.prods_promos) {
        fotos.push(aux.fotos?.[0] || '');
        precios.push(aux.precios?.[0]);
        max_inv.push(aux.precios?.[0].inventario_unidad || 0);
      }
      const modalRef = this.modalService.open(CrearPromoComponent, ngbOptions);
      modalRef.componentInstance.prods = this.prods_promos;
      modalRef.componentInstance.fotos = fotos;
      modalRef.componentInstance.precios = precios;
      modalRef.componentInstance.max_inv = max_inv;
    }
  }

  /**
   * Recupera el nombre de la organización cuyo id entra por parámetro
   * @param pId El id de la organización a buscar
   * @returns El nombre encontrado o undefined si no se encontró
   */
  findOrgNameById(pId: string): string | undefined {
    let ans: string | undefined = undefined;
    if (pId == '') {
      return ans;
    }
    for (const org_aux of this.organizaciones) {
      if (org_aux._id == pId) {
        ans = org_aux.nombre;
        break;
      }
    }
    return ans;
  }

  /**
   * Recupera el nombre de la categoría cuyo id entra por parámetro
   * @param pId El id de la categoría a buscar
   * @returns El nombre encontrado o undefined si no se encontró
   */
  findCatNameById(pId: string): string | undefined {
    let ans: string | undefined = undefined;
    if (pId == '') {
      return ans;
    }
    for (const cat_aux of this.categorias) {
      if (cat_aux._id == pId) {
        ans = cat_aux.nombre;
        break;
      }
    }
    return ans;
  }

  /**
   * Recupera el nombre de la linea cuyo id entra por parámetro
   * @param pId El id de la linea a buscar
   * @returns El nombre encontrado o undefined si no se encontró
   */
  findLineaNameById(pId: string): string | undefined {
    let ans: string | undefined = undefined;
    if (pId == '') {
      return ans;
    }
    for (const linea_aux of this.lineas) {
      if (linea_aux._id == pId) {
        ans = linea_aux.nombre;
        break;
      }
    }
    return ans;
  }

  /**
   * Recupera el nombre de la marca cuyo id entra por parámetro
   * @param pId El id de la marca a buscar
   * @returns El nombre encontrado o undefined si no se encontró
   */
  findMarcaNameById(pId: string): string | undefined {
    let ans: string | undefined = undefined;
    if (pId == '') {
      return ans;
    }
    for (const marca_aux of this.marcas) {
      if (marca_aux._id == pId) {
        ans = marca_aux.nombre;
        break;
      }
    }
    return ans;
  }
}

@Component({
  selector: 'app-btn-crear-saldo',
  template: `
    <button class="btn-purple-trans" (click)="popUpPonerEnSaldo()">Poner en saldo</button>
  `,
  styles: [
    `
      .btn-purple-trans {
        border: none;
        background-color: transparent;
        color: #8e6ff7;
      }
    `,
  ],
})
export class BtnCrearSaldo {
  /** El valor que entra como Input por la tabla */
  @Input() public value?: { producto: Producto };
  constructor(private modalService: NgbModal, private auth: AuthService) {}
  async popUpPonerEnSaldo() {
    // eslint-disable-next-line prefer-const
    let validador = await this.validatePermissions();
    if (validador) {
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        centered: true,
        size: 'lg',
      };
      const modalRef = this.modalService.open(CrearSaldoComponent, ngbModalOptions);
      modalRef.componentInstance.producto = this.value?.producto;
      modalRef.componentInstance.precios = this.value?.producto.precios?.slice(0) || [];
      modalRef.componentInstance.precios_form = this.value?.producto.precios?.slice(0) || [];
      modalRef.componentInstance.fotos = this.value?.producto.fotos?.slice(0) || [];
    }
  }
  async validatePermissions() {
    // eslint-disable-next-line prefer-const
    let blockUser = await this.auth.validatePermissionsUser();
    return blockUser;
  }
}

@Component({
  selector: 'app-chk-crear-promo',
  template: `
    <div class="form-check">
      <input
        class="form-check-input chk-box"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        (change)="clickChk()"
        [checked]="checked" />
    </div>
  `,
  styles: [
    `
      .chk-box {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    `,
  ],
})
export class ChkCrearPromo {
  /** El valor que entra como Input por la tabla */
  @Input() public value: { agregar: any; eliminar: any; index: number } = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    agregar: (i: number) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    eliminar: (i: number) => {},
    index: -1,
  };

  /** Manejador del checkbox */
  checked = false;

  /** Maneja el click del checkbox */
  clickChk() {
    this.checked = !this.checked;
    if (this.checked) {
      this.value.agregar(this.value.index);
    } else {
      this.value.eliminar(this.value.index);
    }
  }

  constructor() {}
}
