import { Component, DoCheck, OnInit } from '@angular/core';
import { ConfirmacionComponent } from 'src/app/modal/confirmacion/confirmacion.component';
import { PlacesService } from 'src/app/services/places/places.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CambiarContrasenaComponent } from 'src/app/modal/cambiar-contrasena/cambiar-contrasena.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {
  faTimes,
  faChevronLeft,
  faTimesCircle,
  faPencilAlt,
  faTrash,
  faTrashAlt,
  faCheckCircle,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from 'src/app/services/rest/rest.service';
import { Trabajador } from 'src/app/models/trabajador.model';
import { PuntoEntrega } from 'src/app/models/punto_entrega.model';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';
import { EditarPuntosAsignadosComponent } from 'src/app/modal/editar-puntos-asignados/editar-puntos-asignados.component';
@Component({
  selector: 'app-detalle-trabajador-aprobado',
  templateUrl: './detalle-trabajador-aprobado.component.html',
  styleUrls: ['./detalle-trabajador-aprobado.component.css'],
})
export class DetalleTrabajadorAprobadoComponent implements OnInit, DoCheck {
  // Iconos FontAwesome para usar en el template
  public faTimes = faTimes;
  public faChevronLeft = faChevronLeft;
  public faTimesCircle = faTimesCircle;
  public faPencilAlt = faPencilAlt;
  public faTrash = faTrash;
  public faTrashAlt = faTrashAlt;
  public faCheckCircle = faCheckCircle;
  public faUser = faUser;
  // Información completa de establecimientos vinculados
  public lista_establecimientos_vinculados: any;
  //Guardara booleano para habilitar o no boton de continuar
  public is_boton_habilitado = false;
  // Guarda los nombre de los establecimientos de los puntos de venta
  public nombre_establecimientos_por_punto: string[] = [];
  // Variable para tener los datos del trabajador y poder editarlos
  public trabajador!: Trabajador;
  // Arreglo de los puntos de venta asociados al trabajador
  public puntos_entrega: any = [];
  // Variables para el manejo de la lista de departamentos y ciudades
  public id_dpto = 0;
  public ciudades: any;
  public departamentos: any;
  // Evita que se pueda hacer click fuera del modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };
  // Formulario reactivo
  public trabajadorFormPaso1: FormGroup;
  copy_perfil = '';
  cant_propietario = 0;
  vinculaciones_no_asociadas: any = [];
  constructor(
    private modalService: NgbModal,
    private restService: RestService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public places: PlacesService,
    public localStorage: LocalStorageService
  ) {
    this.trabajadorFormPaso1 = this.formBuilder.group({
      trabajadorDocumento: ['', Validators.required],
      trabajadorTipo: ['', Validators.required],
      trabajadorNombre: ['', Validators.required],
      trabajadorApellido: ['', Validators.required],
      trabajadorTipoUsuario: ['', Validators.required],
      trabajadorPais: ['', Validators.required],
      trabajadorDepartamento: ['', Validators.required],
      trabajadorCiudad: ['', Validators.required],
      trabajadorCelular: ['', [Validators.required, Validators.pattern(/^3[\d]{9}$/)]],
      trabajadorTelefono: [''],
    });
  }

  ngDoCheck(): void {
    this.habilitarBoton();
  }

  async ngOnInit() {
    this.departamentos = await this.places.getDepartmentFromServerImagine();
    await this.getTrabajador();
    await this.getPuntosTrabajador();

    /** Formulario reactivo */
    this.trabajadorFormPaso1.markAllAsTouched();
  }

  /**
   * Trae los datos del trabajador
   */
  getTrabajador() {
    const id_trab: string = this.activatedRoute.snapshot.params.id;

    this.restService
      .getJWT('trabajador/' + id_trab)
      .toPromise()
      .then((resp: any) => {
        this.trabajador = new Trabajador(
          resp.nombres,
          resp.apellidos,
          resp.correo,
          resp.clave,
          resp.telefono,
          resp.celular || '',
          resp.pais,
          resp.departamento,
          resp.ciudad,
          resp.tipo_documento,
          resp.numero_documento.toString(),
          resp.tipo_trabajador,
          resp.solicitud_vinculacion,
          resp.show_slides,
          undefined,
          resp.puntos_entrega,
          resp.distribuidor,
          undefined,
          resp._id
        );
        this.copy_perfil = this.trabajador.tipo_trabajador;
        this.countPropietarios(this.trabajador.distribuidor);
        /**
         * Biding con el formulario para ver la data en el DOM
         */
        this.trabajadorFormPaso1.setValue({
          trabajadorDocumento: this.trabajador.numero_documento,
          trabajadorTipo: this.trabajador.tipo_documento,
          trabajadorNombre: this.trabajador.nombres,
          trabajadorApellido: this.trabajador.apellidos,
          trabajadorTipoUsuario: this.trabajador.tipo_trabajador,
          trabajadorPais: this.trabajador.pais,
          trabajadorDepartamento: this.trabajador.departamento,
          trabajadorCiudad: this.trabajador.ciudad,
          trabajadorTelefono: '',
          trabajadorCelular: this.trabajador.celular,
        });
        if (this.trabajador.telefono !== undefined) {
          this.trabajadorFormPaso1.patchValue({
            trabajadorTelefono: this.trabajador.telefono,
          });
        }
        /** Guarda los puntos de venta asignados */
        // this.puntos_entrega = this.trabajador.puntos_entrega;
        /**
         * Se obtiene la lista de ciudades
         */
        this.getPlaces();
        this.getPuntosEntrega(this.trabajador.distribuidor!);
      })
      .catch((err) => {
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg =
          'No fue posible recuperar la información de este trabajador. Por favor intenta de nuevo más tarde.';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigate(['/cuenta']);
        };
      });
  }
  countPropietarios(id: any) {
    this.restService
      .getJWT('propietariosDistribuidores/' + id)
      .toPromise()
      .then((resp: any) => {
        this.cant_propietario = resp.data;
      })
      .catch((err) => {
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg =
          'No fue posible recuperar la información de este trabajador. Por favor intenta de nuevo más tarde.';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigate(['/cuenta']);
        };
      });
  }
  getPuntosTrabajador() {
    const id_trab: string = this.activatedRoute.snapshot.params.id;
    this.restService
      .getJWT('distribuidores_vinculados_by_trabajador/' + id_trab)
      .toPromise()
      .then((resp: any) => {
        this.puntos_entrega = resp.data;
      })
      .catch((err) => {
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg =
          'No fue posible recuperar la información de este trabajador. Por favor intenta de nuevo más tarde.';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigate(['/cuenta']);
        };
      });
  }

  /**
   * Recupera los departamentos y ciudades de Colombia
   */
  async getPlaces() {
    let id_dpto = 0;
    for (const dpto of this.departamentos) {
      if (dpto.name == this.trabajadorFormPaso1.get('trabajadorDepartamento')?.value) {
        break;
      }
      id_dpto++;
    }
    if (this.departamentos[id_dpto] != undefined) {
      await this.places.getCitiesFromServerImagine(this.departamentos[id_dpto].code);
      this.ciudades = this.places.cities_colombia;
    } else {
      this.ciudades = [];
    }
  }

  /**
   * Recupera la información de los puntos de entrega del usuario autenticado
   */
  getPuntosEntrega(id_distribuidor: string) {
    try {
      this.restService
        .getJWT(`/establecimientos_vinculados_a_distribuidores_aprobados/${id_distribuidor}`)
        .toPromise()
        .then((resp: any) => {
          this.lista_establecimientos_vinculados = resp.data;
          this.getEstablecimientoNombre();
        });
    } catch (err) {
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'No fue posible recuperar la lista de puntos de venta. Por favor intenta de nuevo más tarde';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {};
    }
  }

  /**
   * Array con el nombre del establecimiento al que pertenece cada punto de venta
   */
  getEstablecimientoNombre() {
    this.puntos_entrega.forEach((_id_punto: any) => {
      this.vinculaciones_no_asociadas.push(_id_punto._id);
      this.lista_establecimientos_vinculados.forEach((element: any) => {
        this.vinculaciones_no_asociadas.push(element.puntos[0]._id);
        if (element.puntos[0]._id === _id_punto._id) {
          this.nombre_establecimientos_por_punto.push(element.puntos[0].horeca[0].nombre_establecimiento);
        }
      });
    });
  }

  /**
   * Editar los puntos de entrega asociados al trabajador
   */
  editarPuntosEntrega() {
    const modalRef = this.modalService.open(EditarPuntosAsignadosComponent, this.ngbModalOptions);
    modalRef.componentInstance.puntos_entrega_asignados = this.puntos_entrega;
    modalRef.componentInstance.id_trabajador = this.activatedRoute.snapshot.params.id;
  }
  ejecutaActualizar() {
    const id_trabajador = this.activatedRoute.snapshot.params.id;
    /**
     * Recuperamos la información del formulario y actualizamos los datos del trabajador
     */
    this.trabajador.numero_documento = this.trabajadorFormPaso1.get('trabajadorDocumento')?.value;
    this.trabajador.tipo_documento = this.trabajadorFormPaso1.get('trabajadorTipo')?.value;
    this.trabajador.nombres = this.trabajadorFormPaso1.get('trabajadorNombre')?.value;
    this.trabajador.apellidos = this.trabajadorFormPaso1.get('trabajadorApellido')?.value;
    this.trabajador.tipo_trabajador = this.trabajadorFormPaso1.get('trabajadorTipoUsuario')?.value;
    this.trabajador.pais = this.trabajadorFormPaso1.get('trabajadorPais')?.value;
    this.trabajador.departamento = this.trabajadorFormPaso1.get('trabajadorDepartamento')?.value;
    this.trabajador.ciudad = this.trabajadorFormPaso1.get('trabajadorCiudad')?.value;
    this.trabajador.celular = this.trabajadorFormPaso1.get('trabajadorCelular')?.value;
    this.trabajador.telefono = this.trabajadorFormPaso1.get('trabajadorTelefono')?.value;
    delete this.trabajador._id;
    delete this.trabajador.organizacion;
    delete this.trabajador.usuario_horeca;
    delete this.trabajador.crado_por_horeca;
    try {
      this.restService
        .putJWT('trabajador/' + id_trabajador, this.trabajador)
        .toPromise()
        .then(() => {
          const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          modalRef.componentInstance.msg = 'Los datos del trabajador fueron actualizados con éxito.';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            window.location.reload();
          };
        });
    } catch (error) {
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'No fue posible actualizar la información de este trabajador. Por favor intenta de nuevo más tarde.';
      modalRef.componentInstance.btn_msg = 'Volver';
    }
  }
  /**
   * Actualiza los datos del trabajador en la base de datos
   */
  actualizarTrabajador() {
    if (
      this.copy_perfil === 'PROPIETARIO/REP LEGAL' &&
      this.trabajadorFormPaso1.get('trabajadorTipoUsuario')?.value !== this.copy_perfil
    ) {
      if (this.cant_propietario > 1) {
        this.ejecutaActualizar();
      } else {
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'Recuerda que debe existir al menos 1 PROPIETARIO/REP LEGAL.';
        modalRef.componentInstance.btn_msg = 'Volver';
      }
    } else {
      this.ejecutaActualizar();
    }
  }

  /**
   * Si no cumple no entra a la asignacion según estado del formulario.
   * Se realiza este paso dado que el telefono del trabajador
   * solo se verifica si se ingresa al menos un digito en el input
   */
  habilitarBoton() {
    this.is_boton_habilitado = false;
    if (
      this.trabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length != 7 &&
      this.trabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length >= 1 &&
      this.trabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length != 10
    ) {
      return;
    } else {
      this.is_boton_habilitado = !this.trabajadorFormPaso1.invalid;
    }
  }

  /**
   * Lanza un diálogo de confirmación. Si el usuario selecciona
   * que sí, se re-activa al trabajador actual. Si no, se cierra
   * el diálogo sin hacer nada
   */
  activarTrabajador() {
    const modalRef = this.modalService.open(ConfirmacionComponent, this.ngbModalOptions);
    modalRef.componentInstance.msg = '¿Estás seguro de activar este elemento?';
    modalRef.componentInstance.btn_msg_no = 'Cancelar';
    modalRef.componentInstance.btn_msg_yes = 'Activar';
    modalRef.componentInstance.callback_no = () => {};
    modalRef.componentInstance.callback_yes = () => {
      const obj_aux: any = {
        solicitud_vinculacion: 'Aprobado',
      };
      this.restService
        .putJWT('trabajador/' + this.trabajador._id, obj_aux)
        .toPromise()
        .then(() => {
          const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          modalRef.componentInstance.msg = 'El trabajador fue activado correctamente.';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            window.location.reload();
          };
        })
        .catch(() => {
          const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg =
            'El trabajador no pudo ser activado por un error inesperado. Intenta de nuevo más tarde.';
          modalRef.componentInstance.btn_msg = 'Volver';
        });
    };
  }
  desactivarTrabajador() {
    if (this.copy_perfil === 'PROPIETARIO/REP LEGAL') {
      if (this.cant_propietario > 1) {
        this.ejecutaDesactivarTrabajador();
      } else {
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'Recuerda que debe existir al menos 1 PROPIETARIO/REP LEGAL.';
        modalRef.componentInstance.btn_msg = 'Volver';
      }
    } else {
      this.ejecutaDesactivarTrabajador();
    }
  }
  /**
   * Lanza un diálogo de confirmación. Si el usuario selecciona
   * que sí, se desactiva al trabajador actual. Si no, se cierra
   * el diálogo sin hacer nada
   */
  ejecutaDesactivarTrabajador() {
    const modalRef = this.modalService.open(ConfirmacionComponent, this.ngbModalOptions);
    modalRef.componentInstance.msg = '¿Estás seguro de desactivar este trabajador?';
    modalRef.componentInstance.btn_msg_no = 'Cancelar';
    modalRef.componentInstance.btn_msg_yes = 'Desactivar';
    modalRef.componentInstance.callback_no = () => {};
    modalRef.componentInstance.callback_yes = () => {
      const obj_aux: any = {
        solicitud_vinculacion: 'Cancelado',
      };
      this.restService
        .putJWT('trabajador/' + this.trabajador._id, obj_aux)
        .toPromise()
        .then(() => {
          const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          modalRef.componentInstance.msg = 'El trabajador fue desactivado correctamente.';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            window.location.reload();
          };
        })
        .catch(() => {
          const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg =
            'El trabajador no pudo ser desactivado por un error inesperado. Intenta de nuevo más tarde.';
          modalRef.componentInstance.btn_msg = 'Volver';
        });
    };
  }

  /**
   * Permite cambiar la contraseña de un trabjador
   */
  cambiarContrasena() {
    const modalRef = this.modalService.open(CambiarContrasenaComponent);
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateOnlyText(event: any) {
    const keyCode = event.keyCode;
    if (keyCode >= 48 && keyCode <= 57) {
      event.preventDefault();
    }
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateNumber(event: any) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || excludedKeys.includes(keyCode))) {
      event.preventDefault();
    }
  }

  /**
   * Alerta si el formulario esta incompleto o un input es invalido
   */
  alertaFormularioInvalido() {
    this.trabajadorFormPaso1.markAllAsTouched();
    const modalRef = this.modalService.open(SimpleComponent);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = '¡Por favor asegúrate de llenar todos los datos y vuelve a intentarlo!';
    modalRef.componentInstance.btn_msg = 'Volver';
  }
}
