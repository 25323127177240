<div class="main-container" [formGroup]="distribuidorForm">
  <!---------Header empresa--------->
  <div class="card p-5 mt-4">
    <div class="mb-4 d-flex flex-row">
      <div class="logo-wrapper">
        <img [src]="imageSrc || empresa.logo || empresa_Placeholder" id="logo-empresa" alt="Logo del horeca" />
      </div>
      <div class="d-flex justify-content-between align-content-between flex-column pl-4">
        <h5>
          {{ distribuidorForm.get('distribuidorNombre')?.value }}
        </h5>
        <div class="upload">
          <input type="file" id="upload" hidden (change)="captureLogo($event)" #fileUploadLogo />
          <label for="upload" class="btn-upload m-0 p-0" (click)="fileUploadLogo.click()">Cambiar logo</label>
        </div>
      </div>
    </div>
    <!--Correo de la empresa-->
    <div class="correo-ingreso col-12 p-0 mb-2 mt-3">
      <h5>Correo de la empresa</h5>
      <div class="col-12 p-0 mb-2">
        <input formControlName="distribuidorCorreo" type="email" placeholder="No registra" [attr.disabled]="true" />
        <span
          class="reactive-text text-danger"
          *ngIf="
            distribuidorForm.get('distribuidorCorreo')?.hasError('email') &&
            distribuidorForm.get('distribuidorCorreo')?.touched
          ">
          <span class="alerta">Ingresa un email valido</span>
        </span>
      </div>
    </div>
    <div class="col-12 p-0 my-2">
      <h5>Sobre la empresa</h5>
    </div>
    <div class="bottom-form mb-1">
      <!--Nombre del distribuidor-->
      <div class="nombre-empresa">
        <label for="nombre_establecimiento">
          <span class="asterisco">*</span>
          Nombre del distribuidor
        </label>
        <input
          formControlName="distribuidorNombre"
          name="nombre_establecimiento"
          placeholder="Escribe aquí"
          [ngClass]="{
            'invalid-warning':
              distribuidorForm.get('distribuidorNombre')?.hasError('required') &&
              distribuidorForm.get('distribuidorNombre')?.touched
          }" />
      </div>
      <!--Razón social-->
      <div class="razon-social">
        <label for="razon_social">
          <span class="asterisco">*</span>
          Razón social
        </label>
        <input
          formControlName="distribuidorRazon"
          [attr.disabled]="true"
          [ngClass]="{
            'invalid-warning':
              distribuidorForm.get('distribuidorRazon')?.hasError('required') &&
              distribuidorForm.get('distribuidorRazon')?.touched
          }"
          name="razon_social"
          placeholder="Escribe aquí" />
      </div>
      <!--NIT-->
      <div class="nit-empresa">
        <label for="nit">
          <span class="asterisco">*</span>
          NIT/Documento propietario
        </label>
        <input
          formControlName="distribuidorNit"
          [attr.disabled]="true"
          [ngClass]="{
            'invalid-warning':
              distribuidorForm.get('distribuidorNit')?.hasError('required') &&
              distribuidorForm.get('distribuidorNit')?.touched
          }"
          name="nit"
          placeholder="Escribe aquí"
          (keydown)="validateNumber($event)" />
      </div>
      <!--Descripción-->
      <div class="descripcion-empresa">
        <label for="documento"><span class="asterisco">*</span>Descripción del distribuidor y datos de cuenta bancaria</label>
        <textarea #text formControlName="distribuidorDescripcion" name="documento" placeholder="Escribe aquí"
          [(ngModel)]="descripcionModelo" (ngModelChange)="determinarCaracteres()"
          [ngModelOptions]="{standalone: true}" required="true" value="500"
          maxlength="500" data-attr="500" rows="2" class="p-3"
          [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorDescripcion')?.hasError('required') && distribuidorForm.get('distribuidorDescripcion')?.touched || ( distribuidorForm.get('distribuidorDescripcion')?.hasError('pattern') && distribuidorForm.get('distribuidorDescripcion')?.touched ) )}">
        </textarea>
        <span id="characterCount" class="text-secondary">Caracteres restantes: {{remainingText}}</span>
        <span class="reactive-text text-danger"
          *ngIf="distribuidorForm.get('distribuidorDescripcion')?.hasError('pattern') && distribuidorForm.get('distribuidorDescripcion')?.touched">
          <span class="alerta">Mínimo 20 carácteres</span>
        </span>
      </div>
      <!--Tipo de establecimiento-->
      <div class="tipo-empresa">
        <label for="tipo_negocio">
          <span class="asterisco">*</span>
          Tipo de distribuidor
        </label>
        <select
          formControlName="distribuidorTipo"
          class="d-inline-block dropdown-form-1"
          [ngClass]="{
            'invalid-warning':
              distribuidorForm.get('distribuidorTipo')?.hasError('required') &&
              distribuidorForm.get('distribuidorTipo')?.touched
          }">
          <option selected disabled value="">Selecciona</option>
          <!--<option [value]="tipo" *ngFor="let tipo of tipos_dist;">{{tipo}}</option>-->
          <option value="Bebidas">Bebidas</option>
          <option value="Frutas y verduras">Frutas y verduras</option>
          <option value="Licores">Licores</option>
          <option value="Cárnicos">Cárnicos</option>
          <option value="Lácteos">Lácteos</option>
          <option value="Aseo y otros">Aseo y otros</option>
          <option value="Maquinaria e implementos">Maquinaria e implementos</option>
          <option value="Especializado - general">Especializado - general</option>
        </select>
      </div>
      <!--Telefono-->
      <div class="celular-empresa">
        <label for="telefono">
          <span class="asterisco">*</span>
          Teléfono (7 o 10 dígitos)
        </label>
        <input
          formControlName="distribuidorTelefono"
          name="telefono"
          type="number"
          placeholder="Escribe aquí"
          (keydown)="validateNumber($event)"
          [ngClass]="{
            'invalid-warning':
              (distribuidorForm.get('distribuidorTelefono')?.hasError('required') &&
                distribuidorForm.get('distribuidorTelefono')?.touched) ||
              (distribuidorForm.get('distribuidorTelefono')?.hasError('pattern') &&
                distribuidorForm.get('distribuidorTelefono')?.touched)
          }" />
        <span
          class="w-100 text-danger fw-bold text-left"
          *ngIf="
            distribuidorForm.get('distribuidorTelefono')?.hasError('pattern') &&
            distribuidorForm.get('distribuidorTelefono')?.touched
          ">
          <span class="alerta">Númerico de 7 o 10 dígitos</span>
        </span>
      </div>
      <!--Teléfono 2-->
      <div class="telefono-empresa">
        <label for="telefono">Teléfono 2 (Opcional)</label>
        <input
          formControlName="distribuidorTelefono2"
          name="telefono2"
          type="text"
          placeholder="Escribe aquí"
          (keydown)="validateNumber($event)"
          [ngClass]="{
            'invalid-warning':
              this.distribuidorForm.get('distribuidorTelefono2')?.value.toString().length >= 1 &&
              distribuidorForm.get('distribuidorTelefono2')?.touched &&
              this.distribuidorForm.get('distribuidorTelefono2')?.value.toString().length != 7 &&
              this.distribuidorForm.get('distribuidorTelefono2')?.value.toString().length != 10
          }" />
        <span
          class="reactive-text text-danger"
          *ngIf="
            this.distribuidorForm.get('distribuidorTelefono2')?.value.toString().length >= 1 &&
            distribuidorForm.get('distribuidorTelefono2')?.touched &&
            this.distribuidorForm.get('distribuidorTelefono2')?.value.toString().length != 7 &&
            this.distribuidorForm.get('distribuidorTelefono2')?.value.toString().length != 10
          ">
          <span class="alerta">Númerico de 7 o 10 dígitos</span>
        </span>
      </div>
      <!--País-->
      <div class="pais-empresa">
        <label for="pais">
          <span class="asterisco">*</span>
          País
        </label>
        <select
          formControlName="distribuidorPais"
          class="d-inline-block dropdown-form-1"
          [ngClass]="{
            'invalid-warning':
              distribuidorForm.get('distribuidorPais')?.hasError('required') &&
              distribuidorForm.get('distribuidorPais')?.touched
          }">
          <option selected disabled value="Colombia">Colombia</option>
        </select>
      </div>
      <!--Departamento-->
      <div class="departamento-empresa">
        <label for="departamento">
          <span class="asterisco">*</span>
          Departamento
        </label>
        <select
          formControlName="distribuidorDepartamento"
          class="d-inline-block dropdown-form-1"
          (change)="getPlaces()"
          (change)="distribuidorForm.patchValue({ distribuidorCiudad: '' })"
          [ngClass]="{
            'invalid-warning':
              distribuidorForm.get('distribuidorDepartamento')?.hasError('required') &&
              distribuidorForm.get('distribuidorDepartamento')?.touched
          }">
          <option [ngValue]="dpto.name" *ngFor="let dpto of departamentos_empresa">
            {{ dpto.name }}
          </option>
        </select>
      </div>
      <!--Ciudad-->
      <div class="ciudad-empresa">
        <label for="ciudad">
          <span class="asterisco">*</span>
          Ciudad
        </label>
        <select
          formControlName="distribuidorCiudad"
          class="d-inline-block dropdown-form-1"
          (change)="getLatLongDireccion()"
          (ngClass)="
            {
              'invalid-warning':
                distribuidorForm.get('distribuidorCiudad')?.hasError('required') &&
                distribuidorForm.get('distribuidorCiudad')?.touched
            }
          ">
          <option selected disabled value="">Selecciona</option>
          <option value="{{ city.name }}" *ngFor="let city of ciudades">{{ city.name }}</option>
        </select>
      </div>
      <!--Direccion-->
      <div class="direccion-empresa">
        <label for="direccion">
          <span class="asterisco">*</span>
          Dirección
        </label>
        <input
          #direccionInput
          formControlName="distribuidorDireccion"
          name="direccion"
          type="text"
          placeholder="Escribe aquí"
          [(ngModel)]="direccionAux"
          [ngModelOptions]="{standalone: true}"
          (keyup.enter)="enterMapRegistro()"
          [ngClass]="{
            'invalid-warning':
              distribuidorForm.get('distribuidorDireccion')?.hasError('required') &&
              distribuidorForm.get('distribuidorDireccion')?.touched
          }" />
      </div>
      <!--Mapa-->
      <div class="ubicacion-distribuidor">
        <label class="w-100">
          Ubicación
        </label>
        <div class="w-100 rounded overflow-hidden">
          <agm-map
            fit="true"
            [latitude]="map.lat"
            [longitude]="map.lng"
            [mapTypeControl]="true"
            (mapReady)="mapReadyHandler($event)">
            <agm-marker [latitude]="map.lat" [longitude]="map.lng"></agm-marker>
          </agm-map>
        </div>
      </div>
      <div class="cobertura-empresa mb-3">
        <div class="cobertura_mapa d-flex flex-column">
          <label class="w-100">
            <span class="asterisco">*</span>
            Indica uno o varios lugares para la(s) zona(s) de cobertura
          </label>
          <span>Confirma los puntos limítrofes para establecer las zonas de cobertura, seleccionando en el mapa los lugares que van a pertenecer a dicha cobertura formando una especie de figura</span>
          <span class="text-secondary" style="font-size: 14px">
            Los marcadores rojos indican las ubicaciones de los puntos de entrega de los establecimientos
          </span>
        </div>
      </div>
      <!--Departamento zona-->
      <div class="departamento-zona">
        <label for="departamento">
          <span class="asterisco">*</span>
          Departamento zona
        </label>
        <select
          class="d-inline-block dropdown-form-1"
          [(ngModel)]="departamentoPoligono"
          [ngModelOptions]="{standalone: true}"
          (change)="getPlacesPoligonos()">
          <option [ngValue]="dpto.name" *ngFor="let dpto of departamentosPoligono">
            {{ dpto.name }}
          </option>
        </select>
      </div>
      <!--Ciudad zona-->
      <div class="ciudad-zona">
        <label for="ciudad">
          <span class="asterisco">*</span>
          Ciudad zona
        </label>
        <select
          class="d-inline-block dropdown-form-1"
          [(ngModel)]="ciudadPoligono"
          [ngModelOptions]="{standalone: true}"
          (change)="getLatLongDireccion(true)">
          <option selected disabled value="">Selecciona</option>
          <option value="{{ city.name }}" *ngFor="let city of ciudadesPoligono">{{ city.name }}</option>
        </select>
      </div>
      <div class="mapa-empresa">
        <div class="w-100 rounded overflow-hidden">
          <agm-map
            [latitude]="mapPoligono.lat"
            [longitude]="mapPoligono.lng"
            (mapReady)="onMapReady($event)"
            [zoom]="10">
            <agm-marker *ngFor="let coord of coordenadasPuntos" [latitude]="coord.lat" [longitude]="coord.lng" 
            [iconUrl]="icon"></agm-marker>
            <agm-polygon
               [fillOpacity]="0.5" [paths]="paths" [strokeOpacity]="0.6" [strokeWeight]="2"
               [visible]="true" [zIndex]="1"></agm-polygon>
          </agm-map>

          <div class="form-group mt-3" *ngIf="coordMulti.length > 0">
            <div class="row datos-coordenadas mb-3" *ngFor="let coordData of coordMulti; let i = index;">
              <div class="col-md-2 text-center centrar-contenido">
                <div class="item-numero-poligono" [style]="{ 'background-color': listaColores[i], 'border': '1px solid '+ listaColores[i] }">{{ (i + 1) }}</div>
              </div>
              <div class="col-md-3">
                <label>Tipo de tiempo</label>
                <select *ngIf="i === 0" class="dropdown-form-1" [(ngModel)]="valoresPedido[i].tipo_promesa" [ngModelOptions]="{standalone: true}">
                  <option value="horas">Horas</option>
                </select>
                <div *ngIf="i > 0">--/--</div>
              </div>
              <div class="col-md-3">
                <label>Promesa entrega</label>
                <div *ngIf="i === 0">
                  <select *ngIf="valoresPedido[i].tipo_promesa === 'horas'" class="dropdown-form-1" [(ngModel)]="valoresPedido[i].valor_promesa" [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let hour of listaHoras" value="{{hour}}">{{hour}}</option>
                  </select>
                  <select *ngIf="valoresPedido[i].tipo_promesa === 'dias'" class="dropdown-form-1" [(ngModel)]="valoresPedido[i].valor_promesa" [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let dia of listaDias" value="{{dia}}">{{dia}}</option>
                  </select>
                  <select *ngIf="valoresPedido[i].tipo_promesa === 'especifico'" class="dropdown-form-1" [(ngModel)]="valoresPedido[i].valor_promesa" [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let diaS of diasSemana" value="{{diaS}}">{{diaS}}</option>
                  </select>
                </div>
                <div *ngIf="i > 0">--/--</div>
              </div>
              <div class="col-md-3">
                <label>Valor Min Pedido</label>
                <input
                  *ngIf="i === 0"
                  placeholder="Valor mínimo"
                  [(ngModel)]="valoresPedido[i].valor_pedido"
                  [ngModelOptions]="{standalone: true}"
                  type="text"
                  (keydown)="validateNumber($event)"
                  (blur)="transformAmountValue(i, $event)" (focus)="transformCurrencyValue(i, $event)"/>
                <div *ngIf="i > 0">--/--</div>
              </div>
              <div class="col-md-1 text-center">
                <fa-icon [icon]="faTimesCircle" class="icon-remove-point" (click)="removePolygon(i)"></fa-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Horarios y fechas disponibles -->
      <div class="disponibilidad-empresa">
        <label class="w-100 m-0">
          <span class="asterisco">*</span>
          Días de atención
        </label>
        <div class="px-3">
          <span class="w-100 text-danger fw-bold text-left rounded" *ngIf="validator_dias_atencion === false">
            <span class="alerta">Debe seleccionar por lo menos una opción</span>
          </span>
          <!--Lunes a viernes-->
          <div class="w-100">
            <mat-checkbox class="example-margin m-0" formControlName="distribuidorHorario_L_V">
              Lunes a viernes
            </mat-checkbox>
          </div>
          <div class="d-flex w-100 flex-column px-2">
            <div class="d-flex w-100 flex-column custom-slider">
              <ngx-slider
                [(value)]="minValueEntreSemana"
                [(highValue)]="maxValueEntreSemana"
                [options]="options"></ngx-slider>
              <div class="w-100">
                <p class="w-100 text-right mb-1">
                  {{ valueToTime(minValueEntreSemana) }} - {{ valueToTime(maxValueEntreSemana) }}
                </p>
              </div>
            </div>
          </div>
          <!--Sábados-->
          <div class="w-100">
            <mat-checkbox class="example-margin m-0" formControlName="distribuidorHorario_S">Sábados</mat-checkbox>
          </div>
          <div class="d-flex w-100 flex-column px-2">
            <div class="d-flex w-100 flex-column">
              <div class="w-100 custom-slider">
                <ngx-slider
                  [(value)]="minValueSabados"
                  [(highValue)]="maxValueSabados"
                  [options]="options"></ngx-slider>
              </div>
              <div class="w-100">
                <p class="w-100 text-right mb-1">
                  {{ valueToTime(minValueSabados) }} - {{ valueToTime(maxValueSabados) }}
                </p>
              </div>
            </div>
          </div>
          <!--Domingos-->
          <div class="w-100">
            <mat-checkbox class="example-margin m-0" formControlName="distribuidorHorario_D">Domingos</mat-checkbox>
          </div>
          <div class="d-flex w-100 flex-column mb-4">
            <div class="d-flex w-100 flex-column px-2">
              <div class="w-100 custom-slider">
                <ngx-slider
                  [(value)]="minValueDomingos"
                  [(highValue)]="maxValueDomingos"
                  [options]="options"></ngx-slider>
              </div>
              <div class="w-100">
                <p class="w-100 text-right mb-1">
                  {{ valueToTime(minValueDomingos) }} - {{ valueToTime(maxValueDomingos) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Medios de pago aceptados-->
      <div class="metodo-pago-empresa d-flex w-100 flex-column">
        <div class="w-100 d-flex flex-column mb-2">
          <span class="w-100 text-left">
            <span class="asterisco">*</span>
            Medios de pago
          </span>
          <span class="w-100 text-danger fw-bold text-left rounded px-3" *ngIf="validator_tiempo_entrega === false">
            <span class="alerta">Debe seleccionar por lo menos un método</span>
          </span>
        </div>
        <div class="w-100 metodos-container">
          <div class="col-6 d-flex justify-content-center">
            <mat-checkbox
              formControlName="distribuidorMetodoPagoCredito"
              class="example-margin"
              name="credito"
              formControlName="distribuidorMetodoPagoCredito">
              Crédito
            </mat-checkbox>
          </div>
          <div class="col-6 d-flex justify-content-center">
            <mat-checkbox
              formControlName="distribuidorMetodoPagoEfectivo"
              class="example-margin"
              name="efectivo"
              formControlName="distribuidorMetodoPagoEfectivo">
              Efectivo (contra entrega)
            </mat-checkbox>
          </div>
          <div class="col-6 d-flex justify-content-center">
            <mat-checkbox
              formControlName="distribuidorMetodoPagoTransferencia"
              class="example-margin"
              name="banco"
              formControlName="distribuidorMetodoPagoTransferencia">
              Transacción bancaria
            </mat-checkbox>
          </div>
          <div class="col-6 d-flex justify-content-center">
            <mat-checkbox
              formControlName="distribuidorMetodoPagoDatafono"
              class="example-margin"
              name="datafono"
              formControlName="distribuidorMetodoPagoDatafono">
              Datáfono (contra entrega)
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-------Documentación------->
  <div class="card p-5 mt-4">
    <div class="row">
      <div class="col-12 mb-2">
        <h5>
          <span class="asterisco">*</span>
          Link de PAGO
        </h5>
      </div>
      <div class="col-12 mb-2">
        <input formControlName="urlPago" name="Link de pago" type="text" placeholder="Link de pago" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-2">
        <h5>
          <span class="asterisco">*</span>
          Documentación
          <h6 class="text-secondary font-weight-light">Máximo 2MB por archivo</h6>
        </h5>
      </div>
    </div>
    <div class="documents-container p-1 mb-3">
      <!--RUT-->
      <div class="w-100 rut-documento">
        <h6 class="w-100">RUT:</h6>
        <div class="w-100 row m-0 p-0" *ngIf="rutPDF !== ''">
          <span class="col-10 text-right d-flex align-items-center justify-content-end">
            RUT_{{ auth.user_distribuidor!.nombre }}.pdf
          </span>
          <a target="_blank" rel="noopener" href="{{ rutPDF }}" class="col-2 btn ojo">
            <fa-icon [icon]="faEye"></fa-icon>
          </a>
        </div>
        <div class="w-100 row m-0 p-0" *ngIf="rutPDF === ''">
          <span class="col-10 text-right d-flex align-items-center justify-content-end">
            No está cargado este archivo
          </span>
          <a class="col-2 btn ojo">
            <fa-icon [icon]="faEyeSlash"></fa-icon>
          </a>
        </div>
        <div class="w-100 m-0 p-0">
          <input type="file" id="file" #fileInputRUT required hidden (change)="captureDocument($event, 1)" />
          <label for="file" class="w-100 row m-0">
            <span class="col-10 text-right d-flex align-items-center justify-content-end">Cambiar Documento</span>
            <fa-icon [icon]="faPaperclip" class="col-2 btn clip"></fa-icon>
          </label>
          <div class="w-100 row m-0">
            <span *ngIf="rutName" class="col-10 text-right d-flex align-items-center justify-content-end">
              Nuevo Doc.: {{ rutName }}
            </span>
            <button *ngIf="rutName" type="button" class="col-2 btn borrar" (click)="clearSelection(1)">
              <fa-icon [icon]="faTrash"></fa-icon>
            </button>
          </div>
        </div>
        <span class="reactive-text text-danger" *ngIf="rutPDF == ''">
          <span class="alerta">Requerido</span>
        </span>
      </div>
      <!--Cámara de comercio-->
      <div class="w-100 camara-documento">
        <h6 class="w-100">Cámara de Comercio:</h6>
        <div class="w-100 row m-0 p-0" *ngIf="camComPDF !== ''">
          <span class="col-10 text-right d-flex align-items-center justify-content-end">
            Camara_{{ auth.user_distribuidor!.nombre }}.pdf
          </span>
          <a target="_blank" rel="noopener" href="{{ camComPDF }}" class="col-2 btn ojo">
            <fa-icon [icon]="faEye"></fa-icon>
          </a>
        </div>
        <div class="w-100 row m-0 p-0" *ngIf="camComPDF === ''">
          <span class="col-10 text-right d-flex align-items-center justify-content-end">
            No está cargado este archivo
          </span>
          <a class="col-2 btn ojo">
            <fa-icon [icon]="faEyeSlash"></fa-icon>
          </a>
        </div>
        <div class="w-100 m-0 p-0">
          <input type="file" id="file2" #fileInputCamCom required hidden (change)="captureDocument($event, 2)" />
          <label for="file2" class="w-100 row m-0">
            <span class="col-10 text-right d-flex align-items-center justify-content-end">Cambiar Documento</span>
            <fa-icon [icon]="faPaperclip" class="col-2 btn clip"></fa-icon>
          </label>
          <div class="w-100 row m-0">
            <span *ngIf="camComName" class="col-10 text-right d-flex align-items-center justify-content-end">
              Nuevo Doc.: {{ camComName }}
            </span>
            <button *ngIf="camComName" type="button" class="col-2 btn borrar" (click)="clearSelection(2)">
              <fa-icon [icon]="faTrash"></fa-icon>
            </button>
          </div>
          <span class="reactive-text text-danger" *ngIf="camComPDF == '' && auth.user_distribuidor!.tipo == 'Empresa'">
            <span class="alerta">Requerido</span>
          </span>
        </div>
      </div>
      <!--Doc. Representante legal-->
      <div class="w-100 representante-documento">
        <h6 class="w-100">Doc. Representante Legal:</h6>
        <div class="w-100 row m-0 p-0" *ngIf="CCPDF !== ''">
          <span class="col-10 text-right d-flex align-items-center justify-content-end">
            Cedula_{{ auth.user_distribuidor!.nombre }}.pdf
          </span>
          <a target="_blank" rel="noopener" href="{{ CCPDF }}" class="col-2 btn ojo">
            <fa-icon [icon]="faEye"></fa-icon>
          </a>
        </div>
        <div class="w-100 row m-0 p-0" *ngIf="CCPDF === ''">
          <span class="col-10 text-right d-flex align-items-center justify-content-end">
            No está cargado este archivo
          </span>
          <a class="col-2 btn ojo">
            <fa-icon [icon]="faEyeSlash"></fa-icon>
          </a>
        </div>
        <div class="w-100 m-0 p-0">
          <input type="file" id="file3" #fileInputCC required hidden (change)="captureDocument($event, 3)" />
          <label for="file3" class="w-100 row m-0">
            <span class="col-10 text-right d-flex align-items-center justify-content-end">Cambiar Documento</span>
            <fa-icon [icon]="faPaperclip" class="col-2 btn clip"></fa-icon>
          </label>
          <div class="w-100 row m-0">
            <span *ngIf="CCName" class="col-10 text-right d-flex align-items-center justify-content-end">
              Nuevo Doc.: {{ CCName }}
            </span>
            <button *ngIf="CCName" type="button" class="col-2 btn borrar" (click)="clearSelection(3)">
              <fa-icon [icon]="faTrash"></fa-icon>
            </button>
          </div>
        </div>
        <span class="reactive-text text-danger" *ngIf="CCPDF == ''">
          <span class="alerta">Requerido</span>
        </span>
      </div>
    </div>
    <div class="documents-container p-1">
      <!--Certificación bancaria-->
      <div class="w-100 rut-documento">
        <h6 class="w-100">Doc. Certificación Bancaria:</h6>
        <div class="w-100 row m-0 p-0" *ngIf="cBancaria !== ''">
          <span class="col-10 text-right d-flex align-items-center justify-content-end">
            Cuenta_{{ auth.user_distribuidor!.nombre }}.pdf
          </span>
          <a target="_blank" rel=noopener href={{cBancaria}} class="col-2 btn ojo">
            <fa-icon [icon]="faEye"></fa-icon>
          </a>
        </div>
        <div class="w-100 row m-0 p-0" *ngIf="cBancaria === ''">
          <span class="col-10 text-right d-flex align-items-center justify-content-end">
            No está cargado este archivo
          </span>
          <a class="col-2 btn ojo">
            <fa-icon [icon]="faEyeSlash"></fa-icon>
          </a>
        </div>
        <div class="w-100  m-0 p-0">
          <input type="file" id="file4" #fileInputCert required hidden (change)="captureDocument($event, 4)">
          <label for="file4" class="w-100 row m-0">
            <span class="col-10 text-right d-flex align-items-center justify-content-end">Cambiar Documento</span>
            <fa-icon [icon]="faPaperclip" class="col-2 btn clip"></fa-icon>
          </label>
          <div class="w-100 row m-0">
            <span *ngIf="certName" class="col-10 text-right d-flex align-items-center justify-content-end">Nuevo Doc.:
              {{certName}}</span>
            <button *ngIf="certName" type="button" class="col-2 btn borrar" (click)="clearSelection(4)">
              <fa-icon [icon]="faTrash"></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <!--Creacion cliente-->
      <div class="w-100 camara-documento">
        <h6 class="w-100">Creación de Cliente:</h6>
        <div class="w-100 row m-0 p-0" *ngIf="creacionCliente !== ''">
          <span class="col-10 text-right d-flex align-items-center justify-content-end">
            Cliente_{{auth.user_distribuidor!.nombre }}.pdf
          </span>
          <a target="_blank" rel=noopener href={{creacionCliente}} class="col-2 btn ojo">
            <fa-icon [icon]="faEye"></fa-icon>
          </a>
        </div>
        <div class="w-100 row m-0 p-0" *ngIf="creacionCliente === ''">
          <span class="col-10 text-right d-flex align-items-center justify-content-end">
            No está cargado este archivo
          </span>
          <a class="col-2 btn ojo">
            <fa-icon [icon]="faEyeSlash"></fa-icon>
          </a>
        </div>
        <div class="w-100  m-0 p-0">
          <input type="file" id="file5" #fileInputCreacion required hidden (change)="captureDocument($event, 5)">
          <label for="file5" class="w-100 row m-0">
            <span class="col-10 text-right d-flex align-items-center justify-content-end">
              Cambiar Documento
            </span>
            <fa-icon [icon]="faPaperclip" class="col-2 btn clip"></fa-icon>
          </label>
          <div class="w-100 row m-0">
            <span *ngIf="creacionName" class="col-10 text-right d-flex align-items-center justify-content-end">
              Nuevo Doc.: {{creacionName}}
            </span>
            <button *ngIf="creacionName" type="button" class="col-2 btn borrar" (click)="clearSelection(5)">
              <fa-icon [icon]="faTrash"></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <!--Aprobacion de credito  -->
      <div class="w-100 representante-documento">
        <h6 class="w-100">Aprobación de crédito:</h6>
        <div class="w-100 row m-0 p-0" *ngIf="credito !== ''">
          <span class="col-10 text-right d-flex align-items-center justify-content-end">
            Credito_{{ auth.user_distribuidor!.nombre }}.pdf
          </span>
          <a target="_blank" rel=noopener href={{credito}} class="col-2 btn ojo">
            <fa-icon [icon]="faEye"></fa-icon>
          </a>
        </div>
        <div class="w-100 row m-0 p-0" *ngIf="credito === ''">
          <span class="col-10 text-right d-flex align-items-center justify-content-end">
            No está cargado este archivo
          </span>
          <a class="col-2 btn ojo">
            <fa-icon [icon]="faEyeSlash"></fa-icon>
          </a>
        </div>
        <div class="w-100  m-0 p-0">
          <input type="file" id="file6" #fileInputCredito required hidden (change)="captureDocument($event, 6)">
          <label for="file6" class="w-100 row m-0">
            <span class="col-10 text-right d-flex align-items-center justify-content-end">Cambiar Documento</span>
            <fa-icon [icon]="faPaperclip" class="col-2 btn clip"></fa-icon>
          </label>
          <div class="w-100 row m-0">
            <span *ngIf="creditoName" class="col-10 text-right d-flex align-items-center justify-content-end">Nuevo Doc.:
              {{creditoName}}</span>
            <button *ngIf="creditoName" type="button" class="col-2 btn borrar" (click)="clearSelection(6)">
              <fa-icon [icon]="faTrash"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Guardar cambios-->
<div class="mt-4">
  <button
    class="btn-submit px-5"
    (click)="is_boton_habilitado && guardarCambios()"
    (click)="!is_boton_habilitado && alertaFormularioInvalido()"
    [ngClass]="{ 'btn-submit-disabled': !is_boton_habilitado }">
    Guardar cambios
  </button>
</div>
