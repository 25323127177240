import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Producto } from 'src/app/models/producto.model';
import { RestService } from 'src/app/services/rest/rest.service';
import { CargandoGenericoComponent } from '../cargando-generico/cargando-generico.component';
import { SimpleComponent } from '../simple/simple.component';

@Component({
  selector: 'app-cargar-catalogo',
  templateUrl: './cargar-catalogo.component.html',
  styleUrls: ['./cargar-catalogo.component.css'],
})
export class CargarCatalogoComponent implements OnInit {
  // Referencias a iconos FontAwesome
  public faTimes = faTimes;
  public faTimesCircle = faTimesCircle;
  // Los datos que devuelve el back sobre los productos creados
  @Input() back_data: any;
  // Información recibida del back sobre los productos cargados y cuántos no fueron cargados
  public max_prods_x_pag = 5;
  public pag_actual: Producto[] = [];
  public productos: Producto[] = [];
  public fotos: SafeUrl[][] = [];
  public file_fotos: File[][] = [];
  public error_formato_img = false;
  public prods_saltados = 0;
  // Imagen por defecto de un producto si el usuario no la carga
  public default_img_producto =
    'https://feat-resources.s3.amazonaws.com/productos/codigo_Ft-639ff26e7353573267bf29d3-imagen1';
  // Arreglo con los formatos validos de imagen
  supported_imgs: string[] = ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp'];

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private router: Router,
    private sanitizer: DomSanitizer,
    private rest: RestService
  ) {}

  ngOnInit(): void {
    this.procesarInfoProductos();
  }

  /**
   * Recibe un archivo del usuario y lo guarda
   * en el arreglo de imágenes del producto. Si
   * el archivo no tiene el formato correcto, no
   * se guarda y se muestra de error
   * @param event El evento generado al subir el archivo
   */
  handleFileInput(event: any, index: number) {
    this.error_formato_img = false;

    const file: File = event.target.files[0];

    if (file != null && this.fotos[index].length < 3) {
      const file_split: string[] = file.name.split('.');
      const file_end: string = file_split[file_split.length - 1].trim().toLowerCase();

      if (this.supported_imgs.includes(file_end)) {
        //Se está intentando subir un archivo de imagen
        this.file_fotos[index].push(file);
        this.fotos[index].push(
          this.sanitizer.sanitize(
            SecurityContext.NONE,
            this.sanitizer.bypassSecurityTrustUrl(
              URL.createObjectURL(this.file_fotos[index][this.file_fotos[index].length - 1])
            )
          ) || ''
        );
      } else {
        this.error_formato_img = true;
      }
    } else {
      const ngbModalOptions: NgbModalOptions = {
        //Evita que al hacer click por fuera se cierre el modal
        backdrop: 'static',
        keyboard: false,
        centered: true,
      };
      const modalRefSimple = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRefSimple.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRefSimple.componentInstance.title = '¡Oh oh!';
      modalRefSimple.componentInstance.msg = 'Puedes subir un máximo de 3 fotos por producto';
      modalRefSimple.componentInstance.btn_msg = 'Listo';
    }
  }

  /**
   * Toma los datos de back_data (que vienen del modal ProcesandoInfoComponent)
   * y extrae los datos de los productos cargados para mostrarlos en el listado
   */
  procesarInfoProductos() {
    this.prods_saltados = this.back_data.skipped;

    let new_prod: Producto;
    for (const aux of this.back_data.data) {
      new_prod = new Producto(
        aux.nombre,
        aux.descripcion,
        [],
        aux.precios,
        undefined,
        undefined,
        aux.promocion,
        aux.saldos,
        aux.codigo_ft,
        aux.codigo_distribuidor,
        undefined,
        aux.marca_producto,
        aux.categoria_producto,
        aux.linea_producto,
        aux._id
      );
      this.productos.push(new_prod);
      if (this.pag_actual.length < this.max_prods_x_pag) {
        this.pag_actual.push(new_prod);
      }
      this.fotos.push([]);
      this.file_fotos.push([]);
    }
  }

  /**
   * Recibe la posición del producto en todos los arreglos
   * y la posición del archivo a eliminar de la lista de
   * fotos por subir
   * @param index_prod La posición del producto en los arreglos
   * @param index_file La posición de la foto en el arreglo de archivos
   */
  quitarFoto(index_prod: number, index_file: number) {
    this.fotos[index_prod].splice(index_file, 1);
    this.file_fotos[index_prod].splice(index_file, 1);
  }

  /**
   * Revisa las imágenes adjuntas y envía los datos de los
   * productos con sus respectivas fotos al back
   */
  async cargar() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    const modalRef = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    let i = 0;
    for (const aux of this.pag_actual) {
      if (this.file_fotos[i].length == 0) {
        // Si no se subio una imagen se agrega una por defecto
        await this.rest.putJWT(`producto/${aux._id}`, { fotos: [this.default_img_producto] }).toPromise();
      } else {
        await this.subirFotos(aux, this.file_fotos[i]);
      }
      i++;
    }
    this.productos.splice(0, i);
    this.file_fotos.splice(0, i);
    this.fotos.splice(0, i);
    modalRef.close();
    if (this.productos.length != 0) {
      this.pag_actual = [];
      for (const prod of this.productos) {
        this.pag_actual.push(prod);
        if (this.pag_actual.length == this.max_prods_x_pag) {
          break;
        }
      }
    } else {
      this.cerrarModalYContinuar();
    }
  }

  /**
   * Función asíncrona para subir las fotos de un producto particular
   * Recibe un objeto con la información del producto (aunque solo se
   * toma de ahi el id y el código Ft) y el arreglo de fotos a subir
   * @param prod La información del producto que va a tener las fotos
   * @param files Las fotos a subir
   */
  async subirFotos(prod: Producto, files: File[]) {
    try {
      const upload_form: FormData = new FormData();
      let i = 1;
      for (const file of files) {
        upload_form.append(`imagen${i}`, file);
        i++;
      }
      const resp: any = await this.rest
        .postJWT(`recursos/producto/${prod._id}/${prod.codigo_ft}`, upload_form)
        .toPromise();
      await this.rest.putJWT(`producto/${prod._id}`, { fotos: resp.logos }).toPromise();
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Cierra el modal, muestra un mensaje de éxito,
   */
  cerrarModalYContinuar() {
    this.activeModal.close();
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
    modalRef.componentInstance.title = '¡Productos cargados al sistema!';
    modalRef.componentInstance.msg = 'Se han cargado con éxito tus productos y ya están activos en el sistema.';
    modalRef.componentInstance.btn_msg = 'Listo';
    modalRef.componentInstance.close_callback = () => {
      window.location.reload();
    };
  }
}
