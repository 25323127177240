<form action="">
  <div class="contenedor" *ngIf="step === 1" [formGroup]="crearTrabajadorFormPaso1">
    <div class="space-between">
      <span>Crea nuevo trabajador</span>
      <fa-icon
        style="cursor: pointer"
        [icon]="faTimes"
        [styles]="{ color: '#41403E' }"
        (click)="activeModal.close('Click close')"></fa-icon>
    </div>
    <ngb-progressbar type="warning" [value]="progressBarValue"></ngb-progressbar>
    <div class="space-between">
      <span>Datos personales</span>
      <span>Paso 1/2</span>
    </div>
    <div class="form-container">
      <div>
        <label for="tipo_documento">
          <span class="asterisco">*</span>
          Tipo de documento
        </label>
        <select
          name="tipo_documento"
          id=""
          formControlName="trabajadorTipo"
          [ngClass]="{
            'invalid-warning':
              crearTrabajadorFormPaso1.get('trabajadorTipo')?.hasError('required') &&
              crearTrabajadorFormPaso1.get('trabajadorTipo')?.touched
          }">
          <option selected value="" [disabled]="true">Selecciona</option>
          <option value="Cédula de ciudadanía" (click)="tipo_documento = 'Cédula de ciudadanía'">
            Cédula de ciudadanía
          </option>
          <option value="Pasaporte" (click)="tipo_documento = 'Pasaporte'">Pasaporte</option>
          <option value="Cédula de extranjería" (click)="tipo_documento = 'Cédula de extranjería'">
            Cédula de extranjería
          </option>
        </select>
      </div>
      <div>
        <label for="numero_documento">
          <span class="asterisco">*</span>
          Número de documento
        </label>
        <input
          name="numero_documento"
          type="number"
          placeholder="Escribe aquí"
          (keydown)="validateNumber($event)"
          formControlName="trabajadorDocumento"
          [ngClass]="{
            'invalid-warning':
              (crearTrabajadorFormPaso1.get('trabajadorDocumento')?.hasError('required') &&
                crearTrabajadorFormPaso1.get('trabajadorDocumento')?.touched) ||
              (crearTrabajadorFormPaso1.get('trabajadorDocumento')?.hasError('pattern') &&
                crearTrabajadorFormPaso1.get('trabajadorDocumento')?.touched)
          }" />
        <span
          class="w-100 text-danger fw-bold text-right"
          *ngIf="
            crearTrabajadorFormPaso1.get('trabajadorDocumento')?.hasError('pattern') &&
            crearTrabajadorFormPaso1.get('trabajadorDocumento')?.touched
          ">
          <span class="alerta">Numérico mayor a 5 dígitos</span>
        </span>
      </div>
      <div>
        <label for="nombres">
          <span class="asterisco">*</span>
          Nombres
        </label>
        <input
          name="nombres"
          type="text"
          placeholder="Escribe aquí"
          formControlName="trabajadorNombre"
          (keydown)="validateOnlyText($event)"
          [ngClass]="{
            'invalid-warning':
              crearTrabajadorFormPaso1.get('trabajadorNombre')?.hasError('required') &&
              crearTrabajadorFormPaso1.get('trabajadorNombre')?.touched
          }" />
      </div>
      <div>
        <label for="apellidos">
          <span class="asterisco">*</span>
          Apellidos
        </label>
        <input
          name="apellidos"
          type="text"
          placeholder="Escribe aquí"
          formControlName="trabajadorApellido"
          (keydown)="validateOnlyText($event)"
          [ngClass]="{
            'invalid-warning':
              crearTrabajadorFormPaso1.get('trabajadorApellido')?.hasError('required') &&
              crearTrabajadorFormPaso1.get('trabajadorApellido')?.touched
          }" />
      </div>
      <div>
        <label for="pais">
          <span class="asterisco">*</span>
          País
        </label>
        <select
          name="pais"
          id=""
          formControlName="trabajadorPais"
          [ngClass]="{
            'invalid-warning':
              crearTrabajadorFormPaso1.get('trabajadorPais')?.hasError('required') &&
              crearTrabajadorFormPaso1.get('trabajadorPais')?.touched
          }">
          <option value="Colombia" (click)="pais = 'Colombia'" selected>Colombia</option>
        </select>
      </div>
      <div>
        <label for="departamento">
          <span class="asterisco">*</span>
          Departamento
        </label>
        <select
          name="departamento"
          id=""
          (change)="getPlaces()"
          class="w-100"
          formControlName="trabajadorDepartamento"
          (change)="crearTrabajadorFormPaso1.patchValue({ trabajadorCiudad: '' })"
          [ngClass]="{
            'invalid-warning':
              crearTrabajadorFormPaso1.get('trabajadorDepartamento')?.hasError('required') &&
              crearTrabajadorFormPaso1.get('trabajadorDepartamento')?.touched
          }">
          <option *ngFor="let dpto of departamentos; index as i" [ngValue]="dpto.name">
            {{ dpto.name }}
          </option>
        </select>
      </div>
      <div>
        <label for="ciudad">
          <span class="asterisco">*</span>
          Ciudad
        </label>
        <select
          class="option_city"
          name="ciudad"
          id=""
          formControlName="trabajadorCiudad"
          [ngClass]="{
            'invalid-warning':
              crearTrabajadorFormPaso1.get('trabajadorCiudad')?.hasError('required') &&
              crearTrabajadorFormPaso1.get('trabajadorCiudad')?.touched
          }">
          <option *ngFor="let city of ciudades; index as i" [ngValue]="city.name" class="option_city">
            {{ city.name }}
          </option>
        </select>
      </div>
      <div>
        <label for="celular">
          <span class="asterisco">*</span>
          Celular (10 dígitos)
        </label>
        <input
          name="celular"
          type="number"
          placeholder="Escribe aquí"
          (keydown)="validateNumber($event)"
          formControlName="trabajadorCelular"
          [ngClass]="{
            'invalid-warning':
              (crearTrabajadorFormPaso1.get('trabajadorCelular')?.hasError('required') &&
                crearTrabajadorFormPaso1.get('trabajadorCelular')?.touched) ||
              (crearTrabajadorFormPaso1.get('trabajadorCelular')?.hasError('pattern') &&
                crearTrabajadorFormPaso1.get('trabajadorCelular')?.touched)
          }" />
        <span
          class="reactive-text text-danger"
          *ngIf="
            crearTrabajadorFormPaso1.get('trabajadorCelular')?.hasError('pattern') &&
            crearTrabajadorFormPaso1.get('trabajadorCelular')?.touched
          ">
          <span class="alerta">Númerico de 10 dígitos</span>
        </span>
      </div>
      <div>
        <label for="telefono">Teléfono (opcional)</label>
        <input
          name="telefono"
          type="text"
          placeholder="Escribe aquí"
          (keydown)="validateNumber($event)"
          formControlName="trabajadorTelefono"
          [ngClass]="{
            'invalid-warning':
              crearTrabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length >= 1 &&
              crearTrabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length != 7 &&
              crearTrabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length != 10 &&
              crearTrabajadorFormPaso1.get('trabajadorTelefono')?.touched
          }" />
        <span
          class="reactive-text text-danger"
          *ngIf="
            crearTrabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length >= 1 &&
            crearTrabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length != 7 &&
            crearTrabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length != 10 &&
            crearTrabajadorFormPaso1.get('trabajadorTelefono')?.touched
          ">
          <span class="alerta">Númerico de 7 o 10 dígitos</span>
        </span>
      </div>
    </div>
    <div>
      <button class="btn-siguiente" (click)="onPaso(2)" *ngIf="is_boton_habilitado">Siguiente</button>
      <!-- Si el formulario es invalido cambia los botones para mostrar alertas -->
      <button
        class="btn-siguiente"
        *ngIf="!is_boton_habilitado"
        (click)="showAlert()"
        [style.background-color]="!is_boton_habilitado ? 'grey' : ''">
        Siguiente
      </button>
    </div>
  </div>
  <div class="contenedor" *ngIf="step == 2" [formGroup]="crearTrabajadorFormPaso2">
    <div class="space-between">
      <span>Crea nuevo integrante</span>
      <fa-icon [icon]="faTimes" [styles]="{ color: '#41403E' }" (click)="activeModal.close('Click close')"></fa-icon>
    </div>
    <ngb-progressbar type="warning" [value]="progressBarValue"></ngb-progressbar>
    <div class="space-between">
      <span>Datos de cuenta</span>
      <span>Paso 2/2</span>
    </div>
    <div class="form-container">
      <div>
        <label for="correo">
          <span class="asterisco">*</span>
          Correo de ingreso
        </label>
        <input
          name="correo"
          type="text"
          placeholder="Escribe aquí"
          [(ngModel)]="correo"
          formControlName="trabajadorCorreo"
          autocomplete="nope"
          [ngClass]="{
            'invalid-warning':
              (crearTrabajadorFormPaso2.get('trabajadorCorreo')?.hasError('required') &&
                crearTrabajadorFormPaso2.get('trabajadorCorreo')?.touched) ||
              (crearTrabajadorFormPaso2.get('trabajadorCorreo')?.hasError('email') &&
                crearTrabajadorFormPaso2.get('trabajadorCorreo')?.touched)
          }" />
        <span
          class="reactive-text text-danger"
          *ngIf="
            crearTrabajadorFormPaso2.get('trabajadorCorreo')?.hasError('email') &&
            crearTrabajadorFormPaso2.get('trabajadorCorreo')?.touched
          ">
          <span class="alerta">Ingresa un email valido</span>
        </span>
      </div>
      <div>
        <label for="clave">
          <span class="asterisco">*</span>
          Crea una contraseña
        </label>
        <div class="d-flex flex-row input-group-password">
          <input
            [type]="fieldTextType ? 'text' : 'password'"
            id="password"
            name="clave"
            placeholder="Escribe aquí"
            [(ngModel)]="clave"
            formControlName="trabajadorContrasena"
            autocomplete="nope"
            [ngClass]="{
              'invalid-warning':
                crearTrabajadorFormPaso2.get('trabajadorContrasena')?.hasError('required') &&
                crearTrabajadorFormPaso2.get('trabajadorContrasena')?.touched
            }" />
          <fa-icon
            class="ojo-pass"
            [icon]="faEyeSlash"
            size="sm"
            [styles]="{ color: 'black' }"
            (click)="mostrarOcultarPassword()"
            *ngIf="fieldTextType"></fa-icon>
          <fa-icon
            class="ojo-pass"
            [icon]="faEye"
            size="sm"
            [styles]="{ color: 'black' }"
            (click)="mostrarOcultarPassword()"
            *ngIf="!fieldTextType"></fa-icon>
          <!-- <div class="input-group-append input-group-password h-100">
            <span class="input-group-text input-group-password h-100">
              
            </span>
          </div> -->
        </div>
      </div>
      <div>
        <label for="tipo_trabajador">
          <span class="asterisco">*</span>
          Tipo de usuario
        </label>
        <select
          name="tipo_trabajador"
          id=""
          [(ngModel)]="tipo_trabajador"
          formControlName="trabajadorTipoUsuario"
          [ngClass]="{
            'invalid-warning':
              crearTrabajadorFormPaso2.get('trabajadorTipoUsuario')?.hasError('required') &&
              crearTrabajadorFormPaso2.get('trabajadorTipoUsuario')?.touched
          }">
          <option selected value="" [disabled]="true">Selecciona</option>
          <option value="ADMINISTRADOR" (click)="tipo_trabajador = 'ADMINISTRADOR APROBADOR'">ADMINISTRADOR</option>
          <option value="OPERATIVO" (click)="tipo_trabajador = 'PLANEADOR PEDIDOS'" *ngIf="distribuidor === ''">
            OPERATIVO
          </option>
        </select>
      </div>
      <!--  <div class="input-group">
        <label for="punto_venta">
          <span class="asterisco">*</span>
          Puntos de venta asignados
        </label>
        <select name="select" class="seleccionar-trabajador py-2 w-100" multiple>
          <option
            *ngFor="let punto_venta of puntos_venta; index as i"
            class=""
            (click)="punto_entrega = punto_venta._id!; punto_entrega_nom = punto_venta.nombre"
            (click)="asignarTrabajador(punto_venta)">
            {{ punto_venta.nombre }}
          </option>
        </select>
      </div> -->
      <!-- <div class="flex-wrap">
        <div *ngFor="let nombre of puntos_entrega_nombre; index as i" class="col-4 p-1 align-items-center">
          <span
            *ngIf="nombre != null && nombre != ''"
            class="noms_punto_entrega col-12 d-flex justify-content-between align-items-start w-100 p-0">
            <span class="trabajador-seleccionado m-0 p-0">
              {{ nombre }}
            </span>
            <button class="trash" (click)="removerTrabajador(i)">
              <fa-icon [icon]="faTrashAlt" id="fa-icon"></fa-icon>
            </button>
          </span>
        </div>
      </div> -->
    </div>
    <div>
      <button class="btn-siguiente boton-volver" (click)="onPaso(1)">
        <fa-icon [icon]="faChevronLeft"></fa-icon>
        Página anterior
      </button>
      <button class="btn-siguiente" (click)="registrarTrabajador()" *ngIf="!crearTrabajadorFormPaso2.invalid">
        Crear
      </button>
      <!-- Si el formulario es invalido cambia los botones para mostrar alertas -->
      <button
        class="btn-siguiente"
        *ngIf="crearTrabajadorFormPaso2.invalid"
        (click)="showAlert()"
        [style.background-color]="crearTrabajadorFormPaso2.invalid ? 'grey' : ''">
        Crear
      </button>
    </div>
  </div>
</form>
