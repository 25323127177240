import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-planes-clientes',
  templateUrl: './planes-clientes.component.html',
  styleUrls: ['./planes-clientes.component.css'],
})
export class PlanesClientesComponent implements OnInit {
  /** Imagen producto placeholder */
  public distribuidor_placeholder = '../../assets/img/icon-organizacion.png';
  /** Guarda los logos y nombres de los distribuidores */
  public distribuidores: any;

  constructor(public restService: RestService) {}

  ngOnInit(): void {
    this.restService
      .getJWT('distribuidor_logo_nombre')
      .toPromise()
      .then((resp: any) => {
        /** Filtra los distribuidores que no tegnan logo */
        this.distribuidores = resp.filter((dist: any) => {
          return dist.logo !== undefined;
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
}
