<app-header-horeca></app-header-horeca>
<div class="d-flex justify-content-center">
  <div class="contenedor w-100 p-5" *ngIf="trabajador != undefined">
    <div class="d-flex flex-row mb-4">
      <button class="boton-volver" routerLink="/cuenta">
        <fa-icon [icon]="faChevronLeft"></fa-icon>
        Volver
      </button>
      <h2 class="main-title text-left ml-4 mb-0">{{ trabajador.nombres }} {{ trabajador.apellidos }}</h2>
    </div>
    <div class="d-flex justify-content-center flex-row row w-100 m-0">
      <form class="d-flex justify-content-center col-lg-8 col-md-6 col-sm-12 col-12 w-100 m-0 row p-4"
        [formGroup]="trabajadorFormPaso1">
        <div class="w-100 text-left mb-4">
          <span class="section-title w-100">Datos personales</span>
        </div>
        <div class="form-input d-flex row w-100">
          <!-- Tipo documento -->
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 w-100">
            <label for="tipo_documento" class="w-100">
              <span class="asterisco">*</span>Tipo de documento
            </label>
            <select name="tipo_documento" id="" formControlName="trabajadorTipo"
              (change)="trabajadorFormPaso1.patchValue({ trabajadorDocumento: '' })"
              [ngClass]="{'invalid-warning': (trabajadorFormPaso1.get('trabajadorTipo')?.hasError('required') && trabajadorFormPaso1.get('trabajadorTipo')?.touched)}">
              <option selected value="" [disabled]="true"> Selecciona</option>
              <option value="Cédula de ciudadanía">Cédula de ciudadanía</option>
              <option value="Pasaporte">Pasaporte</option>
              <option value="Cédula de extranjería">Cédula de extranjería</option>
            </select>
          </div>
          <!-- Número documento -->
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 w-100">
            <label for="num_doc" class="w-100">
              <span class="asterisco">*</span>Número de documento
            </label>
            <input *ngIf="trabajadorFormPaso1.get('trabajadorTipo')?.value === 'Pasaporte'" type="text"
              placeholder="Escribe aquí" formControlName="trabajadorDocumento"
              [ngClass]="{'invalid-warning': (trabajadorFormPaso1.get('trabajadorDocumento')?.hasError('required') && trabajadorFormPaso1.get('trabajadorDocumento')?.touched)}" />
            <input *ngIf="trabajadorFormPaso1.get('trabajadorTipo')?.value !== 'Pasaporte'" type="number"
              placeholder="Escribe aquí" formControlName="trabajadorDocumento" (keydown)="validateNumber($event)"
              [ngClass]="{'invalid-warning': (trabajadorFormPaso1.get('trabajadorDocumento')?.hasError('required') && trabajadorFormPaso1.get('trabajadorDocumento')?.touched)}" />
          </div>
          <!-- Nombre -->
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 w-100">
            <label for="nombres" class="w-100">
              <span class="asterisco">*</span>Nombre
            </label>
            <input name="nombres" type="text" placeholder="Escribe aquí" formControlName="trabajadorNombre"
              (keydown)="validateOnlyText($event)"
              [ngClass]="{'invalid-warning': (trabajadorFormPaso1.get('trabajadorNombre')?.hasError('required') && trabajadorFormPaso1.get('trabajadorNombre')?.touched)}" />
          </div>
          <!-- Apellidos -->
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 w-100">
            <label for="apellidos" class="w-100">
              <span class="asterisco">*</span>Apellidos
            </label>
            <input name="apellidos" type="text" placeholder="Escribe aquí" formControlName="trabajadorApellido"
              (keydown)="validateOnlyText($event)"
              [ngClass]="{'invalid-warning': (trabajadorFormPaso1.get('trabajadorApellido')?.hasError('required') && trabajadorFormPaso1.get('trabajadorApellido')?.touched)}" />
          </div>
          <!-- País -->
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 w-100">
            <label for="pais" class="w-100">
              <span class="asterisco">*</span>País
            </label>
            <select name="pais" id=""
              [ngClass]="{'invalid-warning': (trabajadorFormPaso1.get('trabajadorPais')?.hasError('required') && trabajadorFormPaso1.get('trabajadorPais')?.touched)}">
              <option value="Colombia" selected>Colombia</option>
            </select>
          </div>
          <!-- Departamento -->
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 w-100">
            <label for="departamento" class="w-100">
              <span class="asterisco">*</span>Departamento
            </label>
            <select name="departamento" id="" formControlName="trabajadorDepartamento" (change)="getPlaces()"
              [ngClass]="{'invalid-warning': (trabajadorFormPaso1.get('trabajadorDepartamento')?.hasError('required') && trabajadorFormPaso1.get('trabajadorDepartamento')?.touched)}">
              <option *ngFor="let dpto of departamentos; index as i;" [ngValue]="dpto.name">
                {{ dpto.name }}
              </option>
            </select>
          </div>
          <!-- Ciudad -->
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 w-100">
            <label for="ciudad" class="w-100">
              <span class="asterisco">*</span>Ciudad
            </label>
            <select class="option_city" name="ciudad" id="" formControlName="trabajadorCiudad"
              [ngClass]="{'invalid-warning': (trabajadorFormPaso1.get('trabajadorCiudad')?.hasError('required') && trabajadorFormPaso1.get('trabajadorCiudad')?.touched)}">
              <option *ngFor="let city of ciudades; index as i;" [ngValue]="city.name" class="option_city">
                {{ city.name }}
              </option>
            </select>
          </div>
          <!-- Celular -->
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 w-100">
            <label for="celular" class="w-100">
              <span class="asterisco">*</span>Celular (10 dígitos)
            </label>
            <input formControlName="trabajadorCelular" name="celular" type="number" placeholder="Escribe aquí"
              (keydown)="validateNumber($event)"
              [ngClass]="{'invalid-warning': (trabajadorFormPaso1.get('trabajadorCelular')?.hasError('required') && trabajadorFormPaso1.get('trabajadorCelular')?.touched) || trabajadorFormPaso1.get('trabajadorCelular')?.hasError('pattern') && trabajadorFormPaso1.get('trabajadorCelular')?.touched}" />
            <span class="reactive-text text-danger"
              *ngIf="trabajadorFormPaso1.get('trabajadorCelular')?.hasError('pattern') && trabajadorFormPaso1.get('trabajadorCelular')?.touched">
              <span class="alerta">Númerico de 10 dígitos </span>
            </span>
          </div>
          <!-- Telefono -->
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 w-100">
            <label for="telefono" class="w-100">Teléfono (7 o 10 dígitos)</label>
            <input formControlName="trabajadorTelefono" name="telefono" type="text" placeholder="Escribe aquí"
              class="w-100" (keydown)="validateNumber($event)" [ngClass]="{
                'invalid-warning':
                    trabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length >= 1 &&
                    trabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length != 7 &&
                    trabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length != 10 &&
                    trabajadorFormPaso1.get('trabajadorTelefono')?.touched }" />
            <span class="col-12 text-danger fw-bold text-right" *ngIf="
               trabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length >= 1 &&
                    trabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length != 7 &&
                    trabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length != 10 &&
                    trabajadorFormPaso1.get('trabajadorTelefono')?.touched
              ">
              <span class="alerta">Númerico de 7 o 10 dígitos</span>
            </span>
          </div>
        </div>
        <div class="col-12 w-100 mb-4 mt-2">
          <span class="section-title">Información cuenta</span>
        </div>
        <!-- Correo -->
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 w-100">
          <label for="correo" class="w-100">
            <span class="asterisco">*</span>
            Correo de ingreso
          </label>
          <p class="my-2">{{trabajador.correo}}</p>
        </div>
        <!-- Tipo usuario -->
        <div class="input-info-cuenta col-lg-6 col-md-12 col-sm-12 col-12 w-100 pb-3">
          <label for="tipo_trabajador" class="w-100">
            <span class="asterisco">*</span>
            Tipo de usuario
          </label>
          <select name="tipo_trabajador" id="" formControlName="trabajadorTipoUsuario"
            [ngClass]="{'invalid-warning': (trabajadorFormPaso1.get('trabajadorTipoUsuario')?.hasError('required') && trabajadorFormPaso1.get('trabajadorTipoUsuario')?.touched)}">
            <option selected value="" [disabled]="true">Selecciona</option>
            <option value="ADMINISTRADOR APROBADOR">ADMINISTRADOR APROBADOR</option>
            <option value="PLANEADOR PEDIDOS">PLANEADOR PEDIDOS</option>
            <option value="PROPIETARIO/REP LEGAL">PROPIETARIO/REP LEGAL</option>
          </select>
        </div>
      </form>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 w-100 m-0 p-0">
        <div class="card p-4">
          <span class="titulo-punto-entrega">
            {{ trabajador.tipo_trabajador | titlecase }}
          </span>
          <div class="row-lineal">
            <img src="assets/img/icons/icon-store.png" alt="Ícono tienda" />
            <span class="px-2">Puntos de entrega</span>
            <button (click)="editarPuntosEntrega()" class="btn btn-editar-punto">
              <fa-icon [icon]="faPencilAlt"></fa-icon>
              Editar
            </button>
          </div>
          <div class="sedes-container" *ngIf="puntos_entrega.length != 0">
            <span *ngFor="let punto_entrega of puntos_entrega; index as i" class="mb-1">
              {{ punto_entrega.nombre }}
            </span>
          </div>
          <div class="sedes-container" *ngIf="puntos_entrega.length == 0">
            <span>Este trabajador aún no tiene puntos de entrega asignados</span>
          </div>
          <div class="pt-4 border-top">
            <button (click)="cambiarContrasena()" class="mb-4">Cambiar contraseña</button>
            <div class="estado-desactivar" *ngIf="trabajador.solicitud_vinculacion == 'Aprobado'"
              (click)="desactivarTrabajador()">
              <fa-icon [icon]="faTrashAlt" id="fa-icon"></fa-icon>
              <span> Desactivar trabajador</span>
            </div>
            <div class="estado-activar"
              *ngIf="trabajador.solicitud_vinculacion == 'Rechazado' && puntos_entrega.length != 0"
              (click)="activarTrabajador()">
              <fa-icon [icon]="faCheckCircle" id="fa-icon"></fa-icon>
              <span> Activar trabajador</span>
            </div>
            <div class="estado-desactivar"
              *ngIf="trabajador.solicitud_vinculacion == 'Rechazado' && puntos_entrega.length == 0"
              (click)="editarPuntosEntrega()">
              <fa-icon [icon]="faTimesCircle" id="fa-icon"></fa-icon>
              <span> Asigna un punto de entrega para reactivar al trabajador</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-4 pb-0">
      <button [style.background-color]="!is_boton_habilitado ? 'grey' : ''"
        (click)="is_boton_habilitado && actualizarTrabajador()"
        (click)="!is_boton_habilitado && alertaFormularioInvalido()">
        Guardar cambios
      </button>
    </div>
  </div>
</div>