<section>
  <!-- Portafolio -->
  <div class="card p-4">
    <span class="d-flex flex-row align-items-center justify-content-between pt-4 pb-0">
      <h5 class="m-0">Portafolio</h5>
      <button class="btn-purple" (click)="descargaArchivoPortafolio()">Descargar datos</button>
    </span>
    <div class="card-body scroll-tabla mt-3 p-0 tabla-portafolio">
      <ng2-smart-table [settings]="settings_portafolio" [source]="source_portafolio"></ng2-smart-table>
    </div>
  </div>
  <!-- Distribuidores -->
  <div class="card p-4 mt-4">
    <span class="d-flex flex-row align-items-center justify-content-between pt-4 pb-0">
      <h5 class="m-0">Distribuidores</h5>
      <button class="btn-purple" (click)="descargaArchivoDistribuidores()">Descargar datos</button>
    </span>
    <div class="card-body scroll-tabla mt-3 p-0">
      <ng2-smart-table [settings]="settings_distribuidores" [source]="source_distribuidores"></ng2-smart-table>
    </div>
  </div>
  <!-- Ventas -->
  <div class="card p-4 mt-4">
    <span class="d-flex flex-row align-items-center justify-content-between pt-4 pb-0">
      <h5 class="m-0">Ventas</h5>
      <button class="btn-purple" (click)="descargaArchivoVentas()">Descargar datos</button>
    </span>
    <div class="card-body scroll-tabla mt-3 p-0">
      <ng2-smart-table [settings]="settings_ventas" [source]="source_ventas"></ng2-smart-table>
    </div>
  </div>
  <!-- Usuarios -->
  <div class="card p-4 mt-4">
    <span class="d-flex flex-row align-items-center justify-content-between pt-4 pb-0">
      <h5 class="m-0">Usuarios</h5>
      <button class="btn-purple" (click)="descargaArchivoUsuarios()">Descargar datos</button>
    </span>
    <div class="card-body scroll-tabla mt-3 p-0">
      <ng2-smart-table [settings]="settings_usuarios" [source]="source_usuarios"></ng2-smart-table>
    </div>
  </div>
  <!-- Puntos Feat -->
  <div class="card p-4 mt-4">
    <span class="d-flex flex-row align-items-center justify-content-between pt-4 pb-0">
      <h5 class="m-0">Puntos Feat</h5>
      <button class="btn-purple" (click)="descargaArchivoPuntosFeat()">Descargar datos</button>
    </span>
    <div class="card-body scroll-tabla mt-3 p-0">
      <ng2-smart-table [settings]="settings_puntos_feat2" [source]="source_puntos_feat"></ng2-smart-table>
    </div>
  </div>
  <!-- Establecimientos -->
  <div class="card p-4 mt-4">
    <span class="d-flex flex-row align-items-center justify-content-between pt-4 pb-0">
      <h5 class="m-0">Establecimientos</h5>
      <button class="btn-purple" (click)="descargaArchivoEstablecimiento()">Descargar datos</button>
    </span>
    <div class="card-body scroll-tabla mt-3 p-0">
      <ng2-smart-table [settings]="settings_establecimientos" [source]="source_establecimientos"></ng2-smart-table>
    </div>
  </div>
</section>

