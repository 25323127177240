import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GraficasService } from 'src/app/services/graficas-tablas/graficas.service';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-puntosft-graficas-organizacion',
  templateUrl: './puntosft-graficas-organizacion.component.html',
  styleUrls: ['./puntosft-graficas-organizacion.component.css'],
})
export class PuntosftGraficasOrganizacionComponent implements OnInit {
  // ID organizacion
  public id_organizacion = this.auth.user_organizacion._id;
  // Lista de meses para apoyar el formateo de la fecha
  public filtro_24_meses: any = [];
  private meses: string[] = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  private meses_largo: string[] = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  // Colores para las gráficas
  public colors = [
    'rgba(115, 113, 156, 1)',
    'rgba(199, 195, 239, 1)',
    'rgba(247, 234, 194, 1)',
    'rgba(250, 211, 157, 1)',
    'rgba(179, 179, 210, 1)',
    'rgba(142, 111, 247, 1)',
  ];
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };
  // Biding HTML-Canvas y TS
  @ViewChild('bar_puntos_mes', { static: true })
  private bar_puntos_mes!: ElementRef;
  @ViewChild('bar_puntos_productos', { static: true })
  private bar_puntos_productos!: ElementRef;
  @ViewChild('bar_puntos_categoria', { static: true })
  private bar_puntos_categoria!: ElementRef;
  @ViewChild('bar_puntos_marca', { static: true })
  private bar_puntos_marca!: ElementRef;
  @ViewChild('bar_puntos_distribuidor', { static: true })
  private bar_puntos_distribuidor!: ElementRef;
  @ViewChild('bar_top_productos', { static: true })
  private bar_top_productos!: ElementRef;
  // Guarda datos de la talba y configuraciones
  private data_bar_puntos_mes: any;
  private data_bar_puntos_productos: any;
  private data_bar_puntos_categoria: any;
  private data_bar_puntos_marca: any;
  private data_bar_puntos_distribuidor: any;
  private data_bar_top_productos: any;
  private bar_chart_puntos_mes: any;
  private bar_chart_puntos_productos: any;
  private bar_chart_puntos_categoria: any;
  private bar_chart_puntos_marca: any;
  private bar_chart_puntos_distribuidor: any;
  private bar_chart_top_productos: any;
  public filtro_puntos_mes: any;
  public filtro_puntos_productos: any;
  public filtro_puntos_categoria: any;
  public filtro_puntos_marca: any;
  public filtro_puntos_distribuidor: any;
  public total_puntos_mes: any;
  public total_puntos_productos: any;
  public total_puntos_categoria: any;
  public total_puntos_marca: any;
  public total_puntos_distribuidor: any;
  public total_top_productos: any;

  constructor(
    private rest: RestService,
    private auth: AuthService,
    private currency: CurrencyPipe,
    private modalService: NgbModal,
    private ngxsStore: Store,
    private graficasService: GraficasService,
    private router: Router
  ) {
    this.data_bar_puntos_mes = {
      labels: [],
      datasets: [],
    };
    this.data_bar_puntos_productos = {
      labels: [],
      datasets: [],
    };
    this.data_bar_puntos_categoria = {
      labels: [],
      datasets: [],
    };
    this.data_bar_puntos_marca = {
      labels: [],
      datasets: [],
    };
    this.data_bar_puntos_distribuidor = {
      labels: [],
      datasets: [],
    };
    this.data_bar_top_productos = {
      labels: [],
      datasets: [],
    };
  }

  async ngOnInit() {
    this.id_organizacion = await this.ngxsStore.snapshot().auth.user_organizacion._id;
    try {
      this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
      this.getListaFiltroMeses();
      await this.getGraficaInformacionPuntosMes();
      await this.getGraficaInformacionPuntosProducto();
      await this.getGraficaInformacionPuntosCategoria();
      await this.getGraficaInformacionPuntosMarca();
      await this.getGraficaInformacionPuntosDistribuidor();
      await this.getGraficaInformacionTopProductos();
      this.modalCarga?.close();
    } catch (error) {
      this.mostrarMensajeError();
    }
  }

  /**
   * Arma lista de los últimos 24 meses para el filtro de meses
   */
  getListaFiltroMeses() {
    const fecha_actual = new Date();
    let mes_actual = fecha_actual.getMonth();
    let ano_actual = fecha_actual.getFullYear();
    for (let index = 0; index < 23; index++) {
      if (mes_actual >= 0) {
        const data = {
          filtro: this.meses[mes_actual] + ', ' + ano_actual,
          filtro_inicio: this.meses[mes_actual - 2] + ', ' + ano_actual,
          crudo: Number(mes_actual + 1) + ',' + ano_actual + ',' + (mes_actual - 1) + ',' + ano_actual,
        };
        if (mes_actual <= 1) {
          data.filtro_inicio = this.meses[11 - (1 - mes_actual)] + ', ' + (ano_actual - 1);
          data.crudo = Number(mes_actual + 1) + ',' + ano_actual + ',' + (11 - mes_actual) + ',' + (ano_actual - 1);
        }
        this.filtro_24_meses.push(data);
        mes_actual = mes_actual - 1;
      } else {
        mes_actual = 11;
        ano_actual = ano_actual - 1;
      }
    }
    this.filtro_puntos_mes = this.filtro_24_meses[0].crudo;
    this.filtro_puntos_productos = this.filtro_24_meses[0].crudo;
    this.filtro_puntos_categoria = this.filtro_24_meses[0].crudo;
    this.filtro_puntos_marca = this.filtro_24_meses[0].crudo;
    this.filtro_puntos_distribuidor = this.filtro_24_meses[0].crudo;
  }

  /**
   * Inicializa los datos de las gráficas y llama a los métodos adecuados para mostrar cada una
   */
  /********************************* Puntos por mes ********************************/
  public async getGraficaInformacionPuntosMes() {
    // Si se aplica un filtro se borra la data pevia
    if (this.bar_chart_puntos_mes) {
      this.bar_chart_puntos_mes.destroy();
      this.data_bar_puntos_mes.labels = [];
      this.data_bar_puntos_mes.datasets = [];
    }
    const first_day = this.filtro_puntos_mes.split(',')[1] - 1 + '-' + this.filtro_puntos_mes.split(',')[0] + '-01';
    const last_day = this.filtro_puntos_mes.split(',')[1] + '-' + this.filtro_puntos_mes.split(',')[0] + '-31';
    this.total_puntos_mes = 0;
    await this.rest
      .getJWT(`puntos_X_mes/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((puntos_mes: any) => {
        puntos_mes = puntos_mes.data;
        puntos_mes.forEach((element: any) => {
          this.data_bar_puntos_mes.labels.push(
            this.meses[element._id.split('-')[1] - 1] + '-' + element._id.split('-')[0]
          );
          this.data_bar_puntos_mes.datasets.push(element.total_puntos);
          this.total_puntos_mes = this.total_puntos_mes + element.total_puntos;
        });
      })
      .catch((err) => {});
    this.bar_chart_puntos_mes = this.graficasService.createBarChart(
      this.bar_puntos_mes,
      this.data_bar_puntos_mes,
      'Puntos por mes',
      'Número de puntos acumulados',
      'Meses'
    );
  }
  /********************************* Puntos por producto ********************************/
  public async getGraficaInformacionPuntosProducto() {
    // Si se aplica un filtro se borra la data pevia
    if (this.bar_chart_puntos_productos) {
      this.bar_chart_puntos_productos.destroy();
      this.data_bar_puntos_productos.labels = [];
      this.data_bar_puntos_productos.datasets = [];
    }
    const first_day =
      this.filtro_puntos_productos.split(',')[1] - 1 + '-' + this.filtro_puntos_productos.split(',')[0] + '-01';
    const last_day =
      this.filtro_puntos_productos.split(',')[1] + '-' + this.filtro_puntos_productos.split(',')[0] + '-31';
    this.total_puntos_productos = 0;
    await this.rest
      .getJWT(`puntos_X_producto/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((puntos_productos: any) => {
        puntos_productos = puntos_productos.data;
        puntos_productos.forEach((element: any) => {
          this.data_bar_puntos_productos.labels.push(element._id);
          this.data_bar_puntos_productos.datasets.push(element.total_puntos);
          this.total_puntos_productos = this.total_puntos_productos + element.total_puntos;
        });
      })
      .catch((err) => {});
    this.bar_chart_puntos_productos = this.graficasService.createBarChart(
      this.bar_puntos_productos,
      this.data_bar_puntos_productos,
      'Puntos por producto',
      'Número de puntos acumulados',
      'Productos'
    );
  }
  /********************************* Puntos por categoria ********************************/
  public async getGraficaInformacionPuntosCategoria() {
    // Si se aplica un filtro se borra la data pevia
    if (this.bar_chart_puntos_categoria) {
      this.bar_chart_puntos_categoria.destroy();
      this.data_bar_puntos_categoria.labels = [];
      this.data_bar_puntos_categoria.datasets = [];
    }
    const first_day =
      this.filtro_puntos_categoria.split(',')[1] - 1 + '-' + this.filtro_puntos_categoria.split(',')[0] + '-01';
    const last_day =
      this.filtro_puntos_categoria.split(',')[1] + '-' + this.filtro_puntos_categoria.split(',')[0] + '-31';
    this.total_puntos_categoria = 0;
    await this.rest
      .getJWT(`puntos_X_categoria/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((puntos_categoria: any) => {
        puntos_categoria = puntos_categoria.data;
        puntos_categoria.forEach((element: any) => {
          this.data_bar_puntos_categoria.labels.push(element._id);
          this.data_bar_puntos_categoria.datasets.push(element.total_puntos);
          this.total_puntos_categoria = this.total_puntos_categoria + element.total_puntos;
        });
      })
      .catch((err) => {});
    this.bar_chart_puntos_categoria = this.graficasService.createBarChart(
      this.bar_puntos_categoria,
      this.data_bar_puntos_categoria,
      'Puntos por categoría',
      'Número de puntos acumulados',
      'Categorías'
    );
  }
  /********************************* Puntos por marca ********************************/
  public async getGraficaInformacionPuntosMarca() {
    // Si se aplica un filtro se borra la data pevia
    if (this.bar_chart_puntos_marca) {
      this.bar_chart_puntos_marca.destroy();
      this.data_bar_puntos_marca.labels = [];
      this.data_bar_puntos_marca.datasets = [];
    }
    const first_day = this.filtro_puntos_marca.split(',')[1] - 1 + '-' + this.filtro_puntos_marca.split(',')[0] + '-01';
    const last_day = this.filtro_puntos_marca.split(',')[1] + '-' + this.filtro_puntos_marca.split(',')[0] + '-31';
    this.total_puntos_marca = 0;
    await this.rest
      .getJWT(`puntos_X_marca/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((puntos_marca: any) => {
        puntos_marca = puntos_marca.data;
        puntos_marca.forEach((element: any) => {
          this.data_bar_puntos_marca.labels.push(element._id);
          this.data_bar_puntos_marca.datasets.push(element.total_puntos);
          this.total_puntos_marca = this.total_puntos_marca + element.total_puntos;
        });
      })
      .catch((err) => {});
    this.bar_chart_puntos_marca = this.graficasService.createBarChart(
      this.bar_puntos_marca,
      this.data_bar_puntos_marca,
      'Puntos por marca',
      'Número de puntos acumulados',
      'Marcas'
    );
  }
  /********************************* Puntos por distribuidor ********************************/
  public async getGraficaInformacionPuntosDistribuidor() {
    // Si se aplica un filtro se borra la data pevia
    if (this.bar_chart_puntos_distribuidor) {
      this.bar_chart_puntos_distribuidor.destroy();
      this.data_bar_puntos_distribuidor.labels = [];
      this.data_bar_puntos_distribuidor.datasets = [];
    }
    const first_day =
      this.filtro_puntos_distribuidor.split(',')[1] - 1 + '-' + this.filtro_puntos_distribuidor.split(',')[0] + '-01';
    const last_day =
      this.filtro_puntos_distribuidor.split(',')[1] + '-' + this.filtro_puntos_distribuidor.split(',')[0] + '-31';
    this.total_puntos_distribuidor = 0;
    await this.rest
      .getJWT(`puntos_X_distribuidor/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((puntos_distribuidor: any) => {
        puntos_distribuidor = puntos_distribuidor.data;
        puntos_distribuidor.forEach((element: any) => {
          if (element._id == null) {
            element._id = 'Otro';
          }
          this.data_bar_puntos_distribuidor.labels.push(element._id);
          this.data_bar_puntos_distribuidor.datasets.push(element.total_puntos);
          this.total_puntos_distribuidor = this.total_puntos_distribuidor + element.total_puntos;
        });
      })
      .catch((err) => {});
    this.bar_chart_puntos_distribuidor = this.graficasService.createBarChart(
      this.bar_puntos_distribuidor,
      this.data_bar_puntos_distribuidor,
      'Puntos por distribuidor',
      'Número de puntos acumulados',
      'Distribuidores'
    );
  }
  /********************************* Puntos por distribuidor ********************************/
  public async getGraficaInformacionTopProductos() {
    this.total_top_productos = 0;
    await this.rest
      .getJWT(`puntos_top_10_productos/${this.id_organizacion}`)
      .toPromise()
      .then((top_productos: any) => {
        top_productos = top_productos.data;
        top_productos.forEach((element: any) => {
          this.data_bar_top_productos.labels.push(element.nombre);
          this.data_bar_top_productos.datasets.push(element.total);
          this.total_top_productos = this.total_top_productos + element.total;
        });
      })
      .catch((err) => {});
    this.bar_chart_top_productos = this.graficasService.createBarChart(
      this.bar_top_productos,
      this.data_bar_top_productos,
      'Top 10 puntos acumulados por producto (Últimos 3 meses)',
      'Puntos Acumulados',
      'Producto (Código organización)'
    );
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio-organizacion']);
    };
  }
}
