import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LocalDataSource } from 'ng2-smart-table';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { CargarProductoExcelComponent } from 'src/app/modal/cargar-producto-excel/cargar-producto-excel.component';
import { EditarInventarioProductoComponent } from 'src/app/modal/editar-inventario-producto/editar-inventario-producto.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { Categoria } from 'src/app/models/categoria.model';
import { LineaProducto } from 'src/app/models/linea_producto.model';
import { Marca } from 'src/app/models/marca.model';
import { Organizacion } from 'src/app/models/organizacion.model';
import { Producto } from 'src/app/models/producto.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-inventario',
  templateUrl: './inventario.component.html',
  styleUrls: ['./inventario.component.css'],
})
export class InventarioComponent implements OnInit {
  // Datos y configuracion de la tabla
  public settings: any = {};
  public data: any[] = [];
  public source?: LocalDataSource;
  // Listados para construir la data a mostrar
  public productos: Producto[] = [];
  public organizaciones: Organizacion[] = [];
  public categorias: Categoria[] = [];
  public lineas: LineaProducto[] = [];
  public marcas: Marca[] = [];
  // Referencia al modal de carga
  public modalCarga?: NgbModalRef;
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
  };

  constructor(
    private rest: RestService,
    private auth: AuthService,
    private modalService: NgbModal,
    private currency: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.configSmartTable();
    this.loadData();
  }

  /**
   * Configura la tabla
   */
  private configSmartTable(): void {
    this.settings = {
      noDataMessage: 'Aún no tienes productos agregados',
      pager: {
        display: true,
        perPage: 10,
      },
      actions: {
        columnTitle: 'Acción',
        add: false,
        edit: false,
        delete: false,
      },
      hideSubHeader: false,
      columns: {
        accion: {
          title: 'Acción',
          editable: false,
          filter: false,
          type: 'custom',
          renderComponent: BtnEditarInv,
        },
        codigo_distribuidor_producto: {
          title: 'Código producto distribuidor',
          editable: false,
          filter: true,
        },
        estado: {
          title: 'Estado del producto',
          editable: false,
          filter: true,
        },
        codigo_organizacion_producto: {
          title: 'Código Organización Producto',
          editable: false,
          filter: true,
        },
        nombre_producto: {
          title: 'Nombre de producto',
          editable: false,
          filter: true,
        },
        unidad_medida: {
          title: 'Unidad de medida',
          editable: false,
          filter: true,
        },
        cantidad_medida: {
          title: 'Cantidad de medida',
          editable: false,
          filter: true,
        },
        precio_venta_x_und: {
          title: 'Precio de venta por unidad',
          editable: false,
          filter: true,
        },
        precio_venta_x_caja: {
          title: 'Precio de venta por caja',
          editable: false,
          filter: true,
        },
        und_x_caja: {
          title: 'Unidades por caja',
          editable: false,
          filter: true,
        },
        unidades: {
          title: 'Unidades en inventario',
          editable: false,
          filter: true,
        },
        cajas: {
          title: 'Cajas',
          editable: false,
          filter: true,
        },
        organizacion: {
          title: 'Organización',
          editable: false,
          filter: true,
        },
        marca: {
          title: 'Marca',
          editable: false,
          filter: true,
        },
        prodPedido: {
          title: 'Producto bajo pedido',
          editable: false,
          filter: true,
        },
        prodBiodegradable: {
          title: 'Producto biodegradable',
          editable: false,
          filter: true,
        },
        prodDescuento: {
          title: 'Producto con descuento',
          editable: false,
          filter: true,
        },
        prodPorcentajeDesc: {
          title: '% de descuento',
          editable: false,
          filter: true,
        },
        categoria_prod: {
          title: 'Categoría de producto',
          editable: false,
          filter: true,
        },
        linea_prod: {
          title: 'Línea de producto',
          editable: false,
          filter: true,
        },
        codigo_ft: {
          title: 'Código Feat',
          editable: false,
          filter: true,
        },
        puntos_ft: {
          title: 'Puntos FT.',
          editable: false,
          filter: true,
        },
        ventas_prod: {
          title: 'Ventas del producto (últ. 3 meses)',
          editable: false,
          filter: true,
        },
        establecimientos: {
          title: 'Establecimientos Alcanzados',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /**
   * Carga los datos a mostrar en la tabla
   */
  private async loadData() {
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    try {
      //Se traen los productos del distribuidor
      this.productos = [];
      const resp_prods: any = await this.rest
        .getJWT(`productoInventarioLista/${this.auth.user_distribuidor?._id}`)
        .toPromise();
      if (resp_prods !== null && resp_prods !== undefined) {
        for (const prod_aux of resp_prods) {
          if (prod_aux.cajas) {
            prod_aux.cajas = parseInt(prod_aux.cajas);
            prod_aux.cajas = prod_aux.cajas.toFixed(2);
          } else {
            prod_aux.cajas = 0;
          }
          // Si es un descuento actualiza el precio de venta por unidad
          if (prod_aux.precios && prod_aux.precios[0].precio_descuento && prod_aux.precios[0].precio_descuento > 0) {
            prod_aux.precio_venta_x_und = prod_aux.precios[0].precio_descuento;
            prod_aux.precio_venta_x_und = this.currency.transform(
              prod_aux.precio_venta_x_und || 0,
              '$ ',
              'symbol',
              '1.0-0'
            );
            prod_aux.precio_venta_x_caja = this.currency.transform(
              prod_aux.precio_venta_x_caja || 0,
              '$ ',
              'symbol',
              '1.0-0'
            );
            prod_aux.ventas_prod = this.currency.transform(prod_aux.ventas_prod || 0, '$ ', 'symbol', '1.0-0');
            prod_aux.unidad_medida = prod_aux.precios[0].unidad_medida;
          }
        }
        this.data.push(resp_prods);
        this.source = new LocalDataSource(resp_prods);
      }
      this.modalCarga?.close();
    } catch (err) {
      this.modalCarga?.close();
      this.settings.noDataMessage = 'Ocurrió un error recuperando la información de los productos';
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'No fue posible recuperar los datos del inventario. Por favor intenta de nuevo más tarde';
      modalRef.componentInstance.btn_msg = 'Volver';
    }
  }
  async validatePermissions() {
    // eslint-disable-next-line prefer-const
    let blockUser = await this.auth.validatePermissionsUser();
    return blockUser;
  }
  /**
   * Abre modal para cargar excel con productos del distribuidor
   */
  public async openModalCargarExcel() {
    // eslint-disable-next-line prefer-const
    let validador = await this.validatePermissions();
    if (validador) {
      const modalRef = this.modalService.open(CargarProductoExcelComponent, this.ngbModalOptions);
      modalRef.componentInstance.inventario_xlsx = true;
      modalRef.componentInstance.clickevent.subscribe(() => {
        this.descargarLista();
      });
    }
  }

  /**
   * Permite descargar excel con toda la información de la tabla
   */
  public async descargarLista(): Promise<void> {
    // eslint-disable-next-line prefer-const
    let validador = await this.validatePermissions();
    if (validador) {
      /** Columnas a mostrar */
      const columnas_descarga = [
        'estado',
        'codigo_distribuidor_producto',
        'nombre_producto',
        'precio_venta_x_und',
        'precio_venta_x_caja',
        'und_x_caja',
        'unidades',
        'prodBiodegradable',
        'prodPedido',
        'prodDescuento',
        'prodPorcentajeDesc',

      ];
      /** Recupera los titulos de la tabla */
      const primeraFila: any[] = [];
      for (const column in this.settings.columns) {
        /** Ignora la columna acción */
        if (column === 'unidades') {
          primeraFila.push('Unidades (NO CAJAS) en inventario');
        } else if(column === 'prodBiodegradable'){
          primeraFila.push('Producto biodegradable');
        } else if(column === 'prodPedido'){
          primeraFila.push('Producto bajo pedido');
        }else if(column === 'prodDescuento'){
          primeraFila.push('Producto con descuento');
        }else if(column === 'prodPorcentajeDesc'){
          primeraFila.push('Porcentaje de descuento');
        }
        else if (columnas_descarga.includes(column)) {
          primeraFila.push(this.settings.columns[column].title);
        } else {
          continue;
        }
      }
      /** Recupera el contenido de la tabla */
      const arreglo = [];
      arreglo.push(primeraFila);
      this.data[0].forEach((element: any) => {
        const array = [];
        for (const column in this.settings.columns) {
          /** Solo se cargan las columnas seleccionadas en el array */
          if (
            /** Si es un precio, se eliminan los caracteres y puntos */
            columnas_descarga.includes(column) &&
            (column === 'precio_venta_x_und' || column === 'precio_venta_x_caja')
          ) {
            let elementArr;
            // eslint-disable-next-line prefer-const
            elementArr = element[column].toString().replace(/[^\d,-]/g, '');
            if (column === 'precio_venta_x_und' || column === 'precio_venta_x_caja') {
              elementArr = parseInt(elementArr);
            }
            array.push(elementArr);
          } else if (columnas_descarga.includes(column)) {
            let arrPush = element[column];
            if (column === 'codigo_distribuidor_producto') {
              //Se pregunta que tipo de dato contiene.
              const matchs = '^[0-9]+$';
              if (element[column].match(matchs) !== null) {
                arrPush = parseInt(element[column]);
              } else {
                arrPush = element[column];
              }
            }
            if (column === 'prodPorcentajeDesc') {
              if(element[column]){
                arrPush = parseInt(element[column]);
              }else {
                arrPush = 0;
              }
            }
            array.push(arrPush);
          } else {
            continue;
          }
        }
        arreglo.push(array);
      });
      /** Genera el worksheet */
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(arreglo);
      /* Genera el workbook y agrega el  worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      /** Guarda el archivo */
      XLSX.writeFile(wb, 'Lista_productos.xlsx');
    }
  }
}

@Component({
  selector: 'app-btn-editar-inv',
  template: `
    <button class="btn-purple" (click)="openModalEditarInventario()">Editar</button>
  `,
  styles: [
    `
      .btn-purple {
        width: 100%;
        padding: 0px;
        background-color: transparent;
        border: transparent;
        color: #8e6ff7;
      }
    `,
  ],
})
export class BtnEditarInv {
  //Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
  };
  // Datos del producto a editar
  @Input() rowData?: any;

  constructor(private modalService: NgbModal, private rest: RestService, private auth: AuthService) {}
  async validatePermissions() {
    // eslint-disable-next-line prefer-const
    let blockUser = await this.auth.validatePermissionsUser();
    return blockUser;
  }
  /**
   * Toma la información del producto y abre el modal de editar inventario
   * pasando los valores del producto y definiendo un callback para subir esos cambios
   */
  public async openModalEditarInventario() {
    // eslint-disable-next-line prefer-const
    let validador = await this.validatePermissions();
    if (validador) {
      const modalRef = this.modalService.open(EditarInventarioProductoComponent, this.ngbModalOptions);
      modalRef.componentInstance.cajas = this.rowData?.precios?.[0].inventario_caja || 0;
      modalRef.componentInstance.precio_caja = this.rowData?.precios?.[0].precio_caja || 0;
      modalRef.componentInstance.precio_unidad = this.rowData?.precios?.[0].precio_unidad || 0;
      modalRef.componentInstance.unidades = this.rowData?.precios?.[0].inventario_unidad || 0;
      modalRef.componentInstance.und_x_caja = this.rowData?.precios?.[0].und_x_caja || 0;
      modalRef.componentInstance.foto = this.rowData?.fotos?.[0];
      modalRef.componentInstance.producto = this.rowData?.nombre_producto;
      modalRef.componentInstance.callback = (
        pCajas: number,
        pUnidades: number,
        precio_caja: number,
        precio_unidad: number
      ) => {
        const precio = this.rowData?.precios?.[0] || {};
        precio.inventario_caja = pCajas;
        precio.inventario_unidad = pUnidades;
        precio.precio_caja = precio_caja;
        precio.precio_unidad = precio_unidad;

        this.rest
          .putJWT(`producto/${this.rowData?.producto_id}`, { precios: [precio] })
          .toPromise()
          .then(() => {
            const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
            modalRef.componentInstance.title = '¡Genial!';
            modalRef.componentInstance.msg = '¡El inventario ha sido actualizado con éxito!';
            modalRef.componentInstance.btn_msg = 'Listo';
          })
          .catch((err) => {
            const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
            modalRef.componentInstance.title = '¡Oh oh!';
            modalRef.componentInstance.msg =
              'No fue posible actualizar el inventario. Por favor intenta de nuevo más tarde';
            modalRef.componentInstance.btn_msg = 'Volver';
            throw err;
          });
      };
    }
  }
}
