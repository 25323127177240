import { Component, OnInit } from '@angular/core';
import { NoticiaService } from 'src/app/services/noticia/noticia.service';

@Component({
  selector: 'app-ultimas-noticias',
  templateUrl: './ultimas-noticias.component.html',
  styleUrls: ['./ultimas-noticias.component.css'],
})
export class UltimasNoticiasComponent implements OnInit {
  public noticias: any;
  public filteredNoticias: any;

  constructor(private noticiaService: NoticiaService) {}

  async ngOnInit() {
    try {
      this.noticias = await this.noticiaService.getNoticias().toPromise();
      this.noticias = this.noticias.filter((noticia: any) => noticia.estado === 'Activo');
      this.filteredNoticias = this.noticias.slice(0, 2);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Abre link en una nueva ventana
   */
  public verDocumento(link: any) {
    window.open(link, '_blank');
  }
}
