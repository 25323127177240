<div class="container content">
  <!-- Header -->
  <div class="row">
    <div class="col">
      <h5>Crea nuevo trabajador</h5>
    </div>
    <div class="col text-right">
      <fa-icon
        [icon]="faTimes"
        class="cursorPointer"
        [styles]="{ color: '#41403E' }"
        id="fa-icon"
        (click)="close()"></fa-icon>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <ngb-progressbar type="warning" [max]="2" height="0.7rem" [value]="paso_actual"></ngb-progressbar>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col" *ngIf="paso_actual === 1">Datos de personales</div>
    <div *ngIf="paso_actual === 2" class="col">Datos de cuenta</div>
    <div class="col text-right">Paso {{ paso_actual }}/2</div>
  </div>
  <!-- Contenido -->
  <div class="row">
    <!-- Paso 1 -->
    <div class="col-12 mt-4" id="form-scroll" *ngIf="paso_actual == 1">
      <div class="row">
        <div class="col">
          <label>Tipo de documento de identidad</label>
          <select class="select-default">
            <option>Seleccina</option>
          </select>
        </div>
        <div class="col">
          <label>Número de documento</label>
          <input type="number" placeholder="Escribe aquí" class="input-defecto" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <label>Nombres</label>
          <input type="text" placeholder="Escribe aquí" class="input-defecto" />
        </div>
        <div class="col">
          <label>Apellidos</label>
          <input type="text" placeholder="Escribe aquí" class="input-defecto" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <label>País</label>
          <select class="select-default">
            <option>Seleccina</option>
          </select>
        </div>
        <div class="col">
          <label>Departamento</label>
          <select class="select-default">
            <option>Seleccina</option>
          </select>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <label>Ciudad</label>
          <select class="select-default">
            <option>Seleccina</option>
          </select>
        </div>
        <div class="col">
          <label>Celular</label>
          <input type="number" placeholder="Escribe aquí" class="input-defecto" />
        </div>
      </div>
    </div>
    <!-- Paso 2 -->
    <div class="col-12" id="form-scroll" *ngIf="paso_actual != 1">
      <div class="row mt-3">
        <div class="col">
          <label>Tipo de usuario</label>
          <select class="select-default">
            <option>Seleccina</option>
          </select>
        </div>
        <div class="col">
          <label>Correo de ingreso</label>
          <input type="email" placeholder="Escribe aquí" class="input-defecto"  
          autocorrect="off"
          autocapitalize="none"
          spellcheck="false"/>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <label>Crea una contraseña</label>
          <div class="d-flex">
            <input
              type="password"
              [type]="fieldTextType ? 'text' : 'password'"
              placeholder="Escribe aquí"
              autocomplete = "new-password"
              class="input-defecto" />
            <div class="input-group-append">
              <span class="input-group-text">
                <fa-icon
                  [icon]="faEyeSlash"
                  size="1x"
                  [styles]="{ color: 'black' }"
                  pull="right"
                  (click)="mostrarOcultarPassword()"
                  *ngIf="fieldTextType"></fa-icon>
                <fa-icon
                  [icon]="faEye"
                  size="1x"
                  [styles]="{ color: 'black' }"
                  pull="right"
                  (click)="mostrarOcultarPassword()"
                  *ngIf="!fieldTextType"></fa-icon>
              </span>
            </div>
          </div>
        </div>
        <div class="col"></div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <label>Cliente asignado</label>
          <div class="d-flex">
            <select class="select-default">
              <option>Selecciona</option>
            </select>
            <a class="asignar-cliente ml-2 pt-2">+Asignar otro cliente</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Navegación-->
  <div class="row mt-4">
    <div class="col-12">
      <br />
      <div class="row">
        <!--Anterior-->
        <div class="col text-left">
          <button id="btn_modal_anterior" *ngIf="paso_actual != 1" (click)="anterior()">
            <fa-icon [icon]="faChevronLeft" size="1x" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
            &nbsp;Página anterior
          </button>
        </div>
        <!--Siguiente-->
        <div class="col text-right">
          <button class="btn_modal" *ngIf="paso_actual == 1" (click)="siguiente()">Continuar</button>
          <button class="btn_modal" *ngIf="paso_actual != 1">Crear</button>
        </div>
      </div>
      <br />
    </div>
  </div>
</div>
