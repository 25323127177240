import { Component, DoCheck, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  faTimes,
  faChevronLeft,
  faTimesCircle,
  faPencilAlt,
  faTrash,
  faTrashAlt,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { Trabajador } from 'src/app/models/trabajador.model';
import { PuntoEntrega } from 'src/app/models/punto_entrega.model';
import { RestService } from 'src/app/services/rest/rest.service';
import { EditarPuntosAsignadosComponent } from 'src/app/modal/editar-puntos-asignados/editar-puntos-asignados.component';
import { ConfirmacionComponent } from 'src/app/modal/confirmacion/confirmacion.component';
import { PlacesService } from 'src/app/services/places/places.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CambiarContrasenaComponent } from 'src/app/modal/cambiar-contrasena/cambiar-contrasena.component';

@Component({
  selector: 'app-gestionar',
  templateUrl: './gestionar.component.html',
  styleUrls: ['./gestionar.component.css'],
})
export class GestionarComponent implements OnInit, DoCheck {
  /** Iconos FontAwesome para usar en el template */
  faTimes = faTimes;
  faChevronLeft = faChevronLeft;
  faTimesCircle = faTimesCircle;
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  faTrashAlt = faTrashAlt;
  faCheckCircle = faCheckCircle;

  /**Guardara booleano para habilitar o no boton de continuar */
  public is_boton_habilitado = false;

  /** Formulario reactivo */
  public trabajadorFormPaso1: FormGroup;
  dataTrabajador: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private restService: RestService,
    private modalService: NgbModal,
    private router: Router,
    private formBuilder: FormBuilder,
    public places: PlacesService
  ) {
    this.trabajadorFormPaso1 = this.formBuilder.group({
      trabajadorTipo: ['', Validators.required],
      trabajadorDocumento: ['', Validators.required],
      trabajadorNombre: ['', Validators.required],
      trabajadorApellido: ['', Validators.required],
      trabajadorPais: ['', Validators.required],
      trabajadorDepartamento: ['', Validators.required],
      trabajadorCiudad: ['', Validators.required],
      trabajadorTelefono: [''],
      trabajadorCelular: ['', [Validators.required, Validators.pattern(/^3[\d]{9}$/)]],
      trabajadorTipoUsuario: ['', Validators.required],
    });
  }

  /** Variable para tener los datos del trabajador y poder editarlos */
  public trabajador!: Trabajador;

  /** Arreglo de los puntos de venta asociados al trabajador */
  public puntos_entrega: PuntoEntrega[] = [];

  /** Variables para el manejo de la lista de departamentos y ciudades */
  public id_dpto = 0;
  public ciudades: any;
  public departamentos: any;

  ngDoCheck(): void {
    this.habilitarBoton();
  }

  async getPlaces() {
    let id_dpto = 0;
    for (const dpto of this.departamentos) {
      if (dpto.name == this.trabajadorFormPaso1.get('trabajadorDepartamento')?.value) {
        break;
      }
      id_dpto++;
    }
    if (this.departamentos[id_dpto] != undefined) {
      await this.places.getCitiesFromServerImagine(this.departamentos[id_dpto].code);
      this.ciudades = this.places.cities_colombia;
    } else {
      this.ciudades = [];
    }
  }

  async ngOnInit() {
    this.departamentos = await this.places.getDepartmentFromServerImagine();
    await this.getTrabajador();
  }

  /**
   * Trae los datos del trabajador
   */
  getTrabajador() {
    const id_trab: string = this.activatedRoute.snapshot.params.id;
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
    };
    this.restService
      .getJWT('trabajador/' + id_trab)
      .toPromise()
      .then((resp: any) => {
        this.dataTrabajador = resp;
        if (resp.telefono === null) {
          resp.telefono = '0';
        }
        this.trabajador = new Trabajador(
          resp.nombres,
          resp.apellidos,
          resp.correo,
          resp.clave,
          resp.telefono,
          resp.celular || '',
          resp.pais,
          resp.departamento,
          resp.ciudad,
          resp.tipo_documento,
          resp.numero_documento.toString(),
          resp.tipo_trabajador,
          resp.solicitud_vinculacion,
          resp.show_slides,
          resp.usuario_horeca,
          resp.puntos_entrega || [],
          undefined,
          undefined,
          undefined,
          resp._id
        );
        /**
         * Una vez recuperada la información del trabajador
         * re realiza el biding con el formulario para ver la data en el DOM
         */
        this.trabajadorFormPaso1.patchValue({
          trabajadorTipo: this.trabajador.tipo_documento,
          trabajadorDocumento: this.trabajador.numero_documento,
          trabajadorNombre: this.trabajador.nombres,
          trabajadorApellido: this.trabajador.apellidos,
          trabajadorPais: this.trabajador.pais,
          trabajadorDepartamento: this.trabajador.departamento,
          trabajadorCiudad: this.trabajador.ciudad,
          trabajadorCelular: this.trabajador.celular,
          trabajadorTipoUsuario: this.trabajador.tipo_trabajador,
          trabajadorTelefono: '',
        });
        if (this.trabajador.telefono !== undefined) {
          this.trabajadorFormPaso1.patchValue({
            trabajadorTelefono: this.trabajador.telefono,
          });
        }
        /**
         * Se obtiene la lista de ciudades
         */
        this.getPlaces();
        /**
         * Se recuperan los puntos de entrega asociados al trabajador
         */
        let i = 0;
        let id_punto_actual: any;
        for (i = 0; i < (this.trabajador.puntos_entrega?.length || 0); i++) {
          id_punto_actual = this.trabajador.puntos_entrega?.[i] || '';
          this.restService
            .getJWT('punto_entrega/' + id_punto_actual._id)
            .toPromise()
            .then((resp_2: any) => {
              /**
               * Por temas de optimización, esta declaración iría por fuera del for
               * Pero, por temas de asincronía por la petición HTTP, se debe declarar
               * e inicializar una variable por cada promesa dentro del mismo callback
               */
              const nuevo_punto: PuntoEntrega = new PuntoEntrega(
                resp_2.usuario_horeca,
                resp_2.nombre,
                resp_2.pais,
                resp_2.departamento,
                resp_2.ciudad,
                resp_2.telefono,
                resp_2.direccion,
                resp_2.informacion_contacto,
                resp_2.sillas,
                resp_2.domicilios,
                resp_2.numero_trabajadores,
                resp_2.tipo_aprobador,
                resp_2.dias_atencion,
                resp_2.horario,
                resp_2._id,
                resp_2.estado
              );
              if (resp_2.estado == 'Activo') {
                this.puntos_entrega.push(resp_2);
              }
            });
        }
      })
      .catch((err) => {
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg =
          'No fue posible recuperar la información de este trabajador. Por favor intenta de nuevo más tarde.';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigate(['/cuenta']);
        };
      });
  }

  /**
   * Editar los puntos de entrega asociados al trabajador
   */
  editarPuntosEntrega() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
    };
    const modalRef = this.modalService.open(EditarPuntosAsignadosComponent, ngbModalOptions);
    modalRef.componentInstance.puntos_entrega_asignados = this.puntos_entrega;
    modalRef.componentInstance.id_trabajador = this.trabajador._id;
    modalRef.componentInstance.dataTrabajador = this.dataTrabajador;
    modalRef.componentInstance.close_callback = () => {
      let i = 0;
      let id_punto_actual = '';
      this.puntos_entrega = [];
      for (i = 0; i < (this.trabajador.puntos_entrega?.length || 0); i++) {
        id_punto_actual = this.trabajador.puntos_entrega?.[i] || '';
        /**
         * Trae la información de los puntos de venta asociados a este trabajador
         * */
        this.restService
          .getJWT('punto_entrega/' + id_punto_actual)
          .toPromise()
          .then((resp_2: any) => {
            /**
             * Por temas de optimización, esta declaración iría por fuera del for
             * Pero, por temas de asincronía por la petición HTTP, se debe declarar
             * e inicializar una variable por cada promesa dentro del mismo callback
             */
            const nuevo_punto: PuntoEntrega = new PuntoEntrega(
              resp_2.usuario_horeca,
              resp_2.nombre,
              resp_2.pais,
              resp_2.departamento,
              resp_2.ciudad,
              resp_2.telefono,
              resp_2.direccion,
              resp_2.informacion_contacto,
              resp_2.sillas,
              resp_2.domicilios,
              resp_2.numero_trabajadores,
              resp_2.tipo_aprobador,
              resp_2.dias_atencion,
              resp_2.horario,
              resp_2._id,
              resp_2.estado
            );
            if (resp_2.estado == 'Activo') {
              this.puntos_entrega.push(nuevo_punto);
            }
          });
      }
    };
  }

  /**
   * Actualiza los datos del trabajador en la base de datos
   */
  actualizarTrabajador() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    /**
     * Recuperamos la información del formulario y actualizamos los datos del trabajador
     */
    this.trabajador.tipo_documento = this.trabajadorFormPaso1.get('trabajadorTipo')?.value;
    this.trabajador.numero_documento = this.trabajadorFormPaso1.get('trabajadorDocumento')?.value;
    this.trabajador.nombres = this.trabajadorFormPaso1.get('trabajadorNombre')?.value;
    this.trabajador.apellidos = this.trabajadorFormPaso1.get('trabajadorApellido')?.value;
    this.trabajador.pais = this.trabajadorFormPaso1.get('trabajadorPais')?.value;
    this.trabajador.departamento = this.trabajadorFormPaso1.get('trabajadorDepartamento')?.value;
    this.trabajador.ciudad = this.trabajadorFormPaso1.get('trabajadorCiudad')?.value;
    this.trabajador.telefono = this.trabajadorFormPaso1.get('trabajadorTelefono')?.value;
    this.trabajador.celular = this.trabajadorFormPaso1.get('trabajadorCelular')?.value;
    this.trabajador.tipo_trabajador = this.trabajadorFormPaso1.get('trabajadorTipoUsuario')?.value;
    try {
      this.restService
        .putJWT('trabajador/' + this.trabajador._id, this.trabajador)
        .toPromise()
        .then(() => {
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          modalRef.componentInstance.msg = 'Los datos del trabajador fueron actualizados con éxito.';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            window.location.reload();
          };
        });
    } catch (error) {
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'No fue posible actualizar la información de este trabajador. Por favor intenta de nuevo más tarde.';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {
        //this.router.navigate(["/cuenta"]);
      };
    }
  }

  /**
   * Si no cumple no entra a la asignacion según estado del formulario.
   * Se realiza este paso dado que el telefono del trabajador
   * solo se verifica si se ingresa al menos un digito en el input
   */
  habilitarBoton() {
    this.is_boton_habilitado = false;
    if (
      this.trabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length >= 1 &&
      this.trabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length != 7 &&
      this.trabajadorFormPaso1.get('trabajadorTelefono')?.value.toString().length != 10
    ) {
      return;
    } else {
      this.is_boton_habilitado = !this.trabajadorFormPaso1.invalid;
    }
  }

  /**
   * Alerta si el formulario esta incompleto o un input es invalido
   */
  alertaFormularioInvalido() {
    this.trabajadorFormPaso1.markAllAsTouched();
    const modalRef = this.modalService.open(SimpleComponent);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = '¡Por favor asegúrate de llenar todos los datos y vuelve a intentarlo!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {};
  }

  /**
   * Lanza un diálogo de confirmación. Si el usuario selecciona
   * que sí, se desactiva al trabajador actual. Si no, se cierra
   * el diálogo sin hacer nada
   */
  desactivarTrabajador() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
    };

    const modalRef = this.modalService.open(ConfirmacionComponent, ngbModalOptions);
    modalRef.componentInstance.msg = '¿Estás seguro de desactivar este trabajador?';
    modalRef.componentInstance.btn_msg_no = 'Cancelar';
    modalRef.componentInstance.btn_msg_yes = 'Desactivar';
    modalRef.componentInstance.callback_no = () => {};
    modalRef.componentInstance.callback_yes = () => {
      const obj_aux: any = {
        solicitud_vinculacion: 'Desactivado',
      };
      this.restService
        .putJWT('trabajador/' + this.trabajador._id, obj_aux)
        .toPromise()
        .then(() => {
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          modalRef.componentInstance.msg = 'El trabajador fue desactivado correctamente.';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            window.location.reload();
          };
        })
        .catch(() => {
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg =
            'El trabajador no pudo ser desactivado por un error inesperado. Intenta de nuevo más tarde.';
          modalRef.componentInstance.btn_msg = 'Volver';
          modalRef.componentInstance.close_callback = () => {
            //this.router.navigate(["/cuenta"]);
          };
        });
    };
  }

  /**
   * Lanza un diálogo de confirmación. Si el usuario selecciona
   * que sí, se re-activa al trabajador actual. Si no, se cierra
   * el diálogo sin hacer nada
   */
  activarTrabajador() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
    };

    const modalRef = this.modalService.open(ConfirmacionComponent, ngbModalOptions);
    modalRef.componentInstance.msg = '¿Estás seguro de activar este elemento?';
    modalRef.componentInstance.btn_msg_no = 'Cancelar';
    modalRef.componentInstance.btn_msg_yes = 'Activar';
    modalRef.componentInstance.callback_no = () => {};
    modalRef.componentInstance.callback_yes = () => {
      const obj_aux: any = {
        solicitud_vinculacion: 'Aprobado',
      };
      this.restService
        .putJWT('trabajador/' + this.trabajador._id, obj_aux)
        .toPromise()
        .then(() => {
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          modalRef.componentInstance.msg = 'El trabajador fue activado correctamente.';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            window.location.reload();
          };
        })
        .catch(() => {
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg =
            'El trabajador no pudo ser activado por un error inesperado. Intenta de nuevo más tarde.';
          modalRef.componentInstance.btn_msg = 'Volver';
          modalRef.componentInstance.close_callback = () => {
            //this.router.navigate(["/cuenta"]);
          };
        });
    };
  }

  /**
   * Permite cambiar la contraseña de un trabjador
   */
  cambiarContrasena() {
    const modalRef = this.modalService.open(CambiarContrasenaComponent);
    modalRef.componentInstance.callback = () => {};
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateNumber(event: any) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || excludedKeys.includes(keyCode))) {
      event.preventDefault();
    }
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateOnlyText(event: any) {
    const keyCode = event.keyCode;
    if (keyCode >= 48 && keyCode <= 57) {
      event.preventDefault();
    }
  }
}
