<app-header-horeca *ngIf="tipo_usuario === 'usuario_horeca'"></app-header-horeca>
<app-header-distribuidor *ngIf="tipo_usuario === 'distribuidor'"></app-header-distribuidor>
<app-header-organizacion *ngIf="tipo_usuario === 'organizacion'"></app-header-organizacion>
<div class="container py-5 px-4">
  <div class="col-12 col-md-6 d-flex flex-row p-0">
    <div>
      <button class="boton-volver mr-4" (click)="volver()">
        <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }"></fa-icon>
        Volver
      </button>
    </div>
    <div>
      <h3>Notificaciones</h3>
    </div>
  </div>
  <div *ngIf="notificaciones && notificaciones.length > 0; else estado_vacio">
    <div class="d-flex justify-content-end w-100 my-3">
      <button class="purple-button px-3" (click)="marcarLeido()">
        Marcar como leidas
      </button>
    </div>
    <div class="scroll">
      <div class="mt-4 d-flex justify-content-center w-100" *ngFor="let notificacion of notificaciones; let i = index">
        <div class="d-flex mt-3 w-100" (click)="redireccionNotificacion(notificacion)">
          <div class="notificacion-img d-flex justify-content-center align-items-center">
            <img [src]="getIconoNotificacion(notificacion)" class="img-notification" alt="icono notificaciòn" />
          </div>
          <div class="notificacion-item">
            <div class="d-flex justify-content-between h-100">
              <div class="d-flex align-items-center p-3">
                {{ notificacion.mensaje }}
              </div>
              <div class="d-flex align-items-center justify-content-between pr-3 fecha">
                {{ notificacion.fecha | date: 'd MMM' }} - {{ notificacion.fecha | dateDiff }}
                <div class="d-flex alig-items-start">
                  <span *ngIf="notificacion.estado !== 'Leido'; else badge_leido" class="badge-no-leido"></span>
                  <ng-template #badge_leido><span class="badge-leido"></span></ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #estado_vacio>
    <div>
      <section class="d-flex flex-column justify-conent-center align-items-center">
        <img src="../../assets/img/icon-warning-amarillo.png" class="col-lg-4 col-sm-6 col-10"
          alt="Icono de advertencia" />
        <span>Nada de nada</span>
        <span>No tienes notificanes</span>
      </section>
    </div>
  </ng-template>
</div>