import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { faChevronDown, faChevronUp, faStar } from '@fortawesome/free-solid-svg-icons';
import { Chart } from 'chart.js';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PuntoEntrega } from 'src/app/models/punto_entrega.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { Router } from '@angular/router';
import { SeleccionarPuntoEntregaComponent } from 'src/app/modal/seleccionar-punto-entrega/seleccionar-punto-entrega.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-distribuidores-graficas',
  templateUrl: './distribuidores-graficas.component.html',
  styleUrls: ['./distribuidores-graficas.component.css'],
})
export class DistribuidoresGraficasComponent implements OnInit {
  /** Lista de puntos de entrega */
  public puntos_entrega: PuntoEntrega[] = [];
  public punto_seleccionado?: PuntoEntrega;
  /** Referencias a iconos FontAwesome para la UI */
  public punto_entrega: any;
  public faChevronUp = faChevronUp;
  public faChevronDown = faChevronDown;
  public faStar = faStar;
  /**Variables para los ranking por distribuidores */
  public id_distribuidores_punto: string[] = [];
  public ranking_distribuidores: any = [];
  /** Estado de vinculaciones a distribuidores */
  @ViewChild('pie_estado_distribuidor', { static: true })
  public pie_estado_distribuidor!: ElementRef;
  public data_pie_estado_distribuidor: any;
  public pie_chart_estado_distribuidor: any;
  public filtro_estado_distribuidor: any;
  public total_estado_distribuidor: any;
  /** Compras por distribuidor */
  @ViewChild('pie_distribuidor_compras', { static: true })
  public pie_distribuidor_compras!: ElementRef;
  public data_pie_distribuidor_compras: any;
  public pie_chart_distribuidor_compras: any;
  public filtro_distribuidor_compras: any;
  public total_distribuidor_compras: any;
  /** Compras por distribuidor */
  @ViewChild('pie_distribuidor_pedidos', { static: true })
  public pie_distribuidor_pedidos!: ElementRef;
  public data_pie_distribuidor_pedidos: any;
  public pie_chart_distribuidor_pedidos: any;
  public filtro_distribuidor_pedidos: any;
  public total_distribuidor_pedidos: any;
  /** Colores para las gráficas */
  public colors = [
    'rgba(115, 113, 156, 1)',
    'rgba(199, 195, 239, 1)',
    'rgba(247, 234, 194, 1)',
    'rgba(250, 211, 157, 1)',
    'rgba(199, 195, 239, 1)',
    'rgba(247, 234, 194, 1)',
    'rgba(250, 211, 157, 1)',
  ];
  /**Imagen producto placeholder */
  public distribuidor_placeholder = '../../../assets/img/icon-organizacion.png';
  /** Modal de carga para darle feedback al usuario */
  public modalCarga?: NgbModalRef;
  /** Evita que al hacer click por fuera se cierre el modal */
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };

  constructor(
    private restService: RestService,
    private authService: AuthService,
    private modalService: NgbModal,
    private currency: CurrencyPipe,
    private router: Router
  ) {
    this.data_pie_estado_distribuidor = {
      labels: [],
      datasets: [],
    };
    this.data_pie_distribuidor_compras = {
      labels: [],
      datasets: [],
    };
    this.data_pie_distribuidor_pedidos = {
      labels: [],
      datasets: [],
    };
  }

  async ngOnInit() {
    this.punto_entrega = JSON.parse(localStorage.getItem('punto_entrega_seleccionado') || '{}');
    /** Modal generíco cargando... */
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    /** Carga datos a graficas */
    await this.getChartPedidosDistribuidor();
    await this.getChartPedidosDistribuidorCompras();
    await this.getChartPedidosDistribuidorPedidos();
    /** Cerrar Modal generíco cargando... */
    this.modalCarga?.close();
  }

  /**
   * Inicializa los datos de las gráficas y llama a los métodos adecuados para mostrar cada una
   */
  /********************************* Estado de distribuidores ********************************/
  public async getChartPedidosDistribuidor() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.pie_chart_estado_distribuidor) {
      this.pie_chart_estado_distribuidor.destroy();
      this.data_pie_estado_distribuidor.labels = [];
      this.data_pie_estado_distribuidor.datasets = [];
    }
    let total_estado_distribuidor = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/horeca/pie_chart/distribuidores_estado/${this.punto_entrega._id}`)
      .toPromise()
      .then((distribuidores: any) => {
        distribuidores = distribuidores.data;
        distribuidores.forEach((element: any) => {
          this.data_pie_estado_distribuidor.labels.push(element._id);
          this.data_pie_estado_distribuidor.datasets.push(element.total);
          total_estado_distribuidor = total_estado_distribuidor + element.total;
        });
        this.total_estado_distribuidor = total_estado_distribuidor;
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
    this.pie_chart_estado_distribuidor = new Chart(this.pie_estado_distribuidor.nativeElement, {
      type: 'doughnut',
      data: {
        labels: this.data_pie_estado_distribuidor.labels,
        datasets: [
          {
            label: 'Estado de vinculaciones a distribuidores',
            backgroundColor: this.colors.slice(1, this.colors.length - 1),
            data: this.data_pie_estado_distribuidor.datasets,
          },
        ],
      },
      options: {
        responsive: true,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          legend: {
            position: 'right',
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.label;
                const value = context.formattedValue;
                let sum = 0;
                const dataArr = context.dataset.data;
                dataArr.map((data: any) => {
                  sum += data;
                });
                const percentage = ((+value * 100) / sum).toFixed(2) + '%';
                return [label, value, percentage];
              },
            },
          },
        },
      },
    });
  }
  /********************************* Compras por distribuidor ********************************/
  public async getChartPedidosDistribuidorCompras() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.pie_chart_distribuidor_compras) {
      this.pie_chart_distribuidor_compras.destroy();
      this.data_pie_distribuidor_compras.labels = [];
      this.data_pie_distribuidor_compras.datasets = [];
    }
    let total_distribuidor_compras = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/horeca/pie_chart/distribuidores_compras/${this.punto_entrega._id}`)
      .toPromise()
      .then((distribuidores: any) => {
        distribuidores = distribuidores.data;
        distribuidores.forEach((element: any) => {
          this.data_pie_distribuidor_compras.labels.push(element.distribuidor_nombre);
          this.data_pie_distribuidor_compras.datasets.push(element.total);
          total_distribuidor_compras = total_distribuidor_compras + element.total;
          /** Crea un array con los ID para consultar los rangkings */
          this.id_distribuidores_punto.push(element._id);
        });
        this.total_distribuidor_compras = this.currency.transform(total_distribuidor_compras, '$', 'symbol', '1.0-0');
        /** Recupera los rankings */
        this.fetchranking_distribuidores();
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
    this.pie_chart_distribuidor_compras = new Chart(this.pie_distribuidor_compras.nativeElement, {
      type: 'doughnut',
      data: {
        labels: this.data_pie_distribuidor_compras.labels,
        datasets: [
          {
            label: 'Compras por distribuidor',
            backgroundColor: this.colors.slice(1, this.colors.length - 1),
            data: this.data_pie_distribuidor_compras.datasets,
          },
        ],
      },
      options: {
        responsive: true,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          legend: {
            position: 'right',
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.label;
                const value = context.formattedValue;
                let sum = 0;
                const dataArr = context.dataset.data;
                dataArr.map((data: any) => {
                  sum += data;
                });
                const percentage = ((+value * 100) / sum).toFixed(2) + '%';
                return [label, value, percentage];
              },
            },
          },
        },
      },
    });
  }
  /********************************* Pedidos por distribuidor ********************************/
  public async getChartPedidosDistribuidorPedidos() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.pie_chart_distribuidor_pedidos) {
      this.pie_chart_distribuidor_pedidos.destroy();
      this.data_pie_distribuidor_pedidos.labels = [];
      this.data_pie_distribuidor_pedidos.datasets = [];
    }
    let total_distribuidor_pedidos = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/horeca/pie_chart/distribuidores_pedidos/${this.punto_entrega._id}`)
      .toPromise()
      .then((distribuidores: any) => {
        distribuidores = distribuidores.data;
        distribuidores.forEach((element: any) => {
          this.data_pie_distribuidor_pedidos.labels.push(element.distribuidor_nombre);
          this.data_pie_distribuidor_pedidos.datasets.push(element.total);
          total_distribuidor_pedidos = total_distribuidor_pedidos + element.total;
        });
        this.total_distribuidor_pedidos = total_distribuidor_pedidos;
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
    this.pie_chart_distribuidor_pedidos = new Chart(this.pie_distribuidor_pedidos.nativeElement, {
      type: 'bar',
      data: {
        labels: this.data_pie_distribuidor_pedidos.labels,
        datasets: [
          {
            label: 'Pedidos realizados por distribuidor',
            backgroundColor: this.colors[0],
            data: this.data_pie_distribuidor_pedidos.datasets,
          },
        ],
      },
      options: {
        responsive: true,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.label;
                const value = context.formattedValue;
                let sum = 0;
                const dataArr = context.dataset.data;
                dataArr.map((data: any) => {
                  sum += data;
                });
                const percentage = ((+value * 100) / sum).toFixed(2) + '%';
                return [label, value, percentage];
              },
            },
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Total pedidos realizados',
            },
          },
          x: {
            title: {
              display: true,
              text: 'Nombre distribuidor',
            },
          },
        },
      },
    });
  }

  /**
   * Lanza un modal para seleccionar el punto de entrega sobre el que se
   * van a gestionar las solicitudes
   */
  async selecionarPunto() {
    const modalRef = this.modalService.open(SeleccionarPuntoEntregaComponent, this.ngbModalOptions);
    modalRef.componentInstance.callback = () => {
      this.punto_entrega = this.authService.punto_seleccionado;
      localStorage.setItem('punto_entrega_seleccionado', this.punto_entrega);
      this.getChartPedidosDistribuidor();
      this.getChartPedidosDistribuidorCompras();
      this.getChartPedidosDistribuidorPedidos();
    };
  }

  /**
   * Esta funcion tiene como objeto traer la data
   * para poblar los distribuidores aprobados por el punto de entrega y mostrar su ranking
   */
  fetchranking_distribuidores() {
    this.ranking_distribuidores = [];
    this.id_distribuidores_punto.forEach((element: any) => {
      this.restService
        .getJWT(`distribuidor/${element}`)
        .toPromise()
        .then((resp: any) => {
          this.ranking_distribuidores.push(resp);
        });
    });
  }

  /**
   * Esta funcion tiene como objeto redireccionar al usuario al
   * detalle del distribuidor cuando este de click sobre una de las tarjetas
   */
  verDistribuidor(event: any) {
    const filtrado = event._id;
    this.router.navigate(['/distribuidores', filtrado]);
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio']);
    };
  }
}
