import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { Distribuidor } from 'src/app/models/distribuidor.model';
import { Trabajador } from 'src/app/models/trabajador.model';
import { UsuarioHoreca } from 'src/app/models/usuario-horeca.model';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { SharedService } from 'src/app/services/tools/shared.service';
import { MapService } from '../../services/map/map.service';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css'],
})
export class RegistroComponent implements OnInit {
  constructor(
    private router: Router,
    private rest: RestService,
    private sharedService: SharedService,
    private distribuidorService: DistribuidorService,
    public modalService: NgbModal,
    private map: MapService
  ) {}

  /**
   * Íconos FontAwesome que deben ser importados y declarados
   * para ser usados dentro del template
   */
  public faChevronLeft = faChevronLeft;

  /**
   * Partes dinámicas de la UI que cambian a medida que
   * se avanza en el formulario
   */
  public instruction = '';
  public step = 1;
  public maxStep = 8;

  /** Guarda el logo que se vayan a subir */
  imageSrc: any;

  /** Guarda las politicas y condiciones */
  public politicas_condiciones: any;

  /** Guarda booleano si cada formulario es valido o no
   * Esta variable se usará como puente para comunicar componentes hermanos
   * la barra de navegacion y el formulario, en caso que el forumario sea invalido
   * y el usuario quiera continuar, le mostrar un toast con un mensaje de error*/
  FormInvalid = 0;
  public horecaFormInvalid = true;
  public juridicoFormInvalid = true;
  public establecimientoFormInvalid = true;
  public establecimientoCredencialesFormInvalid = true;

  /** Modal de carga para darle feedback al usuario */
  modalCarga?: NgbModalRef;

  /************************* Datos llenados para perfil Horeca **************************/

  //----Horeca Paso 2
  public horeca_perfil_seleccionado = '';

  /*********** Perfil Horeca Propietario ***********/

  //Horeca Paso 3
  public tipo_persona = '';
  //Horeca Paso 4
  public tipo_doc_identidad: any;
  public doc_identidad: any;
  public nombres: any;
  public apellidos: any;
  public pais = 'Colombia';
  public departamento: any;
  public ciudad: any;
  public celular: any;
  public telefono: any;
  public correo: any;
  public contrasena: any;
  //Horeca Paso 5
  public nombre_establecimiento: any;
  public razon_social: any;
  public nit: any;
  public tipo_negocio: any;
  public pais_empresa = 'Colombia';
  public departamento_empresa: any;
  public ciudad_empresa: any;
  public telefono_empresa_1: any;
  public telefono_empresa_2: any;
  public tipo_doc_identidad_prop: any;
  public doc_identidad_prop: any;
  public nombres_prop: any;
  public apellidos_prop: any;
  public telefono_prop: any;
  public correo_prop: any;
  public contrasena_prop: any;
  //Horeca Paso 6
  public archivos: File[] = []; //0 es RUT, 1 es cámara de comercio, 2 es Doc de representante, y 3 es logo
  public acepto_terminos = false;

  /** Datos para perfil trabajador (son de la forma t_<nombre_variable>) */
  //Horeca Paso 3
  public t_nit: any;
  public t_horeca: UsuarioHoreca | any;

  //Horeca Paso 4
  public t_tipo_doc_identidad: any;
  public t_doc_identidad: any;
  public t_nombres: any;
  public t_apellidos: any;
  public t_pais = 'Colombia';
  public t_departamento: any;
  public t_ciudad: any;
  public t_celular: any;
  public t_telefono: any;
  public t_tipo_usuario: any;

  //Horeca Paso 5
  public t_correo: any;
  public t_contrasena: any;
  public t_acepto_terminos = false;

  /** Flags para controlar la navegación */
  public continue_disabled = true;

  /** Flags para mostrar errores */
  //Horeca Paso 6
  public error_formato_pdf = false;
  public error_formato_icon = false;

  /** Correo y contraseña a compartir con componente */
  correo_servicio: any;
  contraseña_servicio: any;

  /************************* Datos llenados para perfil Distribuidor **************************/

  /*********** Perfil Distribuidor Propietario ***********/

  //---Distribuidor Paso 2
  public distribiudor_perfil_seleccionado = '';

  //---Distribuidor Paso 3
  public dist_tipo_persona = 'Selecciona';

  //---Distribuidor Paso 4
  public dist_tipo_documento = 'Selecciona';
  public dist_numero_documento: any;
  public dist_nombres: any;
  public dist_apellidos: any;
  public dist_pais = 'Colombia';
  public dist_departamento: any;
  public dist_ciudad: any;
  public dist_celular: any;
  public dist_telefono: any;
  public dist_correo: any;
  public dist_contrasena: any;

  //---Distribuidor Paso 5
  public dist_nombre_distribuidor: any;
  public dist_razon_social: any;
  public dist_descripcion = '';
  public url_pago = '';
  public dist_nit: any;
  public dist_tipo = 'Selecciona';
  public dist_celular_empresa: any;
  public dist_telefono_empresa: any;
  public dist_pais_empresa = 'Colombia';
  public dist_departamento_empresa: any;
  public dist_ciudad_empresa: any;
  public dist_direccion: any;
  public dist_tipo_doc_identidad_prop: any;
  public dist_doc_identidad_prop: any;
  public dist_nombres_prop: any;
  public dist_apellidos_prop: any;
  public dist_telefono_prop: any;
  public dist_correo_prop: any;
  public dist_contrasena_prop: any; //Viene de arriba

  //---Distribuidor Paso 6
  public dist_lunes_a_viernes = false;
  public dist_horario_lunes_a_viernes: any;
  public dist_sabados = false;
  public dist_horario_sabados: any;
  public dist_domingos = false;
  public dist_horario_domingos: any;
  public dist_tiempo_entrega: any;
  public dist_valor_minimo: any;
  public dist_credito = false;
  public dist_efectivo = false;
  public dist_transaccion = false;
  public dist_datafono = false;

  //---Distribuidor Paso 7
  public dist_area_cobertura: { longitud: number; latitud: number }[] = [];
  public zonas_cobertura: any = {};
  public datos_poligono: any[] = [];

  //---Distribuidor Paso 8
  public dist_archivos: File[] = []; //0 es RUT, 1 es cámara de comercio, 2 es Doc de representante, y 3 es logo
  public dist_acepto_terminos = false;

  /*********** Perfil Distribuidor Trabajador ***********/

  /** Datos para perfil trabajador (son de la forma dist_t_<nombre_variable>) */
  //---Distribuidor Paso 3
  public dist_t_nit_cc: any;
  public dist_encontrado: { _id: string; nit_cc: string; nombre: string; logo: string } = {
    _id: '',
    nit_cc: '',
    nombre: '',
    logo: '',
  };

  //---Distribuidor Paso 4
  public dist_t_tipo_doc: any;
  public dist_t_num_doc: any;
  public dist_t_nombres: any;
  public dist_t_apellidos: any;
  public dist_t_pais = 'Colombia';
  public dist_t_departamento: any;
  public dist_t_ciudad: any;
  public dist_t_celular: any;
  public dist_t_telefono: any;
  public dist_t_tipo_usuario: any;

  //---Distribuidor Paso 5
  public dist_t_correo: any;
  public dist_t_contrasena: any;
  public dist_t_acepto_terminos = false;

  /************************* **************************** **************************/

  /**
   * Se inicia el componente listo para recibir el registro de un nuevo usuario.
   * Si se entra directamente al formulario de Horeca o de Distribuidor, se cambian
   * los parámetros del formulario para tener en cuenta esto
   */
  ngOnInit(): void {
    this.fetchPolitiasCondiciones();
    this.instruction = '¿En qué entidad estás?\nEscoge el perfil que se acomode a tu empresa';
    this.step = 1;
    if (this.router.url == '/registro/horeca') {
      this.instruction = 'Escoge el perfil con el cual te identificas';
      this.step = 2;
    } else if (this.router.url == '/registro/distribuidor') {
      this.router.navigate(['/registro/distribuidor']);
      this.step = 2;
    }
  }

  /************************ Funciones globales para controlar flujo ************************/

  /**
   * Avanza un paso en el formulario
   * Es para cambiar los elementos dinámicos del UI
   */
  continue() {
    this.step++;
    if (this.router.url == '/registro/horeca') {
      //Se está llenando un perfil Horeca
      this.continueHoreca();
    } else if (this.router.url == '/registro/distribuidor') {
      //Se está llenando un perfil Distribuidor
      this.continueDistribuidor();
    }
  }

  /**
   * Actualiza el tipo de persona para el registro del Horeca,
   * y permite continuar al siguiente paso habilitando el botón
   * adecuado
   * @param tipo_persona Tipo de persona seleccionada. Puede ser
   * "Natural" o "Jurídica"
   */
  selectTipoPersona(tipo_persona: string) {
    this.tipo_persona = tipo_persona;
    this.continue_disabled = false;
  }

  //************************* Flujo en formularios para perfil Horeca **************************/

  /**
   * Se usa cuando el usuario selecciona el registro de un usuario horeca
   */
  selectHoreca() {
    this.router.navigate(['/registro/horeca']);
    this.maxStep = 6;
    this.instruction = 'Escoge el perfil con el cual te identificas';
    this.step++;
  }

  /**
   * Actualiza el perfil Horeca seleccionado y cambia los elementos
   * dinámicos de la UI para reflejar el perfil seleccionado
   * @param perfil El perfil del Horeca, puede ser "Propietario o
   * representante legal" o "Trabajador"
   */
  selectPerfilHoreca(perfil: string) {
    if (this.horeca_perfil_seleccionado == '') {
      this.continue();
    }
    if (perfil == 'Propietario o representante legal') {
      this.maxStep = 6;
    } else if (perfil == 'Trabajador') {
      this.maxStep = 5;
    }
    this.horeca_perfil_seleccionado = perfil;
  }

  /**
   * Controlas las instrucciones de los formularios
   */
  continueHoreca() {
    if (this.step == 1) {
      this.instruction = '¿En qué entidad estás?\nEscoge el perfil que se acomode a tu empresa';
    } else if (this.step == 2 || this.step == 3) {
      this.instruction = 'Escoge el perfil con el cual te identificas';
    } else if (this.step >= 4) {
      if (this.horeca_perfil_seleccionado == 'Propietario o representante legal') {
        this.continueHorecaPropietario();
      } else if (this.horeca_perfil_seleccionado == 'Trabajador') {
        this.continueHorecaTrabajador();
      }
    }
  }

  /**
   * Controla las instrucciones a mostrar en el flujo de los formularios
   */
  continueHorecaPropietario() {
    if (this.step == 4) {
      this.instruction = 'Diligencia tus datos personales y de contacto';
    } else if (this.step == 5) {
      this.instruction = 'Información de la empresa';
    } else if (this.step == 6) {
      this.instruction = 'Adjunta la documentación';
    } else if (this.step == 7) {
      this.step--;
      this.registroUsuarioHoreca();
    }
  }

  /**
   * Se llama para controlar el flujo hacia adelante en el registro de
   * un usuario horeca de tipo trabajador
   */
  continueHorecaTrabajador() {
    if (this.step == 4) {
      this.instruction = 'Diligencia tu información personal';
    } else if (this.step == 5) {
      this.instruction = 'Credenciales';
    } else if (this.step == 6) {
      this.step--;
      this.registroUsuarioHoreca();
    }
  }

  /**
   * Intenta el registro del usuario con los datos llenados
   * y muestra un pop-up dependiendo del estado de la solicitud
   */
  registroUsuarioHoreca() {
    if (this.horeca_perfil_seleccionado == 'Propietario o representante legal') {
      this.registroHorecaPropietario();
    } else if (this.horeca_perfil_seleccionado == 'Trabajador') {
      this.registroHorecaTrabajador();
    }
  }

  /**
   * Maneja el registro del usuario horeca en el caso en que se
   * está registrando el propietario
   */
  registroHorecaPropietario() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    /*********** Primero - registro del usuario Horeca **********/
    const nuevo_usuario: UsuarioHoreca = new UsuarioHoreca(
      this.nombres_prop,
      this.apellidos_prop,
      this.correo_prop,
      this.pais_empresa,
      this.departamento_empresa,
      this.ciudad_empresa,
      this.tipo_doc_identidad_prop,
      this.doc_identidad_prop,
      this.contrasena,
      this.telefono_prop,
      this.telefono_prop,
      'Aprobador',
      this.nombre_establecimiento,
      this.pais_empresa,
      this.departamento_empresa,
      this.ciudad_empresa,
      this.telefono_empresa_1,
      this.telefono_empresa_2,
      this.tipo_doc_identidad_prop,
      this.doc_identidad_prop,
      this.nombres_prop,
      this.apellidos_prop,
      this.telefono_prop,
      this.correo_prop,
      'https://feat-resources.s3.amazonaws.com/logos/Usuario_horeca-626eba0ad8e885ac10203633-icon-organizacion.png',
      'Aprobado',
      'Horeca',
      this.razon_social,
      this.nit,
      'Jurídica',
      this.tipo_negocio
    );
    nuevo_usuario.solicitud_vinculacion = 'Aprobado';
    if (this.tipo_persona == 'Natural') {
      nuevo_usuario.tipo_usuario = 'Natural';
      nuevo_usuario.nit = this.doc_identidad_prop;
      nuevo_usuario.razon_social = `${this.nombres_prop} ${this.apellidos_prop}`;
    }
    // Guarda las credenciales para enviarlas al login
    this.correo_servicio = this.correo_prop;
    this.contraseña_servicio = this.contrasena;

    this.modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    this.rest
      .post('usuario_horeca/registro', nuevo_usuario)
      .toPromise()
      .then((resp: any) => {
        if (resp.success === true) {
          /*********** Segundo - registro del trabajador **********/
          const nuevo_trabajador = new Trabajador(
            this.nombres,
            this.apellidos,
            this.correo,
            this.contrasena,
            this.telefono,
            this.celular,
            this.pais,
            this.departamento,
            this.ciudad,
            this.tipo_doc_identidad,
            this.doc_identidad,
            'PROPIETARIO/REP LEGAL',
            'Aprobado',
            true,
            resp.data._id
          );
          /**
           * Si la información del propietario no es la misma del trabajador
           * se cambia el tipo de trabajador de propietario a administrador
           */
          if (this.doc_identidad !== this.doc_identidad_prop) {
            nuevo_trabajador.tipo_trabajador = 'ADMINISTRADOR APROBADOR';
          }
          this.rest
            .post('trabajador/registro', nuevo_trabajador)
            .toPromise()
            .then((resp_2: any) => {
              if (resp_2.success === true) {
                this.modalCarga?.close();
                this.guardarDocumentos(resp.data._id, 'horeca', 'Usuario_horeca');
              } else {
                this.modalCarga?.close();
                this.lanzarModalError(resp_2.msg);
              }
            })
            .catch(this.lanzarModalErrorInesperado);
        } else {
          this.modalCarga?.close();
          this.lanzarModalError(resp.msg);
        }
      })
      .catch(() => {
        this.modalCarga?.close();
        this.lanzarModalError('Ocurrió un error inesperado, por favor intenta de nuevo más tarde');
      });
  }

  /**
   * Maneja el registro del usuario horeca en el caso en que se
   * está registrando un trabajador
   */
  registroHorecaTrabajador() {
    const nuevo_trabajador = new Trabajador(
      this.t_nombres,
      this.t_apellidos,
      this.t_correo,
      this.t_contrasena,
      this.t_telefono,
      this.t_celular,
      this.t_pais,
      this.t_departamento,
      this.t_ciudad,
      this.t_tipo_doc_identidad,
      this.t_doc_identidad,
      this.t_tipo_usuario,
      'Pendiente',
      true,
      this.t_horeca._id
    );
    /** Guarda las credenciales para enviarlas al login */
    this.correo_servicio = this.t_correo;
    this.contraseña_servicio = this.t_contrasena;

    this.rest
      .post('trabajador/registro', nuevo_trabajador)
      .toPromise()
      .then((resp: any) => {
        if (resp.success === true) {
          this.lanzarModalExito();
        } else {
          this.lanzarModalError(resp.msg);
        }
      })
      .catch(this.lanzarModalErrorInesperado);
  }

  /************************* Flujo en formularios para perfil Distribuidor **************************/
  /**
   * Se usa cuando el usuario selecciona el registro de un usuario distribuidor
   */
  selectDistribuidor() {
    this.router.navigate(['/registro/distribuidor']);
    this.maxStep = 8;
    this.instruction = 'Escoge el perfil con el cual te identificas';
    this.step++;
  }

  /**
   * Controlas las instrucciones de los formularios
   */
  continueDistribuidor() {
    if (this.step == 1) {
      this.instruction = '¿En qué entidad estás?\nEscoge el perfil que se acomode a tu empresa';
    } else if (this.step == 2 || this.step == 3) {
      this.instruction = 'Escoge el perfil con el cual te identificas';
    } else if (this.step >= 4) {
      if (this.distribiudor_perfil_seleccionado == 'Propietario o representante legal') {
        this.continueDistribuidorEmpresa();
      } else if (this.distribiudor_perfil_seleccionado == 'Trabajador') {
        this.continueDistribuidorTrabajador();
      }
    }
  }

  /**
   * Se llama para controlar el flujo en el registro de
   * un distribuidor empresa
   */
  continueDistribuidorEmpresa() {
    //Se está registrando el propietario
    if (this.step == 4) {
      this.instruction = 'Diligencia tus datos personales y de contacto';
    } else if (this.step == 5) {
      this.instruction = 'Información de la empresa';
    } else if (this.step == 6) {
      this.instruction = 'Especificaciones de operación';
    } else if (this.step == 7) {
      this.instruction = 'Zona de cobertura';
    } else if (this.step == 8) {
      this.instruction = 'Adjunta la documentación';
    } else if (this.step == 9) {
      this.step--;
      this.registroUsuarioDistribuidor();
    }
  }

  /**
   * Se llama para controlar el flujo en el registro de
   * un usuario distribuidor de tipo trabajador
   */
  continueDistribuidorTrabajador() {
    if (this.step == 4) {
      this.instruction = 'Diligencia tu información personal';
    } else if (this.step == 5) {
      this.instruction = 'Credenciales';
    } else if (this.step == 6) {
      this.step--;
      this.registroUsuarioDistribuidor();
    }
  }

  /**
   * Actualiza el perfil Distribuidor seleccionado y cambia los elementos
   * dinámicos de la UI para reflejar el perfil seleccionado
   * @param perfil El perfil del Horeca, puede ser "Propietario o
   * representante legal" o "Trabajador"
   */
  selectPerfilDistribuidor(perfil: string) {
    if (this.distribiudor_perfil_seleccionado == '') {
      this.continue();
    }
    if (perfil == 'Propietario o representante legal') {
      this.maxStep = 8;
    } else if (perfil == 'Trabajador') {
      this.maxStep = 5;
    }
    this.distribiudor_perfil_seleccionado = perfil;
  }

  /**
   * Intenta el registro del usuario con los datos llenados
   * y muestra un pop-up dependiendo del estado de la solicitud
   */
  registroUsuarioDistribuidor() {
    if (this.distribiudor_perfil_seleccionado == 'Propietario o representante legal') {
      this.registroDistPropietario();
    } else if (this.distribiudor_perfil_seleccionado == 'Trabajador') {
      this.registroDistTrabajador();
    }
  }

  /**
   * Maneja el registro del usuario distribuidor en el caso en que se
   * está registrando el propietario
   */
  registroDistPropietario() {
    //Horario de atención
    const horario = this.procesarHorarioAtencion();
    //Métodos de pago
    const metodo_pago = this.procesarMetodosPago();
    this.registroDistribuidorPropietario(horario, metodo_pago);
  }

  /**
   * Toma los datos de horario de atención ingresados para el distribuidor
   * y devuelve un string que represente estos datos
   * @returns String representando el horario de atención del distribuidor
   */
  procesarHorarioAtencion(): string {
    let horario = '';

    if (this.dist_lunes_a_viernes) {
      horario += 'Lu-Vi: ' + this.dist_horario_lunes_a_viernes;
    }
    if (this.dist_sabados) {
      if (horario != '') {
        horario += ', ';
      }
      horario += 'Sab: ' + this.dist_horario_sabados;
    }
    if (this.dist_domingos) {
      if (horario != '') {
        horario += ', ';
      }
      horario += 'Dom: ' + this.dist_horario_domingos;
    }

    return horario;
  }

  /**
   * Toma los datos de métodos de pago aceptados ingresados para el distribuidor
   * y devuelve un string que represente estos datos
   * @returns String representando los métodos de pago aceptados por el distribuidor
   */
  procesarMetodosPago(): string {
    let metodo_pago = '';

    if (this.dist_credito) {
      metodo_pago += 'Crédito';
    }
    if (this.dist_efectivo) {
      if (metodo_pago != '') {
        metodo_pago += ', ';
      }
      metodo_pago += 'Efectivo contra entrega';
    }
    if (this.dist_transaccion) {
      if (metodo_pago != '') {
        metodo_pago += ', ';
      }
      metodo_pago += 'Transacción bancaria';
    }
    if (this.dist_datafono) {
      if (metodo_pago != '') {
        metodo_pago += ', ';
      }
      metodo_pago += 'Datáfono contra entrega';
    }

    return metodo_pago;
  }

  /**
   * Maneja el registro del usuario distribuidor en el caso en que se
   * está registrando el propietario como persona natural
   * @param cobertura_coordenadas Las coordenadas del distribuidor
   * @param horario Un string representando el horario de atención
   * @param metodo_pago Un string representando los métodos de pago aceptados
   */
  registroDistribuidorPropietario(horario: string, metodo_pago: string) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    /*********** Primero - registro del distribuidor **********/
    const nuevo_distribuidor: Distribuidor = new Distribuidor(
      this.dist_nombre_distribuidor,
      this.dist_correo_prop,
      this.dist_descripcion,
      this.dist_tiempo_entrega,
      this.dist_ciudad_empresa,
      0,
      this.dist_valor_minimo,
      this.dist_departamento_empresa,
      this.dist_tipo
    );
    nuevo_distribuidor.horario_atencion = horario;
    nuevo_distribuidor.metodo_pago = metodo_pago;
    nuevo_distribuidor.direccion = this.dist_direccion;
    nuevo_distribuidor.celular = this.dist_celular_empresa;
    nuevo_distribuidor.urlPago = this.url_pago;
    nuevo_distribuidor.telefono = this.dist_telefono_empresa;
    nuevo_distribuidor.logo =
      'https://feat-resources.s3.amazonaws.com/logos/Usuario_horeca-626eba0ad8e885ac10203633-icon-organizacion.png';
    nuevo_distribuidor.nit_cc = this.dist_nit;
    nuevo_distribuidor.razon_social = this.dist_razon_social;
    if (this.dist_tipo_persona == 'Natural') {
      nuevo_distribuidor.nit_cc = this.dist_doc_identidad_prop;
      nuevo_distribuidor.razon_social = `${this.dist_nombres_prop} ${this.dist_apellidos_prop}`;
    }
    nuevo_distribuidor.zonas_cobertura = this.zonas_cobertura;
    nuevo_distribuidor.datos_poligono = this.datos_poligono;
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    this.rest
      .post('distribuidor', nuevo_distribuidor)
      .toPromise()
      .then((resp: any) => {
        if (resp.success) {
          /*********** Segundo - registro del trabajador **********/
          const nuevo_trabajador: Trabajador = new Trabajador(
            this.dist_nombres,
            this.dist_apellidos,
            this.dist_correo,
            this.dist_contrasena,
            this.dist_telefono,
            this.dist_celular,
            this.dist_pais,
            this.dist_departamento,
            this.dist_ciudad,
            this.dist_tipo_documento,
            this.dist_numero_documento,
            'PROPIETARIO/REP LEGAL',
            'Aprobado',
            true,
            undefined,
            undefined,
            resp.data._id,
            undefined
          );
          /**
           * Si la información del propietario no es la misma del trabajador
           * se cambia el tipo de trabajador de propietario a administrador
           */
          if (this.dist_numero_documento !== this.dist_doc_identidad_prop) {
            nuevo_trabajador.tipo_trabajador = 'ADMINISTRADOR';
          }
          // Notificación de cobertura a puntos de entrega de la zona
          this.registroNotificacion(resp.data._id);
          const id_dist: string = resp.data._id;
          nuevo_trabajador.distribuidor = id_dist;
          // Guarda las credenciales para enviarlas al login
          this.correo_servicio = this.dist_correo;
          this.contraseña_servicio = this.dist_contrasena;
          this.rest
            .post('trabajador/registro', nuevo_trabajador)
            .toPromise()
            .then((resp_2: any) => {
              if (resp_2.success === true) {
                this.modalCarga?.close();
                this.guardarDocumentos(resp.data._id, 'distribuidor', 'Distribuidor');
              } else {
                this.modalCarga?.close();
                this.lanzarModalError(resp_2.msg);
              }
            });
        } else {
          this.modalCarga?.close();
          this.lanzarModalError(resp.msg);
        }
      })
      .catch(() => {
        this.modalCarga?.close();
        this.lanzarModalError('Ocurrió un error inesperado, por favor intenta de nuevo más tarde');
      });
  }

  /**
   * Realiza el análisis del listado de puntos de entrega con quienes tiene alcance en su cobertura
   * @distribuidor información del distribuidor que acaba de ser registrado
   */
  registroNotificacion(distribuidor: any) {
    this.rest
      .get(`lista_puntos_notificacion/${distribuidor}`)
      .toPromise()
      .then(async (resp: any) => {
        const puntosNotificar = <any>[];
        resp.forEach((punto: any) => {
          puntosNotificar.push({
            punto_entrega: punto._id,
            usuario_horeca: punto.usuario_horeca,
            punto_nombre: punto.nombre,
          });
        });
        const body = {
          distribuidor: distribuidor,
          data: puntosNotificar,
        };
        this.rest
          .post('/notificaciones/nuevo_distribuidor', body)
          .toPromise()
          .then()
          .catch((err) => {
            throw err;
          });
      });
  }

  /**
   * Maneja el registro del usuario distribuidor en el caso en que se
   * está registrando un trabajador
   */
  registroDistTrabajador() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
    };
    const nuevo_trab: Trabajador = new Trabajador(
      this.dist_t_nombres,
      this.dist_t_apellidos,
      this.dist_t_correo,
      this.dist_t_contrasena,
      this.dist_t_telefono,
      this.dist_t_celular,
      this.dist_t_pais,
      this.dist_t_departamento,
      this.dist_t_ciudad,
      this.dist_t_tipo_doc,
      this.dist_t_num_doc,
      this.dist_t_tipo_usuario,
      'Pendiente',
      true,
      undefined,
      undefined,
      this.dist_encontrado._id,
      undefined
    );
    // Guarda las credenciales para enviarlas al login
    this.correo_servicio = this.dist_t_correo;
    this.contraseña_servicio = this.dist_t_contrasena;
    this.rest
      .post('trabajador/registro', nuevo_trab)
      .toPromise()
      .then(() => {
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
        modalRef.componentInstance.title = '¡Genial!';
        modalRef.componentInstance.msg =
          'Tu formulario fue enviado para la aprobación del sistema, te estaremos avisando por correo para que puedas ingresar con las credenciales configuradas';
        modalRef.componentInstance.btn_msg = 'Listo';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigate(['/login']);
        };
      })
      .catch(this.lanzarModalErrorInesperado);
  }

  /******************* Funciones globales para los formularios *******************/

  /**
   * Abre un modal de error mostrando el mensaje que entra por parámetro
   * @param msg El mensaje de error a mostrar por parámetro
   */
  lanzarModalError(msg: string) {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
    };
    const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = msg;
    modalRef.componentInstance.btn_msg = 'Volver a registro';
  }

  /**
   * Abre un modal de error mostrando un mensaje genérico. Este modal se llama
   * cuando se desconoce la causa del error
   */
  lanzarModalErrorInesperado() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
    };
    const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg =
      'Ocurrió un error inesperado registrando al trabajador. Por favor intenta de nuevo más tarde.';
    modalRef.componentInstance.btn_msg = 'Volver a registro';
  }

  /**
   * Abre un modal de éxito mostrando el mensaje que entra por parámetro
   */
  lanzarModalExito() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
    };
    const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
    modalRef.componentInstance.title = '¡Genial!';
    modalRef.componentInstance.msg =
      'Tu formulario fue enviado para la aprobación del sistema, te estaremos avisando por correo para que puedas ingresar con las credenciales configuradas';
    modalRef.componentInstance.btn_msg = 'Listo';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/login']);
    };
  }

  /**
   * Abre un modal de éxito mostrando el mensaje que entra por parámetro
   * luego loguea al usuario atuomaticamente y lo redirige a inicio
   */
  lanzarModalExitoYRedirigirAInicio() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    // Servicio para enviar datos de inici a form. de login
    this.sharedService.sendCorreo(this.correo_servicio);
    this.sharedService.sendClave(this.contraseña_servicio);
    const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
    modalRef.componentInstance.title = '¡Genial!';
    modalRef.componentInstance.msg = 'Comienza a disfrutar de esta plataforma';
    modalRef.componentInstance.btn_msg = 'Listo';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/login']);
      setTimeout(() => {
        this.modalCarga?.close();
        // Autologin
        this.sharedService.sendLoginEvent();
      }, 2000);
    };
  }

  /**
   * Esta función captura el logo subido por el usuario en el DOM
   */
  captureLogo(event: any) {
    const logoCapturado = <File>event.target.files[0];
    this.archivos[3] = logoCapturado;
    this.dist_archivos[3] = logoCapturado;
    // Una vez el usuario sube una imagen, esta se mostrará en el DOM
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => (this.imageSrc = reader.result);
      reader.readAsDataURL(file);
    }
  }

  /**
   * Esta función organiza los dist_archivos cargados
   * por los usuarios y los carga en la base de datos
   */
  guardarDocumentos(idUser: any, form: string, entidad: string) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    /** Valida de donde viene el formulario para tratar los inputs respectivos */
    let archivos: File[] = [];
    if (form === 'horeca') {
      archivos = this.archivos;
    } else if (form === 'distribuidor') {
      archivos = this.dist_archivos;
    }
    try {
      const upload_form: FormData = new FormData();
      if (archivos[3] != undefined) {
        upload_form.append(`logo`, archivos[3]);
      }
      if (archivos[0] != undefined) {
        upload_form.append(`rut`, archivos[0]);
      }
      if (archivos[1] != undefined) {
        upload_form.append(`camara_comercio`, archivos[1]);
      }
      if (archivos[2] != undefined) {
        upload_form.append(`documento_identidad`, archivos[2]);
      }
      if (archivos[4] != undefined) {
        upload_form.append(`cert_bancaria`, archivos[4]);
      }
      if (archivos[5] != undefined) {
        upload_form.append(`cert_creacion`, archivos[5]);
      }
      if (archivos[6] != undefined) {
        upload_form.append(`cert_credito`, archivos[6]);
      }
      this.distribuidorService.postDocuments(document, idUser, form, upload_form).subscribe((resp: any) => {
        this.lanzarModalExitoYRedirigirAInicio();
      });
    } catch (error) {
      this.modalCarga?.close();
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'Ocurrió un error inesperado, uno o todos tus documentos/logo no pudieron ser guardados ¡Por favor ingresa a la plataforma e ingresalos manualmente!';
      modalRef.componentInstance.btn_msg = 'Volver';
    }
  }

  /**
   * Recupera las politicas y condiciones
   */
  fetchPolitiasCondiciones() {
    this.rest
      .get('documentos_politicas')
      .toPromise()
      .then((resp: any) => {
        this.politicas_condiciones = resp;
      });
  }
}
