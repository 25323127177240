import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
@Injectable({
  providedIn: 'root',
})
export class PedidosService {
  constructor(private http: HttpClient, private authService: AuthService) {}
  private headers = new HttpHeaders({ Authorization: this.authService.token });

  /**
   * Retorna los pedidos de un establecimiento
   * @param id identificación del establecimiento
   * @returns pedidos de un horeca
   */
  public getPedidoPorEstablecimiento(userHoreca: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/pedido/establecimiento/${userHoreca}`, {
      headers: this.headers,
    });
  }
  /**
   * Retorna los pedidos de un establecimiento
   * @param id identificación del establecimiento
   * @returns pedidos de un horeca
   */
  public getPuntosEstablecimientosHo(userHoreca: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/punto_entrega_establecimiento/${userHoreca}`, {
      headers: this.headers,
    });
  }
  /**
   *
   * @param id identificación del distribuidor
   * @returns .
   */
  public getPedidoPorPuntosData(userHoreca: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/pedido/distribuidores/${userHoreca}`, {
      headers: this.headers,
    });
  }

  public generarCodigo = (data: any): Observable<any> => {
    return this.http.post(`${environment.backendUrl}/api/codigos_generados`, data, {
      headers: this.headers,
    });
  };

  public parametrizacion(): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/parametrizacion`, {
      headers: this.headers,
    });
  }

  // Servicio para traer el pedido segun el id
  public getMyPedidos(id: any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/pedido/${id}`, {
      headers: this.headers,
    });
  }

  /**Servicio para cambiar el estado del pedido */
  public putPedidos(id: any, trabajador: any, data: any): Observable<any> {
    return this.http.put<any>(`${environment.backendUrl}/api/pedido/${id}/${trabajador}`, data, {
      headers: this.headers,
    });
  }

  // Servicio para traer el codigo generado seguin el id
  public getCodigo(id: any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/codigos_generados/${id}`, {
      headers: this.headers,
    });
  }

  // Servicio para traer el trabajador segun el id
  public getFilteredTrabajador(id: any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/trabajador/${id}`, {
      headers: this.headers,
    });
  }

  // Servicio para traer el punto de entrega segun id
  public getFilteredPuntoEntrega(id: any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/punto_entrega/${id}`, {
      headers: this.headers,
    });
  }

  // Servicio para mostrar el tracking del pedido
  public getTrackingPedido(id: any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/pedido/ultimoTracking/${id}`, {
      headers: this.headers,
    });
  }

  public putUltimoTracking(id: any, data: any): Observable<any> {
    return this.http.put<any>(`${environment.backendUrl}/api/pedido/ultimoTracking/${id}`, data, {
      headers: this.headers,
    });
  }

  // Servicio para calificar pedido
  public putCalificarPedido(id: any, idTrabajador: any, data: any): Observable<any> {
    return this.http.put<any>(`${environment.backendUrl}/api/pedido/${id}/${idTrabajador}`, data, {
      headers: this.headers,
    });
  }
  // Servicio para calificar pedido
  public getTotalCalificacionesDistribuidor(id: any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/calificacion_total/distribuidor/${id}`, {
      headers: this.headers,
    });
  }

  // Servicio para traer el pedido segun el id
  public getPuntoEntrega(id: any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/punto_entrega/${id}`, {
      headers: this.headers,
    });
  }

  // Servicio para traer el pedido segun el id
  public postPedidosPorPunto(message: any): Observable<any> {
    return this.http.post<any>(`${environment.backendUrl}/api/pedidos_por_punto_dist_fechas/`, message, {
      headers: this.headers,
    });
  }
}
