import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-prod-sugeridos-add',
  templateUrl: './prod-sugeridos-add.component.html',
  styleUrls: ['./prod-sugeridos-add.component.css'],
})
export class ProdSugeridosAddComponent implements OnInit {
  @Input() img_src = '';
  @Input() title = '';
  @Input() msg = '';
  @Input() btn_msg = '';
  @Input() dataResult: any;
  @Input() close_callback = (data: any) => {};
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    console.log('Resultado modelo', this.img_src, this.title, this.msg, this.btn_msg, this.dataResult);
  }

  onClose(result: any) {
    this.activeModal.close('Close click');
    this.close_callback(result);
  }
}
