import { Component, OnInit } from '@angular/core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { RestService } from 'src/app/services/rest/rest.service';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-expandir-puntos-organizacion',
  templateUrl: './expandir-puntos-organizacion.component.html',
  styleUrls: ['./expandir-puntos-organizacion.component.css'],
})
export class ExpandirPuntosOrganizacionComponent implements OnInit {
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;

  cantidadPaquetes = 1;
  dataPaquete: any;
  precioPquete = 0;
  valorSinAdmin = 0;
  valorAdmin = 0;

  /** Evita que se pueda hacer click fuera del modal */
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };

  constructor(
    private rest: RestService,
    private router: Router,
    private ngxsStore: Store,
    private modalService: NgbModal
  ) {}

  onPuntosFT() {
    this.router.navigate(['/puntos-ft-organizacion']);
  }

  sumaPaquetes(tipo: boolean) {
    if (this.cantidadPaquetes == 1 && !tipo) {
      this.caculosPaquetes();
      return;
    }
    tipo ? this.cantidadPaquetes++ : this.cantidadPaquetes--;
    this.caculosPaquetes();
  }

  async ngOnInit() {
    await this.fetchParametrizacion();
    this.caculosPaquetes();
  }

  async fetchParametrizacion() {
    const respDataPaquete: any = await this.rest.getJWT(`parametrizacion`).toPromise();
    this.dataPaquete = respDataPaquete[0];
    this.precioPquete = respDataPaquete[0].paquete_base_puntos * respDataPaquete[0].valor_1puntoft;
  }

  caculosPaquetes() {
    this.valorSinAdmin = this.cantidadPaquetes * this.precioPquete;
    this.valorAdmin = (this.valorSinAdmin * this.dataPaquete?.porcentaje_administracion_puntos) / 100;
  }

  async solicitarPaquete() {
    const paquete = {
      cantidad_paquetes: this.cantidadPaquetes,
      puntos_ft_paquete: this.dataPaquete?.paquete_base_puntos,
      total_puntos_ft: this.cantidadPaquetes * this.dataPaquete?.paquete_base_puntos,
      total_pago: this.valorAdmin + this.valorSinAdmin,
      organizacion: this.ngxsStore.snapshot().auth.user.organizacion._id,
      fecha_inicio: Date.now(),
      fecha_cierre: Date.now(),
    };
    try {
      this.rest
        .postJWT('Bolsa_puntos_organizacion', paquete)
        .toPromise()
        .then((resp: any) => {
          const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          modalRef.componentInstance.msg = 'La solicitud ha sido enviada con éxito';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            window.location.reload();
          };
        });
    } catch (error) {
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'No fue posible enviar la solicitud. Por favor intenta de nuevo más tarde.';
      modalRef.componentInstance.btn_msg = 'Volver';
    }
  }
}
