<app-header-distribuidor></app-header-distribuidor>
<br />
<br />
<div class="row">
  <div class="col-1"></div>
  <div class="col-10">
    <!--Header-->
    <div class="row">
      <div class="col-5">
        <h3>Resumen de la transacción</h3>
      </div>
      <div class="col-5 text-right">
        <button id="btn-wa">
          &nbsp; &nbsp;
          <fa-icon [icon]="faWhatsapp" [styles]="{ color: '#FFFFFF' }"></fa-icon>
          &nbsp; Necesito ayuda de Feat &nbsp; &nbsp;
        </button>
      </div>
    </div>
  </div>
  <div class="col-1"></div>
</div>
<br />
<div class="row">
  <!--Info de pago-->
  <div class="col-6 mx-auto">
    <div id="card-pago">
      <!--Título-->
      <div class="row">
        <div class="col-12">
          <h4>Datos</h4>
          <br />
          <ul>
            <li>ID: {{ payment?.reference }}</li>
            <li>Descripción: {{ transaction?.descripcion }}</li>
            <li>Referencia de pago: {{ payment?.id }}</li>
            <li>Valor: {{ payment?.amount_in_cents / 100 | currency: 'COP':'symbol-narrow':'1.1-2' }}</li>
            <li>Moneda: {{ payment?.currency }}</li>
            <li>Método de pago: {{ payment?.payment_method_type }}</li>
            <li>Estado: {{ payment?.status }}</li>
            <li>Mensaje: {{ payment?.status_message }}</li>
            <li>Fecha: {{ payment?.created_at | date }}</li>
          </ul>

          Puede consultar el comprobante
          <a [href]="'https://transaction-redirect.wompi.co/check?id=' + payment?.id">aquí</a>
          .
        </div>
      </div>
      <br />
      <!--Continuar-->
      <div class="row">
        <div class="col-4">
          <button class="btn-purple" routerLink="/inicio-distribuidor">Inicio</button>
        </div>
      </div>
    </div>
  </div>
  <br />
  <br />
  <br />
</div>
