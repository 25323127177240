<div class="pt-5 overflow-auto overflow-y-visible">
  <div class="fit-content">
    <div class="d-flex col-12 scroll-prod p-0">
      <div class="col-prod" *ngFor="let prod of productos; index as i">
        <!--Card saldo-->
        <div class="card card_prod" *ngIf="prod.saldos === true && prod.precios">
          <div class="card-body card_body_prod">
            <!--Header código-->
            <div class="row header_codigo" [ngClass]="{'producto-inactivo': prod.estadoActualizacion === 'Inactivo'}">
              <div class="col-12 text-center">
                <p>Código saldo:<br>{{ prod.codigo_promo || 'No registra'}}</p>
              </div>
            </div>
            <span class="d-flex flex-column align-content-between justify-content-between contenedor-informacion">
              <span>
                <!--Estado de productos y puntos-->
                <div class="row row_data_prod">
                  <div class="col-6 text-left">
                    <p>{{ prod.estadoActualizacion === 'Inactivo' ? 'Inactivo' : prod.precios[0].estado }}</p>
                  </div>
                  <!-- <div class="col-6 text-right">
                    <p>
                      {{ prod.precios[0].puntos_ft_unidad }} punto{{ prod.precios[0].puntos_ft_unidad == 1 ? '' : 's' }}
                      ft.
                    </p>
                  </div> -->
                </div>
                <!--Foto producto-->
                <div class="row row_data_prod contenedor-imagenes">
                  <div class="col-12 text-center">
                    <img *ngIf="prod.fotos" [src]="prod.fotos[0]" class="img-prod" [alt]="'Foto de ' + prod.nombre" />
                  </div>
                </div>
                <!--Nombre producto-->
                <div class="row row_data_prod contenedor-nombre">
                  <div class="col-12 text-left">
                    <p class="m-0">{{ prod.nombre }}</p>
                  </div>
                </div>
                <!--Fecha de vencimiento-->
                <div class="row row_data_prod">
                  <div class="col-12 text-left">
                    <p class="m-0" *ngIf="prod.fecha_vencimiento">
                      Vence: {{ procesarFechaVencimiento(prod.fecha_vencimiento) }}
                    </p>
                  </div>
                </div>
                <!--Precio anterior-->
                <div class="row row_data_prod" style="color: red">
                  <div class="col-6 text-left pr-0">
                    <p class="precio">Precio anterior</p>
                  </div>
                  <div class="col-6 text-right">
                    <h5 style="text-decoration: line-through">
                      {{ prod.precios[0].precio_unidad | currency: '$':'symbol':'1.0-0' }}
                    </h5>
                  </div>
                </div>
                <!--Precio por unidad-->
                <div class="row row_data_prod">
                  <div class="col-5 text-left pr-0">
                    <p class="precio">Precio und.</p>
                  </div>
                  <div class="col-7 text-right">
                    <h4>
                      {{ prod.precios[0].precio_descuento | currency: '$':'symbol':'1.0-0' }}
                    </h4>
                  </div>
                </div>
              </span>
              <!--Btn Editar-->
              <div class="row row_data_prod">
                <div class="col-12 text-center">
                  <button class="btn-white" (click)="popUpEditarSaldo(prod)" id="btn-editar">Editar</button>
                </div>
              </div>
            </span>
          </div>
        </div>
        <!--Card promoción-->
        <div class="card card_prod" *ngIf="prod.promocion === true && prod.precios">
          <div class="card-body card_body_prod">
            <!--Header código-->
            <div class="row header_codigo" [ngClass]="{'producto-inactivo': prod.estadoActualizacion === 'Inactivo'}">
              <div class="col-12 text-center">
                <p>Código promoción:<br>{{prod.codigo_promo || 'No registra'}}</p>
              </div>
            </div>
            <span class="d-flex flex-column align-content-between justify-content-between contenedor-informacion">
              <span>
                <!--Estado de productos -->
                <div class="row row_data_prod">
                  <div class="col-6 text-left">
                    <p>{{ prod.estadoActualizacion === 'Inactivo' ? 'Inactivo' : prod.precios[0].estado }}</p>
                  </div>
                  <div class="col-6 text-right">
                    <div class="promo-tag m-0">Promo</div>
                  </div>
                </div>
                <!--Foto producto-->
                <span class="m-0 w-100 contenedor-imagenes">
                  <div class="col-12 m-0 row_data_prod carouselCont">
                    <ngb-carousel class="w-100">
                      <ng-template ngbSlide *ngFor="let img_src of prod.fotos" class="w-100">
                        <div class="w-100 col-12 d-flex justify-content-center">
                          <img [src]="img_src" class="img-prod rounded" [alt]="'Foto de ' + prod.nombre" />
                        </div>
                      </ng-template>
                    </ngb-carousel>
                  </div>
                </span>
                <!--Nombre producto-->
                <div class="col-12 row_data_prod contenedor-nombre">
                  <div class="col-12 text-left">
                    <p>{{ prod.nombre }}</p>
                  </div>
                </div>
                <!--Precio por unidad-->
                <div class="row row_data_prod">
                  <div class="col-5 text-left">
                    <p class="precio">Precio und.</p>
                  </div>
                  <div class="col-7 text-right">
                    <h4>
                      {{ prod.precios[0].precio_unidad | currency: '$':'symbol':'1.0-0' }}
                    </h4>
                  </div>
                </div>
              </span>
              <!--Btn Editar-->
              <div class="row row_data_prod">
                <div class="col-12 text-center">
                  <button class="btn-white" (click)="popUpEditarPromocion(prod)" id="btn-editar">Editar</button>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>