<div class="mb-4 w-100">
  <h5>Indicadores de tus pedidos del mes</h5>
</div>
<!--Indicadores de los pedidos-->
<div class="indicadores">
  <div class="card-info">
    <h3 class="m-0">{{ pendientes }}</h3>
    <span>Pendientes</span>
  </div>
  <div class="card-info">
    <h3 class="m-0">{{ aprobados }}</h3>
    <span>Aprobados</span>
  </div>
  <div class="card-info">
    <h3 class="m-0">{{ alistando }}</h3>
    <span>Alistando</span>
  </div>
  <div class="card-info">
    <h3 class="m-0">{{ despachados }}</h3>
    <span>Despachados</span>
  </div>
  <div class="card-info">
    <h3 class="m-0">{{ entregados }}</h3>
    <span>Entregados</span>
  </div>
  <div class="card-info">
    <h3 class="m-0">{{ cancelados }}</h3>
    <span>Cancelados</span>
  </div>
</div>
<div class="w-100 ml-1 mt-4">
  <h6>Estados del pedido</h6>
</div>
<!--Estados para filtrar la tabla-->
<div class="mt-3">
  <div class="col p-0 col-md-6 col-lg-7 col-xl-12">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills">
      <li [ngbNavItem]="1" (click)="filtrarPorEstado('todos')">
        <a ngbNavLink>Todos</a>
      </li>
      <li [ngbNavItem]="2" (click)="filtrarPorEstado('pendientes')">
        <a ngbNavLink>Pendientes</a>
      </li>
      <li [ngbNavItem]="3" (click)="filtrarPorEstado('aprobados')">
        <a ngbNavLink>Aprobados</a>
      </li>
      <li [ngbNavItem]="4" (click)="filtrarPorEstado('alistando')">
        <a ngbNavLink>Alistando</a>
      </li>
      <li [ngbNavItem]="5" (click)="filtrarPorEstado('despachados')">
        <a ngbNavLink>Despachado</a>
      </li>
      <li [ngbNavItem]="6" (click)="filtrarPorEstado('entregados')">
        <a ngbNavLink>Entregado</a>
      </li>
    </ul>
  </div>
</div>
<!--Tabla de pedidos-->
<div class="card">
  <div class="card-body scroll-tabla" *ngIf="data.length !== 0"> 
    <ng2-smart-table [settings]="settings" [source]="source"></ng2-smart-table>
  </div>
  <div *ngIf="data.length === 0">
    <div class="d-flex align-items-center justify-content-center align-self-center flex-column">
    <img src="./assets/img/icon-warning-amarillo.png" alt="Advertencia" id="icon-warning"
      style="max-width: 200px" class="w-100 p-4" />
    <h6>Nada de nada</h6>
    <p>No hay información para mostrar</p>
  </div>
  </div>
</div>