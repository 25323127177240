import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SetSelectedToken } from 'src/app/actions/token.actions';
import { RestService } from 'src/app/services/rest/rest.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Trabajador } from 'src/app/models/trabajador.model';
import { SimpleComponent } from '../../../modal/simple/simple.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CambiarContrasenaComponent } from 'src/app/modal/cambiar-contrasena/cambiar-contrasena.component';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';

@Component({
  selector: 'app-info-cuenta-organizaciones',
  templateUrl: './info-cuenta-organizaciones.component.html',
  styleUrls: ['./info-cuenta-organizaciones.component.css'],
})
export class InfoCuentaOrganizacionesComponent implements OnInit, OnDestroy {
  /* @HostListener('window:beforeunload', ['$event'])
  doSomething($event: any) {
    console.log('eiii', $event);
  } */

  /*  @HostListener('window:beforeunload') onBeforeUnload() {
    console.log('Dataaaaaaa:');
    return false;
  } */

  formPerson!: FormGroup;

  /** Arreglo con los formatos validos de imagen */
  supported_imgs: string[] = ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp'];

  formOrg!: FormGroup;
  departamentos: any;
  depmentos: any;
  deptoSelect: any;
  ciudades: any;
  ciudadesOrg: any;
  celularOrg = true;
  celularPerson = true;
  telefonoPersona = true;
  telefonoOrg = true;
  logo: any;
  /** Iconos FontAwesome para usar en el template */
  faUpload = faUpload;
  logo_path: any;
  logoUrl: any;
  imageSrc: any;
  modalCambioClave: any;
  constructor(
    private ngxsStore: Store,
    private formBuilder: FormBuilder,
    private rest: RestService,
    private modalService: NgbModal,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private distribuidorService: DistribuidorService
  ) {
    this.buildFormPerson();
    this.buildFormOrg();
  }
  userData: any;
  orgData: any;

  @HostListener('click') unloadHandler() {
    if (this.formOrg.pristine == false) {
      localStorage.setItem('Organizacion-form-pristine', this.formOrg.pristine.toString());
    }

    if (this.formPerson.pristine == false) {
      localStorage.setItem('Persona-form-pristine', this.formPerson.pristine.toString());
    }
  }

  ngOnDestroy() {
    this.modalCambioClave?.close();
  }

  onSubmitUser() {
    this.rest
      .putJWT(`trabajador/${this.userData._id}`, this.formPerson.value)
      .toPromise()
      .then((resp: any) => {
        if (this.authService.user) {
          this.authService.user.apellidos = this.formPerson.get('apellidos')?.value;
          this.authService.user.celular = this.formPerson.get('celular')?.value;
          this.authService.user.ciudad = this.formPerson.get('ciudad')?.value;
          this.authService.user.departamento = this.formPerson.get('departamento')?.value;
          this.authService.user.nombres = this.formPerson.get('nombres')?.value;
          this.authService.user.numero_documento = this.formPerson.get('numero_documento')?.value;
          this.authService.user.pais = this.formPerson.get('pais')?.value;
          this.authService.user.telefono = this.formPerson.get('telefono')?.value;
          this.authService.user.tipo_documento = this.formPerson.get('tipo_documento')?.value;
          this.authService.user.tipo_trabajador = this.formPerson.get('tipo_trabajador')?.value;
        }

        //Se guarda la info el Store
        this.ngxsStore
          .dispatch(
            new SetSelectedToken({
              token: this.authService.token,
              email: this.authService.email,
              user: this.authService.user,
              user_horeca: this.authService.user_horeca,
              user_distribuidor: this.authService.user_distribuidor,
              user_organizacion: this.authService.user_organizacion,
            })
          )
          .toPromise()
          .then();
        localStorage.removeItem('Persona-form-pristine');
        const modalRef = this.modalService.open(SimpleComponent);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
        modalRef.componentInstance.title = '¡Genial!';
        modalRef.componentInstance.msg = 'Los cambios han sido gardados con exito';
        modalRef.componentInstance.btn_msg = 'Listo';
        modalRef.componentInstance.close_callback = () => {
          window.location.reload();
        };
      })
      .catch(() => {
        const modalRef = this.modalService.open(SimpleComponent);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
        modalRef.componentInstance.btn_msg = 'Volver';
      });
    localStorage.removeItem('Persona-form-pristine');
  }

  onSubmitOrg() {
    if (this.logo_path != undefined) {
      const upload_form: FormData = new FormData();
      upload_form.append(`logo`, this.logo_path);
      this.distribuidorService.postLogo(this.orgData._id, upload_form, 'Organizacion').subscribe((res) => {
        this.formOrg.get('logo')?.setValue(res.logo_ruta);
        this.saveData();
      });
    } else {
      this.saveData();
    }
  }

  saveData() {
    this.rest
      .putJWT(`organizacion/${this.orgData._id}`, this.formOrg.value)
      .toPromise()
      .then((resp: any) => {
        this.authService.user_organizacion.celular = this.formOrg.get('celular')?.value;
        this.authService.user_organizacion.ciudad = this.formOrg.get('ciudad')?.value;
        this.authService.user_organizacion.departamento = this.formOrg.get('departamento')?.value;
        this.authService.user_organizacion.direccion = this.formOrg.get('direccion')?.value;
        this.authService.user_organizacion.logo = this.formOrg.get('logo')?.value;
        this.authService.user_organizacion.nit = this.formOrg.get('nit')?.value;
        this.authService.user_organizacion.nombre = this.formOrg.get('nombre')?.value;
        this.authService.user_organizacion.pais = this.formOrg.get('pais')?.value;
        this.authService.user_organizacion.razon_social = this.formOrg.get('razon_social')?.value;
        this.authService.user_organizacion.sector = this.formOrg.get('sector')?.value;
        this.authService.user_organizacion.telefono = this.formOrg.get('telefono')?.value;
        //Se guarda la info el Store
        this.ngxsStore
          .dispatch(
            new SetSelectedToken({
              token: this.authService.token,
              email: this.authService.email,
              user: this.authService.user,
              user_horeca: this.authService.user_horeca,
              user_distribuidor: this.authService.user_distribuidor,
              user_organizacion: this.authService.user_organizacion,
            })
          )
          .toPromise()
          .then();
        localStorage.removeItem('Organizacion-form-pristine');
        const modalRef = this.modalService.open(SimpleComponent);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
        modalRef.componentInstance.title = '¡Genial!';
        modalRef.componentInstance.msg = 'Los cambios han sido guardados con éxito';
        modalRef.componentInstance.btn_msg = 'Listo';
        modalRef.componentInstance.close_callback = () => {
          window.location.reload();
        };
      })
      .catch(() => {
        const modalRef = this.modalService.open(SimpleComponent);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
        modalRef.componentInstance.btn_msg = 'Volver';
      });
    localStorage.removeItem('Organizacion-form-pristine');
  }

  verificarNumero(e: any) {
    const celularPersona = this.formPerson.get('celular')?.value.toString().length;
    const celularOrg = this.formOrg.get('celular')?.value.toString().length;
    const telefonoPersona = this.formPerson.get('telefono')?.value.toString().length || '';
    const telefonoOrg = this.formOrg.get('telefono')?.value.toString().length;

    if (celularPersona != 10 || celularPersona == null) {
      this.celularPerson = false;
    } else {
      this.celularPerson = true;
    }

    if (telefonoPersona == 10 || telefonoPersona == 7) {
      this.telefonoPersona = true;
    } else {
      this.telefonoPersona = false;
    }

    if (celularOrg != 10 || celularOrg == null) {
      this.celularOrg = false;
    } else {
      this.celularOrg = true;
    }

    if (telefonoOrg == 10 || telefonoOrg == 7) {
      this.telefonoOrg = true;
    } else {
      this.telefonoOrg = false;
    }
  }

  buildFormPerson() {
    this.formPerson = this.formBuilder.group({
      tipo_documento: new FormControl('', [Validators.required]),
      numero_documento: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{5,}$/)]),
      nombres: new FormControl('', [Validators.required]),
      apellidos: new FormControl('', [Validators.required]),
      pais: new FormControl('', [Validators.required]),
      departamento: new FormControl('', [Validators.required]),
      ciudad: new FormControl('', [Validators.required]),
      celular: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9]{5,}$/),
        Validators.minLength(10),
        Validators.maxLength(10),
      ]),
      telefono: new FormControl('', [
        Validators.pattern(/^[0-9]{5,}$/),
        Validators.minLength(7),
        Validators.maxLength(10),
      ]),
      correo: new FormControl({ value: '', disabled: true }, [Validators.required]),
      tipo_trabajador: new FormControl('', [Validators.required]),

      _id: [''],
    });
  }

  buildFormOrg() {
    this.formOrg = this.formBuilder.group({
      nit: ['', [Validators.required, Validators.pattern(/^[0-9.-]*$/)]],
      ciudad: ['', [Validators.required]],
      departamento: ['', [Validators.required]],
      nombre: ['', [Validators.required]],
      pais: ['', [Validators.required]],
      telefono: ['', [Validators.pattern(/^[0-9]{5,}$/), Validators.minLength(7), Validators.maxLength(7)]],
      razon_social: ['', [Validators.required]],
      sector: [''],
      direccion: ['', [Validators.required]],
      celular: [
        '',
        [Validators.required, Validators.pattern(/^[0-9]{5,}$/), Validators.minLength(10), Validators.maxLength(10)],
      ],
      logo: [''],
      _id: [''],
    });
  }

  ngOnInit(): void {
    this.userData = this.ngxsStore.snapshot().auth.user;
    this.orgData = this.ngxsStore.snapshot().auth.user_organizacion;
    this.formPerson.patchValue(this.userData);
    this.formOrg.patchValue(this.orgData);
    this.logo = this.orgData.logo;
    this.rest
      .getImagine('countrylibrary/es/SA/COL/')
      .toPromise()
      .then((resp: any) => {
        this.departamentos = resp.data;
        this.depmentos = true;
        this.fetchCity('org');
        this.fetchCity('person');
      })
      .catch(() => {});
  }

  fetchCity(origen: any) {
    let Depto;
    if (origen == 'org') {
      Depto = this.formOrg.get('departamento')?.value;
    } else {
      Depto = this.formPerson.get('departamento')?.value;
    }
    const depto = Depto;
    let code: any;
    this.departamentos.map((element: any) => {
      if (element.name == depto) {
        code = element.code;
      }
    });
    this.rest
      .getImagine(`countrylibrary/es/SA/COL/${code}/`)
      .toPromise()
      .then((resp: any) => {
        if (origen == 'org') {
          this.ciudadesOrg = resp.data;
        } else {
          this.ciudades = resp.data;
        }
      })
      .catch(() => {});
  }

  onContrasena() {
    this.modalCambioClave = this.modalService.open(CambiarContrasenaComponent, {
      centered: true,
      windowClass: 'modal-cambiar-contrasena',
    });
  }

  /**
   * el Primer metodo captura el logo y - el segundo metodo captura el los documentos cargados en el DOM
   * @param event Selecciòn que viene del input tipo type desde el HTML
   * la función no devuelve nada
   * el cuarto metodo guarda los documentos y el logo
   */
  captureLogo(event: any) {
    const logoCapturado = <File>event.target.files[0];
    if (logoCapturado.size > 2000000) {
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Este archivo supera el máximo de 2MB permitido!';
      modalRef.componentInstance.btn_msg = 'Volver';
      return;
    } else {
      // Una vez el usuario sube una imagen, esta se mostrará en el DOM
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => (this.imageSrc = reader.result);
        reader.readAsDataURL(file);
      }
      this.logo_path = logoCapturado;
    }
  }
}
