export class Organizacion {
  constructor(
    public nombre: string,
    public correo: string,
    public logo: string,
    public solicitud_vinculacion?: string,
    public marcas?: string[],
    public _id?: string
  ) {}
}
