<app-header-organizacion></app-header-organizacion>
<div class="d-flex justify-content-center">
    <div id="content">
        <h4 class="mb-4">Información del sector</h4>
        <p class="col-lg-6 col-md-8 col-sm-12 p-0 mb-4">
            La información dispuesta a continuación, está construida para ver tu participación en el mercado desde
            diferentes
            puntos.
        </p>
        <!------------------ DOUGHNUT CHARTS ------------------>
        <div class="card-pie-container mb-4">
            <!-- Participación de ventas -->
            <div class="card ventas-mercado">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-between">
                        <div>Participación de ventas en el mercado</div>
                        <div class="d-flex justify-content-end mt-3">
                            <select name="" id="" class="w-50 text-secondary px-3 mr-2"
                                [(ngModel)]="filtro_departamento_ventas" (ngModelChange)="ciudades_ventas = []"
                                (ngModelChange)="filtro_ciudad_ventas = ''"
                                (ngModelChange)="getPlaces(filtro_departamento_ventas, 'ventas')"
                                (ngModelChange)="cargarInformacionVentasMercado()">
                                <option value="todos" selected>Todo el país</option>
                                <option [value]="dpto.name" *ngFor="let dpto of departamentos_empresa">
                                    {{dpto.name}}
                                </option>
                            </select>
                            <select name="" id="" class="w-50 text-secondary px-3 ml-2"
                                [(ngModel)]="filtro_ciudad_ventas" (ngModelChange)="cargarInformacionVentasMercado()">
                                <option value="" disabled selected>Selecciona una ciudad</option>
                                <option value="todos" *ngIf="filtro_departamento_ventas == 'todos'" selected>
                                    Todo el país
                                </option>
                                <option [value]="ciudad.name" *ngFor="let ciudad of ciudades_ventas">
                                    {{ciudad.name}}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex justify-content-center pt-3">
                            Total: {{total_ventas}}
                        </div>
                    </div>
                    <canvas #pie_ventas_mercado
                        [ngClass]="{'inactive': data_pie_ventas_mercado.datasets.length == 0}"></canvas>
                    <div *ngIf="data_pie_ventas_mercado.datasets.length > 0 else mostrarError"></div>
                </div>
            </div>
            <!-- Número establecimientos -->
            <div class="card establecimiento-mercado">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-between">
                        <div>Número de establecimientos en el mercado</div>
                        <div class="d-flex justify-content-end mt-3">
                            <select name="" id="" class="w-50 text-secondary px-3 mr-2"
                                [(ngModel)]="filtro_departamento_clientes" (ngModelChange)="ciudades_clientes = []"
                                (ngModelChange)="filtro_ciudad_clientes = ''"
                                (ngModelChange)="getPlaces(filtro_departamento_clientes, 'clientes')"
                                (ngModelChange)="cargarInformacionEstablecimientoMercado()">
                                <option value="todos" selected>Todo el país</option>
                                <option [value]="dpto.name" *ngFor="let dpto of departamentos_empresa">
                                    {{dpto.name}}
                                </option>
                            </select>
                            <select name="" id="" class="w-50 text-secondary px-3 ml-2"
                                [(ngModel)]="filtro_ciudad_clientes"
                                (ngModelChange)="cargarInformacionEstablecimientoMercado()">
                                <option value="" disabled selected>Selecciona una ciudad</option>
                                <option value="todos" *ngIf="filtro_departamento_clientes == 'todos'" selected>
                                    Todo el país
                                </option>
                                <option [value]="ciudad.name" *ngFor="let ciudad of ciudades_clientes">
                                    {{ciudad.name}}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex justify-content-center pt-3">
                            Total: {{total_clientes}}
                        </div>
                    </div>
                    <canvas #pie_establecimiento_mercado
                        [ngClass]="{'inactive': data_pie_establecimiento_mercado.datasets.length == 0}"></canvas>
                    <div *ngIf="data_pie_establecimiento_mercado.datasets.length > 0 else mostrarError"></div>
                </div>
            </div>
            <!-- Número de sillas alcanzadas -->
            <div class="card sillas-mercado">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-between">
                        <div>Sillas alcanzadas en el mercado</div>
                        <div class="d-flex justify-content-end mt-3">
                            <select name="" id="" class="w-50 text-secondary px-3 mr-2"
                                [(ngModel)]="filtro_departamento_sillas" (ngModelChange)="ciudades_sillas = []"
                                (ngModelChange)="filtro_ciudad_sillas = ''"
                                (ngModelChange)="getPlaces(filtro_departamento_sillas, 'sillas')"
                                (ngModelChange)="cargarInformacionSillasMercado()">
                                <option value="todos" selected>Todo el país</option>
                                <option [value]="dpto.name" *ngFor="let dpto of departamentos_empresa">
                                    {{dpto.name}}
                                </option>
                            </select>
                            <select name="" id="" class="w-50 text-secondary px-3 ml-2"
                                [(ngModel)]="filtro_ciudad_sillas" (ngModelChange)="cargarInformacionSillasMercado()">
                                <option value="" disabled selected>Selecciona una ciudad</option>
                                <option value="todos" *ngIf="filtro_departamento_sillas == 'todos'" selected>Todo el
                                    país
                                </option>
                                <option [value]="ciudad.name" *ngFor="let ciudad of ciudades_sillas">
                                    {{ciudad.name}}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex justify-content-center pt-3">
                            Total: {{total_sillas}}
                        </div>
                    </div>
                    <canvas #pie_sillas_mercado
                        [ngClass]="{'inactive': data_pie_sillas_mercado.datasets.length == 0}"></canvas>
                    <div *ngIf="data_pie_sillas_mercado.datasets.length > 0 else mostrarError"></div>
                </div>
            </div>
            <!-- Categorías en el mercado -->
            <div class="card categoria-mercado">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-between">
                        <div>Participación en categorías del mercado</div>
                        <div class="d-flex justify-content-end mt-3">
                            <select name="" id="" class="w-50 text-secondary px-3 mr-2" [(ngModel)]="filtro_categorias"
                                (ngModelChange)="cargarInformacionCategoriaMercado()">
                                <option value="todos" selected>Todos</option>
                                <option [value]="cat._id" *ngFor="let cat of categorias_productos">
                                    {{cat.nombre}}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex justify-content-center pt-3">
                            Total: {{total_categorias}}
                        </div>
                    </div>
                    <canvas #pie_categoria_mercado
                        [ngClass]="{'inactive': data_pie_categoria_mercado.datasets.length == 0}"></canvas>
                    <div *ngIf="data_pie_categoria_mercado.datasets.length > 0 else mostrarError"></div>
                </div>
            </div>
            <!-- Participación lineas de producto -->
            <div class="card linea-mercado">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-between">
                        <div>Participación en líneas de productos en el mercado</div>
                        <div class="d-flex justify-content-end mt-3">
                            <select name="" id="" class="w-50 text-secondary px-3 mr-2" [(ngModel)]="filtro_lineas"
                                (ngModelChange)="cargarInformacionLineaMercado()">
                                <option value="todos" selected>Todos</option>
                                <option [value]="linea._id" *ngFor="let linea of lineas_productos">
                                    {{linea.nombre}} - ({{linea.categoria.nombre}})
                                </option>
                            </select>
                        </div>
                        <div class="d-flex justify-content-center pt-3">
                            Total: {{total_lineas}}
                        </div>
                    </div>
                    <canvas #pie_linea_mercado
                        [ngClass]="{'inactive': data_pie_linea_mercado.datasets.length == 0}"></canvas>
                    <div *ngIf="data_pie_linea_mercado.datasets.length > 0 else mostrarError"></div>
                </div>
            </div>
            <!-- Participación en tipos de negocio -->
            <div class="card negocio-mercado">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-between">
                        <div>Participación en tipos de negocio en el mercado</div>
                        <div class="d-flex justify-content-end mt-3">
                            <select name="" id="" class="w-50 text-secondary px-3 mr-2"
                                [(ngModel)]="filtro_tipo_negocio" (ngModelChange)="cargarInformacionNegociosMercado()">
                                <option value="todos" selected>Todos</option>
                                <option [value]="tipo" *ngFor="let tipo of tipos_negocio">
                                    {{tipo}}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex justify-content-center pt-3">
                            Total: {{total_tipo_negocio}}
                        </div>
                    </div>
                    <canvas #pie_negocios_mercado
                        [ngClass]="{'inactive': data_pie_negocios_mercado.datasets.length == 0}"></canvas>
                    <div *ngIf="data_pie_negocios_mercado.datasets.length > 0 else mostrarError"></div>
                </div>
            </div>
            <!-- Participación distribuidores -->
            <div class="card distribuidores-mercado">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-between">
                        <div>Participación en distribuidores del mercado</div>
                        <div class="d-flex justify-content-end mt-3">
                            <select name="" id="" class="w-50 text-secondary px-3 mr-2"
                                [(ngModel)]="filtro_distribuidores"
                                (ngModelChange)="cargarInformacionDistribuidoresMercado()">
                                <option value="todos" selected>Todos</option>
                                <option [value]="dist._id" *ngFor="let dist of distribuidores">
                                    {{dist.nombre}}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex justify-content-center pt-3">
                            Total: {{total_distribuidores}}
                        </div>
                    </div>
                    <canvas #pie_distribuidores_mercado
                        [ngClass]="{'inactive': data_pie_distribuidores_mercado.datasets.length == 0}"></canvas>
                    <div *ngIf="data_pie_distribuidores_mercado.datasets.length > 0 else mostrarError"></div>
                </div>
            </div>
        </div>
        <!------------------ BAR CHARTS ------------------>
        <div class="card-bar-container">
            <!-- Establecimientos por ciudad -->
            <div class="card mt-3 p-0 establecimiento-ciudad">
                <div class="card-body">
                    <div class="d-flex flex-row justify-content-between mb-4">
                        <div>Establecimientos por ciudad</div>
                        <span>Total: {{ total_chart_ciudad }}</span>
                    </div>
                    <canvas #bar_establecimiento_ciudad
                        [ngClass]="{'inactive': data_bar_establecimiento_ciudad.datasets.length == 0}"></canvas>
                    <div *ngIf="data_bar_establecimiento_ciudad.datasets.length > 0 else mostrarError"></div>
                </div>
            </div>
            <!-- Establecimientos por tipo de negocio -->
            <div class="card mt-3 p-0 establecimiento-negocio">
                <div class="card-body">
                    <div class="d-flex flex-row justify-content-between mb-4">
                        <div>Establecimientos por tipo de negocio</div>
                        <span>Total: {{ total_chart_negocio }}</span>
                    </div>
                    <canvas #bar_establecimiento_negocio
                        [ngClass]="{'inactive': data_bar_establecimiento_negocio.datasets.length == 0}"></canvas>
                    <div *ngIf="data_bar_establecimiento_negocio.datasets.length > 0 else mostrarError"></div>
                </div>
            </div>
            <!-- Establecimientos por categoría -->
            <div class="card mt-3 p-0 establecimiento-categoria">
                <div class="card-body">
                    <div class="d-flex flex-row justify-content-between mb-4">
                        <div>Establecimientos por categoría</div>
                        <span>Total: {{ total_chart_categoria }}</span>
                    </div>
                    <canvas #bar_establecimiento_categoria
                        [ngClass]="{'inactive': data_bar_establecimiento_categoria.datasets.length == 0}"></canvas>
                    <div *ngIf="data_bar_establecimiento_categoria.datasets.length > 0 else mostrarError"></div>
                </div>
            </div>
            <!-- Sillas por ciudad -->
            <div class="card mt-3 p-0 sillas-ciudad">
                <div class="card-body">
                    <div class="d-flex flex-row justify-content-between mb-4">
                        <div>Sillas por ciudad</div>
                        <span>Total: {{ total_chart_sillas_ciudad }}</span>
                    </div>
                    <canvas #bar_sillas_ciudad
                        [ngClass]="{'inactive': data_bar_sillas_ciudad.datasets.length == 0}"></canvas>
                    <div *ngIf="data_bar_sillas_ciudad.datasets.length > 0 else mostrarError"></div>
                </div>
            </div>
            <!-- Sillas por tipo de negocio -->
            <div class="card mt-3 p-0 sillas-negocio">
                <div class="card-body">
                    <div class="d-flex flex-row justify-content-between mb-4">
                        <div>Sillas por tipo de negocio</div>
                        <span>Total: {{ total_chart_sillas_negocio }}</span>
                    </div>
                    <canvas #bar_sillas_negocio
                        [ngClass]="{'inactive': data_bar_sillas_negocio.datasets.length == 0}"></canvas>
                    <div *ngIf="data_bar_sillas_negocio.datasets.length > 0 else mostrarError"></div>
                </div>
            </div>
            <!-- Sillas por categoría -->
            <div class="card mt-3 p-0 sillas-categoria">
                <div class="card-body">
                    <div class="d-flex flex-row justify-content-between mb-4">
                        <div>Sillas por categoría</div>
                        <span>Total: {{ total_chart_sillas_categoria }}</span>
                    </div>
                    <canvas #bar_sillas_categoria
                        [ngClass]="{'inactive': data_bar_sillas_categoria.datasets.length == 0}"></canvas>
                    <div *ngIf="data_bar_sillas_categoria.datasets.length > 0 else mostrarError"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- Manejo de errores -->
    <ng-template #mostrarError>
        <div class="d-flex justify-content-center mt-4">
            <div>
                <img src="assets/img/icon-warning-amarillo.png" alt="icono de una hoja de papel y una lupa sobre ella"
                    style="max-width: 150px;" />
            </div>
        </div>
        <div class="mt-2">
            <p class="text-center">
                ¡Lo siento! No existen datos para ver aquí
            </p>
        </div>
    </ng-template>
</div>