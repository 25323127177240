<div class="card" role="button" [routerLink]="['/detalle-cliente', dist_vinculado._id]">
  <div class="card-body">
    <h6>
      {{ nombreEstablecimiento || 'No info' }}
    </h6>
    <br />
    <div>
      <p>
        <fa-icon [icon]="faStoreAlt"></fa-icon>
        &nbsp;
        {{ dist_vinculado.punto_entrega?.nombre || 'No info' }}
      </p>
      
      <div class="row">
        <div class="col-6 text-center">
          <div class="pill-cat w-100">
              <fa-icon [icon]="faMapMarkerAlt"></fa-icon>
              &nbsp;
              {{ dist_vinculado.punto_entrega?.ciudad || 'No info' }}
          </div>
        </div>
        <div class="col-6 text-center">
          <div class="pill-sillas w-100">{{ dist_vinculado.punto_entrega.usuario_horeca?.tipo_negocio || 'No info' }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-center" hidden>
          <div class="pill-cat w-100" *ngIf="!dist_vinculado.convenio">
            Sin convenio
          </div>
          <div class="pill-cat w-100" *ngIf="dist_vinculado.convenio">
           En convenio
          </div>
        </div>
        <div class="col-6 text-center">
          <div class="pill-sillas w-100">{{ dist_vinculado.punto_entrega?.sillas || 'No info' }} sillas</div>
        </div>
      </div>
      <br />
      <div class="row" *ngIf="dist_vinculado.estado == 'Pendiente'">
        <div class="col-6">
          <button class="btn-blanco">Rechazar</button>
        </div>
        <div class="col-6">
          <button class="btn-blanco-morado">Aprobar</button>
        </div>
      </div>
      <div class="row" *ngIf="dist_vinculado.estado == 'Rechazado'">
        <span class="mx-4 rechazado-tag">Rechazado</span>
      </div>
      <div class="row" *ngIf="dist_vinculado.estado == 'Aprobado'">
        <span class="mx-4 aprobado-tag">Aprobado</span>
      </div>
      <div class="row" *ngIf="dist_vinculado.estado == 'Cancelado'">
        <span class="mx-4 cancelado-tag">Cancelado</span>
      </div>
    </div>
  </div>
</div>