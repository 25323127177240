<div class="row graficas-container m-0 w-100">
  <!--Num pedidos por distribuidor-->
  <div class="pedidos-distribuidor">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-6 text-left">
            Número de pedidos por distribuidor (Últ. 3 meses)
          </div>
          <div class="col-6 text-right">
            <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_pedidos_distribuidor"
              (ngModelChange)="getChartPedidosDistribuidor()">
              <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                {{mes.filtro}}-{{mes.filtro_inicio}}
              </option>
            </select>
          </div>
          <div class="col-12 text-center">
            Total: {{total_pedidos_distribuidor}}
          </div>
        </div>
        <div class="canvas-container" [ngClass]="{'inactive': data_bar_pedidos_distribuidor.datasets.length == 0}">
          <canvas #bar_pedidos_distribuidor></canvas>
        </div>
        <div *ngIf="data_bar_pedidos_distribuidor.datasets.length > 0 else mostrar_error"></div>
      </div>
    </div>
  </div>
  <!--Tiempo entrega de últimos 5 pedidos-->
  <div class="tiempo-entrega">
    <div class="card">
      <div class="card-body">
        Tiempos de entrega de los últimos 5 pedidos realizados
        <hr />
        <ng2-smart-table [settings]="settings_tiempo_entrega" [source]="source_tiempo_entrega">
        </ng2-smart-table>
      </div>
    </div>
  </div>
  <!--Num pedidos por mes-->
  <div class="pedidos-mes">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-6 text-left">
            Número de pedidos realizados por mes
          </div>
          <div class="col-6 text-right">
            <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_pedidos_mes"
              (ngModelChange)="getChartPedidosMes()">
              <option [value]="ano" *ngFor="let ano of filtro_10_anos">
                {{ano}}
              </option>
            </select>
          </div>
          <div class="col-12 text-center">
            Total: {{total_pedidos_mes}}
          </div>
        </div>
        <div class="canvas-container" [ngClass]="{'inactive': data_bar_pedidos_mes.datasets.length == 0}">
          <canvas #bar_pedidos_mes></canvas>
        </div>
        <div *ngIf="data_bar_pedidos_mes.datasets.length > 0 else mostrar_error"></div>
      </div>
    </div>
  </div>
  <!--Top 10 productos-->
  <!-- <div class="top-productos">
    <div class="card">
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-7 text-left d-flex align-items-center">
            Top 10 de productos
          </div>
          <div class="col-5 text-right">
            <div ngbDropdown class="d-inline-block dropdown-form-1">
              <button class="btn dropwdown" id="dropdownBasic3" ngbDropdownToggle>Punto de entrega</button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
                <button ngbDropdownItem>Sede Norte</button>
                <button ngbDropdownItem>Sede Centro</button>
                <button ngbDropdownItem>Sede Sur</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row" id="scroll-top-productos">
          <div class="col-12 p-0">
            <div class="card card-producto">
              <div class="card-body card-body-producto">
                <div class="row w-100 m-0">
                  <div class="col-3">
                    <img src="../../../assets/img/productos/imagen-23.png" alt="recipiente de aceite de oliva"
                      class="producto-imagen" />
                  </div>
                  <div class="col-9">
                    <div class="text-producto">
                      <p>
                        Queso campesino bloque
                      </p>
                      <p>
                        30 und. &nbsp; 10 cajas
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 p-0">
            <div class="card card-producto">
              <div class="card-body card-body-producto">
                <div class="row w-100 m-0">
                  <div class="col-3">
                    <img src="../../../assets/img/productos/imagen-25.png" alt="Bolsa de leche alqueria" />
                  </div>
                  <div class="col-9">
                    <div class="text-producto">
                      <p>
                        Queso campesino bloque
                      </p>
                      <p>
                        30 und. &nbsp; 10 cajas
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 p-0">
            <div class="card card-producto">
              <div class="card-body card-body-producto">
                <div class="row w-100 m-0">
                  <div class="col-3">
                    <img src="../../../assets/img/productos/imagen-24.png" alt="paquete de galletas" />
                  </div>
                  <div class="col-9">
                    <div class="text-producto">
                      <p>
                        Queso campesino bloque
                      </p>
                      <p>
                        30 und. &nbsp; 10 cajas
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 p-0">
            <div class="card card-producto">
              <div class="card-body card-body-producto">
                <div class="row w-100 m-0">
                  <div class="col-3">
                    <img src="../../../assets/img/productos/imagen-27.png" alt="queso holandes" />
                  </div>
                  <div class="col-9">
                    <div class="text-producto">
                      <p>
                        Queso campesino bloque
                      </p>
                      <p>
                        30 und. &nbsp; 10 cajas
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 p-0">
            <div class="card card-producto">
              <div class="card-body card-body-producto">
                <div class="row w-100 m-0">
                  <div class="col-3">
                    <img src="../../../assets/img/productos/imagen-26.png" alt="queso campesino" />
                  </div>
                  <div class="col-9">
                    <div class="text-producto">
                      <p>
                        Queso campesino bloque
                      </p>
                      <p>
                        30 und. &nbsp; 10 cajas
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div> -->
  <!--Productos comprados últimos 3 meses por distribuidor-->
  <div class="productos-comprados">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-6 text-left">
            Productos comprados (Últ. 3 meses)
          </div>
          <div class="col-6 text-right">
            <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_productos"
              (ngModelChange)="getChartPedidosProducto()">
              <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                {{mes.filtro}}-{{mes.filtro_inicio}}
              </option>
            </select>
          </div>
          <div class="col-12 text-center">
            Total: {{total_productos}}
          </div>
        </div>
        <div class="canvas-container" [ngClass]="{'inactive': data_bar_productos.datasets.length == 0}">
          <canvas #bar_productos></canvas>
        </div>
        <div *ngIf="data_bar_productos.datasets.length > 0 else mostrar_error"></div>
      </div>
    </div>
  </div>
  <!--Compras por categoría-->
  <div class="compras-categoria">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-6 text-left">
            Compras por categoría (Últ. 3 meses)
          </div>
          <div class="col-6 text-right">
            <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_categorias"
              (ngModelChange)="getChartPedidosCategoria()">
              <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                {{mes.filtro}}-{{mes.filtro_inicio}}
              </option>
            </select>
          </div>
          <div class="col-12 text-center">
            Total: {{total_categorias}}
          </div>
        </div>
        <div class="canvas-container" [ngClass]="{'inactive': data_bar_categorias.datasets.length == 0}">
          <canvas #bar_categorias></canvas>
        </div>
        <div *ngIf="data_bar_categorias.datasets.length > 0 else mostrar_error"></div>
      </div>
    </div>
  </div>
  <!-- Compras por distribuidor-->
  <div class="compras-distribuidor">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-6 text-left">
            Compras por distribuidor
          </div>
          <div class="col-6 text-right">
            <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_distribuidor"
              (ngModelChange)="getChartPedidosComprasDistribuidor()">
              <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                {{mes.filtro}}-{{mes.filtro_inicio}}
              </option>
            </select>
          </div>
          <div class="col-12 text-center">
            Total: {{total_ventas_distribuidor}}
          </div>
        </div>
        <div class="canvas-container" [ngClass]="{'inactive': data_bar_ventas_distribuidor.datasets.length == 0}">
          <canvas #bar_ventas_distribuidor></canvas>
        </div>
        <div *ngIf="data_bar_ventas_distribuidor.datasets.length > 0 else mostrar_error"></div>
      </div>
    </div>
  </div>
  <!--Compras por organización-->
  <div class="compras-organizacion">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-6 text-left">
            Compras por organización
          </div>
          <div class="col-6 text-right">
            <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_organizacion"
              (ngModelChange)="getChartPedidosOrganizacion()">
              <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                {{mes.filtro}}-{{mes.filtro_inicio}}
              </option>
            </select>
          </div>
          <div class="col-12 text-center">
            Total: {{total_organizacion}}
          </div>
        </div>
        <div class="canvas-container" [ngClass]="{'inactive': data_bar_organizacion.datasets.length == 0}">
          <canvas #bar_organizacion></canvas>
        </div>
        <div *ngIf="data_bar_organizacion.datasets.length > 0 else mostrar_error"></div>
      </div>
    </div>
  </div>
  <ng-template #mostrar_error>
    <div class="d-flex justify-content-center mt-4">
      <div>
        <img src="assets/img/icon-warning-amarillo.png" alt="icono de una hoja de papel y una lupa sobre ella"
          class="error-imagen" />
      </div>
    </div>
    <div class="mt-2">
      <p class="text-center">
        ¡Lo siento! No existen datos para ver aquí
      </p>
    </div>
  </ng-template>
</div>