import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import Chart from 'chart.js/auto';
import { Router } from '@angular/router';
import { RestService } from '../../services/rest/rest.service';
import { Store } from '@ngxs/store';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';

@Component({
  selector: 'app-graficas-general',
  templateUrl: './graficas-general.component.html',
  styleUrls: ['./graficas-general.component.css'],
})
export class GraficasGeneralComponent implements OnInit {
  /** ID horeca */
  public id_horeca = this.authService.user_horeca!._id;
  /** Lista de meses para apoyar el formateo de la fecha */
  public filtro_24_meses: any = [];
  private meses: string[] = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  private meses_largo: string[] = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  /** Colores para las gráficas */
  public colors = [
    'rgba(115, 113, 156, 1)',
    'rgba(199, 195, 239, 1)',
    'rgba(247, 234, 194, 1)',
    'rgba(250, 211, 157, 1)',
    'rgba(179, 179, 210, 1)',
    'rgba(142, 111, 247, 1)',
  ];
  /** Modal de carga para darle feedback al usuario */
  public modalCarga?: NgbModalRef;
  /** Evita que al hacer click por fuera se cierre el modal */
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };
  /** Biding HTML-Canvas y TS */
  @ViewChild('bar_establecimientos_negocio', { static: true })
  private bar_establecimientos_negocio!: ElementRef;
  @ViewChild('bar_establecimientos_ciudad', { static: true })
  private bar_establecimientos_ciudad!: ElementRef;
  @ViewChild('bar_distribuidores_negocio', { static: true })
  private bar_distribuidores_negocio!: ElementRef;
  /** Guarda datos de la talba y configuraciones */
  private data_bar_establecimientos_negocio: any;
  private data_bar_establecimientos_ciudad: any;
  private data_bar_distribuidores_negocio: any;
  private bar_chart_establecimientos_negocio: any;
  private bar_chart_establecimientos_ciudad: any;
  private bar_chart_distribuidores_negocio: any;
  /** Total tabla */
  public total_establecimientos_negocio: any;
  public total_establecimientos_ciudad: any;
  public total_distribuidores_negocio: any;

  constructor(
    private restService: RestService,
    private router: Router,
    private ngxsStore: Store,
    private authService: AuthService,
    private modalService: NgbModal
  ) {
    this.data_bar_establecimientos_negocio = {
      labels: [],
      datasets: [],
    };
    this.data_bar_establecimientos_ciudad = {
      labels: [],
      datasets: [],
    };
    this.data_bar_distribuidores_negocio = {
      labels: [],
      datasets: [],
    };
  }

  async ngOnInit() {
    this.id_horeca = await this.ngxsStore.snapshot().auth.user_horeca._id;
    /** Modal generíco cargando... */
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    /** Carga datos a tabla */
    //await this.cargarInformacionEstablecimientosNegocio();
    //await this.cargarInformacionEstablecimientosCiudad();
    //await this.cargarInformacionDistribuidoresNegocio();
    /** Cerrar Modal generíco cargando... */
    //this.modalCarga?.close();
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Estamos haciendo cambios, pronto las gráficas estarán disponibles';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      // this.router.navigate(['/inicio-organizacion']);
    };
  }

  /**
   * Inicializa los datos de las gráficas y llama a los métodos adecuados para mostrar cada una
   */
  /**************************** Establecimientos por tipo de negocio ***************************/
  public async cargarInformacionEstablecimientosNegocio() {
    let total_establecimientos_negocio = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT('informes/horeca/bar_chart/establecimientos_negocio/')
      .toPromise()
      .then((tipos_negocio: any) => {
        tipos_negocio = tipos_negocio.data;
        tipos_negocio.forEach((element: any) => {
          this.data_bar_establecimientos_negocio.labels.push(element._id || 'N/R');
          this.data_bar_establecimientos_negocio.datasets.push(element.total || 0);
          total_establecimientos_negocio = total_establecimientos_negocio + element.total;
        });
        this.total_establecimientos_negocio = total_establecimientos_negocio;
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
    this.bar_chart_establecimientos_negocio = new Chart(this.bar_establecimientos_negocio.nativeElement, {
      type: 'bar',
      data: {
        labels: this.data_bar_establecimientos_negocio.labels,
        datasets: [
          {
            label: 'Establecimientos por tipo de negocio',
            backgroundColor: this.colors[0],
            data: this.data_bar_establecimientos_negocio.datasets,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Total de establecimientos asociados a Feat',
            },
          },
          x: {
            title: {
              display: true,
              text: 'Tipo de negocios registrados en Feat',
            },
          },
        },
      },
    });
  }

  /******************************** Establecimientos por Ciudad *******************************/
  public async cargarInformacionEstablecimientosCiudad() {
    let total_establecimientos_ciudad = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT('informes/horeca/bar_chart/establecimientos_ciudad/')
      .toPromise()
      .then((ciudades: any) => {
        ciudades = ciudades.data;
        ciudades.forEach((element: any) => {
          this.data_bar_establecimientos_ciudad.labels.push(element._id || 'N/R');
          this.data_bar_establecimientos_ciudad.datasets.push(element.total || 0);
          total_establecimientos_ciudad = total_establecimientos_ciudad + element.total;
        });
        this.total_establecimientos_ciudad = total_establecimientos_ciudad;
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
    this.bar_chart_establecimientos_ciudad = new Chart(this.bar_establecimientos_ciudad.nativeElement, {
      type: 'bar',
      data: {
        labels: this.data_bar_establecimientos_ciudad.labels,
        datasets: [
          {
            label: 'Establecimientos por ciudad',
            backgroundColor: this.colors[0],
            data: this.data_bar_establecimientos_ciudad.datasets,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Total de establecimientos registrados en la ciudad',
            },
            beginAtZero: true,
            ticks: {
              stepSize: 1,
            },
          },
          x: {
            title: {
              display: true,
              text: 'Ciudades registrados en Feat',
            },
          },
        },
      },
    });
  }

  /******************************** Distribuidores por tipo de negocio *******************************/
  public async cargarInformacionDistribuidoresNegocio() {
    let total_distribuidores_negocio = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT('informes/horeca/bar_chart/distribuidores_negocio/')
      .toPromise()
      .then((ventas_mes: any) => {
        ventas_mes = ventas_mes.data;
        ventas_mes.forEach((element: any) => {
          this.data_bar_distribuidores_negocio.labels.push(element._id || 'N/R');
          this.data_bar_distribuidores_negocio.datasets.push(element.total || 0);
          total_distribuidores_negocio = total_distribuidores_negocio + element.total;
        });
        this.total_distribuidores_negocio = total_distribuidores_negocio;
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
    this.bar_chart_distribuidores_negocio = new Chart(this.bar_distribuidores_negocio.nativeElement, {
      type: 'bar',
      data: {
        labels: this.data_bar_distribuidores_negocio.labels,
        datasets: [
          {
            label: 'Distribuidores por tipo de negocio',
            backgroundColor: this.colors[0],
            data: this.data_bar_distribuidores_negocio.datasets,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Cantidad de distribuidores',
            },
          },
          x: {
            title: {
              display: true,
              text: 'Tipos de negocio',
            },
          },
        },
      },
    });
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio']);
    };
  }
}
