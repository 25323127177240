<div class="container_productos">
  <div class="container-top">
    <!-- Top 10 productos -->
    <div class="topVentas">
      <div>
        <span>Top 10 más vendidos</span>
      </div>
      <div class="productos-container">
        <div class="productos" *ngFor="let producto of top_productos">
          <img src="{{ producto.foto_producto }}" alt="imagen producto" />
          <p class="descripcion">{{ producto.nombre_producto }}</p>
        </div>
      </div>
    </div>
    <!-- Filtro de categoria productos -->
    <div class="topVentas">
      <div>
        <span>Categorías</span>
      </div>
      <div class="productos-container categorias">
        <div class="productos pointer"
          (click)="categorias_productos_estados_todos = !categorias_productos_estados_todos"
          (click)="handleClickCategoria(true)" [style]="categorias_productos_estados_todos ? 'color: #8e6ff7' : ''">
          Todos
        </div>
        <div class="productos pointer" *ngFor="let categoria of categorias_productos; index as i;">
          <span class="d-flex flex-row align-items-center"
            (click)="categorias_productos_estados[i] = !categorias_productos_estados[i]"
            (click)="handleClickCategoria(false)" [style]="categorias_productos_estados[i] ? 'color: #8e6ff7' : ''">
            <img src="{{ categoria.logoOn || logo_off }} " alt="cat productos" />
            <p class="descripcion">{{ categoria.nombre }}</p>
          </span>
        </div>
      </div>
    </div>
  </div>
  <!-- Indicadores -->
  <div class="contenido-general">
    <div class="indicadores-container">
      <div>
        <div>
          <span class="contador">
            <img src="../../../../assets/iconos-org/box.png" alt="" />
            {{ total_categorias }}
          </span>
        </div>
        <span>Número de categorías</span>
      </div>
      <div>
        <div>
          <span class="contador">
            <img src="../../../../assets/iconos-org/list.png" alt="" />
            {{ total_productos }}
          </span>
        </div>
        <span>Productos en el portafolio</span>
      </div>
      <div>
        <div>
          <span class="contador">
            <img src="../../../../assets/iconos-org/percentage.png" alt="" />
            {{ total_productos_con_puntosFt }}
          </span>
        </div>
        <span>Productos con puntos</span>
      </div>
    </div>
    <!-- Barra de busqueda -->
    <div class="buscador">
      <span>Mi catálogo</span>
      <div>
        <div class="d-flex flex-row autocompletado-busqueda px-0 h-100 w-100">
          <button class="col-2 col-lg-1 col-sm-0 p-0 boton-buscar d-flex justify-content-center">
            <fa-icon class="fa-icon-search m-0" [icon]="faSearch" size="lg" [styles]="{ color: '#41403E' }">
            </fa-icon>
          </button>
          <form class="example-form col-12 col-sm-10 col-md-11 pr-3">
            <mat-form-field class="example-full-width">
              <input
                      #escogido
                      type="text"
                      placeholder="Buscar por nombre del producto / Cod Org."
                      aria-label="Buscar distribuidor"
                      [(ngModel)]="stBusq"
                      [ngModelOptions]="{standalone: true}"
                      class="w-100 h-100 input-busqueda pr-0" />
              <!--<input #escogido type="text" placeholder="Buscar por nombre del producto / Cod Org." aria-label="Buscar distribuidor"
                matInput [formControl]="mySearch" [matAutocomplete]="auto" class="w-100 h-100 input-busqueda pr-0" />
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" [displayWith]="itemDisplayFn"
                class="w-100" (optionSelected)="verDetalleProducto($event, true)">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{ option?.nombre }}
                </mat-option>
              </mat-autocomplete>-->
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
    <!-- Filtro de linea de productos -->
    <div class="lineas">
      <span>Líneas de producto</span>
      <div class="categorias-lineas">
        <span (click)="lineas_productos_estado_todos = !lineas_productos_estado_todos"
          (click)="handleClickLineaProducto(true)"
          [ngClass]="{'categorias-lineas-selected': lineas_productos_estado_todos === true}">
          Todos
        </span>
        <span *ngFor="let linea of lineas_productos; index as i;"
          (click)="lineas_productos_estado[i] = !lineas_productos_estado[i]" (click)="handleClickLineaProducto(false)"
          [ngClass]="{'categorias-lineas-selected': lineas_productos_estado[i] === true}">
          {{ linea.nombre }}
        </span>
      </div>
    </div>
    <!-- Portafolio -->
    <div class="card_prod">
      <div class="card-body card_body_prod col-6 cursorPointer" *ngFor="let producto of productos_filtrados"
        (click)="verDetalleProducto(producto.id, false)">
        <!--Header código-->
        <div class="row header_codigo">
          <div class="col-12 text-center d-flex flex-column">
            <p>
              Cod. Organización
            </p>
            <p class="saltoLinea">
              {{ producto.codigo_organizacion_producto ? producto.codigo_organizacion_producto :'Sin asignar' }}
            </p>
          </div>
        </div>
        <!--Estado de productos y puntos-->
        <div class="row row_data_prod">
          <div class="col-6 text-left">
            <p>{{ producto?.precios[0]?.estado }}</p>
          </div>
          <div class="col-6 text-right" *ngIf="producto.mostrarPF">
            <p>{{ producto?.precios[0]?.puntos_ft_unidad }} puntos ft.</p>
          </div>
        </div>
        <!--Foto producto-->
        <div class="row row_data_prod">
          <div class="col-12 text-center">
            <img src="{{ producto?.fotos[0] }}" class="img-prod" alt="" />
          </div>
        </div>
        <!--Nombre producto-->
        <div class="row row_data_prod">
          <div class="col-12 text-left">
            <p class="saltoLinea">{{ producto.nombre }}</p>
          </div>
        </div>
        <!--Precio por unidad-->
        <div class="row row_data_prod">
          <div class="col-6 text-left">
            <p class="precio">Precio und.</p>
          </div>
          <div class="col-6 text-right">
            <h5 class="precio">{{ producto.precios[0].precio_unidad | currency: '$':'symbol':'1.0-0' }}</h5>
          </div>
        </div>
        <!--Precio por caja-->
        <div class="row row_data_prod">
          <div class="col-6 text-left">
            <p class="precio">Caja x {{ producto?.precios[0].und_x_caja || 1 }}</p>
          </div>
          <div class="col-6 text-right">
            <p class="precio">{{ producto?.precios[0].precio_caja | currency: '$':'symbol':'1.0-0' }}</p>
          </div>
        </div>
        <!--Precio por gramo-->
        <div class="row row_data_prod">
          <div class="col-6 text-left">
            <p class="precio">Precio x {{ producto?.precios[0].unidad_medida }}</p>
          </div>
          <div class="col-6 text-right">
            <p class="precio">
              {{ producto?.precios[0].precio_unidad/producto?.precios[0].cantidad_medida || 0 | currency:
              '$':'symbol':'1.2-2' }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>