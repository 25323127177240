<div class="content">
  <div class="container">
    <div class="header-title">
      <span>Aprobar cliente</span>
      <div>
        <div>
          <fa-icon
            [icon]="faTimes"
            class="cursorPointer"
            [styles]="{ color: '#41403E' }"
            id="fa-icon"
            (click)="close()"></fa-icon>
        </div>
      </div>
    </div>
    <div class="ml-3 mt-3">
      <form (ngSubmit)="aprobarCliente()">
        <app-info-adicional-cliente
          [distribuidorVinculado]="dataDistribuidor"
          (onVendedoresChange)="onVendedoresChange($event)"
          (onInfoAdicionalChange)="onInfoAdicionalChange($event)"></app-info-adicional-cliente>
        <div class="d-flex justify-content-end mt-5">
          <button type="submit">Aprobar cliente</button>
        </div>
      </form>
    </div>
  </div>
</div>
