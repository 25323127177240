import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PuntoEntrega } from 'src/app/models/punto_entrega.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { SimpleComponent } from '../simple/simple.component';
import { MultipasoFormPuntoVentaComponent } from 'src/app/modal/multipaso-form-punto-venta/multipaso-form-punto-venta.component';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';
import { MapService } from '../../services/map/map.service';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-seleccionar-punto-entrega',
  templateUrl: './seleccionar-punto-entrega.component.html',
  styleUrls: ['./seleccionar-punto-entrega.component.css'],
})
export class SeleccionarPuntoEntregaComponent implements OnInit, DoCheck {
  /** Referencias a íconos FontAwesome para la UI */
  faTimes = faTimes;
  faPlus = faPlus;

  /** Lista de puntos de entrega */
  public puntos_entrega: PuntoEntrega[] = [];
  public punto_seleccionado?: PuntoEntrega;

  /**Muestra la opción de crear punto */
  public mostrar_crear_punto = false;

  /** Modal de carga para darle feedback al usuario */
  public modalCarga?: NgbModalRef;

  /**Coordenadas Cobertura del Dist */
  public distribuidor: any;

  /**Guarda el tipo de usuaro logueado y sus puntos */
  puntos_trabajador_planeador: any[] = [];
  tipo_trabajador_logueago = '';

  @Input() distribuidor_id: any = null;

  @Input() callback = () => {
    console.log('Close');
  };

  constructor(
    private rest: RestService,
    private auth: AuthService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private distribuidorService: DistribuidorService,
    private map: MapService,
    private router: Router,
    private mapsApiLoader: MapsAPILoader
  ) {}

  ngDoCheck(): void {
    this.mostrarCrearPunto();
  }

  /**
   * Trae los puntos de entrega activos que están asociados al Usuario Horeca
   * Si no logra recuperarlos, lanza un mensaje de error y devuelve al usuario
   * al home
   */
  async ngOnInit() {
    await this.getPuntosAsociadosATrabajadorLogueado();
    this.punto_seleccionado = this.auth.punto_seleccionado;
    this.fetchPuntosEntrega();
    if (this.distribuidor_id != null) {
      this.fetchDistribuidor();
    }
  }

  /**
   * Por temas de permiso de acceso, los usuarios tipo Planeador tendran restringido el acceso
   * a información que no haha parte de su cobertura, por ello se debe traer la información
   * del trabajador loguqeado para verificar que tipo de usuario es, si bien esta se encuentra
   * en el local storage, desde allí puede ser manipulable y el acceso violado
   */
  getPuntosAsociadosATrabajadorLogueado() {
    this.rest
      .getJWT('trabajador/' + this.auth.user?._id)
      .toPromise()
      .then((trabajador_logueado: any) => {
        this.puntos_trabajador_planeador = trabajador_logueado.puntos_entrega;
        this.tipo_trabajador_logueago = trabajador_logueado.tipo_trabajador;
      });
  }

  /**
   * Recupera los datos del distribuidor
   */
  fetchDistribuidor() {
    this.distribuidorService
      .getDistribuidorData(this.distribuidor_id)
      .subscribe((resp: any) => (this.distribuidor = resp));
  }

  /**
   * Recupera los puntos de entrega del distribuidor seleccionado
   */
  fetchPuntosEntrega() {
    this.puntos_entrega = [];
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    this.rest
      .getJWT(`punto_entrega_establecimiento/${this.auth.user_horeca?._id}`)
      .toPromise()
      .then((resp: any) => {
        /**
         * Puntos de entrega activos para el establecimiento
         */
        this.puntos_entrega = resp.filter((obj: any) => obj.estado == 'Activo');
        this.puntos_entrega = this.puntos_entrega.sort((a: any, b: any) => b.nombre - a.nombre);
        /**
         * Cuando el usuario logueado es un planeador, solo se mostrarán los puntos asociados a este
         */
        if (this.tipo_trabajador_logueago === 'PLANEADOR PEDIDOS') {
          this.puntos_entrega = this.puntos_entrega.filter((el: any) => {
            return this.puntos_trabajador_planeador.some((f: any) => {
              return f._id == el._id;
            });
          });
        }
        /**
         *************** Se abre el modal desde el detalle de un distribuidor ***************
         * Cuando se va a cambiar el punto de entrega en el detalle de un distribuidor ******
         * pueden ocurrir dos escenarios, (1) cuando se trata a un distribuidor activo para *
         * el punto de entrega, o (2) cuando es un distribuidor no activo o sin vinculación *
         ***********************************************************************************/
        if (this.distribuidor_id != null) {
          /**
           * Se carga relación de vinculación distribuidor con los puntos de entrega del Horeca
           */
          this.rest
            .getJWT(`getAllPuntosDistribuidores/${this.auth.user_horeca?._id}/${this.distribuidor_id}`)
            .toPromise()
            .then((data_puntos_entrega: any) => {
              /**
               * Puntos aprobado y vinculado al distribuidor
               */
              const puntos_activos_dist = data_puntos_entrega.resultDistribuidor.filter(
                (obj: any) => obj.estado == 'Aprobado'
              );
              /**
               * Todos los puntos que tengan algún tipo de vinculación con el distribuidor,
               * la vinculación puede ser aprobado, pendiente o rechazado
               */
              const puntos_vinculados_dist = data_puntos_entrega.resultDistribuidor;
              /*************************************** Escenario 1 **************************************
               *********************** Distribuidor activo en el Punto de Entrega ***********************
               * Solo mostrará los puntos de entrega donde ese distribuidor está actualmente activo *****
               * La idea es que el usuario puede hacer pedidos desde este modulo ************************
               * y si requiere moverse a otro punto a hacer otro pedido tenga la lista disponible *******
               * de donde puede ir a hacer un pedido y no le aparezcan puntos que no puede hacer pedidos*
               *****************************************************************************************/
              if (
                this.router.url == `/distribuidores/${this.distribuidor_id}` ||
                this.router.url == `/mis-distribuidores/${this.distribuidor_id}` ||
                this.router.url == `/solicitudes-distribuidor/${this.distribuidor_id}`
              ) {
                this.puntos_entrega = this.puntos_entrega.filter((el: any) => {
                  return puntos_activos_dist.some((f: any) => {
                    return f.punto_entrega == el._id;
                  });
                });
              } else if (this.router.url == `/distribuidores/novinculado/${this.distribuidor_id}`) {
                /*************************************** Escenario 2 **************************************
                 ********************** Distribuidor NO vinculado al Punto de Entrega *********************
                 * Solo mostrará los puntos de entrega donde ese distribuidor NO está vinculado ***********
                 * La idea es que el usuario puede vincular este distribuidor desde cualquier punto de ****
                 * entrega, por ello podrá navegar por esta lista y ver solo los puntos donde no lo está **
                 ******************************************************************************************/
                const puntos_no_vinculados = this.puntos_entrega.filter((el: any) => {
                  return !puntos_vinculados_dist.some((f: any) => {
                    return f.punto_entrega == el._id;
                  });
                });
                /**
                 * Si bien no están vinculados se verifica que sean puntos dentro de la zona de cobertura
                 */
                this.puntos_entrega = [];
                this.filtrarPorCobertura(puntos_no_vinculados);
              }
            });
        }
      })
      .catch(() => {
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigate([this.router.url]);
        };
      });
  }

  /**
   * Recupera el estado de vinculación del distribuidor, si no esta aprobado sale de la pagina
   * y redirecciona al componente de no aprobados o vinculados
   */
  getPuntosNoVinculados(puntos_no_activos: any) {
    puntos_no_activos.forEach((element: any) => {
      this.rest
        .getJWT(`distribuidores_vinculados_dist_punto/${this.distribuidor_id}/${element._id}`)
        .toPromise()
        .then((resp_3: any) => {
          if (resp_3.data?.length > 0) {
            if ('estado' in resp_3.data[0]) {
              const vinculacion = resp_3.data[0].estado;
            }
          }
        });
    });
  }

  /**
   * Filtra los puntos de entrega segun la cobertura del distribuidor
   * @param puntos_no_aprobados_dist Array de objetos con todos los puntos de entrega
   * @returns Array de objetos con los puntos no vinculados que estan dentro de la zona de cobertura del distribuidor
   */
  async filtrarPorCobertura(puntos_no_aprobados_dist: any) {
    let puntosIds = [];
    puntosIds = puntos_no_aprobados_dist.map((puntoDist: any) => puntoDist._id);
    const bodyPuntos = { puntosIds };
    this.rest
      .postJWT(`listado_coordenadas_distribuidor/${this.distribuidor_id}`, bodyPuntos)
      .toPromise()
      .then((puntos: any) => {
        this.puntos_entrega = puntos;
      });
  }

  /**
   * Cierra el modal
   */
  close() {
    if (this.punto_seleccionado != undefined || this.auth.punto_seleccionado != undefined) {
      this.activeModal.close();
    } else {
      const ngbModalOptions: NgbModalOptions = {
        //Evita que al hacer click por fuera se cierre el modal
        backdrop: 'static',
        keyboard: false,
      };
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Debes escoger un punto de venta para continuar.';
      modalRef.componentInstance.btn_msg = 'Volver';
    }
  }

  /**
   * Cierra el modal y asigna el punto de entrega seleccionado
   */
  done() {
    if (this.punto_seleccionado != undefined) {
      console.log(this.punto_seleccionado);
      this.auth.punto_seleccionado = this.punto_seleccionado;
      this.callback();
      this.close();
    } else {
      const ngbModalOptions: NgbModalOptions = {
        //Evita que al hacer click por fuera se cierre el modal
        backdrop: 'static',
        keyboard: false,
      };
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Debes escoger un punto de venta para continuar.';
      modalRef.componentInstance.btn_msg = 'Volver';
    }
  }

  /**
   * Muestra o no la opción de crear un punto de entrega
   * solo si se encuentra en el Home de Feat
   */
  mostrarCrearPunto() {
    if (this.router.url == '/inicio' || this.router.url == '/distribuidores') {
      this.mostrar_crear_punto = true;
    } else {
      this.mostrar_crear_punto = false;
    }
  }

  /**
   * Lanza el modal de creación de punto de entrega
   */
  crearPuntoDeEntrega() {
    const ngbModalOptions3: NgbModalOptions = {
      centered: true,
      size: 'lg',
    };
    const modalRef = this.modalService.open(MultipasoFormPuntoVentaComponent, ngbModalOptions3);
  }
}
