import { Component, DoCheck, Input } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-editar-inventario-producto',
  templateUrl: './editar-inventario-producto.component.html',
  styleUrls: ['./editar-inventario-producto.component.css'],
})
export class EditarInventarioProductoComponent implements DoCheck {
  // Referencias a iconos FontAwesome para la UI
  faTimes = faTimes;
  //Imagen producto placeholder
  public product_placeholder = '../../assets/img/product-placeholder.png';
  // Guarda el inventario del producto
  cajas = 0;
  unidades_pendientes_caja = 0;
  // Datos del producto e inventario
  @Input() unidades = 0;
  @Input() precio_caja = 0;
  @Input() precio_unidad = 0;
  @Input() und_x_caja = 0;
  @Input() foto = '';
  @Input() producto = '';
  @Input() descripcion = '';
  // Función a llamar una vez se actualice el inventario
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @Input() callback = (pCajas: number, pUnidades: number, precio_caja: number, precio_unidad: number) => {};

  constructor(public activeModal: NgbActiveModal) {}

  ngDoCheck() {
    this.unidades = Math.round(this.unidades);
    if (this.unidades < 0) {
      this.unidades = 0;
    }
    if (this.und_x_caja > 0) {
      /** Verifica cantidad de cajas agregadas */
      this.cajas = Math.round((this.unidades / this.und_x_caja) * 100) / 100;
      /** Verifica cuantas unidades hacen falta para completar otra caja */
      this.unidades_pendientes_caja = this.und_x_caja - (this.unidades % this.und_x_caja);
    } else {
      this.cajas = 0;
    }
    if (this.precio_unidad > 0) {
      this.precio_caja = this.precio_unidad * this.und_x_caja;
    } else {
      this.precio_caja = 0;
    }
  }

  /**
   * Llama al callback para actualizar las undiades y las
   * cajas del producto y cierra el modal
   */
  actualizar() {
    this.callback(this.cajas, this.unidades, this.precio_caja, this.precio_unidad);
    this.activeModal.close();
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateNumber(event: any) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || excludedKeys.includes(keyCode))) {
      event.preventDefault();
    }
  }
}
