import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { GraficasService } from 'src/app/services/graficas-tablas/graficas.service';
import { CurrencyPipe } from '@angular/common';
@Component({
  selector: 'app-grafica-ventas',
  templateUrl: './grafica-ventas.component.html',
  styleUrls: ['./grafica-ventas.component.css'],
})
export class GraficaVentasComponent implements OnInit {
  /** ID distribuidor */
  public id_distribuidor = this.authService.user_distribuidor!._id;
  /** Lista de meses para apoyar el formateo de la fecha */
  public anos_filtro: any = [];
  public filtro_24_meses: any = [];
  private meses: string[] = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  /** Modal de carga para darle feedback al usuario */
  public modalCarga?: NgbModalRef;
  /** Evita que al hacer click por fuera se cierre el modal */
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };
  /** Biding HTML-Canvas y TS */
  @ViewChild('bar_ventas_mes', { static: true })
  private bar_ventas_mes!: ElementRef;
  @ViewChild('bar_usuarios_establecimientos', { static: true })
  private bar_usuarios_establecimientos!: ElementRef;
  @ViewChild('bar_ventas_tipo_negocio', { static: true })
  private bar_ventas_tipo_negocio!: ElementRef;
  @ViewChild('bar_ventas_organizacion', { static: true })
  private bar_ventas_organizacion!: ElementRef;
  @ViewChild('bar_ventas_categoria', { static: true })
  private bar_ventas_categoria!: ElementRef;
  @ViewChild('bar_ventas_marca', { static: true })
  private bar_ventas_marca!: ElementRef;
  @ViewChild('bar_ventas_comercial', { static: true })
  private bar_ventas_comercial!: ElementRef;
  @ViewChild('bar_ventas_ciudad', { static: true })
  private bar_ventas_ciudad!: ElementRef;
  @ViewChild('bar_ventas_pedidos', { static: true })
  private bar_ventas_pedidos!: ElementRef;
  @ViewChild('bar_ventas_pedidos2', { static: true })
  private bar_ventas_pedidos2!: ElementRef;
  @ViewChild('bar_ventas_productos', { static: true })
  private bar_ventas_productos!: ElementRef;
  @ViewChild('bar_ventas_sillas', { static: true })
  private bar_ventas_sillas!: ElementRef;
  @ViewChild('bar_ventas_domicilios', { static: true })
  private bar_ventas_domicilios!: ElementRef;
  /** Guarda datos de la talba y configuraciones */
  public data_bar_ventas_mes: any;
  public data_bar_usuarios_establecimientos: any;
  public data_bar_ventas_tipo_negocio: any;
  public data_bar_ventas_organizacion: any;
  public data_bar_ventas_categoria: any;
  public data_bar_ventas_marca: any;
  public data_bar_ventas_comercial: any;
  public data_bar_ventas_ciudad: any;
  public data_bar_ventas_pedidos: any;
  public data_bar_ventas_pedidos2: any;
  public data_bar_ventas_productos: any;
  public data_bar_ventas_sillas: any;
  public data_bar_ventas_domicilios: any;
  /** Carga la grafica de chart JS */
  public bar_chart_ventas_mes: any;
  public bar_chart_usuarios_establecimientos: any;
  public bar_chart_ventas_tipo_negocio: any;
  public bar_chart_ventas_organizacion: any;
  public bar_chart_ventas_categoria: any;
  public bar_chart_ventas_marca: any;
  public bar_chart_ventas_comercial: any;
  public bar_chart_ventas_ciudad: any;
  public bar_chart_ventas_pedidos: any;
  public bar_chart_ventas_pedidos2: any;
  public bar_chart_ventas_productos: any;
  public bar_chart_ventas_sillas: any;
  public bar_chart_ventas_domicilios: any;
  /** Total tabla */
  public total_ventas_mes: any;
  public total_usuarios_establecimientos: any;
  public total_ventas_tipo_negocio: any;
  public total_ventas_organizacion: any;
  public total_ventas_categoria: any;
  public total_ventas_marca: any;
  public total_ventas_comercial: any;
  public total_ventas_ciudad: any;
  public total_ventas_pedidos: any;
  public total_ventas_productos: any;
  public total_ventas_sillas: any;
  public total_ventas_domicilios: any;
  /** Filtros graficas */
  public filtro_ventas_mes_ano: any;
  public filtro_ventas_tipo_negocio: any;
  public filtro_ventas_organizacion: any;
  public filtro_ventas_categoria: any;
  public filtro_ventas_marca: any;
  public filtro_ventas_comercial: any;
  public filtro_ventas_ciudad: any;
  public filtro_ventas_pedidos: any;
  public filtro_ventas_pedidos_establecimientos: any;
  public filtro_ventas_productos: any;
  public filtro_ventas_sillas: any;
  public filtro_ventas_domicilios: any;

  constructor(
    private restService: RestService,
    private router: Router,
    private ngxsStore: Store,
    private authService: AuthService,
    private modalService: NgbModal,
    private currency: CurrencyPipe,
    private graficasService: GraficasService
  ) {
    this.data_bar_ventas_mes = {
      labels: [],
      datasets: [],
    };
    this.data_bar_usuarios_establecimientos = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_tipo_negocio = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_organizacion = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_categoria = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_marca = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_comercial = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_ciudad = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_pedidos2 = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_pedidos = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_productos = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_sillas = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_domicilios = {
      labels: [],
      datasets: [],
    };
  }
  async ngOnInit() {
    this.id_distribuidor = await this.ngxsStore.snapshot().auth.user_distribuidor._id;
    /** Modal generíco cargando... */
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    /** Arma array de filtros de meses y años */
    await this.getListaFiltroMeses();
    /** Carga datos de graficas */
    await this.getChartVentasMes();
    await this.getChartVentasTipoNegocio();
    await this.getChartVentasOrganizacion();
    await this.getChartVentasCategoria();
    await this.getChartVentasMarca();
    await this.getChartVentasEquipoComercial();
    await this.getChartVentasCiudad();
    await this.getChartVentasPedidosMes();
    await this.getChartVentasProductosMes();
    await this.getChartVentasSillas();
    await this.getChartVentasDomicilios();
    await this.getChartEstablecimientosAlcanzados();
    /** Cerrar Modal generíco cargando... */
    this.modalCarga?.close();
  }

  /**
   * Arma lista de los últimos 24 meses para el filtro de meses
   */
  getListaFiltroMeses() {
    const fecha_actual = new Date();
    let mes_actual = fecha_actual.getMonth();
    let ano_actual = fecha_actual.getFullYear();
    /** Filtro por años (últimos 10 años) */
    for (let index = 0; index < 10; index++) {
      this.anos_filtro.push(ano_actual - index);
    }
    this.filtro_ventas_mes_ano = this.anos_filtro[0];
    this.filtro_ventas_productos = this.anos_filtro[0];
    this.filtro_ventas_pedidos = this.anos_filtro[0];
    this.filtro_ventas_pedidos_establecimientos = this.anos_filtro[0];
    /** Filtro por meses y años */
    for (let index = 0; index < 23; index++) {
      if (mes_actual >= 0) {
        const data = {
          filtro: this.meses[mes_actual] + ', ' + ano_actual,
          filtro_inicio: this.meses[mes_actual - 2] + ', ' + ano_actual,
          crudo: Number(mes_actual + 1) + ',' + ano_actual + ',' + (mes_actual - 1) + ',' + ano_actual,
        };
        if (mes_actual <= 1) {
          data.filtro_inicio = this.meses[11 - (1 - mes_actual)] + ', ' + (ano_actual - 1);
          data.crudo = Number(mes_actual + 1) + ',' + ano_actual + ',' + (11 - mes_actual) + ',' + (ano_actual - 1);
        }
        this.filtro_24_meses.push(data);
        mes_actual = mes_actual - 1;
      } else {
        mes_actual = 11;
        ano_actual = ano_actual - 1;
      }
    }
    this.filtro_ventas_tipo_negocio = this.filtro_24_meses[0].crudo;
    this.filtro_ventas_organizacion = this.filtro_24_meses[0].crudo;
    this.filtro_ventas_categoria = this.filtro_24_meses[0].crudo;
    this.filtro_ventas_marca = this.filtro_24_meses[0].crudo;
    this.filtro_ventas_comercial = this.filtro_24_meses[0].crudo;
    this.filtro_ventas_ciudad = this.filtro_24_meses[0].crudo;
    this.filtro_ventas_sillas = this.filtro_24_meses[0].crudo;
    this.filtro_ventas_domicilios = this.filtro_24_meses[0].crudo;
  }

  /**
   * Inicializa los datos de las gráficas y lo carga en el DOM
   */
  /***************** Ventas por mes ****************/
  public async getChartVentasMes() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_mes) {
      this.bar_chart_ventas_mes.destroy();
      this.data_bar_ventas_mes.labels = [];
      this.data_bar_ventas_mes.datasets = [];
    }
    /** Se arma data para query */
    const first_day = this.filtro_ventas_mes_ano + '-01-01';
    const last_day = this.filtro_ventas_mes_ano + '-12-31';
    let total_ventas_mes = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/ventas/mes/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_ventas_mes.labels.push(
            this.meses[element._id.split('-')[1] - 1] + '-' + element._id.split('-')[0]
          );
          this.data_bar_ventas_mes.datasets.push(element.total);
          total_ventas_mes = total_ventas_mes + element.total;
        });
        this.total_ventas_mes = this.currency.transform(total_ventas_mes, '$ ', 'symbol', '1.0-0');
        /** Se crea la grafica */
        this.bar_chart_ventas_mes = this.graficasService.createBarChart(
          this.bar_ventas_mes,
          this.data_bar_ventas_mes,
          'Ventas por mes',
          'Ventas',
          'Meses'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /***************** Ventas por tipo de negocio ****************/
  public async getChartVentasTipoNegocio() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_tipo_negocio) {
      this.bar_chart_ventas_tipo_negocio.destroy();
      this.data_bar_ventas_tipo_negocio.labels = [];
      this.data_bar_ventas_tipo_negocio.datasets = [];
    }
    /** Se arma data para query */
    const first_day =
      this.filtro_ventas_tipo_negocio.split(',')[3] + '-' + this.filtro_ventas_tipo_negocio.split(',')[2] + '-01';
    const last_day =
      this.filtro_ventas_tipo_negocio.split(',')[1] + '-' + this.filtro_ventas_tipo_negocio.split(',')[0] + '-31';
    this.total_ventas_tipo_negocio = 0;
    let total_ventas_tipo_negocio = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/ventas/tipo_negocio/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_ventas_tipo_negocio.labels.push(element._id);
          this.data_bar_ventas_tipo_negocio.datasets.push(element.total);
          total_ventas_tipo_negocio = total_ventas_tipo_negocio + element.total;
        });
        this.total_ventas_tipo_negocio = this.currency.transform(total_ventas_tipo_negocio, '$ ', 'symbol', '1.0-0');
        /** Se crea la grafica */
        this.bar_chart_ventas_tipo_negocio = this.graficasService.createBarChart(
          this.bar_ventas_tipo_negocio,
          this.data_bar_ventas_tipo_negocio,
          'Ventas por tipo de negocio',
          'Ventas',
          'Tipos de negocio'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /***************** Ventas por organización ****************/
  public async getChartVentasOrganizacion() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_organizacion) {
      this.bar_chart_ventas_organizacion.destroy();
      this.data_bar_ventas_organizacion.labels = [];
      this.data_bar_ventas_organizacion.datasets = [];
    }
    /** Se arma data para query */
    const first_day =
      this.filtro_ventas_organizacion.split(',')[3] + '-' + this.filtro_ventas_organizacion.split(',')[2] + '-01';
    const last_day =
      this.filtro_ventas_organizacion.split(',')[1] + '-' + this.filtro_ventas_organizacion.split(',')[0] + '-31';
    this.total_ventas_organizacion = 0;
    let total_ventas_organizacion = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/ventas/organizacion/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_ventas_organizacion.labels.push(element._id);
          this.data_bar_ventas_organizacion.datasets.push(element.total);
          total_ventas_organizacion = total_ventas_organizacion + element.total;
        });
        this.total_ventas_organizacion = this.currency.transform(total_ventas_organizacion, '$ ', 'symbol', '1.0-0');
        /** Se crea la grafica */
        this.bar_chart_ventas_organizacion = this.graficasService.createBarChart(
          this.bar_ventas_organizacion,
          this.data_bar_ventas_organizacion,
          'Ventas por organización',
          'Ventas',
          'Organización'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /***************** Ventas por Categoría ****************/
  public async getChartVentasCategoria() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_categoria) {
      this.bar_chart_ventas_categoria.destroy();
      this.data_bar_ventas_categoria.labels = [];
      this.data_bar_ventas_categoria.datasets = [];
    }
    /** Se arma data para query */
    const first_day =
      this.filtro_ventas_categoria.split(',')[3] + '-' + this.filtro_ventas_categoria.split(',')[2] + '-01';
    const last_day =
      this.filtro_ventas_categoria.split(',')[1] + '-' + this.filtro_ventas_categoria.split(',')[0] + '-31';
    this.total_ventas_categoria = 0;
    let total_ventas_categoria = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/ventas/categoria/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_ventas_categoria.labels.push(element._id);
          this.data_bar_ventas_categoria.datasets.push(element.total);
          total_ventas_categoria = total_ventas_categoria + element.total;
        });
        this.total_ventas_categoria = this.currency.transform(total_ventas_categoria, '$ ', 'symbol', '1.0-0');
        /** Se crea la grafica */
        this.bar_chart_ventas_categoria = this.graficasService.createBarChart(
          this.bar_ventas_categoria,
          this.data_bar_ventas_categoria,
          'Ventas por categoría',
          'Ventas',
          'Categoría'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /***************** Ventas por Marca ****************/
  public async getChartVentasMarca() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_marca) {
      this.bar_chart_ventas_marca.destroy();
      this.data_bar_ventas_marca.labels = [];
      this.data_bar_ventas_marca.datasets = [];
    }
    /** Se arma data para query */
    const first_day = this.filtro_ventas_marca.split(',')[3] + '-' + this.filtro_ventas_marca.split(',')[2] + '-01';
    const last_day = this.filtro_ventas_marca.split(',')[1] + '-' + this.filtro_ventas_marca.split(',')[0] + '-31';
    this.total_ventas_marca = 0;
    let total_ventas_marca = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/ventas/marca/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_ventas_marca.labels.push(element._id);
          this.data_bar_ventas_marca.datasets.push(element.total);
          total_ventas_marca = total_ventas_marca + element.total;
        });
        this.total_ventas_marca = this.currency.transform(total_ventas_marca, '$ ', 'symbol', '1.0-0');
        /** Se crea la grafica */
        this.bar_chart_ventas_marca = this.graficasService.createBarChart(
          this.bar_ventas_marca,
          this.data_bar_ventas_marca,
          'Ventas por marca',
          'Ventas',
          'Marca'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /***************** Ventas por persona comercial ****************/
  public async getChartVentasEquipoComercial() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_comercial) {
      this.bar_chart_ventas_comercial.destroy();
      this.data_bar_ventas_comercial.labels = [];
      this.data_bar_ventas_comercial.datasets = [];
    }
    /** Se arma data para query */
    const first_day =
      this.filtro_ventas_comercial.split(',')[3] + '-' + this.filtro_ventas_comercial.split(',')[2] + '-01';
    const last_day =
      this.filtro_ventas_comercial.split(',')[1] + '-' + this.filtro_ventas_comercial.split(',')[0] + '-31';
    this.total_ventas_comercial = 0;
    let total_ventas_comercial = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/ventas/usuario/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_ventas_comercial.labels.push(element._id);
          this.data_bar_ventas_comercial.datasets.push(element.total);
          total_ventas_comercial = total_ventas_comercial + element.total;
        });
        this.total_ventas_comercial = this.currency.transform(total_ventas_comercial, '$ ', 'symbol', '1.0-0');
        /** Se crea la grafica */
        this.bar_chart_ventas_comercial = this.graficasService.createBarChart(
          this.bar_ventas_comercial,
          this.data_bar_ventas_comercial,
          'Ventas por persona comercial',
          'Ventas',
          'Equipo comercial'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /***************** Ventas por ciudad ****************/
  public async getChartVentasCiudad() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_ciudad) {
      this.bar_chart_ventas_ciudad.destroy();
      this.data_bar_ventas_ciudad.labels = [];
      this.data_bar_ventas_ciudad.datasets = [];
    }
    /** Se arma data para query */
    const first_day = this.filtro_ventas_ciudad.split(',')[3] + '-' + this.filtro_ventas_ciudad.split(',')[2] + '-01';
    const last_day = this.filtro_ventas_ciudad.split(',')[1] + '-' + this.filtro_ventas_ciudad.split(',')[0] + '-31';
    this.total_ventas_ciudad = 0;
    let total_ventas_ciudad = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/ventas/ciudad/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_ventas_ciudad.labels.push(element._id);
          this.data_bar_ventas_ciudad.datasets.push(element.total);
          total_ventas_ciudad = total_ventas_ciudad + element.total;
        });
        this.total_ventas_ciudad = this.currency.transform(total_ventas_ciudad, '$ ', 'symbol', '1.0-0');
        /** Se crea la grafica */
        this.bar_chart_ventas_ciudad = this.graficasService.createBarChart(
          this.bar_ventas_ciudad,
          this.data_bar_ventas_ciudad,
          'Ventas por ciudad',
          'Ventas',
          'Ciudades'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /***************** Pedidos por mes ****************/
  public async getChartVentasPedidosMes() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_pedidos) {
      this.bar_chart_ventas_pedidos.destroy();
      this.data_bar_ventas_pedidos.labels = [];
      this.data_bar_ventas_pedidos.datasets = [];
    }
    /** Se arma data para query */
    const first_day = this.filtro_ventas_pedidos + '-01-01';
    const last_day = this.filtro_ventas_pedidos + '-12-31';
    let total_ventas_pedidos = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/ventas/pedidos_X_mes/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_ventas_pedidos.labels.push(
            this.meses[element._id.split('-')[1] - 1] + '-' + element._id.split('-')[0]
          );
          this.data_bar_ventas_pedidos.datasets.push(element.total);
          total_ventas_pedidos = total_ventas_pedidos + element.total;
        });
        this.total_ventas_pedidos = total_ventas_pedidos;
        /** Se crea la grafica */
        this.bar_chart_ventas_pedidos = this.graficasService.createBarChart(
          this.bar_ventas_pedidos,
          this.data_bar_ventas_pedidos,
          'Pedidos por mes',
          'Número de pedidos',
          'Meses'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /***************** Pedidos por mes ****************/
  public async getChartEstablecimientosAlcanzados() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_pedidos2) {
      this.bar_chart_ventas_pedidos2.destroy();
      this.data_bar_ventas_pedidos2.labels = [];
      this.data_bar_ventas_pedidos2.datasets = [];
    }
    /** Se arma data para query */
    const first_day = this.filtro_ventas_pedidos_establecimientos + '-01-01';
    const last_day = this.filtro_ventas_pedidos_establecimientos + '-12-31';
    let total_ventas_pedidos = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(
        `informes/distribuidor/ventas/establecimientos_alcanzados_mes/${this.id_distribuidor}/${first_day}/${last_day}`
      )
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_ventas_pedidos2.labels.push(
            this.meses[element._id.split('-')[1] - 1] + '-' + element._id.split('-')[0]
          );
          this.data_bar_ventas_pedidos2.datasets.push(element.total);
          total_ventas_pedidos = total_ventas_pedidos + element.total;
        });
        this.total_ventas_pedidos = total_ventas_pedidos;
        /** Se crea la grafica */
        this.bar_chart_ventas_pedidos2 = this.graficasService.createBarChart(
          this.bar_ventas_pedidos2,
          this.data_bar_ventas_pedidos2,
          'Establecimientos por mes',
          'Número de estblecimientos',
          'Meses'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /***************** Productos por mes ****************/
  public async getChartVentasProductosMes() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_productos) {
      this.bar_chart_ventas_productos.destroy();
      this.data_bar_ventas_productos.labels = [];
      this.data_bar_ventas_productos.datasets = [];
    }
    /** Se arma data para query */
    const first_day = this.filtro_ventas_productos + '-01-01';
    const last_day = this.filtro_ventas_productos + '-12-31';
    let total_ventas_productos = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/ventas/productos_X_mes/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_ventas_productos.labels.push(
            this.meses[element._id.split('-')[1] - 1] + '-' + element._id.split('-')[0]
          );
          this.data_bar_ventas_productos.datasets.push(element.total);
          total_ventas_productos = total_ventas_productos + element.total;
        });
        this.total_ventas_productos = total_ventas_productos;
        /** Se crea la grafica */
        this.bar_chart_ventas_productos = this.graficasService.createBarChart(
          this.bar_ventas_productos,
          this.data_bar_ventas_productos,
          'Referencias por mes',
          'Número de referencias',
          'Meses'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /***************** Ventas por número de sillas ****************/
  public async getChartVentasSillas() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_sillas) {
      this.bar_chart_ventas_sillas.destroy();
      this.data_bar_ventas_sillas.labels = [];
      this.data_bar_ventas_sillas.datasets = [];
    }
    /** Se arma data para query */
    const first_day = this.filtro_ventas_sillas.split(',')[3] + '-' + this.filtro_ventas_sillas.split(',')[2] + '-01';
    const last_day = this.filtro_ventas_sillas.split(',')[1] + '-' + this.filtro_ventas_sillas.split(',')[0] + '-31';
    this.total_ventas_sillas = 0;
    let total_ventas_sillas = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/ventas/sillas/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data[0].cant_sillas.forEach((element: any) => {
          this.data_bar_ventas_sillas.labels.push(element.label);
          this.data_bar_ventas_sillas.datasets.push(element.count);
          total_ventas_sillas = total_ventas_sillas + element.count;
        });
        this.total_ventas_sillas = this.currency.transform(total_ventas_sillas, '$ ', 'symbol', '1.0-0');
        /** Se crea la grafica */
        this.bar_chart_ventas_sillas = this.graficasService.createBarChart(
          this.bar_ventas_sillas,
          this.data_bar_ventas_sillas,
          'Ventas por número de sillas',
          'Ventas',
          'Rangos de número de sillas'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /***************** Ventas por establecimientos con domicilios ****************/
  public async getChartVentasDomicilios() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_domicilios) {
      this.bar_chart_ventas_domicilios.destroy();
      this.data_bar_ventas_domicilios.labels = [];
      this.data_bar_ventas_domicilios.datasets = [];
    }
    /** Se arma data para query */
    const first_day =
      this.filtro_ventas_domicilios.split(',')[3] + '-' + this.filtro_ventas_domicilios.split(',')[2] + '-01';
    const last_day =
      this.filtro_ventas_domicilios.split(',')[1] + '-' + this.filtro_ventas_domicilios.split(',')[0] + '-31';
    this.total_ventas_domicilios = 0;
    let total_ventas_domicilios = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/ventas/domicilios/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((ventas_mes: any) => {
        ventas_mes = ventas_mes.data[0];
        this.data_bar_ventas_domicilios.labels.push('Con domicilio', 'Sin domicilio');
        if (ventas_mes.con_domicilio.length > 0) {
          this.data_bar_ventas_domicilios.datasets.push(ventas_mes.con_domicilio[0].total);
          total_ventas_domicilios = total_ventas_domicilios + ventas_mes.con_domicilio[0].total;
        } else {
          this.data_bar_ventas_domicilios.datasets.push(0);
        }
        if (ventas_mes.sin_Domicilio.length > 0) {
          this.data_bar_ventas_domicilios.datasets.push(ventas_mes.sin_Domicilio[0].total);
          total_ventas_domicilios = total_ventas_domicilios + ventas_mes.sin_Domicilio[0].total;
        } else {
          this.data_bar_ventas_domicilios.datasets.push(0);
        }
        this.total_ventas_domicilios = this.currency.transform(total_ventas_domicilios, '$ ', 'symbol', '1.0-0');
        /** Se crea la grafica */
        this.bar_chart_ventas_domicilios = this.graficasService.createBarChart(
          this.bar_ventas_domicilios,
          this.data_bar_ventas_domicilios,
          'Ventas por establecimientos con domicilios',
          'Ventas',
          'Rangos de número de sillas'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio-distribuidor']);
    };
  }
}
