import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faChevronLeft, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-recordar-clave',
  templateUrl: './recordar-clave.component.html',
  styleUrls: ['./recordar-clave.component.css'],
})
export class RecordarClaveComponent implements OnInit {
  constructor(
    private restService: RestService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) {}

  /** Referencias a íconos FontAwesome para el template */
  faChevronLeft = faChevronLeft;
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  /** Flag para mostrar u ocultar contraseña */
  fieldTextType = false;
  fieldTextTypeConf = false;

  /** Variables de control */
  clave = '';
  clave_conf = '';

  /** Flags de mensajes de error */
  error_form = false;
  error_clave = false;

  /**
   * Oculta o revela la contraseña al usuario
   */
  mostrarOcultarPassword() {
    this.fieldTextType = !this.fieldTextType;
  }

  /**
   * Oculta o revela la contraseña al usuario
   */
  mostrarOcultarPasswordConf() {
    this.fieldTextTypeConf = !this.fieldTextTypeConf;
  }

  ngOnInit(): void {}

  reiniciarClave() {
    this.error_form = false;
    this.error_clave = false;

    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
    };

    if (this.clave.trim() == '' || this.clave_conf.trim() == '') {
      this.error_form = true;
    } else if (this.clave != this.clave_conf) {
      this.error_clave = true;
    } else {
      const update_obj = {
        clave: this.clave,
      };
      this.restService
        .put('trabajador/act_clave/' + this.activatedRoute.snapshot.params.id, update_obj)
        .toPromise()
        .then(() => {
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          modalRef.componentInstance.msg = 'Se reestableció tu contraseña. Ya puedes entrar de nuevo a tu cuenta Feat.';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            this.router.navigate(['/login']);
          };
        });
    }
  }
}
