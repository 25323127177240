<app-header></app-header>
<div class="contenedor">
  <div class="row">
    <div class="col-1">
      <div class="btn-volver text-center" routerLink="/blog">
        <div class="pt-2">
          <fa-icon
            [icon]="faChevronLeft"
            [styles]="{ color: '#41403E' }"
            id="fa-icon"
            style="margin-top: 20px"></fa-icon>
        </div>
      </div>
    </div>
    <div class="col">
      <div>
        <h3 class="title-notice">
          {{ noticia?.titulo }}
        </h3>
      </div>
      <div class="d-flex justify-content-between">
        <div>
          <p class="autor">Autor: {{ noticia?.site }}</p>
        </div>
        <div>
          <p class="autor">{{ noticia?.createdAt | date: 'mediumDate':'UTC':'es-CO' }}</p>
        </div>
      </div>
      <div>
        <img [src]="noticia?.imagen || '../../../assets/img/blog/imagen-7.png'" alt="" class="img-notice" />
      </div>
      <div class="mt-4">
        <p>
          {{ noticia?.descripcion }}
        </p>
      </div>
    </div>
  </div>
</div>
