<div id="content">
  <!-------------------- Bar Chart -------------------->
  <!-- Ventas por mes -->
  <div class="card ventas-mes">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Ventas por mes</div>
        <div class="d-flex justify-content-between">
          <span>Total: {{total_ventas_mes}}</span>
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_mes_ano"
            (ngModelChange)="getChartVentasMes()">
            <option [value]="ano" *ngFor="let ano of anos_filtro">
              {{ano}}
            </option>
          </select>
        </div>
      </div>
      <canvas #bar_ventas_mes [ngClass]="{'inactive': data_bar_ventas_mes.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_ventas_mes.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <!-- Ventas por tipo de negocio -->
  <div class="card ventas-tipo">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Ventas por tipo de negocio</div>
        <div class="d-flex justify-content-between">
          <span>Total: {{total_ventas_tipo_negocio}}</span>
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_tipo_negocio"
            (ngModelChange)="getChartVentasTipoNegocio()">
            <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
              {{mes.filtro}}-{{mes.filtro_inicio}}
            </option>
          </select>
        </div>
      </div>
      <canvas #bar_ventas_tipo_negocio
        [ngClass]="{'inactive': data_bar_ventas_tipo_negocio.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_ventas_tipo_negocio.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <!-- Ventas por organización -->
  <div class="card ventas-organizacion">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Ventas por organización</div>
        <div class="d-flex justify-content-between">
          <span>Total: {{total_ventas_organizacion}}</span>
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_organizacion"
            (ngModelChange)="getChartVentasOrganizacion()">
            <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
              {{mes.filtro}}-{{mes.filtro_inicio}}
            </option>
          </select>
        </div>
      </div>
      <canvas #bar_ventas_organizacion
        [ngClass]="{'inactive': data_bar_ventas_organizacion.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_ventas_organizacion.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <!-- Ventas por categoría -->
  <div class="card ventas-categoria">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Ventas por categoría</div>
        <div class="d-flex justify-content-between">
          <span>Total: {{total_ventas_categoria}}</span>
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_categoria"
            (ngModelChange)="getChartVentasCategoria()">
            <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
              {{mes.filtro}}-{{mes.filtro_inicio}}
            </option>
          </select>
        </div>
      </div>
      <canvas #bar_ventas_categoria [ngClass]="{'inactive': data_bar_ventas_categoria.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_ventas_categoria.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <!-- Ventas por marca -->
  <div class="card ventas-marca">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Ventas por marca</div>
        <div class="d-flex justify-content-between">
          <span>Total: {{total_ventas_marca}}</span>
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_marca"
            (ngModelChange)="getChartVentasMarca()">
            <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
              {{mes.filtro}}-{{mes.filtro_inicio}}
            </option>
          </select>
        </div>
      </div>
      <canvas #bar_ventas_marca [ngClass]="{'inactive': data_bar_ventas_marca.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_ventas_marca.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <!-- Ventas por persona comercial -->
  <div class="card ventas-equipo">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Ventas por persona comercial</div>
        <div class="d-flex justify-content-between">
          <span>Total: {{total_ventas_comercial}}</span>
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_comercial"
            (ngModelChange)="getChartVentasEquipoComercial()">
            <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
              {{mes.filtro}}-{{mes.filtro_inicio}}
            </option>
          </select>
        </div>
      </div>
      <canvas #bar_ventas_comercial [ngClass]="{'inactive': data_bar_ventas_comercial.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_ventas_comercial.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <!-- Ventas por ciudad -->
  <div class="card ventas-ciudad">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Ventas por ciudad</div>
        <div class="d-flex justify-content-between">
          <span>Total: {{total_ventas_ciudad}}</span>
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_ciudad"
            (ngModelChange)="getChartVentasCiudad()">
            <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
              {{mes.filtro}}-{{mes.filtro_inicio}}
            </option>
          </select>
        </div>
      </div>
      <canvas #bar_ventas_ciudad [ngClass]="{'inactive': data_bar_ventas_ciudad.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_ventas_ciudad.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <!-- Pedidos por mes -->
  <div class="card ventas-pedidos">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Pedidos por mes</div>
        <div class="d-flex justify-content-between">
          <span>Total: {{total_ventas_pedidos}}</span>
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_pedidos"
            (ngModelChange)="getChartVentasPedidosMes()">
            <option [value]="ano" *ngFor="let ano of anos_filtro">
              {{ano}}
            </option>
          </select>
        </div>
      </div>
      <canvas #bar_ventas_pedidos [ngClass]="{'inactive': data_bar_ventas_pedidos.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_ventas_pedidos.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <!-- Referencias por mes -->
  <div class="card ventas-productos">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Referencias por mes</div>
        <div class="d-flex justify-content-between">
          <span>Total: {{total_ventas_productos}}</span>
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_productos"
            (ngModelChange)="getChartVentasProductosMes()">
            <option [value]="ano" *ngFor="let ano of anos_filtro">
              {{ano}}
            </option>
          </select>
        </div>
      </div>
      <canvas #bar_ventas_productos [ngClass]="{'inactive': data_bar_ventas_productos.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_ventas_productos.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <!-- Ventas por número de sillas -->
  <div class="card ventas-sillas">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Ventas por número de sillas</div>
        <div class="d-flex justify-content-between">
          <span>Total: {{total_ventas_sillas}}</span>
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_sillas"
            (ngModelChange)="getChartVentasSillas()">
            <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
              {{mes.filtro}}-{{mes.filtro_inicio}}
            </option>
          </select>
        </div>
      </div>
      <canvas #bar_ventas_sillas [ngClass]="{'inactive': data_bar_ventas_sillas.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_ventas_sillas.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <!-- Ventas por establecimientos con domicilios -->
  <div class="card ventas-domicilios">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Ventas por establecimientos con domicilios</div>
        <div class="d-flex justify-content-between">
          <span>Total: {{total_ventas_domicilios}}</span>
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_domicilios"
            (ngModelChange)="getChartVentasDomicilios()">
            <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
              {{mes.filtro}}-{{mes.filtro_inicio}}
            </option>
          </select>
        </div>
      </div>
      <canvas #bar_ventas_domicilios [ngClass]="{'inactive': data_bar_ventas_domicilios.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_ventas_domicilios.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <div class="card ventas-pedidos">
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between mb-3">
        <div class="mb-3">Establecimientos alcanzados por mes</div>
        <div class="d-flex justify-content-between">
          <span>Total: {{total_ventas_pedidos}}</span>
          <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_ventas_pedidos_establecimientos"
            (ngModelChange)="getChartEstablecimientosAlcanzados()">
            <option [value]="ano" *ngFor="let ano of anos_filtro">
              {{ano}}
            </option>
          </select>
        </div>
      </div>
      <canvas #bar_ventas_pedidos2 [ngClass]="{'inactive': data_bar_ventas_pedidos2.datasets.length == 0}"></canvas>
      <div *ngIf="data_bar_ventas_pedidos2.datasets.length > 0 else mostrarError"></div>
    </div>
  </div>
  <!-- Manejo de errores -->
  <ng-template #mostrarError>
    <div class="d-flex justify-content-center mt-4">
      <div>
        <img src="assets/img/icon-warning-amarillo.png" alt="icono de una hoja de papel y una lupa sobre ella"
          style="max-width: 150px;" />
      </div>
    </div>
    <div class="mt-2">
      <p class="text-center">
        ¡Lo siento! No existen datos para ver aquí
      </p>
    </div>
  </ng-template>
</div>