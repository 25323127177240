<div class="container">
  <div class="row">
    <div class="col-11">
      <h5>
        {{inventario_xlsx === false ? 'Cargar productos desde Excel' : 'Actualizar inventario desde Excel'}}
      </h5>
    </div>
    <div class="col-1">
      <fa-icon [icon]="faTimes" style="cursor: pointer;" size="lg" (click)="activeModal.close()">
      </fa-icon>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-12">
      <p *ngIf="!inventario_xlsx">
        Carga el producto bajo la siguiente estructura e instrucciones. Si no cumple con los requisitos, no podremos
        aprobarlo en el sistema.
      </p>
      <!--<p *ngIf="inventario_xlsx" class="text-danger font-weight-bold">
        No debes modificar el nombre y/o el código del producto, de hacerlo no se actualizará el producto.
      </p>-->
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-12">
      <button class="btn-naranja-transp">
        <a *ngIf="!inventario_xlsx" href="../../../assets/Docs/formato_productos_carga.xlsx" download="formato_productos.xlsx"
          class="btn-naranja-transp">
          Descarga el formato de Excel
        </a>
        <span *ngIf="inventario_xlsx" (click)="descargarExcelInventario()">
          Descarga el inventario actual en Excel
        </span>
      </button>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-12">
      <input type="file" class="file-upload" (change)="handleFileInput($event)" #fileUpload />
      <button class="btn-blanco" (click)="fileUpload.click()">
        + Subir archivo Excel
      </button>
      <div *ngIf="error_formato_xlsx">
        <br />
        <p class="error">
          El archivo a subir debe ser de Excel (.xlsx o .xls). Puedes dar click arriba en 'Descarga el formato de Excel'
          para tener el archivo en el formato correcto.
        </p>
      </div>
      <div *ngIf="plantilla_prodcutos">
        <br />
        <p>
          <strong>
            {{ plantilla_prodcutos.name }}
          </strong>
          <fa-icon [icon]="faTimesCircle" size="lg" (click)="handleFileInput(undefined)">
          </fa-icon>
        </p>
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-12 text-right">
      <button class="btn-morado" *ngIf="plantilla_prodcutos" (click)="siguiente()">
        Siguiente
      </button>
      <button class="btn-morado disabled" *ngIf="!plantilla_prodcutos" disabled>
        Siguiente
      </button>
    </div>
  </div>
</div>