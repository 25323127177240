<div class="d-flex flex-column justify-content-center w-100 align-items-center" *ngIf="producto">
  <div class="contenedor">
    <!--Volver-->
    <div class="volver-container d-flex flex-row w-100 pb-5">
      <div class="col-2 p-0">
        <button class="boton-volver" (click)="volver()">
          <fa-icon [icon]="faChevronLeft"></fa-icon>
          Volver
        </button>
      </div>
      <div class="col-9 d-flex align-items-center p-0">
        <h2 class="d-flex align-items-center p-0">
          <div class="convencion mr-2"
            [ngClass]="{'producto-aprobado': (distribuidorForm.get('distribuidorEstado')?.value === 'Aprobado' || distribuidorForm.get('distribuidorEstado')?.value === 'Aceptado'), 'producto-pendiente': distribuidorForm.get('distribuidorEstado')?.value === 'Pendiente', 'producto-rechazado': distribuidorForm.get('distribuidorEstado')?.value === 'Rechazado' || distribuidorForm.get('distribuidorEstado')?.value === 'Inactivo'}">
          </div>{{ distribuidorForm.get('distribuidorProductoNombre')?.value }}
        </h2>
      </div>
    </div>
    <div class="top-form">
      <!--Scroll de fotos-->
      <div id="scroll-fotos">
        <!--Agregar fotos-->
        <input type="file" class="file-upload" (change)="handleFileInput($event)" #fileUpload />
        <button class="btn-normal btn-white" (click)="fileUpload.click()"
          *ngIf="distribuidorForm.get('distribuidorEstado')?.value === 'Pendiente'">
          + Agregar foto
        </button>
        <p *ngIf="error_formato_img" class="error">
          El archivo debe tener un formato válido como .png o .jpg
        </p>
        <p *ngIf="error_max_imgs" class="error">
          El producto sólo puede tener máximo tres imágenes
        </p>
        <div class="row h-100 mt-3">
          <div class="col-12" *ngFor="let foto of fotos; index as i">
            <div class="card card-foto">
              <div class="card-body">
                <fa-icon [icon]="faTimesCircle" pull="right" class="icon-delete" (click)="eliminarFoto(i)"
                  (click)="fileUpload.value = ''"
                  *ngIf="distribuidorForm.get('distribuidorEstado')?.value === 'Pendiente'">
                </fa-icon>
                <span class="foto-mini-container">
                  <img [src]="foto" class="foto-mini foto-mini-scroll" alt="foto" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Foto principal-->
      <div class="foto-principal">
        <div class="card h-100">
          <div class="card-body h-100 d-flex align-center justify-content-center">
            <img [src]="fotos[0] || product_placeholder" class="foto-mini foto-principal-producto" alt="" />
          </div>
        </div>
      </div>
      <!--Codigo Distribuidor-->
      <div class="codigo-distribuidor">
        <div class="card ">
          <div class="card-body ">
            <p class="m-0">
              <span class="negrilla"> Cód. distribuidor: </span>
              {{ distribuidorForm.get('distribuidorCodigoProducto')?.value || 'No encontrado' }}
            </p>
            <p class="m-0">
              <span class="negrilla"> Cód. FEAT:  </span>
              <span
              *ngIf="distribuidorForm.get('distribuidorEstado')?.value === 'Aprobado' || distribuidorForm.get('distribuidorEstado')?.value === 'Aceptado'  || distribuidorForm.get('distribuidorEstado')?.value === 'Inactivo'">
              {{ producto?.codigo_ft || 'No encontrado' }}
            </span>
            <span
              *ngIf="distribuidorForm.get('distribuidorEstado')?.value !== 'Aprobado' && distribuidorForm.get('distribuidorEstado')?.value !== 'Aceptado'">
              Producto no aprobado
            </span>
            </p>
            <p class="m-0">
              <span class="negrilla">Cód. organización: </span>
              <span
                *ngIf="distribuidorForm.get('distribuidorEstado')?.value === 'Aprobado' || distribuidorForm.get('distribuidorEstado')?.value === 'Aceptado'">
                {{ distribuidorForm.get('distribuidorCodigoOrganizacion')?.value || 'No encontrado' }}
              </span>
              <span
                *ngIf="distribuidorForm.get('distribuidorEstado')?.value !== 'Aprobado' && distribuidorForm.get('distribuidorEstado')?.value !== 'Aceptado'">
                Producto no aprobado
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="card inventario">
        <div class="card-body">
          <p>
            Inventario
          </p>
          <!--Unidades-->
          <div class="row row-inv">
            <div class="col-6 text-left">
              {{ this.distribuidorForm.get('distribuidorInventarioUnidad')?.value || 0 }}
            </div>
            <div class="col-6 text-right">
              Unidades
            </div>
          </div>
          <br />
          <!--Cajas-->
          <div class="row row-inv">
            <div class="col-6 text-left">
              {{ this.distribuidorForm.get('distribuidorInventarioCaja')?.value || 0 }}
            </div>
            <div class="col-6 text-right">
              Caja{{this.distribuidorForm.get('distribuidorInventarioCaja')?.value != 1 ? 's' : ''}}
            </div>
          </div>
          <br />
          <!--Btn Ajustar Inventario-->
          <div class="row">
            <div class="col-12">
              <button class="btn-normal btn-white" (click)="editarInventario()">
                <p class="h-btn">
                  Ajustar inventario
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card bottom-container p-5 m-0 mt-5">
      <div class="row">
        <div class="col-md-6">

        </div>
        <div class="col-md-6">

        </div>
      </div>
      <div class="mb-1 bottom-form " [formGroup]="distribuidorForm">
        <!--Precios-->
        <div class="w-100 precio-titulo">
          <h6 class="mt-3 mb-0">
            Precios
          </h6>
        </div>
        <!--Precio por unidad-->
        <div class="precio-unidad">
          <label><span class="asterisco">*</span>Precio por unidad</label>
          <input formControlName="distribuidorPrecioUnidad" (keydown)="validateNumber($event)"
            (blur)="transformAmount($event.target, 'unidad')" (focus)="transformCurrency($event.target, 'unidad')"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorPrecioUnidad')?.hasError('required') && distribuidorForm.get('distribuidorPrecioUnidad')?.touched )}"
            name="tipo documento" placeholder="Escribe aquí" />
        </div>
        <!--Precio por caja-->
        <div class="precio-caja">
          <label><span class="asterisco">*</span>Precio por caja</label>
          <input formControlName="distribuidorPrecioCaja" [attr.disabled]="true" class="disabled"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorPrecioCaja')?.hasError('required') && distribuidorForm.get('distribuidorPrecioCaja')?.touched )}"
            name="tipo documento" placeholder="Escribe aquí" />
        </div>
        <!--Precio por unidad de medida-->
        <div class="precio-unidad-medida">
          <label><span class="asterisco">*</span>Precio por unidad de medida</label>
          <input formControlName="distribuidorPrecioUnidadMedida" [attr.disabled]="true" class="disabled"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorPrecioUnidadMedida')?.hasError('required') && distribuidorForm.get('distribuidorPrecioUnidadMedida')?.touched )}"
            name="tipo documento" placeholder="Escribe aquí" />
        </div>
        <div class="informacion-titulo w-100">
          <h6 class="mb-0 mt-3">Información del producto</h6>
        </div>
        <!--Nombre del producto-->
        <div class="nombre-producto">
          <label class="d-flex flex-row justify-content-between">
            <span>
              <span class="asterisco">*</span>Nombre del producto
            </span>
            <span class="text-secondary" id="characterCount"> {{remainingTextNombre}}/50</span>
          </label>
          <textarea #text_nombre formControlName="distribuidorProductoNombre" name="nombre" placeholder="Escribe aquí"
            required="true" rows="1" class="p-3"
            (keydown)="valueChange(text_nombre.value, 'nombre')" (change)="valueChange(text_nombre.value, 'nombre')"
            [ngClass]="{'disabled':( distribuidorForm.get('distribuidorEstado')?.value === 'Aprobado' ||  distribuidorForm.get('distribuidorEstado')?.value === 'Aceptado' ||   distribuidorForm.get('distribuidorEstado')?.value === 'Rechazado')}"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorProductoNombre')?.hasError('required') && distribuidorForm.get('distribuidorProductoNombre')?.touched || ( distribuidorForm.get('distribuidorProductoNombre')?.hasError('pattern') && distribuidorForm.get('distribuidorProductoNombre')?.touched ) )}">
            </textarea>
        </div>
        <!--Marca-->
        <div class="marca-producto">
          <label for="marca"><span class="asterisco">*</span>Marca</label>
          <select formControlName="distribuidorMarca" class="d-inline-block dropdown-form-1"
            *ngIf="distribuidorForm.get('distribuidorEstado')?.value !== 'Pendiente'"
            [ngClass]="{'disabled':( distribuidorForm.get('distribuidorEstado')?.value === 'Aprobado' ||  distribuidorForm.get('distribuidorEstado')?.value === 'Aceptado' ||   distribuidorForm.get('distribuidorEstado')?.value === 'Rechazado')}"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorMarca')?.hasError('required') && distribuidorForm.get('distribuidorMarca')?.touched )}">
            <option disabled value="">Selecciona</option>
            <option value={{marca._id}} *ngFor="let marca of this.marcas; index as i;">{{marca.nombre}}</option>
          </select>
          <input formControlName="distribuidorMarcaManual" name="marca_manual" type="text"
            *ngIf="distribuidorForm.get('distribuidorEstado')?.value === 'Pendiente'"
            placeholder="Escribe aquí"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorMarcaManual')?.hasError('required') && distribuidorForm.get('distribuidorMarcaManual')?.touched )}" />
        </div>
        <!--Organización-->
        <div class="organizacion">
          <label for="organizacion"><span class="asterisco">*</span>Organización</label>
          <select formControlName="distribuidorOrganizacion" class="d-inline-block dropdown-form-1"
            *ngIf="distribuidorForm.get('distribuidorEstado')?.value !== 'Pendiente'"
            [ngClass]="{'disabled':( distribuidorForm.get('distribuidorEstado')?.value === 'Aprobado' ||  distribuidorForm.get('distribuidorEstado')?.value === 'Aceptado' ||   distribuidorForm.get('distribuidorEstado')?.value === 'Rechazado')}"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorOrganizacion')?.hasError('required') && distribuidorForm.get('distribuidorOrganizacion')?.touched )}">
            <option disabled value="">Selecciona</option>
            <option value={{org._id}} *ngFor="let org of organizaciones; index as i;">{{org.nombre}}</option>
          </select>
          <input formControlName="distribuidorOrganizacionManual" name="organizacion_manual" type="text"
            *ngIf="distribuidorForm.get('distribuidorEstado')?.value === 'Pendiente'"
            placeholder="Escribe aquí"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorOrganizacionManual')?.hasError('required') && distribuidorForm.get('distribuidorOrganizacionManual')?.touched )}" />
        </div>
        <!--Categoría-->
        <div class="categoria">
          <label for="categoria"><span class="asterisco">*</span>Categoría</label>
          <select formControlName="distribuidorCategoria" class="dropdown-form-1 d-inline-block"
            *ngIf="distribuidorForm.get('distribuidorEstado')?.value !== 'Pendiente'"
            (change)="recuperarLineas(this.distribuidorForm.get('distribuidorCategoria')?.value._id)"
            (change)="distribuidorForm.patchValue({ distribuidorLinea: '' })"
            [ngClass]="{'disabled':( distribuidorForm.get('distribuidorEstado')?.value === 'Aprobado' ||  distribuidorForm.get('distribuidorEstado')?.value === 'Aceptado' ||   distribuidorForm.get('distribuidorEstado')?.value === 'Rechazado')}"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorCategoria')?.hasError('required') && distribuidorForm.get('distribuidorCategoria')?.touched )}">
            <option selected disabled value="">Selecciona</option>
            <option  *ngFor="let cat of categorias" [value]="cat._id">{{cat.nombre}}</option>
          </select>
          <input formControlName="distribuidorCategoriaManual" name="categoria_manual" type="text"
            *ngIf="distribuidorForm.get('distribuidorEstado')?.value === 'Pendiente'"
            placeholder="Escribe aquí"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorCategoriaManual')?.hasError('required') && distribuidorForm.get('distribuidorCategoriaManual')?.touched )}" />
        </div>
        <!--Línea de producto-->
        <div class="linea">
          <label for="linea"><span class="asterisco">*</span>Línea de producto</label>
          <select formControlName="distribuidorLinea" class="dropdown-form-1 d-inline-block"
            *ngIf="distribuidorForm.get('distribuidorEstado')?.value !== 'Pendiente'"
            [ngClass]="{'disabled':( distribuidorForm.get('distribuidorEstado')?.value === 'Aprobado' ||  distribuidorForm.get('distribuidorEstado')?.value === 'Aceptado' ||   distribuidorForm.get('distribuidorEstado')?.value === 'Rechazado')}"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorLinea')?.hasError('required') && distribuidorForm.get('distribuidorLinea')?.touched )}">
            <option disabled value="">Selecciona</option>
            <option value={{linea._id}} *ngFor="let linea of lineas">{{linea.nombre}}</option>
          </select>
          <input formControlName="distribuidorLineaManual" name="linea_manual" type="text"
            *ngIf="distribuidorForm.get('distribuidorEstado')?.value === 'Pendiente'"
            placeholder="Escribe aquí"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorLineaManual')?.hasError('required') && distribuidorForm.get('distribuidorLineaManual')?.touched )}" />
        </div>
        <!--Unidad de Medida-->
        <div class="unidad-medida h-100">
          <label for="unidad-medida"><span class="asterisco">*</span>
            Unidad de Medida
            {{ distribuidorForm.get('distribuidorEstado')?.value === 'Pendiente' && producto.precios[0]?.unidad_medida_manual ? '(' + producto.precios[0]?.unidad_medida_manual + ')' : '' }}
          </label>
          <select formControlName="distribuidorUnidadMedida" class="dropdown-form-1 d-inline-block"
            [ngClass]="{'disabled':( distribuidorForm.get('distribuidorEstado')?.value === 'Aprobado' ||  distribuidorForm.get('distribuidorEstado')?.value === 'Aceptado' ||   distribuidorForm.get('distribuidorEstado')?.value === 'Rechazado')}"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorUnidadMedida')?.hasError('required') && distribuidorForm.get('distribuidorUnidadMedida')?.touched )}">
            <option disabled value="">Selecciona</option>
            <option value="kg">kg</option>
            <option value="lb">lb</option>
            <option value="g">g</option>
            <option value="L">L</option>
            <option value="ml">ml</option>
            <option value="und">und</option>
            <option value="otro">Otro</option>
          </select>
        </div>
        <!--Cantidad de medida-->
        <div class="cantidad-medida h-100">
          <label for="cantidad_medida"><span class="asterisco">*</span>Cantidad de medida</label>
          <input formControlName="distribuidorCantidadMedida" name="cantidad_medida" type="number"
            placeholder="Escribe aquí" (keydown)="validateNumber($event)"
            [ngClass]="{'disabled':( distribuidorForm.get('distribuidorEstado')?.value === 'Aprobado' ||  distribuidorForm.get('distribuidorEstado')?.value === 'Aceptado' ||   distribuidorForm.get('distribuidorEstado')?.value === 'Rechazado')}"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorCantidadMedida')?.hasError('required') && distribuidorForm.get('distribuidorCantidadMedida')?.touched )}" />
        </div>
        <!--Presentación Und. X caja-->
        <div class="Presentacion-caja h-100">
          <label for="presentacion_caja"><span class="asterisco">*</span>Presentación Und. X caja</label>
          <input formControlName="distribuidorPresentacionCaja" name="presentacion_caja" type="number"
            placeholder="Escribe aquí" (keydown)="validateNumber($event)"
            [ngClass]="{'disabled':( distribuidorForm.get('distribuidorEstado')?.value === 'Aprobado' ||  distribuidorForm.get('distribuidorEstado')?.value === 'Aceptado' ||   distribuidorForm.get('distribuidorEstado')?.value === 'Rechazado')}"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorPresentacionCaja')?.hasError('required') && distribuidorForm.get('distribuidorPresentacionCaja')?.touched )}" />
        </div>
        <!--Descripción-->
        <div class="descripcion-producto">
          <label for="descripcion" class="d-flex flex-row justify-content-between">
            <span>
              <span class="asterisco">*</span>Descripción
            </span>
            <span id="characterCount" class="text-secondary"> {{remainingTextDescripcion}}/300</span>
          </label>
          <textarea #text formControlName="distribuidorProductoDescripcion" name="descripcion"
            placeholder="Escribe aquí" required="true" value="300" maxlength="300" data-attr="300" rows="3" class="p-3"
            (change)="valueChange(text.value, 'descripcion')" (keydown)="valueChange(text.value, 'descripcion')"
            [ngClass]="{'disabled':( distribuidorForm.get('distribuidorEstado')?.value === 'Aprobado' ||  distribuidorForm.get('distribuidorEstado')?.value === 'Aceptado' ||   distribuidorForm.get('distribuidorEstado')?.value === 'Rechazado')}"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorProductoDescripcion')?.hasError('required') && distribuidorForm.get('distribuidorProductoDescripcion')?.touched || ( distribuidorForm.get('distribuidorProductoDescripcion')?.hasError('pattern') && distribuidorForm.get('distribuidorProductoDescripcion')?.touched ) )}">
              </textarea>
        </div>
        <!--Código producto-->
        <div class="codigo-producto h-100">
          <label for="cantidad_medida"><span class="asterisco">*</span>Código producto</label>
          <input formControlName="distribuidorCodigoProducto" name="cantidad_medida" type="text"
            placeholder="Escribe aquí"
            [ngClass]="{'invalid-warning': ( distribuidorForm.get('distribuidorCodigoProducto')?.hasError('required') && distribuidorForm.get('distribuidorCodigoProducto')?.touched )}" />
        </div>
      </div>
    </div>
    <div class="mb-5">
      <div *ngIf="producto.ficha_tecnica">
        <fa-icon
          [icon]="faEye"
          [styles]="{ color: '#41403E', 'padding-right': '5px', 'padding-top': '7px' }"
          id="fa-eye"></fa-icon>
          <span (click)="goLinkFicha()" class="cursorPointer">Ver ficha técnica</span>
      </div>
      <div>
        <input type="file" class="file-upload" (change)="handleFileFicha($event)" #fileUploadFichaDist hidden/>
        <a (click)="fileUploadFichaDist.click()" class="btn-ficha">
          <fa-icon [icon]="faFilePdf" [styles]="{ color: '#8e6ff7' }" id="fa-icon"></fa-icon>
          <span *ngIf="producto.ficha_tecnica">
            Cambiar ficha técnica
          </span>
          <span *ngIf="!producto.ficha_tecnica">
            Subir ficha técnica
          </span>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <button class="btn-submit w-100" (click)="!distribuidorForm.invalid && guardarCambios()"
            (click)="distribuidorForm.invalid && alertaFormularioInvalido()"
            [ngClass]="{'btn-submit-disabled': distribuidorForm.invalid}">
            Guardar cambios
          </button>
          <div *ngIf="distribuidorForm.get('distribuidorEstado')?.value === 'Aceptado'">
            <p class="text-secondary mtop2">
              Si requieres un cambio en alguna de las celdas restringidas, por favor comunícate con nosotros:
            </p>
            <button type="button" class="btn btn-success w-100" (click)="goToLink()">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-whatsapp"
                viewBox="0 0 16 16">
                <path
                  d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
              </svg>
              Solicitar otro cambio a Feat
            </button>
          </div>
        <div class="col-12 padding0" *ngIf=" distribuidorForm.get('distribuidorEstado')?.value !== 'Pendiente'">
          <button class="btn-delete w-100 mtop2" 
            [ngClass]="{'btn-submit': producto!.estadoActualizacion === 'Inactivo'}"
            (click)="inactivarActivarProducto(producto!.estadoActualizacion !== 'Inactivo' ? 'inactivar' : 'activar')">
            {{producto!.estadoActualizacion !== 'Inactivo' ? 'Inactivar' : 'Activar'}} producto
          </button>
        </div>
      </div>
      <div class="col-md-6" *ngIf="producto.estadoActualizacion === 'Aceptado'">
        <div class="row">
          <div class="col-12">
            <input type="checkbox" [(ngModel)]="prodDescuento" class="check" id="descuento" name="descuento" />
            <label for="descuento">Producto en descuento</label>
            <input *ngIf="prodDescuento" type="number" max="100" [(ngModel)]="prodPorcentajeDesc" class="check discount-input" id="Podescuento" name="Podescuento" />
            <span *ngIf="prodDescuento"> %</span>
          </div>
          <div class="col-12">
            <input type="checkbox" [(ngModel)]="prodBiodegradable" class="check" id="biodegradable" name="biodegradable" />
            <label for="biodegradable">Producto en Biodegradable</label>
          </div>
          <div class="col-12">
            <input type="checkbox" [(ngModel)]="prodPedido"  class="check" id="porpedido" name="porpedido" />
            <label for="porpedido">Producto por pedido</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>