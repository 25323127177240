import { Component, DoCheck, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { SetSelectedToken } from 'src/app/actions/token.actions';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { Trabajador } from 'src/app/models/trabajador.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PlacesService } from 'src/app/services/places/places.service';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-informacion-cuenta-distribuidor',
  templateUrl: './informacion-cuenta-distribuidor.component.html',
  styleUrls: ['./informacion-cuenta-distribuidor.component.css'],
})
export class InformacionCuentaDistribuidorComponent implements OnInit, DoCheck {
  /** Formularios reactivos */
  public distribuidorForm: FormGroup;
  /** Variables para el manejo de la lista de departamentos y ciudades */
  public ciudades: any;
  public ciudades_empresa: any;
  public departamentos: any;
  public departamentos_empresa: any;
  /** Validación de formulario que no entran en los validators */
  public is_boton_habilitado = true;
  public validator_telefono_2 = false;

  constructor(
    private auth: AuthService,
    public places: PlacesService,
    private rest: RestService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private ngxsStore: Store
  ) {
    this.distribuidorForm = this.formBuilder.group({
      distribuidorTipoDocumento: ['', Validators.required],
      distribuidorNumeroDocumento: ['', Validators.required],
      distribuidorNombre: ['', Validators.required],
      distribuidorApellido: ['', Validators.required],
      distribuidorPais: ['', Validators.required],
      distribuidorDepartamento: ['', Validators.required],
      distribuidorCiudad: ['', Validators.required],
      distribuidorTelefono: ['', [Validators.required, Validators.pattern(/^3[\d]{9}$/)]],
      distribuidorTelefono2: [''],
      distribuidorCorreo: ['', [Validators.required, Validators.email]],
      distribuidorTipo: ['', Validators.required],
    });
    this.distribuidorForm.setValue({
      distribuidorTipoDocumento: this.auth.user?.tipo_documento,
      distribuidorNumeroDocumento: this.auth.user?.numero_documento,
      distribuidorNombre: this.auth.user?.nombres,
      distribuidorApellido: this.auth.user?.apellidos,
      distribuidorPais: 'Colombia',
      distribuidorDepartamento: this.auth.user?.departamento,
      distribuidorCiudad: this.auth.user?.ciudad,
      distribuidorTelefono: this.auth.user?.celular,
      distribuidorTelefono2: '',
      distribuidorCorreo: this.auth.user?.correo,
      distribuidorTipo: this.auth.user?.tipo_trabajador,
    });
    if (this.auth.user?.telefono !== undefined) {
      this.distribuidorForm.patchValue({
        distribuidorTelefono2: this.auth.user?.telefono,
      });
    }
  }

  ngDoCheck(): void {
    this.validarFormulario();
  }

  ngOnInit(): void {
    this.distribuidorForm.markAllAsTouched();
    this.getPlaces();
  }

  /******************************************** Mapa *******************************************/
  /**
   * Metodo para obtener departamentos y ciudades mediante API Imagine Apps
   */
  async getPlaces() {
    let id_dpto = 0;
    this.departamentos = this.departamentos_empresa = await this.places.getDepartmentFromServerImagine();
    for (const dpto of this.departamentos) {
      if (dpto.name == this.distribuidorForm.get('distribuidorDepartamento')?.value) {
        break;
      }
      id_dpto++;
    }
    if (this.departamentos[id_dpto] != undefined) {
      this.ciudades = await this.places.getCitiesFromServerImagine(this.departamentos[id_dpto].code);
    } else {
      this.ciudades = [];
    }
  }

  /**************************************** Validadores ****************************************/
  /** Estan son validaciones manuales dado que no son requeridas o dependen de otras validaciones */
  validarFormulario() {
    /** Todas inician el false */
    this.is_boton_habilitado = false;
    this.validator_telefono_2 = false;
    /** Si escribe un segundo telefono que tengas 7 o 10 digítos */
    if (
      this.distribuidorForm.get('distribuidorTelefono2')?.value.toString().length == 0 ||
      this.distribuidorForm.get('distribuidorTelefono2')?.value.toString().length == 7 ||
      this.distribuidorForm.get('distribuidorTelefono2')?.value.toString().length == 10
    ) {
      this.validator_telefono_2 = true;
      this.is_boton_habilitado = !this.distribuidorForm.invalid;
    }
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateNumber(event: any) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || excludedKeys.includes(keyCode))) {
      event.preventDefault();
    }
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateOnlyText(event: any) {
    const keyCode = event.keyCode;
    if (keyCode >= 48 && keyCode <= 57) {
      event.preventDefault();
    }
  }

  /************************************* Guardar y alertar *************************************/
  /**
   * Envía los datos ingresados al back para editar la cuenta del trabajador actual.
   * Se asume que el form está correcto ya que este método sólo se llama con el
   * botón de "Guardar cambios" y este botón sólo se activa cuando el form
   * tiene todos los datos correctos
   */
  async guardarCambios() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
    };
    const nuevo_trab: Trabajador = new Trabajador(
      this.distribuidorForm.get('distribuidorNombre')?.value,
      this.distribuidorForm.get('distribuidorApellido')?.value,
      this.distribuidorForm.get('distribuidorCorreo')?.value,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.auth.user!.clave!,
      this.distribuidorForm.get('distribuidorTelefono2')?.value,
      this.distribuidorForm.get('distribuidorTelefono')?.value,
      this.distribuidorForm.get('distribuidorPais')?.value,
      this.distribuidorForm.get('distribuidorDepartamento')?.value,
      this.distribuidorForm.get('distribuidorCiudad')?.value,
      this.distribuidorForm.get('distribuidorTipoDocumento')?.value,
      this.distribuidorForm.get('distribuidorNumeroDocumento')?.value,
      this.distribuidorForm.get('distribuidorTipo')?.value,
      this.auth.user?.solicitud_vinculacion,
      this.auth.user?.show_slides,
      undefined,
      undefined,
      this.auth.user?.distribuidor,
      undefined
    );
    try {
      const resp: any = await this.rest.putJWT(`trabajador/${this.auth.user?._id}`, nuevo_trab).toPromise();
      this.auth.user = resp;
      //Se guarda la info el Store
      this.ngxsStore
        .dispatch(
          new SetSelectedToken({
            token: this.auth.token,
            email: this.auth.email,
            user: this.auth.user,
            user_horeca: this.auth.user_horeca,
            user_distribuidor: this.auth.user_distribuidor,
          })
        )
        .toPromise()
        .then(() => {
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          modalRef.componentInstance.msg = 'Se actualizó la información de tu cuenta correctamente.';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            window.location.reload();
          };
        });
    } catch (err) {
      console.log(err);
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'No fue posible actualizar la información de la cuenta. ¡Por favor intenta de nuevo más tarde!';
      modalRef.componentInstance.btn_msg = 'Volver';
    }
  }

  /**
   * Alerta si el formulario esta incompleto o un input es invalido
   */
  alertaFormularioInvalido() {
    const modalRef = this.modalService.open(SimpleComponent);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = '¡Por favor asegúrate de llenar todos los datos y vuelve a intentarlo!';
    modalRef.componentInstance.btn_msg = 'Volver';
  }
}
