import { Component, OnInit } from '@angular/core';
import { faShoppingCart, faBars, faUser } from '@fortawesome/free-solid-svg-icons';
import { CarritoDistService } from '../../services/carrito-dist/carrito-dist.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-header-organizacion',
  templateUrl: './header-organizacion.component.html',
  styleUrls: ['./header-organizacion.component.css'],
})
export class HeaderOrganizacionComponent implements OnInit {
  /** Iconos FontAwesome para usar en el template */
  faShoppingCart = faShoppingCart;
  faUser = faUser;
  faBars = faBars;
  userActivo: any;

  constructor(public carritoDist: CarritoDistService, private router: Router, private ngxsStore: Store) {}

  public isMenuCollapsed = true;

  ngOnInit(): void {
    this.userActivo = this.ngxsStore.snapshot().auth.user;
  }

  // onCuenta() {
  //   if (this.userActivo.tipo_trabajador == 'OPERATIVO') {
  //     this.router.navigate(['/detalle-trabajador-organizacion/', this.userActivo._id]);
  //   } else {
  //     this.router.navigate(['/cuenta-organizacion']);
  //   }
  // }

  public verDocumento(link: any) {
    window.open(link, '_blank');
  }
}
