/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, OnInit, SecurityContext } from '@angular/core';
import { faTimes, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PublicacionService } from '../../services/publicacion/publicacion.service';
import { Router } from '@angular/router';
import { RestService } from 'src/app/services/rest/rest.service';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';

@Component({
  selector: 'app-publicar-publicacion',
  templateUrl: './publicar-publicacion.component.html',
  styleUrls: ['./publicar-publicacion.component.css'],
})
export class PublicarPublicacionComponent implements OnInit {
  public archivos: File[] = [];
  public imagenSubir!: File;
  // Iconos
  faTimes = faTimes;
  faArrowUp = faArrowUp;
  // Formulario reactivo
  form!: FormGroup;
  data = new FormData();
  // Logo de usuario
  logo: any;
  // Publicaciones
  publication: any;
  archivoVideo: any;
  duracionVideo: any;
  pesoVideo: any;
  archivosPath: SafeUrl[] = [];
  idPublicacion: any;
  archivoCapturado: any;
  // Acceder al localStorage para hacer la comparacion de id
  userInfo = JSON.parse(localStorage.getItem('auth')!);
  idUserInfo = this.userInfo['user']['_id'];
  userLogo: any;
  idUserInfoHoreca: any;
  /** Tipo de usuario logueado */
  tipo_usuario: any;
  /** Modal de carga para darle feedback al usuario */
  modalCarga?: NgbModalRef;

  constructor(
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
    private publicationService: PublicacionService,
    private modalService: NgbModal,
    private restService: RestService,
    private router: Router
  ) {
    this.buildForm();
  }

  close() {
    this.activeModal.close();
  }

  ngOnInit(): void {
    /** Revisa si es un establecimiento o un distribuidor logueado */
    this.restService
      .get(`tipo_empresa_trabajador/${this.userInfo['user']['_id']}`)
      .toPromise()
      .then((resp: any) => {
        // Datos del formulario para guardar un comentario
        this.tipo_usuario = resp;
        if (this.tipo_usuario === 'distribuidor') {
          this.idUserInfoHoreca = this.userInfo['user_distribuidor']['_id'];
          this.userLogo = this.userInfo['user_distribuidor']['logo'];
          this.publicationService.getUserDistribuidor(this.idUserInfoHoreca).subscribe((res) => {
            this.logo = res.logo;
          });
        } else if (this.tipo_usuario === 'usuario_horeca') {
          this.idUserInfoHoreca = this.userInfo['user_horeca']['_id'];
          this.userLogo = this.userInfo['user_horeca']['logo'];
          this.publicationService.getUserHoreca(this.idUserInfoHoreca).subscribe((res) => {
            this.logo = res.logo;
          });
        } else if (this.tipo_usuario === 'organizacion') {
          this.idUserInfoHoreca = this.userInfo['user_organizacion']['_id'];
          this.userLogo = this.userInfo['user_organizacion']['logo'];
          this.publicationService.getUserOrganizacion(this.idUserInfoHoreca).subscribe((res) => {
            this.logo = res.logo;
          });
        }
        // Datos del formulario para guardar un comentario
        this.fetchPublication();
      });
    this.fetchPublication();
  }

  // Declaro las variables del formulario para validaciones
  private buildForm() {
    this.form = this.formBuilder.group({
      titulo: ['', [Validators.minLength(2), Validators.required]],
      contenido: ['', [Validators.minLength(2), Validators.required]],
      encabezado: [''],
      autor: [this.idUserInfo],
      logo: [''],
      fecha: [Date.now()],
      imagen_publicacion: [''],
      cantidad_likes: [0],
    });
  }

  // Validacion del titulo
  get titleInvalid() {
    return this.form.get('titulo')?.invalid && this.form.get('titulo')?.touched;
  }

  // Validacion del contenido
  get contentInvalid() {
    return this.form.get('contenido')?.invalid && this.form.get('contenido')?.touched;
  }

  /**
   * Función que captura el archivo y lo convierte en base 64,
   * ademas, buscamos la duración del video en el archivo capturado
   */
  captureFile(event: any): any {
    this.archivoCapturado = <File>event.target.files[0];
    this.archivoVideo = event.target.files[0].type;
    this.archivos.push(this.archivoCapturado);
    this.archivosPath.push(
      this.sanitizer.sanitize(
        SecurityContext.NONE,
        this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.archivos[this.archivos.length - 1]))
      ) || ''
    );
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      this.duracionVideo = video.duration;
    };
    video.src = URL.createObjectURL(this.archivoCapturado);
  }

  // Elimina las imagenes
  removeImages(index: number) {
    this.archivos.splice(index, 1);
  }

  // Funcion que extrae el archivo a base64
  extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event);
        const image =
          this.sanitizer.sanitize(
            SecurityContext.NONE,
            this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.archivos[this.archivos.length - 1]))
          ) || '';
        const reader = new FileReader();
        reader.readAsDataURL($event);
        reader.onload = () => {
          resolve({
            base: reader.result,
          });
        };
        reader.onerror = (error) => {
          resolve({
            base: null,
          });
        };
      } catch (e) {
        return $event;
      }
    });

  // Funcion para traer publicaciones
  fetchPublication() {
    this.publicationService.getPublications().subscribe((result) => {
      this.publication = result;
    });
  }

  // Funcion para hacer publicaciones con los datos del usuario
  postPublication() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    if (this.archivos.length > 0) {
      this.modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
      const upload_form: FormData = new FormData();
      let i = 1;
      for (const file in this.archivos) {
        upload_form.append(`publicacion${i}`, this.archivos[file]);
        i++;
      }
      try {
        if (this.form.valid) {
          const publicationn = this.form.value;
          publicationn.encabezado = publicationn.titulo;
          publicationn.logo = this.logo;
          //publicationn.anular_noficiacion = true;
          this.publicationService.postMyPublication(publicationn).subscribe((result) => {
            this.publicationService.uploadImgPublication(result.data._id, upload_form).subscribe(() => {
              const modalRef = this.modalService.open(SimpleComponent);
              modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
              modalRef.componentInstance.title = '¡Genial!';
              modalRef.componentInstance.msg = '¡Tu Publicacion ha sido creada con éxito!';
              modalRef.componentInstance.btn_msg = 'Listo';
              modalRef.componentInstance.close_callback = () => {
                this.modalCarga?.close();
                this.activeModal.close();
                window.location.reload();
              };
            });
          });
        }
      } catch (error) {
        const modalRef = this.modalService.open(SimpleComponent);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          this.modalCarga?.close();
          this.router.navigate(['/comunidad']);
        };
      }
    } else {
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = '¡Debes agregar al menos una imagen!';
      modalRef.componentInstance.btn_msg = 'Volver';
    }
  }
}
