export class Distribuidor {
  constructor(
    public nombre: string,
    public correo: string,
    public descripcion: string,
    public tiempo_entrega: string | undefined,
    public ciudad: string,
    public ranking: number,
    public valor_minimo_pedido: number | undefined,
    public departamento?: string,
    public tipo?: string,
    public logo?: string,
    public _id?: string,
    public cobertura_coordenadas?: { longitud: number; latitud: number }[],
    public horario_atencion?: string,
    public metodo_pago?: string,
    public direccion?: string,
    public nit_cc?: string,
    public razon_social?: string,
    public solicitud_vinculacion?: string,
    public celular?: string,
    public telefono?: string,
    public top_productos?: string[],
    public max_establecimientos?: number,
    public urlPago?: string,
    public datos_poligono?: any[],
    public zonas_cobertura?: any
  ) {}
}
