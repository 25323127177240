<div class="card-placement p-3 mb-3 clickable" (click)="openDetail()">
  <h5 class="font-weight-300 mb-3">{{ punto?.nombre || 'Punto de entrega' }}</h5>
  <p class="pedidos">{{ punto?.sillas || 0 }} sillas</p>
  <div class="d-flex align-items-center">
    <img src="../../../assets/img/icons/icon-pin-map.png" class="icono mr-2"
      alt="Simbolo de pin para indicar un sitio en un mapa" />
    <p class="m-0">{{ punto?.direccion ? punto?.direccion + ' ' + punto?.ciudad : 'Ubicación' }}</p>
  </div>
  <div class="d-flex align-items-center">
    <img src="../../../assets/img/icons/Icono-14.png" class="icono mr-2" alt="Simplo de un reloj" />
    <p class="m-0">Horario: {{ punto?.horario || 'Horario' }}</p>
  </div>
</div>