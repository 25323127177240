/* eslint-disable prefer-const */
import { Component, OnInit } from '@angular/core';
import { faUser, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { Store } from '@ngxs/store';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Router, ActivatedRoute } from '@angular/router';
import { RestService } from 'src/app/services/rest/rest.service';
import { AuthService } from 'src/app/services/auth/auth.service';

import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from '../../../../modal/simple/simple.component';
import { ConfirmacionComponent } from 'src/app/modal/confirmacion/confirmacion.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PlacesService } from 'src/app/services/places/places.service';
import { CambiarContrasenaComponent } from 'src/app/modal/cambiar-contrasena/cambiar-contrasena.component';

@Component({
  selector: 'app-detalle-trabajador-org',
  templateUrl: './detalle-trabajador-org.component.html',
  styleUrls: ['./detalle-trabajador-org.component.css'],
})
export class DetalleTrabajadorOrgComponent implements OnInit {
  faUser = faUser;
  faTrashAlt = faTrashAlt;
  faChevronLeft = faChevronLeft;
  userData: any;
  orgData: any;
  id: any;
  trabajador: any;
  eliminarOpc!: boolean;
  userActivo: any;

  formUser = new FormGroup({
    tipo_documento: new FormControl('', [Validators.required]),
    numero_documento: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{5,}$/)]),
    nombres: new FormControl('', [Validators.required]),
    apellidos: new FormControl('', [Validators.required]),
    pais: new FormControl('', [Validators.required]),
    departamento: new FormControl('', [Validators.required]),
    ciudad: new FormControl('', [Validators.required]),
    celular: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[0-9]{5,}$/),
      Validators.minLength(10),
      Validators.maxLength(10),
    ]),
    telefono: new FormControl(''),
    correo: new FormControl({ value: '', disabled: true }, [Validators.required]),
    tipo_trabajador: new FormControl('', [Validators.required]),
  });
  departamentos: any[] = [];
  ciudades: any[] = [];
  celularPerson = true;
  telefonoPersona = true;
  permision: any;
  permisos_modulo_trabajador: any;
  cambiarClave = true;

  constructor(
    private rest: RestService,
    private auth: AuthService,
    private activeRoute: ActivatedRoute,
    private ngxsStore: Store,
    private router: Router,
    private modalService: NgbModal,
    public places: PlacesService
  ) {}

  /** Evita que se pueda hacer click fuera del modal */
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };

  async ngOnInit() {
    this.id = this.activeRoute.snapshot.params.id;
    const trabajador = await this.rest.getJWT(`trabajador/${this.id}`).toPromise();
    this.formUser.patchValue(trabajador);
    this.userActivo = this.ngxsStore.snapshot().auth.user;
    console.log('user....', this.userActivo);
    this.orgData = this.ngxsStore.snapshot().auth.user_organizacion;
    /** Esta validación tiene como objetivo que un usuario no se pueda eliminar asi mismo */
    this.id == this.userActivo._id ? (this.eliminarOpc = false) : (this.eliminarOpc = true);
    let tipoUser = this.userActivo.tipo_trabajador.replaceAll(' ', '-');
    tipoUser = tipoUser.replaceAll('/', '_');
    console.log('tipoUser', tipoUser);
    this.permision = await this.rest.getJWT(`trabajador_permisos_organizacion/${tipoUser}/cuenta`).toPromise();
    this.permision.modulos.forEach((data: any) => {
      if (data.modulo === 'trabajadores') {
        this.permisos_modulo_trabajador = data.permisos;
      }
    });
    await this.verificarPermisos();
    this.getPlaces();
  }
  verificarPermisos() {
    console.log(this.permisos_modulo_trabajador);
    let cambioclave = this.permisos_modulo_trabajador['editar'].some(function (value: any) {
      return value == 'editar_clave_trabajador';
    });
    this.cambiarClave = cambioclave;
  }
  /**
   * Metodo para conseguir ciudades y departamentos del país
   */
  async getPlaces() {
    await this.places.getDepartmentFromServerImagine();
    this.departamentos = this.places.departments_colombia;
    let id_dpto = 0;
    for (const dpto of this.places.departments_colombia) {
      if (dpto.name == this.formUser.get('departamento')?.value) {
        break;
      }
      id_dpto++;
    }
    if (this.places.departments_colombia[id_dpto] != undefined) {
      await this.places.getCitiesFromServerImagine(this.places.departments_colombia[id_dpto].code);
      this.ciudades = this.places.cities_colombia;
    } else {
      this.ciudades = [];
    }
  }

  /**
   * Lanza un diálogo de confirmación. Si el usuario selecciona
   * que sí, se desactiva al trabajador actual. Si no, se cierra
   * el diálogo sin hacer nada
   */
  onEliminar() {
    const modalRef = this.modalService.open(ConfirmacionComponent, this.ngbModalOptions);
    modalRef.componentInstance.msg = '¿Estás seguro de desactivar a este trabajador?';
    modalRef.componentInstance.btn_msg_no = 'Cancelar';
    modalRef.componentInstance.btn_msg_yes = 'Desactivar';
    modalRef.componentInstance.callback_no = () => {};
    modalRef.componentInstance.callback_yes = () => {
      const obj_aux: any = {
        solicitud_vinculacion: 'Cancelado',
      };
      this.rest
        .putJWT(`trabajador/${this.id}`, obj_aux)
        .toPromise()
        .then((resp: any) => {
          const modalRef = this.modalService.open(SimpleComponent);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          modalRef.componentInstance.msg = 'EL usuario ha sido eliminado con éxito';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            this.router.navigate(['/cuenta-organizacion']);
          };
        })
        .catch(() => {
          const modalRef = this.modalService.open(SimpleComponent);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
          modalRef.componentInstance.btn_msg = 'Volver';
        });
    };
  }

  onDataUsuarios() {
    this.router.navigate(['/cuenta-organizacion']);
  }

  onActualizarInformacion() {
    const modalRef = this.modalService.open(ConfirmacionComponent, this.ngbModalOptions);
    modalRef.componentInstance.msg = '¿Estás seguro de actualizar la información de este trabajador?';
    modalRef.componentInstance.btn_msg_no = 'Cancelar';
    modalRef.componentInstance.btn_msg_yes = 'Continuar';
    modalRef.componentInstance.callback_no = () => {};
    modalRef.componentInstance.callback_yes = () => {
      /* const obj_aux: any = {
        solicitud_vinculacion: 'Cancelado',
      }; */
      this.rest
        .putJWT(`trabajador/${this.id}`, this.formUser.value)
        .toPromise()
        .then((resp: any) => {
          const modalRef = this.modalService.open(SimpleComponent);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          modalRef.componentInstance.msg = 'La información del usuario ha sido actualizada con éxito';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            window.location.reload();
          };
        })
        .catch(() => {
          const modalRef = this.modalService.open(SimpleComponent);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
          modalRef.componentInstance.btn_msg = 'Volver';
        });
    };
  }

  onCambiarContrasena() {
    const modalRef = this.modalService.open(CambiarContrasenaComponent);
    modalRef.componentInstance.id_user = this.id;
    modalRef.componentInstance.clave_actual = true;
  }

  verificarNumero(e: any) {
    const celularPersona = this.formUser.get('celular')?.value.toString().length;
    const telefonoPersona = this.formUser.get('telefono')?.value.toString().length;

    if (celularPersona != 10 || celularPersona == null) {
      this.celularPerson = false;
    } else {
      this.celularPerson = true;
    }

    if (telefonoPersona == 10 || telefonoPersona == 7) {
      this.telefonoPersona = true;
    } else {
      this.telefonoPersona = false;
    }
  }
}
