import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { Producto } from 'src/app/models/producto.model';
import { PuntoEntrega } from 'src/app/models/punto_entrega.model';
import { UsuarioHoreca } from 'src/app/models/usuario-horeca.model';
import { AuthService } from '../auth/auth.service';
import { RestService } from '../rest/rest.service';

@Injectable({
  providedIn: 'root',
})
export class CarritoDistService {
  constructor(private modalService: NgbModal, private rest: RestService, private auth: AuthService) {}

  // Datos del cliente a quien se le va a sugerir el pedido
  public cliente?: UsuarioHoreca;
  public punto_entrega?: PuntoEntrega;
  //Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };
  // Datos del pedido a sugerir
  public productos: Producto[] = [];
  public precios: any[] = [];
  public fotos: string[] = [];
  public unidades: number[] = [];
  public cajas: number[] = [];
  public subtotal = 0;
  public total = 0;
  public valor_1pt = 0;
  public valor_minimo = 0;

  /**
   * Revisa si el producto que entra por parámetro ya esta en el carrito.
   * Si sí esta, se actualizan sus unidades a las que entran por parámetro (asegunrandose
   * de ajustar también las cajas según cuantas unidades debería tener una caja)
   * Si no esta, se agrega al carrito con las unidades que entran por parámetro haciendo
   * el ajuste necesario para que las cajas cuadren según cuantas unidades hay por caja
   * Luego, se vuelve a calcular el subtotal y el total
   * @param prod El producto a actualizar o agregar
   * @param cajas El número de cajas para asignar del producto al carrito
   * @param unidades El número de unidades para asignar del producto al carrito
   */
  actualizarProducto(prod: Producto, cajas: number, unidades: number) {
    const index = this.productos.indexOf(prod);
    if (index == -1) {
      this.productos.push(prod);
      this.precios.push(prod.precios);
      this.fotos.push(prod.fotos?.[0] || '');
      this.cajas.push(cajas);
      this.unidades.push(unidades);
    } else {
      this.unidades[index] = unidades;
      this.cajas[index] = cajas;
    }

    if (this.unidades[index] == 0 && this.cajas[index] == 0) {
      this.eliminarProducto(index);
    }

    this.calcularPrecios();
  }

  /**
   * Elimina la información del producto cuyo índice entra por parámetro
   * @param index La posición en los arreglos que corresponde con la info del
   * del producto a eliminar
   */
  eliminarProducto(index: number) {
    this.productos.splice(index, 1);
    this.precios.splice(index, 1);
    this.fotos.splice(index, 1);
    this.unidades.splice(index, 1);
    this.cajas.splice(index, 1);
  }

  /**
   * Calcula el subtotal, el descuento, y el total
   * usando los productos que están en el carrito
   */
  calcularPrecios() {
    this.subtotal = 0;
    this.total = 0;

    let i = 0;
    for (const precio_aux of this.precios) {
      if (!this.productos[i].saldos) {
        this.subtotal += precio_aux[0].precio_unidad * this.unidades[i];
      } else {
        this.subtotal += precio_aux[0].precio_descuento * this.unidades[i];
      }
      i++;
    }

    this.total = this.subtotal;
  }

  /**
   * Envía el pedido armado al servidor con estado Sugerido
   * para el usuario horeca con su punto de entrega seleccionado
   */
  async sugerirPedido() {
    const resp_dist: any = await this.rest.getJWT(`distribuidor/${this.auth.user_distribuidor?._id}`).toPromise();
    if (!resp_dist) {
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'No se pudo recuperar la información para consolidar y validar el pedido.';
      modalRef.componentInstance.btn_msg = 'Volver';
      return;
    }
    this.valor_minimo = resp_dist?.valor_minimo_pedido;
    if (this.productos.length == 0) {
      //Debe haber al menos un producto en el carrito
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Recuerda que debe haber al menos un producto para poder sugerir el pedido.';
      modalRef.componentInstance.btn_msg = 'Volver';
      return;
    } else if (this.total < this.valor_minimo) {
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'El total del pedido debe ser superior al valor mínimo del pedido. Agrega más productos al carrito para poder sugerir este pedido.';
      modalRef.componentInstance.btn_msg = 'Volver';
      return;
    } else {
      const modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);

      try {
        let pts_ganados = 0;
        const prods: any[] = [];
        let i = 0;
        for (const aux of this.precios) {
          if (!this.productos[i].saldos && !this.productos[i].promocion) {
            pts_ganados += aux[0].puntos_ft_unidad * this.unidades[i];
          }
          prods.push({
            product: this.productos[i]._id,
            caja: this.cajas[i],
            unidad: this.unidades[i],
            referencia: this.precios[i][0]._id || '',
            puntos_ft_unidad: this.precios[i][0].puntos_ft_unidad || 0,
            puntos_ft_caja: this.precios[i][0].puntos_ft_caja || 0,
            data_precioProducto: this.precios[i][0] || {},
          });
          i++;
        }
        // Agrega el detalle de los productos para tabla intermedia para reportes
        const arrProducto: any[] = [];
        let resp_prod_2: any;
        let unidades_inventario = 0;
        let unidades_pedido = 0;

        for (const prod_aux of prods) {
          resp_prod_2 = await this.rest.getJWT(`producto/${prod_aux.product}`).toPromise();
          arrProducto.push(resp_prod_2);
          unidades_inventario = resp_prod_2.precios[0].inventario_unidad;
          unidades_pedido = prod_aux.unidad;
          if (unidades_pedido > unidades_inventario) {
            modalCarga.close();
            const modalRef2 = this.modalService.open(SimpleComponent, this.ngbModalOptions);
            modalRef2.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
            modalRef2.componentInstance.title = '¡Oh oh!';
            modalRef2.componentInstance.msg = `No hay suficientes unidades de ${resp_prod_2.nombre} para completar el pedido. Por favor cambia el pedido e intenta de nuevo`;
            modalRef2.componentInstance.btn_msg = 'Volver';
            return;
          }
        }

        const resp_prod: any = await this.rest
          .postJWT('pedido', {
            listProductos: arrProducto,
            puntoEntrega: this.punto_entrega,
            tipoNegocioUser: this.cliente ? this.cliente.tipo_negocio : '',
            usuario_horeca: this.cliente?._id || '',
            punto_entrega: this.punto_entrega?._id || '',
            trabajador: this.auth.user?._id || '',
            distribuidor: this.auth.user_distribuidor?._id || '',
            fecha: new Date(),
            estado: 'Sugerido',
            ciudad: this.punto_entrega?.ciudad,
            direccion: this.punto_entrega?.direccion,
            total_pedido: this.total,
            subtotal_pedido: this.subtotal,
            descuento_pedido: 0,
            puntos_ganados: pts_ganados,
            puntos_redimidos: 0,
            tiempo_estimado_entrega: this.auth.user_distribuidor?.tiempo_entrega,
            tiempo_tracking_hora: new Date(),
            productos: prods,
            metodo_pago: 'Prueba',
            codigo_descuento: [],
          })
          .toPromise();

        modalCarga.close();

        this.productos = [];
        this.precios = [];
        this.fotos = [];
        this.unidades = [];
        this.cajas = [];
        this.subtotal = 0;
        this.total = 0;
        this.valor_1pt = 0;
        this.valor_minimo = 0;

        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../../assets/img/icon-check-verde.png';
        modalRef.componentInstance.title = '¡Genial!';
        modalRef.componentInstance.msg = 'El pedido fue sugerido al cliente con éxito.';
        modalRef.componentInstance.btn_msg = 'Listo';
        modalRef.componentInstance.close_callback = () => {
          window.location.reload();
          this.notficarTrabPuntoHoreca(resp_prod.pedido.id_pedido);
        };
      } catch (err) {
        console.log(err);
        modalCarga.close();

        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'Ocurrió un error cargando el pedido al sistema. Intenta de nuevo más tarde.';
        modalRef.componentInstance.btn_msg = 'Volver';
      }
    }
  }

  /**
   * Notficia a los trabajadores del punto de entrega
   * que un pedido fue sugerido por el distribuidor
   * @param id_pedido
   */
  notficarTrabPuntoHoreca(id_pedido: any) {
    const mensaje = {
      mensaje:
        'El distribuidor ' + this.auth.user_distribuidor?.nombre + ' ha sugerido el pedido con código ' + id_pedido,
      punto_entrega: this.punto_entrega?._id,
      tipo: 'Actualización de pedido',
    };
    this.rest.putJWT(`notificar/trabajadores/horeca/generico`, mensaje).toPromise();
  }

  limpiarCarritoAlternativo() {
    this.productos = [];
    this.precios = [];
    this.fotos = [];
    this.unidades = [];
    this.cajas = [];
    this.subtotal = 0;
    this.total = 0;
    this.valor_1pt = 0;
    this.valor_minimo = 0;
  }
}
