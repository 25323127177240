import { CurrencyPipe } from '@angular/common';
import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbDateStruct, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Producto } from 'src/app/models/producto.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { CargandoGenericoComponent } from '../cargando-generico/cargando-generico.component';
import { SimpleComponent } from '../simple/simple.component';
import { ConfirmacionComponent } from '../confirmacion/confirmacion.component';

@Component({
  selector: 'app-editar-saldo',
  templateUrl: './editar-saldo.component.html',
  styleUrls: ['./editar-saldo.component.css'],
})
export class EditarSaldoComponent implements OnInit, DoCheck {
  /** Referencia a las funciones de Number para usar en el template */
  public number = Number;
  /** Referencias a iconos FontAwesome */
  public faTimes = faTimes;
  public faChevronDown = faChevronDown;
  /** Vars para manejar la selección de fecha */
  public model?: NgbDateStruct;
  public date: Date = new Date();
  /** Maxima cantidad de unidades disponibles */
  public max_inv = 0;
  /** Flag para levantar error en el template */
  public err_max_unidades = false;
  /** Flag para indicar que se puede usar el boton de editar saldo */
  public editar_saldo_enabled = false;
  /** Precio saldo de la promoción */
  public precio_saldo: any;
  /** Biding. Información del producto a mostrar */
  @Input() public producto!: Producto;
  @Input() public precios: any[] = []; //Para usar en la info del producto
  @Input() public precios_form: any[] = []; //Para usar en el form
  @Input() public fotos: string[] = [];
  @Input() selfCreados: any;

  constructor(
    public activeModal: NgbActiveModal,
    public auth: AuthService,
    private modalService: NgbModal,
    private productsService: ProductsService,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    /** Arma la data a mostrar en el DOM */
    this.max_inv = this.producto?.precios?.[0].inventario_unidad || 0;
    this.precio_saldo = this.precios_form[0].precio_descuento;
    const date = new Date(this.producto.fecha_vencimiento!);
    this.model = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  ngDoCheck(): void {
    /**
     * Revisa que las unidades no sean mayores a las que se pueden poner en saldo,
     * Si se supera, se devuelve al maximo y se levanta un mensaje de alerta
     */
    const max_inv_und = this.max_inv;
    if (this.precios_form[0].inventario_unidad >= max_inv_und) {
      this.precios_form[0].inventario_unidad = max_inv_und;
      this.err_max_unidades = true;
    } else {
      this.err_max_unidades = false;
    }
    /** Actualiza número de cajas en saldo */
    if (this.precios_form[0].und_x_caja > 0) {
      this.precios_form[0].inventario_caja =
        Math.round((this.precios_form[0].inventario_unidad / this.precios_form[0].und_x_caja) * 100) / 100;
    } else {
      this.precios_form[0].inventario_caja = 0;
    }
    /** Revisa que el formulario esté completo y válido */
    if (
      this.model &&
      (this.precio_saldo > 0 || this.precio_saldo != '') &&
      this.precios_form[0].precio_unidad &&
      this.producto.codigo_promo !== ''
    ) {
      this.editar_saldo_enabled = true;
    } else {
      this.editar_saldo_enabled = false;
    }
  }

  /**
   * Intenta la actualización del saldo del producto
   * Si funciona, se muestra un modal con esa información y se vuelve a cargar
   * la información del componente de saldos y promociones
   * Si no funciona, se muestra un error y no se cierra el modal de editar saldo
   */
  public actualizarProductoSaldo() {
    if (this.producto && this.producto._id) {
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        centered: true,
      };
      let modalRef = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
      /**Actualizar el valor de la fecha */
      if (this.model) {
        this.date.setFullYear(this.model.year);
        this.date.setMonth(this.model.month - 1);
        this.date.setDate(this.model.day);
      }
      /** Corrige el formato del precio */
      this.precios_form[0].precio_descuento = this.precio_saldo.toString().replace(/[^\d,-]/g, '');
      /** Data a actualizar en la consulta */
      const productoUpdate: any = {};
      productoUpdate.precios = this.precios_form;
      productoUpdate.fecha_vencimiento = this.date;
      productoUpdate.codigo_promo = this.producto.codigo_promo;
      productoUpdate.saldos = true;
      this.productsService.updateProducto(this.producto._id, productoUpdate).subscribe(
        (res) => {
          modalRef.close();
          this.activeModal.close();
          modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          modalRef.componentInstance.msg = '¡El saldo fue actualizado con éxito!';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            this.selfCreados.fetchData();
            this.activeModal.close();
          };
        },
        (err) => {
          console.log(err);
          modalRef.close();
          modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg =
            'Ocurrió un error en la actualización del saldo. Por favor intenta de nuevo más tarde.';
          modalRef.componentInstance.btn_msg = 'Volver';
        }
      );
    }
  }

  /**************************************** Validadores ****************************************/
  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateNumber(evento: any) {
    const keyCode = evento.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || excludedKeys.includes(keyCode))) {
      evento.preventDefault();
    }
  }

  /**
   * Transforma el dinero minimo de compra de número a moneda y viceversa
   * a moneda se utilizará para reemplazar el input y dar mejor UX al usuario
   * y a número plano para guardar el dato correctamente en la base de datos
   * ademas de poder editar mejor el valor en el input
   */
  public transformCurrency(event: any) {
    this.precio_saldo = event.value.replace(/[^\d,-]/g, '');
  }
  public transformAmount(event: any) {
    this.precio_saldo = this.currencyPipe.transform(event.value, '$ ', 'symbol', '1.0-0');
  }

  activarInactivarSaldo(opcion: string) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    const modalRef = this.modalService.open(ConfirmacionComponent, ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.msg = `¿Estás seguro que deseas ${opcion} este saldo?${
      opcion === 'desactivar' ? ' De hacerlo, podrás volver a activarlo' : ''
    }`;
    modalRef.componentInstance.btn_msg_no = 'Cancelar';
    modalRef.componentInstance.btn_msg_yes = `Si, ${opcion}`;
    modalRef.componentInstance.callback_no = () => {};
    modalRef.componentInstance.callback_yes = () => {
      const body = {
        estadoActualizacion: opcion === 'desactivar' ? 'Inactivo' : 'Aceptado',
      };
      this.productsService
        .updateProducto(this.producto._id ? this.producto._id : '', body)
        .toPromise()
        .then(() => {
          modalRef.close();
          const modalRefResp = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRefResp.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
          modalRefResp.componentInstance.title = '¡Genial!';
          modalRefResp.componentInstance.msg = `¡Tu saldo ha sido ${
            opcion === 'desactivar' ? 'desactivado' : 'Activado'
          } con éxito!`;
          modalRefResp.componentInstance.btn_msg = 'Listo';
          modalRefResp.componentInstance.close_callback = () => {
            this.selfCreados.fetchData();
            this.activeModal.close();
          };
        });
    };
  }

  cerrarModal() {
    this.activeModal.close();
  }
}
