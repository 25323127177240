<app-header-horeca></app-header-horeca>
<div id="content" class="p-0">
  <div class="row p-5">
    <div class="col-12">
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills">
        <li [ngbNavItem]="1">
          <a ngbNavLink>Pedidos en curso</a>
          <ng-template ngbNavContent>
            <app-pedidos-en-curso></app-pedidos-en-curso>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink>Historial</a>
          <ng-template ngbNavContent>
            <app-historial-pedidos></app-historial-pedidos>
          </ng-template>
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink>Gráficas</a>
          <ng-template ngbNavContent>
            <app-graficas-pedidos class="d-flex justify-content-center"></app-graficas-pedidos>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</div>
