import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { RutasService } from '../../../services/tools/rutas.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-expandir-plan-org',
  templateUrl: './expandir-plan-org.component.html',
  styleUrls: ['./expandir-plan-org.component.css'],
})
export class ExpandirPlanOrgComponent implements OnInit {
  // Iconos
  public faChevronLeft = faChevronLeft;
  // Id organización
  public organizacion_id: any = this.ngxsStore.snapshot().auth.user.organizacion._id;
  public organizacion_correo: any = this.ngxsStore.snapshot().auth.user.correo;
  public organizacion_nombre: any = this.ngxsStore.snapshot().auth.user_organizacion.nombre;
  public organizacion_plan_id: any = this.ngxsStore.snapshot().auth.user_organizacion.tipo_plan;
  public organizacion_plan_nombre: any;
  // Planes disponibles
  public planes_organizacion: any;
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
  };
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;

  constructor(
    private ngxsStore: Store,
    private router: Router,
    private rutaservice: RutasService,
    public modalService: NgbModal,
    public rest: RestService
  ) {}

  ngOnInit(): void {
    this.getDataTablaMesActual();
  }

  /**
   * Recupera toda la data de las tablas de mes actual
   * viene la data de los tres filtros en la misma consulta
   */
  public async getDataTablaMesActual() {
    await this.rest
      .getJWT(`planes-membresias/activos/organizacion`)
      .toPromise()
      .then((resp: any) => {
        this.planes_organizacion = resp.sort((a: any, b: any) => parseInt(a.hasta) - parseInt(b.hasta));
        this.planes_organizacion = this.planes_organizacion.sort(
          (a: any, b: any) => parseInt(a.desde) - parseInt(b.desde)
        );
        this.organizacion_plan_nombre = this.planes_organizacion.find((plan: any) => {
          return plan._id == this.organizacion_plan_id;
        });
        if (this.organizacion_plan_nombre) {
          this.organizacion_plan_nombre = this.organizacion_plan_nombre.nombre;
        }
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
  }
  /**
   * Envia mail de solicitud a admin Feat
   */
  enviarEmail(tipo_plan: string) {
    // Objeto mensaje
    const now: Date = new Date();
    const obj_aux: any = {
      asunto: 'Solicitud plan para una organización - ' + this.organizacion_nombre + ' - Plan: ' + tipo_plan,
      destinatario: this.organizacion_correo,
      mensaje: `¡Hola! Soy parte de la organización ${this.organizacion_nombre} y quiero solicitar un cambio de plan. Actualmente tengo ${this.organizacion_plan_nombre} y queremos tener el plan de ${tipo_plan}.`,
      url: '',
      fecha: `${now.getFullYear}-${now.getMonth}-${now.getDate}`,
      tipo_mensaje: 'Solicitud plan organización',
    };
    // Si no tiene plan aún cambia el mensaje
    if (this.organizacion_plan_nombre == undefined || this.organizacion_plan_nombre == null) {
      obj_aux.mensaje = `¡Hola! Soy parte de la organización ${this.organizacion_nombre} y quiero solicitar asociarme al plan de ${tipo_plan}.`;
    }
    this.rest
      .post('correo/enviar-plan-organizacion', obj_aux)
      .toPromise()
      .then(() => {
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
        modalRef.componentInstance.title = '¡Genial!';
        modalRef.componentInstance.msg =
          'Tu solicitud de cambio de plan fue enviada para la aprobación de Feat, te estaremos avisando por correo para que puedas realizar el pago correspondiente.';
        modalRef.componentInstance.btn_msg = 'Listo';
        modalRef.componentInstance.close_callback = () => {
          window.location.reload();
        };
      })
      .catch(() => {
        this.mostrarMensajeError();
      });
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  public mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio-organizacion']);
    };
  }
}
