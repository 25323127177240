export class Producto {
  constructor(
    public nombre: string,
    public descripcion: string,
    public fotos?: string[],
    public precios?: {
      unidad_medida?: string;
      cantidad_medida?: string;
      estado?: string;
      precio_unidad?: number;
      precio_caja?: number;
      precio_descuento?: number;
      inventario_unidad?: any;
      inventario_caja?: number;
      puntos_ft_unidad?: number;
      puntos_ft_caja?: number;
      und_x_caja?: number;
    }[],
    public fecha_vencimiento?: Date,
    public fecha_cierre_puntosft?: Date,
    public promocion?: boolean,
    public saldos?: boolean,
    public codigo_ft?: string,
    public codigo_distribuidor?: string,
    public codigo_organizacion?: string,
    public marca_producto?: string,
    public categoria_producto?: string,
    public linea_producto?: string,
    public _id?: string,
    public nombre_distribuidor?: string,
    public productos_promocion?: string[],
    public codigo_promo?: string,
    public fecha_apertura_puntosft?: Date,
    public codigo_distribuidor_producto?: string,
    public estadoActualizacion?: string,
    public codigo_organizacion_producto?: string,
    public productos_promocion_inventario_unidades?: number[],
    public mostrarPF?: boolean
  ) {}
}
