import { Component, OnInit } from '@angular/core';
import { faShoppingCart, faBars, faUser } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../services/auth/auth.service';
import { RestService } from '../services/rest/rest.service';

@Component({
  selector: 'app-header-distribuidor',
  templateUrl: './header-distribuidor.component.html',
  styleUrls: ['./header-distribuidor.component.css'],
})
export class HeaderDistribuidorComponent implements OnInit {
  /** Iconos FontAwesome para usar en el template */
  faShoppingCart = faShoppingCart;
  faUser = faUser;
  faBars = faBars;
  /** Indicadores */
  public indicadores: any;

  constructor(public authService: AuthService, public restService: RestService) {}

  public isMenuCollapsed = true;

  ngOnInit(): void {
    /**
     * Devuelve los indicadores del distribuidor
     */
    const idDistribuidor = this.authService.user_distribuidor?._id || '';
    const idTrabajador = this.authService.user?._id || '';
    this.restService
      .getJWT(`indicadores_home_distribuidor/${idDistribuidor}/${idTrabajador}`)
      .toPromise()
      .then((resp: any) => {
        this.indicadores = resp;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public verDocumento(link: any) {
    window.open(link, '_blank');
  }
}
