import { Component, OnInit } from '@angular/core';
import { faShoppingCart, faBars, faUser } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../services/auth/auth.service';
import { CarritoService } from '../services/carrito/carrito.service';

@Component({
  selector: 'app-header-horeca',
  templateUrl: './header-horeca.component.html',
  styleUrls: ['./header-horeca.component.css'],
})
export class HeaderHorecaComponent implements OnInit {
  /** Iconos FontAwesome para usar en el template */
  faShoppingCart = faShoppingCart;
  faUser = faUser;
  faBars = faBars;

  productcounter = 0;
  blockUserModule: boolean | undefined;

  constructor(private cartservice: CarritoService, private auth: AuthService) {
    this.cartservice.orderNext();
    this.cartservice.cart$.subscribe(async (productos) => {
      this.productcounter = productos.length;
    });
    this.validatePermissions();
  }

  public isMenuCollapsed = true;

  ngOnInit(): void {}
  async validatePermissions() {
    // eslint-disable-next-line prefer-const
    let blockUser = await this.auth.validatePermissionsUser();
    this.blockUserModule = blockUser;
  }
  public verDocumento(link: any) {
    window.open(link, '_blank');
  }
}
