<div class="container-fluid fondo d-flex flex-column justify-content-center align-items-center overflow-hidden">
    <div class="row">
        <div class="col text-center mt-lg-5">
            <h1>Conoce más de nosotros</h1>
        </div>
    </div>
    <div class="d-flex scroll container mt-5">
        <div class="mr-3 cursorPointer" *ngFor="let blog of banners" (click)="openBlog(blog)">
            <img src="{{blog.imagen}}" class="img-org" alt="banner de {{blog.titulo}}">
        </div>
    </div>
</div>