import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-especificacion-feat',
  templateUrl: './especificacion-feat.component.html',
  styleUrls: ['./especificacion-feat.component.css'],
})
export class EspecificacionFeatComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
