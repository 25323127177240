export class UsuarioHoreca {
  /**
   * Los parámetros requeridos son los que tienen en común tanto el usuario
   * de tipo propietario como el usuario de tipo trabajador
   */
  constructor(
    public nombres: string,
    public apellidos: string,
    public correo: string,
    public pais: string,
    public departamento: string,
    public ciudad: string,
    public tipo_documento: string,
    public numero_documento: number,
    public clave: string,
    public telefono: number | undefined,
    public celular: number,
    public tipo_aprobador: string,
    public nombre_establecimiento: string,
    public empresa_pais: string,
    public empresa_departamento: string,
    public empresa_ciudad: string,
    public empresa_telefono: number,
    public empresa_telefono2: number | undefined,
    public propietario_tipo_documento: string,
    public propietario_numero_documento: string,
    public propietario_nombres: string,
    public propietario_apellidos: string,
    public propietario_telefono: number,
    public propietario_correo: string,
    public logo: string,
    public solicitud_vinculacion?: string,
    public rol?: string,
    public razon_social?: string,
    public nit?: string,
    public tipo_usuario?: string,
    public tipo_negocio?: string,
    public estado?: string,
    public _id?: string
  ) {}
}
