import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-comparando-distribuidores',
  templateUrl: './comparando-distribuidores.component.html',
  styleUrls: ['./comparando-distribuidores.component.css'],
})
export class ComparandoDistribuidoresComponent implements OnInit {
  @Input() img_src = '';
  @Input() title = '';
  @Input() msg = '';
  @Input() btn_msg = '';
  @Input() dataResult: any;
  @Input() close_callback = (data: any) => {};

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    console.log('Resultado modelo', this.img_src, this.title, this.msg, this.btn_msg, this.dataResult);
  }

  onClose(result: any) {
    this.activeModal.close('Close click');
    this.close_callback(result);
  }
}
