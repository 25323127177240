import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  private headers = new HttpHeaders({ Authorization: this.authService.token });

  // Servicio para traer productos filtrados por el id
  public getFilteredProducts(id: any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/producto/${id}`, {
      headers: this.headers,
    });
  }
  public deleteProduct(api: string): Observable<any> {
    return this.http.delete<any>(`${environment.backendUrl}/${api}`, {
      headers: this.headers,
    });
  }
  public getProducts(): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/producto/`, {
      headers: this.headers,
    });
  }

  /** Servicio para actualizar el producto
   * @param id identificador del producto
   * @param data datos del producto
   */
  public updateProducto(id: string, data: any): Observable<any> {
    return this.http.put<any>(`${environment.backendUrl}/api/producto/${id}`, data, {
      headers: this.headers,
    });
  }
}
