import { AfterViewInit, Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RutasService } from './services/tools/rutas.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements AfterViewInit {
  /* Urls de pantalla completa */
  urlPantallaCompleta = [''];
  pantallaCompleta = false;

  /* Urls que no incluyen footer */
  noFooterUrl = ['/registro/horeca', '/registro/distribuidor', '/registro'];
  noFooter = false;

  constructor(private rutaservice: RutasService, private route: Router) {
    /*
     * Esta funcion tiene como objetivo identificar en que url se encuentra la app
     */
    this.route.events.subscribe((val) => {
      this.pantallaCompleta = this.urlPantallaCompleta.includes(this.route.url.toString());
      this.noFooter = this.noFooterUrl.includes(this.route.url.toString());
    });
  }

  title = 'feat-frontend';
  ngAfterViewInit(): void {}
}
