<app-header-horeca></app-header-horeca>
<div class="container-fluid my-5">
  <div class="container-nav">
    <div>
      <button class="button-volver" routerLink="/Inicio">
        <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
        Volver
      </button>
      <span class="titulo-noticias">Saldos y promociones!</span>
    </div>
    <div class="punto-entrega-container">
      <div>
        <div>
          <img src="../../assets/img/icons/icon-pin-map.png" alt="Icono de un pin para señalar un sitio en un mapa"
            srcset="" />
        </div>
        <span class="text-left" id="point">Punto de entrega:
          <span>{{
            punto_entrega?.nombre || "Selecciona un punto"
            }}</span></span>
        <span (click)="selecionarPunto()">Cambiar</span>
      </div>
    </div>
  </div>
  <div class="content">
    <div>
      <p class="welcome">
        Explora tus distribuidores con opciones especiales para ti
      </p>
    </div>
    <div class="filters">
      <div class="buscador-container">
        <div class="buscador">
          <div>
            <fa-icon [icon]="faSearch" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
            <input [(ngModel)]="searchTerm" type="text" placeholder="Busca el nombre de un distribuidor"
              (keyup.enter)="fetchFilteredDistribuidores()" />
            <fa-icon *ngIf="searchTerm != ''" [icon]="faTimes" [styles]="{ color: '#41403E' }" id="fa-icon"
              (click)="clearSearch()"></fa-icon>
            <button (click)="fetchFilteredDistribuidores()" class="btn_buscar">
              Buscar
            </button>
          </div>
        </div>
      </div>
      <div class="menu-filter">
        <div class="bar-menu">
          <button class="min-filters" *ngFor="let item of filters">
            <img *ngIf="item.img" class="logos-filters" src="{{ item.img }}" alt="iconos de categorias" />
            <span>{{ item.name }}</span>
          </button>
        </div>
      </div>
      <div class="section-filter" (click)="onFiltrar()">
        <button>
          <fa-icon [icon]="faFilter" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
          <span>Filtrar</span>
        </button>
      </div>
    </div>
    <div class="content-cards">
      <div class="bar-card" *ngIf="distribuidores && distribuidores.length >= 1">
        <div class="cards cursorPointer" *ngFor="let item of distribuidores"
          (click)="verDistribuidor(item.data_distribuidor[0]._id)">
          <div class="cards-header d-flex flex-row justify-content-between align-content-center align-items-center">
            <div class="header-state m-0">
              <fa-icon [icon]="faCircle" size="lg" [styles]="{ color: '#97AE22' }" *ngIf="item.pazysalvo === true ">
              </fa-icon>
              <fa-icon [icon]="faCircle" size="lg" [styles]="{ color: '#D38989' }" *ngIf="item.pazysalvo === false ">
              </fa-icon>
              <span class="ml-2">{{item.pazysalvo === true ? 'A paz y saldo' : 'Pago pendiente' }}</span>
            </div>
            <div class="header-state m-0">
              <button class="btn-card" *ngIf="item.convenio === true">$ Convenio</button>
            </div>
          </div>
          <div class="border-bottom"></div>
          <div class="card-content">
            <div>
              <img class="img-card" src={{item.data_distribuidor[0].logo}} alt="">
            </div>
            <div class="body-content d-flex flex-column align-items-between">
              <div>{{item.data_distribuidor[0].nombre}}</div>
              <div class="type px-2 py-1 my-2">{{item.data_distribuidor[0].tipo}}</div>
              <div>
                {{item.data_distribuidor[0].total_pedidos}} compra{{item.data_distribuidor[0].total_pedidos == 1 ? '' :
                's'}} realizada{{item.data_distribuidor[0].total_pedidos == 1 ? '' : 's'}}
              </div>
            </div>
            <div>
              <fa-icon [icon]="faStar" size="lg" [styles]="{ color: '#41403E' }">
              </fa-icon>
              <span class="ml-2">{{item.data_distribuidor[0].ranking}}</span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!distribuidores || distribuidores.length < 1">
        <section class="d-flex flex-column justify-conent-center align-items-center">
          <img src="../../assets/img/icon-warning-amarillo.png" class="col-lg-3 col-md-4 col-sm-6 col-10"
            alt="Icono de advertencia" />
          <span>Nada de nada</span>
          <span>No tienes aún saldos y promociones</span>
        </section>
      </div>
    </div>
  </div>
</div>