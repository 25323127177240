import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCarousel, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Chart } from 'chart.js';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GraficasService } from 'src/app/services/graficas-tablas/graficas.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-inicio-informes-dist',
  templateUrl: './inicio-informes-dist.component.html',
  styleUrls: ['./inicio-informes-dist.component.css'],
})
export class InicioInformesDistComponent implements OnInit {
  // ID distribuidor
  public id_distribuidor = this.authService.user_distribuidor!._id;
  // Carousel bienvenida
  @ViewChild('carousel', { static: true }) carousel!: NgbCarousel;
  // Biding HTML-Canvas y TS
  @ViewChild('bar_establecimiento_negocio', { static: true })
  private bar_establecimiento_negocio!: ElementRef;
  @ViewChild('bar_establecimiento_categoria', { static: true })
  private bar_establecimiento_categoria!: ElementRef;
  @ViewChild('bar_sillas_ciudad', { static: true })
  private bar_sillas_ciudad!: ElementRef;
  @ViewChild('bar_sillas_negocio', { static: true })
  private bar_sillas_negocio!: ElementRef;
  @ViewChild('bar_sillas_categoria', { static: true })
  private bar_sillas_categoria!: ElementRef;
  // Guarda datos de la talba y configuraciones
  private bar_chart_establecimiento_ciudad: any;
  private data_bar_establecimiento_negocio: any;
  private bar_chart_establecimiento_negocio: any;
  private data_bar_establecimiento_categoria: any;
  private bar_chart_establecimiento_categoria: any;
  private data_bar_sillas_ciudad: any;
  private bar_chart_sillas_ciudad: any;
  private data_bar_sillas_negocio: any;
  private bar_chart_sillas_negocio: any;
  private data_bar_sillas_categoria: any;
  private bar_chart_sillas_categoria: any;
  // Total grafica
  public total_chart_negocio = 0;
  public total_chart_categoria = 0;
  public total_chart_sillas_ciudad = 0;
  public total_chart_sillas_negocio = 0;
  public total_chart_sillas_categoria = 0;
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;

  constructor(
    public authService: AuthService,
    private rest: RestService,
    public localStorage: LocalStorageService,
    private modalService: NgbModal,
    private router: Router,
    private graficasService: GraficasService,
    private ngxsStore: Store
  ) {
    this.data_bar_establecimiento_negocio = {
      labels: [],
      datasets: [],
    };
    this.data_bar_establecimiento_categoria = {
      labels: [],
      datasets: [],
    };
    this.data_bar_sillas_ciudad = {
      labels: [],
      datasets: [],
    };
    this.data_bar_sillas_negocio = {
      labels: [],
      datasets: [],
    };
    this.data_bar_sillas_categoria = {
      labels: [],
      datasets: [],
    };
  }

  async ngOnInit() {
    this.id_distribuidor = await this.ngxsStore.snapshot().auth.user_distribuidor._id;
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    try {
      await this.getInformacionEstablecimientoNegocio();
      await this.getInformacionSillasCiudad();
      await this.getInformacionSillasNegocio();
      await this.getInformacionEstablecimientoCategoria();
      await this.getInformacionSillasCategoria();
      this.modalCarga?.close();
    } catch (error) {
      this.mostrarMensajeError();
    }
  }

  /**
   * Recupera la data de cada grafica y la construye
   */
  /***** Establecimientos por tipo de negocio *****/
  public async getInformacionEstablecimientoNegocio() {
    this.data_bar_establecimiento_negocio.labels = [];
    this.data_bar_establecimiento_negocio.datasets = [];
    await this.rest
      .getJWT('informes/distribuidor/home/establecimientos_x_tipos_negocio/' + this.id_distribuidor)
      .toPromise()
      .then((tipo_negocios: any) => {
        tipo_negocios.data.forEach((tipo_negocio: any) => {
          this.data_bar_establecimiento_negocio.labels.push(tipo_negocio._id);
          this.data_bar_establecimiento_negocio.datasets.push(tipo_negocio.total);
          this.total_chart_negocio = this.total_chart_negocio + tipo_negocio.total;
        });
        /** Se crea la grafica */
        this.bar_chart_establecimiento_negocio = this.graficasService.createBarChart(
          this.bar_establecimiento_negocio,
          this.data_bar_establecimiento_negocio,
          'Establecimientos por tipo de negocio',
          'Número de establecimientos',
          'Tipo de negocio'
        );
      })
      .catch((err) => {
        throw err;
      });
  }
  /***** Establecimientos por categoría *****/
  public async getInformacionEstablecimientoCategoria() {
    this.data_bar_establecimiento_categoria.labels = [];
    this.data_bar_establecimiento_categoria.datasets = [];
    await this.rest
      .getJWT('informes/distribuidor/home/categorias_x_establecimiento/' + this.id_distribuidor)
      .toPromise()
      .then((categorias: any) => {
        categorias.data.forEach((categoria: any) => {
          this.data_bar_establecimiento_categoria.labels.push(categoria.nombre);
          this.data_bar_establecimiento_categoria.datasets.push(categoria.total);
          this.total_chart_categoria = this.total_chart_categoria + categoria.total;
        });
        /** Se crea la grafica */
        this.bar_chart_establecimiento_categoria = this.graficasService.createBarChart(
          this.bar_establecimiento_categoria,
          this.data_bar_establecimiento_categoria,
          'Establecimientos por categoría',
          'Número de establecimientos',
          'Categorías'
        );
      })
      .catch((err) => {
        throw err;
      });
  }
  /***** Sillas por ciudad *****/
  public async getInformacionSillasCiudad() {
    this.data_bar_sillas_ciudad.labels = [];
    this.data_bar_sillas_ciudad.datasets = [];
    await this.rest
      .getJWT('informes/distribuidor/home/sillas_x_ciudad/' + this.id_distribuidor)
      .toPromise()
      .then((ciudades: any) => {
        ciudades.data.forEach((ciudad: any) => {
          this.data_bar_sillas_ciudad.labels.push(ciudad._id);
          this.data_bar_sillas_ciudad.datasets.push(ciudad.sillas);
          this.total_chart_sillas_ciudad = this.total_chart_sillas_ciudad + ciudad.sillas;
        });
        /** Se crea la grafica */
        this.bar_chart_sillas_ciudad = this.graficasService.createBarChart(
          this.bar_sillas_ciudad,
          this.data_bar_sillas_ciudad,
          'Sillas por ciudad',
          'Número de sillas',
          'Ciudad'
        );
      })
      .catch((err) => {
        throw err;
      });
  }
  /***** Sillas por tipo de negocio *****/
  public async getInformacionSillasNegocio() {
    this.data_bar_sillas_negocio.labels = [];
    this.data_bar_sillas_negocio.datasets = [];
    await this.rest
      .getJWT('informes/distribuidor/home/sillas_x_tipo_negocio/' + this.id_distribuidor)
      .toPromise()
      .then((tipos_negocios: any) => {
        tipos_negocios.data.forEach((tipos_negocio: any) => {
          this.data_bar_sillas_negocio.labels.push(tipos_negocio._id);
          this.data_bar_sillas_negocio.datasets.push(tipos_negocio.total);
          this.total_chart_sillas_negocio = this.total_chart_sillas_negocio + tipos_negocio.total;
        });
        /** Se crea la grafica */
        this.bar_chart_sillas_negocio = this.graficasService.createBarChart(
          this.bar_sillas_negocio,
          this.data_bar_sillas_negocio,
          'Sillas por tipo de negocio',
          'Número de sillas',
          'Tipo de negocio'
        );
      })
      .catch((err) => {
        throw err;
      });
  }
  /***** Sillas por categoría *****/
  public async getInformacionSillasCategoria() {
    this.data_bar_sillas_categoria.labels = [];
    this.data_bar_sillas_categoria.datasets = [];
    await this.rest
      .getJWT('informes/distribuidor/home/categorias_x_sillas/' + this.id_distribuidor)
      .toPromise()
      .then((categorias: any) => {
        if (categorias.data[0]?.cant_sillas) {
          categorias.data[0].cant_sillas.forEach((categoria: any) => {
            this.data_bar_sillas_categoria.labels.push(categoria.label);
            this.data_bar_sillas_categoria.datasets.push(categoria.count);
            this.total_chart_sillas_categoria = this.total_chart_sillas_categoria + categoria.count;
          });
          this.bar_chart_sillas_categoria = this.graficasService.createBarChart(
            this.bar_sillas_categoria,
            this.data_bar_sillas_categoria,
            'Sillas por categoría',
            'Número de sillas',
            'Categoría'
          );
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      // this.router.navigate(['/inicio-distribuidor']);
    };
  }
}
