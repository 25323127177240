<div id="content">
    <!-------------------- Pie Chart -------------------->
    <!-- Estado actual de pedidos -->
    <div class="card pedidos-estados">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-3">
                <div class="mb-3">Estado actual de pedidos</div>
                <div class="d-flex justify-content-between">
                    <span>Total: {{total_pedidos_estado}}</span>
                </div>
            </div>
            <canvas #pie_pedidos_estado [ngClass]="{'inactive': data_pie_pedidos_estado.datasets.length == 0}"></canvas>
            <div *ngIf="data_pie_pedidos_estado.datasets.length > 0 else mostrarError"></div>
        </div>
    </div>
    <!-- NÚMERO DE PEDIDOS CON PROGRAMA DE PUNTOS ( ÚLTIMOS TRES MESES) -->
    <div class="card pedidos-puntos">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-3">
                <div class="mb-3">Número de pedidos con programa de puntos (últimos tres meses)</div>
                <div class="d-flex justify-content-between">
                    <span>Total: {{total_usuarios_establecimientos}}</span>
                </div>
            </div>
            <canvas #bar_usuarios_establecimientos
                [ngClass]="{'inactive': data_bar_usuarios_establecimientos.datasets.length == 0}"></canvas>
            <div *ngIf="data_bar_usuarios_establecimientos.datasets.length > 0 else mostrarError"></div>
        </div>
    </div>
    <!-------------------- Bar Chart -------------------->
    <!-- Número de pedidos entregados por mes -->
    <div class="card pedidos-entregados">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-3">
                <div class="mb-3">Número de pedidos entregados por mes</div>
                <div class="d-flex justify-content-between">
                    <span>Total: {{total_pedidos_entregados}}</span>
                    <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_pedidos_entregados_ano"
                        (ngModelChange)="getChartPedidosEntregadosMes()">
                        <option [value]="ano" *ngFor="let ano of anos_filtro">
                            {{ano}}
                        </option>
                    </select>
                </div>
            </div>
            <canvas #bar_pedidos_entregados
                [ngClass]="{'inactive': data_bar_pedidos_entregados.datasets.length == 0}"></canvas>
            <div *ngIf="data_bar_pedidos_entregados.datasets.length > 0 else mostrarError"></div>
        </div>
    </div>
    <!-- Número de pedidos por tipo de negocio -->
    <div class="card pedidos-tipo">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-3">
                <div class="mb-3">Número de pedidos por tipo de negocio</div>
                <div class="d-flex justify-content-between">
                    <span>Total: {{total_pedidos_tipo_negocio}}</span>
                    <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_pedidos_tipo_negocio"
                        (ngModelChange)="getChartPedidosTipoNegocio()">
                        <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                            {{mes.filtro}}-{{mes.filtro_inicio}}
                        </option>
                    </select>
                </div>
            </div>
            <canvas #bar_pedidos_tipo_negocio
                [ngClass]="{'inactive': data_bar_pedidos_tipo_negocio.datasets.length == 0}"></canvas>
            <div *ngIf="data_bar_pedidos_tipo_negocio.datasets.length > 0 else mostrarError"></div>
        </div>
    </div>
    <!-- Número de pedidos por tipo de negocio -->
    <div class="card pedidos-establecimientos">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-3">
                <div class="mb-3">Establecimientos alcanzados por mes</div>
                <div class="d-flex justify-content-between">
                    <span>Total: {{total_pedidos_establecimientosAnio}}</span>
                    <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_pedidos_estblecimientos_anio"
                        (ngModelChange)="getChartPedidosEstblecimientos()">
                        <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                            {{mes.filtro}}-{{mes.filtro_inicio}}
                        </option>
                    </select>
                </div>
            </div>
            <canvas #bar_pedidos_establecimientos
                [ngClass]="{'inactive': data_bar_pedidos_establecimientos.datasets.length == 0}"></canvas>
            <div *ngIf="data_bar_pedidos_establecimientos.datasets.length > 0 else mostrarError"></div>
        </div>
    </div>
    <!-- Estado Actual de pedidos (tres meses) -->
    <div class="card pedidos-estado-mes">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-3">
                <div class="mb-3">Estado Actual de pedidos (tres meses)</div>
                <div class="d-flex justify-content-between">
                    <span>Total: {{total_pedidos_estado_mes}}</span>
                    <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_pedidos_estado_mes"
                        (ngModelChange)="getChartPedidosEstadoMes()">
                        <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                            {{mes.filtro}}-{{mes.filtro_inicio}}
                        </option>
                    </select>
                </div>
            </div>
            <canvas #bar_pedidos_estado_mes
                [ngClass]="{'inactive': data_bar_pedidos_estado_mes.datasets.length == 0}"></canvas>
            <div *ngIf="data_bar_pedidos_estado_mes.datasets.length > 0 else mostrarError"></div>
        </div>
    </div>
    <!-- Número de pedidos por ciudad -->
    <div class="card pedidos-ciudad">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between mb-3">
                <div class="mb-3">Número de pedidos por ciudad</div>
                <div class="d-flex justify-content-between">
                    <span>Total: {{total_pedidos_ciudad}}</span>
                    <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_pedidos_ciudad"
                        (ngModelChange)="getChartPedidosCiudad()">
                        <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                            {{mes.filtro}}-{{mes.filtro_inicio}}
                        </option>
                    </select>
                </div>
            </div>
            <canvas #bar_pedidos_ciudad [ngClass]="{'inactive': data_bar_pedidos_ciudad.datasets.length == 0}"></canvas>
            <div *ngIf="data_bar_pedidos_ciudad.datasets.length > 0 else mostrarError"></div>
        </div>
    </div>
    <!-- Manejo de errores -->
    <ng-template #mostrarError>
        <div class="d-flex justify-content-center mt-4">
            <div>
                <img src="assets/img/icon-warning-amarillo.png" alt="icono de una hoja de papel y una lupa sobre ella"
                    style="max-width: 150px;" />
            </div>
        </div>
        <div class="mt-2">
            <p class="text-center">
                ¡Lo siento! No existen datos para ver aquí
            </p>
        </div>
    </ng-template>
</div>