<button *ngIf="!err_prods_promo && prods_promos.length > 0" class="btn-purple mt-3 col-12 col-sm-8 col-md-4 col-xl-3"
  (click)="crearPromocion()">
  Crear promoción
</button>
<p *ngIf="err_prods_promo" style="color: red; margin-bottom: 0rem;">
  Recuerda que puedes tener máximo 3 productos en una promoción. Tienes {{ prods_promos.length }} productos
  seleccionados.
</p>
<br />
<div class="card">
  <div class="card-body scroll-tabla">
    <ng2-smart-table [settings]="settings" [source]="source" (rowSelect)="onRowSelect($event)" (userRowSelect)="onUserRowSelect($event)">
    </ng2-smart-table>
  </div>
</div>