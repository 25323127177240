<app-header-distribuidor *ngIf="tipo_usuario === 'distribuidor'"></app-header-distribuidor>
<app-header-organizacion *ngIf="tipo_usuario === 'organizacion'"></app-header-organizacion>
<div class="main-contenedor d-flex justify-content-center pt-2">
    <div class="contenedor w-100 h-100">
        <!--Header-->
        <div class="d-flex flex-wrap">
            <div class="col-12 col-md-6 d-flex flex-row p-0">
                <div>
                    <button class="boton-volver mr-4" (click)="volver()">
                        <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }"></fa-icon>
                        Volver
                    </button>
                </div>
                <div>
                    <h3>Chats disponibles</h3>
                </div>
            </div>
        </div>
        <br />
        <!-- Empresas asociadas -->
        <div class="row contenedor-conversaciones">
            <div class="container-top col-12 col-md-6 col-lg-5 h-100">
                <div class="h-100 card py-2">
                    <div class="scroll" id="scroll-todos">
                        <div class="card-mensaje">
                            <p class="p-3 m-0  border-bottom">
                                {{tipo_usuario == 'distribuidor' ?
                                'Organizaciones asociadas' : 'Distribuidores asociados'}}
                            </p>
                            <div class="card-mensaje" *ngIf="empresas_asociadas_chat.length > 0 else mostrarError">
                                <div *ngFor="let empresa of empresas_asociadas_chat">
                                    <div class="row m-0 p-3 cursorPointer" (click)="cargarNuevoRoom(empresa)"
                                        [ngClass]="{'conversacion-activa': (empresa.codigo_distribuidor == id_distribuidor && tipo_usuario == 'organizacion') || (empresa.codigo_organizacion == id_organizacion && tipo_usuario == 'distribuidor') }">
                                        <div class="col-2 p-0">
                                            <img src="{{empresa.logo || distribuidor_placeholder}}"
                                                class="icon-dist rounded-circle" alt="logo" />
                                        </div>
                                        <div class="row col-10 p-0 pl-3 m-0">
                                            <div class="col-12 m-0 p-0">
                                                <div class="w-100 d-flex justify-content-between">
                                                    <p class="m-0 col-8 p-0 ellipsis">
                                                        <span class="ellipsis">
                                                            {{ empresa.nombre }}
                                                        </span>
                                                    </p>
                                                    <span
                                                        class="col-4 p-0 text-right d-flex flex-row justify-content-between align-items-center">
                                                        <span class="ellipsis">{{empresa.ciudad}}</span>
                                                        <span
                                                            *ngIf="empresa.data_mensaje&& empresa.data_mensaje.ultimo_mensaje && empresa.data_mensaje.ultimo_mensaje.leido === false"
                                                            class="badge-no-leido"></span>
                                                        <span
                                                            *ngIf="empresa.data_mensaje&& empresa.data_mensaje.ultimo_mensaje && empresa.data_mensaje.ultimo_mensaje.leido === true"
                                                            class="badge-leido"></span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex flex-column justify-content-between p-0"
                                                *ngIf="empresa.data_mensaje && empresa.data_mensaje.ultimo_mensaje && !empresa.data_mensaje.ultimo_mensaje.mensaje.startsWith('https://feat-resources.s3') && !empresa.data_mensaje.ultimo_mensaje.mensaje.includes('ha iniciado el chat') else mostrarNoHayMensajes">
                                                <p class="fecha m-0 text-secondary">
                                                    {{empresa.data_mensaje.ultimo_mensaje.tiempo}}
                                                </p>
                                                {{empresa.data_mensaje.ultimo_mensaje.mensaje}}
                                            </div>
                                            <ng-template #mostrarNoHayMensajes>
                                                Inicia esta conversación
                                            </ng-template>
                                        </div>
                                    </div>
                                    <hr class="my-1" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Conversación-->
            <div class="col-12 col-md-6 col-lg-7 h-100">
                <div class="card">
                    <div class="card-body">
                        <!--Header-->
                        <div class="row m-0 mb-0 border-bottom pb-3" *ngIf="empresa_actual_chat !== undefined">
                            <div class="col-3 d-flex justify-content-center align-items-center p-0">
                                <div class="img-container d-flex justify-content-center align-items-center">
                                    <img src="{{empresa_actual_chat.logo || distribuidor_placeholder}}"
                                        class="icon-dist rounded-circle" alt="logo distribuidor" />
                                </div>
                            </div>
                            <div class="col-9 d-flex flex-column justify-content-between">
                                <div>
                                    <h5 class="m-0">
                                        {{empresa_actual_chat.nombre}}
                                    </h5>
                                </div>
                                <div>
                                </div>
                                <p class="m-0">
                                    {{empresa_actual_chat.departamento}} - {{empresa_actual_chat.ciudad }}
                                </p>
                            </div>
                        </div>
                        <!--Mensajes-->
                        <div class="row h-100" *ngIf="empresa_actual_chat !== undefined else mostrarError">
                            <div id="chat-mensajes-caja" class="col-12 caja-mensajes">
                                <div class="mensajes h-100" [innerHTML]="someHtmlCode"></div>
                            </div>
                        </div>
                        <hr />
                        <!--Acciones-->
                        <div class="row">
                            <div class="col-12">
                                <input type="text" [(ngModel)]="message" placeholder="Escribe aquí"
                                    (keydown.enter)="enviarMensaje()" />
                            </div>
                            <div class="col-12 d-flex justify-content-end align-items-center pt-3">
                                <div class="col-2 d-flex justify-content-end">
                                    <button class="btn-img" (click)="enviarImagen()">
                                        <fa-icon [icon]="faCamera" [styles]="{ color: '#8E6FF7' }">
                                        </fa-icon>
                                    </button>
                                </div>
                                <div class="offset-1 col-3 h-100 p-0">
                                    <button class="btn-enviar" (click)="enviarMensaje()">Enviar</button>
                                </div>
                                <div class="offset-1 col-4 h-100 p-0">
                                    <button class="btn-enviar" (click)="guardarConversacion()" (click)="volver();">
                                        Guardar y salir
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Manejo de errores -->
    <ng-template #mostrarError>
        <div class="d-flex justify-content-center mt-4">
            <div>
                <img src="assets/img/icon-warning-amarillo.png" alt="icono de una hoja de papel y una lupa sobre ella"
                    style="max-width: 150px;" />
            </div>
        </div>
        <div class="mt-2">
            <p class="text-center">
                ¡Lo siento! No existen datos para ver aquí
            </p>
        </div>
    </ng-template>
</div>