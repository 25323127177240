import { Component, OnInit } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { RestService } from 'src/app/services/rest/rest.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Store } from '@ngxs/store';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
@Component({
  selector: 'app-detalle-producto-distribuidores-organizacion',
  templateUrl: './detalle-producto-distribuidores-organizacion.component.html',
  styleUrls: ['./detalle-producto-distribuidores-organizacion.component.css'],
})
export class DetalleProductoDistribuidoresOrganizacionComponent implements OnInit {
  // Distribuidor de donde viene la consulta
  public id_distribuidor: any;
  // Organización
  public id_organizacion = this.auth.user_organizacion._id;
  public org: any;
  public organizacion_nombre = this.auth.user_organizacion.nombre;
  // Producto
  public id_producto: any;
  public producto: any;
  public foto_seleccionada: any;
  // Iconos
  public faChevronLeft = faChevronLeft;
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
  };

  constructor(
    private router: Router,
    private rest: RestService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private ngxsStore: Store,
    private auth: AuthService
  ) {}

  async ngOnInit() {
    this.id_organizacion = await this.ngxsStore.snapshot().auth.user_organizacion._id;
    this.organizacion_nombre = await this.ngxsStore.snapshot().auth.user_organizacion.nombre;
    this.route.params.subscribe(async (params) => {
      this.id_producto = params.id;
      this.org = params.org;
      this.id_distribuidor = params.idDist;
    });
    this.getProducto();
  }

  /**
   * Consulta la información del producto a editar. Si no encuentra el producto
   * u ocurre un error, redirige al usuario a la página de portafolio
   */
  getProducto() {
    this.rest
      .getJWT(`producto/${this.id_producto}/organizacion/${this.id_organizacion}/datos_promedios/`)
      .toPromise()
      .then((producto: any) => {
        this.producto = producto[0];
        this.foto_seleccionada = producto[0]?.fotos[0];
      })
      .catch((err: any) => {
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg =
          'No fue posible recuperar la información de este producto. Por favor intenta de nuevo más tarde';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigate(['portafolio-distribuidor-organizacion/' + this.id_distribuidor]);
        };
      });
  }
}
