<div class="contenedor">
  <div class="boton-crear col-12 d-flex justify-content-end p-0">
    <button class="new-button" (click)="crearPuntoDeEntrega()">
      Crear un nuevo punto
    </button>
  </div>
  <!-- Puntos Activos -->
  <div class="mt-2">
    <p class="m-0">Tus puntos de entrega ({{ puntos_entrega.length }})</p>
  </div>
  <div class="carousel-container">
    <div class="card-container pb-3">
      <div class="card" *ngFor="let punto_entrega of puntos_entrega; index as i;"
        [routerLink]="'/cuenta/punto/' + punto_entrega._id" style="cursor: pointer;">
        <p class="pl-1 d-flex justify-content-between m-0">
          {{ punto_entrega.nombre }}
          <fa-icon [icon]="faCircle" size="sm" [styles]="{'color': '#C7D389'}"> </fa-icon>
        </p>
        <span class="order-count px-3 py-1 m-0">{{ punto_entrega.pedidos }} Pedidos</span>
        <span>
          <img src="../../assets/img/icons/icon-pin-map.png" alt="icono de un pin para señalar un sitio en un mapa">
          &nbsp;
          {{ punto_entrega.direccion }}, {{ punto_entrega.ciudad }}
        </span>
        <span>
          <fa-icon [icon]="faClock" class="px-1"></fa-icon>
          &nbsp;
          Horario: {{ formatearHorario(punto_entrega.dias_atencion, punto_entrega.horario) }}
        </span>
      </div>
    </div>
  </div>
  <!-- Puntos Desactivados -->
  <div class="mt-4">
    <p class="m-0">Puntos de entrega Desactivados ({{ this.puntos_entrega_desactivados.length }})</p>
  </div>
  <div class="carousel-container">
    <div class="card-container pb-3">
      <div class="card" *ngFor="let punto_entrega_desactivado of this.puntos_entrega_desactivados; index as i;"
        [routerLink]="'/cuenta/punto/' + punto_entrega_desactivado._id" style="cursor: pointer;">
        <p class="pl-1 d-flex justify-content-between m-0">
          {{ punto_entrega_desactivado.nombre }}
          <fa-icon [icon]="faCircle" size="sm" [styles]="{'color': '#D38989'}"> </fa-icon>
        </p>
        <span class="order-count px-3 py-1 m-0">{{ punto_entrega_desactivado.pedidos }} Pedidos</span>
        <span>
          <img src="../../assets/img/icons/icon-pin-map.png" alt="icono de un pin para señalar un sitio en un mapa">
          &nbsp;
          {{ punto_entrega_desactivado.direccion }}, {{ punto_entrega_desactivado.ciudad }}
        </span>
        <span>
          <fa-icon [icon]="faClock" class="px-1"></fa-icon>
          &nbsp;
          Horario: {{ formatearHorario(punto_entrega_desactivado.dias_atencion, punto_entrega_desactivado.horario) }}
        </span>
      </div>
    </div>
  </div>
</div>