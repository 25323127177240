<div class="container-fluid fondo" [formGroup]="contactoForm">
  <div class="row">
    <div class="col text-center mt-5">
      <h1>No dudes en escribirnos</h1>
    </div>
  </div>
  <div class="d-flex justify-content-center mt-4">
    <div class="card-contact container">
      <div class="row">
        <div class="col mt-4">
          <span class="aviso-contacto">Te responderemos lo antes posible</span>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-md-6">
          *Nombre completo
          <input type="text" class="input-defecto mt-2 px-3" placeholder="Escribe aqui"
            formControlName="contactoName" />
          <span class="reactive-text text-danger"
            *ngIf="contactoForm.get('contactoName')?.hasError('required') && contactoForm.get('contactoName')?.touched">
            <span class="alerta">Requerido</span>
          </span>
        </div>
        <div class="col-12 col-md-6 mt-3 mt-md-0">
          Empresa
          <input type="text" class="input-defecto mt-2 px-3" placeholder="Escribe aqui"
            formControlName="contactoEmpresa" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-md-6">
          *Email
          <input type="text" class="input-defecto mt-2 px-3" placeholder="Escribe aqui"
            formControlName="contactoEmail" />
          <span class="reactive-text text-danger"
            *ngIf="contactoForm.get('contactoEmail')?.hasError('required') && contactoForm.get('contactoEmail')?.touched">
            <span class="alerta">Requerido</span>
          </span>
          <span class="reactive-text text-danger "
            *ngIf="contactoForm.get('contactoEmail')?.hasError('email') && contactoForm.get('contactoEmail')?.touched">
            <span class="alerta">Ingresa un email valido</span>
          </span>
        </div>
        <div class="col-12 col-md-6 mt-3 mt-md-0">
          *Número celular
          <input type="text" class="input-defecto mt-2 px-3" placeholder="Escribe aqui"
            formControlName="contactoCelular" />
          <span class="reactive-text text-danger"
            *ngIf="contactoForm.get('contactoCelular')?.hasError('required') && contactoForm.get('contactoCelular')?.touched">
            <span class="alerta">Requerido</span>
          </span>
          <span class="reactive-text text-danger"
            *ngIf="contactoForm.get('contactoCelular')?.hasError('pattern') && contactoForm.get('contactoCelular')?.touched">
            <span class="alerta">Ingrese un celular valido</span>
          </span>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <span>*Mensaje</span>
          <textarea #textContacto class="input-mensaje px-3 py-2 mt-2" formControlName="contactoMensaje" rows="3"
            placeholder="Escribe tu mensjae aquí" data-attr="2000" required="true" value="2000" maxlength="2000"
            (keypress)="valueChangeEmail(textContacto.value)"
            (keydown)="valueChangeEmail(textContacto.value)"></textarea>
        </div>
        <span class="col-6 text-left text-secondary alerta">{{remainingTextContacto}}
          <span>Caracteres restantes:</span>
        </span>
        <span class="col-6 text-danger fw-bold text-right"
          *ngIf="contactoForm.get('contactoMensaje')?.hasError('required') && contactoForm.get('contactoMensaje')?.touched">
          <span class="alerta">Requerido</span>
        </span>
        <span class="col-6 text-danger fw-bold text-right"
          *ngIf="contactoForm.get('contactoMensaje')?.hasError('maxlength') && contactoForm.get('contactoMensaje')?.touched">
          <span class="alerta">Maximo 2000 caracteres</span>
        </span>
      </div>
      <div class="mt-3">
        <div class="row px-3">
          <div class="col-1 d-flex justify-content-center align-items-center">
            <input type="checkbox" formControlName="contactoTerminos" />
          </div>
          <div class="pl-2 col-11 d-flex justify-content-start align-content-center">
            <p class="mt-2">
              *Acepto las <a class="link-terminos cursorPointer"
                (click)="verDocumento(politicas_condiciones[0].distribuidores.politicas)">Políticas de
                Privacidad</a>
            </p>
          </div>
          <span class="col-12 text-danger fw-bold text-right p-0"
            *ngIf="contactoForm.get('contactoTerminos')?.hasError('required') && contactoForm.get('contactoTerminos')?.touched">
            <span class="alerta">Debes aceptar los terminos</span>
          </span>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12  text-center mt-2">
          <button [disabled]="contactoForm.invalid" class="btn-enviar" (click)="enviarEmail()"
            [style.background-color]="contactoForm.invalid ? 'grey' : ''">Enviar</button>
        </div>
        <span class="col-12 text-secondary text-right mt-2 alerta">(*) Campos obligatorios</span>
      </div>
    </div>
  </div>
</div>