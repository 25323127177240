import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-middle-banner',
  templateUrl: './middle-banner.component.html',
  styleUrls: ['./middle-banner.component.css'],
})
export class MiddleBannerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
