<div class="container-fluid fondo">
  <div class="row">
    <div class="col text-center mt-5 mb-3">
      <h1 class="titulo">¿Cómo lo hacemos?</h1>
    </div>
  </div>
  <div class="row row-cols-1 row-cols-lg-2 d-flex mt-lg-4">
    <div class="col d-flex justify-content-center mb-3 mb-lg-5">
      <div class="card-especification container d-flex">
        <div>
          <img src="../../../assets/img/Landing/estrategia.png" class="img-especification mt-0 mt-lg-4"
            alt="Icono de un porta papeles con dibujos de estrategia pintados" />
        </div>
        <div class="mt-1 mt-lg-5">
          <span class="title-especification">Estrategia</span>
          <p class="mt-2 description-especification">Cuando la intención, los recursos y la acción encuentran su
            objetivo.</p>
        </div>
      </div>
    </div>
    <div class="col d-flex justify-content-center mb-3 mb-lg-5">
      <div class="card-especification container d-flex">
        <div>
          <img src="../../../assets/img/Landing/tecnologia.png" class="img-especification mt-0 mt-lg-4"
            alt="Cursor dando click a un circulo" />
        </div>
        <div class="mt-1 mt-lg-5">
          <span class="title-especification">Tecnología</span>
          <p class="mt-2 description-especification">Cuando todas nuestras necesidades se nos cumplen con tan solo un
            CLIC.</p>
        </div>
      </div>
    </div>
    <div class="col d-flex justify-content-center mb-3 mb-lg-5">
      <div class="card-especification container d-flex">
        <div>
          <img src="../../../assets/img/Landing/aliados.png" class="img-especification mt-0 mt-lg-4"
            alt="manos estrechandose" />
        </div>
        <div class="mt-1 mt-lg-5">
          <span class="title-especification">Aliados</span>
          <p class="mt-2 description-especification">Cuando juntos le ponemos la energía, sucede la sincronía.</p>
        </div>
      </div>
    </div>
    <div class="col d-flex justify-content-center mb-3 mb-lg-5">
      <div class="card-especification container d-flex">
        <div>
          <img src="../../../assets/img/Landing/Inteligencia-mercado.png" class="img-especification mt-0 mt-lg-4"
            alt="papel con graficas de estadistica" />
        </div>
        <div class="mt-1 mt-lg-5">
          <span class="title-especification">Inteligencia de mercado</span>
          <p class="mt-2 description-especification">Siempre será más eficiente y simple cuando todos tenemos la
            información correcta.</p>
        </div>
      </div>
    </div>
  </div>
</div>