import { Component, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { CargandoGenericoComponent } from '../cargando-generico/cargando-generico.component';
import { SimpleComponent } from '../simple/simple.component';

@Component({
  selector: 'app-pre-aprobar-cliente',
  templateUrl: './pre-aprobar-cliente.component.html',
  styleUrls: ['./pre-aprobar-cliente.component.css'],
})
export class PreAprobarClienteComponent implements OnInit {
  // Iconos
  public faTimes = faTimes;
  // Variables de inputs
  public documento_cliente = '';
  public invitar = false;
  public nombre_cliente = '';
  public correo_cliente = '';
  public empresa_seleccionada = '';
  // Modal de informacion para preaprobar
  public modalInfo?: NgbModalRef;
  // Empresas registradas
  public empresas_registradas: any;
  // Configuraciónn global para modales
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
  };

  constructor(
    public activeModal: NgbActiveModal,
    private auth: AuthService,
    private rest: RestService,
    private modalService: NgbModal,
    private distribuidoresService: DistribuidorService
  ) {}

  async ngOnInit() {
    await this.getUsuariosHoreca();
  }

  /**
   * Agrega el nit del cliente al array de preaprobados del distribuidor
   */
  public async preaprobar() {
    // TODO Hacer loaders y mensaje de error si el servidor arroja un error
    this.modalInfo = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    const checkMessage = this.checkData();
    if (!checkMessage) {
      try {
        const preaprobar = await this.distribuidoresService
          .preaprobarCliente(this.auth.user_distribuidor?._id || '', {
            documento: this.documento_cliente,
            invitar: this.invitar,
            nombre: this.nombre_cliente,
            correo: this.correo_cliente,
            correo_distribuidor: this.auth.user_distribuidor?.correo,
          })
          .toPromise();
        this.modalInfo?.close();
        this.close();
        this.showSuccess('El cliente fue preaprobado correctamente.');
      } catch (error: any) {
        this.modalInfo?.close();
        this.close();
        this.showError(error?.error?.message || 'Ocurrió un problema al preaprobar el cliente. Intenta más tarde.');
      }
    } else {
      this.modalInfo?.close();
      this.showError(checkMessage);
    }
  }

  public checkData(): string | undefined {
    if (!this.documento_cliente) {
      return 'Debes ingresar el documento del cliente.';
    }
    if (this.invitar) {
      if (!this.nombre_cliente) {
        return 'Para invitar, debes ingresar el nombre del cliente.';
      }
      if (!this.correo_cliente || !this.validateEmail(this.correo_cliente.toLowerCase())) {
        return 'Para invitar, debes ingresar un correo válidos del cliente.';
      }
    }

    return undefined;
  }

  /**
   * Recuperar la información de todas las empresas registradas en el sistema
   */
  getUsuariosHoreca() {
    this.rest
      .getJWT('usuario_horeca_reducidos/')
      .toPromise()
      .then((resp: any) => {
        this.empresas_registradas = resp.data;
      })
      .catch(() => {
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'Ocurrió un error recuperando la lista de empresas registradas con nosotros.';
        modalRef.componentInstance.btn_msg = 'Continuar';
      });
  }

  /**
   * Usa el input del NIT y busca el nombre del cliente
   */
  getEmpresaPorNit() {
    this.empresa_seleccionada = '';
    this.nombre_cliente = '';
    this.correo_cliente = '';
    if (this.documento_cliente && this.documento_cliente != '') {
      for (let index = 0; index < this.empresas_registradas.length; index++) {
        if (this.empresas_registradas[index].nit == this.documento_cliente) {
          this.empresa_seleccionada = this.empresas_registradas[index].nombre_establecimiento || '';
          this.nombre_cliente = this.empresas_registradas[index].nombre_establecimiento || '';
          this.correo_cliente = this.empresas_registradas[index].propietario_correo || '';
        }
      }
    }
  }

  public showError(msg: string | null | undefined) {
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = msg || 'Ocurrió un problema al preaprobar el cliente. Intenta más tarde.';
    modalRef.componentInstance.btn_msg = 'Volver';
  }

  public showSuccess(msg: string | null | undefined) {
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../../assets/img/icon-check-verde.png';
    modalRef.componentInstance.title = '¡Éxito!';
    modalRef.componentInstance.msg = msg || 'El cliente fue preaprobado correctamente.';
    modalRef.componentInstance.btn_msg = 'Volver';
  }

  private validateEmail(mail: string) {
    return /^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$/.test(mail);
  }

  /**Metodo para cerrar el modal */
  close() {
    this.activeModal.close();
  }
}
