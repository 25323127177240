<div class="card_prod rounded mt-2 mx-2">
  <div class="header_codigo p-2">SKU Distribuidor:<br>{{ producto?.codigo_distribuidor_producto || '' }}
  </div>
  <div class="row px-3 py-3">
    <p class="col-6 text-left sub-title">{{ producto?.precios[0]?.estado }}</p>
    <p class="col-6 text-right sub-title" *ngIf="producto?.flag_aplica_puntos_feat">
      {{ producto?.precios[0]?.puntos_ft_unidad }} puntos ft.
    </p>
  </div>
  <div class="indicators-relative">
    <div
      *ngIf="producto?.prodDescuento && producto?.prodPorcentajeDesc > 0"
      class="container-discount absolute-icons pos-discount">
      <span>-{{ producto.prodPorcentajeDesc }}%</span>
    </div>
    <div
      *ngIf="producto?.prodPedido"
      class="indicator indicator-on-order absolute-icons pos-on-order">
      <img class="align-img-product-indicator" src="./assets/img/icons/box-open.png" alt="Icon box" title="Encargo bajo pedido">
    </div>
    <div
      class="indicator indicator-bio absolute-icons pos-bio"
      *ngIf="producto?.prodBiodegradable">
      <img class="align-img-product-indicator" src="./assets/img/icons/recycle.png" alt="Icon box" title="Producto biodegradable">
    </div>
  </div>
  <div class="col-12 text-center pt-2">
    <img [src]="producto?.fotos[0] || '../../../assets/img/aceite.png'" class="img-prod" alt="aceite de cocina" />
  </div>
  <div class="col-12 text-left px-3 pb-2 pt-1">
    <p class="sub-title">{{ producto?.nombre }}</p>
  </div>
  <div class="row px-3" *ngIf="producto?.prodDescuento && producto?.prodPorcentajeDesc">
    <div class="col-6 text-left">
      <p class="sub-title color-previous-price">Precio ant.</p>
    </div>
    <div class="col-6 text-right">
      <p class="sub-title color-previous-price previous-line">{{ producto?.precios[0]?.precio_unidad | currency: 'COP':'symbol-narrow':'1.0-2' }}</p>
    </div>
  </div>
  <div class="row px-3">
    <div class="col-6 text-left">
      <p class="sub-title">Precio und.</p>
    </div>
    <div class="col-6 text-right">
      <h5 *ngIf="producto?.prodDescuento && producto?.prodPorcentajeDesc">
        {{ ((producto?.precios[0]?.precio_unidad - (producto?.precios[0]?.precio_unidad * (producto?.prodPorcentajeDesc / 100))) | currency: 'COP':'symbol-narrow':'1.0-0') }}
      </h5>
      <h5 *ngIf="!producto?.prodDescuento">{{ producto?.precios[0]?.precio_unidad | currency: 'COP':'symbol-narrow':'1.0-2' }}</h5>
    </div>
  </div>
  <div class="row px-3">
    <div class="col-6 text-left">
      <p class="sub-title">Precio {{ producto?.precios[0]?.unidad_medida }}</p>
    </div>
    <div class="col-6 text-right">
      <p class="sub-title" *ngIf="!producto?.prodDescuento">
        {{
        producto?.precios[0]?.precio_unidad / producto?.precios[0]?.cantidad_medida
        | currency: 'COP':'symbol-narrow':'1.0-2'
        }}
      </p>
      <p class="sub-title" *ngIf="producto?.prodDescuento && producto?.prodPorcentajeDesc">
        {{
          (producto?.precios[0]?.precio_unidad - (producto?.precios[0]?.precio_unidad * (producto?.prodPorcentajeDesc / 100))) / producto?.precios[0]?.cantidad_medida
          | currency: 'COP':'symbol-narrow':'1.0-2'
        }}
      </p>
    </div>
  </div>
  <div class="row px-3 pt-2" [ngClass]="{'separator-indicators': producto.prodPedido || producto.prodBiodegradable}">
    <div class="col-6 text-left">
      <p class="sub-title">Caja x{{ producto?.precios[0]?.und_x_caja }}</p>
    </div>
    <div class="col-6 text-right">
      <p *ngIf="!producto?.prodDescuento" class="sub-title">{{ producto?.precios[0]?.precio_caja | currency: 'COP':'symbol-narrow':'1.0-2' }}</p>
      <p *ngIf="producto?.prodDescuento && producto?.prodPorcentajeDesc" class="sub-title">
        {{ calcularPrecioCaja(producto) | currency: 'COP':'symbol-narrow':'1.0-0' }}
      </p>
    </div>
  </div>
  <div class="col-12 px-3 pt-3">
    <button class="btn btn-light border border-secondary w-100">Editar</button>
  </div>
</div>