import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  constructor(private http: HttpClient, private authService: AuthService) {}
  private headers = new HttpHeaders({ Authorization: this.authService.token });

  // Servicio para traer data del distribuidor vinculados
  public getDistribuidorVinculado(): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/distribuidores_vinculados/`, {
      headers: this.headers,
    });
  }
  // Servicio para traer data segun el id del distribuidor
  public getDistribuidorDataById(id: any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/distribuidores_vinculados_detallado/${id}`, {
      headers: this.headers,
    });
  }

  // Servicio para traer los documentos de un usuario
  public getDocumentosbyId(id: any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/documentos_solicitante/${id}`, {
      headers: this.headers,
    });
  }

  /**Servicio para traer data de clientes y
   * mostrarla en la tabla de clientes-info
   * @param id identificacion del distribuidor*/
  public getClienteByDistribuidor(id: any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/api/distribuidores_vinculados_detallado_horeca/${id}`, {
      headers: this.headers,
    });
  }

  /**
   * Cancelar vinculación punto entrega y distribuidor
   */
  public cancelarVinculacionPuntoDistribuidor(vinculacion_id: string, data: any): Observable<any> {
    return this.http.put<any>(`${environment.backendUrl}/api/distribuidores_vinculados/${vinculacion_id}`, data, {
      headers: this.headers,
    });
  }
}
