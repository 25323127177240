import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CargandoGenericoComponent } from '../modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from '../modal/simple/simple.component';
import { NoticiaService } from '../services/noticia/noticia.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css'],
})
export class BlogComponent implements OnInit {
  constructor(private modalService: NgbModal, private noticiaService: NoticiaService) {}

  modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
  };

  noticias: any[] = [];

  filteredNoticias: any[] = [];

  ngOnInit(): void {
    this.loadNoticias();
  }

  async loadNoticias() {
    const modalCarga = this.modalService.open(CargandoGenericoComponent, this.modalOptions);

    try {
      this.noticias = await this.noticiaService.getNoticias().toPromise();
      this.noticias = this.noticias.filter((noticia) => noticia.estado === 'Activo');
      delete this.noticias[2];
      this.filteredNoticias = this.noticias;
    } catch (error) {
      console.error(error);
      const modalRef = this.modalService.open(SimpleComponent, this.modalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {
        window.location.href = '/home';
      };
    }

    modalCarga.close();
  }

  filterResults(event: any) {
    const query = (event.target.value as string).toLowerCase();
    this.filteredNoticias = this.noticias.filter((noticia) => noticia.titulo.toLowerCase().includes(query));
  }
}
