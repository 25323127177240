import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  /** Autenticación de usuario */
  private user: any;
  /** Guarda las politicas y condiciones */
  public politicas_condiciones: any;
  public terminos_condiciones: any;

  constructor(private rest: RestService, private auth: AuthService, private router: Router) {}

  ngOnInit() {}

  /**
   * Recupera las politicas y condiciones
   */
  fetchPolitiasCondiciones(destino: string) {
    const url = this.router.url;
    if (
      url == '/home' ||
      url == '/blog' ||
      url == '/login' ||
      url == '/registro' ||
      url == '/registro/horeca' ||
      url == '/registro/distribuidor'
    ) {
      /** Se dejan temporalmente los documentos de un horeca mientras se montan los generales */
      this.rest
        .get('documentos_politicas')
        .toPromise()
        .then((documentos: any) => {
          if (destino === 'terminos') {
            window.open(documentos[0].establecimientos.terminos, '_blank');
          } else if (destino === 'politicas') {
            window.open(documentos[0].establecimientos.politicas, '_blank');
          }
        });
    } else {
      this.rest
        .get(`tipo_empresa_trabajador/${this.auth.user?._id}`)
        .toPromise()
        .then((resp: any) => {
          this.rest
            .get('documentos_politicas')
            .toPromise()
            .then((documentos: any) => {
              this.user = resp;
              console.log(this.user);
              if (this.user == 'distribuidor') {
                if (destino === 'terminos') {
                  window.open(documentos[0].distribuidores.terminos, '_blank');
                } else if (destino === 'politicas') {
                  window.open(documentos[0].distribuidores.politicas, '_blank');
                }
              }
              if (this.user == 'usuario_horeca') {
                if (destino === 'terminos') {
                  window.open(documentos[0].establecimientos.terminos, '_blank');
                } else if (destino === 'politicas') {
                  window.open(documentos[0].establecimientos.politicas, '_blank');
                }
              }
              if (this.user == 'organizacion' || this.user == 'No registra') {
                if (destino === 'terminos') {
                  window.open(documentos[0].organizaciones.terminos, '_blank');
                } else if (destino === 'politicas') {
                  window.open(documentos[0].organizaciones.politicas, '_blank');
                }
              }
            });
        });
    }
  }

  /**
   * Abre link en una nueva ventana
   */
  public verDocumento(link: any) {
    console.log(link);
    window.open(link, '_blank');
  }
}
