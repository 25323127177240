import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { GraficasService } from 'src/app/services/graficas-tablas/graficas.service';
import { CurrencyPipe } from '@angular/common';
@Component({
  selector: 'app-graficas-distribuidor',
  templateUrl: './graficas-distribuidor.component.html',
  styleUrls: ['./graficas-distribuidor.component.css'],
})
export class GraficasDistribuidorComponent implements OnInit {
  /** ID distribuidor */
  public id_distribuidor = this.authService.user_distribuidor!._id;
  /** Lista de meses para apoyar el formateo de la fecha */
  public filtro_24_meses: any = [];
  private meses: string[] = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  /** Modal de carga para darle feedback al usuario */
  public modalCarga?: NgbModalRef;
  /** Evita que al hacer click por fuera se cierre el modal */
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };
  /** Biding HTML-Canvas y TS */
  @ViewChild('pie_usuarios_tipos', { static: true })
  private pie_usuarios_tipos!: ElementRef;
  @ViewChild('pie_usuarios_estados', { static: true })
  private pie_usuarios_estados!: ElementRef;
  @ViewChild('bar_usuarios_ventas', { static: true })
  private bar_usuarios_ventas!: ElementRef;
  @ViewChild('bar_usuarios_establecimientos', { static: true })
  private bar_usuarios_establecimientos!: ElementRef;
  @ViewChild('bar_usuarios_pedidos', { static: true })
  private bar_usuarios_pedidos!: ElementRef;
  @ViewChild('bar_usuarios_productos', { static: true })
  private bar_usuarios_productos!: ElementRef;
  @ViewChild('bar_usuarios_sillas', { static: true })
  private bar_usuarios_sillas!: ElementRef;
  /** Guarda datos de la talba y configuraciones */
  public data_pie_usuarios_tipos: any;
  public data_pie_usuarios_estados: any;
  public data_bar_usuarios_ventas: any;
  public data_bar_usuarios_establecimientos: any;
  public data_bar_usuarios_pedidos: any;
  public data_bar_usuarios_productos: any;
  public data_bar_usuarios_sillas: any;
  /** Carga la grafica de chart JS */
  public pie_chart_usuarios_tipos: any;
  public pie_chart_usuarios_estados: any;
  public bar_chart_usuarios_ventas: any;
  public bar_chart_usuarios_establecimientos: any;
  public bar_chart_usuarios_pedidos: any;
  public bar_chart_usuarios_productos: any;
  public bar_chart_usuarios_sillas: any;
  /** Total tabla */
  public total_usuarios_tipos: any;
  public total_usuarios_estados: any;
  public total_usuarios_ventas: any;
  public total_usuarios_establecimientos: any;
  public total_usuarios_pedidos: any;
  public total_usuarios_productos: any;
  public total_usuarios_sillas: any;
  /** Filtros graficas */
  public filtro_usuarios_tipos: any;
  public filtro_usuarios_estados: any;
  public filtro_usuarios_ventas: any;
  public filtro_usuarios_pedidos: any;
  public filtro_usuarios_productos: any;
  public filtro_usuarios_sillas: any;

  constructor(
    private restService: RestService,
    private router: Router,
    private ngxsStore: Store,
    private authService: AuthService,
    private modalService: NgbModal,
    private currency: CurrencyPipe,
    private graficasService: GraficasService
  ) {
    this.data_pie_usuarios_tipos = {
      labels: [],
      datasets: [],
    };
    this.data_pie_usuarios_estados = {
      labels: [],
      datasets: [],
    };
    this.data_bar_usuarios_ventas = {
      labels: [],
      datasets: [],
    };
    this.data_bar_usuarios_establecimientos = {
      labels: [],
      datasets: [],
    };
    this.data_bar_usuarios_pedidos = {
      labels: [],
      datasets: [],
    };
    this.data_bar_usuarios_productos = {
      labels: [],
      datasets: [],
    };
    this.data_bar_usuarios_sillas = {
      labels: [],
      datasets: [],
    };
  }
  async ngOnInit() {
    this.id_distribuidor = await this.ngxsStore.snapshot().auth.user_distribuidor._id;
    /** Modal generíco cargando... */
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    /** Arma array de filtros de meses y años */
    await this.getListaFiltroMeses();
    /** Carga datos de graficas */
    await this.getChartUsuariosTipos();
    await this.getChartUsuariosEstados();
    await this.getChartUsuariosVentas();
    await this.getChartUsuariosEstablecimientos();
    await this.getChartUsuariosPedidos();
    await this.getChartUsuariosProductos();
    await this.getChartUsuariosSillas();
    /** Cerrar Modal generíco cargando... */
    this.modalCarga?.close();
  }

  /**
   * Arma lista de los últimos 24 meses para el filtro de meses
   */
  getListaFiltroMeses() {
    const fecha_actual = new Date();
    let mes_actual = fecha_actual.getMonth();
    let ano_actual = fecha_actual.getFullYear();
    /** Filtro por meses y años */
    for (let index = 0; index < 23; index++) {
      if (mes_actual >= 0) {
        const data = {
          filtro: this.meses[mes_actual] + ', ' + ano_actual,
          filtro_inicio: this.meses[mes_actual - 2] + ', ' + ano_actual,
          crudo: Number(mes_actual + 1) + ',' + ano_actual + ',' + (mes_actual - 1) + ',' + ano_actual,
        };
        if (mes_actual <= 1) {
          data.filtro_inicio = this.meses[11 - (1 - mes_actual)] + ', ' + (ano_actual - 1);
          data.crudo = Number(mes_actual + 1) + ',' + ano_actual + ',' + (11 - mes_actual) + ',' + (ano_actual - 1);
        }
        this.filtro_24_meses.push(data);
        mes_actual = mes_actual - 1;
      } else {
        mes_actual = 11;
        ano_actual = ano_actual - 1;
      }
    }
    this.filtro_usuarios_ventas = this.filtro_24_meses[0].crudo;
    this.filtro_usuarios_pedidos = this.filtro_24_meses[0].crudo;
    this.filtro_usuarios_productos = this.filtro_24_meses[0].crudo;
    this.filtro_usuarios_sillas = this.filtro_24_meses[0].crudo;
  }

  /**
   * Inicializa los datos de las gráficas y lo carga en el DOM
   */
  /***************** Tipos usuario comercial ****************/
  public async getChartUsuariosTipos() {
    let total_usuarios_tipos = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/cuenta/tipos_usuarios/${this.id_distribuidor}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_pie_usuarios_tipos.labels.push(element._id);
          this.data_pie_usuarios_tipos.datasets.push(element.total);
          total_usuarios_tipos = total_usuarios_tipos + element.total;
        });
        this.total_usuarios_tipos = total_usuarios_tipos;
        /** Se crea la grafica */
        this.pie_chart_usuarios_tipos = this.graficasService.createPieChart(
          this.pie_usuarios_tipos,
          this.data_pie_usuarios_tipos,
          'Tipos de usuario comercial'
        );
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
  }
  /***************** Estados usuario comercial ****************/
  public async getChartUsuariosEstados() {
    let total_usuarios_estados = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/cuenta/estados_usuarios/${this.id_distribuidor}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          let estado = element._id;
          if (estado === 'Rechazado' || estado === 'Desactivado' || estado === 'Inactivo' || estado === 'Cancelado') {
            estado = 'INACTIVO/RECHAZADO';
          }
          if (estado === 'Aprobado') {
            estado = 'APROBADO';
          }
          this.data_pie_usuarios_estados.labels.push(estado);
          this.data_pie_usuarios_estados.datasets.push(element.total);
          total_usuarios_estados = total_usuarios_estados + element.total;
        });
        this.total_usuarios_estados = total_usuarios_estados;
        /** Se crea la grafica */
        this.pie_chart_usuarios_estados = this.graficasService.createPieChart(
          this.pie_usuarios_estados,
          this.data_pie_usuarios_estados,
          'Estados de usuario comercial'
        );
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
  }
  /***************** Ventas por usuario comercial ****************/
  public async getChartUsuariosVentas() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_usuarios_ventas) {
      this.bar_chart_usuarios_ventas.destroy();
      this.data_bar_usuarios_ventas.labels = [];
      this.data_bar_usuarios_ventas.datasets = [];
    }
    /** Se arma data para query */
    const first_day =
      this.filtro_usuarios_ventas.split(',')[3] + '-' + this.filtro_usuarios_ventas.split(',')[2] + '-01';
    const last_day =
      this.filtro_usuarios_ventas.split(',')[1] + '-' + this.filtro_usuarios_ventas.split(',')[0] + '-31';
    let total_usuarios_ventas = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/equipo_comercial/ventas/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_usuarios_ventas.labels.push(element._id);
          this.data_bar_usuarios_ventas.datasets.push(element.total);
          total_usuarios_ventas = total_usuarios_ventas + element.total;
        });
        this.total_usuarios_ventas = this.currency.transform(total_usuarios_ventas, '$ ', 'symbol', '1.0-0');
        /** Se crea la grafica */
        this.bar_chart_usuarios_ventas = this.graficasService.createBarChart(
          this.bar_usuarios_ventas,
          this.data_bar_usuarios_ventas,
          'Ventas por usuario comercial',
          'Ventas',
          'Usuarios comerciales'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /***************** Referencias por organizacion ****************/
  public async getChartUsuariosEstablecimientos() {
    let total_usuarios_establecimientos = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/equipo_comercial/establecimientos/${this.id_distribuidor}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_usuarios_establecimientos.labels.push(element._id);
          this.data_bar_usuarios_establecimientos.datasets.push(element.total);
          total_usuarios_establecimientos = total_usuarios_establecimientos + element.total;
        });
        this.total_usuarios_establecimientos = total_usuarios_establecimientos;
        /** Se crea la grafica */
        this.bar_chart_usuarios_establecimientos = this.graficasService.createBarChart(
          this.bar_usuarios_establecimientos,
          this.data_bar_usuarios_establecimientos,
          'Establecimientos por usuario comercial',
          'Número de establecimientos',
          'Usuarios comerciales'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /***************** Número de pedidos ****************/
  public async getChartUsuariosPedidos() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_usuarios_pedidos) {
      this.bar_chart_usuarios_pedidos.destroy();
      this.data_bar_usuarios_pedidos.labels = [];
      this.data_bar_usuarios_pedidos.datasets = [];
    }
    /** Se arma data para query */
    const first_day =
      this.filtro_usuarios_pedidos.split(',')[3] + '-' + this.filtro_usuarios_pedidos.split(',')[2] + '-01';
    const last_day =
      this.filtro_usuarios_pedidos.split(',')[1] + '-' + this.filtro_usuarios_pedidos.split(',')[0] + '-31';
    this.total_usuarios_pedidos = 0;
    let total_usuarios_pedidos = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/equipo_comercial/total_pedidos/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_usuarios_pedidos.labels.push(element._id);
          this.data_bar_usuarios_pedidos.datasets.push(element.total);
          total_usuarios_pedidos = total_usuarios_pedidos + element.total;
        });
        this.total_usuarios_pedidos = total_usuarios_pedidos;
        /** Se crea la grafica */
        this.bar_chart_usuarios_pedidos = this.graficasService.createBarChart(
          this.bar_usuarios_pedidos,
          this.data_bar_usuarios_pedidos,
          'Número de pedidos',
          'Número de pedidos',
          'Usuarios comerciales'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /***************** Número de referencias en pedidos****************/
  public async getChartUsuariosProductos() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_usuarios_productos) {
      this.bar_chart_usuarios_productos.destroy();
      this.data_bar_usuarios_productos.labels = [];
      this.data_bar_usuarios_productos.datasets = [];
    }
    /** Se arma data para query */
    const first_day =
      this.filtro_usuarios_productos.split(',')[3] + '-' + this.filtro_usuarios_productos.split(',')[2] + '-01';
    const last_day =
      this.filtro_usuarios_productos.split(',')[1] + '-' + this.filtro_usuarios_productos.split(',')[0] + '-31';
    this.total_usuarios_productos = 0;
    let total_usuarios_productos = 0;
    let cant_elementos = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(
        `informes/distribuidor/equipo_comercial/productos_X_pedido/${this.id_distribuidor}/${first_day}/${last_day}`
      )
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          if (element._id && element.total) {
            this.data_bar_usuarios_productos.labels.push(element._id);
            this.data_bar_usuarios_productos.datasets.push(element.total);
            total_usuarios_productos = total_usuarios_productos + element.total;
            ++cant_elementos;
          }
        });
        if (cant_elementos > 0)
          this.total_usuarios_productos = Math.round((total_usuarios_productos / cant_elementos) * 100) / 100;
        /** Se crea la grafica */
        this.bar_chart_usuarios_productos = this.graficasService.createBarChart(
          this.bar_usuarios_productos,
          this.data_bar_usuarios_productos,
          'Número de referencias en pedidos',
          'Número de referencias promedio',
          'Usuarios comerciales'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /***************** sillas por usuario ****************/
  public async getChartUsuariosSillas() {
    let total_usuarios_sillas = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/equipo_comercial/sillas/${this.id_distribuidor}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_usuarios_sillas.labels.push(element._id);
          this.data_bar_usuarios_sillas.datasets.push(element.total);
          total_usuarios_sillas = total_usuarios_sillas + element.total;
        });
        this.total_usuarios_sillas = total_usuarios_sillas;
        /** Se crea la grafica */
        this.bar_chart_usuarios_sillas = this.graficasService.createBarChart(
          this.bar_usuarios_sillas,
          this.data_bar_usuarios_sillas,
          '# de sillas alcanzadas por usuario comercial',
          'Número de sillas',
          'Usuarios comerciales'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio-distribuidor']);
    };
  }
}
