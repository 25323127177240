import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest/rest.service';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { faSearch, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { createOfflineCompileUrlResolver } from '@angular/compiler';
@Component({
  selector: 'app-contenido-portafolio',
  templateUrl: './contenido-portafolio.component.html',
  styleUrls: ['./contenido-portafolio.component.css'],
})
export class ContenidoPortafolioComponent implements OnInit {
  [x: string]: any;
  stBusq: any;
  //ID organizacion
  public id_organizacion = this.auth.user_organizacion._id;
  //Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;
  //Modal generíco cargando...
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
  };
  // Imagen producto placeholder
  public product_placeholder = '../../assets/img/product-placeholder.png';
  public distribuidor_placeholder = '../../assets/img/icon-organizacion.png';
  //Iconos
  public faSearch = faSearch;
  public faAngleDown = faAngleDown;
  //Variables de filtrado
  public lineas_productos_estado: boolean[] = [];
  public categorias_filtro_distribuidores: any[] = [];
  //Nombre a buscar entre los productos y sus resultados
  public productos_filtrados: any[] = [];
  //Guarda los distribuidores sin filtrar
  public buffer_productos: any = [];
  //Funcionalidad de filtros por categoría y líneas
  public buffer_categorias_productos: any[] = [];
  public categorias_productos: any[] = [];
  public categorias_productos_seleccionadas: any[] = [];
  public categorias_productos_estados: any[] = [];
  public categorias_productos_estados_todos = true;
  public buffer_lineas_productos: any[] = [];
  public lineas_productos: any[] = [];
  public lineas_productos_estado_todos = true;
  public lineas_productos_actuales_distribuidor: any[] = [];
  // Guardamos dinamicamente los valores de autocompletado
  public filteredOptions: any;
  public mySearch = new FormControl();
  public searchTerm = '';
  //Indicadores
  public total_categorias: any;
  public total_productos: any;
  public total_productos_con_puntosFt: any;
  //Productos
  public productos: any;
  public top_productos: any;
  //Logo producto
  public logo_off = '../../assets/img/icons/icono-caja-off.png';
  public logo_on = '../../assets/img/icons/icono-caja-on.png';

  constructor(
    private rest: RestService,
    private router: Router,
    private ngxsStore: Store,
    private auth: AuthService,
    private modalService: NgbModal
  ) {}

  async ngOnInit() {
    this.id_organizacion = await this.ngxsStore.snapshot().auth.user_organizacion._id;
    await this.getDataComponente();
    await this.autoCompletadoBusqueda();
  }
  ngDoCheck(): void {
    if (this.stBusq && this.stBusq.length > 2) {
      this.gen(this.stBusq);
    }else{
      console.log('aca........');
      this.handleClickLineaProducto(true);
    }
  }
  /**
   * Recupera toda la información de indicadores, productos
   * categorías, lineas de productos y top productos del compoente
   */
  getDataComponente() {
    this.rest
      .getJWT(`productos_por_organizacion/componente/portafolio/${this.id_organizacion}`)
      .toPromise()
      .then((resp: any) => {
        console.log('Resp', resp);
        this.top_productos = resp[0].top_productos ? resp[0].top_productos : [];
        this.total_categorias = resp[0].total_categorias.length > 0 ? resp[0].total_categorias[0].total : 0;
        this.total_productos = resp[0].total_porductos.length > 0 ? resp[0].total_porductos[0].total : 0;
        this.total_productos_con_puntosFt = resp[0].puntosFt_activos.length > 0 ? resp[0].puntosFt_activos[0].total : 0;
        this.productos = resp[0].portafolio ? resp[0].portafolio : [];
        this.categorias_productos = resp[0].categorias_productos ? resp[0].categorias_productos : [];
        this.lineas_productos = resp[0].lineas_productos ? resp[0].lineas_productos : [];
        this.productos_filtrados = this.productos;
        for (const item of this.productos_filtrados) {
          if (!item.codigo_distribuidor_producto) {
            item.codigo_distribuidor_producto = '';
          }
          if (!item.codigo_organizacion_producto) {
            item.codigo_organizacion_producto = '';
          }
        }
        console.log('productos_filtrados', this.productos_filtrados);
        this.buffer_productos = this.productos;
        this.buffer_categorias_productos = this.categorias_productos;
        this.buffer_lineas_productos = this.lineas_productos;
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
  }
  filterByValue(array: any, string: any) {
    return array.filter(
      (o: any) =>
        o.nombre.toLowerCase().includes(string.toLowerCase()) ||
        o.codigo_distribuidor_producto.toLowerCase().includes(string.toLowerCase()) ||
        o.codigo_organizacion_producto.toLowerCase().includes(string.toLowerCase())
    );
  }
  gen(st: any) {
    this.productos_filtrados = this.filterByValue(this.buffer_productos, st);
    console.log(this.productos_filtrados);
  }
  /************************** Busqueda autocompletado *************************/
  /**
   * Este metodo tiene como objetoautocompletar la busqueda del usuario
   */
  autoCompletadoBusqueda() {
    this.filteredOptions = this.mySearch.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.nombre)),
      map((nombre) => (nombre ? this._filter(nombre) : this.productos_filtrados.slice()))
    );
  }

  displayFn(user: any) {
    console.log(user ? user.value : '');
    return user ? user.nombre : undefined;
  }

  returnFn(user: any) {
    return user ? user.value : undefined;
  }

  itemDisplayFn(item: any) {
    return item ? item.name : '';
  }

  private _filter(nombre: string) {
    const filterValue = this.normalizeString(nombre.toLowerCase());
    return this.productos_filtrados.filter((option: any) =>
      this.normalizeString(option.nombre).toLowerCase().includes(filterValue)
    );
  }

  clearSearch() {
    this.searchTerm = '';
  }

  /**
   * Estos metodos tienen como objeto ver el detalle de los distribuidores seleccionado en el buscador
   */

  /**
   * @param event data proveniente del Dom peude ser el "event" del click
   * en la barra de busqueda o puede ser el ID del producto desde la card
   * @param evento true si la función se llama desde la barra de busqueda
   */
  verDetalleProducto(event: any, evento: boolean) {
    console.log(event);
    let filtrado = event;
    if (evento) {
      filtrado = event.option.value.id;
    }
    this.router.navigate([`/detalle-producto-organizacion/${filtrado}/portafolio`]);
  }

  /**
   * Toma el string que entra por parámetro y cambia tildes y diéresis
   * por las letras sin acento, y lo pasa a minúsculas
   * @param pWord El string a filtrar
   * @returns El string filtrado
   */
  normalizeString(pWord: string): string {
    return pWord
      .trim()
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .toLowerCase();
  }

  /************************** Portafolio - Categorias y Lineas *************************/
  /**
   * Este metodo tiene como objeto organizar los filtros por categorías
   * @param click_filtro_todos de darse click en el filtro Todos, reinicia todas las categorías
   */
  handleClickCategoria(click_filtro_todos: boolean) {
    /** Carga todas las categorías disponibles y deselecciona todas las categorias */
    this.categorias_productos = this.buffer_categorias_productos;
    this.categorias_productos_seleccionadas = [];
    /**
     * Verifica Si está seleccionada la categoría dentro de los filtros aplicados
     * si lo está, la agrega al array de seleccionados, si no, no la toma en cuenta
     */
    for (let index = 0; index < this.categorias_productos_estados.length; index++) {
      if (this.categorias_productos_estados[index] === true) {
        this.categorias_productos_seleccionadas.push(this.categorias_productos[index]);
      }
    }
    /*************** Verifica si existen filtros aplicados ***************/
    if (this.categorias_productos_seleccionadas.length == 0 || click_filtro_todos == true) {
      /********************* NO hay filtros aplicados ********************/
      /** Garantiza todas las categorias deseleccionadas y "Todos" activo*/
      this.categorias_productos_estados_todos = true;
      for (let index = 0; index < this.categorias_productos_estados.length; index++) {
        this.categorias_productos_estados[index] = false;
      }
      /** Todas las categorias disbonibles y seleccionadas */
      this.categorias_productos_seleccionadas = this.categorias_productos;
    } else {
      /********************* SI hay filtros aplicados ********************/
      this.categorias_productos_estados_todos = false;
    }
    /****** Organiza nuevamente las lineas de productos disponibles ******/
    /**
     * Crea un array con el estado de la selección de filtros de lineas
     * de productos. Todas inician en false, es decir, se mostrarán todas
     * el estado es true (filtro activo) o false (filtro NO activo)
     */
    for (let index = 0; index < this.lineas_productos_estado.length; index++) {
      this.lineas_productos_estado[index] = false;
    }
    this.handleLineasProductos();
  }

  /**
   * Organiza las lineas de productos de las categorías
   * Esta función reorganiza la lineas de productos cada vez que se cambian las categorías
   * Solo se mostrarán las linea de las categorías seleccionadas
   */
  handleLineasProductos() {
    this.lineas_productos = [];
    /** Si no hay categorías seleccionadas, las muestra todas */
    if (this.categorias_productos_seleccionadas.length == 0) {
      this.categorias_productos_seleccionadas = this.categorias_productos;
    }
    /** Crea un array con todas las lineas de productos disponibles de las categorias seleccionadas */
    const lineas_productos_disponibles_por_categoria: any[] = [];
    for (const categoria of this.categorias_productos_seleccionadas) {
      categoria.lineas_producto.forEach((element: any) => {
        lineas_productos_disponibles_por_categoria.push(element);
      });
    }
    /**
     * Filtra dejando solo las lineas de productos que SI están disponibles en el distribuidor.
     * Hay mas de 100, por lo que se realiza este filtro para mejorar la experiencia del usuario.
     * De las lineas disponbiles del distribuidor, se filtran según las las categorías seleccionadas.
     */
    this.lineas_productos = this.buffer_lineas_productos.filter((el: any) => {
      return lineas_productos_disponibles_por_categoria.some((f: any) => {
        return f == el._id;
      });
    });
    /**
     * Crea un array con el estado de la selección de filtros de lineas de productos.
     * Todas inician en false. Opciones: True (filtro activdo) o False (filtro NO activo).
     */
    this.lineas_productos.forEach(() => {
      this.lineas_productos_estado.push(false);
    });
    /** Ya establecido los filtros se muestran los productos según aplique */
    this.handleClickLineaProducto(false);
  }

  /**
   * Este metodo tiene como objeto filtrar los productos por linea de producto
   * @param click_filtro_todos de darse click en el filtro Todos, habilita todas
   * las lineas de producto disponibles y muestra toda la data respectiva
   */
  handleClickLineaProducto(click_filtro_todos: boolean) {
    /** Reinicia el array de productos sin filtro */
    this.productos_filtrados = this.buffer_productos;
    /** Crea un array con las categorías de filtro seleccionadas */
    let lineas_productos_seleccionadas: any = [];
    for (let index = 0; index < this.lineas_productos_estado.length; index++) {
      if (this.lineas_productos_estado[index] === true) {
        lineas_productos_seleccionadas.push(this.lineas_productos[index]);
      }
    }
    /*********** Verifica si existen filtros aplicados **********/
    if (lineas_productos_seleccionadas.length === 0 || click_filtro_todos === true) {
      /**************** NO hay filtros aplicados ***************/
      /** Reinicia botones y habilita todas las lineas para mostrar todos los productos */
      this.lineas_productos_estado_todos = true;
      this.lineas_productos_estado = this.lineas_productos_estado.map((element: boolean) => (element = false));
      lineas_productos_seleccionadas = this.lineas_productos;
    } else {
      /***************** SI hay filtros aplicados ****************/
      this.lineas_productos_estado_todos = false;
    }
    /*************************** Filtro **************************/
    /** Filtra los productos según las lineas de productos seleccionadas */
    this.productos_filtrados = this.productos_filtrados.filter((el: any) => {
      if (el.linea_producto[0]) {
        return lineas_productos_seleccionadas.some((f: any) => {
          if (f._id) {
            return f._id == el.linea_producto[0];
          } else {
            return false;
          }
        });
      } else {
        return false;
      }
    });
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio-organizacion']);
    };
  }
}
