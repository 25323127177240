<div class="contenedor">
    <fa-icon [icon]="faTimes" [styles]="{ color: '#41403E' }" id="fa-icon" (click)="close()"></fa-icon>
    <h5>Calificación de mi servicio</h5>
    <div class="w-100 d-flex flex-row align-items-center justify-content-between item">
        <span class="d-flex flex-row align-items-center justify-content-between">
            <p class="m-0 pr-2">{{calificacion.calificacion.abastecimiento}}</p>
            <fa-icon class="icon-star" [icon]="faStar" [styles]="{ color: '#41403E' }"></fa-icon>
        </span>
        <p class="m-0">Abastecimiento</p>
    </div>
    <div class="w-100 d-flex flex-row align-items-center justify-content-between item">
        <span class="d-flex flex-row align-items-center justify-content-between">
            <p class="m-0 pr-2">{{calificacion.calificacion.precio}}</p>
            <fa-icon class="icon-star" [icon]="faStar" [styles]="{ color: '#41403E' }"></fa-icon>
        </span>
        <p class="m-0">Precio</p>
    </div>
    <div class="w-100 d-flex flex-row align-items-center justify-content-between item">
        <span class="d-flex flex-row align-items-center justify-content-between">
            <p class="m-0 pr-2">{{calificacion.calificacion.puntualidad_entrega}}</p>
            <fa-icon class="icon-star" [icon]="faStar" [styles]="{ color: '#41403E' }"></fa-icon>
        </span>
        <p class="m-0">Puntualidad</p>
    </div>
    <button class="mt-4" (click)="close()">
        Listo
    </button>
</div>