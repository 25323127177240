import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PlacesService {
  /**
   * Arreglo de lugares en Colombia ordenados alfabéticamente por departamento
   * Cada objeto en el arreglo es de la forma:
   * {
   *  id: Un número correspondiente a su lugar en el arreglo,
   *  departamento: El departamento correspondiente
   *  ciudades: Un arreglo de strings con cada ciudad/municipio/pueblo bien conocido del departamento
   * }
   */
  public places_colombia: any[] = [];
  public departments_colombia: any[] = [];
  public cities_colombia: any[] = [];

  /** URL pública donde se hostea la lista completa */
  private server = 'https://raw.githubusercontent.com/pa-suarezm/colombia-json/master/colombia.json';

  constructor(private http: HttpClient) {}

  /**
   * Trae la lista de lugares de Colombia desde el host
   */
  public async getPlacesFromServer() {
    try {
      const resp: any = await this.http.get(this.server).toPromise();
      this.places_colombia = resp;
    } catch (err) {
      console.log('Error en places.service.ts');
      console.log(err);
    }
  }

  /** URL de Imagine Apps donde se hostea la lista completa y apikey */
  private server_imagine_places = 'https://api.imagineapps.co/countrylibrary/es/SA/COL/';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json', apiKey: 'VQYHJtawdLuyqg4bDEm5kEwHnZfZ5lKW' });

  /**
   * Trae la lista de lugares de Colombia desde el host de Imagine APPS
   */
  public async getDepartmentFromServerImagine() {
    try {
      const resp: any = await this.http
        .get(this.server_imagine_places, {
          headers: this.headers,
        })
        .toPromise();
      this.departments_colombia = resp.data;
      return resp.data;
    } catch (err) {
      console.log('Error en places.service.ts');
      console.log(err);
    }
  }

  public async getCitiesFromServerImagine(department: any) {
    try {
      const resp: any = await this.http
        .get(`${this.server_imagine_places}${department}/`, {
          headers: this.headers,
        })
        .toPromise();
      this.cities_colombia = resp.data;
      return resp.data;
    } catch (err) {
      console.log('Error en places.service.ts');
      console.log(err);
    }
  }
}
