import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Chart } from 'chart.js';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GraficasService } from 'src/app/services/graficas-tablas/graficas.service';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-grafiacas-distribuidores-org',
  templateUrl: './grafiacas-distribuidores-org.component.html',
  styleUrls: ['./grafiacas-distribuidores-org.component.css'],
})
export class GrafiacasDistribuidoresOrgComponent implements OnInit {
  // ID organizacion
  public id_organizacion = this.auth.user_organizacion._id;
  // Lista de meses para apoyar el formateo de la fecha
  public filtro_24_meses: any = [];
  private meses: string[] = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  // Colores para las gráficas
  public colors = [
    'rgba(115, 113, 156, 1)',
    'rgba(199, 195, 239, 1)',
    'rgba(247, 234, 194, 1)',
    'rgba(250, 211, 157, 1)',
    'rgba(179, 179, 210, 1)',
    'rgba(142, 111, 247, 1)',
  ];
  public colors_pie = ['rgba(247, 234, 194, 1)', 'rgba(250, 211, 157, 1)'];
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };
  // Ciudades distribuidor
  public ciudades_distribuidor: any[] = [];
  // Meses ventas distribuidor
  public meses_ventas_distribuidor: any[] = [];
  // Biding HTML-Canvas y TS
  @ViewChild('bar_dist_ventas', { static: true })
  private bar_dist_ventas!: ElementRef;
  @ViewChild('pie_ventas_ciudad', { static: true })
  private pie_ventas_ciudad!: ElementRef;
  @ViewChild('bar_ventas_mes_actual', { static: true })
  private bar_ventas_mes_actual!: ElementRef;
  @ViewChild('bar_productos_distribuidor', { static: true })
  private bar_productos_distribuidor!: ElementRef;
  @ViewChild('bar_vendedores_distribuidor', { static: true })
  private bar_vendedores_distribuidor!: ElementRef;
  @ViewChild('bar_puntos_distribuidor', { static: true })
  private bar_puntos_distribuidor!: ElementRef;
  @ViewChild('bar_distribuidores_ciudad', { static: true })
  private bar_distribuidores_ciudad!: ElementRef;
  // Guarda datos de la talba y configuraciones
  private data_bar_dist_ventas: any;
  private data_pie_ventas_ciudad: any;
  private data_bar_ventas_mes_actual: any;
  public buffer_ventas_mes_actual: any;
  private data_bar_productos_distribuidor: any;
  private data_bar_vendedores_distribuidor: any;
  private data_bar_puntos_distribuidor: any;
  private data_bar_distribuidores_ciudad: any;
  private bar_chart_dist_ventas: any;
  private pie_chart_ventas_ciudad: any;
  private bar_chart_ventas_mes_actual: any;
  private bar_chart_productos_distribuidor: any;
  private bar_chart_vendedores_distribuidor: any;
  private bar_chart_puntos_distribuidor: any;
  private bar_chart_distribuidores_ciudad: any;
  public filtro_ventas_ciudad: any;
  public filtro_ventas_mes_actual: any;
  public filtro_puntos_distribuidor: any;
  public total_dist_ventas: any;
  public total_ventas_ciudad: any;
  public total_ventas_mes_actual: any;
  public total_productos_distribuidor: any;
  public total_vendedores_distribuidor: any;
  public total_puntos_distribuidor: any;
  public total_distribuidores_ciudad: any;

  constructor(
    private graficasService: GraficasService,
    private rest: RestService,
    private auth: AuthService,
    private currency: CurrencyPipe,
    private ngxsStore: Store,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.data_bar_dist_ventas = {
      labels: [],
      datasets: [],
    };
    this.data_pie_ventas_ciudad = {
      labels: [],
      datasets: [],
    };
    this.data_bar_ventas_mes_actual = {
      labels: [],
      datasets: [],
    };
    this.data_bar_productos_distribuidor = {
      labels: [],
      datasets: [],
    };
    this.data_bar_vendedores_distribuidor = {
      labels: [],
      datasets: [],
    };
    this.data_bar_puntos_distribuidor = {
      labels: [],
      datasets: [],
    };
    this.data_bar_distribuidores_ciudad = {
      labels: [],
      datasets: [],
    };
  }

  async ngOnInit() {
    this.id_organizacion = await this.ngxsStore.snapshot().auth.user_organizacion._id;
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    try {
      await this.getVentasDistribuidorTresMeses();
      await this.getDistribuidorCiudades();
      await this.getDistribuidorMesesArrayVentas();
      await this.getGraficaProductosPorDistribuidor();
      await this.getGraficaInformacionVendedoresDistribuidor();
      await this.getGraficaInfoDistPorCiudad();
      await this.getGraficaInformacionPuntosAlcanzadosDistribuidor();
      this.modalCarga?.close();
    } catch (error) {
      this.mostrarMensajeError();
    }
  }

  /**
   * Inicializa los datos de las gráficas y llama a los métodos adecuados para mostrar cada una
   */
  /******** Ventas 3 meses por distribuidor *********/
  public async getVentasDistribuidorTresMeses() {
    let total_dist_ventas = 0;
    await this.rest
      .getJWT(`organizaciones/graficas/distribuidor/ventas_distribuidor/${this.id_organizacion}`)
      .toPromise()
      .then((dist_ventas: any) => {
        dist_ventas = dist_ventas.data;
        dist_ventas.forEach((element: any) => {
          this.data_bar_dist_ventas.labels.push(element.distribuidor_nombre);
          this.data_bar_dist_ventas.datasets.push(element.total);
          total_dist_ventas = total_dist_ventas + element.total;
        });
        this.total_dist_ventas = this.currency.transform(total_dist_ventas, '$ ', 'symbol', '1.0-0');
      })
      .catch((err) => {
        throw err;
      });
    this.bar_chart_dist_ventas = this.graficasService.createBarChart(
      this.bar_dist_ventas,
      this.data_bar_dist_ventas,
      'Ventas acumuladas por distribuidor',
      '$ Ventas',
      'Distribuidores'
    );
  }
  /******** Ciudades donde están los distribuidores *********/
  public async getDistribuidorCiudades() {
    await this.rest
      .getJWT(`organizaciones/graficas/distribuidor/ciudades/${this.id_organizacion}`)
      .toPromise()
      .then((ciudades: any) => {
        ciudades = ciudades.data;
        ciudades.forEach((element: any) => {
          this.ciudades_distribuidor.push(element._id);
        });
        this.filtro_ventas_ciudad = this.ciudades_distribuidor[0];
        this.getVentasDistribuidorPorCiudad();
      })
      .catch((err) => {
        throw err;
      });
  }
  /******** Ventas por ciudad *********/
  public async getVentasDistribuidorPorCiudad() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.pie_chart_ventas_ciudad) {
      this.pie_chart_ventas_ciudad.destroy();
      this.data_pie_ventas_ciudad.labels = [];
      this.data_pie_ventas_ciudad.datasets = [];
    }
    let total_ventas_ciudad = 0;
    await this.rest
      .getJWT(`organizaciones/graficas/distribuidor/ventas_distribuidor/${this.id_organizacion}`)
      .toPromise()
      .then((dist_ventas: any) => {
        /** Filtra la data según ciudad seleccionada */
        dist_ventas = dist_ventas.data.filter(
          (element: any) => element.distribuidor_ciudad == this.filtro_ventas_ciudad
        );
        /** Calcula el total de ventas de la ciudad */
        dist_ventas.forEach((element: any) => {
          total_ventas_ciudad = total_ventas_ciudad + element.total;
        });
        this.total_ventas_ciudad = this.currency.transform(total_ventas_ciudad, '$ ', 'symbol', '1.0-0');
        /** Arma los labels con los porcentajes y el dataset */
        dist_ventas.forEach((element: any) => {
          this.data_pie_ventas_ciudad.labels.push(
            element.distribuidor_nombre + ' - (' + Math.round((element.total / total_ventas_ciudad) * 100) + '%)'
          );
          this.data_pie_ventas_ciudad.datasets.push(element.total);
        });
      })
      .catch((err) => {
        throw err;
      });
    this.pie_chart_ventas_ciudad = this.graficasService.createPieChart(
      this.pie_ventas_ciudad,
      this.data_pie_ventas_ciudad,
      'Ventas distribuidor por ciudad'
    );
  }
  /********* Ciudades donde están los distribuidores *********/
  public async getDistribuidorMesesArrayVentas() {
    await this.rest
      .getJWT(`organizaciones/graficas/distribuidor/meses_array/${this.id_organizacion}`)
      .toPromise()
      .then((meses: any) => {
        meses = meses.data;
        meses.forEach((element: any) => {
          this.meses_ventas_distribuidor.push(element._id);
        });
        this.filtro_ventas_mes_actual = this.meses_ventas_distribuidor[0];
        this.getGraficaInformacionVentasMes('inicio');
      })
      .catch((err) => {
        throw err;
      });
  }
  /******** Ventas por mes  *********/
  public async getGraficaInformacionVentasMes(tipo: string) {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_ventas_mes_actual) {
      this.bar_chart_ventas_mes_actual.destroy();
      this.data_bar_ventas_mes_actual.labels = [];
      this.data_bar_ventas_mes_actual.datasets = [];
    }
    /** Solo se hace fetch en la carga inicial y se guarda la data en un buffer */
    if (tipo == 'inicio') {
      await this.rest
        .getJWT(`organizaciones/graficas/distribuidor/ventas_mes/${this.id_organizacion}`)
        .toPromise()
        .then((ventas_mes_actual: any) => {
          ventas_mes_actual = ventas_mes_actual.data;
          this.buffer_ventas_mes_actual = ventas_mes_actual;
        })
        .catch((err) => {
          throw err;
        });
    }
    /** Filtra la data según el mes seleccionado */
    let total_ventas_mes_actual = 0;
    const data_filtrada = this.buffer_ventas_mes_actual.filter(
      (element: any) => element._id.fechaGroup == this.filtro_ventas_mes_actual
    );
    data_filtrada.forEach((element: any) => {
      this.data_bar_ventas_mes_actual.labels.push(element._id.distribuidor_nombre || 'Otros');
      this.data_bar_ventas_mes_actual.datasets.push(element.total);
      total_ventas_mes_actual = total_ventas_mes_actual + element.total;
    });
    this.total_ventas_mes_actual = this.currency.transform(total_ventas_mes_actual, '$ ', 'symbol', '1.0-0');
    /** Crea la grafica */
    this.bar_chart_ventas_mes_actual = this.graficasService.createBarChart(
      this.bar_ventas_mes_actual,
      this.data_bar_ventas_mes_actual,
      'Ventas por mes',
      '$ Ventas',
      'Distribuidor'
    );
  }
  /************************* Productos por distribuidor ************************/
  public async getGraficaProductosPorDistribuidor() {
    this.total_productos_distribuidor = 0;
    await this.rest
      .getJWT(`organizaciones/graficas/distribuidor/productos/${this.id_organizacion}`)
      .toPromise()
      .then((productos_distribuidor: any) => {
        productos_distribuidor = productos_distribuidor.data;
        productos_distribuidor.forEach((element: any) => {
          this.data_bar_productos_distribuidor.labels.push(element.distribuidor_nombre || 'Otros');
          this.data_bar_productos_distribuidor.datasets.push(element.total_productos);
          this.total_productos_distribuidor = this.total_productos_distribuidor + element.total_productos;
        });
      })
      .catch((err) => {
        throw err;
      });
    this.bar_chart_productos_distribuidor = this.graficasService.createBarChart(
      this.bar_productos_distribuidor,
      this.data_bar_productos_distribuidor,
      'Productos por distribuidor',
      'Número de productos en portafolio',
      'Distribuidores'
    );
  }
  /******** Vendedores por distribuidor *********/
  public async getGraficaInformacionVendedoresDistribuidor() {
    this.total_vendedores_distribuidor = 0;
    await this.rest
      .getJWT(`organizaciones/graficas/distribuidor/vendedores/${this.id_organizacion}`)
      .toPromise()
      .then((vendedores_distribuidor: any) => {
        vendedores_distribuidor = vendedores_distribuidor.data;
        vendedores_distribuidor.forEach((element: any) => {
          this.data_bar_vendedores_distribuidor.labels.push(element._id || 'Otros');
          this.data_bar_vendedores_distribuidor.datasets.push(element.total || 0);
          this.total_vendedores_distribuidor = this.total_vendedores_distribuidor + element.total;
        });
      })
      .catch((err) => {
        throw err;
      });
    this.bar_chart_vendedores_distribuidor = this.graficasService.createBarChart(
      this.bar_vendedores_distribuidor,
      this.data_bar_vendedores_distribuidor,
      'Vendedores por distribuidor',
      'Número de vendedores',
      'Distribuidores'
    );
  }
  /******** Puntos alcanzados por distribuidor *********/
  public async getGraficaInformacionPuntosAlcanzadosDistribuidor() {
    this.total_puntos_distribuidor = 0;
    await this.rest
      .getJWT(`organizaciones/graficas/distribuidor/establecimientos_alcanzados/${this.id_organizacion}`)
      .toPromise()
      .then((puntos_distribuidor: any) => {
        puntos_distribuidor = puntos_distribuidor.data;
        puntos_distribuidor.forEach((element: any) => {
          this.data_bar_puntos_distribuidor.labels.push(element._id || 'Otros');
          this.data_bar_puntos_distribuidor.datasets.push(element.total || 0);
          this.total_puntos_distribuidor = this.total_puntos_distribuidor + element.total;
        });
      })
      .catch((err) => {
        throw err;
      });
    this.bar_chart_puntos_distribuidor = this.graficasService.createBarChart(
      this.bar_puntos_distribuidor,
      this.data_bar_puntos_distribuidor,
      'Puntos alcanzados por distribuidor',
      'Número de establecimientos alcanzados',
      'Distribuidores'
    );
  }
  /******** Distribuidores por ciudad *********/
  public async getGraficaInfoDistPorCiudad() {
    this.total_distribuidores_ciudad = 0;
    await this.rest
      .getJWT(`organizaciones/graficas/distribuidor/distribuidores_ciudad/${this.id_organizacion}`)
      .toPromise()
      .then((resp: any) => {
        resp = resp.data;
        resp.forEach((element: any) => {
          this.data_bar_distribuidores_ciudad.labels.push(element._id || 'Otros');
          this.data_bar_distribuidores_ciudad.datasets.push(element.total || 0);
          this.total_distribuidores_ciudad = this.total_distribuidores_ciudad + element.total;
        });
      })
      .catch((err) => {
        throw err;
      });
    this.bar_chart_distribuidores_ciudad = this.graficasService.createBarChart(
      this.bar_distribuidores_ciudad,
      this.data_bar_distribuidores_ciudad,
      'Distribuidores por ciudad',
      'Número de distribuidores',
      'Ciudades'
    );
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio-organizacion']);
    };
  }
}
