<div class="container_productos">
  <div class="container-top">
    <div class="puntos-container">
      <div>
        <h4>Puntos FT</h4>
        <span class="pildora" *ngIf="plan_actual != undefined">Paquetes: {{puntos_paquetes}}</span>
      </div>
      <div class="grafica-container" *ngIf="plan_actual != undefined ; else noPlan">
        <div class="grafica text-center mr-2">
          <mat-progress-spinner class="mat-progress-spinner" [value]="(puntos_usados * 100)/puntos_disponibles" diameter="60">
          </mat-progress-spinner>
          <h6 class="m-0 pt-2">{{(puntos_usados * 100)/puntos_disponibles | number: '0.0-2'}}%</h6>
        </div>
        <div> 
          <h5 class="m-0">{{puntos_disponibles | number:'1.0-2' }}</h5>
          <span>Tus puntos Ft.</span>
          <span>{{ puntos_usados | number:'1.0-2' }} usados</span>
          <span>{{ restaPuntos}} disponibles</span>
        </div>
      </div>
      <ng-template #noPlan>
        <div class="grafica-container">
          <div class="grafica text-center">
            <img src="../../../assets/img/icon-warning-amarillo.png" class="lsogo-error"
              alt="Icono de una banana para indicar  advertencia" style="height: 70px;" />
          </div>
          <div>
            <span>Sin paquete</span>
            <span>0 puntos FT.</span>
            <span class="cursorPointer texto-link" routerLink="/comprar-puntos-ft-organizacion">
              No tienes paquete de puntos
            </span>
          </div>
        </div>
      </ng-template>
      <button routerLink="/comprar-puntos-ft-organizacion">Comprar paquete</button>
    </div>
    <div class="topVentas" *ngIf="top_10_productos">
      <div>
        <span>Top 10 más vendidos</span>
      </div>
      <div class="productos-container">
        <div class="productos" *ngFor="let producto of top_10_productos">
          <img src="{{ producto.foto_producto }}" alt="logo producto" />
          <div class="d-flex flex-column">
            <p class="descripcion">{{ producto.nombre_producto }}</p>
            <p class="descripcion">{{ producto.total | currency: '$ ':'symbol':'1.0-0' }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="contenido-general">
    <div class="containt-table p-4 m-0">
      <div>
        <span>Mes Actual</span>
        <div>
          <span class="cursorPointer" [ngClass]="{'option-selected': filtro_mes_actual == 'producto'}"
            (click)="filtro_mes_actual = 'producto'" (click)="armaTablaMesActual()">
            Producto
          </span>
          <span class="cursorPointer" [ngClass]="{'option-selected': filtro_mes_actual == 'marca'}"
            (click)="filtro_mes_actual = 'marca'" (click)="armaTablaMesActual()">
            Marca
          </span>
          <span class="cursorPointer" [ngClass]="{'option-selected': filtro_mes_actual == 'categoría'}"
            (click)="filtro_mes_actual = 'categoría'" (click)="armaTablaMesActual()">
            Categoría
          </span>
        </div>
      </div>
      <div class="card p-3 mt-3">
        <div class="p-3" *ngIf="data_puntos_mes_actual else mostrarError">
          <div *ngIf="source_puntos_mes_actual">
            <ng2-smart-table  [settings]="settings_puntos_mes" [source]="source_puntos_mes_actual"
            *ngIf="filtro_mes_actual == 'producto'">
          </ng2-smart-table>
          </div>
         
          <div *ngIf=" source_puntos_mes_actual && source_puntos_mes_actual.length === 0">
            Sin información
          </div>
          <ng2-smart-table [settings]="settings_puntos_mes_marca" [source]="source_puntos_mes_actual" 
            *ngIf="filtro_mes_actual == 'marca'">
          </ng2-smart-table>
          <ng2-smart-table [settings]="settings_puntos_mes_categoria" [source]="source_puntos_mes_actual"
            *ngIf="filtro_mes_actual == 'categoría'">
          </ng2-smart-table>
        </div>
      </div>
    </div>
    <div class="containt-table p-4 m-0">
      <div>
        <span>Puntos por trimestre</span>
        <div>
          <span class="cursorPointer" [ngClass]="{'option-selected': filtro_puntos_por_mes == 'producto'}"
            (click)="filtro_puntos_por_mes = 'producto'" (click)="armaTablaPuntosPorMes()">
            Producto
          </span>
          <span class="cursorPointer" [ngClass]="{'option-selected': filtro_puntos_por_mes == 'marca'}"
            (click)="filtro_puntos_por_mes = 'marca'" (click)="armaTablaPuntosPorMes()">
            Marca
          </span>
          <span class="cursorPointer" [ngClass]="{'option-selected': filtro_puntos_por_mes == 'categoría'}"
            (click)="filtro_puntos_por_mes = 'categoría'" (click)="armaTablaPuntosPorMes()">
            Categoría
          </span>
        </div>
      </div>
      <div class="card p-3 mt-3">
        <div>
          <span class="d-flex flex-row w-100 justify-content-end">
            <select name="" id="" class="text-secondary px-3" [(ngModel)]="filtro_puntos_mes"
              (ngModelChange)="getDataTablaFiltroMes()">
              <option [value]="mes.crudo" *ngFor="let mes of filtro_24_meses">
                {{mes.filtro}}-{{mes.filtro_inicio}}
              </option>
            </select>
          </span>
        </div>
        <div class="p-3" *ngIf="data_completa_puntos_x_mes && data_completa_puntos_x_mes.length > 0 else mostrarError">
          
          <div *ngIf="source_puntos_x_mes">
            <ng2-smart-table [settings]="settings_puntos_mes" [source]="source_puntos_x_mes"
            *ngIf="filtro_puntos_por_mes == 'producto'">
          </ng2-smart-table>
          </div>
         
          <div *ngIf=" source_puntos_x_mes && source_puntos_x_mes.length === 0">
            Sin información
          </div>




          <ng2-smart-table [settings]="settings_puntos_mes_marca" [source]="source_puntos_x_mes"
            *ngIf="filtro_puntos_por_mes == 'marca'">
          </ng2-smart-table>
          <ng2-smart-table [settings]="settings_puntos_mes_categoria" [source]="source_puntos_x_mes"
            *ngIf="filtro_puntos_por_mes == 'categoría'">
          </ng2-smart-table>
        </div>
      </div>
    </div>
    <div class="containt-table p-4 m-0">
      <div>
        <span>Puntos por producto</span>
      </div>
      <div class="card p-3 mt-3">
        <div class="p-3" *ngIf="data_puntos_por_producto.length > 0 else mostrarError">
          <ng2-smart-table [settings]="settings_puntos_por_producto" [source]="source_puntos_por_producto">
          </ng2-smart-table>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #mostrarError>
  <div class="row p-4 d-flex flex-column ">
    <div class="error-message text-center">
      <div class="text-center">
        <img src="../../../assets/img/icon-warning-amarillo.png" class="logo-error"
          alt="Icono de una banana para indicar  advertencia" />
        <p class="text-center">Lo siento, aún no registras información por aquí.</p>
      </div>
    </div>
  </div>
</ng-template>