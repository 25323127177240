<div class="container">
  <!--Header-->
  <div class="row">
    <div class="col-11">
      <h5>Pon un producto en saldo</h5>
    </div>
    <div class="col-1" style="cursor: pointer">
      <fa-icon [icon]="faTimes" size="lg" (click)="activeModal.close()"></fa-icon>
    </div>
  </div>
  <!--Instrucciones-->
  <div class="row">
    <div class="col-12">
      <p>
        Selecciona un precio especial para este producto y las unidades disponibles. Ten en cuenta que no se quitará del
        catálogo bajo la disponibilidad normal y el precio inicial, tendrás dos veces este producto en el catálogo, uno
        con el Descuento y otro con el precio normal.
      </p>
    </div>
  </div>
  <!--Info producto-->
  <div class="row">
    <div class="col-4 col-lg-2">
      <img [src]="fotos[0] || ''" alt="iamgen del producto  {{ producto?.nombre }}" />
    </div>
    <div class="col-8 col-lg-5">
      <p>
        {{ producto?.nombre }}
      </p>
      <p>Precio und. {{ precios[0].precio_unidad || 0 | currency: '$ ':'symbol' }}</p>
      <p>Caja x{{ precios[0].und_x_caja || 0 }} {{ precios[0].precio_caja || 0 | currency: '$ ':'symbol' }}</p>
    </div>
    <div class="col-12 col-lg-5 text-center">
      <br />
      <p class="p-cod-dist">
        Código producto distribuidor:<br>{{ producto?.codigo_distribuidor_producto || 'No registra'}}
      </p>
    </div>
  </div>
  <br />
  <!--Form-->
  <div class="row">
    <!--PrecCódigoio del saldo-->
    <div class="col-12 col-sm-6 py-2">
      <label>Código del saldo</label>
      <br />
      <input type="text" placeholder="ABC-328" [(ngModel)]="codigo_promo" />
    </div>
    <!--Precio del saldo-->
    <div class="col-12 col-sm-6 py-2">
      <label>Precio del saldo</label>
      <br />
      <input type="text" [ngModel]="precios_form[0].precio_descuento " (keydown)="validateNumber($event)"
        (blur)="transformAmount($event.target)" (focus)="transformCurrency($event.target)" />
    </div>
    <!--Fecha de vencimiento-->
    <div class="col-12 col-lg-6 py-2">
      <label>Fecha de vencimiento</label>
      <div class="input-group">
        <input class="form-control input-date" placeholder="Fecha" name="dp" [(ngModel)]="model" ngbDatepicker
          #d="ngbDatepicker" />
        <div class="input-group-append input-date">
          <button class="btn btn-outline-secondary calendar input-date" (click)="d.toggle()" type="button">
            <fa-icon class="fa-icon-calendar" [icon]="faChevronDown" size="lg" [styles]="{ color: '#41403E' }">
            </fa-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <!--Inventario disponible-->
    <div class="unidades col-12 col-sm-6 col-lg-4">
      <label class="text-center">Unidades disponibles</label>
      <div>
        <button class="btn_cantidad btn_menos"
          (click)="precios_form[0].inventario_unidad = precios_form[0].inventario_unidad - 1"
          [disabled]="precios_form[0].inventario_unidad == 0">
          -
        </button>
        <input class="span_cnt" type="number" [(ngModel)]="precios_form[0].inventario_unidad" />
        <button class="btn_cantidad btn_mas"
          (click)="precios_form[0].inventario_unidad = precios_form[0].inventario_unidad + 1">
          +
        </button>
      </div>
    </div>
    <div class="cajas col-12 col-sm-6 col-lg-4">
      <label class="text-center">Cajas disponibles</label>
      <h5 class="h-100">
        {{precios_form[0].inventario_caja || 0}}
      </h5>
    </div>
  </div>
  <br />
  <!--Mensaje de error para unidades-->
  <div class="row" *ngIf="err_max_unidades">
    <div class="col-12">
      <p style="color: red">Ten en cuenta que estás poniendo en saldo todo el inventario de este producto.</p>
    </div>
  </div>
  <br />
  <!--Btn de crear saldo-->
  <div class="row">
    <div class="col-12 text-right">
      <button class="btn-purple" (click)="publicarSaldo()" [disabled]="!crear_saldo_enabled">Crear saldo</button>
    </div>
  </div>
</div>