import { Component, OnInit } from '@angular/core';
import { faChevronLeft, faCircle, faSearch, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { DistribuidorService } from '../services/distribuidor/distribuidor.service';
import { AuthService } from '../services/auth/auth.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SeleccionarPuntoEntregaComponent } from '../modal/seleccionar-punto-entrega/seleccionar-punto-entrega.component';
import { LocalStorageService } from '../services/local-storage/local-storage.service';
import { RestService } from '../services/rest/rest.service';
import { PedidosService } from '../services/pedidos/pedidos.service';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-puntos-feat',
  templateUrl: './puntos-feat.component.html',
  styleUrls: ['./puntos-feat.component.css'],
})
export class PuntosFeatComponent implements OnInit {
  /** Referencias a íconos FontAwesome para la UI */
  faStar = faStar;
  faChevronLeft = faChevronLeft;
  faCircle = faCircle;
  faSearch = faSearch;
  faShoppingCart = faShoppingCart;
  distribuidores: any = [];
  punto_entrega: any;
  trabajador: any;
  listaCodigosDescuento: any;
  distribuidorSeleccionado = '';
  valorSeleccionado = 0;
  codigo_distribuidor = '';
  codigo_creado = '';
  valores_puntos = <any>[];
  buttonActive = 'compras';
  balancePuntos: any;
  /** Modal generico */
  public modalCarga?: NgbModalRef;
  /** Evita que al hacer click por fuera se cierre el modal */
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };
  /**No hay información de puntos */
  isPuntosNull = false;
  minPF: any = 0;
  puntosProceso: any;
  puntosPorCalificar: any;
  diasRestantes: any;

  /** Puntos Feats disponibles */
  public puntos_feat = 0;

  /**Cantidad de digítos en los puntos feat */
  public total_digitos = 1;
  descontarBilletera = false;
  copXpunto: any;
  historicoPuntos = 0;
  codGenerados = 0;
  idPuntoSeleccionado: any;
  constructor(
    private distribuidorService: DistribuidorService,
    private auth: AuthService,
    private modalService: NgbModal,
    public localStorage: LocalStorageService,
    private restService: RestService,
    private router: Router,
    private pedidosService: PedidosService
  ) {
    this.getPuntosConfiguracion();
    this.valorPuntosFT();
  }

  async ngOnInit() {
    this.trabajador = this.auth.user;

    await this.localStorage.getItem<any>('punto_entrega_seleccionado').then((resp) => {
      console.log('resp', resp.length);
      if (resp.length === 0 || resp.length === undefined) {
        this.seleccionarPunto();
      } else {
        this.punto_entrega = resp;
        this.idPuntoSeleccionado = this.punto_entrega._id;
        this.getPuntosFeat();
        this.fetchCodigos();
        this.sectionActive('compras');
      }
    });

    this.restService
      .getJWT('parametrizacion')
      .toPromise()
      .then((resp: any) => {
        this.valores_puntos = resp[0].bloques_codigo_descuento;
      })
      .catch((err) => {
        this.mostrarMensajeError();
      });
  }
  /**
   * Metodo para obtener el minimo de puntos para redimir
   */
  public valorPuntosFT() {
    this.restService
      .getJWT('parametrizacion/')
      .toPromise()
      .then((resp: any) => {
        this.copXpunto = resp[0].valor_1puntoft;
      })
      .catch((err: any) => {
        this.isPuntosNull = true;
      });
  }
  /**
   * Metodo para obtener el minimo de puntos para redimir
   */
  public getPuntosConfiguracion() {
    this.restService
      .getJWT('codigos_niveles_all/')
      .toPromise()
      .then((resp: any) => {
        this.minPF = resp[0].nivelA;
      })
      .catch((err: any) => {
        this.isPuntosNull = true;
      });
  }
  /**
   * Lanza un modal para seleccionar el punto de entrega sobre el que se
   * van a gestionar las solicitudes
   */
  seleccionarPunto() {
    const modalRef = this.modalService.open(SeleccionarPuntoEntregaComponent, this.ngbModalOptions);
    modalRef.componentInstance.callback = () => {
      this.idPuntoSeleccionado = undefined;
      this.punto_entrega = this.auth.punto_seleccionado;
      this.idPuntoSeleccionado = this.punto_entrega._id;
      this.getPuntosFeat();
      this.fetchCodigos();
      this.localStorage.setItem<any>('punto_entrega_seleccionado', this.punto_entrega);
      this.sectionActive('compras');
    };
  }

  /**
   * Información de puntos por punto de entrega
   */
  public cargaInformacionReporte() {
    this.restService
      .getJWT('informes/puntos_feat_compras/balance/' + this.punto_entrega._id)
      .toPromise()
      .then((resp: any) => {
        this.balancePuntos = resp[0];
      })
      .catch((err: any) => {
        // this.mostrarMensajeError();
      });
  }
  /**
   * Información de puntos por punto de entrega
   */
  public getPuntosFeat() {
    this.restService
      .getJWT('/puntos_feat/punto_entrega/' + this.punto_entrega._id)
      .toPromise()
      .then((resp: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let xCalificar = 0;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let disponibles = 0;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let generados = 0;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let proceso = 0;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let perdidos;
        for (const item of resp) {
          if (item._id === 'Por congelar') {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            xCalificar = item.total_puntos;
          }
          if (item._id === 'Congelados') {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            disponibles = item.total_puntos;
          }
          if (item._id === 'CodigoGenerado') {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            generados = item.total_puntos;
          }
          if (item._id === 'Aplica') {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            proceso = item.total_puntos;
          }
          if (item._id === 'Congelados feat') {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            perdidos = item.total_puntos;
          }
        }
        //resp[0].disponibles[0] ? (this.puntos_feat = resp[0].disponibles[0].total) : 0;
        /**se toma la cantidad de digitos para ubicar el número en el centro del spinner */
        this.puntos_feat = disponibles;
        this.total_digitos = disponibles.toString().trim().length;
        this.puntosProceso = proceso;
        this.puntosPorCalificar = xCalificar;
        this.isPuntosNull = false;
        this.historicoPuntos = generados + disponibles;
        this.codGenerados = generados;
      })
      .catch((err: any) => {
        this.isPuntosNull = true;
      });
  }
  sectionActive(buttonActive: string) {
    this.buttonActive = buttonActive;
  }

  async fetchDistribuidoresVinculados() {
    this.distribuidorService.getDistribuidoresVinculados('Aprobado', this.punto_entrega._id).subscribe((result) => {
      this.distribuidores = result;
    });
  }

  /**
   * Lanza un modal para seleccionar el punto de entrega sobre el que se
   * van a gestionar las solicitudes
   */
  async selecionarPunto() {
    const modalRef = this.modalService.open(SeleccionarPuntoEntregaComponent, this.ngbModalOptions);
    modalRef.componentInstance.callback = async () => {
      this.punto_entrega = this.auth.punto_seleccionado;
      this.localStorage.setItem<any>('punto_entrega_seleccionado', this.punto_entrega);
      await this.fetchDistribuidoresVinculados();
      await this.fetchCodigos();
    };
  }

  async fetchCodigos() {
    this.restService
      .getJWT('codigos_generados/vigentes/' + this.punto_entrega._id)
      .toPromise()
      .then((resp: any) => {
        this.listaCodigosDescuento = resp.reverse();
      });
  }

  /**
   * Genera el codigo para ser redimido
   */
  onGenerarCodigo() {
    let totalPuntos = this.puntos_feat;
    let newPuntosPerfil;
    let discountPuntos;
    //this.punto_entrega.puntos_inscripcion = 12;
    if (this.descontarBilletera && this.punto_entrega.puntos_inscripcion > 0) {
      totalPuntos = totalPuntos + this.punto_entrega.puntos_inscripcion;
      if (this.punto_entrega.puntos_inscripcion >= this.minPF) {
        newPuntosPerfil = this.punto_entrega.puntos_inscripcion - this.minPF;
      } else {
        newPuntosPerfil = 0;
        discountPuntos = this.minPF - this.punto_entrega.puntos_inscripcion;
      }
    } else {
      discountPuntos = this.minPF;
    }
    if (totalPuntos >= this.minPF) {
      const data = {
        dataPunto: this.punto_entrega,
        punto_entrega: this.punto_entrega._id,
        trabajador: this.trabajador._id,
        valor_paquete: this.minPF,
        newPuntosPerfil: newPuntosPerfil,
        discountPuntos: discountPuntos,
        valor_moneda: this.minPF * this.copXpunto,
      };
      console.log('data a generar......', data);
      this.pedidosService.generarCodigo(data).subscribe(
        async (result) => {
          //this.codigo_creado = result.data.codigoGenerado[0].codigo_creado;
          //this.getPuntosFeat();

          const modalRef = this.modalService.open(SimpleComponent);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = ' ¡Enhorabuena! ';
          modalRef.componentInstance.msg =
            'Se ha generado el Cod descuento: ' +
            result.data.codigoGenerado[0].codigo_creado +
            ', equivalente a: ' +
            (this.minPF * this.copXpunto).toFixed(2) +
            ' COP ' +
            'que puedes aplicar en tu próxima compra';
          modalRef.componentInstance.btn_msg = 'Ok';
          modalRef.componentInstance.close_callback = () => {
            this.descontarBilletera = false;
            this.punto_entrega.puntos_inscripcion = data.newPuntosPerfil;
            this.getPuntosFeat();
            this.getPuntosConfiguracion();
            this.fetchCodigos();
          };
        },
        (err: any) => {
          if (err.status == 400) {
            const modalRef = this.modalService.open(SimpleComponent);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
            modalRef.componentInstance.title = '¡Oh oh!';
            modalRef.componentInstance.msg = err.error.message;
            modalRef.componentInstance.btn_msg = 'Volver';
            modalRef.componentInstance.close_callback = () => {};
          }
        }
      );
    } else {
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'No tienes Puntos feat disponibles para redimir ';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {};
    }
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio']);
    };
  }
}
