<app-header-horeca></app-header-horeca>
<div class="container">
  <!-- Buscador y Filtros -->
  <section class="buscador-container w-100 pt-0 mb-3">
    <div class="volver d-flex align-items-center justify-content-start flex-row p-0">
      <div class="boton-volver" [routerLink]="['/distribuidores']">
        <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
        <span>Volver</span>
      </div>
      <h4 class="pl-2">Mis distribuidores</h4>
    </div>
    <span class="titulo d-flex align-items-center">Encuentra un nuevo distribuidor</span>
    <div class="barra-busqueda">
      <div class="d-flex flex-row autocompletado-busqueda px-0 h-100 w-100">
        <button class="col-2 col-sm-1 p-0 boton-buscar d-flex justify-content-center">
          <fa-icon class="fa-icon-search m-0" [icon]="faSearch" size="lg" [styles]="{ color: '#41403E' }"></fa-icon>
        </button>
        <form class="example-form col-10 col-sm-11 pr-0">
          <mat-form-field class="example-full-width">
            <input #escogido type="text" placeholder="Buscar por nombre del distribuidor"
              aria-label="Buscar distribuidor" matInput [formControl]="mySearch" [matAutocomplete]="auto"
              class="w-100 h-100 input-busqueda pr-0" />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" [displayWith]="itemDisplayFn"
              class="w-100" (optionSelected)="verDetalleDistribuidor($event)">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{ option?.nombre }} | {{ option?.tipo }} | {{ option?.ciudad }} | {{
                getPazYSalvo(option._id) ? 'A paz y salvo' : 'Pago pendiente'}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>
      </div>
    </div>
    <div class="punto-entrega-container rounded overflow-hidden p-0 pr-3">
      <div class="punto-entrega-icono col-1">
        <img src="../../assets/img/icons/icon-pin-map.png" alt="icono de un pin para señalar un sitio en un mapa"
          srcset="" />
      </div>
      <span class="text-left punto-entrega-texto col-6 col-sm-7 col-md-8 p-0" id="point">
        <span class="texto-novisible">Punto de entrega: </span>
        <span>{{ punto_entrega?.nombre || 'Selecciona un punto' }}</span>
      </span>
      <span (click)="selecionarPunto()" class="punto-entrega-cambiar col-5 col-sm-4 col-md-3 text-center m-0">
        Cambiar
      </span>
    </div>
    <div (click)="onFiltrar()" class="boton-filtro">
      <fa-icon [icon]="faFilter" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
      <span>Filtrar</span>
    </div>
    <div class="categorias">
      <div (click)="todos = !todos" (click)="filtrarTodos()" (click)="filtroCategoria(true)">
        <img [src]="todos ? '../../assets/img/icons/icono-caja-on.png' : '../../assets/img/icons/icono-caja-off.png'"
          alt="Todos" srcset="" />
        <span [style]="todos ? 'color: #8E6FF7;' : 'color: #41403E;'">&nbsp;Todos</span>
      </div>
      <div (click)="carnicos = !carnicos" (click)="filtroCategoria(true)">
        <img [src]="
                carnicos ? '../../assets/img/icons/icono-carne-on.png' : '../../assets/img/icons/icono-carne-off.png'
              " alt="Cárnicos" srcset="" />
        <span [style]="carnicos ? 'color: #8E6FF7;' : 'color: #41403E;'">&nbsp;Cárnicos</span>
      </div>
      <div (click)="lacteos = !lacteos" (click)="filtroCategoria(true)">
        <img [src]="
                lacteos ? '../../assets/img/icons/icono-lacteos-on.png' : '../../assets/img/icons/icono-lacteos-off.png'
              " alt="Lácteos" srcset="" />
        <span [style]="lacteos ? 'color: #8E6FF7;' : 'color: #41403E;'">&nbsp;Lácteos</span>
      </div>
      <div (click)="bebidas = !bebidas" (click)="filtroCategoria(true)">
        <img [src]="
                bebidas ? '../../assets/img/icons/icono-bebidas-on.png' : '../../assets/img/icons/icono-bebidas-off.png'
              " alt="Bebidas" srcset="" />
        <span [style]="bebidas ? 'color: #8E6FF7;' : 'color: #41403E;'">&nbsp;Bebidas</span>
      </div>
      <div (click)="frutas = !frutas" (click)="filtroCategoria(true)">
        <img [src]="
                frutas
                  ? '../../assets/img/icons/icono-frutas-verduras-on.png'
                  : '../../assets/img/icons/icono-frutas-verduras-off.png'
              " alt="Frutas y verduras" srcset="" />
        <span [style]="frutas ? 'color: #8E6FF7;' : 'color: #41403E;'">&nbsp;Frutas y verduras</span>
      </div>
      <div (click)="maquinaria = !maquinaria" (click)="filtroCategoria(true)">
        <img [src]="
                maquinaria
                  ? '../../assets/img/icons/icono-maquinaria-implementos-on.png'
                  : '../../assets/img/icons/icono-maquinaria-implementos-off.png'
              " alt="Maquinaria e implementos" srcset="" />
        <span [style]="maquinaria ? 'color: #8E6FF7;' : 'color: #41403E;'">&nbsp;Maquinaria e implementos</span>
      </div>
      <div (click)="licores = !licores" (click)="filtroCategoria(true)">
        <img [src]="
                licores ? '../../assets/img/icons/icono-licores-on.png' : '../../assets/img/icons/icono-licores-off.png'
              " alt="Licores" srcset="" />
        <span [style]="licores ? 'color: #8E6FF7;' : 'color: #41403E;'">&nbsp;Licores</span>
      </div>
      <div (click)="aseo = !aseo" (click)="filtroCategoria(true)">
        <img [src]="aseo ? '../../assets/img/icons/icono-aseo-on.png' : '../../assets/img/icons/icono-aseo-off.png'"
          alt="Aseo y otros" srcset="" />
        <span [style]="aseo ? 'color: #8E6FF7;' : 'color: #41403E;'">&nbsp;Aseo y otros</span>
      </div>
      <div (click)="especializado = !especializado" (click)="filtroCategoria(true)">
        <img [src]="
                especializado
                  ? '../../assets/img/icons/icono-especializado-on.png'
                  : '../../assets/img/icons/icono-especializado-off.png'
              " alt="Especializado - general" srcset="" />
        <span [style]="especializado ? 'color: #8E6FF7;' : 'color: #41403E;'">&nbsp;Especializado - general</span>
      </div>
    </div>
  </section>
  <!-- Tarjetas -->
  <div class="distribuidores-container mb-5" *ngIf="distVinculadosAprobados.length > 0; else mostrarError">
    <div class="mr-3 mis-distribuidores" *ngFor="let item of distVinculadosAprobados"
      [routerLink]="['/mis-distribuidores', item?._id]">
      <div class="card-headers border-bottom d-flex flex-row pb-2">
        <div class="w-75 d-flex flex-row  p-0">
          <span *ngIf="getPazYSalvo(item._id) == true">
            <fa-icon [icon]="faCircle" style="color: #c7d389" id="fa-icon"></fa-icon>
            <span class="ml-2"> A paz y salvo</span>
          </span>
          <span *ngIf="getPazYSalvo(item._id) != true || getPazYSalvo(item._id) == undefined">
            <fa-icon [icon]="faCircle" style="color: #d38989" id="fa-icon"></fa-icon>
            <span class="ml-2"> En cartera</span>
          </span>
        </div>
        <div class="w-50 text-right pl-2">
          <span class="convenio">Convenio</span>
          <span *ngIf="item.convenio === true" class="convenio">$ Convenio</span>
          <span *ngIf="item.convenio === false" class="convenio">No tiene convenio</span>
        </div>
      </div>
      <div class="card-logo">
        <img src="{{ item.logo || product_placeholder }}" alt="dist-logo" srcset="" />
      </div>
      <div class="card-nombre d-flex flex-row justify-content-between">
        <span>{{ item.nombre }}</span>
        <span>
          <fa-icon [icon]="faStar" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
          <span> {{item.ranking}}</span>
        </span>
      </div>
      <div class="card-categoria">
        <span class="categoria">{{ item.tipo }}</span>
      </div>
      <div class="card-compras text-secondary">
        <span>{{item.pedidos || 0}} compra{{item.pedidos > 1 ? 's' : ''}} al distribuidor</span>
      </div>
    </div>
  </div>
  <ng-template #mostrarError>
    <div class="d-flex flex-column justify-content-center align-items-center w-100">
      <div class="text-center">
        <img src="../../../assets/img/icon-warning-amarillo.png"
          alt="Icono de una banana para indicar una advertencia" />
        <p class="text-center">Parece que no has aprobado las solicitudes</p>
      </div>
    </div>
  </ng-template>
</div>