import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { CargarCatalogoComponent } from '../cargar-catalogo/cargar-catalogo.component';
import { SimpleComponent } from '../simple/simple.component';
import * as XLSX from 'xlsx';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-procesando-info',
  templateUrl: './procesando-info.component.html',
  styleUrls: ['./procesando-info.component.css'],
})
export class ProcesandoInfoComponent implements OnInit {
  /** El archivo XLSX a subir para ser procesado */
  @Input() productos_xlsx?: File;
  @Input() inventario_xlsx? = false;
  user: any;

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private rest: RestService,
    private auth: AuthService,
    private ngxsStore: Store
  ) {}

  ngOnInit(): void {
    this.user = this.ngxsStore.snapshot().auth.user;
    console.log('use', this.user);
    this.cargarXLSX();
  }

  /**
   * Se envía el archivo de Excel al back para ser procesado
   * y espera una respuesta
   */
  async cargarXLSX() {
    if (this.productos_xlsx && this.inventario_xlsx === false) {
      //Se cargan productos
      const upload_form: FormData = new FormData();
      upload_form.append('xlsx', this.productos_xlsx);
      try {
        const resp: any = await this.rest
          .postJWT(`producto/crear_por_xlsx/${this.auth.user_distribuidor?._id}`, upload_form)
          .toPromise();
        console.log('resp post', resp);
        this.siguiente(resp);
      } catch (err) {
        this.error(err);
      }
    } else if (this.productos_xlsx && this.inventario_xlsx === true) {
      //Se actualiza inventario y productos
      const upload_form: FormData = new FormData();
      upload_form.append('xlsx', this.productos_xlsx);
      try {
        const resp: any = await this.rest
          .putJWT(`producto/actualizar_por_xlsx/${this.auth.user_distribuidor?._id}`, upload_form)
          .toPromise()
          .then((resp: any) => {
            this.activeModal.close();
            //El mensaje cambia dependiendo de la cantidad de productos actualizados
            // eslint-disable-next-line prefer-const
            let agg = ``;
            if (resp.productos_fall.length > 0) {
              agg = `Otros ${resp.skipped} productos no fueron actualizados, por lo que te descargará un archivo XLS con los productos fallidos.`;
            }
            let msg = '';
            if (resp.msg) {
              msg = resp.msg;
            } else {
              msg = `Se han actualizado ${resp.data.length} productos con éxito y ya están en el sistema. ${agg}`;
            }
            const modalRef = this.modalService.open(SimpleComponent);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
            modalRef.componentInstance.title = '¡Inventario y precios actualizados en el sistema!';
            modalRef.componentInstance.msg = msg;
            modalRef.componentInstance.btn_msg = 'Listo';
            modalRef.componentInstance.close_callback = (respModal: any) => {
              console.log('respModal', respModal);
              if (resp.productos_fall.length > 0) {
                this.generateXLSError(resp.productos_fall);
              }
              window.location.reload();
            };
          });
      } catch (err) {
        this.activeModal.close();
        this.error(err);
      }
    }
  }
  public generateXLSError(data: any) {
    /** Arma el contenido de la tabla con titulos*/
    const arreglo_final: any[] = [];
    arreglo_final.push(['Producto', 'Código distribuidor producto', 'Comentario']);
    data.forEach((element: any) => {
      const array = [
        element.nombre,
        element.codigo_distribuidor_producto,
        'Producto no relacionado en base de datos, verificar nombre y codigo distribuidor',
      ];
      arreglo_final.push(array);
    });
    this.DescargaExcel({ data: arreglo_final, nombreArchivo: `productos_fallidos.xlsx` });
  }
  /**
   * Descargue a excel un listado
   */
  public DescargaExcel(obj: any) {
    const readyToExport = obj.data;
    const workBook = XLSX.utils.book_new(); // create a new blank book
    const workSheet = XLSX.utils.json_to_sheet(readyToExport);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
    XLSX.writeFile(workBook, obj.nombreArchivo); // initiate a file download in browser
  }
  /**
   * Una vez se termine la carga y procesamiento del archivo,
   * se cierra este modal y se llama al siguiente con la
   * información pertinente
   */
  async siguiente(prods: any) {
    this.activeModal.close();

    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg',
    };

    const modalRef = this.modalService.open(CargarCatalogoComponent, ngbModalOptions);
    modalRef.componentInstance.back_data = prods;
  }

  /**
   * Cierra este modal y lanza un mensaje de error genérico
   * También imprime el error por consola para facilitar el debugging
   * @param err El error que disparó este método
   */
  error(err: any) {
    this.activeModal.close();

    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };

    console.log(err);

    let errorMessage = 'Ocurrió un error subiendo la información. Por favor, intenta de nuevo más tarde.';
    if (err.error?.emptyData) {
      errorMessage = 'El archivo no contiene información de los productos.';
    }

    const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = errorMessage;
    modalRef.componentInstance.btn_msg = 'Volver';
  }
}
