import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
@Injectable({
  providedIn: 'root'
})
export class EditPedidoService {
  i = 0;
  total = 0;
  amount = 0;
  private order: any = {
    paymentStatus: false,
    products: [
      { unidad: 0, product: '', caja: 0, und_x_caja: 0, inventario: 0, puntos_ft_unidad: 0, puntos_ft_caja: 0 },
    ],
  };
  private products: any[] = [];
  private cart = new BehaviorSubject<any[]>([]);
  private orderList = new BehaviorSubject<any>(null);
  private state = new BehaviorSubject(false);
  private totalPrice = new BehaviorSubject(0);
  verLista = false;
  totalPrice$ = this.totalPrice.asObservable();
  orderList$ = this.orderList.asObservable();
  cart$ = this.cart.asObservable();
  state$ = this.state.asObservable();
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };
  constructor(private modalService: NgbModal) {}

  setOrder(totalprice: any, products: any, order: any) {
    this.totalPrice = totalprice;
    this.products = products;
    this.order = order;
    this.sumaTotal();
    this.cart.next(this.products);
    this.orderList.next(this.order);
  }

  /**
   * Esta función tiene como objetivo añadir un nuevo producto al carrito
   * @param product es el producto que se va a añadir
   * @param unidad cantidad de unidades a agregar
   * @param caja cantidad de cajas a agregar
   * @param und_x_caja la cantidad de unidades que vienen por caja
   * Primero se identifica si un producto esta en el carrito,
   * si es un indice negativo se procede a agregar el producto a la lista de productos en el carrito
   * y luego agregar la informacion del producto y las cantidades de cajas o unidades a agregar en el pedido.
   *
   * Si un producto esta en el carrito lo unico que se hace es agregar mas unidades o cajas al pedido de ese producto.
   */
  async addCart(product: any, unidad: number, caja: number, und_x_caja: number, inv_unidades: number) {
    const index = this.products.findIndex((element) => element._id == product._id);
    if (index == -1) {
      this.products.push(product);
      if (this.products.length > 1) {
        this.order.products.push({
          product: product._id,
          unidad: unidad,
          caja: unidad / und_x_caja,
          und_x_caja: und_x_caja,
          inventario: inv_unidades,
          referencia: product.precios[0]._id || '',
          puntos_ft_unidad: product.saldos == false ? product.precios[0].puntos_ft_unidad : 0,
          puntos_ft_caja: product.saldos == false ? product.precios[0].puntos_ft_caja : 0,
          precio_original:
            product.prodDescuento && product.prodPorcentajeDesc > 0
              ? this.calcularValorCaja(product, und_x_caja).precio_original
              : product.precios[0].precio_unidad,
          precio_caja:
            product.prodDescuento && product.prodPorcentajeDesc > 0
              ? this.calcularValorCaja(product, und_x_caja).precio_caja
              : product.precios[0].precio_unidad * und_x_caja,
          porcentaje_descuento: product.prodDescuento && product.prodPorcentajeDesc > 0,
        });
      } else {
        this.order.products = [
          {
            product: product._id,
            unidad: unidad,
            caja: unidad / und_x_caja,
            und_x_caja: und_x_caja,
            inventario: inv_unidades,
            puntos_ft_unidad: product.saldos == false ? product.precios[0].puntos_ft_unidad : 0,
            puntos_ft_caja: product.saldos == false ? product.precios[0].puntos_ft_caja : 0,
            precio_original:
              product.prodDescuento && product.prodPorcentajeDesc > 0
                ? this.calcularValorCaja(product, und_x_caja).precio_original
                : product.precios[0].precio_unidad,
            precio_caja:
              product.prodDescuento && product.prodPorcentajeDesc > 0
                ? this.calcularValorCaja(product, und_x_caja).precio_caja
                : product.precios[0].precio_unidad * und_x_caja,
            porcentaje_descuento: product.prodDescuento && product.prodPorcentajeDesc > 0,
          },
        ];
      }
    } else {
      if (unidad) {
        this.order.products[index].unidad += unidad;
      } else if (caja) {
        this.order.products[index].caja += caja;
      }
    }
    this.validarDistribuidor();
    this.sumaTotal();
    this.cart.next(this.products);
    this.orderList.next(this.order);
  }

  calcularValorCaja(product: any, und_x_caja: number) {
    const precioDescuento = Math.round(
      product.precios[0].precio_unidad - product.precios[0].precio_unidad * (product.prodPorcentajeDesc / 100)
    );
    return {
      precio_caja: precioDescuento * und_x_caja,
      precio_original: precioDescuento,
    };
  }

  /**
   * Esta función se encarga de remover un producto del carrito.
   * @param i index del producto que sera removido.
   */
  removeCart(i: number) {
    this.products.splice(i, 1);
    this.order.products.splice(i, 1);
    this.orderList.next(this.order);
    this.cart.next(this.products);
    this.sumaTotal();
    this.validarDistribuidor();
  }

  /**
   * Esta funcion se encarga de vaciar el carrito de compras.
   */
  emptyCart() {
    this.products = [];
    this.order = {
      paymentStatus: false,
      products: [{ unidad: 0, product: '', caja: 0, und_x_caja: 0, inventario: 0 }],
    };
    this.cart.next(this.products);
    this.orderList.next(this.order);
    this.sumaTotal();
  }
  /**
   * Esta funcion se encarga de vaciar el carrito de compras.
   */
  emptyCartSugerido() {
    this.products = [];
    this.order = {
      paymentStatus: false,
      products: [{ unidad: 0, product: '', caja: 0, und_x_caja: 0, inventario: 0 }],
    };
    this.orderList.next(this.order);
    this.cart.next(this.products);
  }
  /**
   * El objetivo de esto es sumar o restar la cantidad de cajas o unidades que se comprará de cada producto
   * @param operation es la operacion que sera ejecutada (false: resta, true: suma)
   * @param i index del producto en el que se aplicara la operacion.
   * @param item identifica si se ejecutará la operacion en cajas o productos
   * @param cant identifica si se ejecutará la operacion en cajas o productos
   * Si en determinado momento la cantidad de cajas y unidades es 0, el producto es eliminado del carrito
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sumaresta(operation: boolean, i: number, item: string, cant: number) {
    if (item === 'multiple') {
      this.order.products[i].unidad = cant;
      if (this.order.products[i].unidad == this.order.products[i].und_x_caja) {
        // this.order.products[i].unidad = 0;
        this.order.products[i].caja++;
      }
    } else {
      if (item == 'unidad') {
        if (operation == false) {
          if (this.order.products[i].unidad === 1 || !this.order.products[i].unidad) {
            this.order.products[i].unidad = 0;
            this.order.products[i].caja = this.order.products[i].unidad / this.order.products[i].und_x_caja;
            //this.removeCart(i);
          } else {
            const und = this.order.products[i].unidad - 1;
            this.order.products[i].unidad = und;
            this.order.products[i].caja = this.order.products[i].unidad / this.order.products[i].und_x_caja;
          }
        } else {
          this.order.products[i].unidad++;
          //if (this.order.products[i].unidad > this.order.products[i].und_x_caja) {
          // this.order.products[i].unidad = 0;
          this.order.products[i].caja = this.order.products[i].unidad / this.order.products[i].und_x_caja;
          //}
        }
      } else {
        if (operation == false) {
          this.order.products[i].caja--;
          if (this.order.products[i].caja < 0) {
            this.order.products[i].caja = 0;
          }
        } else {
          this.order.products[i].caja++;
        }
      }
    }
    const unidades = parseInt(this.order.products[i].unidad.toString());
    const cajas = parseInt(this.order.products[i].caja.toString());
    const und_x_caja = parseInt(this.order.products[i].und_x_caja.toString());
    const inventario = parseInt(this.order.products[i].inventario.toString());
    const unidades_totales = unidades; /* + cajas * und_x_caja */
    /** Si no hay más unidades, se cuadra la orden para que tenga la mayor cantidad de unidades posible */
    if (unidades_totales > inventario) {
      // this.order.products[i].caja = Math.floor(inventario / und_x_caja);
      this.order.products[i].unidad = inventario;
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'No hay suficientes unidades de este producto para este pedido. La cantidad se ajustó automáticamente a la cantidad máxima posible.';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {};
    }
    if (this.order.products[i].caja == 0 && this.order.products[i].unidad == 0) {
      this.removeCart(i);
    }
    this.orderList.next(this.order);
    this.sumaTotal();
  }

  descuentosVerificacion(descuento: any, subtotal: any) {
    if (descuento < subtotal) {
      return {
        status: false,
        total: subtotal - descuento,
      };
    } else {
      return {
        status: true,
        total: subtotal - descuento,
      };
    }
  }

  /**
   * Esta funcion calcula el total a pagar
   */
  sumaTotal() {
    const totl = [];
    let total;
    for (const product of this.products) {
      if (product?.saldos === false) {
        if (!product?.prodDescuento) {
          total = product?.precios[0].precio_unidad * this.order.products[this.i].unidad;
        } else if (product?.prodPorcentajeDesc > 0) {
          total =
            Math.round(
              product?.precios[0]?.precio_unidad -
                product?.precios[0]?.precio_unidad * (product?.prodPorcentajeDesc / 100)
            ) * this.order.products[this.i].unidad;
        }
      } else {
        total = product?.precios[0].precio_descuento * this.order.products[this.i].unidad;
      }
      totl.push(total);
      this.i++;
    }
    this.order.total = totl.reduce((a:any, b:any) => a + b, 0);
    this.i = 0;
    this.totalPrice.next(this.order.total);
    this.validarDistribuidor();
  }

  /**
   * Esta funcion actualiza la orden y los productos que hay en ella
   */
  orderNext() {
    this.orderList.next(this.order);
    this.cart.next(this.products);
    this.validarDistribuidor();
  }

  /**
   * Valida si los productos son del mismo distribuidor
   * de no serlos procede a vaciar el carrito y alertar
   * NO se puden tener productos de disferentes dist.
   */
  validarDistribuidor() {
    if (this.products.length > 0) {
      const flag_id_distribuidor = this.products[0].codigo_distribuidor;
      this.products.forEach((element: any) => {
        if (element.codigo_distribuidor != flag_id_distribuidor) {
          const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg =
            'No es posible agregar al carrito productos de diferentes distribuidores, por favor procede a realizar compras independientes.';
          modalRef.componentInstance.btn_msg = 'Volver';
          modalRef.componentInstance.close_callback = () => {
            this.emptyCart();
            window.location.reload();
          };
        }
      });
    }
  }
}
