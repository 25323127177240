<app-header-distribuidor></app-header-distribuidor>
<div class="container" [ngClass]="{'blur-contenido': productos?.length === 0}">
  <h2 class="main-title">Editar pedido</h2>
  <div class="detalle-compra">
    <div class="data-distribuidor p-4">
      <div class="productos-carrito p-4">
        <div class="productos-carrito-titulos pb-3">
          <span>{{ productos_length }} producto{{productos_length === 1 ? '' : 's'}} - {{ total_unidades_compra }}
            und{{total_unidades_compra === 1 ? '' : 's'}}.<span class="texto-temporal"> en tu
              compra</span></span>
        </div>
        <hr />
        <div class="products-list-container pt-3">
          <div class="product-item" *ngFor="let producto of productos; let i = index">
            <!-- Imagen producto -->
            <div class="d-flex flex-column w-25">
              <span *ngIf="producto.promocion == false" class="d-flex justify-content-center">
                <img class="mb-0 h-75" src="{{ producto?.fotos[0] || product_placeholder}}"
                  [alt]="'Foto de ' + producto?.nombre" />
              </span>
              <span *ngIf="producto.promocion == true">
                <div class="h-75 carouselCont">
                  <ngb-carousel class="w-100">
                    <ng-template ngbSlide *ngFor="let img_src of producto.fotos" class="w-100">
                      <div class="w-100 col-12 d-flex justify-content-center">
                        <img [src]="img_src" class="img-prod rounded" [alt]="'Foto de ' + producto.nombre" />
                      </div>
                    </ng-template>
                  </ngb-carousel>
                </div>
              </span>
              <span class="w-100 text-center h-25 mb-2"
                *ngIf="producto.promocion == false && producto.saldos == false && producto?.flag_aplica_puntos_feat == true">
                {{producto?.precios[0].puntos_ft_unidad}} pts Ft
              </span>
            </div>
            <div class="item-data">
              <!-- Producto nombre y tipo -->
              <div>
                <span class="w-75 d-flex flex-row justify-content-start align-items-start">
                  <span class="promo-tag m-0" *ngIf="producto.saldos === true || producto.promocion === true">
                    {{producto.promocion === true ? 'Promo' : ''}}{{producto.saldos === true ? 'Saldo' : ''}}
                  </span>
                  &nbsp;{{ producto?.nombre }}
                </span>
                <fa-icon class="w-25 text-right" [icon]="faTrash" [styles]="{ color: '#41403E' }" id="fa-icon"
                  (click)="removeCart(i)"></fa-icon>
              </div>
              <!-- Codigo producto/saldo/promo -->
              <div class="w-100 d-flex flex-column">
                <span class="w-100" *ngIf="producto.saldos === true">
                  Código saldo: {{producto?.codigo_promo}}
                </span>
                <span class="w-100" *ngIf="producto.promocion === false">
                  Código distribuidor: {{producto?.codigo_distribuidor_producto}}
                </span>
                <span class="w-100" *ngIf="producto.promocion === true">
                  Código promoción: {{producto?.codigo_promo}}
                </span>
              </div>
              <!-- Precios -->
              <div class="d-flex flex-row justify-content-between">
                <!-- Precio unidad o descuento si es saldo -->
                <span class="pl-0" *ngIf="producto.saldos === false && !producto?.prodDescuento">
                  {{ producto?.precios[0].precio_unidad | currency: '$':'symbol':'1.0-0' }}und
                </span>
                <span *ngIf="producto.saldos === false && producto?.prodDescuento && producto?.prodPorcentajeDesc > 0">
                  {{ ((producto?.precios[0]?.precio_unidad - (producto?.precios[0]?.precio_unidad * (producto?.prodPorcentajeDesc / 100))) | currency: '$':'symbol':'1.0-0') }}
                </span>
                <span class="pl-0" *ngIf="producto.saldos === true">
                  {{ producto?.precios[0].precio_descuento | currency: '$':'symbol':'1.0-0' }}und
                </span>
                <!-- Precio caja -->
                <span *ngIf="producto.promocion === false">&nbsp;|&nbsp;</span>
                <span *ngIf="producto.promocion === false && producto.saldos === false && !producto?.prodDescuento">
                  {{ producto?.precios[0].precio_caja | currency: '$':'symbol':'1.0-0' }}caja
                </span>
                <span *ngIf="producto.promocion === false && producto.saldos === false && producto?.prodDescuento && producto?.prodPorcentajeDesc">
                  {{ calcularPrecioCaja(producto) | currency: '$':'symbol':'1.0-0' }}caja
                </span>
                <span *ngIf="producto.promocion === false && producto.saldos === true">
                  {{ producto?.precios[0].precio_descuento * producto?.precios[0].und_x_caja | currency:
                  '$':'symbol':'1.0-0' }}caja
                </span>
                <!-- Precio por unidad medida -->
                <span *ngIf="producto.promocion === false">&nbsp;|&nbsp;</span>
                <span *ngIf="producto.promocion === false" class="text-right pr-0">
                  {{producto?.precios[0].cantidad_medida}} ${{producto?.precios[0].unidad_medida}}
                </span>
              </div>
              <!-- Botones -->
              <div class="botones">
                <div class="w-50 align-items-center">
                  <span class="text-center">Unidades</span>
                  <div>
                    <button (click)="quantity(false, i, 'unidad')">-</button>
                    <input [(ngModel)]="order.products[i].unidad"  (keyup)="calculatePrice(false, i, 'new',order.products[i].unidad)">
                    <button (click)="quantity(true, i, 'unidad')">+</button>
                  </div>
                </div>
                <div class="w-50 align-items-center">
                  <span class="text-center">Total en cajas</span>
                  <div class="cajas-container">
                    <p class="m-0">
                      {{totalCajasPedido(producto?.precios[0].und_x_caja, order.products[i].unidad)}}
                    </p>
                  </div>
                </div>
              </div>
              <p class="text-secondary w-100 text-center">
                {{unidadesPendientesCaja(producto?.precios[0].und_x_caja, order.products[i].unidad)}} und
                pend. para otra caja
              </p>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
    <div class="resumen p-0 d-flex flex-column justify-content-between">
      <div class="resumen-titulo-contenedor pad15px">
        <h6 *ngIf="punto_entrega">{{punto_entrega.nombre}}
        </h6>
        <h6 class="resumen-titulo" *ngIf="flag_editar_pedido">
          ID Pedido: {{ pedio_editar.id_pedido
          }}</h6>
      </div>
      <div class="space-between resumen-puntos">
        <div class="w-50 d-flex justify-content-start">
          <span>Puntos FT ganados</span>
          <span class="pr-2 resumen-puntos-digitos">{{ puntosGanados }}</span>
        </div>
        <div class="w-50 d-flex justify-content-end">
          <span>Puntos FT usados</span>
          <span class=" resumen-puntos-digitos">{{ puntosUsados }}</span>
        </div>
      </div>
      <div class="colunm-direction px-4">
        <div class="space-between">
          <span>Subtotal</span>
          <span>{{ subtotalPrice | currency: '$ ':'symbol':'1.0-0' }}</span>
        </div>
        <div class="space-between">
          <span>Descuento</span>
          <span class="descuento">{{ descuento | currency: '$ ':'symbol':'1.0-0' }}</span>
        </div>
        <div class="space-between total" [ngClass]="{ 'error-color': descuento_excedido }">
          <span>Total</span>
          <span>{{ totalPrice | currency: '$ ':'symbol':'1.0-0' }}</span>
        </div>

      </div>
      <button class="space-between crear-pedido" [disabled]="descuento_excedido"
        [ngClass]="{ excedido: descuento_excedido }" (click)="crearPedido()"
        *ngIf="!flag_editar_pedido; else editar_pedido">
        <span>
          Crear pedido
        </span>
        <fa-icon [icon]="faChevronRight" [styles]="{ color: '#fff' }" id="fa-icon"></fa-icon>
      </button>
      <ng-template #editar_pedido>
        <button class="space-between crear-pedido" [disabled]="descuento_excedido"
          [ngClass]="{ excedido: descuento_excedido }" (click)="editarPedidoEnCurso()">
          <span>
            Editar pedido
          </span>
          <fa-icon [icon]="faChevronRight" [styles]="{ color: '#fff' }" id="fa-icon"></fa-icon>
        </button>
      </ng-template>
    </div>
  </div>


</div>
<br />