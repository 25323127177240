<div class="row">
  <div class="col-12 text-right">
    <h6> Debes seleccionar la zona
    </h6>
  </div>
</div>
<div>
  <div class="listaCiudadesContainer">
    <span>Distribuidores disponibles</span>
    <div class="punto-entrega-container rounded overflow-hidden p-0 pr-3">
      <div class="punto-entrega-icono col-1">
        <img src="../../assets/img/icons/icon-pin-map.png" alt="icono de un pin para señalar un sitio en un mapa"
          srcset="" />
      </div>
      <ng-template #tipContent>{{ data.join(', ') }}</ng-template>
      <span class="text-left punto-entrega-texto col-6 col-sm-7 col-md-8 p-0" id="point" placement="bottom"
        [ngbTooltip]="tipContent">
        <span class="texto-novisible">{{ data.join(', ') }}</span>
      </span>
      <span class="punto-entrega-cambiar col-5 col-sm-4 col-md-3 text-center" (click)="selecionarCiudad()">
        Cambiar
      </span>
    </div>
  </div>
  <div class="contendioContenedor">
    <div>
      <div class="cardDistribuidor" *ngFor="let distribuidor of distribuidoresData"
        (click)="onDetalleDistribuidor(distribuidor._id)">
        <div class="ubicacion_Puntaje">
          <div *ngIf="distribuidoresData">
            <span class="iconosCard">
              <img src="../../../../assets/iconos-org/location.png" alt="" />
              {{ distribuidor.ciudad }}
            </span>
          </div>
          <div>
            <span class="iconosCard">
              <img src="../../../../assets/iconos-org/star.png" alt="" />
              {{ distribuidor.ranking }}
            </span>
          </div>
        </div>
        <div>
          <span class="iconosCard">
            <img src="../../../../assets/iconos-org/transport.png" alt="" />
            {{ distribuidor.tiempo_entrega }}
          </span>
        </div>
        <div class="imgContainer">
          <img src="{{ distribuidor.logo || distribuidor_placeholder }}" alt="" />
        </div>
        <span>{{ distribuidor.nombre }}</span>
        <span class="categoria">{{ distribuidor.tipo }}</span>
        <div class="descripcion">
          <p>
            {{ distribuidor.descripcion }}
          </p>
        </div>
      </div>
    </div>
    <div *ngIf="vacio">
      <img src="../../../../assets/img/icon-warning-amarillo.png" alt="" />
      <span>Nada de nada</span>
      <span>Selecciona un distribuidor para ver más información</span>
    </div>
    <!-- Distribuidor detalle -->
    <div *ngIf="!vacio" class="distribuidor-detalle">
      <div class="scrollDetalles contenidoDetalles">
        <div class="preciosDetalle flexSpace">
          <div>
            <span class="iconosCard" *ngIf="distribuidor">
              <img src="../../../../../assets/iconos-org/location.png" alt="" />
              {{ distribuidor.ciudad}}
            </span>
            <span class="cantidadVendedores">{{total_trabajadores}} Vendedores</span>
          </div>
          <div>
            <img src="{{ distribuidor.logo }}" alt="" />
            <div>
              <span>{{ distribuidor.nombre }}</span>
              <span class="sectorPildora">{{ distribuidor.tipo }}</span>
              <div>
                <span class="iconosCard">
                  <img src="../../../../assets/iconos-org/transport.png" alt="" />
                  {{ distribuidor.tiempo_entrega }}
                </span>
              </div>
            </div>
          </div>
          <span>Evaluación</span>
          <div class="evaluacionData">
            <span class="iconosCard">
              <img src="../../../../../assets/iconos-org/star.png" alt="" />
              {{calificacion.abastecimiento}}
            </span>
            <span>Abastecimiento</span>
          </div>
          <div class="evaluacionData">
            <span class="iconosCard">
              <img src="../../../../../assets/iconos-org/star.png" alt="" />
              {{calificacion.precio}}
            </span>
            <span>Precio</span>
          </div>
          <div class="evaluacionData">
            <span class="iconosCard">
              <img src="../../../../../assets/iconos-org/star.png" alt="" />
              {{calificacion.puntualidad_entrega}}
            </span>
            <span>Puntualidad</span>
          </div>
          <div class="metodosPago">
            <span>Métodos de pago:</span>
            <span>{{distribuidor.metodo_pago}}</span>
          </div>
          <div>
            <span>Pedido mínimo:</span>
            <span>{{ distribuidor.valor_minimo_pedido | currency: '$ ':'symbol': '1.0-0' }}</span>
          </div>
          <div>
            <span>Productos:</span>
            <span>{{total_productos}}</span>
          </div>
          <div>
            <span>Establecimientos alcanzados:</span>
            <span>{{total_establecimientos}}</span>
          </div>
          <div>
            <span>Sillas alcanzadas:</span>
            <span>{{sillas}}</span>
          </div>
          <div>
            <span>Promedio de ventas:</span>
            <span>{{promedio_ventas | currency: '$ ':'symbol': '1.0-0'}}</span>
          </div>
          <span>Información adicional</span>
          <div>
            <span>Dirección:</span>
            <span class="text-right">{{ distribuidor.direccion }} - {{ distribuidor.ciudad }}</span>
          </div>
          <div>
            <span>Teléfono:</span>
            <span class="text-right">{{ distribuidor.celular }} - {{ distribuidor.telefono }}</span>
          </div>
          <div>
            <span>Correo:</span>
            <span class="text-right">{{ distribuidor.correo }}</span>
          </div>
        </div>
      </div>
      <div *ngIf="!vacio" class="chatBoton">
        <span (click)="onPortafolioDistribuidor(distribuidor._id)">Portafolio</span>
        <button (click)="getChatRoomId()">Chat</button>
      </div>
    </div>
  </div>
</div>