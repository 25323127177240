import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
@Injectable({
  providedIn: 'root',
})
export class EstablecimientosService {
  constructor(private http: HttpClient, private authService: AuthService) {}
  private headers = new HttpHeaders({ Authorization: this.authService.token });

  public getEstablecimientos(): Observable<any> {
    return this.http.get(`${environment.backendUrl}/api/usuario_horeca/`, {
      headers: this.headers,
    });
  }

  public getFilteredEstablecimientos(id: any): Observable<any> {
    return this.http.get(`${environment.backendUrl}/api/usuario_horeca/${id}`, {
      headers: this.headers,
    });
  }
  public getPropietario(id: any): Observable<any> {
    return this.http.get(`${environment.backendUrl}/api/usuario_horeca_propietario/${id}`, {
      headers: this.headers,
    });
  }
  public getPuntoEntrega(): Observable<any> {
    return this.http.get(`${environment.backendUrl}/api/punto_entrega/`, {
      headers: this.headers,
    });
  }
  public getFilteredPuntoEntrega(id: any): Observable<any> {
    return this.http.get(`${environment.backendUrl}/api/punto_entrega/${id}`, {
      headers: this.headers,
    });
  }
  public putFilteredPuntoEntrega(id: any, data: any): Observable<any> {
    return this.http.put(`${environment.backendUrl}/api/punto_entrega/${id}`, data, {
      headers: this.headers,
    });
  }
}
