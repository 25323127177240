import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-experiencia-feat',
  templateUrl: './experiencia-feat.component.html',
  styleUrls: ['./experiencia-feat.component.css'],
})
export class ExperienciaFeatComponent implements OnInit {
  @ViewChild('videoPlayer', { static: false })
  videoplayer!: ElementRef;

  isPlay = false;

  constructor() {}

  ngOnInit(): void {}
  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }
  playPause() {
    const myVideo: any = document.getElementById('my_video_1');
    if (myVideo.paused) myVideo.play();
    else myVideo.pause();
  }

  makeBig() {
    const myVideo: any = document.getElementById('my_video_1');
    myVideo.width = 560;
  }

  makeSmall() {
    const myVideo: any = document.getElementById('my_video_1');
    myVideo.width = 320;
  }

  makeNormal() {
    const myVideo: any = document.getElementById('my_video_1');
    myVideo.width = 420;
  }

  skip(value: any) {
    const video: any = document.getElementById('my_video_1');
    video.currentTime += value;
  }

  restart() {
    const video: any = document.getElementById('my_video_1');
    video.currentTime = 0;
  }
}
