<app-header-distribuidor></app-header-distribuidor>
<div id="content">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills">
    <li [ngbNavItem]="1">
      <a ngbNavLink>Informes</a>
      <ng-template ngbNavContent>
        <app-tablas-informes-distribuidor></app-tablas-informes-distribuidor>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>Informes del sector</a>
      <ng-template ngbNavContent>
        <app-informes-sector></app-informes-sector>
      </ng-template>
    </li>
    <li [ngbNavItem]="3">
      <a ngbNavLink>Gráficas de ventas</a>
      <ng-template ngbNavContent>
        <app-grafica-ventas></app-grafica-ventas>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
