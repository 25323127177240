<div class="d-flex flex-column justify-content-center align-items-center">
  <div class="col-12 m-0 p-0">
    <h4>
      {{ title }}
    </h4>
  </div>
  <div class="col-12 m-0 p-0">
    <p class="m-0">
      {{ msg }}
    </p>
  </div>
  <div class="col-12 text-right m-0 p-0">
    <button id="btn_modal" (click)="onClose()">
      {{ btn_msg }}
    </button>
  </div>
</div>