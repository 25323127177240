<div class="d-flex clickable" (click)="openNoticia(noticia)">
  <div class="notificacion-img">
    <img [src]="noticia?.imagen || '../../../assets/img/blog/img1.png'" class="img-notification" alt="" />
  </div>
  <div class="card-notice">
    <div class="specification text-center">{{ noticia?.site || 'Feat' }}</div>
    <div class="my-2 text-container">
      <p>{{ noticia?.titulo || 'Sin titulo' }}</p>
    </div>
  </div>
</div>
