<section class="mb-5 pb-5">
  <div *ngIf="registroComp.step == 2 || registroComp.step == 3" class="d-flex flex-wrap">
    <!--Paso 2-->
    <div class="card-propietario col-6 m-0">
      <!--Card propietario ON-->
      <div class="card text-center" style="cursor: pointer;"
        (click)="seleccionarPerfil('Propietario o representante legal')" *ngIf="
                registroComp.distribiudor_perfil_seleccionado == 'Propietario o representante legal' ||
                registroComp.distribiudor_perfil_seleccionado == ''
              ">
        <div class="card-body row">
          <div class="col-12 col-md-4">
            <img src="../../../../assets/img/horeca-legal-on.png" alt="Representante legal o dueño"
              class="icon-registro" />
          </div>
          <div class="col-12 col-md-8">
            <h5 class="card-title title-tipo-usuario text-left">
              Propietario o representante legal
            </h5>
          </div>
        </div>
      </div>
      <!--Card propietario OFF-->
      <div class="card text-center" style="cursor: pointer;"
        (click)="seleccionarPerfil('Propietario o representante legal')" *ngIf="
                registroComp.distribiudor_perfil_seleccionado != 'Propietario o representante legal' &&
                registroComp.distribiudor_perfil_seleccionado != ''
              ">
        <div class="card-body row">
          <div class="col-12 col-md-4">
            <img src="../../../../assets/img/horeca-legal-off.png" alt="Representante legal o dueño"
              class="icon-registro" />
          </div>
          <div class="col-12 col-md-8">
            <h5 class="card-title title-tipo-usuario text-left" style="color: #aeaead;">
              Propietario o representante legal
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="card-trabajador col-6 m-0">
      <!--Card trabajador ON-->
      <div class="card text-center" style="cursor: pointer;" (click)="seleccionarPerfil('Trabajador')" *ngIf="
                registroComp.distribiudor_perfil_seleccionado == 'Trabajador' ||
                registroComp.distribiudor_perfil_seleccionado == ''
              ">
        <div class="card-body row">
          <div class="col-12 col-md-4">
            <img src="../../../../assets/img/horeca-trabajador-on.png" alt="Trabajador" class="icon-registro" />
          </div>
          <div class="col-12 col-md-8">
            <h5 class="card-title title-tipo-usuario text-left">
              Usuario del distribuidor
            </h5>
          </div>
        </div>
      </div>
      <!--Card trabajador OFF-->
      <div class="card text-center" style="cursor: pointer;" (click)="seleccionarPerfil('Trabajador')" *ngIf="
                registroComp.distribiudor_perfil_seleccionado != 'Trabajador' &&
                registroComp.distribiudor_perfil_seleccionado != ''
              ">
        <div class="card-body row">
          <div class="col-12 col-md-4">
            <img src="../../../../assets/img/horeca-trabajador-off.png" alt="Trabajador" class="icon-registro" />
          </div>
          <div class="col-12 col-md-8">
            <h5 class="card-title title-tipo-usuario text-left" style="color: #aeaead;">
              Usuario del distribuidor
            </h5>
          </div>
        </div>
      </div>
    </div>
    <!--Paso 3-->
    <div class="col-12 mt-4 p-0" *ngIf="
        registroComp.distribiudor_perfil_seleccionado == 'Trabajador' ||
        registroComp.distribiudor_perfil_seleccionado == 'Propietario o representante legal'
      ">
      <!--Propietario o representante legal-->
      <div *ngIf="registroComp.distribiudor_perfil_seleccionado == 'Propietario o representante legal'">
        <div class="card">
          <div class="card-body p-4 flex-column">
            <div class="w-100 text-left">
              <h5 class="card-title">
                Selecciona según tu perfil
              </h5>
            </div>
            <div class="w-100 text-left">
              <p class="card-subtitle my-2 w-100">
                Tipo de persona
              </p>
            </div>
            <div class="w-100">
              <select #tipo_value class="d-inline-block dropdown-form-1 input-nit"
                [(ngModel)]="registroComp.dist_tipo_persona" (change)="seleccionarTipoPersona(tipo_value.value)">
                <option ngValue="Selecciona">Selecciona</option>
                <option ngValue="Natural">Natural</option>
                <option ngValue="Jurídica">Jurídica</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!--Trabajador-->
      <div *ngIf="registroComp.distribiudor_perfil_seleccionado == 'Trabajador'">
        <div class="card">
          <div class="card-body p-4 flex-column">
            <div class="w-100 text-left">
              <h5 class="card-title">
                La empresa a la que haces parte debe estar registrada para que te unas al equipo desde la
                plataforma
              </h5>
            </div>
            <div class="w-100 text-left nit-cedula">
              <p class="card-subtitle my-2 w-100">
                NIT empresa o cédula del propietario
              </p>
            </div>
            <div class="row w-100 m-0">
              <div class="col-md-6 col-12">
                <input type="text" placeholder="Escribe aquí" [(ngModel)]="registroComp.dist_t_nit_cc"
                  class="input-nit w-100" />
              </div>
              <div class="col-md-6 col-12 pl-2">
                <div class="d-flex align-items-center">
                  <p *ngIf="registroComp.dist_encontrado.nombre != ''" class="m-0">
                    <fa-icon [icon]="faCheck" [styles]="{'color': '#41403E'}"></fa-icon>
                    {{ registroComp.dist_encontrado.nombre }}
                  </p>
                  <p *ngIf="registroComp.dist_t_nit_cc != '' && registroComp.dist_t_nit_cc?.length > 0 && registroComp.dist_encontrado.nombre == ''"
                    class="m-0">
                    <fa-icon [icon]="faTimes" [styles]="{'color': '#41403E'}"></fa-icon>
                    No se encontró el NIT ingresado
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Propietario o representante legal-->
  <div [hidden]="registroComp.distribiudor_perfil_seleccionado !== 'Propietario o representante legal'">
    <div *ngIf="registroComp.step == 4">
      <div class="formulario-propietario p-4" [formGroup]="distribuidorForm">
        <!--Tipo de documento-->
        <div class="propietario-documento">
          <label for="tipo documento"><span class="asterisco">*</span>Tipo de documento de identidad</label>
          <select formControlName="distribuidorTipo" class="d-inline-block dropdown-form-1"
            [(ngModel)]="registroComp.dist_tipo_documento " (change)="registroComp.dist_numero_documento = ''"
            [ngClass]="{'invalid-warning': ((registroComp.dist_tipo_documento  == '' || registroComp.dist_tipo_documento  == 'Selecciona') && distribuidorForm.get('distribuidorTipo')?.touched)}">
            <option selected disabled value="">Selecciona</option>
            <option value="Cédula de ciudadanía">Cédula de ciudadanía</option>
            <option value="Cédula de extranjería">Cédula de extranjería</option>
            <option value="Pasaporte">Pasaporte</option>
          </select>
        </div>
        <!--Número de documento-->
        <div class="propietario-cedula">
          <label for="documento"><span class="asterisco">*</span>Número de documento de identidad</label>
          <input *ngIf="registroComp.dist_tipo_documento === 'Pasaporte'" placeholder="Escribe aquí"
            [(ngModel)]="registroComp.dist_numero_documento" class="w-100" formControlName="distribuidorCedula" value=""
            [ngClass]="{'invalid-warning': (distribuidorForm.get('distribuidorCedula')?.hasError('required') && distribuidorForm.get('distribuidorCedula')?.touched)}" />
          <input *ngIf="registroComp.dist_tipo_documento !== 'Pasaporte'" placeholder="Escribe aquí"
            [(ngModel)]="registroComp.dist_numero_documento" class="w-100" formControlName="distribuidorCedula" value=""
            (keydown)="validateNumber($event)"
            [ngClass]="{'invalid-warning': (distribuidorForm.get('distribuidorCedula')?.hasError('required') && distribuidorForm.get('distribuidorCedula')?.touched)}" />
        </div>
        <!--Nombres-->
        <div class="propietario-nombre">
          <label for="nombres"><span class="asterisco">*</span>Nombres</label>
          <input placeholder="Escribe aquí" [(ngModel)]="registroComp.dist_nombres" class="w-100"
            formControlName="distribuidorNombre" (keydown)="validateOnlyText($event)"
            [ngClass]="{'invalid-warning': (distribuidorForm.get('distribuidorNombre')?.hasError('required') && distribuidorForm.get('distribuidorNombre')?.touched)}" />
        </div>
        <!--Apellidos-->
        <div class="propietario-apellido">
          <label for="apellidos"><span class="asterisco">*</span>Apellidos</label>
          <input placeholder="Escribe aquí" [(ngModel)]="registroComp.dist_apellidos" class="w-100"
            formControlName="distribuidorApellido" (keydown)="validateOnlyText($event)"
            [ngClass]="{'invalid-warning': (distribuidorForm.get('distribuidorApellido')?.hasError('required') && distribuidorForm.get('distribuidorApellido')?.touched)}" />
        </div>
        <!--País-->
        <div class="propietario-pais">
          <label for="país"><span class="asterisco">*</span>País</label>
          <select formControlName="distribuidorPais" class="d-inline-block dropdown-form-1"
            [(ngModel)]="registroComp.dist_pais"
            [ngClass]="{'invalid-warning': (distribuidorForm.get('distribuidorPais')?.hasError('required') && distribuidorForm.get('distribuidorPais')?.touched )}">
            <option selected disabled value="Colombia">Colombia</option>
          </select>
        </div>
        <!--Departamento-->
        <div class="propietario-departamento">
          <label for="departamento"><span class="asterisco">*</span>Departamento</label>
          <select formControlName="distribuidorDepartamento" class="d-inline-block dropdown-form-1"
            [(ngModel)]="registroComp.dist_departamento" (change)="getPlaces('distribuidor')"
            (change)="registroComp.dist_ciudad = ''"
            [ngClass]="{'invalid-warning': (distribuidorForm.get('distribuidorDepartamento')?.hasError('required') && distribuidorForm.get('distribuidorDepartamento')?.touched)}">
            <option disabled value="">Selecciona</option>
            <option [ngValue]="dpto.name" *ngFor="let dpto of departamentos">
              {{ dpto.name }}
            </option>
          </select>
        </div>
        <!--Ciudad-->
        <div class="propietario-ciudad">
          <label for="ciudad"><span class="asterisco">*</span>Ciudad</label>
          <select formControlName="distribuidorCiudad" class="d-inline-block dropdown-form-1"
            [(ngModel)]="registroComp.dist_ciudad"
            [ngClass]="{'invalid-warning': (distribuidorForm.get('distribuidorCiudad')?.hasError('required') && distribuidorForm.get('distribuidorCiudad')?.touched)}">
            <option disabled value="">Selecciona</option>
            <option value={{city.name}} *ngFor="let city of ciudades">{{city.name}}</option>
          </select>
        </div>
        <!--Celular-->
        <div class="propietario-celular w-100">
          <label for="celular"><span class="asterisco">*</span>Celular (10 dígitos)</label>
          <input type="number" placeholder="Escribe aquí" [(ngModel)]="registroComp.dist_celular" class="w-100"
          maxlength="10"
            formControlName="distribuidorCelular" (keydown)="validateNumber($event)"
            [ngClass]="{'invalid-warning': (distribuidorForm.get('distribuidorCelular')?.hasError('required') && distribuidorForm.get('distribuidorCelular')?.touched) || distribuidorForm.get('distribuidorCelular')?.hasError('pattern') && distribuidorForm.get('distribuidorCelular')?.touched}" />
          <span class="reactive-text text-danger"
            *ngIf="distribuidorForm.get('distribuidorCelular')?.hasError('pattern') && distribuidorForm.get('distribuidorCelular')?.touched">
            <span class="alerta">Númerico de 10 dígitos</span>
          </span>
        </div>
        <!--Teléfono-->
        <div class="propietario-telefono w-100">
          <label for="telefono">Teléfono (Opcional)</label>
          <input type="text" placeholder="Escribe aquí" (keydown)="validateNumber($event)"
            maxlength="10"
            formControlName="distribuidorTelefono" [(ngModel)]="registroComp.dist_telefono" class="w-100"
            [ngClass]="{'invalid-warning': (this.registroComp.dist_telefono != undefined && this.registroComp.dist_telefono != null && this.registroComp.dist_telefono.length >=  1 && distribuidorForm.get('distribuidorTelefono')?.touched) && this.registroComp.dist_telefono.length != 7 && this.registroComp.dist_telefono.length != 10 }" />
          <span class="reactive-text text-danger"
            *ngIf="(this.registroComp.dist_telefono != undefined  && this.registroComp.dist_telefono != null  && this.registroComp.dist_telefono.length >=  1 && distribuidorForm.get('distribuidorTelefono')?.touched) && this.registroComp.dist_telefono.length != 7 && this.registroComp.dist_telefono.length != 10">
            <span class="alerta">Númerico de 7 o 10 dígitos</span>
          </span>
        </div>
      
        <div class="propietario-credenciales">
          <h5>Credenciales de acceso</h5>
        </div>
        <!--Correo-->
        <div class="propietario-email w-100">
          <label for="correo"><span class="asterisco">*</span>Correo (con este ingresas)</label>
          <input placeholder="Escribe aquí" type="email" [(ngModel)]="registroComp.dist_correo" class="w-100"
          autocomplete="new-password"  
          formControlName="distribuidorCorreo" 
            [ngClass]="{'invalid-warning': (distribuidorForm.get('distribuidorCorreo')?.hasError('required') && distribuidorForm.get('distribuidorCorreo')?.touched) || distribuidorForm.get('distribuidorCorreo')?.hasError('email') && distribuidorForm.get('distribuidorCorreo')?.touched}" />
          <span class="reactive-text text-danger "
            *ngIf="distribuidorForm.get('distribuidorCorreo')?.hasError('email') && distribuidorForm.get('distribuidorCorreo')?.touched">
            <span class="alerta">Ingresa un email valido</span>
          </span>
        </div>
        <!--Contraseña-->
        <div class="propietario-contraseña w-100">
          <label for="contrasena"><span class="asterisco">*</span>Contraseña</label>
          <div class="w-100 row m-0 rounded"
            [ngClass]="{'invalid-warning': (distribuidorForm.get('distribuidorContraseña')?.hasError('required') && distribuidorForm.get('distribuidorContraseña')?.touched)}">
            <input [type]="fieldTextType ? 'text' : 'password'" id="password" name="password" placeholder="Escribe aquí"
              class="form-control input-pass" [(ngModel)]="registroComp.dist_contrasena" autocomplete="new-password"
              formControlName="distribuidorContraseña" />
            <span class="input-group-text">
              <fa-icon [icon]="faEyeSlash" size="1x" [styles]="{'color': 'black'}" (click)="mostrarOcultarPassword()"
                *ngIf="fieldTextType"></fa-icon>
              <fa-icon [icon]="faEye" size="1x" [styles]="{'color': 'black'}" (click)="mostrarOcultarPassword()"
                *ngIf="!fieldTextType"></fa-icon>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!--Paso 5-->
    <div [hidden]="registroComp.step !== 5" class="formulario-juridico p-4" [formGroup]="juridicoForm">
      <div class="w-100 juridico-info ">
        <h5>Sobre la empresa</h5>
        
      </div>
      <!--Nombre del distribuidor-->
      <div class="w-100 h-100 juridico-nombre-establecimiento">
        <label for="nombre establecimiento"><span class="asterisco">*</span>Nombre del distribuidor</label>
        <input type="text" placeholder="Escribe aquí" [(ngModel)]="registroComp.dist_nombre_distribuidor"
          formControlName="juridicoNombreEstablecimiento"
          [ngClass]="{'invalid-warning': ( juridicoForm.get('juridicoNombreEstablecimiento')?.hasError('required') && juridicoForm.get('juridicoNombreEstablecimiento')?.touched )}" />
      </div>
      <!--Razón social-->
      <div class="w-100 h-100 juridico-razon" *ngIf="registroComp.dist_tipo_persona == 'Jurídica'">
        <label for="razón social"><span class="asterisco">*</span>Razón social</label>
        <input placeholder="Escribe aquí" [(ngModel)]="registroComp.dist_razon_social" formControlName="juridicoRazon"
          [ngClass]="{'invalid-warning': ( registroComp.dist_razon_social == ''  && juridicoForm.get('juridicoRazon')?.touched )}" />
      </div>
      <!--NIT-->
      <div class="w-100 h-100 juridico-nit" *ngIf="registroComp.dist_tipo_persona == 'Jurídica'">
        <label for="nit"><span class="asterisco">*</span>NIT</label>
        <input type="text" placeholder="Escribe aquí" (keydown)="validateNumber($event)"
          [(ngModel)]="registroComp.dist_nit" formControlName="juridicoNit"
          [ngClass]="{'invalid-warning': ( registroComp.dist_nit == '' && juridicoForm.get('juridicoNit')?.touched )}" />
      </div>
      <!--Tipo de negocio-->
      <div class="juridico-tipo">
        <label for="tipo_negocio"><span class="asterisco">*</span>Tipo de distribuidor</label>
        <select formControlName="juridicoEstablecimiento" class="d-inline-block dropdown-form-1"
          [(ngModel)]="registroComp.dist_tipo"
          [ngClass]="{'invalid-warning': ((registroComp.dist_tipo == '' || registroComp.dist_tipo == 'Selecciona') && juridicoForm.get('juridicoEstablecimiento')?.touched)}">
          <option selected disabled value="">Selecciona</option>
          <option value="Bebidas">Bebidas</option>
          <option value="Frutas y verduras">Frutas y verduras</option>
          <option value="Licores">Licores</option>
          <option value="Cárnicos">Cárnicos</option>
          <option value="Lácteos">Lácteos</option>
          <option value="Aseo y otros">Aseo y otros</option>
          <option value="Maquinaria e implementos">Maquinaria e implementos</option>
          <option value="Especializado - general">Especializado - general</option>
        </select>
      </div>
      <!--Descripción del distribuidor-->
      <div class="juridico-descripcion">
        <label for="apellidos"><span class="asterisco">*</span>Descripción del distribuidor y datos de cuenta bancaria</label>
        <textarea #text placeholder="Escribe aquí" [(ngModel)]="registroComp.dist_descripcion"
          class="w-100 p-2 text-secondary" formControlName="juridicoDescripcion" data-attr="500" required="true"
          value="500" maxlength="500" (ngModelChange)="determinarCaracteres()"
          [ngModelOptions]="{standalone: true}"
          [ngClass]="{'invalid-warning':  (registroComp.dist_descripcion.toString().length == 0 && juridicoForm.get('juridicoDescripcion')?.touched) || (juridicoForm.get('juridicoDescripcion')?.hasError('pattern') && juridicoForm.get('juridicoDescripcion')?.touched)}"></textarea>
        <div class="w-100 fs-6 fw-light">
          <span id="characterCount">Caracteres restantes: {{remainingText}}</span>
        </div>
        <span class="reactive-text text-danger"
          *ngIf="juridicoForm.get('juridicoDescripcion')?.hasError('pattern') && juridicoForm.get('juridicoDescripcion')?.touched">
          <span class="alerta">Mínimo 20 carácteres</span>
        </span>
      </div>
      <!--País-->
      <div class="w-100 h-100 juridico-pais">
        <label for="país"><span class="asterisco">*</span>País</label>
        <select formControlName="juridicoPais" class="d-inline-block dropdown-form-1"
          [(ngModel)]="registroComp.dist_pais_empresa"
          [ngClass]="{'invalid-warning': (juridicoForm.get('juridicoPais')?.hasError('required') && juridicoForm.get('juridicoPais')?.touched )}">
          <option selected disabled value="Colombia">Colombia</option>
        </select>
      </div>
      <!--Departamento-->
      <div class="w-100 h-100 juridico-departamento">
        <label for="departamento"><span class="asterisco">*</span>Departamento</label>
        <select formControlName="juridicoDepartamento" class="d-inline-block dropdown-form-1"
          [(ngModel)]="registroComp.dist_departamento_empresa" (change)="getPlaces('juridico')"
           (change)="registroComp.dist_ciudad_empresa = ''"
          [ngClass]="{'invalid-warning': (juridicoForm.get('juridicoDepartamento')?.hasError('required') && juridicoForm.get('juridicoDepartamento')?.touched)}">
          <option selected disabled value="">Selecciona</option>
          <option [ngValue]="dpto.name" *ngFor="let dpto of departamentos">
            {{ dpto.name }}
          </option>
        </select>
      </div>
      <!--Ciudad-->
      <div class="w-100 h-100 juridico-ciudad">
        <label for="ciudad"><span class="asterisco">*</span>Ciudad</label>
        <select formControlName="juridicoCiudad" class="d-inline-block dropdown-form-1"
          (change)="buscarDireccion()"
          [(ngModel)]="registroComp.dist_ciudad_empresa" 
          [ngClass]="{'invalid-warning': (juridicoForm.get('juridicoCiudad')?.hasError('required') && juridicoForm.get('juridicoCiudad')?.touched)}">
          <option selected disabled value="">Selecciona</option>
          <option value={{city.name}} *ngFor="let city of ciudades_empresa">{{city.name}}</option>
        </select>
      </div>
      <!--Dirección-->
      <div class="juridico-direccion">
        <label for="nombres"><span class="asterisco">*</span>Dirección</label>
        <input #direccionRegistro placeholder="Escribe aquí" [(ngModel)]="registroComp.dist_direccion" class="w-100"
          formControlName="juridicoDireccion" (change)="buscarDireccion()"
          (keyup.enter)="enterDireccion()"
          [ngClass]="{'invalid-warning': (juridicoForm.get('juridicoDireccion')?.hasError('required') && juridicoForm.get('juridicoDireccion')?.touched)}" />
        </div>
      <!--Mapa-->
      <div class="juridico-mapa">
        <label for="apellidos"><span class="asterisco">*</span>Confirma el punto en el mapa</label>
        <agm-map #AgmMapNat fit="true" [zoom]="12" [latitude]="map.lat" [longitude]="map.lng"
          style="width: 100%; height: 15rem;" (mapReady)="mapReadyHandler($event)">
          <agm-marker [latitude]="map.lat" [longitude]="map.lng"></agm-marker>
        </agm-map>
      </div>
      <!--Celular empresa 1-->
      <div class="w-100 h-100 juridico-telefono">
        <label for="celular"><span class="asterisco">*</span>Teléfono empresa</label>
        <input type="number" placeholder="Escribe aquí" (keydown)="validateNumber($event)"
          [(ngModel)]="registroComp.dist_celular_empresa" formControlName="juridicoTelefono"
          maxlength="10"
          [ngClass]="{'invalid-warning': ( juridicoForm.get('juridicoTelefono')?.hasError('required') && juridicoForm.get('juridicoTelefono')?.touched ) || ( juridicoForm.get('juridicoTelefono')?.hasError('pattern') && juridicoForm.get('juridicoTelefono')?.touched )}" />
        <span class="w-100 text-danger fw-bold text-right"
          *ngIf="juridicoForm.get('juridicoTelefono')?.hasError('pattern') && juridicoForm.get('juridicoTelefono')?.touched">
          <span class="alerta">Númerico de 7 o 10 dígitos</span>
        </span>
      </div>
      <!--Teléfono emrpesa 2-->
      <div class="w-100 h-100 juridico-telefono-2">
        <label for="telefono">Teléfono empresa 2 (Opcional)</label>
        <input type="text" placeholder="Escribe aquí" (keydown)="validateNumber($event)"
        maxlength="10"
          formControlName="juridicoTelefono2" [(ngModel)]="registroComp.dist_telefono_empresa"
          [ngClass]="{'invalid-warning': (this.registroComp.dist_telefono_empresa != undefined && this.registroComp.dist_telefono_empresa != null && this.registroComp.dist_telefono_empresa.length >=  1 && juridicoForm.get('juridicoTelefono2')?.touched) && this.registroComp.dist_telefono_empresa.length != 7 && this.registroComp.dist_telefono_empresa.length != 10 }" />
        <span class="reactive-text text-danger"
          *ngIf="(this.registroComp.dist_telefono_empresa != undefined  && this.registroComp.dist_telefono_empresa != null  && this.registroComp.dist_telefono_empresa.length >=  1 && juridicoForm.get('juridicoTelefono2')?.touched) && this.registroComp.dist_telefono_empresa.length != 7 && this.registroComp.dist_telefono_empresa.length != 10">
          <span class="alerta">Númerico de 7 o 10 dígitos</span>
        </span>
      </div>
      <div class="w-100 h-100">
        <label for="juridico-url-pago">Url de pago para pedidos (Opcional)</label>
        <input type="text" placeholder="Escribe aquí"
          formControlName="url_pago" [(ngModel)]="registroComp.url_pago"/>
      </div>
      <!--Información del propietario-->
      <div class="w-100 h-100 mt-2 juridico-info-propietario">
        <h5>Información del propietario</h5>
        <p class="subtitulo-juridico p-0 m-0 cursorPointer" (click)="soy_propietario = !soy_propietario"
          (click)="soy_propietario && duplicarDatosPropeitario(false)"
          (click)="!soy_propietario && borrarDuplicadoDatosPropeitario()">
          {{soy_propietario ? 'No soy el propietario' : 'Yo soy el propietario'}}
        </p>
      </div>
      <!--Tipo de documento-->
      <div class="w-100 h-100 juridico-propietario-documento">
        <label for="tipo documento"><span class="asterisco">*</span>Tipo de documento de identidad</label>
        <select formControlName="juridicoPropietarioTipo" class="d-inline-block dropdown-form-1"
          [(ngModel)]="registroComp.dist_tipo_doc_identidad_prop" (change)="registroComp.dist_doc_identidad_prop = ''"
          [ngClass]="{'invalid-warning': ((this.registroComp.dist_tipo_doc_identidad_prop == '' || this.registroComp.dist_tipo_doc_identidad_prop == 'Selecciona') && juridicoForm.get('juridicoPropietarioTipo')?.touched)}">
          <option value="Cédula de ciudadanía">Cédula de ciudadanía</option>
          <option value="Cédula de extranjería">Cédula de extranjería</option>
          <option value="Pasaporte">Pasaporte</option>
        </select>
      </div>
      <!--Número de documento-->
      <div class="w-100 h-100 juridico-propietario-cedula">
        <label for="documento"><span class="asterisco">*</span>Número de documento de identidad</label>
        <input *ngIf="registroComp.dist_tipo_doc_identidad_prop === 'Pasaporte'" placeholder="Escribe aquí"
          [(ngModel)]="registroComp.dist_doc_identidad_prop" formControlName="juridicoPropietarioCedula"
          [ngClass]="{'invalid-warning': ( juridicoForm.get('juridicoPropietarioCedula')?.hasError('required') && juridicoForm.get('juridicoPropietarioCedula')?.touched )}" />
        <input *ngIf="registroComp.dist_tipo_doc_identidad_prop !== 'Pasaporte'" placeholder="Escribe aquí"
          [(ngModel)]="registroComp.dist_doc_identidad_prop" formControlName="juridicoPropietarioCedula"
          (keydown)="validateNumber($event)"
          [ngClass]="{'invalid-warning': ( juridicoForm.get('juridicoPropietarioCedula')?.hasError('required') && juridicoForm.get('juridicoPropietarioCedula')?.touched )}" />
      </div>
      <!--Nombres-->
      <div class="w-100 h-100 juridico-propietario-nombre">
        <label for="nombres"><span class="asterisco">*</span>Nombres</label>
        <input placeholder="Escribe aquí" [(ngModel)]="registroComp.dist_nombres_prop"
          formControlName="juridicoPropietarioNombre" (keydown)="validateOnlyText($event)"
          [ngClass]="{'invalid-warning': ( juridicoForm.get('juridicoPropietarioNombre')?.hasError('required') && juridicoForm.get('juridicoPropietarioNombre')?.touched )}" />
      </div>
      <!--Apellidos-->
      <div class="w-100 h-100 juridico-propietario-apellido">
        <label for="apellidos"><span class="asterisco">*</span>Apellidos</label>
        <input placeholder="Escribe aquí" [(ngModel)]="registroComp.dist_apellidos_prop"
          formControlName="juridicoPropietarioApellido" (keydown)="validateOnlyText($event)"
          [ngClass]="{'invalid-warning': ( juridicoForm.get('juridicoPropietarioApellido')?.hasError('required') && juridicoForm.get('juridicoPropietarioApellido')?.touched )}" />
      </div>
      <!--Telefono-->
      <div class="w-100 h-100 juridico-propietario-telefono">
        <label for="documento"><span class="asterisco">*</span>Teléfono propietario</label>
        <input placeholder="Escribe aquí" [(ngModel)]="registroComp.dist_telefono_prop"
        maxlength="10"
          formControlName="juridicoPropietarioTelefono" (keydown)="validateNumber($event)"
          [ngClass]="{'invalid-warning': ( juridicoForm.get('juridicoPropietarioTelefono')?.hasError('required') && juridicoForm.get('juridicoPropietarioTelefono')?.touched ) || ( juridicoForm.get('juridicoPropietarioTelefono')?.hasError('pattern') && juridicoForm.get('juridicoPropietarioTelefono')?.touched )}" />
        <span class="w-100 text-danger fw-bold text-right"
          *ngIf="juridicoForm.get('juridicoPropietarioTelefono')?.hasError('pattern') && juridicoForm.get('juridicoPropietarioTelefono')?.touched">
          <span class="alerta">Númerico de 7 o 10 dígitos</span>
        </span>
      </div>
      <!--Correo-->
      <div class="w-100 h-100 juridico-propietario-correo">
        <label for="correo"><span class="asterisco">*</span>Correo</label>
        <input placeholder="Escribe aquí" type="email" [(ngModel)]="registroComp.dist_correo_prop"
          formControlName="juridicoPropietarioCorreo"
          [ngClass]="{'invalid-warning': ( juridicoForm.get('juridicoPropietarioCorreo')?.hasError('required') && juridicoForm.get('juridicoPropietarioCorreo')?.touched ) || ( juridicoForm.get('juridicoPropietarioCorreo')?.hasError('email') && juridicoForm.get('juridicoPropietarioCorreo')?.touched )}" />
        <span class="reactive-text text-danger "
          *ngIf="juridicoForm.get('juridicoPropietarioCorreo')?.hasError('email') && juridicoForm.get('juridicoPropietarioCorreo')?.touched">
          <span class="alerta">Ingresa un email valido</span>
        </span>
      </div>
    </div>
    <!--Paso 6-->
    <div *ngIf="registroComp.step == 6">
      <div class="card card-step">
        <div class="card-body p-4 d-flex flex-wrap formulario-dias-atencion"
          [formGroup]="establecimientoTiempoEntregaForm">
          <label class="w-100">
            <span class="asterisco">*</span>Días de atención
          </label>
          <span class="w-100 text-danger fw-bold text-left rounded"
            *ngIf="validatorDiasAtencionError == true && establecimientoTiempoEntregaForm.get('establecimeintoAtencionSemana')?.touched">
            <span class="alerta">Debe seleccionar por lo menos una opción</span>
          </span>
          <!--Lunes a viernes-->
          <div class="w-100">
            <mat-checkbox formControlName="establecimeintoAtencionSemana" class="example-margin"
              [(ngModel)]="registroComp.dist_lunes_a_viernes">
              Lunes a viernes
            </mat-checkbox>
          </div>
          <div class="d-flex w-100 flex-column">
            <div class="d-flex w-100 flex-column custom-slider">
              <ngx-slider [(value)]="minValueEntreSemana" [(highValue)]="maxValueEntreSemana" [options]="options">
              </ngx-slider>
              <div class="w-100">
                <p class="w-100 text-right mt-2 mb-1">
                  {{ valueToTime(minValueEntreSemana) }} - {{ valueToTime(maxValueEntreSemana) }}
                </p>
              </div>
            </div>
          </div>
          <!--Sábados-->
          <div class="w-100">
            <mat-checkbox formControlName="establecimeintoAtencionSabado" class="example-margin"
              [(ngModel)]="registroComp.dist_sabados">
              Sábados
            </mat-checkbox>
          </div>
          <div class="d-flex w-100 flex-column">
            <div class="d-flex w-100 flex-column">
              <div class="w-100 custom-slider">
                <ngx-slider [(value)]="minValueSabados" [(highValue)]="maxValueSabados" [options]="options">
                </ngx-slider>
              </div>
              <div class="w-100">
                <p class="w-100 text-right mt-2 mb-1">
                  {{ valueToTime(minValueSabados) }} - {{ valueToTime(maxValueSabados) }}
                </p>
              </div>
            </div>
          </div>
          <!--Domingos-->
          <div class="w-100">
            <mat-checkbox formControlName="establecimeintoAtencionDomingo" class="example-margin"
              [(ngModel)]="registroComp.dist_domingos">
              Domingos
            </mat-checkbox>
          </div>
          <div class="d-flex w-100 flex-column mb-4">
            <div class="d-flex w-100 flex-column">
              <div class="w-100 custom-slider">
                <ngx-slider [(value)]="minValueDomingos" [(highValue)]="maxValueDomingos" [options]="options">
                </ngx-slider>
              </div>
              <div class="w-100">
                <p class="w-100 text-right mt-2 mb-1">
                  {{ valueToTime(minValueDomingos) }} - {{ valueToTime(maxValueDomingos) }}
                </p>
              </div>
            </div>
          </div>
          <!--Medios de pago aceptados-->
          <div class=" d-flex w-100 flex-wrap">
            <div class="w-100 d-flex flex-column mb-2">
              <span class="w-100 text-left"><span class="asterisco">*</span>Medios de pago</span>
              <span class="w-100 text-danger fw-bold text-left rounded"
                *ngIf="validatorTiempoEntregaError == true && establecimientoTiempoEntregaForm.get('establecimeintoMetodoPagoCredito')?.touched">
                <span class="alerta">Debe seleccionar por lo menos un método</span>
              </span>
            </div>
            <div class="col-md-6 col-sm-12">
              <mat-checkbox formControlName="establecimeintoMetodoPagoCredito" class="example-margin" name="credito"
                [(ngModel)]="registroComp.dist_credito">
                Crédito
              </mat-checkbox>
            </div>
            <div class="col-md-6 col-sm-12">
              <mat-checkbox formControlName="establecimeintoMetodoPagoEfectivo" class="example-margin" name="efectivo"
                [(ngModel)]="registroComp.dist_efectivo">
                Efectivo (contra entrega)
              </mat-checkbox>
            </div>
            <div class="col-md-6 col-sm-12">
              <mat-checkbox formControlName="establecimeintoMetodoPagoTransferencia" class="example-margin" name="banco"
                [(ngModel)]="registroComp.dist_transaccion">
                Transacción bancaria
              </mat-checkbox>
            </div>
            <div class="col-md-6 col-sm-12">
              <mat-checkbox formControlName="establecimeintoMetodoPagoDatafono" class="example-margin" name="datafono"
                [(ngModel)]="registroComp.dist_datafono">
                Datáfono (contra entrega)
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Paso 7-->
    <div *ngIf="registroComp.step == 7">
      <div class="card card-step">
        <div class="card-body p-4 cobertura_paso_siete" [formGroup]="establecimientoCoberturaForm">
          <div class="cobertura_titulo">
            <h5>
              Indica el lugar de la zona de cobertura
            </h5>
            <p class="text-secondary">
              Recuerda que en esta pantalla no puedes modificar la información. Si quieres editar algo por favor regresa
              al paso anterior.
            </p>
          </div>
          <!--País-->
          <div class="cobertura_pais">
            <label for="país">País</label>
            <input [readonly]="true" formControlName="coberturaPais" name="pais" placeholder="Escribe aquí"
              [(ngModel)]="registroComp.dist_pais_empresa" />
          </div>
          <!--Departamento-->
          <div class="cobertura_departamento">
            <label for="departamento">Departamento</label>
            <input [readonly]="true" formControlName="coberturaDepartamento" name="departamento"
              placeholder="Escribe aquí" [(ngModel)]="registroComp.dist_departamento_empresa" />
          </div>
          <!--Ciudad-->
          <div class="cobertura_ciudad">
            <label for="ciudad">Ciudad</label>
            <input [readonly]="true" formControlName="coberturaCiudad" name="ciudad" placeholder="Escribe aquí"
              [(ngModel)]="registroComp.dist_ciudad_empresa" />
          </div>
          <!--Dirección-->
          <div class="cobertura_dirección">
            <label for="direccion">Dirección</label>
            <input [readonly]="true" formControlName="coberturaDireccion" name="direccion" placeholder="Escribe aquí"
              [(ngModel)]="registroComp.dist_direccion" />
          </div>
          <div class="cobertura_departamento_zona">
            <label for="departamento">
              <span class="asterisco">*</span>
              Departamento zona
            </label>
            <select
              [(ngModel)]="departamentoPoligono"
              [ngModelOptions]="{standalone: true}"
              (change)="getPlacesPoligonos()">
              <option [ngValue]="dpto.name" *ngFor="let dpto of places.departments_colombia">
                {{ dpto.name }}
              </option>
            </select>
          </div>
          <div class="cobertura_ciudad_zona">
            <label for="ciudad">
              <span class="asterisco">*</span>
              Ciudad zona
            </label>
            <select
              [(ngModel)]="ciudadPoligono"
              [ngModelOptions]="{standalone: true}"
              (change)="generarDireccionPoligono(true)">
              <option selected disabled value="">Selecciona</option>
              <option value="{{ city.name }}" *ngFor="let city of ciudadesPoligono">{{ city.name }}</option>
            </select>
          </div>
          <!--Mapa de area de cobertura-->
          <div class="cobertura_mapa d-flex flex-column">
            <label class="w-100">
              <span class="asterisco">*</span>Confirma los puntos limítrofes para establecer las zonas de cobertura, seleccionando en el mapa los lugares que van a pertenecer a dicha cobertura formando una especie de figura
              <div>
                <span class="text-secondary" style="font-size: 14px">
                  Los marcadores rojos indican las ubicaciones de los puntos de entrega de los establecimientos
                </span>
              </div>
            </label>
            <div class="w-100">
              <agm-map
                #AgmMapArea
                [latitude]="mapPoligono.lat"
                [longitude]="mapPoligono.lng"
                (mapReady)="onMapReady($event)"
                [zoom]="10"
                style="width: 100%; height: 20rem;">
                <agm-marker *ngFor="let coord of coordenadasPuntos" [latitude]="coord.lat" [longitude]="coord.lng"></agm-marker>
                <agm-polygon
                [fillOpacity]="0.5" [paths]="paths" [strokeOpacity]="0.6" [strokeWeight]="2"
                [visible]="true" [zIndex]="1"></agm-polygon>
              </agm-map>
            </div>
            <span class="w-100 text-danger fw-bold text-left rounded"
              *ngIf="coordMulti.length == 0 && establecimientoCoberturaForm.get('coberturaDireccion')?.touched">
              <span class="alerta">Debe seleccionar el área de cobertura para poder continuar</span>
            </span>
          </div>
        </div>
        <div class="form-group" *ngIf="coordMulti.length > 0">
          <div class="row datos-coordenadas mb-3 justify-content-center" *ngFor="let coordData of coordMulti; let i = index;">
            <div class="col-md-1 text-center centrar-contenido">
              <div class="item-numero-poligono" [style]="{ 'background-color': listaColores[i], 'border': '1px solid '+ listaColores[i] }">{{ (i + 1) }}</div>
            </div>
            <div class="col-md-3">
              <label>Tipo de tiempo</label>
              <select *ngIf="i === 0" class="dropdown-form-1" [(ngModel)]="valoresPedido[i].tipo_promesa" [ngModelOptions]="{standalone: true}">
                <option value="horas">Horas</option>
              </select>
              <div *ngIf="i > 0">--/--</div>
            </div>
            <div class="col-md-3">
              <label>Promesa entrega</label>
              <div *ngIf="i === 0">
                <select *ngIf="valoresPedido[i].tipo_promesa === 'horas'" class="dropdown-form-1" [(ngModel)]="valoresPedido[i].valor_promesa" [ngModelOptions]="{standalone: true}">
                  <option *ngFor="let hour of listaHoras" value="{{hour}}">{{hour}}</option>
                </select>
                <select *ngIf="valoresPedido[i].tipo_promesa === 'dias'" class="dropdown-form-1" [(ngModel)]="valoresPedido[i].valor_promesa" [ngModelOptions]="{standalone: true}">
                  <option *ngFor="let dia of listaDias" value="{{dia}}">{{dia}}</option>
                </select>
                <select *ngIf="valoresPedido[i].tipo_promesa === 'especifico'" class="dropdown-form-1" [(ngModel)]="valoresPedido[i].valor_promesa" [ngModelOptions]="{standalone: true}">
                  <option *ngFor="let diaS of diasSemana" value="{{diaS}}">{{diaS}}</option>
                </select>
              </div>
              <div *ngIf="i > 0">--/--</div>
            </div>
            <div class="col-md-3">
              <label>Valor Min Pedido</label>
              <input
                *ngIf="i === 0"
                class="w-100"
                placeholder="Valor mínimo"
                [(ngModel)]="valoresPedido[i].valor_pedido"
                [ngModelOptions]="{standalone: true}"
                type="text"
                (keydown)="validateNumber($event)"
                (blur)="transformAmountValue(i, $event)" (focus)="transformCurrencyValue(i, $event)"/>
              <div *ngIf="i > 0">--/--</div>
            </div>
            <div class="col-md-1 text-center content-icon-remove">
              <fa-icon [icon]="faTimesCircle" class="icon-remove-point" (click)="removePolygon(i)"></fa-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Paso 8-->
    <div *ngIf="registroComp.step == 8">
      <div class="w-100">
        <div class="card p-4">
          <div class="w-100 mb-3">
            <h5>
              Carga los siguientes documentos para la verificación
              <h6 class="text-secondary font-weight-light">Máximo 2MB por archivo</h6>
            </h5>
          </div>
          <div class="document-upload-container row text-center p-4">
            <!--RUT-->
            <div class="upload-rut">
              <input type="file" class="file-upload" (change)="handleFileInput($event, 0)" #fileUploadRUT />
              <button class="btn-upload h-100 d-flex flex-column align-items-center justify-content-start pt-4"
                (click)="fileUploadRUT.click()">
                <fa-icon [icon]="faUpload" size="2x" [styles]="{'color': '#41403E'}"
                  class="d-flex justify-content-center mb-2 w-100" pull="center"></fa-icon>
                RUT
                <div *ngIf="registroComp.dist_archivos[0] != null">
                  <p class="break-word">
                    <strong>
                      {{ registroComp.dist_archivos[0].name }}
                    </strong>
                  </p>
                </div>
              </button>
            </div>
            <!--Cámara de comercio-->
            <div class="upload-camara">
              <input type="file" class="file-upload" (change)="handleFileInput($event, 1)" #fileUploadCamara />
              <button class="btn-upload h-100 d-flex flex-column align-items-center justify-content-start pt-4"
                (click)="fileUploadCamara.click()">
                <fa-icon [icon]="faUpload" size="2x" [styles]="{'color': '#41403E'}"
                  class="d-flex justify-content-center mb-2 w-100" pull="left"></fa-icon>
                Cámara de Comercio {{ registroComp.tipo_persona == 'Natural' ? '(opcional)' : '' }}
                <div *ngIf="registroComp.dist_archivos[1] != null">
                  <p class="break-word">
                    <strong>
                      {{ registroComp.dist_archivos[1].name }}
                    </strong>
                  </p>
                </div>
              </button>
            </div>
            <!--Documento de representante legal-->
            <div class="upload-representante">
              <input type="file" class="file-upload" (change)="handleFileInput($event, 2)" #fileUploadRepresentante />
              <button class="btn-upload h-100 d-flex flex-column align-items-center justify-content-start pt-4"
                (click)="fileUploadRepresentante.click()">
                <fa-icon [icon]="faUpload" size="2x" [styles]="{'color': '#41403E'}"
                  class="d-flex justify-content-center mb-2 w-100" pull="left"></fa-icon>
                Documento representante legal
                <div *ngIf="registroComp.dist_archivos[2] != null">
                  <p class="break-word">
                    <strong>
                      {{ registroComp.dist_archivos[2].name }}
                    </strong>
                  </p>
                </div>
              </button>
            </div>
            <!--Logo-->
            <div class="upload-logo">
              <input type="file" class="file-upload" (change)="handleFileInput($event, 3)" #fileUploadLogo />
              <button class="btn-upload" (click)="fileUploadLogo.click()">
                <fa-icon [icon]="faUpload" size="2x" [styles]="{'color': '#41403E'}"
                  class="d-flex justify-content-center mb-2 w-100" pull="center">
                </fa-icon>
                Logo
                <div *ngIf="logo_path != null">
                  <img id="logo" [src]="logo_path" alt="Logo" height="100px" />
                </div>
              </button>
            </div>
          </div>
          <div class="document-upload-container row text-center p-4">
            <!--Certificación bancaria-->
            <div class="upload-rut">
              <input type="file" class="file-upload" (change)="handleFileInput($event, 4)" #fileUploadBancaria />
              <button class="btn-upload h-100 d-flex flex-column align-items-center justify-content-start pt-4"
                (click)="fileUploadBancaria.click()">
                <fa-icon [icon]="faUpload" size="2x" [styles]="{'color': '#41403E'}"
                  class="d-flex justify-content-center mb-2 w-100" pull="left"></fa-icon>
                  Certificación Bancaria (opcional)
                <div *ngIf="registroComp.dist_archivos[4] != null">
                  <p class="break-word">
                    <strong>
                      {{ registroComp.dist_archivos[4].name }}
                    </strong>
                  </p>
                </div>
              </button>
            </div>
            <!--Creacion cliente-->
            <div class="upload-camara">
              <input type="file" class="file-upload" (change)="handleFileInput($event, 5)" #fileUploadCreacion />
              <button class="btn-upload h-100 d-flex flex-column align-items-center justify-content-start pt-4"
                (click)="fileUploadCreacion.click()">
                <fa-icon [icon]="faUpload" size="2x" [styles]="{'color': '#41403E'}"
                  class="d-flex justify-content-center mb-2 w-100" pull="left"></fa-icon>
                  Creación de Cliente (opcional)
                <div *ngIf="registroComp.dist_archivos[5] != null">
                  <p class="break-word">
                    <strong>
                      {{ registroComp.dist_archivos[5].name }}
                    </strong>
                  </p>
                </div>
              </button>
            </div>
            <!--Aprobacion de credito-->
            <div class="upload-representante">
              <input type="file" class="file-upload" (change)="handleFileInput($event, 6)" #fileUploadCredito />
              <button class="btn-upload h-100 d-flex flex-column align-items-center justify-content-start pt-4"
                (click)="fileUploadCredito.click()">
                <fa-icon [icon]="faUpload" size="2x" [styles]="{'color': '#41403E'}"
                  class="d-flex justify-content-center mb-2 w-100" pull="left"></fa-icon>
                  Aprobación de crédito (opcional)
                <div *ngIf="registroComp.dist_archivos[6] != null">
                  <p class="break-word">
                    <strong>
                      {{ registroComp.dist_archivos[6].name }}
                    </strong>
                  </p>
                </div>
              </button>
            </div>
          </div>
          <!--Aceptó términos y condiciones-->
          <div class="w-100 d-flex flex-wrap">
            <mat-checkbox class="example-margin" name="datafono" [(ngModel)]="registroComp.dist_acepto_terminos">
            </mat-checkbox>
            <label for="" class="pl-2 text-dark">
              Acepto los <a class="link-terminos cursorPointer"
                (click)="verDocumento(registroComp.politicas_condiciones[0].distribuidores.terminos)">Términos y
                Condiciones</a> y <a class=" link-terminos cursorPointer"
                (click)="verDocumento(registroComp.politicas_condiciones[0].distribuidores.politicas)">Políticas de
                Privacidad</a>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Trabajador-->
  <div *ngIf=" registroComp.distribiudor_perfil_seleccionado=='Trabajador'">
    <div *ngIf=" registroComp.step==4">
      <div class="formulario-establecimiento p-4" [formGroup]="establecimientoForm">
        <!--Card de resumen de empresa-->
        <div class="card establecimiento-resumen" id="resumen-empresa">
          <div class="card-body row">
            <div class="col-3 d-flex align-items-center justify-content-center">
              <!--Logo de la empresa-->
              <img [src]="registroComp.dist_encontrado.logo || distribuidor_placeholder" alt="Logo de la empresa"
                width="auto" height="80px">
            </div>
            <div class="col col-9 d-flex flex-column justify-content-center">
              <div>
                {{ registroComp.dist_encontrado.nombre }}
              </div>
              <div>
                CC/NIT: {{ registroComp.dist_encontrado.nit_cc }}
              </div>
            </div>
          </div>
        </div>
        <!--Tipo de documento de identidad-->
        <div class="establecimiento-documento w-100">
          <label for="t tipo documento"><span class="asterisco">*</span>Tipo de documento de
            identidad</label><br>
          <select formControlName="establecimientoTipo" class="d-inline-block dropdown-form-1"
            [(ngModel)]="registroComp.dist_t_tipo_doc" (change)="registroComp.dist_t_num_doc = ''"
            [ngClass]="{'invalid-warning': ( establecimientoForm.get('establecimientoTipo')?.hasError('required')  && establecimientoForm.get('establecimientoTipo')?.touched )}">
            <option disabled value="">Selecciona</option>
            <option value="Cédula de ciudadanía">Cédula de ciudadanía</option>
            <option value="Cédula de extranjería">Cédula de extranjería</option>
            <option value="Pasaporte">Pasaporte</option>
          </select>
        </div>
        <!--Número de documento de identidad-->
        <div class="establecimiento-cedula w-100">
          <label for="t documento"><span class="asterisco">*</span>Número de documento de identidad</label>
          <input *ngIf="registroComp.dist_t_tipo_doc === 'Pasaporte'" placeholder="Escribe aquí"
            [(ngModel)]="registroComp.dist_t_num_doc" class="w-100 input-nit" formControlName="establecimientoCedula"
            [ngClass]="{'invalid-warning': ( establecimientoForm.get('establecimientoCedula')?.hasError('required') && establecimientoForm.get('establecimientoCedula')?.touched )}" />
          <input *ngIf="registroComp.dist_t_tipo_doc !== 'Pasaporte'" placeholder="Escribe aquí"
            [(ngModel)]="registroComp.dist_t_num_doc" class="w-100 input-nit" formControlName="establecimientoCedula"
            (keydown)="validateNumber($event)"
            [ngClass]="{'invalid-warning': ( establecimientoForm.get('establecimientoCedula')?.hasError('required') && establecimientoForm.get('establecimientoCedula')?.touched )}" />
        </div>
        <!--Nombres-->
        <div class="establecimiento-nombre w-100">
          <label for="t nombres"><span class="asterisco">*</span>Nombres</label>
          <input placeholder="Escribe aquí" [(ngModel)]="registroComp.dist_t_nombres" class="w-100 input-nit"
            formControlName="establecimientoNombre" (keydown)="validateOnlyText($event)"
            [ngClass]="{'invalid-warning': ( establecimientoForm.get('establecimientoNombre')?.hasError('required') && establecimientoForm.get('establecimientoNombre')?.touched )}" />
        </div>
        <!--Apellidos-->
        <div class="establecimiento-apellido w-100">
          <label for="t apellidos"><span class="asterisco">*</span>Apellidos</label>
          <input placeholder="Escribe aquí" [(ngModel)]="registroComp.dist_t_apellidos" class="w-100 input-nit"
            formControlName="establecimientoApellido" (keydown)="validateOnlyText($event)"
            [ngClass]="{'invalid-warning': ( establecimientoForm.get('establecimientoApellido')?.hasError('required') && establecimientoForm.get('establecimientoApellido')?.touched )}" />
        </div>
        <!--País-->
        <div class="establecimiento-pais w-100">
          <label for="país"><span class="asterisco">*</span>País</label><br>
          <select formControlName="establecimientoPais" class="d-inline-block dropdown-form-1"
            [(ngModel)]="registroComp.dist_t_pais "
            [ngClass]="{'invalid-warning': ( establecimientoForm.get('establecimientoPais')?.hasError('required') && establecimientoForm.get('establecimientoPais')?.touched )}">
            <option selected disabled value="Colombia">Colombia</option>
          </select>
        </div>
        <!--Departamento-->
        <div class="establecimiento-departamento w-100">
          <label for="departamento"><span class="asterisco">*</span>Departamento</label>
          <select formControlName="establecimientoDepartamento" class="d-inline-block dropdown-form-1"
            [(ngModel)]="registroComp.dist_t_departamento" (change)="getPlaces('establecimiento')"
            (change)="registroComp.dist_t_ciudad = ''"
            [ngClass]="{'invalid-warning': ( establecimientoForm.get('establecimientoDepartamento')?.hasError('required') && establecimientoForm.get('establecimientoDepartamento')?.touched )}">
            <option [ngValue]="dpto.name" *ngFor="let dpto of places.departments_colombia">
              {{ dpto.name }}
            </option>
          </select>
        </div>
        <!--Ciudad-->
        <div class="establecimiento-ciudad w-100">
          <label for="ciudad"><span class="asterisco">*</span>Ciudad</label>
          <select formControlName="establecimientoCiudad" class="d-inline-block dropdown-form-1"
            [(ngModel)]="registroComp.dist_t_ciudad"
            [ngClass]="{'invalid-warning': ( establecimientoForm.get('establecimientoCiudad')?.hasError('required') && establecimientoForm.get('establecimientoCiudad')?.touched )}">
            <option selected disabled value="">Selecciona</option>
            <option value={{city.name}} *ngFor="let city of ciudades_trab">{{city.name}}</option>
          </select>
        </div>
        <!--Celular-->
        <div class="establecimiento-celular w-100">
          <label for="celular"><span class="asterisco">*</span>Celular (10 dígitos)</label>
          <input type="number" placeholder="Escribe aquí" [(ngModel)]="registroComp.dist_t_celular"
          maxlength="10"
            class="w-100 input-nit" formControlName="establecimientoCelular" (keydown)="validateNumber($event)"
            [ngClass]="{'invalid-warning': ( establecimientoForm.get('establecimientoCelular')?.hasError('required') && establecimientoForm.get('establecimientoCelular')?.touched ) || ( establecimientoForm.get('establecimientoCelular')?.hasError('pattern') && establecimientoForm.get('establecimientoCelular')?.touched )}" />
          <span class="reactive-text text-danger"
            *ngIf="establecimientoForm.get('establecimientoCelular')?.hasError('pattern') && establecimientoForm.get('establecimientoCelular')?.touched">
            <span class="alerta">Númerico 10 dígitos</span>
          </span>
        </div>
        <!--Teléfono-->
        <div class="establecimiento-telefono w-100">
          <label for="telefono">Teléfono (Opcional)</label>
          <input type="text" placeholder="Escribe aquí" [(ngModel)]="registroComp.dist_t_telefono"
          maxlength="10"
            (keydown)="validateNumber($event)" class="w-100 input-nit" formControlName="establecimientoTelefono"
            [ngClass]="{'invalid-warning': (this.registroComp.dist_t_telefono != undefined && this.registroComp.dist_t_telefono != null && this.registroComp.dist_t_telefono.length >=  1 && establecimientoForm.get('establecimientoTelefono')?.touched) && this.registroComp.dist_t_telefono.length != 7 && this.registroComp.dist_t_telefono.length != 10 }" />
          <span class="reactive-text text-danger"
            *ngIf="(this.registroComp.dist_t_telefono != undefined && this.registroComp.dist_t_telefono != null  && this.registroComp.dist_t_telefono.length >=  1 && establecimientoForm.get('establecimientoTelefono')?.touched) && this.registroComp.dist_t_telefono.length != 7 && this.registroComp.dist_t_telefono.length != 10">
            <span class="alerta">Númerico de 7 o 10 dígitos</span>
          </span>
        </div>
        <!--Tipo de usuario-->
        <div class="establecimiento-tipo w-100">
          <label for="tipo_usuario"><span class="asterisco">*</span>Tipo de usuario</label>
          <select formControlName="establecimientoEstablecimiento" class="d-inline-block dropdown-form-1"
            [(ngModel)]="registroComp.dist_t_tipo_usuario"
            [ngClass]="{'invalid-warning': ( establecimientoForm.get('establecimientoEstablecimiento')?.hasError('required')  && establecimientoForm.get('establecimientoEstablecimiento')?.touched )}">
            <option selected disabled value="">Selecciona</option>
            <option value="ADMINISTRADOR">ADMINISTRADOR</option>
            <option value="OPERATIVO COMERCIAL">OPERATIVO COMERCIAL</option>
            <option value="PROPIETARIO/REP LEGAL">PROPIETARIO/REP LEGAL</option>
          </select>
        </div>
      </div>
    </div>
    <div *ngIf="registroComp.step == 5">
      <div class="formulario-establecimiento-credenciales row p-4 m-0" [formGroup]="establecimientoCredencialesForm">
        <div class="establecimiento-credenciales w-100">
          <h5>
            Ingresa las credenciales con las cuales ingresarás a la plataforma
          </h5>
        </div>
        <!--Correo-->
        <div class="establecimiento-correo w-100">
          <label for="t correo" class="w-100"><span class="asterisco">*</span>Correo (con este ingresas)</label>
          <input placeholder="Escribe aquí" type="email" [(ngModel)]="registroComp.dist_t_correo"
            class="w-100 mt-0 input-nit" formControlName="establecimientoCorreo" autocomplete="new-password"
            [ngClass]="{'invalid-warning': ( establecimientoCredencialesForm.get('establecimientoCorreo')?.hasError('required') && establecimientoCredencialesForm.get('establecimientoCorreo')?.touched ) || ( establecimientoCredencialesForm.get('establecimientoCorreo')?.hasError('email') && establecimientoCredencialesForm.get('establecimientoCorreo')?.touched )}" />
          <span class="reactive-text text-danger "
            *ngIf="establecimientoCredencialesForm.get('establecimientoCorreo')?.hasError('email') && establecimientoCredencialesForm.get('establecimientoCorreo')?.touched">
            <span class="alerta">Ingresa un email valido</span>
          </span>
        </div>
        <!--Contraseña-->
        <div class="establecimiento-contraseña w-100">
          <label for="t contrasena" class="w-100"><span class="asterisco">*</span>Contraseña</label>
          <div class="w-100 row m-0 rounded"
            [ngClass]="{'invalid-warning': ( establecimientoCredencialesForm.get('establecimientoContraseña')?.hasError('required') && establecimientoCredencialesForm.get('establecimientoContraseña')?.touched )}">
            <input [type]="fieldTextType ? 'text' : 'password'" id="password" name="password" placeholder="Escribe aquí"
              class="form-control input-pass" autocomplete="new-password" [(ngModel)]="registroComp.dist_t_contrasena"
              formControlName="establecimientoContraseña" style="height: 42px !important" />
            <span class="input-group-text" style="height: 42px !important">
              <fa-icon [icon]="faEyeSlash" size="1x" [styles]="{'color': 'black'}" (click)="mostrarOcultarPassword()"
                *ngIf="fieldTextType"></fa-icon>
              <fa-icon [icon]="faEye" size="1x" [styles]="{'color': 'black'}" (click)="mostrarOcultarPassword()"
                *ngIf="!fieldTextType"></fa-icon>
            </span>
          </div>
        </div>
        <!--Aceptó términos y condiciones-->
        <div class="establecimiento-terminos w-100 d-flex align-items-start">
          <input type="checkbox" id="dist_t_acepto_terminos" name="dist_t_acepto_terminos"
            [(ngModel)]="registroComp.dist_t_acepto_terminos" class="col-1 text-center"
            formControlName="establecimientoTerminos"
            [ngClass]="{'invalid-warning': ( establecimientoCredencialesForm.get('establecimientoTerminos')?.hasError('required') && establecimientoCredencialesForm.get('establecimientoTerminos')?.touched )}">
          <label for="" class="col-11 text-dark">
            Acepto los <a class="link-terminos cursorPointer"
              (click)="verDocumento(registroComp.politicas_condiciones[0].distribuidores.terminos)">Términos y
              Condiciones</a> y <a class="link-terminos cursorPointer"
              (click)="verDocumento(registroComp.politicas_condiciones[0].distribuidores.politicas)">Políticas de
              Privacidad</a>
          </label>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- TEMPLATE TOAST FORUMARIO INVALIDO -->
<ngb-toast *ngFor="let toast of toastService.toasts" [class]="toast.classname" [autohide]="true"
  [delay]="toast.delay || 5000" (hidden)="toastService.remove(toast)">
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>
  <ng-template #text>{{ toast.textOrTpl }}</ng-template>
</ngb-toast>
<!-- BOTONOES DE NAVEGACIÓN -->
<div id="registro-nav" class="d-flex flex-row w-100 justify-content-around align-items-center py-4 fixed-bottom newTam"
*ngIf="registroComp.step !== 1">
  <button (click)="stepBack()" class="btn-volver d-flex flex-row justify-content-center align-items-center">
    <fa-icon [icon]="faChevronLeft" size="lg" class="mr-2"></fa-icon>
    Paso anterior
  </button>
  <button (click)="siguiente()" (click)="!isBotonHabilitado && checkIfFormIsInvalid()"
    Class="btn-continuar-enabled" [ngClass]="{'btn-continuar-disabled': (!isBotonHabilitado)}"
    *ngIf="!isFormularioCompleto">
    Continuar
  </button>
  <button Class="btn-continuar-enabled" *ngIf="isFormularioCompleto"
    (click)="registroComp.registroUsuarioDistribuidor()">
    Regístrate
  </button>
</div>