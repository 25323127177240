/* eslint-disable prefer-const */
import { Component, OnInit } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from '../../services/rest/rest.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import * as XLSX from 'xlsx';
import { CurrencyPipe } from '@angular/common';
import { LocalDataSource } from 'ng2-smart-table';
@Component({
  selector: 'app-pre-factura-pedido',
  templateUrl: './pre-factura-pedido.component.html',
  styleUrls: ['./pre-factura-pedido.component.scss'],
})
export class PreFacturaPedidoComponent implements OnInit {
  /** Referencias a íconos */
  public faChevronLeft = faChevronLeft;
  /** Id del pedido para generar el detalle de productos de l aprefactura*/
  public pedidoId: any;
  /** Guarda el tipo de usuario logueado */
  public tipo_usuario: any;
  /** Guarda los datos de la prefactura */
  public datos_prefactura: any = [];
  /** Guarda los datos de la tabla */
  public resumen_pedido: any = [];
  /** Parametrización de la tabla de pre factura */
  public config_tabla: any = {};
  /** Data o listado de productos del pedido */
  public source?: LocalDataSource;
  public userDistribuidor: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private restService: RestService,
    private currency: CurrencyPipe,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.restService
      .get(`tipo_empresa_trabajador/${this.authService.user?._id}`)
      .toPromise()
      .then((resp: any) => {
        this.tipo_usuario = resp;
        if (this.tipo_usuario === 'distribuidor') {
            this.userDistribuidor = true;
        }
        this.pedidoId = this.activatedRoute.snapshot.paramMap.get('id');
        this.configSmartTable();
        this.loadDataTablas();
      });
  }

  /**
   * Método para configurar la Nebular Smart Table
   * @returns void
   */
  private configSmartTable(): void {
    let dataColumns
    if(!this.userDistribuidor){
      dataColumns = {
        cod_dist: {
          title: 'Código producto distribuidor',
          editable: false,
          filter: true,
        },
        nombre: {
          title: 'Nombre de producto',
          editable: false,
          filter: true,
        },
        marca: {
          title: 'Marca',
          editable: false,
          filter: true,
        },
        cod_promo_saldo: {
          title: 'Código promoción/saldo',
          editable: false,
          filter: true,
        },
        unidad_medida: {
          title: 'Unidad de medida',
          editable: false,
          filter: true,
        },
        cantidad_medida: {
          title: 'Cantidad medida',
          editable: false,
          filter: true,
        },
        und_x_caja: {
          title: 'Unidades por caja',
          editable: false,
          filter: true,
        },
        puntos_ft: {
          title: 'Puntos Feat por unidad',
          editable: false,
          filter: true,
        },
        cantidad: {
          title: 'Cantidad en unidades',
          editable: false,
          filter: true,
        },
        cajas: {
          title: 'Cantidad en cajas',
          editable: false,
          filter: true,
        },
        precio_unidad: {
          title: 'Precio Unidad (COP)',
          editable: false,
          filter: true,
        },
        
        precio_caja: {
          title: 'Precio Caja (COP)',
          editable: false,
          filter: true,
        },
        total: {
          title: 'Total pedido (COP)',
          editable: false,
          filter: true,
        },

      }
    } else {
dataColumns = {
  cod_dist: {
    title: 'Código producto distribuidor',
    editable: false,
    filter: true,
  },
  nombre: {
    title: 'Nombre de producto',
    editable: false,
    filter: true,
  },
  marca: {
    title: 'Marca',
    editable: false,
    filter: true,
  },
  cod_promo_saldo: {
    title: 'Código promoción/saldo',
    editable: false,
    filter: true,
  },
  unidad_medida: {
    title: 'Unidad de medida',
    editable: false,
    filter: true,
  },
  cantidad_medida: {
    title: 'Cantidad medida',
    editable: false,
    filter: true,
  },
  und_x_caja: {
    title: 'Unidades por caja',
    editable: false,
    filter: true,
  },
  puntos_ft: {
    title: 'Puntos Feat por unidad',
    editable: false,
    filter: true,
  },
  cantidad: {
    title: 'Cantidad en unidades',
    editable: false,
    filter: true,
  },
  cajas: {
    title: 'Cantidad en cajas',
    editable: false,
    filter: true,
  },
  precioVenta: {
    title: 'Precio base (COP)',
    editable: false,
    filter: true,
  },
  precio_unidad: {
    title: 'Precio Unidad (COP)',
    editable: false,
    filter: true,
  },
  
  precio_caja: {
    title: 'Precio Caja (COP)',
    editable: false,
    filter: true,
  },
  total: {
    title: 'Total pedido (COP)',
    editable: false,
    filter: true,
  },

}
    }

    this.config_tabla = {
      noDataMessage: 'No hay datos asociados al pedido',
      pager: {
        display: true,
        perPage: 10,
      },
      actions: false,
      hideSubHeader: false,
      columns: dataColumns,
    };

  }

  /**
   * Trae los datos para generar una prefactura
   */
  loadDataTablas() {
    this.source?.empty();
    this.restService
      .getJWT('pedido/prefactura/' + this.pedidoId)
      .toPromise()
      .then((pedidos_data: any) => {
        this.datos_prefactura = pedidos_data[0];
        /** Datos de la tabla */
        for (let index = 0; index < this.datos_prefactura.data_producto.length; index++) {
          /** Con el id del producto recupera la data de lo comprado */
          const data_compra = this.datos_prefactura.productos.find(
            (item: any) => item.product === this.datos_prefactura.data_producto[index]._id
          );
          let precioEspecial = 'No'
          let precioUnd
          let precioCj
          let precioT
          if(data_compra.precioEspecial){
            if(data_compra.precioEspecial.length > 0){
              precioEspecial = 'Si'
              precioUnd = data_compra.precioEspecial[0].precioActual
              precioCj= data_compra.precioEspecial[0].precioActual * this.datos_prefactura.data_producto[index].precios[0].und_x_caja;
              precioT = data_compra.precioEspecial[0].precioActual * data_compra.unidad;
            }else {
              precioEspecial = 'No'
              precioUnd = this.datos_prefactura.data_producto[index].precios[0].precio_unidad;
              precioCj = this.datos_prefactura.data_producto[index].precios[0].precio_caja;
              precioT = this.datos_prefactura.data_producto[index].precios[0].precio_unidad * data_compra.unidad
            }
           
          } else {
            precioEspecial = 'No'
            precioUnd = this.datos_prefactura.data_producto[index].precios[0].precio_unidad;
            precioCj = this.datos_prefactura.data_producto[index].precios[0].precio_caja;
            precioT = this.datos_prefactura.data_producto[index].precios[0].precio_unidad * data_compra.unidad
          }
          const data: any = {
            cod_dist: this.datos_prefactura.data_producto[index].codigo_distribuidor_producto || '',
            cod_promo_saldo: this.datos_prefactura.data_producto[index].codigo_promo || '',
            nombre: this.datos_prefactura.data_producto[index].nombre || '',
            unidad_medida: this.datos_prefactura.data_producto[index].precios[0].unidad_medida || '',
            cantidad_medida: this.datos_prefactura.data_producto[index].precios[0].cantidad_medida || '',
            und_x_caja: this.datos_prefactura.data_producto[index].precios[0].und_x_caja || '',
            puntos_ft: data_compra.puntos_ft_unidad || '',
            cantidad: data_compra.unidad || '',
            cajas:
              Math.round(
                (data_compra.unidad / this.datos_prefactura.data_producto[index].precios[0].und_x_caja) * 100
              ) / 100,
            precio_unidad: precioUnd,
            precio_caja: precioCj ,
            total: precioT,
            marca: '',
          };
          /** Completa/Modifica la información del producto segun su categoría */
          if (
            !this.datos_prefactura.data_producto[index].promocion &&
            !this.datos_prefactura.data_producto[index].saldos
          ) {
            /** Catalogo */
            data.marca = this.datos_prefactura.data_producto[index].nombre_marca[0].nombre;
            data.cod_promo_saldo = '-';
          } else if (this.datos_prefactura.data_producto[index].promocion) {
            /** Promoción */
            data.marca = '*Promoción*';
            data.cajas = 0;
            data.cod_dist = '-';
            data.precio_caja = '-';
            data.puntos_ft = '-';
            data.unidad_medida = '-';
            data.cantidad_medida = '-';
            data.und_x_caja = '-';
          } else if (this.datos_prefactura.data_producto[index].saldos) {
            /** Saldo */
            data.marca = '*Saldo*';
            data.puntos_ft = '-';
            data.precio_unidad = this.datos_prefactura.data_producto[index].precios[0].precio_descuento;
            data.precio_caja =
              this.datos_prefactura.data_producto[index].precios[0].precio_descuento *
              this.datos_prefactura.data_producto[index].precios[0].und_x_caja;
            data.total =
              this.datos_prefactura.data_producto[index].precios[0].precio_descuento *
              this.datos_prefactura.productos[index].unidad;
          }
          if(this.userDistribuidor){
            data.precioVenta = this.datos_prefactura.data_producto[index].precios[0].precio_unidad
          }
          this.resumen_pedido.push(data);
        }
        
        /** Se envía información a la talba */
        this.source = new LocalDataSource(this.resumen_pedido);
      });
  }

  /*
   * Organiza los datos de la prefactua y los descarga en un excel
   */
  async DescargaArchivo() {
    let codigosDescuentos = '';
    let sumatoriaCodDescuentos = 0;
    let i = 0;
    for (let cod of this.datos_prefactura.codigos_list) {
      if (i === 0) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        codigosDescuentos += cod.codigo_creado;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        codigosDescuentos += ' / ' + cod.codigo_creado;
      }
      sumatoriaCodDescuentos = sumatoriaCodDescuentos + cod.valor_moneda;
      i++;
    }
    //Recorre si contiene codigos descuento
    const resp_estado: any = await this.restService.getJWT(`pedido/ultimoTracking/${this.pedidoId}`).toPromise();
    const fecha_estado = ' (' + this.processTimeDate(resp_estado.createdAt) + ')';
    /** Guarda el contenido de la tabla */
    const arreglo = [];
    /** Resumen pedido */
    const fila_1 = ['Pedido ID', this.datos_prefactura.id_pedido];
    const fila_2 = ['Estado', this.datos_prefactura.estado + fecha_estado];
    const fila_3 = ['Distribuidor', this.datos_prefactura.nombre_distribuidor];
    const fila_4 = ['Establecimiento', this.datos_prefactura.nombre_horeca[0]];
    const fila_5 = ['Punto de entrega', this.datos_prefactura.nombre_punto[0]];
    const fila_5A = ['Dirección punto', this.datos_prefactura.data_punto[0].direccion + ' - ' + this.datos_prefactura.data_punto[0].departamento];
    const fila_6 = ['NIT/CC establecimiento', this.datos_prefactura.nit_horeca[0]];
    const fila_7 = ['Puntos ganados', this.datos_prefactura.puntos_ganados];
    const fila_8 = ['Puntos redimidos', this.datos_prefactura.puntos_redimidos];
    const fila_13 = ['Códigos redimidos', codigosDescuentos];
    const fila_14 = ['Valor descuento', sumatoriaCodDescuentos];

    const fila_9 = ['TOTAL PEDIDO (COP):', this.datos_prefactura.total_pedido];
    const fila_10: any[] = [];
    const fila_11: any[] = [];
    let fila_12 
    if(!this.userDistribuidor){
       fila_12 = [
        'Código producto distribuidor',
        'Nombre de producto',
        'Marca',
        'Código promoción/saldo',
        'Unidad de medida',
        'Cantidad medida',
        'Unidades por caja',
        'Puntos Feat por unidad',
        'Cantidad en unidades',
        'Cantidad en cajas',
        'Precio unidad (COP)',
        'Precio caja (COP)',
        'Total Pedido (COP)',
      ];
    } else {
       fila_12 = [
        'Código producto distribuidor',
        'Nombre de producto',
        'Marca',
        'Código promoción/saldo',
        'Unidad de medida',
        'Cantidad medida',
        'Unidades por caja',
        'Puntos Feat por unidad',
        'Cantidad en unidades',
        'Cantidad en cajas',
        'Precio base (COP)',
        'Precio especial (COP)',
        'Precio caja (COP)',
        'Total Pedido (COP)',
      ];
    }
    /** Titulos tabla */
    
    arreglo.push(
      fila_1,
      fila_2,
      fila_3,
      fila_4,
      fila_5,
      fila_5A,
      fila_6,
      fila_7,
      fila_8,
      fila_13,
      fila_14,
      fila_9,
      fila_10,
      fila_11,
      fila_12
    );
    for (let index = 0; index < this.resumen_pedido.length; index++) {
      const data_fila_pedido = [];
      const data_compra = this.datos_prefactura.productos.find(
        (item: any) => item.product === this.datos_prefactura.data_producto[index]._id
      );
      if(!this.userDistribuidor){
        data_fila_pedido.push(
          this.resumen_pedido[index].cod_dist,
          this.resumen_pedido[index].nombre,
          this.resumen_pedido[index].marca,
          this.resumen_pedido[index].cod_promo_saldo,
          this.resumen_pedido[index].unidad_medida,
          this.resumen_pedido[index].cantidad_medida,
          this.resumen_pedido[index].und_x_caja,
          this.resumen_pedido[index].puntos_ft,
          this.resumen_pedido[index].cantidad,
          this.resumen_pedido[index].cajas,
          this.resumen_pedido[index].precio_unidad,
          this.resumen_pedido[index].precio_unidad,
          this.resumen_pedido[index].total,
        )
      } else {
        data_fila_pedido.push(
          this.resumen_pedido[index].cod_dist,
          this.resumen_pedido[index].nombre,
          this.resumen_pedido[index].marca,
          this.resumen_pedido[index].cod_promo_saldo,
          this.resumen_pedido[index].unidad_medida,
          this.resumen_pedido[index].cantidad_medida,
          this.resumen_pedido[index].und_x_caja,
          this.resumen_pedido[index].puntos_ft,
          this.resumen_pedido[index].cantidad,
          this.resumen_pedido[index].cajas,
          this.resumen_pedido[index].precioVenta,
          this.resumen_pedido[index].precio_unidad,
          this.resumen_pedido[index].precio_unidad,
          this.resumen_pedido[index].total,
        )
      }
     
      /*if (!this.datos_prefactura.data_producto[index].promocion && !this.datos_prefactura.data_producto[index].saldos) {
        data_fila_pedido.push(
          this.datos_prefactura.data_producto[index].codigo_distribuidor_producto,
          this.datos_prefactura.data_producto[index].nombre,
          this.datos_prefactura.data_producto[index].nombre_marca[0].nombre || '',
          '-',
          this.datos_prefactura.data_producto[index].precios[0].unidad_medida,
          this.datos_prefactura.data_producto[index].precios[0].cantidad_medida,
          this.datos_prefactura.data_producto[index].precios[0].und_x_caja,
          this.datos_prefactura.data_producto[index].precios[0].puntos_ft_unidad,
          data_compra.unidad,
          Math.round((data_compra.unidad / this.datos_prefactura.data_producto[index].precios[0].und_x_caja) * 100) /
            100,
          this.datos_prefactura.data_producto[index].precios[0].precio_unidad,
          this.datos_prefactura.data_producto[index].precios[0].precio_caja,
          this.datos_prefactura.data_producto[index].precios[0].precio_unidad *
            this.datos_prefactura.productos[index].unidad
        );
      } else if (this.datos_prefactura.data_producto[index].promocion) {
        console.log('entre 02');
        //Promoción
        data_fila_pedido.push(
          '-',
          this.datos_prefactura.data_producto[index].nombre,
          '*Promoción',
          this.datos_prefactura.data_producto[index].codigo_promo,
          '-',
          '-',
          '-',
          '-',
          data_compra.unidad,
          0,
          this.currency.transform(
            this.datos_prefactura.data_producto[index].precios[0].precio_unidad,
            '$',
            'symbol',
            '1.0-0'
          ),
          '-',
          this.currency.transform(
            this.datos_prefactura.data_producto[index].precios[0].precio_unidad *
              this.datos_prefactura.productos[index].unidad,
            '$',
            'symbol',
            '1.0-0'
          )
        );
      } else if (this.datos_prefactura.data_producto[index].saldos) {
        console.log('entre 03');
        // Saldo
        data_fila_pedido.push(
          this.datos_prefactura.data_producto[index].codigo_distribuidor_producto,
          this.datos_prefactura.data_producto[index].nombre,
          '*Saldo*',
          this.datos_prefactura.data_producto[index].codigo_promo,
          this.datos_prefactura.data_producto[index].precios[0].unidad_medida,
          this.datos_prefactura.data_producto[index].precios[0].cantidad_medida,
          this.datos_prefactura.data_producto[index].precios[0].und_x_caja,
          '-',
          data_compra.unidad,
          Math.round((data_compra.unidad / this.datos_prefactura.data_producto[index].precios[0].und_x_caja) * 100) /
            100,
          this.currency.transform(
            this.datos_prefactura.data_producto[index].precios[0].precio_descuento,
            '$',
            'symbol',
            '1.0-0'
          ),
          this.currency.transform(
            this.datos_prefactura.data_producto[index].precios[0].precio_descuento *
              this.datos_prefactura.data_producto[index].precios[0].und_x_caja,
            '$',
            'symbol',
            '1.0-0'
          ),
          this.currency.transform(
            this.datos_prefactura.data_producto[index].precios[0].precio_descuento *
              this.datos_prefactura.productos[index].unidad,
            '$',
            'symbol',
            '1.0-0'
          )
        );
      }*/
      arreglo.push(data_fila_pedido);
    }
    /** Genera el worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(arreglo);
    /* Genera el workbook y agrega el  worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /** Guarda el archivo */
    XLSX.writeFile(wb, 'Lista_productos.xlsx');
  }

  /**
   * Toma la fecha y hora que entra por parámetro y lo interpreta
   * para mostrarlo en el estado del pedido
   * @param fecha_hora La fecha y hora a interpretar. Se trae del último
   * estado del pedido y es de la forma {YYYY}-{MM}-{DD}T{HH}:{MM}:{SS}.{MS}Z
   */
  processTimeDate(fecha_hora: string) {
    const fecha_pedido_entrega = new Date();
    fecha_pedido_entrega.setTime(new Date(fecha_hora).getTime());
    const hora_estado = new Date(fecha_pedido_entrega).toLocaleString('en-US').split(',')[1].trim();
    const fecha_estado = new Date(fecha_pedido_entrega).toLocaleString('en-US').split(',')[0].trim().split('/');
    return fecha_estado[1] + '/' + fecha_estado[0] + '/' + fecha_estado[2] + ' ' + hora_estado;
  }

  /**
   * Voler a modulo anterior dependiendo de donde haya ingresado
   */
  volver() {
    if (this.tipo_usuario === 'usuario_horeca') {
      this.router.navigate(['/pedidos/' + this.pedidoId]);
    } else if (this.tipo_usuario === 'distribuidor') {
      this.router.navigate(['/pedidos-distribuidor/' + this.pedidoId]);
    }
  }
}
