<app-header-horeca></app-header-horeca>
<div class="container p-0 d-flex justify-content-center">
  <div class="contenedor">
    <section class="botones-menu">
      <button (click)="sectionActive('Distribuidores')" [ngClass]="{ selected: buttonActive == 'Distribuidores' }">
        Distribuidores
      </button>
      <button (click)="sectionActive('Gráficas')" [ngClass]="{ selected: buttonActive == 'Gráficas' }">Gráficas</button>
    </section>
    <app-distribuidores-buscador *ngIf="buttonActive == 'Distribuidores'"></app-distribuidores-buscador>
    <app-distribuidores-graficas *ngIf="buttonActive == 'Gráficas'"></app-distribuidores-graficas>
  </div>
</div>
<br />