/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Input, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PlacesService } from 'src/app/services/places/places.service';

@Component({
  selector: 'app-cambiar-ciudad',
  templateUrl: './cambiar-ciudad.component.html',
  styleUrls: ['./cambiar-ciudad.component.css'],
})
export class CambiarCiudadComponent implements OnInit {
  /** Íconos */
  faTimes = faTimes;

  /** Ubicación actual */
  public departamento: any;
  public ciudad: any;

  /** Variables para el manejo de la lista de departamentos y ciudades */
  public departamentos: any[] = [];
  public ciudades: any[] = [];

  /** Callback para asignar la ciudad seleccionada */
  @Input()
  callback = (pDepto: any, pCiudad: any, pCiudades: any) => {};

  constructor(public places: PlacesService, public activeModal: NgbActiveModal) {}

  async ngOnInit() {
    this.places.getDepartmentFromServerImagine().then((departamentos: any) => {
      this.departamentos = departamentos;
      if (this.departamento !== undefined) {
        this.getPlaces();
      }
    });
  }

  async getPlaces() {
    let id_dpto = 0;
    for (const dpto of this.departamentos) {
      if (dpto.name == this.departamento) {
        break;
      }
      id_dpto++;
    }
    if (this.places.departments_colombia[id_dpto] != undefined) {
      await this.places
        .getCitiesFromServerImagine(this.places.departments_colombia[id_dpto].code)
        .then((ciudades: any) => {
          this.ciudades = ciudades;
        });
    }
  }
  /**
   * Permite que cuando se haga click sobre el botón de listo se pase la ciudad
   * al componente que lo llamó
   */
  handleSubmit() {
    /**
     * Se pasa la ciudad como objeto en aras de usar el codigo de la
     * ciudad para la consulta a la api de productos por ciudad
     */
    const ciudad = this.ciudades.find((city: any) => city.name === this.ciudad);
    this.callback(this.departamento, this.ciudad, ciudad);
    this.activeModal.close();
  }
}
