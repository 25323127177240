<div class="content">
  <div class="container">
    <div class="header-title">
      <span>Editar una Publicación</span>
      <div>
        <fa-icon [icon]="faTimes" class="cursorPointer" [styles]="{ color: '#41403E' }" id="fa-icon" (click)="close()">
        </fa-icon>
      </div>
    </div>
    <form [formGroup]="form" method="POST" (ngSubmit)="postPublication()">
      <div class="body-file" align="center">
        <div *ngIf="archivos.length > 0; else noimagen" class="imagenes-publicar">
          <div *ngFor="let item of archivosPath; let i = index">
            <div class="icono d-flex justify-content-end" (click)="removeImages(i)">
              <img src="../../../assets/img/eliminar-3.png" alt="Eliminar-imagen" class="delete-img" />
            </div>
            <div *ngIf="archivoVideo === 'video/mp4'; else MostrarImagen">
              <video controls width="200" height="200" muted>
                <source [src]="item" />
              </video>
            </div>
            <ng-template #MostrarImagen>
              <img [src]="item" alt="imagenes-publicacion m-0" class="imagen-publicacion" />
            </ng-template>
          </div>
        </div>
        <ng-template #noimagen>
          <div>
            <img class="no-preview" src="../../../assets/img/comunidad-feat – 1.png" alt="subir-imagen" />
          </div>
          <p>Sube aquí imágenes relacionadas con tu post, comparte con la comunidad las grandes cosas de tu negocio</p>
        </ng-template>
      </div>
      <div *ngIf="archivos.length > 5 || archivoVideo === 'video/mp4'; else MostrarBoton" class="text-danger mt-3 mb-3">
        Solo puedes subir 5 imagenes o un video por publicación
      </div>
      <div *ngIf="archivoVideo === 'video/mp4' && duracionVideo > 30.0">
        <div class="text-danger mt-3 mb-3">Solo puedes subir un video de 30 segundos</div>
      </div>
      <div *ngIf="pesoVideo > 10000000">
        <div class="text-danger">Solo puedes subir un video de 10 mb</div>
      </div>
      <ng-template #MostrarBoton>
        <div class="upload">
          <input type="file" id="upload" hidden (change)="captureFile($event)" formControlName="imagen_publicacion" />
          <label for="upload" class="btn-upload">
            <fa-icon [icon]="faArrowUp" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
            + Foto o video
          </label>
        </div>
      </ng-template>
      <div>
        <div>
          <span>Escribe el título de tu publicación</span>
          <input type="text" placeholder="Titulo" class="input-title" formControlName="titulo" maxlength="80" />
          <p class="error" *ngIf="titleInvalid">Ingrese el título requerido</p>
        </div>
        <span>Escribe algo en tu publicación</span>
        <textarea class="text-publication p-3" placeholder="Escribe aquí" formControlName="contenido"></textarea>
        <p class="error" *ngIf="contentInvalid">Ingrese descripción de la publicación</p>
      </div>
      <div class="share">
        <button class="btn-share" type="submit"
          [disabled]="form.invalid || duracionVideo > 30.0 || archivos.length > 5">
          Publicar
        </button>
      </div>
    </form>
  </div>
</div>