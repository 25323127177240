import { Component, OnInit } from '@angular/core';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';

@Component({
  selector: 'app-inicio-perfil-dist',
  templateUrl: './inicio-perfil-dist.component.html',
  styleUrls: ['./inicio-perfil-dist.component.css'],
})
export class InicioPerfilDistComponent implements OnInit {
  /** Íconos FontAwesome para la UI */
  faPen = faPen;
  /**Placeholder logo distribuidor  */
  placeholder_dist = 'src/assets/img/icon-organizacion.png';

  nombrePlan = 'Principiantes';
  cantidadClientes = 0;
  cantidadMaxima = 0;
  flag_plan_activo: any;

  constructor(public authService: AuthService, private distribuidorService: DistribuidorService) {}

  ngOnInit(): void {
    this.getInfoDistribuidor();
  }

  async getInfoDistribuidor() {
    const id = this.authService.user_distribuidor?._id || '';
    this.cantidadClientes = await this.distribuidorService.getClientsLength(id).toPromise();
    this.distribuidorService
      .getCurrentPlan(id)
      .toPromise()
      .then((resp: any) => {
        this.nombrePlan = resp.plan?.nombre;
        //this.flag_plan_activo = true;
      })
      .catch((err: any) => {
        console.log(err);
        //this.flag_plan_activo = false;
      });
    const cantidad = (await this.distribuidorService.getDistribuidorData(id).toPromise()).max_establecimientos;
    this.cantidadMaxima = cantidad || '+2000';
    this.cantidadClientes = this.cantidadClientes > this.cantidadMaxima ? this.cantidadMaxima : this.cantidadClientes;
  }
}
