export class Trabajador {
  constructor(
    public nombres: string,
    public apellidos: string,
    public correo: string,
    public clave: string,
    public telefono: string | undefined,
    public celular: string,
    public pais: string,
    public departamento: string,
    public ciudad: string,
    public tipo_documento: string,
    public numero_documento: string,
    public tipo_trabajador: string,
    public solicitud_vinculacion?: string,
    public show_slides?: boolean | true,
    //Representa el id del empleador
    //Solo uno entre usuario_horeca, distribuidor, y organizacion
    //debe NO ser null
    public usuario_horeca?: string,
    public puntos_entrega?: string[],
    public distribuidor?: string,
    public organizacion?: string,
    public crado_por_horeca?: boolean | false,
    public _id?: string
  ) {}
}
