import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ProcesandoInfoComponent } from '../procesando-info/procesando-info.component';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';
type AOA = any[][];
@Component({
  selector: 'app-cargar-producto-excel',
  templateUrl: './cargar-producto-excel.component.html',
  styleUrls: ['./cargar-producto-excel.component.css'],
})
export class CargarProductoExcelComponent {
  /** Referencias a iconos FontAwesome */
  faTimes = faTimes;
  faTimesCircle = faTimesCircle;
  /** Flag de error de formato */
  error_formato_xlsx = false;
  /** Archivo de excel */
  plantilla_prodcutos?: File;
  /** Recibe instruccion de padre si es creacion de producto o es actualziación de inventario */
  @Input() inventario_xlsx? = false;
  /** Recibe la funcion para descargar el excel de inventario actual */
  @Output() clickevent = new EventEmitter();

  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal) {}

  /**
   * Revisa que se haya subido un archivo de Excel
   * Si sí se subió uno, continua con el proceso
   * Si no, le avisa al usuario del error
   */
  siguiente() {
    console.log('this.plantilla_prodcutos', this.plantilla_prodcutos);
    console.log('this.inventario_xlsx', this.inventario_xlsx);
    this.activeModal.close();
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    const modalRef = this.modalService.open(ProcesandoInfoComponent, ngbModalOptions);
    modalRef.componentInstance.productos_xlsx = this.plantilla_prodcutos;
    modalRef.componentInstance.inventario_xlsx = this.inventario_xlsx;
  }

  /**
   * Permite comunicarse con componente padre y decargar Excel con inventario actual
   */
  descargarExcelInventario() {
    this.clickevent.emit();
  }

  /**
   * Recibe un archivo del usuario y lo guarda
   * en el arreglo de imágenes del producto. Si
   * el archivo no tiene el formato correcto, no
   * se guarda y se muestra de error
   * @param event El evento generado al subir el archivo
   */
  handleFileInput(event: any) {
  /* wire up file reader */
  const target: DataTransfer = <DataTransfer>(event.target);
  if (target.files.length !== 1) throw new Error('Cannot use multiple files');
  const reader: FileReader = new FileReader();
  reader.onload = (e: any) => {
    /* read workbook */
    const bstr: string = e.target.result;
    const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

    /* grab first sheet */
    const wsname: string = wb.SheetNames[0];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];

    /* save data */
    let data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
    this.verifiData(data)
  };
  reader.readAsBinaryString(target.files[0]);











    this.error_formato_xlsx = false;
    if (event) {
      const file: File = event.target.files[0];
      if (file) {
        const file_split: string[] = file.name.split('.');
        const file_end: string = file_split[file_split.length - 1].trim().toLowerCase();
        if (file_end.toLowerCase() == 'xlsx') {
          //Se está intentando subir un archivo de imagen
          this.plantilla_prodcutos = file;
        } else {
          this.error_formato_xlsx = true;
        }
      } else {
        this.plantilla_prodcutos = undefined;
      }
    } else {
      this.plantilla_prodcutos = undefined;
    }
  }

  verifiData(data: any){
    for(let camp of data){
      console.log(camp)
    }
  }
}
