<app-header-horeca></app-header-horeca>
<div class="container" [ngClass]="{'blur-contenido': productos?.length === 0}">
  <h2 class="main-title">Mi carrito de compra</h2>
  <div class="detalle-compra">
    <div class="data-distribuidor p-4">
      <div class="d-flex align-items-center h-100" *ngIf="vinculacion_distribuidor_punto">
        <img [src]="vinculacion_distribuidor_punto.distribuidor.logo" alt="logo" class="pr-3" />
        <div class="d-flex flex-column">
          <span class="pl-2">{{vinculacion_distribuidor_punto.distribuidor.nombre}}</span>
          <span class="convenio">
            {{vinculacion_distribuidor_punto.convenio == true ? '$ Convenio' : 'Sin Convenio'}}
          </span>
          <div class="w-100 pl-2">
            Valor mínimo del pedido: {{vinculacion_distribuidor_punto.distribuidor.valor_minimo_pedido | currency:
            '$':'symbol':'1.0-0'}}
          </div>
        </div>
      </div>
      <span class="continuar-comprando" (click)="continuarComprando()">Continuar comprando</span>
    </div>
    <div class="productos-carrito p-4">
      <div class="productos-carrito-titulos pb-3">
        <span>{{ productos_length }} producto{{productos_length === 1 ? '' : 's'}} - {{ total_unidades_compra }}
          und{{total_unidades_compra === 1 ? '' : 's'}}.<span class="texto-temporal"> en tu
            compra</span></span>
        <span class="vaciar text-right" (click)="amptyCart()">Vaciar carrito</span>
      </div>
      <hr />
      <div class="products-list-container pt-3">
        <div class="product-item" *ngFor="let producto of productos; let i = index">
          <div  *ngIf="producto?.descuentosEspeciales.length > 0">
       
            



            <div class="d-flex flex-column w-25">
              <span *ngIf="producto.promocion == false" class="d-flex justify-content-center">
                <img class="mb-0 h-75" src="{{ producto?.fotos[0] || product_placeholder}}"
                  [alt]="'Foto de ' + producto?.nombre" />
              </span>
              <span *ngIf="producto.promocion == true">
                <div class="h-75 carouselCont">
                  <ngb-carousel class="w-100">
                    <ng-template ngbSlide *ngFor="let img_src of producto.fotos" class="w-100">
                      <div class="w-100 col-12 d-flex justify-content-center">
                        <img [src]="img_src" class="img-prod rounded" [alt]="'Foto de ' + producto.nombre" />
                      </div>
                    </ng-template>
                  </ngb-carousel>
                </div>
              </span>
              <span class="w-100 text-center h-25 mb-2"
                *ngIf="producto.promocion == false && producto.saldos == false && producto?.flag_aplica_puntos_feat == true">
                {{producto?.precios[0].puntos_ft_unidad}} pts Ft
              </span>
            </div>
            <div class="item-data">
              <div>
                <span class="w-75 d-flex flex-row justify-content-start align-items-start">
                  &nbsp;{{ producto?.nombre }}
                </span>
                <fa-icon class="w-25 text-right" [icon]="faTrash" [styles]="{ color: '#41403E' }" id="fa-icon"
                  (click)="removeCart(i)"></fa-icon>
              </div>
              <div class="w-100 d-flex flex-column">
                <span class="w-100" *ngIf="producto.saldos === true">
                  Código saldo: {{producto?.codigo_promo}}
                </span>
                <span class="w-100" *ngIf="producto.promocion === false">
                  Código distribuidor: {{producto?.codigo_distribuidor_producto}}
                </span>
                <span class="w-100" *ngIf="producto.promocion === true">
                  Código promoción: {{producto?.codigo_promo}}
                </span>
              </div>
              <div class="d-flex flex-row justify-content-between">
                
                <span class="pl-0">
                  {{ producto?.descuentosEspeciales[0].precioActual | currency: '$':'symbol':'1.0-0' }}
                </span>
                <span>&nbsp;|&nbsp;</span>
               
                  <span class="text-right pr-0">
                    {{ producto?.descuentosEspeciales[0].precioActual * producto?.precios[0].und_x_caja | currency:
                      '$':'symbol':'1.0-0' }}caja
                  </span>

                  <span *ngIf="producto.promocion === false">&nbsp;|&nbsp;</span>
                <span class="text-right pr-0">
                  {{producto?.precios[0].cantidad_medida}} ${{producto?.precios[0].unidad_medida}}
                </span>
              </div>
              <div class="botones">
                <div class="w-50 align-items-center">
                  <span class="text-center">Unidades</span>
                  <div>
                    <button (click)="quantity(false, i, 'unidad')">-</button>
                    <input [(ngModel)]="order.products[i].unidad"  (keyup)="calculatePrice(false, i, 'new',order.products[i].unidad)">
                    <button (click)="quantity(true, i, 'unidad')">+</button>
                  </div>
                </div>
                <div class="w-50 align-items-center">
                  <span class="text-center">Total en cajas</span>
                  <div class="cajas-container">
                    <p class="m-0">
                      {{totalCajasPedido(producto?.precios[0].und_x_caja, order.products[i].unidad)}}
                    </p>
                  </div>
                </div>
              </div>
              <p class="text-secondary w-100 text-center">
                {{unidadesPendientesCaja(producto?.precios[0].und_x_caja, order.products[i].unidad)}} und
                pend. para otra caja
              </p>
            </div> 




          </div>
          <div  *ngIf="producto?.descuentosEspeciales.length === 0">
            <div class="d-flex flex-column w-25">
              <span *ngIf="producto.promocion == false" class="d-flex justify-content-center">
                <img class="mb-0 h-75" src="{{ producto?.fotos[0] || product_placeholder}}"
                  [alt]="'Foto de ' + producto?.nombre" />
              </span>
              <span *ngIf="producto.promocion == true">
                <div class="h-75 carouselCont">
                  <ngb-carousel class="w-100">
                    <ng-template ngbSlide *ngFor="let img_src of producto.fotos" class="w-100">
                      <div class="w-100 col-12 d-flex justify-content-center">
                        <img [src]="img_src" class="img-prod rounded" [alt]="'Foto de ' + producto.nombre" />
                      </div>
                    </ng-template>
                  </ngb-carousel>
                </div>
              </span>
              <span class="w-100 text-center h-25 mb-2"
                *ngIf="producto.promocion == false && producto.saldos == false && producto?.flag_aplica_puntos_feat == true">
                {{producto?.precios[0].puntos_ft_unidad}} pts Ft
              </span>
            </div>
            <div class="item-data">
              <div>
                <span class="w-75 d-flex flex-row justify-content-start align-items-start">
                  <span class="promo-tag m-0" *ngIf="producto.saldos === true || producto.promocion === true">
                    {{producto.promocion === true ? 'Promo' : ''}}{{producto.saldos === true ? 'Saldo' : ''}}
                  </span>
                  &nbsp;{{ producto?.nombre }}
                </span>
                <fa-icon class="w-25 text-right" [icon]="faTrash" [styles]="{ color: '#41403E' }" id="fa-icon"
                  (click)="removeCart(i)"></fa-icon>
              </div>
              <div class="w-100 d-flex flex-column">
                <span class="w-100" *ngIf="producto.saldos === true">
                  Código saldo: {{producto?.codigo_promo}}
                </span>
                <span class="w-100" *ngIf="producto.promocion === false">
                  Código distribuidor: {{producto?.codigo_distribuidor_producto}}
                </span>
                <span class="w-100" *ngIf="producto.promocion === true">
                  Código promoción: {{producto?.codigo_promo}}
                </span>
              </div>
              <div class="d-flex flex-row justify-content-between">
                <span class="pl-0" *ngIf="producto.saldos === false && !producto?.prodDescuento">
                  {{ producto?.precios[0].precio_unidad | currency: '$':'symbol':'1.0-0' }}und
                </span>
                <span *ngIf="producto.saldos === false && producto?.prodDescuento && producto?.prodPorcentajeDesc > 0">
                  {{ ((producto?.precios[0]?.precio_unidad - (producto?.precios[0]?.precio_unidad * (producto?.prodPorcentajeDesc / 100))) | currency: '$':'symbol':'1.0-0') }}
                </span>
                <span class="pl-0" *ngIf="producto.saldos === true">
                  {{ producto?.precios[0].precio_descuento | currency: '$':'symbol':'1.0-0' }}und
                </span>
                <span *ngIf="producto.promocion === false">&nbsp;|&nbsp;</span>
                <span *ngIf="producto.promocion === false && producto.saldos === false && !producto?.prodDescuento">
                  {{ producto?.precios[0].precio_caja | currency: '$':'symbol':'1.0-0' }}caja
                </span>
                <span *ngIf="producto.promocion === false && producto.saldos === false && producto?.prodDescuento && producto?.prodPorcentajeDesc">
                  {{ calcularPrecioCaja(producto) | currency: '$':'symbol':'1.0-0' }}caja
                </span>
                <span *ngIf="producto.promocion === false && producto.saldos === true">
                  {{ producto?.precios[0].precio_descuento * producto?.precios[0].und_x_caja | currency:
                  '$':'symbol':'1.0-0' }}caja
                </span>
                <span *ngIf="producto.promocion === false">&nbsp;|&nbsp;</span>
                <span *ngIf="producto.promocion === false" class="text-right pr-0">
                  {{producto?.precios[0].cantidad_medida}} ${{producto?.precios[0].unidad_medida}}
                </span>
              </div>
              <div class="botones">
                <div class="w-50 align-items-center">
                  <span class="text-center">Unidades</span>
                  <div>
                    <button (click)="quantity(false, i, 'unidad')">-</button>
                    <input [(ngModel)]="order.products[i].unidad"  (keyup)="calculatePrice(false, i, 'new',order.products[i].unidad)">
                    <button (click)="quantity(true, i, 'unidad')">+</button>
                  </div>
                </div>
                <div class="w-50 align-items-center">
                  <span class="text-center">Total en cajas</span>
                  <div class="cajas-container">
                    <p class="m-0">
                      {{totalCajasPedido(producto?.precios[0].und_x_caja, order.products[i].unidad)}}
                    </p>
                  </div>
                </div>
              </div>
              <p class="text-secondary w-100 text-center">
                {{unidadesPendientesCaja(producto?.precios[0].und_x_caja, order.products[i].unidad)}} und
                pend. para otra caja
              </p>
            </div> 
          </div>
          <hr />
        </div>
      </div>
    </div>
    <div class="resumen p-0 d-flex flex-column justify-content-between">
      <div class="resumen-titulo-contenedor space-between px-4 pt-4">
        <span class="resumen-titulo" *ngIf="!flag_editar_pedido">Resumen del pedido</span>
        <span class="resumen-titulo" *ngIf="flag_editar_pedido">Editar tu pedido en curso ID: {{ pedio_editar.id_pedido
          }}</span>
        <span class="resumen-punto-entrega">
          <img src="../../assets/img/icons/icon-pin-map.png" style="width: 20px; height: 20px" alt="Ícono de mapa" />
          {{ punto_entrega?.nombre }}
        </span>
      </div>
      <div class="space-between resumen-puntos">
        <div class="w-50 d-flex justify-content-start">
          <span>Puntos FT ganados</span>
          <span class="pr-2 resumen-puntos-digitos">{{ puntosGanados }}</span>
        </div>
        <div class="w-50 d-flex justify-content-end">
          <span>Puntos FT usados</span>
          <span class=" resumen-puntos-digitos">{{ puntosUsados }}</span>
        </div>
      </div>
      <div class="colunm-direction px-4">
        <div class="space-between">
          <span>Subtotal</span>
          <span>{{ subtotalPrice | currency: '$ ':'symbol':'1.0-0' }}</span>
        </div>
        <div class="space-between">
          <span>Descuento</span>
          <span class="descuento">{{ descuento | currency: '$ ':'symbol':'1.0-0' }}</span>
        </div>
        <div class="space-between total" [ngClass]="{ 'error-color': descuento_excedido }">
          <span>Total</span>
          <span>{{ totalPrice | currency: '$ ':'symbol':'1.0-0' }}</span>
        </div>
        <span>
          Los pagos no están integrados con la aplicación, gestionarás el pago de tu pedido directamente con el
          distribuidor.
        </span>
      </div>
      <div class="colunm-direction codigo-descuento px-4">
        <h5 style="color: #ff7171" *ngIf="limite_codigos">Solo es posible agregar maximo tres codigos de descuento</h5>
        <span>Código descuento</span>
        <span *ngIf="descuento_excedido" class="descuento-excedido">
          El descuento excede el valor total de la compra, elimine un codigo para continuar con el pedido
        </span>
        <div *ngIf="listaCodigosDescuento">
          <select id="descuentos" [(ngModel)]="codigoSeleccionado" placeholder="Selecciona" *ngIf="!flag_editar_pedido" style="font-size: 12.5px;">
            <option *ngIf="listaCodigosDescuento.length == 0">No tienes códigos para redimir</option>
            <option *ngFor="let codigo of listaCodigosDescuento; let i = index" value="{{ codigo._id }}">
              {{ codigo.codigo_creado }}
            </option>
          </select>
          <span class="aplicar" (click)="addCodDescuento()"
            *ngIf="!flag_editar_pedido && listaCodigosDescuento.length > 0">Aplicar</span>
        </div>
        <div>
          <div *ngFor="let cod of codigosDescuentoArray; let j = index" class="codigo">
            <span>Código: {{ cod.codigo_creado }}</span>
            <fa-icon *ngIf="!flag_editar_pedido" [icon]="faTimes" [styles]="{ color: '#41403E' }" id="fa-icon"
              (click)="returnedCodDescuento(cod._id)"></fa-icon>
          </div>
        </div>
        <div>
          <div>
            <span>Método de pago:</span> <br>
            <select id="descuentos" [(ngModel)]="metodoPagoSelecionado" placeholder="Selecciona" style="    font-size: 12.5px;">
              <option *ngFor="let met of metodosPagoDistribuidor; let i = index" value="{{met}}">
                {{met }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <button class="space-between crear-pedido" [disabled]="descuento_excedido"
        [ngClass]="{ excedido: descuento_excedido }" (click)="crearPedido()"
        *ngIf="!flag_editar_pedido; else editar_pedido">
        <span>
          Crear pedido
        </span>
        <fa-icon [icon]="faChevronRight" [styles]="{ color: '#fff' }" id="fa-icon"></fa-icon>
      </button>
      <ng-template #editar_pedido>
        <button class="space-between crear-pedido" [disabled]="descuento_excedido"
          [ngClass]="{ excedido: descuento_excedido }" (click)="editarPedidoEnCurso()">
          <span>
            Editar pedido
          </span>
          <fa-icon [icon]="faChevronRight" [styles]="{ color: '#fff' }" id="fa-icon"></fa-icon>
        </button>
      </ng-template>
    </div>
  </div>
  <!--Completa tu compra-->
  <span style="font-size: 18px" class="mt-3">Completa tu compra</span>
  <!--Estado vacío-->
  <div *ngIf="productosCompletaCompra.length == 0">
    <div class="row text-center">
      <div class="col-12">
        <img class="imagen-vacio" src="../../assets/img/icon-warning-amarillo.png" alt="Ícono de estado vacío" />
      </div>
      <br />
      <div class="col-12">
        <h6>El distribuidor seleccionado no tiene promociones disponibles para que completes tu compra</h6>
      </div>
    </div>
  </div>
  <!--------Productos Completa tu compra-------->
  <div class="products-container" *ngIf="productosCompletaCompra.length != 0">
    <div class="col-prod" *ngFor="let prod of productosCompletaCompra; index as i">
      <!------Card saldo------>
      <div class="card card_prod" *ngIf="prod.saldos === true">
        <div class="card-body card_body_prod">
          <!--Header código-->
          <div class="row header_codigo">
            <div class="col-12 text-center">
              <p>Código saldo:<br>{{ prod?.codigo_distribuidor_producto || '' }}</p>
            </div>
          </div>
          <!--Estado de productos y puntos-->
          <div class="row row_data_prod">
            <div class="col-12 text-left">
              <p>{{ prod.precios[0].estado }}</p>
            </div>
          </div>
          <!--Foto producto-->
          <div class="row row_data_prod">
            <div class="col-12 text-center">
              <img [src]="prod.fotos[0]" class="img-prod" [alt]="'Foto de ' + prod.nombre" />
            </div>
          </div>
          <!--Nombre producto-->
          <div class="row row_data_prod">
            <div class="col-12 text-left">
              <p>{{ prod.nombre }}</p>
            </div>
          </div>
          <!--Fecha de vencimiento-->
          <div class="row row_data_prod" *ngIf="prod.fecha_vencimiento">
            <div class="col-12 text-left">
              <p>Vence: {{ prod.fecha_vencimiento.split('T')[0] }}</p>
            </div>
          </div>
          <!--Precio anterior-->
          <div class="row row_data_prod" style="color: red">
            <div class="col-6 text-left pr-0">
              <p class="precio">Precio anterior</p>
            </div>
            <div class="col-6 text-right">
              <h5 style="text-decoration: line-through" class="pl-0">
                {{ prod.precios[0].precio_unidad | currency: '$ ':'symbol':'1.0-0' }}
              </h5>
            </div>
          </div>
          <!--Precio por unidad-->
          <div class="row row_data_prod">
            <div class="col-5 text-left pr-0">
              <p class="precio">Precio und.</p>
            </div>
            <div class="col-7 text-right">
              <h5 class="pl-0">
                {{ prod.precios[0].precio_descuento | currency: '$ ':'symbol':'1.0-0' }}
              </h5>
            </div>
          </div>
          <br />
          <!--Btns unidades y cajas-->
          <div class="row row_data_prod">
            <div class="botones-contenedor">
              <div class="botones-completar-compra">
                <div class="suma" *ngIf="prod?.index && order.products[prod?.index - 1]?.unidad">
                  <span>Unidades</span>
                  <div>
                    <button (click)="quantity(false, prod?.index - 1, 'unidad')">-</button>
                    <span>{{ order.products[prod?.index - 1]?.unidad }}</span>
                    <button (click)="quantity(true, prod?.index - 1, 'unidad')">+</button>
                  </div>
                </div>
                <div (click)="
                    agregarProduct(prod, 1, 0, prod.precios[0].und_x_caja || 1, prod.precios[0].inventario_unidad || 0, true)
                  " class="agregar" *ngIf="!prod?.index || !order.products[prod?.index - 1]?.unidad">
                  <span>+ agregar</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!------Card promoción------>
      <div class="card card_prod" *ngIf="prod.promocion === true">
        <div class="card-body card_body_prod">
          <!--Header código-->
          <div class="row header_codigo">
            <div class="col-12 text-center">
              <p>Código promoción:<br>{{ prod?.codigo_promo || '' }}</p>
            </div>
          </div>
          <!--Estado de productos y puntos-->
          <div class="row row_data_prod">
            <div class="col-6 text-left">
              <p>{{ prod.precios[0].estado }}</p>
            </div>
            <div class="col-6 text-right">
              <div class="promo-tag">
                Promo
              </div>
            </div>
          </div>
          <!--Foto producto-->
          <div class="row row_data_prod carouselCont">
            <ngb-carousel class="w-100">
              <ng-template ngbSlide *ngFor="let img_src of prod.fotos" class="w-100">
                <div class="w-100 col-12 d-flex justify-content-center">
                  <img [src]="img_src" class="img-prod rounded" [alt]="'Foto de ' + prod.nombre" />
                </div>
              </ng-template>
            </ngb-carousel>
          </div>
          <!--Nombre producto-->
          <div class="row row_data_prod">
            <div class="col-12 text-left">
              <p>{{ prod.nombre }}</p>
            </div>
          </div>
          <!--Precio por unidad-->
          <div class="row row_data_prod">
            <div class="col-5 text-left">
              <p class="precio">Precio und.</p>
            </div>
            <div class="col-7 text-right">
              <h5 class="pl-0">
                {{ prod.precios[0].precio_unidad | currency: '$ ':'symbol':'1.0-0' }}
              </h5>
            </div>
          </div>
          <br />
          <!--Btns unidades y cajas-->
          <div class="row row_data_prod">
            <div class="botones-contenedor">
              <div class="botones-completar-compra">
                <div class="suma" *ngIf="prod?.index && order.products[prod?.index - 1]?.unidad">
                  <span>Unidades</span>
                  <div>
                    <button (click)="quantity(false, prod?.index - 1, 'unidad')">-</button>
                    <span>{{ order.products[prod?.index - 1]?.unidad }}</span>
                    <button (click)="quantity(true, prod?.index - 1, 'unidad')">+</button>
                  </div>
                </div>
                <div (click)="
                    agregarProduct(prod, 1, 0, prod.precios[0].und_x_caja || 1, prod.precios[0].inventario_unidad || 0, true)
                  " class="agregar" *ngIf="!prod?.index || !order.products[prod?.index - 1]?.unidad">
                  <span>+ agregar</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br />