import { Component, OnInit } from '@angular/core';
import { faBell, faClipboard, faCommentDots, faUser } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-inicio-indicadores-dist',
  templateUrl: './inicio-indicadores-dist.component.html',
  styleUrls: ['./inicio-indicadores-dist.component.css'],
})
export class InicioIndicadoresDistComponent implements OnInit {
  /** Íconos FontAwesome para la UI */
  faBell = faBell;
  faCommentDots = faCommentDots;
  faClipboard = faClipboard;
  faUser = faUser;

  /** Guarda los banners publicitarios */
  public banners: any;
  public indicadores: any;
  constructor(private restService: RestService, public authService: AuthService) {}
  ngOnInit(): void {
    this.getBanners();
    this.getindicadores();
  }

  /**
   * Devuelve los indicadores del distribuidor
   */
  getindicadores() {
    const idDistribuidor = this.authService.user_distribuidor?._id || '';
    const idTrabajador = this.authService.user?._id || '';
    this.restService
      .getJWT(`indicadores_home_distribuidor/${idDistribuidor}/${idTrabajador}`)
      .toPromise()
      .then((resp: any) => {
        this.indicadores = resp;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  /**
   * Devuelve los banners del home page
   */
  getBanners() {
    this.restService
      .getJWT('banner/Distribuidores')
      .toPromise()
      .then((res5: any) => {
        this.banners = res5.filter((element: any) => element.estado === 'Activo');
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  /**
   * Abre url de un banner seleccionado
   */
  goToLink(url: string) {
    window.open(url, '_blank');
  }
}
