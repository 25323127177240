import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { faChevronDown, faCircle } from '@fortawesome/free-solid-svg-icons';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GraficasService } from 'src/app/services/graficas-tablas/graficas.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { RestService } from 'src/app/services/rest/rest.service';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';

@Component({
  selector: 'app-grafica-puntos-ft',
  templateUrl: './grafica-puntos-ft.component.html',
  styleUrls: ['./grafica-puntos-ft.component.css'],
})
export class GraficaPuntosFtComponent implements OnInit {
  /** ID distribuidor */
  public id_distribuidor = this.authService.user_distribuidor!._id;
  /** Lista de meses para apoyar el formateo de la fecha */
  public anos_filtro: any = [];
  public filtro_24_meses: any = [];
  private meses: string[] = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  /** Modal de carga para darle feedback al usuario */
  public modalCarga?: NgbModalRef;
  /** Evita que al hacer click por fuera se cierre el modal */
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };
  /** Biding HTML-Canvas y TS */
  @ViewChild('bar_puntosFt_establecimientos', { static: true })
  private bar_puntosFt_establecimientos!: ElementRef;
  @ViewChild('bar_puntosFt_mes', { static: true })
  private bar_puntosFt_mes!: ElementRef;
  @ViewChild('bar_puntosFt_codigo', { static: true })
  private bar_puntosFt_codigo!: ElementRef;
  @ViewChild('pie_puntosFt_estados', { static: true })
  private pie_puntosFt_estados!: ElementRef;
  /** Guarda datos de la talba y configuraciones */
  public data_bar_puntosFt_establecimientos: any;
  public data_bar_puntosFt_mes: any;
  public data_bar_puntosFt_codigo: any;
  public data_pie_puntosFt_estados: any;
  /** Carga la grafica de chart JS */
  public bar_chart_puntosFt_establecimientos: any;
  public bar_chart_puntosFt_mes: any;
  public bar_chart_puntosFt_codigo: any;
  public bar_chart_puntosFt_estados: any;
  /** Total tabla */
  public total_puntosFt_mes: any;
  public total_puntosFt_establecimientos: any;
  public total_puntosFt_codigo: any;
  public total_puntosFt_estados: any;
  /** Filtros graficas */
  public filtro_puntosFt_mes: any;
  public filtro_puntosFt_establecimientos_ano: any;
  public filtro_puntosFt_codigo: any;
  public filtro_puntosFt_estados: any;

  constructor(
    private restService: RestService,
    private router: Router,
    private ngxsStore: Store,
    private authService: AuthService,
    private modalService: NgbModal,
    private graficasService: GraficasService
  ) {
    this.data_bar_puntosFt_mes = {
      labels: [],
      datasets: [],
    };
    this.data_bar_puntosFt_establecimientos = {
      labels: [],
      datasets: [],
    };
    this.data_bar_puntosFt_codigo = {
      labels: [],
      datasets: [],
    };
    this.data_pie_puntosFt_estados = {
      labels: [],
      datasets: [],
    };
  }
  async ngOnInit() {
    this.id_distribuidor = await this.ngxsStore.snapshot().auth.user_distribuidor._id;
    /** Modal generíco cargando... */
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    /** Arma array de filtros de meses y años */
    await this.getListaFiltroAnio();
    /** Carga datos de graficas */
    await this.getChartPuntosFtMes();
    await this.getChartPuntosFtEstablecimiento();
    await this.getChartPuntosFtCodigo();
    await this.getChartPuntosFtEstados();
    /** Cerrar Modal generíco cargando... */
    this.modalCarga?.close();
  }

  /**
   * Arma lista de filro año
   */
  getListaFiltroAnio() {
    const fecha_actual = new Date();
    const ano_actual = fecha_actual.getFullYear();
    /** Filtro por años (últimos 10 años) */
    for (let index = 0; index < 2; index++) {
      this.anos_filtro.push(ano_actual - index);
    }
    this.filtro_puntosFt_mes = this.anos_filtro[0];
    this.filtro_puntosFt_establecimientos_ano = this.anos_filtro[0];
    this.filtro_puntosFt_codigo = this.anos_filtro[0];
    this.filtro_puntosFt_estados = this.anos_filtro[0];
  }

  /**
   * Inicializa los datos de las gráficas y lo carga en el DOM
   */
  /***************** Puntos Feat por establecimiento ****************/
  public async getChartPuntosFtEstablecimiento() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_puntosFt_establecimientos) {
      this.bar_chart_puntosFt_establecimientos.destroy();
      this.data_bar_puntosFt_establecimientos.labels = [];
      this.data_bar_puntosFt_establecimientos.datasets = [];
    }
    /** Se arma data para query */
    const first_day = this.filtro_puntosFt_establecimientos_ano + '-01-01';
    const last_day = this.filtro_puntosFt_establecimientos_ano + '-12-31';
    let total_puntosFt_establecimientos = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/puntosFt/establecimientos/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_puntosFt_establecimientos.labels.push(
            this.meses[element._id.split('-')[1] - 1] + '-' + element._id.split('-')[0]
          );
          this.data_bar_puntosFt_establecimientos.datasets.push(element.total);
          total_puntosFt_establecimientos = total_puntosFt_establecimientos + element.total;
        });
        this.total_puntosFt_establecimientos = total_puntosFt_establecimientos;
        /** Se crea la grafica */
        this.bar_chart_puntosFt_establecimientos = this.graficasService.createBarChart(
          this.bar_puntosFt_establecimientos,
          this.data_bar_puntosFt_establecimientos,
          'Número de establecimientos redimiendo códigos',
          '# de establecimientos',
          'Meses'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }

  /***************** Puntos Fet por mes ****************/
  public async getChartPuntosFtMes() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_puntosFt_mes) {
      this.bar_chart_puntosFt_mes.destroy();
      this.data_bar_puntosFt_mes.labels = [];
      this.data_bar_puntosFt_mes.datasets = [];
    }
    /** Se arma data para query */
    const first_day = this.filtro_puntosFt_mes + '-01-01';
    const last_day = this.filtro_puntosFt_mes + '-12-31';
    let total_puntosFt_mes = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/puntosFt/mes/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_puntosFt_mes.labels.push(
            this.meses[element._id.split('-')[1] - 1] + '-' + element._id.split('-')[0]
          );
          this.data_bar_puntosFt_mes.datasets.push(element.total);
          total_puntosFt_mes = total_puntosFt_mes + element.total;
        });
        this.total_puntosFt_mes = total_puntosFt_mes;
        /** Se crea la grafica */
        this.bar_chart_puntosFt_mes = this.graficasService.createBarChart(
          this.bar_puntosFt_mes,
          this.data_bar_puntosFt_mes,
          'Puntos Fet por mes',
          'Número de puntos',
          'Meses'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }
  /***************** Número de código en redención ****************/
  public async getChartPuntosFtCodigo() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_puntosFt_codigo) {
      this.bar_chart_puntosFt_codigo.destroy();
      this.data_bar_puntosFt_codigo.labels = [];
      this.data_bar_puntosFt_codigo.datasets = [];
    }
    /** Se arma data para query */
    const first_day = this.filtro_puntosFt_codigo + '-01-01';
    const last_day = this.filtro_puntosFt_codigo + '-12-31';
    let total_puntosFt_codigo = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/puntosFt/codigo/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((tipos: any) => {
        tipos.data.forEach((element: any) => {
          this.data_bar_puntosFt_codigo.labels.push(
            this.meses[element._id.split('-')[1] - 1] + '-' + element._id.split('-')[0]
          );
          this.data_bar_puntosFt_codigo.datasets.push(element.total);
          total_puntosFt_codigo = total_puntosFt_codigo + element.total;
        });
        this.total_puntosFt_codigo = total_puntosFt_codigo;
        /** Se crea la grafica */
        this.bar_chart_puntosFt_codigo = this.graficasService.createBarChart(
          this.bar_puntosFt_codigo,
          this.data_bar_puntosFt_codigo,
          'Número de códigos en redención',
          'Número de códigos',
          'Meses'
        );
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }

  /***************** Estados códgios de redención ****************/
  public async getChartPuntosFtEstados() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_puntosFt_estados) {
      this.bar_chart_puntosFt_estados.destroy();
      this.data_pie_puntosFt_estados.labels = [];
      this.data_pie_puntosFt_estados.datasets = [];
    }
    /** Se arma data para query */
    const first_day = this.filtro_puntosFt_estados + '-01-01';
    const last_day = this.filtro_puntosFt_estados + '-12-31';
    this.total_puntosFt_estados = 0;
    let total_puntosFt_estados = 0;
    /** Query de la data para la grafica */
    await this.restService
      .getJWT(`informes/distribuidor/puntosFt/estado/${this.id_distribuidor}/${first_day}/${last_day}`)
      .toPromise()
      .then((estados: any) => {
        if (estados.data.length > 0) {
          estados.data.forEach((element: any) => {
            this.data_pie_puntosFt_estados.labels.push(element._id);
            this.data_pie_puntosFt_estados.datasets.push(element.total);
            total_puntosFt_estados = total_puntosFt_estados + element.total;
          });
          this.total_puntosFt_estados = total_puntosFt_estados;
          this.bar_chart_puntosFt_estados = this.graficasService.createPieChart(
            this.pie_puntosFt_estados,
            this.data_pie_puntosFt_estados,
            'Estados códgios de redención'
          );
        }
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio-distribuidor']);
    };
  }
}
